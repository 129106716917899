import React, { useContext, useEffect, useState, useRef, } from "react";
import User from "../../API/APIServices/User";
import { Link, useNavigate } from "react-router-dom";
import Company from "../../API/APIServices/Company";
import LoginApi from "../../API/APIServices/User";
import Loader from "../Props/Loader";
import MyContext from "../MyContext";
import AlertBox from "../Props/AlertBox";
import UserCredential from "../../API/APIServices/UserCredential";
import Order from "../../API/APIServices/Order";
import logo from "./../../images/logo.png";
import CommonAlertBox from "../Props/CommonAlertBox";

import { useSearchParams } from 'react-router-dom';
import ModalBoxMaster from "../Props/ModalBoxMaster";

function LogInPanel() {

  const [isSoftwareConfigOpen, setIsSoftwareConfigOpen] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    OpenAlertBoxEdit,
    alertBoxEdit,
    OpenAlertBox,
    alertBox,
    setUserDetails,
    fetchCompanyData,
    isAuthenticated,
    setIsAuthenticated
  } = useContext(MyContext);
  const [inputValues, setInputValues] = useState({
    compCode: "",
    userName: "",
    password: "",
    comClientRefId: "",
  });
  const [Clientregistered, setClientregistered] = useState(false)

  const { logindone, setLoginUser } = useContext(MyContext);

  const [isShowAlertBox, setIsShowAlertBox] = useState(false);
  const [isSingleButton, setIsSingleButton] = useState(false);
  const [headingTop, setHeadingTop] = useState("Alert");
  const [message, setMessage] = useState("");
  const [closeBtnName, setCloseBtnName] = useState("Close");
  const [okayBtnName, setOkayBtnName] = useState("OK");

  const closeBtn = () => {
    setIsShowAlertBox(false)
  }

  async function getCompany(q) {
    try {
      let company_list = await Company.GetCompany(q);
      setCompanyList(company_list);
      setInputValues((prevValues) => ({
        ...prevValues,
        compCode: company_list[0]?.branchCode || "",
      }));
    } catch (error) {
      console.log("Error on fetching get company with query parameter", error)
    }
  }

  useEffect(() => {
    const myParam = searchParams.get('q');

    if (myParam) {

      getCompany(myParam);
      return;
    }

    const fetchData = async () => {
      try {
        const isCommonDeployment = await Company.IsCommonDeployment()
        const data = await Company.GetCompanyForLogin();
        // console.log(data);
        if (data && data.length > 0) {
          setCompanyList(data);
          setInputValues((prevValues) => ({
            ...prevValues,
            compCode: data[0]?.branchCode || "",
          }));
        } else if (isCommonDeployment) {
          navigate("/LoginCommonDeploymentPanel");
        }
        else {
          navigate("/LoginMaster")
        }
      } catch (error) {
        // localStorage.removeItem("loginAccountUserData")
        // localStorage.removeItem("loginUser")
      }
    };

    const IsClientregistered = async () => {
      var data = await Company.IsClientregistered(myParam);
      if (data === true) {
        fetchData();
        setClientregistered(true)
      } else {
        navigate("/LoginMaster")
      }
    }
    IsClientregistered()
  }, []);

  useEffect(() => {
    try {
      const checkSessionStorage = localStorage.getItem("loginUser");
      //console.log(data)
      if (checkSessionStorage !== "" && checkSessionStorage !== null) {
        const data = JSON.parse(checkSessionStorage)
        //alert(data.loginType);
        if (data.loginType == 562) {
          navigate("/Delivery");
        }
        else if (data.loginType == 563) {
          navigate("/OrderTakerView");
        } else if (data.loginType == 565) {
          navigate("/OrderList");
        } else if (data.loginType == 560 || data.loginType == 561 || data.loginType == 567 || data.loginType == 568) {
          navigate("/Welcome");
        } else if (data.loginType == 566) {
          navigate("/ChefList");
        } else if (data.loginType == 564) {
          navigate("/OrderTakerView");
          // localStorage.setItem("IsFloorManager", data.loginType);
        }
      }
      // const checkSessionStorage = JSON.parse(checkSessionStorage);
      // const data = JSON.parse(checkSessionStorage)

      // if (data && data.loginType) {
      //   // switch (data.loginType) {
      //   //   case 562:
      //   //     navigate("/Delivery");
      //   //     break;
      //   //   case 563:
      //   //     navigate("/OrderTakerView");
      //   //     break;
      //   //   case 565:
      //   //     navigate("/OrderList");
      //   //     break;
      //   //   case 560:
      //   //     navigate("/Welcome");
      //   //     break;
      //   //   case 566:
      //   //     navigate("/ChefList");
      //   //     break;
      //   //   case 564:
      //   //     navigate("/OrderTakerView");
      //   //     localStorage.setItem("IsFloorManager", data.loginType);
      //   //     break;
      //   //   default:
      //   //     console.warn("Unrecognized loginType:", data.loginType);
      //   //     break;
      //   if (data.loginType == 562) {
      //     navigate("/Delivery");
      //   }
      //   else if (data.loginType == 563) {
      //     navigate("/OrderTakerView");
      //   } else if (data.loginType == 565) {
      //     navigate("/OrderList");
      //   } else if (data.loginType == 560) {
      //     navigate("/Welcome");
      //   } else if (data.loginType == 566) {
      //     navigate("/ChefList");
      //   } else if (data.loginType == 564) {
      //     navigate("/OrderTakerView");
      //     // localStorage.setItem("IsFloorManager", data.loginType);
      //   }
      // }
    } catch (error) {
      // localStorage.removeItem("loginAccountUserData")
      // localStorage.removeItem("loginUser")
    }

    if (Clientregistered) {
      const userDetails = async () => {
        const checkSessionStorage = localStorage.getItem("loginUser");
        const data = JSON.parse(checkSessionStorage)
        if (data) {
          var Userdata = await UserCredential.getUserCredentialById(data.userId)
          if (Userdata) {
            setUserDetails(Userdata)
          }
        }
      }
      userDetails()
    }
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await Company.getCompanyMasterSettings();
  //       // console.log(data);
  //       setSettingMaster(data)
  //       setCompanyCode(data.loginThroughBranchCode)
  //     } catch (error) {
  //     }
  //   };

  //   fetchData();
  // }, [companyList]);

  const navigate = useNavigate();

  const login = async () => {
    //const name = "toshika wange "
    const updatedSentence = inputValues.userName.trim();
    const UniqueClientId = searchParams.get('q');
    const updatedValues = {
      ...inputValues,
      userName: updatedSentence,
      comClientRefId: UniqueClientId,
    };
    if (UniqueClientId != null && UniqueClientId != undefined && UniqueClientId != "") {
      localStorage.setItem("CommonDeploymentUniqueId", UniqueClientId);
    }
    try {
      setLoading(true);
      const response = await LoginApi.loginUser(updatedValues);
      if (response) {
        console.log(response);
        var Userdata = await UserCredential.getUserCredentialById(
          response.userId
        );
        if (Userdata) {
          setUserDetails(Userdata)
        }
        const arrayToStore = response;
        const arrayString = JSON.stringify(arrayToStore);
        localStorage.setItem('loginAccountUserData', arrayString);
        // alert(response.loginType)
        setLoading(false)
        // OpenAlertBoxEdit();
        setLoginUser(true)
        await logindone();
        fetchCompanyData();
        setIsAuthenticated(true);
        if (response.loginType === 562) {
          // OpenAlertBox();
          navigate('/Delivery');
        }
        else if (response.loginType === 563) {
          navigate('/OrderTakerView');
        }
        else if (response.loginType === 565) {
          // OpenAlertBox();
          navigate('/OrderList');
        }
        else if (response.loginType === 560 || response.loginType == 561 || response.loginType == 567 || response.loginType == 568) {
          // OpenAlertBox();
          const filterData = [{
            fieldName: "orderConfirmedBy",
            operatorName: "Equal",
            compareValue: response.userId
          }]
          const data = await Order.getRawOrderReport(filterData, 1);
          if (data?.orderList?.length > 0) {
            navigate('/Welcome');
          }
          else {
            const companyData = await Company.GetCompanyById(response.compId);
            if (companyData.businessTypes == 845) {
              navigate('/POSInvoice');
            }
            else {
              navigate('/Welcome');
            }
          }
        }
        else if (response.loginType === 566) {
          // OpenAlertBox();
          navigate('/ChefList');
        }
        else if (response.loginType == 564) {
          // OpenAlertBox();
          navigate('/OrderTakerView');
          localStorage.setItem("IsFloorManager", 564);
        }
        else {
          // alert("Incorrect UserName or Password");
          setIsShowAlertBox(true);
          setIsSingleButton(true);
          setHeadingTop("");
          setMessage(`Incorrect UserName or Password`);
          return false;
        }
      }
      else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      //alert("Wrong ID and Password")
      console.error("Error during login:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const [errorMessages, setErrorMessages] = useState({
    compCode: '',
    userName: '',
    password: '',
  });

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    handleInputChange(e);
  };

  const [CompanyCode, setCompanyCode] = useState(false);
  const showCheck = () => {
    setCompanyCode(!CompanyCode);
  };

  function stringToBoolean(str) {
    return str ? str.trim().toLowerCase() === "true" : "";
  }

  const GetLoginThroughBranchCodeSetting = async () => {
    const UniqueClientId = searchParams.get('q');
    var data = await Company.GetLoginThroughBranchCodeSetting(UniqueClientId);
    if (data?.isSuccess == true) {
      setCompanyCode(stringToBoolean(data.result));
    }
  };

  useEffect(() => {
    GetLoginThroughBranchCodeSetting();
  }, []);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(true);
  };
  const handleTogglePasswordhide = () => {
    setShowPassword(false);
  };

  const [ResetPassword, setResetPassword] = useState(false);
  const showReset = () => {
    setResetPassword(true);
  };
  const hideReset = () => {
    setResetPassword(false);
  };

  // ************************{ENter Button}*****************************
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInputRef.current.focus();
    }

  };
  const handleKeyPress1 = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      login();
    }
  };
  const handleSubmitClick = () => {
    login();
  };
  useEffect(() => {
    try {
      input1Ref.current.focus();
    } catch (error) {
      console.log(error);
    }
  }, []);


  const [SettingMaster, setSettingMaster] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Company.LoginThroughBranchCode();
        // console.log(data);
        setSettingMaster(data)
        setCompanyCode(data)
      } catch (error) {
        // localStorage.removeItem("loginAccountUserData")
        // localStorage.removeItem("loginUser")
      }
    };

    fetchData();
  }, []);

  function sendMessageToRN() {
    const message = 'buttonPressed';
    // Send message to React Native
    window.ReactNativeWebView.postMessage(message);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === 'c') {
        toggleSoftwareConfigOpen();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function toggleSoftwareConfigOpen() {

    setIsSoftwareConfigOpen(!isSoftwareConfigOpen);
  }

  return (
    <div style={{ width: "100%", height: "100vh" }}>

      <ModalBoxMaster
        isSoftwareConfigOpen={isSoftwareConfigOpen}
        toggleSoftwareConfigOpen={toggleSoftwareConfigOpen}
      />

      <div className="Formheader">
        {!ResetPassword && (
          <div>
            <div className="form-container">
              {/* <input type="checkbox" onClick={showCheck} /> */}
              <div className="form-sidebar-yesbm-logo ">
                <img src={logo} alt="" />
              </div>
              <p className="FormTitle">Log In!</p>
              <div className="formMainDiv">
                {!CompanyCode && (
                  <div className="InputTextD">
                    <select className="FormInputBox " name="compCode" ref={input1Ref} value={inputValues.compCode}
                      onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required onChange={handleInputChange}>
                      {/* <option className="s-1 form-control" value="">
                      -Select-
                    </option>
                    <option className="s-1 form-control" value="1">
                    My Optical
                    </option>
                    <option className="s-1 form-control" value="2">
                      My Inventory
                    </option>
                    <option className="s-1 form-control" value="3">
                      Restaurant
                    </option> */}
                      <option className="s-1 form-control" value="" >
                        -Select-
                      </option>
                      {/* {companyList.map((company, index) => (
                        <option className="s-1 form-control" value={company.branchCode} key={index}>{company.name}</option>
                      ))} */}
                      {(() => {
                        if (
                          companyList != null &&
                          companyList != undefined &&
                          companyList.length > 0
                        ) {
                          return companyList.map((company, index) => (
                            <option
                              className="s-1 form-control"
                              value={company.branchCode}
                              key={index}
                            >
                              {company.name}
                            </option>
                          ));
                        }
                      })()}
                    </select>

                    <label className="formTextLabelUser ">Select Company</label>
                  </div>
                )}
                {CompanyCode && (
                  <div className="InputTextD">
                    <input
                      type="text"
                      className="FormInputBox "
                      placeholder=""
                      ref={input1Ref}
                      onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                      onChange={handleInputChange}
                      name="compCode"
                      required
                      value={inputValues.compCode}
                    />
                    <label className="formTextLabelUser ">Company Code </label>
                  </div>
                )}

                <div className="InputTextD">
                  <input
                    type="text"
                    className="FormInputBox "
                    placeholder=""
                    ref={input2Ref}
                    onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                    required
                    onChange={handleInputChange}
                    name="userName"
                    value={inputValues.userName}
                  />
                  <label className="formTextLabelUser ">User Name
                    <span className="StarClr">*
                      {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                    </span></label>
                </div>
                <div className="InputTextD">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    name="password"
                    onChange={handlePasswordChange}
                    className="FormInputBox "
                    ref={input3Ref}
                    onKeyPress={handleKeyPress1}
                    placeholder=""
                    required
                  />
                  <label className="formTextLabelUser ">Password</label>
                  {!showPassword && (
                    <i
                      onClick={handleTogglePassword}
                      className="fa-regular ShowHideIcon fa-eye"
                    ></i>
                  )}
                  {showPassword && (
                    <i
                      onClick={handleTogglePasswordhide}
                      className="fa-regular ShowHideIcon fa-eye-slash"
                    ></i>
                  )}
                </div>
                <button onClick={login}
                  ref={input4Ref}
                  onKeyPress={handleKeyPress1} className="form-btn">
                  Log In
                </button>
                {/* <button onClick={sendMessageToRN} className="form-btn">
                  Testing
                </button> */}
              </div>
              <p onClick={showReset} className="sign-up-label">
                <span className="sign-up-link">Reset Password</span>
              </p>
              <Link to="/LoginMaster" style={{ textDecoration: "none" }}>
                <p className="sign-up-label">
                  <span className="sign-up-link">Sign Up</span>
                </p>
              </Link>
              <div className="buttons-container"></div>
            </div>
          </div>
        )}
        {ResetPassword && (
          <div>
            <div className="form-container">
              <input type="checkbox" onClick={showCheck} />
              <div className="form-sidebar-yesbm-logo ">
                <img src={logo} alt="" />
              </div>
              <p className="FormTitle">Reset Password !</p>
              <div className="formMainDiv">
                <div className="InputTextD">
                  <input
                    type="text"
                    className="FormInputBox "
                    placeholder=""
                    required
                  />
                  <label className="formTextLabelUser ">Enter Email</label>
                </div>

                {/* <Link style={{ width: "100%" }} to="/ModifyCompany1" > */}
                <button className="form-btn">Submit</button>
                {/* </Link> */}
              </div>

              <p onClick={hideReset} className="sign-up-label">
                <span className="sign-up-link">Log In</span>
              </p>
              <div className="buttons-container"></div>
            </div>
          </div>
        )}
        {alertBoxEdit && (
          <AlertBox
            HeadingTop="Login Successfull"
            onYesClick={OpenAlertBoxEdit}
            singleButton={true}
            OpenAlertBox={OpenAlertBoxEdit}
          />
        )}
        {isShowAlertBox && (
          <CommonAlertBox
            isSingleButton={isSingleButton}
            headingTop={headingTop}
            message={message}
            closeBtn={closeBtn}
            closeBtnName={closeBtnName}
            okayBtn=''
            okayBtnName="Yes" />
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default LogInPanel;
