// import { useState } from "react";
import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import TableButton from "./../../Props/TableButton";
import MiscellaneousMasterApi from "../../../API/APIServices/MiscellaneousMaster";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "../../Master/TableMaster/DataTable";
import MiscellaneousMaster from "../../Master/AccountMaster/MiscellaneousMaster";
import Transaction from "../../../API/APIServices/Transaction";
import BatchWiseModalBox from "../../Props/BatchWiseModalBox";
import BarcodeGenerator from "../../Master/MasterSetting/BarcodeGenrator";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import TemplateSetting from "../../../API/APIServices/TemplateSetting";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import ReportDesign from "../../../Restaurant/Orders/ReportDesign";
import BarCodeDesign from "./BarCodePrint";
import WorkshopModalLens from "../../WorkshopOrder/WorkshopModalLens";
import ReportDesignForA4 from "../../../Restaurant/Orders/ReportDesignForA4";
import Company from "../../../API/APIServices/Company";
import ConvertInvoiceAndSynchDataController from "../../../API/APIServices/ConvertInvoiceAndSynchDataController";

function Salelist() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    companyData,
    // input5,
  } = useContext(MyContext);
  const { id } = useParams();
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [mainData, setMainData] = useState([]);
  const [transActionList, setTransActionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TransType, setTransType] = useState(id || 12);
  const [WorkshopModal, setWorkshopModal] = useState(false);
  const [TransId, setTransId] = useState(0);

  const openWorkshopModal = (Id) => {
    setWorkshopModal(true);
    setTransId(Id);
  };

  const closeWorkshopModal = () => {
    setWorkshopModal(false);
  };

  const [LabelField] = useState([]);

  const WithFrameChange = () => {
    const val = document.getElementById("withFrame").value;
    console.log(val);
    if (val === "Y" || val === "y") {
      document.getElementById("FrameName").style.display = "block";
    } else {
      document.getElementById("FrameName").style.display = "none";
    }
  };

  const changetablevalue = (e) => {
    const value = e.target.value;
    if (parseInt(value) == 53) {
      navigate(`/masterlistreport?${value}`);
      return;
    }
    navigate(`/salelist/${value}`);
  };

  useEffect(() => {
    setTransType(id || 12);
  }, [TransType, id]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTransId, setCurrentTransId] = useState(null);
  var currencyDecimalPlaces = companyData?.currencyDecimalPlaces || 2;

  const openModal = (TransId) => {
    setCurrentTransId(TransId);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    // setCurrentTransId(null);
  };
  const fetchData = async (TransType) => {
    // setLoading(true);
    try {
      const val = await Transaction.GetTransactionList(TransType);
      // console.log(val);
      const newData = val.map((item, i) => {
        // const newData = val.item1.map((item, i) => {
        return {
          input: (
            <input
              type="checkbox"
              onClick={() => openWorkshopModal(item.TransId)}
            />
          ),
          VouchNo: item.VouchNo,
          PartyName: item.PartyName,
          Mobile: item.Mobile,
          vouchDate: item.vouchDate,
          VouchSeries: item.VouchSeries,
          BillAmtPaid: Number(item.BillAmtPaid).toFixed(currencyDecimalPlaces),
          DueAmount: Number(item.DueAmount).toFixed(currencyDecimalPlaces),
          BillAmt: item.BillAmt === null ? "-----" : Number(item.BillAmt).toFixed(currencyDecimalPlaces),
          adjAmt: Number(item.adjAmt).toFixed(currencyDecimalPlaces),
          billStatus: item.billStatus,
          isEcomInvoice: item.isEcomInvoice,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.TransId, TransType)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.TransId)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i
                className="fa-solid fa-print TablePrint"
                onClick={() => openModal(item.TransId)}
              ></i>
              {item.billStatus != "Done" && (
                <>
                  {Number(TransType) === 71 && (
                    <i
                      className="fa-solid fa-arrows-turn-right TableConvertIcon"
                      onClick={() =>
                        navigate(`/saleinvoice/${12}`, {
                          state: {
                            ConversionTransId: item.TransId,
                            IsConversion: true,
                            transTypeT1: TransType,
                            ConvertType: 1,
                          },
                        })
                      }
                    ></i>
                  )}
                </>
              )}
              {Number(TransType) === 55 && (
                <i
                  className="fa-solid fa-warehouse TableStockIcon"
                  onClick={() => {
                    fetchCompanyData(item.TransId);
                    toggleModal();
                  }}
                ></i>
              )}
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
    var transActionListData = await Transaction.GetTransactionTypes();
    if (transActionListData) {
      setTransActionList(transActionListData);
    }
  };

  const [companies, setCompanies] = useState([]);
  const [isStockModalOpen, setIsStockModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedTransId, setSelectedTransId] = useState("");
  const [date, setDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const toggleModal = () => setIsStockModalOpen(!isStockModalOpen);
  const fetchCompanyData = async (TransId) => {
    debugger;
    setSelectedTransId(TransId);
    const data = await Company.GetCompany();
    if (data && companyData) {
      const filterComp = data.filter((x) => x.id !== companyData.id);
      console.log("data", filterComp);
      setCompanies(filterComp);
    }
  };

  useEffect(() => {
    if (companyData) {
      fetchCompanyData();
    }
  }, [companyData]);

  const handleStockOutSubmit = async (e) => {
    e.preventDefault();
    try {
      const res =
        await ConvertInvoiceAndSynchDataController.SetTransConvertInfoData(
          4,
          companyData.id,
          selectedCompany,
          selectedTransId, //from trans id
          0 //to trans id
        );
      console.log("res", res);
      if (res.result.length === 2 && res.result.every((value) => value === 1)) {
        alert("Request Sent Successfully");
      }
    } catch (error) {
      console.error(error);
    }

    toggleModal();
  };

  useEffect(() => {
    fetchData(TransType);
  }, [id, TransType]);

  const Edit = async (TransId, TransTypevalue) => {
    const data = await Transaction.GetTransactionById(TransId, TransTypevalue);
    // debugger;
    console.log(data);
    setEditData(data);
    setRefID(data);
    // Navigate to the new page with state (passing refID)
    navigate(`/saleinvoice/${TransTypevalue}`, {
      state: { refID: data, isEdit: true, transId: TransId },
    });
  };

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await Transaction.DeleteTransactionData(getdeleteId);
      debugger;
      if (Boolean(val) === true) {
        fetchData(TransType);
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData(TransType);
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const [editData, setEditData] = useState("");
  const [RefID, setRefID] = useState("");

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "Vouch No", accessor: "VouchNo" },
    { Header: "Party Name", accessor: "PartyName" },
    { Header: "Mobile", accessor: "Mobile" },
    { Header: "Vouch Date", accessor: "vouchDate" },
    { Header: "Vouch Series", accessor: "VouchSeries" },
    { Header: "Bill Amount", accessor: "BillAmt" },
    { Header: "Bill Amt Paid", accessor: "BillAmtPaid" },
    { Header: "Due Amount", accessor: "DueAmount" },
    { Header: "Bill Ajd Amt", accessor: "adjAmt" },
    { Header: "Bill Status", accessor: "billStatus" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    // { Header: "", accessor: "" },
    {
      Header: (
        <select onChange={(e) => changetablevalue(e)} value={TransType}>
          {transActionList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.MasterName}
            </option>
          ))}
        </select>
      ),
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Party Name" ref={input1} />,
      accessor: "",
    },
    {
      Header: <input type="text" placeholder="Mobile" ref={input2} />,
      accessor: "printName",
    },
    {
      Header: <input type="text" placeholder="Vouch Date" ref={input2} />,
      accessor: "vouchDate",
    },
    {
      Header: <input type="text" placeholder="Vouch Series" ref={input3} />,
      accessor: "availableFrom",
    },
    {
      Header: <input type="text" placeholder="Bill Amount" ref={input4} />,
      accessor: "availableTo",
    },
    {
      Header: <input type="text" placeholder="Bill Amt Paid" />,
      accessor: "BillAmtPaid",
    },
    {
      Header: <input type="text" placeholder="Due Amount" />,
      accessor: "DueAmount",
    },
    {
      Header: <input type="text" placeholder="Bill Adj Amt" />,
      accessor: "adjAmt",
    },
    {
      Header: <input type="text" placeholder="Bill Status" />,
      accessor: "billStatus",
    },
    
  ];
  const [showBillPrint, setShowBillPrint] = useState(false);

  const OpenBillPrint = () => {
    setShowBillPrint(true);
  };

  const closeBillPrint = () => {
    setShowBillPrint(false);
  };

  const [showBillPrintBarCode, setShowBillPrintBarCode] = useState(false);

  const OpenBillPrintBarCode = () => {
    setShowBillPrintBarCode(true);
  };

  const closeBillPrintBarCode = () => {
    setShowBillPrintBarCode(false);
  };
  return (
    <div className="">
      <PageTabs locationTab={`/saleinvoice/${TransType}`} modelOpen={true} />
      <div className="nav-1">
        <div className="panel-heading">
          {/* <h2 className="panel-title">Sale List</h2> */}
          <h2 className="panel-title">
            {transActionList?.filter((x) => x.id === TransType).length > 0
              ? transActionList.filter((x) => x.id === TransType)[0]
                  ?.MasterName + " List"
              : "Sale List"}
          </h2>
          <TableButton
            PageCreate={`/saleinvoice/${TransType}`}
            filterData={filterData}
            columns={columns}
            data={mainData}
            PageName="Masters"
          />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />

        {alertBoxDelete && (
          <AlertBox
            HeadingTop="DELETE"
            Heading="Are You sure you want to delete this"
            OpenAlertBox={OpenAlertBoxDelete}
            onYesClick={DeleteAccountConfirm}
          />
        )}
        {loading && <Loader />}
      </div>

      {isModalOpen && (
        <div className="salelist-modal-overlay">
          <div className="salelist-modal-content">
            <h2>Confirm</h2>
            <p>Do you want to print for TransId: {currentTransId}?</p>
            <div className="salelist-modal-buttons">
              <button
                onClick={() => {
                  console.log("Voucher Printed for TransId:", currentTransId);
                  closeModal();
                  OpenBillPrintBarCode();
                }}
              >
                Print Barcode
              </button>
              <button
                onClick={() => {
                  console.log("Voucher Printed for TransId:", currentTransId);
                  closeModal();
                  OpenBillPrint();
                }}
              >
                Print Bill
              </button>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {showBillPrintBarCode && (
        <div style={{ display: "none" }}>
          {" "}
          {/**/}
          <div className="modal-back" onClick={closeBillPrintBarCode}>
            <div className="billPrintModal" style={{}}>
              <div style={{ padding: "5px", backgroundColor: "white" }}>
                <BarCodeDesign
                  templateName="Template2"
                  ord1Id={currentTransId}
                  setShowBillPrint={setShowBillPrintBarCode}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showBillPrint && (
        <div style={{ display: "none" }}>
          {" "}
          {/**/}
          <div className="modal-back" onClick={closeBillPrint}>
            <div className="billPrintModal" style={{}}>
              <div style={{ padding: "5px", backgroundColor: "white" }}>
                <ReportDesignForA4
                  templateName="FinalBillPrint"
                  ord1Id={currentTransId}
                  setShowBillPrint={setShowBillPrint}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {WorkshopModal && (
        <WorkshopModalLens
          closeWorkshopModal={closeWorkshopModal}
          WithFrameChange={WithFrameChange}
          TransId={TransId}
        />
      )}

      {/* Stock Modal */}
      {isStockModalOpen && (
        <div className="modal-overlay-stockout">
          <div className="modal-stockout">
            <h2 className="modal-title-stockout">Send Stock Request</h2>
            <form onSubmit={handleStockOutSubmit}>
              {/* Company Select */}
              <div className="form-group-stockout">
                <label htmlFor="company" className="form-label-stockout">
                  Select Company
                </label>
                <select
                  id="company"
                  className="form-select-stockout"
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Choose a company
                  </option>
                  {companies.length > 0 &&
                    companies.map((company, index) => (
                      <option key={index} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                </select>
              </div>

              {/* Date Field */}
              <div className="form-group-stockout">
                <label htmlFor="date" className="form-label-stockout">
                  Select Date
                </label>
                <input
                  type="date"
                  id="date"
                  className="form-input-stockout"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>

              {/* Submit Button */}
              <button type="submit" className="submit-btn-stockout">
                Send Request
              </button>
            </form>

            {/* Close Button */}
            <button onClick={toggleModal} className="close-btn-stockout">
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Salelist;
