import React, { useState, useRef, useEffect, useContext } from "react";
import MyContext from "../MyContext";
import { useNavigate } from "react-router-dom";
import ItemMaster from "../../API/APIServices/ItemMaster";

function ImageUploadWithPreview(props) {
  const { imgIndex, ItemImage, setItemImage, imageDelete } = props;
  const [selectedImage, setSelectedImage] = useState("");
  const [previousImage, setPreviousImage] = useState(null);
  const { imageBaseUrl } = useContext(MyContext);
  const navigate = useNavigate();
  const [ImageID, setImageID] = useState(0);
  const openFile = useRef(null);

    useEffect(() => {
        try {
            if (Array.isArray(props.imageIds) && props.imageIds.length > 0) {
                const firstId = props.imageIds[props.imgIndex]; // Adjust this logic based on your needs
                setImageID(firstId)
                if (firstId != undefined) {
                    setSelectedImage(`${imageBaseUrl}${firstId}`);
                }
                else {
                    setSelectedImage("");
                }
            } else {
                setSelectedImage("");
            }
        } catch (error) {
            console.log(error);
        }
        // Optionally, handle other unexpected cases if needed
    }, [props.imageIds]);

    // console.log(ItemImage);

    const handleImageChange = (event, id) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
        props.ItemImageUpload(file, id)
    };

    // const handlePreviewClick = () => {
    //     // setSelectedImage(previousImage);
    // };

    const handleDeleteImage = () => {
        // setShowDeleteButton(false);
        // // Clear the value of the file input to allow re-uploading the same file
        // if (openFile.current) {
        //     openFile.current.value = null;
        // }
    debugger;
    if (imageDelete == "RemoveImage") {
      setImageID(0);
      setSelectedImage("");
      return;
    }
        if (ImageID != 0) {
            var deleteImage = ItemMaster.DeleteImage(ImageID)
            if (deleteImage) {
                setImageID(0)
                setSelectedImage(null);
                alert("Image Deleted")
                if (imgIndex >= 0 && imgIndex < ItemImage.length) {
                    // Create a new array with the item removed
                    const newSetItemImage = ItemImage.filter((_, i) => i !== imgIndex);
                    // Update the state
                    setItemImage(newSetItemImage);
                  } else {
                    console.log("Invalid index");
                  }
            } else {
                alert("Error")
            }

        } else {
            setImageID(0)
            setSelectedImage(null);
            if (imgIndex >= 0 && imgIndex < ItemImage.length) {
                // Create a new array with the item removed
                const newSetItemImage = ItemImage.filter((_, i) => i !== imgIndex);
                // Update the state
                setItemImage(newSetItemImage);
              } else {
                console.log("Invalid index");
              }
        }



        // i have to call the image delete api
    };

    const openFileBox = () => {
        if (selectedImage) {

        } else {
            openFile.current.click();
        }
    };

    return (
        <div>
            <div style={{ position: "relative" }}>
                {selectedImage && (
                    <div className="delete-button DeleteBtnImg" onClick={handleDeleteImage}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                )}
                <div style={{ textAlign: "center" }} onClick={openFileBox}>
                    <label className="picture" htmlFor="picture__input" tabIndex="0" style={{ zIndex: "", overflow: "visible" }}>
                        <span className="picture__image"></span>
                        {selectedImage ? (

                            <img style={{ width: "100%" }} src={selectedImage} alt="" />
                        ) : (
                            <div className="upload-button">
                                <i style={{ fontSize: "18px" }} className="fa-solid fa-plus"></i>
                            </div>
                        )}

                    </label>
                    <input type="file" name="picture__input" id={props.id} ref={openFile} className="picture__input" accept="image/*" onChange={(e) => { handleImageChange(e, props.id) }} />
                </div>
            </div>
            
        </div>
    );
}

export default ImageUploadWithPreview;
