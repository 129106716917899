import axios from "../axiosConfig";
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
const formatStartTime = (time) => {
  if (!time) return ""; // Return empty string if time is null or undefined
  const isProperFormat = /^[0-9]{2}:[0-9]{2}:[0-9]{2}$/.test(time);
  return isProperFormat ? time : `${time}:00`;
};

const CouponMasterApi = {
  insertCouponMaster: async (data) => {
    debugger;
    const apiData = {
      id: data.id != 0 ? data.id : 0,
      couponName: data.couponName,
      couponNameId: data.couponNameId,
      couponMethod: data.couponMethodName,
      couponMethodId: data.couponMethodId,
      couponTitleOrCode: data.couponTitleOrCode,
      discountType: data.discountType,
      discountValue: data.discountValue,
      discountApplyFor: data.discountApplyFor,
      discountCategoryList: data.discountCategoryList,
      applyDiscountOncePerOrder: data.applyDiscountOncePerOrder,
      allowPOSProLocation: data.allowPOSProLocation,
      minPurchaseReqType: data.minPurchaseReqType,
      minPurchaseReqValue: data.minPurchaseReqValue,
      customerEligibility: data.customerEligibility,
      customerEligibilityList: data.customerEligibilityList,
      maxDiscountUses: data.maxDiscountUses,
      maxDiscountUsesValue: data.maxDiscountUsesValue,
      couponCombination: data.couponCombination,
      startDate: data.startDate,
      startTime: data.startTime !== null ? formatStartTime(data.startTime) : "",
      setEndDate: data.setEndDate,
      endDate: data.endDate,
      endTime: data.endTime !== null ? formatStartTime(data.endTime) : "",
      isCustmrBuysMinQtyItm: data.isCustmrBuysMinQtyItm,
      custmrBuysMinQtyItm: data.custmrBuysMinQtyItm,
      isCustmrSpendsMinPurAmt: data.isCustmrSpendsMinPurAmt,
      custmrSpendsMinPurAmt: data.custmrSpendsMinPurAmt,
      custmrBuysOrSpendsFromSpecificCatgry:
        data.custmrBuysOrSpendsFromSpecificCatgry,
      custmrBuysOrSpendsCatgryList: data.custmrBuysOrSpendsCatgryList,
      custmrGetsQty: data.custmrGetsQty,
      custmrGetsFromSpecificCatgry: data.custmrGetsFromSpecificCatgry,
      custmrGetsSpecificCatgryList: data.custmrGetsSpecificCatgryList,
      custmrGetsDiscountedOn: data.custmrGetsDiscountedOn,
      custmrGetsDiscountedValue: data.custmrGetsDiscountedValue,
      setMaxNoOfUsesPerOrder: data.setMaxNoOfUsesPerOrder,
      allowFreeShipForAllCountries: data.allowFreeShipForAllCountries,
      freeShipCountryList: data.freeShipCountryList,
      shippingRates: data.shippingRates,
      isVisible: data.isVisible,
      useType : Number(data.useType),
    };
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster`;
      console.log(apiUrl);
      console.log(apiData);
      const response = await axios.post(apiUrl, apiData, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getCouponMaster: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster/GetCouponList`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );

      console.log("Response:", response);
      return response.data;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Get bill series Filtered ***************************
  getCouponMasterFiltered: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster/GetCouponMasters`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Delete ****************************
  deleteCouponMaster: async (CouponMasterId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster?CouponId=${CouponMasterId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Get By Id ****************************
  getCouponMasterId: async (id) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster?id=${id}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // *************************** Update ***************************

  updateCouponMaster: async (data) => {
    debugger;
    console.log(data.id);
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster?CouponMasterId=${data.id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get bill series by masterType ***************************
  getCouponMasterByMasterType: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster/GetCouponMastersByMasterType`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [
            {
              fieldName: "isDefault",
              operatorName: "Equal",
              compareValue: true,
            },
          ],
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          masterType: 29,
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data);
      //debugger;
      return response.data;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },


  // ************************* Get All ***************************
  getCouponMasterFilter: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/CouponMaster/GetCouponListFilter`;
      const response = await axios.post(
        apiUrl,
        {
          filter: {
            or: [
              {
                and: filteredData,
              }
            ]
          },
          order: [
            {
              propertyName: "string",
              ascending: false
            }
          ],
          pageNumber: 1,
          pageSize: 1
        },
        {
          withCredentials: true,
        }
      );

      console.log("Response:", response);
      return response.data;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },
};

export default CouponMasterApi;
