import React, { useEffect, useState } from "react";

const CommonAlertBox = (props) => {
    const [Button1] = useState(props.isSingleButton);

    const [timer, setTimer] = useState(3); // Countdown starts from 3

    useEffect(() => {
        let countdown;
        if (Button1) {
            countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        props.closeBtn(); // Close modal after countdown
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown); // Cleanup interval on unmount
    }, [Button1, props.closeBtn]);


    return (
        <div className="modal-back" >{/*onClick={props.OpenAlertBox}*/}
            <div className="variableItem-modal" style={{ backgroundColor: "white", maxWidth: "98%", minWidth: "55%", padding: "15px", top: "40%" }}>
                <div className="">
                    <div className="space-between">
                        <span className="">
                            <i className="fa-solid fa-circle-exclamation" style={{ marginBottom: "10px", marginLeft: "3px", fontSize: "14px", color: "#757575" }}></i>&nbsp;
                            <label style={{ color: "black", fontWeight: "600", }}>{props.headingTop ? (props.headingTop).toUpperCase() : "INFO"}</label> </span>
                        <button
                            className="crossBtn"
                            type="button"
                            onClick={props.closeBtn}
                        > X
                        </button>
                    </div>
                    <hr style={{ padding: "0px", margin: "0px" }} />
                    <div className="desc-error mt-3" style={{ fontSize: "16px", color: "black", textAlign: "center", fontWeight: "600", padding: "20px" }}>{props.message}</div>

                    {Button1 ? (
                        <>
                            {/* <hr /> */}
                            <div style={{ textAlign: "end" }} className="action mt-3">
                                <div onClick={props.closeBtn} className="download-error" style={{ backgroundColor: "rgb(26 72 181 / 76%)", maxWidth: "90%" }} >
                                    {props.closeBtnName ? `${(props.closeBtnName).toUpperCase()} (${timer}S)` : `CLOSE (${timer}S)`}
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="actions mt-2">
                            <div>
                                <div onClick={props.closeBtn} className="notnow">
                                    {props.closeBtnName ? props.closeBtnName : 'Close'}
                                </div>
                            </div>
                            <div>
                                <div onClick={props.okayBtn} className="download-error">
                                    {props.okayBtnName ? props.okayBtnName : 'OK'}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CommonAlertBox;
