import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from 'react-router-dom';
import MyContext from "../Inventory/MyContext";
import User from "../API/APIServices/User";
import CommonAlertBox from "../Inventory/Props/CommonAlertBox";

function AppHeader({ setLoading, fetchData, sidebar, title, link, bellIcon, handleBellIcon, counting }) {

    useState(() => {
        document.title = title;
    }, []);

    const navigate = useNavigate();

    const { appToWebLink, webToAppLink } = useContext(MyContext);

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        // fetchData(751);
        setLoading(false)
    };

    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");

    const [isShowLogoutPopup, setIsShowLogOutPopup] = useState(false);

    const handleLogOutClick = async () => {
        setIsShowLogOutPopup(true);
        setMessage(`Are you sure, You want to LogOut ??`);
        setIsSingleButton(false);
        setHeadingTop("Info");
    }
    const handleOkBtnClick = async () => {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        await User.Logout();
        localStorage.clear();
        // navigate("/loginpanel");
        setIsShowLogOutPopup(false);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                if (loginUser.loginType == 563)
                    navigate(link)
                else if (loginUser.loginType == 565)
                    navigate(link)
                else if (loginUser.loginType == 566)
                    navigate(link)
                else if (loginUser.loginType == 564)
                    navigate(link)
                else {
                    // navigate("/LoginPanel")
                    // window.location.reload();
                    await User.Logout();
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    const refreshBtnClick = async () => {
        setLoading(true)
        await fetchData(751);
        // handleTouchEnd();
        setLoading(false);
    }
    const closeBtn = () => {
        setIsShowLogOutPopup(false);
    }

    return (
        <div>
            <div>
                <div className="heading"
                    style={{ display: "flex" }}>
                    <i style={{ fontSize: '20px' }} className="fa-solid fa-bars icon-white" onClick={sidebar}></i>
                    <div className=""
                        onTouchStart={e => { handleTouchStart(e) }}
                        onTouchMove={e => { handleTouchMove(e) }}
                        onTouchEnd={e => { handleTouchEnd(e) }}
                        style={{ alignItems: "baseline", height: "25px", width: "100%" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <label className="head-title-ot">{title}</label>
                            <div className="space-evenly">
                                <div class="notification-container">
                                    <i class="fa-solid fa-arrow-rotate-right head-title-ot" onClick={(e) => { e.stopPropagation(); refreshBtnClick(); }} style={{ marginRight: "18px" }}></i>
                                </div>
                                {bellIcon && bellIcon == true && (
                                    <div class="notification-container">
                                        <i class="fa-solid fa-bell head-title-ot" onClick={handleBellIcon} style={{ marginRight: "18px" }}></i>
                                        <div class="notification-badge" id="notification-badge">{counting}</div>
                                    </div>
                                )}
                                <i class="fa-solid fa-power-off head-title-ot" onClick={(e) => { e.stopPropagation(); handleLogOutClick(); }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowLogoutPopup && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn={handleOkBtnClick}
                    okayBtnName="Yes" />
            )}
        </div>
    );
}


export default AppHeader;
