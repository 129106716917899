import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Email = {

  SendEmail: async (AccountData, saveOrder) => {
    console.log(AccountData)
    console.log(saveOrder)
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Email/send-email`;
        const response = await axios.post(
          apiUrl,
          {
            //to: AccountData.emailId,
            to: "technical.techcherry@gmail.com",
            subject: "Hello Techcherry",
            body: "Hello Techcherry"
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        console.log("Response:", response.data.result);
        return true;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *********************** Generate OTP *************************
  SendOTP: async (email, otp) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Email/send-email`;
        const response = await axios.post(
          apiUrl,
          {
            to: email,
            subject: "OTP",
            body: `Your OTP is: ${otp}. Use this code to verify your identity. Do not share this code with anyone.`
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        console.log("Response:", response.data);
        return true;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

};

export default Email;