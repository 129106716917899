import DateTime from "../../Components/DateTime";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PageTabs from "../../Components/PageTabs";
import CouponMasterApi from "../../../API/APIServices/CouponMaster";
import LoyaltyMaster from "../../../API/APIServices/LoyaltyMaster";
const ModelAddCoupon = forwardRef((props, ref) => {
  /***********title***************/
  useState(() => {
    document.title = "Add Coupon";
  }, []);

  const [couponList, setCouponList] = useState([]);

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState([]);
  // const [selectedOptionsCoupon, setSelectedOptionsCoupon] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [cardId, setCardId] = useState(null);
  const [inputValues, setInputValues] = useState({
    cardName: "",
    description: "",
    duration: 0,
    noOfDays_Year: 0,
    autoApplyCouponsList: [],
    isActive: true,
    allowCashBack: "",
    discountValueType: "",
    discountValue: "",
  });

  const [transactionData, setTransactionData] = useState([]);

  // const transactionData = [
  //     { id: 1, cardName: "Anniversary SMS", description: "Special Offer", dayYear: "2023", applyCoupon: "Yes", status: "Active" },
  //     { id: 2, cardName: "Birthday SMS", description: "Monthly Deal", dayYear: "2024", applyCoupon: "No", status: "Inactive" }
  // ];

  /******************************************Console Logs********************************************** */
  useEffect(() => {
    console.log("inputValues", inputValues);
  }, [inputValues]);

  useEffect(() => {
    console.log("selectedCoupons", selectedCoupons);
  }, [selectedCoupons]);

  useEffect(() => {
    console.log("couponList", couponList);
  }, [couponList]);

  /******************************************Console Logs********************************************** */

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // const handleCheckboxChange = (checkboxValue) => {
  //     if (selectedOptionsCoupon.includes(checkboxValue)) {
  //         setSelectedOptionsCoupon(selectedOptionsCoupon.filter((item) => item !== checkboxValue));
  //     } else {
  //         setSelectedOptionsCoupon([...selectedOptionsCoupon, checkboxValue]);
  //     }
  // };
  // const handleSelectAllChange = () => {
  //     if (selectedOptionsCoupon.length === 0) {
  //         const allOptions = ['checkBox1', 'checkBox2', 'checkBox3', 'checkBox4'];
  //         setSelectedOptionsCoupon(allOptions);
  //     } else {
  //         setSelectedOptionsCoupon([]);
  //     }
  // };
  //******************************************Utility Function***************************************************//
  const getCouponNames = (ids) => {
    if (
      !Array.isArray(ids) ||
      !Array.isArray(couponList) ||
      !couponList.length > 0
    ) {
      return "";
    }

    const names = ids
      .map((id) => {
        const coupon = couponList.find((c) => c.id === id);
        return coupon ? coupon.couponName : null;
      })
      .filter((name) => name);

    return names.join(", ");
  };

  //******************************************Utility Function***************************************************//

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  /*********************************Onchange****************************** */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (id) => {
    setSelectedCoupons((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((couponId) => couponId !== id)
        : [...prevSelected, id]
    );
    setInputValues((prev) => ({
      ...prev,
      autoApplyCouponsList: prev.autoApplyCouponsList.includes(id)
        ? prev.autoApplyCouponsList.filter((couponId) => couponId !== id) // Remove if already selected
        : [...prev.autoApplyCouponsList, id], // Add if not selected
    }));
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedCoupons(couponList.map((coupon) => coupon.id));
    } else {
      setSelectedCoupons([]);
    }
    setInputValues((prev) => ({
      ...prev,
      autoApplyCouponsList: e.target.checked
        ? couponList.map((coupon) => coupon.id) // Select all
        : [], // Deselect all
    }));
  };
  useImperativeHandle(ref, () => ({
    save: CreateLoyaltyMemberShipAccountCardDetails,
    update: UpdateLoyaltyMemberShipAccountCardDetail,
  }));

  const handleDelete = async (id) => {
    DeleteLoyaltyMemberShipAccountCardDetail(id);
  };

  const handleEdit = async (id) => {
    setCardId(id);
    props.setIsEdit(true);
    GetLoyaltyMemberShipAccountCardDetail(id);
  };

  /*********************************Onchange End****************************** */

  /***********************************Api Calls Start************************************** */

  ////****************************Get Coupon List*******************************************////
  const GetCoupons = async () => {
    try {
      const CouponList = await CouponMasterApi.getCouponMaster();
      if (CouponList.length > 0) {
        setCouponList(CouponList);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetCoupons();
  }, []);
  ////****************************Get Coupon List*******************************************////

  ////*********************************Create Loyalty MemberShip Account Card Details***********************************************////
  const CreateLoyaltyMemberShipAccountCardDetails = async () => {
    debugger;
    if (props.onSave) {
      props.onSave();
    }
    const data = inputValues;
    try {
      const response =
        await LoyaltyMaster.CreateLoyaltyMemberShipAccountCardDetails(data);
      if (response.isSuccess && response.statusCode === 201) {
        console.log("response", response);
        alert("Card Created Successfully");
        setInputValues({
          cardName: "",
          description: "",
          duration: 0,
          noOfDays_Year: 0,
          autoApplyCouponsList: [],
          isActive: true,
        });
        setSelectedCoupons([]);
        GetAllLoyaltyMemberShipAccountCardDetails();
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  ////*********************************Create Loyalty MemberShip Account Card Details***********************************************////

  ////*********************************Get All Loyalty MemberShip Account Card Details***************************************************////
  const GetAllLoyaltyMemberShipAccountCardDetails = async () => {
    try {
      const response =
        await LoyaltyMaster.GetAllLoyaltyMemberShipAccountCardDetails();
      if (response.isSuccess && response.statusCode === 200) {
        console.log("response", response);
        setTransactionData(response.result);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  useEffect(() => {
    GetAllLoyaltyMemberShipAccountCardDetails();
  }, []);

  ////*********************************Get All Loyalty MemberShip Account Card Details***************************************************////

  ////*********************************Get Loyalty MemberShip Account Card Details By Id***************************************************////
  const GetLoyaltyMemberShipAccountCardDetail = async (id) => {
    try {
      const response =
        await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(id);
      if (response) {
        setInputValues(response);
        setSelectedCoupons(response.autoApplyCouponsList);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  ////*********************************Get Loyalty MemberShip Account Card Details By Id***************************************************////

  ////*****************************Update Loyalty MemberShip Account Card Detail*****************************************************************////
  const UpdateLoyaltyMemberShipAccountCardDetail = async () => {
    debugger;

    const data = inputValues;
    try {
      const response =
        await LoyaltyMaster.UpdateLoyaltyMemberShipAccountCardDetail(data);
      if (response.isSuccess && response.statusCode === 201) {
        console.log("response", response);
        alert("Card updated successfully");
        GetAllLoyaltyMemberShipAccountCardDetails();
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      setInputValues({
        cardName: "",
        description: "",
        duration: 0,
        noOfDays_Year: 0,
        autoApplyCouponsList: [],
        isActive: true,
      });
      setSelectedCoupons([]);
      props.setIsEdit(false);
    }
  };

  //* ***********************************Update Loyalty MemberShip Account Card Detail*****************************************************************
  ////*****************************Update Loyalty MemberShip Account Card Detail*****************************************************************////

  ////****************************************************Delete Loyalty MemberShip Account Card Detail******************************************************************////

  const DeleteLoyaltyMemberShipAccountCardDetail = async (id) => {
    try {
      const response =
        await LoyaltyMaster.DeleteLoyaltyMemberShipAccountCardDetail(id);
      if (response) {
        alert("Card deleted successfully");
        GetAllLoyaltyMemberShipAccountCardDetails();
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  ////****************************************************Delete Loyalty MemberShip Account Card Detail******************************************************************////

  /***********************************Api Calls End************************************** */

  return (
    <div>
      <PageTabs />
      <div>
        <DateTime />
      </div>
      <div>
        <div className="input-box-Im input-height2">
          <div className="input-box-m1">
            <input
              className="modify_im_input mode_limit mode_category"
              value={inputValues.cardName}
              type="text"
              name="cardName"
              onChange={handleInputChange}
              required
            />
            <label className="modify_lbl_text3">Card Name</label>
          </div>
          <div className="input-box-m1">
            <input
              className="modify_im_input mode_limit mode_category"
              value={inputValues.description}
              type="text"
              name="description"
              onChange={handleInputChange}
              required
            />
            <label className="modify_lbl_text4">Descriptions</label>
          </div>
        </div>

        <div className="input-box-Im input-height2">
          <div className="input-box-m1">
            <input
              className="modify_im_input mode_limit mode_category"
              value={inputValues.duration}
              type="number"
              name="duration"
              onChange={handleInputChange}
              required
            />
            <label className="modify_lbl_text3">Duration</label>
          </div>
          <div className="input-box-m1">
            <select
              className="modify_im_input mode_limit mode_category"
              value={inputValues.noOfDays_Year}
              type="number"
              name="noOfDays_Year"
              onChange={handleInputChange}
              required
            >
              <option value={1}>Days</option>
              <option value={2}>Yearly</option>
            </select>
            <label className="modify_lbl_text4">Select days/Yearly</label>
          </div>
        </div>
        <div className="input-box-Im input-height2">
          <div
            className="CompWidthMasterUser patient-widthcredit add-coupon-loyalty"
            ref={containerRef}
          >
            <div className="input-box-mok1" onClick={toggleDropdown}>
              <select
                className="modify_im_input mode_category1"
                type="checkbox"
                required
              >
                <option>Select options</option>
              </select>
              <label className="UseMasterLebalFix">
                Check to Select Auto Apply Coupons
              </label>
              <div className="overSelect"></div>
            </div>
            {/* {isOpen && (
                            <div id="checkBoxesCoupon" className="dropdown-content">
                                <label htmlFor="selectAll">
                                    <input className="user_checkbox" type="checkbox" id="selectAll"
                                        checked={selectedOptionsCoupon.length === 4}
                                        onChange={handleSelectAllChange} />
                                    Select All
                                </label>

                                <label htmlFor="first">
                                    <input className="user_checkbox" type="checkbox" id="first"
                                        checked={selectedOptionsCoupon.includes('checkBox1')}
                                        onChange={() => handleCheckboxChange('checkBox1')} />
                                    Discount
                                </label>

                                <label htmlFor="second">
                                    <input className="user_checkbox" type="checkbox" id="second"
                                        checked={selectedOptionsCoupon.includes('checkBox2')}
                                        onChange={() => handleCheckboxChange('checkBox2')} />
                                    Buy X Get Y Free
                                </label>
                            </div>
                        )} */}
            {isOpen && (
              <div id="checkBoxesCoupon" className="dropdown-content">
                <label htmlFor="selectAll">
                  <input
                    className="user_checkbox"
                    type="checkbox"
                    id="selectAll"
                    checked={selectedCoupons.length === couponList.length}
                    onChange={handleSelectAllChange}
                  />
                  Select All
                </label>

                {couponList.length > 0 &&
                  couponList.map((coupon) => (
                    <label key={coupon.id} htmlFor={`coupon-${coupon.id}`}>
                      <input
                        className="user_checkbox"
                        type="checkbox"
                        id={`coupon-${coupon.id}`}
                        checked={selectedCoupons.includes(coupon.id)}
                        onChange={() => handleCheckboxChange(coupon.id)}
                      />
                      {coupon.couponName}
                    </label>
                  ))}
              </div>
            )}
          </div>
          <div className="input-box-m1">
            <select
              className="modify_im_input mode_limit mode_category"
              value={inputValues.isActive}
              type="number"
              name="isActive"
              onChange={(e) => {
                handleInputChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value === "true",
                  },
                });
              }}
              required
            >
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
            <label className="modify_lbl_text4">Status</label>
          </div>
        </div>
        <div className="input-box-Im input-height2">
          <div className="input-box-m1">
            <select
              className="modify_im_input mode_limit mode_category"
              type="text"
              // onChange={handleAllowCashbackChange}
              // value={allowCashback}
              name="allowCashBack"
              onChange={handleInputChange}
              value={inputValues.allowCashBack}
              required
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
              <option value={2}>After First Purchase</option>
            </select>
            <label className="modify_lbl_text3">Allow Cashback</label>
          </div>
          {inputValues.allowCashBack == 1 && (
            <div className="input-box-Im input-height2">
              <div className="input-box-coupon">
                <select
                  className="modify_im_input mode_category"
                  type="text"
                  // onChange={handleModeChange}
                  // value={mode}
                  name="discountValueType"
                  value={inputValues.discountValueType}
                  onChange={handleInputChange}
                >
                  <option value={1}>Percentage</option>
                  <option value={2}>Fixed Amount</option>
                </select>
                <label class="modify_lbl_text3">Discount Value</label>
              </div>
              <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                <div className="symbol-container-cpn">
                  {inputValues.discountValueType === "Fixed Amount" && (
                    <span className="symbol-left-cpn">₹</span>
                  )}
                  <input
                    className="modify_im_input mode_category value-cpn-padding"
                    type="text"
                    placeholder={
                      inputValues.discountValueType === "Percentage"
                        ? ""
                        : "0.00"
                    }
                    // value={value}
                    // onChange={handleInputChangeMode}
                    name="discountValue"
                    onChange={handleInputChange}
                    value={inputValues.discountValue}
                  />
                  {inputValues.discountValueType === "Percentage" && (
                    <span className="symbol-right-cpn">%</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="col-sm-12">
          <div className="table-responsive scroll-coupon-loyalty">
            <table
              id="tblTransactionList"
              className="table_ord_mod table-bordered1"
              frame="box"
            >
              <thead className="thead_scroll_orderlist table-tbody">
                <tr>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      S.No.
                    </div>
                  </th>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      Card Name
                    </div>
                  </th>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      Description
                    </div>
                  </th>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      Day/Year
                    </div>
                  </th>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      Apply Coupon
                    </div>
                  </th>
                  <th className="td_col6">
                    <div
                      className="table_head_mmb1"
                      style={{ textAlign: "center" }}
                    >
                      Status
                    </div>
                  </th>
                  <th
                    className="table_edit table_head_mmb1"
                    style={{ textAlign: "center" }}
                  >
                    Edit
                  </th>
                  <th
                    className="table_delete table_head_mmb1"
                    style={{ textAlign: "center" }}
                  >
                    Delete
                  </th>
                </tr>
              </thead>

              <tbody className="tbody_body_m1">
                {transactionData.length > 0 &&
                  transactionData.map((transaction, index) => (
                    <tr key={transaction.id} className="table_tbody_Order_List">
                      <td className="td_col6">{index + 1}</td>
                      <td className="td_col6" style={{ textAlign: "start" }}>
                        {transaction.cardName}
                      </td>
                      <td className="td_col6">{transaction.description}</td>
                      <td className="" style={{ textAlign: "center" }}>
                        {transaction.noOfDays_Year}
                      </td>
                      <td className="td_col6" style={{ textAlign: "center" }}>
                        {getCouponNames(transaction.autoApplyCouponsList)}
                      </td>
                      <td className="td_col6" style={{ textAlign: "center" }}>
                        {transaction.isActive ? "Active" : "Inactive"}
                      </td>
                      <td
                        className="table_edit"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="edit_btn"
                          onClick={() => handleEdit(transaction.id)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                      </td>
                      <td
                        className="table_delete"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          className="btn-table1"
                          onClick={() => handleDelete(transaction.id)}
                        >
                          <i className="fa-solid fa-trash delete-btn"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModelAddCoupon;
