import React, { useContext, useEffect, useState } from "react";
import "./OrderTakerStylesheet.css";
import { Link } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../Inventory/Props/Loader";
import AppHeaderDetail from "../AppHeaderDetail";
import MyContext from "../../Inventory/MyContext";
import ReportDesign from "../Orders/ReportDesign";
import Order from "../../API/APIServices/Order";
import CommonAlertBox from "../../Inventory/Props/CommonAlertBox";

function OrderDetail() {

    const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const ord1Id = searchParams.get('ord1Id');
    // const d1Id = searchParams.get('d1Id');
    // const d2Id = searchParams.get('d2Id');
    // const orderNo = searchParams.get('orderNo');

    const navigate = useNavigate();
    const { ord1Id, d1Id, d2Id, orderNo } = location.state || {}

    const [ord2Id, setOrd2Id] = useState();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log(d1Id, + '--' + d2Id);
        localStorage.setItem('ord1Id', ord1Id);
    }, [])
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const ord1Id = searchParams.get('ord1Id');
    // const ord2Id = searchParams.get('ord2Id');
    // const d1Id = searchParams.get('d1Id');
    // const d2Id = searchParams.get('d2Id');
    //const ord2Id = searchParams.get('ord2Id');

    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");

    const closeBtn = () => {
        setIsShowAlertBox(false)
    }

    const [Modal, setModal] = useState(false);

    const openModal = async (id) => {
        await handleStatusClick(id)
        setModal(true);
    }

    const closeModal = () => {
        setModal(false);
    };
    const { appToWebLink, webToAppLink, convertDateTimeToDateAndTime } = useContext(MyContext);

    const handleWebsitClick = () => {
        setLoading(true)
        // window.open("http://localhost:3000/")
        // ${compCode}&${userName}&${password}&
        // const compCode = "C0001";
        // const userName = "admin";
        // const password = "admin";
        // const d1Id = d1Id;
        // const d2Id = d2Id;
        // const ord1Id = ord1Id;
        console.log(d1Id, + '--' + d2Id)
        //window.open(`http://localhost:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`);

        var width = window.screen.width;// Adjust width as needed
        var height = window.screen.height; // Adjust height as needed

        // Open the window with specified dimensions
        //window.open(`http://122.168.117.143:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`, '_blank', 'width=' + width + ', height=' + height);
        // window.open(`http://localhost:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`, '_blank', 'width=' + width + ', height=' + height);
        //window.open(`http://122.168.117.143:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`, '_blank', 'width=' + width + ', height=' + height);
        // window.open(`http://103.234.94.19:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`, '_blank', 'width=' + width + ', height=' + height);
        // window.location.href = `http://localhost:3000/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`;
        // window.location.href = `http://tc54.yes-bm.com/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`;
        window.location.href = `${appToWebLink}/OurMenu?qs=${d1Id}&${d2Id}&${ord1Id}`;
        // window.location.reload();
        setLoading(false)
    }

    const runningOrderClick = async () => {
        if (tableOrder && tableOrder.length > 0) {
            const tableId = tableOrder && tableOrder[0].tableId;
            const tableGroupId = tableOrder && tableOrder[0].tableGroupId;
            navigate("/POSInvoice", {
                state: {
                    "runningOrd1Id": ord1Id,
                    "d1Id": d1Id,
                    "d2Id": d2Id,
                    "tableGroupId": tableGroupId,
                    "tableId": tableId,
                }
            })
        } else {
            const data = await Order.getOrderListByOrd1Id(ord1Id);
            var orderData = data.item1;
            const tableId = orderData && orderData[0]?.tableId;
            const tableGroupId = orderData && orderData[0]?.tableGroupId;
            navigate("/POSInvoice", {
                state: {
                    "runningOrd1Id": ord1Id,
                    "d1Id": d1Id,
                    "d2Id": d2Id,
                    "tableGroupId": tableGroupId,
                    "tableId": tableId,
                }
            })
        }
    }

    const [tableOrder, setTableOrder] = useState([]);
    console.log(tableOrder)

    const fetchData = async () => {
        setLoading(true);
        try {
            const data = await OrderApi.getOrderItemWiseList(ord1Id);
            console.log(data);
            setTableOrder(data);
            calculation(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        console.log(ord1Id)
        fetchData();
    }, [])

    // ******************** status wise list ***********************
    const [statusList, setStatusList] = useState([]);

    const handleStatusClick = async (ord2Id) => {
        try {
            const data = await OrderApi.getItemWiseStatusList(ord1Id, ord2Id);
            console.log(data);
            setStatusList(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    // useEffect(() => {
    //     const fetchData = async () => {
    //     };
    //     fetchData();
    // }, [])


    //  ****************** Update Preference No *********************

    const handlePreferenceChange = (e, itemId, itemConfirmedByName) => {
        //alert(tableOrder[0].itemConfirmedByName);
        if (itemConfirmedByName == null) {
            const preferenceValue = e.target.value;

            const index = tableOrder.findIndex(item => item.ord2Id === itemId);

            if (index !== -1) {
                setTableOrder(prevTableOrder => {
                    const updatedTableOrder = [...prevTableOrder];
                    updatedTableOrder[index] = { ...updatedTableOrder[index], preferenceNo: preferenceValue };
                    return updatedTableOrder;
                });
            }
        }
        else {
            // alert("Preferences should not be change as Order is Confirmed");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Preferences should not be change as Order is Confirmed');
        }
    };
    // const location = useLocation();
    // const value = location.state && location.state.value;

    const handleIncrement = (id) => {
        const itemIndex = tableOrder.findIndex(item => item.ord2Id === id);
        if (itemIndex !== -1) {
            const updatedCartData = [...tableOrder];
            updatedCartData[itemIndex].qty += 1;
            setTableOrder(updatedCartData);
            calculation(updatedCartData);
        }
    };

    const handleDecrement = async (id) => {
        const itemIndex = tableOrder.findIndex(item => item.ord2Id === id);
        if (itemIndex !== -1) {
            const updatedCartData = [...tableOrder];
            updatedCartData[itemIndex].qty -= 1;

            if (updatedCartData[itemIndex].qty < 1) {
                const userConfirmed = window.confirm("Are you sure, you want to cancel this item?");

                if (userConfirmed) {
                    await OrderApi.deleteItemFromOrder(ord1Id, updatedCartData[itemIndex].ord2Id)
                    // alert("Item Cancellled")
                    setIsShowAlertBox(true);
                    setIsSingleButton(true);
                    setHeadingTop("");
                    setMessage('Item Cancelled');
                } else {
                    console.log("Not Cancelled");
                }
                fetchData();
                const totalAmount = calculation(updatedCartData);
                // const filteredData = [
                //     {
                //         operationType: 0,
                //         path: "TotalAmt",
                //         op: "Add",
                //         from: "string",
                //         value: totalAmount
                //     },
                //     {
                //         operationType: 0,
                //         path: "GrandTotalAmt",
                //         op: "Add",
                //         from: "string",
                //         value: totalAmount
                //     }
                // ]
                // await OrderApi.updateOrderPartially(filteredData, tableOrder[0].ord1Id);
            } else {
                setTableOrder(updatedCartData);
                calculation(updatedCartData);
            }
        }
    };

    // ****************** Price Calculate ******************

    const calculation = (updatedCartData) => {
        console.log(updatedCartData)
        if (updatedCartData && updatedCartData.length > 0) {
            let total = 0;
            for (let i = 0; i < updatedCartData.length; i++) {
                const item = updatedCartData[i];
                const qty = parseInt(item.qty);
                const rate = parseFloat(item.rate);
                if (!isNaN(qty) && !isNaN(rate)) {
                    total += qty * rate;
                }
            }
            setTotalAmount(total);
            console.log("total", total);
            return total;
        }
    }

    const closeBtnForSave = () => {
        navigate(`/OrderTakerView`, {
            state: { scrollOrd1Id: ord1Id }
        });
        setIsShowAlertBoxForSave(false);
    }

    const preferenceSaveBtn = async () => {
        setLoading(true)
        var success = false;
        for (var i = 0; i < tableOrder.length; i++) {
            const updatedValues = [
                {
                    operationType: 0,
                    path: "preferenceNo",
                    op: "Add",
                    from: "string",
                    value: Number(tableOrder[i].preferenceNo)
                },
                {
                    operationType: 0,
                    path: "itemSuggestion",
                    op: "Add",
                    from: "string",
                    value: tableOrder[i].itemSuggestion
                }
            ];
            console.log(updatedValues)
            success = await OrderApi.updateItemPartially(updatedValues, tableOrder[i].ord2Id);
        }
        // if (success === true) {
        //     alert("Set Preferences Successfully");
        // }
        await handleQuantityChange();
        // tableOrder.forEach(order => {
        //     updatedValues.push({
        //         operationType: 0,
        //         path: "preferenceNo",
        //         op: "Add",
        //         from: "string",
        //         value: Number(order.preferenceNo)
        //     });
        // });
        setLoading(false)
        // navigate(`/OrderTakerView`, {
        //     state: { scrollOrd1Id: ord1Id }
        // });

    }

    const [isShowAlertBoxForSave, setIsShowAlertBoxForSave] = useState(false);

    const handleQuantityChange = async () => {
        var success = false;
        let totalRunAmount = 0;
        for (var i = 0; i < tableOrder.length; i++) {
            if (tableOrder[i].itemRunningQty > 1) {
                const itemAmount = tableOrder[i].qty * tableOrder[i].rate;
                totalRunAmount += itemAmount;
            }
            const updatedValues = [
                {
                    operationType: 0,
                    path: "qty",
                    op: "Add",
                    from: "string",
                    value: Number(tableOrder[i].qty)
                },
                {
                    operationType: 0,
                    path: "ITotalAmount",
                    op: "Add",
                    from: "string",
                    value: tableOrder[i].qty * tableOrder[i].rate // totalAmount (qty * rate)
                }
            ];

            // Add an additional operation if qty is greater than 1
            if (tableOrder[i].itemRunningQty > 1) {
                updatedValues.push({
                    operationType: 0,
                    path: "itemRunningQty",
                    op: "Add",
                    from: "string",
                    value: Number(tableOrder[i].qty)
                });
            }
            success = await OrderApi.updateItemPartially(updatedValues, tableOrder[i].ord2Id);
        }

        const totalAmount = calculation(tableOrder);
        const filteredData = [
            {
                operationType: 0,
                path: "TotalAmt",
                op: "Add",
                from: "string",
                value: totalAmount
            },
            {
                operationType: 0,
                path: "GrandTotalAmt",
                op: "Add",
                from: "string",
                value: totalAmount
            },
            {
                operationType: 0,
                path: "RunningOrderTotalAmt",
                op: "Add",
                from: "string",
                value: totalRunAmount ? (totalRunAmount || 0) : (tableOrder[0].runningOrderTotalAmount || 0),
            }
        ]
        await OrderApi.updateOrderPartially(filteredData, tableOrder[0].ord1Id);
        if (success === true) {
            // alert("Updated");
            setIsShowAlertBoxForSave(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Updated');
        }
    }

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false)
        setLoading(false);
    }

    const [totalAmount, setTotalAmount] = useState(0);

    const handlePrint = (orderNo, itemName, preferenceNo, qty, sku, ord2Id) => {
        // console.log(orderNo)
        // console.log(itemName)
        // console.log(preferenceNo)
        // console.log(qty)
        // console.log(sku)
        setOrd2Id(ord2Id);
        setShowBillPrint(true);

        // navigate("/KotPrintPage", {
        //     state: {
        //         orderNo,
        //         itemName,
        //         preferenceNo,
        //         qty,
        //         sku,
        //         ord2Id
        //     }
        // });
    }
    const handleFocus = (event) => {
        event.target.select();
    };

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        fetchData();
        setLoading(false)
    };

    const handleItemSuggestionChange = (e, ord2Id) => {
        const newSuggestion = e.target.value;
        // Update the suggestion for the corresponding ord2Id
        const updatedTableOrder = tableOrder.map(item => {
            if (item.ord2Id === ord2Id) {
                return { ...item, itemSuggestion: newSuggestion };
            }
            return item;
        });
        // Update the state with the new table order
        setTableOrder(updatedTableOrder);
    };

    const nextBtnClick = (index) => {
        const inputElement = document.getElementById(`input-${index + 1}`);
        if (inputElement) {
            inputElement.select();
            inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).replace(',', '');
    }

    return (
        <div style={{ backgroundColor: "whitesmoke" }}>
            {/* <div className="heading"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}>
                <div className="head-display">
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title-ot">Detail of Order No : {orderNo}</h2>
                    </div>
                </div>
            </div> */}
            <AppHeaderDetail title={`Detail of Order No : ${orderNo}`} setLoading={setLoading} fetchData={fetchData} isShowTable={true} name={tableOrder && tableOrder.length > 0 ? tableOrder[0].tableName : ""} />
            <div style={{ paddingBottom: "120px", backgroundColor: "white" }}>
                <div style={{ marginTop: "48px", padding: "1px 0px", backgroundColor: "rgb(100, 49, 23)" }}>
                    <div style={{}}>
                        {(() => {
                            if (tableOrder && tableOrder.length > 0) {
                                return (
                                    tableOrder.map((data, index) => (
                                        <div className="order-panel">
                                            {data.cancelApprovalStatus == "Approve" && (
                                                <div className="flex-center" style={{ backgroundColor: "#8b000029", maxWidth: "fitContent", borderRadius: "5px", width: "30%" }}>
                                                    <label style={{ color: "darkred", fontSize: "14px", fontWeight: "600", display: "flex", textAlign: "center" }}>&nbsp;Cancelled&nbsp; -- {data.cancelApprovedQty}</label>
                                                </div>
                                            )}
                                            <div className="flex-start">
                                                <label className="ques">Delivery Preference/Note : &nbsp;</label>
                                                <input type="number" className="preferenceInput" id={`input-${index}`} onFocus={(e) => handleFocus(e)} value={data.preferenceNo}
                                                    // onChange={(e) => handlePreferenceChange(e, data.ord2Id, data.itemConfirmedByName)}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                                        handlePreferenceChange({ target: { name: "preferenceNo", value } }, data.ord2Id, data.itemConfirmedByName);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "." || e.key === "-" || e.key === "e") {
                                                            e.preventDefault(); // Prevent decimals, negatives, and scientific notation
                                                        }
                                                    }}
                                                />
                                                <i className="fa-solid fa-forward" onClick={() => nextBtnClick(index)} style={{ color: "black", fontSize: "18px", padding: "1px", margin: "3px 5px", backgroundColor: "white" }}></i>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques">KOT Status : &nbsp;</label>
                                                <label className="ans">
                                                    {/* {data.kotStatus === "Received" ? "Pending" : data.kotStatus} &nbsp; &nbsp; */}
                                                    {data.kotStatus} &nbsp; &nbsp;
                                                </label>
                                                <div className="/*smallCircle*/" onClick={() => openModal(data.ord2Id)} style={{ backgroundColor: "transparent", marginTop: "1px", width: "25px", height: "25px" }}>
                                                    <i className="fa-solid fa-pen-to-square leftSideIcon" style={{ fontSize: "14px", color: "black", marginLeft: "1px" }}></i>
                                                </div>
                                            </div>
                                            <label className="ques">{data.d2ItemName}</label><br />
                                            <label className="ques">SKU : {data.sku}</label><br />
                                            <label className="ques">Item Department : {data.itemDepartmentId}</label><br />
                                            <label className="ques">Preparation Time : {data.preparationTime ? `${data.preparationTime} min` : ""}</label><br />
                                            <div className="flex-start">
                                                <label className="ques" style={{ color: "darkgreen", }}>Item Suggestion : </label>
                                                <input type="text" value={data.itemSuggestion} disabled={data.itemConfirmedByName == null ? false : true} onChange={(e) => handleItemSuggestionChange(e, data.ord2Id)} />
                                            </div>

                                            <div className="flex-start mt-3">
                                                <label className="ques" >Confirm&nbsp;By&nbsp;:&nbsp;</label>
                                                {/* <div className="flex-start"> */}
                                                <label className="ans" style={{ color: "#bb1149", }}>
                                                    {data.itemConfirmedByName ? (`${data.itemConfirmedByName} on ${moment(data.itemConfirmedOn).format('DD-MM-YYYY hh:mm A')}`) : ""}
                                                    {/* {data.itemConfirmedByName ? (`${data.itemConfirmedByName} on ${convertDateTimeToDateAndTime(data.itemConfirmedOn1)}`) : ""} */}
                                                    {/* <label style={{ display: data.orderConfirmedByName == "" ? "block" : "none" }}>{moment(data.itemConfirmedOn1).format('DD-MM-YYYY HH:mm')}</label> */}
                                                    {/* <span style={{ display: !data.itemConfirmedByName ? "none" : "block" }}></span>  */}
                                                </label>
                                                {/* </div> */}
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques" style={{}}>Transferred&nbsp;By&nbsp;:&nbsp;</label>
                                                <label className="ans" style={{ color: "#bb1149", }}>
                                                    {/* {data.itemTransferredByName ? (`${data.itemTransferredByName} on ${moment.utc(data.itemTransferredOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}`) : ""} */}
                                                    {data.itemTransferredByName ? (`${data.itemTransferredByName} on ${formatDateTime(data.itemTransferredOn)}`) : ""}
                                                    {/* <label style={{ display: !data.itemTransferredByName ? "none" : "block" }}>on {moment.utc(data.itemTransferredOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} </label> */}
                                                    {/* <span style={{ display: !data.itemTransferredByName ? "none" : "block" }}>on {moment(data.itemTransferredOn).format('DD-MM-YYYY hh:mm A')}</span>  */}
                                                </label>
                                            </div>
                                            <div className="flex-start">
                                                <label className="ques" style={{}}>Delivered&nbsp;By&nbsp;:&nbsp;</label>
                                                <label className="ans" style={{ color: "#bb1149" }}>
                                                    {/* {data.itemDeliveredByName ? (`${data.itemDeliveredByName} on ${moment.utc(data.itemDeliveredOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}`) : ""} */}
                                                    {data.itemDeliveredByName ? (`${data.itemDeliveredByName} on ${formatDateTime(data.itemDeliveredOn)}`) : ""}
                                                    {/* <label style={{ display: !data.itemDeliveredByName ? "none" : "block" }}>on {moment.utc(data.itemDeliveredOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} </label> */}
                                                    {/* <span style={{ display: !data.itemDeliveredByName ? "none" : "block" }}>on {moment(data.itemDeliveredOn).format('DD-MM-YYYY hh:mm A')} </span> */}
                                                </label>
                                            </div>
                                            <hr />
                                            <div className="space-between">
                                                <div className="flex-start">
                                                    <div className="smallCircle" onClick={() => handlePrint(data.orderNo, data.d2ItemName, data.preferenceNo, data.qty, data.sku, data.ord2Id)} style={{ backgroundColor: "#6b65d3", marginTop: "-0px", width: "25px", height: "25px" }}>
                                                        <i className="fa-solid fa-print leftSideIcon"></i>
                                                    </div>&nbsp;
                                                    {/* <div style={{ display: data.orderConfirmedByName === "" ? "none" : "block" }}> */}
                                                    <div style={{ display: data.itemConfirmedByName != "" && data.itemConfirmedByName != null ? "none" : "block" }}>
                                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                            <div className="smallCircle" onClick={() => handleIncrement(data.ord2Id)} style={{ backgroundColor: "#fd5370", marginTop: "-0px", width: "25px", height: "25px" }}>
                                                                <i className="fa-solid fa-plus leftSideIcon" style={{ marginLeft: "5px" }}></i>
                                                            </div>&nbsp;
                                                            <div className="smallCircle" onClick={() => handleDecrement(data.ord2Id)} style={{ backgroundColor: "#cb78fe", marginTop: "-0px", width: "25px", height: "25px" }}>
                                                                <i className="fa-solid fa-minus leftSideIcon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label className="ques">{data.qty} x &#x20B9; {data.rate} = &#x20B9;{data.qty * data.rate}</label>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        })()}
                    </div>
                    {/**************************************************/}
                    <div className="bottomFixed_modal order-panel" style={{ display: "grid", margin: "0px", zIndex: "2", border: "2px solid #643117", borderBottom: "none" }}>
                        <div className="flex-end">
                            <label className="ques">Sub Total : &nbsp;</label>
                            {totalAmount === 0 ? (
                                <label className="ans">&#x20B9; {tableOrder && tableOrder.length > 0 ? tableOrder[0].itemTotalAmt : 'Loading...'}</label>
                            ) : (
                                <label className="ans" value={totalAmount}>&#x20B9; {totalAmount}</label>
                            )}

                        </div>
                        <div className="flex-end" style={{ display: "none" }}>
                            <label className="ques">Coupon Amount : &nbsp;</label>
                            <label className="ans">&#x20B9; 0</label>
                        </div>
                        {/* <div className="flex-end">
                        <label className="ques">Service Tax : &nbsp;</label>
                        <label className="ans">&#x20B9; 0</label>
                    </div> */}
                        <div className="flex-end" style={{ display: "none" }}>
                            <label className="ques">Grand Total : &nbsp;</label>
                            {/* <label className="ans">&#x20B9; {tableOrder.length > 0 ? tableOrder[0].grandTotal : 'Loading...'}</label> */}
                            <label className="ans" value={totalAmount}>&#x20B9; {totalAmount}</label>
                        </div>

                        <div style={{ display: "block" }}>
                            {tableOrder !== null && tableOrder.length > 0 &&
                                //const length = (tableOrder.length) -1,
                                //tableOrder[0].itemConfirmedByName !== null && tableOrder[0].itemConfirmedByName !== undefined && 
                                (
                                    <div className="space-evenly mt-2" id="preferenceSet">
                                        <button
                                            className="orderTakerSaveBtn"
                                            style={{
                                                display: tableOrder[(tableOrder.length) - 1].itemConfirmedByName ? "none" : "block",
                                                backgroundColor: "#2a9f79",
                                                color: "white",
                                                width: "50%",
                                                border: "2px solid white"
                                            }}
                                            onClick={preferenceSaveBtn}
                                        >
                                            Save
                                        </button>
                                        <Link to={{ pathname: '/OrderTakerView', state: { scrollOrd1Id: ord1Id } }} style={{ width: "60%" }}>
                                            <button className="orderTakerSaveBtn" style={{ backgroundColor: "#ff4163", color: "white", border: "2px solid white" }}>
                                                Back
                                            </button>
                                        </Link>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="floating-button-container">
                <div className="circle mt-1" onClick={handleWebsitClick}>
                    <i className="fa-solid fa-plus icon-white" style={{ marginLeft: "2px" }}></i>
                </div>
            </div> */}
            <div className="floating-button-container" style={{ bottom: "150px" }}>
                <div className="circle mt-1" onClick={runningOrderClick}>
                    <i className="fa-solid fa-plus icon-white" style={{ marginLeft: "2px" }}></i>
                    {/* <i className="fa-solid fa-plus icon-white" style={{ marginLeft: "2px" }}></i> */}
                </div>
            </div>
            {/************************** KOT Status Modal ***************/}
            {
                Modal && (
                    <div className="modal-back1">
                        <div className="kotStatus-modal" style={{ maxHeight: "90%", overflow: "scroll" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}>Kot Status</label>
                                <i className="fa-solid fa-xmark xmark" onClick={closeModal}></i>
                            </div>
                            <hr />
                            <div className="flex-start" style={{ marginLeft: "15px" }}>
                                <ul style={{ paddingLeft: "0px" }}>
                                    {statusList.map((data, index) => (
                                        <>
                                            <label>• Kot <label style={{ fontWeight: "600" }}>{data.orderStatus}</label> by <label style={{ fontWeight: "600" }}>{data.stausCreatedBy}</label>,
                                                <br />Time :
                                                {/* {data.orderStatus == "Transferred" ? (
                                                    moment.utc(data.statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')
                                                    // moment(data.statusDateTime).format('DD-MM-YYYY hh:mm A')
                                                ) : moment.utc(data.statusDateTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} */}
                                                {moment(data.statusDateTime).format('DD-MM-YYYY hh:mm A')}
                                            </label><br /><br />
                                        </>
                                    ))}
                                </ul>
                            </div>
                            <hr />
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                {/*<button className="btn greyBtn" style={{ width: "25%" }} >Print</button>*/}
                                <button className="redBtn" style={{ width: "25%" }} onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* ****************************** BILL PRINT *************************** */}
            {showBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="KOTBillPrint" ord1Id={ord2Id} setShowBillPrint={setShowBillPrint} setLoading={setLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isShowAlertBox && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn=''
                    okayBtnName="Yes" />
            )}
            {isShowAlertBoxForSave && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtnForSave}
                    closeBtnName={closeBtnName}
                    okayBtn=''
                    okayBtnName="Yes" />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default OrderDetail;
