import React from "react";

const SundryDetailsData = (props) => {
  const {
    nextCellRefs,
    inputValues,
    handleKeyDown_SundrySuggestion,
    handleSundryNameChange,
    inputPosition,
    suggestionBoxRef,
    getPosition,
    sundryRows,
    handleSundryCellChange,
    handleInputChangeEdit,
    currencyDecimalPlaces,
    editMode,
    itemRefs,
    deleteSundryRow,
    handleSundryRowKeyDown,
    handleBlurForEdit,
    handleSundrySelection,
    setSundryRows,
  } = props;

  const handleCloseDropdown = (rowIndex) => {
    // var checkId = sundryRows
    console.log(sundryRows);
    debugger
    setTimeout(() => {
      setSundryRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          isDropdownOpen: false,
          filteredSundry: [],
        };
        return newRows; // Ensure you return the updated array
      });
    }, 300);
  };

  return (
    <div
      className="tab-content-1"
      // style={{ padding: "5px" }}
    >
      <div className="input-box-top-head">
        <div className="">
          <div>
            <table className="table-scrol1" style={{ border: "0" }}>
              <thead
                className="table-tbody"
                style={{ backgroundColor: "#e9e5e5" }}
              >
                <tr className="table-tr1">
                  <th
                    style={{
                      width: "30px",
                      textAlign: "center",
                    }}
                    className="table-th-1"
                  >
                    SN
                  </th>
                  <th className=" td-col2">
                    <span style={{ color: "red" }}>T</span>
                    axes & Othr Charge
                  </th>
                  <th className="table-th-6">@%</th>
                  <th className="table-th-5">Amount</th>
                  <th className="table-th-4">Action</th>
                </tr>
              </thead>
              <tbody
                className=" table-container-tbody"
                style={{
                  maxHeight: "85px",
                  margin: "5px 0px 0px 0px",
                  overflowY: "scroll",
                  borderBottom: "1px solid #cccccc",
                }}
              >
                {sundryRows.map((row, rowIndex) => (
                  <tr className="table-tr1" key={rowIndex}>
                    <td
                      className="table-td-2"
                      style={{
                        textAlign: "center",
                        width: "30px",
                      }}
                    >
                      {rowIndex + 1}
                    </td>
                    <td className="td-col2" style={{ position: "relative" }}>
                      <input
                        id={`cell-${rowIndex}-0`}
                        className="table-input-13 input-box-td1"
                        type="text"
                        value={row.name}
                        onChange={(e) => handleSundryNameChange(e, rowIndex)}
                        disabled={inputValues?.TotalItemAmt == 0}
                        onKeyUp={(e) =>
                          handleKeyDown_SundrySuggestion(e, rowIndex)
                        }
                        autoComplete="off"
                        ref={(input) => {
                          if (!nextCellRefs.current[rowIndex])
                            nextCellRefs.current[rowIndex] = [];
                          nextCellRefs.current[rowIndex][1] = input;
                        }}
                        onBlur={() => handleCloseDropdown(rowIndex)}
                        onClick={() => getPosition(rowIndex, 1)} // Get position on click
                        onFocus={() => getPosition(rowIndex, 1)} // Get position on click
                      />
                      {row.isDropdownOpen && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {row.filteredSundry.map((item, index) => (
                            <li
                              key={item.id}
                              onClick={() =>
                                handleSundrySelection(rowIndex, item)
                              }
                              ref={(el) => (itemRefs.current[index] = el)}
                              style={{
                                backgroundColor:
                                  row.highlightedIndex === index
                                    ? "#e6f7ff"
                                    : "transparent",
                              }}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      )}

                      <input type="hidden" id="hddn_sunId" value={row.id} />
                      <input
                        type="hidden"
                        id="hnnd_sundryType"
                        value={row.sundryType}
                      />
                      <input
                        type="hidden"
                        id="hnnd_defaultValue"
                        value={row.defaultValue}
                      />
                      <input
                        type="hidden"
                        id="hnnd_methodApplied"
                        value={row.methodApplied}
                      />
                      <input
                        type="hidden"
                        id="hnnd_absolutePercentAmount"
                        value={row.absolutePercentAmount}
                      />
                      <input
                        type="hidden"
                        id="hnnd_percentOfAmount"
                        value={row.percentOfAmount}
                      />
                      <input
                        type="hidden"
                        id="hnnd_applyOnBill"
                        value={row.applyOnBill}
                      />
                      <input
                        type="hidden"
                        id="hnnd_roundOffSundryAmount"
                        value={row.roundOffSundryAmount}
                      />
                      <input
                        type="hidden"
                        id="hnnd_amtRoundOffConfig"
                        value={row.amtRoundOffConfig}
                      />
                      <input
                        type="hidden"
                        id="hnnd_amtAdjustSaleAccount"
                        value={row.amtAdjustSaleAccount}
                      />
                      <input
                        type="hidden"
                        id="hnnd_selectedAccountSale"
                        value={row.selectedAccountSale}
                      />
                      <input
                        type="hidden"
                        id="hnnd_amtAdjustPurchaseAccount"
                        value={row.amtAdjustPurchaseAccount}
                      />
                      <input
                        type="hidden"
                        id="hnnd_selectedAccountPurchase"
                        value={row.selectedAccountPurchase}
                      />
                      <input
                        type="hidden"
                        id="hnnd_roundOffLimit"
                        value={row.roundOffLimit}
                      />
                    </td>
                    <td className="table-td-2 table-th-6">
                      <input
                        className="table-input-13 input-box-td2"
                        type="text"
                        name="Percent"
                        id={`Percent-${row.rowIndex}-${rowIndex}`}
                        value={
                          editMode[`Percent-${row.rowIndex}-${rowIndex}`]
                            ? row.Percent
                            :row.Percent
                        }
                        onFocus={(e) => handleInputChangeEdit(e)}
                        disabled={inputValues?.TotalItemAmt == 0}
                        onChange={(e) => handleSundryCellChange(e, rowIndex)}
                        onBlur={(e) =>
                          handleBlurForEdit(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) => handleSundryRowKeyDown(e, rowIndex, 2)}
                        ref={(input) => {
                          if (!nextCellRefs.current[rowIndex])
                            nextCellRefs.current[rowIndex] = [];
                          nextCellRefs.current[rowIndex][2] = input;
                        }}
                      />
                    </td>
                    <td className="table-td-2 table-th-5">
                      <input
                        className="table-input-13 input-box-td2"
                        type="text"
                        name="Amount"
                        id={`Amount-${row.rowIndex}-${rowIndex}`}
                        value={
                          editMode[`Amount-${row.rowIndex}-${rowIndex}`]
                            ? row.Amount
                            : row.Amount
                        }
                        autoComplete="off"
                        onFocus={(e) => handleInputChangeEdit(e)}
                        onChange={(e) => handleSundryCellChange(e, rowIndex)}
                        disabled={inputValues?.TotalItemAmt == 0}
                        onBlur={(e) =>
                          handleBlurForEdit(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) => handleSundryRowKeyDown(e, rowIndex, 3)}
                        ref={(input) => {
                          if (!nextCellRefs.current[rowIndex])
                            nextCellRefs.current[rowIndex] = [];
                          nextCellRefs.current[rowIndex][3] = input;
                        }}
                      />
                    </td>
                    <td
                      className="table-td-2 table-th-4"
                      style={{ textAlign: "center" }}
                    >
                      <button
                        className="btn-table1"
                        style={{ margin: "0" }}
                        onClick={() => deleteSundryRow(row.rowIndex)}
                      >
                        <i className="fa-solid fa-trash delete-btn"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SundryDetailsData;
