import React, { useState, useEffect } from "react";

const SaleInvoiceAlert = (props) => {
  const [Button1] = useState(props.singleButton);
  const [countdown, setCountdown] = useState(props.timer || 5);

  useEffect(() => {
    let interval;
    if (Button1) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [Button1]);

  useEffect(() => {
    if (countdown === 0) {
      handleCloseModal();
    }
  }, [countdown]);
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        props.onYesClick(false);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleCloseModal = () => {
    props.onYesClick(false);
  };

  return (
    <div className="modal-back">
      <div className="alert-card">
        <div className="content" style={{ padding: "0" }}>
          <span className="alert-title">{props.HeadingTop}</span>
          <div className="desc">{props.Heading}</div> 

          <div
            style={{ textAlign: "center", flexWrap: "wrap" }}
            className="action"
          >
            <div onClick={props.onYesClick} className="download meg1">
              Done (Go to list)
              {/* {countdown} */}
            </div>
            <div onClick={props.PrintBill} className="download meg1">
              Print Bill
            </div>
            <div onClick={props.Reload} className="notnow meg1">
              Reload
            </div>
            {/* <div onClick={props.onYesClick} className="notnow meg1">
              Cancel
            </div> */}
          </div>
        </div>
        <button
          className="alert-close"
          type="button"
          onClick={props.OpenAlertBox}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              clip-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SaleInvoiceAlert;
