import { hasUtf16BOM } from "pdf-lib";
import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Transaction = {
  /*----------------------Transaction---------------------------------------------------------------------*/

  GetTransRefNo: async (Id) => {
    console.log(Id);
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransRefNo`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          referenceNumber: "",
          transtype: Number(Id),
          transId: 0,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetTransBillData: async (data, InputValueAccMaster, ReferenceNumber) => {
    // debugger;
    console.log(InputValueAccMaster);

    // console.log(Id);
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetTransBillData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          transtype: Number(data.TransType) || 0,
          billTypeId: Number(data.billTaxTypeId) || 0,
          transId: Number(data.transId) || 0,
          billSeriesId: Number(data.billSeriesId) || 0,
          billDate: data.billDate ? new Date(data.billDate).toISOString() : "",
          deliveryDate: data.billDate ? new Date(data.billDate).toISOString() : "",
          billNumber: String(data.billNumber) || "",
          godownId: Number(data.godownId) || 0,
          bookedById: Number(data.bookedById) || 0,
          partyId: Number(InputValueAccMaster.AccId) || 0,
          refById: Number(InputValueAccMaster.AccRefById) || 0,
          stateOfSale: Number(data.pos) || 0,
          refBillNumber: Number(data.refBillNumber) || 0,
          refBillDate: data.refBillDate ? new Date(data.refBillDate).toISOString() : "",
          shortNarration: String(data.Remark) || "",
          billRemark: String(data.Remark),     
          billDeliveryDate: data.DeliveryDate ? new Date(data.DeliveryDate).toISOString() : "",
          billStatus: Number(data.vochStatusId) || 0,
          billRemark1: [Number(data.Remark1)] || [0],
          billRemark2: [Number(data.Remark2)] || [0],
          billRemark3: [Number(data.Remark3)] || [0],
          paymentStatus: true,
          isEcomInvoice: false,
          ecomDelAddress: "",
          ecomBillAddress: "",
          ecomDelContact: ""
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransBillData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransBillData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetProductTempData: async (data, ReferenceNumber) => {
    // debugger;
    // var ProductDataList = [];
    // for (var i = 0; i < data?.length; i++) {
    //   // Check if itemId_d2 (data[i].d2_Id) is null or blank
    //   if (data[i].d2_Id == null || data[i].d2_Id === "" || data[i].d2_Id === undefined|| data[i].d2_Id === 0) {
    //     continue; // Skip this iteration if d2_Id is null or blank
    //   }

    //   var obj = {
    //     rowIndex: Number(data[i].rowIndex) || 0,
    //     itemCode: Number(data[i].defaultBarcode) || "",
    //     itemId_d2: Number(data[i].d2_Id) || 0,
    //     itemName: data[i].productName || "",
    //     itmGrpId_d2: Number(data[i].parentGrp) || 0,
    //     itmGrpName: data[i].parentGrpName || "",
    //     godownId: Number(data[i].godownId) || 0,
    //     godownName: data[i].godownName || "",
    //     masterDescription: data[i].masterDescription || "",
    //     itemDescription: data[i].shortDescription || "",
    //     itemAttributes: data[i].itemAttributes || [],
    //     batchNo: Number(data[i].BatchNo) || "",
    //     mgfDate: data[i].MgfDt || new Date().toISOString(),
    //     expDate: data[i].ExpDt || new Date().toISOString(),
    //     unit: Number(data[i].productUnit) || 0,
    //     qty: Number(data[i].Qty) || 0,
    //     freeQty: Number(data[i].FreeQty) || 0,
    //     price: Number(data[i].itemPrice) || 0,
    //     discountPer1: Number(data[i].DiscPer) || 0,
    //     discountAmt1: Number(data[i].DiscAmt) || 0,
    //     discountPer2: Number(data[i].DoubleDis) || 0,
    //     discountAmt2: Number(data[i].DiscAmt) || 0,
    //     salePrice: Number(data[i].salePrice) || 0,
    //     mrpPrice: Number(data[i].mrpPrice) || 0,
    //     purPageSaleProfPer: Number(data[i].purPageSaleProfPer) || 0,
    //     purPageSalePrice: Number(data[i].purPageSalePrice) || 0,
    //     purPageMrpPrice: Number(data[i].purPageMrpPrice) || 0,
    //     eye: Number(data[i].lens_Eye) || "",
    //     dia: Number(data[i].lens_Dia) || "",
    //     axis: Number(data[i].lens_AXIS) || 0,
    //     sph: Number(data[i].lens_SPH) || 0,
    //     cyl: Number(data[i].lens_CYL) || 0,
    //     add: Number(data[i].lens_Add) || 0,
    //     extraFields: data[i].extraFields || [],
    //   };
    //   ProductDataList.push(obj);
    // }

    // console.log(Id);

    let BatchObj = [];
    if (Array.isArray(data[0]?.batchNos) && data[0].batchNos.length > 0) {
      BatchObj = data[0].batchNos.map(batch => ({
        id: Number(batch.id) || 0,
        batchNo: batch.batchNo || 0,
        mgfDate: batch.mgfDate || "",
        expDate: batch.expDate  || "",
        qty: Number(batch.qty) || 0,
        purPrice: Number(batch.purPrice) || 0,
        salePrice: Number(batch.salePrice) || 0,
        mrp: Number(batch.mrp) || 0,
        costPrice: Number(batch.costPrice) || 0,
        opPacks: Number(batch.opPacks) || 0,
        opLoose: Number(batch.opLoose) || 0,
        opValue: Number(batch.opValue) || 0,
        godownId: Number(batch.godownId) || 0,
        vc1: batch.vc1 || "",
        vc2: batch.vc2 || "",
        vc3: batch.vc3 || "",
        vc4: batch.vc4 || "",
        vc5: batch.vc5 || "",
        vc6: batch.vc6 || "",
        vc7: batch.vc7 || "",
        vc8: batch.vc8 || "",
        vc9: batch.vc9 || "",
        vc10: batch.vc10 || "",
      }));
    }

    var ProductDataList = [];
    var obj = {
      rowIndex: Number(data[0].rowIndex) || 0,
      itemCode: String(data[0].defaultBarcode) || "",
      itemId_d2: Number(data[0].d2_Id) || 0,
      itemName: data[0].productName || "",
      itmGrpId_d2: Number(data[0].parentGrp) || 0,
      itmGrpName: data[0].parentGrpName || "",
      godownId: Number(data[0].godownId) || 0,
      godownName: data[0].godownName || "",
      masterDescription: data[0].masterDescription || "",
      itemDescription: data[0].shortDescription || "",
      itemAttributes: data[0].itemAttributes || [],
      batchNos: BatchObj || [],
      serialNumbers: data[0].serialNumbers || [],
      mgfDate: data[0].MgfDt || new Date().toISOString(),
      expDate: data[0].ExpDt || new Date().toISOString(),
      unit: Number(data[0].productUnit) || 0,
      qty: Number(data[0].Qty) || 1,
      freeQty: Number(data[0].FreeQty) || 0,
      price: Number(data[0].itemPrice) || 0,
      discountPer1: Number(data[0].DiscPer) || 0,
      discountAmt1: Number(data[0].DiscAmt) || 0,
      discountPer2: Number(data[0].DoubleDis) || 0,
      discountAmt2: Number(data[0].disc2Amt) || 0,
      salePrice: Number(data[0].salePrice) || 0,
      mrpPrice: Number(data[0].mrpPrice) || 0,
      purPageSaleProfPer: Number(data[0].purPageSaleProfPer) || 0,
      purPageSalePrice: Number(data[0].purPageSalePrice) || 0,
      purPageMrpPrice: Number(data[0].purPageMrpPrice) || 0,
      eye: data[0].lens_Eye || "",
      dia: data[0].lens_Dia || "",
      axis: Number(data[0].lens_AXIS) || 0,
      sph: Number(data[0].lens_SPH) || 0,
      cyl: Number(data[0].lens_CYL) || 0,
      add: Number(data[0].lens_Add) || 0,
      extraFields: data[0].extraFields || [],
    };
    ProductDataList.push(obj);
    // debugger
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetProductTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      if (ProductDataList.length !== 0) {
        const response = await axios.post(
          apiUrl,
          {
            ProductDataList,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        // debugger;
        console.log("Response:", response.data);
        return response.data;
      }
      else {
        return "no item";
      }
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetProductTempData: async (ReferenceNumber, filter) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetProductTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        { filter },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  RemoveProductTempData: async (ReferenceNumber, index) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/RemoveProductTempData?ReferenceNumber=${ReferenceNumber}&Indx=${index}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetSundryTempData: async (data, ReferenceNumber) => {
    debugger
    var SundryDataList = [];
    for (var i = 0; i < data?.length; i++) {
      var obj = {
        rowIndex: Number(data[i].rowIndex) || 0,
        sundryId_d2: Number(data[i].id) || 0,
        adjustmentInItem: data[i].adjustmentInItem || true,
        sundryPer: Number(data[i].Percent) || 0,
        sundryAmt: Number(data[i].Amount) || 0,
      };

      SundryDataList.push(obj);
    }
    // console.log(Id);
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetSundryTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          SundryDataList,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetSundryTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetSundryTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  RemoveSundryTempData: async (ReferenceNumber, index) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/RemoveSundryTempData?ReferenceNumber=${ReferenceNumber}&Indx=${index}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetAccountTempData: async (data, ReferenceNumber, TransactionLListForPayment, adjamountToSend, adjamountRemark) => {
    debugger
    const Ids = TransactionLListForPayment
      .filter(row => row.hasOwnProperty('checked') && row.checked === true)  // Check if 'checked' exists and is true
      .map(row => parseInt(row.id));  // Extract t4 values and convert to integers

    console.log(Ids);  // Output: [914, 913]

    // debugger
    var dataForAccount = [];
    for (var i = 0; i < data?.length; i++) {
      var obj = {
        //rowIndex: i + 1|| 0,
        accountId_d2: Number(data[i].accountId) || 0,
        amt: Number(data[i].PayAmt) || 0,
        //dt: data[i].dt || "2024-11-05T07:44:13.047Z",
        remark: data[i].remark || ""
      };

      dataForAccount.push(obj);
    }

    var AccountTempData = {
      amt: dataForAccount,
      vch: Ids.length > 0 ? {
        inv: Ids,
        amt: Number(adjamountToSend) || 0,
        remark: adjamountRemark || ""
      } : {},
    };

    debugger
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetAccountTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, { AccountTempData }, {
        withCredentials: true,
        credentials: "include",
      });
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetAccountTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetAccountTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetTransLensData: async (
    regularLens,
    contactLens,
    trnsposeLens,
    IMLens,
    ARLens,
    lensOtherInfo,
    // inputValues,
    ReferenceNumber
  ) => {
    debugger;
    console.log(ReferenceNumber);
    // if (regularLens.RItemId_d2 != "" || regularLens.ITEM_RIGHT != "") {
    var LensDataList = [];
    let obj = {
      indx: lensOtherInfo.rowindex,
      // vouchNo: inputValues.billNumber || "", // Sale voucher number
      ItemId_d2: 0,
      rItemId_d2: regularLens.RItemId_d2 || 0, // Right lens item ID
      rItemName: regularLens.ITEM_RIGHT || "", // Right lens item name
      rSphDv: regularLens.SPH_DV_R || "", // Right lens spherical value for distance
      rCylDv: regularLens.CYL_DV_R || "", // Right lens cylinder value for distance
      rAxisDv: regularLens.AXS_DV_R || "", // Right lens axis for distance
      rVnDv: regularLens.VN_DV_R || "", // Right lens vision distance
      rSphNv: regularLens.SPH_NV_R || "", // Right lens spherical value for near vision
      rCylNv: regularLens.CYL_NV_R || "", // Right lens cylinder value for near vision
      rAxisNv: regularLens.AXS_NV_R || "", // Right lens axis for near vision
      rVnNv: regularLens.VN_NV_R || "", // Right lens vision near vision
      rAdd: regularLens.ADD_R || "", // Right lens add power
      rpB1: regularLens.PRIZM_R || "", // Right lens pupil base 1
      rpB2: regularLens.BASE_R || "", // Right lens pupil base 2
      lItemId_d2: regularLens.LItemId_d2 || 0, // Left lens item ID
      lItemName: regularLens.ITEM_LEFT || "", // Left lens item name
      lSphDv: regularLens.SPH_DV_L || "", // Left lens spherical value for distance
      lCylDv: regularLens.CYL_DV_L || "", // Left lens cylinder value for distance
      lAxisDv: regularLens.AXS_DV_L || "", // Left lens axis for distance
      lVnDv: regularLens.VN_DV_L || "", // Left lens vision distance
      lSphNv: regularLens.SPH_NV_L || "", // Left lens spherical value for near vision
      lCylNv: regularLens.CYL_NV_L || "", // Left lens cylinder value for near vision
      lAxisNv: regularLens.AXS_NV_L || "", // Left lens axis for near vision
      lVnNv: regularLens.VN_NV_L || "", // Left lens vision near vision
      lAdd: regularLens.ADD_L || "", // Left lens add power
      lpB1: regularLens.PRIZM_L || "", // Left lens pupil base 1
      lpB2: regularLens.BASE_L || "", // Left lens pupil base 2
      // refBy: lensOtherInfo.PrescribedBy || "", // Referring party
      printLenseStatus: lensOtherInfo.LenseStatus || "", // Lens status (active, sold, etc.)
      printLensEye: lensOtherInfo.PrintLens || "", // Print instructions for the lens
      lenseType: lensOtherInfo.LensType || "", // Lens type (single vision, bifocal, etc.)
      prescribedBy: lensOtherInfo.PrescribedBy || "", // Prescribed by (doctor's name)
      partyId: Number(lensOtherInfo.PartyId) || 0, // Customer/party ID
      partyName: lensOtherInfo.PartyName || "", // Customer/party name
      frameName: lensOtherInfo.FrameName || "",
      remark: lensOtherInfo.remark || "", // Additional remarks
      nextVisit: lensOtherInfo.NvVal || "", // Next visit date or reminder
      nvDate: lensOtherInfo.NvDt || "", // Near vision date (if applicable)
      lensExpRem: lensOtherInfo.CleVal || "", // Lens expiration reminder
      lExDate: lensOtherInfo.CleDt || "", // Left lens expiration date
      pdr: lensOtherInfo.PdR || "", // Pupillary distance
      pdl: lensOtherInfo.PdL || "", // Pupillary distance
      pdrl: lensOtherInfo.PdRL || "", // Pupillary distance
      deliverDays: lensOtherInfo.DelivDay || "", // Delivery days estimate
      deliverTime: lensOtherInfo.DelivTime || "", // Expected delivery date
      contact: JSON.stringify(contactLens) || "", // Contact number
      transpose: JSON.stringify(trnsposeLens) || "", // Transposition details if any
      ar: JSON.stringify(ARLens) || "", // Additional details for the right lens
      im: JSON.stringify(IMLens) || "", // Additional details for the left lens
      imgId: Number(lensOtherInfo.imgId) || 0,
      linkedItm: 0,
    };
    LensDataList.push(obj);
    // debugger
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetTransLensData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      if (LensDataList.length !== 0) {
        const response = await axios.post(
          apiUrl,
          {
            LensDataList,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        // debugger;
        console.log("Response:", response.data);
        return response.data;
      } else {
        return "no item";
      }
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransLensData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransLensData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        { withCredentials: true, credentials: "include" }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  DeleteTransLensData: async (LensId) => {
    debugger
    console.log(LensId)
    if (LensId) {
      try {
        console.log("delete");
        const apiUrl = `${WebApiUrl}/api/Transaction/DeleteTransLensData?LensId=${LensId}`;
        const response = await axios.delete(apiUrl, {
          withCredentials: true,
        });
        debugger

        console.log("Delete Response:", response.data.isSuccess);
        return response.data.isSuccess;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        }
      }
    }
  },

  // ***************************** Create Transaction ***********************
  SaveTransactions: async (ReferenceNumber) => {
    // debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SaveTransactions?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  GetTransactionList: async (TransType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionList?TransType=${TransType}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransactionById: async (Trans1Id, TransType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionById?Trans1Id=${Trans1Id}&TransType=${TransType}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  GetTransactionTypes: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionTypes`;
      console.log("API URL:", apiUrl);
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      if (error.response) {
        // Handle known error responses
        if (error.response.status === 401) {
          alert("Unauthorized request. Please log in.");
        } else if (error.response.status === 500) {
          alert("Server error. Please try again later.");
        } else {
          alert(`An error occurred: ${error.response.status}`);
        }
        console.error("Error response:", error.response.data);
      } else {
        // Handle network or unexpected errors
        alert("Network error. Please check your connection.");
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        }
      }
    }
  },

  GetTransactionVchSrsInfo: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionVchSrsInfo`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        data,
        { withCredentials: true, credentials: "include" }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransactionVchSrsInfo: async (data) => {
    try {
      debugger
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionVchSrsInfo`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        data,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransactionSummaryBySrsId: async (data, Ids) => {
    debugger
    var pagenumber = 1
    var pagesize = 50
    if (Ids.length > 0) {

      try {
        debugger
        const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionSummaryBySrsId?acc_d2id=${data}&pagenumber=${pagenumber}&pagesize=${pagesize}`;
        console.log(apiUrl);
        const response = await axios.post(
          apiUrl,
          Ids,
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        // debugger;
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        }

        if (error.response && error.response.status === 401) {
          alert("Failed");
        } else {
          alert("An error occurred. Please try again.");
        }
      }
    }
  },

  // *************************** Update Lens Prescription Image ********************
  UpdateLensPrescriptionImage: async (data) => {
    debugger
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/UpdateLensPrescriptionImage`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        data,
        { withCredentials: true, credentials: "include" }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  GetBarcodeSerialBatchNo: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetBarcodeSerialBatchNo`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        data,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }

    }
  },


  TransactionsStatus: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/TransactionsStatus?ReferenceNumber=${ReferenceNumber}`;
      console.log("API URL:", apiUrl);
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      debugger
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      if (error.response) {
        // Handle known error responses
        if (error.response.status === 401) {
          alert("Unauthorized request. Please log in.");
        } else if (error.response.status === 500) {
          alert("Server error. Please try again later.");
        } else {
          alert(`An error occurred: ${error.response.status}`);
        }
        console.error("Error response:", error.response.data);
      } else {
        // Handle network or unexpected errors
        alert("Network error. Please check your connection.");
        console.error("Error:", error.message);
      }
    }
  },

  // ************************ Order Challan Data To Convert  **************************************
  GetOrderChallanDataToConvert: async (ReferenceNumber, ConversionTransId) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetOrderChallanDataToConvert?ReferenceNumber=${ReferenceNumber}&ConversionTransId=${ConversionTransId}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  DeleteTransactionData: async (TransId) => {
    debugger
    console.log(TransId)
    if (TransId) {
      try {
        console.log("delete");
        const apiUrl = `${WebApiUrl}/api/Transaction/DeleteTransactionData?TransId=${TransId}`;
        const response = await axios.delete(apiUrl, {
          withCredentials: true,
        });
        debugger

        console.log("Delete Response:", response.data.isSuccess);
        return response.data.isSuccess;
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  },

  CalcualteTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/CalcualteTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  

  // // ************************ Work shop API**************************************



  CreateOrUpdateWorkshopDetail: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/CreateOrUpdateWorkshopDetail`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {data},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  

  GetAllTransWorkShopDetail: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetAllTransWorkShopDetail`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {data},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  
  GetTransWorkShopDetailByID: async (TransWorkShopDetailId) => {
    try {
      console.log("get by id");
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransWorkShopDetail?TransWorkShopDetailId=${TransWorkShopDetailId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  DeleteTransWorkShopDetail: async (TransId) => {
    debugger
    if (TransId) {
      try {
        console.log("delete");
        const apiUrl = `${WebApiUrl}/api/Transaction/DeleteTransWorkShopDetail`;
        const response = await axios.delete(apiUrl, [TransId], {
          withCredentials: true,
        });
        console.log("Delete Response:", response.data.isSuccess);
        return response.data.isSuccess;
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  },


};

export default Transaction;
