import React, { useContext, useEffect, useRef, useState } from 'react'
import PageTabs from '../Components/PageTabs'
import MainButton from '../Props/MainButton'
import MyContext from '../MyContext';
import ModuleNameAPI from '../../API/APIServices/ModuleName';
import AlertBox from '../Props/AlertBox';
import Loader from '../Props/Loader';
import DataTable from './TableMaster/DataTable';

const ModuleName = () => {

    useState(() => {
        document.title = 'Module Name';
    }, []);

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit, OpenAlertBoxDelete, setAlertBoxDelete, alertBoxDelete, input2, input1, input3, input4 } = useContext(MyContext);

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);


    // const [alertBoxDelete1] = useState(alertBoxDelete);
    // const [alertBoxEdit1] = useState(alertBoxEdit);
    // const [alertBox1] = useState(alertBox);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [inputValues, setInputValues] = useState({
        id: 0,
        moduleName: '',
        moduleDescription: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [edit, setEdit] = useState(false);

    const SaveBtn = async () => {
        if (inputValues.moduleName && inputValues.moduleName != "" && inputValues.moduleName != 0) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await ModuleNameAPI.updateModuleName(inputValues);
                if (isSuccess.isSuccess) {
                    setLoading(false);
                    setEdit(false);
                    OpenAlertBox();
                    fetchData();
                } else if (isSuccess.errorMessages != "") {
                    alert(isSuccess.errorMessages)
                    setLoading(false);
                } else {
                    alert("Error inserting data");
                    setLoading(false);
                }
            }
            else {
                setLoading(true);
                const isSuccess = await ModuleNameAPI.insertModuleName(inputValues);
                if (isSuccess.isSuccess) {
                    setLoading(false);
                    fetchData()
                    OpenAlertBox();
                } else if (isSuccess.errorMessages != "") {
                    alert(isSuccess.errorMessages);
                    setLoading(false);
                } else {
                    alert("Error inserting data");
                    setLoading(false);
                }
            }
            setInputValues({
                moduleName: "",
                moduleDescription: "",
            });
        }
    }

    const editModuleName = async (id) => {
        setEdit(true);
        const data = await ModuleNameAPI.getModuleNameById(id);
        console.log(data);
        setInputValues(data);
        fetchData();
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteModuleName = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteModuleConfirm = async () => {
        setLoading(true);
        try {
            const val = await ModuleNameAPI.deleteModuleName(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    // *********************Data Table***************************
    const [data1, setData1] = useState([]);
    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "Module Name", accessor: "moduleName" },
        { Header: "Module Description", accessor: "moduleDescription" },
        { Header: "ACTION", accessor: "action" },
    ];
    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Module Name" ref={input1} />, accessor: "moduleName" },
        { Header: <input type="text" placeholder="Module Description" ref={input2} />, accessor: "moduleDescription" },
    ];
    const fetchData = async () => {
        try {
            const val = await ModuleNameAPI.getModuleNames();
            console.log(val.length);
            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    moduleName: item.moduleName,
                    moduleDescription: item.moduleDescription,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editModuleName(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteModuleName(item.id)}></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div>
                < PageTabs />
                <div className="panel-heading">
                    <div className="panel-head-title">
                        <h2 className="panel-title">Module Name</h2>
                    </div >
                    {/* *******************Top Buttons****************** */}
                    <MainButton PrintIcon={true} SaveButton={SaveBtn} modelOpen={true} PageName="Masters" />
                </div>
                <div className="content_box">
                    <div className="tab-content-box tab-patient-top">
                        <div className="tab-content-1">
                            <div className="company-info-top-left11 patient-padding patient-table-width" >
                                <div className="configure_radius_add_update">
                                    <div className="company_control">
                                        <div className="stock-im-dep1">
                                            <div className="input-box-Imo1 input-height2">
                                                <div className="input-box-mok1">
                                                    <input className="modify_im_input mode_category1" ref={input1Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input2Ref)} name="moduleName" value={inputValues.moduleName} onChange={handleInputChange} type="text" required />
                                                    <label class="modify_p_text2">Module Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stock-im-dep1">
                                            <div className="input-box-Imo1 input-height2">
                                                <div className="input-box-mok1">
                                                    <input className="modify_im_input mode_category1" ref={input2Ref}
                                                        onKeyDown={(event) => handleKeyPress(event, input1Ref)} type="text" name="moduleDescription" value={inputValues.moduleDescription} onChange={handleInputChange} required />
                                                    <label class="modify_p_text2"> Module Description</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="configure_radius_add_update config-search-top">
                                    {/* <div className="input-box-Imo1 input-height2">
                                        <div className="input-box-mok1 search-add-width">
                                            <input className="modify_im_input mode_category1" type="text" required />
                                            <label style={{ left: "35px" }} class="modify_p_text2">Search</label>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-12">
                                        <div className="table-resposive" >
                                            <div className="nav-1" >
                                                <DataTable filterData={filterData} columns={columns} data={data1} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {alertBoxDelete && (
                    <AlertBox
                        HeadingTop="DELETE"
                        Heading="Are You sure you want to delete this?"
                        OpenAlertBox={OpenAlertBoxDelete}
                        onYesClick={DeleteModuleConfirm}
                    />
                )}
                {alertBox && (
                    <AlertBox
                        HeadingTop="Saved"
                        Heading="Data Saved"
                        onYesClick={OpenAlertBox}
                        singleButton={true}
                        OpenAlertBox={OpenAlertBox}
                    />
                )}

                {alertBoxEdit && (
                    <AlertBox
                        HeadingTop="Updated"
                        Heading="Data Updated"
                        onYesClick={OpenAlertBoxEdit}
                        singleButton={true}
                        OpenAlertBox={OpenAlertBoxEdit}
                    />
                )}
                {loading && <Loader />}
            </div >
        </div>
    )
}

export default ModuleName