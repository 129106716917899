import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useContext } from "react";
import 'react-quill/dist/quill.snow.css'
import MainButton from "../../Props/MainButton";
import TableApi from "../../../API/APIServices/Table";
import TableGroupApi from "../../../API/APIServices/TableGroup";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import { useNavigate } from 'react-router-dom';
import ModalBoxMaster from "../../Props/ModalBoxMaster";

function TableMaster(props) {

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);
    const navigate = useNavigate();

    useState(() => {
        document.title = 'Table Master';
    }, []);

    //******************************************************//
    const [errorMessages, setErrorMessages] = useState({
        tableName: '',
        tableGroup: '',
        tableNumber: '',
    });
    const [inputValues, setInputValues] = useState({
        tableName: '',
        tableGroup: '',
        tableNumber: '',
        tablePriority: 0,
        tableCapacity: 0,
        isActive: true,
        tableStatus :"",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };

    const fieldNames = ['tableName', 'tableGroup', 'tableNumber'];

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (props.editedDataModal === true) {
                const isSuccess = await TableApi.updateTable(inputValues);
                props.fetchData();
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);
                    props.fetchData()
                    props.hideModal();
                } else {
                    alert("error while updating");
                }
            }
            else {
                setLoading(true);
                const isSuccess = await TableApi.insertTable(inputValues);
                debugger;
                if (props.fetchData) {
                    props.fetchData();
                }
                if (isSuccess) {
                    setLoading(false);
                    OpenAlertBox();
                    // props.hideModal();
                } else {
                    alert("Error inserting data");
                }
            }
            if (props.fetchData) {
                props.fetchData();
            }
            setInputValues({
                tableName: '',
                tableGroup: 0,
                tableNumber: '',
                tablePriority: 0,
                tableCapacity: 0,
                isActive: true,
                tableStatus:"",
            });
        }
    }

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };
    // **********************************************
    const [tableGroupValues, setTableGroupValues] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TableGroupApi.getTableGroup();
                if (data && Array.isArray(data)) {
                    setTableGroupValues(data);
                    console.log(data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData; // Assuming props.editData is a Promise
                        console.log("data");
                        console.log(data);
                        const newData = {
                            id: data.id,
                            tableName: data.tableName,
                            tableGroup: data.tableGroupId,
                            tableNumber: data.tableNumber,
                            tablePriority: data.tablePriority,
                            tableCapacity: data.tableCapacity,
                            isActive: true,
                            tableStatus:data.tableStatus,
                        }
                        setInputValues(newData);
                    } catch (error) {
                        console.log(error);
                    }
                }
                else if (props.tableGroupId) {
                    const newData = {
                        tableName: "",
                        tableGroup: props.tableGroupId,
                        tableNumber: "",
                        tablePriority: 0,
                        tableCapacity: 0,
                        isActive: true,
                    }
                    setInputValues(newData);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Escape') {
                props.close();
            }
            if (event.key === 'F10') {
                SaveBtn();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    });

    const [tableGroupModal, setTableGroupModal] = useState(false);

    const openTableGroupModal = () => {
        setTableGroupModal(true)
    }
    const closeTableGroupModal = () => {
        setTableGroupModal(false)
    }

    return (
        <div>
            {/* <PageTabs /> */}
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Table Master</h2>
                </div >
                {/* **********************{Save Button}*********************** */}
                < MainButton SaveButton={SaveBtn} PageName="Masters" />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" style={{ width: "100%" }} >
                                        <div className="company_info-text-im td-1">Table Master
                                            <div className="input-box-checkbox1 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="">
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <select className="modify_im_input mode_limit mode_category" onChange={(e) => handleInputChange(e)} type="text" required value={inputValues.tableGroup} name="tableGroup">
                                                    <option value=""></option>
                                                    {(() => {
                                                        if (tableGroupValues !== null && Array.isArray(tableGroupValues)) {
                                                            return tableGroupValues.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ))
                                                        }
                                                    })()}
                                                </select>
                                                <label className="modify_p_text2">Table Group
                                                    <span className="StarClr">*
                                                        {errorMessages.tableGroup && <label className="errorMessage">{errorMessages.tableGroup}</label>}
                                                    </span>
                                                </label>
                                            </div>
                                            <div onClick={openTableGroupModal}>
                                                <i class="fa-solid fa-circle-plus dm_4" ></i>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <input className="modify_im_input mode_category"
                                                    onChange={handleInputChange}
                                                    value={inputValues.tableName}
                                                    name="tableName"
                                                    type="text" required />
                                                <label className="modify_p_text2">Table Name
                                                    <span className="StarClr">*
                                                        {errorMessages.tableName && <label className="errorMessage">{errorMessages.tableName}</label>}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <input className="modify_im_input mode_category"
                                                    onChange={handleInputChange}
                                                    value={inputValues.tableNumber}
                                                    name="tableNumber"
                                                    type="text" required />
                                                <label className="modify_p_text2">Table Number
                                                    <span className="StarClr">*
                                                        {errorMessages.tableNumber && <label className="errorMessage">{errorMessages.tableNumber}</label>}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <input className="modify_im_input mode_category"
                                                    onChange={handleInputChange}
                                                    value={inputValues.tablePriority}
                                                    name="tablePriority"
                                                    type="number" required />
                                                <label className="modify_p_text2">Priority</label>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                <input className="modify_im_input mode_category"
                                                    onChange={handleInputChange}
                                                    value={inputValues.tableCapacity}
                                                    name="tableCapacity"
                                                    type="number" required />
                                                <label className="modify_p_text2">Capacity</label>
                                            </div>
                                        </div>
                                        <div className="input-box-Im ">
                                            <div className="input-box-m2">
                                                    <select className="modify_im_input mode_limit mode_category" onChange={(e) => handleInputChange(e)} type="text" 
                                                    required value={inputValues.tableStatus} name="tableStatus">
                                                        <option value=""></option>
                                                        <option value={"Free"}>Free</option>
                                                        <option value={"Busy"}>Busy</option>
                                                   </select>
                                                <label className="modify_p_text2">Table Status</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBoxMaster
                tableGroupModal={tableGroupModal}
                closeTableGroupModal={closeTableGroupModal} />

            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Table is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Table is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}


export default TableMaster;
