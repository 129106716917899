// import { useState } from "react";
import { useContext, useEffect, useState } from "react";
import PageTabs from "../../Components/PageTabs";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import { useNavigate } from "react-router-dom";
import DataTable from "../../Master/TableMaster/DataTable";
import AddVoucherApi from "../../../API/APIServices/AddVoucher";
import AddVoucher from "./AddVoucher";
import TableButton from "../../Props/TableButton";
function AddVoucherList() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    // input5,
  } = useContext(MyContext);

  const navigate = useNavigate();
  const [mainData, setMainData] = useState([]);
  // console.log(mainData);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [controlType, setControlType] = useState(19);

  const changetablevalue = (e) => {
    const value = e.target.value;
    console.log(value);
    setControlType(value);
    fetchData(value);
  };
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  // console.log(controlType);

  useEffect(() => {
    setControlType(controlType);
  }, [controlType]);

  const toggleSlider = (value) => {
    setSliderOpen(value);
  };

  const fetchData = async (controlTypevalue) => {
    // setLoading(true);
    try {
      const val = await AddVoucherApi.GetVouchers(controlTypevalue);
      // console.log(val);
      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          mastTypeName: item.mastTypeName,
          vouchSeriesName: item.vouchSeriesName,
          vouchDate: formatDate(item.vouchDate),
          refBillRemark:
            item.refBillRemark === null ? "-----" : item.refBillRemark,
          //   availableTo: item.availableTo === null ? "-----" : item.availableTo,
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => Edit(item.id, controlTypevalue)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteAccount(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setMainData(newData);
      setLoading(false);
      // console.log(val);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(controlType);
    fetchData(controlType);
  }, []);

  const Edit = async (id, controlTypevalue) => {
    const data = await AddVoucherApi.GetVouchersById(id, controlTypevalue);
    setEditData(data);
    toggleSlider(true);
  };

  const [getdeleteId, setDeleteId] = useState(null);
  const DeleteAccount = (id) => {
    // console.log("delete", id);
    setAlertBoxDelete(true);
    setDeleteId(id);
  };

  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await AddVoucherApi.DeleteVouchers(getdeleteId, controlType);
      if (Boolean(val) === true) {
        fetchData();
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const [data, setData] = useState({});
  const [editData, setEditData] = useState("");

  const columns = [
    { Header: "", accessor: "input" },
    { Header: "SNo", accessor: "sno" },
    { Header: "Master Type Name", accessor: "mastTypeName" },
    { Header: "Vouch Series Name", accessor: "vouchSeriesName" },
    { Header: "Vouch Date", accessor: "vouchDate" },
    { Header: "Ref Bill Remark", accessor: "refBillRemark" },
    // { Header: "Phone", accessor: "phone" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    // { Header: "", accessor: "" },
    {
      Header: (
        // <input type="text" placeholder="Name" ref={input1} />
        <select onChange={(e) => changetablevalue(e)} value={controlType}>
          <option value="19">Payment</option>
          <option value="20">Receipt</option>
          <option value="21">Journal</option>
          <option value="22">Contra</option>
          <option value="23">Debit Note</option>
          <option value="24">Credit Note</option>
        </select>
      ),
      accessor: "Name",
    },
    {
      Header: <input type="text" placeholder="Name" ref={input1} />,
      accessor: "",
    },
    {
      Header: <input type="text" placeholder="Print Name" ref={input2} />,
      accessor: "printName",
    },
    {
      Header: <input type="text" placeholder="Available From" ref={input3} />,
      accessor: "availableFrom",
    },
    {
      Header: <input type="text" placeholder="Available To" ref={input4} />,
      accessor: "availableTo",
    },
    // {
    //   Header: <input type="text" placeholder="Phone" ref={input5} />,
    //   accessor: "phone",
    // },
  ];

  return (
    <div className="">
      <PageTabs
        locationTab={"/AddVoucher"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Add Voucher List</h2>
          <TableButton
            PageCreate={"/AddVoucher"}
            filterData={filterData}
            columns={columns}
            data={mainData}
            PageName="Masters"
          />
        </div>

        <DataTable columns={columns} data={mainData} filterData={filterData} />

        <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
          <div style={{ padding: "15px" }}>
            {/* <AddVoucher
            data={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            // fetchData={fetchData}
          /> */}
            <AddVoucher
              data={editData}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
            />
          </div>
        </div>

        {alertBoxDelete && (
          <AlertBox
            HeadingTop="DELETE"
            Heading="Are You sure you want to delete this"
            OpenAlertBox={OpenAlertBoxDelete}
            onYesClick={DeleteAccountConfirm}
          />
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
}

export default AddVoucherList;
