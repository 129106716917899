import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//import AccountGroupApi from './AccountGroup';
//import StateInfoApi from './StateInfo';
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ShopBanner = {
  // ****************************** Create Image Banner ******************************************************** */
  CreateAddImageBannerName: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ShopBanner/CreateAddImageBanner`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          data,
        },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ****************************** Update Image Banner Name **************************************************** */
  UpdateImageBannerName: async (id, data) => {
    console.log(data);

    // Now you can safely access d2Id and d1Id properties
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ShopBanner/UpdateImageBannerName?id=${id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  //**************************************************Get All Image Banner *********************************** */
  GetAllImageBannerName: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/GetAllImageBannerName`;
        const response = await axios.post(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  //**************************************************Get Image Banner By Id*********************************** */
  GetImageBannerNameById: async (id) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      console.log(id);
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/GetImageBannerNameById?BannerId=${id}`;
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  //**************************************************Delete Image Banner By Id*********************************** */
  DeleteImageBannerNameById: async (id) => {
    debugger;
    console.log(id);
    try {
      const apiUrl = `${WebApiUrl}/api/ShopBanner/DeleteImageBannerNameById?BannerId=${id}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ****************************** Create Shop Banner ****************************************************** */
  CreateShopBanner: async (data) => {
    console.log(data);

    try {
      // debugger;
      const apiUrl = `${WebApiUrl}/api/ShopBanner/CreateShopBanner`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          data,
        },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      alert(error);
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  //**************************************************Get All Shop Banner *********************************** */
  GetShopBanners: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/GetShopBanners`;
        const response = await axios.post(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  //**************************************************Get Shop Banner By Id*********************************** */
  GetShopBannerById: async (id) => {
    console.log(id);
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/GetShopBannerById?BannerId=${id}`;
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  //**************************************************Delete Shop Banner By Id*********************************** */
  DeleteShopBannerById: async (id) => {
    console.log(id);
    try {
      const apiUrl = `${WebApiUrl}/api/ShopBanner/DeleteShopBannerById?BannerId=${id}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ****************************** Update Shop Banner Name ******************************************************* */
  UpdateShopBanner: async (id, data) => {
    console.log(data);

    // Now you can safely access d2Id and d1Id properties
    try {
      debugger;
      const apiUrl = `${WebApiUrl}/api/ShopBanner/UpdateShopBanner?id=${id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ****************************** Update Shop Banner Image ******************************************************* */
  UpdateShopBannerImage: async (data) => {
    console.log(data);
    data.forEach((value, key) => {
      if (value instanceof File) {
        console.log(
          `${key}: ${value.name}, ${value.size} bytes, ${value.type}`
        );
      } else {
        console.log(`${key}: ${value}`);
      }
    });
    try {
      const apiUrl = `${WebApiUrl}/api/ShopBanner/UpdateShopBannerImage`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "multipart/form-data", // Set Content-Type for FormData
        },
      });
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        console.error("An error occurred. Please try again.");
      }
    }
  },
  // ****************************** Get Shop Banner Image Detail ******************************************************* */
  GetShopBannerImageDetail: async (BranchId, ShopBannerId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/ShopBannerImageDetail?BranchId=${BranchId}&ShopBannerId=${ShopBannerId}`;
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ****************************** Get Shop Banner Image By Id ******************************************************* */
  GetShopBannerImage: async (ImgStoreId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/ShopBannerImage?ImgStoreId=${ImgStoreId}`;
        const response = await axios.get(apiUrl, {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "image/webp; charset=UTF-8",
          },
        });
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ****************************** Create Website Design Page ******************************************************* */
  CreateWebsiteDesignPage: async (data) => {
    console.log(data);
    // Now you can safely access d2Id and d1Id properties
    try {
      const apiUrl = `${WebApiUrl}/api/ShopBanner/CreateWebsiteDesignPage`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          data,
        },
        {
          withCredentials: true,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  //**************************************************Get All Get All Website Design Page*********************************** */
  GetAllWebsiteDesignPage: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ShopBanner/GetAllWebsiteDesignPage`;
        const response = await axios.post(apiUrl, {
          withCredentials: true,
          credentials: "include",
        });
        console.log("Response:", response.data);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
};

export default ShopBanner;
