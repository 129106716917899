import React, { useState, useRef } from 'react';
import MasterReportDataTable from '../../Master/TableMaster/MasterReportDataTable';
import TableButton from '../../Props/TableButton';

const ReportDemo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputValues, setInputValues] = useState({
        searchByDate: false,
        searchAnyText: false,
        searchByColumn: false,
        orderBy: false,
        printButton: false,
        excelButton: false
    });

    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(prev => !prev);

    const handleCheckboxChangeDrop = (field) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [field]: !prevValues[field]
        }));
    };

    const handleOptionClick = (field) => {
        handleCheckboxChangeDrop(field);
        // Optionally close dropdown after selection
        setIsOpen(false);
    };

    const getVisibleColumns = () => {
        return [
            { Header: <input type="checkbox" />, accessor: "input" },
            { Header: "SNO", accessor: "sno" },
            inputValues.searchByDate && { Header: "SEARCH BY DATE", accessor: "searchByDate" },
            inputValues.searchAnyText && { Header: "SEARCH BY ANY TEXT", accessor: "searchAnyText" },
            inputValues.searchByColumn && { Header: "SEARCH BY COLUMN", accessor: "searchByColumn" },
            inputValues.orderBy && { Header: "ORDER BY", accessor: "orderBy" },
            { Header: "ACTION", accessor: "action" }
        ].filter(column => column);
    };

    const columns = getVisibleColumns();

    // Sample data
    const data1 = []; // Replace with your actual data
    const selectedData = []; // Replace with your actual selected data

    return (
        <div className="nav-1">
            <div className="panel-heading">
                <h2 className="panel-title">Master List Report</h2>
                <TableButton PageCreate="/MasterReport"  columns={columns} data={data1} selectedData={selectedData} />
            </div>
            <div className="stock-im-dep1">
                <div className="input-box-Imo1 input-height2">
                    <div className="input-box-mok1" ref={dropdownRef}>
                        <div className="modify_im_input mode_category1" onClick={toggleDropdown}>
                            <label className="Campaigns-lbl">Search By Column</label>
                        </div>
                        {isOpen && (
                            <div className="dropdown-list-select">
                                {Object.keys(inputValues).map(key => (
                                    <div key={key} className="dropdown-option">
                                        <input
                                            type="checkbox"
                                            checked={inputValues[key]}
                                            onChange={() => handleCheckboxChangeDrop(key)}
                                        />
                                        <span onClick={() => handleOptionClick(key)}>
                                            {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <MasterReportDataTable  columns={columns} data={data1} />
        </div>
    );
};

export default ReportDemo;
