import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
  WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const VoucherConfigurationAndOtherSettingController = {

  GetVoucherConfigurationAndOtherSettingController: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/VoucherConfigurationAndOtherSettingController/GetAllVoucherConfigAndOtherSett`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [
            {
              fieldName: "string",
              operatorName: "string",
              compareValue: "string",
            },
          ],
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          masterType: 262,
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  CreateVoucherConfigurationAndOtherSettingController: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/VoucherConfigurationAndOtherSettingController/Create`;
      const response = await axios.post(
        apiUrl,
        data,
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },


 
};

export default VoucherConfigurationAndOtherSettingController;
