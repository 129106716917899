import axios from "../axiosConfig";
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const BarcodeAndSerial = {
  insertBarcodeAndSerial: async (data) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial`;
      console.log(apiUrl);
      console.log(data);
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getBarcodeAndSerial: async (StructureCategory) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial/GetBarcodeAndSerial?StructureCategory=${StructureCategory}`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );

      console.log("Response:", response);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get bill series Filtered ***************************
  getBarcodeAndSerialFiltered: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial/GetBarcodeAndSerials`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Delete ****************************
  deleteBarcodeAndSerial: async (BarcodeAndSerialId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial?BarcodeAndSerialId=${BarcodeAndSerialId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get By Id ****************************
  getBarcodeAndSerialId: async (id, structureCategory) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial?id=${id}&StructureCategory=${structureCategory}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  SetBarcodeActive: async (id, structureCategory) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial/SetActive?id=${id}&StructureCategory=${structureCategory}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // *************************** Update ***************************

  updateBarcodeAndSerial: async (data) => {
    debugger;
    console.log(data.id);
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial?BarcodeAndSerialId=${data.id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, data, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get bill series by masterType ***************************
  getBarcodeAndSerialByMasterType: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/BarcodeAndSerial/GetBarcodeAndSerialsByMasterType`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [
            {
              fieldName: "isDefault",
              operatorName: "Equal",
              compareValue: true,
            },
          ],
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          masterType: 29,
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data);
      //debugger;
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
};

export default BarcodeAndSerial;
