import React, { useEffect, useState } from 'react';
import moment from "moment";
import BillSeriesMaster from '../../API/APIServices/BillSeriesMaster';
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import CurrentDateAndTime from '../../API/APIServices/CurrentDateAndTime';
import Payment from '../../API/APIServices/Payment';
import axios from 'axios';
import Order from '../../API/APIServices/Order';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const PaymantModeModel = (props) => {
  const { closePaymentModal, PaymentModalData, ord1Id, orderList, orderListHomeDelivery, orderListPickUp, serviceTypeId, isFromPOS } = props

  // console.log(PaymentModalData);

  const [paymentTransactionData, setPaymentTransactionData] = useState([]);

  useEffect(() => {
    setPaymentTransactionData(PaymentModalData)
  }, [PaymentModalData])

  const [orders, setOrders] = useState([]);
  const [totalAmt, setTotalAmt] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (paymentTransactionData && paymentTransactionData.length > 0) {
        const total = paymentTransactionData.reduce((total, item) => total + item.paymentAmount, 0)
        setTotalAmt(total)
      }
      else {
        const total = PaymentModalData.reduce((total, item) => total + item.paymentAmount, 0)
        setTotalAmt(total)
      }
      // let data;
      // if (serviceTypeId == 751)
      //   data = orderList.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // if (serviceTypeId == 752)
      //   data = orderListHomeDelivery.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // if (serviceTypeId == 753)
      //   data = orderListPickUp.filter(item => item.ord1Id == PaymentModalData[0].ord1Id);
      // setOrderData(data);
    } catch (error) {
      console.error(error);
    }
  }, [PaymentModalData, paymentTransactionData])

  const [paymentModeData, setPaymentModeData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filteredData = [
          {
            fieldName: "isDefault",
            operatorName: "Equal",
            compareValue: true
          },
          {
            fieldName: "masterType",
            operatorName: "Equal",
            compareValue: 29
          }
        ]
        const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
        console.log("BillData", data);
        const order = await Order.getOrderListByOrd1Id(ord1Id);
        setOrders(order.item1[0]);
        if (data.length > 0) {
          const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
          setPaymentModeData(paymentMode);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const [inputValues, setInputValues] = useState({
    paymentMode: 0,
    transactionId: "",
    amount: 0,
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  const clearPayment = async () => {
    if (window.confirm("Are you sure you want to clear payment?")) {
      try {
        const data = await Order.deletePaymentModeWithOrders(ord1Id);
        setPaymentTransactionData([]);
        setTotalAmt(0);
        const filterData = [
          {
            "operationType": 0,
            "path": "paymentAmount",
            "op": "Add",
            "from": "string",
            "value": 0
          },
          {
            "operationType": 0,
            "path": "paymentStatus",
            "op": "Add",
            "from": "string",
            "value": "Pending"
          }
        ]
        await Order.updateOrderPartially(filterData, ord1Id);
        alert("Payment cleared successfully:");
      } catch (error) {
        console.error("Error clearing payment:", error);
      }
    }
  }
  const onlinePayment = async () => {
    setLoading(true);
    const orderData = orders //tableOrder.find(item => item.ord1Id === ord1Id);

    if (!orderData) {
      console.error('Order not found');
      return;
    }

    let orderNo = orderData.orderNo;

    let totalAmt = orderData.confirmTotalAmt;
    if (orderData.paymentType == 2) {
      totalAmt = orderData.grandTotal;
    }
    const balAmt = Number(totalAmt - (orderData.o1CancelledAmt + orderData.paymentAmount));

    if (balAmt && balAmt != 0) {
      // ******************* Payment Gateway ********************
      const filteredData = [{
        fieldName: "ord1Id",
        operatorName: "Equal",
        compareValue: orderData.ord1Id,
      }]

      const dateTime = await CurrentDateAndTime.getCurrentDateTime();
      // const dateTime = date.toISOString();

      const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

      let paymentData;

      if (previousPaymentDetails && previousPaymentDetails.length > 0) {
        const spiltData = Number((previousPaymentDetails[0].orderNo).split("_")[1]) || 0;

        orderNo = `${orderData.orderNo}_${spiltData + 1}`

        paymentData = {
          ord1Id: orderData.ord1Id,
          orderNo: orderNo,
          orderRefNo: orderData.orderSeriesId,
          date: dateTime,
          amount: balAmt,
          apiId: 0,
          apiName: "",
          pgOrderId: "",
          pgOrderStatus: "",
          pgTrackingId: "",
          pgBankRefNo: "",
          pgFailureMessage: "",
          pgPaymentMode: "",
          pgCardName: "",
          pgStatusCode: "",
          pgStatusMessage: "",
          transactionDetail: "",
          remarks: "",
        }
      }
      else {
        orderNo = orderData.orderNo;
        paymentData = {
          ord1Id: orderData.ord1Id,
          orderNo: orderNo,
          orderRefNo: orderData.orderSeriesId,
          date: dateTime,
          amount: balAmt,
          apiId: 0,
          apiName: "",
          pgOrderId: "",
          pgOrderStatus: "",
          pgTrackingId: "",
          pgBankRefNo: "",
          pgFailureMessage: "",
          pgPaymentMode: "",
          pgCardName: "",
          pgStatusCode: "",
          pgStatusMessage: "",
          transactionDetail: "",
          remarks: "",
        }
      }
      const insertedData = await Payment.insertPayment(paymentData);

      // ***********************************
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

      // const returnUrl = `${fullUrl}/Response`;
      const returnUrl = `https://pay.mothershut.com/Response`;

      const d2AccountId = orderData.accId || orderData.d2AccountId || 0;

      const data = { orderNo, balAmt, returnUrl, d2AccountId };
      const apiUrl = `${WebApiUrl}/api/JusPayPayment/initiateJuspayPayment`;
      try {
        const response = await axios.post(apiUrl, data, { withCredentials: true });

        if (response.data && response.data.payment_links && response.data.payment_links.web) {
          const url = response.data.payment_links.web;
          console.log("orderNo", orderNo);
          window.location.href = url;
          localStorage.setItem("JusPayOrderNo", orderNo);
          localStorage.setItem("Ord1Id", ord1Id);
        } else {
          console.error('Payment links not found in response');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error initiating payment:', error);
        setLoading(false);
      }
    }
    else if (balAmt == 0) {
      alert("Balance Amount is Zero(0)");
    }
    setLoading(false);
  }

  const addPayment = async () => {
    if (inputValues.paymentMode == 0 || inputValues.paymentMode == "") {
      alert("Please Select Payment Mode");
      return 0;
    }
    else if (inputValues.amount == 0 || inputValues.amount == "") {
      alert("Please Enter Amount");
      return 0;
    }
    // if (Number((orders.grandTotal || 0) - ((orders.o1CancelledAmt || 0) + (orders.paymentAmount || 0))) < Number(totalAmt + Number(inputValues.amount))) {
    if (Number((orders.grandTotal || 0) - (orders.o1CancelledAmt || 0)) < Number(totalAmt + Number(inputValues.amount))) {
      alert("Amount cannot exceed the actual amount");
      return 0;
    }
    const filterData = [{
      ord1Id: ord1Id,
      paymentModeId: inputValues.paymentMode,
      paymentAmount: inputValues.amount,
      remarks: "",
      transId: inputValues.transactionId,
      payUId: 0,
      bankRefNo: "",
      status: "",
      paymentTypeMethod: ""
    }]
    const data = await Order.createPaymentModeWithOrder(filterData);
    const paymentData = await Order.GetPaymentModeWithOrders(ord1Id);
    setPaymentTransactionData(paymentData);

    const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
    // const formattedDate = new Date(isoString);

    const storedArrayString = localStorage.getItem('loginAccountUserData');
    const loginUser = JSON.parse(storedArrayString);

    let updateData = [
      {
        operationType: 0,
        path: "paymentAmount",
        op: "Add",
        from: "string",
        value: Number(totalAmt + Number(inputValues.amount))
      },]
    debugger;
    if (Number((orders.grandTotal || 0) - (orders.o1CancelledAmt || 0)) == Number(totalAmt + Number(inputValues.amount))) {
      updateData.push(
        {
          operationType: 0,
          path: "paymentStatus",
          op: "Add",
          from: "string",
          value: "Done"
        },
        {
          operationType: 0,
          path: "PaymentDoneOn",
          op: "Add",
          from: "string",
          value: formattedDate
        },
        {
          operationType: 0,
          path: "PaymentDoneBy",
          op: "Add",
          from: "string",
          value: loginUser.userId
        })
    }
    await Order.updateOrderPartially(updateData, ord1Id);
    alert("Updated Successfully");
  }

  return (
    <div className="modal-back">
      <div className="info-modal" style={{ maxHeight: "750px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1px 10px",
          }}
        >
          <label className="fw-600" style={{ fontSize: "18px" }}>
            Payment Details
          </label>
          <i
            class="fa-solid fa-xmark xmark icon_ov1"
            onClick={closePaymentModal}
          ></i>
        </div>
        <hr />
        <div style={{ overflowX: 'auto' }}>
          <table className="tableStyle">
            <thead className="thead_scroll_orderlist table_headerSticky">
              <tr>
                <th className="thStyle" style={{}} >S.No</th>
                <th className="thStyle">Order No</th>
                <th className="thStyle">Trans Id</th>
                <th className="thStyle">Payu Id</th>
                <th className="thStyle">Bank Ref No</th>
                <th className="thStyle">Amount</th>
                <th className="thStyle">Pay Mode</th>
                <th className="thStyle">Payment Time</th>
                <th className="thStyle">Payment Received By</th>
                <th className="thStyle">Status</th>
              </tr>
            </thead>
            <tbody>
              {paymentTransactionData && paymentTransactionData.map((data, index) => (
                console.log(paymentTransactionData),
                <tr key={index}>
                  <td style={{ textAlign: "center" }} className="tdStyle">{index + 1}</td>
                  {/* <td style={{ textAlign: "center" }} className="tdStyle">{orderData && orderData[0] != undefined && orderData[0] != null ? orderData[0].orderNo : 0}</td> */}
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.orderNo}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.transId}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.payUId}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.bankRefNo}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">₹&nbsp;{data.paymentAmount}</td> {/* Assuming 'amount' is the key for the value 315 */}
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.paymentMode}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{moment(data.paymentDoneOn).format('DD-MM-YYYY hh:mm A')}</td>{/*{moment.utc(data.paymentDoneOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}*/}
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.paymentDoneBy}</td>
                  <td style={{ textAlign: "center" }} className="tdStyle">{data.status}</td>
                </tr>
              ))}
              <tr>
                <td style={{ textAlign: "end" }} colSpan={5} className="tdStyle">Total Amt:</td>
                <td style={{ textAlign: "center" }} className="tdStyle">₹&nbsp;{totalAmt}</td> {/* Assuming 'amount' is the key for the value 315 */}
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
                <td style={{ textAlign: "center" }} className="tdStyle"></td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* ******************************* Payment Adjust ************************ */}
        {isFromPOS && (
          <div className='mt-3'>
            <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "5px" }}>
              <div className='space-evenly'>
                <div className="input-box-m11">
                  <select className="modify_im_input mode_category" type="text"
                    value={inputValues.paymentMode} name="paymentMode" onChange={handleInputChange} required>
                    <option Classname="form-control s-1" value="">Select Payment Mode</option>
                    {(() => {
                      if (paymentModeData !== null && Array.isArray(paymentModeData)) {
                        return paymentModeData.map((data) => (
                          <option key={data.id} value={data.accountId}>
                            {data.accountName}
                          </option>
                        ))
                      }
                    })()}
                  </select>
                  <label className="ItemMasterLabelFix" >Payment Mode</label>
                </div>
                <div className="input-box-m11">
                  <input className="modify_im_input mode_category" type="text"
                    value={inputValues.transactionId} name="transactionId" onChange={handleInputChange} required />
                  <label className="ItemMasterLabelFix">Transaction Id</label>
                </div>
                <div className="input-box-m11">
                  <input className="modify_im_input mode_category" type="text"
                    value={inputValues.amount} name="amount" onChange={handleInputChange} required />
                  <label className="ItemMasterLabelFix">Amount</label>
                </div>
              </div>
              <div className='flex-center mt-2'>
                <button className="btn" style={{ backgroundColor: "slategray", color: "white" }} onClick={clearPayment}>
                  Clear Payment
                </button>
                <button className="btn" style={{ backgroundColor: "slategray", color: "white" }} onClick={onlinePayment}>
                  Online Payment
                </button>
                <button className="btn" style={{ backgroundColor: "slategray", color: "white" }} onClick={addPayment}>
                  Add/Adjust Payment
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            border: "2px solid grey",
            padding: "25px",
          }}
        > */}
        {/* <button
            className="btn"
            style={{
              fontWeight: "600",
              color: "white",
              backgroundColor: "#06aadf",
            }}
            onClick={props.handlePayment}
          >
            Online Payment Verify
          </button> */}
        {/* </div> */}
        <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
          <button className="redBtn" style={{ width: "17%", backgroundColor: "#bb0828" }} onClick={closePaymentModal}>Close</button>
        </div>
      </div>
    </div>
  )
}

export default PaymantModeModel