
import DateTime from "./../Components/DateTime";
import React, { useState, useRef, useContext, useEffect } from "react";
import PageTabs from "./../Components/PageTabs";
import MainButton from "./../Props/MainButton";
import AlertBox from "./../Props/AlertBox";
import MyContext from "./../MyContext";
import Loader from "./../Props/Loader";
import FormNameInfoAPI from "./../../API/APIServices/FormNameInfo"
import DataTable from "./TableMaster/DataTable";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import ModuleName from "../../API/APIServices/ModuleName";
import ModuleNamePage from "./ModuleName";

function AddUpdateFormName(props) {
    /***********title***************/
    useState(() => {
        document.title = 'Add/Update Form Name';
    }, []);

    const navigate = useNavigate();
    // const [editData, setEditData] = useState([]);

    const [loading, setLoading] = useState(false);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit, OpenAlertBoxDelete, setAlertBoxDelete, alertBoxDelete, input2, input1, input3, input4 } = useContext(MyContext);
    const [errorMessages, setErrorMessages] = useState({ name: '' });

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [inputValues, setInputValues] = useState({
        moduleName: '',
        formName: '',
        formDescription: '',
        formLink: '',
        moduleDescription: '',
        moduleId: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);
        if (name == 'moduleName') {
            const moduleValue = moduleNames.find((item) => item.id == value);
            setInputValues((prevValues) => ({
                ...prevValues,
                moduleId: value,
                moduleName: moduleValue.moduleName
            }));
            setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
            return 0;
        }
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }
        return true;
    };
    const fieldNames = ['moduleName'];

    const [edit, setEdit] = useState(false);

    const SaveBtn = async () => {
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            if (Boolean(edit) === true) {
                const isSuccess = await FormNameInfoAPI.updateFormNameInfo(inputValues);
                if (isSuccess.data.isSuccess) {
                    setLoading(false);
                    setEdit(false);
                    OpenAlertBox();
                    fetchData()
                } else if (isSuccess.data.errorMessages === "Form Name Info Already Exists!") {
                    alert("Form Name Info Already Exists!")
                    setLoading(false);
                } else {
                    alert("Error inserting data");
                    setLoading(false);
                }
            }
            else {
                setLoading(true);
                const isSuccess = await FormNameInfoAPI.insertFormNameInfo(inputValues);
                if (isSuccess.data.isSuccess) {
                    setLoading(false);
                    fetchData()
                    OpenAlertBox();
                } else if (isSuccess.data.errorMessages === "Form Name Info Already Exists!") {
                    alert("Form Name Info Already Exists!")
                    setLoading(false);
                } else {
                    alert("Error inserting data");
                    setLoading(false);
                }
            }
            setInputValues({
                formName: "",
                formDescription: "",
                moduleName: "",
                moduleDescription: "",
                formLink: "",
                moduleId: 0
            });
        }
    }

    const editFormName = async (id) => {
        setEdit(true)
        const data = await FormNameInfoAPI.getFormNameInfoById(id);
        console.log(data);
        setInputValues(data);
        // fetchData();
    }

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteFormName = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    }
    const DeleteFormNameConfirm = async () => {
        setLoading(true);
        try {
            const val = await FormNameInfoAPI.deleteFormNameInfo(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                alert("Error Deleting Data");
            }
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    // *********************Data Table***************************
    const [data1, setData1] = useState([]);
    const columns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "Module Name", accessor: "moduleName" },
        { Header: "Form Name", accessor: "formName" },
        { Header: "Description", accessor: "formDescription" },
        { Header: "Form Link", accessor: "formLink" },
        { Header: "ACTION", accessor: "action" },
    ];
    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        { Header: <input type="text" placeholder="Module Name" ref={input1} />, accessor: "moduleName" },
        { Header: <input type="text" placeholder="Form Name" ref={input2} />, accessor: "formName" },
        { Header: <input type="text" placeholder="Form Description" ref={input3} />, accessor: "formDescription" },
        { Header: <input type="text" placeholder="Form Link" ref={input4} />, accessor: "formLink" },
    ];

    const fetchData = async () => {
        // setLoading(true);
        try {
            const val = await FormNameInfoAPI.getFormNameInfo();
            console.log(val.length);

            const newData = val?.slice().reverse().map((item, i) => {

                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    formName: item.formName,
                    formDescription: item.formDescription,
                    moduleName: item.moduleName,
                    moduleDescription: item.moduleDescription,
                    formLink: item.formLink,
                    action: (
                        <div className="tablelist-action-box">
                            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editFormName(item.id)}></i>
                            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteFormName(item.id)}></i>
                        </div>
                    ),
                };
            });
            setData1(newData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [moduleNames, setModuleNames] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ModuleName.getModuleNames();
                setModuleNames(data);
                setLoading(false);
            } catch (error) {
                setLoading(false)
                console.log(error);
            }
        }
        fetchData();
    }, []);

    const [moduleNamePage, setModuleNamePage] = useState(false);

    const showModuleForm = () => {
        setModuleNamePage(true);
    }

    const closeModuleForm = () => {
        setModuleNamePage(false);
    }

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Add/Update Form Name</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} SaveButton={SaveBtn} modelOpen={props.modelOpen} PageName="Masters" />
            </div>
            <DateTime />
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left11 patient-padding patient-table-width" >
                            <div className="configure_radius_add_update">
                                {/* <div className="company_info-text-im td-1">Search By Cancellation Detail</div> */}
                                <div className="company_control">
                                    <div className="stock-im-dep1">
                                        <div className="input-box-Imo1 input-height2">
                                            <div className="input-box-m11 dm_3" style={{ width: "100%" }}>
                                                <select className="modify_im_input mode_category" ref={input1Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input2Ref)} name="moduleName" value={inputValues.moduleId}
                                                    onChange={handleInputChange} type="text" required>
                                                    <option>--Select--</option>
                                                    {/* <option value="App">App</option>
                                                    <option value="Clinic">Clinic</option>
                                                    <option value="Company">Company</option>
                                                    <option value="e-Commerce">e-Commerce</option>
                                                    <option value="Extra">Extra</option>
                                                    <option value="Lens Transaction">Lens Transaction</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Mshop">Mshop</option>
                                                    <option value="Pay Remaining">Pay Remaining</option>
                                                    <option value="Report">Report</option>
                                                    <option value="RX Transaction">RX Transaction</option>
                                                    <option value="Transaction">Transaction</option>
                                                    <option value="Utilities">Utilities</option> */}
                                                    {moduleNames?.length > 0 &&
                                                        moduleNames.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.moduleName}
                                                            </option>
                                                        ))}

                                                </select>
                                                <label class="ItemMasterLabelFix">Module Name <span className="StarClr">*
                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                </span></label>
                                                <div onClick={showModuleForm}>
                                                    <i className="fa-solid fa-circle-plus dm_4" ></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-box-Imo1 input-height2">
                                            <div className="input-box-mok1">
                                                <input className="modify_im_input mode_category1" ref={input2Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input3Ref)} name="formName" value={inputValues.formName} onChange={handleInputChange} type="text" required />
                                                <label class="modify_p_text2">Form Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stock-im-dep1">
                                        <div className="input-box-Imo1 input-height2">
                                            <div className="input-box-mok1">
                                                <input className="modify_im_input mode_category1" ref={input3Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" name="moduleDescription" value={inputValues.moduleDescription} onChange={handleInputChange} required />
                                                <label class="modify_p_text2"> Module Description</label>
                                            </div>
                                        </div>

                                        <div className="input-box-Imo1 input-height2">
                                            <div className="input-box-mok1">
                                                <input className="modify_im_input mode_category1" ref={input4Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="text" name="formDescription" value={inputValues.formDescription} onChange={handleInputChange} required />
                                                <label class="modify_p_text2">Form Description</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stock-im-dep1">
                                        <div className="input-box-Imo1 input-height2">
                                            <div className="input-box-mok1">
                                                <input className="modify_im_input mode_category1" ref={input5Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input1Ref)} type="text" name="formLink" value={inputValues.formLink} onChange={handleInputChange} required />
                                                <label class="modify_p_text2"> Form Link</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div className="input-box-Imo1 input-height2">
                                    <div className="input-box-mok1 search-add-width">
                                        <input className="modify_im_input mode_category1" type="text" required />
                                        <label style={{ left: "35px" }} class="modify_p_text2">Search</label>
                                    </div>
                                </div>
                                {/* <div className="company_info-text-im td-1">Search By Cancellation Detail</div> */}
                                <div className="col-sm-12">
                                    <div className="table-resposive" >
                                        <div className="nav-1" >
                                            <DataTable filterData={filterData} columns={columns} data={data1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {moduleNamePage && (
                <div className="modal-back">
                    <div className="modal-content" style={{ maxWidth: "100%", height: "80%", marginTop: "5px", overflow: "scroll" }}>
                        <div className="modal-title">
                            <h3 className="modal-h">Module Name</h3>
                            <span className="close" onClick={closeModuleForm}>
                                &times;
                            </span>
                        </div>
                        <div className="modal-content-im">
                            <ModuleNamePage />
                        </div>
                    </div>
                </div>
            )}
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this?"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteFormNameConfirm}
                />
            )}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Data Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Data Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default AddUpdateFormName;




