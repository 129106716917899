import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useEffect, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import ModelAddCollection from "./ModelAddCollection";
import ModelAddProducts from "./ModelAddProducts";
import ItemMaster from "../../../API/APIServices/ItemMaster";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import CouponMasterApi from "../../../API/APIServices/CouponMaster";
import Account from "../../../API/APIServices/Account";
import { useNavigate } from "react-router-dom";

// var with 2 for custmrGetsSpecificCatgryList
// var without 2 for custmrBuysOrSpendsCatgryList

function CouponMaster(props) {
  /***********title***************/
  useState(() => {
    document.title = "Coupon Master";
  }, []);
  const navigate = useNavigate();
  //************modal bo
  //************/ /

  const [activeTab, setActiveTab] = useState(2);
  const [codeValue, setCodeValue] = useState("");
  const [mode, setMode] = useState("1");
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [showError, setShowError] = useState(false);
  const [showErrorRadio, setShowErrorRadio] = useState(false);
  const [showErrorRadioItem, setShowErrorRadioItem] = useState(false);
  const [selectedOption, setSelectedOption] = useState("1");
  const [selectedOptionMinimum, setSelectedOptionMinimum] = useState("1");
  const [selectedOptionRadio, setSelectedOptionRadio] = useState("");
  const [selectedOptionRadioItem, setSelectedOptionRadioItem] = useState("");

  const [searchVal, setSearchVal] = useState("");
  const [searchVal2, setSearchVal2] = useState("");
  const [searchVal3, setSearchVal3] = useState("");
  const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
  const [debouncedSearchVal2, setDebouncedSearchVal2] = useState("");
  const [debouncedSearchVal3, setDebouncedSearchVal3] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const [selectedOptionCustomer, setSelectedOptionCustomer] = useState("1");
  const [isChecked, setIsChecked] = useState(false);
  const [valueLimit, setValueLimit] = useState("");
  const [showErrorLimit, setShowErrorLimit] = useState(false);
  const [selectedOptionCombined, setSelectedOptionCombined] = useState("");
  const [isDateChecked, setIsDateChecked] = useState(false);

  const [isCheckedDiscount, setIsCheckedDiscount] = useState(true);
  const [isCheckedChannels, setIsCheckedChannels] = useState(false);
  const [isPOSChecked, setIsPOSChecked] = useState(false);

  const [selectedOptionCoupon, setSelectedOptionCoupon] = useState("1");
  const [selectedOptionBuy, setSelectedOptionBuy] = useState("1");
  const [selectedOptionGet, setSelectedOptionGet] = useState("");

  const [selectedOptionQuantity, setSelectedOptionQuantity] = useState("1");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [isCheckedOrder, setIsCheckedOrder] = useState(false);
  const [valueOrd, setValueOrd] = useState(0);
  const [showErrorOrder, setShowErrorOrder] = useState(false);

  const [isCheckedCountries, setIsCheckedCountries] = useState(false);
  const [valueCountries, setValueCountries] = useState(0);
  const [showErrorCountries, setShowErrorCountries] = useState(false);
  const [selectedOptionCountries, setSelectedOptionCountries] = useState("1");

  const [suggestionsCustomer, setSuggestionsCustomer] = useState([]);
  const [showDropdownCustomer, setShowDropdownCustomer] = useState(false);

  const [inputValues, setInputValues] = useState({
    id: 0,
    couponName: "Amount off on total Order value",
    couponNameId: 1,
    couponMethodName: "Discount Coupon Code",
    couponMethodId: 1,
    couponTitle: "",
    couponCode: "",
    discountType: "1",
    discountValue: 0,
    discountApplyFor: "",
    discountCategoryList: [
      // {
      //     masterType: 0,
      //     masterValue: 0
      // }
    ],
    applyDiscountOncePerOrder: true,
    allowPOSProLocation: true,
    minPurchaseReqType: "",
    minPurchaseReqValue: 0,
    customerEligibility: "",
    customerEligibilityList: [
      // {
      //     masterType: 0,
      //     masterValue: 0
      // }
    ],
    maxDiscountUses: "",
    maxDiscountUsesValue: 0,
    couponCombination: "",
    startDate: "",
    startTime: "",
    setEndDate: false,
    endDate: "",
    endTime: "",
    isCustmrBuysMinQtyItm: true,
    custmrBuysMinQtyItm: 0,
    isCustmrSpendsMinPurAmt: true,
    custmrSpendsMinPurAmt: 0,
    custmrBuysOrSpendsFromSpecificCatgry: "",
    custmrBuysOrSpendsCatgryList: [
      // {
      //     masterType: 0,
      //     masterValue: 0
      // }
    ],
    custmrGetsQty: 0,
    custmrGetsFromSpecificCatgry: "",
    custmrGetsSpecificCatgryList: [
      // {
      //     masterType: 0,
      //     masterValue: 0
      // }
    ],
    custmrGetsDiscountedOn: "",
    custmrGetsDiscountedValue: 0,
    setMaxNoOfUsesPerOrder: 0,
    allowFreeShipForAllCountries: true,
    freeShipCountryList: [
      // {
      //     masterType: 0,
      //     masterValue: 0
      // }
    ],
    shippingRates: 0,
    salesChannelList: [
      {
        masterType: 0,
        masterValue: 0,
      },
    ],
    isVisible : true,
    useType : 1,
  });

  useEffect(() => {
    console.log("inputValues", inputValues);
  }, [inputValues]);

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [showDropdown]);

  useEffect(() => {
    if (focusedIndex >= 0 && itemRefs.current[focusedIndex]) {
      itemRefs.current[focusedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [focusedIndex]);

  // useEffect(() => {
  //     console.log("inputValues", inputValues);
  // }, [inputValues]);
  const relevantOptions = [
    selectedOption,
    selectedOptionBuy,
    // selectedOptionGet
  ];
  var listMap = {
    2: "discountCategoryList",
    3: "custmrBuysOrSpendsCatgryList",
    // "3": "custmrGetsSpecificCatgryList"
  };

  // List of countries with IDs
  const countriesList = [
    { id: 1101, name: "India" },
    { id: 1102, name: "United States" },
    { id: 1103, name: "Canada" },
    { id: 1104, name: "Australia" },
    { id: 1105, name: "United Kingdom" },
    { id: 1106, name: "Germany" },
    { id: 1107, name: "France" },
    { id: 1108, name: "Japan" },
    { id: 1109, name: "China" },
  ];
  const [searchCountryVal, setSearchCountryVal] = useState("");
  const [ShowCountryDropdown, setShowCountryDropdown] = useState(false);
  const [CountrySuggestions, setCountrySuggestions] = useState([]);
  const [selectedCountries, setselectedCountries] = useState([]);

  useEffect(() => {
    console.log("selectedCountries", selectedCountries);
  }, [selectedCountries]);

  const handleCountryInputChange = (e) => {
    const value = e.target.value;
    setSearchCountryVal(value);

    if (value.length > 0) {
      const filteredCountries = countriesList.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );

      setCountrySuggestions(filteredCountries);
      setShowCountryDropdown(true);
    } else {
      setCountrySuggestions([]);
      setShowCountryDropdown(false);
    }
  };

  const handleSelectCountry = (country) => {
    setInputValues((prev) => ({
      ...prev,
      freeShipCountryList: [
        {
          masterType: 0,
          masterValue: country.id,
        },
      ],
    }));
    setselectedCountries((prev) => [...prev, country]);
    setSearchCountryVal("");
    setShowCountryDropdown(false);
  };

  const handleRemoveCountry = (itemId) => {
    setselectedCountries((prev) => prev.filter((item) => item.id !== itemId));
    setInputValues((prev) => ({
      ...prev,
      freeShipCountryList: prev.freeShipCountryList.filter(
        (entry) => entry.masterValue !== itemId
      ),
    }));
  };

  useEffect(() => {
    console.log("selectedItems", selectedItems);
  }, [selectedItems]);
  /*******************************************UTILS******************************************************** */
  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer); // Clear any previously set timer
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  const inputRef = useRef(null);
  const handleKeyDown = (event) => {
    //event.preventDefault();
    if (!showDropdown || suggestions.length == 0) return;

    if (event.key == "ArrowDown") {
      // Move focus down
      event.preventDefault();
      setFocusedIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
    } else if (event.key == "ArrowUp") {
      // Move focus up
      event.preventDefault();
      setFocusedIndex((prev) => Math.max(prev - 1, 0));
    } else if (event.key == "Enter") {
      // Select the focused item
      if (focusedIndex >= 0 && focusedIndex < suggestions.length) {
        handleSelectItem(suggestions[focusedIndex]);
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleMouseEnter = (index) => {
    setFocusedIndex(index);
  };

  const handleMouseLeave = () => {
    setFocusedIndex(-1);
  };

  /*******************************************UTILS******************************************************** */
  //**********{Model Collection}*****************//
  const [isModalOpenCollection, setModalOpenCollection] = useState(false);
  // const showModalCollection = () => {
  //     setModalOpenCollection(true);
  // };
  const showModalCollection = () => {
    if (selectedOption) {
      setModalOpenCollection(true);
    } else {
      alert("Please select an option first.");
    }
  };

  const hideModalCollection = () => {
    setModalOpenCollection(false);
  };

  /*****************************************EDIT********************************************************** */

  useEffect(() => {
    debugger;
    if (props.data != null) {
      handleTabClick(1);
      showtabs1();
      setSelectedOptionMinimum("");
      setSelectedOptionRadio("");
      setSelectedOptionRadioItem("");
      setValue1("");
      setValue2("");
      setSelectedOptionCountries("");
      setInputValues({
        id: 0,
        couponName: "",
        couponNameId: "",
        couponMethodName: "",
        couponMethodId: 0,
        couponCode: "",
        couponTitle: "",
        discountType: "",
        discountValue: 0,
        discountApplyFor: "",
        discountCategoryList: [],
        applyDiscountOncePerOrder: false,
        allowPOSProLocation: false,
        minPurchaseReqType: "",
        minPurchaseReqValue: 0,
        customerEligibility: "",
        customerEligibilityList: [],
        maxDiscountUses: false,
        maxDiscountUsesValue: 0,
        couponCombination: "",
        startDate: "",
        startTime: "",
        setEndDate: false,
        endDate: "",
        endTime: "",
        isCustmrBuysMinQtyItm: false,
        custmrBuysMinQtyItm: 0,
        isCustmrSpendsMinPurAmt: false,
        custmrSpendsMinPurAmt: 0,
        custmrBuysOrSpendsFromSpecificCatgry: false,
        custmrBuysOrSpendsCatgryList: [],
        custmrGetsQty: 0,
        custmrGetsFromSpecificCatgry: false,
        custmrGetsSpecificCatgryList: [],
        custmrGetsDiscountedOn: "",
        custmrGetsDiscountedValue: 0,
        setMaxNoOfUsesPerOrder: false,
        allowFreeShipForAllCountries: false,
        freeShipCountryList: [],
        shippingRates: 0,
      });
      setSelectedOptionCustomer("");
      setIsChecked(false);
      setValueLimit(0);
      setSelectedOption("");
      setSelectedOptionCombined("");
      setSelectedOptionCoupon("");
      setSelectedOptionGet(false);
      setSelectedOptionBuy(false);
      setSelectedDiscount("");
      setValueOrd(0);
      setValueCountries([]);
      setIsPOSChecked(false);
      setIsDateChecked(false);
      setselectedCountries([]);

      setSelectedItems([]);
      setSelectedItems2([]);

      if (props.data.couponMethodId == 1) {
        handleTabClick(2);
        showtabs1();
      } else {
        handleTabClick(1);
        showtabs2();
      }

      if (props.data.minPurchaseReqType == "1") {
        setSelectedOptionMinimum(props.data.minPurchaseReqType);
      } else if (props.data.minPurchaseReqType == "2") {
        setSelectedOptionRadio(props.data.minPurchaseReqType);
        setValue1(props.data.minPurchaseReqValue);
      } else {
        setSelectedOptionRadioItem(props.data.minPurchaseReqType);
        setValue2(props.data.minPurchaseReqValue);
      }

      if (props.data.allowFreeShipForAllCountries) {
        setSelectedOptionCountries("1");
      } else {
        setSelectedOptionCountries("2");
      }

      setInputValues({
        id: props.data.id,
        couponName: props.data.couponName,
        couponNameId: props.data.couponNameId,
        couponMethodName: props.data.couponMethod,
        couponMethodId: props.data.couponMethodId,
        couponCode:
          props.data.couponMethod == "Discount Coupon Code"
            ? props.data.couponTitleOrCode
            : "",
        couponTitle:
          props.data.couponMethod ==
          "Automatic discount Applied to Cart to Check"
            ? props.data.couponTitleOrCode
            : "",
        discountType: props.data.discountType,
        discountValue: props.data.discountValue,
        discountApplyFor: props.data.discountApplyFor,
        discountCategoryList: props.data.discountCategoryList,
        applyDiscountOncePerOrder: props.data.applyDiscountOncePerOrder,
        allowPOSProLocation: props.data.allowPOSProLocation,
        minPurchaseReqType: props.data.minPurchaseReqType,
        minPurchaseReqValue: props.data.minPurchaseReqValue,
        customerEligibility: props.data.customerEligibility,
        customerEligibilityList: props.data.customerEligibilityList,
        maxDiscountUses: props.data.maxDiscountUses,
        maxDiscountUsesValue: props.data.maxDiscountUsesValue,
        couponCombination: props.data.couponCombination,
        startDate: props.data.startDate
          ? props.data.startDate.split("T")[0]
          : "",
        startTime: props.data.startTime,
        setEndDate: props.data.setEndDate,
        endDate: props.data.endDate ? props.data.endDate.split("T")[0] : "",
        endTime: props.data.endTime,
        isCustmrBuysMinQtyItm: props.data.isCustmrBuysMinQtyItm,
        custmrBuysMinQtyItm: props.data.custmrBuysMinQtyItm,
        isCustmrSpendsMinPurAmt: props.data.isCustmrSpendsMinPurAmt,
        custmrSpendsMinPurAmt: props.data.custmrSpendsMinPurAmt,
        custmrBuysOrSpendsFromSpecificCatgry:
          props.data.custmrBuysOrSpendsFromSpecificCatgry,
        custmrBuysOrSpendsCatgryList: props.data.custmrBuysOrSpendsCatgryList,
        custmrGetsQty: props.data.custmrGetsQty,
        custmrGetsFromSpecificCatgry: props.data.custmrGetsFromSpecificCatgry,
        custmrGetsSpecificCatgryList: props.data.custmrGetsSpecificCatgryList,
        custmrGetsDiscountedOn: props.data.custmrGetsDiscountedOn,
        custmrGetsDiscountedValue: props.data.custmrGetsDiscountedValue,
        setMaxNoOfUsesPerOrder: props.data.setMaxNoOfUsesPerOrder,
        allowFreeShipForAllCountries: props.data.allowFreeShipForAllCountries,
        freeShipCountryList: props.data.freeShipCountryList,
        shippingRates: props.data.shippingRates,
        isVisible : props.data.isVisible,
        useType : props.data.useType
      });
      setSelectedOptionCustomer(props.data.customerEligibility);
      setIsChecked(props.data.maxDiscountUses);
      setValueLimit(props.data.maxDiscountUsesValue);
      setSelectedOption(props.data.discountApplyFor);
      setSelectedOptionCombined(props.data.selectedOptionCombined);
      setSelectedOptionCoupon(props.data.couponNameId);
      setSelectedOptionGet(props.data.custmrGetsFromSpecificCatgry);
      setSelectedOptionBuy(props.data.custmrBuysOrSpendsFromSpecificCatgry);
      setSelectedDiscount(props.data.custmrGetsDiscountedOn);
      setValueOrd(props.data.setMaxNoOfUsesPerOrder);
      setValueCountries(props.data.shippingRates);
      setIsPOSChecked(props.data.allowPOSProLocation);
      setSelectedOptionCombined(props.data.couponCombination);
      setIsDateChecked(props.data.setEndDate);

      // setSearchCountryVal(props.data.)

      if (props.data && props.data.discountCategoryList.length > 0) {
        const categoryData = props.data.discountCategoryList;
        const hasMasterType4 = categoryData.some(
          (category) => category.masterType == 4
        );
        if (hasMasterType4) {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };

            getItems("", [filter]);
          });
        } else {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2_Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };
            getItemGroup("", [filter]);
          });
        }
      }

      if (props.data && props.data.custmrBuysOrSpendsCatgryList.length > 0) {
        const categoryData = props.data.custmrBuysOrSpendsCatgryList;
        const hasMasterType4 = categoryData.some(
          (category) => category.masterType == 4
        );
        if (hasMasterType4) {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };

            getItems("", [filter]);
          });
        } else {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2_Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };
            getItemGroup("", [filter]);
          });
        }
      }

      //for get
      if (props.data && props.data.custmrGetsSpecificCatgryList.length > 0) {
        const categoryData = props.data.custmrGetsSpecificCatgryList;
        const hasMasterType4 = categoryData.some(
          (category) => category.masterType == 4
        );
        if (hasMasterType4) {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };

            getItems("", [filter], true);
          });
        } else {
          categoryData.forEach((category) => {
            const filter = {
              fieldName: "d2_Id",
              operatorName: "equal",
              compareValue: category.masterValue,
            };
            getItemGroup("", [filter], true);
          });
        }
      }
      if (props.data && props.data.freeShipCountryList.length > 0) {
        const countryData = props.data.freeShipCountryList.map((country) => {
          // Find the country name from countriesList
          const matchingCountry = countriesList.find(
            (c) => c.id == country.masterValue
          );
          return {
            id: country.masterValue,
            name: matchingCountry ? matchingCountry.name : "Unknown",
          };
        });

        // Set the selected countries
        setselectedCountries(countryData);
      }
      if (props.data && props.data.customerEligibilityList.length > 0) {
        const CustomerList = props.data.customerEligibilityList;
        CustomerList.forEach((customer) => {
          const filter = {
            fieldName: "d2Id",
            operatorName: "equal",
            compareValue: customer.masterValue,
          };
          GetCustomers("", filter);
        });
      }
    }
  }, [props.data]);

  /*****************************************EDIT********************************************************** */

  //**********{Model Products}*****************//
  const [isModalOpenProducts, setModalOpenProducts] = useState(false);
  const showModalProducts = () => {
    // setModalOpenProducts(true);
    if (selectedOptionBuy) {
      setModalOpenProducts(true);
    } else {
      alert("Please select an option first.");
    }
  };
  const hideModalProducts = () => {
    setModalOpenProducts(false);
  };

  //**********{Model Products}*****************//
  const [isModalOpenBrowse, setModalOpenBrowse] = useState(false);
  const showModalBrowse = () => {
    // setModalOpenProducts(true);
    if (selectedOptionGet) {
      setModalOpenBrowse(true);
    } else {
      alert("Please select an option first.");
    }
  };
  const hideModalBrowse = () => {
    setModalOpenBrowse(false);
  };

  //**********{Model Countries}*****************//
  const [isModalOpenCountries, setModalOpenCountries] = useState(false);
  const showModalCountries = () => {
    setModalOpenCountries(true);
  };
  const hideModalCountries = () => {
    setModalOpenCountries(false);
  };

  //**********{Model Countries}*****************//
  const [isModalOpenEligible, setIsModalOpenEligible] = useState(false);
  const handleBrowseClick = () => {
    if (selectedOptionCustomer) {
      setIsModalOpenEligible(true);
    } else {
      alert("Please select an option first.");
    }
  };
  const hideModalEligible = () => {
    setIsModalOpenEligible(false);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index !== 2) {
      var firstTabElement = document.getElementById("firstTab");
      firstTabElement.classList.remove("active1");
      console.log(index);
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      couponMethod: index,
    }));
  };

  /* *******************Tab****************** */

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
  };

  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
  };

  /****************************************** */
  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const codeLength = 12;
    let randomCode = "";
    for (let i = 0; i < codeLength; i++) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    // setCodeValue(randomCode);
    setInputValues((prevValues) => ({
      ...prevValues,
      couponCode: randomCode,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setCodeValue(value);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name == "discountType") {
      setShowError(false);
      setIsCheckedDiscount(value == "2");
    }
  };

  /*************************************************** */
  const handleInputChangeModeRadio = (e) => {
    setValue1(e.target.value);
    setShowErrorRadio(false);
  };

  const handleInputChangeModeItem = (e) => {
    setValue2(e.target.value);
    setShowErrorRadioItem(false);
  };

  const handleInputBlur = () => {
    if (value.trim() == "") {
      // setShowError(true);
    }
  };

  const handleInputBlurRadio = () => {
    if (value1.trim() == "") {
      setShowErrorRadio(true);
    }
  };

  const handleInputBlurRadioItem = () => {
    if (value2.trim() == "") {
      setShowErrorRadioItem(true);
    }
  };
  /************************************************ */

  const handleOptionChange = (e) => {
    setSelectedOptionMinimum(e.target.value);
    setSelectedOptionRadio(e.target.value);
    setSelectedOptionRadioItem(e.target.value);
  };

  // const handleOptionChange = (e) => {
  //     setSelectedOptionRadioItem(e.target.value);
  // };

  const handleOptionChangeCustomer = (event) => {
    setSelectedOptionCustomer(event.target.value);
    setSearchVal3("");
    setShowDropdownCustomer(false);
  };

  const handleInputChangeCustomer = (e) => {
    const value = e.target.value;
    setSearchVal3(value);
  };

  const handleSelectSuggestionCustomer = (cust) => {
    debugger;
    if (!selectedCustomer.some((customer) => customer.d2Id == cust.d2Id)) {
      setSelectedCustomer((prev) => [...prev, cust]);
    }
    setInputValues((prev) => ({
      ...prev,
      customerEligibilityList: prev.customerEligibilityList.some(
        (entry) => entry.masterValue == cust.d2Id
      )
        ? prev.customerEligibilityList
        : [
            ...prev.customerEligibilityList,
            {
              masterType: 2,
              masterValue: cust.d2Id,
            },
          ],
    }));
    setShowDropdownCustomer(false);
  };

  const handleRemoveCustomer = (itemId) => {
    console.log("selectedItems", selectedCustomer);
    setSelectedCustomer((prev) => prev.filter((item) => item.d2Id !== itemId));
    console.log("inputValues", inputValues);
    setInputValues((prev) => ({
      ...prev,
      customerEligibilityList: prev.customerEligibilityList.filter(
        (entry) => entry.masterValue !== itemId
      ),
    }));
  };

  /******************************************* */

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.value);
  };

  const handleInputChangeLimit = (e) => {
    setValueLimit(e.target.value);
    setShowErrorLimit(false); // Reset error when input changes
  };

  const handleInputBlurLimit = () => {
    if (value.trim() == "") {
      setShowErrorLimit(true); // Show error if input is empty
    }
  };

  const handleCheckboxChangeCombined = (option) => {
    setSelectedOptionCombined(option);
  };

  const handleCheckboxChangeDate = (e) => {
    debugger;
    setInputValues((prev) => ({
      ...prev,
      setEndDate: e.target.checked,
    }));
    setIsDateChecked(e.target.checked);
  };

  const handleCheckboxChangeDiscount = (e) => {
    setIsCheckedDiscount(e.target.checked);
  };

  const handleCheckboxChangeChannels = (event) => {
    setIsCheckedChannels(event.target.checked);
  };

  const handleCheckboxChangePos = (e) => {
    setIsPOSChecked(e.target.checked);
  };

  const handleSelectChangeCoupon = (event) => {
    const { name, value } = event.target;
    setSelectedOptionCoupon(value);

    let couponName;
    if (value == "1") {
      couponName = "Amount off on total Order value";
    } else if (value == "2") {
      couponName = "Amount Off on Item/(Category/Group)";
    } else if (value == "3") {
      couponName = "Buy X get Y";
    } else if (value == "4") {
      couponName = "Free Shipping";
    } else if (value == "5") {
      couponName = "Pay For 1";
    } else if (value == "6") {
      couponName = "DOB";
    } else if (value == "7") {
      couponName = "DOA";
    } else if (value == "8") {
      couponName = "CLE";
    } else if (value == "9") {
      couponName = "ECR";
    }
    setInputValues((prevValues) => ({
      ...prevValues,
      couponNameId: value,
      couponName: couponName,
    }));
  };


  const useTypeChange = (event) => {
    const { name, value } = event.target; 
    setInputValues((prevValues) => ({
      ...prevValues,
      useType: value,
    }));
  };

  const handleSetIsvisible = (event) => {
    const { name, value , checked } = event.target; 
    setInputValues((prevValues) => ({
      ...prevValues,
      isVisible: checked,
    }));
  };


  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);

    setInputValues((prev) => ({
      ...prev,
      discountCategoryList: prev.discountCategoryList.filter(
        (entry) => entry.masterType == (e.target.value == "2" ? 4 : 3)
      ),
    }));

    setSearchVal("");
    setShowDropdown(false);
    setSelectedItems([]);
  };

  const handleOptionChangeBuy = (event) => {
    setSelectedOptionBuy(event.target.value);

    setInputValues((prev) => ({
      ...prev,
      custmrBuysOrSpendsCatgryList: prev.custmrBuysOrSpendsCatgryList.filter(
        (entry) => entry.masterType == (event.target.value == "2" ? 4 : 3)
      ),
    }));

    setSearchVal("");
    setShowDropdown(false);
    setSelectedItems([]);
  };

  const handleOptionChangeGet = (event) => {
    setSelectedOptionGet(event.target.value);

    setInputValues((prev) => ({
      ...prev,
      custmrGetsSpecificCatgryList: prev.custmrGetsSpecificCatgryList.filter(
        (entry) => entry.masterType == (event.target.value == "2" ? 4 : 3)
      ),
    }));

    setSearchVal2("");
    setShowDropdown2(false);
    setSelectedItems2([]);
  };

  const handleRadioChangeQuantity = (event) => {
    setSelectedOptionQuantity(event.target.value);
  };

  const handleRadioChangeDiscount = (e) => {
    setSelectedDiscount(e.target.value);
  };

  /*************************************************** */
  const handleCheckboxChangeOrder = (e) => {
    setIsCheckedOrder(e.target.checked);
  };

  const handleInputChangeModeOder = (e) => {
    setValueOrd(e.target.value);
    setShowErrorOrder(e.target.value.trim() == ""); // Show error if input is blank
  };

  const handleInputBlurOrder = () => {
    if (valueOrd.trim() == "") {
      setShowErrorOrder(true);
    }
  };

  const handleRadioChangeCountries = (event) => {
    setSelectedOptionCountries(event.target.value);
  };

  /*************************************************** */
  const handleCheckboxChangeCountries = (e) => {
    setIsCheckedCountries(e.target.checked);
  };

  const handleInputChangeCountries = (e) => {
    setValueCountries(e.target.value);
    setShowErrorCountries(e.target.value.trim() == ""); // Show error if input is blank
  };

  const handleInputBlurCountries = () => {
    if (valueCountries.trim() == "") {
      setShowErrorCountries(true);
    }
  };

  const handleSelectItem = (item) => {
    debugger;
    const listName = listMap[selectedOptionCoupon];

    if (!listName) {
      console.error(
        "Invalid selectedOptionCoupon value:",
        selectedOptionCoupon
      );
      return;
    }

    const propertyName = relevantOptions.includes("2") ? "d2Id" : "d2_Id";
    // const propertyName = selectedOption == '2' ? 'd2Id' : 'd2_Id';

    if (
      !selectedItems.some(
        (selected) => selected[propertyName] == item[propertyName]
      )
    ) {
      setSelectedItems((prev) => [...prev, item]);

      setInputValues((prev) => ({
        ...prev,
        [listName]: [
          ...prev[listName].filter(
            (entry) => entry.masterValue !== item[propertyName]
          ),
          {
            masterType: relevantOptions.includes("2") ? 4 : 3,
            masterValue: item[propertyName],
          },
        ],
      }));
    }

    setShowDropdown(false);
    setSearchVal("");
  };

  const handleSelectItemForGet = (item) => {
    debugger;

    const propertyName = selectedOptionGet == "2" ? "d2Id" : "d2_Id";

    if (
      !selectedItems2.some(
        (selected) => selected[propertyName] == item[propertyName]
      )
    ) {
      setSelectedItems2((prev) => [...prev, item]);

      setInputValues((prev) => ({
        ...prev,
        custmrGetsSpecificCatgryList: [
          ...prev.custmrGetsSpecificCatgryList.filter(
            (entry) => entry.masterValue !== item[propertyName]
          ),
          {
            masterType: selectedOptionGet == "2" ? 4 : 3,
            masterValue: item[propertyName],
          },
        ],
      }));
    }

    setShowDropdown2(false);
    setSearchVal2("");
  };

  const handleRemoveItem = (itemId) => {
    // Map selectedOptionCoupon values to the corresponding input field list

    // Determine the list to update
    const listName = listMap[selectedOptionCoupon];

    if (!listName) {
      console.error(
        "Invalid selectedOptionCoupon value:",
        selectedOptionCoupon
      );
      return;
    }

    const propertyName = relevantOptions.includes("2") ? "d2Id" : "d2_Id";

    // Remove item from selectedItems
    setSelectedItems((prev) =>
      prev.filter((item) => item[propertyName] !== itemId)
    );

    // Remove item from the corresponding list
    setInputValues((prev) => ({
      ...prev,
      [listName]: prev[listName].filter(
        (entry) => entry.masterValue !== itemId
      ),
    }));
  };

  const handleRemoveItemForGet = (itemId) => {
    // Map selectedOptionCoupon values to the corresponding input field list

    // Determine the list to update
    const propertyName = selectedOptionGet == "2" ? "d2Id" : "d2_Id";

    // Remove item from selectedItems
    setSelectedItems((prev) =>
      prev.filter((item) => item[propertyName] !== itemId)
    );

    // Remove item from the corresponding list
    setInputValues((prev) => ({
      ...prev,
      custmrGetsSpecificCatgryList: prev.custmrGetsSpecificCatgryList.filter(
        (entry) => entry.masterValue !== itemId
      ),
    }));
  };

  // const handleRemoveItem = (itemId) => {

  //     const propertyName = relevantOptions.includes('2') ? 'd2Id' : 'd2_Id';

  //     setSelectedItems((prev) => prev.filter((item) => item[propertyName] !== itemId));

  //     setInputValues((prev) => ({
  //         ...prev,
  //         discountCategoryList: prev.discountCategoryList.filter(
  //             (entry) => entry.masterValue !== itemId
  //         )
  //     }));
  // };

  // const handleBrowseClick = () => {
  //     if (selectedOptionCustomer) {
  //         setIsModalOpenEligible(true);
  //     } else {
  //         alert("Please select an option first.");
  //     }
  // };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////                                                                                                        ////
  const handleSave = async () => {
    debugger;
    const data = {
      ...inputValues,
      couponTitleOrCode: inputValues.couponTitle || inputValues.couponCode,
      minPurchaseReqType:
        selectedOptionMinimum || selectedOptionRadio || selectedOptionRadioItem,
      minPurchaseReqValue:
        selectedOptionMinimum == "1"
          ? 0
          : selectedOptionRadio == "2"
          ? value1
          : value2,
      customerEligibility: selectedOptionCustomer,
      maxDiscountUses: isChecked,
      maxDiscountUsesValue: valueLimit,
      allowPOSProLocation: isPOSChecked,
      couponCombination: selectedOptionCombined,
      discountApplyFor: selectedOption,
      isCustmrBuysMinQtyItm: selectedOptionQuantity == "1" ? true : false, //
      isCustmrSpendsMinPurAmt: selectedOptionQuantity == "2" ? true : false, //
      custmrBuysOrSpendsFromSpecificCatgry: selectedOptionBuy,
      custmrGetsFromSpecificCatgry: selectedOptionGet,
      custmrGetsDiscountedOn: selectedDiscount,
      custmrGetsDiscountedValue:
        inputValues.custmrGetsDiscountedOn == "3"
          ? 0
          : inputValues.custmrGetsDiscountedValue,
      setMaxNoOfUsesPerOrder: valueOrd,
      allowFreeShipForAllCountries:
        selectedOptionCountries == "1" ? true : false,
      shippingRates: valueCountries,
    };
    console.log("SendData", data);
    try {
      const response = await CouponMasterApi.insertCouponMaster(data);
      if (response) {
        console.log("response", response);
        if (inputValues.id !== 0) {
          alert("Coupon Updated Successfully");
        } else {
          alert("Coupon Created Successfully");
          navigate("/CouponMasterList");
        }
      }
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };

  const GetCustomers = async (value, filter) => {
    debugger;
    let filteredData;
    if (value !== "") {
      filteredData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: value || "",
        },
        { fieldName: "MasterType", operatorName: "equal", compareValue: "2" },
      ];
    } else {
      filteredData = [
        filter,
        { fieldName: "MasterType", operatorName: "equal", compareValue: "2" },
      ];
    }
    try {
      const response = await Account.getAccountWithFilter(filteredData);
      console.log("Accounts", response);
      if (response?.length > 0 && filter == undefined) {
        setSuggestionsCustomer(response);
        setShowDropdownCustomer(response.length > 0);
      } else {
        setSelectedCustomer((prev) => {
          if (!prev.some((customer) => customer.id === response[0].id)) {
            return [...prev, response[0]];
          }
          return prev;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getItems = async (SearchVal, data = [], isGet = false) => {
    debugger;
    let sendData;
    if (SearchVal !== "" || searchVal !== "") {
      sendData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: SearchVal,
        },
      ];
    } else {
      sendData = data;
    }

    try {
      const items = await ItemMaster.GetItemMasterWithFilterAndPagination(
        sendData
      );
      // console.log("items", items);
      setSuggestions(items || []);
      if (data.length > 0 && !isGet) {
        setSelectedItems((prev) => [...prev, items[0]]);
      } else if (isGet) {
        setSelectedItems2((prev) => [...prev, items[0]]);
      }
      if (searchVal.trim() !== "") {
        setShowDropdown(items?.length > 0);
      } else {
        if (searchVal2.trim() !== "") {
          setShowDropdown2(items?.length > 0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getItemGroup = async (SearchVal, data = [], isGet) => {
    let sendData;
    if (SearchVal !== "" || searchVal !== "") {
      sendData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: SearchVal,
        },
      ];
    } else {
      sendData = data;
    }
    try {
      const items = await ItemGroup.GetItemGroupWithFilter(sendData, 100);
      // console.log("items", items);
      setSuggestions(items || []);
      if (data.length > 0 && !isGet) {
        setSelectedItems((prev) => [...prev, items[0]]);
      } else if (isGet) {
        setSelectedItems2((prev) => [...prev, items[0]]);
      }
      if (searchVal.trim() !== "") {
        setShowDropdown(items?.length > 0);
      } else {
        if (searchVal2.trim() !== "") {
          setShowDropdown2(items?.length > 0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedSetSearchVal = useCallback(
    debounce((value) => {
      setDebouncedSearchVal(value);
    }, 300),
    []
  );

  const debouncedSetSearchVal2 = useCallback(
    debounce((value) => {
      setDebouncedSearchVal2(value);
    }, 300),
    []
  );

  const debouncedSetSearchVal3 = useCallback(
    debounce((value) => {
      setDebouncedSearchVal3(value);
    }, 300),
    []
  );

  useEffect(() => {
    if (searchVal.trim() !== "") {
      debouncedSetSearchVal(searchVal);
    }
  }, [searchVal, debouncedSetSearchVal]);

  useEffect(() => {
    if (searchVal2.trim() !== "") {
      debouncedSetSearchVal2(searchVal2);
    }
  }, [searchVal2, debouncedSetSearchVal2]); //for multiples

  useEffect(() => {
    if (searchVal3.trim() !== "") {
      debouncedSetSearchVal3(searchVal3);
    }
  }, [searchVal3, debouncedSetSearchVal3]);

  useEffect(() => {
    debugger;
    if (debouncedSearchVal && relevantOptions.includes("2")) {
      getItems(debouncedSearchVal);
    } else if (debouncedSearchVal && relevantOptions.includes("1")) {
      getItemGroup(debouncedSearchVal);
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  }, [debouncedSearchVal]);

  useEffect(() => {
    debugger;
    if (debouncedSearchVal2 && selectedOptionGet == "2") {
      getItems(debouncedSearchVal2);
    } else if (debouncedSearchVal2 && selectedOptionGet == "1") {
      getItemGroup(debouncedSearchVal2);
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  }, [debouncedSearchVal2]);

  useEffect(() => {
    if (debouncedSearchVal3) {
      GetCustomers(debouncedSearchVal3);
    }
  }, [debouncedSearchVal3]);

  ////                                                                                                        ////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [isModalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(!isModalOpen);
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
  };

  return (
    <div>
      <PageTabs />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Coupon Master</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          SaveButton={handleSave}
          showModal={showModal}
          isBtnSetting={true}
          modelOpen={props.modelOpen}
          setSliderCallBack={props.setSliderCallBack}
          isBtnOpticalField={true}
        />
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-left coupon-new-left">
                    {/* <div className="company_info-text-im td-1">Title</div> */}
                    <div className="">
                      <div className="Coupon-box-template">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-coupon">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              value={selectedOptionCoupon}
                              name="couponNameId"
                              onChange={handleSelectChangeCoupon}
                            >
                              <option value="1">
                                Amount off on total Order value
                              </option>
                              <option value="2">
                                Amount Off on Item/(Category/Group)
                              </option>
                              <option value="3">Buy X get Y</option>
                              <option value="4">Free Shipping</option>
                              <option value="5">Pay For 1</option>
                              <option value="6">DOB</option>
                              <option value="7">DOA</option>
                              <option value="8">CLE</option>
                              <option value="9">ECR</option>
                            </select>
                            <label class="modify_lbl_text3">
                              Coupon Discount Type
                            </label>
                          </div>
                        </div>
                        {/* <div className="flex-products-dis-coupons">
                                                    {selectedOptionCoupon == "2" && (
                                                        <div className="Amount-off-products-coupon">Amount Off Products</div>
                                                    )}
                                                    {selectedOptionCoupon == "2" && (
                                                        <div className="product-discount-coupon">Product Discount</div>
                                                    )}

                                                    {selectedOptionCoupon == "1" && (
                                                        <div className="Amount-off-products-coupon">Amount off order</div>
                                                    )}
                                                    {selectedOptionCoupon == "1" && (
                                                        <div className="product-discount-coupon">Order discount</div>
                                                    )}

                                                    {selectedOptionCoupon == "3" && (
                                                        <div className="Amount-off-products-coupon">Buy X get Y</div>
                                                    )}
                                                    {selectedOptionCoupon == "3" && (
                                                        <div className="product-discount-coupon">Product discount</div>
                                                    )}

                                                    {selectedOptionCoupon == "3" && (
                                                        <div className="Amount-off-products-coupon">Free Shipping</div>
                                                    )}
                                                    {selectedOptionCoupon == "3" && (
                                                        <div className="product-discount-coupon">Shipping discount</div>
                                                    )}
                                                </div> */}
                        {/* ****************************** Method ********************************* */}
                        <div className="flex-start mt-1" style={{}}>
                          <div className="coupon-method">Method</div>
                          <div className="space-between">
                            <div
                              className={`tab ${
                                activeTab == 2 ? "active1" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(2);
                                showtabs1();
                              }}
                            >
                              <input
                                className="discount-coupon"
                                id="firstTab"
                                type="checkbox"
                                checked={
                                  inputValues.couponMethodId == 1 &&
                                  activeTab == 2
                                }
                                // checked={activeTab == 2}
                                value={1}
                                name="Discount Coupon Code"
                                onChange={(e) =>
                                  setInputValues((prev) => ({
                                    ...prev,
                                    couponMethodName: e.target.name,
                                    couponMethodId: Number(e.target.value),
                                  }))
                                }
                              />
                              &nbsp;&nbsp;
                              <label>Discount Coupon Code</label>
                            </div>
                            {/* <div id="firstTab" className={`tabs-coupon  ${activeTab == 2 ? "active" : ""}`}
                                                            onClick={() => { handleTabClick(2); showtabs1(); }}>
                                                            Discount coupon Code
                                                            </div> */}
                            <div
                              className={`tab ${
                                activeTab == 1 ? "active1" : ""
                              }`}
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                handleTabClick(1);
                                showtabs2();
                              }}
                            >
                              <input
                                className="automatic-discount-coupon"
                                type="checkbox"
                                checked={
                                  inputValues.couponMethodId == 2 &&
                                  activeTab == 1
                                }
                                // checked={activeTab == 1}
                                value={2}
                                name="Automatic discount Applied to Cart to Check"
                                onChange={(e) =>
                                  setInputValues((prev) => ({
                                    ...prev,
                                    couponMethodName: e.target.name,
                                    couponMethodId: Number(e.target.value),
                                  }))
                                }
                              />
                              &nbsp;&nbsp;
                              <label>
                                Automatic discount Applied to Cart to Check
                              </label>
                            </div>
                          </div>
                        </div>
                        {tabcontent1 && (
                          <div className="">
                            <div
                              className="gen-random-code"
                              onClick={generateRandomCode}
                              style={{ cursor: "pointer" }}
                            >
                              Generate random code
                            </div>
                            <div className="input-box-Im input-height2">
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  name="couponCode"
                                  value={inputValues.couponCode}
                                  onChange={handleInputChange}
                                />
                                <label class="modify_p_text2">
                                  Discount Code
                                </label>
                                {/* <label id="input1" className="labelEffect1">Discount Code</label> */}
                                <span>
                                  Customers must enter this code at checkout
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        {tabcontent2 && (
                          <div className="Top-tab2-cpn">
                            <div className="input-box-Im input-height2">
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  type="text"
                                  name="couponTitle"
                                  value={inputValues.couponTitle}
                                  onChange={handleInputChange}
                                />
                                <label class="modify_p_text2">Title</label>
                                {/* <label id="input1" className="labelEffect1">Discount Code</label> */}
                                <span>
                                  Customers will see this in their cart and at
                                  checkout.
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="Coupon-box-template top-box-coupon">
                        <div className="flex-radio-cutomer-cpn" style={{marginTop:"20px"}}>
                        <div className="input-box-coupon">
                            <select
                              className="modify_im_input mode_category"
                              type="text"
                              name="useType"
                              value={inputValues.useType}
                              onChange={useTypeChange}
                            >
                              <option value="1">
                             General
                              </option>
                              <option value="2">
                              Membership
                              </option>
                            </select>
                            <label class="modify_lbl_text3">
                             UseType
                            </label>
                          </div>
                        </div>
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn" >
                            <input
                              className="customer-radio-cpn"
                              type="checkbox"
                              name="isVisible"
                              checked={inputValues.isVisible}
                              onChange={handleSetIsvisible}
                            />
                          </div>
                          <div className="all-customer-cpn">
                           IsActive
                          </div>
                        </div>
                      </div>

                      {tabcontent1 && (
                        <div>
                          {(selectedOptionCoupon == "1" ||
                            selectedOptionCoupon !== "3") &&
                            selectedOptionCoupon !== "3" && (
                              <div className="Coupon-box-template top-box-coupon">
                                <div>
                                  <div className="input-box-Im input-height2">
                                    <div className="input-box-coupon">
                                      <select
                                        className="modify_im_input mode_category"
                                        type="text"
                                        name="discountType"
                                        onChange={handleInputChange}
                                        value={inputValues.discountType}
                                      >
                                        <option value={1}>Percentage</option>
                                        <option value={2}>Fixed Amount</option>
                                      </select>
                                      <label class="modify_lbl_text3">
                                        Discount Value
                                      </label>
                                    </div>
                                    <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                      <div className="symbol-container-cpn">
                                        {inputValues.discountType == "2" && (
                                          <span className="symbol-left-cpn">
                                            â‚¹
                                          </span>
                                        )}
                                        <input
                                          className="modify_im_input mode_category value-cpn-padding"
                                          type="number"
                                          name="discountValue"
                                          placeholder={
                                            inputValues.discountType == "1"
                                              ? ""
                                              : "0.00"
                                          }
                                          value={inputValues.discountValue}
                                          onChange={handleInputChange}
                                          onBlur={handleInputBlur}
                                        />
                                        {inputValues.discountType == "1" && (
                                          <span className="symbol-right-cpn">
                                            %
                                          </span>
                                        )}
                                      </div>
                                      {/* {showError && (
                                                                            <div className="error-message-cpn">
                                                                                <span className="alert-icon-cpn"><i class="fa-solid fa-circle-exclamation"></i></span>
                                                                                Discount value canâ€™t be blank
                                                                            </div>
                                                                        )} */}
                                    </div>
                                  </div>
                                  {(selectedOptionCoupon == 2 ||
                                    selectedOptionCoupon == "2") && (
                                    <div>
                                      <div className="input-box-Im input-height2">
                                        <div className="input-box-coupon">
                                          <select
                                            className="modify_im_input mode_category"
                                            type="text"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                          >
                                            <option value={1}>
                                              Specific Groups/Category
                                            </option>
                                            <option value={2}>
                                              Specific Items
                                            </option>
                                          </select>
                                          <label class="modify_lbl_text3">
                                            Applies To
                                          </label>
                                        </div>
                                      </div>
                                      <div className="input-box-Im input-height2">
                                        <div className="input-box-coupon search-container-cpn">
                                          <span className="search-icon-cpn">
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                          </span>
                                          <input
                                            className="modify_im_input mode_category search-cpn-padding"
                                            type="search"
                                            onChange={(e) =>
                                              setSearchVal(e.target.value)
                                            }
                                            value={searchVal}
                                            placeholder={
                                              selectedOption == "1"
                                                ? "Search Groups/Category"
                                                : "Search Items"
                                            }
                                            ref={inputRef}
                                          />

                                          <div
                                            className="dropdown-container"
                                            tabIndex={0}
                                            onKeyDown={handleKeyDown}
                                          >
                                            {showDropdown && (
                                              <ul
                                                className="suggestions-dropdown-coupon"
                                                ref={dropdownRef}
                                              >
                                                {suggestions.length > 0 &&
                                                  suggestions.map(
                                                    (item, index) => (
                                                      <li
                                                        key={item.id}
                                                        className={`suggestion-item-coupon ${
                                                          focusedIndex == index
                                                            ? "focused"
                                                            : ""
                                                        }`}
                                                        onClick={() =>
                                                          handleSelectItem(item)
                                                        }
                                                        onMouseLeave={
                                                          handleMouseLeave
                                                        }
                                                        onMouseEnter={() =>
                                                          handleMouseEnter(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {item.name}
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            )}
                                          </div>

                                          <div className="selected-items-container-coupon">
                                            {selectedItems.length > 0 &&
                                              selectedItems.map((item) => {
                                                const propertyName =
                                                  relevantOptions.includes("2")
                                                    ? "d2Id"
                                                    : "d2_Id";

                                                return (
                                                  <div
                                                    key={item[propertyName]}
                                                    className="selected-item-coupon"
                                                  >
                                                    <span>{item.name}</span>
                                                    <button
                                                      className="remove-coupon-item-button"
                                                      onClick={() =>
                                                        handleRemoveItem(
                                                          item[propertyName]
                                                        )
                                                      }
                                                    >
                                                      x
                                                    </button>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                        {/* <div className="btn-browse-cpn">
                                                                                <button className="btn-browse" onClick={showModalCollection}>Browse</button>
                                                                            </div> */}
                                      </div>
                                    </div>
                                  )}
                                  {mode == "2" &&
                                    (selectedOptionCoupon !== 1 ||
                                      selectedOptionCoupon !== "1") && (
                                      <div>
                                        <div className="flex-radio-cutomer-cpn">
                                          <div className="radio-height-cpn">
                                            <input
                                              className="customer-radio-cpn"
                                              type="checkbox"
                                              name="customer"
                                              checked={isCheckedDiscount}
                                              onChange={
                                                handleCheckboxChangeDiscount
                                              }
                                            />
                                          </div>
                                          <div className="all-customer-cpn">
                                            Only apply discount once per order
                                          </div>
                                        </div>
                                        <div className="redeemable-cpn">
                                          If not selected,{" "}
                                          <strong>
                                            {value.trim()
                                              ? mode == "2"
                                                ? `â‚¹${value}.00`
                                                : ``
                                              : mode == "2"
                                              ? "the amount"
                                              : ""}
                                          </strong>{" "}
                                          the amount will be taken off each
                                          eligible item in an order.
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}

                          {(selectedOptionCoupon == 3 ||
                            selectedOptionCoupon == "3") && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                {selectedOptionQuantity == "2"
                                  ? "Customer spends"
                                  : "Customer buys"}
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="item"
                                    value="1"
                                    checked={selectedOptionQuantity == "1"}
                                    onChange={handleRadioChangeQuantity}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum quantity of items
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="item"
                                    value="2"
                                    checked={selectedOptionQuantity == "2"}
                                    onChange={handleRadioChangeQuantity}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum purchase amount
                                </div>
                              </div>
                              <div className="input-box-Im input-height2 customer-buy">
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                  <div className="symbol-container-cpn">
                                    {selectedOptionQuantity == "2" && (
                                      <span className="symbol-left-cpn">
                                        â‚¹
                                      </span>
                                    )}
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding"
                                      type="text"
                                      placeholder={
                                        selectedOptionQuantity == "2"
                                          ? "0.00"
                                          : "Enter quantity"
                                      }
                                      value={
                                        selectedOptionQuantity == "2"
                                          ? inputValues.custmrSpendsMinPurAmt
                                          : inputValues.custmrBuysMinQtyItm
                                      }
                                      name={
                                        selectedOptionQuantity == "2"
                                          ? "custmrSpendsMinPurAmt"
                                          : "custmrBuysMinQtyItm"
                                      }
                                      onChange={handleInputChange}
                                    />
                                    <label class="modify_lbl_text3">
                                      {selectedOptionQuantity == "2"
                                        ? "Amount"
                                        : "Quantity"}
                                    </label>
                                  </div>
                                </div>
                                <div className="input-box-coupon">
                                  <select
                                    className="modify_im_input mode_category"
                                    type="text"
                                    value={selectedOptionBuy}
                                    onChange={handleOptionChangeBuy}
                                  >
                                    <option value={1}>
                                      Specific Groups/Category
                                    </option>
                                    <option value={2}>Specific Items</option>
                                  </select>
                                  <label class="modify_lbl_text3">
                                    Any Items From
                                  </label>
                                </div>
                              </div>

                              <div className="Top-customer-cpn">
                                <div className="input-box-Im input-height2">
                                  <div className="input-box-coupon search-container-cpn">
                                    <span className="search-icon-cpn">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                    <input
                                      className="modify_im_input mode_category search-cpn-padding"
                                      type="search"
                                      onChange={(e) =>
                                        setSearchVal(e.target.value)
                                      }
                                      ref={inputRef}
                                      value={searchVal}
                                      placeholder={
                                        selectedOptionBuy == "2"
                                          ? "Search Items"
                                          : "Search Group/Category"
                                      }
                                    />

                                    <div
                                      className="dropdown-container"
                                      tabIndex={0}
                                      onKeyDown={handleKeyDown}
                                    >
                                      {showDropdown && (
                                        <ul
                                          className="suggestions-dropdown-coupon"
                                          ref={dropdownRef}
                                        >
                                          {suggestions.length > 0 &&
                                            suggestions.map((item, index) => (
                                              <li
                                                key={item.id}
                                                className={`suggestion-item-coupon ${
                                                  focusedIndex == index
                                                    ? "focused"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleSelectItem(item)
                                                }
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={() =>
                                                  handleMouseEnter(index)
                                                }
                                              >
                                                {item.name}
                                              </li>
                                            ))}
                                        </ul>
                                      )}
                                    </div>

                                    <div className="selected-items-container-coupon">
                                      {selectedItems.map((item) => {
                                        const propertyName =
                                          relevantOptions.includes("2")
                                            ? "d2Id"
                                            : "d2_Id";
                                        return (
                                          <div
                                            key={item[propertyName]}
                                            className="selected-item-coupon"
                                          >
                                            <span>{item.name}</span>
                                            <button
                                              className="remove-coupon-item-button"
                                              onClick={() =>
                                                handleRemoveItem(
                                                  item[propertyName]
                                                )
                                              }
                                            >
                                              x
                                            </button>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="customer-eligibility-cpn">
                                Customer gets
                              </div>
                              <div className="redeemable-cpn">
                                Customers must add the quantity of items
                                specified below to their cart.
                              </div>
                              <div className="input-box-Im input-height2 customer-buy">
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding"
                                      name="custmrGetsQty"
                                      value={inputValues.custmrGetsQty}
                                      onChange={handleInputChange}
                                      type="text"
                                    />
                                    <label class="modify_lbl_text3">
                                      Quantity
                                    </label>
                                  </div>
                                </div>
                                <div className="input-box-coupon">
                                  <select
                                    className="modify_im_input mode_category"
                                    type="text"
                                    value={selectedOptionGet}
                                    onChange={handleOptionChangeGet}
                                  >
                                    <option value={1}>
                                      Specific Groups/Category
                                    </option>
                                    <option value={2}>Specific Items</option>
                                  </select>
                                  <label class="modify_lbl_text3">
                                    Any Items From
                                  </label>
                                </div>
                              </div>
                              <div className="Top-customer-cpn">
                                <div className="input-box-Im input-height2">
                                  <div className="input-box-coupon search-container-cpn">
                                    <span className="search-icon-cpn">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                    <input
                                      className="modify_im_input mode_category search-cpn-padding"
                                      type="search"
                                      onChange={(e) =>
                                        setSearchVal2(e.target.value)
                                      }
                                      //   ref={inputRef}
                                      value={searchVal2}
                                      placeholder={
                                        selectedOptionGet == "2"
                                          ? "Search Items"
                                          : "Search Collection"
                                      }
                                    />

                                    <div
                                      className="dropdown-container"
                                      tabIndex={0}
                                      onKeyDown={handleKeyDown}
                                    >
                                      {showDropdown2 && (
                                        <ul
                                          className="suggestions-dropdown-coupon"
                                          ref={dropdownRef}
                                        >
                                          {suggestions.length > 0 &&
                                            suggestions.map((item, index) => (
                                              <li
                                                key={item.id}
                                                className={`suggestion-item-coupon ${
                                                  focusedIndex == index
                                                    ? "focused"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleSelectItemForGet(item)
                                                }
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={() =>
                                                  handleMouseEnter(index)
                                                }
                                              >
                                                {item.name}
                                              </li>
                                            ))}
                                        </ul>
                                      )}
                                    </div>
                                    <div className="selected-items-container-coupon">
                                      {selectedItems2.map((item) => {
                                        const propertyName =
                                          selectedOptionGet == "2"
                                            ? "d2Id"
                                            : "d2_Id";

                                        return (
                                          <div
                                            key={item[propertyName]}
                                            className="selected-item-coupon"
                                          >
                                            <span>{item.name}</span>
                                            <button
                                              className="remove-coupon-item-button"
                                              onClick={() =>
                                                handleRemoveItemForGet(
                                                  item[propertyName]
                                                )
                                              }
                                            >
                                              x
                                            </button>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  {/* <div className="btn-browse-cpn">
                                                                        <button className="btn-browse" onClick={showModalBrowse}>Browse</button>
                                                                    </div>
                                                                    <div>
                                                                        {isModalOpenBrowse && (
                                                                            <div className="modal" >
                                                                                <div className="AddNewItem-modal modal_collection-cpn">
                                                                                    <div className="modal-title">
                                                                                        
                                                                                        <h3 className="modal-OrderLIst collection-color-cpn">
                                                                                            {selectedOptionGet == "2"
                                                                                                ? "Add Items"
                                                                                                : "Add Groups/Category"}
                                                                                        </h3>
                                                                                        <span className="close_modal" onClick={hideModalBrowse}>
                                                                                            &times;
                                                                                        </span>
                                                                                    </div>
                                                                                 

                                                                                    {selectedOptionGet == "2" ? (
                                                                                        <div className="ModalContentModalSettingP ">
                                                                                            <ModelAddProducts />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="ModalContentModalSettingP ">
                                                                                            <ModelAddCollection />
                                                                                        </div>
                                                                                    )}
                                                                                    <div className="footer-collection-cpn">
                                                                                        <div>0 collections selected</div>
                                                                                        <div className="flex-btn-cancel-add">
                                                                                            <div>
                                                                                                <button className="cancel-btn-cpn" onClick={hideModalBrowse}>Cancel</button>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button className="add-btn-cpn">Add</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div> */}
                                </div>
                              </div>

                              <div className="customer-eligibility-cpn">
                                At a discounted value
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value={1}
                                    checked={selectedDiscount == "1"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Percentage
                                </div>
                              </div>
                              {selectedDiscount == "1" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-right-cpn percentage-right-cpn">
                                      %
                                    </span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      name="custmrGetsDiscountedValue"
                                      value={
                                        inputValues.custmrGetsDiscountedValue
                                      }
                                      onChange={handleInputChange}
                                      type="text"
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value="2"
                                    checked={selectedDiscount == "2"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Amount off each
                                </div>
                              </div>
                              {selectedDiscount == "2" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      name="custmrGetsDiscountedValue"
                                      value={
                                        inputValues.custmrGetsDiscountedValue
                                      }
                                      type="text"
                                      placeholder="0.00"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    For multiple quantities, the discount amount
                                    will be taken off each Y item.
                                  </span>
                                </div>
                              )}
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value="3"
                                    checked={selectedDiscount == "3"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">Free</div>
                              </div>

                              <hr></hr>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="order"
                                    checked={isCheckedOrder}
                                    onChange={handleCheckboxChangeOrder}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Set a maximum number of uses per order
                                </div>
                              </div>
                              {isCheckedOrder && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="number"
                                      value={valueOrd}
                                      onChange={handleInputChangeModeOder}
                                      onBlur={handleInputBlurOrder}
                                    />
                                  </div>
                                  {showErrorOrder && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Maximum number of uses per order canâ€™t
                                      be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(selectedOptionCoupon == 3 ||
                            selectedOptionCoupon == "3") && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                Countries
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="countries"
                                    value="1"
                                    checked={selectedOptionCountries == "1"}
                                    onChange={handleRadioChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  All Countries
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="countries"
                                    value="2"
                                    checked={selectedOptionCountries == "2"}
                                    onChange={handleRadioChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Selected Countries
                                </div>
                              </div>
                              {selectedOptionCountries == "2" && (
                                <div className="Top-customer-cpn">
                                  <div className="input-box-Im input-height2">
                                    <div className="input-box-coupon search-container-cpn">
                                      <span className="search-icon-cpn">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                      </span>
                                      <input
                                        className="modify_im_input mode_category search-cpn-padding"
                                        type="search"
                                        value={searchCountryVal}
                                        onChange={handleCountryInputChange}
                                        placeholder="Search Countries"
                                      />
                                    </div>
                                    {ShowCountryDropdown && (
                                      <div className="suggestions-country-dropdown">
                                        {CountrySuggestions.length > 0 ? (
                                          CountrySuggestions.map((country) => (
                                            <div
                                              key={country.id}
                                              className="suggestion-country-item"
                                              onClick={() =>
                                                handleSelectCountry(country)
                                              }
                                            >
                                              {country.name}
                                            </div>
                                          ))
                                        ) : (
                                          <span>No countries found</span>
                                        )}
                                      </div>
                                    )}

                                    <div className="selected-items-container-coupon">
                                      {selectedCountries &&
                                        selectedCountries.map((item) => {
                                          return (
                                            <div
                                              key={item.id}
                                              className="selected-item-coupon"
                                            >
                                              <span>{item.name}</span>
                                              <button
                                                className="remove-coupon-item-button"
                                                onClick={() =>
                                                  handleRemoveCountry(item.id)
                                                }
                                              >
                                                x
                                              </button>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="customer-eligibility-cpn">
                                Shipping rates
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="countries"
                                    checked={isCheckedCountries}
                                    onChange={handleCheckboxChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Exclude shipping rates over a certain amount
                                </div>
                              </div>
                              {isCheckedCountries && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                      value={valueCountries}
                                      onChange={handleInputChangeCountries}
                                      onBlur={handleInputBlurCountries}
                                      placeholder="0.00"
                                    />
                                  </div>
                                  {showErrorCountries && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Shipping rate amount canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {(selectedOptionCoupon !== 3 ||
                            selectedOptionCoupon !== "3") && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                Minimum Purchase Requirements
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="1"
                                    checked={selectedOptionMinimum == "1"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  No Minimum requirements
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="2"
                                    checked={selectedOptionRadio == "2"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum purchase amount (â‚¹)
                                </div>
                              </div>
                              {selectedOptionRadio == "2" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                      placeholder="0.00"
                                      value={value1}
                                      onChange={handleInputChangeModeRadio}
                                      onBlur={handleInputBlurRadio}
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    Applies only to selected collections.
                                  </span>
                                  {showErrorRadio && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Minimum purchase canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="3"
                                    checked={selectedOptionRadioItem == "3"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum quantity of items
                                </div>
                              </div>
                              {selectedOptionRadioItem == "3" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="number"
                                      value={value2}
                                      placeholder=""
                                      onChange={handleInputChangeModeItem}
                                      onBlur={handleInputBlurRadioItem}
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    Applies only to selected collections.
                                  </span>
                                  {showErrorRadioItem && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Minimum quantity canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="Coupon-box-template top-box-coupon">
                            <div className="customer-eligibility-cpn">
                              Combinations
                            </div>

                            <div className="redeemable-cpn">
                              {codeValue ? (
                                <div className="customer-eligibility-cpn">
                                  {codeValue} can be combined with
                                </div>
                              ) : (
                                <div className="customer-eligibility-cpn">
                                  This product discount can be combined with
                                </div>
                              )}
                            </div>
                            {selectedOptionCoupon !== "1" && (
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name=""
                                    checked={
                                      selectedOptionCombined == "product"
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeCombined(
                                        selectedOptionCombined == "product"
                                          ? ""
                                          : "product"
                                      )
                                    }
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Product discounts
                                </div>
                              </div>
                            )}
                            {selectedOptionCombined == "product" && (
                              <div className="all-customer-cpn">
                                No product discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one product discount that combines with
                                product discounts.
                              </div>
                            )}
                            {selectedOptionCoupon !== "2" &&
                              selectedOptionCoupon !== "1" &&
                              selectedOptionCoupon !== "3" && (
                                <div className="flex-radio-cutomer-cpn">
                                  <div className="radio-height-cpn">
                                    <input
                                      className="customer-radio-cpn"
                                      type="checkbox"
                                      name=""
                                      checked={
                                        selectedOptionCombined == "order"
                                      }
                                      onChange={() =>
                                        handleCheckboxChangeCombined(
                                          selectedOptionCombined == "order"
                                            ? ""
                                            : "order"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="all-customer-cpn">
                                    Order discounts
                                  </div>
                                </div>
                              )}
                            {selectedOptionCombined == "order" && (
                              <div className="all-customer-cpn">
                                No order discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one order discount that combines with
                                product discounts.
                              </div>
                            )}
                            {selectedOptionCoupon !== "3" && (
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name=""
                                    checked={
                                      selectedOptionCombined == "shipping"
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeCombined(
                                        selectedOptionCombined == "shipping"
                                          ? ""
                                          : "shipping"
                                      )
                                    }
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Shipping discounts
                                </div>
                              </div>
                            )}
                            {selectedOptionCombined == "shipping" && (
                              <div className="all-customer-cpn">
                                No shipping discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one shipping discount that combines with
                                product discounts.
                              </div>
                            )}
                          </div>

                          <div className="Coupon-box-template top-box-coupon">
                            <div className="customer-eligibility-cpn">
                              Active dates
                            </div>
                            <div className="input-box-Im input-height2 top-active-input">
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  value={inputValues.startDate}
                                  type="date"
                                  onChange={handleInputChange}
                                  name="startDate"
                                />
                                <label class="modify_lbl_text3">
                                  Start Date
                                </label>
                              </div>
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  value={inputValues.startTime}
                                  type="time"
                                  onChange={handleInputChange}
                                  name="startTime"
                                />
                                <label class="modify_lbl_text4">
                                  Start Time (IST)
                                </label>
                              </div>
                            </div>
                            <div className="flex-radio-cutomer-cpn top-box-coupon">
                              <div className="radio-height-cpn">
                                <input
                                  className="customer-radio-cpn"
                                  type="checkbox"
                                  name="customer"
                                  checked={inputValues.setEndDate}
                                  value={inputValues.setEndDate}
                                  onChange={handleCheckboxChangeDate}
                                />
                              </div>
                              <div className="all-customer-cpn">
                                Set end date
                              </div>
                            </div>
                            {isDateChecked && (
                              <div className="input-box-Im input-height2 top-active-input">
                                <div className="input-box-coupon">
                                  <input
                                    className="modify_im_input mode_category"
                                    value={inputValues.endDate}
                                    type="date"
                                    onChange={handleInputChange}
                                    name="endDate"
                                  />
                                  <label class="modify_lbl_text3">
                                    End Date
                                  </label>
                                </div>
                                <div className="input-box-coupon">
                                  <input
                                    className="modify_im_input mode_category"
                                    value={inputValues.endTime}
                                    type="time"
                                    onChange={handleInputChange}
                                    name="endTime"
                                  />
                                  <label class="modify_lbl_text4">
                                    End Time (IST)
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {tabcontent2 && (
                        <div className="">
                          {(selectedOptionCoupon == "1" ||
                            selectedOptionCoupon !== "3") &&
                            selectedOptionCoupon !== "3" && (
                              <div className="Coupon-box-template top-box-coupon">
                                <div>
                                  <div className="input-box-Im input-height2">
                                    <div className="input-box-coupon">
                                      <select
                                        className="modify_im_input mode_category"
                                        type="text"
                                        name="discountType"
                                        onChange={handleInputChange}
                                        value={inputValues.discountType}
                                      >
                                        <option value={1}>Percentage</option>
                                        <option value={2}>Fixed Amount</option>
                                      </select>
                                      <label class="modify_lbl_text3">
                                        Discount Value
                                      </label>
                                    </div>
                                    <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                      <div className="symbol-container-cpn">
                                        {inputValues.discountType == "2" && (
                                          <span className="symbol-left-cpn">
                                            â‚¹
                                          </span>
                                        )}
                                        <input
                                          className="modify_im_input mode_category value-cpn-padding"
                                          type="number"
                                          name="discountValue"
                                          placeholder={
                                            inputValues.discountType == "1"
                                              ? ""
                                              : "0.00"
                                          }
                                          value={inputValues.discountValue}
                                          onChange={handleInputChange}
                                          onBlur={handleInputBlur}
                                        />
                                        {inputValues.discountValue == "1" && (
                                          <span className="symbol-right-cpn">
                                            %
                                          </span>
                                        )}
                                      </div>
                                      {/* {showError && (
                                                                            <div className="error-message-cpn">
                                                                                <span className="alert-icon-cpn"><i class="fa-solid fa-circle-exclamation"></i></span>
                                                                                Discount value canâ€™t be blank
                                                                            </div>
                                                                        )} */}
                                    </div>
                                  </div>
                                  {selectedOptionCoupon == "2" && (
                                    <div>
                                      <div className="input-box-Im input-height2">
                                        <div className="input-box-coupon">
                                          <select
                                            className="modify_im_input mode_category"
                                            type="text"
                                            value={selectedOption}
                                            onChange={handleSelectChange}
                                          >
                                            <option value={1}>
                                              Specific Groups/Category
                                            </option>
                                            <option value={2}>
                                              Specific Items
                                            </option>
                                          </select>
                                          <label class="modify_lbl_text3">
                                            Applies To
                                          </label>
                                        </div>
                                      </div>
                                      <div className="input-box-Im input-height2">
                                        <div className="input-box-coupon search-container-cpn">
                                          <span className="search-icon-cpn">
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                          </span>
                                          <input
                                            className="modify_im_input mode_category search-cpn-padding"
                                            type="search"
                                            onChange={(e) =>
                                              setSearchVal(e.target.value)
                                            }
                                            value={searchVal}
                                            placeholder={
                                              selectedOption == "1"
                                                ? "Search Groups/Category"
                                                : "Search Items"
                                            }
                                            ref={inputRef}
                                          />

                                          {/* <div className="btn-browse-cpn">
                                                                                <button className="btn-browse" onClick={showModalCollection}>Browse1</button>
                                                                            </div>
                                                                            <div>
                                                                                {isModalOpenCollection && (
                                                                                    <div className="modal" >
                                                                                        <div className="AddNewItem-modal modal_collection-cpn">
                                                                                            <div className="modal-title">
                                                                                                <h3 className="modal-OrderLIst collection-color-cpn">
                                                                                                    {selectedOption == "Specific Groups/Category"
                                                                                                        ? "Add Groups/Category"
                                                                                                        : "Add Items"}
                                                                                                </h3>
                                                                                                <span className="close_modal" onClick={hideModalCollection}>
                                                                                                    &times;
                                                                                                </span>
                                                                                            </div>
                                                                                            {selectedOption == "Specific Groups/Category" ? (
                                                                                                <div className="ModalContentModalSettingP ">
                                                                                                    <ModelAddCollection />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="ModalContentModalSettingP ">
                                                                                                    <ModelAddProducts />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="footer-collection-cpn">
                                                                                                <div>0 collections selected</div>
                                                                                                <div className="flex-btn-cancel-add">
                                                                                                    <div>
                                                                                                        <button className="cancel-btn-cpn" onClick={hideModalCollection}>Cancel</button>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <button className="add-btn-cpn">Add</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div> */}
                                          <div
                                            className="dropdown-container"
                                            tabIndex={0}
                                            onKeyDown={handleKeyDown}
                                          >
                                            {showDropdown && (
                                              <ul
                                                className="suggestions-dropdown-coupon"
                                                ref={dropdownRef}
                                              >
                                                {suggestions.length > 0 &&
                                                  suggestions.map(
                                                    (item, index) => (
                                                      <li
                                                        key={item.id}
                                                        className={`suggestion-item-coupon ${
                                                          focusedIndex == index
                                                            ? "focused"
                                                            : ""
                                                        }`}
                                                        onClick={() =>
                                                          handleSelectItem(item)
                                                        }
                                                        onMouseLeave={
                                                          handleMouseLeave
                                                        }
                                                        onMouseEnter={() =>
                                                          handleMouseEnter(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        {item.name}
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            )}
                                          </div>

                                          <div className="selected-items-container-coupon">
                                            {selectedItems.map((item) => {
                                              const propertyName =
                                                relevantOptions.includes("2")
                                                  ? "d2Id"
                                                  : "d2_Id";

                                              return (
                                                <div
                                                  key={item[propertyName]}
                                                  className="selected-item-coupon"
                                                >
                                                  <span>{item.name}</span>
                                                  <button
                                                    className="remove-coupon-item-button"
                                                    onClick={() =>
                                                      handleRemoveItem(
                                                        item[propertyName]
                                                      )
                                                    }
                                                  >
                                                    x
                                                  </button>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                      {mode == "2" && (
                                        <div>
                                          <div className="flex-radio-cutomer-cpn">
                                            <div className="radio-height-cpn">
                                              <input
                                                className="customer-radio-cpn"
                                                type="checkbox"
                                                name="customer"
                                                checked={isCheckedDiscount}
                                                onChange={
                                                  handleCheckboxChangeDiscount
                                                }
                                              />
                                            </div>
                                            <div className="all-customer-cpn">
                                              Only apply discount once per order
                                            </div>
                                          </div>
                                          <div className="redeemable-cpn">
                                            If not selected,{" "}
                                            <strong>
                                              {value.trim()
                                                ? mode == "2"
                                                  ? `â‚¹${value}.00`
                                                  : ``
                                                : mode == "2"
                                                ? "the amount"
                                                : ""}
                                            </strong>{" "}
                                            the amount will be taken off each
                                            eligible item in an order.
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                          {(selectedOptionCoupon == 3 ||
                            selectedOptionCoupon == "3") && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                Countries
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="countries"
                                    value="1"
                                    checked={selectedOptionCountries == "1"}
                                    onChange={handleRadioChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  All Countries
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="countries"
                                    value="2"
                                    checked={selectedOptionCountries == "2"}
                                    onChange={handleRadioChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Selected Countries
                                </div>
                              </div>
                              {selectedOptionCountries == "2" && (
                                <div className="Top-customer-cpn">
                                  <div className="input-box-Im input-height2">
                                    <div className="input-box-coupon search-container-cpn">
                                      <span className="search-icon-cpn">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                      </span>
                                      <input
                                        className="modify_im_input mode_category search-cpn-padding"
                                        type="search"
                                        value={searchCountryVal}
                                        onChange={handleCountryInputChange}
                                        placeholder="Search Countries"
                                      />
                                    </div>
                                    {ShowCountryDropdown && (
                                      <div className="suggestions-country-dropdown">
                                        {CountrySuggestions.length > 0 ? (
                                          CountrySuggestions.map((country) => (
                                            <div
                                              key={country.id}
                                              className="suggestion-country-item"
                                              onClick={() =>
                                                handleSelectCountry(country)
                                              }
                                            >
                                              {country.name}
                                            </div>
                                          ))
                                        ) : (
                                          <span>No countries found</span>
                                        )}
                                      </div>
                                    )}
                                    <span>here</span>
                                    <div className="selected-items-container-coupon">
                                      {selectedCountries &&
                                        selectedCountries.map((item) => {
                                          return (
                                            <div
                                              key={item.id}
                                              className="selected-item-coupon"
                                            >
                                              <span>{item.name}</span>
                                              <button
                                                className="remove-coupon-item-button"
                                                // onClick={() => handleRemoveItem(item.id)}
                                              >
                                                x
                                              </button>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="customer-eligibility-cpn">
                                Shipping rates
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="countries"
                                    checked={isCheckedCountries}
                                    onChange={handleCheckboxChangeCountries}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Exclude shipping rates over a certain amount
                                </div>
                              </div>
                              {isCheckedCountries && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                      value={valueCountries}
                                      onChange={handleInputChangeCountries}
                                      onBlur={handleInputBlurCountries}
                                      placeholder="0.00"
                                    />
                                  </div>
                                  {showErrorCountries && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Shipping rate amount canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          {selectedOptionCoupon == "3" && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                {selectedOptionQuantity == "2"
                                  ? "Customer spends"
                                  : "Customer buys"}
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="item"
                                    value="1"
                                    checked={selectedOptionQuantity == "1"}
                                    onChange={handleRadioChangeQuantity}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum quantity of items
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="item"
                                    value="2"
                                    checked={selectedOptionQuantity == "2"}
                                    onChange={handleRadioChangeQuantity}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum purchase amount
                                </div>
                              </div>
                              <div className="input-box-Im input-height2 customer-buy">
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                  <div className="symbol-container-cpn">
                                    {selectedOptionQuantity == "2" && (
                                      <span className="symbol-left-cpn">
                                        â‚¹
                                      </span>
                                    )}
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding"
                                      type="text"
                                      placeholder={
                                        selectedOptionQuantity == "2"
                                          ? "0.00"
                                          : "Enter quantity"
                                      }
                                    />
                                    <label class="modify_lbl_text3">
                                      {selectedOptionQuantity == "2"
                                        ? "Amount"
                                        : "Quantity"}
                                    </label>
                                  </div>
                                </div>
                                <div className="input-box-coupon">
                                  <select
                                    className="modify_im_input mode_category"
                                    type="text"
                                    value={selectedOptionBuy}
                                    onChange={handleOptionChangeBuy}
                                  >
                                    <option value={1}>
                                      Specific Groups/Category
                                    </option>
                                    <option value={2}>Specific Items</option>
                                  </select>
                                  <label class="modify_lbl_text3">
                                    Any Items From
                                  </label>
                                </div>
                              </div>

                              <div className="Top-customer-cpn">
                                <div className="input-box-Im input-height2">
                                  <div className="input-box-coupon search-container-cpn">
                                    <span className="search-icon-cpn">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                    <input
                                      className="modify_im_input mode_category search-cpn-padding"
                                      type="search"
                                      placeholder={
                                        selectedOptionBuy == "2"
                                          ? "Search Items"
                                          : "Search Group/Category"
                                      }
                                    />
                                  </div>
                                  <div className="btn-browse-cpn">
                                    <button
                                      className="btn-browse"
                                      onClick={showModalProducts}
                                    >
                                      Browse
                                    </button>
                                  </div>
                                  <div>
                                    {isModalOpenProducts && (
                                      <div className="modal">
                                        <div className="AddNewItem-modal modal_collection-cpn">
                                          <div className="modal-title">
                                            {/* <h3 className="modal-OrderLIst collection-color-cpn">Add Products</h3> */}
                                            <h3 className="modal-OrderLIst collection-color-cpn">
                                              {selectedOptionBuy == "2"
                                                ? "Add Items"
                                                : "Add Groups/Category"}
                                            </h3>
                                            <span
                                              className="close_modal"
                                              onClick={hideModalProducts}
                                            >
                                              &times;
                                            </span>
                                          </div>
                                          {/* <div className="ModalContentModalSettingP ">
                                                                                        <ModelAddProducts />
                                                                                    </div> */}
                                          {selectedOptionBuy == "2" ? (
                                            <div className="ModalContentModalSettingP ">
                                              <ModelAddProducts />
                                            </div>
                                          ) : (
                                            <div className="ModalContentModalSettingP ">
                                              <ModelAddCollection />
                                            </div>
                                          )}
                                          <div className="footer-collection-cpn">
                                            <div>0 collections selected</div>
                                            <div className="flex-btn-cancel-add">
                                              <div>
                                                <button
                                                  className="cancel-btn-cpn"
                                                  onClick={hideModalProducts}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                              <div>
                                                <button className="add-btn-cpn">
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="customer-eligibility-cpn">
                                Customer gets
                              </div>
                              <div className="redeemable-cpn">
                                Customers must add the quantity of items
                                specified below to their cart.
                              </div>
                              <div className="input-box-Im input-height2 customer-buy">
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding"
                                      type="text"
                                    />
                                    <label class="modify_lbl_text3">
                                      Quantity
                                    </label>
                                  </div>
                                </div>
                                <div className="input-box-coupon">
                                  <select
                                    className="modify_im_input mode_category"
                                    type="text"
                                    value={selectedOptionGet}
                                    onChange={handleOptionChangeGet}
                                  >
                                    <option value={1}>
                                      Specific Groups/Category
                                    </option>
                                    <option value={2}>Specific Items</option>
                                  </select>
                                  <label class="modify_lbl_text3">
                                    Any Items From
                                  </label>
                                </div>
                              </div>
                              <div className="Top-customer-cpn">
                                <div className="input-box-Im input-height2">
                                  <div className="input-box-coupon search-container-cpn">
                                    <span className="search-icon-cpn">
                                      <i class="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                    <input
                                      className="modify_im_input mode_category search-cpn-padding"
                                      type="search"
                                      placeholder={
                                        selectedOptionGet == "2"
                                          ? "Search Items"
                                          : "Search Group/Category"
                                      }
                                    />
                                  </div>
                                  <div className="btn-browse-cpn">
                                    <button
                                      className="btn-browse"
                                      onClick={showModalBrowse}
                                    >
                                      Browse
                                    </button>
                                  </div>
                                  <div>
                                    {isModalOpenBrowse && (
                                      <div className="modal">
                                        <div className="AddNewItem-modal modal_collection-cpn">
                                          <div className="modal-title">
                                            {/* <h3 className="modal-OrderLIst collection-color-cpn">Add Groups/Category</h3>
                                             */}
                                            <h3 className="modal-OrderLIst collection-color-cpn">
                                              {selectedOptionGet == "2"
                                                ? "Add Items"
                                                : "Add Groups/Category"}
                                            </h3>
                                            <span
                                              className="close_modal"
                                              onClick={hideModalBrowse}
                                            >
                                              &times;
                                            </span>
                                          </div>
                                          {/* <div className="ModalContentModalSettingP ">
                                                                                        <ModelAddCollection />
                                                                                    </div> */}

                                          {selectedOptionGet == "2" ? (
                                            <div className="ModalContentModalSettingP ">
                                              <ModelAddProducts />
                                            </div>
                                          ) : (
                                            <div className="ModalContentModalSettingP ">
                                              <ModelAddCollection />
                                            </div>
                                          )}
                                          <div className="footer-collection-cpn">
                                            <div>0 collections selected</div>
                                            <div className="flex-btn-cancel-add">
                                              <div>
                                                <button
                                                  className="cancel-btn-cpn"
                                                  onClick={hideModalBrowse}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                              <div>
                                                <button className="add-btn-cpn">
                                                  Add
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="customer-eligibility-cpn">
                                At a discounted value
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value="percentage"
                                    checked={selectedDiscount == "1"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Percentage
                                </div>
                              </div>
                              {selectedDiscount == "1" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-right-cpn percentage-right-cpn">
                                      %
                                    </span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value="2"
                                    checked={selectedDiscount == "2"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Amount off each
                                </div>
                              </div>
                              {selectedDiscount == "2" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                      placeholder="0.00"
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    For multiple quantities, the discount amount
                                    will be taken off each Y item.
                                  </span>
                                </div>
                              )}
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="discount"
                                    value="3"
                                    checked={selectedDiscount == "3"}
                                    onChange={handleRadioChangeDiscount}
                                  />
                                </div>
                                <div className="all-customer-cpn">Free</div>
                              </div>

                              <hr></hr>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="order"
                                    checked={isCheckedOrder}
                                    onChange={handleCheckboxChangeOrder}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Set a maximum number of uses per order
                                </div>
                              </div>
                              {isCheckedOrder && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="number"
                                      value={valueOrd}
                                      onChange={handleInputChangeModeOder}
                                      onBlur={handleInputBlurOrder}
                                    />
                                  </div>
                                  {showErrorOrder && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Maximum number of uses per order canâ€™t
                                      be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="Coupon-box-template top-box-coupon">
                            <div className="customer-eligibility-cpn">
                              Availability
                            </div>
                            <div className="redeemable-cpn">
                              This discount is automatically applied to all
                              sales channels.
                            </div>
                            <div className="flex-radio-cutomer-cpn">
                              <div className="radio-height-cpn">
                                <input
                                  className="customer-radio-cpn"
                                  type="checkbox"
                                  name="pos"
                                  checked={isPOSChecked}
                                  onChange={handleCheckboxChangePos}
                                />
                              </div>
                              <div className="all-customer-cpn">
                                Also offer on Point of Sale (POS Pro locations
                                only)
                              </div>
                            </div>
                          </div>
                          {selectedOptionCoupon !== "3" && (
                            <div className="Coupon-box-template top-box-coupon">
                              <div className="customer-eligibility-cpn">
                                Minimum Purchase Requirements
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="1"
                                    checked={selectedOptionMinimum == "1"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  No Minimum requirements
                                </div>
                              </div>
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="2"
                                    checked={selectedOptionRadio == "2"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum purchase amount (â‚¹)
                                </div>
                              </div>
                              {selectedOptionRadio == "2" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <span className="symbol-left-cpn">â‚¹</span>
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="text"
                                      placeholder="0.00"
                                      value={value1}
                                      onChange={handleInputChangeModeRadio}
                                      onBlur={handleInputBlurRadio}
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    Applies only to selected collections.
                                  </span>
                                  {showErrorRadio && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Minimum purchase canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="radio"
                                    name="customer"
                                    value="3"
                                    checked={selectedOptionRadioItem == "3"}
                                    onChange={handleOptionChange}
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Minimum quantity of items
                                </div>
                              </div>
                              {selectedOptionRadioItem == "3" && (
                                <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                                  <div className="symbol-container-cpn">
                                    <input
                                      className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                      type="number"
                                      value={value2}
                                      placeholder=""
                                      onChange={handleInputChangeModeItem}
                                      onBlur={handleInputBlurRadioItem}
                                    />
                                  </div>
                                  <span className="selected-collection-cpn">
                                    Applies only to selected collections.
                                  </span>
                                  {showErrorRadioItem && (
                                    <div className="error-message-cpn">
                                      <span className="alert-icon-cpn">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                      </span>
                                      Minimum quantity canâ€™t be blank
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="Coupon-box-template top-box-coupon">
                            <div className="customer-eligibility-cpn">
                              Combinations
                            </div>
                            <div className="redeemable-cpn">
                              {codeValue ? (
                                <div className="customer-eligibility-cpn">
                                  {codeValue} can be combined with
                                </div>
                              ) : (
                                <div className="customer-eligibility-cpn">
                                  This product discount can be combined with
                                </div>
                              )}
                            </div>
                            {selectedOptionCoupon !== "1" && (
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="customer"
                                    checked={
                                      selectedOptionCombined == "product"
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeCombined(
                                        selectedOptionCombined == "product"
                                          ? ""
                                          : "product"
                                      )
                                    }
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Product discounts
                                </div>
                              </div>
                            )}
                            {selectedOptionCombined == "product" && (
                              <div className="all-customer-cpn">
                                No product discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one product discount that combines with
                                product discounts.
                              </div>
                            )}

                            {selectedOptionCoupon !== "2" &&
                              selectedOptionCoupon !== "1" &&
                              selectedOptionCoupon !== "3" && (
                                <div className="flex-radio-cutomer-cpn">
                                  <div className="radio-height-cpn">
                                    <input
                                      className="customer-radio-cpn"
                                      type="checkbox"
                                      name="customer"
                                      checked={
                                        selectedOptionCombined == "order"
                                      }
                                      onChange={() =>
                                        handleCheckboxChangeCombined(
                                          selectedOptionCombined == "order"
                                            ? ""
                                            : "order"
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="all-customer-cpn">
                                    Order discounts
                                  </div>
                                </div>
                              )}
                            {selectedOptionCombined == "order" && (
                              <div className="all-customer-cpn">
                                No order discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one order discount that combines with
                                product discounts.
                              </div>
                            )}
                            {selectedOptionCoupon !== "3" && (
                              <div className="flex-radio-cutomer-cpn">
                                <div className="radio-height-cpn">
                                  <input
                                    className="customer-radio-cpn"
                                    type="checkbox"
                                    name="customer"
                                    checked={
                                      selectedOptionCombined == "shipping"
                                    }
                                    onChange={() =>
                                      handleCheckboxChangeCombined(
                                        selectedOptionCombined == "shipping"
                                          ? ""
                                          : "shipping"
                                      )
                                    }
                                  />
                                </div>
                                <div className="all-customer-cpn">
                                  Shipping discounts
                                </div>
                              </div>
                            )}
                            {selectedOptionCombined == "shipping" && (
                              <div className="all-customer-cpn">
                                No shipping discounts are set to combine. To let
                                customers use more than one discount, set up at
                                least one shipping discount that combines with
                                product discounts.
                              </div>
                            )}
                          </div>

                          <div className="Coupon-box-template top-box-coupon">
                            <div className="customer-eligibility-cpn">
                              Active dates
                            </div>
                            <div className="input-box-Im input-height2 top-active-input">
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  value={inputValues.startDate}
                                  type="date"
                                  onChange={handleInputChange}
                                  name="startDate"
                                />
                                <label class="modify_lbl_text3">
                                  Start Date
                                </label>
                              </div>
                              <div className="input-box-coupon">
                                <input
                                  className="modify_im_input mode_category"
                                  value={inputValues.startTime}
                                  type="time"
                                  onChange={handleInputChange}
                                  name="startTime"
                                />
                                <label class="modify_lbl_text4">
                                  Start Time (IST)
                                </label>
                              </div>
                            </div>
                            <div className="flex-radio-cutomer-cpn top-box-coupon">
                              <div className="radio-height-cpn">
                                <input
                                  className="customer-radio-cpn"
                                  type="checkbox"
                                  name="customer"
                                  checked={inputValues.setEndDate}
                                  value={inputValues.setEndDate}
                                  onChange={handleCheckboxChangeDate}
                                />
                              </div>
                              <div className="all-customer-cpn">
                                Set end date
                              </div>
                            </div>
                            {isDateChecked && (
                              <div className="input-box-Im input-height2 top-active-input">
                                <div className="input-box-coupon">
                                  <input
                                    className="modify_im_input mode_category"
                                    value={inputValues.endDate}
                                    type="date"
                                    onChange={handleInputChange}
                                    name="endDate"
                                  />
                                  <label class="modify_lbl_text3">
                                    End Date
                                  </label>
                                </div>
                                <div className="input-box-coupon">
                                  <input
                                    className="modify_im_input mode_category"
                                    value={inputValues.endTime}
                                    type="time"
                                    onChange={handleInputChange}
                                    name="endTime"
                                  />
                                  <label class="modify_lbl_text4">
                                    End Time (IST)
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ***************************************************************************** Right Part ****************************************************** */}

                  {tabcontent1 && (
                    <div
                      className="company-info-top-right"
                      style={{ width: "50%" }}
                    >
                      <div className="Coupon-box-template top-box-coupon">
                        <div className="customer-eligibility-cpn">
                          Customer Eligibility
                        </div>
                        <div className="redeemable-cpn">
                          Redeemable on all sales channels you have set up
                        </div>
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn">
                            <input
                              className="customer-radio-cpn"
                              type="radio"
                              name="eligibility"
                              value="1"
                              checked={selectedOptionCustomer == "1"}
                              onChange={handleOptionChangeCustomer}
                            />
                          </div>
                          <div className="all-customer-cpn">All Customers</div>
                        </div>
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn">
                            <input
                              className="customer-radio-cpn"
                              type="radio"
                              name="eligibility"
                              value="2"
                              checked={selectedOptionCustomer == "2"}
                              onChange={handleOptionChangeCustomer}
                            />
                          </div>
                          <div className="all-customer-cpn">
                            Specific customer segments(Sundry Debtor)
                          </div>
                        </div>
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn">
                            <input
                              className="customer-radio-cpn"
                              type="radio"
                              name="eligibility"
                              value="3"
                              checked={selectedOptionCustomer == "3"}
                              onChange={handleOptionChangeCustomer}
                            />
                          </div>
                          <div className="all-customer-cpn">
                            Specific Customers
                          </div>
                        </div>
                        {(selectedOptionCustomer == "2" ||
                          selectedOptionCustomer == "3") && (
                          <div className="Top-customer-cpn">
                            <div className="input-box-Im input-height2">
                              <div className="input-box-coupon search-container-cpn">
                                <span className="search-icon-cpn">
                                  <i class="fa-solid fa-magnifying-glass"></i>
                                </span>
                                <input
                                  className="modify_im_input mode_category search-cpn-padding"
                                  type="search"
                                  placeholder={
                                    selectedOptionCustomer == "2"
                                      ? "Search Customer Segments"
                                      : "Search Customers"
                                  }
                                  value={searchVal3}
                                  onChange={handleInputChangeCustomer}
                                />
                                {showDropdownCustomer && (
                                  <div className="suggestions-dropdown-customer">
                                    {suggestionsCustomer.map((suggestion) => (
                                      <div
                                        key={suggestion.d2Id}
                                        className="suggestion-item-customer"
                                        onClick={() =>
                                          handleSelectSuggestionCustomer(
                                            suggestion
                                          )
                                        }
                                      >
                                        {suggestion.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <div className="selected-items-container-coupon">
                                  {selectedCustomer.length > 0 &&
                                    selectedCustomer.map((item) => {
                                      return (
                                        <div
                                          key={item.d2Id}
                                          className="selected-item-coupon"
                                        >
                                          <span>{item.name}</span>
                                          <button
                                            className="remove-coupon-item-button"
                                            onClick={() =>
                                              handleRemoveCustomer(item.d2Id)
                                            }
                                          >
                                            x
                                          </button>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className="all-customer-cpn">
                              {selectedOptionCustomer == "2"
                                ? "Select customer segments that can use this discount."
                                : ""}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="Coupon-box-template top-box-coupon">
                        <div className="customer-eligibility-cpn">
                          Maximum discount uses
                        </div>
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn">
                            <input
                              className="customer-radio-cpn"
                              type="checkbox"
                              name="customer"
                              value="1"
                              onChange={handleCheckboxChange}
                              checked={isChecked == "1"}
                            />
                          </div>
                          <div className="all-customer-cpn">
                            Limit number of times this discount can be used in
                            total
                          </div>
                        </div>
                        {isChecked == "1" && (
                          <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn purchase-margin-cpn">
                            <div className="symbol-container-cpn">
                              <input
                                className="modify_im_input mode_category value-cpn-padding input-cpn-puramt"
                                type="number"
                                value={valueLimit}
                                placeholder=""
                                onChange={handleInputChangeLimit}
                                onBlur={handleInputBlurLimit}
                              />
                            </div>
                            {showErrorLimit && (
                              <div className="error-message-cpn">
                                <span className="alert-icon-cpn">
                                  <i className="fa-solid fa-circle-exclamation"></i>
                                </span>
                                Total usage limit canâ€™t be blank
                              </div>
                            )}
                          </div>
                        )}
                        <div className="flex-radio-cutomer-cpn">
                          <div className="radio-height-cpn">
                            <input
                              className="customer-radio-cpn"
                              type="checkbox"
                              name="customer"
                              value="2"
                              onChange={handleCheckboxChange}
                              checked={isChecked == "2"}
                            />
                          </div>
                          <div className="all-customer-cpn">
                            Limit to one use per customer
                          </div>
                        </div>
                      </div>


                    </div>
                  )}
                    

                  {/* ************************************* Extra Details *********************************** */}
                  {/* {tabcontent1 && (
                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                            <div className="stock-im-1">
                                                <div className="Coupon-box-template">
                                                    <div>
                                                        {inputValue ? (
                                                            <div className="customer-eligibility-cpn">
                                                                {inputValue}
                                                            </div>
                                                        ) : (
                                                            <div className="customer-eligibility-cpn">NO discount code yet</div>
                                                        )}
                                                        <div className="redeemable-cpn">Code</div>
                                                    </div>
                                                    <div className="top-type-cpn">
                                                        <div className="customer-eligibility-cpn">Type</div>
                                                        {selectedOptionCoupon == "2" && (
                                                            <div className="redeemable-cpn">Amount off products</div>
                                                        )}

                                                        {selectedOptionCoupon == "1" && (
                                                            <div className="redeemable-cpn">Amount off Order</div>
                                                        )}


                                                        {selectedOptionCoupon == "3" && (
                                                            <div className="redeemable-cpn">Buy X get Y</div>
                                                        )}

                                                        {selectedOptionCoupon == "3" && (
                                                            <div className="redeemable-cpn">Free Shipping</div>
                                                        )}
                                                    </div>
                                                    <div className="top-type-cpn">
                                                        <div className="customer-eligibility-cpn">Details</div>
                                                        <div>
                                                            <ul className="ul-cpn-detail">
                                                                {isCheckedChannels ? (
                                                                    <li className="li-cpn-detail">For Online Store and 1 sales channel</li>
                                                                ) : (
                                                                    <li className="li-cpn-detail">For Online Store</li>
                                                                )}
                                                                {value && (
                                                                    <li className="li-cpn-detail">
                                                                        {mode == "Percentage"
                                                                            ? `${value}% Off Collections `
                                                                            : `â‚¹${value}.00 Off Collections`}
                                                                    </li>
                                                                )}

                                                                {mode == "2" && (
                                                                    <ul className="coupon-details">
                                                                        {isCheckedDiscount ? (
                                                                            <li className="li-cpn-detail">Applies once per order</li>
                                                                        ) : (
                                                                            <li className="li-cpn-detail">Applies to each eligible item per order</li>
                                                                        )}
                                                                    </ul>
                                                                )}
                                                                {selectedOptionCoupon !== "1" && selectedOptionCoupon !== "2" && (
                                                                    <ul>
                                                                        {selectedOptionCountries == "allcountries" && (
                                                                            <>
                                                                                <li className="li-cpn-detail">Free shipping on all products</li>
                                                                                <li className="li-cpn-detail">For all countries</li>
                                                                            </>
                                                                        )}
                                                                        {selectedOptionCountries == "selectedcountries" && (
                                                                            <li className="li-cpn-detail">Free shipping on all products</li>
                                                                        )}
                                                                    </ul>
                                                                )}

                                                                {!(selectedOptionRadio == "Minimum purchase amount" || selectedOptionRadio == "Minimum quantity of items") && (
                                                                    <li className="li-cpn-detail">No minimum purchase requirement</li>
                                                                )}
                                                                {!(selectedOptionCustomer == "Specific customer segments" || selectedOptionCustomer == "Specific Customers") && (
                                                                    <li className="li-cpn-detail">All customers</li>
                                                                )}
                                                                <li className="li-cpn-detail">NO usage limit</li>
                                                                <li className="li-cpn-detail">Can`t combine with other discounts</li>
                                                                {selectedOptionCombined == "product" && (
                                                                    <li className="li-cpn-detail">Combines with product discounts</li>
                                                                )}
                                                                {selectedOptionCombined == "order" && (
                                                                    <li className="li-cpn-detail">Combines with order discounts</li>
                                                                )}
                                                                {selectedOptionCombined == "shipping" && (
                                                                    <li className="li-cpn-detail">Combines with product and shipping discounts</li>
                                                                )}

                                                                <li className="li-cpn-detail">Active from today</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="Coupon-box-template top-box-coupon">
                                                    <div className="flex-products-dis-coupons">
                                                        <div className="Amount-off-products-coupon">Sales channels</div>
                                                        <div className="product-discount-coupon">0 of 1 selected</div>
                                                    </div>
                                                    <div className="flex-radio-cutomer-cpn">
                                                        <div className="radio-height-cpn">
                                                            <input className="customer-radio-cpn"
                                                                type="checkbox"
                                                                name="customer"
                                                                onChange={handleCheckboxChangeChannels}
                                                                checked={isCheckedChannels}
                                                            />
                                                        </div>
                                                        <div className="all-customer-cpn">
                                                            Point of Sale
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex-save-discard">
                                                    <div className="discard-delete-btn-cpn">
                                                        <i class="fa-regular fa-trash-can discard-icon"></i>
                                                        <button className="btn-discard">Discard</button>
                                                    </div>
                                                    <div>
                                                        <button className="btn-save-discount">Save Discount</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                    {tabcontent2 && (
                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                            <div className="stock-im-1">
                                                <div className="Coupon-box-template">
                                                    <div>
                                                        {inputValue ? (
                                                            <div className="customer-eligibility-cpn">
                                                                {inputValue}
                                                            </div>
                                                        ) : (
                                                            <div className="customer-eligibility-cpn">NO title yet</div>
                                                        )}
                                                        <div className="redeemable-cpn">Automatic</div>
                                                    </div>
                                                    <div className="top-type-cpn">
                                                        <div className="customer-eligibility-cpn">Type</div>
                                                        {selectedOptionCoupon == "2" && (
                                                            <div className="redeemable-cpn">Amount off products</div>
                                                        )}

                                                        {selectedOptionCoupon == "1" && (
                                                            <div className="redeemable-cpn">Amount off Order</div>
                                                        )}

                                                        {selectedOptionCoupon == "3" && (
                                                            <div className="redeemable-cpn">Buy X get Y</div>
                                                        )}

                                                        {selectedOptionCoupon == "3" && (
                                                            <div className="redeemable-cpn">Free Shipping</div>
                                                        )}
                                                    </div>
                                                    <div className="top-type-cpn">
                                                        <div className="customer-eligibility-cpn">Details</div>
                                                        <div>
                                                            <ul className="ul-cpn-detail">
                                                                {isPOSChecked ? (
                                                                    <li className="li-cpn-detail">For Online Store and 1 sales channel</li>
                                                                ) : (
                                                                    <li className="li-cpn-detail">For Online Store</li>
                                                                )}
                                                                {value && (
                                                                    <li className="li-cpn-detail">
                                                                        {mode == "Percentage"
                                                                            ? `${value}% Off Collections `
                                                                            : `â‚¹${value}.00 Off Collections`}
                                                                    </li>
                                                                )}
                                                                {selectedOptionCoupon !== "1" && selectedOptionCoupon !== "2" && (
                                                                    <ul>
                                                                        {selectedOptionCountries == "allcountries" && (
                                                                            <>
                                                                                <li className="li-cpn-detail">Free shipping on all products</li>
                                                                                <li className="li-cpn-detail">For all countries</li>
                                                                            </>
                                                                        )}
                                                                        {selectedOptionCountries == "selectedcountries" && (
                                                                            <li className="li-cpn-detail">Free shipping on all products</li>
                                                                        )}
                                                                    </ul>
                                                                )}

                                                                {mode == "2" && (
                                                                    <ul className="coupon-details">
                                                                        {isCheckedDiscount ? (
                                                                            <li className="li-cpn-detail">Applies once per order</li>
                                                                        ) : (
                                                                            <li className="li-cpn-detail">Applies to each eligible item per order</li>
                                                                        )}
                                                                    </ul>
                                                                )}
                                                                {!(selectedOptionRadio == "Minimum purchase amount" || selectedOptionRadio == "Minimum quantity of items") && (
                                                                    <li className="li-cpn-detail">No minimum purchase requirement</li>
                                                                )}

                                                                {!(selectedOptionCombined == "product" || selectedOptionCombined == "order" || selectedOptionCombined == "shipping") && (
                                                                    <li className="li-cpn-detail">Can`t combine with other discounts</li>
                                                                )}
                                                                {selectedOptionCombined == "product" && (
                                                                    <li className="li-cpn-detail">Combines with product discounts</li>
                                                                )}
                                                                {selectedOptionCombined == "order" && (
                                                                    <li className="li-cpn-detail">Combines with order discounts</li>
                                                                )}
                                                                {selectedOptionCombined == "shipping" && (
                                                                    <li className="li-cpn-detail">Combines with shipping discounts</li>
                                                                )}

                                                                <li className="li-cpn-detail">Active from today</li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="flex-save-discard">
                                                        <div className="discard-delete-btn-cpn">
                                                            <i class="fa-regular fa-trash-can"></i>
                                                            <button className="btn-discard">Discard</button>
                                                        </div>
                                                        <div>
                                                            <button className="btn-save-discount">Save Discount</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponMaster;
