import React, { useContext, useEffect, useState } from "react";
import AccountAPI from "../../API/APIServices/Account";
import AlertBox from "../Props/AlertBox";
import Loader from "../Props/Loader";
import { Alert } from "bootstrap";
import MyContext from "../MyContext";

const BillInvoiceModal = (props) => {
  const [AccountData, setAccountData] = useState([]);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    handleModalClick,
  } = useContext(MyContext);
  const {
    CloseSettingModal,
    inputValuesPaymentStatic,
    handleInputChangeActivePaymentMode,
    SaveBtnPaymentMode,
    inputValuesPaidAmount,
    handleInputChangePaymentMode,
    billSeriesId,
    inputLayout,
    loading,
    hideModal,
    PageName,
  } = props;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterData = [
          {
            fieldName: "parentGroupId",
            operatorName: "NotEqual",
            compareValue: "211",
          },
          {
            fieldName: "parentGroupId",
            operatorName: "NotEqual",
            compareValue: "212",
          },
          {
            fieldName: "parentGroupId",
            operatorName: "NotEqual",
            compareValue: "213",
          },
        ];

        const data = await AccountAPI.getAccountWithFilter(filterData);
        setAccountData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    // <div className="modal-back">
    //   <div
    //     className="partyDetail-modal"
    //     style={{ height: "auto", backgroundColor: "white", width: "52%" }}
    //   >
    //     <div style={{ padding: "15px" }}>
    //       <div style={{ display: "flex", justifyContent: "space-between" }}>
    //         <label>Party Settlement and Payment Mode</label>
    //         <label
    //           style={{ cursor: "pointer", fontSize: "24px" }}
    //           onClick={CloseSettingModal}
    //         >
    //           x
    //         </label>
    //       </div>
    //       <hr />
    //       <div className="">
    //         <table
    //           id="tblTransactionList"
    //           className="table_hd1 table-bordered1"
    //         >
    //           <thead
    //             textAlign="center"
    //             className="thead_scroll_col12"
    //             style={{ background: "rgb(33, 46, 79)", color: "white" }}
    //           >
    //             <tr>
    //               <th style={{ width: "150px", textAlign: "center" }}>Mode</th>
    //               <th style={{ width: "150px", textAlign: "center" }}>
    //                 Assign
    //               </th>
    //               <th style={{ width: "150px", textAlign: "center" }}>
    //                 Party Name
    //               </th>
    //               <th style={{ width: "150px", textAlign: "center" }}>Title</th>
    //             </tr>
    //           </thead>
    //           <tbody className="tbody_body_m1">
    //             {inputValuesPaymentStatic.map((data1, index) => (
    //               <tr key={data1.id}>
    //                 <td style={{ textAlign: "center" }}>{data1.PaymentMode}</td>
    //                 <td style={{ textAlign: "center" }}>
    //                   <input
    //                     type="checkbox"
    //                     className="checkbox-input tableCardSide"
    //                     name="assignToShow"
    //                     checked={data1.assignToShow}
    //                     onChange={(e) =>
    //                       handleInputChangeActivePaymentMode(index, e)
    //                     }
    //                   />
    //                 </td>
    //                 <td style={{ textAlign: "center" }}>
    //                   <select
    //                     type="text"
    //                     className="modify_im_input mode_limit mode_category"
    //                     onChange={(e) => handleInputChangePaymentMode(index, e)}
    //                     name="accountId"
    //                     value={data1.accountId}
    //                     disabled={
    //                       !data1.assignToShow || data1.disabled === "enabled"
    //                     }
    //                   >
    //                     <option Classname="form-control s-1">Select Acc</option>
    //                     {(() => {
    //                       if (
    //                         AccountData !== null &&
    //                         Array.isArray(AccountData)
    //                       ) {
    //                         return AccountData.map((data) => (
    //                           <option key={data.d2Id} value={data.d2Id}>
    //                             {data.name}
    //                           </option>
    //                         ));
    //                       }
    //                     })()}
    //                   </select>
    //                 </td>
    //                 <td style={{ textAlign: "center" }}>
    //                   <input
    //                     type="text"
    //                     className="modify_im_input mode_limit mode_category"
    //                     onChange={(e) => handleInputChangePaymentMode(index, e)}
    //                     name="accountTitle"
    //                     value={data1.accountTitle}
    //                     disabled={
    //                       !data1.assignToShow || data1.disabled === "enabled"
    //                     }
    //                   />
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //     </div>
    //     <hr className="mt-2" />
    //     <div className="flex-end" style={{ padding: "5px" }}>
    //       <button
    //         className="greenBtn"
    //         style={{ width: "15%" }}
    //         onClick={SaveBtnPaymentMode}
    //       >
    //         Save
    //       </button>
    //       <button
    //         className="redBtn"
    //         style={{ width: "15%" }}
    //         onClick={CloseSettingModal}
    //       >
    //         Close
    //       </button>
    //     </div>
    //   </div>

    //   {alertBox && (
    //     <AlertBox
    //       HeadingTop="Saved"
    //       Heading="Your Bill Series Master is Saved"
    //       onYesClick={OpenAlertBox}
    //       singleButton={true}
    //       OpenAlertBox={OpenAlertBox}
    //     />
    //   )}
    //   {alertBoxEdit && (
    //     <AlertBox
    //       HeadingTop="Updated"
    //       Heading="Your Bill Series Master is Updated"
    //       onYesClick={OpenAlertBoxEdit}
    //       singleButton={true}
    //       OpenAlertBox={OpenAlertBoxEdit}
    //     />
    //   )}

    //   {loading && <Loader />}
    // </div>

    <div className="modal">
      <div
        onClick={handleModalClick}
        className="OpticalField-modal PaymentSattlementModal"
        style={{ width: "auto" }}
      >
        <div className="modal-title">
          <span className="close_modal" onClick={hideModal}>
            &times;
          </span>
        </div>
        <div className="">
          <div className="tab-content-modal" style={{ height: "max-content" }}>
            <div
              className="company-info-top-modal control_flex_modal item_scroll"
              style={{ height: "auto" }}
            >
              <div className="AddBillHeadText" style={{ paddingBottom: "2px" }}>
                <span
                  style={{
                    color: "red",
                    fontSize: "18px",
                    fontWeight: "600",
                    justifyContent: "center",
                  }}
                >
                  Party Settlement and Payment Mode
                </span>
              </div>
              <div
                className="HeadContentInputBpx"
                style={{ paddingBottom: "0px" }}
              >
                <div className="input-box-m11">
                  <div className=" mode_category">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <label class="InputTextBoxLabel5 ">Bill Type:</label>
                      <input
                        style={{ width: "50%" }}
                        className="modify_im_input InputH_6 mode_category disabledInput"
                        type="text"
                        // value={inputLayout?.billTypeId || billSeriesId}
                        value="Sale Series"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-box-m11">
                  <div className=" mode_category">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <label class="InputTextBoxLabel5 ">Series:</label>
                      <input
                        style={{ width: "50%" }}
                        className="modify_im_input InputH_6 mode_category disabledInput"
                        type="text"
                        value={inputValuesPaidAmount?.[0]?.billSeriesName || ""} // Corrected line

                        // value={inputLayout?.billSeriesName}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div
                className="HeadContentInputBpx"
                style={{ paddingTop: "0px" }}
              >
                <div
                  className="table-container-s1"
                  style={{
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <table
                    id="tblTransactionList"
                    className="table_hd1 table-bordered1"
                  >
                    <thead
                      textAlign="center"
                      className="thead_scroll_col12"
                      style={{
                        background: "rgb(33, 46, 79)",
                        color: "white",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            width: "80px",
                            textAlign: "center",
                          }}
                        >
                          Mode
                        </th>
                        <th
                          style={{
                            width: "80px",
                            textAlign: "center",
                          }}
                        >
                          Assign
                        </th>
                        <th
                          style={{
                            width: "200px",
                            textAlign: "center",
                          }}
                        >
                          Party Name
                        </th>
                        <th
                          style={{
                            width: "200px",
                            textAlign: "center",
                          }}
                        >
                          Title
                        </th>
                        {PageName !== "saleInvoice" && (
                          <th
                            style={{
                              width: "150px",
                              textAlign: "center",
                            }}
                          >
                            Is Enable/Disable
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="tbody_body_m1">
                      {inputValuesPaymentStatic.map((data1, index) => (
                        <tr key={data1.id}>
                          <td style={{ textAlign: "center" }}>
                            {data1.PaymentMode}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="checkbox"
                              className="checkbox-input tableCardSide"
                              name="assignToShow"
                              checked={data1.assignToShow}
                              onChange={(e) =>
                                handleInputChangeActivePaymentMode(index, e)
                              }
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <select
                              type="text"
                              className="modify_im_input mode_limit mode_category"
                              onChange={(e) =>
                                handleInputChangePaymentMode(index, e)
                              }
                              name="accountId"
                              value={data1.accountId}
                              disabled={
                                !data1.assignToShow ||
                                data1.disabled === "enabled"
                              }
                            >
                              <option Classname="form-control s-1">
                                Select Acc
                              </option>
                              {(() => {
                                if (
                                  AccountData !== null &&
                                  Array.isArray(AccountData)
                                ) {
                                  return AccountData.filter(
                                    (x) =>
                                      x.parentGroupId === 203 ||
                                      x.parentGroupId === 202
                                  ).map((data) => (
                                    <option key={data.d2Id} value={data.d2Id}>
                                      {data.name}
                                    </option>
                                  ));
                                }
                              })()}
                            </select>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              className="modify_im_input mode_limit mode_category"
                              onChange={(e) =>
                                handleInputChangePaymentMode(index, e)
                              }
                              name="accountTitle"
                              value={data1.accountTitle}
                              disabled={
                                !data1.assignToShow ||
                                data1.disabled === "enabled"
                              }
                            />
                          </td>
                          {PageName !== "saleInvoice" && (
                            <td style={{ textAlign: "center" }}>
                              <input
                                type="checkbox"
                                className="checkbox-input tableCardSide"
                                name="display"
                                checked={data1.display}
                                onChange={(e) =>
                                  handleInputChangeActivePaymentMode(index, e)
                                }
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "5px",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "15px",
                  padding: "15px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div className="flex-center">
                    <button
                      onClick={SaveBtnPaymentMode}
                      style={{ borderRadius: "6px" }}
                      className="Add_btn MasterSettingSaveBtn"
                    >
                      Save
                    </button>
                  </div>
                  <div className="flex-center">
                    <button
                      onClick={hideModal}
                      style={{
                        borderRadius: "6px",
                        background: "white",
                        color: "black",
                      }}
                      className="Add_btn MasterSettingSaveBtn"
                    >
                      Back
                    </button>
                  </div>
                </div>

                <span
                  style={{
                    color: "red",
                    paddingLeft: "5px",
                    textAlign: "center",
                  }}
                >
                  *Please select checkbox for enter payment mode
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillInvoiceModal;
