import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const ChildCategory = (props) => {
  const [isLink2Visible,] = useState(props.showLink2);
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(); // Call the onClick function if it exists
    }
    if (props.Link) {
      navigate(props.Link); // Navigate to the URL provided in props.Link
    }
    window.location.reload(); // Reload the page
  };

  const handleClick2 = () => {
    debugger
    if (props.handleLink2Click) {
      props.handleLink2Click();
    }
  };


  return (
    <>
      <div className="yes-bm-child-category-box ">
        <i className="fa-solid fa-caret-right floting-arrow"></i>
        <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', margin: '0px', width: '220px', height: '35px', textAlign: 'center' }} onClick={handleClick}>
          {props.heading}

        </p>
        {isLink2Visible && (
          <Link to={props.Link2} onClick={handleClick2} className="fixed-arrow">
            <i className="fa-solid fa-plus"></i>
          </Link>
        )}

      </div>


    </>
  )
}

export default ChildCategory