import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import MyContext from '../../../Inventory/MyContext';

const Link = (props, { initialWidthPercent = 100, initialHeight = 100 }) => {
  const { setDroppedItems, setTemplateData, handleContentChange, handleStyleChange, stylesArray } = useContext(MyContext);
  const [isEditing, setIsEditing] = useState(false);
  const [linkText, setLinkText] = useState('');
  const [linkHref, setLinkHref] = useState('');
  const [hrefWithProtocol, setHrefWithProtocol] = useState('');
  // const [timeoutId, setTimeoutId] = useState(null);
  const divRef = useRef(null);
  const getId = props.keyValueId;

  const [hoveredComponentId, setHoveredComponentId] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredComponentId(id);
  };

  const handleMouseLeave = () => {
    setHoveredComponentId(null);
  };

  useEffect(() => {
    const matchingStyle = stylesArray.find(item => item.id === getId);
    if (matchingStyle) {
      setLinkText(matchingStyle.content || '');
      setLinkHref(matchingStyle.link);
    } else {
      // setLinkText('');
      // setLinkHref('');
    }
  }, [getId, stylesArray]);

  const [dimensions, setDimensions] = useState({
    widthPercent: initialWidthPercent,
    minHeight: initialHeight,
    top: 0,
    left: 0,
  });

  const handleBlur = () => {
    debugger
    if (linkText?.trim() !== '' && linkHref?.trim() !== '') {
      setIsEditing(false);
    }

    setTimeout(() => {
      if (divRef.current) {
        const updatedInnerHTML = divRef.current.innerHTML;
        if (linkHref && linkText) {
          handleContentChange(linkText, getId, linkHref);
        }
        setDroppedItems(prevItems => {
          const updatedItems = prevItems.map(item => {
            if (item.id === getId) {
              return { ...item, textContents: updatedInnerHTML };
            }
            return item;
          });
          setTemplateData(prevState => ({
            ...prevState,
            jsonStructure: updatedItems,
          }));
          return updatedItems;
        });
      }
    }, 1000);
  };

  const handleMouseDown = useCallback((e, direction) => {
    e.preventDefault();
    const originalWidthPercent = dimensions.widthPercent;
    const originalHeight = dimensions.minHeight;
    const originalMouseX = e.pageX;
    const originalMouseY = e.pageY;
    const container = document.getElementById(props.keyValueId);

    if (!container) return;

    const containerWidth = container.offsetWidth; // Get container width in pixels
    const maxWidth = containerWidth; // Maximum width in pixels (100% of container width)
    const minWidthPercent = 5; // Minimum width percentage
    const minHeight = 100; // Minimum height in pixels

    const onMouseMove = (e) => {
      let newWidthPercent = originalWidthPercent;
      let newHeight = originalHeight;

      if (direction === 'right') {
        const deltaX = e.pageX - originalMouseX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) + deltaX;
        if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }
        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'left') {
        const deltaX = originalMouseX - e.pageX;
        let newWidth = (originalWidthPercent / 100 * containerWidth) - deltaX;
        const minWidthInPixels = minWidthPercent / 100 * containerWidth;
        if (newWidth < minWidthInPixels) {
          newWidth = minWidthInPixels;
        }
        newWidthPercent = (newWidth / containerWidth) * 100;
      } else if (direction === 'top') {
        newHeight = originalHeight - (e.pageY - originalMouseY);
        if (newHeight < minHeight) {
          newHeight = minHeight;
        }
      } else if (direction === 'bottom') {
        newHeight = originalHeight + (e.pageY - originalMouseY);
      }

      if (newWidthPercent >= minWidthPercent && newWidthPercent <= 100) {
        setDimensions(prev => ({ ...prev, widthPercent: newWidthPercent }));
      }
      if (newHeight >= minHeight) {
        setDimensions(prev => ({ ...prev, minHeight: newHeight }));
      }

      if (props.keyValueId) {
        const widthValue = `${newWidthPercent}`;
        const heightValue = `${newHeight}`;
        handleStyleChange("width", widthValue, "%", props.keyValueId);
        handleStyleChange("min-height", heightValue, "px", props.keyValueId);
      }
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }, [dimensions, props.keyValueId]);

  const enterEditMode = () => {
    setIsEditing(true);
  };

  const handleLinkTextChange = (e) => {
    setLinkText(e.target.value);
  };

  const handleLinkHrefChange = (e) => {
    setLinkHref(e.target.value);
  };
  const deletebuttonshow = () => {
    const deleteButton = document.getElementById(props.keyValueId + "delete");
    if (deleteButton) {
      deleteButton.classList.toggle("column-one-delete-web-template");
    }

    const resizers = document.getElementsByClassName(props.keyValueId);
    if (resizers.length > 0) {
      for (let i = 0; i < resizers.length; i++) {
        resizers[i].classList.toggle("displayblock");
      }
    }
  };

  const handleColumnClick = (id) => {
    deletebuttonshow()
    props.onComponentClick(id);
  };

  const isHovered = hoveredComponentId === props.keyValueId;

  useEffect(() => {
    const processedHref = linkHref.startsWith('http://') || linkHref.startsWith('https://')
      ? linkHref
      : `https://${linkHref}`;

    setHrefWithProtocol(processedHref);
  }, [linkHref]);

  return (
    <div
      style={{ position: 'relative' }}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      onDragOver={props.dragover}
      draggable="true"
      ref={divRef}
      // className="resizers-web-template"
      className={`component-web-template resizers-web-template ${isHovered ? 'hover-border-web-template' : ''} `}
      onMouseEnter={() => handleMouseEnter(props.keyValueId)}
      onMouseLeave={handleMouseLeave}
      // onClick={() => handleColumnClick(getId)}
      onClick={() => handleColumnClick(getId)}
      onDoubleClick={enterEditMode}
    // onMouseDown={(e) => handleMouseDown(e, 'top')}
    >
      <div className={`resizer-web-template ${getId} top-web-template `} onMouseDown={(e) => handleMouseDown(e, 'top')}></div>
      <div className={`resizer-web-template ${getId} right-web-template `} onMouseDown={(e) => handleMouseDown(e, 'right')}></div>
      <div className={`resizer-web-template ${getId} bottom-web-template `} onMouseDown={(e) => handleMouseDown(e, 'bottom')}></div>
      <div className={`resizer-web-template ${getId} left-web-template `} onMouseDown={(e) => handleMouseDown(e, 'left')}></div>
      {isEditing ? (
        <div>
          <input
            type="text"
            placeholder="Enter link text"
            value={linkText || ''}
            onChange={handleLinkTextChange}
            onBlur={handleBlur}
            // autoFocus
            style={{
              width: '100%',
              padding: '8px',
              boxSizing: 'border-box',
            }}
          />
          <input
            type="text"
            placeholder="Enter link URL"
            value={linkHref || ''}
            onChange={handleLinkHrefChange}
            onBlur={handleBlur}
            style={{
              width: '100%',
              padding: '8px',
              boxSizing: 'border-box',
              marginTop: '8px',
            }}
          />
        </div>
      ) : (
        <a id={getId}  className='link-web-template' href={hrefWithProtocol || '#'} style={{ position: 'relative', textDecoration: "none", color:"inherit"}}
          target="_blank"
          rel="noopener noreferrer" >
          {linkText || 'Double Click Below to edit text...'}
        </a>
      )}
    </div>
  );
};

export default Link;
