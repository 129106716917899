import React, { useState, useEffect } from "react";
import BusinessTypeApi from "../../API/APIServices/BusinessType"
import logo from "./../../images/logo.png";
import { useNavigate } from 'react-router-dom';

function CreateBusiness(props) {

  const [businessTypeValue, setBusinessTypeValue] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await BusinessTypeApi.getBusinessType();
        setBusinessTypeValue(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [])

  return (
    <div className="Formheader">
      <div>
        <div className="form-container">
          <div className="form-sidebar-yesbm-logo ">
            <img src={logo} alt="" />
          </div>
          <p className="FormTitle">Set Company!</p>

          <div className="formMainDiv">
            <div className="InputTextD">
              <input
                type="text"
                className="FormInputBox "
                placeholder=""
                required
                onChange={props.handleInputChange}
                name="name"
              />
              <label className="formTextLabelUser ">Company Name</label>
            </div>

            <div className="InputTextD">
              <select
                className="FormInputBox mode_category1"
                type="text"
                required
                onChange={props.handleInputChange}
                name="businessTypes"
              >
                <option className="s-1 form-control" value="">
                  -Select-
                </option>
                {/* {(() => {
                  if (businessTypeValue !== null && Array.isArray(businessTypeValue)) {  // && Array.isArray(stateInfo)
                    return businessTypeValue.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))
                  }
                })()} */}
                <option value="841">My Inventory</option>
                <option value="842">Optical</option>
                <option value="844">E Com</option>
                <option value="845">Restaurant</option>
                <option value="846">Pharmacy</option>
              </select>
              <label className="formTextLabelUser ">Business Type</label>
            </div>

            <button onClick={props.CreateCompany} className="form-btn">Submit</button>

          </div>
          <p className="sign-up-label">
            By Continuing,you Accept the
            <span className="sign-up-link">Terms and Conditions</span>
          </p>
          <div className="buttons-container"></div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default CreateBusiness;
