import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import "./OrderTakerStylesheet.css";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import OrderApi from '../../API/APIServices/Order';
import AccountApi from '../../API/APIServices/Account';
import moment from 'moment';
import TableAllocationApi from '../../API/APIServices/TableAllocation';
import Order from '../../API/APIServices/Order';
import Table from '../../API/APIServices/Table';
import TableGroup from '../../API/APIServices/TableGroup';
import BillLayoutSetting from '../../API/APIServices/BillLayoutSetting';
import Email from '../../API/APIServices/Email';
import Payment from '../../API/APIServices/Payment';
import Company from '../../API/APIServices/Company';
import PineLab from '../../API/APIServices/PineLab';
import Loader from '../../Inventory/Props/Loader';
import BillSeriesMaster from '../../API/APIServices/BillSeriesMaster';
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import UserCredential from '../../API/APIServices/UserCredential';
import User from '../../API/APIServices/User';
import AppHeader from '../AppHeader';
import MyContext from '../../Inventory/MyContext';
// import ReactPullToRefresh from 'react-pull-to-refresh';
//import html2pdf from 'html2pdf.js';
import PaymentModeModel from '../Orders/PaymentModeModel';
import axios from '../../API/axiosConfig';
import SMS from '../../API/APIServices/SMS';
import SmsTemplate from '../../API/APIServices/SmsTemplate';
import CurrentDateAndTime from '../../API/APIServices/CurrentDateAndTime';
import { useParams } from 'react-router-dom';
import ReportDesign from '../Orders/ReportDesign';
import CommonAlertBox from '../../Inventory/Props/CommonAlertBox';
import newOrder from '../../images/newOrder.png';
import OrderNow from '../../images/orderNow.jpg';
import Pay from '../../images/Pay.png';
import Rupee from '../../images/Rupee.png';
import View from '../../images/View.png';
import OrderStatus from '../../images/Order.png';
import Cancel from '../../images/Cancel.png';
import tableChangeIcon from '../../images/Change.png';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const OrderTakerView = () => {

    const navigate = useNavigate();
    const location = useLocation();
    let { scrollOrd1Id } = location.state || {};

    // const [scrollOrd1Id, setScrollOrd1Id] = useState(location.state?.scrollOrd1Id || null);

    const inputRef = useRef([]);

    const [loading, setLoading] = useState(true);

    const { appToWebLink, webToAppLink, isAutoTransferToKitchen, messageFunction,
        setAppToWebLink,
        setWebToAppLink,
        setAutoTransferToKitchen,
        setCompanyData,
        convertDateTimeToDateAndTime,
        companyData
    } = useContext(MyContext);


    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");
    const [closeBtnFun, setCloseBtnFun] = useState();

    const closeBtn = () => {
        setIsShowAlertBox(false)
    }

    const sidebar = () => {
        try {
            document.getElementById("sidebar1").style.left = "0px";
            if (document.getElementById("sidebar1").style.left === "0px") {
                document.getElementById('scrollBar').classList.remove('scroll-container');
                document.getElementById('scrollBar').style.overflow = "hidden";
            }
        }
        catch { }
    }

    const sidebarclose = () => {
        try {
            document.getElementById("sidebar1").style.left = "-300px";
            document.getElementById("body").classList.toggle("back-blur");
            document.getElementById('scrollBar').classList.add('scroll-container');
            document.getElementById('scrollBar').style.overflow = "scroll";
        }
        catch { }
    }

    const [isFloorManager, setIsFloorManager] = useState(false);

    useEffect(() => {
        const data = localStorage.getItem("IsFloorManager");
        // Convert data to number before comparison
        if (Number(data) === 564) {
            setIsFloorManager(true);
        } else {
            setIsFloorManager(false);
        }
    }, []);

    //const phoneNumber = '9876543210';
    const callButtonClick = (number) => {
        window.location.href = `tel:${number}`
    }

    const [showToast, setShowToast] = useState(false);

    const handleButtonClick = () => {
        setShowToast(true);

        setTimeout(() => {
            setShowToast(false);
        }, 1000);
    };

    const [Modal, setModal] = useState(false);

    const [orderNo, setOrderNo] = useState();

    const [accountDetails, setAccountDetails] = useState({
        name: "",
        mobileNo: "",
        companyGSTNo: "",
        companyDetails: "",
        address: ""
    });

    const openModal = async (d1Id, orderNum, name, mobileNo, gstNo, companyDetails, order1Id) => {
        setLoading(true);
        setOrderNo(orderNum)
        setOrd1Id(order1Id)
        const data = await AccountApi.getAccountById(d1Id);
        //setAccountDetails(data.item1[0]);
        console.log(data.item1[0].address)
        setAccountDetails({
            name: name,
            mobileNo: mobileNo,
            companyGSTNo: gstNo,
            companyDetails: companyDetails,
            address: data.item1[0].address
        });
        setModal(true);
        //console.log(data.item1[0]);
        setLoading(false)
    }

    const closeModal = () => {
        setAccountDetails('');
        setModal(false);
        // fetchData();
    };

    const [mobileNo, setMobileNo] = useState();
    const [OrderStatusModal, setOrderStatusModal] = useState(false);
    const [prePostPaid, setPrePostPaid] = useState(null);
    const [balanceAmount, setBalanceAmount] = useState();

    const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

    const [options, setOptions] = useState();

    const fetchPayOptions = async (rupee) => {
        try {
            const data = await Payment.getPaymentOptions(rupee);
            console.log("options1", data);
            setOptions(data);
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handlePayment = async (rupeeAmt, orderNo, ord1Id) => {
    //     //debugger;
    //     if (!window.Razorpay) {
    //         const script = document.createElement('script');
    //         // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    //         script.src = 'https://api.razorpay.com/v1/checkout/embedded';
    //         script.onload = () => {
    //             setIsRazorpayLoaded(true);
    //         };
    //         document.body.appendChild(script);

    //     } else {
    //         setIsRazorpayLoaded(true);
    //     }
    //     if (!isRazorpayLoaded) {
    //         console.error('Razorpay SDK is not loaded yet.');
    //         return;
    //     }

    //     const data = await fetchPayOptions(rupeeAmt);

    //     // const options = {
    //     //     key: data.key,
    //     //     amount: data.amount,
    //     //     currency: data.currency,
    //     //     name: data.name,
    //     //     description: data.description,
    //     //     handler: function (response) {
    //     //         console.log(response)
    //     //         console.log("Payment is Successful\n" +
    //     //             "razorpay_payment_id:", response.razorpay_payment_id,
    //     //             "Order No:", orderNo,
    //     //             "Amount:", rupeeAmt);
    //     //         alert("Payment is Successful\n" +
    //     //             "razorpay_payment_id: " + response.razorpay_payment_id +
    //     //             "\nOrder No: " + orderNo +
    //     //             "\nAmount: " + rupeeAmt);
    //     //     },
    //     //     prefill: {
    //     //         name: 'Customer Name',
    //     //         email: 'customer@example.com',
    //     //         contact: '8319871045'
    //     //     },
    //     //     theme: {
    //     //         color: '#3009cc'
    //     //     }
    //     // };

    //     console.log("options2", options);
    //     // const rzp1 = await new window.Razorpay(options);
    //     // console.log(rzp1);
    //     // rzp1.open();

    //     // *******************************
    //     const currentDate = new Date();
    //     const formattedDate = currentDate.toISOString();

    //     const storedArrayString = localStorage.getItem('loginAccountUserData');
    //     const loginUser = JSON.parse(storedArrayString);

    //     const updatedValues = [
    //         {
    //             operationType: 0,
    //             path: "overallOrderStatus",
    //             op: "Add",
    //             from: "string",
    //             value: "Confirm"
    //         },
    //         {
    //             operationType: 0,
    //             path: "orderConfirmedBy",
    //             op: "Add",
    //             from: "string",
    //             value: loginUser.userId
    //         },
    //         {
    //             operationType: 0,
    //             path: "orderConfirmedOn",
    //             op: "Add",
    //             from: "string",
    //             value: formattedDate
    //         },
    //         {
    //             operationType: 0,
    //             path: "paymentStatus",
    //             op: "Add",
    //             from: "string",
    //             value: "Confirm"
    //         },
    //         {
    //             operationType: 0,
    //             path: "paymentConfirmedBy",
    //             op: "Add",
    //             from: "string",
    //             value: loginUser.userId
    //         },
    //         {
    //             operationType: 0,
    //             path: "paymentConfirmedOn",
    //             op: "Add",
    //             from: "string",
    //             value: formattedDate
    //         },
    //     ]
    //     await OrderApi.updateOrderPartially(updatedValues, ord1Id);
    //     fetchData();
    // };


    // const handlePayment = () => {
    //     if (!isRazorpayLoaded) {
    //         console.error('Razorpay SDK is not loaded yet.');
    //         return;
    //     }

    //     const options = {
    //         key: "rzp_test_6gzv7z3KTwC4Gp", // Replace with your key
    //         amount: '50000', // Amount in smallest currency unit (e.g., 50000 paise for ?500)
    //         currency: 'INR',
    //         name: 'Techcherry',
    //         description: 'Software Company',
    //         // Add your callback handler here
    //         handler: function (response) {
    //             alert(response.razorpay_payment_id);
    //             alert(response.status);
    //             alert(response.razorpay_order_id);
    //             alert(response.razorpay_signature);
    //             alert("Success");
    //         },
    //         prefill: {
    //             name: 'Customer Name',
    //             email: 'customer@example.com',
    //             contact: '8319871045'
    //         },
    //         theme: {
    //             color: "#3009cc"
    //         }
    //     };

    //     const rzp1 = new window.Razorpay(options);
    //     console.log(rzp1);
    //     rzp1.open();
    // };

    const [orderDetailData, setOrderDetailData] = useState({
        ord1Id: 0,
        d1Id: 0,
        d2Id: 0,
        ord2Id: 0,
        orderNo: 0
    });
    const [tableId, setTableId] = useState();
    const [currentindex, setCurrentIndex] = useState(0);
    const [hasRunningOrder, setHasRunningOrder] = useState(false);
    const [runningOrderCounter, setRunningOrderCounter] = useState(0);
    const [overAllOrderStatus, setOverAllOrderStatus] = useState("");

    const [selectedOverAllStatus, setSelectedOverAllStatus] = useState("");
    const [paymentType, setPaymentType] = useState(0);

    const [isOrderAlreadyConfirmed, setOrderAlreadyConfirmed] = useState(false);

    const [indexForPerson, setIndexForPerson] = useState(-1);
    const [isShowAlertBoxForPerson, setIsShowAlertBoxForPerson] = useState(false);
    const [isShowAlertBoxForDelPref, setIsShowAlertBoxForDelPref] = useState(false);

    const closeBtnForNoOfPer = () => {
        if (inputRef.current && inputRef.current[indexForPerson]) {
            inputRef.current[indexForPerson].focus();
        }
        setIsShowAlertBoxForPerson(false);
    };

    const [d1Id, setD1Id] = useState(0)
    const [d2Id, setD2Id] = useState(0)

    const closeBtnForDelPref = () => {
        navigate("/OrderDetail", {
            state: {
                ord1Id, d1Id, d2Id, orderNo
            }
        });
        setIsShowAlertBoxForDelPref(false);
    }

    const openOrderStatusModal = async (ord1Id, overallOrderStatus, mobileNo, orderSeriesId, grandTotal, payingAmount, d1Id, d2Id, ord2Id,
        orderNo, index, tableId, cancelAmt, hasRunningOrd, runningOrderCount, seriesId, paymentType, orderConfirmedByName) => {
        setLoading(true);
        setOrd1Id(ord1Id);
        setD1Id(d1Id)
        setD2Id(d2Id)
        setOrderNo(orderNo);
        setTableId(tableId);
        setBillSeriesId(seriesId);
        setSelectedOverAllStatus(overallOrderStatus)
        setHasRunningOrder(hasRunningOrd)
        setRunningOrderCounter(runningOrderCount)
        if (orderConfirmedByName != null && orderConfirmedByName != undefined && orderConfirmedByName != "" && orderConfirmedByName != 0) {
            setOrderAlreadyConfirmed(false);
        }
        else {
            setOrderAlreadyConfirmed(true);
        }
        const data = await OrderApi.getOrderValidate(ord1Id);
        if (data.checkNoOfPersonBeforeOrdConfirm === true) {
            if (data.isValidateNoOfPerson === false) {
                // alert("Please Enter No Of Person");
                setIsShowAlertBoxForPerson(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setIndexForPerson(index);
                setMessage('Please Enter No Of Person');

                closeOrderStatusModal();
                // if (inputRef.current) {
                //     inputRef.current[index].focus();
                // }
                setLoading(false);
                return false;
            }
        }
        if (data.checkDlvryPrefer === true) {
            if (data.isValidateDeliveryPref === false) {
                // alert("Please Set Delivery Preference");
                setIsShowAlertBoxForDelPref(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setMessage('Please Set Delivery Preference');
                // navigate("/OrderDetail", {
                //     state: {
                //         ord1Id, d1Id, d2Id, ord2Id, orderNo
                //     }
                // });
                setLoading(false);
                return false;
            }
        }
        setMobileNo(mobileNo)
        setOrderId(ord1Id);
        setOrderStatus(overallOrderStatus);
        setOverAllOrderStatus(overallOrderStatus)
        setCurrentIndex(index);
        setOrderDetailData((prevValues) => ({
            ...prevValues,
            ord1Id: ord1Id,
            d1Id: d1Id,
            d2Id: d2Id,
            ord2Id: ord2Id,
            orderNo: orderNo
        }));
        setPaymentType(paymentType)

        const balAmt = Number(grandTotal) - Number(payingAmount + cancelAmt);
        console.log(balAmt)
        setBalanceAmount(balAmt);

        // const BillData = await BillLayoutSetting.getBillLayoutSettingById(orderSeriesId);
        // if (BillData !== null) {
        //     console.log("paymentType", BillData.paymentType);
        //     setPrePostPaid(BillData.paymentType);
        // }
        setOrderStatusModal(true);
        setLoading(false);
    }

    const closeOrderStatusModal = () => {
        setMobileNo('')
        setOrderId('')
        setPrePostPaid('')
        setOrderStatusModal(false);
        setHasRunningOrder(false);
        setRunningOrderCounter(0);
        // fetchData();
    };

    const [ResetTableStatusModal, setTableStatusModal] = useState(false);

    const openResetTableStatusModal = () => {
        setTableStatusModal(true);
    }

    const closeResetTableStatusModal = () => {
        setTableStatusModal(false);
        // fetchData();
    };

    const [TableChangeModal, setTableChangeModal] = useState(false);

    const [ordDetail, setOrdDetail] = useState({
        orderNo: "",
        tableName: "",
        ord1Id: 0,
        tableId: 0
    })

    const openTableChangeModal = (orderNo, tableName, ord1Id, tableId) => {
        setLoading(true)
        setOrdDetail((prevValues) => ({
            ...prevValues,
            "orderNo": orderNo,
            "tableName": tableName,
            "ord1Id": ord1Id,
            "tableId": tableId
        }));

        openTableChangeModal2();
        //setTableChangeModal(true);
        setLoading(false)
    }

    const closeTableChangeModal = () => {
        setTableChangeModal(false);
        // fetchData();
    };

    const [CancelOrderModal, setCancelOrderModal] = useState(false);
    const [ord1Id, setOrd1Id] = useState();
    const [orderNo1, setOrderNo1] = useState();

    const openCancelOrderModal = (id, orderNo) => {
        setOrd1Id(id);
        setOrderNo1(orderNo)
        setCancelOrderModal(true);
    }

    const closeCancelOrderModal = () => {
        setCancelOrderModal(false);
        fetchData();
    };

    const [orderId, setOrderId] = useState();
    const [NoOfPersonModal, setNoOfPersonModal] = useState(false);

    const closeNoOfPersonModal = () => {
        setOrderId('');
        setNoOfPersonModal(false);
        // fetchData();
    };

    const [ComplimentaryBonusModal, setComplimentaryBonusModal] = useState(false);

    const openComplimentaryBonusModal = () => {
        setComplimentaryBonusModal(true);
    }

    const closeComplimentaryBonusModal = () => {
        setComplimentaryBonusModal(false);
        // fetchData();
    }

    const [PaymentModal, setPaymentModal] = useState(false);

    const closePaymentModal = () => {
        setPaymentModal(false);
    }

    const [PaymentStatusModal, setPaymentStatusModal] = useState(false);

    const [amount, setAmount] = useState({
        paymentAmount: 0,
        balanceAmt: 0,
        grandTotal: 0
    })

    const fetchDataByOrderId = async (orderId) => {
        const checkCancelStatus = await Order.getOrderItemCancelStatus(orderId);
        console.log(orderId, "checkCancelStatus", checkCancelStatus);
        return checkCancelStatus
    }

    const openPaymentStatusModal = async (id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus, cancelAmt) => {
        setLoading(true)
        const data = await Order.getOrderItemCancelStatus(id);
        //alert(id);
        if (data === "Completed") {
            console.log(orderStatus);
            setOrderStatus(orderStatus);
            console.log(balanceAmt, grandTotal)
            const balAmt = grandTotal - (paymentAmt + cancelAmt);
            setAmount((prevValues) => ({
                ...prevValues,
                "paymentAmount": paymentAmt,
                "balanceAmt": balAmt,
                "grandTotal": grandTotal,
                "cancelAmt": cancelAmt,
            }));
            setPayingAmount(balAmt);
            setOrderId(id);
            setPaymentStatusModal(true);
            setLoading(false);
        }
        else {
            // alert("Please Settle Cancel Order Status");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please Settle Cancel Order Status');
            setLoading(false);
        }
    }

    const closePaymentStatusModal = () => {
        setAmount((prevValues) => ({
            ...prevValues,
            "paymentAmount": 0,
            "balanceAmt": 0,
            "grandTotal": 0,
            "cancelAmt": 0
        }));
        setOrderId('')
        setPaymentStatusModal(false);
        // fetchData();
    }
    // const handlePrintButton = () => {
    //     window.print();
    // }

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false)
        setLoading(false);
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 3000)
    // }, [showBillPrint])

    const handlePrintButton = async (id, paymentAmt, balanceAmt, grandTotal, paymentStatus, orderStatus) => {
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id,
        //         paymentAmt,
        //         balanceAmt,
        //         grandTotal,
        //         paymentStatus,
        //         orderStatus
        //     }
        // });
        setOrd1Id(id)
        setShowBillPrint(true)
        // const params = new URLSearchParams({
        //     id,
        //     paymentAmt,
        //     balanceAmt,
        //     grandTotal,
        //     paymentStatus,
        //     orderStatus
        // }).toString();

        // const otherPageUrl = `http://localhost:44483/OrderTakerBillPrint?${params}`;

        // const otherPageWindow = window.open(otherPageUrl);

        // otherPageWindow.onload = async () => {
        //     await otherPageWindow.print();
        // };
    }

    const [TableChangeModal2, setTableChangeModal2] = useState(false);

    const openTableChangeModal2 = () => {
        fetchTableData();
        setTableChangeModal2(true);
    }

    const closeTableChangeModal2 = () => {
        setTableChangeModal2(false);
    }

    const tableChange = async () => {
        setLoading(true);
        if (selectedTableChange == undefined) {
            // alert("Please select Table")
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please select Table');
            setLoading(false)
        }
        else {
            const filterData = [{
                fieldName: "id",
                operatorName: "Equal",
                compareValue: selectedTableChange
            }]
            const tableData = await Table.getTableGroupByTableId(filterData);
            console.log(tableData);
            const data = [
                {
                    "operationType": 0,
                    "path": "tableId",
                    "op": "Add",
                    "from": "string",
                    "value": Number(selectedTableChange)
                },
                {
                    "operationType": 0,
                    "path": "tableGroupId",
                    "op": "Add",
                    "from": "string",
                    "value": Number(tableData[0].tableGroupId)
                }
            ]
            await OrderApi.updateOrderPartially(data, ordDetail.ord1Id);

            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Busy"
            }]
            await Table.updateTablePartially(filteredData, selectedTableChange);

            const filteredDataFree = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Free"
            }]
            await Table.updateTablePartially(filteredDataFree, ordDetail.tableId);

            // alert("Updated");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Updated');
            setLoading(false)
            fetchData();
            closeTableChangeModal2();
            closeTableChangeModal();
        }
        setLoading(false);
    }
    const [selectedTableChange, setSelectedTableChange] = useState();
    const tableSelected = (event) => {
        setSelectedTableChange(event.target.value);
    }

    const handlePersonChange = (itemId, event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        const index = tableOrder.findIndex(item => item.ord1Id === itemId);

        if (index !== -1) {
            setTableOrder((prevValues) => {
                const updatedOrder = [...prevValues];
                updatedOrder[index] = {
                    ...updatedOrder[index],
                    [name]: value,
                };
                return updatedOrder;
            });
        }
    };

    const personChangeBtn = async (ord1Id) => {
        setLoading(true)
        const index = tableOrder.findIndex(item => item.ord1Id === ord1Id);

        const NoOfPerson = Number(tableOrder[index].noAdultPerson) + Number(tableOrder[index].noChildPerson)
        console.log("Number of Person", NoOfPerson);

        const updatedValues = [
            {
                operationType: 0,
                path: "noOfAdult",
                op: "Add",
                from: "string",
                value: tableOrder[index].noAdultPerson || 0
            },
            {
                operationType: 0,
                path: "noOfChild",
                op: "Add",
                from: "string",
                value: tableOrder[index].noChildPerson || 0
            },
            {
                operationType: 0,
                path: "noOfPerson",
                op: "Add",
                from: "string",
                value: NoOfPerson
            },
        ]
        if (NoOfPerson == 0) {
            // alert("Please select valid number")
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please select a valid number');
            setLoading(false)
        }
        else {
            await OrderApi.updateOrderPartially(updatedValues, ord1Id);
            // alert("Updated")
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Updated');
            setLoading(false)
        }
        closeNoOfPersonModal();
    }

    const [paymentStatusValue, setPaymentStatusValue] = useState(0);

    const handlePaymentStatus = (event) => {
        const val = event.target.value;
        // if (val === "Pending") {
        //     setOrderStatus(val);
        // }
        // else if (val === "Cash") {
        //     setOrderStatus("Done");
        // }
        setPaymentStatusValue(val);
        //alert(val);
        // fetchData();
    }

    const [orderStatus, setOrderStatus] = useState('Pending')

    const orderStatusChange = (e) => {
        setOrderStatus(e.target.value)
    }

    const [payingAmount, setPayingAmount] = useState();

    const handleAmountChange = (e) => {
        if (e.target.value < 0) {
            // alert("The payment amount cannot be negative.");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('The payment amount cannot be negative.');
            setPayingAmount(0);
        }
        else if (e.target.value > amount.balanceAmt) {
            // alert("The payment cannot exceed the actual balance amount.")
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('The payment cannot exceed the actual balance amount');
            setPayingAmount(amount.balanceAmt)
        }
        else {
            setPayingAmount(e.target.value);
        }
    }
    const handlePineAmountChange = (e) => {
        setBalanceAmount(e.target.value);
    }

    const paymentStatusSaveBtn = async () => {
        // console.log("paymentStatusValue",paymentStatusValue);
        //alert(payingAmount);
        setLoading(true)
        if (payingAmount == "" || payingAmount == "0") {
            // alert("Please Enter Payment Amount";
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please Enter Payment Amount');
            setLoading(false);
        }
        else if (paymentStatusValue == "0") {
            // alert("Please select Payment Mode");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please select Payment Mode');
            setLoading(false);
        }
        else {
            console.log(Number(amount.paymentAmount) + Number(payingAmount))
            const val = amount.grandTotal - ((amount.paymentAmount + payingAmount) + amount.cancelAmt)
            console.log(val)
            let updatedValues = [
                {
                    operationType: 0,
                    path: "PaymentModeId",
                    op: "Add",
                    from: "string",
                    value: paymentStatusValue
                },
                // {
                //     operationType: 0,
                //     path: "overallOrderStatus",
                //     op: "Add",
                //     from: "string",
                //     value: orderStatus
                // },
                {
                    operationType: 0,
                    path: "balanceAmt",
                    op: "Add",
                    from: "string",
                    value: val
                },
                {
                    operationType: 0,
                    path: "paymentAmount",
                    op: "Add",
                    from: "string",
                    value: Number(amount.paymentAmount) + Number(payingAmount)
                }
            ]
            const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
            // const formattedDate = new Date(isoString);

            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);


            const filterData = [{
                ord1Id: orderId,
                paymentModeId: paymentStatusValue,
                paymentAmount: payingAmount,
                transId: "",
                payUId: 0,
                bankRefNo: "",
                status: "",
                paymentTypeMethod: "",
                //paymentAmount: amount.paymentAmount + payingAmount,
            }]

            const paymentResponse = await OrderApi.createPaymentModeWithOrder(filterData)
            if (val == 0) {
                updatedValues.push(
                    {
                        operationType: 0,
                        path: "paymentStatus",
                        op: "Add",
                        from: "string",
                        value: "Done"
                    },
                    {
                        operationType: 0,
                        path: "PaymentDoneOn",
                        op: "Add",
                        from: "string",
                        value: formattedDate
                    },
                    {
                        operationType: 0,
                        path: "PaymentDoneBy",
                        op: "Add",
                        from: "string",
                        value: loginUser.userId
                    },
                );
            }
            if (paymentResponse == "True") {
                await OrderApi.updateOrderPartially(updatedValues, orderId);
            }
            else {
                // alert("Payment Failed. Please Try Again..");
                setIsShowAlertBox(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setMessage('Payment Failed. Please Try Again..');
            }
            fetchData();
            setLoading(false);
            closePaymentStatusModal();
        }
    }
    const [status, setStatus] = useState();
    const handleOrderStatusChange = (e) => {
        setOrderStatus(e.target.value)
        setStatus(e.target.value)
    }

    const orderStatusFunction = async () => {
        setLoading(true);
        let formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        // const formattedDate = new Date(isoString);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        if (formattedDate == "" || formattedDate == null || formattedDate == undefined) {
            formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        }
        const filterData = {
            ord1Id: orderId,
            createdById: loginUser.userId || 0,
            createdOn: formattedDate
        }
        const dataaa = await OrderApi.setOrderToConfirmed(filterData);
        await fetchData();
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop(``);
        setMessage(`Updated status to ${orderStatus}`);
        setLoading(false);
        return false;

        let updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: orderStatus
            },
            {
                operationType: 0,
                path: "orderConfirmedBy",
                op: "Add",
                from: "string",
                value: loginUser.userId
            },
        ]
        if (hasRunningOrder == null || hasRunningOrder == undefined || hasRunningOrder == false) {
            updatedValues.push({
                operationType: 0,
                path: "orderConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            })
        }
        else if (isOrderAlreadyConfirmed) {
            updatedValues.push({
                operationType: 0,
                path: "orderConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            })
        }
        await OrderApi.updateOrderPartially(updatedValues, orderId);
        // ***************************
        const itemListData = await OrderApi.getOrderItemWiseList(orderId);
        console.log(itemListData);
        setLoading(true)
        for (var i = 0; i < itemListData.length; i++) {
            //alert(itemListData[i].kotStatus)
            if (itemListData[i].kotStatus == "Pending") {
                // **************************** Order 3 *********************
                const statusData = {
                    ord1Id: orderId,
                    ord2Id: itemListData[i].ord2Id,
                    orderStatus: "Received",
                    orderDateTime: formattedDate,
                }
                await OrderApi.insertItemStatus(statusData);

                // ************************** Order2 **********************
                const data = [
                    {
                        operationType: 0,
                        path: "KotStatus",
                        op: "Add",
                        from: "string",
                        value: "Received"
                    }
                ]
                await OrderApi.updateItemPartially(data, itemListData[i].ord2Id);
            }
        }
        const data = await OrderApi.setOrderToConfirmed(filterData);
        // const statusDataList = itemListData
        //     .filter(item => item.kotStatus == "Pending")
        //     .map(item => ({
        //         ord1Id: orderId,
        //         ord2Id: item.ord2Id,
        //         orderStatus: "Received",
        //         orderDateTime: formattedDate
        //     }));

        // console.log(statusDataList);
        // if (statusDataList && statusDataList.length > 0) {
        //     await OrderApi.insertItemStatusList(statusDataList); // Send all data in one request
        // }
        await fetchData();
        // alert(`Updated status to ${orderStatus}`);
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop(``);
        setMessage(`Updated status to ${orderStatus}`);
        setLoading(false);
        //alert(loginUserDetails.userId)
    }

    const orderValidate = async () => {
        const data = await OrderApi.getOrderValidate(orderId);
        if (data.checkNoOfPersonBeforeOrdConfirm === true) {
            if (data.isValidateNoOfPerson === false) {
                // alert("Please Enter No Of Person");
                isShowAlertBoxForPerson(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setIndexForPerson(currentindex);
                // setCloseBtnFun(closeBtnForNoOfPer);
                setMessage('Please Enter No Of Person');
                // closeOrderStatusModal();
                // if (inputRef.current) {
                //     inputRef.current[currentindex].focus();
                // }
                return false;
            }
        }
        if (data.checkDlvryPrefer === true) {
            if (data.isValidateDeliveryPref === false) {
                // alert("Please Set Delivery Preference");
                setIsShowAlertBoxForDelPref(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setMessage('Please Set Delivery Preference');
                const ord1Id = orderDetailData.ord1Id;
                const d1Id = orderDetailData.d1Id;
                const d2Id = orderDetailData.d2Id;
                const ord2Id = orderDetailData.ord2Id;
                const orderNo = orderDetailData.orderNo;

                setOrd1Id(ord1Id);
                setD1Id(d1Id)
                setD2Id(d2Id)
                setOrderNo(orderNo);
                // setCloseBtnFun(closeBtnForDelPref)
                // navigate("/OrderDetail", {
                //     state: {
                //         ord1Id, d1Id, d2Id, ord2Id, orderNo
                //     }
                // });
                return false;
            }
        }
    }

    const [billSeriesId, setBillSeriesId] = useState(0);

    const [isShowAlertBoxForCancel, setIsShowAlertBoxForCancel] = useState(false);

    const closeBtnForCancel = () => {
        navigate(`/CancelOrderedItem?ord1Id=${orderDetailData.ord1Id}&orderNo=${orderDetailData.orderNo}`)
        setIsShowAlertBoxForCancel(false);
    }

    const orderStatusSaveBtn = async (event) => {
        //alert(orderStatus)
        setLoading(true)
        //const BillData = await BillLayoutSetting.getBillLayoutSettingById(billSeriesId);

        const checkCancelStatus = await Order.getOrderItemCancelStatus(orderId); //Ord1Id
        const selectedOrderData = tableOrder.find(item => item.ord1Id == orderId)

        const currentDate = await CurrentDateAndTime.getCurrentDateTime();

        if (orderStatus === "Confirm") {
            // alert(`prePostPaid-${prePostPaid}`);
            console.log(balanceAmount);
            // if (BillData && BillData != null && BillData != undefined && BillData.paymentType === 2) {  //|| prePostPaid === "") {
            if (paymentType == 2) {  // 1->Post, 2->Pre
                if (balanceAmount != 0) {
                    // alert(`Please first pay Balance Amount: ${balanceAmount}`);
                    setIsShowAlertBox(true);
                    setIsSingleButton(true);
                    setHeadingTop("");
                    setMessage(`Please first pay Balance Amount: ${balanceAmount}`);
                    setLoading(false);
                    return false;
                }
                else {
                    const data = await orderValidate();
                    console.log("data", data)
                    if (data == undefined) {
                        await orderStatusFunction();
                        if (isAutoTransferToKitchen == true) {
                            const autoTransfer = await Order.autoTransferForTable(orderId, currentDate);
                        }
                        messageFunction("on_order_confirm", orderId, selectedOrderData.d1AccId, (selectedOrderData.mobileNo || selectedOrderData.accMobileNo))
                    }
                    else {
                        setLoading(false);
                        return false;
                    }
                }
            }
            else {
                const data = await orderValidate()
                console.log("data", data)
                if (data == undefined) {
                    await orderStatusFunction();
                    if (isAutoTransferToKitchen == true) {
                        const autoTransfer = await Order.autoTransferForTable(orderId, currentDate);
                    }
                    messageFunction("on_order_confirm", orderId, selectedOrderData.d1AccId, (selectedOrderData.mobileNo || selectedOrderData.accMobileNo))
                }
                else {
                    setLoading(false)
                    return false;
                }
            }
            // ***********************************
            if (overAllOrderStatus != orderStatus) {
                if (hasRunningOrder == true) {
                    const filteredData = [
                        {
                            operationType: 0,
                            path: "RunningOrderCounter",
                            op: "Add",
                            from: "string",
                            value: runningOrderCounter + 1,
                        }
                    ]
                    await OrderApi.updateOrderPartially(filteredData, orderId);
                }
            }
        }
        else if (orderStatus === "Done") {
            if (balanceAmount === 0) {
                if (checkCancelStatus == "Completed") {
                    const filteredData = [{
                        operationType: 0,
                        path: "tableStatus",
                        op: "Add",
                        from: "string",
                        value: "Free"
                    }]
                    await Table.updateTablePartially(filteredData, tableId);

                    const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
                    // const formattedDate = new Date(isoString);

                    const storedArrayString = localStorage.getItem('loginAccountUserData');
                    const loginUser = JSON.parse(storedArrayString);

                    const updatedValues = [
                        {
                            operationType: 0,
                            path: "overallOrderStatus",
                            op: "Add",
                            from: "string",
                            value: orderStatus
                        },
                        {
                            operationType: 0,
                            path: "orderDoneBy",
                            op: "Add",
                            from: "string",
                            value: loginUser.userId
                        },
                        {
                            operationType: 0,
                            path: "orderDoneOn",
                            op: "Add",
                            from: "string",
                            value: formattedDate
                        },
                        {
                            operationType: 0,
                            path: "orderPrintStatus",
                            op: "Add",
                            from: "string",
                            value: false
                        }
                    ]
                    await OrderApi.updateOrderPartially(updatedValues, orderId);
                    // ***************************
                    //await orderStatusFunction();
                    messageFunction("on_order_done", orderId, selectedOrderData.d1AccId, (selectedOrderData.mobileNo || selectedOrderData.accMobileNo))
                    // messageFunction("on_place_order", orderId, selectedOrderData.d1AccId, (selectedOrderData.mobileNo || selectedOrderData.accMobileNo))
                }
                else {
                    // alert("Please Settle Cancel Order Status")
                    setIsShowAlertBoxForCancel(true);
                    setIsSingleButton(true);
                    setHeadingTop("");
                    setMessage('Please Settle Cancel Order Status');
                    // navigate(`/CancelOrderedItem?ord1Id=${orderDetailData.ord1Id}&orderNo=${orderDetailData.orderNo}`)
                }
            }
            else {
                // alert("Please first pay Balance Amount: " + balanceAmount);
                setIsShowAlertBox(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setMessage(`Please first pay Balance Amount: ${balanceAmount}`);
                setLoading(false)
                return false;
            }
        }
        else if (orderStatus == "Pending" && overAllOrderStatus == "Confirm") {
            // alert("Cannot Update Status Pending as Order is already Confirm");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Cannot Update Status Pending as Order is already Confirm');
        }
        else {
            await orderStatusFunction();
            if (isAutoTransferToKitchen == true) {
                const autoTransfer = await Order.autoTransferForTable(orderId, currentDate);
            }
        }
        setLoading(false)
        fetchData();
        closeOrderStatusModal();
        //window.location.reload();
        //event.preventDefault();
    }

    const handleGstInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setAccountDetails((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }

    const UpdateGstBtn = async () => {
        setLoading(true);
        console.log(accountDetails);

        const updatedData = [
            {
                operationType: 0,
                path: "CompanyGSTNo",
                op: "Add",
                from: "string",
                value: accountDetails.companyGSTNo
            },
            {
                operationType: 0,
                path: "CompanyDetails",
                op: "Add",
                from: "string",
                value: accountDetails.companyDetails
            },
            {
                operationType: 0,
                path: "Name",
                op: "Add",
                from: "string",
                value: accountDetails.name
            }
        ]
        await OrderApi.updateOrderPartially(updatedData, ord1Id);
        // await AccountApi.updateAccountPartially(updatedData, accountDetails.d1Id, accountDetails.d2Id)
        setLoading(false)
        // alert("Updated Successfully")
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop("");
        setMessage('Updated Successfully');
        fetchData();
        closeModal();
    }

    // ************************* Login User Details ***********************
    const [loginUserDetails, setLoginUserDetails] = useState({});
    const [firmName, setFirmName] = useState();

    const [isShowPaymt, setShowPayment] = useState(true);
    const [showRunningOrderColor, setRunningOrderColor] = useState();
    const [showPineDevice, setPineDevice] = useState(true);
    const [showBillAmt, setBillAmt] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                const loginUser = JSON.parse(storedArrayString);
                setLoginUserDetails(loginUser);
                const data = await Company.GetCompanyById(loginUser.compId);
                setFirmName(data.name);
                console.log("", data.isEnblPayFrmOrdrTakerApp);
                setAppToWebLink(data.urL1);
                setWebToAppLink(data.urL2);
                setAutoTransferToKitchen(data.isEnblAutoTransferToKitchen);
                setCompanyData(data);
                //alert("data.isEnblPayFrmOrdrTakerApp", data.isEnblPayFrmOrdrTakerApp);
                setShowPayment(data.isEnblPayFrmOrdrTakerApp)
                setRunningOrderColor(data.isAllowDiffColorRunningOrder)
                setPineDevice(data.canUsePineLabDevice)
                setBillAmt(data.isShowBillAmtAfterOrderConfirm)
            }
            catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            const data = await TableAllocationApi.getTableAllocationById(loginUser.userId);
            const freeTable = data.filter(item => item.status === "Free");
            console.log("freeTable", freeTable)
            setTableData(freeTable);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, [])

    const handleWesiteRedirect = () => {
        // setLoading(true)
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        var width = window.screen.width;
        var height = window.screen.height;

        window.location.href = (`${appToWebLink}/OurMenu?App?${loginUser.userId}`);
        setLoading(false)
        // window.open(`http://localhost:3000/OurMenu?App?${loginUser.userId}`, '_blank', 'width=' + width + ', height=' + height);
        //window.open(url, '_blank', `width=${width},height=${height}`);
        // window.location.href = `http://122.168.117.143:3000/OurMenu?App?${loginUser.userId}`;
        // window.location.href = `${appToWebLink}/OurMenu?App?${loginUser.userId}`;
        // window.open(`${appToWebLink}/OurMenu?App?${loginUser.userId}`);
        // window.location.reload();
    }

    // const handleLogOutClick = () => {
    //     localStorage.removeItem('loginAccountUserData');
    //     localStorage.removeItem("loginUser");
    //     window.location.href = `${webToAppLink}/loginpanel`;
    //     //window.location.href = "http://122.168.117.143:44483/loginpage";
    // }

    // **************************** Table Order ******************************
    const [tableOrder, setTableOrder] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);
            //if (loginUser != null && loginUser != undefined && loginUser.length > 0) {
            const filterData = [
                // {
                //     fieldName: "paymentStatus",
                //     operatorName: "NotEqual",
                //     compareValue: "Done"
                // },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Done"
                },
                {
                    fieldName: "serviceTypeId",
                    operatorName: "Equal",
                    compareValue: 751
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Cancel"
                },
                {
                    fieldName: "overallOrderStatus",
                    operatorName: "NotEqual",
                    compareValue: "Returned"
                },
            ]
            const data = await OrderApi.getOrderListByUserFilter(loginUser.userId, filterData);
            setTableOrder(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchLoginData = async () => {
        setLoading(true);
        try {
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            if (loginUser != null && loginUser != undefined && loginUser.length > 0) {
                const data = await UserCredential.getUserCredentialById(loginUser.userId);
                const inputData = {
                    compCode: loginUser.compCode,
                    userName: data.userName,
                    password: data.password,
                }
                await User.loginUser(inputData);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        fetchLoginData();
        //}, [paymentStatusSaveBtn][orderStatusSaveBtn])
    }, [])

    // ************************** Search **************************

    const [openSearchModal, setSearchModal] = useState(false);

    const OpenSearchModal = () => {
        setSearchModal(true);
    }

    const CloseSearchModal = () => {
        setSearchModal(false);
    }

    const [table, setTable] = useState([]);
    const [tableGroup, setTableGroup] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tableGroupData = await TableGroup.getTableGroup();
                setTableGroup(tableGroupData)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    const [searchData, setSearchData] = useState({
        tableGroupId: 0,
        tableId: 0,
        orderNo: ""
    })
    const handleTableGroupChange = async (event) => {
        const tableGroupId = event.target.value;
        let tableData = await Table.getTableByTableGroupId(tableGroupId);
        if (tableData) {
            tableData = tableData.sort((a, b) => {
                if (a.tablePriority == null) return 1;
                if (b.tablePriority == null) return -1;
                return a.tablePriority - b.tablePriority;
            })
        }
        setTable(tableData)

        setSearchData((prevValues) => ({
            ...prevValues,
            "tableGroupId": Number(tableGroupId),
            "tableId": 0
        }));
    }

    const handleTableChange = (event) => {
        const tableId = event.target.value;
        setSearchData((prevValues) => ({
            ...prevValues,
            "tableId": Number(tableId),
        }));
    }

    const handleOrderChange = (event) => {
        const orderNo = event.target.value;
        setSearchData((prevValues) => ({
            ...prevValues,
            "orderNo": orderNo,
        }));
    }

    const handleSearch = async () => {
        setLoading(true);
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const filterData = [
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Done"
            },
            {
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 751
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Cancel"
            },
            {
                fieldName: "overallOrderStatus",
                operatorName: "NotEqual",
                compareValue: "Returned"
            },
        ];

        if (searchData.tableGroupId !== 0) {
            filterData.push({
                fieldName: "tableGroupId",
                operatorName: "Equal",
                compareValue: searchData.tableGroupId
            });
        }
        if (searchData.tableId !== 0) {
            filterData.push({
                fieldName: "tableId",
                operatorName: "Equal",
                compareValue: searchData.tableId
            });
        }
        if (searchData.orderNo !== "") {
            filterData.push({
                fieldName: "orderNo",
                operatorName: "Equal",
                compareValue: searchData.orderNo
            });
        }
        try {
            const data = await OrderApi.getOrderListByUserFilter(loginUser.userId, filterData);  // userId
            setTableOrder(data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        CloseSearchModal();
    }

    const handleReset = async () => {
        setSearchData((prevValues) => ({
            ...prevValues,
            "tableGroupId": 0,
            "tableId": 0,
            "orderNo": ""
        }));
    }

    const EmailBtn = async () => {
        await Email.SendEmail();
        handleButtonClick();
    }

    const SendSMS = async () => {
        await Email.SendEmail();
        handleButtonClick();
    }

    // const [responseData, setResponseData] = useState(null);

    // const fetchDataFromPineLabs = async () => {
    //     const response = await PineLab.fetchDataFromPineLabs();
    //     setResponseData(response.data);
    // };

    const [paymentModeDetails, setPaymentModeDetails] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const filteredData = [
                    {
                        fieldName: "isDefault",
                        operatorName: "Equal",
                        compareValue: true
                    },
                    {
                        fieldName: "masterType",
                        operatorName: "Equal",
                        compareValue: 29
                    }
                ]
                const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
                console.log("BillData", data);
                if (data.length > 0) {
                    const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
                    setPaymentModeDetails(paymentMode);
                    console.log("paymentMode", paymentMode[0]?.accountId)
                    setPaymentStatusValue(paymentMode[0]?.accountId)
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [])

    // const handleRefresh = async () => {
    //     fetchData();
    // }

    // const handleSubmit = async (event) => {
    //     // if (!window.Razorpay) {
    //     //     const script = document.createElement('script');
    //     //     script.src = 'https://api.razorpay.com/v1/checkout/embedded';
    //     //     script.onload = () => {
    //     //         setIsRazorpayLoaded(true);
    //     //     };
    //     //     document.body.appendChild(script);
    //     // } else {
    //     //     setIsRazorpayLoaded(true);
    //     // }

    //     const options = {
    //         key: 'rzp_test_6gzv7z3KTwC4Gp',
    //         order_id: '1',
    //         amount: 1500, // Amount should be in the smallest currency unit (paise for INR)
    //         currency: 'INR',
    //         name: 'Techcherry',
    //         description: 'Software Company',
    //         handler: function (response) {
    //             alert("Payment is Successful\n" +
    //                 "razorpay_payment_id: " + response.razorpay_payment_id +
    //                 "\nStatus: " + response.status +
    //                 "\nrazorpay_order_id: " + response.razorpay_order_id +
    //                 "\nrazorpay_signature: " + response.razorpay_signature);
    //         },
    //         prefill: {
    //             name: 'Customer Name',
    //             email: 'customer@example.com',
    //             contact: '9039024004'
    //         },
    //         theme: {
    //             color: '#3009cc'
    //         }
    //     };

    //     event.preventDefault();

    //     try {
    //         const response = await fetch(`https://api.razorpay.com/v1/checkout/embedded?key_id=rzp_test_6gzv7z3KTwC4Gp&order_id=order123&amount=100&currency=INR`);
    //         //const response = await fetch(`https://api.razorpay.com/v1/preferences?key_id=rzp_test_6gzv7z3KTwC4Gp&amount=50000&currency=INR`);

    //         const responseData = await response.json();

    //         const rzp1 = new window.Razorpay({
    //             key: options.key,
    //             ...responseData // Include other response data if needed
    //         });
    //         //debugger;
    //         rzp1.open();
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    const handlePayment = async (rupeeAmt, orderNo, ord1Id) => {
        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });
    }

    const handleJustPayPayment = async (ord1Id) => {
        setLoading(true);
        const orderData = tableOrder.find(item => item.ord1Id === ord1Id);

        if (!orderData) {
            console.error('Order not found');
            return;
        }

        let orderNo = orderData.orderNo;

        let totalAmt = orderData.confirmTotalAmt;
        if (orderData.paymentType == 2) {
            totalAmt = orderData.grandTotal;
        }
        const balAmt = Number(totalAmt - (orderData.o1CancelledAmt + orderData.paymentAmount));

        if (balAmt && balAmt != 0) {
            // ******************* Payment Gateway ********************
            const filteredData = [{
                fieldName: "ord1Id",
                operatorName: "Equal",
                compareValue: orderData.ord1Id,
            }]

            const dateTime = await CurrentDateAndTime.getCurrentDateTime();
            // const dateTime = date.toISOString();

            const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

            let paymentData;

            if (previousPaymentDetails && previousPaymentDetails.length > 0) {
                const spiltData = Number((previousPaymentDetails[0].orderNo).split("_")[1]) || 0;

                orderNo = `${orderData.orderNo}_${spiltData + 1}`

                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
                try {
                    console.log("if - 1", paymentData)
                    const insertedData = await Payment.insertPayment(paymentData);
                    console.log("if - 2", insertedData)
                } catch (e) {
                    alert("if", e)
                    console.log(e);
                }
            }
            else {
                orderNo = orderData.orderNo;
                paymentData = {
                    ord1Id: orderData.ord1Id,
                    orderNo: orderNo,
                    orderRefNo: orderData.orderSeriesId,
                    date: dateTime,
                    amount: balAmt,
                    apiId: 0,
                    apiName: "",
                    pgOrderId: "",
                    pgOrderStatus: "",
                    pgTrackingId: "",
                    pgBankRefNo: "",
                    pgFailureMessage: "",
                    pgPaymentMode: "",
                    pgCardName: "",
                    pgStatusCode: "",
                    pgStatusMessage: "",
                    transactionDetail: "",
                    remarks: "",
                }
                try {
                    console.log("else - 1", paymentData)
                    const insertedData = await Payment.insertPayment(paymentData);
                    console.log("else - 2", insertedData)
                } catch (e) {
                    alert("else", e)
                    console.log(e);
                }
                // const insertedData = await Payment.insertPayment(paymentData);
            }

            // ***********************************
            const protocol = window.location.protocol;
            const hostname = window.location.hostname;
            const port = window.location.port;

            const fullUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

            // const returnUrl = `${fullUrl}/Response`;
            const returnUrl = `https://pay.mothershut.com/Response`;

            const d2AccountId = orderData.accId || orderData.d2AccountId || 0;

            const data = { orderNo, balAmt, returnUrl, d2AccountId };
            const apiUrl = `${WebApiUrl}/api/JusPayPayment/initiateJuspayPayment`;
            try {
                const response = await axios.post(apiUrl, data, { withCredentials: true });

                if (response.data && response.data.payment_links && response.data.payment_links.web) {
                    const url = response.data.payment_links.web;
                    console.log("orderNo", orderNo);
                    window.location.href = url;
                    localStorage.setItem("JusPayOrderNo", orderNo);
                    localStorage.setItem("Ord1Id", ord1Id);
                } else {
                    console.error('Payment links not found in response');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error initiating payment:', error);
                setLoading(false);
            }
        }
        else if (balAmt == 0) {
            // alert("Balance Amount is Zero(0)");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Balance Amount is Zero(0)');
        }
        setLoading(false);
        //alert(response.status)
        // const xhr = new XMLHttpRequest();
        // console.log(xhr)
        // xhr.open('POST', 'initiateJuspayPayment', true);
        // xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        // xhr.onreadystatechange = function () {
        //     if (xhr.readyState === 4) {
        //         const response = JSON.parse(xhr.responseText);
        //         if (xhr.status === 200) {
        //             if (response.status === 'NEW') {
        //                 const url = response.payment_links.web;
        //                 window.location.href = url;
        //             }
        //         } else {
        //             alert(`http status code: ${xhr.status} message: ${response.message}`);
        //         }
        //     }
        // };
        // xhr.send(null);
    };

    const [PineLabModal, setPineLabModal] = useState(false);

    const handlePineLabPayment = async (balanceAmount, orderNo) => {
        setBalanceAmount(balanceAmount);
        setOrderNo(orderNo);
        setPineLabModal(true)
    }

    const closePineLab = () => {
        setPineLabModal(false);
    }

    const handleFocus = (event) => {
        event.target.select();
    };

    const orderDetailClick = async (ord1Id, d1Id, d2Id, ord2Id, orderNo) => {
        navigate("/OrderDetail", {
            state: {
                ord1Id, d1Id, d2Id, ord2Id, orderNo
            }
        })
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }
    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    // const [cancelDataState, setCancelDataState] = useState({});

    // useEffect(() => {
    //     const fetchCancelData = async () => {
    //         const results = await Promise.all(tableOrder.filter(item => item.countCancelled != 0 || item.overallOrderStatus != "Pending" || item.paymentStatus != "Done")
    //             .map(data => OrderApi.getCancelItemByOrd1Id(data.ord1Id)));
    //         const cancelDataMap = results.reduce((acc, cancelData, idx) => {
    //             acc[tableOrder[idx].ord1Id] = cancelData.length > 0;
    //             return acc;
    //         }, {});
    //         setCancelDataState(cancelDataMap);
    //     };

    //     if (tableOrder && tableOrder.length > 0) {
    //         fetchCancelData();
    //     }
    // }, [tableOrder]);

    const [paymentDetailModalPopup, setPaymentDetailModal] = useState(false)

    const openPaymentDetailsModal = async (ord1Id) => {
        setLoading(true);
        const data = await handleGetPayment(ord1Id)
        if (data)
            setPaymentDetailModal(true)
        setLoading(false);
    }

    const closePaymentDetailsModal = () => {
        setPaymentDetailModal(false)
    }
    const [PaymentModalData, setPaymentModalData] = useState([]);

    const handleGetPayment = async (orderId) => {
        const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
        console.log(PaymentData);
        setPaymentModalData(PaymentData);
        if (PaymentData.length > 0) {
            setPaymentModal(true);
            return true
        }
        else {
            // alert("No Payment Found");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('No Payment Found');
            return false;
        }
    };

    const handleSendSMS = async (e) => {
        // const imageUpload = await SmsTemplate.uploadWhatsAppFile(e.target.files[0]);
        messageFunction("Order Done", 10760, 5055, "8109833688")
    }

    const containerRef = useRef(null);

    const handleScroll = (id) => {
        console.log(scrollOrd1Id)
        if (containerRef.current) {
            const targetElement = document.getElementById(id);

            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                localStorage.setItem('ord1Id', 0)
            } else {
                console.log(`Element with ID '${id}' not found.`);
            }
        }
    };

    useEffect(() => {
        const ord1Id = localStorage.getItem('ord1Id')
        if (scrollOrd1Id || ord1Id != 0) {
            const timer = setTimeout(() => {
                handleScroll(scrollOrd1Id || ord1Id);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [scrollOrd1Id, tableOrder]);

    const [openBellPopup, setOpenBellPopup] = useState(false);
    const [transferToPopupData, setTransferToPopupData] = useState();
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        fetchTranferData();
    }, [])

    const fetchTranferData = async () => {
        try {
            setLoading(true)
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            const data = await OrderApi.getPopUpNotificationItemListByUserId(loginUser.userId);
            setLoading(false)
            if (data.length > 0) {
                setTransferToPopupData(data);
                setNotificationCount(data.length)
                setLoading(false);
                return data;
            }
            // else {
            //     setTransferToPopupData([]);
            //     setOpenBellPopup(false);
            //     setNotificationCount(data.length)
            //     alert("Zero(0) item for transfer")
            //     setLoading(false);
            //     return [];
            // }
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const handleBellIcon = async () => {
        setLoading(true);
        const data = transferToPopupData

        if (data && data.length > 0) {
            setOpenBellPopup(true);
            setLoading(false);
        }
        else {
            // alert("No more item for transfer");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('No more items for transfer');
            setLoading(false);
        }
    }

    const closeBellPopup = () => {
        setOpenBellPopup(false)
    }

    const transferToKitchen = async (ord1Id, ord2Id, status) => {
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);

        const updatedValues =
        {
            ord1Id: ord1Id,
            ord2Id: ord2Id,
            orderStatus: "Transferred",//status,
            orderDateTime: currentDate,
        }
        await OrderApi.insertItemStatus(updatedValues);

        const data = [
            {
                operationType: 0,
                path: "KotStatus",
                op: "Add",
                from: "string",
                value: "Transferred"
            },
            {
                operationType: 0,
                path: "CookStatus",
                op: "Add",
                from: "string",
                value: "Queue"
            }
        ]
        await OrderApi.updateItemPartially(data, ord2Id);
        await fetchTranferData();
        // alert("Transferred Successfully");
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop("");
        setMessage('Transferred Successfully');
    }

    const [selectedTableId, setSelectedTableId] = useState(null);

    const handleTableClick = (tableId) => {
        setSelectedTableId(prevId => (prevId === tableId ? null : tableId));
    };

    const [groupedData, setGroupedData] = useState([]);

    useEffect(() => {
        const data = (transferToPopupData || []).reduce((acc, data) => {
            if (!acc[data.tableId]) {
                acc[data.tableId] = [];
            }
            acc[data.tableId].push(data);
            return acc;
        }, {});
        setGroupedData(data);

    }, [transferToPopupData]);

    const openPOSPage = () => {
        localStorage.setItem("isOrderFromMobile", "Yes");
        navigate(`/POSInvoice`);
    }

    return (
        <div style={{ backgroundColor: "white" }} id="yourContainerDivId" ref={containerRef}> {/*#a52a2a59*/}
            <div
            // style={{
            //     height: '100vh',
            //     display: 'flex',
            //     flexDirection: 'column',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            // }}

            //="pullDownContent" style={contentStyle}
            >
                <AppHeader title={"Order Taker App"} counting={notificationCount} bellIcon={true} handleBellIcon={handleBellIcon} link={"/OrderTakerView"} setLoading={setLoading} fetchData={fetchData} sidebar={sidebar} />
                <div className='containerTop-ot' style={{ backgroundColor: tableOrder && tableOrder.length > 0 ? "dimgrey" : "white" }} onClick={sidebarclose}>
                    {/* <ReactPullToRefresh onRefresh={handleRefresh} > */}
                    {/* <button onClick={() => handleScroll(10823)}>Testing</button> */}
                    {/* <input type="file" onChange={handleSendSMS} /> */}
                    {/*########################################################################################################*/}
                    {tableOrder && tableOrder.length > 0 && tableOrder.slice().reverse().map((data, index) => {
                        let backgroundColor = "white"; // Set default color
                        // if (data.runningOrderCounter && data.runningOrderCounter > 0) {
                        if (data.hasRunningOrder == true) {
                            if (showRunningOrderColor)
                                backgroundColor = "rgb(234, 163, 163)";
                            else
                                backgroundColor = "white"; //"#d0f0d0";   rgb(185, 234, 163)
                        }
                        let totalAmt = data.confirmTotalAmt;
                        if (showBillAmt == true) {
                            if (data.paymentType == 2) {   //Prepaid
                                totalAmt = data.grandTotal;
                            }
                        }
                        else {
                            totalAmt = data.grandTotal;
                        }
                        // console.log(data)
                        return (
                            <div className="detail-panel" id={data.ord1Id} key={data.ord1Id}>
                                <div className="topBar" style={{ justifyContent: "space-between", backgroundColor: backgroundColor }} >
                                    <div className="" style={{ display: "flex", marginLeft: "12px", padding: "0px" }}>
                                        <i className="fa-solid fa-utensils mt-1" style={{ color: "black" }}></i>
                                        <div className="flex-start" style={{ marginLeft: "20px", padding: "0" }}>
                                            <label className="ans" style={{ fontSize: "14px", color: "black" }}>Table No : &nbsp;</label>
                                            <label className="ques" style={{ fontSize: "14px", color: "black" }}>{data.tableName}</label>
                                        </div>
                                    </div>
                                    <div className="flex-start" style={{ padding: "0" }}>
                                        <label className="ans" style={{ fontSize: "14px", color: "black" }}>Order No : &nbsp;</label>
                                        <label className="ques" style={{ fontSize: "14px", color: "black" }}>{data.orderNo}</label>
                                    </div>
                                </div>
                                <div className="flex-start" style={{ padding: "0px" }}>
                                    {/***************Left container ************/}
                                    <div className="leftContainer">
                                        <>
                                            <Link to={`/CustomerInfo?accId=${data.d1AccId}&orderNo=${data.orderNo}`} style={{ textDecoration: "none" }}>
                                                <div className="smallCircle" style={{ backgroundColor: "#fe6480", marginTop: "0px" }}>
                                                    {/* <i className="fa-solid fa-user leftSideIcon" style={{ marginRight: "0px" }}></i> */}
                                                    <label className="iconName ans">Cust. Info</label>
                                                </div>
                                            </Link>
                                            {/* <label className="iconName ans">Cust. Info</label> */}
                                        </>
                                        <>
                                            <div className="smallCircle" style={{ backgroundColor: "#f29c2d" }} onClick={() => callButtonClick(data.mobileNo)}>
                                                {/* <i className="fa-solid fa-phone leftSideIcon" style={{ marginRight: "0px" }}></i> */}
                                                <label className="iconName ans">Call Cust.</label>
                                            </div>
                                            {/* <label className="iconName ans">Call Cust.</label> */}
                                        </>

                                        {/* <>
                                            <div className="smallCircle" style={{ backgroundColor: "#ce77fa" }} onClick={EmailBtn}>
                                                <i className="fa-solid fa-envelope leftSideIcon" style={{ marginRight: "0px" }}></i>
                                            </div>
                                            <label className="iconName ans">Email</label>
                                        </> */}
                                        {showToast && (
                                            <div className="toastDiv" >
                                                Message Send Successfully !!
                                            </div>
                                        )}
                                        <>
                                            <div className="smallCircle" style={{ backgroundColor: "rgb(213, 92, 114)" }} onClick={() => openModal(data.d1AccId, data.orderNo, data.name, data.accMobileNo, data.companyGSTNo, data.companyDetails, data.ord1Id)}>
                                                {/* <i className="fa-solid fa-g leftSideIcon"></i> */}
                                                <label className="iconName ans">GST</label>
                                            </div>
                                            {/* <label className="iconName ans">GST</label> */}
                                        </>

                                        <>
                                            <div className="smallCircle" style={{ backgroundColor: "rgb(42, 159, 121)" }} onClick={SendSMS}>
                                                {/* <i className="fa-solid fa-comment-sms leftSideIcon"></i> */}
                                                <label className="iconName ans">MSG</label>
                                            </div>
                                            {/* <label className="iconName ans">Message</label> */}
                                        </>
                                        {/* <>
                                            <div className="smallCircle" style={{ backgroundColor: "#ff944e" }} onClick={openResetTableStatusModal}>
                                                <i className="fa-solid fa-minus leftSideIcon"></i>
                                            </div>
                                            <label className="iconName ans">Table</label>
                                            <label className="iconName ans" style={{ lineHeight: "0" }}>Status</label>
                                        </> */}

                                        <Link to={`/OrderFeedback?ord1Id=${data.ord1Id}`} style={{ textDecoration: "none" }}>
                                            <>
                                                <div className="smallCircle" style={{ backgroundColor: "#00b3ed" }}>
                                                    {/* <i className="fa-solid fa-star leftSideIcon"></i> */}
                                                    <label className="iconName ans">Feed back</label>
                                                </div>
                                                {/* <label className="iconName ans">Feedback</label> */}
                                            </>
                                        </Link>
                                        {/* <>
                                            <div className="smallCircle" style={{ backgroundColor: "#fd637f" }} onClick={openComplimentaryBonusModal}>
                                                <i className="fa-solid fa-gift leftSideIcon"></i>
                                            </div>
                                            <label className="iconName ans">Complimentary</label>
                                        </> */}
                                        <>
                                            <div className="smallCircle" style={{ backgroundColor: "#ff4163" }} onClick={() => openTableChangeModal(data.orderNo, data.tableName, data.ord1Id, data.tableId)}>
                                                {/* <i className="fa-solid fa-t leftSideIcon"></i> */}
                                                {/* <img className="OTImage" src={tableChangeIcon} alt="" /> */}
                                                <label className="iconName ans">Table Change</label>
                                            </div>
                                            {/* <label className="iconName ans" style={{}}>Table</label> */}
                                            {/* <label className="iconName ans" style={{}}>T.Change</label> */}
                                        </>
                                    </div>
                                    {/***************Center container ************/}
                                    <div className="bg-white" style={{ padding: "0px 10px", paddingTop: "0px" }}>
                                        <>
                                            <div className="flex-start-ot">
                                                <label className="ques">Mobile No : &nbsp;</label>
                                                <label className="ans">{data.accMobileNo}</label>
                                            </div>
                                            <div className="space-between">
                                                <div className="flex-start-ot">
                                                    <label className="ques">Bill Amt : &nbsp;</label>
                                                    {/* {showBillAmt ?
                                                    (data.overallOrderStatus === "Confirm" ? (
                                                        <label className="ans">&#x20B9; {data.confirmTotalAmt}</label>
                                                    ) : <label className="ans">&#x20B9; 0</label>
                                                    ) : */}
                                                    <label className="ans">&#x20B9; {totalAmt}</label>
                                                    {/* } */}
                                                    {/* {data.orderConfirmedByName !== "" && (
                                                    <label className="ans">&#x20B9; {data.grandTotal}</label>
                                                )} */}
                                                    {/* <label className="ans">&#x20B9; {data.grandTotal}</label> */}
                                                </div>
                                                <div className="flex-start-ot">
                                                    <label className="ques">Cancel Amt : &nbsp;</label>
                                                    <label className="ans">&#x20B9; {data.o1CancelledAmt == null ? 0 : data.o1CancelledAmt}</label>
                                                    {/* {showBillAmt ?
                                                    (data.overallOrderStatus === "Confirm" ? (
                                                        <label className="ans">&#x20B9; {data.o1CancelledAmt}</label>
                                                    ) : <label className="ans">&#x20B9; 0</label>
                                                    ) :
                                                    <label className="ans">&#x20B9; {data.o1CancelledAmt}</label>
                                                } */}
                                                </div>
                                            </div>
                                            <div className="space-between">
                                                <div className="flex-start-ot">
                                                    <label className="ques">Total Amt : &nbsp;</label>
                                                    {/* {showBillAmt ?
                                                    (data.overallOrderStatus === "Confirm" ? (
                                                        <label className="ans">&#x20B9; {totalAmt}</label>
                                                    ) : <label className="ans">&#x20B9; 0</label>
                                                    ) : */}
                                                    <label className="ans">&#x20B9; {totalAmt}</label>
                                                    {/* } */}
                                                </div>
                                                <div className="flex-start-ot">
                                                    <label className="ques">Paid Amt : &nbsp;</label>
                                                    {/* <label className="ans">&#x20B9; {data.paymentAmount}</label> */}
                                                    {/* {showBillAmt ?
                                                    (data.overallOrderStatus === "Confirm" ? (
                                                        (data.paymentAmount ? <label className="ans">&#x20B9; {data.paymentAmount}</label> : <label className="ans">&#x20B9; 0</label>)
                                                    ) : <label className="ans">&#x20B9; 0</label>
                                                    ) : */}
                                                    <label className="ans">&#x20B9; {data.paymentAmount}</label>
                                                    {/* } */}
                                                </div>
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Balance Amount : &nbsp;</label>
                                                {/* <label className="ans">&#x20B9; {data.grandTotal - data.paymentAmount}</label> */}
                                                {/* <label className="ans">&#x20B9; {data.grandTotal - data.o1CancelledAmt - data.paymentAmount}</label> */}
                                                {/* {showBillAmt ?
                                                (data.overallOrderStatus === "Confirm" ? (
                                                    <label className="ans">&#x20B9; {totalAmt - data.o1CancelledAmt - data.paymentAmount}</label>
                                                ) : <label className="ans">&#x20B9; 0</label>
                                                ) : */}
                                                <label className="ans">&#x20B9; {totalAmt - (data.o1CancelledAmt + data.paymentAmount)}</label>
                                                {/* } */}
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Order Date : &nbsp;</label>
                                                <label className="ans">{moment(data.orderDate).format('DD-MM-YYYY')}, {moment(data.orderTime, "hh:mm A").format("hh:mm A")}</label>
                                                {/* <label className="ans">{data.orderDate} {data.orderTime}</label> */}
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Order Status : &nbsp;</label>
                                                <label className="ans">{data.overallOrderStatus}</label>
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Delivery Time : &nbsp;</label>
                                                {/* <label className="ans">{data.deliveryTime}</label> */}
                                                {/* <label className="ans">{timeFormat(data.deliveryTime)}</label> */}
                                                <label className="ans">{data.timeSlotName}</label>
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Payment Status : &nbsp;</label>
                                                <label className="ans">{data.paymentStatus}</label>
                                            </div>
                                            <div>
                                                {isShowPaymt && (
                                                    <div style={{ display: "flex" }}>
                                                        <label className="ques">Payment Mode :&nbsp;</label>
                                                        <label className="ans">{data.paymentModeName} &nbsp;</label>
                                                        <label className="ans" style={{ display: totalAmt - data.paymentAmount === 0 ? "none" : "block" }}></label>

                                                        {(data.overallOrderStatus === "Pending" && data.paymentType === 2 && data.paymentStatus !== "Done") || data.overallOrderStatus !== "Pending" ? (
                                                            <>
                                                                <label
                                                                    className="ans"
                                                                    style={{
                                                                        display: totalAmt - data.paymentAmount === 0 ? "none" : "block",
                                                                        color: "darkblue",
                                                                        textDecoration: "underline",
                                                                    }}
                                                                    onClick={() => handleJustPayPayment(data.ord1Id)}
                                                                >
                                                                    Jus Pay
                                                                </label>

                                                                {showPineDevice && (
                                                                    <div style={{ display: "flex" }}>
                                                                        <label
                                                                            className="ans"
                                                                            style={{
                                                                                display: totalAmt - data.paymentAmount === 0 ? "none" : "block",
                                                                                color: "black",
                                                                            }}
                                                                        >
                                                                            &nbsp;or&nbsp;
                                                                        </label>
                                                                        <label
                                                                            className="ans"
                                                                            style={{
                                                                                display: totalAmt - data.paymentAmount === 0 ? "none" : "block",
                                                                                color: "darkblue",
                                                                                textDecoration: "underline",
                                                                            }}
                                                                            onClick={() => handlePineLabPayment(data.grandTotal - data.paymentAmount, data.orderNo)}
                                                                        >
                                                                            Pay Pine Lab
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Kot Manager : &nbsp;</label>
                                                <label className="ans">{data.kitchenManagerName}</label>
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Confirm By : &nbsp;</label>
                                                <label className="ans">{data.orderConfirmedByName}</label>
                                            </div>
                                            <div className="flex-start-ot">
                                                <label className="ques">Confirm On : &nbsp;</label>
                                                <label className="ans" style={{ display: !data.orderConfirmedByName ? "none" : "block" }}>
                                                    {/* {moment.utc(data.orderConfirmedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} */}
                                                    {convertDateTimeToDateAndTime(data.orderConfirmedOn)}
                                                </label>
                                            </div>
                                            <div className="flex-start-ot">
                                                <div className="flex-start-ot">
                                                    <label className="ques">Adult : &nbsp;</label>
                                                    <input type="number" className="preferenceInput" ref={(el) => (inputRef.current[index] = el)}
                                                        onFocus={(e) => handleFocus(e, index)} name="noAdultPerson"
                                                        // onChange={(e) => handlePersonChange(data.ord1Id, e)}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                                            handlePersonChange(data.ord1Id, { target: { name: "noAdultPerson", value } });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "." || e.key === "-" || e.key === "e") {
                                                                e.preventDefault(); // Prevent decimals, negatives, and exponential input
                                                            }
                                                        }}
                                                        value={data.noAdultPerson} />
                                                </div>
                                                <div className="flex-start-ot" style={{ marginLeft: "-20px" }}>
                                                    <label className="ques">&nbsp; Child : &nbsp;</label>
                                                    <input type="number" className="preferenceInput" onFocus={handleFocus} value={data.noChildPerson} name="noChildPerson"
                                                        // onChange={(e) => handlePersonChange(data.ord1Id, e)} 
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                                            handlePersonChange(data.ord1Id, { target: { name: "noChildPerson", value } });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "." || e.key === "-" || e.key === "e") {
                                                                e.preventDefault(); // Prevent decimals, negatives, and exponential input
                                                            }
                                                        }}
                                                    /> &nbsp; &nbsp;
                                                    {/* <i className="fa-solid fa-plus" onClick={() => openNoOfPersonModal(data.ord1Id)} style={{ color: "black", fontSize: "12px", padding: "5px", margin: "2px", backgroundColor: "lightgray" }}></i> */}
                                                    <i className="fa-solid fa-floppy-disk" onClick={() => personChangeBtn(data.ord1Id)} style={{ color: "black", fontSize: "18px", padding: "0px", margin: "2px", backgroundColor: "white" }}></i>
                                                </div>
                                            </div>
                                        </>
                                        {/********************* Bottom Container **************/}
                                        <div className="bottomContainer" style={{ borderTop: "1px solid lightgrey", marginLeft: "-6px" }}>
                                            {/* <label htmlFor=""></label> */}
                                            {/* <Link to={`/OrderDetail?ord1Id=${data.ord1Id}&d1Id=${data.d1AccId}&d2Id=${data.accId}&ord2Id=${data.ord2Id}&orderNo=${data.orderNo}`} style={{ textDecoration: 'none' }}>
                                            <div className="smallCircle" style={{ backgroundColor: "#5a51d0", marginLeft: "-15px", marginTop: "0px" }}>
                                                <i className="fa-solid fa-bars leftSideIcon" style={{ marginRight: "0px" }}></i>
                                            </div>
                                        </Link> */}
                                            <div className='alignCenterOT'>
                                                <div className="smallCircle" onClick={() => orderDetailClick(data.ord1Id, data.d1AccId, data.accId, data.ord2Id, data.orderNo)} style={{ backgroundColor: "#5a51d0", marginTop: "0px" }}>
                                                    {/* <i className="fa-solid fa-bars leftSideIcon" style={{ marginRight: "0px" }}></i> */}
                                                    {/* <img className="OTImage" src={View} alt="" /> */}
                                                    <label className="iconName ans">View Order</label>
                                                </div>
                                                {/* <label className="iconName ans">V.Order</label> */}
                                            </div>

                                            {/************** Set Order Status *************/}
                                            {!isFloorManager && (
                                                <>
                                                    <div className='alignCenterOT'>
                                                        <div className="smallCircle" onClick={() => openOrderStatusModal(data.ord1Id, data.overallOrderStatus, data.accMobileNo, data.orderSeriesId, totalAmt, data.paymentAmount, data.d1AccId, data.accId,
                                                            data.ord2Id, data.orderNo, index, data.tableId, data.o1CancelledAmt, data.hasRunningOrder, data.runningOrderCounter, data.orderSeriesId, data.paymentType,
                                                            data.orderConfirmedByName)} style={{ backgroundColor: "#3ec277", marginTop: "0px" }}>
                                                            {/* <i className="fa-solid fa-pen-to-square leftSideIcon" style={{ marginRight: "0px" }}></i> */}
                                                            {/* <img className="OTImage" src={OrderStatus} alt="" /> */}
                                                            <label className="iconName ans">Order Status</label>
                                                        </div>
                                                        {/* <label className="iconName ans">O.Status</label> */}
                                                    </div>
                                                    <div className='alignCenterOT'>
                                                        <div className="smallCircle" onClick={() => openPaymentDetailsModal(data.ord1Id,)} style={{ backgroundColor: "#d55c72", marginTop: "0px" }}>
                                                            {/* <i class="fa-regular fa-credit-card leftSideIcon" style={{ marginRight: "0px" }}></i> */}
                                                            {/* <img className="OTImage" src={Pay} alt="" /> */}
                                                            <label className="iconName ans">Pay Details</label>
                                                        </div>
                                                        {/* <label className="iconName ans">P.Detail</label> */}
                                                    </div>
                                                    {/* <div className="smallCircle" onClick={() => openPaymentStatusModal(data.ord1Id, data.paymentAmount, data.balanceAmt, data.grandTotal, data.paymentStatus, data.overallOrderStatus)} style={{ backgroundColor: "rgb(151, 130, 19)", marginTop: "0px" }}>
                                                    <i className="fa-solid fa-indian-rupee-sign leftSideIcon" style={{ marginRight: "0px" }}></i>
                                                    </div> */}
                                                    {/* <div style={{ display: fetchDataByOrderId(data.ord1Id) == "Pending" ? "block" : "none" }}> */}
                                                    {/* <h1>{fetchDataByOrderId(data.ord1Id)}</h1> */}

                                                    {isShowPaymt && data.paymentStatus !== "Done" && (
                                                        (data.overallOrderStatus !== "Pending" || data.paymentType === 2) && (
                                                            <div className="alignCenterOT">
                                                                <div
                                                                    className="smallCircle"
                                                                    onClick={() =>
                                                                        openPaymentStatusModal(
                                                                            data.ord1Id,
                                                                            data.paymentAmount,
                                                                            data.balanceAmt,
                                                                            totalAmt,
                                                                            data.paymentStatus,
                                                                            data.overallOrderStatus,
                                                                            data.o1CancelledAmt
                                                                        )
                                                                    }
                                                                    style={{
                                                                        backgroundColor: "rgb(151, 130, 19)",
                                                                        marginTop: "0px",
                                                                    }}
                                                                >
                                                                    {/* <img className="OTImage" src={Rupee} alt="Rupee Icon" /> */}
                                                                    <label className="iconName ans">Make Payment</label>
                                                                </div>
                                                                {/* <label className="iconName ans">Payment</label> */}
                                                            </div>
                                                        )
                                                    )}

                                                </>
                                            )}
                                            {/* </div> */}

                                            {/* <div className="smallCircle" onClick={handlePrintButton} style={{ backgroundColor: "#cb77ff", marginTop: "0px" }}>
                                            <i className="fa-solid fa-print leftSideIcon"></i>
                                        </div> */}
                                            {data.paymentStatus == "Done" && (
                                                <div className='alignCenterOT'>
                                                    <div className="smallCircle" onClick={() => handlePrintButton(data.ord1Id, data.paymentAmount, data.balanceAmt, totalAmt, data.paymentStatus, data.orderStatus)} style={{ backgroundColor: "#cb77ff", marginTop: "0px" }}>
                                                        {/* <i className="fa-solid fa-print leftSideIcon"></i> */}
                                                        <label className="iconName ans">Print</label>
                                                    </div>
                                                    {/* <label className="iconName ans">Print</label> */}
                                                </div>
                                            )}
                                            {!isFloorManager && (
                                                data.countCancelled != 0 || data.overallOrderStatus == "Pending" || data.paymentStatus == "Done" ? (
                                                    <></>
                                                ) :
                                                    companyData?.isEnblCnclItmFrmOTApp && (
                                                        <Link to={`/CancelOrderedItem?ord1Id=${data.ord1Id}&orderNo=${data.orderNo}`} style={{ textDecoration: 'none' }}>
                                                            <div className='alignCenterOT'>
                                                                <div className="smallCircle" style={{ backgroundColor: "#ff944e", marginTop: "0px" }}>
                                                                    {/* <i className="fa-solid fa-c leftSideIcon"></i> */}
                                                                    {/* <img className="OTImage" src={Cancel} alt="" /> */}
                                                                    <label className="iconName ans">Cancel Order</label>
                                                                </div>
                                                                {/* <label className="iconName ans">Cancel</label> */}
                                                                {/* <label className="iconName ans" style={{}}>C.Order</label> */}
                                                            </div>
                                                        </Link>
                                                    )
                                            )}
                                            {/* <div className="smallCircle" style={{ backgroundColor: "#ff944e", marginTop: "0px" }} onClick={() => openCancelOrderModal(data.ord1Id, data.orderNo)}>
                                            <i className="fa-solid fa-c leftSideIcon"></i>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {(tableOrder == null || tableOrder == undefined || tableOrder.length == 0) && (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                            <label>No Order Found.</label>
                        </div>
                    )}
                    {/* </ReactPullToRefresh> */}
                </div>
                {/* ****************************************** */}
                {/* <div style={{ backgroundColor: "white", display: "flex", justifyContent: "center", alignContent: "center", marginTop: "25px" }}>
                    {(tableOrder == null || tableOrder.length < 0) || (
                        <div style={{ color: "gray", fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>No More Orders</div>
                    )}
                </div> */}
                {/***********************************/}
                <div className="floating-button-container">
                    <div className="circle" onClick={OpenSearchModal} style={{ width: "45px", height: "45px", marginRight: "-4px" }}>
                        <i className="fa-solid fa-magnifying-glass icon-white"></i>
                    </div>
                    <div className="circle mt-2" onClick={openPOSPage}>
                        {/* <i className="fa-brands fa-slack icon-white"></i> */}
                        {/* <i className="fa-solid fa-plus icon-white" style={{ marginLeft: "2px" }}></i> */}
                        <img className="OTImage" src={newOrder} alt="" style={{ height: "65px", width: "65px" }} />
                    </div>
                    {/* <div className="circle mt-1" onClick={handleWesiteRedirect}>
                        <i className="fa-brands fa-slack icon-white" onClick={handleWesiteRedirect}></i>
                    </div> */}
                </div>
                {/*############################################### Side Bar #######################################*/}
                <div className='backbtn'>
                    <div id="" className="wrapper1">
                        <div className="">
                            <div id="sidebar1" className="sidebar1">
                                <div className="space-between side-topbar">
                                    <div className='flex-start'>
                                        <div className="img_icon">
                                            <img className="userProfileIcon" src="https://clipart-library.com/images/kTKo7BB8c.png" alt="user" />
                                        </div>
                                        <div className="" style={{ margin: "7px 10px auto" }}>
                                            <h5 className="c-w">{loginUserDetails ? loginUserDetails.userName : ""}</h5>
                                            <p className="c-w" style={{ marginTop: "-8px" }}>{firmName}</p>
                                        </div>
                                    </div>
                                    <p className="back_button" onClick={sidebarclose} style={{ color: "white", textAlign: "end", padding: "8PX" }}>
                                        <i className="fa-solid fa-xmark" style={{ color: "white", fontSize: "22px" }}></i>
                                    </p>
                                </div>
                                <div className="side-bottomBar">
                                    <Link to="/TableOrderAllocation" style={{ textDecoration: "none" }}>
                                        <div className="flex-start linksDiv">
                                            <i className="fa-solid fa-chair"></i>
                                            <label className=""> &nbsp; Table Allocation</label>
                                        </div>
                                    </Link>
                                    <hr />
                                    {/* <div className="flex-start linksDiv">
                                        <i className="fa-solid fa-bullseye"></i>
                                        <label className=""> &nbsp; Sale Target</label>
                                    </div><hr />
                                    <div className="flex-start linksDiv">
                                        <i className="fa-solid fa-chair"></i>
                                        <label className=""> &nbsp; Merge Table</label>
                                    </div><hr /> */}
                                    <div className="flex-start linksDiv" onClick={openPOSPage}>
                                        <i className="fa-solid fa-n"></i>
                                        <label className=""> &nbsp; New Order</label>
                                    </div>
                                    {/* <div className="flex-start linksDiv" onClick={handleWesiteRedirect}>
                                        <i className="fa-solid fa-n"></i>
                                        <label className=""> &nbsp; New Order</label>
                                    </div> */}
                                    <hr />
                                    {/* <div className="flex-start linksDiv">
                                        <i className="fa-solid fa-money-bill-wave"></i>
                                        <label className=""> &nbsp; Item Incentive</label>
                                    </div><hr /> */}
                                    <Link to="/CancellationReport" style={{ textDecoration: "none" }}>
                                        <div className="flex-start linksDiv">
                                            <i className="fa-solid fa-c"></i>
                                            <label className=""> &nbsp; Cancellation Report</label>
                                        </div>
                                    </Link><hr />
                                    <Link to="/PaymentReceivedDetail" style={{ textDecoration: "none" }}>
                                        <div className="flex-start linksDiv">
                                            <i className="fa-solid fa-file"></i>
                                            <label className=""> &nbsp; Payment Receive Report</label>
                                        </div>
                                    </Link><hr />
                                    {/* <div className="flex-start linksDiv" onClick={fetchDataFromPineLabs}>
                                        <i className="fa-solid fa-file"></i>
                                        <label className=""> &nbsp; Fetch Data from Pine Labs</label>
                                    </div><hr />
                                    {responseData && (
                                        <div>
                                            <h2>Response from Pine Labs:</h2>
                                            <pre>{JSON.stringify(responseData, null, 2)}</pre>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/************************** GST Update Modal ***************/}
                {
                    Modal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Update GST Details</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeModal}></i>
                                </div>
                                <hr />
                                <div className="">
                                    <div className="flex-start p-1">
                                        <label className="ques">Order No : &nbsp;</label>
                                        <label className="ans"> {orderNo}</label>
                                    </div>
                                    <div className="flex-start p-1">
                                        <label className="ques">Mobile No : &nbsp;</label>
                                        <label className="ans"> {accountDetails.mobileNo}</label>
                                    </div>
                                    <div className="flex-start p-1">
                                        <label className="ques">Address : &nbsp;</label>
                                        <label className="ans"> {accountDetails.address}</label>
                                    </div>
                                    <div className="flex-start p-1">
                                        <label className="ques">Name :&nbsp;</label>
                                        <input type="text" className="preferenceInput" name="name" value={accountDetails.name} onChange={handleGstInputChange} style={{ width: "50%" }} />
                                    </div>
                                    <div className="flex-start p-1">
                                        <label className="ques">Company Gst No&nbsp;:&nbsp;</label>
                                        <input type="text" className="preferenceInput" name="companyGSTNo" value={accountDetails.companyGSTNo} onChange={handleGstInputChange} style={{ width: "50%" }} />
                                    </div>
                                    <div className="flex-start p-1">
                                        <label className="ques">Company Detail&nbsp;:&nbsp;</label>
                                        <input type="text" className="preferenceInput" name="companyDetails" value={accountDetails.companyDetails} onChange={handleGstInputChange} style={{ width: "55%" }} />
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "30%" }} onClick={UpdateGstBtn}>Update</button>
                                    <button className="redBtn" style={{ width: "30%" }} onClick={closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/************************** Order Status Modal ***************/}
                {
                    OrderStatusModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Set Order Status</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeOrderStatusModal}></i>
                                </div>
                                <hr />
                                <div className="">
                                    {/* <input type="text" className="form-control" /> */}
                                    <label className="ques" style={{ marginTop: "0px" }}>Order Status</label>
                                    <select className="form-control inputEffect" onChange={handleOrderStatusChange} value={orderStatus}>
                                        <option value="Pending" >Pending</option> {/*disabled={orderStatus === "Confirm" ? true : false} */}
                                        <option value="Confirm">Confirm</option>
                                        <option value="Done" disabled={selectedOverAllStatus == "Pending" ? true : false}>Done</option>
                                    </select>
                                    <label className="ques" style={{ marginTop: "10px" }}>Mobile Number</label>
                                    <input type="text" value={mobileNo} className="form-control" />
                                    {/*<div className="input-containerEffect mt-1" style={{ margin: "3%" }}>*/}
                                    {/*    <input className="form-control inputEffect" type="text"  id="" required />*/}
                                    {/*    <label id="input1" className="labelEffect " style={{}}></label>*/}
                                    {/*</div>*/}
                                    {/*<div className="input-containerEffect mt-3" style={{ margin: "3%" }}>*/}
                                    {/*    <select className="form-control  inputEffect">*/}
                                    {/*        <option>Select Order Status</option>*/}
                                    {/*        <option>Confirm</option>*/}
                                    {/*        <option>Done</option>*/}
                                    {/*    </select>*/}
                                    {/*    <label id="input1" className="labelEffect " style={{}}>Order Status</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="input-containerEffect mt-3" style={{ margin: "3%" }}>*/}
                                    {/*    <input className="form-control inputEffect" type="text" value="9876543210" id="" required />*/}
                                    {/*    <label id="input1" className="labelEffect " style={{}}>Mobile Number</label>*/}
                                    {/*</div>*/}
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={orderStatusSaveBtn}>Submit</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeOrderStatusModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Reset Table Status Modal ***************/}
                {
                    ResetTableStatusModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Reset Table Status</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeResetTableStatusModal}></i>
                                </div>
                                <hr />
                                <div className="p-1">
                                    <p> Do you really want to Reset Table Status ?</p>
                                </div>
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} >Submit</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeResetTableStatusModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Change Table Modal ***************/}
                {
                    TableChangeModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Change Table Status</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeTableChangeModal}></i>
                                </div>
                                <hr />
                                <div className="p-1">
                                    <p> Do you really want to change Table ?</p>
                                </div>
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={openTableChangeModal2}>Submit</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeTableChangeModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Change Table Modal 2 ***************/}
                {
                    TableChangeModal2 && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Change Table</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeTableChangeModal2}></i>
                                </div>
                                <hr style={{ padding: "0px", margin: "5px", marginTop: "10px" }} />
                                <div className="">
                                    <label className="ques" style={{ marginTop: "5px" }}>Order Number</label>
                                    <input type="text" value={ordDetail.orderNo} className="form-control" />

                                    <label className="ques" style={{ marginTop: "10px" }}>Current Table</label>
                                    <input type="text" value={ordDetail.tableName} className="form-control" />

                                    <label className="ques" style={{ marginTop: "10px" }}>Table List</label>
                                    <select className="form-control  inputEffect" onChange={tableSelected}>
                                        <option>Select</option>
                                        {(() => {
                                            if (tableData !== null && Array.isArray(tableData)) {
                                                return tableData.map((data) => (
                                                    <option key={data.id} value={data.tableId}>
                                                        {data.tableName}
                                                    </option>
                                                ))
                                            }
                                        })()}
                                    </select>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={tableChange}>Save</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeTableChangeModal2}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Cancel Order Modal ***************/}
                {
                    CancelOrderModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Cancel Order</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeCancelOrderModal}></i>
                                </div>
                                <hr />
                                <div className="p-1">
                                    <p> Do you really want to Cancel Item in Table Order ?</p>
                                </div>
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <Link to={`/CancelOrderedItem?ord1Id=${ord1Id}&orderNo=${orderNo1}`} style={{ textDecoration: 'none' }}>
                                        {/* <Link to="/CancelOrderedItem"> */}
                                        <button className="greenBtn" style={{ width: "90%" }} >Submit</button>
                                    </Link>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeCancelOrderModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** No Of Person Modal ***************/}
                {
                    NoOfPersonModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Change No of Person Status</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeNoOfPersonModal}></i>
                                </div>
                                <hr />
                                <div className="p-1">
                                    <p> Do you really want to Reset No. of Person in Table ?</p>
                                </div>
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={() => personChangeBtn(orderId)} >Submit</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closeNoOfPersonModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Complimentary Modal ***************/}
                {
                    ComplimentaryBonusModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Complimentary Bonus</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeComplimentaryBonusModal}></i>
                                </div>
                                <hr />
                                <div className="p-2">
                                    <label className="ques">Compl. Bonus Amount : 0</label><br />
                                    <label className="ques">Compl. Item Amount : 60</label><br /><br />

                                    <label className="ques">Range 1</label><br />
                                    <label className="ans">Minimum Amt : 100</label><br />
                                    <label className="ans">Maximum Amt : 299</label><br />
                                    <label className="ans">Complimentary Amt : 50</label><br /><br />

                                    <label className="ques">Range 2</label><br />
                                    <label className="ans">Minimum Amt : 300</label><br />
                                    <label className="ans">Maximum Amt : 500</label><br />
                                    <label className="ans">Complimentary Amt : 75</label><br /><br />

                                    <label className="ques">Range 3</label><br />
                                    <label className="ans">Minimum Amt : 500</label><br />
                                    <label className="ans">Maximum Amt : 1000</label><br />
                                    <label className="ans">Complimentary Amt : 100</label>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "65%" }} >Add Compl. Product</button>
                                    <button className="redBtn" style={{ width: "35%" }} onClick={closeComplimentaryBonusModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Payment Modal ***************/}
                {
                    PaymentModal && (
                        <div >
                            {/* <div className="modal-back1"> */}
                            {/* <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Payment</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closePaymentModal}></i>
                                </div>
                                <hr />
                                <div className="p-2">
                                    <p> Do you really want to Pay Online ??</p>
                                </div>
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <Link to="/PaymentGateWay">
                                        <button className="greenBtn" style={{ width: "90%" }} >Submit</button>
                                    </Link>&nbsp;
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closePaymentModal}>Cancel</button>
                                </div>
                            </div> */}
                            {/* </div> */}
                        </div>
                    )
                }
                {/************************** Set Payment Status ***************/}
                {
                    PaymentStatusModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Set Payment Status</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closePaymentStatusModal}></i>
                                </div>
                                <hr />
                                <div className="">
                                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "90%" }}>
                                        <input type="text" className="inputEffect Min_height_mob" onChange={handleAmountChange} value={payingAmount} />
                                        <label id="input1" className="labelEffect " style={{}}>Amount</label>
                                    </div>
                                    {/* <label>{tableOrder.paymentStatus}</label> */}
                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" value={tableOrder.paymentMode || paymentStatusValue} onChange={handlePaymentStatus}>
                                            <option value="0"> Select Payment Mode </option>
                                            {(() => {
                                                if (paymentModeDetails && Array.isArray(paymentModeDetails)) {
                                                    const filteredDetails = paymentModeDetails//.filter(item => item.display == true)
                                                    // .filter(data =>
                                                    //     data.accountTitle && typeof data.accountTitle === 'string' &&
                                                    //     !data.accountTitle.toLowerCase().includes("online" || "cdc")
                                                    // );
                                                    return filteredDetails.map(data => (
                                                        <option key={data.accountId} disabled={data.display == true ? false : true} value={data.accountId}>
                                                            {data.accountTitle}
                                                        </option>
                                                    ));
                                                }
                                                return null;
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Payment Status</label>
                                    </div>

                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" id="orderStatus" value={orderStatus} onChange={orderStatusChange}>
                                            <option value="Pending">Pending</option>
                                            <option valaue="Done">Done</option>
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Order Status</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={paymentStatusSaveBtn} >Submit</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closePaymentStatusModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Search Modal ***************/}
                {
                    openSearchModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Search</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={CloseSearchModal}></i>
                                </div>
                                <hr />
                                <div className="">
                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" value={searchData.tableGroupId} onChange={handleTableGroupChange}>
                                            <option value="0">Select</option>
                                            {(() => {
                                                if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                    return tableGroup.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Select Floor</label>
                                    </div>

                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" value={searchData.tableId} onChange={handleTableChange}>
                                            <option value="0">Select</option>
                                            {(() => {
                                                if (table !== null && Array.isArray(table)) {
                                                    return table.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.tableName}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Select Table</label>
                                    </div>

                                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "90%" }}>
                                        <input type="text" className="inputEffect Min_height_mob" value={searchData.orderNo} onChange={handleOrderChange} />
                                        <label id="input1" className="labelEffect " style={{}}>Order Number</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={handleReset} >Reset</button>
                                    <button className="greenBtn" style={{ width: "25%" }} onClick={handleSearch} >Search</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={CloseSearchModal}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Pine Lab Modal ***************/}
                {
                    PineLabModal && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal">
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Pay Pine Lab</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closePineLab}></i>
                                </div>
                                <hr />
                                <div className="">
                                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "90%" }}>
                                        <input type="text" className="inputEffect Min_height_mob" value={orderNo} />
                                        <label id="input1" className="labelEffect " style={{}}>Order No</label>
                                    </div>
                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <input type="text" className="inputEffect Min_height_mob" onChange={handlePineAmountChange} value={balanceAmount} />
                                        <label id="input1" className="labelEffect " style={{}}>Amount</label>
                                    </div>
                                    <div className="input-containerEffect mt-3" style={{ margin: "3%", width: "90%" }}>
                                        <select className="inputEffect Min_height_mob" >
                                            <option value="0">Select Method</option>
                                            <option value="1001"> Do Transaction</option>
                                            <option value="1002"> Print Data</option>
                                            <option value="1003"> Settlement</option>
                                            <option value="1004"> Get Terminal Info</option>
                                            <option value="1005"> Connect Bluetooth</option>
                                            <option value="1006"> Disconnect Bluetooth</option>
                                            <option value="1007"> Scan Code</option>
                                            <option value="1008"> Upload Invoice</option>
                                            <option value="1009"> Single Scan QR/Barcode from Camera</option>
                                            <option value="1010"> Multi-Scan QR/Barcode from Camera</option>
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Method Id's</label>
                                    </div>
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    <button className="greenBtn" style={{ width: "25%" }}>Pay</button>
                                    <button className="redBtn" style={{ width: "25%" }} onClick={closePineLab}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/************************** Payment Detail Modal ***************/}
                {
                    paymentDetailModalPopup && (
                        <div className="">
                            <PaymentModeModel handlePayment={handlePayment}
                                PaymentModalData={PaymentModalData}
                                closePaymentModal={closePaymentDetailsModal} />
                        </div>
                    )
                }
                {/************************** Bell Icon Modal ***************/}
                {
                    openBellPopup && (
                        <div className="modal-back1">
                            <div className="kotStatus-modal" style={{ height: "auto", maxHeight: "95%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                    <label className="fw-600" style={{ fontSize: "18px" }}>Transfer Item</label>
                                    <i className="fa-solid fa-xmark xmark" onClick={closeBellPopup}></i>
                                </div>
                                <hr />
                                <div className='scrollable_div'>
                                    {Object.keys(groupedData).map((tableId) => (
                                        <div key={tableId} style={{ border: "1px solid lightgrey", borderRadius: "5px", padding: "5px", marginTop: "5px", backgroundColor: "white" }}>
                                            <div onClick={() => handleTableClick(tableId)} style={{ cursor: "pointer" }}>
                                                <div className="space-between">
                                                    <div>
                                                        <div className="flex-start" style={{ alignItems: "center" }}>
                                                            <i className="fa-solid fa-utensils" style={{ fontSize: "14px" }}></i>
                                                            <label className="ques"> &nbsp; {groupedData[tableId][0]?.tableName || 'No Table Name'}</label><br />
                                                        </div>
                                                    </div>
                                                    <div className="flex-center" style={{ padding: "5px" }}>
                                                        <i className="fa-solid fa-square-plus" style={{ color: "darkgreen" }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedTableId === tableId && (
                                                <div style={{ borderTop: "1px solid lightgrey" }}>
                                                    {groupedData[tableId].map((data, index) => (
                                                        <div key={index} style={{ backgroundColor: "white", padding: "0px" }}>
                                                            <div className='space-between'>
                                                                <div className="flex-start p-1">
                                                                    <label className="ques">Order No : &nbsp;</label>
                                                                    <label className="ans"> {data.orderNo}</label>
                                                                </div>
                                                                <div className="flex-start p-1">
                                                                    <label className="ques">Preference No : &nbsp;</label>
                                                                    <label className="ans"> {data.preferenceNo}</label>
                                                                </div>
                                                            </div>
                                                            <div className='space-between'>
                                                                <div className="flex-start p-1">
                                                                    <label className="ques">Item Name : &nbsp;</label>
                                                                    <label className="ans"> {(data.itemName || 'No Item Name').toUpperCase()}</label>
                                                                </div>
                                                            </div>
                                                            <div className='space-between'>
                                                                <div className="flex-start p-1">
                                                                    <label className="ques">Kot Status : &nbsp;</label>
                                                                    <label className="ans"> {data.o2KotStatus || 'No Status'}</label>
                                                                </div>
                                                                <div className="flex-start p-1">
                                                                    <label className="ques">Qty : &nbsp;</label>
                                                                    <label className="ans"> {data.qty || 0} * ₹{data.rate || 0} = ₹{data.totalAmt || 0}</label>
                                                                </div>
                                                            </div>
                                                            <div className="flex-start p-1">
                                                                <label className="ques">Item Department : &nbsp;</label>
                                                                <label className="ans"> {data.itemDepartmentName || 'No Department'}</label>
                                                            </div>
                                                            <div className='space-between'>
                                                                <div></div>
                                                                <button className="greenBtn" style={{ width: "auto", padding: "5px", maxWidth: "50%" }} onClick={() => transferToKitchen(data.ord1Id, data.ord2Id, data.o2KotStatus)}>Transfer To Kitchen</button>
                                                            </div>
                                                            <hr style={{ padding: "0px" }} />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <hr />
                                <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                    {/* <button className="greenBtn" style={{ width: "30%" }} onClick={UpdateGstBtn}>Update</button> */}
                                    <button className="redBtn" style={{ width: "30%" }} onClick={closeBellPopup}>Close</button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {/* ****************************** BILL PRINT *************************** */}
                {showBillPrint && (
                    <div style={{ display: "none" }}> {/**/}
                        <div className="modal-back" onClick={closeBillPrint}>
                            <div className="billPrintModal" style={{}}>
                                <div style={{ padding: "5px", backgroundColor: "white" }}>
                                    <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} setLoading={setLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isShowAlertBox && (
                    <CommonAlertBox
                        isSingleButton={isSingleButton}
                        headingTop={headingTop}
                        message={message}
                        closeBtn={closeBtn}
                        closeBtnName={closeBtnName}
                        okayBtn=''
                        okayBtnName="Yes" />
                )}
                {isShowAlertBoxForPerson && (
                    <CommonAlertBox
                        isSingleButton={isSingleButton}
                        headingTop={headingTop}
                        message={message}
                        closeBtn={closeBtnForNoOfPer}
                        closeBtnName={closeBtnName}
                        okayBtn=''
                        okayBtnName="Yes" />
                )}
                {isShowAlertBoxForDelPref && (
                    <CommonAlertBox
                        isSingleButton={isSingleButton}
                        headingTop={headingTop}
                        message={message}
                        closeBtn={closeBtnForDelPref}
                        closeBtnName={closeBtnName}
                        okayBtn=''
                        okayBtnName="Yes" />
                )}
                {isShowAlertBoxForCancel && (
                    <CommonAlertBox
                        isSingleButton={isSingleButton}
                        headingTop={headingTop}
                        message={message}
                        closeBtn={closeBtnForCancel}
                        closeBtnName={closeBtnName}
                        okayBtn=''
                        okayBtnName="Yes" />
                )}
                {loading && <Loader />}
            </div>
        </div >
    );
};

export default OrderTakerView;
