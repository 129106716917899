//import { useState } from "react";
import { Link } from "react-router-dom";
import './OrderTakerStylesheet.css';
import { useState, useEffect } from "react";
import AccountApi from "../../API/APIServices/Account";
import DepartmentApi from "../../API/APIServices/Department";
//import CountryApi from "../../API/APIServices/Country";
import StateInfoApi from "../../API/APIServices/StateInfo";
import { useLocation, useNavigate } from 'react-router-dom';
//import { navigate } from '@reach/router';
import AppHeaderDetail from "../AppHeaderDetail";
import Loader from "../../Inventory/Props/Loader";
import CommonAlertBox from "../../Inventory/Props/CommonAlertBox";

const CustomerInfo = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const accId = searchParams.get('accId');
    const orderNo = searchParams.get('orderNo');

    const [loading, setLoading] = useState()

    const [department, setDepartment] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await DepartmentApi.getDepartment();
                console.log(data);
                setDepartment(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])

    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");

    const closeBtn = () => {
        setIsShowAlertBox(false)
    }

    //const [country, setCountry] = useState([]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await CountryApi.GetAllCountry();
    //             console.log(data);
    //             setCountry(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, [])

    const [stateInfo, setStateInfo] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await StateInfoApi.GetAllState();
                setStateInfo(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [])
    // const handleCountryChange = (event) => {
    //     const value = event.target.value;
    //     console.log(value);
    //     const filteredData = [{
    //         fieldName: "countryId",
    //         operatorName: "Equal",
    //         compareValue: Number(value),
    //     }]
    // }

    const [account, setAccount] = useState([]);

    const fetchData = async () => {
        setLoading(true)
        try {
            const data = await AccountApi.getAccountById(accId);
            const accountData = data.item1[0];
            setAccount(accountData);
            console.log(accountData);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setAccount((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const AccountUpdate = async () => {
        console.log(account);
        const updatedData = [
            {
                operationType: 0,
                path: "Name",
                op: "Add",
                from: "string",
                value: account.name
            },
            {
                operationType: 0,
                path: "AMobileNo",
                op: "Add",
                from: "string",
                value: account.mobileNo
            },
            {
                operationType: 0,
                path: "AGender",
                op: "Add",
                from: "string",
                value: account.gender
            },
            {
                operationType: 0,
                path: "ADepartment",
                op: "Add",
                from: "string",
                value: account.departmentId
            },
            {
                operationType: 0,
                path: "AState",
                op: "Add",
                from: "string",
                value: account.stateId
            },
            {
                operationType: 0,
                path: "APincode",
                op: "Add",
                from: "string",
                value: account.pincode
            },
            {
                operationType: 0,
                path: "ADob",
                op: "Add",
                from: "string",
                value: account.dob
            },
            {
                operationType: 0,
                path: "ADom",
                op: "Add",
                from: "string",
                value: account.dom
            }
        ]
        await AccountApi.updateAccountPartially(updatedData, account.d1Id, account.d2Id);
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop("");
        setMessage('Successfully Updated');
    }

    const handleNavigate = () => {
        navigate('/OrderTakerView');
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <AppHeaderDetail title={`Customer Info : ${orderNo}`} setLoading={setLoading} fetchData={fetchData} />
            {/* {(() => {
                if (account !== null && account.length > 0) {
                    return ( */}
            {/* //account.map((data, index) => ( */}
            <div className="" style={{ padding: "5px", backgroundColor: "white", marginTop: "60px", paddingBottom: "20px", border: "3px solid white", marginLeft: "2px", marginRight: "2px", borderRadius: "2px", height: "100%" }}>
                <div className="input-containerEffect mt-1" style={{ margin: "3%" }}>
                    <input className="form-control inputEffect Min_height_mob" type="text" name="name" value={account.name} onChange={handleInputChange} required />
                    <label id="input1" className="labelEffect " style={{}}>Customer Name</label>
                </div>
                <div style={{ marginTop: "12px" }}>
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", }}>
                        <input className="form-control inputEffect Min_height_mob" type="text" name="mobileNo" value={account.mobileNo} onChange={handleInputChange} required />
                        <label id="input1" className="labelEffect " style={{}}>Mobile No</label>
                    </div>
                    {/* <div className="input-containerEffect mt-1" style={{ margin: "3%", }}>
                                        <input className="form-control inputEffect Min_height_mob" type="text" name="contactpersonMbl" value={account.contactpersonMbl} required />
                                        <label id="input1" className="labelEffect " style={{}}>Mobile No -2</label>
                                    </div> */}
                </div>

                <div className="space-between">
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                        <select className="form-control inputEffect Min_height_mob" name="gender" value={account.gender} onChange={handleInputChange} required>
                            <option>Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        <label id="input1" className="labelEffect " style={{}}>Gender</label>
                    </div>
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                        <select className="form-control inputEffect Min_height_mob" name="departmentId" value={account.departmentId} onChange={handleInputChange} required>
                            <option>Select</option>
                            {(() => {
                                if (department !== null && Array.isArray(department)) {
                                    return department.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.departmentName}
                                        </option>
                                    ))
                                }
                            })()}
                        </select>
                        <label id="input1" className="labelEffect " style={{}}>Profession</label>
                    </div>
                </div>

                <div className="space-between">
                    {/* <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                                        <select className="form-control inputEffect Min_height_mob" name="" value={account.} onChange={handleCountryChange} required>
                                            <option selected disabled>Select Country</option>
                                            {(() => {
                                                if (country !== null && Array.isArray(country)) {
                                                    return country.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.name}
                                                        </option>
                                                    ))
                                                }
                                            })()}
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>Country</label>
                                    </div> */}
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                        <select className="form-control inputEffect Min_height_mob" name="stateId" value={account.stateId} onChange={handleInputChange} required>
                            <option selected disabled>Select State</option>
                            {(() => {
                                if (stateInfo !== null && Array.isArray(stateInfo)) {
                                    return stateInfo.map((data) => (
                                        <option key={data.id} value={data.id}>
                                            {data.stateName}
                                        </option>
                                    ))
                                }
                            })()}
                        </select>
                        <label id="input1" className="labelEffect " style={{}}>State</label>
                    </div>
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", }}>
                        <input className="form-control inputEffect Min_height_mob" type="number" name="pincode" value={account.pincode} onChange={handleInputChange} required />
                        <label id="input1" className="labelEffect " style={{}}>Pincode</label>
                    </div>
                    {/* <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                                        <select className="form-control inputEffect Min_height_mob" name="districtId" value={account.districtId} required>
                                            <option selected disabled>Select District</option>
                                            <option>Raipur</option>
                                            <option>Durg</option>
                                        </select>
                                        <label id="input1" className="labelEffect " style={{}}>District</label>
                                    </div> */}
                </div>

                <div className="space-between">
                    {/* <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                                        <input className="form-control inputEffect Min_height_mob" type="text" name="city" value={account.city} id="" required />
                                        <label id="input1" className="labelEffect " style={{}}>City/Village</label>
                                    </div> */}

                </div>

                <label style={{ margin: "3%", fontWeight: "400", color: "#4f5459" }}><u>Please fill up to get very special offer :-</u></label>
                <div className="space-between mt-2">
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                        <input className="form-control inputEffect Min_height_mob" type="date" name="dob" value={account.dob} onChange={handleInputChange} required />
                        <label id="input1" className="labelEffect " style={{}}>Date of Birth &nbsp; &nbsp;</label>
                    </div>
                    <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "50%" }}>
                        <input className="form-control inputEffect Min_height_mob" type="date" name="dom" value={account.dom} onChange={handleInputChange} required />
                        <label id="input1" className="labelEffect " style={{}}>Date of Marriage &nbsp;  &nbsp;</label>
                    </div>
                </div>

                <div className="relativeDetailsDiv" style={{ display: "none" }}>
                    <div className="space-between">
                        <div className="input-containerEffect mt-1" style={{ margin: "3%" }}>
                            <input className="form-control inputEffect Min_height_mob" type="text" id="" required />
                            <label id="input1" className="labelEffect " style={{}}>Name</label>
                        </div>
                        <div className="input-containerEffect mt-1" style={{ margin: "3%" }}>
                            <input className="form-control inputEffect Min_height_mob" type="text" id="" required />
                            <label id="input1" className="labelEffect " style={{}}>Relation with</label>
                        </div>
                    </div>
                    <div className="space-between">
                        <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "45%" }}>
                            <input className="form-control inputEffect Min_height_mob" type="date" id="" required />
                            <label id="input1" className="labelEffect " style={{}}>Date of Birth &nbsp; &nbsp;</label>
                        </div>
                        <div className="input-containerEffect mt-1" style={{ margin: "3%", width: "45%" }}>
                            <input className="form-control inputEffect Min_height_mob" type="date" id="" required />
                            <label id="input1" className="labelEffect " style={{}}>Date of Marriage &nbsp;  &nbsp;</label>
                        </div>
                    </div>
                </div>

                <div className="flex-center" style={{}}>
                    <button className="redBtn" style={{}} onClick={handleNavigate}>Previous</button>
                    <button className="greenBtn" onClick={AccountUpdate} style={{}}>Submit</button>
                </div>
            </div>
            {isShowAlertBox && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn=''
                    okayBtnName="Yes" />
            )}
            {loading && (<Loader />)}
        </div>
    );
};

export default CustomerInfo;
