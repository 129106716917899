import { Router } from "react-router-dom";
import axios from "../axiosConfig";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ThemeMaster = {
  CreateThemeMaster: async (body) => {
    try {
      debugger
      const apiUrl = `${WebApiUrl}/api/ThemeMater/create`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, body, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetThemeMaster: async () => {
    try {
        debugger
      const apiUrl = `${WebApiUrl}/api/ThemeMater/gettheme`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  DeleteThemeMaster: async (mastertype) => {
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/ThemeMater/deletetheme/${mastertype}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
        credentials: "include",
      });
      console.log("Delete Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
      return error
    }
  },

  UpdateThemeMaster: async (body) => {
    try {
      debugger
      const apiUrl = `${WebApiUrl}/api/ThemeMater/updatetheme`;
      console.log(apiUrl);

      const response = await axios.put(apiUrl, body, {
        withCredentials: true,
        credentials: "include",
      });

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
};

export default ThemeMaster;
