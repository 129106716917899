import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
//import img1 from "../Assets/img/img1.jpg";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import AlertBox from "../../Props/AlertBox";
import DataTable from "../TableMaster/DataTable";
import TransactionTypeSetting from "../../../API/APIServices/TransactionTypeSetting";
import MainButton from "../../Props/MainButton";
import PageTabs from "../../Components/PageTabs";

function TransactionTypeList() {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
  } = useContext(MyContext);

  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } =
    useContext(MyContext);

  const [loading, setLoading] = useState(true);

  useState(() => {
    document.title = "Transaction type Master";
  }, []);
  //******************************************************//
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    priority: "",
  });
  const [inputValues, setInputValues] = useState({
    name: "",
    discription: "",
    isActive: true,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }

    return true;
  };
  const fieldNames = ["name"];

  const SaveBtn = async () => {
    let allValid = true;
    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);
      if (!isValid) {
        allValid = false;
      }
    });
    if (allValid) {
      setLoading(true);
      if (Boolean(edit) === true) {
        const isSuccess =
          await TransactionTypeSetting.UpdateTransactionTypeMaster(inputValues);
        fetchData();
        if (isSuccess) {
          fetchData();
          setEdit(false);
          OpenAlertBoxEdit();
          setLoading(false);
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess =
          await TransactionTypeSetting.CreateTransactionTypeMaster(inputValues);
        fetchData();
        if (isSuccess) {
          fetchData();
          setLoading(false);
          OpenAlertBox();
        } else {
          alert("Error inserting data");
        }
      }
      fetchData();
      setInputValues({ name: "", discription: "" });
    }
  };

  // ********************************************List Part ******************************

  const [data1, setData1] = useState([]);

  const columns = [
    { Header: <input type="checkbox" />, accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "TYPE ID", accessor: "typeId" },
    { Header: "NAME", accessor: "name" },
    { Header: "is Active", accessor: "isActive" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    {
      Header: <input type="text" placeholder="Type Id" ref={input1} />,
      accessor: "typeId",
    },
    {
      Header: <input type="text" placeholder="Name" ref={input2} />,
      accessor: "name",
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      debugger;
      const val = await TransactionTypeSetting.GetAllTransactionTypeMaster();
      console.log(val.length);

      const newData = val.map((item, i) => {
        return {
          input: <input type="checkbox" />,
          sno: i + 1,
          typeId: String(item.typeId),
          name: item.masterName,
          isActive: item.isActive ? "true" : "false",
          action: (
            <div className="tablelist-action-box">
              <i
                className="fa-regular fa-pen-to-square TableEditIcon"
                onClick={() => editTransactionTypeSetting(item.id)}
              ></i>
              <i
                className="fa-regular fa-trash-can TableDelIcon"
                onClick={() => DeleteTransactionTypeSetting(item.id)}
              ></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setData1(newData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [edit, setEdit] = useState(false);

  const editTransactionTypeSetting = async (id) => {
    setEdit(true);
    const dataById = await TransactionTypeSetting.GetTransactionTypeMasterByID(
      id
    );
    debugger;
    if (dataById) {
      try {
        setInputValues((prevValues) => ({
          ...prevValues,
          id: Number(dataById.id),
          name: dataById.masterName,
          discription: dataById.discription,
          isActive: dataById.isActive,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const [getdeleteId, setDeleteId] = useState(null);

  const DeleteTransactionTypeSetting = (id) => {
    setAlertBoxDelete(true);
    setDeleteId(id);
    debugger
  };

  const DeleteTransactionTypeSettingConfirm = async () => {
    setLoading(true);
    debugger
    try {
      const val = await TransactionTypeSetting.DeleteTransactionTypeMaster(
        getdeleteId
      );
      if (Boolean(val) === true) {
        fetchData();
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    console.log(inputValues);
  };

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };
  useEffect(() => {
    input1Ref.current.focus();
  }, []);

  return (
    <div>
      <PageTabs />

      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Transaction Type master </h2>
        </div>
        <MainButton SaveButton={SaveBtn} />
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div
                    className="company-info-top-right control_flex_m1"
                    style={{ overflowY: "scroll", height: "70vh" }}
                  >
                    <div className="control_radius">
                      <div className="company_info-text-im td-1">
                        Transaction Type master
                        <div className="input-box-checkbox1 ">
                          <div className=" td-2">
                            <div className="cl-toggle-switch td-3">
                              <label className="cl-switch">
                                <input
                                  type="checkbox"
                                  onChange={handleInputChangeActive}
                                  name="isActive"
                                  checked={inputValues.isActive}
                                  value={inputValues.isActive}
                                />
                                <span></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div
                          className="stock-im-dep1"
                          style={{ width: "100%", display: "flex" }}
                        >
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                type="text"
                                value={inputValues.name}
                                name="name"
                                onKeyPress={(e) => handleKeyPress(e, input2Ref)}
                                ref={input1Ref}
                                onChange={handleInputChange}
                                required
                              />
                              <label className="modify_p_text2">
                                Name
                                <span className="StarClr">
                                  *
                                  {errorMessages.name && (
                                    <label className="errorMessage">
                                      {errorMessages.name}
                                    </label>
                                  )}
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                type="text"
                                value={inputValues.discription}
                                name="discription"
                                onKeyPress={(e) => handleKeyPress(e, input3Ref)}
                                ref={input2Ref}
                                onChange={handleInputChange}
                                required
                              />
                              <label className="modify_p_text2">
                                Discription
                                <span className="StarClr">
                                  *
                                  {errorMessages.priority && (
                                    <label className="errorMessage">
                                      {errorMessages.priority}
                                    </label>
                                  )}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="control_radius">
                      {/* <div className="company_info-text-im depart_top">Department Category Details</div> */}
                      <div className="col-sm-12 depart_top">
                        <div className="table-resposive">
                          <DataTable
                            filterData={filterData}
                            columns={columns}
                            data={data1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3">
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteTransactionTypeSettingConfirm}
        />
      )}
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Transaction Type master  is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Transaction Type master  is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default TransactionTypeList;
