import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import PaymentModeAPI from "../../API/APIServices/BillPaymentMode";
import MyContext from "../MyContext";
import BillInvoiceModal from "./BillInvoiceModal";
import Transaction from "../../API/APIServices/Transaction";

// import { useEffect } from "react";

function BillPaymentInvoice(props) {
  const {
    childRef,
    inputRefs,
    modalOpen,
    inputValues,
    setModalOpen,
    setInputValues,
    nextInput,
    ReferenceNumber,
    currencyDecimalPlaces,
    BillSerisMasterData,
    setBillSerisMasterData,
    GetAccountDataFunction,
    TransactionLListForPayment,
    setTransactionLListForPayment,
    GetTransactionSummaryBySrsId,
    inputValuesPaidAmount,
    setInputValuesPaidAmount,
    adjamountToSend,
    setAdjamountToSend,
    adjamountRemark,
    setAdjamountRemark,
    count,
    setcount,
  } = props;
  let billSeriesId = inputValues.billSeriesId;
  let TransName = inputValues.TransName;

  useImperativeHandle(childRef, () => ({
    onChangeSavePayment,
    CloseSettingModal,
  }));

  const [CashTenderReturn, setCashTenderReturn] = useState({
    cashTender: "",
    cashReturn: "",
  });

  const calculateReturnAmount = (e) => {
    // debugger;
    let totalAmount = parseFloat(inputValues.GrossAmt);
    let cashTender = parseFloat(e.target.value);
    let returnAmount = cashTender - totalAmount;
    setCashTenderReturn({ cashTender, cashReturn: returnAmount });
  };

  const [TotalAdjAmount, setTotalAdjAmount] = useState(0);

  const ShowadjamountFunction = () => {
    setShowadjamount(!Showadjamount);
    // setcount(!count);
    getPosition();
    GetTransactionSummaryBySrsId();
  };
  const ShowadjamountFunction1 = () => {
    setShowadjamount1(!Showadjamount1);
    getPosition1();
    // setcount(!count);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowadjamount(false);
        setShowadjamount1(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [Showadjamount, setShowadjamount] = useState(false);
  const [Showadjamount1, setShowadjamount1] = useState(false);

  const adjamountToSendFnc = (e) => {
    setAdjamountToSend(e.target.value);
  };

  const adjamountRemarkFnc = (e) => {
    setAdjamountRemark(e.target.value);
  };

  console.log(TransactionLListForPayment);

  const [divPosition, setDivPosition] = useState(null);
  const [divPosition1, setDivPosition1] = useState(null);

  const divRef = useRef(null); // Create a reference for the div
  const divRef1 = useRef(null); // Create a reference for the div

  const getPosition = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect(); // Get position and size of the div
      setDivPosition({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      });
    }
  };

  const getPosition1 = () => {
    if (divRef1.current) {
      const rect = divRef1.current.getBoundingClientRect(); // Get position and size of the div
      setDivPosition1({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      });
    }
  };

  const FetchTransactions = (e) => {
    debugger;

    var VchNo = e.target.value;
    var checkedbox = e.target.checked;

    setBillSerisMasterData((prevList) => {
      // Update the state
      const updatedList = prevList.map((item) =>
        item.t4 === VchNo ? { ...item, checked: checkedbox } : item
      );

      // Now use the updated state immediately
      const t4Values = updatedList
        .filter((row) => row.checked) // Filter checked items
        .map((row) => parseInt(row.t4)); // Extract t4 values

      console.log(t4Values); // Logs updated values correctly
      if (checkedbox == true) {
        GetTransactionSummaryBySrsId(updatedList);
      }
      return updatedList; // Return the new state
    });
  };

  const calculateTotalAmountInvoice = (e) => {
    // debugger;
    var BillNo = e.target.value;
    var checkedbox = e.target.checked;

    // Find the matching VchNo in TransactionLListForPayment
    setTransactionLListForPayment((prevList) => {
      return prevList.map((item) => {
        // If the VchNo matches BillNo, update the 'checked' property
        if (item.VchNo === BillNo) {
          return { ...item, checked: checkedbox };
        }
        return item;
      });
    });

    // Calculate the total amount for 'checked' items
    // Optionally, log the updated list to verify the changes
    console.log(TransactionLListForPayment);
  };

  useEffect(() => {
    let TotalAdjustAmount = 0;

    // Loop through the array
    for (let i = 0; i < TransactionLListForPayment.length; i++) {
      let item = TransactionLListForPayment[i];

      // Only sum the BillAmt if 'checked' exists and is true
      if (item.checked !== undefined && item.checked === true) {
        TotalAdjustAmount += parseFloat(item.BillAmt); // Add the BillAmt value
      }
    }

    console.log(TotalAdjustAmount);
    setTotalAdjAmount(TotalAdjustAmount);
  }, [TransactionLListForPayment]);

  console.log(BillSerisMasterData);

  const [SettingModal, setSettingModal] = useState(false);

  const OpenSettingModal = () => {
    setSettingModal(!SettingModal);
    setModalOpen(!modalOpen);
  };

  const CloseSettingModal = () => {
    setSettingModal(false);
    setModalOpen(false);
  };

  const SearchOldVchNo = (e) => {
    alert(e.target.value);
  };

  /**********************Start From(Shivendra 21062024)**********************/

  //------------------Payment Mode Setting From Invoice Page-------------------

  const { OpenAlertBoxDelete, alertBoxDelete, setAlertBoxDelete } =
    useContext(MyContext);

  const [loading, setLoading] = useState(false);
  const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } =
    useContext(MyContext);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const [isEditPaymentMode, setEditPaymentMode] = useState(false);

  const [inputValuesPaymentStatic, setInputValuesPaymentStatic] = useState([
    {
      id: "1",
      PaymentMode: "Mode 1",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "2",
      PaymentMode: "Mode 2",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "3",
      PaymentMode: "Mode 3",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
    {
      id: "4",
      PaymentMode: "Mode 4",
      disabled: "disabled",
      billSeries: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
    },
  ]);

  //---------------Fill Payment Modes (Load Event)-----------------------

  const fetchPaymentModeList = async (billSeriesId) => {
    // // debugger;
    if (billSeriesId != 0) {
      try {
        const data1 = await PaymentModeAPI.getvoucherPaymentModeId(
          billSeriesId
        );
        if (data1 && data1.length > 0) {
          setEditPaymentMode(true);
          console.log(data1);
          setInputValuesPaidAmount(data1);
        }
        // /      // debugger;

        setInputValuesPaymentStatic((prevValues) =>
          Array.from({ length: 4 }, (_, index) => {
            if (index < data1.length && data1.length) {
              const item = data1[index];
              return {
                ...prevValues[index],
                id: index + 1,
                PaymentMode: "Mode " + (index + 1),
                disabled: "disabled",
                billSeries: item.billSeries,
                assignToShow: item.assignToShow,
                accountId: item.accountId,
                accountTitle: item.accountTitle,
              };
            } else {
              return {
                id: index + 1,
                PaymentMode: "Mode " + (index + 1),
                disabled: "disabled",
                billSeries: "",
                assignToShow: "",
                accountId: "",
                accountTitle: "",
              };
            }
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    fetchPaymentModeList(billSeriesId);
  }, [billSeriesId]);

  //------------------Change Payment Mode-------------------

  const handleInputChangeActivePaymentMode = (index, e) => {
    const { name, checked } = e.target;

    setInputValuesPaymentStatic((prevValues) => {
      const updatedInputValues = [...prevValues];
      updatedInputValues[index].assignToShow = checked;
      return updatedInputValues;
    });

    handleInputChangePaymentMode(index, e);
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInputChangePaymentMode = (index, e) => {
    const { name, type, value, checked } = e.target; // Destructure name, type, value, and checked from event.target
    // Check the type of input element
    let updatedValue;
    if (type === "checkbox") {
      updatedValue = checked;
    } else {
      updatedValue = value;
    }

    setInputValuesPaymentStatic((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[index]) {
        updatedValues[index][name] = updatedValue;
        updatedValues[index].billSeries = billSeriesId;
      }
      return updatedValues;
    });
    //console.log(inputValuesPaymentMode);
  };

  useEffect(() => {
    console.log(inputValuesPaidAmount);
  }, [inputValuesPaidAmount]);

  const handleChangePayAmt = (index, e) => {
    debugger;
    const { name, value } = e.target; // Extract name and value from the event

    setInputValuesPaidAmount((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[index]) {
        updatedValues[index][name] = value;
        // Update the 'dt' field with the current date and time (ISO format)
        updatedValues[index].dt = new Date().toISOString();
      }
      return updatedValues;
    });

    setTimeout(() => {
      let totalPayAmt = inputValuesPaidAmount.reduce(
        (sum, item) => sum + parseFloat(item.PayAmt || 0),
        0
      );
      let oldAmtInotherInput = inputValuesPaidAmount.reduce(
        (sum, item, index2) =>
          index2 === index ? sum : sum + parseFloat(item.PayAmt || 0),
        0
      );

      let DueAmt =
        inputValues.GrossAmt - inputValues.AdjstAmt - oldAmtInotherInput;

      let TotalDueAmt = inputValues.GrossAmt - inputValues.AdjstAmt;

      let totalIgnoringIndex =
        totalPayAmt - parseFloat(inputValuesPaidAmount[index]?.PayAmt || 0);

      let totalAmountPaying = totalIgnoringIndex + Number(e.target.value);

      if (DueAmt != 0 && totalAmountPaying > TotalDueAmt) {
        // alert("Paid Amount should not be greater than Due Amount");
        debugger;
        setInputValuesPaidAmount((prev) =>
          prev.map((item, index1) =>
            index1 === index ? { ...item, PayAmt: DueAmt } : item
          )
        );
      }
    }, 10);
  };

  useEffect(() => {
    // Calculate the sum of PayAmt
    debugger;
    let totalPayAmt = inputValuesPaidAmount.reduce(
      (sum, item) => sum + parseFloat(item.PayAmt || 0),
      0
    );
    let GrossAmt_ = inputValues.GrossAmt;
    let AdjstAmt = inputValues.AdjstAmt;
    console.log(inputValues.AdjstAmt);
    setInputValues((prev) => ({
      ...prev,
      PaidAmt:
        (Number(totalPayAmt) || 0) +
        (Number(AdjstAmt) || 0) +
        (Number(adjamountToSend) || 0),
      DueAmt:
        (Number(GrossAmt_) || 0) -
        (Number(totalPayAmt) || 0) -
        (Number(AdjstAmt) || 0) -
        (Number(adjamountToSend) || 0),
    }));

    console.log(inputValuesPaidAmount);
  }, [
    inputValuesPaidAmount,
    inputValues.GrossAmt,
    adjamountToSend,
    inputValues.AdjstAmt,
    // inputValues,
  ]);

  // Function to handle Enter key press
  const handleKeyPress = (index, event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      let nextIndex = index + 1;
      // Loop to find the next valid input element
      while (
        nextIndex < inputRefs.current.length &&
        !inputRefs.current[nextIndex]
      ) {
        nextIndex++;
      }
      // If a valid next index is found, focus on it
      if (nextIndex < inputRefs.current.length) {
        inputRefs.current[nextIndex].focus();
      } else if (nextInput.current) {
        nextInput.current[100].focus();
      }
    }
  };

  //------------------Save Payment Mode-------------------

  const SaveBtnPaymentMode = async () => {
    setLoading(true);
    // debugger;
    const shouldStop = inputValuesPaymentStatic.some(
      (item) => item.billSeries === 0
    );
    if (shouldStop) {
      alert("Please select a valid bill series to continue.");
      setLoading(false);
      return;
    }
    if (isEditPaymentMode === true) {
      const isSuccess = await PaymentModeAPI.updateVoucherPaymentMode(
        inputValuesPaymentStatic
      );
      // debugger;
      if (isSuccess) {
        OpenAlertBoxEdit();
        OpenSettingModal();
        fetchPaymentModeList(billSeriesId);
        setLoading(false);
      } else {
        alert("error while updating");
        setLoading(false);
      }
    } else {
      const isSuccess = await PaymentModeAPI.insertVoucherPaymentMode(
        inputValuesPaymentStatic
      );
      if (isSuccess) {
        OpenAlertBox();
        fetchPaymentModeList(billSeriesId);
        setLoading(false);
      } else {
        setLoading(false);
        alert("Error inserting data");
      }
    }
    // setLoading(false);
    // setInputValuesPaymentStatic([
    //   {
    //     billSeries: "",
    //     assignToShow: "",
    //     accountId: "",
    //     accountTitle: "",
    //   },
    // ]);
  };

  const onChangeSavePayment = async () => {
    var AccountTempData = await Transaction.SetAccountTempData(
      inputValuesPaidAmount,
      ReferenceNumber,
      TransactionLListForPayment,
      adjamountToSend,
      adjamountRemark
    );
    if (AccountTempData.isSuccess === true) {
      console.log("Product Temp Data Data saved");
      // debugger;
      var data = await GetAccountDataFunction();
      // debugger;
      if (data) {
        setInputValuesPaidAmount((prevValues) => {
          const updatedValues = [...prevValues]; // Create a copy of prevValues

          data.amt.forEach((item, index) => {
            if (updatedValues[index]) {
              // If the index exists in prevValues, update it
              updatedValues[index] = {
                ...updatedValues[index], // Preserve existing data
                remark: item.remark,
                assignToShow: item.assignToShow,
                accountId: item.accountId_d2,
                accountTitle: item.accountName,
                PayAmt: item.amt || "",
              };
            } else {
              // If the index does not exist, push a new item at the end
              updatedValues.push({
                remark: item.remark,
                assignToShow: item.assignToShow,
                accountId: item.accountId_d2,
                accountTitle: item.accountName,
                PayAmt: item.amt || "",
              });
            }
          });

          return updatedValues; // Return the updated array
        });
        setAdjamountToSend(data.vch.amt);
        setAdjamountRemark(data.vch.remark);
        const vouchSeriesIds = data.vch.inv.map((item) => item.vouchSeriesId);
        setTransactionLListForPayment(vouchSeriesIds);
      }
    } else {
      // debugger;
      // alert(AccountTempData.errorMessages);
      console.log("Error in Product Temp Data");
    }
  };
  return (
    <div>
      <div
        className="tab-btn-box1 tab-b3 bg-color space-between"
        style={{ backgroundColor: "#5e709e", paddingRight: "10px" }}
      >
        <label className="c-w">
          &nbsp;{" "}
          <span style={{ color: "red", textShadow: "0px 0px 3px white" }}>
            B
          </span>
          ill Payment Details
        </label>

        <label className="c-w f-12">&nbsp; Cash Tender</label>
        <input
          onChange={(e) => calculateReturnAmount(e)}
          className="Salebillinput"
          type="number"
          value={CashTenderReturn.cashTender}
        />
        <label className="c-w f-12">&nbsp; Cash Return</label>
        <input
          className="Salebillinput"
          value={CashTenderReturn.cashReturn}
          type="number"
        />

        <i
          className="fa-solid fa-gear settingIcon"
          onClick={OpenSettingModal}
          style={{ color: "white", fontSize: "14px" }}
        ></i>
      </div>
      <div className="">
        <div className="input-box-content-l1 container_over ">
          <div className="" style={{ marginTop: "5px", width: "100%" }}>
            <div className="space-between input-box-bill alignCenter">
              <div className="BillPayAccountDetails-box">
                <div className="BillPayAccountDetails-box-2">
                  {inputValuesPaidAmount?.map((data1, index) => (
                    <React.Fragment key={index}>
                      <div className="BillPayAccountDetails">
                        <div>
                          <div className="BillPayAccountDetails-input">
                            <div
                              className="input-containerEffect width50mobile"
                              id="DivCreditLimit"
                            >
                              <input
                                id={`CreditLimit-${index}`}
                                type="text"
                                className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                                name="PayAmt"
                                autoComplete="off"
                                value={data1.PayAmt === 0 ? "" : data1.PayAmt}
                                onChange={(e) => handleChangePayAmt(index, e)}
                                onKeyDown={
                                  (e) => handleKeyPress(index * 2, e) // Map index to inputRefs
                                }
                                ref={(el) =>
                                  (inputRefs.current[index * 2] = el)
                                }
                                required
                              />
                              <label id="input1" className="labelEffect1">
                                {data1.accountTitle}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="BillPayAccountDetails">
                        <div>
                          <div className="BillPayAccountDetails-input">
                            <div
                              className="input-containerEffect width50mobile"
                              id="DivCreditLimit"
                            >
                              <input
                                id={`Remark-${index}`}
                                type="text"
                                className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                                name="remark"
                                autoComplete="off"
                                value={data1.remark}
                                onChange={(e) => handleChangePayAmt(index, e)}
                                onKeyDown={
                                  (e) => handleKeyPress(index * 2 + 1, e) // Map index to inputRefs
                                }
                                ref={(el) =>
                                  (inputRefs.current[index * 2 + 1] = el)
                                }
                                required
                              />
                              <label id="input1" className="labelEffect1">
                                Remark {index + 1}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                <div className="BillPayAccountDetails-box-2">
                  <div className="BillPayAccountDetails">
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          {/* <select
                            className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                            type="text"
                            style={{
                              padding: "0px 7px",
                            }}
                            onChange={(e) => SearchOldVchNo(e)}
                            required
                          > */}
                          <div
                            className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                            style={{ position: "relative", width: "100%" }}
                            onClick={ShowadjamountFunction}
                            ref={divRef}
                          >
                            {" "}
                          </div>
                          {Showadjamount && (
                            <div
                              ref={modalRef}
                              className="Checkboxandoptioninvoice"
                              style={{
                                top: `${divPosition.top + 25}px`,
                                left: `${divPosition.left}px`,
                                width: `${divPosition.width}px`,
                              }}
                            >
                              {BillSerisMasterData.map((item) => (
                                <div value={item.t4}>
                                  <input
                                    type="checkbox"
                                    name=""
                                    id={`checkbox-${item.t4}`}
                                    checked={item.checked}
                                    value={item.t4}
                                    onChange={(e) => FetchTransactions(e)}
                                  />
                                  <label htmlFor={`checkbox-${item.t4}`}>
                                    {item.t3}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                          {/* </select> */}

                          <label id="input1" className="labelEffect1">
                            Adv Adjst Series
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            autoComplete="off"
                            value={adjamountToSend}
                            onChange={(e) => adjamountToSendFnc(e)}
                            // onBlur={onChangeSavePayment}
                            onKeyDown={
                              (e) => handleKeyPress(6, e) // Map index to inputRefs
                            }
                            ref={(el) => (inputRefs.current[6] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Pay Adjst Amt
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="BillPayAccountDetails">
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <div
                            className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                            style={{ position: "relative", width: "100%" }}
                            onClick={ShowadjamountFunction1}
                            ref={divRef1}
                          >
                            <div className="AdjAmountBox">
                              Total Amt :-{TotalAdjAmount.toFixed(2)} Rs
                            </div>
                          </div>
                          {TransactionLListForPayment.length > 0 && (
                            <>
                              {Showadjamount1 && (
                                <div
                                  ref={modalRef}
                                  className="Checkboxandoptioninvoice"
                                  style={{
                                    top: `${divPosition1.top + 25}px`,
                                    left: `${divPosition1.left}px`,
                                    // width: `${divPosition1.width}px`,
                                  }}
                                >
                                  {TransactionLListForPayment.map((item) => (
                                    <div key={item.t4} value={item.t4}>
                                      <input
                                        type="checkbox"
                                        value={item.VchNo}
                                        checked={item.checked}
                                        name=""
                                        id={`checkbox1-${item.VchNo}`}
                                        onChange={(e) =>
                                          calculateTotalAmountInvoice(e)
                                        }
                                      />
                                      <label
                                        htmlFor={`checkbox1-${item.VchNo}`}
                                      >
                                        {item.VchName} ({item.VchNo}){" "}
                                        {item.VchDate}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </>
                          )}

                          <label id="input1" className="labelEffect1">
                            Adv Adjst Vch No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="BillPayAccountDetails-input">
                        <div
                          className="input-containerEffect width50mobile"
                          id="DivCreditLimit"
                        >
                          <input
                            id="CreditLimit"
                            type="text"
                            className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 BorderBottomsale"
                            name="PayAmt"
                            value={adjamountRemark == 0 ? "" : adjamountRemark}
                            autoComplete="off"
                            onChange={(e) => adjamountRemarkFnc(e)}
                            // value={data1.PayAmt}
                            // onBlur={onChangeSavePayment}
                            // onChange={(e) => handleChangePayAmt(index, e)}
                            onKeyDown={
                              (e) => handleKeyPress(7, e) // Map index to inputRefs
                            }
                            ref={(el) => (inputRefs.current[7] = el)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Remark {inputValuesPaidAmount?.length + 1}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="BillPayAccountDetails-1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30px",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <input
                      type="hidden"
                      name="accountId"
                      value={inputValuesPaidAmount.accountId}
                    />
                    <label>&nbsp;Credit Note Adjustment</label>
                  </div>
                </div> */}

                <div className="BillPayAccountDetails-2">
                  <div
                    style={{ fontSize: "14px" }}
                    onClick={onChangeSavePayment}
                  >
                    Paid Amount =&nbsp;
                    <span className="pay_amt1">
                      {/* {inputValues.PaidAmt}  */}
                      {parseFloat(inputValues.PaidAmt).toFixed(
                        currencyDecimalPlaces
                      )}
                    </span>
                  </div>
                  <div style={{ fontSize: "14px" }}>
                    Due Amt =&nbsp;
                    <span className="pay_amt1">
                      {/* {inputValues.DueAmt} */}
                      {parseFloat(inputValues.DueAmt).toFixed(
                        currencyDecimalPlaces
                      )}
                    </span>
                  </div>

                  <div style={{ fontSize: "14px" }}>
                    Adjst Amt =&nbsp;
                    <span className="pay_amt1">
                      {/* {inputValues.DueAmt} */}
                      {parseFloat(inputValues.AdjstAmt).toFixed(
                        currencyDecimalPlaces
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/************************* Bill Payment Setting Modal *****************************/}
      {SettingModal && (
        <BillInvoiceModal
          hideModal={OpenSettingModal}
          inputValuesPaymentStatic={inputValuesPaymentStatic}
          handleInputChangeActivePaymentMode={
            handleInputChangeActivePaymentMode
          }
          inputValuesPaidAmount={inputValuesPaidAmount}
          billSeriesId={billSeriesId}
          SaveBtnPaymentMode={SaveBtnPaymentMode}
          handleInputChangePaymentMode={handleInputChangePaymentMode}
          loading={loading}
          PageName="saleInvoice"
        />
      )}
    </div>
  );
}

export default BillPaymentInvoice;
