import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import MasterReport from "./ReportTemplateSetting";
import MasterReportDataTable from "../../Master/TableMaster/MasterReportDataTable";
import ReportTableButton from "../../Props/ReportTableButton";
import { Link } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import ItemMaster from "../../Master/InvertoryMaster/ItemMaster";
import AccountMaster1 from "../../Master/AccountMaster/AccountMaster";
import ItemMasterApi from "../../../API/APIServices/ItemMaster";
import AccountMasterApi from "../../../API/APIServices/Account";
import { useNavigate } from "react-router-dom";
import AccountApi from "../../../API/APIServices/Account";
import { use } from "i18next";
import AccountGroupMaster from "../../Master/AccountMaster/AccountGroupMaster";
import ItemGroupMaster from "../../Master/InvertoryMaster/ItemGroupMaster";
import UnitMaster from "../../Master/InvertoryMaster/UnitMaster";
import ConvertInvoiceAndSynchDataController from "../../../API/APIServices/ConvertInvoiceAndSynchDataController";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import AccountGroup from "../../../API/APIServices/AccountGroup";
import UnitMasterApi from "../../../API/APIServices/UnitMaster";

function MasterListReport(props) {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    input8,
  } = useContext(MyContext);

  // const {
  //     inputValues,
  //   } = props;

  // const { selectedDateFilter } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [columns, setColumns] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [totalValue, setTotalValue] = useState([]);
  const [finalresult, setfinalresult] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [calculatedTotals, setCalculatedTotals] = useState({});
  const [reportAttachTo, setReportAttachTo] = useState("");

  const [searchAnyText, setSearchAnyText] = useState(false);
  const [searchByDate, setSearchByDate] = useState(false);
  const [printButton, setPrintButton] = useState(false);
  const [excelButton, setExcelButton] = useState(false);
  const [searchByColumn, setSearchByColumn] = useState(false);
  const [rowWiseEdit, setRowWiseEdit] = useState(false);
  const [rowWisePrint, setRowWisePrint] = useState(false);
  const [rowWiseWhatsapp, setRowWiseWhatsapp] = useState(false);
  const [rowWiseDelete, setRowWiseDelete] = useState(false);
  const [shareButton, setShareButton] = useState(false);
  const [columnShowTotalValue, setColumnShowTotalValue] = useState(false);
  const [firmNameSelect, setFirmNameSelect] = useState(false);
  const [transactionType, setTransactionType] = useState(false);
  const [billSeries, setBillSeries] = useState(false);
  const [reportConfig, setReportConfig] = useState([]);

  const [masterId, setMasterId] = useState(0);
  const [universalSearch, setUniversalSearch] = useState("");
  const url = window.location.href;
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [heading, setHeading] = useState("");
  const [queryData, setQueryData] = useState("");

  // Filter data based on search queries
  useEffect(() => {
    console.log(templateData);
  }, [templateData]);

  const [filtercondition, setfiltercondition] = useState([]);
  const GetcolName = (name) => {
    var slicedQuery = queryData.slice(
      queryData.toLocaleLowerCase().indexOf("select") + "select".length,
      queryData.toLocaleLowerCase().indexOf("from")
    );
    let arr = slicedQuery.split(",").map((item) => item.trim());
    var searchval =
      arr.find((item) => {
        if (name.toLowerCase() === "id") {
          let parts = item.split(".");
          let columnName = parts.length > 1 ? parts[1].trim() : parts[0].trim();
          return columnName.toLowerCase() === "id";
        } else {
          return item.toLowerCase().includes(name.toLowerCase());
        }
      }) || null;
    if (searchval) {
      searchval = searchval.replace(/\s+AS\s+.*/i, "").trim();
    }
    return searchval;
  };
  const handleKeyDown = async (e, filt) => {
    console.log(filt);
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;

      if (!value) {
        fetchDataTemp();
        setfiltercondition([
          {
            fieldName: templateData.templateSettingDTO.eF1,
            operatorName: "equal",
            compareValue: reportAttachTo,
          },
          {
            fieldName: updateFieldName(
              templateData.templateSettingDTO.eF1,
              "isDeleted"
            ),
            operatorName: "equal",
            compareValue: 0,
          },
        ]);
        return;
      }
      let searchval;
      if (queryData) {
        searchval = GetcolName(name);
      }

      setfiltercondition((prev) => [
        // ...prev,

        {
          fieldName: searchval,
          operatorName: "like",
          compareValue: value,
        },
        {
          fieldName: templateData.templateSettingDTO.eF1,
          operatorName: "equal",
          compareValue: reportAttachTo,
        },
        {
          fieldName: updateFieldName(
            templateData.templateSettingDTO.eF1,
            "isDeleted"
          ),
          operatorName: "equal",
          compareValue: 0,
        },
      ]);
    }
  };

  const fetchfilter = async () => {
    console.log("Calling the Fetch Filter ");
    try {
      const response = await TemplateSettingApi.getDatasetExecuteQueryFilter(
        queryData,
        filtercondition,
        pagecount,
        PageNumber,
        templateData.templateSettingDTO.eF2,
        templateData.templateSettingDTO.reportType
      );
      console.log(response);
      listReport(response.table);
      setTotalRecords(response.table[0].totalRecords);
    } catch (error) {
      console.error("Error executing query:", error);
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [filtercondition]);

  const handleKeyDownUniversal = async (e, filterdata) => {
    //debugger;
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;
      console.log(filterdata);
      if (!value) {
        fetchDataTemp();
        return;
      }
      var searchval;

      try {
        const finalResults = [];

        for (let i = 0; i < filterdata.length; i++) {
          const item = filterdata[i];
          if (queryData) {
            searchval = GetcolName(item.accessor);
          }
          if (item.accessor.length === 0) {
            continue;
          }

          let filterData = [
            {
              fieldName: searchval,
              operatorName: "like",
              compareValue: value,
            },
            {
              fieldName: updateFieldName(
                templateData.templateSettingDTO.eF1,
                "isDeleted"
              ),
              operatorName: "equal",
              compareValue: 0,
            },
          ];

          try {
            // Await the result of the API call
            console.log("Key Down Universal");

            const response =
              await TemplateSettingApi.getDatasetExecuteQueryFilter(
                queryData,
                filterData,
                pagecount,
                PageNumber,
                templateData.templateSettingDTO.eF2,
                templateData.templateSettingDTO.reportType
              );
            setTotalRecords(response.table[0].totalRecords);
            // Collect the result
            finalResults.push(...response.table);
            console.log(response);
          } catch (apiError) {
            // Handle errors specific to the API call
            console.error("Error executing query for item:", apiError);
          }
        }

        // After the loop, log and process the final results
        console.log(finalResults);
        listReport(finalResults);
      } catch (error) {
        // Handle errors that occur outside of the loop
        console.error("Error executing query:", error);
      }
    }
  };

  // Update custom dates
  const handleCustomDateChange = async (e, filterData) => {
    //debugger;
    const { id, value, textContent } = e.target;
    if (id === "start-date") {
      setCustomStartDate(value);
    } else if (id === "end-date") {
      setCustomEndDate(value);
    }

    // --------------------changes --------------------

    if (!value && !textContent) {
      fetchDataTemp();
      return;
    }

    if (customStartDate && customEndDate && textContent == "Apply") {
      let filterDataa = {
        or: [
          {
            and: [
              {
                fieldName: updateFieldName(
                  templateData.templateSettingDTO.eF1,
                  "createdOn"
                ),
                operatorName: "between",
                compareValue: `${customStartDate},${customEndDate}`,
              },
              {
                fieldName: templateData.templateSettingDTO.eF1,
                operatorName: "equal",
                compareValue: reportAttachTo,
              },
              {
                fieldName: updateFieldName(
                  templateData.templateSettingDTO.eF1,
                  "isDeleted"
                ),
                operatorName: "equal",
                compareValue: 0,
              },
            ],
          },
        ],
      };

      try {
        console.log("Calling Inside Custom Date");
        const response = await TemplateSettingApi.getDatasetExecuteQueryFilter(
          queryData,
          filterDataa,
          pagecount,
          PageNumber,
          templateData.templateSettingDTO.eF2,
          templateData.templateSettingDTO.reportType
        );
        console.log(response);
        listReport(response.table);
        setTotalRecords(response.table[0].totalRecords);
      } catch (error) {
        console.error("Error executing query:", error);
      }
    }
    // }
    console.log(filterData);
  };

  useEffect(() => {
    fetchDataTemp();
  }, [queryData, selectedDateFilter]);

  const getDateRange = (filter) => {
    //debugger;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    if (!startDate || !endDate) {
      console.warn("Start date and end date must be set to fetch data.");
      setLoading(false);
      return;
    }

    switch (filter) {
      case "Today":
        // startDate = endDate = today;
        // startDate.setHours(0, 0, 0, 0);
        // endDate.setHours(23, 59, 59, 999);
        break;
      case "Yesterday":
        startDate.setDate(today.getDate() - 1);
        endDate.setDate(today.getDate() - 1);
        break;
      case "This Week":
        // startDate.setDate(today.getDate() - today.getDay());
        startDate.setDate(today.getDate() - today.getDay());
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Last 7 Days":
        // startDate.setDate(today.getDate() - 6);
        startDate.setDate(today.getDate() - 6);
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "This Month":
        // startDate.setDate(1);
        startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Set startDate to the first day of this month
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Previous Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Custom":
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
        break;
      default:
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
    }

    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
    }
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
    }

    return { startDate, endDate };
  };

  const [pagecount, setpagecount] = useState(1);

  const [
    rowwiseArrowforNavigationtoAnotherPage,
    setRowwiseArrowforNavigationtoAnotherPage,
  ] = useState(false);
  const [rowwiseButtontoOpenPopupWindow, setRowwiseButtontoOpenPopupWindow] =
    useState(false);
  const [useDoubleClick, setUseDoubleClick] = useState(false);

  function updateFieldName(fieldName, newSuffix) {
    if (!fieldName) {
      return;
    }
    const lastDotIndex = fieldName.lastIndexOf(".");
    if (lastDotIndex > -1) {
      const baseName = fieldName.substring(0, lastDotIndex);
      return `${baseName}.${newSuffix}`;
    } else {
      return newSuffix;
    }
  }
  const fetchDataTemp = async () => {
    try {
      setLoading(true);
      console.log(selectedDateFilter);
      const url = window.location.href;
      const id = url.split("?")[1];

      const filterData = [
        {
          fieldName: "reportAttachTo",
          operatorName: "Equal",
          compareValue: id,
        },
      ];
      const tempdata = await TemplateSettingApi.getTemplateSettingFilter(
        filterData
      );
      console.log(tempdata);
      // [1].templateSettingDTO.pagination

      // setTemplateList(tempdata);
      const FilterTempdata = tempdata.find(
        (item) => item.templateSettingDTO.reportType == 1
      );
      console.log(FilterTempdata.templateSettingDTO.pagination);
      var masterid = FilterTempdata.templateSettingDTO.id;
      console.log(masterid);
      setpagecount(FilterTempdata.templateSettingDTO.pagination);
      setReportAttachTo(id);
      setLoading(false);

      const response = await TemplateSettingApi.getTemplateSettingById(
        masterid
      );
      // alert(id[1])
      console.log(id);
      console.log(response);

      //debugger;
      setTemplateData(response);
      setQueryData(response.templateSettingDTO.queryForSql);
      setSearchAnyText(response.templateSettingDTO.search_AnyText);
      setSearchByDate(response.templateSettingDTO.search_ByDate);
      setPrintButton(response.templateSettingDTO.printButton);
      setExcelButton(response.templateSettingDTO.excelButton);
      setSearchByColumn(response.templateSettingDTO.search_ByColumn);
      setRowWiseEdit(response.templateSettingDTO.rowWise_Edit);
      setRowWisePrint(response.templateSettingDTO.rowWise_Print);
      setRowWiseWhatsapp(response.templateSettingDTO.rowWise_Whatsapp);
      setRowWiseDelete(response.templateSettingDTO.rowWise_Delete);
      setFirmNameSelect(response.templateSettingDTO.firmNameSelect);
      setTransactionType(response.templateSettingDTO.transactionTypeSelect);
      setBillSeries(response.templateSettingDTO.billSeriesNameSelect);
      setRowwiseArrowforNavigationtoAnotherPage(
        response.templateSettingDTO.rowWise_ArrowForOtherPage
      );
      setUseDoubleClick(response.templateSettingDTO.srch1);
      setRowwiseButtontoOpenPopupWindow(
        response.templateSettingDTO.rowWise_TransporterOrOther
      );
      const defaultColumns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
      ];

      const dynamicColumns = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
          .filter((column) => column.showOrHide)
          .map((column) => ({
            Header:
              column.columnNameByUser.trim() !== ""
                ? column.columnNameByUser
                : column.columnName,
            accessor: column.columnName.toLowerCase(),
            priority: parseInt(column.col1, 10) || 0,
          }))
        : [];

      const sortedDynamicColumns = dynamicColumns.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedColumns = [
        ...defaultColumns,
        // ...dynamicColumns,
        ...sortedDynamicColumns,
        { Header: "ACTION", accessor: "action" },
      ];

      setColumns(combinedColumns);
      console.log(combinedColumns);

      const defaultFilterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
      ];
      //debugger;
      const dynamicFilterData = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
          .filter((column) => column.showOrHide)
          .map((filter, index) => ({
            Header: (
              <input
                type="text"
                name={filter.columnName}
                placeholder={filter.columnNameByUser}
                // onChange={handleChange}
                onKeyDown={(e) => handleKeyDown(e, filter)}
              />
            ),
            // accessor: filter.accessor || `dynamic_filter_${index}`,
            accessor:
              filter.columnName.toLowerCase() || `dynamic_filter_${index}`,
            priority: parseInt(filter.col1, 10) || 0,
          }))
        : [];

      const sortedDynamicFilterData = dynamicFilterData.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedFilterData = [
        ...defaultFilterData,
        // ...dynamicFilterData,
        ...sortedDynamicFilterData,
      ];

      setFilterData(combinedFilterData);
      console.log(combinedFilterData);

      fetchData(
        response.templateSettingDTO.rowWise_Edit,
        response.templateSettingDTO.rowWise_Delete,
        response.templateSettingDTO.rowWise_Whatsapp,
        response.templateSettingDTO.rowWise_Print,
        response.templateSettingDTO.queryForSql,
        response.templateSettingReportConfigList,
        response.templateSettingDTO.eF1,
        response.templateSettingDTO.eF2,
        templateData.templateSettingDTO.reportType
      );
      setReportConfig(response.templateSettingReportConfigList);

      // -----------------filter based on selected date------------------
      if (selectedDateFilter.length != 0) {
        const date = getDateRange(selectedDateFilter);
        const startDate = new Date(date.startDate);
        const endDate = new Date(date.endDate);
        const utcStartDate = new Date(startDate.toISOString());
        const utcEndDate = new Date(endDate.toISOString());
        console.log(utcStartDate);
        const formatDate = (date) => {
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, "0");
          const day = String(date.getUTCDate()).padStart(2, "0");
          // const hours = String(date.getUTCHours()).padStart(2, '0');
          // const minutes = String(date.getUTCMinutes()).padStart(2, '0');
          // const seconds = String(date.getUTCSeconds()).padStart(2, '0');
          // const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

          return `${year}-${month}-${day}`;
        };
        const startdate_apiform = formatDate(utcStartDate);
        const enddate_apiform = formatDate(utcEndDate);

        console.log(startdate_apiform);
        console.log(enddate_apiform);
        // ----------------------call api --------------------
        let filterDataa = {
          or: [
            {
              and: [
                {
                  fieldName: updateFieldName(
                    templateData.templateSettingDTO.eF1,
                    "createdOn"
                  ),
                  operatorName: "between",
                  compareValue: `${startdate_apiform},${enddate_apiform}`,
                },
                {
                  fieldName: templateData.templateSettingDTO.eF1,
                  operatorName: "equal",
                  compareValue: reportAttachTo,
                },
                {
                  fieldName: updateFieldName(
                    templateData.templateSettingDTO.eF1,
                    "isDeleted"
                  ),
                  operatorName: "equal",
                  compareValue: 0,
                },
              ],
            },
          ],
        };

        try {
          console.log("Calling Inside Temp");
          const response =
            await TemplateSettingApi.getDatasetExecuteQueryFilter(
              queryData,
              filterDataa,
              pagecount,
              PageNumber,
              templateData.templateSettingDTO.eF2,
              templateData.templateSettingDTO.reportType
            );
          console.log(response);
          listReport(response.table);
          setTotalRecords(response.table[0].totalRecords);
        } catch (error) {
          console.error("Error executing query:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataTemp();
  }, [selectedDateFilter]);

  const [PageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const fetchData = async (
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    queryData,
    combinedTotalValue,
    ef1,
    ef2,
    reportType,
    filter = selectedDateFilter,
    customStart = customStartDate,
    customEnd = customEndDate
  ) => {
    setLoading(true);

    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    let filterData = [];
    if (ef1?.toLowerCase().includes(".string")) {
      filterData = [
        {
          fieldName: updateFieldName(ef1, "isDeleted"),
          operatorName: "equal",
          compareValue: 0,
        },
      ];
    } else {
      filterData = [
        {
          fieldName: ef1,
          operatorName: "equal",
          compareValue: reportAttachTo,
        },
        {
          fieldName: updateFieldName(ef1, "isDeleted"),
          operatorName: "equal",
          compareValue: 0,
        },
      ];
    }

    // const { startDate, endDate } = getDateRange(filter, customStartDate, customEndDate);

    let { startDate, endDate } = getDateRange(filter);
    if (customStart && customEnd) {
      startDate = new Date(customStart);
      endDate = new Date(customEnd);
    }

    try {
      // let pagesize = 60
      console.log("Calling Inside FetchData");

      const val = await TemplateSettingApi.getDatasetExecuteQueryFilter(
        queryData,
        filterData,
        pagecount,
        PageNumber,
        ef2,
        reportType
      );
      console.log(val);
      listReport(
        val.table,
        rowWiseEdit1,
        rowWiseDelete1,
        rowWiseWhatsapp1,
        rowWisePrint1,
        combinedTotalValue
      );
      setTotalRecords(val.table[0].totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  const recall = async () => {
    //debugger;
    console.log("Calling the Recall ");
    if (templateData == null) {
      return;
    }

    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    let filterData = [];
    if (templateData.templateSettingDTO.eF1.trim() == "string") {
      filterData = [
        {
          fieldName: updateFieldName(
            templateData.templateSettingDTO.eF1,
            "isDeleted"
          ),
          operatorName: "equal",
          compareValue: 0,
        },
      ];
    } else {
      filterData = [
        {
          fieldName: templateData.templateSettingDTO.eF1,
          operatorName: "equal",
          compareValue: reportAttachTo,
        },
        {
          fieldName: updateFieldName(
            templateData.templateSettingDTO.eF1,
            "isDeleted"
          ),
          operatorName: "equal",
          compareValue: 0,
        },
      ];
    }
    console.log(templateData);
    console.log("Calling Inside recall");
    const val = await TemplateSettingApi.getDatasetExecuteQueryFilter(
      queryData,
      filterData,
      pagecount,
      PageNumber,
      templateData.templateSettingDTO.eF2,
      templateData.templateSettingDTO.reportType
    );
    listReport(val?.table);
    setTotalRecords(val.table[0].totalRecords);
    console.log(val);
  };
  useEffect(() => {
    if (filtercondition.length > 2) {
      fetchfilter();
      return;
    }
    recall();
  }, [PageNumber]);

  const listReport = async (
    tableData,
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    combinedTotalValue
  ) => {
    try {
      let filteredData = tableData;

      const newData = filteredData?.map((item, i) => {
        const dynamicProperties = Object.entries(item).reduce(
          (acc, [key, value]) => {
            acc[key.toLowerCase()] = value;
            return acc;
          },
          {}
        );
        return {
          input: (
            <input type="checkbox" onChange={() => handleCheckboxChange(i)} />
          ),
          sno: i + 1,
          // ...rest,
          ...dynamicProperties,
          action: (
            <div className="tablelist-action-box">
              {(rowWiseEdit1 || rowWiseEdit) &&
                (parseInt(url.split("?")[1]) == 53 ? (
                  <i
                    className="fa-solid fa-pen-to-square TableEditIcon"
                    onClick={() =>
                      navigate(`/saleinvoice/${53}`, {
                        state: {
                          ConversionTransId:
                            item?.Trans1IdT1 ?? item?.trans1IdT1,
                          IsConversion: true,
                          transTypeT1: item?.TransTypeT1 ?? item?.transTypeT1,
                          ConvertType: 3,
                          DBIdT1: item?.DBIdT1 ?? item?.dbIdT1, // From Db1Id
                          TableId: item?.Id ?? item?.id,
                        },
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fa-regular fa-pen-to-square TableEditIcon"
                    onClick={() => editTemplateSetting(item)}
                  ></i>
                ))}
              {(rowWiseDelete1 || rowWiseDelete) && (
                <i
                  className="fa-regular fa-trash-can TableDelIcon"
                  onClick={() => deleteTemplateSetting(item)}
                ></i>
              )}
              {(rowWiseWhatsapp1 || rowWiseWhatsapp) && (
                <i
                  className="fa-brands fa-square-whatsapp TableWhatsappcon"
                  onClick={() => handleShare(item.id)}
                ></i>
              )}
              {(rowWisePrint1 || rowWisePrint) && (
                <i className="fa-solid fa-print TablePrint"></i>
              )}
              {item.productCategory != null &&
                parseInt(item.productCategory) === 1 && (
                  <Link to={`/LensMaster/${item.id}`} state={{ item }}>
                    <i className="fa-solid fa-gear"></i>
                  </Link>
                )}
              {rowwiseArrowforNavigationtoAnotherPage && (
                <i
                  className="fa-solid fa-link TablePrint"
                  onClick={() => alert("navigation")}
                ></i>
              )}
              {rowwiseArrowforNavigationtoAnotherPage && (
                <i
                  className="fa-solid fa-link TablePrint"
                  onClick={() => alert("popup")}
                ></i>
              )}
              {/* {parseInt(url.split("?")[1]) == 53 && (
                <i
                  className="fa-solid fa-pen-to-square TableEditIcon"
                  onClick={() =>
                    navigate(`/saleinvoice/${53}`, {
                      state: {
                        ConversionTransId: item.Trans1IdT1 || item.trans1IdT1,
                        IsConversion: true,
                        transTypeT1: item.TransTypeT1 || item.transTypeT1,
                        ConvertType: 3,
                        DBIdT1: item.fromDbID, // From Db1Id
                      },
                    })
                  }
                ></i>
              )} */}
            </div>
          ),
        };
      });

      console.log(newData);
      setData1(newData);
      console.log(combinedTotalValue);

      const dynamicTotalValue = Array.isArray(
        combinedTotalValue || reportConfig
      )
        ? (combinedTotalValue || reportConfig).map((total) => {
          const columnName = total.columnName.toLowerCase() || "";
          const columnSum = newData.reduce((sum, row) => {
            const value = parseFloat(row[columnName]);
            return !isNaN(value) ? sum + value : sum;
          }, 0);
          return {
            accessor: total.columnName.toLowerCase(),
            columnShowTotalValue: total.columnShowTotalValue,
            total: columnSum,
            priority: parseInt(total.col1, 10) || 0,
          };
        })
        : [];

      const sortedDynamicTotalValue = dynamicTotalValue.sort(
        (a, b) => a.priority - b.priority
      );

      setTotalValue(sortedDynamicTotalValue);
      console.log("Calculated Totals", sortedDynamicTotalValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [editData, setEditData] = useState(null);

  const editTemplateSetting = async (data) => {
    console.log(data);

    try {
      const reportAttachTo1 = url.split("?")[1];

      let data1;
      if (reportAttachTo1 == 2) {
        data1 = null;
        const itemData = await AccountApi.getAccountById(
          data.d1RefId || data.Id
        );
        setEditData(itemData.item1);
        setReportAttachTo(2);
      } else if (reportAttachTo1 == 4) {
        // data1 = `/ItemMaster?${data.id}`; // Adjust the path as needed
        data1 = null;
        const itemData = await ItemMasterApi.GetItemMasterById(
          data.id || data.Id
        );
        setEditData(itemData);
        setReportAttachTo(4);
      } else if (reportAttachTo1 == 1) {
        data1 = null;
        const itemData = await AccountGroup.GetAccountGroupById(
          data.id || data.Id
        );
        // console.log(data);
        setEditData(itemData);
        setReportAttachTo(1);
      } else if (reportAttachTo1 == 3) {
        // data1 = `/ItemGroupMaster?${data.id}`;
        data1 = null;
        const itemData = await ItemGroup.GetItemGroupById(data.id || data.Id);
        // console.log(data);
        setEditData(itemData);
        setReportAttachTo(3);
        // toggleSlider(true);
      } else if (reportAttachTo1 == 8) {
        data1 = null;
        const itemData = await UnitMasterApi.getUnitMasterById(
          data.id || data.Id
        );
        // console.log(data);
        setEditData(itemData);
        setReportAttachTo(8);
      }

      if (data1) {
        navigate(data1); // Redirect to the appropriate page
        console.log("Navigating to:", data1);
      }

      setMasterId(data);
      toggleSlider();
      setSliderOpen(true); // Open the slider
    } catch (error) {
      console.error("Error editing data:", error);
    }
  };

  const [getMasterType, setGetMasterType] = useState(null);
  const [getdeleteId, setDeleteId] = useState(null);

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    console.log(editData);
    setSliderOpen(!isSliderOpen);
  };

  const deleteTemplateSetting = (item) => {
    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    setAlertBoxDelete(true);
    setGetMasterType(reportAttachTo);
    setDeleteId(item.id);
  };

  const DeleteTemplateConfirm = async () => {
    setLoading(true);
    try {
      const val = await TemplateSettingApi.deleteTemplateSetting(
        getMasterType,
        getdeleteId
      );
      console.log(val);

      if (Boolean(val.isSuccess) === true) {
        setAlertBoxDelete(false);
        setGetMasterType(null);
        setDeleteId(null);
        setLoading(false);
        fetchDataTemp();
      } else if (val.statusCode == 409) {
        alert(val.errorMessages);
      }
      // fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find((item) => item.id === id);

    if (selectedItem) {
      setSelectedData((prevSelectedData) => {
        const isSelected = prevSelectedData.some((item) => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter((item) => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
  };

  const handleShare = (id) => {
    const listToSend = data1.find((item) => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      listToSend
    )}`;
    window.location.href = whatsappURL;
  };

  const [createData, setCreateData] = useState(null);
  console.log(createData);

  useEffect(() => {
    createTemplateSetting();
  }, []);

  const createTemplateSetting = async () => {
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1]; // Get the reportAttachTo value

      // let destinationUrl;

      // if (reportAttachTo == 2) {
      //   destinationUrl = "/AccountMaster"; // Set URL for AccountMaster page
      // } else if (reportAttachTo == 4) {
      //   destinationUrl = "/ItemMaster"; // Set URL for ItemMaster page
      // } else if (reportAttachTo == 1) {
      //   destinationUrl = "/AccountGroupMaster"; // Set URL for ItemMaster page
      // } else if (reportAttachTo == 3) {
      //   destinationUrl = "/ItemGroupMaster"; // Set URL for ItemMaster page
      // } else if (reportAttachTo == 8) {
      //   destinationUrl = "/UnitMaster"; // Set URL for ItemMaster page
      // }

      const destinationMap = {
        1: "/AccountGroupMaster",
        2: "/AccountMaster",
        3: "/ItemGroupMaster",
        4: "/ItemMaster",
        8: "/UnitMaster",
        55: "/saleinvoice/55",
        53: "/saleinvoice/53",
      };

      const destinationUrl = destinationMap[reportAttachTo] || "/Welcome";

      if (destinationUrl) {
        setCreateData(destinationUrl);
      }
    } catch (error) {
      console.error("Error creating template setting:", error);
    }
  };

  const fetchData1 = async () => {
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1];
      const response = await TemplateSettingApi.getAllMasterType();
      if (response && Array.isArray(response)) {
        const matchingItem = response.find((item) => item.id == reportAttachTo);

        if (matchingItem) {
          setHeading(matchingItem.name);
        } else {
          console.error(
            "No matching master type found for reportAttachTo:",
            reportAttachTo
          );
        }
      } else {
        console.error("Error:", response.errorMessages);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData1();
  }, [reportAttachTo]);

  return (
    <div className="">
      <PageTabs
        locationTab={"/MasterReport"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">{heading} List</h2>
          <ReportTableButton
            createTemplateSetting={createTemplateSetting}
            createData={createData}
            filterData={filterData}
            columns={columns}
            data={data1}
            selectedData={selectedData}
            printButton={printButton}
            excelButton={excelButton}
            shareButton={shareButton}
          />
        </div>
        <MasterReportDataTable
          PageNumber={PageNumber}
          setPageNumber={setPageNumber}
          setSelectedDateFilter={setSelectedDateFilter}
          pagecount={pagecount}
          fetchData={fetchData}
          filterData={filterData}
          columns={columns}
          totalValue={totalValue}
          data={data1}
          searchAnyText={searchAnyText}
          searchByDate={searchByDate}
          searchByColumn={searchByColumn}
          columnShowTotalValue={columnShowTotalValue}
          calculatedTotals={calculatedTotals}
          tableData={tableData}
          universalSearch={universalSearch}
          handleKeyDownUniversal={handleKeyDownUniversal}
          setUniversalSearch={setUniversalSearch}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          handleCustomDateChange={handleCustomDateChange}
          fetchDataTemp={fetchDataTemp}
          onDateFilterClick={setSelectedDateFilter}
          firmNameSelect={firmNameSelect}
          transactionType={transactionType}
          billSeries={billSeries}
          useDoubleClick={useDoubleClick}
          totalRecords={totalRecords}
        />
      </div>
      {/******************************* Unit Master Modal*************************/}
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        {/* <div style={{ padding: "15px" }}>
                <MasterReport editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} 
                reportAttachTo={reportAttachTo == 2 ? AccountMaster1 : (reportAttachTo == 4 ? ItemMaster : null)}
                />
                </div> */}
        {reportAttachTo == 2 && (
          <div style={{ padding: "15px" }}>
            <AccountMaster1
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          </div>
        )}
        {reportAttachTo == 4 && (
          <div style={{ padding: "15px" }}>
            <ItemMaster
              data={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData} //data={data1}
              masterId={masterId}
            />
          </div>
        )}
        {reportAttachTo == 1 && (
          <div style={{ padding: "15px" }}>
            <AccountGroupMaster
              data={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              // data={data1}
              masterId={masterId}
            />
          </div>
        )}
        {reportAttachTo == 3 && (
          <div style={{ padding: "15px" }}>
            <ItemGroupMaster
              data={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchDataTemp}
              // data={data1}
              masterId={masterId}
            />
          </div>
        )}
        {reportAttachTo == 8 && (
          <div style={{ padding: "15px" }}>
            <UnitMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          </div>
        )}
      </div>
      {/* ********************************************** Mobile View ***************************** */}
      <div className="wrapper">
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListSaveButton />
            </div>
          </div>
        </div>
      </div>
      {/* ***************************** */}
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteTemplateConfirm}
        />
      )}
    </div>
  );
}
export default MasterListReport;
