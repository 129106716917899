import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SMS = {

  SendSMS: async (data) => {
    // console.log(CompanyDetail)
    // console.log(AccountData)
    // console.log(saveOrder)
    // const orderaData = saveOrder.result
    // console.log(orderaData.orderNo)
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/SMS/send-sms`;
        const response = await axios.post(
          apiUrl,
          {
            to: data.to,
            message: data.message,
            smsTempAttr: data.smsTempAttr,
            smsTempVal: data.smsTempVal,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        console.log("Response:", response.data);
        return true;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************** WhatsApp API **********************
  SendWhatsApp: async (data) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/SMS/send-WhatsAppMsg`;
        const response = await axios.post(
          apiUrl,
          {
            to: data.to,
            templateName: data.templateName,
            templateLanguage: data.templateLanguage,
            headerSection: data.headerSection,
            bodySection: data.bodySection,
            fileMediaId: data.fileMediaId,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        console.log("Response:", response.data);
        return true;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ********************** Upload WhatsApp File********************
  uploadWhatsAppFile: async (blob) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        console.log(blob);
        const formData = new FormData();
        formData.append('files', blob, 'file.pdf');

        const apiUrl = `${WebApiUrl}/api/SmsTemplates/send-WhatsAppFile`;
        const response = await axios.post(apiUrl, formData, {
          withCredentials: true,
          headers: {
            // 'Content-Type': 'multipart/form-data', // Axios handles this automatically
          },
        });
        console.log("Response:", response.data.isSuccess);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
        if (error.response) {
          console.error("Server Response:", error.response.data);
        }
        if (error.request) {
          console.error("Request Error:", error.request);
        }
      }
    }
    return fetchData();
  },

};

export default SMS;