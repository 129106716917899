import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import TemplateSetting from "../../../API/APIServices/TemplateSetting";
// import Loader from "../../Props/Loader";

import html2pdf from 'html2pdf.js';
const BarcodeGenerator = (prop) => {

    const [apiData, setApiData] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [styleArray, setStylesArray] = useState([]);
    const [loading, setLoading] = useState(true)
    const [variableReplaceData, setVariableReplaceData] = useState('')
    const [printData, setPrintData] = useState("");
    useEffect(() => {
        console.log("droppedItems", droppedItems);
        console.log("printData", printData);
        console.log("variableReplaceData", variableReplaceData);
        console.log("styleArray", styleArray);
    }, [droppedItems, printData, variableReplaceData, styleArray]);

    const showTemplateData = async (transId, templateName) => {
        debugger
        setLoading(true)
        if (!transId) {
            return;
        }
        const filteredData = [{
            "fieldName": "name",
            "operatorName": "equal",
            "compareValue": templateName || "New Template",
        }];
        var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
        if (getdata) {
            if (getdata.jsonStructure) {
                try {
                    const decodedData = decodeURIComponent(getdata.jsonStructure);
                    const parsedData = JSON.parse(decodedData);
                    console.log("parsedData1", parsedData);

                    setDroppedItems(parsedData);

                    const transactionId = transId;
                    let sqlQuery = getdata?.insertQuery;
                    if (sqlQuery?.includes('@transId')) {
                        sqlQuery = sqlQuery.replace(/@transId/g, transactionId);
                    }
                    const queryData = {
                        query1: sqlQuery,
                        query2: sqlQuery,
                        query3: sqlQuery
                    }
                    let tableData;
                    if (sqlQuery) {
                        tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
                        setVariableReplaceData(tableData);
                    }
                    if (getdata.textContents) {
                        const decodedData = decodeURIComponent(getdata.textContents)
                        const parsedData = JSON.parse(decodedData);
                        console.log("parsedData2", parsedData);
                        // getdata.jsonStructure = parsedData
                        return { 'printData': parsedData, 'replaceData': tableData };
                        // setStylesArray(parsedData);
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setDroppedItems([]);
                }
            } else {
                setDroppedItems([]);
            }
            var templateInnerHtml = getdata.textContents;
            var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
            getdata.textContents = templateInnerHtmlDecode

            setApiData(getdata);
            setLoading(false)
            // setDataHtml(getdata)
        } else {
            //document.getElementById("drop-target-innerHtml").outerHTML = "";
            setDroppedItems([]);
            setLoading(false)
        }
    }

    const renderDroppedItems = () => {
        return droppedItems.length > 0 && droppedItems.some(item => item.items.length > 0) ? (
            droppedItems.map((item, index) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData = itemContent;
                    if (variableReplaceData && variableReplaceData != "") {
                        updatedData = replaceVariables1(itemContent, variableReplaceData);
                        // const data = replaceVariables(itemContent, variableReplaceData)
                        // updatedData = replacePlaceholders(data, variableReplaceData)
                        // generatePDF(updatedData)
                        const placeholderRegex = /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
                        if (placeholderRegex.test(updatedData)) {
                            return null; // If placeholders are still present, hide the div by returning null
                        }
                    }
                    return (
                        <div> {/*style={{ transformOrigin: "5% 5%", transform: "scale(0.5, 0.5)", width: '100%', overflow: 'hidden', }}*/}
                            <div key={index} dangerouslySetInnerHTML={{ __html: updatedData }} />
                        </div>
                    );
                }
                return null;
            })
        ) : null;
    };




    // Function to generate a barcode image from an itmcode
    function replaceVariables1(itemContent, variableReplaceData) {
        let rowContent = ''; // Start with an empty row content

        // Process each product one at a time
        let productIndex = 0; // Track the product index to ensure we replace for one product at a time

        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            if (Array.isArray(tableData) && tableData.length > 0) {
                // Loop over each product (row of data)
                for (const obj of tableData) {
                    if (productIndex < tableData.length) {
                        let productContent = itemContent; // Work on a copy of the original content for each product

                        // Replace the variables for the current product
                        for (const key in obj) {
                            if (key === '@T1.ItmCode') {
                                const itmcode = obj[key];
                                const itmcodeRegex = new RegExp('@T1.ItmCode', 'g');
                                productContent = productContent.replace(itmcodeRegex, itmcode);

                                const barcodeImage = generateBarcode(itmcode); // Generate barcode image URL
                                const barcodeRegex = /BarcodeImage/g;
                                const imgTag = `<img src="${barcodeImage}" alt="Barcode" />`;
                                productContent = productContent.replace(barcodeRegex, imgTag);
                            } else {
                                const regex = new RegExp(key, 'g');
                                productContent = productContent.replace(regex, obj[key]);
                            }
                        }

                        // After processing the first product, wrap it in a div and move to the next
                        rowContent += `<div style="margin: 10px;">${productContent}</div>`;
                    }

                    // Increment product index after processing each product
                    productIndex++;
                }
            }
        }

        return rowContent; // Return the final content after all products are processed
    }

    function generateBarcode(itmcode) {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, itmcode, {
            format: "CODE128",
            width: 1,
            height: 20,
            displayValue: false
        });
        return canvas.toDataURL();
    }





    useEffect(() => {
        debugger;
        if (droppedItems.length > 0 && variableReplaceData !== "") {
            // const Structure = getLayoutStructure(droppedItems);
            // console.log("Structure", Structure);
            droppedItems.forEach((item) => {
                if (item.items.length > 0) {
                    const itemContent = item.textContents;
                    let updatedData = null;

                    if (variableReplaceData && Object.keys(variableReplaceData).length > 0) {
                        updatedData = replaceVariables1(itemContent, variableReplaceData);
                    }

                    if (updatedData) {
                        setPrintData(prevValues => prevValues + updatedData);
                    }
                }
            });
        }
    }, [droppedItems, variableReplaceData]);



    useEffect(() => {
        let isMounted = true; // To prevent state updates on an unmounted component

        const fetchData = async () => {
            debugger
            try {
                const replacedData = {};
                const { printData, replaceData } = await showTemplateData(21, "Template2");

                if (isMounted && printData && replaceData) {
                    for (const item of printData) {
                        const { content, style } = item;

                        for (const tableName in replaceData) {
                            const tableData = replaceData[tableName];

                            if (Array.isArray(tableData) && tableData.length > 0) {
                                tableData.forEach((obj) => {
                                    let updatedContent = replaceVariables(content, obj);

                                    const productId = obj["@T1.ProductId"];
                                    const productName = obj["@T1.ItmCode"];
                                    const productQty = obj["@T1.Qty"];
                                    const variableNames = Object.keys(obj).filter(key =>
                                        key.startsWith('@T1.')
                                    );

                                    const variableData = variableNames.reduce((acc, key) => {
                                        const variableName = key.replace('@T1.', '');
                                        acc[variableName] = obj[key];
                                        return acc;
                                    }, {});

                                    if (!replacedData[productName]) {
                                        replacedData[productName] = [];
                                    }

                                    replacedData[productName].push({
                                        content: updatedContent,
                                        style,
                                        productId,
                                        variableData,
                                        qty: productQty,
                                    });
                                });
                            }
                        }
                    }

                    setStylesArray(replacedData); // Ensure state updates only if mounted
                    console.log("replacedData", replacedData);
                }
            } catch (error) {
                console.error("Error fetching or processing data", error);
            }
        };

        fetchData();

        // Cleanup function to avoid state updates after unmount
        return () => {
            isMounted = false;
        };
    }, []);




    function replaceVariables(itemContent, productData) {
        // console.log("productData", productData);

        // Loop through each key in productData
        for (const key in productData) {
            const placeholder = `${key}`;
            const value = productData[key];


            const placeholderRegex = new RegExp(placeholder, 'g');

            // Replace the placeholder with the actual value or an empty string if value is null/undefined
            if (value === null || value === undefined) {
                itemContent = itemContent.replace(placeholderRegex, "");
            } else {
                itemContent = itemContent.replace(placeholderRegex, value);
            }
        }

        return itemContent;
    }


    async function generatePDF() {
        // Initialize jsPDF
        debugger
        const pdf = new jsPDF();

        // Fetch the dynamic HTML content
        // const contentElement = document.querySelector('.resizers-web-template');  // Adjust the selector as needed

        // Ensure contentElement exists
        // if (contentElement) {
        // Get the HTML content (including barcode image) from the element
        // const htmlContent = contentElement.innerHTML;
        const opt = {
            margin: 12,
            filename: 'myfile.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 10 },
            // jsPDF: { unit: 'mm', format: [58, contentHeight / 1.75], orientation: 'portrait' },
            // pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }
        };

        const pdfDataUri = await html2pdf()
            .from(printData)
            .set(opt)
            .toPdf()
            .output('datauristring')

        const pdfBase64 = pdfDataUri.split(',')[1];


        const byteCharacters = atob(pdfBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);
        const newWindow = window.open(pdfUrl, '_blank');

        if (newWindow) {
            newWindow.onload = function () {
                newWindow.print();
                newWindow.onafterprint = function () {
                    newWindow.close();
                };
            };
        }

        // Add the HTML content to the PDF
        // pdf.html(printData, {
        //     callback: function (pdf) {
        //         // Save the PDF
        //         const pdfBlob = pdf.output("blob");
        //         const pdfUrl = URL.createObjectURL(pdfBlob);
        //         window.open(pdfUrl, "_blank");
        //     },
        //     margin: [10, 10],  // Adjust margins as needed
        //     x: 10,
        //     y: 10
        // });
        // // } else {
        //     console.error('Content element not found.');
        // }
    }


    
    function pixelsToMm(pixels) {
        // Check if the input is a string (e.g., "153px"), and if so, remove the "px" part
        if (typeof pixels === 'string') {
            pixels = parseFloat(pixels.replace('px', ''));
        }

        // Ensure pixels is a number
        if (isNaN(pixels)) {
            console.error("Invalid pixel value:", pixels);
            return 0; // Return 0 if invalid pixel value
        }

        // Get the screen's DPI (dots per inch)
        const dpi = window.devicePixelRatio * 96; // Default DPI for most screens is around 96

        // Convert pixels to inches
        const inches = pixels / dpi;

        // Convert inches to millimeters
        const mm = inches * 25.4;

        return mm;
    }

    function pixelsToPoints(pixels) {
        if (typeof pixels === 'string') {
            pixels = parseFloat(pixels.replace('px', ''));
        }
        return pixels * 0.75;
    }

    const generatePDF1 = () => {
        debugger

        const containerMinHeight = parseFloat(printData[0]?.style?.['min-height']) || 297; // Default to 297mm (A4 size) if not found


        const doc = new jsPDF();
        const pageHeight = containerMinHeight; // Use the container's min-height as the page height
        doc.setPage([210, pageHeight]); // A4 width is 210mm, height is set dynamically


        printData.forEach(item => {
            const { style, content, id } = item;
            var htmlNode = document.getElementById(id);
            var rect = htmlNode.getBoundingClientRect();
            console.log("rect", rect);
            // let positionX = pixelsToMm(style.positionX) || 0;
            // let positionY = pixelsToMm(style.positionY) || 0;
            let positionX = pixelsToMm(Math.abs(rect.left))
            let positionY = pixelsToMm(Math.abs(rect.top))


            console.log(`Original positionX: ${positionX}, positionY: ${positionY}`);

            // Constrain positionX within the page width (0 to 210mm for A4)
            positionX = Math.max(0, Math.min(positionX, 210));
            positionY = Math.max(0, Math.min(positionY, 297));

            // Debugging: Log the constrained positions
            console.log(`Constrained positionX: ${positionX}, positionY: ${positionY}`);

            // Generate barcode if content is "BarcodeImage"
            if (content === 'BarcodeImage') {
                // Generate barcode image as base64
                const barcodeCanvas = document.createElement('canvas');
                JsBarcode(barcodeCanvas, '123456789012', { format: "CODE128", displayValue: false });
                const barcodeDataUrl = barcodeCanvas.toDataURL('image/png');

                // Constrain barcode to fit within the page size
                const barcodeWidth = 50;
                const barcodeHeight = 20;

                // Ensure barcode fits within the width and height of the page
                const finalPositionX = Math.min(positionX, 210 - barcodeWidth);
                const finalPositionY = Math.min(positionY, 297 - barcodeHeight);

                // Add barcode to the PDF
                console.log(`Adding Barcode at X: ${finalPositionX}, Y: ${finalPositionY}`);
                doc.addImage(barcodeDataUrl, 'PNG', finalPositionX, finalPositionY, barcodeWidth, barcodeHeight); // Adjust size and position
            } else {
                // For non-barcode content, replace placeholders and add text to the PDF
                const replacedContent = content === '@T1.ProductName' ? 'Product Name'
                    : content === '@T1.ADepartment' ? 'Department'
                        : content === '@T1.PurSalePrice' ? '100' // Replace with actual dynamic data
                            : content === '@T1.ItmCode' ? '12345' // Replace with actual dynamic data
                                : content;

                // Calculate text width and height for positioning
                const textWidth = doc.getTextWidth(replacedContent);
                const textHeight = 10; // Approximate height for text (adjust as necessary)

                // Ensure text is within bounds
                const finalTextPositionX = Math.min(positionX, 210 - textWidth);
                const finalTextPositionY = Math.min(positionY, 297 - textHeight);

                // Debugging: Log final text positions
                console.log(`Adding Text: "${replacedContent}" at X: ${finalTextPositionX}, Y: ${finalTextPositionY}`);

                // Add the text to the PDF
                doc.text(replacedContent, finalTextPositionX, finalTextPositionY); // Add text at the specified position
            }
        });



        // Save the generated PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
    };


    const generatePDFWithBarcodes = () => {
        debugger
        var totalProducts = 0
        var leftf
        var topf
        var pageHeight, pageWidth, orientation, pageUnit;
        const constrainedX = 2;
        const constrainedY = 1.25;

        const extractValueAndUnit = (value) => {
            if (typeof value === "string") {
                const match = value.match(/^(\d+(\.\d+)?)([a-zA-Z%]*)$/);
                return {
                    numericValue: match ? parseFloat(match[1]) : null,
                    unit: match ? match[3] : null,
                };
            }
            return { numericValue: value, unit: null };
        };

        for (const product in styleArray) {
            const styleArray1 = styleArray[product];
            var style = styleArray1[0].style;

            const pageHeightResult = extractValueAndUnit(style["page-height"]);
            const pageWidthResult = extractValueAndUnit(style["page-width"]);

            // Extract height and its unit
            pageHeight = pageHeightResult.numericValue;
            pageUnit = pageHeightResult.unit;

            // Validate width uses the same unit
            if (pageWidthResult.unit && pageWidthResult.unit !== pageUnit) {
                throw new Error(
                    `Mismatch in units: page-height uses "${pageUnit}", but page-width uses "${pageWidthResult.unit}".`
                );
            }

            pageWidth = pageWidthResult.numericValue;
            orientation = style["orientation"];

            console.log("style", style);
            console.log("Extracted pageHeight:", pageHeight, "Unit:", pageUnit);
            console.log("Extracted pageWidth:", pageWidth, "Unit:", pageUnit);
            console.log("Orientation:", orientation);
        }


        const doc = new jsPDF(orientation || 'p', pageUnit || 'mm', [pageHeight, pageWidth]);

        for (const product in styleArray) {
            const styleArray1 = styleArray[product];

            if (styleArray1 && styleArray1.length > 0) {
                styleArray1.forEach((item) => {
                    const { content, style, variableData } = item;
                    const itmcode = variableData.ItmCode;
                    topf = constrainedY * pixelsToMm(style.top)
                    leftf = constrainedX * pixelsToMm(style.left)
                    const left = Math.ceil(leftf);
                    const top = Math.ceil(topf);
                    console.log(`left: ${left}, top: ${top}`);

                    if (isNaN(left) || isNaN(top)) {
                        return;
                    }


                    if (content === "BarcodeImage") {

                        const canvas = document.createElement("canvas");
                        JsBarcode(canvas, itmcode, {
                            format: "CODE128", // make it dynamic
                            width: 1.5,
                            height: 50,
                            displayValue: false,
                        });

                        // Add barcode to PDF
                        const barcodeImage = canvas.toDataURL("image/png");
                        doc.addImage(barcodeImage, "PNG", left, top, 40, 15);  // Adjust width and height as needed
                    } else {
                        if (style.fontWeight) {
                            debugger
                            doc.setFont('Helvetica', style.fontWeight?.toLowerCase());
                        } else {
                            doc.setFont('Helvetica', 'normal');
                        }
                        if (style['font-size']) {
                            doc.setFontSize(pixelsToPoints(style['font-size']))
                        }
                        doc.text((content), left, top, { maxWidth: pixelsToMm(style?.width) });
                    }
                });
            }

            doc.setFont('Helvetica', 'normal');
            totalProducts += 1;
            if (totalProducts < Object.keys(styleArray).length) {
                doc.addPage();
            }
        }

        // Save the generated PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
    };


    const generatePDFWithBarcode = () => {
        debugger
        var totalProducts = 0;
        var leftf;
        var topf;
        var pageHeight, pageWidth, orientation, pageUnit;
        const constrainedX = 2;
        const constrainedY = 1.25;

        const extractValueAndUnit = (value) => {
            if (typeof value === "string") {
                const match = value.match(/^(\d+(\.\d+)?)([a-zA-Z%]*)$/);
                return {
                    numericValue: match ? parseFloat(match[1]) : null,
                    unit: match ? match[3] : null,
                };
            }
            return { numericValue: value, unit: null };
        };

        for (const product in styleArray) {
            const styleArray1 = styleArray[product];
            var style = styleArray1[0].style;

            const pageHeightResult = extractValueAndUnit(style["page-height"]);
            const pageWidthResult = extractValueAndUnit(style["page-width"]);

            // Extract height and its unit
            pageHeight = pageHeightResult.numericValue;
            pageUnit = pageHeightResult.unit;

            // Validate width uses the same unit
            if (pageWidthResult.unit && pageWidthResult.unit !== pageUnit) {
                throw new Error(
                    `Mismatch in units: page-height uses "${pageUnit}", but page-width uses "${pageWidthResult.unit}".`
                );
            }

            pageWidth = pageWidthResult.numericValue;
            orientation = style["orientation"];

            console.log("style", style);
            console.log("Extracted pageHeight:", pageHeight, "Unit:", pageUnit);
            console.log("Extracted pageWidth:", pageWidth, "Unit:", pageUnit);
            console.log("Orientation:", orientation);
        }

        const doc = new jsPDF(orientation || 'p', pageUnit || 'mm', [pageHeight, pageWidth]);
        let productQty = 1
        for (const product in styleArray) {
            const styleArray1 = styleArray[product];

            if (styleArray1 && styleArray1.length > 0) {

                for (let i = 0; i < productQty; i++) {
                    if (i > 0) {
                        doc.addPage();
                    }

                    styleArray1.forEach((item) => {
                        const { content, style, variableData } = item;
                        const itmcode = variableData.ItmCode;
                        productQty = variableData.Qty || 1;

                        topf = constrainedY * pixelsToMm(style.top);
                        leftf = constrainedX * pixelsToMm(style.left);
                        const left = Math.ceil(leftf);
                        const top = Math.ceil(topf);
                        console.log(`left: ${left}, top: ${top}`);

                        if (isNaN(left) || isNaN(top)) {
                            return;
                        }

                        // Generate multiple barcodes based on quantity

                        const barcodeTop = top;
                        const barcodeLeft = left;

                        if (content === "BarcodeImage") {
                            const canvas = document.createElement("canvas");
                            JsBarcode(canvas, itmcode, {
                                format: "CODE128", // Make it dynamic
                                width: 1.5,
                                height: 50,
                                displayValue: false,
                            });

                            // Add barcode to PDF
                            const barcodeImage = canvas.toDataURL("image/png");
                            doc.addImage(barcodeImage, "PNG", barcodeLeft, barcodeTop, 40, 15);  // Adjust width and height as needed
                        } else {
                            if (style.fontWeight) {
                                doc.setFont('Helvetica', style.fontWeight?.toLowerCase());
                            } else {
                                doc.setFont('Helvetica', 'normal');
                            }
                            if (style['font-size']) {
                                doc.setFontSize(pixelsToPoints(style['font-size']));
                            }
                            doc.text(content, left, top, { maxWidth: pixelsToMm(style?.width) });
                        }

                    }
                    );
                }
            }

            doc.setFont('Helvetica', 'normal');
            // totalProducts += 1;
            // if (totalProducts < Object.keys(styleArray).length) {
            //     doc.addPage();
            // }

        }

        // Save the generated PDF
        const pdfBlob = doc.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
    };






    return (
        <div>
            {/* <div id="pdf-content" style={{ width: '100mm', margin: '5 auto', overflow: 'hidden' }}>
                <div style={{ padding: '5mm', boxSizing: 'border-box' }}>
                    <div
                        style={{
                            transformOrigin: "5% 5%",
                            transform: "scale(0.5, 0.5)",
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {renderDroppedItems()}
                    </div>
                </div>
                <style>
                    {`
                @media screen {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                @media print {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                `}
                </style>
            </div> */}

            {/* <button onClick={generatePDF}>Generate Barcode PDF</button> */}
            <button onClick={generatePDFWithBarcode}>Generate Barcode PDF</button>
            <button onClick={generatePDFWithBarcodes}>Generate PDF</button>
        </div>
    );
};

export default BarcodeGenerator;
