import React, { useState, useEffect, useContext } from 'react';
import GlobalSelector from './GlobalSelector';
import MyContext from '../../../Inventory/MyContext';

const PageSettingSection = (props) => {
    const { TemplateData, setStylesArray, stylesArray } = useContext(MyContext)
    const defaultWidth = "210";
    const defaultHeight = "297";
    const defaultOrientation = 'portrait';
    const currentStyle = stylesArray.find(styleObj => styleObj.id === `page-settings-${TemplateData.id}`);
    const extractValueAndUnit = (styleValue) => {

        if (typeof styleValue !== 'string' || styleValue.trim() === '') {
            return { value: 'auto', unit: '' };
        }
        if (styleValue.trim().toLowerCase() === 'auto') {
            return { value: 'auto', unit: '' };
        }

        // Check if the unit is mm, if present
        let value = parseFloat(styleValue);
        let unit = styleValue.replace(value, '').trim();

        // Default to 'mm' unit if no unit is provided for page settings
        // if (unit === '') {
        //     unit = 'mm';
        // }

        // Handle the case where the value isn't a valid number
        if (isNaN(value)) {
            value = 'auto';
            unit = '';
        }

        const integerValue = unit === '' || unit === 'mm' ? Math.round(value) : parseInt(value, 10);
        return { value: integerValue, unit };
    };

    const handlePageSettingChange = (style, value, unit) => {
        debugger
        const templateId = TemplateData.id;

        // Define the reserved index for page settings
        const pageSettingsIndex = 0; // You can change this to any index you prefer

        const valueWithUnit = unit === null ? `${value}` : `${value}${unit}`;

        setStylesArray((prevStyles) => {
            const clearedStyles = prevStyles.filter(
                (styleObj) => styleObj.templateId !== templateId
            );
            debugger;
            if (clearedStyles[pageSettingsIndex]) {
                // Update the page settings at the reserved index
                clearedStyles[pageSettingsIndex] = {
                    ...clearedStyles[pageSettingsIndex],
                    style: {
                        ...clearedStyles[pageSettingsIndex].style,
                        [style]: valueWithUnit,
                    },
                };
            } else {
                // If the reserved index doesn't exist, create it
                clearedStyles[pageSettingsIndex] = {
                    id: `page-settings-${templateId}`,
                    style: { [style]: valueWithUnit },
                    content: "",
                    link: "",
                };
            }

            return clearedStyles;
        });
    };

    // Extract values and set defaults if not provided
    const { value: currentPageWidthValue, unit: extractedWidthUnit } = extractValueAndUnit(currentStyle?.style["page-width"] || defaultWidth);
    const { value: currentPageHeightValue, unit: extractedHeightUnit } = extractValueAndUnit(currentStyle?.style["page-height"] || defaultHeight);
    const { value: pageOrientation } = currentStyle?.style["orientation"] || defaultOrientation;

    const { value: pageMarginTopValue, unit: marginTopUnit } = extractValueAndUnit(currentStyle?.style["page-margin-top"] || '0');
    const { value: pageMarginBottomValue, unit: marginBottomUnit } = extractValueAndUnit(currentStyle?.style["page-margin-bottom"] || '0');
    const { value: pageMarginLeftValue, unit: marginLeftUnit } = extractValueAndUnit(currentStyle?.style["page-margin-left"] || '0');
    const { value: pageMarginRightValue, unit: marginRightUnit } = extractValueAndUnit(currentStyle?.style["page-margin-right"] || '0');

    const { value: pagePaddingTopValue, unit: paddingTopUnit } = extractValueAndUnit(currentStyle?.style["page-padding-top"] || '0');
    const { value: pagePaddingBottomValue, unit: paddingBottomUnit } = extractValueAndUnit(currentStyle?.style["page-padding-bottom"] || '0');
    const { value: pagePaddingLeftValue, unit: paddingLeftUnit } = extractValueAndUnit(currentStyle?.style["page-padding-left"] || '0');
    const { value: pagePaddingRightValue, unit: paddingRightUnit } = extractValueAndUnit(currentStyle?.style["page-padding-right"] || '0');

    useEffect(() => {
        console.log("extractedWidthUnit", extractedWidthUnit);
        console.log("extractedHeightUnit", extractedHeightUnit);
    }, [extractedWidthUnit, extractedHeightUnit]);

    // Use 'mm' as the default unit
    let currentWidthUnit = extractedWidthUnit || 'mm';
    let currentHeightUnit = extractedHeightUnit || 'mm';

    useEffect(() => {
        console.log("Page Width", currentPageWidthValue + " " + currentWidthUnit);
        console.log("Page Height", currentPageHeightValue + " " + currentHeightUnit);
        console.log("Page Orientation", pageOrientation);
    }, [currentPageWidthValue, currentPageHeightValue, pageOrientation]);

    const handlePageMargins = () => {
        const marginValue = "0"; // Default margin in mm
        handlePageSettingChange("page-margin-top", marginValue, "");
        handlePageSettingChange("page-margin-bottom", marginValue, "");
        handlePageSettingChange("page-margin-left", marginValue, "");
        handlePageSettingChange("page-margin-right", marginValue, "");
    };

    const handlePagePadding = () => {
        const paddingValue = "0"; // Default padding in mm
        handlePageSettingChange("page-padding-top", paddingValue, "");
        handlePageSettingChange("page-padding-bottom", paddingValue, "");
        handlePageSettingChange("page-padding-left", paddingValue, "");
        handlePageSettingChange("page-padding-right", paddingValue, "");
    };

    const handleOrientationChange = (event) => {
        handlePageSettingChange("orientation", event.target.value, "");
    };

    return (
        <div className="page-setting-container">
            <div className="global-selectbutton">
                <GlobalSelector placeholder="0" value={currentPageWidthValue} unit={currentWidthUnit} name="page-width" id={props.id} onChange={handlePageSettingChange} />
                <GlobalSelector placeholder="0" value={currentPageHeightValue} unit={currentHeightUnit} name="page-height" id={props.id} onChange={handlePageSettingChange} />
            </div>
            <div className="global-selectbutton">
                <label>Orientation</label>
                <select value={pageOrientation} onChange={handleOrientationChange}>
                    <option value="portrait">Portrait</option>
                    <option value="landscape">Landscape</option>
                </select>
            </div>

            <div className="page-margins">
                <p className='general-heading'>Page Margins</p>
                <button className='margin-button-main' onClick={handlePageMargins}>0 mm</button>
                <div className="global-selectbutton">
                    <GlobalSelector placeholder="0" value={pageMarginTopValue} unit={marginTopUnit} name="page-margin-top" id={props.id} onChange={handlePageSettingChange} />
                    <GlobalSelector placeholder="0" value={pageMarginBottomValue} unit={marginBottomUnit} name="page-margin-bottom" id={props.id} onChange={handlePageSettingChange} />
                </div>
                <div className="global-selectbutton">
                    <GlobalSelector placeholder="0" value={pageMarginLeftValue} unit={marginLeftUnit} name="page-margin-left" id={props.id} onChange={handlePageSettingChange} />
                    <GlobalSelector placeholder="0" value={pageMarginRightValue} unit={marginRightUnit} name="page-margin-right" id={props.id} onChange={handlePageSettingChange} />
                </div>
            </div>

            <div className="page-padding">
                <p className='general-heading'>Page Padding</p>
                <button className='padding-button-main' onClick={handlePagePadding}>0 mm</button>
                <div className="global-selectbutton">
                    <GlobalSelector placeholder="0" value={pagePaddingTopValue} unit={paddingTopUnit} name="page-padding-top" id={props.id} onChange={handlePageSettingChange} />
                    <GlobalSelector placeholder="0" value={pagePaddingBottomValue} unit={paddingBottomUnit} name="page-padding-bottom" id={props.id} onChange={props.handlePageSettingChange} />
                </div>
                <div className="global-selectbutton">
                    <GlobalSelector placeholder="0" value={pagePaddingLeftValue} unit={paddingLeftUnit} name="page-padding-left" id={props.id} onChange={handlePageSettingChange} />
                    <GlobalSelector placeholder="0" value={pagePaddingRightValue} unit={paddingRightUnit} name="page-padding-right" id={props.id} onChange={handlePageSettingChange} />
                </div>
            </div>
        </div>
    );
};

export default PageSettingSection;
