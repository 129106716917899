import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const LensItemCreation = {

    insertLensItemCreation: async (PowerRangedata) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation/LensItemCreation`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                //lensPowerArray:processedData,
                lensPowerRang:
                {
                    "itemName": (PowerRangedata.ItemName),
                    "itemId": parseFloat(PowerRangedata.ItemId),
                    "spH_Min": parseFloat(PowerRangedata.SPH_Min),
                    "spH_Max": parseFloat(PowerRangedata.SPH_Max),
                    "spH_Step": parseFloat(PowerRangedata.SPH_Step),
                    "cyL_Min": parseFloat(PowerRangedata.CYL_Min),
                    "cyL_Max": parseFloat(PowerRangedata.CYL_Max),
                    "cyL_Step": parseFloat(PowerRangedata.CYL_Step),
                    "axis": (PowerRangedata.Axis).toString() || "0",
                    "add_Min": parseFloat(PowerRangedata.Add_Min),
                    "add_Max": parseFloat(PowerRangedata.Add_Max),
                    "add_Step": parseFloat(PowerRangedata.Add_Step),
                    "eye": (PowerRangedata.Eye),
                }
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get By Id ****************************
    getLensItemCreationById: async (data) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/LensItemCreation/GetLensPowers`;
                const response = await axios.post(apiUrl,
                    {
                        itemId: data.ItemId,
                        spH_Min: data.SPH_Min,
                        spH_Max: data.SPH_Max,
                        cyL_Min: data.CYL_Min,
                        cyL_Max: data.CYL_Max,
                        axis: data.Axis,
                        add_Min: data.Add_Min,
                        add_Max: data.Add_Max,
                        eye: data.Eye || "RL"
                    },
                    {
                        withCredentials: true,
                    });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    GetLensItemMaster: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
                const response = await axios.post(
                    apiUrl,
                    {
                        "filter": [
                            {
                                "fieldName": "iAttributeParent",
                                "operatorName": "equal",
                                "compareValue": "0"
                            }
                        ],
                        "order": [
                        ],
                        "pageNumber": 1,
                        "pageSize": 50000
                    },
                    {
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                console.log("Response:", response);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteLensItemCreation: async (LensItemId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation?LensItemId=${LensItemId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // *************************** Update ***************************
    updateLensItemCreation: async (data) => {
        const processedData = data.map(loop => ({
            billSeries: loop.billSeries,
            assignToShow: loop.assignToShow,
            accountId: loop.accountId,
            accountTitle: loop.accountTitle,
        }));

        var LensItemId = processedData[0].billSeries;
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation?LensItemId=${LensItemId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get By Id ****************************
    getLensItemCreationByItemId: async (itemId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/LensItemCreation/GetItemPowerByItemId?ItemMasterId=${itemId}`;
                const response = await axios.get(apiUrl, {},
                    {
                        withCredentials: true,
                    });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    UpdateLensItemPower: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/LensItemCreation/UpdateLensItemPower`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                data
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },


};

export default LensItemCreation;
