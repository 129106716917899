import { hasUtf16BOM } from "pdf-lib";
import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ConvertInvoiceAndSynchDataController = {
  // // ************************ Order Challan Data To Convert  **************************************
  SendTransDataToConvert: async (
    ReferenceNumber,
    ConversionTransId,
    FromBranch_Id,
    ToBranch_Id,
    convertType,
    MastType
  ) => {
    debugger;
    try {
      const apiUrl =
        `${WebApiUrl}/api/ConvertInvoiceAndSynchDataController/SendTransDataToConvert` +
        `?ReferenceNumber=${ReferenceNumber}` +
        `&ConversionTransId=${ConversionTransId}` +
        `&FromBranch_Id=${FromBranch_Id}` +
        `&ToBranch_Id=${ToBranch_Id}` +
        `&convertType=${convertType}` +
        `&MastType=${MastType}`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetTransConvertInfoData: async (
    convertType,
    FromBranch_Id,
    ToBranch_Id,
    FromTransId1,
    ToTransId1
  ) => {
    debugger;
    try {
      const apiUrl =
        `${WebApiUrl}/api/ConvertInvoiceAndSynchDataController/SetTransConvertInfoData` +
        `?convertType=${convertType}` +
        `&FromBranch_Id=${FromBranch_Id}` +
        `&ToBranch_Id=${ToBranch_Id}` +
        `&FromTransId1=${FromTransId1}` +
        `&ToTransId1=${ToTransId1}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  UpdateTransConvertInfoData: async (
    convertType,
    FromBranch_Id,
    ToBranch_Id,
    TableId,
    TransId1
  ) => {
    debugger;
    try {
      const apiUrl =
        `${WebApiUrl}/api/ConvertInvoiceAndSynchDataController/UpdateTransConvertInfoData` +
        `?convertType=${convertType}` +
        `&FromBranch_Id=${FromBranch_Id}` +
        `&ToBranch_Id=${ToBranch_Id}` +
        `&TableId=${TableId}` +
        `&TransId1=${TransId1}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
};
export default ConvertInvoiceAndSynchDataController;
