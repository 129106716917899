import React, { useState, useEffect } from "react";

function PopforSelectingLens(props) {
  const {
    lensType,
    // changeLensType,
    closePopUpForLensSelection,
    selectedValue,
    setSelectedValue,
    handleClick,
    selectedPopup,
    setSelectedPopup,
    NameOfSelectedLens,
    FocusPrevInput,
    FocusNextInput,
  } = props;
  // const [lensType, setLensType] = useState(1); // To manage the lens type
  const [loopContent, setLoopContent] = useState([]); // To store loop div elements

  // Function to generate the loop based on lens type
  useEffect(() => {
    generateLoop();
  }, [lensType]);

  const generateLoop = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      // For lensType 1, generate values from -20 to 20, incrementing by 0.25
      for (let i = 0; i <= 20; i += 0.25) {
        // newLoopContent.push(i.toFixed(2));
        newLoopContent.push(
          i === 0 ? i.toFixed(2) : i > 0 ? "+" + i.toFixed(2) : i.toFixed(2)
        );
      }
    } else if (lensType === 2) {
      // For lensType 2, generate values from -10 to 10, incrementing by 0.25
      for (let i = 0; i <= 10; i += 0.25) {
        // newLoopContent.push(i.toFixed(2));
        newLoopContent.push(
          i === 0 ? i.toFixed(2) : i > 0 ? "+" + i.toFixed(2) : i.toFixed(2)
        );
      }
    } else if (lensType === 3) {
      // For lensType 3, generate integer values from 0 to 180, incrementing by 1
      // for (let i = 0; i <= 180; i += 1) {
      //   newLoopContent.push(i.toFixed(0)); // Integer format
      // }

      // First, add the most commonly used values around 90
      const mostCommonAxes = [
        90, 180, 45, 135, 30, 150, 60, 120, 0, 10, 20, 170, 160, 110, 100, 80,
        70, 50, 40, 130,
      ];

      // Add these values to the newLoopContent array
      newLoopContent.push(...mostCommonAxes);

      // Then, add the remaining values incrementing from 0 to 180, excluding the ones we've already added
      for (let i = 0; i <= 180; i++) {
        if (!mostCommonAxes.includes(i)) {
          newLoopContent.push(i);
        }
      }
    } else if (lensType === 4) {
      // For lensType 4, generate values from 0 to 12, incrementing by 0.25
      for (let i = 0; i <= 12; i += 0.25) {
        // newLoopContent.push('+' + i.toFixed(2));
        newLoopContent.push(i === 0 ? i.toFixed(2) : "+" + i.toFixed(2));
      }
    } else if (lensType === 5) {
      // For lensType 5, use predefined array of string values
      const a = [
        "6/6",
        "6/7.5",
        "6/9",
        "6/12",
        "6/18",
        "6/24",
        "6/36",
        "6/60",
        "3/60",
        "1/60",
        "6/6P",
        "6/9P",
        "6/12P",
        "6/18P",
        "6/24P",
        "6/36P",
        "6/60P",
      ];
      // Loop through array and add each element to the newLoopContent
      for (let i = 0; i < a.length; i++) {
        newLoopContent.push(a[i]);
      }
    } else {
      // Default case for unexpected lensType values
      console.error("Invalid lensType value");
    }

    // Set the generated content to the state (assuming this is inside a React component)
    setLoopContent(newLoopContent);
  };

  // Show only negative values in the loop
  const showOnlyNegative = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      // for (let i = -20; i < 0; i += 0.25) {
      //   newLoopContent.push(i.toFixed(2));
      // }
      for (let i = 0; i >= -20; i -= 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else if (lensType === 2) {
      for (let i = 0; i >= -10; i -= 0.25) {
        newLoopContent.push(i.toFixed(2));
      }
    } else {
      newLoopContent = ["No negative values for this lens type!"];
    }

    setLoopContent(newLoopContent);
  };

  // Show only positive values in the loop
  const showOnlyPositive = () => {
    let newLoopContent = [];

    if (lensType === 1) {
      for (let i = 0; i <= 20; i += 0.25) {
        newLoopContent.push(i === 0 ? i.toFixed(2) : "+" + i.toFixed(2));
      }
    } else if (lensType === 2) {
      for (let i = 0; i <= 10; i += 0.25) {
        newLoopContent.push(i === 0 ? i.toFixed(2) : "+" + i.toFixed(2));
      }
    } else {
      newLoopContent = ["Only positive values for this lens type!"];
    }

    setLoopContent(newLoopContent);
  };

  return (
    <>
      <div
        className="popup-back"
        onClick={() => closePopUpForLensSelection()}
      ></div>
      <div onClick={() => closePopUpForLensSelection()} class="xmark-ms-2">
        <i class="fa-solid fa-xmark"></i>
      </div>
      <div className="PopforSelectingLens">
        <div className="PopforSelectingLens-top">
          <button className="salePrevNextBtn" onClick={FocusPrevInput}>
            <i class="fa-solid fa-angle-left"></i>
          </button>
          <h1 className="PopforSelectingLens_heading">{NameOfSelectedLens}</h1>
          <button className="salePrevNextBtn" onClick={FocusNextInput}>
            <i class="fa-solid fa-angle-right"></i>
          </button>
        </div>
        <div className="PopforSelectingLens_Loop" id="loopContainer">
          {lensType == 0 ? (
            <button
              className="PopforSelectingLens_nxt-button"
              onClick={() => handleClick()}
            >
              Next
            </button>
          ) : (
            loopContent.map((value, index) => (
              <div
                key={index}
                className="PopforSelectingLens_Loop_div"
                onClick={() => handleClick(value, lensType)}
              >
                {value}
              </div>
            ))
          )}
        </div>

        {(lensType == 1 || lensType == 2) && (
          <div
            id="PopforSelectingLens_Buttons"
            className="PopforSelectingLens_Buttons"
          >
            <button onClick={showOnlyNegative}>-</button>
            <button>0.00</button>
            <button onClick={showOnlyPositive}>+</button>
          </div>
        )}

        {selectedPopup && (
          <div id="customAlert">You selected {selectedValue}</div>
        )}
      </div>
    </>
  );
}

export default PopforSelectingLens;
