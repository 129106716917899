import React, { useEffect, useRef, useState, Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Mainpage from "../AppRoutes";
import SmallSideBar from "./Components/SmallSideBar";
import "../css/new.css";
import SideBarNew from "./SideNavBar/SideBarNew";
import LogInPanel from "./LogInPage/LogInPanel";
import MyContext from "./MyContext";
import User from "../API/APIServices/User";
import Company from "../API/APIServices/Company";
import UserCredential from "../API/APIServices/UserCredential";
import Authentication from "../API/APIServices/Authentication";
import AlertBoxError from "./Props/AlertBoxError";
import SmsTemplate from "../API/APIServices/SmsTemplate";
import SMS from "../API/APIServices/SMS";
import jsPDF from "jspdf";
import pako from "pako";
import Compress from 'compress.js';
import html2canvas from 'html2canvas';
import { jsPDF as jsPDFInstance } from 'jspdf'; // Import jsPDF instance for compression
import { PDFDocument } from 'pdf-lib';
import html2pdf from 'html2pdf.js';
import OrderTakerBillPrint from "../Restaurant/OrderTaker/OrderTakerBillPrint";
import axios from "axios";
import BusinessTypeApi from "../API/APIServices/BusinessType"
import ReportDesignForMessagePDF from "../Restaurant/Orders/ReportDesignForMessagePDF";
import ReportDesignForMessagePDFA4 from "../Restaurant/Orders/ReportDesignForMessagePdfA4";
import InternetConnection from "./InternetConnection";
import Loader from "./Props/Loader";
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function HomePage() {

  const isOnline = InternetConnection();

  // ?????????????????????????????????????? web template ??????????????????????????????????????????????????????????
  const [droppedItems, setDroppedItems] = useState([]);
  const [TemplateData, setTemplateData] = useState([]);
  const [childComponentValue, setChildComponentValue] = useState([]);

  const [clickedItemId, setClickedItemId] = useState("");
  const [clickedItemName, setClickedItemName] = useState();

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("isAuthenticated") === "true";
  });

  useEffect(() => {
    localStorage.setItem("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  // useEffect(() => {
  //   console.log("TemplateData", TemplateData);
  //   console.log("droppedItems", droppedItems);
  // }, [droppedItems, TemplateData,])

  // useEffect(() => {
  //   console.log("clickedItemId", clickedItemId);
  // }, [clickedItemId]);

  // {/* *******************Tab****************** */}
  const [leftContainerWidth, setLeftContainerWidth] = useState(55);
  const [alertBoxEdit, setAlertBoxEdit] = useState(false);
  const [alertBox, setAlertBox] = useState(false);
  const [alertBoxForSalePage, setAlertBoxForSalePage] = useState(false);

  const [alertBoxDelete, setAlertBoxDelete] = useState(false);
  const [alertBoxErrorInsert, setAlertBoxErrorInsert] = useState(false);
  const [alertBoxErrorUpdate, setAlertBoxErrorUpdate] = useState(false);
  const [openAlertItemExists, setOpenAlertItemExists] = useState(false);
  const [alertBoxCantDelete, setAlertBoxCantDelete] = useState(false)
  const [BusinessType, setBusinessType] = useState();
  const [UserDetails, setUserDetails] = useState([])
  const [stylesArray, setStylesArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("stylesArray", stylesArray);
  }, [stylesArray]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await BusinessTypeApi.getBusinessType();
        setBusinessType(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (isAuthenticated) {
      fetchData();
    }
  }, [isAuthenticated]);

  const updateStyle = (id, styleKey, styleValue) => {
    const templateId = TemplateData.id;
    setStylesArray((prevArray) => {
      const index = prevArray.findIndex((item) => item.id === id);
      const newStyle = { [styleKey]: styleValue };

      if (index !== -1) {
        // Update existing style object
        const clearedStyles = prevArray.filter(styleObj => styleObj.templateId !== templateId);
        // Update the existing style object
        // const updatedStyles = [...prevStyles];
        const updatedArray = clearedStyles;
        // const updatedArray = [...prevArray];
        updatedArray[index] = {
          ...updatedArray[index],
          style: {
            ...updatedArray[index].style,
            ...newStyle
          }
        };
        return updatedArray;
      } else {
        const clearedStyles = prevArray.filter(styleObj => styleObj.templateId !== templateId);

        return [
          ...clearedStyles,
          {
            id,
            style: newStyle,
            content: "", // You can set or update this field as needed
            link: "",
          }
        ];
      }
    });
  };

  const [isShowTotalAmountInReport, setShowTotalAmountInReport] = useState(false);
  const [isAllowModificationOfVoucher, setAllowModificationOfVoucher] = useState(false);
  const [isAllowExportExcel, setIsAllowExportExcel] = useState(false);

  const fetchCompanyData = async () => {
    const storedArrayString = localStorage.getItem('loginAccountUserData');
    var loginUser;
    if (storedArrayString) {
      loginUser = JSON.parse(storedArrayString);
    }
    if (loginUser) {
      const data = await Company.GetCompanyById(loginUser.compId)
      setAppToWebLink(data.urL1)
      setWebToAppLink(data.urL2)
      setAutoTransferToKitchen(data.isEnblAutoTransferToKitchen)
      setCompanyData(data);

      if (data) {
        const userData = await UserCredential.getUserCredentialById(loginUser.userId);
        console.log("dataaaa", userData)
        if (userData) {
          setUserDetails(userData)
          console.log("userDataa", userData?.isTotalAmtInRpt)
          setShowTotalAmountInReport(userData?.isTotalAmtInRpt);
        }
        setAllowModificationOfVoucher(data?.canModifyVoucher);
        setIsAllowExportExcel(data?.canExportReport);
      }
      return data
    }
  }

  useEffect(() => {
    // setTimeout(() => {
    fetchCompanyData();
    // }, 5000);
  }, [])  //stylesArray

  const handleContentChange = (content, id, link) => {
    const templateId = TemplateData.id;
    setStylesArray((prevStyles) => {
      const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

      if (existingStyleIndex !== -1) {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        const updatedStyles = clearedStyles;
        updatedStyles[existingStyleIndex] = {
          ...updatedStyles[existingStyleIndex],
          content: content,
          link: link != undefined ? link : "",
        };
        return updatedStyles;
      } else {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);

        if (link !== undefined) {
          return [
            ...clearedStyles,
            {
              id,
              style: {},
              content: content,
              link: link,
            },
          ];
        } else {
          return [
            // ...prevStyles,
            ...clearedStyles,
            {
              id,
              style: {},
              content: content,
              link: "",
            },
          ];
        }
      }
    });
  };

  const handleStyleChange = (style, value, unit, id) => {
    const templateId = TemplateData.id
    if (style === "border") {
      const newValue = value
      setStylesArray((prevStyles) => {
        const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

        if (existingStyleIndex !== -1) {
          const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
          // Update the existing style object
          // const updatedStyles = [...prevStyles];
          const updatedStyles = clearedStyles;
          updatedStyles[existingStyleIndex] = {
            ...updatedStyles[existingStyleIndex],
            style: {
              ...updatedStyles[existingStyleIndex].style,
              [style]: newValue,
            },
          };
          return updatedStyles;
        } else {
          const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
          return [
            ...clearedStyles,
            {
              id,
              style: { [style]: newValue },
              content: "",
              link: "",
            },
          ];
        }
      });
      return;
    }

    const valueWithUnit = unit === null ? `${value}` : `${value}${unit}`;
    setStylesArray((prevStyles) => {
      const existingStyleIndex = prevStyles.findIndex(styleObj => styleObj.id === id);

      if (existingStyleIndex !== -1) {
        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        // Update the existing style object
        // const updatedStyles = [...prevStyles];
        const updatedStyles = clearedStyles;
        updatedStyles[existingStyleIndex] = {
          ...updatedStyles[existingStyleIndex],
          style: {
            ...updatedStyles[existingStyleIndex].style,
            [style]: valueWithUnit,
          },
        };

        return updatedStyles;
      } else {

        const clearedStyles = prevStyles.filter(styleObj => styleObj.templateId !== templateId);
        return [
          ...clearedStyles,
          {
            id,
            style: { [style]: valueWithUnit },
            content: "",
            link: "",
          },
        ];
      }
    });
  };

  useEffect(() => {
    const styleObject = stylesArray.find((item) => item.id === clickedItemId);

    if (styleObject && styleObject.id === clickedItemId) {
      const element = document.getElementById(clickedItemId);
      if (element) {

        Object.entries(styleObject.style).forEach(([key, value]) => {
          element.style[key] = value;
        });
      }
    }
  }, [stylesArray, clickedItemId]);

  useEffect(() => {
    // debugger;
    console.log(stylesArray);
    for (let index = 0; index < stylesArray.length; index++) {
      const styleObject = stylesArray[index];
      // console.log(styleObject.id);

      // Find the element with the matching ID
      const element = document.getElementById(styleObject.id);

      if (element) {
        // Apply each style property to the element
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [stylesArray]);


  const ensurePageSettingsAtIndexZero = () => {

    if (TemplateData && TemplateData !== undefined) {
      const templateId = TemplateData.id;
      setStylesArray((prevStyles) => {
        let pageSettings = null;
        const otherStyles = [];

        // Separate page settings (if exists) and other styles
        prevStyles.forEach((styleObj) => {
          if (styleObj.id && styleObj.id.startsWith(`page-settings-${templateId}`)) {
            pageSettings = styleObj;
          } else {
            otherStyles.push(styleObj);
          }
        });

        // If no page settings exist, create a default one
        if (!pageSettings) {
          pageSettings = {
            id: `page-settings-${templateId}`, // Default ID
            style: {}, // Default styles
            content: "",
            link: "",
          };
        }

        // Return a new array with page settings at index 0 and other styles following
        return [pageSettings, ...otherStyles];
      });
    }
  };

  useEffect(() => {
    ensurePageSettingsAtIndexZero();
  }, [TemplateData]);

  // useEffect(() => {
  //   console.log(BusinessType);
  // }, [BusinessType]);

  // useEffect(() => {
  //   console.log("UserDetails", UserDetails);
  // }, [UserDetails])

  function debounce(func, delay, immediate = false) {
    let timer;
    const debounced = function (...args) {
      const context = this;
      const callNow = immediate && !timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        if (!immediate) func.apply(context, args);
      }, delay);
      if (callNow) func.apply(context, args);
    };

    debounced.cancel = function () {
      clearTimeout(timer);
      timer = null;
    };

    return debounced;
  }

  const [loginuser, setLoginUser] = useState(true);

  useEffect(() => {
    const url = (window.location.href).toLowerCase();
    const val = url.split("/")
    //alert(val[3])
    if (val[3] == "LoginPanel" || val[3] == "loginpanel" || val[3] == "loginmaster")
      setLoginUser(false);
    else
      setLoginUser(true);
    const storedArrayString = localStorage.getItem('loginAccountUserData');
    console.log(storedArrayString);
    if (storedArrayString == null || storedArrayString == undefined) {
      setLoginUser(false);
    }
  }, [])

  const handleToggle = () => {
    if (leftContainerWidth === 55) {
      setLeftContainerWidth(260);
    } else {
      setLeftContainerWidth(55);
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);
  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const sidebarClassName =
    openSidebar === true ? "small-sidebar" : "small-sidebar-hidden";
  const sidebarIconchnage =
    openSidebar === true ? "rotate-neg180icon" : "rotate-180icon";

  const [loginSuccess, setLoginSuccess] = useState(true);

  const logindone = async () => {
    const data = await Company.getLoginValidation();
    //alert(loginSuccess)
    setLoginSuccess(data);
  };

  useEffect(() => {
    //logindone();
  }, [loginSuccess])

  const [isEditing, setIsEditing] = useState(false);

  const OpenAlertBox = () => {
    setAlertBox(!alertBox);
  };

  const OpenAlertBoxForSalePage = () => {
    setAlertBoxForSalePage(!alertBoxForSalePage);
  };

  const OpenAlertBoxDelete = () => {
    setAlertBoxDelete(!alertBoxDelete);
  };

  const OpenAlertBoxEdit = () => {
    setAlertBoxEdit(!alertBoxEdit);
  };
  const OpenAlertBoxErrorInsert = () => {
    setAlertBoxErrorInsert(!alertBoxErrorInsert);
  };

  const OpenAlertBoxErrorUpdate = () => {
    setAlertBoxErrorUpdate(!alertBoxErrorUpdate);
  };

  const OpenAlertBoxWhenItemExists = () => {
    setOpenAlertItemExists(!openAlertItemExists)
  }

  const OpenAlertBoxDeleteCant = () => {
    setAlertBoxCantDelete(false)
  }

  const imageBaseUrl = `${WebApiUrl}/api/ItemMaster/GetImage?D1ItemId=`;

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const input6 = useRef();
  const input7 = useRef();

  const handleKeyPress = (event) => {
    if (event.altKey && event.key === "1") {
      if (input1.current) {
        input1.current.focus();
      }
    }
    if (event.altKey && event.key === "2") {
      if (input2.current) {
        input2.current.focus();
      }
    }
    if (event.altKey && event.key === "3") {
      if (input3.current) {
        input3.current.focus();
      }
    }
    if (event.altKey && event.key === "4") {
      if (input4.current) {
        input4.current.focus();
      }
    }
    if (event.altKey && event.key === "5") {
      if (input5.current) {
        input5.current.focus();
      }
    }
    if (event.altKey && event.key === "6") {
      if (input6.current) {
        input6.current.focus();
      }
    }
    if (event.altKey && event.key === "7") {
      if (input7.current) {
        input7.current.focus();
      }
    }
  };

  const maskMobileNo = (number) => {
    if (number) {
      if (number.length <= 3) return number;
      return number.slice(0, -3) + 'XXX';
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [routeLink, setRouteLink] = useState(location.search);

  // Effect to update routeLink based on location.search changes
  useEffect(() => {
    setRouteLink(location.search);
  }, [location.search]);

  // useEffect(() => {
  //   console.log("formAuthentication");
  //   const formAuthentication = async () => {
  //     try {
  //       const url = window.location.pathname;
  //       // const formName = (url.substring(url.lastIndexOf('/') + 1)).toLowerCase();
  //       // const formName = url.substring(0, url.lastIndexOf('/')).toLowerCase();
  //       const formName = url.split('/').slice(0, 2).join('/').toLowerCase();

  //       if (formName != "previousorder" && formName != "loginpanel" && formName != "addupdateformname" &&
  //         formName != "createbusiness" && formName != "loginmaster" && formName != "welcome") {
  //         const storedArrayString = localStorage.getItem('loginAccountUserData');
  //         var loginUser;
  //         if (storedArrayString) {
  //           loginUser = JSON.parse(storedArrayString);
  //         }
  //         const filterData = [{
  //           fieldName: "formName",
  //           operatorName: "equal",
  //           compareValue: formName,
  //         },
  //         {
  //           fieldName: "UserId",
  //           operatorName: "equal",
  //           compareValue: loginUser.userId
  //         }
  //         ];

  //         const formData = await Authentication.getAuthenticationWithFilter(filterData);
  //         const length = formData.result ? formData.result.length : 0;
  //         console.log("formData", formData)

  //         if (length > 0) {
  //           //navigate(`/${formName}`);
  //         } else {
  //           OpenAlertBoxErrorInsert();
  //           navigate("/Welcome");
  //         }
  //       }
  //     } catch (error) {
  //       navigate("/Welcome");
  //     }
  //   }
  //   if (routeLink) {
  //     formAuthentication();
  //   }
  // }, [routeLink, navigate]);

  // ****************************** new *********************************

  useEffect(() => {
    const formAuthentication = async () => {
      const storedArrayString = localStorage.getItem('loginAccountUserData');
      let loginUser;
      if (storedArrayString) {
        loginUser = JSON.parse(storedArrayString);
      }
      try {
        const url = window.location.pathname;
        const urlParts = url.split('/').filter(Boolean);

        const lastPart = urlParts[urlParts.length - 1];
        const formName = isNaN(lastPart) ? urlParts.join('/').toLowerCase() : urlParts.slice(0, -1).join('/').toLowerCase();

        // if (loginUser == null || loginUser == undefined || loginUser.userId == 0 || loginUser.userId == "") {
        //   if (formName !== "loginpanel" && formName !== "loginmaster") {
        //     await User.Logout();
        //     return false;
        //   }
        // }
        if (formName !== "previousorder" && formName !== "loginpanel" && formName !== "addupdateformname" &&
          formName !== "createbusiness" && formName !== "loginmaster" && formName !== "welcome" && formName !== "response" &&
          formName !== "createaccount" && formName !== "logincommondeploymentpanel") {

          const filterData = [
            {
              fieldName: "formName",
              operatorName: "equal",
              compareValue: formName,
            },
            {
              fieldName: "UserId",
              operatorName: "equal",
              compareValue: loginUser.userId
            }
          ];

          let isCheck = true
          if (formName == 'posinvoice') {
            if (window.innerWidth < 500) {
              isCheck = false;
            } else {
              isCheck = true;
            }
          }
          if (isCheck) {
            const formData = await Authentication.getAuthenticationWithFilter(filterData);
            const length = formData.result ? formData.result.length : 0;
            console.log("formData", formData);
            if (length > 0) {
              // navigate(`/${formName}`);
            } else {
              OpenAlertBoxErrorInsert();
              if (loginUser == null || loginUser == undefined || loginUser.userId == 0 || loginUser.userId == "") {
                await User.Logout();
                // navigate("/LoginPanel");
              }
              else {
                //navigate("/Welcome");
                window.history.back();
              }
            }
          }
        }
      } catch (error) {
        if (loginUser == null || loginUser == undefined || loginUser.userId == 0 || loginUser.userId == "") {
          // navigate("/LoginPanel");
          await User.Logout();
        }
        else {
          navigate("/Welcome");
        }
        // navigate("/Welcome");
      }
    }

    if (routeLink) {
      formAuthentication();
    }
  }, [routeLink, navigate]);

  useEffect(() => {
    if (routeLink === "") {
      setRouteLink("/Welcome");
    }
  }, [routeLink]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const [appToWebLink, setAppToWebLink] = useState("");
  const [webToAppLink, setWebToAppLink] = useState("");
  const [isAutoTransferToKitchen, setAutoTransferToKitchen] = useState(false);
  const [companyData, setCompanyData] = useState();

  const pdfRef = useRef();
  const [billPdf, setBillPdf] = useState(false);

  const [blob, setBlob] = useState("");
  const [smsData, setSmsData] = useState()
  const [mobileNo, setMobileData] = useState()
  const [orderId, setOrderId] = useState();

  const [billPrint, setBillPrint] = useState(false);

  const closeBillPrint = () => {
    setBillPrint(false);
    setLoading(false);
  }

  const messageFunction = async (smsType, OrderId, AccId, mobileNo, smsTypeId) => {
    // ******************** SMS/MSG **********************************
    setLoading(true)
    setMobileData(mobileNo);
    setOrderId(OrderId);

    let smsData;
    try {
      smsData = await SmsTemplate.GetAssignTemplatesByName(
        smsType,
        OrderId,
        AccId,
        smsTypeId
      );
      // setSmsData(smsData);
      const sendDataSms = {
        to: mobileNo,
        message: smsData[0]?.templateForFormDetails,
        smsTempAttr: smsData[0]?.templateForSMSAPI,
        smsTempVal: smsData[0]?.templateId,
      }
      const sendSMS = await SMS.SendSMS(sendDataSms);
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
    // ******************** WhatsApp MSG **********************************
    try {
      let mediaId = "";

      if (smsData[0]?.messageHeaderSection_ != "" && smsData[0]?.messageHeaderSection_.includes("WhatsappInvPdfUpload")) {
        setOrderId(OrderId);
        const sendDataWhatsApp = {
          to: mobileNo,
          templateName: smsData[0]?.templateForWhatsappAPI,
          templateLanguage: smsData[0]?.whatsappLanguage,
          headerSection: smsData[0]?.messageHeaderSection_,
          bodySection: smsData[0]?.messageBodySection_,
          fileMediaId: 0
        }
        setSmsData(sendDataWhatsApp);
        setBillPrint(true);
        setTimeout(() => {
          setBillPrint(false);
        }, 10000)
        setLoading(false)
        return true;
      }
      const sendDataWhatsApp = {
        to: mobileNo,
        templateName: smsData[0]?.templateForWhatsappAPI,
        templateLanguage: smsData[0]?.whatsappLanguage,
        headerSection: smsData[0]?.messageHeaderSection_,
        bodySection: smsData[0]?.messageBodySection_,
        fileMediaId: mediaId
      }
      const sendWhatsApp = await SMS.SendWhatsApp(sendDataWhatsApp)
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      setLoading(false)
    }
    // ****************************************************************
  }
  const [tableCellData, setTableCellData] = useState(() => {
    // Retrieve the number of columns and rows from local storage or use default values
    const storedColumns = localStorage.getItem("columns_rd");
    const initialColumns = storedColumns && !isNaN(parseInt(storedColumns)) ? parseInt(storedColumns) : 1;

    const storedRows = localStorage.getItem("rows_rd");
    const initialRows = storedRows && !isNaN(parseInt(storedRows)) ? parseInt(storedRows) : 1;

    // Initialize the data structure safely
    return {
      headerData: Array(initialColumns).fill(""),  // For the header
      footerData: Array(initialColumns).fill(""),  // For the footer
      detailData: Array.from({ length: initialRows }, () => Array(initialColumns).fill("")) // 2D array for body
    };
  });

  function convertDateTimeToDateAndTime(dateTime) {
    if (dateTime) {
      const date = new Date(dateTime);
      // Adjust the time by adding the UTC +5:30 offset
      const utcPlus530 = new Date(date.getTime() + (5.5 * 60 * 60 * 1000));

      // Format the new date to the desired output format (DD-MM-YYYY hh:mm:ss AM/PM)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      };
      const formattedDate = utcPlus530.toLocaleString('en-IN', options);
      return formattedDate;
    } else {
      return "";
    }

    // const date = new Date(dateTime);

    // // Convert the date to IST (UTC +5:30)
    // const options = {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   hour12: true,
    //   timeZone: 'Asia/Kolkata' // IST time zone
    // };

    // // Use Intl.DateTimeFormat to format the date as needed in IST
    // const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

    // debugger;
    // // To match 'DD-MM-YYYY hh:mm A' format, modify the result if needed
    // const [day, month, year] = formattedDate.split('/');
    // const [hour, minute] = date.toLocaleTimeString('en-GB', { hour12: true, timeZone: 'Asia/Kolkata' }).split(':');
    // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    // return `${day}-${month}-${year} ${hour}:${minute} ${ampm}`;
  }

  return (
    <>
      <div>
        {!isOnline && (
          <div style={{
            backgroundColor: "red",
            color: "white",
            padding: "10px",
            position: "fixed",
            top: 0,
            width: "100%",
            textAlign: "center",
            zIndex: 1000
          }}>
            No Internet Connection
          </div>
        )}
        {/* <div style={{ marginTop: "50px" }}>
          <p>{isOnline ? "You are online!" : "You are offline!"}</p>
        </div> */}
      </div>
      {isOnline && (
        <MyContext.Provider
          value={{
            alertBox,
            OpenAlertBox,
            OpenAlertBoxDelete,
            alertBoxDelete,
            setAlertBoxDelete,
            OpenAlertBoxEdit,
            alertBoxEdit,
            alertBoxErrorInsert,
            setAlertBoxErrorInsert,
            alertBoxErrorUpdate,
            setAlertBoxErrorUpdate,
            OpenAlertBoxErrorInsert,
            OpenAlertBoxErrorUpdate,
            OpenAlertBoxWhenItemExists,
            openAlertItemExists,
            setOpenAlertItemExists,
            input1,
            input2,
            input3,
            input4,
            input5,
            input6,
            input7,
            BusinessType,
            setBusinessType,
            setLoginSuccess,
            UserDetails,
            setUserDetails,
            imageBaseUrl,
            TemplateData,
            setTemplateData,
            droppedItems,
            setDroppedItems,
            setClickedItemId,
            clickedItemId,
            clickedItemName,
            childComponentValue,
            setChildComponentValue,
            setClickedItemName,
            setStylesArray,
            handleStyleChange,
            updateStyle,
            stylesArray,
            handleContentChange,
            setLoginUser,
            isShowTotalAmountInReport,
            appToWebLink,
            webToAppLink,
            isAllowModificationOfVoucher,
            isAutoTransferToKitchen,
            messageFunction,
            setAlertBoxCantDelete,
            alertBoxCantDelete,
            OpenAlertBoxDeleteCant,
            logindone,
            setAppToWebLink,
            setWebToAppLink,
            companyData,
            maskMobileNo,
            setAppToWebLink,
            setWebToAppLink,
            setAutoTransferToKitchen,
            setCompanyData,
            tableCellData,
            setTableCellData,
            fetchCompanyData,
            isEditing,
            setIsEditing,
            isAllowExportExcel,
            fetchCompanyData,
            debounce,
            convertDateTimeToDateAndTime,
            alertBoxForSalePage,
            OpenAlertBoxForSalePage,
            isAuthenticated,
            setIsAuthenticated,
          }}
        >
          {loginSuccess ? (
            <div ref={pdfRef} id="pdf-content">
              <>
                <div className="app-container">
                  <div className="left-container">
                    {/* <SideBar class_name={`left-container ${leftContainerWidth === 230 ? 'expanded' : ''}`}
                      width={leftContainerWidth}
                      onMouseEnter1={handleLeftContainerHover}
                      onMouseLeave1={handleLeftContainerLeave}
                      handleToggle={handleToggle} /> */}
                    {loginuser == true ? (
                      <SideBarNew
                        class_name={`left-container ${leftContainerWidth === 260 ? "expanded" : ""
                          }`}
                        width={leftContainerWidth}
                        handleToggle={handleToggle}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="right-container">
                    <div className="mastercomapny-container">
                      <Mainpage />
                    </div>
                  </div>

                  {loginuser == true ? (
                    <div className={sidebarClassName}>
                      {openSidebar && <SmallSideBar />}
                      <div className="inout-smallbar" onClick={toggleSidebar}>
                        <i
                          className={`fa-solid fa-chevron-left ${sidebarIconchnage}`}
                          style={{ color: "black" }}
                        ></i>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            </div>
          ) : (
            <div >
              {!loginSuccess && (
                <div style={{ display: loginSuccess === null ? "none" : "block" }}>
                  <LogInPanel logindone={logindone} setLoginUser={setLoginUser} loginuser={loginuser} />
                </div>
              )}
            </div>
          )}
          <div style={{ display: "none" }}>{/**/}
            {billPdf && (
              <OrderTakerBillPrint id={orderId} mobileNo={mobileNo} isSendMSG={true} setBlob={setBlob} smsData={smsData} setBillPdf={setBillPdf} />
            )}
          </div>

          {/******************************* Bill Modal *************************/}
          {
            billPrint && (
              <div style={{ display: "none" }}> {/**/}
                <div className="modal-back" onClick={closeBillPrint}>
                  <div className="billPrintModal" style={{}}>
                    <div style={{ padding: "5px", backgroundColor: "white", display: "none" }}>
                      <ReportDesignForMessagePDF templateName="FinalBillPrint" ord1Id={orderId} setShowBillPrint={setBillPrint} smsData={smsData} setLoading={setLoading} /> {/*TestReport */}
                      {/*<OrderTakerBillPrint id={orderListData && orderListData[0]?.ord1Id} isOnloadPrint={true} closePrintModal={closeBillPrint} backBtn={"modal"} />*/}
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {alertBoxErrorInsert && (
            <AlertBoxError
              HeadingTop="Warning"
              Heading="YOU DO NOT HAVE PERMISSION TO ACCESS THIS PAGE."
              onYesClick={OpenAlertBoxErrorInsert}
              singleButton={true}
              OpenAlertBox={OpenAlertBoxErrorInsert}
            />
          )}
          {loading && <Loader />}
        </MyContext.Provider>
      )}
    </>
  );
}

export default HomePage;
