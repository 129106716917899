import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import PopforSelectingLens from "./Dropdown/PopforSelectingLens.jsx";
import Transaction from "../../API/APIServices/Transaction.js";
import AccountAPI from "../../API/APIServices/Account";
import ItemMaster from "../../API/APIServices/ItemMaster.js";

/************Lens Info box (shivendra 28062024)****************/

function LensInfoForInvoice(props) {
  const {
    inputValues,
    activeTab1,
    setActiveTab1,
    handleTabClick1,
    mainPageInputRef,
    lensPageInputRef,
    ReferenceNumber,
    // filteredAccount,
    suggestionBoxRef,
    openAccountMasterModal,
    highlightedIndex,
    setHighlightedIndex,
    itemRefs,
    itemMasterRows,
    nextCellRefs,
    InputValueAccMaster,
    imageBaseUrl,
    confirmation,
    lensinforef,
    onDelivDayChange,
    nextInput,
    inputRefs,
  } = props;

  useImperativeHandle(nextInput, () => ({
    closePopUpForLensSelection,
  }));

  const [imageName, setImageName] = useState(""); // State to hold the image name

  const [isDropdownOpen1, setIsDropdownOpen1] = useState();
  const [isDropdownOpen2, setIsDropdownOpen2] = useState();
  const [isDropdownOpen3, setIsDropdownOpen3] = useState();
  const [isDropdownOpen4, setIsDropdownOpen4] = useState();
  const [selectedImageId, setSelectedImageId] = useState(0);
  useEffect(() => {
    // console.log(isDropdownOpen2);
  }, [isDropdownOpen2]);
  const [selectedValue, setSelectedValue] = useState(0); // To store selected value for custom alert
  const [selectedPopup, setSelectedPopup] = useState(false);
  const [nextIndexSaved, setNextIndexSaved] = useState(3);
  const [FocusedName, setFocusedName] = useState("");
  const [lensInfoInList, setLensInfoInList] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [PopUpSelectingLens, setPopUpSelectingLens] = useState(false);

  const [LensTypeNumber, setLensTypeNumber] = useState(1);
  const [NameOfSelectedLens, setNameOfSelectedLens] =
    useState("Distance-SPH-Power");

  let TransType = inputValues.TransType;
  let TransName = inputValues.TransName;
  /********************Tab*******************/
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);
  const [tabcontent4, setTabContent4] = useState(false);
  const [tabcontent5, setTabContent5] = useState(false);
  const [tabcontent6, setTabContent6] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs3 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(true);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(false);

    handleTabIndexChanged();
  };
  const showtabs4 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(true);
    setTabContent5(false);
    setTabContent6(false);
  };
  const showtabs5 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(true);
    setTabContent6(false);
  };
  const showtabs6 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
    setTabContent6(true);
  };
  const [inputPosition, setInputPosition] = useState({
    top: 0,
    left: 0,
    width: 80,
  });
  const getPosition = (index) => {
    const inputRef = inputRefs?.current[index];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width,
      });
    }
  };
  //*****************************Lens Formula************************************** */

  const handleLAddRLeave = () => {
    debugger
    try {
      let value = parseFloat(regularLens.ADD_R);

      if ((value < 0 || value > 15) && value != "") {
        alert("Please enter a value between 0 and 15.");
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_R: "",
        }));
        return;
      }

      if (!isNaN(value) && value % 0.25 !== 0 && value != "") {
        alert(
          "Please enter a value that is a multiple of 0.25 (e.g., 0.25, 0.5, 0.75, 1)."
        );
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_R: "",
        }));
        return;
      }
      if(isNaN(value)){
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_R: "",
        }));
        return
      }

      const formattedValue = value === 0 ? "0.00" : `+${value.toFixed(2)}`;

      setRegularLens((prevState) => ({
        ...prevState,
        ADD_R: formattedValue,
        ADD_L: formattedValue,
      }));

      // Simulate get_Barcode_fromItem(1);
      getBarcodeFromItem(1);
    } catch (err) {
      console.error("Error in handleLAddRLeave:", err.message);
    }

    // Update SPH N.V. value
    try {
      let sphDv = regularLens.SPH_DV_R;
      let addValue = parseFloat(regularLens.ADD_R);

      sphDv = sphDv && sphDv.trim() !== "" ? parseFloat(sphDv) : 0;

      if (!isNaN(sphDv) && !isNaN(addValue)) {
        const nvValue = (sphDv + addValue).toFixed(2);
        setRegularLens((prevState) => ({
          ...prevState,
          SPH_NV_R: nvValue >= 0 ? `+${nvValue}` : nvValue,
        }));
      }

      // Copy other values if ADD_R is non-zero
      if (Math.abs(Number(regularLens.ADD_R)) !== 0) {
        setRegularLens((prevState) => ({
          ...prevState,
          CYL_NV_R: prevState.CYL_DV_R,
          AXS_NV_R: prevState.AXS_DV_R,
        }));
      }
    } catch (err) {
      console.error("Error in updating SPH N.V. value:", err.message);
    }

    // Ensure default values are set if empty
    setRegularLens((prevState) => ({
      ...prevState,
      SPH_DV_R: prevState.SPH_DV_R || "",
      CYL_DV_R: prevState.CYL_DV_R || "",
      AXS_DV_R: prevState.AXS_DV_R || "",
      SPH_NV_R: prevState.SPH_NV_R || "",
      CYL_NV_R: prevState.CYL_NV_R || "",
      AXS_NV_R: prevState.AXS_NV_R || "",
    }));
  };

  const [lensData, setLensData] = useState([]);
  useEffect(() => {
    debugger;
    if (itemMasterRows && itemMasterRows.length > 0) {
      const LensData = itemMasterRows.filter(
        (row) => row.productCategory == "1" || row.productCategory == "2"
      );
      setLensData(LensData);
      // console.log("LensData", LensData);
    }
  }, [itemMasterRows]);

  const handleLAddLLeave = () => {
    try {
      let value = parseFloat(regularLens.ADD_L);

      if ((value < 0 || value > 15) && value != "") {
        alert("Please enter a value between 0 and 15.");
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_L: "",
        }));
        return;
      }

      if (!isNaN(value) && value % 0.25 !== 0 && value != "") {
        alert(
          "Please enter a value that is a multiple of 0.25 (e.g., 0.25, 0.5, 0.75, 1)."
        );
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_L: "",
        }));
        return;
      }
      if(isNaN(value)){
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_L: "",
        }));
        return
      }

      // Check if the value is a multiple of 0.25 and is not empty
      if (value % 0.25 === 0 && regularLens.ADD_L !== "") {
        if (value < 0) {
          setRegularLens((prevState) => ({
            ...prevState,
            ADD_L: "",
          }));
          alert("-Ve value not allowed");
        } else {
          const formattedValue =
            value === 0
              ? "0.00"
              : value > 0
              ? `+${Math.abs(value).toFixed(2)}`
              : Math.abs(value).toFixed(2);
          setRegularLens((prevState) => ({
            ...prevState,
            ADD_L: formattedValue,
            ADD_R: formattedValue,
          }));
        }
      } else {
        setRegularLens((prevState) => ({
          ...prevState,
          ADD_L: "",
        }));
      }

      // Simulate get_Barcode_fromItem(2);
      getBarcodeFromItem(2);
    } catch (err) {
      console.error("Error in handleLAddLLeave:", err.message);
    }

    // Update SPH N.V. value for left eye (L)
    var sphDv = 0;
    var addValue = 0;
    try {
      // const sphDv = parseFloat(regularLens.SPH_DV_L || 0);
      // const addValue = parseFloat(regularLens.ADD_L || 0);

      sphDv = regularLens.SPH_DV_L;
      addValue = parseFloat(regularLens.ADD_L);
      sphDv = sphDv.trim() != "" ? parseFloat(sphDv) : 0;

      if (!isNaN(sphDv) && !isNaN(addValue)) {
        const nvValue = (sphDv + addValue).toFixed(2);
        setRegularLens((prevState) => ({
          ...prevState,
          SPH_NV_L: nvValue >= 0 ? `+${nvValue}` : nvValue,
        }));
      }

      // Copy other values if conditions are met
      if (Math.abs(Number(regularLens.ADD_L)) !== 0) {
        setRegularLens((prevState) => ({
          ...prevState,
          CYL_NV_L: prevState.CYL_DV_L,
          AXS_NV_L: prevState.AXS_DV_L,
        }));
      }
    } catch (err) {
      console.error("Error in updating SPH N.V. value:", err.message);
    }

    // Set default values for DV and NV fields if they are empty
    const defaultValues = () => {
      setRegularLens((prevState) => ({
        ...prevState,
        SPH_DV_L: prevState.SPH_DV_L || "",
        CYL_DV_L: prevState.CYL_DV_L || "",
        AXS_DV_L: prevState.AXS_DV_L || "",
        SPH_NV_L: prevState.SPH_NV_L || "",
        CYL_NV_L: prevState.CYL_NV_L || "",
        AXS_NV_L: prevState.AXS_NV_L || "",
      }));
    };

    defaultValues();
  };

  const handleLSphRNvLeave = () => {
    try {
      let value = parseFloat(regularLens.SPH_NV_R);

      if (value % 0.25 === 0 && regularLens.SPH_NV_R !== "") {
        if (value === 0) {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_R: "0.00",
          }));
        } else if (value < 0) {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_R: parseFloat(value).toFixed(2),
          }));
        } else {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_R: `+${Math.abs(parseFloat(value)).toFixed(2)}`,
          }));
        }
      } else {
        setRegularLens((prevState) => ({
          ...prevState,
          SPH_NV_R: "",
        }));
      }
    } catch (err) {
      console.error("Error in handleLSphRNvLeave:", err.message);
    }

    // Update Add Value
    try {
      // const sphDv = parseFloat(regularLens.SPH_DV_R || 0);
      // const sphNv = parseFloat(regularLens.SPH_NV_R || 0);
      var sphDv = 0;
      var sphNv = 0;

      if (regularLens.SPH_DV_R !== "") {
        sphDv = parseFloat(regularLens.SPH_DV_R);
        sphNv = parseFloat(regularLens.SPH_NV_R);

        if (!isNaN(sphDv) && !isNaN(sphNv)) {
          const addValue = (sphNv - sphDv).toFixed(2);

          setRegularLens((prevState) => ({
            ...prevState,
            ADD_R: addValue > 0 ? `+${addValue}` : addValue,
          }));
        }
      }
    } catch (err) {
      console.error("Error in updating Add Value:", err.message);
    }
  };

  const handleLSphLNvLeave = () => {
    try {
      let value = parseFloat(regularLens.SPH_NV_L);

      // Validate and format the SPH_NV_L value
      if (value % 0.25 === 0 && regularLens.SPH_NV_L !== "") {
        if (value === 0) {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_L: "0.00",
          }));
        } else if (value < 0) {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_L: parseFloat(value).toFixed(2),
          }));
        } else {
          setRegularLens((prevState) => ({
            ...prevState,
            SPH_NV_L: `+${Math.abs(parseFloat(value)).toFixed(2)}`,
          }));
        }
      } else {
        setRegularLens((prevState) => ({
          ...prevState,
          SPH_NV_L: "",
        }));
      }
    } catch (err) {
      console.error("Error in handleLSphLNvLeave:", err.message);
    }

    // Update Add Value for the left eye (L)
    try {
      // const sphDv = parseFloat(regularLens.SPH_DV_L || 0);
      // const sphNv = parseFloat(regularLens.SPH_NV_L || 0);
      var sphDv = 0;
      var sphNv = 0;
      if (regularLens.SPH_DV_L !== "") {
        sphDv = parseFloat(regularLens.SPH_DV_L);
        sphNv = parseFloat(regularLens.SPH_NV_L);

        if (!isNaN(sphDv) && !isNaN(sphNv)) {
          const addValue = (sphNv - sphDv).toFixed(2);
          setRegularLens((prevState) => ({
            ...prevState,
            ADD_L: addValue > 0 ? `+${addValue}` : addValue,
          }));
        }
      }
    } catch (err) {
      console.error("Error in updating Add Value:", err.message);
    }
  };

  const handleTabIndexChanged = () => {
    try {
      setTrnsposeLens({
        ITEM_RIGHT: "",
        ITEM_LEFT: "",
        SPH_DV_R: "",
        CYL_DV_R: "",
        AXS_DV_R: "",
        VN_DV_R: "",
        SPH_NV_R: "",
        CYL_NV_R: "",
        AXS_NV_R: "",
        VN_NV_R: "",
        ADD_R: "",
        SPH_DV_L: "",
        CYL_DV_L: "",
        AXS_DV_L: "",
        VN_DV_L: "",
        SPH_NV_L: "",
        CYL_NV_L: "",
        AXS_NV_L: "",
        VN_NV_L: "",
        ADD_L: "",
      });

      const updateLeftEye = () => {
        const sphDvL = parseFloat(regularLens.SPH_DV_L || "");
        const cylDvL = parseFloat(regularLens.CYL_DV_L || "");
        const sphNvL = parseFloat(regularLens.SPH_NV_L || "");
        const cylNvL = parseFloat(regularLens.CYL_NV_L || "");
        const axsDvL = parseFloat(regularLens.AXS_DV_L || "");
        const axsNvL = parseFloat(regularLens.AXS_NV_L || "");

        setTrnsposeLens((prevState) => ({
          ...prevState,
          SPH_DV_L:
            sphDvL + cylDvL < 0
              ? (sphDvL + cylDvL).toFixed(2)
              : `+${Math.abs(sphDvL + cylDvL).toFixed(2)}`,
          SPH_NV_L:
            sphNvL + cylNvL < 0
              ? (sphNvL + cylNvL).toFixed(2)
              : `+${Math.abs(sphNvL + cylNvL).toFixed(2)}`,
          CYL_DV_L:
            cylDvL > 0
              ? `-${Math.abs(cylDvL).toFixed(2)}`
              : `+${Math.abs(cylDvL).toFixed(2)}`,
          CYL_NV_L:
            cylNvL > 0
              ? `-${Math.abs(cylNvL).toFixed(2)}`
              : `+${Math.abs(cylNvL).toFixed(2)}`,
          AXS_DV_L: axsDvL > 90 ? Math.abs(90 - axsDvL) : Math.abs(90 + axsDvL),
          AXS_NV_L: axsNvL > 90 ? Math.abs(90 - axsNvL) : Math.abs(90 + axsNvL),
          ADD_L: regularLens.ADD_L,
        }));
      };

      const updateRightEye = () => {
        debugger;
        const sphDvR = parseFloat(regularLens.SPH_DV_R || "");
        const cylDvR = parseFloat(regularLens.CYL_DV_R || "");
        const sphNvR = parseFloat(regularLens.SPH_NV_R || "");
        const cylNvR = parseFloat(regularLens.CYL_NV_R || "");
        const axsDvR = parseFloat(regularLens.AXS_DV_R || "");
        const axsNvR = parseFloat(regularLens.AXS_NV_R || "");

        setTrnsposeLens((prevState) => ({
          ...prevState,
          SPH_DV_R:
            sphDvR + cylDvR < 0
              ? (sphDvR + cylDvR).toFixed(2)
              : `+${Math.abs(sphDvR + cylDvR).toFixed(2)}`,
          SPH_NV_R:
            sphNvR + cylNvR < 0
              ? (sphNvR + cylNvR).toFixed(2)
              : `+${Math.abs(sphNvR + cylNvR).toFixed(2)}`,
          CYL_DV_R:
            cylDvR > 0
              ? `-${Math.abs(cylDvR).toFixed(2)}`
              : `+${Math.abs(cylDvR).toFixed(2)}`,
          CYL_NV_R:
            cylNvR > 0
              ? `-${Math.abs(cylNvR).toFixed(2)}`
              : `+${Math.abs(cylNvR).toFixed(2)}`,
          AXS_DV_R: axsDvR > 90 ? Math.abs(90 - axsDvR) : Math.abs(90 + axsDvR),
          AXS_NV_R: axsNvR > 90 ? Math.abs(90 - axsNvR) : Math.abs(90 + axsNvR),
          ADD_R: regularLens.ADD_R,
        }));
      };

      updateLeftEye();
      updateRightEye();
    } catch (err) {
      console.error("Error in handleTabIndexChanged:", err.message);
    }

    try {
      setTrnsposeLens((prevState) => {
        debugger;
        const cleanedState = { ...prevState };
        Object.keys(cleanedState).forEach((key) => {
          if (
            typeof cleanedState[key] === "string" &&
            cleanedState[key].includes("NaN")
          ) {
            cleanedState[key] = "";
          }

          if (
            typeof cleanedState[key] === "number" &&
            isNaN(cleanedState[key])
          ) {
            cleanedState[key] = "";
          }
        });
        return cleanedState;
      });
    } catch (err) {
      console.error("Error in removing NaN values:", err.message);
    }
  };

  const btnBySphEqui_Click = () => {
    try {
      debugger;
      setContactLens({
        ITEM_RIGHT: "",
        ITEM_LEFT: "",
        SPH_DV_R: "",
        CYL_DV_R: "",
        AXS_DV_R: "",
        VN_DV_R: "",
        SPH_NV_R: "",
        CYL_NV_R: "",
        AXS_NV_R: "",
        VN_NV_R: "",
        ADD_R: "",
        BASE_R: "",
        DAI_R: "",
        REM_R: "",
        SPH_DV_L: "",
        CYL_DV_L: "",
        AXS_DV_L: "",
        VN_DV_L: "",
        SPH_NV_L: "",
        CYL_NV_L: "",
        AXS_NV_L: "",
        VN_NV_L: "",
        ADD_L: "",
        BASE_L: "",
        DIA_L: "",
        REM_L: "",
      });

      // Get values from the regularLens state (no reset for regularLens)
      const sphRight = parseFloat(regularLens.SPH_DV_R);
      const cylRight = parseFloat(regularLens.CYL_DV_R);
      const sphLeft = parseFloat(regularLens.SPH_DV_L);
      const cylLeft = parseFloat(regularLens.CYL_DV_L);

      // Calculate for right side cylinder value
      let str_cyl_right_value = calculateCylinderValue(cylRight);

      // Calculate for left side cylinder value
      let str_cyl_left_value = calculateCylinderValue(cylLeft);

      // Calculation for right side spherical value
      let str_calculation = calculateSphericalValue(
        sphRight,
        str_cyl_right_value
      );
      setContactLens((prevState) => ({
        ...prevState,
        SPH_DV_R:
          str_calculation < 0
            ? str_calculation.toFixed(2).toString()
            : "+" + str_calculation.toFixed(2),
      }));

      // Calculation for left side spherical value
      str_calculation = calculateSphericalValue(sphLeft, str_cyl_left_value);
      setContactLens((prevState) => ({
        ...prevState,
        SPH_DV_L:
          str_calculation < 0
            ? str_calculation.toFixed(2)
            : "+" + str_calculation.toFixed(2),
      }));
    } catch (err) {
      console.error("Error in btnBySphEqui_Click:", err.message);
    }
  };

  const btn_By_sph_Click = () => {
    setContactLens((prevState) => ({
      ...prevState,
      SPH_DV_R: regularLens.SPH_DV_R,
      CYL_DV_R: regularLens.CYL_DV_R,
      AXS_DV_R: regularLens.AXS_DV_R,
      VN_DV_R: regularLens.VN_DV_R,
      SPH_NV_R: regularLens.SPH_NV_R,
      CYL_NV_R: regularLens.CYL_NV_R,
      AXS_NV_R: regularLens.AXS_NV_R,
      VN_NV_R: regularLens.VN_NV_R,
      ADD_R: regularLens.ADD_R,
      SPH_DV_L: regularLens.SPH_DV_L,
      CYL_DV_L: regularLens.CYL_DV_L,
      AXS_DV_L: regularLens.AXS_DV_L,
      VN_DV_L: regularLens.VN_DV_L,
      SPH_NV_L: regularLens.SPH_NV_L,
      CYL_NV_L: regularLens.CYL_NV_L,
      AXS_NV_L: regularLens.AXS_NV_L,
      VN_NV_L: regularLens.VN_NV_L,
      ADD_L: regularLens.ADD_L,
    }));
  };

  // Helper function to calculate cylinder value
  const calculateCylinderValue = (cylValue) => {
    let result = 0;
    let i = 0.25;
    let val = Math.abs(cylValue);
    while (i < 2) {
      const y = val / 2;
      const diff = Math.abs(i - y);
      if (diff < 0.13) {
        result = i;
        return cylValue < 0 ? -result : result;
      }
      i += 0.25;
    }
    return result;
  };

  // Helper function to calculate spherical value based on input
  const calculateSphericalValue = (sphValue, cylValue) => {
    let str_calculation = sphValue + cylValue;
    return str_calculation;
  };

  //*****************************Lens Formula************************************** */

  const getBarcodeFromItem = (id) => {
    // console.log(`Simulated barcode fetch for item: ${id}`);
  };

  const [EyeModal, setEyeModal] = useState(false);

  const OpenEyeModal = () => {
    setEyeModal(true);
  };
  const CloseEyeModal = () => {
    setEyeModal(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    // console.log(event.target.value);
    if (event.target.value === "Format1") {
      document.getElementById("LensFormat1").style.display = "block";
      document.getElementById("LensFormat2").style.display = "none";
    } else if (event.target.value === "Format2") {
      document.getElementById("LensFormat1").style.display = "none";
      document.getElementById("LensFormat2").style.display = "block";
    }
  };

  useImperativeHandle(lensinforef, () => ({
    saveLensInfoInList,
    GetLensData,
  }));

  const [regularLens, setRegularLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",
    itemId_d2: "0",
    linkedItm: "0",
    RItemId_d2: "",

    LItemId_d2: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",
    PRIZM_R: "",
    BASE_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
    PRIZM_L: "",
    BASE_L: "",
  });

  const [contactLens, setContactLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",
    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",
    ADD_R: "",
    BASE_R: "",
    DAI_R: "",
    REM_R: "",
    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
    BASE_L: "",
    DIA_L: "",
    REM_L: "",
  });

  const [trnsposeLens, setTrnsposeLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [ARLens, setARLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [IMLens, setIMLens] = useState({
    ITEM_RIGHT: "",
    ITEM_LEFT: "",

    SPH_DV_R: "", //DV
    CYL_DV_R: "",
    AXS_DV_R: "",
    VN_DV_R: "",
    SPH_NV_R: "", //NV
    CYL_NV_R: "",
    AXS_NV_R: "",
    VN_NV_R: "",

    ADD_R: "",

    SPH_DV_L: "", //DV
    CYL_DV_L: "",
    AXS_DV_L: "",
    VN_DV_L: "",
    SPH_NV_L: "", //NV
    CYL_NV_L: "",
    AXS_NV_L: "",
    VN_NV_L: "",

    ADD_L: "",
  });

  const [lensOtherInfo, setLensOtherInfo] = useState({
    rowindex: 0,
    PrintPwrType: "",
    PrintPwrEye: "",
    LensPrice: "",
    LensType: "",
    PrescribedBy: "",
    PartyId: "",
    PartyName: "",
    FrameName: "",
    LenseStatus: "",
    PrintLens: "",

    NvVal: "",
    NvDt: "",
    CleVal: "",
    CleDt: "",
    PdRL: "",
    PdR: "",
    PdL: "",
    DelivDay: "",
    DelivTime: "",
    Rdata: false,
    Ldata: false,
    imgId: 0,
  });

  useEffect(() => {
    // console.log(lensOtherInfo);
  }, [lensOtherInfo]);

  useEffect(() => {
    if (lensOtherInfo.PdR.trim() !== "" && lensOtherInfo.PdL.trim() !== "") {
      const val = parseFloat(lensOtherInfo.PdR) + parseFloat(lensOtherInfo.PdL);
      setLensOtherInfo((prevState) => ({
        ...prevState,
        PdRL: val,
      }));
    }
  }, [lensOtherInfo.PdR, lensOtherInfo.PdL]);

  //---------------For Lens Power And Other Info Inpute Change-----------------------

  const handleFocus = (e, lensTypeNo) => {
    openPopUpForLensSelection(e, lensTypeNo);
    setLensTypeNumber(lensTypeNo);

    if (lensTypeNo == 1) {
      setNameOfSelectedLens("Distance-SPH-Power");
    } else if (lensTypeNo == 2) {
      setNameOfSelectedLens("Distance-CYL-Power");
    } else if (lensTypeNo == 3) {
      setNameOfSelectedLens("Distance-AXIS-Power");
    } else if (lensTypeNo == 4) {
      setNameOfSelectedLens("Distance-ADD-Power");
    } else if (lensTypeNo == 5) {
      setNameOfSelectedLens("Distance-V/N-Power");
    }

    let nextIndex = inputRefs?.current.indexOf(e.target);
    setNextIndexSaved(nextIndex);
    // console.log(e.target.name);
    setFocusedName(e.target.name);
    e.preventDefault();

    // Optionally, you can also show a custom behavior here
  };

  useEffect(() => {
    // console.log("selectedValue", selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    // console.log("nextIndexSaved", nextIndexSaved);
  }, [nextIndexSaved]);

  useEffect(() => {
    // console.log("FocusedName", FocusedName);
  }, [FocusedName]);

  // Handle click on a value
  const handleClick = (value, lensNo) => {
    debugger;
    if (value) {
      setSelectedValue(value);
    } else {
      setSelectedValue("0");
      setFocusedName("");
    }
    if (value) {
      var index = 1;
      switch (index) {
        case 1:
          setRegularLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 2:
          setContactLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 3:
          setTrnsposeLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 4:
          setARLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        case 5:
          setIMLens((prevValues) => ({
            ...prevValues,
            [FocusedName]: value,
          }));
          break;
        default:
          console.warn("Invalid lens index:", index);
          break;
      }
    }
    if (lensNo == 3) {
      const NxtIndex = nextIndexSaved + 1;
      setNextIndexSaved(NxtIndex);

      if (NxtIndex === 0) {
        NxtIndex = 2;
      }
      if (NxtIndex < inputRefs?.current.length) {
        inputRefs.current[NxtIndex].focus();
      } else if (NxtIndex > 37) {
        mainPageInputRef.current.focus();
      }
    } else {
      const NxtIndex = nextIndexSaved + 1;
      setNextIndexSaved(NxtIndex);

      if (NxtIndex === 0) {
        NxtIndex = 2;
      }
      if (NxtIndex < inputRefs?.current.length) {
        inputRefs.current[NxtIndex].focus();
      } else if (NxtIndex > 37) {
        mainPageInputRef.current.focus();
      }
    }

    setSelectedPopup(true);
    setTimeout(() => {
      setSelectedPopup(false);
    }, 1000);
  };

  const handleLensPwrChange = (e, index) => {
    debugger;
    const { name, value } = e.target;
    if (name == "ITEM_RIGHT") {
      setIsDropdownOpen2(true);
    }
    if (name == "ITEM_LEFT") {
      setIsDropdownOpen3(true);
    }

    switch (index) {
      case 1:
        setRegularLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 2:
        setContactLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 3:
        setTrnsposeLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 4:
        setARLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      case 5:
        setIMLens((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        break;
      default:
        console.warn("Invalid lens index:", index);
        break;
    }
  };

  const onBlurCheckValueSPHCYL = (e, index) => {
    
    if (e.target.value && e.target.value != "") {
      setTimeout(() => {
        function isDivisibleByQuarter(a) {
          return Math.abs(a % 0.25) < Number.EPSILON;
        }
        const { name, value } = e.target;

        function formatLensValue(value) {
          let num = value.toString();
          if (num.startsWith(".")) {
            num = "0" + num; // Prepend 0 if value starts with "."
          }
          
          let formattedValue = parseFloat(num).toFixed(2);
          
          if (!formattedValue.startsWith("-") && !formattedValue.startsWith("+")) {
            formattedValue = "+" + formattedValue; // Add "+" if no sign present
          }
          
          return formattedValue;
        }

        var isAllowed = isDivisibleByQuarter(value);

        var numericValue = parseFloat(value);
        if (isNaN(numericValue) || numericValue < -30 || numericValue > 30) {
          alert("Please enter a number between -30 and 30.");
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          return;
        }
        else{
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          // return;
        }

        if (isAllowed) {

          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: formatLensValue(value),
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          
          
          
        } else {
          alert(
            "Please enter a value that is a quarter (0.25, 0.5, 0.75, or 1)."
          );
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          return;
        }
      }, 100);
    }
  };

  const onBlurCheckValueAxis = (e, index) => {
    if (e.target.value && e.target.value != "") {
      setTimeout(() => {
        function isDivisibleByQuarter(a) {
          return Math.abs((a * 100) % 25) < 1e-9; // Ensures divisibility by 0.25
        }

        const { name, value } = e.target;
        const numericValue = parseFloat(value);

        if (isNaN(numericValue) || numericValue < 0 || numericValue > 180) {
          alert("Please enter a number between 0 and 180.");
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          return;
        }
        if (!isDivisibleByQuarter(numericValue)) {
          alert(
            "Please enter a value that is a quarter (0.25, 0.5, 0.75, or 1)."
          );

          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
        }
      }, 300);
    }
  };

  const onBlurCheckValueADD = (e, index) => {
    if (e.target.value && e.target.value != "") {
      setTimeout(() => {
        function isDivisibleByQuarter(a) {
          return Math.abs(a % 0.25) < Number.EPSILON;
        }
        const { name, value } = e.target;

        var isAllowed = isDivisibleByQuarter(value);
        var numericValue = parseFloat(value);
        if (isNaN(numericValue) || numericValue <= 0 || numericValue > 15) {
          alert("Please enter a number between -30 and 30.");
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          return;
        }

        if (isAllowed) {
        } else {
          alert(
            "Please enter a value that is a quarter (0.25, 0.5, 0.75, or 1)."
          );
          switch (index) {
            case 1:
              setRegularLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 2:
              setContactLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 3:
              setTrnsposeLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 4:
              setARLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            case 5:
              setIMLens((prevValues) => ({
                ...prevValues,
                [name]: "",
              }));
              break;
            default:
              console.warn("Invalid lens index:", index);
              break;
          }
          return;
        }
      }, 100);
    }
  };

  useEffect(() => {
    debugger;
    if (lensOtherInfo.Ldata == false && lensOtherInfo.Rdata == true) {
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintLens: "R",
      }));
    } else if (lensOtherInfo.Ldata == true && lensOtherInfo.Rdata == false) {
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintLens: "L",
      }));
    } else if (lensOtherInfo.Ldata == true && lensOtherInfo.Rdata == true) {
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintLens: "RL",
      }));
    } else {
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintLens: "",
      }));
    }
  }, [lensOtherInfo.Ldata, lensOtherInfo.Rdata]);

  const handleDelivDayChange = (e , save) => {
    const newDelivDay = parseInt(e.target.value, 10);

    onDelivDayChange(newDelivDay , save);
  };

  const handleLensOtherInfoChange = async (e) => {
    const { name, value, checked } = e.target;
    debugger;

    if (name == "FrameName") {
      setIsDropdownOpen4(true);
    }

    if (name == "Ldata" || name == "Rdata") {
      if (checked == true) {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          [name]: true,
        }));
      } else {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          [name]: false,
        }));
      }
    } else {
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name == "PartyName") {
      const filteredData = {
        MasterType: 2,
        GroupWiseSuggestion: true,
        GroupId: [211, 212, 214, 203, 202],
        search_str: value,
        PageNumber: 1,
        PageSize: 50,
      };
      const SearchItem = await AccountAPI.GetAccountListForAutoSuggestion(
        filteredData
      );
      if (SearchItem) {
        setFilteredAccount(SearchItem);
      }
      setIsDropdownOpen1(true);
    }

    if (name == "PartyName") {
      const filteredData = {
        MasterType: 2,
        GroupWiseSuggestion: true,
        GroupId: [211, 212, 214, 203, 202],
        search_str: value,
        PageNumber: 1,
        PageSize: 50,
      };
      const SearchItem = await AccountAPI.GetAccountListForAutoSuggestion(
        filteredData
      );
      if (SearchItem) {
        setFilteredAccount(SearchItem);
      }
      setIsDropdownOpen1(true);
    }

    if (name === "NvVal" || name === "CleVal") {
      const currentDate = new Date();
      let updatedDate = new Date(currentDate);

      if (value === "1w") {
        updatedDate.setDate(currentDate.getDate() + 7); // Add 7 days
      } else if (value === "1m") {
        updatedDate.setMonth(currentDate.getMonth() + 1); // Add 1 month
      } else if (value === "3m") {
        updatedDate.setMonth(currentDate.getMonth() + 3); // Add 3 months
      } else if (value === "6m") {
        updatedDate.setMonth(currentDate.getMonth() + 6); // Add 6 months
      } else if (value === "1y") {
        updatedDate.setFullYear(currentDate.getFullYear() + 1); // Add 1 year
      }

      const formattedDate = updatedDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        [name === "NvVal" ? "NvDt" : "CleDt"]: formattedDate, // Dynamically update NvDt or CleDt
      }));
    }
  };

  const openOnFocusSuggestion = (e) => {
    if (e.target.name == "FrameName") {
      setIsDropdownOpen4(true);
    } else if (e.target.name == "ITEM_RIGHT") {
      setIsDropdownOpen2(true);
    } else if (e.target.name == "ITEM_LEFT") {
      setIsDropdownOpen3(true);
    }
  };

  const handleAccountSelection = (Id, AccData, name) => {
    debugger;
    setLensOtherInfo((prevValues) => ({
      ...prevValues, // Spread the previous state to retain other properties
      [name]: AccData.name || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
      PartyId: Id || "", // Set PartyId to `Id` or empty string if `Id` is falsy
    }));
    setIsDropdownOpen1(false);
  };

  const handleRightSelection = (Data, name) => {
    debugger;
    if (Data) {
      setRegularLens((prevValues) => ({
        ...prevValues, // Spread the previous state to retain other properties
        [name]: Data.productName || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
        RItemId_d2: Data.d2_Id || "", // Set PartyId to `Id` or empty string if `Id` is falsy
      }));
    }
    setIsDropdownOpen2(false);
  };

  const handleLeftSelection = (Data, name) => {
    if (Data) {
      setRegularLens((prevValues) => ({
        ...prevValues, // Spread the previous state to retain other properties
        [name]: Data.productName || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
        LItemId_d2: Data.d2_Id || "", // Set PartyId to `Id` or empty string if `Id` is false
      }));
    }
    setIsDropdownOpen3(false);
  };

  const handleFrameSelection = (Data, name) => {
    if (Data) {
      setLensOtherInfo((prevValues) => ({
        ...prevValues, // Spread the previous state to retain other properties
        [name]: Data.productName || "", // Update the property `name` dynamically (or set it to empty string if `AccData.name` is falsy)
      }));
    }
    setIsDropdownOpen4(false);
  };

  const openPopUpForLensSelection = (LensType) => {
    setPopUpSelectingLens(true);
    setLensTypeNumber(LensType);
    if (LensType == 1) {
      setNameOfSelectedLens("Distance-SPH-Power");
    } else if (LensType == 2) {
      setNameOfSelectedLens("Distance-CYL-Power");
    } else if (LensType == 3) {
      setNameOfSelectedLens("Distance-AXIS-Power");
    } else if (LensType == 4) {
      setNameOfSelectedLens("Distance-ADD-Power");
    } else if (LensType == 5) {
      setNameOfSelectedLens("Distance-V/N-Power");
    }

    // Scroll the element with ID 'tabs-main-lens-selection' to the top of the screen
    const lensSelectionElement = document.getElementById(
      "tabs-main-lens-selection"
    );
    if (lensSelectionElement) {
      // Scroll the element into view
      lensSelectionElement.scrollIntoView({
        behavior: "smooth",
        block: "start", // Align the element at the top of the viewport
        inline: "nearest", // Horizontal alignment (default)
      });

      // After the element scrolls into view, adjust the scroll position by 100px
      window.setTimeout(() => {
        window.scrollTo({
          top:
            lensSelectionElement.getBoundingClientRect().top +
            window.scrollY -
            52,
          behavior: "smooth", // Optional: You can use smooth scrolling for this too
        });
      }, 300); // Delay to ensure scrollIntoView has been applied before adjusting
    }
  };

  // const changeLensType = (LensType) => {
  //   // setTimeout(() => {
  //   setLensTypeNumber(LensType);
  //   setPopUpSelectingLens(true);
  //   // }, 100);
  // };
  // useEffect(() => {
  //   changeLensType(LensTypeNumber);
  // }, [selectedValue, LensTypeNumber]);

  const closePopUpForLensSelection = () => {
    setTimeout(() => {
      setPopUpSelectingLens(false);
    }, 100);
  };

  const [issavingLens, setIssavingLens] = useState(false);

  useEffect(() => {
    // console.log("issavingLens", issavingLens);
  }, [issavingLens]);

  const saveLensInfoInList = async (e) => {
    debugger;
    if (!issavingLens) {
      return;
    }
    var LensSave = await Transaction.SetTransLensData(
      regularLens,
      contactLens,
      trnsposeLens,
      IMLens,
      ARLens,
      lensOtherInfo,
      ReferenceNumber
    );

    if (LensSave.isSuccess == true) {
      if ([LensSave.result] == 0) {
        alert("Check the Data And Try Again");
      } else {
        // alert("lens information saved sucessfully");
        const lensElement = document.getElementById("Add_Lens");
        lensElement.classList.add("blinking");

        // Remove the "blinking" class after 3 seconds
        setTimeout(() => {
          lensElement.classList.remove("blinking");
        }, 3000);
        GetLensData(true);
        setSelectedLens("default");
        ResetLensdata();
      }
    } else {
      // console.log("Check the Data And Try Again");
    }
    setIssavingLens(false);
  };

  const DeleteTransLensData = async () => {
    var LensDelete = await Transaction.DeleteTransLensData(selectedLens);
    if (LensDelete == true) {
      alert("lens information deleted sucessfully");
      GetLensData();
      setSelectedLens("default");
    }
  };

  const ResetLensdata = () => {
    setRegularLens((prevValues) => ({
      ...prevValues,
      ITEM_RIGHT: "",
      ITEM_LEFT: "",
      RItemId_d2: "",
      LItemId_d2: "",
      SPH_DV_R: "",
      CYL_DV_R: "",
      AXS_DV_R: "",
      VN_DV_R: "",
      SPH_NV_R: "",
      CYL_NV_R: "",
      AXS_NV_R: "",
      VN_NV_R: "",
      ADD_R: "",
      PRIZM_R: "",
      BASE_R: "",
      SPH_DV_L: "",
      CYL_DV_L: "",
      AXS_DV_L: "",
      VN_DV_L: "",
      SPH_NV_L: "",
      CYL_NV_L: "",
      AXS_NV_L: "",
      VN_NV_L: "",
      ADD_L: "",
      PRIZM_L: "",
      BASE_L: "",
    }));
    setLensOtherInfo((prevValues) => ({
      ...prevValues,
      PrintPwrType: "",
      PrintPwrEye: "",
      LensPrice: "",
      LensType: "",
      PrescribedBy: "",
      PartyId: "",
      PartyName: "",
      FrameName: "",
      LenseStatus: "",
      PrintLens: "",
      NvVal: "",
      NvDt: "",
      CleVal: "",
      CleDt: "",
      PdRL: "",
      PdR: "",
      PdL: "",
      DelivDay: "",
      DelivTime: "",
      rowindex: 0,
      imgId: 0,
    }));
    // setContactLens([]);
    // setTrnsposeLens([]);
    // setIMLens([]);
    // setARLens([]);
  };

  const [LensCount, setLensCount] = useState([]);
  const [selectedLens, setSelectedLens] = useState("default");
  useEffect(() => {
    // console.log(LensCount, selectedLens);
  }, [LensCount, selectedLens]);

  const GetLensData = async (Isfetch = false) => {
    debugger;
    var LensData = await Transaction.GetTransLensData(ReferenceNumber);
    if (LensData) {
      setLensCount(LensData.result);
    }

    if (Isfetch) {
      return;
    }

    var data = LensData.result;
    if (data.length > 0) {
      setSelectedLens(data[0].id);
      setRegularLens((prevValues) => ({
        ...prevValues,
        ITEM_RIGHT: data[0].rItemName || "",
        ITEM_LEFT: data[0].lItemName || "",
        RItemId_d2: data[0].rItemId_d2 || "",
        LItemId_d2: data[0].lItemId_d2 || "",
        SPH_DV_R: data[0].rSphDv || "",
        CYL_DV_R: data[0].rCylDv || "",
        AXS_DV_R: data[0].rAxisDv || "",
        VN_DV_R: data[0].rVnDv || "",
        SPH_NV_R: data[0].rSphNv || "",
        CYL_NV_R: data[0].rCylNv || "",
        AXS_NV_R: data[0].rAxisNv || "",
        VN_NV_R: data[0].rVnNv || "",
        ADD_R: data[0].rAdd || "",
        PRIZM_R: data[0].rpB1 || "",
        BASE_R: data[0].rpB2 || "",
        SPH_DV_L: data[0].lSphDv || "",
        CYL_DV_L: data[0].lCylDv || "",
        AXS_DV_L: data[0].lAxisDv || "",
        VN_DV_L: data[0].lVnDv || "",
        SPH_NV_L: data[0].lSphNv || "",
        CYL_NV_L: data[0].lCylNv || "",
        AXS_NV_L: data[0].lAxisNv || "",
        VN_NV_L: data[0].lVnNv || "",
        ADD_L: data[0].lAdd || "",
        PRIZM_L: data[0].lpB1 || "",
        BASE_L: data[0].lpB2 || "",
      }));

      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintPwrType: data[0].PrintPwrType || "",
        PrintPwrEye: data[0].PrintPwrEye || "",
        LensPrice: data[0].LensPrice || "",
        LensType: data[0].lenseType,
        PrescribedBy: data[0].prescribedBy,
        PartyId: data[0].partyId,
        PartyName: data[0].partyName,
        FrameName: data[0].frameName,
        LenseStatus: data[0].printLenseStatus,
        PrintLens: data[0].printLensEye,
        NvVal: data[0].NvVal,
        NvDt: data[0].nvDate,
        CleVal: data[0].lensExpRem,
        CleDt: data[0].lExDate,
        PdRL: data[0].pdrl,
        PdR: data[0].pdr,
        PdL: data[0].pdl,
        DelivDay: data[0].deliverDays,
        DelivTime: data[0].deliverTime,
        rowindex: data[0].indx,
        imgId: Number(data[0].imgId) || 0,
      }));

      if (data[0].printLensEye == "R") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: true,
          Ldata: false,
        }));
      } else if (data[0].printLensEye == "L") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: false,
          Ldata: true,
        }));
      } else if (data[0].printLensEye == "") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: false,
          Ldata: false,
        }));
      } else {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: true,
          Ldata: true,
        }));
      }
    } else {
      ResetLensdata();
      setSelectedLens("default");
    }
  };

  const FetchlensData = (e) => {
    if (e.target.value == "default") {
      setSelectedLens("default");
      setRegularLens((prevValues) => ({
        ...prevValues,
        ITEM_RIGHT: "",
        ITEM_LEFT: "",
        RItemId_d2: "",
        LItemId_d2: "",
        SPH_DV_R: "",
        CYL_DV_R: "",
        AXS_DV_R: "",
        VN_DV_R: "",
        SPH_NV_R: "",
        CYL_NV_R: "",
        AXS_NV_R: "",
        VN_NV_R: "",
        ADD_R: "",
        PRIZM_R: "",
        BASE_R: "",
        SPH_DV_L: "",
        CYL_DV_L: "",
        AXS_DV_L: "",
        VN_DV_L: "",
        SPH_NV_L: "",
        CYL_NV_L: "",
        AXS_NV_L: "",
        VN_NV_L: "",
        ADD_L: "",
        PRIZM_L: "",
        BASE_L: "",
      }));
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintPwrType: "",
        PrintPwrEye: "",
        LensPrice: "",
        LensType: "",
        PrescribedBy: "",
        PartyId: "",
        PartyName: "",
        FrameName: "",
        LenseStatus: "",
        PrintLens: "",
        NvVal: "",
        NvDt: "",
        CleVal: "",
        CleDt: "",
        PdRL: "",
        PdR: "",
        PdL: "",
        DelivDay: "",
        DelivTime: "",
        rowindex: 0,
        imgId: 0,
        Rdata: false,
        Ldata: false,
      }));
      // setContactLens([]);
      // setTrnsposeLens([]);
      // setIMLens([]);
      // setARLens([]);
      return;
    }
    var data = LensCount.filter((item) => item.id == e.target.value);
    if (data.length > 0) {
      // console.log(data);
      debugger;
      setSelectedLens(data[0].id);
      setRegularLens((prevValues) => ({
        ...prevValues,
        ITEM_RIGHT: data[0].rItemName || "",
        ITEM_LEFT: data[0].lItemName || "",
        RItemId_d2: data[0].rItemId_d2 || "",
        LItemId_d2: data[0].lItemId_d2 || "",
        SPH_DV_R: data[0].rSphDv || "",
        CYL_DV_R: data[0].rCylDv || "",
        AXS_DV_R: data[0].rAxisDv || "",
        VN_DV_R: data[0].rVnDv || "",
        SPH_NV_R: data[0].rSphNv || "",
        CYL_NV_R: data[0].rCylNv || "",
        AXS_NV_R: data[0].rAxisNv || "",
        VN_NV_R: data[0].rVnNv || "",
        ADD_R: data[0].rAdd || "",
        PRIZM_R: data[0].rpB1 || "",
        BASE_R: data[0].rpB2 || "",
        SPH_DV_L: data[0].lSphDv || "",
        CYL_DV_L: data[0].lCylDv || "",
        AXS_DV_L: data[0].lAxisDv || "",
        VN_DV_L: data[0].lVnDv || "",
        SPH_NV_L: data[0].lSphNv || "",
        CYL_NV_L: data[0].lCylNv || "",
        AXS_NV_L: data[0].lAxisNv || "",
        VN_NV_L: data[0].lVnNv || "",
        ADD_L: data[0].lAdd || "",
        PRIZM_L: data[0].lpB1 || "",
        BASE_L: data[0].lpB2 || "",
      }));

      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        PrintPwrType: data[0].PrintPwrType || "",
        PrintPwrEye: data[0].PrintPwrEye || "",
        LensPrice: data[0].LensPrice || "",
        LensType: data[0].lenseType,
        PrescribedBy: data[0].prescribedBy,
        PartyId: data[0].partyId,
        PartyName: data[0].partyName,
        FrameName: data[0].frameName,
        LenseStatus: data[0].printLenseStatus,
        PrintLens: data[0].printLensEye,
        NvVal: data[0].NvVal,
        NvDt: data[0].nvDate,
        CleVal: data[0].lensExpRem,
        CleDt: data[0].lExDate,
        PdRL: data[0].pdrl,
        PdR: data[0].pdr,
        PdL: data[0].pdl,
        DelivDay: data[0].deliverDays,
        DelivTime: data[0].deliverTime,
        rowindex: data[0].indx,
        imgId: Number(data[0].imgId) || 0,
      }));

      if (data[0].printLensEye == "R") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: true,
          Ldata: false,
        }));
      } else if (data[0].printLensEye == "L") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: false,
          Ldata: true,
        }));
      } else if (data[0].printLensEye == "") {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: false,
          Ldata: false,
        }));
      } else {
        setLensOtherInfo((prevValues) => ({
          ...prevValues,
          Rdata: true,
          Ldata: true,
        }));
      }
    }
  };

  useEffect(() => {
    if (ReferenceNumber) {
      GetLensData();
    }
  }, [ReferenceNumber]);

  useEffect(() => {
    // console.log(lensInfoInList);
  }, [lensInfoInList]);

  //---------------Focus on the next input field if Enter is pressed-----------------------

  const handleKeyDownLens = (e) => {
    if (e.key === "Enter" || e.key === "PageDown") {
      let nextIndex = inputRefs?.current.indexOf(e.target) + 1;
      setNextIndexSaved(nextIndex);
      if (nextIndex === 0) {
        nextIndex = 2;
      }
      if (nextIndex < inputRefs?.current.length) {
        inputRefs.current[nextIndex].focus();
      } else if (nextIndex > 37) {
        if (nextCellRefs.current[0] && nextCellRefs.current[0][1]) {
          nextCellRefs.current[0][1].focus();
        }
      }
    } else if (e.key === "PageUp") {
      let nextIndex = inputRefs?.current.indexOf(e.target) - 1;
      setNextIndexSaved(nextIndex);
      if (nextIndex === 0) {
        nextIndex = 2;
      }
      if (nextIndex < inputRefs?.current.length) {
        inputRefs.current[nextIndex].focus();
      } else if (nextIndex > 37) {
        if (nextCellRefs.current[0] && nextCellRefs.current[0][1]) {
          nextCellRefs.current[0][1].focus();
        }
      }
    }
  };

  const FocusNextInput = (e) => {
    let nextIndex = nextIndexSaved + 1;
    setNextIndexSaved(nextIndex);
    if (nextIndex === 0) {
      nextIndex = 2;
    }
    if (nextIndex < inputRefs?.current.length) {
      inputRefs.current[nextIndex].focus();
    } else if (nextIndex > 37) {
      if (nextCellRefs.current[0] && nextCellRefs.current[0][2]) {
        nextCellRefs.current[0][2].focus();
      }
    }
  };

  const FocusPrevInput = (e) => {
    debugger;

    let nextIndex = nextIndexSaved - 1;
    setNextIndexSaved(nextIndex);
    if (nextIndex === 0) {
      nextIndex = 2;
      closePopUpForLensSelection();
      nextInput.current[10].focus();
      return;
    }
    if (nextIndex < inputRefs?.current.length) {
      inputRefs.current[nextIndex].focus();
    } else if (nextIndex > 37) {
      if (nextCellRefs.current[0] && nextCellRefs.current[0][2]) {
        nextCellRefs.current[0][2].focus();
      }
    }
  };

  const handleKeyDown_AccountSuggestion = (e, ToFilterData) => {
    debugger;
    handleKeyDownLens(e);
    if (!ToFilterData.length) return;

    if (
      e.target.name == "ITEM_LEFT" ||
      e.target.name == "ITEM_RIGHT" ||
      e.target.name == "FrameName"
    ) {
      var itemdata = ToFilterData.filter(function (item, index) {
        return index === highlightedIndex;
      });
    } else {
      var Accdata = ToFilterData.filter(function (item, index) {
        return index === highlightedIndex;
      });
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % (ToFilterData.length + 1) // +1 for "Add New" option
        );

        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? ToFilterData.length : prevIndex - 1
        );
        break;
      case "Enter":
        let nextIndex = inputRefs?.current.indexOf(e.target) + 1;
        if (e.target.name == "ITEM_LEFT") {
          handleLeftSelection(itemdata[0], "ITEM_LEFT");
        } else if (e.target.name == "ITEM_RIGHT") {
          handleRightSelection(itemdata[0], "ITEM_RIGHT");
        } else if (e.target.name == "FrameName") {
          handleFrameSelection(itemdata[0], "FrameName");
        } else {
          handleAccountSelection(Accdata[0].id, Accdata[0], "PartyName");
        }
        setNextIndexSaved(nextIndex);
        if (nextIndex === 0) {
          nextIndex = 2;
        }
        if (nextIndex < inputRefs?.current.length) {
          inputRefs.current[nextIndex].focus();
        } else if (nextIndex > 37) {
          if (nextCellRefs.current[0] && nextCellRefs.current[0][2]) {
            nextCellRefs.current[0][2].focus();
          }
        }
        break;
      case "Escape":
        setIsDropdownOpen1(false);
        break;
      default:
        break;
    }
  };

  const removeModalSuggestion = () => {
    setTimeout(() => {
      setIsDropdownOpen1(false);
      setIsDropdownOpen2(false);
      setIsDropdownOpen3(false);
      setIsDropdownOpen4(false);
    }, 100);
  };

  const UploadImagePrescription = async (e) => {
    debugger;

    const file = e.target.files[0]; // Get the first selected file
    // if (file) {
    //   setImageName(file.name); // Set the file name to the state
    // }

    const formData = new FormData();

    formData.append(`files`, file);
    formData.append(`AccId`, Number(InputValueAccMaster.AccId));

    // // console.log(formData);

    const uploadimage = await Transaction.UpdateLensPrescriptionImage(formData);
    if (uploadimage.isSuccess == true) {
      alert("Image uploaded successfully");
      var imageIdToSend = uploadimage.result.imageId[0];
      setLensOtherInfo((prevValues) => ({
        ...prevValues,
        imgId: imageIdToSend,
      }));
      setSelectedImageId(selectedImageId);
    } else {
      alert("error while uploading the image");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (lensOtherInfo.imgId != 0) {
        saveLensInfoInList();
      }
    }, 10);
  }, [selectedImageId]);

  const [PresImgModal, setPresImgModal] = useState(false);

  const ViewPresImg = () => {
    setPresImgModal(!PresImgModal);
  };

  const DeletePrescImage =(ImageID)=>{
    var deleteImage = ItemMaster.DeleteImage(ImageID);
    if (deleteImage) {
      alert("Image deleted successfully");
      setLensOtherInfo((prevValues) => ({
       ...prevValues,
        imgId: 0,
      }));
      setSelectedImageId(0);
    }
  }

  return (
    <div
      className="table-right-padding"
      style={{ backgroundColor: "white", borderLeft: "1px solid lightgrey" }}
    >
      <div className="tabs-main" id="tabs-main-lens-selection">
        <div id="tabsLensInfo" className="si-size-1">
          <div className="tab-btn-box1 tab-b2">
            <div
              className={`tab-b1 tab-1  ${activeTab1 === 1 ? "active1" : ""}`}
              id="firstTab"
              onClick={() => {
                handleTabClick1(1);
                showtabs1();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                <span style={{ color: "red" }}>L</span>
                ens
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 2 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(2);
                showtabs2();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Cont
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 3 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(3);
                showtabs3();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Trans
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 4 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(4);
                showtabs4();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                AR
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 5 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(5);
                showtabs5();
              }}
            >
              {" "}
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                IM
              </h6>
            </div>
            <div
              className={`tab-b1 tab-1 ${activeTab1 === 6 ? "active1" : ""}`}
              onClick={() => {
                handleTabClick1(6);
                showtabs6();
              }}
            >
              <h6 style={{ margin: "5px" }} className="mt-1 fw-600 fw-12">
                Prevs
              </h6>
            </div>
          </div>

          <div className="tab-content-box1 overflow-lens">
            {tabcontent1 && (
              <div className="tab-content-1">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div
                        style={{ alignItems: "center" }}
                        className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                      >
                        <div className="input-containerEffect mt-1 width-righteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_RIGHT"
                            value={regularLens.ITEM_RIGHT}
                            onChange={(e) => handleLensPwrChange(e, 1)}
                            onKeyUp={(e) => {
                              handleKeyDown_AccountSuggestion(
                                e,
                                itemMasterRows
                              );
                            }}
                            onFocus={(e) => {
                              openOnFocusSuggestion(e);
                              setIssavingLens(true);
                            }}
                            ref={(el) => {
                              lensPageInputRef.current = el;
                              if (inputRefs.current == null) {
                                inputRefs.current = []; // Initialize as an empty array if null
                              }
                              inputRefs.current[1] = el;
                            }}
                            required
                            autoComplete="off"
                            onBlur={() => removeModalSuggestion()}
                            onClick={() => getPosition(1)}
                          />
                          <label id="input1" className="labelEffect1">
                            Right
                          </label>

                          {itemMasterRows.length >= 1 && isDropdownOpen2 && (
                            <ul
                              className="ItemmasterDuggestionsaleinvoice"
                              ref={suggestionBoxRef}
                              // style={{ position: "absolute" }}
                              style={{
                                top: `${inputPosition.top}px`,
                                left: `${inputPosition.left}px`,
                                width: `${inputPosition.width}px`,
                              }}
                            >
                              {lensData.map((Data, index) => (
                                <>
                                  {Data.productName && (
                                    <li
                                      key={index}
                                      ref={(el) =>
                                        (itemRefs.current[index] = el)
                                      }
                                      onClick={() =>
                                        handleRightSelection(Data, "ITEM_RIGHT")
                                      }
                                      style={{
                                        // padding: "2px 5px",
                                        // cursor: "pointer",
                                        // borderBottom: "1px solid #ccc",
                                        backgroundColor:
                                          highlightedIndex === index
                                            ? "#e6f7ff"
                                            : "transparent",
                                      }}
                                    >
                                      {Data.productName}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="input-containerEffect mt-1 width-lefteye">
                          <input
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_LEFT"
                            value={regularLens.ITEM_LEFT}
                            onChange={(e) => handleLensPwrChange(e, 1)}
                            onKeyUp={(e) => {
                              handleKeyDown_AccountSuggestion(
                                e,
                                itemMasterRows
                              );
                            }}
                            onFocus={(e) => {
                              openOnFocusSuggestion(e);
                              setIssavingLens(true);
                            }}
                            ref={(el) => (inputRefs.current[2] = el)}
                            required
                            autoComplete="off"
                            onBlur={() => removeModalSuggestion()}
                            onClick={() => getPosition(2)}
                          />
                          <label id="input1" className="labelEffect1">
                            Left
                          </label>
                          {isDropdownOpen3 && itemMasterRows.length >= 1 && (
                            <ul
                              className="ItemmasterDuggestionsaleinvoice"
                              ref={suggestionBoxRef}
                              // style={{ position: "absolute" }}
                              style={{
                                top: `${inputPosition.top}px`,
                                left: `${inputPosition.left}px`,
                                width: `${inputPosition.width}px`,
                              }}
                            >
                              {lensData.map((Data, index) => (
                                <>
                                  {Data.productName && (
                                    <li
                                      key={index}
                                      ref={(el) =>
                                        (itemRefs.current[index] = el)
                                      }
                                      onClick={() =>
                                        handleLeftSelection(Data, "ITEM_LEFT")
                                      }
                                      style={{
                                        // padding: "2px 5px",
                                        // cursor: "pointer",
                                        // borderBottom: "1px solid #ccc",
                                        backgroundColor:
                                          highlightedIndex === index
                                            ? "#e6f7ff"
                                            : "transparent",
                                      }}
                                    >
                                      {Data.productName}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          )}
                        </div>
                        <i
                          onClick={OpenEyeModal}
                          id="step-2"
                          className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                        ></i>
                      </div>
                      {/******************* First Format ********************/}
                      <div id="LensFormat1" style={{ display: "block" }}>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                style={{ marginRight: "12px" }}
                                className="col-sm-4 "
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                onFocus={(e) => {
                                  handleFocus(e, 1);
                                  setIssavingLens(true);
                                }}
                                inputMode="none"
                                value={regularLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[3] = el)}
                                required
                                // onFocus={() => openPopUpForLensSelection(1)}
                                onClick={() => openPopUpForLensSelection(1)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_DV_R"
                                value={regularLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[4] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={regularLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                onBlur={(e) => onBlurCheckValueAxis(e, 1)}
                                ref={(el) => (inputRefs.current[5] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[6] = el)}
                                required
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                // onBlur={() => changeLensType(2)}
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_DV_L"
                                value={regularLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[14] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_DV_L"
                                value={regularLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[15] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_DV_L"
                                value={regularLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onBlur={(e) => onBlurCheckValueAxis(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[16] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="VN_DV_L"
                                value={regularLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[17] = el)}
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_NV_R"
                                value={regularLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[7] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={handleLSphRNvLeave}
                                onBlur={(e) => {onBlurCheckValueSPHCYL(e, 1); handleLSphRNvLeave() }}

                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_NV_R"
                                value={regularLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[8] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}

                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_NV_R"
                                value={regularLens.AXS_NV_R}
                                onBlur={(e) => onBlurCheckValueAxis(e, 1)}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[9] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 5)}
                                // inputMode="none"
                                name="VN_NV_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[10] = el)}
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                required
                                // onBlur={() => changeLensType(2)}
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="SPH_NV_L"
                                value={regularLens.SPH_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[18] = el)}
                                onBlur={(e) => {onBlurCheckValueSPHCYL(e, 1) ; handleLSphLNvLeave()}}
                                required
                                onClick={() => openPopUpForLensSelection(1)}
                                // onBlur={handleLSphLNvLeave}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 2)}
                                inputMode="none"
                                name="CYL_NV_L"
                                value={regularLens.CYL_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[19] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(2)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="AXS_NV_L"
                                value={regularLens.AXS_NV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 1)}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[20] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="VN_NV_L"
                                value={regularLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[21] = el)}
                                required
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                // onBlur={() => changeLensType(2)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" ">
                              <label className="col-sm-4 ">Add</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 4)}
                                inputMode="none"
                                name="ADD_R"
                                value={regularLens.ADD_R}
                                // onBlur={handleLAddRLeave}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[11] = el)}
                                required
                                onBlur={handleLAddRLeave}
                                onClick={() => openPopUpForLensSelection(4)}
                                // onBlur={() => changeLensType(2)}
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                P.B
                              </label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="PRIZM_R"
                                value={regularLens.PRIZM_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[12] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                //  // onBlur={() => changeLensType(2)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 4)}
                                // inputMode="none"
                                name="BASE_R"
                                style={{ marginRight: "12px" }}
                                value={regularLens.BASE_R}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[13] = el)}
                                required
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                // onBlur={() => changeLensType(2)}
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 4)}
                                inputMode="none"
                                name="ADD_L"
                                value={regularLens.ADD_L}
                                onBlur={handleLAddLLeave}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[22] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(4)}
                                // onBlur={handleLAddLLeave}
                                // onBlur={() => changeLensType(2)}
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                P.B
                              </label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onFocus={(e) => handleFocus(e, 3)}
                                inputMode="none"
                                name="PRIZM_L"
                                value={regularLens.PRIZM_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[23] = el)}
                                required
                                onClick={() => openPopUpForLensSelection(3)}
                                // onBlur={() => closePopUpForLensSelection()}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                // onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                name="BASE_L"
                                value={regularLens.BASE_L}
                                onChange={(e) => handleLensPwrChange(e, 1)}
                                onKeyUp={handleKeyDownLens}
                                ref={(el) => (inputRefs.current[24] = el)}
                                required
                                onFocus={(e) => handleFocus(e, 5)}
                                onClick={() => openPopUpForLensSelection(5)}
                                onBlur={() => closePopUpForLensSelection()}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/******************* Second Format ********************/}
                      <div id="LensFormat2" style={{ display: "none" }}>
                        <div className="input-box-SI-2">
                          <div className="input-box-m6">
                            <div
                              className=" space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              {/* <label className="col-sm-1"></label> */}
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                SPH
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                CYL
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                AXIS
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                V/N
                              </label>
                              <label
                                className="col-sm-3"
                                style={{ width: "16.5%" }}
                              >
                                Add
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="input-box-SI-2"
                          style={{ width: "100%" }}
                        >
                          <div className="input-box-m6">
                            <div
                              className="space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              <label className="col-sm-4 "> R</label>
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                onFocus={(e) => handleFocus(e, 1)}
                                inputMode="none"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2">
                          <div className="input-box-m6">
                            <div
                              className="space-evenly row mt-2"
                              style={{ width: "100%" }}
                            >
                              <label className="col-sm-4 "> L</label>
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                              <input
                                autoComplete="off"
                                className="pwrInput col-sm-4 table-input BorderBottomsaletable-input1 box-size1 "
                                style={{ width: "16.5%" }}
                                type="text"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/********************/}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/********************************* For Cont *************************/}
            {tabcontent2 && (
              <div className="tab-content-2">
                <div className="LensOverflow">
                  <div className="input-box-top-head">
                    <div className="input-box-head-left1">
                      <div className="input-box-head-1">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={contactLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 2)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={contactLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 2)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={contactLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={contactLens.CYL_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                value={contactLens.AXS_DV_R}
                                onBlur={(e) => onBlurCheckValueAxis(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={contactLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={contactLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={contactLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_L"
                                value={contactLens.AXS_DV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={contactLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={contactLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                value={contactLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={contactLens.AXS_NV_R}
                                onBlur={(e) => onBlurCheckValueAxis(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_R"
                                style={{ marginRight: "12px" }}
                                value={contactLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={contactLens.SPH_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={contactLens.CYL_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={contactLens.AXS_NV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 2)}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={contactLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                value={contactLens.ADD_R}
                                onBlur={handleLAddRLeave}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                B&D
                              </label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="BASE_R"
                                value={contactLens.BASE_R}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="DAI_R"
                                value={contactLens.DAI_R}
                                style={{ marginRight: "12px" }}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                onBlur={handleLAddLLeave}
                                value={contactLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <label className="col-sm-4 BorderBottomsale">
                                B&D
                              </label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="BASE_L"
                                value={contactLens.BASE_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="DIA_L"
                                value={contactLens.DIA_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Rem.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-5 table-input  BorderBottomsale"
                                type="text"
                                name="REM_R"
                                value={contactLens.REM_R}
                                style={{ marginRight: "12px" }}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-5 table-input  BorderBottomsale"
                                type="text"
                                name="REM_L"
                                value={contactLens.REM_L}
                                onChange={(e) => handleLensPwrChange(e, 2)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lens-invoice-division">
                        <button onClick={btnBySphEqui_Click}>
                          By SPH Equivalent
                        </button>
                        <button onClick={btn_By_sph_Click}>By SPH</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/************************************ For Trans *********************************/}
            {tabcontent3 && (
              <div className="tab-content-3">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div className="">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={trnsposeLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={trnsposeLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                      </div>
                      <div
                        className="input-box-SI-2 padding-1 "
                        style={{ height: "22px", marginTop: "3px" }}
                      >
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">PWR</label>
                            <label className="col-sm-4 ">SPH</label>
                            <label className="col-sm-4 ">CYL</label>
                            <label className="col-sm-4 ">AXIS</label>
                            <label
                              className="col-sm-4 "
                              style={{ marginRight: "12px" }}
                            >
                              V/N
                            </label>
                            <label className="col-sm-4 ">SPH</label>
                            <label className="col-sm-4 ">CYL</label>
                            <label className="col-sm-4 ">AXIS</label>
                            <label className="col-sm-4 ">V/N</label>
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">D.V.</label>
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_DV_R"
                              value={trnsposeLens.SPH_DV_R}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_DV_R"
                              value={trnsposeLens.CYL_DV_R}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_DV_R"
                              value={trnsposeLens.AXS_DV_R}
                              onBlur={(e) => onBlurCheckValueAxis(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_DV_R"
                              style={{ marginRight: "12px" }}
                              value={trnsposeLens.VN_DV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />

                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_DV_L"
                              value={trnsposeLens.SPH_DV_L}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_DV_L"
                              value={trnsposeLens.CYL_DV_L}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_DV_L"
                              value={trnsposeLens.AXS_DV_L}
                              onBlur={(e) => onBlurCheckValueAxis(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_DV_L"
                              value={trnsposeLens.VN_DV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className="">
                            <label className="col-sm-4 ">N.V.</label>
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_NV_R"
                              value={trnsposeLens.SPH_NV_R}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_NV_R"
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              value={trnsposeLens.CYL_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_NV_R"
                              value={trnsposeLens.AXS_NV_R}
                              onBlur={(e) => onBlurCheckValueAxis(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              style={{ marginRight: "12px" }}
                              name="VN_NV_R"
                              value={trnsposeLens.VN_NV_R}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />

                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="SPH_NV_L"
                              value={trnsposeLens.SPH_NV_L}
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="CYL_NV_L"
                              onBlur={(e) => onBlurCheckValueSPHCYL(e, 3)}
                              value={trnsposeLens.CYL_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="AXS_NV_L"
                              value={trnsposeLens.AXS_NV_L}
                              onBlur={(e) => onBlurCheckValueAxis(e, 3)}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="VN_NV_L"
                              value={trnsposeLens.VN_NV_L}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-box-SI-2 ">
                        <div className="input-box-m6">
                          <div className=" row-m1">
                            <label className="col-sm-4">Add</label>
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="ADD_R"
                              value={trnsposeLens.ADD_R}
                              onBlur={handleLAddRLeave}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                            <div
                              className="col-sm-4 "
                              style={{ marginRight: "12px" }}
                            ></div>
                            <input
                              autoComplete="off"
                              className="col-sm-4 table-input BorderBottomsale"
                              type="text"
                              name="ADD_L"
                              value={trnsposeLens.ADD_L}
                              onBlur={handleLAddLLeave}
                              onChange={(e) => handleLensPwrChange(e, 3)}
                              required
                            />
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                            <div className="col-sm-4 "></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*************************************** AR *******************************************/}
            {tabcontent4 && (
              <div className="tab-content-4">
                <div className="">
                  <div className="input-box-top-head">
                    <div className="input-box-head-left1">
                      <div className="input-box-head-1">
                        <div
                          style={{ alignItems: "center" }}
                          className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                        >
                          <div className="input-containerEffect mt-1 width-righteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_RIGHT"
                              value={ARLens.ITEM_RIGHT}
                              onChange={(e) => handleLensPwrChange(e, 4)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Right
                            </label>
                          </div>
                          <div className="input-containerEffect mt-1 width-lefteye">
                            <input
                              autoComplete="off"
                              className="inputEffect modify_im_input input-s1 BorderBottomsale"
                              type="text"
                              name="ITEM_LEFT"
                              value={ARLens.ITEM_LEFT}
                              onChange={(e) => handleLensPwrChange(e, 4)}
                              required
                            />
                            <label id="input1" className="labelEffect1">
                              Left
                            </label>
                          </div>
                          <i
                            onClick={OpenEyeModal}
                            id="step-2"
                            className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                          ></i>
                        </div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={ARLens.SPH_DV_R}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={ARLens.CYL_DV_R}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                onBlur={(e) => onBlurCheckValueAxis(e, 4)}
                                value={ARLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={ARLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={ARLens.SPH_DV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={ARLens.CYL_DV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                onBlur={(e) => onBlurCheckValueAxis(e, 4)}
                                name="AXS_DV_L"
                                value={ARLens.AXS_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={ARLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={ARLens.SPH_NV_R}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                value={ARLens.CYL_NV_R}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={ARLens.AXS_NV_R}
                                onBlur={(e) => onBlurCheckValueAxis(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                style={{ marginRight: "12px" }}
                                name="VN_NV_R"
                                value={ARLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={ARLens.SPH_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={ARLens.CYL_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={ARLens.AXS_NV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 4)}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={ARLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                onBlur={handleLAddRLeave}
                                value={ARLens.ADD_R}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              ></div>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                onBlur={handleLAddLLeave}
                                value={ARLens.ADD_L}
                                onChange={(e) => handleLensPwrChange(e, 4)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/***************************************** Im **********************************/}
            {tabcontent5 && (
              <div className="tab-content-1">
                <div className="input-box-top-head">
                  <div className="input-box-head-left1">
                    <div className="input-box-head-1">
                      <div
                        style={{ alignItems: "center" }}
                        className="input-box-SI-1 input-height2 bottm-01 paddingzero"
                      >
                        <div className="input-containerEffect mt-1 width-righteye">
                          <input
                            autoComplete="off"
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_RIGHT"
                            value={IMLens.ITEM_RIGHT}
                            onChange={(e) => handleLensPwrChange(e, 5)}
                            onClick={() => setIsDropdownOpen2(true)}
                            required
                          />
                          <label id="input1" className="labelEffect1">
                            Right
                          </label>
                        </div>
                        <div className="input-containerEffect mt-1 width-lefteye">
                          <input
                            autoComplete="off"
                            className="inputEffect modify_im_input input-s1 BorderBottomsale"
                            type="text"
                            name="ITEM_LEFT"
                            value={IMLens.ITEM_LEFT}
                            onChange={(e) => handleLensPwrChange(e, 5)}
                            required
                            onClick={() => {
                              // console.log("onClick triggered");
                              setIsDropdownOpen3(true);
                            }}
                          />
                          <label id="input1" className="labelEffect1">
                            Left
                          </label>
                        </div>
                        <i
                          onClick={OpenEyeModal}
                          id="step-2"
                          className="fa-solid fa-eye im-icon-2 InvoiceLensSettingicon"
                        ></i>
                      </div>
                      {/**************************************/}
                      <div>
                        <div
                          className="input-box-SI-2 padding-1 "
                          style={{ height: "22px", marginTop: "3px" }}
                        >
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">PWR</label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              >
                                V/N
                              </label>
                              <label className="col-sm-4 ">SPH</label>
                              <label className="col-sm-4 ">CYL</label>
                              <label className="col-sm-4 ">AXIS</label>
                              <label className="col-sm-4 ">V/N</label>
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">D.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_R"
                                value={IMLens.SPH_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_R"
                                value={IMLens.CYL_DV_R}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_R"
                                onBlur={(e) => onBlurCheckValueAxis(e, 5)}
                                value={IMLens.AXS_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_R"
                                style={{ marginRight: "12px" }}
                                value={IMLens.VN_DV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_DV_L"
                                value={IMLens.SPH_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_DV_L"
                                value={IMLens.CYL_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_DV_L"
                                value={IMLens.AXS_DV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_DV_L"
                                value={IMLens.VN_DV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className="">
                              <label className="col-sm-4 ">N.V.</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_R"
                                value={IMLens.SPH_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_R"
                                value={IMLens.CYL_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_R"
                                value={IMLens.AXS_NV_R}
                                onBlur={(e) => onBlurCheckValueAxis(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                style={{ marginRight: "12px" }}
                                name="VN_NV_R"
                                value={IMLens.VN_NV_R}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />

                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="SPH_NV_L"
                                value={IMLens.SPH_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="CYL_NV_L"
                                value={IMLens.CYL_NV_L}
                                onBlur={(e) => onBlurCheckValueSPHCYL(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="AXS_NV_L"
                                value={IMLens.AXS_NV_L}
                                onBlur={(e) => onBlurCheckValueAxis(e, 5)}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="VN_NV_L"
                                value={IMLens.VN_NV_L}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-box-SI-2 ">
                          <div className="input-box-m6">
                            <div className=" row-m1">
                              <label className="col-sm-4">Add</label>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_R"
                                value={IMLens.ADD_R}
                                onBlur={handleLAddRLeave}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div
                                className="col-sm-4 "
                                style={{ marginRight: "12px" }}
                              ></div>
                              <input
                                autoComplete="off"
                                className="col-sm-4 table-input BorderBottomsale"
                                type="text"
                                name="ADD_L"
                                value={IMLens.ADD_L}
                                onBlur={handleLAddLLeave}
                                onChange={(e) => handleLensPwrChange(e, 5)}
                                required
                              />
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                              <div className="col-sm-4 "></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/***************************************** Prevs **********************************/}
            {tabcontent6 && (
              <div className="tab-content-5">
                <div className="">
                  <div className="input-box-m8">
                    <table className="table-scrol1" style={{ width: "95%" }}>
                      <thead className="table-tbody">
                        <tr>
                          <th className="lensTableHeader" rowSpan="2">
                            SN.
                          </th>
                          <th className="lensTableHeader" rowSpan="2">
                            Vch Dtl
                          </th>
                          <th className="lensTableHeader" colSpan="4">
                            Right Lens
                          </th>
                          <th className="lensTableHeader" colSpan="4">
                            Left Lens
                          </th>
                        </tr>
                        <tr>
                          <th className="lensTableHeader">SPH</th>
                          <th className="lensTableHeader">CYL</th>
                          <th className="lensTableHeader">AXIS</th>
                          <th className="lensTableHeader">ADD</th>
                          <th className="lensTableHeader">SPH</th>
                          <th className="lensTableHeader">CYL</th>
                          <th className="lensTableHeader">AXIS</th>
                          <th className="lensTableHeader">ADD</th>
                        </tr>
                      </thead>
                      <tbody className="table-container-tbody"></tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/************************************************************************/}
        <div id="tabs si-size-2" className="si-size-2">
          <div
            className="tab-btn-box2 tab-b2"
            style={{ backgroundColor: "#5e709e" }}
          >
            <div
              className="radio-btn-1"
              style={{
                width: "100%",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              {/* <div className="flex">
                  <input
autoComplete="off"
                  type="radio"
                  id="All"
                  className="radio-box-1 box-size1"
                  name="options"
                  value="All"
                />
                <span
                  For="All"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; All
                </span>
              </div>

              <div className="flex">
                  <input
autoComplete="off"
                  type="radio"
                  id="DV"
                  className="radio-box-1 box-size1"
                  name="options"
                  value="DV"
                />
                <span
                  For="DV"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; DV
                </span>
              </div>

              <div className="flex">
                  <input
autoComplete="off"
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="NV"
                  name="options"
                  value="NV"
                />
                <span
                  For="NV"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; NV
                </span>
              </div>

              <div className="flex">
                  <input
autoComplete="off"
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="CL"
                  name="options"
                  value="CL"
                />
                <span
                  For="CL"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; CL
                </span>
              </div>

              <div className="flex">
                  <input
autoComplete="off"
                  type="radio"
                  className="radio-box-1 box-size1"
                  id="Trans"
                  name="options"
                  value="Trans"
                />
                <span
                  For="Trans"
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                  }}
                >
                  &nbsp; Trans
                </span>
              </div> */}

              {/* <label htmlFor="" style={{ color: "white" }}>
                Status for print :
              </label> */}
              <div className="flex">
                <input
                  autoComplete="off"
                  type="checkbox"
                  className="radio-box-1 box-size1"
                  name="Rdata"
                  value={lensOtherInfo.Rdata}
                  checked={lensOtherInfo.Rdata}
                  style={{ width: "16px" }}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                />
                <span
                  htmlFor="R" // Corrected `For` to `htmlFor`
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                    width: "15px",
                  }}
                >
                  &nbsp; R
                </span>
              </div>
              <div className="flex">
                <input
                  autoComplete="off"
                  type="checkbox"
                  className="radio-box-1 box-size1"
                  name="Ldata"
                  value={lensOtherInfo.Ldata}
                  checked={lensOtherInfo.Ldata}
                  style={{ width: "16px" }}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                />
                <span
                  htmlFor="L" // Corrected `For` to `htmlFor`
                  style={{
                    fontWeight: "600",
                    color: "white",
                    fontSize: "11px",
                    width: "15px",
                  }}
                >
                  &nbsp; L
                </span>
              </div>

              <select
                name="LenseStatus"
                id=""
                value={lensOtherInfo.LenseStatus}
                className="LensSelectioninvoice"
                onChange={(e) => handleLensOtherInfoChange(e)}
              >
                <option value="">Status print</option>
                <option value="All">All</option>
                <option value="NV">NV</option>
                <option value="DV">DV</option>
                <option value="CL">CL</option>
                <option value="Trans">Trans</option>
              </select>

              <select
                name="LenseStatus"
                id="Add_Lens"
                value={selectedLens}
                className="LensSelectioninvoice"
                onChange={(e) => FetchlensData(e)}
              >
                {selectedLens == "default" ? (
                  <option value="default">Saved Lenses</option>
                ) : (
                  <option value="default">Add Lens</option>
                )}
                {LensCount.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.partyName} -- {item.frameName}
                  </option>
                ))}
              </select>

              <div className="Uploadprescription-lens">
                {lensOtherInfo.imgId == 0 ? (
                  <>
                    <span>Upload Prescription</span>
                    <input type="file" onChange={UploadImagePrescription} />
                  </>
                ) : (
                  <span onClick={ViewPresImg}>View image</span>
                )}
              </div>
            </div>
          </div>

          <div className="space-evenly mobileFlex-direction">
            <div className="lensInfoLeft-box">
              <div
                className="input-containerEffect w-100-48"
                style={{ marginTop: "8px" }}
              >
                <input
                  autoComplete="off"
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="LensType"
                  value={lensOtherInfo.LensType}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[25] = el)}
                  required
                  onFocus={closePopUpForLensSelection}
                />
                <label id="input1" className="labelEffect1">
                  Lens Type
                </label>
              </div>
              <div
                className="input-containerEffect mt-2 w-100-48"
                // style={{ margin: "1%" }}
              >
                <input
                  autoComplete="off"
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="PrescribedBy"
                  value={lensOtherInfo.PrescribedBy}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[26] = el)}
                  required
                />
                <label id="input1" className="labelEffect1">
                  Prescribed By
                </label>
              </div>
              <div
                className="input-containerEffect mt-2 w-100-48"
                // style={{ margin: "1%" }}//
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="PartyName"
                  value={lensOtherInfo.PartyName}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={(e) => {
                    handleKeyDown_AccountSuggestion(e, filteredAccount);
                  }}
                  ref={(el) => (inputRefs.current[27] = el)}
                  required
                  autoComplete="off"
                  // onFocus={(e) => openOnFocusSuggestion(e)}
                  onBlur={() => removeModalSuggestion()}
                />
                <label id="input1" className="labelEffect1">
                  Party Name
                </label>

                {isDropdownOpen1 && (
                  <ul
                    className="ItemmasterDuggestionsaleinvoice"
                    ref={suggestionBoxRef}
                    style={{ position: "absolute" }}
                  >
                    {filteredAccount.map((Acc, index) => (
                      <li
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)}
                        onClick={() =>
                          handleAccountSelection(Acc.id, Acc, "PartyName")
                        }
                        style={{
                          // padding: "2px 5px",
                          // cursor: "pointer",
                          // borderBottom: "1px solid #ccc",
                          backgroundColor:
                            highlightedIndex === index
                              ? "#e6f7ff"
                              : "transparent",
                        }}
                      >
                        {Acc.name}
                      </li>
                    ))}
                    <li
                      key={filteredAccount.length}
                      ref={(el) =>
                        (itemRefs.current[filteredAccount.length] = el)
                      }
                      className="addNew_btn_saleInvoice"
                      onClick={() => openAccountMasterModal("Add")}
                      style={{
                        borderBottom: "1px solid #ccc",
                        backgroundColor:
                          highlightedIndex === filteredAccount.length
                            ? "#e6f7ff"
                            : "#5e709e",
                      }}
                    >
                      Add New <i class="fa-solid fa-plus"></i>
                    </li>
                  </ul>
                )}
              </div>
              <div
                className="input-containerEffect mt-2 w-100-48"
                // style={{ margin: "1%" }}
              >
                <input
                  className="inputEffect modify_im_input input-s1 BorderBottomsale"
                  type="text"
                  name="FrameName"
                  value={lensOtherInfo.FrameName}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={(e) => {
                    handleKeyDown_AccountSuggestion(e, itemMasterRows);
                  }}
                  onFocus={(e) => openOnFocusSuggestion(e)}
                  ref={(el) => (inputRefs.current[28] = el)}
                  required
                  onBlur={() => removeModalSuggestion()}
                  autoComplete="off"
                />
                <label id="input1" className="labelEffect1">
                  Frame Name
                </label>
                {itemMasterRows.length >= 1 && isDropdownOpen4 && (
                  <ul
                    className="ItemmasterDuggestionsaleinvoice"
                    ref={suggestionBoxRef}
                    style={{ position: "absolute" }}
                  >
                    {itemMasterRows.map((Data, index) => (
                      <>
                        {Data.productName && (
                          <li
                            key={index}
                            ref={(el) => (itemRefs.current[index] = el)}
                            onClick={() =>
                              handleFrameSelection(Data, "FrameName")
                            }
                            style={{
                              // padding: "2px 5px",
                              // cursor: "pointer",
                              // borderBottom: "1px solid #ccc",
                              backgroundColor:
                                highlightedIndex === index
                                  ? "#e6f7ff"
                                  : "transparent",
                            }}
                          >
                            {Data.productName}
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="lensInfoRight-box">
              <div className="input-box-m9 dis-flex mt-2">
                <span
                  className="label-box-si-12 sol1"
                  style={{ textAlign: "right" }}
                >
                  NV &nbsp; &nbsp;
                </span>

                <div className="input-box-m1" style={{ width: "30%" }}>
                  <select
                    className="modify_im_input mode_limit mode_category input-s1 box-size1 BorderBottomsale"
                    type="text"
                    name="NvVal"
                    value={lensOtherInfo.NvVal}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyUp={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[29] = el)}
                    required
                  >
                    <option Classname="form-control s-1" value="">
                      NA
                    </option>
                    <option Classname="form-control s-1" value="1w">
                      1W
                    </option>
                    <option Classname="form-control s-1" value="1m">
                      1M
                    </option>
                    <option Classname="form-control s-1" value="3m">
                      3M
                    </option>
                    <option Classname="form-control s-1" value="6m">
                      6M
                    </option>
                    <option Classname="form-control s-1" value="1y">
                      1Y
                    </option>
                  </select>
                </div>
                <input
                  autoComplete="off"
                  className="modify_im_input mode_category input-s1 BorderBottomsale"
                  type="DATE"
                  style={{ width: "52%" }}
                  name="NvDt"
                  value={lensOtherInfo.NvDt}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[30] = el)}
                  required
                />
              </div>
              <div className="input-box-m9 dis-flex mt-2">
                <span
                  className="label-box-si-12 sol1"
                  style={{ textAlign: "right" }}
                >
                  CLE &nbsp; &nbsp;
                </span>
                <div className="input-box-m1" style={{ width: "30%" }}>
                  <select
                    className="modify_im_input mode_limit mode_category input-s1 box-size1 BorderBottomsale"
                    type="text"
                    name="CleVal"
                    value={lensOtherInfo.CleVal}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyUp={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[31] = el)}
                    required
                  >
                    <option Classname="form-control s-1" value="">
                      NA
                    </option>
                    <option Classname="form-control s-1" value="1w">
                      1W
                    </option>
                    <option Classname="form-control s-1" value="1m">
                      1M
                    </option>
                    <option Classname="form-control s-1" value="3m">
                      3M
                    </option>
                    <option Classname="form-control s-1" value="6m">
                      6M
                    </option>
                    <option Classname="form-control s-1" value="1y">
                      1Y
                    </option>
                  </select>
                </div>
                <input
                  autoComplete="off"
                  className="modify_im_input mode_category input-s1 BorderBottomsale"
                  type="DATE"
                  style={{ width: "52%" }}
                  name="CleDt"
                  value={lensOtherInfo.CleDt}
                  onChange={(e) => handleLensOtherInfoChange(e)}
                  onKeyUp={handleKeyDownLens}
                  ref={(el) => (inputRefs.current[32] = el)}
                  required
                />
              </div>

              <div className="input-box-m9 dis-flex mt-2">
                <div
                  className="flex-end sale-in-lens-mobile"
                  style={{ width: "100%" }}
                >
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      autoComplete="off"
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdRL"
                      value={lensOtherInfo.PdRL}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyUp={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[33] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className=" labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD RL
                    </label>
                  </div>
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      autoComplete="off"
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdR"
                      value={lensOtherInfo.PdR}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyUp={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[34] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className="  labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD R
                    </label>
                  </div>
                  <div
                    className="input-containerEffect w-30"
                    style={{ margin: "1%" }}
                  >
                    <input
                      autoComplete="off"
                      className="inputEffect modify_im_input input-s1 BorderBottomsale"
                      type="text"
                      name="PdL"
                      value={lensOtherInfo.PdL}
                      onChange={(e) => handleLensOtherInfoChange(e)}
                      onKeyUp={handleKeyDownLens}
                      ref={(el) => (inputRefs.current[35] = el)}
                      required
                    />
                    <label
                      id="input1"
                      className=" labelEffect1"
                      style={{ fontSize: "12px" }}
                    >
                      PD L
                    </label>
                  </div>
                </div>
              </div>
              <div
                className="flex-start"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <div
                  className="input-containerEffect w-30"
                  style={{ margin: "1%" }}
                >
                  <input
                    autoComplete="off"
                    className="inputEffect modify_im_input input-s1 BorderBottomsale inDays"
                    type="number"
                    name="DelivDay"
                    value={lensOtherInfo.DelivDay}
                    onChange={(e) => {
                      handleLensOtherInfoChange(e);
                      handleDelivDayChange(e);
                    }}
                    onBlur={(e)=>handleDelivDayChange(e, true)}
                    onKeyUp={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[36] = el)}
                    required
                    onFocus={()=>setIssavingLens(true)}
                  />
                  <label id="input1" className=" labelEffect1">
                    Deli.Days{" "}
                  </label>
                </div>
                <div
                  className="input-containerEffect w-30"
                  style={{ margin: "1%" }}
                >
                  <input
                    autoComplete="off"
                    className="inputEffect modify_im_input input-s1 BorderBottomsale inTime"
                    type="time"
                    name="DelivTime"
                    value={lensOtherInfo.DelivTime}
                    onChange={(e) => handleLensOtherInfoChange(e)}
                    onKeyUp={handleKeyDownLens}
                    ref={(el) => (inputRefs.current[37] = el)}
                    required
                  />
                  <label
                    id="input1"
                    className="labelEffect1"
                    style={{ fontSize: "12px", padding: "0px" }}
                  >
                    Time
                  </label>
                </div>
                <div
                  className="input-box-s12"
                  // style={{ width: "74px", justifyContent: "center" }}
                >
                  <div
                    className="flex-end mt-1"
                    style={{ position: "relative", justifyContent: "center" }}
                  >
                    {selectedLens != "default" ? (
                      <>
                        <button
                          onClick={saveLensInfoInList}
                          className="lensSectionInvoiceButton"
                        >
                          Update
                        </button>
                        <i
                          onClick={DeleteTransLensData}
                          class="fa-solid fa-trash lensSectionInvoiceIcon"
                        ></i>
                        {/* <i class="fa-solid fa-trash-can lensSectionInvoice Icon"></i> */}
                      </>
                    ) : (
                      <>
                        <i
                          className="fa-solid fa-square-plus"
                          onMouseEnter={() => setIsHovered9(true)}
                          onMouseLeave={() => setIsHovered9(false)}
                          onClick={saveLensInfoInList}
                          style={{ color: "darkgreen" }}
                        ></i>
                        &nbsp; &nbsp;
                        {isHovered9 && (
                          <div className="hover-text1">Add Lens</div>
                        )}
                        <br />
                        <i
                          className="fa-solid fa-rotate-left"
                          onMouseEnter={() => setIsHovered10(true)}
                          onMouseLeave={() => setIsHovered10(false)}
                          style={{ color: "#ff4163" }}
                          onClick={ResetLensdata}
                        ></i>
                        {isHovered10 && (
                          <div
                            className="hover-text1"
                            // style={{ top: "25%", right: "5%" }}
                          >
                            Reset
                          </div>
                        )}
                      </>
                    )}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*********************************/}
        </div>
      </div>
      <div className="">
        <div className="">
          <div></div>
        </div>
      </div>

      {PopUpSelectingLens && (
        <PopforSelectingLens
          setSelectedValue={setSelectedValue}
          selectedValue={selectedValue}
          lensType={LensTypeNumber}
          NameOfSelectedLens={NameOfSelectedLens}
          closePopUpForLensSelection={closePopUpForLensSelection}
          handleClick={handleClick}
          selectedPopup={selectedPopup}
          setSelectedPopup={setSelectedPopup}
          FocusPrevInput={FocusPrevInput}
          FocusNextInput={FocusNextInput}
        />
      )}

      {/**************************** Eye/Lens Modal ********************/}
      {EyeModal && (
        <div className="modal-back">
          <div
            className="partyDetail-modal"
            style={{ height: "auto", width: "22%" }}
          >
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label style={{ color: "white" }}>Select the format</label>
                <label
                  className="closeBtnhover "
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={CloseEyeModal}
                >
                  x
                </label>
              </div>
              <div className="radiolabel_box">
                <label className="radio-label">
                  <input
                    autoComplete="off"
                    type="radio"
                    value="Format1"
                    checked={selectedOption === "Format1"}
                    onChange={handleOptionChange}
                    className="large-radio"
                    style={{ boxShadow: "none" }}
                  />
                  &nbsp; With NV
                </label>

                <label className="radio-label">
                  <input
                    autoComplete="off"
                    type="radio"
                    value="Format2"
                    checked={selectedOption === "Format2"}
                    onChange={handleOptionChange}
                    className="large-radio"
                    style={{ boxShadow: "none" }}
                  />
                  &nbsp; Without NV
                </label>
              </div>
              {/* <div className="flex-end">
                <button className="close-btn1" onClick={CloseEyeModal}>
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
      {PresImgModal && (
        <div className="modal-back" onClick={ViewPresImg}>
          <img
            className="PresImageView"
            src={`${imageBaseUrl}${lensOtherInfo.imgId}`}
            alt=""
          />
          <button className="PresImageViewDelete" onClick={()=>DeletePrescImage(lensOtherInfo.imgId)}>Delete Prescription Image</button>
        </div>
      )}
    </div>
  );
}

export default LensInfoForInvoice;
