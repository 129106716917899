import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url,
  WebApiUrl = express_server_url.replace("localhost", window.location.hostname)

const MiscellaneousMaster = {

  GetMiscellaneousMaster: async (controlType) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster/GetMiscellaneous?ControlType=${controlType}`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        // console.log(apiUrl);
        console.log("Response:", response.data.result);
        return response.data.result;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  InsertMiscellaneousMaster: async (data) => {
    try {
      console.log(data);
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster`;
      const response = await axios.post(
        apiUrl,
        {
          controlType: Number(data.controlType),
          name: data.name,
          printName: data.printName,
          availableFrom: data.availableFrom + ":00",
          availableTo: data.availableTo + ":00",
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  GetMiscellaneousMasterById: async (MiscellaneousMasterId, ControlType) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${MiscellaneousMasterId}&ControlType=${ControlType}`;
        const response = await axios.get(apiUrl, {}, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  deleteMiscellaneousMaster: async (MiscellaneousMasterId, ControlType) => {
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${MiscellaneousMasterId}&ControlType=${ControlType}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  updateMiscellaneousMaster: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/MiscellaneousMaster?MiscellaneousMasterId=${data.id}&ControlType=${data.controlType}`;
      console.log(data);
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          controlType: Number(data.controlType),
          name: data.name,
          printName: data.printName,
          availableFrom: data.availableFrom,
          availableTo: data.availableTo,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
};

export default MiscellaneousMaster;
