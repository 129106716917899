import axios from '../axiosConfig';
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const UnitMaster = {
    insertUnitMaster: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster/UnitMaster`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                unitName: data.name,
                printName: data.printName,
                eWayBillUnitName: data.eWayBillUnitName,
                //mainUnit: data.mainUnit,
                altUnit: Number(data.altUnit),
                //conversionUnit: data.conversionUnit,
                conversionFactor: Number(data.conversionFactor),
                conversionValue: Number(data.conversionValue),
                isDefautUnit: false,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getUnitMaster: async (
        filter = [
            {
                fieldName: "string",
                operatorName: "string",
                compareValue: "string",
            },
        ]
    ) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster/GetUnitMasters`;
            const response = await axios.post(apiUrl, {
                filter: filter,
                order: [
                    {
                        propertyName: "string",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 50000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },
    // ************************* Get All ***************************
    getUnitMasterFilter: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/UnitMaster/GetUnitMasters`;
                const response = await axios.post(apiUrl, {
                    filter: [],
                    order: [
                        {
                            propertyName: "id",
                            ascending: false
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 500000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteUnitMaster: async (unitId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${unitId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get By Id ****************************
    getUnitMasterById: async (unitId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${unitId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // *************************** Update ***************************
    updateUnitMaster: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/UnitMaster?unitId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                printName: data.printName,
                eWayBillUnitName: data.eWayBillUnitName,
                mainUnit: data.mainUnit,
                altUnit: data.altUnit,
                conversionUnit: data.conversionUnit,
                conversionFactor: data.conversionFactor,
                conversionValue: data.conversionValue,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default UnitMaster;
