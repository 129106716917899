import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import MyContext from "../../MyContext";
import TransactionTypeSettingApi from "../../../API/APIServices/TransactionTypeSetting";
import DataTable from "../TableMaster/DataTable";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import AccountGroup from "../../../API/APIServices/AccountGroup";
import Account from "../../../API/APIServices/Account";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import UserMaster from "../Userdetails/UserMaster";
import UserCredential from "../../../API/APIServices/UserCredential";
import Department from "../../../API/APIServices/Department";
import ModalBoxMaster from "../../Props/ModalBoxMaster";

function TransactionTypeSetting(props) {
  const [loading, setLoading] = useState(false);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    input1,
    input2,
    setAlertBoxDelete,
    alertBoxDelete,
    OpenAlertBoxDelete,
  } = useContext(MyContext);
  // const [filteredAccount, setFilteredAccount] = useState([]);

  // useEffect(() => {
  //   const filterAccountdata = async () => {
  //     const filteredData = [
  //       {
  //         fieldName: "parentGroupId",
  //         operatorName: "notequal",
  //         compareValue: 211,
  //       },
  //       {
  //         fieldName: "parentGroupId",
  //         operatorName: "notequal",
  //         compareValue: 212,
  //       },
  //     ];
  //     var data = await Account.getAccountWithFilter(filteredData);
  //     if (data.length > 0) {
  //       setFilteredAccount(data);
  //     }
  //   };
  //   filterAccountdata();
  // }, []);
  /***********title***************/
  useState(() => {
    document.title = "Transaction Behaviour Setting";
  }, []);

  const [TransactypaTypemasterList, setTransactypaTypemasterList] = useState(
    []
  );

  const filterdata = async () => {
    var data = await TransactionTypeSettingApi.GetAllTransactionTypeMaster();
    if (data.length > 0) {
      setTransactypaTypemasterList(data);
    }
  };
  useEffect(() => {
    filterdata();
  }, []);

  //**********{control modal box 1}*****************//

  //*****************{modal box}*****************//
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  /***********End Enter code*****************/

  // const input1Ref = useRef(null);
  // const input2Ref = useRef(null);

  // const handleKeyPress = (event, nextInputRef) => {
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         nextInputRef.current.focus();
  //     }
  // };

  /****************************************************** */

  const [errorMessages, setErrorMessages] = useState({
    transName: "",
    extraField1: "",
    extraField2: "",
    extraField3: "",
  });

  const [inputValues, setInputValues] = useState({
    id: 0,
    transType: 0,
    transName: "string",
    transAccount: 216,
    enableStockCalculation: true,
    stockNature: true,
    transAccTaxAccAssetsAcc: true,
    transAccTaxAccAssetsAccSign: true,
    otherSettlementAcc: true,
    otherSettlementAccSign: true,
    cashFlow: true,
    cashFlowSign: true,
    settlementAmountNature: "string",
    enableLedgerCalculation: true,
    enableTax: true,
    taxType: true,
    price: 0,
    enableSellingPrice: true,
    sellingPrice: 0,
    invoiceType: 0,
    selectForAllowItemGroups: "",
    selectForAllowUserDepartment: "",
    selectForAllowUserMaster: "",
    enableBarcodeGeneration: 0,
    enableSerialNoGeneration: 0,
    enableBatchNoEntry: 0,
    extraField1: "",
    extraField2: "",
  });

  const [TransctionModal, setTransctionModal] = useState(false);

  const showModal3 = () => {
    setTransctionModal(!TransctionModal);
  };

  const CloseModal3 = () => {
    setTransctionModal(!TransctionModal);
    filterdata();
  };

  useEffect(() => {
    console.log(inputValues);
  }, [inputValues]);

  useEffect(() => {
    GetDettingTransactionById();
  }, []);

  const GetDettingTransactionById = async (event , copy) => {

    var filterdata = [
      {
        fieldName: "transType",
        operatorName: "equal",
        compareValue: event?.target?.value || 12,
      },
    ];

    var data = await TransactionTypeSettingApi.getTransactionTypeSettingFilter(
      filterdata
    );
    debugger;
    if (data && data.length > 0) {
      if(copy){
        setInputValues((prevValues) => ({
          ...prevValues,
          // id: "",
          // transType: "",
          // transName: "",
          transAccount: data[0].transAccount,
          enableStockCalculation: data[0].enableStockCalculation,
          stockNature: data[0].stockNature,
          transAccTaxAccAssetsAcc: data[0].transAccTaxAccAssetsAcc,
          transAccTaxAccAssetsAccSign: data[0].transAccTaxAccAssetsAccSign,
          otherSettlementAcc: data[0].otherSettlementAcc,
          otherSettlementAccSign: data[0].otherSettlementAccSign,
          cashFlow: data[0].cashFlow,
          cashFlowSign: data[0].cashFlowSign,
          settlementAmountNature: data[0].settlementAmountNature,
          enableLedgerCalculation: data[0].enableLedgerCalculation,
          enableTax: data[0].enableTax,
          taxType: data[0].taxType,
          price: data[0].price,
          enableSellingPrice: data[0].enableSellingPrice,
          sellingPrice: data[0].sellingPrice,
          invoiceType: data[0].invoiceType,
          selectForAllowItemGroups: data[0].selectForAllowItemGroups,
          selectForAllowUserDepartment: data[0].selectForAllowUserDepartment,
          selectForAllowUserMaster: data[0].selectForAllowUserMaster,
          enableBarcodeGeneration: data[0].enableBarcodeGeneration,
          enableSerialNoGeneration: data[0].enableSerialNoGeneration,
          enableBatchNoEntry: data[0].enableBatchNoEntry,
          extraField1: data[0].extraField1,
          extraField2: data[0].extraField2,
        }));
        setEdit(false);
      }else{
        setInputValues((prevValues) => ({
          ...prevValues,
          id: data[0].id,
          transType: data[0].transType,
          transName: data[0].transName,
          transAccount: data[0].transAccount,
          enableStockCalculation: data[0].enableStockCalculation,
          stockNature: data[0].stockNature,
          transAccTaxAccAssetsAcc: data[0].transAccTaxAccAssetsAcc,
          transAccTaxAccAssetsAccSign: data[0].transAccTaxAccAssetsAccSign,
          otherSettlementAcc: data[0].otherSettlementAcc,
          otherSettlementAccSign: data[0].otherSettlementAccSign,
          cashFlow: data[0].cashFlow,
          cashFlowSign: data[0].cashFlowSign,
          settlementAmountNature: data[0].settlementAmountNature,
          enableLedgerCalculation: data[0].enableLedgerCalculation,
          enableTax: data[0].enableTax,
          taxType: data[0].taxType,
          price: data[0].price,
          enableSellingPrice: data[0].enableSellingPrice,
          sellingPrice: data[0].sellingPrice,
          invoiceType: data[0].invoiceType,
          selectForAllowItemGroups: data[0].selectForAllowItemGroups,
          selectForAllowUserDepartment: data[0].selectForAllowUserDepartment,
          selectForAllowUserMaster: data[0].selectForAllowUserMaster,
          enableBarcodeGeneration: data[0].enableBarcodeGeneration,
          enableSerialNoGeneration: data[0].enableSerialNoGeneration,
          enableBatchNoEntry: data[0].enableBatchNoEntry,
          extraField1: data[0].extraField1,
          extraField2: data[0].extraField2,
        }));
     
        setEdit(true); }
 
    }
  };

  const handleInputChange = (event , copy) => {
    const { name, value } = event.target;
    if(copy){
      if (name == "transType") {
        GetDettingTransactionById(event , true);
      }
    }
    else{
      debugger;
      console.log(`Changing ${name} to ${value}`);
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
        transName: name === "name" ? value : prevInputValues.transName,
      }));
      setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  
      if (name == "transType") {
        GetDettingTransactionById(event);
      }
    }
  };




  const [edit, setEdit] = useState(false);

  const handleInputChangeActive = (e) => {
    const { name, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : e.target.value;

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: type === "checkbox" ? !prevInputValues[name] : newValue,
    }));

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    console.log(inputValues);
  };

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const validateInput = (fieldName) => {
    console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }
    setErrorMessages((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    return true;
  };

  //  const fieldNames = ['transType', 'transName', 'amountNature', 'stockNature', 'addInLedger', 'extraField1', 'extraField2', 'extraField3', 'extraField4', 'extraField5'];
  const fieldNames = [
    "transType",
    "transName",
    "amountNature",
    "stockNature",
    "addInLedger",
    "extraField1",
    "extraField2",
    "extraField3",
    "extraField4",
    "extraField5",
  ];

  const SaveBtn = async () => {
    // debugger
    // let allValid = true;
    // fieldNames.forEach((fieldName) => {
    //     const isValid = validateInput(fieldName);
    //     if (!isValid) {
    //         allValid = false;
    //     }
    // });
    // if (allValid) {
    setLoading(true);
    if (Boolean(edit) === true) {
      const isSuccess =
        await TransactionTypeSettingApi.updateTransactionTypeSetting(
          inputValues
        );
      if (isSuccess) {
        // fetchData();
        setEdit(false);
        OpenAlertBoxEdit();
        setLoading(false);
      } else {
        alert("error while updating");
      }
    } else {
      setLoading(true);
      const isSuccess =
        await TransactionTypeSettingApi.insertTransactionTypeSetting(
          inputValues
        );
      if (isSuccess) {
        // fetchData();
        setLoading(false);
        OpenAlertBox();
      } else {
        alert("Error inserting data");
      }
    }
  };
  // }

  useEffect(() => {
    const transNameMapping = {
      12: "Sale",
      13: "Sale Return",
      14: "Purchase",
      15: "Purchase Return",
      71: "Order",
    };
    setInputValues((prevValues) => ({
      ...prevValues,
      transName: transNameMapping[prevValues.transType] || "",
    }));
  }, [inputValues.transType]);

  // ********************************************List Part ******************************

  const [ItemGroupList, setItemGroupList] = useState([]);
  const [UserMasterList, setUserMasterList] = useState([]);
  const [UserDepartmentList, setUserDepartmentList] = useState([]);
  useEffect(() => {
    const fetchItemGroupMaster = async () => {
      try {
        const val = await ItemGroup.GetItemGroup();
        console.log(val);
        setItemGroupList(val);
      } catch (error) {
        console.log(error);
      }
    };

    fetchItemGroupMaster();
  }, []);

  // Handler to manage checkbox change
  const handleCheckboxChangeItemGroup = (e) => {
    const { value, checked } = e.target;

    setInputValues((prevValues) => {
      let updatedItemGroups;

      if (checked) {
        // If checked, add the value to existing IDs (comma-separated string)
        updatedItemGroups = prevValues.selectForAllowItemGroups
          ? `${prevValues.selectForAllowItemGroups},${value}`
          : value;
      } else {
        // If unchecked, remove the value from the existing comma-separated string
        updatedItemGroups = prevValues.selectForAllowItemGroups
          .split(",")
          .filter((id) => id !== value)
          .join(",");
      }

      return {
        ...prevValues,
        selectForAllowItemGroups: updatedItemGroups,
      };
    });
  };

  useEffect(() => {
    const fetchUserCre = async () => {
      try {
        const val = await UserCredential.getUserCredential();
        console.log(val);
        setUserMasterList(val);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserCre();
  }, []);

  // Handler to manage checkbox change
  const handleCheckboxChangeUserDept = (e) => {
    const { value, checked } = e.target;

    setInputValues((prevValues) => {
      let updatedUserDepartment;

      if (checked) {
        // If checked, add the value to existing IDs (comma-separated string)
        updatedUserDepartment = prevValues.selectForAllowUserDepartment
          ? `${prevValues.selectForAllowUserDepartment},${value}`
          : value;
      } else {
        // If unchecked, remove the value from the existing comma-separated string
        updatedUserDepartment = prevValues.selectForAllowUserDepartment
          .split(",")
          .filter((id) => id !== value)
          .join(",");
      }

      return {
        ...prevValues,
        selectForAllowUserDepartment: updatedUserDepartment,
      };
    });
  };
  useEffect(() => {
    const fetchItemGroupMaster = async () => {
      try {
        const val = await Department.getDepartment();
        console.log(val);
        setUserDepartmentList(val);
      } catch (error) {
        console.log(error);
      }
    };

    fetchItemGroupMaster();
  }, []);

  const handleCheckboxChangeUserMaster = (e) => {
    const { value, checked } = e.target;

    setInputValues((prevValues) => {
      let updatedUserMaster;

      if (checked) {
        // If checked, add the value to existing IDs (comma-separated string)
        updatedUserMaster = prevValues.selectForAllowUserMaster
          ? `${prevValues.selectForAllowUserMaster},${value}`
          : value;
      } else {
        // If unchecked, remove the value from the existing comma-separated string
        updatedUserMaster = prevValues.selectForAllowUserMaster
          .split(",")
          .filter((id) => id !== value)
          .join(",");
      }

      return {
        ...prevValues,
        selectForAllowUserMaster: updatedUserMaster,
      };
    });
  };

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false);
    setIsOpen2(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen1(false);
    setIsOpen(false);
    setIsOpen2(!isOpen2);
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target)
  //     ) {
  //       setIsOpen(false);
  //       setIsOpen1(false);
  //       setIsOpen2(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [containerRef]);

  return (
    <div>
      <PageTabs />

      {/* <PageTabs locationTab={"/AccountMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} showModal={showModal} /> */}
      <ModalBoxMaster
        isOpenTransactionType={TransctionModal}
        CloseTransactionType={CloseModal3}
      />

      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Transaction Type Setting</h2>
       
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          SaveButton={SaveBtn}
          isBtnSetting={false}
          showModal={showModal}
        />
      </div>

      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1 item_scroll">
                    <div className="control_radius">
                      <div className="company_info-text-im depart_top" style={{display:"flex", justifyContent:"space-between"
                      }}>
                        Transaction Type Setting
                        <div style={{width:"35%" , display:"flex" , justifyContent:"space-between" , alignItems:"center"}}>
                        <label htmlFor="">
                        Copy From Transaction   
                        </label>
                          <select 
                                className="modify_im_input mode_category1"
                                onChange={(e)=>handleInputChange(e,true)}
                                type="text"
                                name="transType"
                                style={{ width: "60%" }}
                                // value={inputValues.transType}
                                required
                              >
                                <option value="">--Select--</option>
                                {TransactypaTypemasterList.map((item) => (
                                  <option value={item.id}>
                                    {item.masterName}
                                  </option>
                                ))}
                              </select>
                        </div>

                      </div>
                      
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="input-box-mok1"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="transType"
                                style={{ width: "94%" }}
                                value={inputValues.transType}
                                required
                              >
                                {TransactypaTypemasterList.map((item) => (
                                  <option value={item.id}>
                                    {item.masterName}
                                  </option>
                                ))}
                              </select>
                              <label class="modify_p_text2">
                                {/* Transaction Type */}
                              </label>

                              <div onClick={showModal3}>
                                <i class="fa-solid fa-circle-plus dm_4"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="transAccount"
                                value={inputValues.transAccount}
                                required
                              >
                                {/* {filteredAccount.map((Acc, index) => (
                                  <option value={Acc.id}>{Acc.name}</option>
                                ))} */}

                                <option value="216">Sale</option>
                                <option value="215">Purchase</option>
                              </select>
                              <label class="modify_p_text2">
                                Transaction Account
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableStockCalculation"
                                value={inputValues.enableStockCalculation}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Enable Stock Calcultation
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="stockNature"
                                value={inputValues.stockNature}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">Stock Nature</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="transAccTaxAccAssetsAcc"
                                value={inputValues.transAccTaxAccAssetsAcc}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                TransAcc TaxAcc AssetsAcc
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="transAccTaxAccAssetsAccSign"
                                value={inputValues.transAccTaxAccAssetsAccSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Trans Acc Tax Acc Assets Acc Sign
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="otherSettlementAcc"
                                value={inputValues.otherSettlementAcc}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Other Settlement Acc
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="otherSettlementAccSign"
                                value={inputValues.otherSettlementAccSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Other Settlement Acc Sign
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="cashFlow"
                                value={inputValues.cashFlow}
                                onChange={handleInputChange}
                                required
                              >
                                {/* <option value="">Select Amount Nature</option> */}
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              {/* <label class="modify_lbl_text3">Shop</label> */}
                              <label class="modify_p_text2">Cash Flow</label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="cashFlowSign"
                                value={inputValues.cashFlowSign}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              <label class="modify_p_text2">
                                Cash Flow Nautre
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="settlementAmountNature"
                                value={inputValues.settlementAmountNature}
                                onChange={handleInputChange}
                                required
                              >
                                {/* <option value="">Select Amount Nature</option> */}
                                <option value="true">Add</option>
                                <option value="false">Substract</option>
                              </select>
                              {/* <label class="modify_lbl_text3">Shop</label> */}
                              <label class="modify_p_text2">
                                Settlement Amount Nature
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableLedgerCalculation"
                                value={inputValues.enableLedgerCalculation}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Add In Ledger
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableTax"
                                value={inputValues.enableTax}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">Enable Tax</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="taxType"
                                value={inputValues.taxType}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">Tax Type</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="enableSellingPrice"
                                value={inputValues.enableSellingPrice}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                              </select>
                              <label class="modify_p_text2">
                                Enable Selling Price
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="sellingPrice"
                                value={inputValues.sellingPrice}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">Purchase Price</option>
                                <option value="2">Sale Price</option>
                                <option value="3">MRP</option>
                              </select>
                              <label class="modify_p_text2">
                                Selling Price
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="price"
                                value={inputValues.price}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">Purchase Price</option>
                                <option value="2">Sale Price</option>
                                <option value="3">MRP</option>
                              </select>
                              <label class="modify_p_text2">Price</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="invoiceType"
                                value={inputValues.invoiceType}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="0">--Select--</option>
                                <option value="1">OutWards Supply</option>
                                <option value="2">Inwards Supply</option>
                                <option value="3">Consume</option>
                                <option value="4">Production</option>
                                <option value="5">Stock Out</option>
                                <option value="6">Stoct In </option>
                                <option value="7">Request Send</option>
                                <option value="8">Request Recived</option>
                              </select>
                              <label class="modify_p_text2">
                                Invoice Type Nature
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            {/* <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                type="text"
                                name="price"
                                value={inputValues.selectForAllowItemGroups}
                                onChange={handleInputChange}
                                required
                              >
                                <option> 1 </option>
                                <option> 2 </option>
                              </select>
                              <label class="modify_p_text2">
                                Select For Allow Item Groups
                              </label>
                            </div> */}

                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div
                                className="input-box-mok1"
                                onClick={toggleDropdown}
                              >
                                <select
                                  className="modify_im_input mode_category1"
                                  type="checkbox"
                                  required
                                >
                                  <option>Select options</option>
                                </select>
                                <label
                                  className="UseMasterLebalFix"
                                  style={{
                                    position: "absolute",
                                    left: "25px",
                                    top: "-10px",
                                  }}
                                >
                                  Select For Allow Item Groups
                                </label>
                                <div className="overSelect"></div>
                              </div>
                              {isOpen && (
                                <div
                                  id="checkBoxes"
                                  className="dropdown-content"
                                  style={{
                                    left: "22px",
                                    width: "93%",
                                    maxHeight: "210px",
                                    overflowY: "scroll",
                                    padding: "0px",
                                  }}
                                >
                                  {ItemGroupList.map((item, index) => (
                                    <label htmlFor={`second-${index}`}>
                                      <input
                                        className="user_checkbox"
                                        type="checkbox"
                                        id={`second-${index}`}
                                        checked={inputValues.selectForAllowItemGroups
                                          ?.split(",")
                                          .includes(String(item.d2_Id))}
                                        value={item.d2_Id}
                                        onChange={(e) =>
                                          handleCheckboxChangeItemGroup(e)
                                        }
                                        style={{
                                          borderBottom: "1px dolid #eee",
                                        }}
                                      />
                                      {/* Buy X Get Y Free */} {item.name}{" "}
                                      {item.d2_Id}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div
                                className="input-box-mok1"
                                onClick={toggleDropdown1}
                              >
                                <select
                                  className="modify_im_input mode_category1"
                                  type="checkbox"
                                  required
                                >
                                  <option>Select options</option>
                                </select>
                                <label
                                  className="UseMasterLebalFix"
                                  style={{
                                    position: "absolute",
                                    left: "25px",
                                    top: "-10px",
                                  }}
                                >
                                  Select For Allow User Department
                                </label>
                                <div className="overSelect"></div>
                              </div>
                              {isOpen1 && (
                                <div
                                  id="checkBoxes"
                                  className="dropdown-content"
                                  style={{
                                    left: "22px",
                                    width: "93%",
                                    maxHeight: "210px",
                                    overflowY: "scroll",
                                    padding: "0px",
                                  }}
                                >
                                  <label htmlFor="selectAll">
                                    <input
                                      className="user_checkbox"
                                      type="checkbox"
                                      id="selectAll"
                                      // checked={
                                      //   selectedOptionsCredit.length === 4
                                      // }
                                      // onChange={handleSelectAllChange}
                                    />
                                    Select All
                                  </label>

                                  {UserDepartmentList.map((item, index) => (
                                    <label htmlFor={`second-${index}`}>
                                      <input
                                        className="user_checkbox"
                                        type="checkbox"
                                        id={`second-${index}`}
                                        checked={inputValues.selectForAllowUserDepartment
                                          ?.split(",")
                                          .includes(String(item.id))}
                                        value={item.id}
                                        onChange={(e) =>
                                          handleCheckboxChangeUserDept(e)
                                        }
                                        style={{
                                          borderBottom: "1px dolid #eee",
                                        }}
                                        // onChange={() => handleCheckboxChange1()}
                                      />
                                      {/* Buy X Get Y Free */}{" "}
                                      {item.departmentName} -- {item.id}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div
                                className="input-box-mok1"
                                onClick={toggleDropdown2}
                              >
                                <select
                                  className="modify_im_input mode_category1"
                                  type="checkbox"
                                  required
                                >
                                  <option>Select options</option>
                                </select>
                                <label
                                  className="UseMasterLebalFix"
                                  style={{
                                    position: "absolute",
                                    left: "25px",
                                    top: "-10px",
                                  }}
                                >
                                  Select For Allow User Master
                                </label>
                                <div className="overSelect"></div>
                              </div>
                              {isOpen2 && (
                                <div
                                  id="checkBoxes"
                                  className="dropdown-content"
                                  style={{
                                    left: "22px",
                                    width: "93%",
                                    maxHeight: "210px",
                                    overflowY: "scroll",
                                    padding: "0px",
                                  }}
                                >
                                  <label htmlFor="selectAll">
                                    <input
                                      className="user_checkbox"
                                      type="checkbox"
                                      id="selectAll"
                                      // checked={
                                      //   selectedOptionsCredit.length === 4
                                      // }
                                      // onChange={handleSelectAllChange}
                                    />
                                    Select All
                                  </label>

                                  {UserMasterList.map((item, index) => (
                                    <label htmlFor={`second-${index}`}>
                                      <input
                                        className="user_checkbox"
                                        type="checkbox"
                                        id={`second-${index}`}
                                        checked={inputValues.selectForAllowUserMaster
                                          ?.split(",")
                                          .includes(String(item.id))}
                                        value={item.id}
                                        onChange={(e) =>
                                          handleCheckboxChangeUserMaster(e)
                                        }
                                        style={{
                                          borderBottom: "1px dolid #eee",
                                        }}
                                      />
                                      {/* Buy X Get Y Free */} {item.userName}{" "}
                                      {item.id}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  type="text"
                                  name="enableBatchNoEntry"
                                  value={inputValues.enableBatchNoEntry}
                                  onChange={handleInputChange}
                                  required
                                >
                                  <option value="0">N/A</option>
                                  <option value="1">Select behaviour</option>
                                  <option value="2">Generate behaviour</option>
                                </select>
                                <label class="modify_p_text2">
                                  Batch No behaviour
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  type="text"
                                  name="enableSerialNoGeneration"
                                  value={inputValues.enableSerialNoGeneration}
                                  onChange={handleInputChange}
                                  required
                                >
                                  <option value="0">N/A</option>
                                  <option value="1">Select behaviour</option>
                                  <option value="2">Generate behaviour</option>
                                </select>
                                <label class="modify_p_text2">
                                  Serial No behaviour
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  type="text"
                                  name="enableBarcodeGeneration"
                                  value={inputValues.enableBarcodeGeneration}
                                  onChange={handleInputChange}
                                  required
                                >
                                  <option value="0">N/A</option>
                                  <option value="1">Select behaviour</option>
                                  <option value="2">Generate behaviour</option>
                                </select>
                                <label class="modify_p_text2">
                                  Barcode Generation behaviour
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div
                              className="patient-widthcredit"
                              ref={containerRef}
                            >
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  type="text"
                                  name="extraField1"
                                  value={inputValues.extraField1}
                                  onChange={handleInputChange}
                                  required
                                >
                                  <option value="0">false</option>
                                  <option value="1">True</option>
                                </select>
                                <label class="modify_p_text2">
                                  Is Lens Prescription Show
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3">
                <i class="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          // onYesClick={DeleteLoginTypeConfirm}
        />
      )}
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Transaction Type Setting is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Transaction Type Setting is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      {/* {loading && <Loader />} */}
    </div>
  );
}

export default TransactionTypeSetting;
