import axios from "../axiosConfig";
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TemplateSetting = {

  insertTemplateSetting: async (data, template) => {
    const processedTemplate = template.rows.map((temp) => ({
      showOrHide: temp.showOrHide,
      columnName: temp.columnName,
      columnDataType: temp.columnDataType,
      columnNameByUser: temp.columnNameByUser,
      columnShowTotalValue: temp.columnShowTotalValue,
      columnSelectBoxWithSaveBtn: temp.columnSelectBoxWithSaveBtn,
      col1: temp.col1,
      col2: temp.col2,
      col3: temp.col3,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/TemplateSetting`;
      console.log(apiUrl);
      console.log(data);
      const response = await axios.post(
        apiUrl,
        {
          reportName: data.reportName,
          queryForSql: data.queryForSql,
          queryForSqlite: data.queryForSqlite,
          queryForMySQL: data.queryForMySQL,
          reportAttachTo: data.reportAttachTo,
          pageLink: data.pageLink,
          reportType: data.reportType,
          pagination: data.pagination,
          templateSettingReportConfigList: processedTemplate,
          search_ByDate: data.search_ByDate,
          search_AnyText: data.search_AnyText,
          search_ByColumn: data.search_ByColumn,
          orderByButton: data.orderByButton,
          firmNameSelect: data.firmNameSelect,
          transactionTypeSelect: data.transactionTypeSelect,
          billSeriesNameSelect: data.billSeriesNameSelect,
          printButton: data.printButton,
          excelButton: data.excelButton,
          shareButton: data.shareButton,
          rowWise_Edit: data.rowWise_Edit,
          rowWise_Print: data.rowWise_Print,
          rowWise_Whatsapp: data.rowWise_Whatsapp,
          rowWise_Delete: data.rowWise_Delete,
          rowWise_ArrowForOtherPage: data.rowWise_ArrowForOtherPage,
          rowWise_TransporterOrOther: data.rowWise_TransporterOrOther,
          srch1: data.srch1,
          srch2: data.srch2,
          srch3: data.srch3,
          srch4: data.srch4,
          eF1: data.eF1,
          eF2: data.eF2,
          eF3: data.eF3,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getTemplateSetting: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetTemplateSettings`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // ************************* Get All ***************************
  getTemplateSettingFilter: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetTemplateSettings`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filteredData,
            order: [
              {
                propertyName: "id",
                ascending: false,
              },
            ],
            pageNumber: 0,
            pageSize: 500000,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Import CSV ***************************
  ImportCSVExcelFile: async (file, masterType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/ImportCsvExcelFile`;
      const formdata = new FormData();
      formdata.append("file", file);
      const response = await axios.post(apiUrl, formdata, {
        params: {
          masterType,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type
        }
      }
      );
      console.log("Excel Import Response:", response);
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  },

  // ************************* Delete ****************************
  deleteTemplateSetting: async (mastertypeid, itemid) => {
    try {
      // http://122.168.117.143/yesbm-api1/api/v1/TemplateSetting/1269
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/DeleteMasterEntry`;
      const response = await axios.delete(apiUrl, {
        params: {
          masterType: mastertypeid,
          tempId: itemid
        },
        withCredentials: true,
      });
      console.log("Delete Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  //-----------------------------------Delete template settings--------------------------------
  DeleteTemplateSetting2: async (itemid) => {
    try {
      // http://122.168.117.143/yesbm-api1/api/v1/TemplateSetting/1269
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/DeleteByid`;
      const response = await axios.delete(apiUrl, {
        params: {
          //tempId: itemid
          id: itemid
        },
        withCredentials: true,
      });
      console.log("Delete Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get By Id ****************************
  getTemplateSettingById: async (tempId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/TemplateSetting?tempId=${tempId}`;
        const response = await axios.get(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // *************************** Update ***************************
  updateTemplateSetting: async (data, template) => {
    console.log(data.id);
    const processedTemplate = template.rows.map((temp) => ({
      showOrHide: temp.showOrHide,
      columnName: temp.columnName,
      columnDataType: temp.columnDataType,
      columnNameByUser: temp.columnNameByUser,
      columnShowTotalValue: temp.columnShowTotalValue,
      columnSelectBoxWithSaveBtn: temp.columnSelectBoxWithSaveBtn,
      col1: temp.col1,
      col2: temp.col2,
      col3: temp.col3,
      Sub_Query: temp.Sub_Query,
      isActive: true,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/TemplateSetting?tempId=${data.id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          reportName: data.reportName,
          queryForSql: data.queryForSql,
          queryForSqlite: data.queryForSqlite,
          queryForMySQL: data.queryForMySQL,
          reportAttachTo: data.reportAttachTo,
          pageLink: data.pageLink,
          reportType: data.reportType,
          pagination: data.pagination,
          templateSettingReportConfigList: processedTemplate,
          search_ByDate: data.search_ByDate,
          search_AnyText: data.search_AnyText,
          search_ByColumn: data.search_ByColumn,
          orderByButton: data.orderByButton,
          firmNameSelect: data.firmNameSelect,
          transactionTypeSelect: data.transactionTypeSelect,
          billSeriesNameSelect: data.billSeriesNameSelect,
          printButton: data.printButton,
          excelButton: data.excelButton,
          shareButton: data.shareButton,
          rowWise_Edit: data.rowWise_Edit,
          rowWise_Print: data.rowWise_Print,
          rowWise_Whatsapp: data.rowWise_Whatsapp,
          rowWise_Delete: data.rowWise_Delete,
          rowWise_ArrowForOtherPage: data.rowWise_ArrowForOtherPage,
          rowWise_TransporterOrOther: data.rowWise_TransporterOrOther,
          srch1: data.srch1,
          srch2: data.srch2,
          srch3: data.srch3,
          srch4: data.srch4,
          eF1: data.eF1,
          eF2: data.eF2,
          eF3: data.eF3,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // *************************Get Query Column Name****************************
  getQueryColumnName: async (q1, q2, q3) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetQueryColumnName?q1=${q1}&q2=${q2}&q3=${q3}`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *************************Get Data set Execute Query****************************
  getDatasetExecuteQuery: async (queryData) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetDatasetExecuteQuery?queryData=${queryData}`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // *************************Get Data set Execute Query Filter****************************
  getDatasetExecuteQueryFilter: async (
    queryData,
    filterData,
    pageSize = 50,
    pagenumber = 0,
    popName = "string",
    ReportType
  ) => {
    console.log("type ", typeof filterData === "object");
    debugger;

    if (queryData.trim() == "") {
      return;
    }

    const filteredData = Array.isArray(filterData)
      ? {
          or: [
            {
              and: filterData.map((data) => ({
                fieldName: data.fieldName,
                operatorName: data.operatorName,
                compareValue: data.compareValue,
              })),
            },
          ],
        }
      : filterData;
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetDatasetExecuteQuery?queryData=${queryData}&ReportType=${ReportType}`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filteredData,
            order: [
              {
                propertyName: popName,
                ascending: false,
              },
            ],
            pageNumber: pagenumber,
            pageSize: pageSize,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Create Selected Master Type ***************************
  createSelectedMasterType: async (s31, s32) => {
    try {
      const apiUrl = `${WebApiUrl}/api/TemplateSetting/CreateSelectedMasterType`;
      const data = {
        s31: s31,
        s32: s32,
      };
      const response = await axios.post(
        apiUrl,
        data,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get All Master Type***************************
  getAllMasterType: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetAllMasterType`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Get All Master Type ***************************
  getAllMasterTypeFilter: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetAllMasterType`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filteredData,
            order: [
              {
                propertyName: "id",
                ascending: false,
              },
            ],
            pageNumber: 0,
            pageSize: 500000,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *************************Get Data set Execute Query Filter for report****************************
  getDatasetExecuteQueryFilterForReport: async (queryData, filterData, pageSize = 50, pagenumber = 0) => {
    console.log("type ", typeof (filterData) === "object")
    const filteredData = Array.isArray(filterData) ? {
      or: [{
        and: filterData.map((data) => (
          {
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue,
          }

        ))
      }]
    } : filterData;
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetDatasetExecuteQuery?queryData=${queryData}`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filteredData,
            order: [
              {
                propertyName: "Ord1Id",
                ascending: false,
              },
            ],
            pageNumber: pagenumber,
            pageSize: pageSize,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *************************Get Multi Data set Execute Query Filter for report****************************
  getMultiDatasetExecuteQuery: async (queryData) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetMultiDatasetExecuteQuery`;
        const response = await axios.post(
          apiUrl, queryData,
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *************************Get Data set Execute Query Filter For Ledger****************************
  getDatasetExecuteQueryFilter_Ledger: async (queryData, filterData) => {
    if (queryData.trim() === "") {
      return [];
    }
    console.log("type ", typeof filterData === "object");
    const filteredData = Array.isArray(filterData)
      ? {
        or: [
          {
            and: filterData.map((data) => ({
              fieldName: data.fieldName,
              operatorName: data.operatorName,
              compareValue: data.compareValue,
            })),
          },
        ],
      }
      : filterData;
    const encodedQueryData = encodeURIComponent(queryData);
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/TemplateSetting/GetDatasetExecuteQuery?queryData=${encodedQueryData}`;
        const response = await axios.post(
          apiUrl,
          {
            filter: filteredData,
            order: [
              {
                propertyName: "string",
                ascending: false,
              },
            ],
            pageNumber: 0,
            pageSize: 50,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

};

export default TemplateSetting;
