import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalKOTItemDetail from "./ModalKOTItemDetail";
import ModalGSTDetails from "./ModalGSTDetails";
import Order from "../../API/APIServices/Order";
import ModalPaymentDetailOrder from "./ModalPaymentDetailOrder";
import UserCredentialApi from "../../API/APIServices/UserCredential";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "../../API/APIServices/Table";
import Loader from "../../Inventory/Props/Loader";
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import BillPaymentMode from "../../API/APIServices/BillPaymentMode";
import GstModalOrderView from "./GstModalOrderView";
import MyContext from "../../Inventory/MyContext";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ReportDesign from "./ReportDesign";
import * as XLSX from 'xlsx';

function OrderReport() {
    /***********title***************/
    useState(() => {
        document.title = "Order Report";
    }, []);

    const [loading, setLoading] = useState(false);

    const { isShowTotalAmountInReport, UserDetails, maskMobileNo, convertDateTimeToDateAndTime } = useContext(MyContext);

    const navigate = useNavigate();

    const [stopScrollingTable, setStopScrollingTable] = useState(true);
    const [stopScrollingOrdDetails, setStopScrollingOrdDetails] = useState(true);

    const getCurrentDate = async () => {
        const isoString = await CurrentDateAndTime.getCurrentDate();
        return isoString;
    };

    const [orderList, setOrderList] = useState([]);

    const [orderListFilter] = useState([
        {
            fieldName: "string",
            operatorName: "string",
            compareValue: "string",
        },
    ])

    const fetchOrder = async () => {
        try {
            setLoading(true);
            var data = await Order.getRawOrderList(orderListFilter, 1);
            setOrderList(data.orderList);
            //fetchTotalAmt(data);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    };
    // useEffect(() => {
    //     fetchOrder();
    // }, []);

    // const [orderTotalAmt, setOrderTotalAmt] = useState(0);
    // const [orderCancelAmt, setOrderCancelAmt] = useState(0);
    // const [orderBalAmt, setOrderBalAmt] = useState(0);
    // const [orderPaidAmt, setOrderPaidAmt] = useState(0);
    // const [totalReturn, setTotalReturn] = useState(0);
    // const [totalReturnRequest, setTotalReturnRequest] = useState(0);
    // const [totalNoOfChild, setTotalNoOfChild] = useState(0);
    // const [totalNoOfAdult, setTotalNoOfAdult] = useState(0);
    // const [o1TotalAmt, setO1TotalAmt] = useState(0);
    // const [grandTotal, setGrandTotal] = useState(0);

    // const fetchTotalAmt = (orderReport) => {
    //     setLoading(true)
    //     const total = orderReport.reduce((sum, data) => sum + (data.grandTotal), 0);
    //     setOrderTotalAmt(total.toFixed(2));

    //     const cancelTotal = orderReport.reduce((sum, data) => sum + (data.o1CancelledAmt), 0);
    //     setOrderCancelAmt(cancelTotal.toFixed(2));

    //     const balTotal = orderReport.reduce((sum, data) => sum + (data.grandTotal - (data.o1CancelledAmt + data.paymentAmount)), 0);
    //     setOrderBalAmt(balTotal.toFixed(2));

    //     const paidTotal = orderReport.reduce((sum, data) => sum + (data.paymentAmount), 0);
    //     setOrderPaidAmt(paidTotal.toFixed(2));

    //     const returnTotal = orderReport.filter(item => item.overallOrderStatus == "Returned");
    //     setTotalReturn(returnTotal.length)

    //     const returnRequestTotal = orderReport.filter(item => item.overallOrderStatus == "Return Request");
    //     setTotalReturnRequest(returnRequestTotal.length)

    //     const noOfChild = orderReport.reduce((sum, data) => sum + (data.noOfChild ?? 0), 0);
    //     setTotalNoOfChild(noOfChild)

    //     const noOfAdult = orderReport.reduce((sum, data) => sum + (data.noOfAdult ?? 0), 0);
    //     setTotalNoOfAdult(noOfAdult)

    //     const o1TotalAmt = orderReport.reduce((sum, data) => sum + (data.o1TotalAmt), 0);
    //     setO1TotalAmt(o1TotalAmt)

    //     const grandTotal = orderReport.reduce((sum, data) => sum + (data.grandTotal), 0);
    //     setGrandTotal(grandTotal)

    //     setLoading(false);
    // }

    const [tableoption, setTableOption] = useState(false);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let data = await Table.getTable();
            if (data) {
                data = data.sort((a, b) => {
                    if (a.tablePriority == null) return 1;
                    if (b.tablePriority == null) return -1;
                    return a.tablePriority - b.tablePriority;
                })
            }
            setLoading(false);
            setTableData(data);
        };
        fetchData();
    }, []);

    const [paymentModeDetails, setPaymentModeDetails] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const filteredData = [
                    {
                        fieldName: "isDefault",
                        operatorName: "Equal",
                        compareValue: true
                    },
                    {
                        fieldName: "masterType",
                        operatorName: "Equal",
                        compareValue: 29
                    }
                ]
                const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
                console.log("BillData", data);
                if (data.length > 0) {
                    const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
                    console.log("paymentMode", paymentMode[0].accountId)
                    setPaymentModeDetails(paymentMode);
                }
            } catch (error) {
                console.error("Error")
            }
            setLoading(false);
        }
        fetchData();
    }, [])

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                // const data = await UserCredentialApi.getUserCredential();
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    //************{modal box}************/ /
    const [ItemDetail, setItemDetail] = useState({});
    const [isModalOpenKotItemDetail, setModalOpenKotItemDetail] = useState(false);
    const [serviceTypeId, setServiceTypeId] = useState(751)

    const showModalKotItemDetail = async (id, serviceTypeId) => {
        setServiceTypeId(serviceTypeId)
        setLoading(true)
        await handleGetItem(id);
        setModalOpenKotItemDetail(true);
        setLoading(false)
    };

    const handleGetItem = async (id) => {
        var filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
        };
        // const billdata = await Order.getOrderListWithFilter(filterData);
        const orderlistdata = await Order.getOrderItemWiseList(id);
        setItemDetail(orderlistdata);
    };

    const hideModalKotItemDetail = () => {
        setModalOpenKotItemDetail(false);
    };

    const [isModalOpenPaymentDetailorder, setModalOpenPaymentDetailorder] = useState(false);

    const showModalPaymentDetailorder = async (id) => {
        await handleGetItem(id);
        setModalOpenPaymentDetailorder(true);
    };

    const hideModalPaymentDetailorder = () => {
        setModalOpenPaymentDetailorder(false);
    };

    const [isModalOpenGstDetail, setModalOpenGstDetail] = useState(false);
    const [ord1Id, setOrd1Id] = useState();

    const showModalGstDetail = async (id) => {
        setLoading(true);
        setOrd1Id(id);
        await handleGetItem(id);
        setModalOpenGstDetail(true);
        setLoading(false);
    };

    const hideModalGstDetail = () => {
        setModalOpenGstDetail(false);
    };

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false);
        setLoading(false);
    }

    const showModalPrint = (id) => {
        setOrd1Id(id)
        setShowBillPrint(true)
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id,
        //     },
        // });
    };

    // ***************** Get Assign user from user master ***********************
    const [assignUser, setAssignUser] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const data = await UserCredentialApi.getUserCredential();
                setAssignUser(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

    const handleTabClick = (index) => {
        setActiveTab(index);
        setStopScrollingTable(true);
        if (index !== 7) {
            var firstTabElement = document.getElementById("firstTab");
            firstTabElement.classList.remove("active1");
            console.log(index);
        }
    };

    // *******************Tab******************
    const [tabcontent1, setTabContent1] = useState(true);
    const [tabcontent2, setTabContent2] = useState(false);
    const [tabcontent3, setTabContent3] = useState(false);

    const showtabs1 = () => {
        setTabContent1(true);
        setTabContent2(false);
        setTabContent3(false);
    };

    const showtabs2 = async () => {
        if (orderList == null || orderList == undefined || orderList?.length == 0) {
            await fetchOrder();
        }
        setTabContent1(false);
        setTabContent2(true);
        setTabContent3(false);
    };

    const showtabs3 = () => {
        setTabContent1(false);
        setTabContent2(false);
        setTabContent3(true);
    };

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === "Enter") {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [pageIndex, setPageIndex] = useState(1);
    const [pageIndexItem, setPageIndexItem] = useState(1);

    const [dataArray, setDataArray] = useState();
    const [summary, setSummary] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const date = await getCurrentDate();
            const filteredData = [
                {
                    fieldName: "orderDate",
                    operatorName: "Equal",
                    compareValue: date,
                },
            ];
            try {
                // const val = await Order.getOrderListReport(filteredData);
                const val = await Order.getRawOrderReport(filteredData);
                let summaryData = val.summary
                let data = val.orderList
                console.log(val);
                setDataArray(data);
                setSummary(summaryData)
                // fetchTotalAmt(val);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addFilter, setFilter] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const date = await getCurrentDate();
            setFromDate(date)
            setToDate(date)
            const newFilter = [
                {
                    fieldName: "orderDate",
                    operatorName: "GreaterOrEqualThan",
                    compareValue: date
                },
                {
                    fieldName: "orderDate",
                    operatorName: "LessOrEqualThan",
                    compareValue: date
                }
            ];
            setFilter(newFilter);
        }
        fetchData();
    }, [])

    const handleFilterChange = async (event) => {
        setLoading(true);
        setPageIndex(1);
        setStartIndex(0);
        setStopScrollingTable(true);
        const { name, value } = event.target;

        let updatedFilter = [...addFilter];

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            if (name === "serviceTypeId") {
                setTableOption(value == 751);
            }
            let newFilter;
            if (name === "fromDate" || name === "toDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                if (name === "fromDate") {
                    setFromDate(formattedValue);
                } else if (name === "toDate") {
                    setToDate(formattedValue);
                }
            } else if (name === "AMobileNo" || name === "Name") {
                newFilter = {
                    fieldName: name,
                    operatorName: "LIKE",
                    compareValue: value
                };
            } else if (name === "overallOrderStatus") {
                // newFilter = {
                //     fieldName: name,
                //     operatorName: value === "Cancel" ? "NotEqual" : "Equal",
                //     compareValue: value === "Cancel" ? "Cancel" : "Returned"
                // };
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
                updatedFilter = updatedFilter.filter(filter => !(filter.fieldName === name && filter.operatorName !== newFilter.operatorName));
            } else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
            }

            const indexToUpdate = updatedFilter.findIndex(
                filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName
            );

            if (indexToUpdate !== -1) {
                updatedFilter[indexToUpdate] = {
                    ...updatedFilter[indexToUpdate],
                    compareValue: newFilter.compareValue
                };
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
        console.log(updatedFilter);

        try {
            // const val = await Order.getOrderListReport(updatedFilter);
            const val = await Order.getRawOrderReport(updatedFilter);
            let summaryData = val.summary
            let data = val.orderList
            setDataArray(data);
            setSummary(summaryData)
        } catch (error) {
            console.error("Error fetching order list:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleTabClick(3);
        showtabs1();
    }, [])

    const [startIndex, setStartIndex] = useState(0);

    const previousPage = async () => {
        setLoading(true);
        const index = pageIndex - 1;
        if (index != 0) {
            setPageIndex(index)
            const val = await Order.getRawOrderReport(addFilter, index);
            setStartIndex(Number(50 * index) - 50) //Number(val.length * index) - 50
            let summaryData = val.summary
            let data = val.orderList
            console.log(val);
            setSummary(summaryData)
            if (data.length > 0) {
                setDataArray(data);
                setLoading(false);
            }
            else {
                alert("No More Data")
                setLoading(false);
            }
        }
        else {
            //alert("Not Found")
            setLoading(false);
        }
    }

    const nextPage = async () => {
        if (stopScrollingTable == false || loading) return;
        setLoading(true);
        const index = pageIndex + 1;
        const val = await Order.getRawOrderReport(addFilter, index);
        let summaryData = val.summary
        setSummary(summaryData)
        let data = val.orderList
        if (data.length > 0) {
            // setStartIndex(Number(100 * index) - 100)  //Number(val.length * index) - 50
            setStartIndex(index);
            setDataArray((prev) => [...prev, ...data]);
            setPageIndex(index)
            setLoading(false);
        }
        else {
            setStopScrollingTable(false);
            setPageIndex(1);
            setLoading(false);
        }
    }

    const [startIndexItem, setStartIndexItem] = useState(0);

    const previousPageItem = async () => {
        setLoading(true);
        const index = pageIndexItem - 1;
        if (index != 0) {
            setPageIndexItem(index)
            const val = await Order.getRawOrderList(orderListFilter, index);
            setStartIndexItem(Number(50 * index) - 50) //Number(val.length * index) - 50
            let summaryData = val.summary
            let data = val.orderList
            setSummary(summaryData)
            if (data.length > 0) {
                setOrderList(data);
                setLoading(false);
            }
            else {
                alert("No More Data")
                setLoading(false);
            }
        }
        else {
            alert("No More Data");
            setLoading(false);
        }
    }

    const nextPageItem = async () => {
        if (stopScrollingOrdDetails == false || loading) return;
        setLoading(true);
        const index = pageIndexItem + 1;
        const val = await Order.getRawOrderList(orderListFilter, index);
        let summaryData = val.summary
        let data = val.orderList
        console.log(val);
        setSummary(summaryData)
        if (data.length > 0) {
            setStartIndexItem(Number(50 * index) - 50)  //Number(val.length * index) - 50
            // setDataArray((prev) => [...prev, ...data]);
            setOrderList((prev) => [...prev, ...data]);
            setPageIndexItem(index)
            setLoading(false);
        }
        else {
            setStopScrollingOrdDetails(false);
            setPageIndex(1);
            setLoading(false);
        }
    }

    function convertTimeFormat(time) {
        if (time) {
            const [hour, minute] = time.split(':').map(Number);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            const adjustedHour = hour % 12 || 12;

            return `${adjustedHour}:${minute < 10 ? `0${minute}` : minute} ${ampm}`;
        }
    }

    const handleExportFileClick = async () => {
        setLoading(true);
        const data = await Order.getOverallRawOrderReport(addFilter);

        if (!data || data.orderList.length === 0) {
            setLoading(false);
            return false;
        }

        const headers = ["S.No", "Order No", "Service Type", "Table Name", "No of Person", "Customer", "Order Date/Time", "Delivery Date", "Order Status", "Payment Status",
            "Total Amt", "Cancel Amt", "Bal. Amt", "Paid Amt", "Order Confirm By", "Pay Done By"];

        const tableData = data.orderList.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.ord1Id === item.ord1Id
            ))
        );
        const excelData = [headers, ...tableData.map((row, index) => [
            index + 1,
            row.orderNo,
            row.serviceTypeName,
            row.tableName,
            `NP: ${row.noOfAdult || 0 + row.noOfChild || 0} | NCP: ${row.noOfChild || 0} | NAP: ${row.noOfAdult || 0}`,
            `${moment.utc(row.orderDate).format("DD-MM-YYYY")}, ${convertTimeFormat(row.orderTime)}`,
            `${moment.utc(row.deliveryDate).format("DD-MM-YYYY")}`,
            `Del Boy: ${row.deliveryBoyName || ""} | DDT: ${row.deliveryBoyDispatchTime ? convertTo12HourFormat(String(row.deliveryBoyDispatchTime)) : "00:00:00"} | DT: ${row.deliveredTime || "00:00:00"}`,
            row.overallOrderStatus,
            row.paymentStatus,
            // `Returned By: ${row.returnedByName ? `${row.returnedByName} on ${moment.utc(row.returnedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}` : ""} `,
            row.grandTotal,
            row.o1CancelledAmt,
            (row.grandTotal - (row.o1CancelledAmt || 0 + row.paymentAmount || 0)),
            row.paymentAmount,
            row.orderConfirmedByName,
            row.paymentDoneByName,
        ])];

        // Create Excel file
        const ws = XLSX.utils.aoa_to_sheet(excelData); // Convert to sheet
        const wb = XLSX.utils.book_new(); // Create new workbook
        XLSX.utils.book_append_sheet(wb, ws, "Order Report"); // Add sheet to workbook

        // Write Excel file to user's computer
        XLSX.writeFile(wb, "OrderReport.xlsx");
        setLoading(false); // Disable loading after file is created
    };

    // ******************* Order Report ******************
    const lastRowRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const lastRow = entries[0];
                if (lastRow.isIntersecting && !loading) {
                    nextPage();
                }
            },
            { root: null, threshold: 1.0 } // Fully visible in viewport
        );
        if (lastRowRef.current) {
            observer.observe(lastRowRef.current);
        }
        return () => {
            if (lastRowRef.current) {
                observer.unobserve(lastRowRef.current);
            }
        };
    }, [dataArray, loading, stopScrollingTable]);

    useEffect(() => {
        console.log("stopScrolling updated:", stopScrollingTable);
        if (stopScrollingTable) {
            //setStopScrollingTable(false);
        }
    }, [stopScrollingTable]);

    // ******************* Order Details ******************
    const lastRowRefDetails = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const lastRow = entries[0];
                if (lastRow.isIntersecting && !loading) {
                    nextPageItem();
                }
            },
            { root: null, threshold: 1.0 } // Fully visible in viewport
        );
        if (lastRowRefDetails.current) {
            observer.observe(lastRowRefDetails.current);
        }
        return () => {
            if (lastRowRefDetails.current) {
                observer.unobserve(lastRowRefDetails.current);
            }
        };
    }, [orderList, loading, stopScrollingOrdDetails]);

    useEffect(() => {
        console.log("stopScrolling updated:", stopScrollingOrdDetails);
        if (stopScrollingOrdDetails) {
            //setStopScrollingOrdDetails(false);
        }
    }, [stopScrollingOrdDetails]);

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    return (
        <div>
            <PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Order Report</h2>
                </div>
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnShow={true} saveButtonHide={true} isExportFile={true} handleExportFileClick={handleExportFileClick} />
            </div>
            <div><DateTime /></div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width company-width-pymt">
                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Order Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required
                                            >
                                                <option value="default">All</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ));
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Service Type
                                            </label>
                                        </div>
                                        {tableoption && (
                                            <div
                                                className="input-containerEffect mt-1 input-pur-invoice"
                                                style={{ margin: "1%", width: "50%" }}
                                            >
                                                <select
                                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                    onChange={handleFilterChange}
                                                    name="tableId"
                                                >
                                                    <option value="default">Select Table No</option>
                                                    {(() => {
                                                        if (
                                                            tableData !== null &&
                                                            Array.isArray(tableData)
                                                        ) {
                                                            return tableData.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.tableName}
                                                                </option>
                                                            ));
                                                        }
                                                    })()}
                                                </select>
                                            </div>
                                        )}

                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "50%" }} >
                                            <input
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                type="date"
                                                required
                                                onChange={handleFilterChange}
                                                name="fromDate"
                                                style={{ fontSize: "12px" }}
                                                value={fromDate}
                                            />
                                            <label id="input1" className="labelEffect1">
                                                From Date
                                            </label>
                                        </div>
                                        <div
                                            className="input-containerEffect mt-1 input-pur-invoice"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <input
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                type="date"
                                                required
                                                onChange={handleFilterChange}
                                                name="toDate"
                                                value={toDate}
                                                style={{ fontSize: "12px" }}
                                            />
                                            <label id="input1" className="labelEffect1">
                                                To Date
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div
                                            className="input-containerEffect mt-1 input-pur-invoice"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <input
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)}
                                                type="text"
                                                required
                                                name="Name"
                                                onChange={handleFilterChange}
                                            />
                                            <label id="input1" className="labelEffect1">
                                                Customer Name
                                            </label>
                                        </div>

                                        <div
                                            className="input-containerEffect mt-1 input-pur-invoice"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <input
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input8Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input9Ref)}
                                                type="text"
                                                required
                                                name="AMobileNo"
                                                onChange={handleFilterChange}
                                            />
                                            <label id="input1" className="labelEffect1"> Mobile No </label>
                                        </div>
                                        <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input6Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="deliveryBoyId"
                                                required
                                            >
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (
                                                        assignUser !== null &&
                                                        Array.isArray(assignUser)
                                                    ) {
                                                        return assignUser.filter(item => item.userRole == 562) //DeliveryBoy
                                                            .map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.userName}
                                                                </option>
                                                            ));
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Delivery Boy
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input7Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="paymentStatus"
                                                required
                                            >
                                                <option value="default">--Select--</option>
                                                <option value="Pending">Pending</option>
                                                {/* <option value="Confirm">Confirm</option> */}
                                                <option value="Done">Done</option>
                                                {/* <option value="Cancel">Cancel</option> */}
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Payment Status
                                            </label>
                                        </div>
                                        {/* <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input10Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input11Ref)}
                                                style={{ fontSize: "14px" }}
                                                required
                                                name="overallOrderStatus"
                                                onChange={handleFilterChange}
                                            >
                                                <option value="default">--Select--</option>
                                                <option value="Confirm">Active Order</option>
                                                <option value="Cancel">Cancel Order</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Order Manage
                                            </label>
                                        </div> */}
                                        <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input11Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input12Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="overallOrderStatus"
                                                required
                                            >
                                                <option value="default">--Select--</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Confirm">Confirm</option>
                                                <option value="Done">Done</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Return">Return Request</option>
                                                <option value="Returned">Returned</option>
                                                <option value="Cancel">Cancel</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Order Status
                                            </label>
                                        </div>
                                        {/* <div
                                            className="input-containerEffect mt-1 input-pur-invoice"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input13Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input14Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="paymentDoneById"
                                                required
                                            >
                                                <option value="default ">--Select--</option>
                                                {(() => {
                                                    if (
                                                        assignUser !== null &&
                                                        Array.isArray(assignUser)
                                                    ) {
                                                        return assignUser.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ));
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Payment Done By
                                            </label>
                                        </div> */}
                                        {/* <div
                                            className="input-containerEffect mt-1"
                                            style={{ margin: "1%", width: "50%" }}
                                        >
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input12Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                                                style={{ fontSize: "14px" }}
                                                required
                                            >
                                                <option>Printed/Non-Printed</option>
                                                <option>Printed</option>
                                                <option>Non-Printed</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">
                                                Bill Print Status
                                            </label>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                    </div>
                                </div> */}
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div id="tabs">
                                    <div className="tab-btn-box">
                                        <div
                                            id="firstTab"
                                            className={`tab  ${activeTab === 3 ? "active" : ""}`}
                                            onClick={() => {
                                                handleTabClick(3);
                                                showtabs1();
                                            }}
                                        >
                                            Order Report
                                        </div>
                                        <div
                                            className={`tab ${activeTab === 1 ? "active" : ""}`}
                                            onClick={() => {
                                                handleTabClick(1);
                                                showtabs2();
                                            }}
                                        >
                                            Order Details
                                        </div>
                                        <div
                                            className={`tab ${activeTab === 2 ? "active" : ""}`}
                                            onClick={() => {
                                                handleTabClick(2);
                                                showtabs3();
                                            }}
                                        >
                                            Payment Details
                                        </div>
                                    </div>
                                    {/* <hr></hr> */}
                                    {/* ******************** Tab Content 1 **************** */}
                                    {tabcontent1 && (
                                        <div className="col-sm-12 order-rpt-top">
                                            {/* ************************** Page Index *********************** */}
                                            {/* <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    margin: "5px 0px"
                                                }}
                                            >
                                                <button
                                                    onClick={() => previousPage()}
                                                    //disabled={!canPreviousPage}
                                                    className="table-list-button"
                                                >
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </button>
                                                <span className="table-list-page-txt">
                                                    Page -
                                                    <strong style={{ margin: "0px 3px" }}>
                                                        {pageIndex}
                                                    </strong>
                                                </span>
                                                <button
                                                    onClick={() => nextPage()}
                                                    //disabled={!canNextPage}
                                                    className="table-list-button"
                                                >
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </button>
                                            </div> */}
                                            <div className="table-resposive of-scroll" style={{ height: "800px" }}>
                                                <table
                                                    id="tblTransactionList"
                                                    className="table_ord_mod table-bordered1 table-width-cancel"
                                                    frame="box"
                                                >
                                                    <thead
                                                        textAlign="center"
                                                        className="table_headerSticky thead_scroll_orderlist th-bg-color"
                                                    >
                                                        <tr className="">
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    S.No.
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Option
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order Details
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Customer
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order Date/Time
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Del&nbsp;Date/Time
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Status
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Payment Details
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Odr Confirm By
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Pay Done By
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        C Amt
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="tbody_body_m1">
                                                        {dataArray && dataArray.length > 0 && dataArray
                                                            .filter((item, index, self) =>
                                                                index === self.findIndex((t) => (
                                                                    t.ord1Id === item.ord1Id
                                                                ))
                                                            )
                                                            .slice().reverse()
                                                            .map((item, index, array) => (
                                                                // Check if current ord1Id is the first occurrence in the array
                                                                array.findIndex(data => data.ord1Id === item.ord1Id) === index && (
                                                                    <tr ref={index === dataArray.length - 1 ? lastRowRef : null}
                                                                        key={index}
                                                                        className="table_tbody_Order_List"
                                                                    >
                                                                        <td className="td_col6 td_sno-btn">
                                                                            {index + 1}
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            <div>
                                                                                <div>
                                                                                    <button
                                                                                        className="copyBtn-chaitan select-btn"
                                                                                        onClick={() =>
                                                                                            showModalKotItemDetail(item.ord1Id, item.serviceTypeId)
                                                                                        }
                                                                                    >
                                                                                        Select
                                                                                    </button>
                                                                                </div>
                                                                                <div>
                                                                                    <button
                                                                                        className="copyBtn-chaitan select-btn"
                                                                                        onClick={() =>
                                                                                            showModalPrint(item.ord1Id)
                                                                                        }
                                                                                    >
                                                                                        Print
                                                                                    </button>
                                                                                </div>
                                                                                <div>
                                                                                    <button
                                                                                        className="copyBtn-chaitan select-btn"
                                                                                        onClick={() =>
                                                                                            showModalPaymentDetailorder(
                                                                                                item.ord1Id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Payment Detail
                                                                                    </button>
                                                                                </div>
                                                                                <div>
                                                                                    <button
                                                                                        className="copyBtn-chaitan select-btn"
                                                                                        onClick={() => showModalGstDetail(item.ord1Id)}
                                                                                    >
                                                                                        GST Details
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Ord&nbsp;No:&nbsp;<span className="ans">{item.orderNo}</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Type : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.serviceTypeName}
                                                                                    </label>
                                                                                </div>
                                                                                {item.serviceTypeId == 751 && (
                                                                                    <div className="">
                                                                                        <label className="ques-text-col Ord-txt-bold">
                                                                                            Table:&nbsp;<span className="ans">{item.tableName}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        NP : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">{item.noOfAdult + item.noOfChild}</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        NCP : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">{item.noOfChild}</label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        NAP : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">{item.noOfAdult}</label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start", width: "10%" }}
                                                                        >
                                                                            {item.accName} <br />
                                                                            {UserDetails.canLst3DgtNo ? maskMobileNo(item.accMobileNo) : item.accMobileNo} <br />
                                                                            {item.accAddress}
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            {moment
                                                                                //.utc
                                                                                (item.orderDate)
                                                                                //.utcOffset("+05:30")
                                                                                .format("DD-MM-YYYY")}, {convertTimeFormat(item.orderTime)}
                                                                            {/* {moment
                                                                                .utc(item.orderTime)
                                                                                .utcOffset("+05:30")
                                                                                .format("h:mm A")} */}
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            {moment
                                                                                // .utc
                                                                                (item.deliveryDate)
                                                                                //.utcOffset("+05:30")  h:mm A
                                                                                .format("DD-MM-YYYY")}
                                                                            <div className="">
                                                                                {/* <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Delivery&nbsp;Boy:&nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.deliveryBoyName}
                                                                                    </label>
                                                                                </div> */}
                                                                                <div style={{ display: item.serviceTypeId == 752 ? "block" : "none" }}>
                                                                                    <label>
                                                                                        <span className="ques">Del Boy:</span> {item.deliveryBoyName}
                                                                                    </label>
                                                                                    <label>
                                                                                        <span className="ques"> DDT:</span> {item.deliveryBoyDispatchTime != "" && item.deliveryBoyDispatchTime != "00:00:00" ? convertTo12HourFormat(String(item.deliveryBoyDispatchTime)) : "00:00:00"}
                                                                                    </label>
                                                                                    <label>
                                                                                        <span className="ques"> DT:</span> {item.overallOrderStatus == "Cancel" ? "00:00:00" : (item.deliveredTime == "" ? "00:00:00" : item.deliveredTime)}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }} >
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Ord&nbsp;Status:&nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.overallOrderStatus}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Pay&nbsp;Status:&nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.paymentStatus}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="" style={{ display: item.returnedBy != "" && item.returnedBy != null && item.returnedBy != undefined ? "block" : "none", }}>
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Returned&nbsp;by:&nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        <span style={{ fontWeight: "600" }}>{item.returnedByName}</span><br />
                                                                                        {/* {moment.utc(item.returnedOn).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')} */}
                                                                                        {convertDateTimeToDateAndTime(item.returnedOn)}
                                                                                        {/* {moment(item.returnedOn).format('DD-MM-YYYY hh:mm A')} */}
                                                                                    </label>
                                                                                </div>
                                                                                {/* <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Mode:&nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.paymentModeName}
                                                                                    </label>
                                                                                </div> */}
                                                                            </div>
                                                                        </td>
                                                                        <td className="tdStyle" style={{ width: "16%", textAlign: "center" }}>
                                                                            <span style={{ fontWeight: "600" }}>Total&nbsp;Amt: &#x20B9;</span>
                                                                            {item.grandTotal !== null &&
                                                                                item.grandTotal !== undefined
                                                                                ? item.grandTotal
                                                                                : 0}
                                                                            <br />
                                                                            <span style={{ fontWeight: "600" }}>Cancel&nbsp;Amt: &#x20B9;</span>
                                                                            {item.o1CancelledAmt !== null &&
                                                                                item.o1CancelledAmt !== undefined
                                                                                ? item.o1CancelledAmt
                                                                                : 0}
                                                                            <br />
                                                                            <span style={{ fontWeight: "600" }}>Bal.&nbsp;Amt: &#x20B9;</span>
                                                                            {item.grandTotal !== null &&
                                                                                item.grandTotal !== undefined
                                                                                ? item.grandTotal - (item.o1CancelledAmt + item.paymentAmount)
                                                                                : 0}
                                                                            <br />
                                                                            <span style={{ fontWeight: "600" }}>Paid&nbsp;Amt: &#x20B9;</span>
                                                                            {item.paymentAmount !== null &&
                                                                                item.paymentAmount !== undefined
                                                                                ? item.paymentAmount
                                                                                : 0}
                                                                        </td>
                                                                        {/* <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Total Amt : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.o1TotalAmt}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Cancel Amt : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.cancelAmt}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Del Charge : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.deliveryCharges}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">
                                                                                        Grand Total : &nbsp;
                                                                                    </label>
                                                                                    <label className="ans">
                                                                                        {item.grandTotal}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </td> */}
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            {item.orderConfirmedByName}
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "center" }}
                                                                        >
                                                                            {item.paymentDoneByName}
                                                                        </td>
                                                                        <td
                                                                            className="td_col6 td_sno-btn"
                                                                            style={{ textAlign: "start" }}
                                                                        >
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">Item&nbsp;Amt: ₹ {item.rate ? item.rate : 0}
                                                                                        {/* </label> <label className="ans alignCenter"> */}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">Bonus&nbsp;Amt: ₹ 0</label>
                                                                                    {/* <label className="ans alignCenter"></label> */}
                                                                                </div>
                                                                                <div className="">
                                                                                    <label className="ques-text-col Ord-txt-bold">Avail&nbsp;Amt: ₹ {item.paymentAmount ? item.paymentAmount : 0}</label>
                                                                                    {/* <label className="ans alignCenter"></label> */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className="td_col6 td_sno-btn" style={{ textAlign: "start" }}></td> */}
                                                                    </tr>
                                                                )
                                                            ))}
                                                        {isShowTotalAmountInReport && (
                                                            <tr className="table_tbody_Order_List"
                                                                style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "center" }}>
                                                                    <span style={{ fontWeight: "600" }}>Total NP:</span>
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].noOfPerson
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Total NCP:</span>
                                                                    {/* {totalNoOfChild == isNaN ? 0 : totalNoOfChild} &nbsp; */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].noOfChild
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Total NAP:</span>
                                                                    {/* {totalNoOfAdult == isNaN ? 0 : totalNoOfAdult} &nbsp; */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].noOfAdult
                                                                    )}
                                                                    <br />
                                                                </td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                {/* <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td> */}
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={2}>
                                                                    <span style={{ fontWeight: "600" }}>Total Returned: </span>
                                                                    {/* {totalReturn} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].returnAmt
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Total Return Request: </span>
                                                                    {/* {totalReturnRequest} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].returnRequestQty
                                                                    )}
                                                                    <br />
                                                                </td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={2}>
                                                                    <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                                                                    {/* {orderTotalAmt == isNaN ? 0 : orderTotalAmt} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].grandTotalAmt
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                                                                    {/* {orderCancelAmt == isNaN ? 0 : orderCancelAmt} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].cancelAmt
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                                                                    {/* {orderBalAmt == isNaN ? 0 : orderBalAmt} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].grandTotalAmt - (summary[0].paidAmt + summary[0].cancelAmt)
                                                                    )}
                                                                    <br />
                                                                    <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                                                                    {/* {orderPaidAmt == isNaN ? 0 : orderPaidAmt} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].paidAmt
                                                                    )}
                                                                </td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                {/* <td className="td_col6 td-padding-sn td_footer" colSpan={3}></td> */}
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {/* ******************** Tab Content 2 **************** */}
                                    {tabcontent2 && (
                                        <div className="col-sm-12 order-rpt-top">
                                            {/* ************************** Page Index *********************** */}
                                            {/* <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    margin: "5px 0px"
                                                }}
                                            >
                                                <button
                                                    onClick={() => previousPageItem()}
                                                    //disabled={!canPreviousPage}
                                                    className="table-list-button"
                                                >
                                                    <i className="fa-solid fa-chevron-left"></i>
                                                </button>
                                                <span className="table-list-page-txt">
                                                    Page -
                                                    <strong style={{ margin: "0px 3px" }}>
                                                        {pageIndexItem}
                                                    </strong>
                                                </span>
                                                <button
                                                    onClick={() => nextPageItem()}
                                                    //disabled={!canNextPage}
                                                    className="table-list-button"
                                                >
                                                    <i className="fa-solid fa-chevron-right"></i>
                                                </button>
                                            </div> */}
                                            <div className="table-resposive of-scroll" style={{ height: "800px" }}>
                                                {/* ***************** */}
                                                <table
                                                    id="tblTransactionList"
                                                    className="table_ord_mod table-bordered1"
                                                    frame="box"
                                                >
                                                    <thead
                                                        textAlign="center"
                                                        className="table_headerSticky thead_scroll_orderlist th-bg-color"
                                                    >
                                                        <tr className="">
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    S.No.
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order No
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Category
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Item
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Quantity
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Rate
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Amount
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="tbody_body_m1">
                                                        {orderList && orderList.length > 0 && orderList
                                                            .slice().reverse()
                                                            .map((item, index) => (
                                                                <tr ref={index === orderList.length - 1 ? lastRowRefDetails : null}
                                                                    key={index}
                                                                    className="table_tbody_Order_List"
                                                                >
                                                                    <td className="td_col6 td_sno-btn">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {item.orderNo}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.itemGroupCategory}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.d2ItemName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {item.qty}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {item.rate}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {item.rate * item.qty}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {/* ******************** Tab Content 3 **************** */}
                                    {tabcontent3 && (
                                        <div className="col-sm-12 order-rpt-top">
                                            <div className="table-resposive of-scroll" style={{ height: "510px" }}>
                                                <table
                                                    id="tblTransactionList"
                                                    className="table_ord_mod table-bordered1 table-width-cancel"
                                                    frame="box"
                                                >
                                                    <thead
                                                        textAlign="center"
                                                        className="table_headerSticky thead_scroll_orderlist th-bg-color"
                                                    >
                                                        <tr className="">
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    S.No.
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order No
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Table No
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Name
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div
                                                                    className="table_head_mmb1"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    Mobile
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Address
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order Date
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Total Amt
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Del Date
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Sr. Tax Amt
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Del Charge
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Grand Total
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Payment
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Service Type
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Order Confirmed By
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        Pay Rec. By Emp
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        PayTrackingId
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">

                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        PaymentStatus
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th className="td_col6">
                                                                <div className="table_head_OrderList">
                                                                    <div
                                                                        className="table_head_mmb1"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        PayFailureMessage
                                                                    </div>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="tbody_body_m1">
                                                        {dataArray && dataArray.length > 0 && dataArray
                                                            .slice().reverse()
                                                            .map((item, index) => (
                                                                <tr
                                                                    key={index}
                                                                    className="table_tbody_Order_List"
                                                                >
                                                                    <td className="td_col6 td_sno-btn">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.orderNo}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "end" }}
                                                                    >
                                                                        {item.tableName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {item.accName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {UserDetails.canLst3DgtNo ? maskMobileNo(item.accMobileNo) : item.accMobileNo}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.accAddress}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "center" }}
                                                                    >
                                                                        {moment(item.orderDate).format(
                                                                            "MMMM Do YYYY"
                                                                        )}
                                                                    </td>
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>
                                                                        &#x20B9;&nbsp;{item.o1TotalAmt}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        Pending-
                                                                        {moment(item.deliveryDate).format(
                                                                            "MMMM Do YYYY"
                                                                        )}
                                                                        {/* Confirm-02/04/2024 Done-03/04/2024 */}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        0
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.deliveryCharges}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        &#x20B9;&nbsp;{item.grandTotal}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.paymentModeName} &#x20B9;{item.paymentAmount}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.serviceTypeName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.orderConfirmedByName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.paymentDoneByName}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        19554204461
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        {item.paymentStatus}
                                                                    </td>
                                                                    <td
                                                                        className="td_col6 td_sno-btn"
                                                                        style={{ textAlign: "start" }}
                                                                    >
                                                                        No Error
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        {isShowTotalAmountInReport && (
                                                            <tr className="table_tbody_Order_List"
                                                                style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                    <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                                                                    {/* {o1TotalAmt == isNaN ? 0 : o1TotalAmt} */}
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].grandTotalAmt
                                                                    )}
                                                                    <br />
                                                                </td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                    <span style={{ fontWeight: "600" }}>Grand Amt: &#x20B9;</span>
                                                                    {summary && summary.length > 0 && (
                                                                        summary[0].grandTotalAmt
                                                                    )}
                                                                    <br />
                                                                </td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                                <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/*********************** Kot Item Detail Modal ********************/}
                                {isModalOpenKotItemDetail && (
                                    // <div className="modal">
                                    //     <div className="AddNewItem-modal modal-payment">
                                    //         <div className="modal-title modal-ord-title">
                                    //             <h3 className="modal-OrderLIst modal-color-CAD">
                                    //                 KOT Item Detail
                                    //             </h3>
                                    //             <span className="close_modal" onClick={hideModalKotItemDetail}>
                                    //                 &times;
                                    //             </span>
                                    //         </div>
                                    //         <hr style={{ margin: "0px", padding: "0px" }} />
                                    //         <div className="ModalContentModalSettingP modal-overflow-pymt">
                                    <ModalKOTItemDetail ItemDetail={ItemDetail} closeItemDetailModal={hideModalKotItemDetail}
                                        setItemDetail={setItemDetail} serviceTypeId={serviceTypeId} />
                                    //         </div>
                                    //     </div>
                                    // </div>
                                )}
                                {/*********************** status Modal ********************/}
                                {isModalOpenPaymentDetailorder && (
                                    <div className="modal">
                                        <div className="AddNewItem-modal modal-payment">
                                            <div className="modal-title modal-ord-title">
                                                <h3 className="modal-OrderLIst modal-color-CAD">
                                                    Payment Detail
                                                </h3>
                                                <span
                                                    className="close_modal"
                                                    onClick={hideModalPaymentDetailorder}
                                                >
                                                    &times;
                                                </span>
                                            </div>
                                            <hr style={{ margin: "0px", padding: "0px" }} />
                                            <div className="ModalContentModalSettingP modal-overflow-pymt">
                                                <ModalPaymentDetailOrder ItemDetail={ItemDetail} hideModalPaymentDetailorder={hideModalPaymentDetailorder} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/*********************** status Modal ********************/}
                                {isModalOpenGstDetail && (
                                    <GstModalOrderView closeGstModal={hideModalGstDetail} ItemDetail={ItemDetail} ord1Id={ord1Id} />
                                    // <div className="modal">
                                    //     <div className="AddNewItem-modal modal-payment">
                                    //         <div className="modal-title modal-ord-title">
                                    //             <h3 className="modal-OrderLIst modal-color-CAD">
                                    //                 Update GST Details
                                    //             </h3>
                                    //             <span
                                    //                 className="close_modal"
                                    //                 onClick={hideModalGstDetail}
                                    //             >
                                    //                 &times;
                                    //             </span>
                                    //         </div>
                                    //         <div className="ModalContentModalSettingP modal-overflow-pymt">
                                    //             <ModalGSTDetails />
                                    //         </div>
                                    //     </div>
                                    // </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ****************************** BILL PRINT *************************** */}
            {showBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} setLoading={setLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </div>
    );
}

export default OrderReport;
