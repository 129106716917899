import React, { useState } from "react";
import "./ImportCSV.css";
import TemplateSetting from "../../../API/APIServices/TemplateSetting";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
// import { toast, ToastContainer } from 'react-toastify';

const CSVModal = ({ isOpen, onClose, option, ReportAttachto }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [csvData, setCsvData] = useState(null);
  const [csvFile, setCsvfile] = useState(null);
  const [showSuccess, setshowSuccess] = useState(false);
  console.log(option, ReportAttachto);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvfile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.split(","));
        setCsvData(rows);
      };
      reader.readAsText(file);
    }
  };

  const handleClose = () => {
    setCsvData(null);
    onClose();
  };

  if (!isOpen) return null;

  const handleCSVUpload = async (e) => {
    try {
      setIsLoading(true);
      debugger;
      const data = await TemplateSetting.ImportCSVExcelFile(
        csvFile,
        ReportAttachto
      );
      console.log(data);
      if (data.isCompletedSuccessfully) {
        setshowSuccess(true);
        setIsLoading(false);
        setTimeout(() => {
          onClose(false);
        }, 1500);
      } else {
        alert("Unexpected error");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  //   console.log(option.filter((item) => item.id == ReportAttachto)[0].name);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <h4>Upload CSV File</h4>

        <div className="form-group">
          <label htmlFor="selectOption">Report For</label>
          <input
            value={option.filter((item) => item.id == ReportAttachto)[0].name}
            className="input-field"
            readOnly
          />
        </div>

        <div className="form-group">
          <label style={{ marginRight: "10px" }}>Select CSV file:- </label>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ margin: "10px 0", left: "30px" }} // Add your desired styles here
          />
        </div>
        {showSuccess && (
          <span className="upload-message">CSV file uploaded</span>
        )}
        <button onClick={handleCSVUpload}>Upload CSV</button>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default CSVModal;
