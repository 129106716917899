import Addimage from "../../images/plus.png";
function ToolTipMaster({
  isActiveInactive,
  isInfoGodown,
  isToolDoubleTax,
  isToolSecondTax,
  isToolBarcode,
  isToolMultipleAttribute,
  isToolManagePrice,
  NoOfPerson,
  saveNoOfPerson,
  cancelDetail,
  cancelItemDetailModal,
  tableGroup,
  OpenAttribute,
  tableGroupModal,
}) {
  //*********************{Button Script}*********************//

  return (
    <div>
      {/* *****************************{Station Master}****************************** */}
      {isActiveInactive && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">Active/Inactive</span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltip1"></i>
          </span>
        </div>
      )}
      {/* *****************************{Meal Course Master}****************************** */}
      {isInfoGodown && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">Setting info for multiple godown</span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {isToolDoubleTax && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">If the item attracts double tax.</span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {isToolSecondTax && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">
            After what amount will the second tax category be applicable?
          </span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {isToolBarcode && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">
            If the item has a predefined barcode and you do not want software
            genereted barcode
          </span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {isToolMultipleAttribute && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">
            If item has multiple attributes like color, size etc.
          </span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {isToolManagePrice && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">
            If you want to manage customer category wise price, Rate A for
            customer category A, Rate B for customer category B, and like wise.
          </span>
          <span>
            <i className="fa-solid fa-circle-info TextTooltipWhite"></i>
          </span>
        </div>
      )}
      {NoOfPerson && (
        <div className="tooltip-containerform" onClick={saveNoOfPerson}>
          <span className="tooltipForm">Save No of Person</span>
          <span>
            <i
              class="fa-solid fa-square-plus mt-2"
              style={{ marginLeft: "5px", color: "black" }}
            ></i>
          </span>
        </div>
      )}
      {cancelDetail && (
        <div className="tooltip-containerform" onClick={cancelItemDetailModal}>
          <span className="tooltipForm">View Cancel Item</span>
          <span>
            <i
              class="fa-solid fa-circle-info"
              style={{ marginLeft: "5px", color: "black" }}
            ></i>
          </span>
        </div>
      )}
      {tableGroup && (
        <div className="tooltip-containerform" onClick={tableGroupModal}>
          <span className="tooltipForm">Add Table Group</span>
          <span>
            <i
              class="fa-solid fa-circle-plus dm_4"
              style={{ marginLeft: "5px", color: "black", marginTop: "-10px" }}
            ></i>
          </span>
        </div>
      )}

      {OpenAttribute && (
        <div className="tooltip-containerform">
          <span className="tooltipForm">Create Attribute</span>
          <span>
            {/* <i
              class="fa-solid fa-arrow-up-right-from-square dm_4"
              style={{ color: "black", fontSize: "15px" }}
            ></i> */}

            <img
              src={Addimage}
              style={{ width: "25px", margin: "5px 0px" }}
              alt=""
            />
          </span>
        </div>
      )}
    </div>
  )
}

export default ToolTipMaster;