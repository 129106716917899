//import { useState } from "react";
import { useEffect, useState } from "react";
import "./POSInvoice.css";
//import NavBar from '../Components/NavBar';
import { Link } from 'react-router-dom'
import Table from "../../API/APIServices/Table";
import Loader from "../../Inventory/Props/Loader";
import ToolTipMaster from "../../Inventory/Props/ToolTipMaster";
import ModalBoxMaster from "../../Inventory/Props/ModalBoxMaster";
import TableGroup from "../../API/APIServices/TableGroup";

const DineIn = (props) => {

    const [loading, setLoading] = useState(true);

    const { handleCloseDineIn, handleTableSelect } = props;

    const handleTableButtonClick = (id, tableStatus, tableGroupId) => {
        handleCloseDineIn();
        handleTableSelect(id, tableStatus, "Yes", tableGroupId);
    }

    const [tableList, setTableList] = useState();
    const [tableGroup, setTableGroup] = useState();

    const fetchTableData = async () => {
        try {
            setLoading(true);
            let data = await Table.getTable();
            const groupedTables = data.reduce((acc, data) => {
                if (!acc[data.tableGroup]) {
                    acc[data.tableGroup] = [];
                }
                acc[data.tableGroup].push(data);
                return acc;
            }, {});
            if (data) {
                data = data.sort((a, b) => {
                    if (a.tablePriority == null) return 1;
                    if (b.tablePriority == null) return -1;
                    return a.tablePriority - b.tablePriority;
                })
            }
            setTableList(data);

            const tableGroupData = await TableGroup.getTableGroup();
            setTableGroup(tableGroupData)
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchTableData();
    }, [])

    const [openTableGroupModal, setTableGroupModal] = useState(false);

    const openTableGroupMaster = () => {
        setTableGroupModal(true);
    }

    const closeTableGroupMaster = async () => {
        fetchTableData();
        setTableGroupModal(false);
    }

    const [openTableMasterModal, setTableMasterModal] = useState(false);
    const [tableGroupId, setTableGroupId] = useState(0);

    const openTableModal = (id) => {
        setTableGroupId(id);
        setTableMasterModal(true);
    }

    const closeTableModal = async () => {
        setTableGroupId(0);
        fetchTableData();
        setTableMasterModal(false);
    }

    return (
        <div>
            {/*<NavBar />*/}
            <div className="scroll-container" style={{ height: "98vh", backgroundColor: "white" }}>
                {/* <div style={{ display: "", justifyContent: "", width: "100%", backgroundColor: "white", paddingBottom: "0px" }}>
                    <label className="mt-2" style={{ fontWeight: "700", marginTop: "0px", fontSize: "18px", color: "#3d470a", padding: "10px", position: "fixed", zIndex: "0" }}>Table View</label>
                    <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px" }}>
                        <i className="fa-solid fa-arrows-rotate mt-3" style={{ cursor: "pointer", color: "black", marginBottom: "10px", marginRight: "5px" }}></i>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px", }}>Delivery</button>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px" }}>Pick Up</button>
                        <button className="OrderNow-btn" style={{ width: "10%", marginBottom: "10px" }}>+ Add Table</button>
                    </div>
                </div>
                <hr /> */}
                <div className="" style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* <label style={{ fontWeight: "700", backgroundColor: "lightgray", padding: "10px", borderRadius: "5px" }}>
                        <input type="radio" value="MoveKOT/Items" style={{ boxShadow: "none" }} />
                        &nbsp;Move KOT/Items
                    </label> */}
                    <div className="" style={{ padding: "0px" }}>
                        <div className=" space-evenly mt-2" style={{ justifyContent: "space-around" }}>
                            <div style={{ display: "flex", marginLeft: "10px" }}>
                                <div className="pos-circleDineIn" style={{ backgroundColor: "white", border: "1px solid #b3adad" }}>
                                    {tableList && tableList.filter(item => item.tableStatus == "Free").length}
                                </div>
                                <label className="ques">&nbsp; Free &nbsp;</label>
                            </div>
                            <div style={{ display: "flex", marginLeft: "10px" }}>
                                <div className="pos-circleDineIn" style={{ backgroundColor: "bisque", border: "1px solid #b3adad" }}></div>
                                <label className="ques">&nbsp; Selected &nbsp;</label>
                            </div>
                            <div style={{ display: "flex", marginLeft: "10px" }}>
                                <div className="pos-circleDineIn" style={{ backgroundColor: "#9acd328a" }}>
                                    {tableList && tableList.filter(item => item.tableStatus == "Busy").length}
                                </div>
                                <label className="ques">&nbsp; Busy &nbsp;</label>
                            </div>
                            <div style={{ display: "flex", marginLeft: "10px" }}>
                                <div className="pos-circleDineIn" style={{ backgroundColor: "rgb(237 124 251 / 51%)" }}>
                                    {tableList && tableList.filter(item => item.orderPrintStatus == true).length}
                                </div>
                                <label className="ques">&nbsp; Printed &nbsp;</label>
                            </div>
                            <div style={{ display: "flex", marginLeft: "10px" }}>
                                <div className="pos-circleDineIn" style={{ backgroundColor: "rgb(52 192 238 / 44%)" }}>
                                    {tableList && tableList.filter(item => item.paymentStatus == "Done").length}
                                </div>
                                <label className="ques">&nbsp;Payment Done&nbsp;</label> {/* Running KOT */}
                            </div>
                        </div>
                    </div>
                </div>
                {tableGroup && tableGroup.map((groupData, index) => (
                    //console.log("tableGroupModal" + tableList[group][0].tableGroupId),
                    <div key={index} style={{ marginLeft: "15px", marginTop: "5px" }}>
                        <div style={{ display: "flex" }}>
                            <label><u style={{ fontWeight: "700", fontSize: "18px" }}>{(groupData.name)}</u></label>
                            {index === 0 && (
                                <ToolTipMaster tableGroup={true} tableGroupModal={openTableGroupMaster} />
                            )}
                        </div>
                        <div className="table-VerticalScroll" style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }}>
                            {tableList
                                .filter(item => item.tableGroupId == groupData.id)
                                .map((data, i) => (
                                    <div className="mt-2" key={i}>
                                        <div className="tableNumber" id={"table" + i} onClick={() => handleTableButtonClick(data.id, data.tableStatus, data.tableGroupId)}
                                            style={{
                                                background: (() => {
                                                    if (data.paymentStatus === "Done") {
                                                        if (data.orderPrintStatus == true) {
                                                            return 'linear-gradient(48deg, #34c0ee, rgb(237 105 253))';
                                                        }
                                                        else {
                                                            return "#34c0ee";
                                                        }
                                                    } else if (data.tableStatus === "Selected") {
                                                        return "#ffe4c4";
                                                    } else if (data.tableStatus === "Busy") {
                                                        return data.orderPrintStatus ? "#ed7cfbbf" : "yellowgreen";
                                                    } else {
                                                        return "white"; // Default color
                                                    }
                                                })(),
                                            }}
                                        >
                                            <label>{data.tableNumber}</label>
                                            {(data.paymentStatus === "Done" || data.tableStatus === "Busy") && (
                                                <label style={{ fontWeight: "500", fontSize: "13px", textDecoration: "underline" }}>View</label>
                                            )}
                                            {/* <Link to="/payment"> */}
                                            {/* {(data.paymentStatus != "Done" && data.tableStatus === "Busy") && (
                                            <i className="fa-solid fa-sack-dollar" style={{ cursor: "pointer", color: "black" }}></i>
                                             )} */}
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                ))}
                            <div className="tableNumber mt-2" onClick={() => openTableModal(groupData.id)}>
                                <label style={{ fontSize: "24px" }}>+</label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ModalBoxMaster
                tableGroupModal={openTableGroupModal}
                closeTableGroupModal={closeTableGroupMaster} />

            <ModalBoxMaster
                isOpenTableMaster={openTableMasterModal}
                closeTableMaster={closeTableModal}
                tableGroupId={tableGroupId} />

            {loading && <Loader />}
        </div>
    );
};

export default DineIn;
