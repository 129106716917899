
import DateTime from "../../Components/DateTime";
import React, { useState } from "react";
import PageTabs from "../../Components/PageTabs";

function ModelAddProducts() {
    /***********title***************/
    useState(() => {
        document.title = 'Add Products';
    }, []);

    return (
        <div>
            < PageTabs />
            <div>
                < DateTime />
            </div>
            <div>
                <div className="input-box-Im input-height2">
                    <div className="input-box-coupon search-container-cpn">
                        <span className="search-icon-cpn">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </span>
                        <input className="modify_im_input mode_category search-cpn-padding" type="search"
                           placeholder="Search Products" />
                    </div>
                </div>
                <div className="flex-home-check-cpn">
                    <div className="check-cpn-box">
                        <input className="cpn-collection-checkbox" type="checkbox" ></input>
                    </div>
                    <div className="icon-cpn-collection">
                        <i class="fa-regular fa-image"></i>
                    </div>
                    <div className="collection-home-cn">
                    <div className="homepage-cpn-name">Home Page</div>
                    <label className="product-cpn-lbl">0 Products</label>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default ModelAddProducts;
