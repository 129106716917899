import React, { useState, useEffect, useRef } from 'react'
import logo from '../../images/techcherry2_welcome.png'
import cherry from '../../images/cherry.png'
import OTPService from '../../API/APIServices/OTPService';
import Loader from '../Props/Loader';
import AlertBox from '../Props/AlertBox';
import CommonDeploymentService from '../../API/APIServices/CommonDeploymentService';


function CreateAccountPage() {

    useEffect(() => {
        document.title = "Create Account";
    }, []);

    const [loading, setLoading] = useState(false);
    const [openAlertBox, setOpenAlertBox] = useState(false);
    const [headingTop, setHeadingTop] = useState("YES-BM");
    const [heading, setHeading] = useState("");

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [emailId, setEmailId] = useState("");
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [businessTypeId, setBusinessTypeId] = useState(0);
    const [OTP, setOTP] = useState('');

    const nameRef = useRef(null);
    const addressRef = useRef(null);
    const mobileRef = useRef(null);
    const emailRef = useRef(null);
    const countryRef = useRef(null);
    const stateRef = useRef(null);
    const businessTypeRef = useRef(null);
    const OTPRef = useRef(null);

    function closeAlertBox() {
        setOpenAlertBox(false);
    }

    async function handleSendOTP() {

        if (!emailId.trim()) {
            setHeading("Please provide email to send OTP!");
            setOpenAlertBox(true);
            return;
        }

        setLoading(true);

        try {
            let response = await OTPService.CompanySendOTP(emailId.trim());

            if (response.data.isSuccess) {
                setHeading(response.data.result);
                setOpenAlertBox(true);
            } else {
                setHeading(`Error on sending OTP: ${response.data.errorMessages}`);
                setOpenAlertBox(true);
            }
        } catch (error) {
            setHeading(`Error on sending OTP: ${error.response.data.errorMessages}`);
            setOpenAlertBox(true);
        }

        setLoading(false);
    }

    async function handleSubmit() {

        if (!name.trim()) {
            nameRef.current.focus();
            setHeading("Please Enter Your Name!");
            setOpenAlertBox(true);
            return;
        }

        if (!mobile.trim()) {
            mobileRef.current.focus();
            setHeading("Please Enter Your Mobile Number!");
            setOpenAlertBox(true);
            return;
        }


        if (!emailId.trim()) {
            emailRef.current.focus();
            setHeading("Please Enter Your E-Mail!");
            setOpenAlertBox(true);
            return;
        }

        if (!OTP.trim()) {
            OTPRef.current.focus();
            setHeading("Please Enter OTP!");
            setOpenAlertBox(true);
            return;
        }

        if (!countryId) {
            countryRef.current.focus();
            setHeading("Please Select Your Country!");
            setOpenAlertBox(true);
            return;
        }

        if (!stateId) {
            stateRef.current.focus();
            setHeading("Please Select Your State!");
            setOpenAlertBox(true);
            return;
        }

        if (!address.trim()) {
            addressRef.current.focus();
            setHeading("Please Enter Your Address!");
            setOpenAlertBox(true);
            return;
        }

        if (!businessTypeId) {
            businessTypeRef.current.focus();
            setHeading("Please Select Your Business Type!");
            setOpenAlertBox(true);
            return;
        }

        const data = {
            "name": name.trim(),
            "address": address.trim(),
            "mobile": mobile.trim(),
            "emailId": emailId,
            "countryId": countryId,
            "stateId": stateId,
            "businessTypeId": businessTypeId,
        };

        setLoading(true);

        try {
            let response = await CommonDeploymentService.createYESBMAccount(data);

            if (response.data.isSuccess) {
                setHeading("Your Account Created Successfully.");
                setOpenAlertBox(true);
            } else {
                setHeading(`Error on creating account: ${response.data.errorMessages}`);
                setOpenAlertBox(true);
            }
        } catch (error) {
            setHeading(`Error on creating account: ${error.response.data.errorMessages}`);
            setOpenAlertBox(true);
        }

        setLoading(false);

    }

    return (
        <div className='flex-center-column create-account-container'>

            <div className='flex-center-column create-account-box'>


              <img className='cherry1' src={cherry} alt="cherry" />
              <img className='cherry2' src={cherry} alt="cherry" />
              <img className='cherry3' src={cherry} alt="cherry" />
              <img className='cherry4' src={cherry} alt="cherry" />
              <img className='cherry5' src={cherry} alt="cherry" />
              <img className='cherry6' src={cherry} alt="cherry" />


                <div st>


                </div>

                <img src={logo} style={{ width: "200px" }} alt="techcherry logo" />
                <h3>Create Your YES-BM Account</h3>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-user"></i>
                    </span>
                    <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' />
                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-envelope"></i>
                    </span>
                    <input ref={mobileRef} value={mobile} onChange={(e) => setMobile(e.target.value)} type="tel" placeholder='Mobile Number' />

                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-phone"></i>
                    </span>
                    <input ref={emailRef} className='cancCss' value={emailId} onChange={(e) => setEmailId(e.target.value)} type="email" placeholder='E-Mail' />

                    <button onClick={handleSendOTP} className='blue-button'>Send OTP</button>

                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-key"></i>
                    </span>
                    <input ref={OTPRef} value={OTP} onChange={(e) => setOTP(e.target.value)} type="text" placeholder='Enter OTP' />
                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-location-dot"></i>
                    </span>
                    <select ref={countryRef} value={countryId} onChange={(e) => setCountryId(Number(e.target.value))} >
                        <option value="0">---Select Country---</option>
                        <option value="1">India</option>
                        <option value="2">America</option>
                        <option value="3">France</option>
                    </select>
                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-location-dot"></i>
                    </span>
                    <select ref={stateRef} value={stateId} onChange={(e) => setStateId(Number(e.target.value))} >
                        <option value="0">---Select State---</option>
                        <option value="1">Chhatisgarh</option>
                        <option value="2">Banglore</option>
                        <option value="3">Delhi</option>
                    </select>
                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-location-dot"></i>
                    </span>
                    <input ref={addressRef} value={address} onChange={(e) => setAddress(e.target.value)} type="text" placeholder='Enter Your Address' />
                </div>

                <div className='create-account-div'>
                    <span>
                        <i class="fa-solid fa-building"></i>
                    </span>
                    <select ref={businessTypeRef} value={businessTypeId} onChange={(e) => setBusinessTypeId(Number(e.target.value))} >
                        <option value="0">---Select Your Business Type---</option>
                        <option value="1">Education</option>
                        <option value="2">Finance</option>
                        <option value="3">Factory</option>
                    </select>
                </div>

                <button onClick={handleSubmit} className='blue-button br-4 px-20px my-5px'>Submit</button>

            </div>

            {loading && <Loader />}
            {openAlertBox && <AlertBox
                OpenAlertBox={closeAlertBox}
                HeadingTop={headingTop}
                Heading={heading}
                singleButton={true}
                onYesClick={closeAlertBox}
            />}
        </div>
    )
}

export default CreateAccountPage;