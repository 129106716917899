import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useContext, useEffect } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import ToolTipMaster from "../../Props/ToolTipMaster";
import ApiConfig from "../../../API/APIServices/APIConfiguration";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import APIConfiguration from "../../../API/APIServices/APIConfiguration";

function APIConfigurationMaster(props) {
    /***********title***************/
    useState(() => {
        document.title = 'API Configuration Master';
    }, []);
    const { OpenAlertBox, alertBox, OpenAlertBoxEdit, alertBoxEdit } = useContext(MyContext);
    
    const [inputData, setInputParameterData] = useState([{
        paramHeader: '',
        paramType: '',
        paramName: '',
        paramValue: ''
    }]);

    const [autotxt, setAutotxt] = useState('');

    const [inputValue, setInputValue] = useState(autotxt);

    console.log("inputData", inputData)

    const [inputValues, setInputValues] = useState({
        id: "",
        configType: 0,
        formateName: "",
        accessCode: "",
        workingKey: "",
        isDefault: false,
    isActive: true,
    gatewayTypeId: 0,
    gatewayTypeName: "",
    parameterNameAndValue: inputData,
  });

    const [errorMessages, setErrorMessages] = useState({
        formateName: "",
        // menuName:"",
    });


    useEffect(() => {
        const fetchData = async () => {
            if (props.data) {
                try {
                    const data = await props.data;
                    console.log(data);
                    setInputValues({
                        id: data.id || "",
                        configType: data.configType || "",
                        formateName: data.formateName || "",
                        accessCode: data.accessCode || "",
                        workingKey: data.workingKey || "",
                        isDefault: data.isDefault || "",
                        isActive: data.isActive || "",
            gatewayTypeId: data.gatewayTypeId ,
            gatewayTypeName: data.gatewayTypeName ,
            parameterNameAndValue: data.parameterNameAndValue || [],
          });

                    if (data.configType || data.configType != 0) {
                        debugger
                        setSelectedOption(data.configType);
                        // handleInputChange(event);
                        if (data.configType == '62') {
                            setIsModalOpenSMS(true);
                        } else {
                            setIsModalOpenSMS(false);
                        }

                        if (data.configType == '78') {
                            setIsModalOpenEmail(true);
                        } else {
                            setIsModalOpenEmail(false);
                        }
                        if (data.configType == '59') {
                            setIsModalOpenWhatsapp(true);
                        } else {
                            setIsModalOpenWhatsapp(false);
                        }

                        if (data.configType == '60') {
                            setIsModalOpenPayment(true);
                        } else {
                            setIsModalOpenPayment(false);
                        }

                    }

                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                console.error("props.data is not a Promise");
            }
        };

        const fetchParamData = async () => {
            // debugger;
            console.log(props.data1);
            try {
                if (props.data1) {
                    const data1 = await props.data1;
                    console.log(data1);
                    // setInputParameterData([
                    //   {
                    //     paramHeader: data1.paramHeader || '',
                    //     paramType: data1.paramTypev || '',
                    //     paramName: data1.paramName || '',
                    //     paramValue: data1.paramValue || '',
                    //   },
                    // ]);
                    var num = data1
                    handleInputChange2(num.length)

                    if (data1 && Array.isArray(data1)) {
                        setInputParameterData(data1.map(item => ({
                            paramHeader: item.paramHeader || '',
                            paramType: item.paramType || '',
                            paramName: item.paramName || '',
                            paramValue: item.paramValue || '',
                        })));
                    } else {
                        console.error("props.data1 is not a Promise");
                    }
                }
            } catch (error) {
                console.error("Error fetching parameter data:", error);
            }
        };


        fetchData();
        fetchParamData();
    }, [props.data, props.data1, inputValue]);

  const [GateWayTypes , setGateWayTypes ]  = useState([])

  useEffect(() => {
    const GetGatewayTypes = async () => {
        var data = await APIConfiguration.GetGatewayTypes()
          debugger
        if(data.isSuccess == true){
            setGateWayTypes(data.result)
        }
        else{
            console.error("error");
        }
    };
    GetGatewayTypes();
  },[]);

    const [loading, setLoading] = useState(false);

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        // console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (
            !fieldValue ||
            (typeof fieldValue === "string" && fieldValue.trim() === "")
        ) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };

    const fieldNames = ["formateName"];

    const SaveBtn = async () => {
        // debugger;
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });
        if (allValid) {
            setLoading(true);
            console.log(inputValues);
            console.log(inputData);
            if (props.modelOpen === true) {
                const isSuccess = await ApiConfig.updateAPIConfiguration(inputValues, inputData);
                // console.log(isSuccess);
                if (isSuccess) {
                    OpenAlertBoxEdit();
                    setLoading(false);

                    props.fetchData();
                    props.modelOpen(false);
                } else {
                    alert("error while updating");
                }
            } else {
                setLoading(true);
                const data = await ApiConfig.insertApiConfig(inputValues, inputData);
                console.log(data.data.result);

                if (data.data.isSuccess === true) {

                    OpenAlertBox();
                    setLoading(false);
                }
            }
            setInputValues({

                configType: 0,
                formateName: "",
                accessCode: "",
                workingKey: "",
                isDefault: false,
                isActive: true,
                parameterNameAndValue: inputData,
            });

            //  setInputParameterData({
            //     paramHeader: "",
            //     paramType: "",
            //     paramName: "",
            //     paramValue: "",
            // });
        }
    };


    /**************{No of Parameter}********************/

    const handleInputChange1 = (event) => {
        const value = event.target.value;
        setAutotxt(value);

        const data = Array.from({ length: parseInt(value) }, (_, index) => ({
            paramHeader: "",
            paramType: "",
            paramName: "",
            paramValue: ""
        }));
        setInputParameterData(data);
    };

    const handleInputChange2 = (value) => {
        const value1 = value;
        setAutotxt(value1);

        const data = Array.from({ length: parseInt(value) }, (_, index) => ({
            paramHeader: "",
            paramType: "",
            paramName: "",
            paramValue: ""
        }));
        setInputParameterData(data);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };


  const handleGatewayChange = (e) => {
   debugger
   const selectedOption = e.target.options[e.target.selectedIndex];
   const item = JSON.parse(selectedOption.dataset.item);
   setInputValues((prevValues) => ({
      ...prevValues,
      gatewayTypeId: item.id,
      gatewayTypeName: item.name,
    }));
  };

  const handleTypeChange = (index, event) => {
    const newData = [...inputData];
    newData[index].paramType = event.target.value;
    setInputParameterData(newData);

        // handleTypeChange1(index,event);
    };

    const handleSmsChange = (index, event) => {

        const newData = [...inputData];
        newData[index].paramHeader = event.target.value;
        setInputParameterData(newData);
    };

    const handleEmailChange = (index, event) => {
        const newData = [...inputData];
        newData[index].paramHeader = event.target.value;
        setInputParameterData(newData);
    };

    const handleWhatsappChange = (index, event) => {
        const newData = [...inputData];
        newData[index].paramHeader = event.target.value;
        setInputParameterData(newData);
    };

    const handlePaymentChange = (index, event) => {
        const newInputData = [...inputData];
        newInputData[index].paramHeader = event.target.value;
        newInputData[index].paramName = event.target.value; // Update the input value to the selected option's value
        setInputParameterData(newInputData);
    };


    const handleParameterChange = (index, event) => {
        const newInputData = [...inputData];
        newInputData[index].paramName = event.target.value;
        setInputParameterData(newInputData);
    };

    const handleValueChange = (index, event) => {
        const newInputData = [...inputData];
        newInputData[index].paramValue = event.target.value;
        setInputParameterData(newInputData);
    };

    const handleDeleteRow = (index) => {
        setInputParameterData((prevTableData) => {
          const updatedData = [...prevTableData];
          updatedData.splice(index, 1);
          setAutotxt(updatedData.length.toString());
          return updatedData;
        });
      };

    /**************{Modal Box}********************/

    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpenSMS, setIsModalOpenSMS] = useState(true);
    const [isModalOpenEmail, setIsModalOpenEmail] = useState(false);
    const [isModalOpenWhatsapp, setIsModalOpenWhatsapp] = useState(false);
    const [isModalOpenPayment, setIsModalOpenPayment] = useState(false);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        handleInputChange(event);
        if (selectedValue == '62') {
            setIsModalOpenSMS(true);
        } else {
            setIsModalOpenSMS(false);
        }

        if (selectedValue == '78') {
            setIsModalOpenEmail(true);
        } else {
            setIsModalOpenEmail(false);
        }
        if (selectedValue == '59') {
            setIsModalOpenWhatsapp(true);
        } else {
            setIsModalOpenWhatsapp(false);
        }

        if (selectedValue == '60') {
            setIsModalOpenPayment(true);
        } else {
            setIsModalOpenPayment(false);
        }
        /*
              if (selectedValue === '61') {
                setIsModalOpenPayment(true);
              } else {
                setIsModalOpenPayment(false);
              }*/

        const newInputData = inputData.map((data) => ({ ...data, paramName: "" }));
        setInputParameterData(newInputData);
    };

    //*********************{Enter Key}*********************//

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        // console.log(isInputVisiblemc1);
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };




    return (
        <div>
            < PageTabs locationTab={"/APIConfigurationMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">API Configuration Master</h2>
                </div >

                {/* ********************************{Buttob}********************************* */}
                <MainButton SaveButton={SaveBtn}
                    modelOpen={props.modelOpen}
                    setSliderCallBack={props.setSliderCallBack} PageName="Masters" />
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right control_flex_m1">
                                        <div className="control_radius hd-rd-bottom">
                                            <div className="company_info-text-im">API Type</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" name="configType" ref={input1Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} onChange={handleSelectChange}
                                                                value={inputValues.configType} type="text" required>
                                                                <option value="">--Select--</option>
                                                                <option value="62" >SMS</option>
                                                                <option value="78" >Email</option>
                                                                <option value="59" >Whatsapp</option>
                                                                <option value="60" >Payment Gateway</option>
                                                                <option value="61" >Google Map</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">API Type</label>
                                                        </div>
                                                    </div>
                                                </div>
                        {inputValues.configType == "60" && (
                          <div className="stock-im-dep1">
                            <div className="input-box-Imo1 input-height2">
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  name="configType"
                                  ref={input1Ref}
                                  onKeyDown={(event) =>
                                    handleKeyPress(event, input2Ref)
                                  }
                                //   onChange={handleSelectChange}
                                  onChange={(e)=>handleGatewayChange(e)}
                                  value={inputValues.gatewayTypeId}
                                  type="text"
                                  required
                                >
                                    <option value="">Select Gateway</option>
                                    {GateWayTypes.map(items=>(
                                      <option key={items.id} value={items.id} data-item={JSON.stringify(items)}>
                                        {items.name}</option>
                                    ))}
                                </select>
                                <label className="modify_lbl_text3">
                                  Select Your Payment Gateway
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                                        <div className="control_radius hd-rd-bottom">
                                            <div className="company_info-text-im">API Configuration</div>
                                            <div className="company_control">
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" name="formateName"
                                                                value={inputValues.formateName} onChange={handleInputChange} ref={input2Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="text" required />
                                                            <label className="modify_p_text2">Format Name
                                                                <span className="StarClr">
                                                                    *
                                                                    {errorMessages.formateName && (
                                                                        <label className="errorMessage">
                                                                            {errorMessages.formateName}
                                                                        </label>
                                                                    )}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" name="accessCode"
                                                                value={inputValues.accessCode} onChange={handleInputChange} ref={input4Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="text" required />
                                                            <label className="modify_p_text2">Access Code/Longitude/Merchant Code</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" name="isDefault"
                                                                value={inputValues.isDefault} onChange={handleInputChange} ref={input6Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text" required>
                                                                <option value="false">No</option>
                                                                <option value="true">Yes</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Set As Default</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="stock-im-dep1">
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1"
                                                                value={inputValues.workingKey} ref={input3Ref} onChange={handleInputChange}
                                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)} name="workingKey" type="text" required />
                                                            <label className="modify_p_text2">Working Key/API Key/Merchant Key</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" name="isActive"
                                                                value={inputValues.isActive} onChange={handleInputChange} ref={input5Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="text" required>
                                                                <option value="true">Active</option>
                                                                <option value="false">Inactive</option>
                                                            </select>
                                                            <label className="modify_lbl_text3">Status</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Imo1 input-height2">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" name="parameterNameAndValue" ref={input7Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required value={autotxt} onChange={(event) => {
                                                                    setInputValue(event.target.value);
                                                                    handleInputChange1(event);
                                                                  }} />
                                                            <label className="modify_p_text2">No of Parameter & Value</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="control_radius hd-rd-bottom">
                                            <div className="company_info-text-im">Parameter & Value</div>
                                            {/* <div className="company_control">
                                                <div className="stock-im-dep1"> */}
                                            {inputData.map((data, index) => (
                                                <div key={index} className="input-box-Imo1 input_height_Api">
                                                    <div className="input-box-mok1">
                                                        <select
                                                            className="modify_im_input mode_category1 input-Api-width"
                                                            type="text"
                                                            name="paramType"
                                                            value={data.paramType}
                                                            ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                            onChange={(event) => handleTypeChange(index, event)}
                                                            required
                                                        >
                                                            <option value="">--Select--</option>
                                                            <option value="header" >Header</option>
                                                            <option value="query string" >Query String</option>
                                                            <option value="body" >Body</option>
                                                        </select>
                                                    </div>
                                                    {isModalOpenSMS && (
                                                        <div className="input-box-mok1 Sms-config-margin">
                                                            <select
                                                                className="modify_im_input mode_category1 input-Api-width"
                                                                type="text"
                                                                name="paramHeader"
                                                                value={data.paramHeader}
                                                                ref={input7Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                                onChange={(event) => handleSmsChange(index, event)}
                                                                required
                                                            >
                                                                <option value="">--Select--</option>
                                                                <option value={"1"}>API URL</option>
                                                                <option value={"2"}>User Name</option>
                                                                <option value={"3"}>Password</option>
                                                                <option value={"4"}>Sender Id</option>
                                                                <option value={"5"}>Phone Country Code</option>
                                                                <option value={"6"}>Mobile</option>
                                                                <option value={"7"}>Message</option>
                                                                <option value={"8"}>No. Of Character Per Sms</option>
                                                                <option value={"9"}>Mobile No. Length</option>

                                                            </select>
                                                        </div>
                                                    )}
                                                    {isModalOpenEmail && (
                                                        <div className="input-box-mok1 Sms-config-margin">
                                                            <select
                                                                className="modify_im_input mode_category1 input-Api-width"
                                                                type="text"
                                                                name="paramHeader"
                                                                value={data.paramHeader}
                                                                ref={input7Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                                onChange={(event) => handleEmailChange(index, event)}
                                                                required
                                                            >
                                                                <option value="">--Select--</option>
                                                                <option value={"1"}>Email Address</option>
                                                                <option value={"2"}>Password</option>
                                                                <option value={"3"}>Re-Enter Password</option>
                                                                <option value={"4"}>Host Name</option>
                                                                <option value={"5"}>Port No</option>
                                                                <option value={"6"}>Enable Ssl</option>

                                                            </select>
                                                        </div>
                                                    )}
                                                    {isModalOpenWhatsapp && (
                                                        <div className="input-box-mok1 Sms-config-margin">
                                                            <select
                                                                className="modify_im_input mode_category1 input-Api-width"
                                                                type="text"
                                                                name="paramHeader"
                                                                value={data.paramHeader}
                                                                ref={input7Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)}
                                                                onChange={(event) => handleWhatsappChange(index, event)}
                                                                required
                                                            >
                                                                <option value="">--Select--</option>
                                                                <option value={"1"}>URL</option>
                                                                <option value={"2"}>Version</option>
                                                                <option value={"3"}>Phone No Id</option>
                                                                <option value={"4"}>Country Code</option>
                                                                <option value={"5"}>Mobile No</option>
                                                                <option value={"6"}>Request Header</option>
                                                                <option value={"7"}>Request Parameter</option>

                                                            </select>
                                                        </div>
                          )}
                          {isModalOpenPayment && (
                            <div className="input-box-mok1 Sms-config-margin">
                              <select
                                className="modify_im_input mode_category1 input-Api-width"
                                type="text"
                                name="paramHeader"
                                ref={input7Ref}
                                value={data.paramHeader}
                                // value={data.paramType}
                                onKeyDown={(event) =>
                                  handleKeyPress(event, input8Ref)
                                }
                                onChange={(event) =>
                                  handlePaymentChange(index, event)
                                }
                                required
                              >
                                <option value="">--Select--</option>
                                <option value={"@GatewayPostData"}>
                                  Gateway Post Data
                                </option>
                                <option value={"@GatewayUrl"}>
                                  Gateway Url
                                </option>
                                <option value={"@TransLoginId"}>
                                  Transaction Login Id
                                </option>
                                <option value={"@TransPassword"}>
                                  Transaction Password
                                </option>
                                <option value={"@TransInCurrency"}>
                                  Transaction In Currency
                                </option>
                                <option value={"@ResponseUrl"}>
                                  Response Url
                                </option>
                                <option value={"@TransSignature"}>
                                  Transaction Signature
                                </option>
                                <option value={"@TransProductID"}>
                                  Transaction Product ID
                                </option>
                                <option value={"@OrderNo"}>OrderNo</option>
                                <option value={"@OrderDate"}>OrderDate</option>
                                <option value={"@TotalFinalAmount"}>
                                  TotalFinalAmount
                                </option>
                                <option value={"@B_Name"}>B_Name</option>
                                <option value={"@B_MobilNo"}>B_MobilNo</option>
                                <option value={"@B_Email"}>B_Email</option>
                                <option value={"@B_Address"}>B_Address</option>
                                <option value={"@B_City"}>B_City</option>
                                <option value={"@B_State"}>B_State</option>
                                <option value={"@PRIVATE_KEY"}>
                                  PRIVATE_KEY
                                </option>
                                <option value={"@PUBLIC_KEY"}>
                                  PUBLIC_KEY
                                </option>
                                <option value={"@KEY_UUID"}>KEY_UUID</option>
                                <option value={"@PAYMENT_PAGE_CLIENT_ID"}>
                                  PAYMENT_PAGE_CLIENT_ID
                                </option>
                              </select>
                            </div>
                          )}
                          <div className="input-box-mok1 parameter-margin-left">
                            <textarea
                              key={index}
                              className="modify_im_input mode_category1 input_Api_width"
                              type="text"
                              name="paramName"
                              value={data.paramName}
                              ref={input8Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input9Ref)
                              }
                              placeholder={`Enter Parameter Name ${index + 1}`}
                              // value={data.parameter}
                              // value={data.inputValue}
                              onChange={(event) =>
                                handleParameterChange(index, event)
                              }
                              required
                            />
                          </div>
                          <div className="input-box-mok1 Api-flex-end">
                            <textarea
                              className="modify_im_input mode_category1 input_Apivalue_width"
                              type="text"
                              name="paramValue"
                              value={data.paramValue}
                              ref={input9Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input10Ref)
                              }
                              placeholder={`Enter Parameter Value ${index + 1}`}
                              // value={data.value}
                              onChange={(event) =>
                                handleValueChange(index, event)
                              }
                              required
                            />
                          </div>
                          <div className="" style={{ textAlign: "center" }}>
                            <button
                              className="btn-table1"
                              onClick={() => handleDeleteRow(index)}
                            >
                              <i className="fa-solid fa-trash delete-btn"></i>
                            </button>
                          </div>
                        </div>
                      ))}
                      {/* </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i className="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your API Configuration is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}

            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your API Configuration is Saved Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}


export default APIConfigurationMaster;
