import { asNumber } from "pdf-lib";
import React, { useEffect, useState } from "react";
import Transaction from "../../API/APIServices/Transaction";

const WorkshopModalLens = (props) => {
  const { closeWorkshopModal, WithFrameChange, TransId } = props;

  useEffect(() => {
    GetTransWorkShopDetailByID(TransId);
  }, [TransId]);

  const GetTransWorkShopDetailByID = async (id) => {
    try {
      const response = await Transaction.GetTransWorkShopDetailByID(id);
      if (response.data && response.data.length > 0) {
        setWorkshopDetails(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [workshopDetails, setWorkshopDetails] = useState([
    {
      upsertId: 0,
      workshopName: 0,
      orderNo: "",
      partyName: "",
      orderRef: "",
      spH_R: "",
      spH_L: "",
      cyL_R: "",
      cyL_L: "",
      axiS_R: "",
      axiS_L: "",
      v_N_R: "",
      v_N_L: "",
      adD_R: "",
      adD_L: "",
      pD_R: "",
      pD_L: "",
      customerCard: "",
      dia: "",
      fittingHeight: "",
      fittingType: "",
      diM_R: "",
      diM_L: "",
      base: "",
      prismRemarks: "",
      tint: "",
      fitting: "",
      mirror: "",
      remarks: "",
      withFrame: "",
      lensOnly: "",
      trans1Id: Number(TransId),
    },
  ]);

  useEffect(() => {
    console.log(workshopDetails);
  }, [workshopDetails]);

  const AddRowInWorkshopDetails = () => {
    setWorkshopDetails((prevValues) => [
      ...prevValues,
      {
        upsertId: 0,
        workshopName: 0,
        orderNo: "",
        partyName: "",
        orderRef: "",
        spH_R: "",
        spH_L: "",
        cyL_R: "",
        cyL_L: "",
        axiS_R: "",
        axiS_L: "",
        v_N_R: "",
        v_N_L: "",
        adD_R: "",
        adD_L: "",
        pD_R: "",
        pD_L: "",
        customerCard: "",
        dia: "",
        fittingHeight: "",
        fittingType: "",
        diM_R: "",
        diM_L: "",
        base: "",
        prismRemarks: "",
        tint: "",
        fitting: "",
        mirror: "",
        remarks: "",
        withFrame: "",
        lensOnly: "",
        trans1Id: Number(TransId),
      },
    ]);
  };

  const DeleteRowInWorkshopDetails = (index) => {
    if (workshopDetails.length > 1) {
      // Only delete if there's more than one row
      setWorkshopDetails((prevValues) =>
        prevValues.filter((_, i) => i !== index)
      );
    } else {
      console.log("Cannot delete the last row.");
    }
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value} at index ${index}`);
    setWorkshopDetails((prevValues) => {
      const updatedValues = [...prevValues]; // Create a shallow copy of the array
      updatedValues[index] = {
        ...updatedValues[index], // Create a copy of the object at the specific index
        [name]: value, // Update the specific property
      };
      return updatedValues; // Return the updated array
    });
  };

  const SaveAndUpdateWorkshopData = async () => {
    var SaveData = await Transaction.CreateOrUpdateWorkshopDetail(
      workshopDetails
    );
    if (SaveData.isSuccess) {
      closeWorkshopModal();
    } else {
      alert("Error while saving data");
    }
  };

  return (
    <div className="modal-back">
      <div
        className="partyDetail-modal"
        style={{ height: "auto", backgroundColor: "white", width: "40%" }}
      >
        <div style={{ padding: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label>Workshop Details</label>
            <label
              style={{ cursor: "pointer", fontSize: "24px" }}
              onClick={closeWorkshopModal}
            >
              x
            </label>
          </div>
          {/* <hr /> */}
          <div
            className="overflowY"
            style={{
              height: "auto",
              maxHeight: "500px",
              // padding: "10px",
              border: "none",
            }}
          >
            {workshopDetails.map((Item, index) => (
              <div
                style={{
                  position: "relative",
                  border: "1px solid #cacaca",
                  padding: "10px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              >
                <div className="space-between">
                  <span
                    className="DeleteWorkShoputton"
                    type="button"
                    onClick={() => DeleteRowInWorkshopDetails(index)}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </span>
                  <div
                    className="input-containerEffect mt-1"
                    id="PartyInput"
                    style={{ margin: "1%", width: "100%" }}
                  >
                    <select
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      required
                      style={{ fontSize: "12px" }}
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.workshopName}
                      name="workshopName"
                    >
                      <option value="Workshop A">Workshop A</option>
                      <option value="Workshop B">Workshop B</option>
                      <option value="Workshop C">Workshop C</option>
                    </select>
                    <label id="input1" className="labelEffect1">
                      Workshop Name
                    </label>
                  </div>
                  <div
                    className="input-containerEffect mt-1"
                    id="PartyInput"
                    style={{ margin: "1%", width: "100%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      style={{}}
                      type="text"
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.orderNo}
                      name="orderNo"
                    />
                    <label id="input1" className="labelEffect1">
                      Order No.
                    </label>
                  </div>
                </div>
                <div className="space-between mt-1">
                  <div
                    className="input-containerEffect mt-1"
                    id="PartyInput"
                    style={{ margin: "1%", width: "100%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      style={{}}
                      type="text"
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.partyName}
                      name="partyName"
                    />
                    <label id="input1" className="labelEffect1">
                      Party Name
                    </label>
                  </div>
                  <div
                    className="input-containerEffect mt-1"
                    id="PartyInput"
                    style={{ margin: "1%", width: "100%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      style={{}}
                      type="text"
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.orderRef}
                      name="orderRef"
                    />
                    <label id="input1" className="labelEffect1">
                      Ord Ref.
                    </label>
                  </div>
                </div>

                <div className="" style={{}}>
                  <div className="input-box-SI-2">
                    <div className="input-box-m6">
                      <div
                        className="row mt-2"
                        style={{ width: "96%", margin: "auto" }}
                      >
                        <label className="col-sm-3"></label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          SPH
                        </label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          CYL
                        </label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          AXIS
                        </label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          V/N
                        </label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Add
                        </label>
                        <label
                          className="col-sm1-4 fs-131"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          PD
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="input-box-SI-2" style={{ width: "100%" }}>
                    <div className="input-box-m6">
                      <div
                        className="row mt-2"
                        style={{ width: "96%", margin: "auto" }}
                      >
                        <label
                          className="col-sm-3 fs-131"
                          style={{ padding: "4px" }}
                        >
                          {" "}
                          Right &nbsp;
                        </label>
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.spH_R}
                          name="spH_R"
                        />
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.cyL_R}
                          name="cyL_R"
                        />
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.axiS_R}
                          name="axiS_R"
                        />
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.v_N_R}
                          name="v_N_R"
                          required
                        />
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.adD_R}
                          name="adD_R"
                          required
                        />
                        <input
                          className="pwrInput col-sm1-3 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.pD_R}
                          name="pD_R"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-box-SI-2">
                    <div className="input-box-m6">
                      <div
                        className="row mt-2"
                        style={{ width: "96%", margin: "auto" }}
                      >
                        <label
                          className="col-sm-3 fs-131"
                          style={{ padding: "4px" }}
                        >
                          {" "}
                          Left &nbsp;
                        </label>
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.spH_L}
                          name="spH_L"
                        />
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.cyL_L}
                          name="cyL_L"
                        />
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.axiS_L}
                          name="axiS_L"
                        />
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.v_N_L}
                          name="v_N_L"
                        />
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.adD_L}
                          name="adD_L"
                        />
                        <input
                          className="pwrInput col-sm1-4 table-input box-size1"
                          style={{ width: "13%" }}
                          type="text"
                          required
                          onChange={(e) => handleInputChange(e, index)}
                          value={Item.pD_L}
                          name="pD_L"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="labelFieldGrid mt-3"
                  style={{ justifyContent: "initial" }}
                >
                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.customerCard}
                      name="customerCard"
                    />
                    <label id="input1" className="labelEffect1">
                      Customer Card
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.dia}
                      name="dia"
                    />
                    <label id="input1" className="labelEffect1">
                      DIA
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.fittingHeight}
                      name="fittingHeight"
                    />
                    <label id="input1" className="labelEffect1">
                      Fitting Height
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.fittingType}
                      name="fittingType"
                    />
                    <label id="input1" className="labelEffect1">
                      Fitting Type
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.diM_R}
                      name="diM_R"
                    />
                    <label id="input1" className="labelEffect1">
                      DiM_R
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.diM_L}
                      name="diM_L"
                    />
                    <label id="input1" className="labelEffect1">
                      DiM_L
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.base}
                      name="base"
                    />
                    <label id="input1" className="labelEffect1">
                      Base
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.prismRemarks}
                      name="prismRemarks"
                    />
                    <label id="input1" className="labelEffect1">
                      Prism Remarks
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.tint}
                      name="tint"
                    />
                    <label id="input1" className="labelEffect1">
                      Tint
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.fitting}
                      name="fitting"
                    />
                    <label id="input1" className="labelEffect1">
                      Fitting
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.mirror}
                      name="mirror"
                    />
                    <label id="input1" className="labelEffect1">
                      Mirror
                    </label>
                  </div>

                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.remarks}
                      name="remarks"
                    />
                    <label id="input1" className="labelEffect1">
                      {" "}
                      Remarks
                    </label>
                  </div>

                  {/* <div className="space-between"> */}
                  <div
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      id="withFrame"
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      type="text"
                      onChange={() => WithFrameChange()}
                      required
                      // onChange={(e) => handleInputChange(e , index)}
                      value={Item.withFrame}
                      name="withFrame"
                    />
                    <label id="input1" className="labelEffect1">
                      With Frame (Y/N)
                    </label>
                  </div>
                  {/*<label className=" fs-131 mt-2">Lens only</label>*/}
                  <div
                    id=""
                    className="input-containerEffect mt-2"
                    style={{ margin: "1%" }}
                  >
                    <input
                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                      placeholder="Lens Name"
                      type="text"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                      value={Item.lensOnly}
                      name="lensOnly"
                    />
                    <label id="input1" className="labelEffect1">
                      Lens Only
                    </label>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div className="labelFieldGrid"> */}
                </div>
                {/* <div className="space-between"> */}
                <div
                  id="FrameName"
                  className="input-containerEffect mt-2"
                  style={{ margin: "1%", display: "none", width: "50%" }}
                >
                  <input
                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1"
                    type="text"
                    required
                    onChange={(e) => handleInputChange(e, index)}
                    value={Item.workshopName}
                    name="workshopName"
                  />
                  <label id="input1" className="labelEffect1">
                    Frame Name
                  </label>
                </div>
                {/* </div> */}
              </div>
            ))}
            <button onClick={AddRowInWorkshopDetails}>Add Row </button>
          </div>

          {/**************************************************/}
          <div className="flex-end" style={{ padding: "0px" }}>
            <button
              className="greenBtn"
              style={{ width: "18%" }}
              onClick={() => SaveAndUpdateWorkshopData()}
            >
              Save
            </button>
            <button
              className="redBtn"
              style={{ width: "18%" }}
              onClick={closeWorkshopModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopModalLens;
