
import React, { useState, useRef } from "react";

function ModelFixedHeaderMultiplePage() {
  //*****************{title}*********************/

  //******************{modal box}******************/ /
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };


  //*********************{Button Script}*********************//

  /***********End Enter code*****************/


  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    // console.log(isInputVisiblemc1);
    if (event.key === 'Enter') {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };


  return (
    <div>
      <div className="">
        <table>
          {/* <thead>
            <tr>
              <th className="left">Left Side</th>
              <th className="right">Right Side</th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <td className="left-table-layout">Fixed header for multiple page</td>
              <td>:</td>
              <td className="right-table-layout">Print header in every page at the time of bill print. </td>
            </tr>
            <tr>
              <td className="left-table-layout">Header section</td>
              <td>:</td>
              <td className="right-table-layout">It shows all the header and detail sections, from which we can select any one to make the header. </td>
            </tr>
            <tr>
              <td className="left-table-layout">Footer section</td>
              <td>:</td>
              <td className="right-table-layout">Which is to be shown in the footer can be selected from the list </td>
            </tr>
            <tr>
              <td className="left-table-layout">Footer Line</td>
              <td>:</td>
              <td className="right-table-layout">The first box shows the bottom line, and the second box is used to remove the entire border from the table in the detail section. </td>
            </tr>
            <tr>
              <td className="left-table-layout">Footer line position</td>
              <td>:</td>
              <td className="right-table-layout">We can set width of line , and display location from left and bottom. </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div >
  );
}


export default ModelFixedHeaderMultiplePage;
