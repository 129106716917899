import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const VoucherPaymentMode = {

    insertVoucherPaymentMode: async (data) => {
        const processedData = data.map(loop => ({
            billSeries: loop.billSeries,
            assignToShow: loop.assignToShow,
            accountId: loop.accountId,
            accountTitle: loop.accountTitle,
            display: loop.display,
            isActive: true
        }));

        try {
            const apiUrl = `${WebApiUrl}/api/BillPaymentMode/VoucherPaymentMode`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get By Id ****************************
    getvoucherPaymentModeId: async (voucherPaymentModeId) => {  //voucherPaymentModeId is bill series Id
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillPaymentMode?voucherPaymentModeId=${voucherPaymentModeId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteVoucherPaymentMode: async (voucherPaymentModeId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillPaymentMode?voucherPaymentModeId=${voucherPaymentModeId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // *************************** Update ***************************
    updateVoucherPaymentMode: async (data) => {
        const processedData = data.map(loop => ({
            billSeries: loop.billSeries,
            assignToShow: loop.assignToShow,
            accountId: loop.accountId,
            accountTitle: loop.accountTitle,
            display: loop.display,
            isActive: true
        }));
        var voucherPaymentModeId = processedData[0]?.billSeries;
        if (voucherPaymentModeId == null || voucherPaymentModeId == undefined || voucherPaymentModeId == 0 || voucherPaymentModeId == "") {
            return false;
        }
        try {
            const apiUrl = `${WebApiUrl}/api/BillPaymentMode?voucherPaymentModeId=${voucherPaymentModeId}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                processedData
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default VoucherPaymentMode;
