import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function parseBoolean(v) {
  if (typeof v === "boolean") {
    return v;
  } else if (typeof v === "string") {
    v = v.toLowerCase().trim();
    return v === "true";
  } else {
    console.log("error", v);
    return false;
  }
}

const APIConfiguration = {
  insertApiConfig: async (data, optionalData) => {
    // debugger;
    let param_data = [];
    for (var i = 0; i < optionalData.length; i++) {
      param_data.push({
        paramHeader: optionalData[i].paramHeader,
        paramName: optionalData[i].paramName,
        paramType: optionalData[i].paramType,
        paramValue: optionalData[i].paramValue
      });
    }

    console.log(data)
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration`;
      const response = await axios.post(
        apiUrl,
        {
          configType: data.configType,
          formateName: data.formateName,
          accessCode: data.accessCode,
          workingKey: data.workingKey,
          isDefault: parseBoolean(data.isDefault),
          isActive: parseBoolean(data.isActive),
          gatewayTypeId: Number(data.gatewayTypeId),
          gatewayTypeName: data.gatewayTypeName,
          parameterNameAndValue: param_data
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  getAPIConfigurationById: async (ApiId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${ApiId}`;
        const response = await axios.get(apiUrl, {}, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        //setEditCallBack(response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Get All ***************************
  getAPIConfiguration: async (controlType) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration?ControlType=${controlType}`;
        const response = await axios.post(apiUrl, {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 50000
        }, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Get All with filters ***************************
  getAPIConfigurationFilter: async (controlType, filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration?ControlType=${controlType}`;
        const response = await axios.post(apiUrl, {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 0
        }, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Get All ***************************
  getAPIConfigurationFilter: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetAllApiConfiguration`;
        const response = await axios.post(apiUrl, {
          filter: [],
          order: [
            {
              propertyName: "id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 500000
        }, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  getApiParameterNameAndValue: async (mastType, ApiId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetApiParameterNameAndValue?MasterType=${mastType}&ApiId=${ApiId}`;
        const response = await axios.get(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *********************  Update API Configuration ***************
  updateAPIConfiguration: async (data, data1) => {
    console.log(data);
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          configType: data.configType,
          formateName: data.formateName,
          accessCode: data.accessCode,
          workingKey: data.workingKey,
          isDefault: parseBoolean(data.isDefault),
          isActive: parseBoolean(data.isActive),
          gatewayTypeId: Number(data.gatewayTypeId),
          gatewayTypeName: data.gatewayTypeName,
          parameterNameAndValue: data1,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  deleteAPIConfiguration: async (ApiId) => {
    console.log(ApiId)
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/APIConfiguration?ApiId=${ApiId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  GetGatewayTypes: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/APIConfiguration/GetGatewayType`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      //setEditCallBack(response.data.result);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

};
export default APIConfiguration;