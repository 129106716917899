import axios from '../axiosConfig';
import CurrentDateAndTime from './CurrentDateAndTime';
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Order = {

    insertOrder: async (data, order2, order3, accountData, companyData) => {
        console.log(data);
        debugger
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        console.log("accountData", accountData);

        const isOrderFromMobile = localStorage.getItem("isOrderFromMobile");
        let order3Data;
        if (isOrderFromMobile == "Yes") {
            order3Data = [];
        }
        else {
            if (companyData?.isPOSConfirmNKotBtn) {
                order3Data = [
                    {
                        orderStatus: order3.orderStatus || "",
                        orderDateTime: order3.orderDateTime || "",
                        isActive: true,
                    }
                ]
            }
            else {
                order3Data = [];
            }
        }
        const orderDate = await CurrentDateAndTime.getCurrentDate();
        const orderTime = await CurrentDateAndTime.getCurrentTime();

        const validOrders = order2.filter(item => item.d1ItemId !== 0 && item.d2ItemId !== 0 && item.qty && item.qty != "0" && item.qty != '');

        if (validOrders.length !== order2.length) {
            const invalidOrders = order2.filter(item => !item.qty || item.qty == 0);
            invalidOrders.forEach(order => alert(`Please select ${order.itemName} quantity`));
            return false;
        }

        const processedOrders = order2.filter(item => item.d1ItemId != 0 && item.d2ItemId != 0)
            .map(order => (
                {
                    orderNo: data.orderNo === undefined ? "" : data.orderNo.toString(),
                    serviceTypeId: isOrderFromMobile == "Yes" ? 751 : (Number(data.serviceTypeId) || 0),
                    itemDepartmentId: Number(order.itemDepartmentId) || 0,
                    godownId: Number(order.godownId) || 0,
                    d1ItemId: Number(order.d1ItemId) || 0,
                    d2ItemId: Number(order.d2ItemId) || 0,
                    qty: Number(order.qty) || 0,
                    rate: Number(order.rate) || 0,
                    totalAmt: (Number(order.qty || 1) * Number(order.rate)) || Number(order.totalAmt) || 0,
                    kotStatus: isOrderFromMobile == "Yes" ? "Pending" : order.kotStatus || "Pending",
                    preferenceNo: isOrderFromMobile == "Yes" ? 0 : Number(order.preferenceNo) || 0,
                    orderDate: orderDate || data.orderDate || "",
                    orderTime: orderTime || data.orderTime || "",
                    cancelQty: Number(order.cancelQty) || 0,
                    cancelAmt: Number(order.cancelAmt) || 0,
                    itemSuggestion: order.itemSuggestion || "",
                    itemRunningOrderCounter: Number(data.itemRunningOrderCounter) || 0,
                    orderRunningQty: Number(data.orderRunningQty) || 0,
                    itemRunningQty: Number(data.itemRunningQty) || 0,
                    taxableAmount: Number(order.taxableAmount) || 0,
                    cgstPer: Number(order.cgstPer) || 0,
                    cgstValue: Number(order.cgstValue) || 0,
                    sgstPer: Number(order.sgstPer) || 0,
                    sgstValue: Number(order.sgstValue) || 0,
                    igstPer: Number(order.igstPer) || 0,
                    igstValue: Number(order.igstValue) || 0,
                    cessPer: Number(order.cessPer) || 0,
                    cessValue: Number(order.cessValue) || 0,
                    totalTaxableAmt: Number(order.totalTaxableAmt) || 0,
                    isLocal: Boolean(order.isLocal),
                    isInclusive: Boolean(order.isInclusive),
                    hasComplementryItem: Boolean(order.hasComplementryItem),
                    cookStatus: order.cookStatus || "",
                    itemIncentivePoint: Number(order.itemIncentivePoint) || 0,
                    userOrderImg: Number(order.userOrderImg) || 0,
                    preparationTime: Number(order.preparationTime) || 0,
                    itemName: order.itemName || "",
                    popupCategoryForTable: Boolean(order.popupCategoryForTable) || "",
                    immediateTransferForHD: Boolean(order.immediateTransferForHD),
                    isActive: true,
                }
            ));
        console.log(order2);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                // var WebApiUrl = express_server_url;
                // WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
                const apiUrl = `${WebApiUrl}/api/Order/CreateOrder`;
                //const apiUrl = `${WebApiUrl}/api/Order/CreateOrder`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, {
                    orderSeriesId: Number(data.orderSeriesId),
                    orderNo: data.orderNo === undefined ? "" : data.orderNo.toString(),
                    autoOrderNo: Number(data.autoOrderNo) || 0,
                    serviceTypeId: isOrderFromMobile == "Yes" ? 751 : (Number(data.serviceTypeId) || 0),
                    overallOrderStatus: isOrderFromMobile == "Yes" ? "Pending" : (Number(data.serviceTypeId) == 754 ? "Done" : (data.overallOrderStatus || "Pending")),
                    orderDate: orderDate || data.orderDate || "",
                    orderTime: orderTime || data.orderTime || "",
                    orderDoneBy: Number(data.orderDoneBy) || 0,
                    orderDoneOn: data.orderDoneOn || "",
                    orderConfirmedBy: isOrderFromMobile == "Yes" ? 0 : Number(data.orderConfirmedBy) || 0,
                    orderConfirmedOn: data.orderConfirmedOn || "",
                    orderDoneFrom: isOrderFromMobile == "Yes" ? "App" : data.orderDoneFrom || "",
                    d1AccountId: (Number(accountData?.d1AccountId)) || Number(data.d1AccountId) || 0,
                    d2AccountId: Number(accountData?.d2AccountId) || Number(data.d2AccountId) || 0,
                    billTypeId: Number(data.billTypeId) || 0,
                    tableGroupId: Number(data.tableGroupId) || 0,
                    tableId: Number(data.tableId) || 0,
                    noOfPerson: Number(data.noOfPerson) || 0,
                    noOfAdult: Number(data.noOfAdult) || 0,
                    noOfChild: Number(data.noOfChild) || 0,
                    couponCode: data.couponCode || "",
                    couponCodeAmount: Number(data.couponCodeAmount) || 0,
                    deliveryCharges: Number(data.deliveryCharges) || 0,
                    deliveryDate: orderDate || data.deliveryDate || "",
                    deliveryTimeId: Number(data.deliveryTimeId) || 812 || 0,
                    deliveryStatus: data.deliveryStatus || "",
                    deliveryBoyId: Number(data.deliveryBoyId) || 0,
                    deliveryBoyDispatchTime: data.deliveryBoyDispatchTime || "",
                    deliveryBoyTripNo: Number(data.deliveryBoyTripNo) || 0,
                    deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive) || 0,
                    deliveryReferenceNo: Number(data.deliveryReferenceNo) || 0,
                    deliveryPreferenceNo: Number(data.deliveryPreferenceNo) || 0,
                    deliveryTripStatus: data.deliveryTripStatus || "",
                    orderPrintStatus: Number(data.orderPrintStatus) || 0,
                    totalAmt: Number(data.totalAmt) || 0,
                    initialOrderTotalAmt: Number(data.initialOrderTotalAmt) || 0,
                    grandTotalAmt: Number(data.grandTotalAmt) || 0,
                    paymentStatus: data.paymentStatus || "",
                    paymentAmount: Number(data.paymentAmount) || 0,
                    returnAmount: Number(data.returnAmount) || 0,
                    discountAmount: Number(data.discountAmount) || 0,
                    paymentDoneBy: Number(data.paymentDoneBy) || 0,
                    paymentDoneOn: data.paymentDoneOn || "",
                    paymentAttemptedCounter: Number(data.paymentAttemptedCounter) || 0,
                    paymentMode: Number(data.paymentMode) || 0,
                    paymentBank: data.paymentBank || "",
                    paymentRemarks: data.paymentRemarks || "",
                    remarks: data.remarks || "",
                    orderSuggestion: data.orderSuggestion || "",
                    kotDispatchTargetTime: data.kotDispatchTargetTime || "",
                    latitude: Number(data.latitude) || 0,
                    longitude: Number(data.longitude) || 0,
                    currencyId: 0,
                    balanceAmt: Number(data.balanceAmt) || 0,
                    kotManagerId: 0,
                    deliveryAddress: "",
                    name: accountData?.name || data.name || "",
                    mobileNo: accountData?.mobileNo || data.mobileNo || "",
                    companyGSTNo: accountData?.companyGSTNo || data.companyGSTNo || "",
                    companyDetails: accountData?.companyDetails || data.companyDetails || "",
                    createdBy: Number(loginUser?.userId) || 0,
                    orders2: processedOrders,
                    orders3: order3Data,
                }, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                if (isOrderFromMobile == "Yes" && companyData?.isImmediateConfirmOrderAfterPlace) {
                    const currentDateTime = await CurrentDateAndTime.getCurrentDateTime();
                    const filterData = {
                        ord1Id: response?.data?.result?.order1?.id,
                        createdById: loginUser?.userId || 0,
                        createdOn: currentDateTime
                    }
                    const data = await Order.setOrderToConfirmed(filterData);
                }
                return response.data;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' ||
                    error.message === 'Request failed with status code 500') &&
                    retryCount < maxRetries) {

                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Serivce Type ***************************
    getServiceType: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                var WebApiUrl = express_server_url;
                WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
                const apiUrl = `${WebApiUrl}/api/Order/GetServiceTypes`;

                //const apiUrl = `${WebApiUrl}/api/Order/GetServiceTypes`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' ||
                    error.message === 'Request failed with status code 500') &&
                    retryCount < maxRetries) {

                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Update Serivce Type ***************************
    updateServiceType: async (data) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                var WebApiUrl = express_server_url;
                WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
                const apiUrl = `${WebApiUrl}/api/Order/UpdateServiceTypes`;

                //const apiUrl = `${WebApiUrl}/api/Order/GetServiceTypes`;
                const response = await axios.put(apiUrl, data, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' ||
                    error.message === 'Request failed with status code 500') &&
                    retryCount < maxRetries) {

                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Bill Series Counter ***************************
    getBillSeriesCounter: async (billId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetBillSeriesCounter?billSeriesId=${billId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' ||
                    error.message === 'Request failed with status code 500') &&
                    retryCount < maxRetries) {

                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get All ***************************
    getOrderList: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
                const response = await axios.post(apiUrl, {
                    "filter": [],
                    "order": [
                        {
                            "propertyName": "string",
                            "ascending": true
                        }
                    ],
                    "pageNumber": 0,
                    "pageSize": 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get All with filter ***************************
    getOrderListWithFilter: async (filterData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
                const response = await axios.post(apiUrl, {
                    filter: [{
                        fieldName: filterData.fieldName,
                        operatorName: filterData.operatorName,
                        compareValue: filterData.compareValue
                    }],
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 0
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get table order from table and current date ***************************
    getOrderListFilter: async (filterData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            const filteredData = filterData.map(data => ({
                fieldName: data.fieldName,
                operatorName: data.operatorName,
                compareValue: data.compareValue
            }));
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                // debugger;
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *************************** Patch update order partially ***************************
    updateOrderPartially: async (data, ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderPartially?ord1Id=${ord1Id}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    console.log("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // *************************** Patch update item partially ***************************
    updateItemPartially: async (data, ord2Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderItemWisePartially?ord2Id=${ord2Id}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // *************************** Put ***************************
    updateOrder: async (data, order2, order3, companyData) => {
        const orderId = order2[0]?.ord1Id || data.ord1Id;
        console.log(data)
        console.log(order2);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const orderDate = await CurrentDateAndTime.getCurrentDate();
        const orderTime = await CurrentDateAndTime.getCurrentTime();

        const isOrderFromMobile = localStorage.getItem("isOrderFromMobile");
        let order3Data;
        if (isOrderFromMobile == "Yes") {
            order3Data = [];
        }
        else {
            if (companyData?.isPOSConfirmNKotBtn) {
                order3Data = [
                    {
                        orderStatus: order3.orderStatus,
                        orderDateTime: order3.orderDateTime, //order3.orderDateTime === null ? "" : order3.orderDateTime,
                        isActive: true,
                    }
                ]
            } else {
                order3Data = [];
            }
        }
        const processedOrders = order2.filter(item => item.d1ItemId != 0 && item.d2ItemId != 0)
            .map(order => ({
                godownId: Number(order.godownId) || 0,
                d1ItemId: Number(order.d1ItemId) || 0,
                d2ItemId: Number(order.d2ItemId) || 0,
                qty: Number(order.qty) || 0,
                rate: Number(order.rate) || 0,
                totalAmt: (Number(order.qty || 1) * Number(order.rate)) || Number(order.totalAmt) || 0,
                cancelQty: 0,
                cancelAmt: 0,
                kotStatus: isOrderFromMobile == "Yes" ? "Pending" : order.kotStatus || "Pending",
                preferenceNo: isOrderFromMobile == "Yes" ? 0 : Number(order.preferenceNo) || 0,
                orderDate: orderDate || order.orderDate === null ? "" : order.orderDate,
                orderTime: orderTime || order.orderTime === null ? "" : order.orderTime,
                itemSuggestion: order.itemSuggestion === null ? "" : order.itemSuggestion,
                itemRunningOrderCounter: Number(order.itemRunningOrderCounter),
                orderRunningQty: Number(order.orderRunningQty),
                itemRunningQty: Number(order.itemRunningQty),
                taxableAmount: Number(order.taxableAmount) || 0,
                cgstPer: Number(order.cgstPer) || 0,
                cgstValue: Number(order.cgstValue) || 0,
                sgstPer: Number(order.sgstPer) || 0,
                sgstValue: Number(order.sgstValue) || 0,
                igstPer: Number(order.igstPer) || 0,
                igstValue: Number(order.igstValue) || 0,
                cessPer: Number(order.cessPer) || 0,
                cessValue: Number(order.cessValue) || 0,
                totalTaxableAmt: Number(order.totalTaxableAmt) || 0,
                isLocal: Boolean(order.isLocal),
                isInclusive: Boolean(order.isInclusive),
                hasComplementryItem: Boolean(order.hasComplementryItem),
                cookStatus: order.cookStatus === null ? "" : order.cookStatus,
                itemIncentivePoint: Number(order.itemIncentivePoint) || 0,
                itemDepartmentId: Number(order.itemDepartmentId) || 0,
                preparationTime: Number(order.preparationTime) || 0,
                itemName: order.itemName || "",
                popupCategoryForTable: Boolean(order.popupCategoryForTable) || "",
                immediateTransferForHD: Boolean(order.immediateTransferForHD),
                isActive: true,
                updatedBy: Number(loginUser?.userId) || 0,
            }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateOrder?orderId=${orderId}`;
                console.log(apiUrl);
                const response = await axios.put(apiUrl, {
                    ord1Id: Number(orderId) || 0,
                    serviceTypeId: isOrderFromMobile == "Yes" ? 751 : Number(data.serviceTypeId) || 0,
                    tableGroupId: Number(data.tableGroupId) || 0,
                    tableId: Number(data.tableId) || 0,
                    noOfPerson: Number(data.noOfPerson) || 0,
                    noOfAdult: Number(data.noOfAdult) || 0,
                    noOfChild: Number(data.noOfChild) || 0,
                    couponCode: data.couponCode === null ? "" : data.couponCode,
                    couponCodeAmount: Number(data.couponCodeAmount) || 0,
                    overallOrderStatus: isOrderFromMobile == "Yes" ? "Pending" : (data.overallOrderStatus || "Pending"),
                    orderDate: orderDate || data.orderDate === null ? "" : data.orderDate,
                    orderTime: orderTime || data.orderTime === null ? "" : data.orderTime,
                    orderDoneBy: Number(data.orderDoneBy) || 0,
                    orderDoneOn: data.orderDoneOn === null ? "" : data.orderDoneOn,
                    orderConfirmedBy: isOrderFromMobile == "Yes" ? 0 : Number(data.orderConfirmedBy) || 0,
                    orderConfirmedOn: data.orderConfirmedOn === null ? "" : data.orderConfirmedOn,
                    deliveryCharges: Number(data.deliveryCharges) || 0,
                    deliveryDate: data.deliveryDate === null ? "" : data.deliveryDate,
                    deliveryTimeId: Number(data.deliveryTimeId) || 0,
                    deliveryStatus: data.deliveryStatus === null ? "" : data.deliveryStatus,
                    deliveryBoyId: Number(data.deliveryBoyId) || 0,
                    deliveryBoyDispatchTime: data.deliveryBoyDispatchTime === null ? "" : data.deliveryBoyDispatchTime,
                    deliveryBoyTripNo: Number(data.deliveryBoyTripNo) || 0,
                    deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive) || 0,
                    deliveryReferenceNo: Number(data.deliveryReferenceNo) || 0,
                    deliveryPreferenceNo: Number(data.deliveryPreferenceNo) || 0,
                    deliveryTripStatus: data.deliveryTripStatus === null ? "" : data.deliveryTripStatus,
                    orderPrintStatus: Number(data.orderPrintStatus) || 0,
                    totalAmt: Number(data.totalAmt) || 0,
                    grandTotalAmt: Number(data.grandTotalAmt) || 0,
                    paymentStatus: data.paymentStatus === null ? "" : data.paymentStatus,
                    paymentAmount: Number(data.paymentAmount) || 0,
                    returnAmount: Number(data.returnAmount) || 0,
                    discountAmount: Number(data.discountAmount) || 0,
                    paymentDoneBy: Number(data.paymentDoneBy) || 0,
                    paymentDoneOn: data.paymentDoneOn === null ? "" : data.paymentDoneOn,
                    paymentAttemptedCounter: Number(data.paymentAttemptedCounter) || 0,
                    paymentModeId: Number(data.paymentModeId) || 0,
                    paymentBank: data.paymentBank === null ? "" : data.paymentBank,
                    paymentRemarks: data.paymentRemarks === null ? "" : data.paymentRemarks,
                    remarks: data.remarks === null ? "" : data.remarks,
                    orderSuggestion: data.orderSuggestion === null ? "" : data.orderSuggestion,
                    kotDispatchTargetTime: data.kotDispatchTargetTime === null ? "" : data.kotDispatchTargetTime,
                    latitude: data.latitude === null ? "" : data.latitude,
                    longitude: data.longitude === null ? "" : data.longitude,
                    currencyId: Number(data.currencyId) || 0,
                    hasRunningOrder: Boolean(data.hasRunningOrder),
                    runningOrderCounter: Number(data.runningOrderCounter),
                    runningOrderTotalAmt: Number(data.runningOrderTotalAmt),
                    name: data.name,
                    mobileNo: data.mobileNo,
                    companyGSTNo: data.companyGSTNo,
                    companyDetails: data.companyDetails,
                    orders2: processedOrders,
                    orders3: order3Data,
                }, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                if (isOrderFromMobile == "Yes" && companyData?.isImmediateConfirmOrderAfterPlace) {
                    const currentDateTime = await CurrentDateAndTime.getCurrentDateTime();
                    const filterData = {
                        ord1Id: Number(orderId) || 0,
                        createdById: loginUser.userId || 0,
                        createdOn: currentDateTime
                    }
                    const data = await Order.setOrderToConfirmed(filterData);
                }
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Order List by order No ***************************
    getOrderListByOrderNo: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Create Item Status ***************************
    insertItemStatus: async (data) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        const updatedData = [
            {
                ord1Id: Number(data.ord1Id),
                ord2Id: Number(data.ord2Id),
                orderStatus: data.orderStatus,
                orderDateTime: data.orderDateTime,
                createdBy: loginUser.userId,
                isActive: true,
            }
        ]
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateItemStatus`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ************************* Create Item Status List***************************
    insertItemStatusList: async (dataArray) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        const updatedData = dataArray.map(data => ({
            ord1Id: Number(data.ord1Id),
            ord2Id: Number(data.ord2Id),
            orderStatus: data.orderStatus,
            orderDateTime: data.orderDateTime,
            createdBy: loginUser.userId,
            isActive: true,
        }));
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateItemStatus`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Create Item Status  (for kot status) ***************************
    insertItemStatusForKotStatusList: async (data) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        const updatedData = data.map(data1 => ({
            ord1Id: Number(data1.ord1Id),
            ord2Id: Number(data1.ord2Id),
            orderStatus: data1.orderStatus,
            orderDateTime: data1.orderDateTime,
            createdBy: loginUser.userId,
            isActive: true
        }));
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateItemStatus`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Order Item Wise List ***************************
    getOrderItemWiseList: async (ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderItemWiseList?ord1Id=${ord1Id}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Item Wise Status List ***************************
    getItemWiseStatusList: async (ord1Id, ord2Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderItemWiseStatusList?ord1Id=${ord1Id}&ord2Id=${ord2Id}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Order Item Received List ***************************
    getOrderItemReceivedList: async (ord1Id, loginId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderItemReceivedList?ord1Id=${ord1Id}&loginId=${loginId}`; //itemStatus=${itemStatus}&
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Order List by order No ***************************
    getOrderListByOrderNoFilter: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
                //alert(apiUrl);
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 0
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ********************* Order Report Filter ***************************
    getOrderReportFilter: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/getOrderListReport`;
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [],
                    pageNumber: 0,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Delete Item from order ****************************
    deleteItemFromOrder: async (ord1Id, ord2Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/DeleteItemFromOrder?ord1Id=${ord1Id}&ord2Id=${ord2Id}`;
                const response = await axios.delete(apiUrl, {
                    withCredentials: true,
                });
                console.log('Delete Response:', response.data.result);
                return true;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ******************* Get Order List *************************
    getOrderListReport: async (filterData) => {
        const filteredData = filterData.map((data) => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue,
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/getOrderListReport`;
                const response = await axios.post(
                    apiUrl,
                    {
                        filter: filteredData,
                        order: [
                            // {
                            //     propertyName: "ord1Id",
                            //     ascending: false,
                            // },
                        ],
                        pageNumber: 0,
                        pageSize: 500000,
                    },
                    {
                        withCredentials: true,
                    }
                );
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    //************************************ Create Order2 ************************************
    createOrder2: async () => {
        const updatedData = [
            {
                amount: 50000,
                currency: "INR",
                receipt: "1",
                partial_payment: false,
                notes: {
                    key1: "value3",
                    key2: "value2",
                },
            }
        ]
        console.log(updatedData);
        try {
            const apiUrl = `${WebApiUrl}/api/PaymentGateway/createOrder2`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, updatedData, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Order Validate ***************************
    getOrderValidate: async (ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderValidationAsync?ord1Id=${ord1Id}`;
                const response = await axios.get(apiUrl, {},
                    {
                        withCredentials: true,
                    }
                );
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // *************************** Create Payment Mode with order ***************************
    createPaymentModeWithOrder: async (filterData, createdBy) => {
        console.log(filterData)
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const filteredData = filterData.map((data) => ({
            ord1Id: Number(data.ord1Id),
            paymentModeId: Number(data.paymentModeId) || 0,
            paymentAmount: Number(data.paymentAmount) || 0,
            remarks: "",
            transId: data.transId || "",
            payUId: data.payUId || 0,
            bankRefNo: data.bankRefNo || "",
            status: data.status || "",
            paymentTypeMethod: data.paymentTypeMethod || "",
            createdBy: Number(createdBy) || loginUser.userId,
            isActive: true
        }));
        console.log(filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreatePaymentModeWithOrder`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, filteredData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Order List by user ***************************
    getOrderListByUserFilter: async (userId, filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByUserId?userId=${userId}`;
                const response = await axios.post(apiUrl,
                    {
                        filter: {
                            or: [
                                {
                                    and: filteredData
                                }
                            ]
                        },
                        order: [
                            {
                                propertyName: "O1.Id",
                                ascending: true
                            }
                        ],
                        pageNumber: 1,
                        pageSize: 10000
                    },
                    {
                        withCredentials: true,
                    });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ****************************** Update Item Partially Multiple *********************
    updateItemPartiallyMultiple: async (FilterArray, orderIdArray) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                // Loop through the FilterArray and orderIdArray simultaneously
                for (let i = 0; i < FilterArray.length; i++) {
                    const filterData = FilterArray[i];
                    const orderId = orderIdArray[i];

                    // Construct API URL with orderId
                    const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderItemWisePartially?ord2Id=${orderId.ord2Id}`;
                    console.log(apiUrl);

                    // Assuming 'data' variable is defined elsewhere in your code
                    const response = await axios.patch(apiUrl, filterData, {
                        withCredentials: true,
                        credentials: 'include',
                    });

                    console.log("Response:", response.data);

                    // Assuming response.data.isSuccess indicates success
                    if (!response.data.isSuccess) {
                        // Handle failure scenario
                        console.error("API request failed:", response.data.errorMessage);
                        alert("An error occurred. Please try again.");
                        return false; // Assuming you want to stop execution on failure
                    }
                }
                //alert("status Updated")
                return true;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
                return false; // Assuming you want to stop execution on error
            }
        }
        return fetchData();
    },

    // *************************** Get Payment Mode with order ***************************
    GetPaymentModeWithOrders: async (ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetPaymentModeWithOrders?ord1Id=${ord1Id}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Order Item Cancel Status ***************************
    getOrderItemCancelStatus: async (ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderItemCancelStatus?ord1Id=${ord1Id}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *********************** Create Delivery Trip ***********************
    createDeliveryTrip: async (data, orderData) => {
        console.log(data);
        const orderDetails = orderData.map(order => ({
            delBoyTripId: 0,
            ord1Id: Number(order.ord1Id),
            d1AccountId: Number(order.d1AccId) || 0,
            d2AccountId: Number(order.accId) || 0,
            customerLongitude: Number(order.longitude) || 0,
            customerLatitude: Number(order.latitude) || 0,
            deliveredLongitude: 0,
            deliveredLatitude: 0,
            dispatchTime: order.dispatchTime,
            distance: Number(order.distance),
            deliveryStatus: order.overallOrderStatus,
            callDetails: order.accMobileNo,
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateDeliveryBoyTrip`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, {
                    delBoyId: Number(data.delBoyId) || 0,
                    vehicleId: Number(data.vehicleId) || 0,
                    vehicleType: data.vehicleType,
                    fuelAvgLtrKM: Number(data.fuelAvgLtrKM) || 0,
                    fuelChargeKM: Number(data.fuelChargeKM) || 0,
                    speed: Number(data.speed) || 0,
                    date: data.date,
                    time: data.time,
                    totalTime: data.totalTime,
                    totalDistance: Number(data.totalDistance) || 0,
                    tripIncentiveAmt: Number(data.tripIncentiveAmt) || 0,
                    openingKM: Number(data.openingKM) || 0,
                    closingKM: Number(data.closingKM) || 0,
                    openingKMImg: Number(data.openingKMImg) || 0,
                    closingKMImg: Number(data.closingKMImg) || 0,
                    openingTime: data.openingTime,
                    closingTime: data.closingTime,
                    closingDate: data.closingDate,
                    startLongitude: Number(data.startLongitude) || 0,
                    startLatitude: Number(data.startLatitude) || 0,
                    tripStatus: "Open",//data.tripStatus,
                    ordDetails: data.ordDetails,
                    delBoyTripOrdDetailDTOList: orderDetails,
                }, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // *************************** Patch update item partially ***************************
    updateDelBoyTripPartially: async (data, deliveryBoyTripId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateDelBoyTripPartially?deliveryBoyTripId=${deliveryBoyTripId}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // *************************** Patch Item Related Linked Lens Product Setting ***************************
    updateItemRelatedLinkedLensProdSetting: async (data, LensItemId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/SaveLensMasterSettAndFeatures?d2ItemId=${LensItemId}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // *************************** Post LensMaster Features ***************************
    UpdateLensMasterSettAndFeatures: async (data, LensItemId) => {
        const processedOrders = data.map(order => ({
            masterType: Number(order.masterType) || 224,
            itemId: Number(LensItemId),
            // d2ItemId: Number(order.d2ItemId) || 0,
            features: order.features === null ? "" : order.features,
            price: Number(order.price) || 0,
            disableBuy: order.disableBuy === null || order.disableBuy === "" ? false : order.disableBuy,
            illustrationImage: order.illustrationImage === null ? "" : order.illustrationImage,
            remarks: order.remarks === null ? "" : order.remarks,
            group: Number(order.group) || 0,
            displayAtoZ: order.displayAtoZ === null ? "" : order.displayAtoZ,
            extraFt1: order.extraFt1 === null ? "" : order.extraFt1,
            extraFt2: order.extraFt2 === null ? "" : order.extraFt2,
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/AddLensFeatures?d2ItemId=${LensItemId}`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, processedOrders, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ***************************Get All******************************
    GetLensMasterSettAndFeatures: async (LensDataId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                console.log("get by id");
                const apiUrl = `${WebApiUrl}/api/Order/LensMasterSettAndFeatures?LensDataId=${LensDataId}`;
                console.log(apiUrl);
                const response = await axios.get(
                    apiUrl,
                    {},
                    { withCredentials: true, credentials: "include" }
                );
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ******************************* Update Image ************************
    UpdateDeliveryImage: async (imageArray) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                console.log(imageArray);

                const apiUrl = `${WebApiUrl}/api/Order/UpdateDelBoyTripImage`;
                const response = await axios.post(apiUrl, imageArray, {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                console.log("Response:", response.data.isSuccess);
                if (response.data.isSuccess == false) {
                    console.error("Update failed:", response.data.errorMessages);
                    alert(response.data.errorMessages);
                    return false;
                }
                await new Promise(resolve => setTimeout(resolve, 1000));
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
                else {
                    throw error;
                }
            }
        }
        return fetchData();
    },
    // ******************* Get Image Id ***********************
    GettingDeliveryImage: async (imageId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/TripImage?imageId=${imageId}`;
                const response = await axios.get(
                    apiUrl,
                    {},
                    { withCredentials: true, credentials: "include" }
                );
                console.log("Response:", response.data.isSuccess);
                if (!response.data.isSuccess) {
                    console.error("Update failed:", response.data.errorMessage);
                }
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
                else {
                    throw error;
                }
            }
        }
        return fetchData();
    },
    // ******************* Get Order Item Department Wise ***********************
    getOrderItemDepartmentWise: async (ItemDepartmentId, LoginId) => {
        //const isLoginIdWise = true;
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderItemDepartmentWise?ItemDepartmentId=${ItemDepartmentId}&LoginId=${LoginId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log("Response:", response.data.isSuccess);
                if (!response.data.isSuccess) {
                    console.error("Update failed:", response.data.errorMessage);
                }
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
                else {
                    throw error;
                }
            }
        }
        return fetchData();
    },
    // ******************* Get Item List for Cook ***********************
    getItemListForCook: async (D2ItemId, CookStatus) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetItemListForCook?D2ItemId=${D2ItemId}&CookStatus=${CookStatus}`;
                const response = await axios.get(
                    apiUrl,
                    {},
                    { withCredentials: true, credentials: "include" }
                );
                console.log("Response:", response.data.isSuccess);
                if (!response.data.isSuccess) {
                    console.error("Update failed:", response.data.errorMessage);
                }
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    throw error;
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Running Order Item Deparment Wise ***************************
    getRunningOrderItemDepartmentWise: async (ItemDepartmentId, LoginId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetRunOrderItemDepartmentWise?ItemDepartmentId=${ItemDepartmentId}&LoginId=${LoginId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *************************** Patch update cancel item partially ***************************
    updateCancelItemPartially: async (data, ord2Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateCancelledItemPartially?ord2Id=${ord2Id}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************ Create Item Cancel ********************************
    createCancelItem: async (data) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateItemCancel`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, [{
                    ord1Id: Number(data.ord1Id),
                    ord2Id: Number(data.ord2Id),
                    d2ItemId: Number(data.d2ItemId),
                    cancelRequestQty: Number(data.cancelRequestQty),
                    cancelAmt: Number(data.cancelAmt),
                    cancelRemarks: data.cancelRemarks,
                    cancelStatus: data.cancelStatus,
                    cancelApprovalStatus: data.cancelApprovalStatus,
                    cancelRequestedBy: Number(data.cancelRequestedBy),
                    cancelRequestedOn: data.cancelRequestedOn,
                    cancelApprovedBy: data.cancelApprovedBy,
                    cancelApprovedOn: data.cancelApprovedOn,
                    cancelApprovedQty: data.cancelApprovedQty,
                }], {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ************************ Get Item Cancel by Ord1Id ********************************
    getCancelItemByOrd1Id: async (Ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetCancelledItemList?Ord1Id=${Ord1Id}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Cancel Item list ***************************
    getAllCancelItemList: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetAllCancelledItemList`;
                const response = await axios.post(apiUrl,
                    {
                        filter: filteredData,
                        order: [],
                        attribute: [],
                        pageNumber: 0,
                        pageSize: 50000
                    },
                    {
                        withCredentials: true,
                    });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get delivery Boy trip ***************************
    getDeliveryBoyTrip: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetAllDeliveryBoyTripsAsync`;
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Bill Series Counter ***************************
    getDelIncentiveAmt: async (delBoyId, tripNo) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetDelBoyIncentiveAmt?delBoyId=${delBoyId}&tripNo=${tripNo}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Last order of counter by user ***************************
    getLastOrderCounterByUser: async () => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const transformData = (data) => {
            const { colsAlias, orderList, summary } = data;

            // Ensure OrderList is structured properly
            const transformedOrderList = orderList.map(item => {
                let transformedItem = {};

                Object.keys(item).forEach(key => {
                    if (colsAlias[key]) {
                        const aliasKey = colsAlias[key];
                        transformedItem[aliasKey] = item[key];
                    } else {
                        transformedItem[key] = item[key];
                    }
                });
                return transformedItem;
            });
            // return { orderList: transformedOrderList };
            return { orderList: transformedOrderList, summary: summary };
        };
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrder1List`;  //Request failed with status code 502
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: [
                                    {
                                        fieldName: "OrderConfirmedBy",
                                        operatorName: "Equal",
                                        compareValue: loginUser.userId
                                    }
                                ]
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "Ord1Id",
                            ascending: false
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 1
                }, {
                    withCredentials: true,
                });
                console.log('Response:', transformData(response.data.result));
                return transformData(response.data.result);

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' ||
                    error.message === 'Request failed with status code 500') &&
                    retryCount < maxRetries) {

                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Payment received report ***************************
    getPaymentReceivedReport: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetPaymentReceivedList`;
                const response = await axios.post(apiUrl, {
                    filter: [],
                    order: [],
                    pageNumber: 0,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Payment received report ***************************
    getPaymentReceivedReportWithFilter: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetPaymentReceivedList`;
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [],
                    pageNumber: 0,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Order List By Ord1Id ***************************
    getOrderListByOrd1Id: async (order1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderByOrd1Id`;
                const response = await axios.post(
                    apiUrl, {
                    filter: [],
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    ord1Id: order1Id,
                    pageNumber: 0,
                    pageSize: 5000
                },
                    {
                        withCredentials: true,
                    }
                );
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // *********************** Get Order Listby Ord1Id Filter *************
    getOrderListByOrd1IdWithFilter: async (filterData, order1Id) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: Number(data.compareValue)
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderByOrd1Id`;
                const response = await axios.post(
                    apiUrl, {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    ord1Id: order1Id,
                    pageNumber: 0,
                    pageSize: 5000
                },
                    {
                        withCredentials: true,
                    }
                );
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Order List By Table Id ****************************
    getOrderListByTableId: async (tableId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetOrderByOrd1IdByTableId?tableId=${tableId}`;
                const response = await axios.post(apiUrl, {
                    filter: [],
                    order: [],
                    pageNumber: 0,
                    pageSize: 50
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Delivery Pickup List ***************************
    getDeliveryPickupList: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetDeliveryPickupList`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Feedback Report ***************************
    getFeedbackReport: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetFeedbackReport`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Feedback Filter ***************************
    getFeedbackReportFilter: async (filteredData) => {
        const formattedFilter = filteredData.map(filter => ({
            fieldName: filter.fieldName,
            operatorName: filter.operatorName,
            compareValue: filter.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetFeedbackReport`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter,
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "Ord1Id",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 50000

                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Feedback Report By ItemId ***************************
    getFeedbackReportByItemId: async (itemId, filteredData) => {
        const formattedFilter = filteredData.map(filter => ({
            fieldName: filter.fieldName,
            operatorName: filter.operatorName,
            compareValue: filter.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetFeedbackReportByItemId?itemId=${itemId}`;
                const response = await axios.post(apiUrl, {
                    filter: formattedFilter,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 50000
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Auto Tranfer to Kitchen ***************************
    autoTranferToKitchen: async () => {
        const dateTime = await CurrentDateAndTime.getCurrentDateTime();
        // const dateTime = new Date(isoString);

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/AutoTransferForHD`;
                const response = await axios.post(apiUrl,
                    {
                        "createdById": loginUser.userId,
                        "createdOn": dateTime
                    }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Raw Order List ***************************
    getRawOrderList: async (filteredData, pageIndex) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrderList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: true
                        }
                    ],
                    pageNumber: pageIndex || 0,
                    pageSize: 100,
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get OverAll Raw Order Report ***************************
    getOverallRawOrderReport: async (filteredData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrderList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 10000,
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },


    // ************************* Get Raw Order Report (for pageSize 50) ***************************
    getRawOrderReport: async (filteredData, pageIndex) => {
        console.log("Filtered Data:", filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData?.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const transformData = (data) => {
                    const { colsAlias, orderList, summary } = data;

                    // Ensure OrderList is structured properly
                    const transformedOrderList = orderList.map(item => {
                        let transformedItem = {};

                        Object.keys(item).forEach(key => {
                            if (colsAlias[key]) {
                                const aliasKey = colsAlias[key];
                                transformedItem[aliasKey] = item[key];
                            } else {
                                transformedItem[key] = item[key];
                            }
                        });
                        return transformedItem;
                    });
                    return { orderList: transformedOrderList, summary: summary };
                };

                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrder1List`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter || []
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1.id",
                            ascending: true
                        }
                    ],
                    pageNumber: pageIndex || 1,
                    pageSize: 100
                });
                console.log("Response:", transformData(response.data.result));
                return transformData(response.data.result);
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Raw Order Report (for pageSize 100) ***************************
    getRawOrderReportforPageSize100: async (filteredData, pageIndex) => {
        console.log("Filtered Data:", filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const transformData = (data) => {
                    const { colsAlias, orderList, summary } = data;
                    // Ensure OrderList is structured properly
                    const transformedOrderList = orderList.map(item => {
                        let transformedItem = {};

                        Object.keys(item).forEach(key => {
                            if (colsAlias[key]) {
                                const aliasKey = colsAlias[key];
                                transformedItem[aliasKey] = item[key];
                            } else {
                                transformedItem[key] = item[key];
                            }
                        });
                        return transformedItem;
                    });
                    return { orderList: transformedOrderList, summary: summary };
                };

                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrder1List`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: false
                        }
                    ],
                    pageNumber: pageIndex || 1,
                    pageSize: 100
                });
                console.log("Response:", transformData(response.data.result));
                return transformData(response.data.result);
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Raw Order Report (OR filter) ***************************
    getRawOrderReportFilter: async (filteredData, pageIndex) => {
        const formattedFilter = filteredData
            .map(filter => ({
                fieldName: filter.fieldName,
                operatorName: filter.operatorName,
                compareValue: filter.compareValue
            }));

        const formattedFilter1 = filteredData
            .map(filter => ({
                fieldName: (filter.fieldName == "orderDate" ? "deliveryDate" : filter.fieldName),
                operatorName: filter.operatorName,
                compareValue: filter.compareValue
            }));

        const data = {
            filter: {
                or: [
                    {
                        and: formattedFilter
                    },
                    {
                        and: formattedFilter1
                    }
                ]
            },
            order: [
                {
                    propertyName: "ord1Id",
                    ascending: false
                }
            ],
            pageNumber: pageIndex || 1,
            pageSize: 100
        }
        console.log(data);

        const transformData = (data) => {
            const { colsAlias, orderList, summary } = data;

            // Ensure OrderList is structured properly
            const transformedOrderList = orderList?.map(item => {
                let transformedItem = {};

                Object.keys(item).forEach(key => {
                    if (colsAlias[key]) {
                        const aliasKey = colsAlias[key];
                        transformedItem[aliasKey] = item[key];
                    } else {
                        transformedItem[key] = item[key];
                    }
                });
                return transformedItem;
            });
            return { orderList: (transformedOrderList || []), summary: summary };
        };
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrder1List`;
                const response = await axios.post(apiUrl, data);
                console.log("Response:", transformData(response.data.result));
                return transformData(response.data.result);
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Auto transfer for Table ***************************
    autoTransferForTable: async (ord1Id, currentDate) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        // const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);

        const updatedData = {
            ord1Id: ord1Id,
            createdById: loginUser.userId,
            createdOn: currentDate,
        }
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/AutoTransferForTable`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Update Order Status as Process ***************************
    updateOrderStatusAsProcess: async (ord1Id, ord2Id, currentDateTime) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        // const currentDateTime = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDateTime = new Date(isoString);

        const updatedData = {
            ord1Id: ord1Id,
            ord2Id: ord2Id,
            createdById: loginUser.userId,
            createdOn: currentDateTime,
        }
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderStatusAsProcess?ord2Id=${ord2Id}`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
    // ************************* Get PopUp Notification Item List By UserId ***************************
    getPopUpNotificationItemListByUserId: async (userId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetPopUpNotificationItemListByUserId?userId=${userId}`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Payment Mode by ord1Id ***************************
    getPaymentModeByOrd1Id: async (ord1Id, paymentModeName) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetPayIdByOrd1Id?ord1Id=${ord1Id}&paymentModeName=${paymentModeName}`;
                const response = await axios.post(apiUrl, {},
                    {
                        withCredentials: true,
                    });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Update Order Status as Delivered ***************************
    updateOrderStatusAsDelivered: async (ord1Id, ord2Id, currentDateTime) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);
        console.log("loginUserId", loginUser)

        // const currentDateTime = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDateTime = new Date(isoString);

        const updatedData = {
            ord1Id: Number(ord1Id),
            ord2Id: Number(ord2Id),
            createdById: loginUser.userId,
            createdOn: currentDateTime,
        }
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderStatusAsDelivered`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });

                console.log("Response:", response.data);
                return true;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ************************* Get KOT Order Summary ***************************
    getKotOrderSummary: async (filteredData, pageIndex) => {
        console.log("Filtered Data:", filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetKOTItemSummaryList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: []
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: true
                        }
                    ],
                    pageNumber: pageIndex || 1,
                    pageSize: 50
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get KOT Order Summary Filter ***************************
    getKotOrderSummaryFilter: async (filteredData, pageIndex) => {
        console.log("Filtered Data:", filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetKOTItemSummaryList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: false
                        },
                        {
                            propertyName: "iPrefNo",
                            ascending: true
                        }
                    ],
                    pageNumber: pageIndex || 1,
                    pageSize: 100
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ****************** Overall Summary Data ***************
    getOverAllKotOrderSummaryFilter: async (filteredData) => {
        console.log("Filtered Data:", filteredData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetKOTItemSummaryList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: false
                        },
                        {
                            propertyName: "iPrefNo",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 100000
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get Raw Order Report (POS filter) ***************************
    getRawOrderReportForPOS: async (filteredData, pageIndex) => {
        const data = {
            filter: {
                or: filteredData,
            },
            order: [
                {
                    propertyName: "ord1Id",
                    ascending: true
                }
            ],
            pageNumber: pageIndex || 1,
            pageSize: 100
        }
        console.log(data);

        const transformData = (data) => {
            const { colsAlias, orderList, summary } = data;

            // Ensure OrderList is structured properly
            const transformedOrderList = orderList.map(item => {
                let transformedItem = {};

                Object.keys(item).forEach(key => {
                    if (colsAlias[key]) {
                        const aliasKey = colsAlias[key];
                        transformedItem[aliasKey] = item[key];
                    } else {
                        transformedItem[key] = item[key];
                    }
                });
                return transformedItem;
            });
            // return { orderList: transformedOrderList };
            return { orderList: transformedOrderList, summary: summary };
        };
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrder1List`;
                const response = await axios.post(apiUrl, data);
                console.log("Response:", transformData(response.data.result));
                return transformData(response.data.result);
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************ Create/Update Item Incentive Master ******************
    createOrUpdateItemIncentiveMaster: async (data) => {
        const updatedData = data.map(item => ({
            incentiveDateFrom: item.incentiveDateFrom,
            incentiveDateTo: item.incentiveDateTo,
            itemId: item.itemId,
            isActive: true,
        }));
        console.log(updatedData);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateOrUpdateItemIncentiveMaster`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl, updatedData, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ************************* Get All Item Incentive Master ***************************
    getAllItemIncentiveMaster: async (filteredData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                let formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                formattedFilter.push({
                    fieldName: "masterType",
                    operatorName: "Equal",
                    compareValue: "259"
                })

                const apiUrl = `${WebApiUrl}/api/Order/GetAllItemIncentiveMaster`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "Id",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 10000
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get All Item Incentive Report ***************************
    getAllReportItemIncentiveMaster: async (filteredData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetAllReportItemIncentiveMaster`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter,
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "Id",
                            ascending: true
                        }
                    ],
                    pageNumber: 1,
                    pageSize: 10000
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get Raw Order List multiple ***************************
    getRawOrderListAll: async (filteredData, pageIndex) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const formattedFilter = filteredData.map(filter => ({
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    compareValue: filter.compareValue
                }));

                const apiUrl = `${WebApiUrl}/api/Order/GetRawOrderList`;
                const response = await axios.post(apiUrl, {
                    filter: {
                        or: [
                            {
                                and: formattedFilter
                            }
                        ]
                    },
                    order: [
                        {
                            propertyName: "ord1Id",
                            ascending: true
                        }
                    ],
                    pageNumber: pageIndex || 0,
                    pageSize: 50000,
                });
                console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete Payment Mode with Orders ****************************
    deletePaymentModeWithOrders: async (ord1Id) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/RemovePaymentModeWithOrder?ord1Id=${ord1Id}`;
                const response = await axios.delete(apiUrl, {
                    withCredentials: true,
                });
                console.log('Delete Response:', response.data.result);
                return true;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // *************************** Create service Type ***************************
    createServiceType: async (filterData) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateServiceTypes`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl,
                    {
                        name: filterData.name,
                        printName: filterData.name,
                        itemTransferTime: filterData.itemTransferTime,
                        advanceOrderDays: filterData.advanceOrderDays,
                        noOfTimeSlot: filterData.noOfTimeSlot,
                        checkItmGrpToShow: filterData.checkItmGrpToShow,
                        itmGrpValues: filterData.itmGrpValues,
                    }, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },

    // ************************* Insert Add to Cart CreateEcomCartDetail ***************************
    insertCreateEcomCartDetail: async (data) => {
        const storedArrayString = localStorage.getItem('loginAccountUserData');
        var loginUser;
        if (storedArrayString) {
            loginUser = JSON.parse(storedArrayString);
        }
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/CreateEcomCartDetail`;
                console.log(apiUrl);
                const response = await axios.post(
                    apiUrl,
                    {
                        d1ItemId: Number(data.d1ItemId),
                        d2ItemId: Number(data.d2ItemId),
                        d1AccountId: Number(data.d1AccountId) || 0,
                        d2AccountId: Number(data.d2AccountId) || 0,
                        userId: loginUser?.userId || 0 || Number(data.userId),
                        quantity: Number(data.quantity),
                        //unit: Number(data.unit) || "Nos",
                        // itemPrice: Number(data.itemPrice),
                        clientId: 1, //Number(data.clientId),
                        tableId: Number(data.tableId),
                        tableGroupId: Number(data.tableGroupId),
                        itemSuggestion: data.itemSuggestion || "",
                    },
                    {
                        withCredentials: true,
                        credentials: "include",
                    }
                );

                console.log("Response:", response.data);
                return true;
            } catch (error) {
                console.error("Error:", error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Remove from Cart CreateEcomCartDetail ***************************
    RemoveEcomCartDetail: async (data) => {
        console.log(data);
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/RemoveEcomCartDetail`;
                console.log(apiUrl);
                const response = await axios.post(
                    apiUrl,
                    {
                        d1ItemId: Number(data.d1ItemId),
                        d2ItemId: Number(data.d2ItemId),
                        d1AccountId: Number(data.d1AccountId),
                        d2AccountId: Number(data.d2AccountId),
                        userId: Number(data.userId),
                        quantity: Number(data.quantity),
                        unit: Number(data.unit),
                        itemPrice: Number(data.itemPrice),
                        clientId: Number(data.clientId)
                    },
                    {
                        withCredentials: true,
                        credentials: "include",
                    }
                );
                console.log("Response:", response.data);
                return true;
            } catch (error) {
                console.error("Error:", error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Get All Ecom Cart Detail ***************************
    getAllEcomCartDetails: async (d2AccountId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetAllEcomCartDetails?d2AccountId=${d2AccountId || 0}`;
                const response = await axios.post(
                    apiUrl,
                    {
                        filter: [],
                        order: [
                            {
                                propertyName: "string",
                                ascending: true
                            }
                        ],
                        pageNumber: 0,
                        pageSize: 0
                    },
                    {
                        withCredentials: true,
                    }
                );
                // console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error("Error:", error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get All Ecom Cart Detail Filter ***************************
    getAllEcomCartDetailsFilter: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/GetAllEcomCartDetailsFilter`;
                const response = await axios.post(
                    apiUrl,
                    {
                        filter: filteredData,
                        order: [
                            {
                                propertyName: "string",
                                ascending: true
                            }
                        ],
                        pageNumber: 1,
                        pageSize: 1000
                    },
                    {
                        withCredentials: true,
                    }
                );
                // console.log("Response:", response.data.result);
                return response.data.result;
            } catch (error) {
                console.error("Error:", error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete from Ecom ****************************
    deleteItemFromCart: async (EcomCartId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/DeleteEcomCartDetail?EcomCartId=${EcomCartId}`;
                const response = await axios.delete(apiUrl, {
                    withCredentials: true,
                });
                console.log('Delete Response:', response.data.result);
                return true;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* update cartdata partially  ***************************
    UpdateEcomCartPartially: async (data, EcomCartId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/UpdateEcomCartPartiallyWithEcomId?EcomCartId=${EcomCartId}`;
                console.log(apiUrl);
                const response = await axios.patch(apiUrl, data, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.isSuccess
            } catch (error) {
                console.error("Error:", error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ************************* Delete from Ecom ****************************
    deleteEcomCartByTableId: async (tableId, d2AccountId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/RemoveAllEcomCartItems?tableId=${tableId}&d2AccountId=${d2AccountId}`;
                const response = await axios.delete(apiUrl, {
                    withCredentials: true,
                });
                console.log('Delete Response:', response.data.result);
                return true;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // *************************** Set Order to Confirmed ***************************
    setOrderToConfirmed: async (data) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/Order/SetOrderToConfirmed`;
                console.log(apiUrl);
                const response = await axios.post(apiUrl,
                    {
                        ord1Id: data.ord1Id || 0,
                        createdById: data.createdById || 0,
                        createdOn: data.createdOn || ""
                    }, {
                    withCredentials: true,
                    credentials: 'include',
                });
                console.log("Response:", response.data);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                    // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                } else {
                    alert("An error occurred. Please try again.");
                }
            }
        }
        return fetchData();
    },
};

export default Order;


