import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import Transaction from "../../API/APIServices/Transaction";

const PopUpSerialNumber = (props) => {
  const {
    PopUpSerialOpen,
    enableSerialNoGeneration,
    ProductOpnedInPopup,
    serialNoData,
    setSerialNoData,
    itemMasterRows,
    setItemMasterRows,
    onChangeProductSaveMethod,
    inputValues,
  } = props;
  console.log(serialNoData);

  console.log(ProductOpnedInPopup);

  const [totalChecked, setTotalChecked] = useState(0);

  const CheckTheSerialQty = async (rowIndex, d2_Id) => {
    debugger;
    if (ProductOpnedInPopup.Qty == totalChecked) {
      await onChangeProductSaveMethod("", rowIndex, "", d2_Id);
    } else if (ProductOpnedInPopup.Qty < totalChecked) {
      alert("Please select more serial number to match the quantity");
    } else {
      alert("Please select less serial number to match the quantity");
    }
  };

  useEffect(() => {
    if (serialNoData && serialNoData.length > 0) {
      var TotalcheckedCount = serialNoData.filter(
        (item) => item.checked
      ).length;
      setTotalChecked(TotalcheckedCount);
    }
  }, [serialNoData]);

  const handleKeyPress = (event) => {
    // Check if both Alt key and X key are pressed
    if (event.key === "Escape") {
      PopUpSerialOpen(ProductOpnedInPopup.rowIndex);
    }
  };

  const fetchDataForSerialNo = async () => {
    if (ProductOpnedInPopup) {
      var filterData = {
        isSummary: true,
        codeType: 2,
        itmId_d2: [ProductOpnedInPopup.d2_Id],
        codeNo: "",
        mgfDate: "",
        expDate: "",
        invoiceId: [],
        vendorId_d2: [],
        pageNumber: 0,
        pagesize: 0,
      };

      var data = await Transaction.GetBarcodeSerialBatchNo(filterData);
      if (data && data.length > 0) {
        var newRows = []; // Initialize the array

        for (var i = 0; i < data.length; i++) {
          // Create a new row object for each item in the data array
          var newRow = {
            id: data[i].id,
            serialNumber: data[i].codeNo,
            vc1: data[i].mrp,
            vc2: data[i].sellingPrice,
            vc3: data[i].costPrice,
            vc4: data[i].purPrice,
            vc5: data[i].qty,
            vc6: "",
            vc7: "",
            vc8: "",
            vc9: "",
            vc10: "",
            checked: false,
          };

          // Push the new row object into the newRows array
          newRows.push(newRow);
        }

        // Set the new rows data in the state
        setSerialNoData(
          newRows // Using the correct object key to set the value
        );
      }
    }
    debugger;
    if (inputValues.transId && inputValues.transId != 0) {
      var filterData = {
        isSummary: true,
        codeType: 2,
        itmId_d2: [],
        codeNo: "",
        mgfDate: "",
        expDate: "",
        invoiceId: [inputValues.transId],
        vendorId_d2: [],
        pageNumber: 0,
        pagesize: 0,
      };

      var data = await Transaction.GetBarcodeSerialBatchNo(filterData);
      if (data && data.length > 0) {
        var newRows = []; // Initialize the array

        for (var i = 0; i < data.length; i++) {
          // Create a new row object for each item in the data array
          var newRow = {
            id: data[i].id,
            serialNumber: data[i].codeNo,
            vc1: data[i].mrp,
            vc2: data[i].sellingPrice,
            vc3: data[i].costPrice,
            vc4: data[i].purPrice,
            vc5: data[i].qty,
            vc6: "",
            vc7: "",
            vc8: "",
            vc9: "",
            vc10: "",
            checked: true,
          };

          // Push the new row object into the newRows array
          newRows.push(newRow);
        }

        // Append the new rows data to the existing state
        setSerialNoData((prevSerialNoData) => [
          ...newRows, // Append new rows
          ...prevSerialNoData, // Retain existing rows
        ]);
      }
    }
  };

  const fetchDataForSerialNoAutoGeneration = async () => {
    debugger;
    if (
      (ProductOpnedInPopup.serialNumbers?.length ?? 0) ==
      ProductOpnedInPopup.Qty
    ) {
      setSerialNoData(ProductOpnedInPopup.serialNumbers);
    } else if (ProductOpnedInPopup?.Qty) {
      setSerialNoData(
        Array.from({ length: ProductOpnedInPopup.Qty }, (_, index) => ({
          id: 0,
          serialNumber: "",
          vc1: "",
          vc2: "",
          vc3: "",
          vc4: "",
          vc5: "",
          vc6: "",
          vc7: "",
          vc8: "",
          vc9: "",
          vc10: "",
          checked: false,
        }))
      );
    }
  };

  useEffect(() => {
    if (enableSerialNoGeneration == "1") {
      fetchDataForSerialNo();
    }
  }, [ProductOpnedInPopup]);

  useEffect(() => {
    if (enableSerialNoGeneration == "2") {
      fetchDataForSerialNoAutoGeneration();
    }
  }, [ProductOpnedInPopup?.Qty]);

  useEffect(() => {
    // Add event listener when component is mounted
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener when component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleInputChange = (index, field, value) => {
    debugger;
    setSerialNoData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  useEffect(() => {
    debugger;
    if (enableSerialNoGeneration == "1") {
      if (serialNoData && serialNoData.length > 0) {
        var FilteredserialNoData = serialNoData.filter(
          (item) => item.serialNumber.trim() !== "" && item.checked == true
        );
        console.log(FilteredserialNoData);
        setItemMasterRows((prevRows) => {
          return prevRows.map((row, idx) => {
            if (idx + 1 == ProductOpnedInPopup.rowIndex) {
              return { ...row, serialNumbers: FilteredserialNoData };
            }
            return row;
          });
        });
      }
    } else if (enableSerialNoGeneration == "2") {
      if (serialNoData && serialNoData.length > 0) {
        var FilteredserialNoData = serialNoData.filter(
          (item) => item.serialNumber.trim() !== ""
        );
        console.log(FilteredserialNoData);
        setItemMasterRows((prevRows) => {
          return prevRows.map((row, idx) => {
            if (idx + 1 == ProductOpnedInPopup.rowIndex) {
              return { ...row, serialNumbers: FilteredserialNoData };
            }
            return row;
          });
        });
      }
    }
  }, [serialNoData]);

  const [TopSetting, setTopSetting] = useState(false);
  const showSetting = () => {
    setTopSetting(true);
  };
  const hideSetting = () => {
    setTopSetting(false);
  };

  const setHideandShow = (e) => {
    const { name, checked } = e.target;
    setHideAndShowRow((prevRows) => ({
      ...prevRows, // Spread the previous state
      [name]: checked, // Update the specific row visibility
    }));
  };

  const [HideAndShowRow, setHideAndShowRow] = useState({
    SerialNumber: true,
    Mrp: false,
    SalePrice: false,
    CostPrice: false,
    PurchasePrice: false,
    Qty: false,
  });

  const [filteredData, setFilteredData] = useState([]);
  const [filteredSearch, setFilteredSearch] = useState(false);

  const FetchDerialNoSearch = (e) => {
    debugger;
    var searchValue = e.target.value;
    if (searchValue) {
      var data = serialNoData.filter((item) =>
        item.serialNumber.includes(searchValue)
      );
      console.log(data);
      if (data) {
        setFilteredSearch(true);
        setFilteredData(data);
      }
    }

    if (searchValue == "") {
      setFilteredData(serialNoData);
      setFilteredSearch(false);
    }
  };

  return (
    <div className="modal-back">
      <div className="PopUpSerialNumber">
        <div className="PopUpSerialNumber-itemName">
          {ProductOpnedInPopup.productName}{" "}
          <i
            className="fa-solid fa-gear"
            onClick={showSetting}
            style={{ display: "block" }}
          ></i>
        </div>

        <div className="PopUpSerialNumber-itemDetails">
          <div className="PopUpSerialNumber-itemDetails-1">
            ITEM :- {ProductOpnedInPopup?.productName}
          </div>
          <div className="PopUpSerialNumber-itemDetails-1">
            Qty :- {ProductOpnedInPopup?.Qty}
          </div>
          <div className="PopUpSerialNumber-itemDetails-1">
            Warranty Month :- 12
          </div>
        </div>
        <div
          className="PopUpSerialNumber-itemDetails"
          style={{ paddingTop: 0 }}
        >
          {" "}
          <input
            type="number"
            placeholder="Search Serial No"
            onChange={(e) => FetchDerialNoSearch(e)}
            style={{ padding: "0px 5px" }}
          />
        </div>

        {/* {filteredSearch == true ? "true" : "fasle"}

        {filteredData.length === 0 && serialNoData.length === 0 ? (
          <>Length 0: No data found</>
        ) : (
          <>
            Length not 0: Filtered Data Length = {filteredData.length}, Serial
            No Data Length = {serialNoData.length}
          </>
        )} */}

        <table className="PopUpSerialNumber-table">
          <tbody>
            <thead>
              <tr>
                <th style={{ maxWidth: "40px" }}>
                  {enableSerialNoGeneration == 2 && "S No"}

                  {enableSerialNoGeneration == 1 && (
                    <input style={{ width: "15px" }} type="checkbox" />
                  )}
                </th>
                {HideAndShowRow.SerialNumber && <th>Serial Number</th>}
                {HideAndShowRow.Mrp && <th>Mrp</th>}
                {HideAndShowRow.SalePrice && <th>Sale Price</th>}
                {HideAndShowRow.CostPrice && <th>Cost Price</th>}
                {HideAndShowRow.PurchasePrice && <th>Purchase Price</th>}
                {HideAndShowRow.Qty && <th>Qty</th>}
              </tr>
            </thead>
            {filteredSearch &&
            (filteredData.length === 0 || serialNoData.length === 0) ? (
              <tr>
                <td
                  colSpan={
                    /* Adjust this number to match your total column count */ 7
                  }
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    padding: "10px",
                  }}
                >
                  No serial numbers found
                </td>
              </tr>
            ) : (
              (filteredSearch ? filteredData : serialNoData).map(
                (item, index) => (
                  <tr key={index}>
                    <td style={{ width: "40px" }}>
                      {enableSerialNoGeneration == 2 && index + 1}
                      {enableSerialNoGeneration == 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>{index + 1}</span>
                          <input
                            style={{ width: "15px", marginLeft: "12px" }}
                            type="checkbox"
                            checked={item.checked || false}
                            placeholder="Enter Serial Number"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "checked",
                                e.target.checked
                              )
                            }
                          />
                        </div>
                      )}
                    </td>

                    {HideAndShowRow.SerialNumber && (
                      <td>
                        {enableSerialNoGeneration == 1 && (
                          <span>{item.serialNumber}</span>
                        )}
                        {enableSerialNoGeneration == 2 && (
                          <input
                            type="text"
                            value={item.serialNumber || ""}
                            placeholder="Enter Serial Number"
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "serialNumber",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </td>
                    )}
                    {HideAndShowRow.Mrp && (
                      <td>
                        <input
                          type="number"
                          value={item.vc1 || ""}
                          placeholder="Enter MRP"
                          onChange={(e) =>
                            handleInputChange(index, "vc1", e.target.value)
                          }
                        />
                      </td>
                    )}
                    {HideAndShowRow.SalePrice && (
                      <td>
                        <input
                          type="number"
                          value={item.vc2 || ""}
                          placeholder="Enter Sale Price"
                          onChange={(e) =>
                            handleInputChange(index, "vc2", e.target.value)
                          }
                        />
                      </td>
                    )}
                    {HideAndShowRow.CostPrice && (
                      <td>
                        <input
                          type="number"
                          value={item.vc3 || ""}
                          placeholder="Enter Cost Price"
                          onChange={(e) =>
                            handleInputChange(index, "vc3", e.target.value)
                          }
                        />
                      </td>
                    )}
                    {HideAndShowRow.PurchasePrice && (
                      <td>
                        <input
                          type="number"
                          value={item.vc4 || ""}
                          placeholder="Enter P. Price"
                          onChange={(e) =>
                            handleInputChange(index, "vc4", e.target.value)
                          }
                        />
                      </td>
                    )}
                    {HideAndShowRow.Qty && (
                      <td>
                        <input
                          type="number"
                          value={item.vc5 || ""}
                          placeholder="Enter Qty"
                          onChange={(e) =>
                            handleInputChange(index, "vc5", e.target.value)
                          }
                        />
                      </td>
                    )}
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
        <div className="PopUpSerialNumber-buttonBox">
          {enableSerialNoGeneration == "1" && (
            <>
              <span style={{ marginLeft: "15px" }}>
                Total Item Selected = ({totalChecked})
              </span>
              <br />
              <span style={{ marginLeft: "10px" }}>
                Total Qty Left = ({serialNoData.length})
              </span>
            </>
          )}
          {/* <button>Generate Auto items serial number</button> */}
          <div>
            <button
              onClick={() => PopUpSerialOpen(ProductOpnedInPopup.rowIndex)}
              className="PopUpSerialNumber-button1"
            >
              Close
            </button>
            {enableSerialNoGeneration === "1" ? (
              <button
                className="PopUpSerialNumber-button2"
                onClick={() =>
                  CheckTheSerialQty(
                    ProductOpnedInPopup.rowIndex,
                    ProductOpnedInPopup.d2_Id
                  )
                }
              >
                Save
              </button>
            ) : (
              <button
                className="PopUpSerialNumber-button2"
                onClick={() =>
                  onChangeProductSaveMethod(
                    "",
                    ProductOpnedInPopup.rowIndex,
                    "",
                    ProductOpnedInPopup.d2_Id
                  )
                }
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      {TopSetting && (
        <div className="modal">
          <div className="ModalBoxProductSetting">
            <div className="modal-title">
              <h3 className="modal-h">Add Columns into Table</h3>
              <span className="close" onClick={hideSetting}>
                &times;
              </span>
            </div>
            <div
              className="ModalContentModalSettingP"
              style={{ paddingTop: "5px" }}
            >
              <div className="gridview2">
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.SerialNumber}
                      disabled={HideAndShowRow.SerialNumber}
                      name="SerialNumber"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      Serial Number
                    </label>
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.Mrp}
                      name="Mrp"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Mrp</label>
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.SalePrice}
                      name="SalePrice"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Sale Price</label>
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.CostPrice}
                      name="CostPrice"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Cost Price</label>
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.PurchasePrice}
                      name="PurchasePrice"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      Purchase Price
                    </label>
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      checked={HideAndShowRow.Qty}
                      name="Qty"
                      onChange={(e) => setHideandShow(e)}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Qty</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpSerialNumber;
