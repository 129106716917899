import React, { useState } from 'react';
import CommonDeploymentService from '../../API/APIServices/CommonDeploymentService';
import Loader from '../Props/Loader';


function SoftwareConfigurationPopUP() {

    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleCheckDatabaseConnectionClick(){

        setIsShow(true);
    }

    async function handleCommonDeploymentClick(){

        setLoading(true);

        try{

            let response = await CommonDeploymentService.createCommonDeployment();

            if(response.data.isSuccess){
                alert(response.data.result);
            }else{
                alert(response.data.errorMessages);
            }
        }catch(e){
            alert(e.response.data.errorMessages);
        }
        setLoading(false);

    }



    return (
        <div>
            <div>
                <button className='config-button' onClick={handleCheckDatabaseConnectionClick}>Check Database Connection</button>
                <div className='c-red my-5px'>If server name is not comes, please click again.</div>
            </div>

            <hr />

            {isShow && (
                <>
                    <div className='px-20px flex-between'>
                        <div className='flex-center-column relative t-14'>
                            <button className='config-button'>Create New Company</button>
                            <div>-------------OR-------------</div>
                        </div>

                        <button className='config-button' onClick={handleCommonDeploymentClick}>App Setup (Common Deployment)</button>
                    </div>

                    <div className='px-20px mt-30px'>
                        <strong>Restore Database</strong>
                        <input className='border-2 br-4 p-5px display-block w-100pr' type="file" />
                    </div>

                    <div className='px-20px my-5px'>
                        <input className='mr-5' type="checkbox" />
                        <span>Do you want to replace existing database in server?</span>
                    </div>

                    <div className='flex-center my-5px'>
                        <button className='config-button blue-button'>Restore Files</button>
                    </div>
                </>
            )}

            {loading && <Loader />}
        </div>
    )
}

export default SoftwareConfigurationPopUP;