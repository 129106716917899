import { useContext, useEffect, useRef, useState } from "react";
import "./POSInvoice.css";
import Order from "../../API/APIServices/Order";
import ItemDetailModalBox from "./ItemDetailModalBox";
import { useNavigate } from "react-router-dom";
import OrderApi from "../../API/APIServices/Order";
import GstModalOrderView from "./GstModalOrderView";
import DeliveryAndTimeSlot from "../../API/APIServices/DeliveryAndTimeSlot";
import OrderDetailStatusModal from "./OrderDetailStatusModal";
import PaymantModeModel from "./PaymentModeModel";
import BillPaymentMode from '../../API/APIServices/BillPaymentMode';
import BillSeriesMaster from "../../API/APIServices/BillSeriesMaster";
import Loader from "../../Inventory/Props/Loader";
import TrackOrderModel from "./TrackOrderModel";
import moment from "moment";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import MyContext from "../../Inventory/MyContext";
import ReportDesign from "./ReportDesign";

const POS = (props) => {
    const { setOrderListPos, orderListPos, fetchOrderData, handleRefreshBtn, formatDate, startIndexPos, handlePaymentPay, summary,
        setSummary, addFilterForPOS, setFilterForPPOS,
    } = props;

    const [loading, setLoading] = useState(true);

    const orderList = orderListPos;
    const { UserDetails, maskMobileNo, convertDateTimeToDateAndTime } = useContext(MyContext)

    const [RemarkId, setRemarkId] = useState([]);

    const [GstModal, setGstModal] = useState(false);

    var [filterDataForOrder, setfilterDataForOrder] = useState([{
        fieldName: "serviceTypeId",
        operatorName: "Equal",
        compareValue: 754,
    }])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const date = await CurrentDateAndTime.getCurrentDate();
                setFilterForPPOS([
                    {
                        fieldName: "serviceTypeId",
                        operatorName: "Equal",
                        compareValue: 754
                    },
                    {
                        fieldName: "orderDate",
                        operatorName: "Equal",
                        compareValue: date
                    }
                ]);
            } catch (e) {
                console.log(e);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const UpdatePaymentPartially = async (e, data) => {

        if (window.confirm("Are you sure you want to change the payment status")) {
            // payment status and paid amount and balance amount  
            console.log(e.target.value)
            console.log(data)

            const value = Number(e.target.value);

            const storedArrayString = localStorage.getItem('loginAccountUserData');
            var loginUser;
            if (storedArrayString) {
                loginUser = JSON.parse(storedArrayString);
            }

            const datetime = await CurrentDateAndTime.getCurrentDateTime();
            // const datetime = new Date(isoString);

            const updatedValues = [
                {
                    operationType: 0,
                    path: "PaymentModeId",
                    op: "Add",
                    from: "string",
                    value: value
                },
                {
                    operationType: 0,
                    path: "balanceAmt",
                    op: "Add",
                    from: "string",
                    value: "0"
                },
                {
                    operationType: 0,
                    path: "paymentAmount",
                    op: "Add",
                    from: "string",
                    value: Number(data.grandTotal) - (Number(data.paymentAmount) + Number(data.o1CancelledAmt))
                },
                {
                    operationType: 0,
                    path: "paymentStatus",
                    op: "Add",
                    from: "string",
                    value: "Done"
                },
                {
                    operationType: 0,
                    path: "paymentDoneOn",
                    op: "Add",
                    from: "string",
                    value: datetime
                },
                {
                    operationType: 0,
                    path: "paymentDoneBy",
                    op: "Add",
                    from: "string",
                    value: loginUser.userId
                }
            ]
            await Order.updateOrderPartially(updatedValues, data.ord1Id);
            const filterData1 = [{
                ord1Id: data.ord1Id,
                paymentModeId: value,
                paymentAmount: Number(data.grandTotal) - (Number(data.paymentAmount) + Number(data.o1CancelledAmt)),
                transId: "",
                payUId: 0,
                bankRefNo: "",
                status: "",
                paymentTypeMethod: "",
            }]
            await Order.createPaymentModeWithOrder(filterData1)
            fetchOrderData()
            alert("Updated")
        }

        // if (window.confirm("Are you sure you want to change the payment status")) {
        //     // payment status and paid amount and balance amount  
        //     console.log(e.target.value)
        //     console.log(data)
        //     const time = await getCurrentTime();
        //     const updatedValues = [
        //         {
        //             operationType: 0,
        //             path: "PaymentModeId",
        //             op: "Add",
        //             from: "string",
        //             value: e.target.value
        //         },
        //         {
        //             operationType: 0,
        //             path: "balanceAmt",
        //             op: "Add",
        //             from: "string",
        //             value: "0"
        //         },
        //         {
        //             operationType: 0,
        //             path: "paymentAmount",
        //             op: "Add",
        //             from: "string",
        //             value: Number(data.confirmTotalAmt) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0))
        //         },
        //         {
        //             operationType: 0,
        //             path: "paymentStatus",
        //             op: "Add",
        //             from: "string",
        //             value: "Done"
        //         },
        //         // {
        //         //   operationType: 0,
        //         //   path: "PaymentDoneTime",
        //         //   op: "Add",
        //         //   from: "string",
        //         //   value: new Date().toISOString()
        //         // },
        //         {
        //             operationType: 0,
        //             path: "PaymentDoneOn",
        //             op: "Add",
        //             from: "string",
        //             value: time,
        //         },
        //         {
        //             operationType: 0,
        //             path: "paymentDoneBy",
        //             op: "Add",
        //             from: "string",
        //             value: loginUser.userId
        //         }
        //     ]
        //     await Order.updateOrderPartially(updatedValues, data.ord1Id);

        //     const filterData1 = [{
        //         ord1Id: data.ord1Id,
        //         paymentModeId: e.target.value,
        //         paymentAmount: Number(data.confirmTotalAmt) - (Number(data.paymentAmount || 0) + Number(data.o1CancelledAmt || 0)),
        //         transId: "",
        //         payUId: 0,
        //         bankRefNo: "",
        //         status: "",
        //         paymentTypeMethod: "",
        //     }]
        //     await Order.createPaymentModeWithOrder(filterData1)
        //     fetchOrderData()
        //     alert("Updated")

        // }
    }

    const [ord1Id, setOrd1Id] = useState();

    const openGstModal = async (id) => {
        handleRefreshBtn();
        setOrd1Id(id);
        await handleGetItem(id);
        setGstModal(true);
    };

    const closeGstModal = () => {
        handleRefreshBtn();
        setGstModal(false);
    };

    const [selectedDate, setSelectedDate] = useState('default');
    const [selectedDateFD, setSelectedDateFD] = useState('default');

    const [previousDates, setPreviousDates] = useState([]);

    const dataForAllotedTime = {
        deliveryPointName: 1,
        orderType: 752,
        distance: 0
    }

    const [DeliverySlotData, setDeliveryData] = useState([])

    useEffect(() => {
        const getdeliveryTimeAlot = async () => {
            const Data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(dataForAllotedTime)
            setDeliveryData(Data)
        }
        getdeliveryTimeAlot()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const isoString = await CurrentDateAndTime.getCurrentDateTime();
            const today = new Date(isoString);

            const dates = [];
            for (let i = 0; i < 5; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                dates.push(date);
            }
            console.log("Formated Date", dates);
            setPreviousDates(dates);
            setSelectedDate(formatDate(dates[0]));
        }
        fetchData();
    }, []);

    const handleAddFilter = async (e, name) => {
        setStopScrollingPos(false);
        setPageIndexPos(1);

        let FilterToSend;

        if (name == "orderDate")
            setSelectedDate(e.target.value);
        else if (name == "deliveryDate")
            setSelectedDateFD(e.target.value);

        if (e.target.value === "default") {
            // Remove the filter object with the specified name
            setfilterDataForOrder(prevFilters => prevFilters.filter(filter => filter.fieldName !== name));
            const updatedData = filterDataForOrder.filter(filter => filter.fieldName !== name);
            handletestFetch(updatedData);
            return;
        }

        const newFilter = {
            fieldName: name,
            operatorName: "Equal",
            compareValue: e.target.value,
        };

        // Check if a filter with the same name already exists
        const existingFilterIndex = filterDataForOrder.findIndex(filter => filter.fieldName === name);

        if (existingFilterIndex !== -1) {
            // If a filter with the same name exists, update its value
            const updatedFilters = [...filterDataForOrder]; // Copying filterDataForOrder array
            updatedFilters[existingFilterIndex] = newFilter; // Updating the desired index with newFilter
            FilterToSend = updatedFilters;


            setfilterDataForOrder(prevFilters => {
                const updatedFilters = [...prevFilters];
                updatedFilters[existingFilterIndex] = newFilter;
                return updatedFilters;
            });

        } else {
            // If no filter with the same name exists, add the new filter
            setfilterDataForOrder(prevFilters => [...prevFilters, newFilter]);
            FilterToSend = [...filterDataForOrder, newFilter];
        }
        handletestFetch(FilterToSend)
    };

    const handletestFetch = async (FilterToSend) => {
        // console.log(filterDataForOrder)
        // const data = await OrderApi.getOrderListReport(FilterToSend);
        const data = await OrderApi.getRawOrderReportforPageSize100(FilterToSend, 1);
        // Update state with the filtered order list
        setOrderListPos(data);
    }

    useEffect(() => {
        console.log(filterDataForOrder)
    }, [filterDataForOrder])


    const RemarkSet = (e, id, rowIndex) => {
        const newRemarkId = e.target.value;
        const newDataArray = [...RemarkId]; // Copy the original array
        newDataArray[rowIndex] = {
            ...newDataArray[rowIndex],
            RemarkId: newRemarkId,
        };
        setRemarkId(newDataArray);
    };

    const remarkUpdate = (id, index) => {
        setLoading(true);
        const dataToUpdate = [
            {
                operationType: 0,
                path: "Remarks",
                op: "Add",
                from: "string",
                value: RemarkId[index].RemarkId,
            },
        ];
        console.log("Data to update order:", dataToUpdate); // Logging for debugging
        try {
            const updateDeliveryBoy = Order.updateOrderPartially(dataToUpdate, id);
            if (updateDeliveryBoy) {
                alert("Updated Remark")
            }
            // Handle success or further processing
        } catch (error) {
            console.error("Error updating order:", error); // Error handling
            // Handle error condition
        }
        setLoading(false);
    }
    const [hoverStates, setHoverStates] = useState({});

    const navigate = useNavigate();

    const handleMouseEnter = (rowIndex, btnId) => {
        setHoverStates((prev) => ({
            ...prev,
            [rowIndex]: { ...prev[rowIndex], [btnId]: true },
        }));
    };

    const handleMouseLeave = (rowIndex, btnId) => {
        setHoverStates((prev) => ({
            ...prev,
            [rowIndex]: { ...prev[rowIndex], [btnId]: false },
        }));
    };

    const isButtonHovered = (rowIndex, btnId) => {
        return hoverStates[rowIndex]?.[btnId];
    };

    const [ItemDetailModal, setItemDetailModal] = useState(false);
    const [ItemDetail, setItemDetail] = useState([]);

    const openItemDetailModal = async (id) => {
        setLoading(true);
        await handleGetItem(id);
        setItemDetailModal(true);
        setLoading(false);
    };

    const handleGetItem = async (id) => {
        var filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
        };
        // const billdata = await Order.getOrderListWithFilter(filterData);
        const orderlistdata = await Order.getOrderItemWiseList(id);
        setItemDetail(orderlistdata);
        console.log(orderlistdata);
    };

    const closeItemDetailModal = () => {
        setItemDetailModal(false);
    };

    const [showBillPrint, setShowBillPrint] = useState(false);

    const closeBillPrint = () => {
        setShowBillPrint(false);
        setLoading(false);
    }

    const handlePrintButton = (id) => {
        setOrd1Id(id)
        setShowBillPrint(true)
        // navigate("/OrderTakerBillPrint", {
        //     state: {
        //         id,
        //     },
        // });
    };

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }

        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    useEffect(() => {
        const newDataArray = [...RemarkId]; // Copy the original array
        if (orderListPos) {
            if (orderListPos.length > 0) {
                // Loop through the orderList to update the remarks in RemarkId
                orderListPos.forEach((order, index) => {
                    const newRemarkId = order.remarks; // Get the remark from orderList
                    newDataArray[index] = { ...newDataArray[index], RemarkId: newRemarkId };
                });
                setRemarkId(newDataArray); // Update the RemarkId state
            }
        }
    }, [orderList]);

    const [Modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState();

    const openModal = async (id) => {
        await handleGetItem(id);
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    // const handlePaymentPay = async (orderNo, ord1Id) => {
    //     navigate("/Request", {
    //         state: {
    //             orderNo,
    //             ord1Id
    //         }
    //     });
    // }

    const [PaymentModal, setPaymentModal] = useState(false);
    const [PaymentModalData, setPaymentModalData] = useState([]);
    const [orderId, setOrderId] = useState();

    const openPaymentModal = (ord1Id, orderId) => {
        handleGetPayment(ord1Id)
        setOrderId({ ord1Id: ord1Id, orderNo: orderId });
    };

    const closePaymentModal = async () => {
        await fetchOrderData();
        setPaymentModal(false);
    };

    const handleGetPayment = async (orderId) => {
        const PaymentData = await Order.GetPaymentModeWithOrders(orderId);
        console.log(PaymentData);
        setPaymentModalData(PaymentData);
        setOrd1Id(orderId)
        //if (PaymentData.length > 0) {
        setPaymentModal(true);
        // }
        // else {
        //     alert("No Payment Found")
        // }
    };

    const handlePayment = async () => {
        const orderNo = orderId.orderNo;
        const ord1Id = orderId.ord1Id;

        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });
    }

    const [paymentModeDetails, setPaymentModeDetails] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const filteredData = [
                {
                    fieldName: "isDefault",
                    operatorName: "Equal",
                    compareValue: true
                },
                {
                    fieldName: "masterType",
                    operatorName: "Equal",
                    compareValue: 29
                }
            ]
            const data = await BillSeriesMaster.getBillSeriesMasterFiltered(filteredData);
            console.log("data", data);
            if (data.length > 0) {
                const paymentMode = await BillPaymentMode.getvoucherPaymentModeId(data[0].id)
                console.log(paymentMode)
                setPaymentModeDetails(paymentMode);
                setLoading(false);
            }
            else {
                setLoading(false)
                //alert("Please select default Bill series");
            }
        }
        fetchData();
    }, [])

    const orderStatusFunction = async (event, ord1Id, index, deliveryBoyId) => {
        const val = event.target.value;

        setOrderListPos(prevValues => {
            const updatedList = [...prevValues];
            updatedList[index] = { ...updatedList[index], overallOrderStatus: event.target.value };
            return updatedList;
        });

        setLoading(true);
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);
        const formattedDate = currentDate

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        var updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: event.target.value
            },
            {
                operationType: 0,
                path: "orderConfirmedBy",
                op: "Add",
                from: "string",
                value: loginUser.userId
            },
            {
                operationType: 0,
                path: "orderConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
        ]
        if (val == "Delivered") {
            updatedValues.push(
                {
                    operationType: 0,
                    path: "DeliveryTripStatus",
                    op: "Add",
                    from: "string",
                    value: "Close"
                })
        }
        await OrderApi.updateOrderPartially(updatedValues, ord1Id);
        // ***************************
        const itemListData = await OrderApi.getOrderItemWiseList(ord1Id);
        console.log(itemListData);
        setLoading(true)
        for (var i = 0; i < itemListData.length; i++) {
            //alert(itemListData[i].kotStatus)
            if (itemListData[i].kotStatus === "Pending") {
                // **************************** Order 3 *********************
                const statusData = {
                    ord1Id: ord1Id,
                    ord2Id: itemListData[i].ord2Id,
                    orderStatus: "Received",
                    orderDateTime: formattedDate,
                }
                await OrderApi.insertItemStatus(statusData);

                // ************************** Order2 **********************
                const data = [
                    {
                        operationType: 0,
                        path: "KotStatus",
                        op: "Add",
                        from: "string",
                        value: "Received"
                    }
                ]
                await OrderApi.updateItemPartially(data, itemListData[i].ord2Id);
            }
        }
        fetchOrderData();
        alert(`Updated status to ${val}`);
        setLoading(false);
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00`;
        }
    }

    const [showKotBillPrint, setShowKotBillPrint] = useState(false);

    const closeKotBillPrint = () => {
        setShowBillPrint(false);
        setLoading(false);
    }

    const [orderListData, setOrderListData] = useState();

    const handleKotPrint = async (ord1Id) => {
        const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
        setOrderListData(data.item1);
        setShowKotBillPrint(true);
    }

    const [stopScrollingPos, setStopScrollingPos] = useState(true);
    const [pageIndexPos, setPageIndexPos] = useState(1)

    const nextPagePos = async () => {
        if (stopScrollingPos == false || loading) return;
        setLoading(true);
        const index = pageIndexPos + 1;

        let filterData;
        if (addFilterForPOS && addFilterForPOS.length > 0) {
            filterData = addFilterForPOS;
        }
        else {
            const date = await CurrentDateAndTime.getCurrentDate();
            filterData = [{
                fieldName: "serviceTypeId",
                operatorName: "Equal",
                compareValue: 754
            },
            {
                fieldName: "orderDate",
                operatorName: "Equal",
                compareValue: date
            }]
        }
        const list = await OrderApi.getRawOrderReport(filterData, index);
        const orderList = list?.orderList
        let summaryData = list?.summary || [];
        if (orderList.length > 0) {
            setPageIndexPos(index)
            // setStartIndexPos(Number(100 * index) - 100) //Number(val.length * index) - 50
            setLoading(false);
            setOrderListPos((prev) => [...prev, ...orderList]);
            setSummary(summaryData)
        }
        else {
            setStopScrollingPos(false);
            setPageIndexPos(1);
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("stopScrolling updated:", stopScrollingPos);
        // if (stopScrolling) {
        //   setStopScrolling(false);
        // }
    }, [stopScrollingPos]);

    const lastRowRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const lastRow = entries[0];
                if (lastRow.isIntersecting && !loading) {
                    nextPagePos();
                }
            },
            { root: null, threshold: 1.0 } // Fully visible in viewport
        );

        if (lastRowRef.current) {
            observer.observe(lastRowRef.current);
        }
        return () => {
            if (lastRowRef.current) {
                observer.unobserve(lastRowRef.current);
            }
        };
    }, [loading, orderList]);

    return (
        <div className="handleScrollReport">
            <table className="tableStyle mt-4">
                <thead className="table_headerSticky" style={{ top: "-10px" }}>
                    <tr>
                        <th className="thStyle">Select</th>
                        <th className="thStyle">Order No.</th>
                        <th className="thStyle">Name</th>
                        <th className="thStyle" style={{ width: "auto" }}>
                            Total&nbsp;Amt.
                        </th>
                        <th className="thStyle">
                            <div>
                                <label>Order</label>
                            </div>
                        </th>
                        <th className="thStyle" style={{ width: "10%" }}>
                            <div>
                                <label>Order Status</label>
                                <select style={{ backgroundColor: "white" }}
                                    onChange={(e) => handleAddFilter(e, "overallOrderStatus")}
                                // onChange={orderStatusFilter}
                                >
                                    <option value="default">Select</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Confirm">Confirm</option>
                                    <option value="Done">Done</option>
                                    <option value="Cancel">Cancel</option>
                                </select>
                            </div>
                        </th>
                        <th className="thStyle" style={{ width: "12%" }}>
                            Payment
                        </th>
                        <th className="thStyle" style={{ width: "7%" }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="tbody_body_m1 ">
                    {(() => {
                        if (orderList !== null && Array.isArray(orderList)) {
                            return orderList
                                // .slice().reverse()
                                .map((data, rowIndex) => (
                                    <tr key={rowIndex} className="hoverRow" style={{ cursor: "pointer" }} ref={rowIndex === orderList.length - 1 ? lastRowRef : null}>
                                        <td
                                            className="tdStyle"
                                            onClick={() => openItemDetailModal(data.ord1Id)}
                                        >
                                            <button style={{ padding: "5px 10px" }}>
                                                {startIndexPos + rowIndex + 1}
                                            </button>
                                        </td>
                                        <td className="tdStyle" style={{ textAlign: "center" }}>
                                            <div>
                                                <label><span style={{ fontWeight: "600" }}>Order No : &nbsp;</span>{data.orderNo}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>NP : &nbsp;</span>{data.noOfChild + data.noOfAdult}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>NCP : &nbsp;</span>{data.noOfChild}</label><br />
                                                <label><span style={{ fontWeight: "600" }}>NAP : &nbsp;</span> {data.noOfAdult}</label><br />
                                            </div>
                                        </td>
                                        <td className="tdStyle">
                                            {data.accName}
                                            <br />
                                            {/* {data.accMobileNo} */}
                                            {UserDetails.canLst3DgtNo ? (maskMobileNo(data.mobileNo)) : (data.mobileNo)}
                                        </td>
                                        <td className="tdStyle" style={{ width: "20%", textAlign: "center" }}>
                                            <span style={{ fontWeight: "600" }}>Total&nbsp;Amt: &#x20B9;</span>
                                            {data.confirmTotalAmt !== null &&
                                                data.confirmTotalAmt !== undefined
                                                ? data.confirmTotalAmt
                                                : 0}
                                            <br />
                                            <span style={{ fontWeight: "600" }}>Cancel&nbsp;Amt: &#x20B9;</span>
                                            {data.o1CancelledAmt !== null &&
                                                data.o1CancelledAmt !== undefined
                                                ? data.o1CancelledAmt
                                                : 0}
                                            <br />
                                            <span style={{ fontWeight: "600" }}>Bal.&nbsp;Amt: &#x20B9;</span>
                                            {data.confirmTotalAmt !== null &&
                                                data.confirmTotalAmt !== undefined
                                                ? data.confirmTotalAmt - (data.o1CancelledAmt + data.paymentAmount)
                                                : 0}
                                            <br />
                                            <span style={{ fontWeight: "600" }}>Paid&nbsp;Amt: &#x20B9;</span>
                                            {data.paymentAmount !== null &&
                                                data.paymentAmount !== undefined
                                                ? data.paymentAmount
                                                : 0}
                                        </td>
                                        <td className="tdStyle" style={{ textAlign: "center" }}>
                                            {formatDate(data.orderDate)} <br /> {convertTo12HourFormat(String(data.orderTime))}
                                        </td>
                                        <td className="tdStyle">
                                            <div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                                                    <select
                                                        className="form-control modify_im_input"
                                                        style={{ width: "80%" }}
                                                        value={data.overallOrderStatus}
                                                        onChange={(event) => orderStatusFunction(event, data.ord1Id, rowIndex)}
                                                    >
                                                        <option value="Pending"
                                                            disabled={data.overallOrderStatus == "Done" || data.overallOrderStatus == "Confirm" || data.overallOrderStatus == "Delivered"
                                                                || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Pending</option>
                                                        <option value="Confirm"
                                                            disabled={data.overallOrderStatus == "Done" || data.overallOrderStatus == "Delivered"
                                                                || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Confirm</option>
                                                        <option value="Done"
                                                            disabled={data.overallOrderStatus == "Delivered" || data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false}>Done</option>
                                                        {/* <option value="cancel">cancel</option> */}
                                                        <option value="Delivered"
                                                            disabled={data.overallOrderStatus == "Return Request" || data.overallOrderStatus == "Returned" ? true : false} >Delivered</option>
                                                        <option value="Return Request" disabled>Return Request</option>
                                                        <option value="Returned">Returned</option>
                                                    </select>
                                                    <i onClick={() => openModal(data.ord1Id)}
                                                        class="fa-solid fa-circle-info mt-1 icon_ov1"
                                                        style={{
                                                            color: "#646464",
                                                            cursor: "pointer",
                                                            margin: "0 !important",
                                                        }}
                                                    ></i>
                                                </div>
                                                <div className="mt-2">
                                                    <label style={{
                                                        display: data.orderConfirmedByName != "" && data.orderConfirmedByName != null && data.orderConfirmedByName != undefined ? "block" : "none",
                                                        fontSize: "14px"
                                                    }} >
                                                        Confirm By :- <span style={{ fontWeight: "600" }}>{data.orderConfirmedByName}</span><br />
                                                        {/* {`${formatTimestamp(data.orderConfirmedOn)}`} */}
                                                        {`${convertDateTimeToDateAndTime(data.orderConfirmedOn)}`}
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="tdStyle">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ display: "grid", alignItems: "baseline", alignContent: "baseline" }}>
                                                    <div
                                                        className="mt-2"
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {/* <input
                                                            type="text"
                                                            className="form-control remark-ov"
                                                            placeholder="Remark"
                                                            onChange={(e) =>
                                                                RemarkSet(e, data.ord1Id, rowIndex)
                                                            }
                                                            value={
                                                                RemarkId[rowIndex]?.RemarkId
                                                            }
                                                            style={{ width: "138px", marginRight: "5px" }}
                                                        />
                                                        <i
                                                            onClick={() => remarkUpdate(data.ord1Id, rowIndex)}
                                                            class="fa-solid fa-floppy-disk icon_ov1"
                                                            style={{
                                                                color: "brown",
                                                                cursor: "pointer",
                                                                position: "relative",
                                                                fontSize: "20px"
                                                            }}
                                                            onMouseEnter={() =>
                                                                handleMouseEnter(rowIndex, "btn10")
                                                            }
                                                            onMouseLeave={() =>
                                                                handleMouseLeave(rowIndex, "btn10")
                                                            }
                                                        >
                                                            {isButtonHovered(rowIndex, "btn10") && (
                                                                <div className="hover-text">Save Remark</div>
                                                            )}
                                                        </i> */}
                                                    </div>

                                                    <div className="mt-2" style={{ display: "flex", marginTop: "10px" }}>
                                                        <select
                                                            disabled={data.paymentStatus === "Done" ? true : false}
                                                            value={data.paymentMode || 0}
                                                            onChange={(e) => UpdatePaymentPartially(e, data)}
                                                            style={{
                                                                width: "70px",
                                                                padding: "2px 5px",
                                                                textAlign: "center",
                                                            }}
                                                            className="OrderPayMode form-control mt-1"
                                                        >
                                                            <option value="0"> --- </option>
                                                            {(() => {
                                                                if (paymentModeDetails !== null && Array.isArray(paymentModeDetails)) {
                                                                    return paymentModeDetails //.filter(item => item.display == true)
                                                                        // .filter(data =>
                                                                        //     data.accountTitle && typeof data.accountTitle === 'string' &&
                                                                        //     !data.accountTitle.toLowerCase().includes("online" || "cdc"))
                                                                        .map((data, index) => (
                                                                            <option key={index} disabled={data.display == true ? false : true} value={data.accountId}>
                                                                                {data.accountTitle}
                                                                            </option>
                                                                        ))
                                                                }
                                                            })()}
                                                        </select>{" "}
                                                        &nbsp;
                                                        <button
                                                            className="btn mt-1 btnPaymentStatus-ov"
                                                        >
                                                            {data.paymentStatus ? data.paymentStatus : "Pending"}
                                                        </button>

                                                        <div
                                                            className="iconBox-ov"
                                                        >
                                                            <i
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(rowIndex, "btn1")
                                                                }
                                                                onMouseLeave={() =>
                                                                    handleMouseLeave(rowIndex, "btn1")
                                                                }
                                                                class="fa-solid fa-note-sticky icon_ov1"
                                                                style={{
                                                                    color: "indigo",
                                                                    position: "relative",
                                                                }}
                                                                onClick={() => openPaymentModal(data.ord1Id, data.orderNo)}
                                                            >
                                                                {isButtonHovered(rowIndex, "btn1") && (
                                                                    <div className="hover-text">Payment Details</div>
                                                                )}
                                                            </i>

                                                            <i
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(rowIndex, "btn2")
                                                                }
                                                                onMouseLeave={() =>
                                                                    handleMouseLeave(rowIndex, "btn2")
                                                                }
                                                                onClick={() => handlePaymentPay(data.orderNo, data.ord1Id, orderList, data.d2AccountId)}
                                                                class="fa-solid fa-sack-dollar icon_ov1"
                                                                style={{
                                                                    color: "#7c9504",
                                                                    position: "relative",
                                                                    // marginLeft: "20px",
                                                                }}
                                                            >
                                                                {isButtonHovered(rowIndex, "btn2") && (
                                                                    <div className="hover-text">Pay Online</div>
                                                                )}
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="tdStyle">
                                            <div
                                                style={{
                                                    display: "grid",
                                                    justifyContent: "center",
                                                    height: "100px",
                                                }}
                                            >
                                                <i
                                                    onMouseEnter={() => handleMouseEnter(rowIndex, "btn4")}
                                                    onMouseLeave={() => handleMouseLeave(rowIndex, "btn4")}
                                                    class="fa-brands fa-google icon_ov1"
                                                    style={{ color: "darkgoldenrod", position: "relative" }}
                                                    onClick={() => openGstModal(data.ord1Id)}
                                                >
                                                    {isButtonHovered(rowIndex, "btn4") && (
                                                        <div
                                                            className="hover-text"
                                                            style={{ marginTop: "4%" }}
                                                        >
                                                            GST
                                                        </div>
                                                    )}
                                                </i>
                                                <div style={{ display: data.paymentStatus == "Done" ? "block" : "none" }}>
                                                    <i
                                                        onClick={() => handlePrintButton(data.ord1Id)}
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(rowIndex, "btn5")
                                                        }
                                                        onMouseLeave={() =>
                                                            handleMouseLeave(rowIndex, "btn5")
                                                        }
                                                        class="fa-solid fa-receipt icon_ov1"
                                                        style={{ color: "darkred", position: "relative" }}
                                                    // onClick={}
                                                    >
                                                        {isButtonHovered(rowIndex, "btn5") && (
                                                            <div
                                                                className="hover-text"
                                                                style={{ marginTop: "6%" }}
                                                            >
                                                                Bill Receipt
                                                            </div>
                                                        )}
                                                    </i>
                                                </div>
                                                <div style={{}}>
                                                    <i
                                                        onClick={() => handleKotPrint(data.ord1Id)}
                                                        onMouseEnter={() =>
                                                            handleMouseEnter(rowIndex, "btn6")
                                                        }
                                                        onMouseLeave={() =>
                                                            handleMouseLeave(rowIndex, "btn6")
                                                        }
                                                        class="fa-solid fa-file-invoice icon_ov1"
                                                        style={{ color: "darkgreen", position: "relative" }}
                                                    >
                                                        {isButtonHovered(rowIndex, "btn6") && (
                                                            <div style={{ marginTop: "6%" }}
                                                                className="hover-text"
                                                            >
                                                                KOT Receipt
                                                            </div>
                                                        )}
                                                    </i>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ));
                        } else {
                            // alert("else")
                        }
                    })()}
                    {/* ******************************** Total ******************************** */}
                    {props.isShowTotalAmountInReport && (
                        <tr className="table_tbody_Order_List"
                            style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "center" }}>
                                <span style={{ fontWeight: "600" }}>Total NP:</span>
                                {summary && summary.length > 0 && (
                                    summary[0].noOfPerson
                                )}
                                <br />
                                <span style={{ fontWeight: "600" }}>Total NCP:</span>
                                {summary && summary.length > 0 && (
                                    summary[0].noOfChild
                                )}
                                <br />
                                <span style={{ fontWeight: "600" }}>Total NAP:</span>
                                {summary && summary.length > 0 && (
                                    summary[0].noOfAdult
                                )}
                                <br />
                            </td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                <span style={{ fontWeight: "600" }}>Total Amt: &#x20B9;</span>
                                <span style={{ fontWeight: "500" }}>
                                    {summary && summary.length > 0 && (
                                        summary[0].grandTotalAmt
                                    )}
                                </span>
                                <br />
                                <span style={{ fontWeight: "600" }}>Cancel Amt: &#x20B9;</span>
                                <span style={{ fontWeight: "500" }}>
                                    {summary && summary.length > 0 && (
                                        summary[0].cancelOrderAmt
                                    )}
                                </span>
                                <br />
                                <span style={{ fontWeight: "600" }}>Bal. Amt: &#x20B9;</span>
                                <span style={{ fontWeight: "500" }}>
                                    {summary && summary.length > 0 && (
                                        summary[0].grandTotalAmt - (summary[0].paidAmt + summary[0].cancelAmt)
                                    )}
                                </span>
                                <br />
                                <span style={{ fontWeight: "600" }}>Paid Amt: &#x20B9;</span>
                                <span style={{ fontWeight: "500" }}>
                                    {summary && summary.length > 0 && (
                                        summary[0].paidAmt
                                    )}
                                </span>
                            </td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                <span style={{ fontWeight: "600" }}>Total Cancel Order: </span>
                                {summary && summary.length > 0 && (
                                    summary[0].NoOfCancelOrder
                                )}</td>
                            {/* <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td> */}
                            {/* <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td> */}
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                {/* <br /> */}
                                {/* <span style={{ fontWeight: "600" }}>Total Return Request: </span>
                                {summary && summary.length > 0 && (
                                    summary[0].returnRequestQty
                                )} */}
                                {/* <br /> */}
                            </td>
                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}></td>
                        </tr>
                    )}
                </tbody>
            </table>

            {GstModal && (
                <GstModalOrderView closeGstModal={closeGstModal} ItemDetail={ItemDetail} ord1Id={ord1Id} />
            )}
            {ItemDetailModal && (
                <ItemDetailModalBox
                    closeItemDetailModal={closeItemDetailModal}
                    ItemDetail={ItemDetail}
                    setItemDetail={setItemDetail}
                    serviceTypeId={props.serviceTypeId}
                />
            )}
            {/************************** Info Modal ***************/}
            {Modal && (
                <OrderDetailStatusModal
                    closeModal={closeModal}
                    ItemDetail={ItemDetail}
                />
            )}
            {/************************** Payment Modal ***************/}
            {PaymentModal && (
                <PaymantModeModel handlePayment={handlePayment} PaymentModalData={PaymentModalData} closePaymentModal={closePaymentModal} ord1Id={ord1Id} isFromPOS={true} />
            )}

            {/* ****************************** BILL PRINT *************************** */}
            {showBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="FinalBillPrint" ord1Id={ord1Id} setShowBillPrint={setShowBillPrint} setLoading={setLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* ****************************** KOT PRINT *************************** */}
            {showKotBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeKotBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="KOTBillPrint" orderData={orderListData} ord1Id={0} setShowBillPrint={setShowKotBillPrint} setLoading={setLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && (<Loader />)}
        </div>
    );
};

export default POS;
