import React, { useEffect, useState } from "react";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import { useParams } from "react-router-dom";
import TemplateSetting from "../../../API/APIServices/TemplateSetting";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
// import "jspdf-autotable";
import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";
// import NotoSansBengali from "../../Assets/Fonts/NotoSans";

const BarCodeDesign = (props) => {
  const [apiData, setApiData] = useState([]);

  const [droppedItems, setDroppedItems] = useState([]);
  const [styleArray, setStylesArray] = useState([]);

  // useEffect(() => {
  //     console.log("styleArray", styleArray);
  //     console.log("apiData", apiData);
  //     console.log("droppedItems", droppedItems);
  // }, [apiData, droppedItems, styleArray]);

  const [variableReplaceData, setVariableReplaceData] = useState("");

  // const showTemplateData = async (e) => {
  //     debugger;
  //     props.setLoading(true)
  //     const filteredData = [{
  //         "fieldName": "name",
  //         "operatorName": "equal",
  //         "compareValue": props.templateName || "FinalBillPrint" || "TestReport" || "ReportDesign" || "TestReport",
  //     }];
  //     var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
  //     if (getdata) {
  //         if (getdata.jsonStructure) {
  //             try {
  //                 const decodedData = decodeURIComponent(getdata.jsonStructure);
  //                 const parsedData = JSON.parse(decodedData);
  //                 // getdata.jsonStructure = parsedData
  //                 setDroppedItems(parsedData);
  //                 if (getdata.textContents) {
  //                     const decodedData = decodeURIComponent(getdata.textContents)
  //                     const parsedData = JSON.parse(decodedData);
  //                     // getdata.jsonStructure = parsedData
  //                     setStylesArray(parsedData);
  //                 }
  //                 console.log("ord1Id", props.ord1Id)
  //                 const orderNo = props.ord1Id || 10923 || 256;
  //                 let sqlQuery = getdata?.insertQuery;
  //                 if (sqlQuery?.includes('@transId')) {
  //                     sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
  //                 }
  //                 const queryData = {
  //                     query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
  //                     query2: sqlQuery,
  //                     query3: sqlQuery
  //                 }
  //                 if (sqlQuery) {
  //                     const tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
  //                     setVariableReplaceData(tableData);
  //                 }
  //             } catch (error) {
  //                 console.error('Error parsing JSON:', error);
  //                 setDroppedItems([]);
  //             }
  //         } else {
  //             setDroppedItems([]);
  //         }
  //         var templateInnerHtml = getdata.textContents;
  //         var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
  //         getdata.textContents = templateInnerHtmlDecode

  //         setApiData(getdata);
  //         props.setLoading(false)
  //         // setDataHtml(getdata)
  //     } else {
  //         //document.getElementById("drop-target-innerHtml").outerHTML = "";
  //         setDroppedItems([]);
  //         props.setLoading(false)
  //     }
  // }

  const showTemplateData = async (ord1Id, templateName) => {
    props.setLoading(true);

    const filteredData = [
      {
        fieldName: "name",
        operatorName: "equal",
        compareValue:
          templateName ||
          props.templateName ||
          "FinalBillPrint" ||
          "TestReport" ||
          "ReportDesign" ||
          "TestReport",
      },
    ];
    var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(
      filteredData
    );
    if (getdata) {
      if (getdata.jsonStructure) {
        try {
          const decodedData = decodeURIComponent(getdata.jsonStructure);
          const parsedData = JSON.parse(decodedData);
          console.log("parsedData1", parsedData);
          // getdata.jsonStructure = parsedData
          setDroppedItems(parsedData);
          // if (getdata.textContents) {
          // const decodedData = decodeURIComponent(getdata.textContents)
          // const parsedData = JSON.parse(decodedData);
          // console.log("parsedData2", parsedData);
          // getdata.jsonStructure = parsedData

          //setStylesArray(parsedData);
          // }
          console.log("ord1Id", props.ord1Id);
          const orderNo = ord1Id || props.ord1Id || 0;
          let sqlQuery = getdata?.insertQuery;
          if (sqlQuery?.includes("@transId")) {
            sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
          }
          const queryData = {
            query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
            query2: sqlQuery,
            query3: sqlQuery,
          };
          let tableData;
          if (sqlQuery) {
            tableData = await TemplateSetting.getMultiDatasetExecuteQuery(
              queryData
            );
            setVariableReplaceData(tableData);
          }
          if (getdata.textContents) {
            const decodedData = decodeURIComponent(getdata.textContents);
            const parsedData = JSON.parse(decodedData);
            console.log("parsedData2", parsedData);
            // getdata.jsonStructure = parsedData
            return { printData: parsedData, replaceData: tableData };
            // setStylesArray(parsedData);
          }
          props.setLoading(false);
        } catch (error) {
          props.setLoading(false);
          console.error("Error parsing JSON:", error);
          setDroppedItems([]);
        }
      } else {
        setDroppedItems([]);
        props.setLoading(false);
      }
      var templateInnerHtml = getdata.textContents;
      var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
      getdata.textContents = templateInnerHtmlDecode;

      setApiData(getdata);
      props.setLoading(false);
      // setDataHtml(getdata)
    } else {
      //document.getElementById("drop-target-innerHtml").outerHTML = "";
      setDroppedItems([]);
      props.setLoading(false);
    }
    props.setLoading(false);
  };

  const templateFunction = async () => {
    let replacedData = {};
    const warnings = [];
    // debugger;
    let { printData, replaceData } = await showTemplateData(
      props.ord1Id,
      "Template2"
    );
    console.log("printData", printData);
    console.log("replaceData", replaceData);

    if (printData && replaceData) {
      // Iterate over all printData items
      for (const item of printData) {
        const { content, style } = item;

        // For each product in the replaceData
        for (const tableName in replaceData) {
          const tableData = replaceData[tableName];

          if (Array.isArray(tableData) && tableData.length > 0) {
            tableData.forEach((obj, index) => {
              // Create a fresh copy of content for each product
              let updatedContent = content;

              // Replace variables for the current product
              // updatedContent = replaceVariables(updatedContent, obj);
              updatedContent = replaceVariables(updatedContent, obj);
              console.log("updatedContent:", updatedContent); // Log updatedContent

              // Extract product info
              const productId = obj["@T1.ProductId"];
              const Barcode = obj["@T1.ItmCode"];
              const productQty = obj["@T1.Qty"];

              if (Barcode.trim() === "") {
                console.log("Warning: Missing Barcode for", obj); // Warn if Barcode is missing
                warnings.push(
                  "Warning: Missing Barcode for " +
                    obj["@T1.ProductName"] +
                    " Generate the Barcode And Retry"
                );
                // return
              }
              const variableNames = Object.keys(obj).filter((key) =>
                key.startsWith("@T1.")
              );
              const variableData = variableNames.reduce((acc, key) => {
                // Remove the '@T1.' prefix and store the variable name with its value
                const variableName = key.replace("@T1.", "");
                acc[variableName] = obj[key];
                return acc;
              }, {});

              // Initialize product array if it doesn't exist
              if (!replacedData[Barcode]) {
                replacedData[Barcode] = [];
                console.log(
                  "Initialized replacedData[Barcode]:",
                  replacedData[Barcode]
                ); // Log initialization
              }

              // Before push, check the data
              console.log(
                "Before push, replacedData[Barcode]:",
                replacedData[Barcode]
              );

              // Push the replaced content grouped by product name
              replacedData[Barcode].push({
                content: updatedContent,
                style,
                productId,
                variableData,
                qty: productQty,
              });

              // After push, check the data
              console.log(
                "After push, replacedData[Barcode]:",
                replacedData[Barcode]
              );
            });
          }
        }
      }
      if (warnings.length > 0) {
        console.log(`Warnings:\n\n${warnings.join("\n")}`);
      }
    }
    setStylesArray(replacedData);
    console.log("replacedData:", replacedData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const htmlRegex =
          /<([a-z][\w0-9]*)\s*[^>]*>.*<\/\1\s*>|<([a-z][\w0-9]*)\s*[^>]*\/>/i;
        if (props.orderData && props.orderData.length > 0) {
          if (props.isBillAndKot) {
            let replacedData = [];
            try {
              // First, handle data for props.ord1Id
              templateFunction();
              // let container = document.createElement('div');
              // container.id = 'temporary-container';
              // container.style.width = "300px";

              // debugger;
              // let htmlContent = replacedData && replacedData.map(item => {
              //     return `<div>${item.content}</div>`;
              // }).join('');

              // container.innerHTML = `<div id="innerhtml">${htmlContent}</div>`  //style="width:100%";
              // document.body.appendChild(container);

              // const contentHeight = container.offsetHeight;
              // let contentHeightMm = pixelToMM(contentHeight);

              // debugger;
              // // localStorage.setItem("temporyHeight", contentHeightMm);

              // container.remove();

              // **************************** Kot Print *********************
              templateFunction();
              // const allReplaceDataPromises = props.orderData.map(order => showTemplateData(order?.ord2Id));
              // const resolvedReplaceData = await Promise.all(allReplaceDataPromises);

              // for (const { printData, replaceData } of resolvedReplaceData) {
              //     if (printData && replaceData) {
              //         for (const item of printData) {
              //             const { content, style } = item;
              //             if (htmlRegex.test(content)) {
              //                 const updatedContent = await replaceVariables(content, replaceData);
              //                 replacedData.push({ content: updatedContent, style });
              //             } else {
              //                 const updatedContent = await replaceVariables1(content, replaceData);
              //                 replacedData.push({ content: updatedContent, style });
              //             }
              //         }
              //     }
              // }
              // console.log(replacedData);
              // setStylesArray(replacedData);
            } catch (error) {
              console.error("Failed to process data:", error);
            }
          } else {
            // let replacedData = [];
            // const allReplaceDataPromises = props.orderData.map(order => showTemplateData(order?.ord2Id));
            // const resolvedReplaceData = await Promise.all(allReplaceDataPromises);

            // for (const { printData, replaceData } of resolvedReplaceData) {
            //     if (printData && replaceData) {
            //         for (const item of printData) {
            //             const { content, style } = item;
            //             if (htmlRegex.test(content)) {
            //                 const updatedContent = await replaceVariables(content, replaceData);
            //                 replacedData.push({ content: updatedContent, style });
            //             } else {
            //                 const updatedContent = await replaceVariables1(content, replaceData);
            //                 replacedData.push({ content: updatedContent, style });
            //             }
            //         }
            //     }
            // }
            // console.log(replacedData);
            // setStylesArray(replacedData);
            templateFunction();
          }
        } else {
          templateFunction();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [props.ord1Id, props.orderData]);

  useEffect(() => {
    // Use a for loop to iterate over stylesArray
    for (let index = 0; index < styleArray.length; index++) {
      const styleObject = styleArray[index];
      console.log(styleObject.id);

      const element = document.getElementById(styleObject.id);

      if (element) {
        for (const [key, value] of Object.entries(styleObject.style)) {
          // Convert kebab-case to camelCase for inline styles
          const camelCaseKey = key.replace(/-([a-z])/g, (g) =>
            g[1].toUpperCase()
          );
          element.style[camelCaseKey] = value;
        }
      }
    }
  }, [styleArray]);

  // function replacePlaceholders(itemContent, variableReplaceData) {
  //     // Iterate over each table in variableReplaceData
  //     for (const tableName in variableReplaceData) {
  //         const tableData = variableReplaceData[tableName];

  //         // Check if tableData is an array (as expected)
  //         if (Array.isArray(tableData)) {
  //             // Iterate over the objects in tableData (assuming each object contains key-value pairs for replacement)
  //             for (const obj of tableData) {
  //                 if (typeof obj === 'object' && obj !== null) { // Ensure obj is a valid object
  //                     for (const key in obj) {
  //                         if (obj.hasOwnProperty(key)) {
  //                             const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
  //                             itemContent = itemContent.replace(regex, obj[key]); // Replace in itemContent
  //                         }
  //                     }
  //                 }
  //             }
  //         } else if (typeof tableData === 'object' && tableData !== null) {
  //             // Handle case where tableData is a single object instead of an array of objects
  //             for (const key in tableData) {
  //                 if (tableData.hasOwnProperty(key)) {
  //                     const regex = new RegExp(key, 'g'); // Create regex to match the placeholder
  //                     itemContent = itemContent.replace(regex, tableData[key]); // Replace in itemContent
  //                 }
  //             }
  //         }
  //         // If tableData is neither an object nor an array, it is ignored (or handled according to your needs)
  //     }
  //     return itemContent;
  // }

  // function replaceVariables(itemContent, variableReplaceData) {
  //     for (const tableName in variableReplaceData) {
  //         const tableData = variableReplaceData[tableName];

  //         // Find the first <tr> that contains <td> or is inside <tbody>
  //         const tbodyRegex = /<tbody[^>]*>(.*?)<\/tbody>/gs;
  //         let tbodyMatch = tbodyRegex.exec(itemContent);
  //         let firstRowStructure = null;

  //         if (tbodyMatch) {
  //             const tbodyContent = tbodyMatch[1];  // Content inside the first <tbody>
  //             const trRegex = /<tr>(.*?)<\/tr>/g;
  //             const firstRowMatch = trRegex.exec(tbodyContent);
  //             if (firstRowMatch) {
  //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content within <tbody>
  //             }
  //         }

  //         // If no <tbody> is found, fall back to searching for the first <tr> with <td> directly
  //         if (!firstRowStructure) {
  //             const trRegex = /<tr>(.*?)<\/tr>/g;
  //             const firstRowMatch = trRegex.exec(itemContent);
  //             if (firstRowMatch) {
  //                 firstRowStructure = firstRowMatch[1]; // The first <tr> content
  //             }
  //         }

  //         // If we found a <tr> structure
  //         if (firstRowStructure) {
  //             let rowsToAdd = '';

  //             // Process replacements for each object in tableData
  //             for (const obj of tableData) {
  //                 let shouldAppend = false;

  //                 // Check if the first row (or <td>) contains placeholders matching @T1, @T2, etc.
  //                 for (const key in obj) {
  //                     if (firstRowStructure.includes(key)) {
  //                         shouldAppend = true;  // If it contains a relevant placeholder, mark for appending
  //                         break;
  //                     }
  //                 }

  //                 // If there's a match, process the row and append it
  //                 if (shouldAppend) {
  //                     let rowContent = firstRowStructure;

  //                     // Replace placeholders in the row structure with corresponding values from the current object
  //                     for (const key in obj) {
  //                         const regex = new RegExp(key, 'g');
  //                         rowContent = rowContent.replace(regex, obj[key]);
  //                     }

  //                     rowsToAdd += `<tr>${rowContent}</tr>`; // Wrap the updated row in <tr> tags
  //                 }
  //             }

  //             // Append the rows before the closing </table> tag, if rows were added
  //             if (rowsToAdd) {
  //                 itemContent = itemContent.replace('</table>', rowsToAdd + '</table>');
  //             }
  //         }

  //         // Replace any remaining placeholders in the entire content outside of the table
  //         for (const obj of tableData) {
  //             for (const key in obj) {
  //                 const regex = new RegExp(key, 'g');
  //                 itemContent = itemContent.replace(regex, obj[key]);
  //             }
  //         }
  //     }
  //     return itemContent;
  // }

  function replaceVariables(itemContent, productData) {
    // Loop through each key in productData

    for (const key in productData) {
      const placeholder = `${key}`;
      const value = productData[key];

      const placeholderRegex = new RegExp(placeholder, "g");

      // Replace the placeholder with the actual value or an empty string if value is null/undefined
      if (value === null || value === undefined || value == "") {
        itemContent = itemContent.replace(placeholderRegex, "");
      } else {
        itemContent = itemContent.replace(placeholderRegex, value);
      }
    }
    return itemContent;
  }

  // function replaceVariablesInTable(html, variableReplaceData) {
  //     if (typeof variableReplaceData !== "object" || variableReplaceData === null) {
  //         console.error("Error: variableReplaceData should be an object with arrays");
  //         return html;
  //     }

  //     return html.replace(/@\w+\.\w+/g, (match) => {
  //         for (const tableKey in variableReplaceData) {
  //             const table = variableReplaceData[tableKey];
  //             if (Array.isArray(table)) {
  //                 for (const row of table) {
  //                     if (row[match] !== undefined) {
  //                         return row[match];
  //                     }
  //                 }
  //             }
  //         }
  //         return match;
  //     });
  // }

  const [printData, setPrintData] = useState("");

  useEffect(() => {
    console.log("printData", printData);
  }, [printData]);

  const renderDroppedItems = () => {
    return droppedItems.length > 0 &&
      droppedItems.some((item) => item.items.length > 0)
      ? droppedItems.map((item, index) => {
          if (item.items.length > 0) {
            const itemContent = item.textContents;
            let updatedData = itemContent;
            if (variableReplaceData && variableReplaceData != "") {
              updatedData = replaceVariables(itemContent, variableReplaceData);
              // const data = replaceVariables(itemContent, variableReplaceData)
              // updatedData = replacePlaceholders(data, variableReplaceData)
              // generatePDF(updatedData)
              const placeholderRegex =
                /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
              if (placeholderRegex.test(updatedData)) {
                return null; // If placeholders are still present, hide the div by returning null
              }
            }
            return (
              <div>
                {" "}
                {/*style={{ transformOrigin: "5% 5%", transform: "scale(0.5, 0.5)", width: '100%', overflow: 'hidden', }}*/}
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: updatedData }}
                />
              </div>
            );
          }
          return null;
        })
      : null;
  };

  useEffect(() => {
    if (droppedItems.length > 0) {
      droppedItems.forEach((item) => {
        if (item.items.length > 0) {
          const itemContent = item.textContents;
          let updatedData = null;

          if (
            variableReplaceData &&
            Object.keys(variableReplaceData).length > 0
          ) {
            updatedData = replaceVariables(itemContent, variableReplaceData);
          }
          if (updatedData) {
            const placeholderRegex =
              /<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/;
            if (placeholderRegex.test(updatedData)) {
              return null; // If placeholders are still present, hide the div by returning null
            }
            if (
              printData == "" ||
              printData == null ||
              printData == undefined
            ) {
              // updatedData = `<div style="width:300px ; height:300px"> ${updatedData} </div>`
            }
            setPrintData((prevValues) => prevValues + updatedData);
          }
          // else {
          //     setPrintData(prevValues => prevValues + itemContent);
          // }
        }
      });
    }
  }, [droppedItems, variableReplaceData]);

  const getAllElementIds = (element) => {
    let ids = [];

    if (element.id) {
      ids.push(element.id);
    }
    // Recursively get IDs from child elements
    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      ids = ids.concat(getAllElementIds(children[i]));
    }
    return ids;
  };

  const sortJsonByHtmlOrder = (jsonArray, htmlContent) => {
    // Create a DOM from the HTML content string
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    // Extract all IDs present in parent and child elements in DOM order
    const htmlIds = getAllElementIds(doc.body);

    // Sort the JSON array according to the order of IDs present in HTML
    return jsonArray.sort((a, b) => {
      return htmlIds.indexOf(a.id) - htmlIds.indexOf(b.id);
    });
  };

  function pixelsToMm(pixels) {
    // Check if the input is a string (e.g., "153px"), and if so, remove the "px" part
    if (typeof pixels === "string") {
      pixels = parseFloat(pixels.replace("px", ""));
    }

    // Ensure pixels is a number
    if (isNaN(pixels)) {
      console.error("Invalid pixel value:", pixels);
      return 0; // Return 0 if invalid pixel value
    }

    // Get the screen's DPI (dots per inch)
    const dpi = window.devicePixelRatio * 96; // Default DPI for most screens is around 96

    // Convert pixels to inches
    const inches = pixels / dpi;

    // Convert inches to millimeters
    const mm = inches * 25.4;

    return mm;
  }

  function pixelsToPoints(pixels) {
    if (typeof pixels === "string") {
      pixels = parseFloat(pixels.replace("px", ""));
    }
    return pixels * 0.75;
  }

  const removeDuplicates = (array) => {
    const seen = new Set();

    return array.filter((item) => {
      const key = JSON.stringify(item); // Create a unique key for each object
      if (seen.has(key)) {
        return false; // Exclude duplicates
      }
      seen.add(key);
      return true;
    });
  };

  const generatePDF = () => {
    //with Barcode
    debugger;
    var totalProducts = Object.keys(styleArray).length;
    var leftf;
    var topf;
    var pageHeight, pageWidth;
    var orientation = "p",
      pageUnit = "mm";
    const constrainedX = 2;
    const constrainedY = 1.25;

    const extractValueAndUnit = (value) => {
      if (typeof value === "string") {
        const match = value.match(/^(\d+(\.\d+)?)([a-zA-Z%]*)$/);
        return {
          numericValue: match ? parseFloat(match[1]) : null,
          unit: match ? match[3] : null,
        };
      }
      return { numericValue: value, unit: null };
    };

    for (const product in styleArray) {
      const styleArray1 = styleArray[product];

      var style = styleArray1[0].style;

      const pageHeightResult = extractValueAndUnit(style["page-height"]);
      const pageWidthResult = extractValueAndUnit(style["page-width"]);

      // Extract height and its unit
      pageHeight = pageHeightResult.numericValue || 297;
      pageUnit = pageHeightResult.unit || "mm";

      // Validate width uses the same unit
      if (pageWidthResult.unit && pageWidthResult.unit !== pageUnit) {
        throw new Error(
          `Mismatch in units: page-height uses "${pageUnit}", but page-width uses "${pageWidthResult.unit}".`
        );
      }

      pageWidth = pageWidthResult.numericValue || 210;
      orientation = style["orientation"] || "p";

      console.log("style", style);
      console.log("Extracted pageHeight:", pageHeight, "Unit:", pageUnit);
      console.log("Extracted pageWidth:", pageWidth, "Unit:", pageUnit);
      console.log("Orientation:", orientation);

      console.log("totalProducts", totalProducts);
    }

    const doc = new jsPDF(orientation || "p", pageUnit || "mm", [
      pageHeight,
      pageWidth,
    ]);

    // for (const product in styleArray) {
    //     const styleArray1 = styleArray[product];
    //     debugger
    //     if (styleArray1 && styleArray1.length > 0) {
    //         styleArray1.forEach((item) => {
    //             const { content, style, variableData } = item;
    //             const itmcode = variableData.ItmCode || "Test123";
    //             // topf = constrainedY * pixelsToMm((style.top || (style['page-margin-top']) || 1));
    //             // leftf = constrainedX * pixelsToMm((style.left || (style['page-margin-left']) || 1));

    //             topf = constrainedY * pixelsToMm(style.top || 0);
    //             leftf = constrainedX * pixelsToMm(style.left || 0);

    //             const left = leftf || 0;
    //             const top = topf || 10;
    //             console.log(`left: ${left}, top: ${top}`);

    //             if (isNaN(left) || isNaN(top)) {
    //                 return;
    //             }
    //             if (content === "BarcodeImage") {
    //                 const canvas = document.createElement("canvas");
    //                 JsBarcode(canvas, itmcode, {
    //                     format: "CODE128",
    //                     width: 1.5,
    //                     height: 50,
    //                     displayValue: false,
    //                 });
    //                 // Add barcode to PDF
    //                 const barcodeImage = canvas.toDataURL("image/png");
    //                 doc.addImage(barcodeImage, "PNG", left, top, 40, 15);  // Adjust width and height as needed
    //             } else {
    //                 if (style.fontWeight) {
    //                     doc.setFont('Helvetica', style.fontWeight?.toLowerCase());
    //                 } else {
    //                     doc.setFont('Helvetica', 'normal');
    //                 }
    //                 if ((pixelsToPoints(style['font-size']))) {
    //                     doc.setFontSize(pixelsToPoints(style['font-size']))
    //                 }
    //                 doc.text((content), (left || 0), (top || 0), { maxWidth: (pixelsToMm(style?.width) || 0) }
    //                 );
    //             }
    //         });
    //     }
    //     doc.setFont('Helvetica', 'normal');
    //     totalProducts += 1;
    //     if (totalProducts < Object.keys(styleArray).length) {
    //         doc.addPage();
    //     }
    // }
    // console.log(doc.output());
    // Save the generated PDF

    // let productQty = 0;
    let isBarcodeMissing = false;
    // for (const product in styleArray) {
    //   const styleArray1 = styleArray[product];

    //   if (styleArray1 && styleArray1.length > 0) {
    //     for (let i = 0; i <= productQty; i++) {
    //       if (i > 0) {
    //         doc.addPage();
    //       }

    //       styleArray1.forEach((item) => {
    //         const { content, style, variableData } = item;
    //         const itmcode = variableData.ItmCode;
    //         // if (itmcode.trim() === '') {
    //         //   alert('Barcode Is missing for' + content);
    //         //   return
    //         // }
    //         if (variableData.ItmCode.trim() === "") {
    //           isBarcodeMissing = true;
    //           return;
    //         }
    //         productQty = variableData.Qty || 1;
    //         topf = constrainedY * pixelsToMm(style.top);
    //         leftf = constrainedX * pixelsToMm(style.left);
    //         const left = Math.ceil(leftf);
    //         const top = Math.ceil(topf);
    //         // console.log(`left: ${left}, top: ${top}`);

    //         if (isNaN(left) || isNaN(top)) {
    //           return;
    //         }

    //         if (
    //           content === "BarcodeImage" &&
    //           variableData.ItmCode.trim() !== ""
    //         ) {
    //           const height = Math.ceil(pixelsToMm(style.height));
    //           const width = Math.ceil(pixelsToMm(style.width));
    //           const scaleFactor = 3;
    //           const canvas = document.createElement("canvas");
    //           canvas.width = width * scaleFactor;
    //           canvas.height = height * scaleFactor;
    //           const ctx = canvas.getContext("2d");
    //           ctx.scale(scaleFactor, scaleFactor);
    //           JsBarcode(canvas, itmcode, {
    //             format: "CODE128",
    //             width: 1.5,
    //             height: 50,
    //             displayValue: false,
    //           });

    //           // Add barcode to PDF
    //           const barcodeImage = canvas.toDataURL("image/png");
    //           doc.addImage(barcodeImage, "PNG", left, top, width, height);
    //         } else {
    //           if (style.fontWeight) {
    //             debugger;
    //             doc.setFont("Times", style.fontWeight?.toLowerCase());
    //           } else {
    //             doc.setFont("Times", "normal");
    //           }
    //           doc.setFontSize(pixelsToPoints(style["font-size"]));
    //           doc.text(content, left, top, {
    //             maxWidth: pixelsToMm(style?.width),
    //           });
    //         }
    //       });
    //     }
    //   }
    //   doc.setFont("Times", "normal");
    //   // totalProducts += 1;
    //   // if (totalProducts < Object.keys(styleArray).length && productQty === 1) {
    //   //   doc.addPage();
    //   // }
    // }
    // if (isBarcodeMissing) {
    //   alert("Generate the Barcode and try again");
    //   props.setLoading(false);
    //   return;
    // }
    // const pdfBlob = doc.output("blob");
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // window.open(pdfUrl, "_blank");
    // props.setLoading(false);
    // props.setShowBillPrint(false);

    let index = 0;
    for (const product in styleArray) {
      var styleArray1 = styleArray[product];
      styleArray1 = removeDuplicates(styleArray1);
      if (styleArray1 && styleArray1.length > 0) {
        // Determine product quantity for the current product
        const productQty = styleArray1[0]?.variableData?.Qty || 1;

        for (let i = 0; i < productQty; i++) {
          // Correct loop condition
          if (i > 0) {
            doc.addPage(); // Add a new page after the first iteration
            console.log(`Added page for copy ${i + 1}`);
          }

          styleArray1.forEach((item) => {
            const { content, style, variableData } = item;
            const itmcode = variableData?.ItmCode || "";

            if (itmcode.trim() === "") {
              isBarcodeMissing = true;
              return;
            }

            const top = Math.ceil(constrainedY * pixelsToMm(style.top));
            const left = Math.ceil(constrainedX * pixelsToMm(style.left));

            if (isNaN(left) || isNaN(top)) {
              return;
            }

            if (content === "BarcodeImage" && itmcode.trim() !== "") {
              const height = Math.ceil(pixelsToMm(style.height));
              const width = Math.ceil(pixelsToMm(style.width));
              const scaleFactor = 3;
              const canvas = document.createElement("canvas");
              canvas.width = width * scaleFactor;
              canvas.height = height * scaleFactor;
              const ctx = canvas.getContext("2d");
              ctx.scale(scaleFactor, scaleFactor);

              JsBarcode(canvas, itmcode, {
                format: "CODE128",
                width: 1.5,
                height: 50,
                displayValue: false,
              });

              // Add barcode to PDF
              const barcodeImage = canvas.toDataURL("image/png");
              doc.addImage(barcodeImage, "PNG", left, top, width, height);
            } else {
              doc.setFont("Times", style.fontWeight?.toLowerCase() || "normal");
              doc.setFontSize(pixelsToPoints(style["font-size"]));
              doc.text(content, left, top, {
                maxWidth: pixelsToMm(style?.width),
              });
            }
          });
          // totalProducts += 1;
          if (i == productQty - 1 && totalProducts == index) {
            break;
          }
          if (i == productQty - 1) {
            doc.addPage();
          }

          // if (totalProducts < Object.keys(styleArray).length && productQty) {
          //   doc.addPage();
          //   console.log(`Added page for copy totalProducts ${totalProducts}`);
          // }
        }
      }
      index += 1;
    }

    if (isBarcodeMissing) {
      alert("Generate the Barcode and try again");
      props.setLoading(false);
      return;
    }

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    props.setLoading(false);
    props.setShowBillPrint(false);
  };

  useEffect(() => {
    if (droppedItems.length > 0) {
      const timer = setTimeout(() => {
        generatePDF();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [droppedItems, printData, styleArray, props.nowPrint]);

  return (
    <>
      <div
        id="pdf-content"
        style={{ width: "100mm", margin: "5 auto", overflow: "hidden" }}
      >
        <div style={{ padding: "5mm", boxSizing: "border-box" }}>
          <div
            style={{
              transformOrigin: "5% 5%",
              transform: "scale(0.5, 0.5)",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {renderDroppedItems()}
          </div>
        </div>
        <style>
          {`
                @media screen {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                @media print {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                `}
        </style>
      </div>
    </>
  );
};

export default BarCodeDesign;
