import React, { useEffect, useState } from "react";

import MainButton from "../../Props/MainButton";
import BarcodeAndSerial from "../../../API/APIServices/BarcodeAndSerial";
import BarcodeGenerator from "./BarcodeGenrator";

const BarcodeSetting = () => {
  const [inputData, setInputData] = useState({
    structureCategory: "",
    numberType: "Manual",
    structureName: "",
    startingAutoNumber: "",
    isActive: false,
  });

  const [rows, setRows] = useState([
    {
      categoryFieldId: "",
      categoryFieldName: "",
      textLength: "",
      alignment: "",
    },
    {
      categoryFieldId: "",
      categoryFieldName: "",
      textLength: "",
      alignment: "",
    },
    {
      categoryFieldId: "",
      categoryFieldName: "",
      textLength: "",
      alignment: "",
    },
  ]);

  const [selectedBarcode, setSelectedBarcode] = useState(null);

  useEffect(() => {
    if (inputData.structureCategory === "") {
      setInputData({
        structureCategory: "",
        numberType: "Manual",
        structureName: "",
        startingAutoNumber: "",
        isActive: false,
      });
      setRows([
        {
          categoryFieldId: "",
          categoryFieldName: "",
          textLength: "",
          alignment: "",
        },
        {
          categoryFieldId: "",
          categoryFieldName: "",
          textLength: "",
          alignment: "",
        },
        {
          categoryFieldId: "",
          categoryFieldName: "",
          textLength: "",
          alignment: "",
        },
      ]);
      setSelectedBarcode(null);
      setBarcodeData([]);
    }
  }, [inputData.structureCategory]);

  const handleInputChange = (field, value) => {
    setInputData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    console.log("inputData", inputData);
  }, [inputData]);

  useState(() => {
    document.title = "Barcode Setting";
  }, []);

  const CategoryData = [
    {
      id: 1,
      Name: "Barcode Number",
    },
    {
      id: 2,
      Name: "Serial Number",
    },
  ];

  const [BarcodeData, setBarcodeData] = useState([]);

  // const BarcodeData = [
  //     {
  //         id: 1,
  //         Name: "Barcode 1"
  //     },
  //     {
  //         id: 2,
  //         Name: "Barcode 2"
  //     },
  // ]

  const tableCategory = [
    { id: 1, name: "CompanyId" },
    { id: 2, name: "BranchCode" },
    { id: 3, name: "ItemName" },
    { id: 4, name: "ItemId" },
    { id: 5, name: "GodownName" },
    { id: 6, name: "VendorName" },
    { id: 7, name: "Year" },
    { id: 8, name: "AutoNo" },
    { id: 9, name: "TotalAutoNo" },
  ];

  useEffect(() => {
    console.log("rows", rows);
  }, [rows]);

  const clearRow = (index) => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[index] = {
        categoryFieldId: "",
        categoryFieldName: "",
        textLength: "",
        alignment: "",
      };
      return newRows;
    });
  };

  const handleTableInputChange = (index, field, value) => {
    const newRows = [...rows];
    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleCategoryChange = (index, value) => {
    const selectedCategory = tableCategory.find(
      (category) => category.name === value
    );
    const newRows = [...rows];

    if (selectedCategory) {
      newRows[index].categoryFieldId = selectedCategory.id;
      newRows[index].categoryFieldName = selectedCategory.name;
    } else {
      newRows[index].categoryFieldId = "";
      newRows[index].categoryFieldName = "";
    }

    setRows(newRows);
  };

  const renderTableRows = () =>
    rows.map((row, index) => (
      <tr key={index}>
        <td style={{ padding: "8px" }}>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {" "}
            {index + 1}{" "}
            <i
              style={{ fontSize: "12px" }}
              class="fa-solid fa-broom"
              onClick={() => clearRow(index)}
            ></i>
          </div>
        </td>
        <td>
          <select
            value={row.categoryFieldName}
            onChange={(e) => handleCategoryChange(index, e.target.value)}
          >
            <option value=""></option>
            {tableCategory.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </td>

        {/* Length Input */}
        <td>
          <input
            type="number"
            value={row.textLength}
            min="0"
            onChange={(e) =>
              handleTableInputChange(index, "textLength", e.target.value)
            }
          />
        </td>

        {/* Alignment Dropdown */}
        <td>
          <select
            value={row.alignment}
            onChange={(e) =>
              handleTableInputChange(index, "alignment", e.target.value)
            }
          >
            <option value=""></option>
            <option value="Right">Right</option>
            <option value="Left">Left</option>
          </select>
        </td>
      </tr>
    ));

  const handleSave = async () => {
    debugger;

    const data = {
      structureCategory: inputData.structureCategory,
      structureName: inputData.structureName,
      numberingType: inputData.numberType === "Manual" ? false : true,
      startingAutoNumber: inputData.startingAutoNumber,
      isActive: true,
      structureFields: rows,
    };
    if (selectedBarcode) {
      const updatedData = {
        ...data,
        id: selectedBarcode,
      };
      try {
        const response = await BarcodeAndSerial.updateBarcodeAndSerial(
          updatedData
        );
        if (response) {
          alert("Updated Successfully");
          fetchById(selectedBarcode, inputData.structureCategory);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("data", data);
      try {
        const response = await BarcodeAndSerial.insertBarcodeAndSerial(data);
        if (response) {
          alert("Created Successfully");
          setInputData({
            structureCategory: "",
            numberType: "Manual",
            structureName: "",
            startingAutoNumber: "",
            isActive: false,
          });
          setRows([
            {
              categoryFieldId: "",
              categoryFieldName: "",
              textLength: "",
              alignment: "",
            },
            {
              categoryFieldId: "",
              categoryFieldName: "",
              textLength: "",
              alignment: "",
            },
            {
              categoryFieldId: "",
              categoryFieldName: "",
              textLength: "",
              alignment: "",
            },
          ]);
          setSelectedBarcode(null);
          setBarcodeData([]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchBarcodeAndSerialData = async (id) => {
    try {
      const response = await BarcodeAndSerial.getBarcodeAndSerial(id);
      debugger;
      if (response) {
        setBarcodeData(response);
        console.log("response", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (inputData.structureCategory != "") {
      fetchBarcodeAndSerialData(inputData.structureCategory);
    }
  }, [inputData.structureCategory]);

  const fetchById = async (id, structureCategory) => {
    try {
      const response = await BarcodeAndSerial.getBarcodeAndSerialId(
        id,
        structureCategory
      );
      debugger;
      if (response) {
        const barcode = response;
        setInputData((prevState) => ({
          ...prevState,
          structureName: barcode[0].structureName,
          numberType: barcode[0].numberingType ? "Automatic" : "Manual",
          startingAutoNumber: barcode[0].startingAutoNumber,
          isActive: barcode[0].isActive,
        }));
        setRows(barcode[0].structureFields);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedBarcode) {
      debugger;
      fetchById(selectedBarcode, inputData.structureCategory);
      // const barcode = BarcodeData.find(item => item.id === Number(selectedBarcode));
      // if (barcode) {
      //     setInputData(prevState => ({
      //         ...prevState,
      //         structureName: barcode.structureName,
      //         numberType: barcode.numberingType ? "Automatic" : "Manual",
      //         startingAutoNumber: barcode.startingAutoNumber,
      //         isActive: barcode.isActive,
      //     }));
      //     setRows(barcode.structureFields)
      // }
    }
  }, [selectedBarcode]);

  const handleSetActive = () => {
    if (selectedBarcode && inputData.structureCategory) {
      SetBarcodeAndSerialActive(selectedBarcode, inputData.structureCategory);
    }
  };

  const SetBarcodeAndSerialActive = async (id, structureCategory) => {
    try {
      const response = await BarcodeAndSerial.SetBarcodeActive(
        id,
        structureCategory
      );
      debugger;
      if (response) {
        alert("Active");
        fetchById(id, structureCategory);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await BarcodeAndSerial.deleteBarcodeAndSerial(
        selectedBarcode
      );
      if (response) {
        alert("Deleted Successfully");
        fetchBarcodeAndSerialData(1);
        setInputData({
          structureCategory: "",
          numberType: "Manual",
          structureName: "",
          startingAutoNumber: "",
          isActive: false,
        });
      } else {
        alert("Failed to delete");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Barcode And Serial Number Setting</h2>
        </div>

        {/* ********************************{Buttob}********************************* */}
        <MainButton SaveButton={handleSave} PageName="Masters" />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1">
                    <div className="control_radius">
                      <div className="company_info-text-im">
                        Barcode And Serial Number Setting
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                value={inputData.structureCategory}
                                onChange={(e) =>
                                  handleInputChange(
                                    "structureCategory",
                                    e.target.value
                                  )
                                }
                                type="text"
                                required
                              >
                                <option value="">--Select--</option>
                                {CategoryData.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </select>
                              <label class="modify_lbl_text3">
                                Select Category
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div
                            className="input-box-Imo1 input-height2"
                            style={{ display: "flex" }}
                          >
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                value={selectedBarcode}
                                onChange={(e) =>
                                  setSelectedBarcode(e.target.value)
                                }
                                type="text"
                                required
                              >
                                <option>--Select--</option>
                                {BarcodeData.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.structureName}
                                  </option>
                                ))}
                              </select>
                              <label class="modify_lbl_text3">
                                Select Barcode
                              </label>
                            </div>
                            {selectedBarcode !== null && (
                              <button
                                className="save-button-main-save"
                                onClick={handleSetActive}
                                // disabled={inputData.isActive}
                                // style={{ display: inputData.isActive ? 'none' : 'block' }}
                              >
                                Set Active
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                type="text"
                                required
                                id="txtAttributeNo"
                                value={inputData.structureName}
                                onChange={(e) =>
                                  handleInputChange(
                                    "structureName",
                                    e.target.value
                                  )
                                }
                              />

                              <label class="modify_p_text2">
                                Structure Name
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <label className="modify_p_text2">
                                Number Type:
                              </label>

                              <div
                                className="radio-buttons"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "50px",
                                }}
                              >
                                <label>
                                  <input
                                    type="radio"
                                    name="numberType"
                                    value="Manual"
                                    checked={inputData.numberType === "Manual"}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "numberType",
                                        e.target.value
                                      )
                                    }
                                  />
                                  Manual
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="numberType"
                                    value="Automatic"
                                    checked={
                                      inputData.numberType === "Automatic"
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        "numberType",
                                        e.target.value
                                      )
                                    }
                                  />
                                  Automatic
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {inputData.numberType === "Automatic" && (
                        <>
                          <div className="company_control">
                            <div className="stock-im-dep1">
                              <div className="input-box-Imo1 input-height2">
                                <div className="input-box-mok1">
                                  <input
                                    className="modify_im_input mode_category1"
                                    type="text"
                                    required
                                    id="txtAttributeNo"
                                    value={inputData.startingAutoNumber}
                                    onChange={(e) =>
                                      handleInputChange(
                                        "startingAutoNumber",
                                        e.target.value
                                      )
                                    }
                                  />

                                  {/* {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>} */}
                                  <label class="modify_p_text2">
                                    Starting Auto No.
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="stock-im-dep1">
                              <table
                                className="tableList-main"
                                style={{ width: "100%" }}
                              >
                                <thead className="table-head-main">
                                  <tr>
                                    <th style={{ textAlign: "center" }}>SN.</th>
                                    <th style={{ textAlign: "center" }}>
                                      Category
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Length
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Alignment
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderTableRows()}</tbody>
                              </table>
                            </div>
                          </div>

                          <div className="company_control">
                            {/* <div className="stock-im-dep1">
                                                            <div className="input-box-Imo1 input-height2">
                                                                <div    >
                                                                    <input
                                                                        type="checkbox"
                                                                        required
                                                                        id="txtAttributeNo"
                                                                        checked={inputData.setActive}
                                                                        onChange={(e) =>
                                                                            handleInputChange("setActive", e.target.checked)
                                                                        }
                                                                    />

                                                                    <label class="modify_p_text2" style={{ fontSize: 16 }}>
                                                                        Set Active
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                            {/* <div className="stock-im-dep1">
                                                            <div className="input-box-Imo1 input-height2">
                                                                <div className="input-box-mok1">
                                                                    <label className="modify_p_text2">Number Type:</label>

                                                                    <div className="radio-buttons" style={{ display: "flex", justifyContent: "center", gap: "50px" }}>
                                                                        <label>
                                                                            <input type="radio" name="numberType" value="Manual" />
                                                                            Manual
                                                                        </label>
                                                                        <label>
                                                                            <input type="radio" name="numberType" value="Automatic" />
                                                                            Automatic
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                          </div>

                          {/* <table className="tableList-main" style={{ width: "50%", marginTop: "50px" }}>
                                                        <thead className='table-head-main'>
                                                            <tr>
                                                                <th style={{ textAlign: "center" }}>SN.</th>
                                                                <th style={{ textAlign: "center" }}>Category</th>
                                                                <th style={{ textAlign: "center" }}>Length</th>
                                                                <th style={{ textAlign: "center" }}>Alignment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{renderTableRows()}</tbody>
                                                    </table> */}
                        </>
                      )}
                      {selectedBarcode && (
                        <button
                          style={{
                            backgroundColor: "#f64040",
                            color: "white",
                            padding: "5px 20px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginTop: "12px",
                          }}
                          onClick={handleDelete}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                    {/* <div className="company_info-text-im">Attribute List</div> */}
                    {/*  */}
                    {/* <BarcodeGenerator /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarcodeSetting;
