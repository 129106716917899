import React from 'react'

const OrderDetailStatusModal = (props) => {

  const { ItemDetail, closeModal } = props

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  function convertTo12HourFormat(twentyFourHourTime) {
    if (!twentyFourHourTime) {
      return "";
    }
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return "";
    }

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }

    return `${twelveHourHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  return (
    <div className="modal-back">
      <div className="info-modal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "1px 10px",
          }}
        >
          <label className="fw-600" style={{ fontSize: "18px" }}>
            Order Status Details
          </label>
          <i class="fa-solid fa-xmark xmark" onClick={closeModal}></i>
        </div>
        <hr />
        <div>
          <table className="tableStyle" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  className="thStyle"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  S.No
                </th>
                <th
                  className="thStyle"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  Status
                </th>
                <th
                  className="thStyle"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  Date
                </th>
                <th
                  className="thStyle"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  Change By
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(ItemDetail) ? (
                ItemDetail
                  .filter((data, index, self) =>
                    index === self.findIndex((t) => (
                      t.overallOrderStatus === data.overallOrderStatus //|| t.ord2Id === data.ord2Id
                    ))
                  )
                  .map((item, index) => (
                    <tr>
                      <td className="tdStyle">{index + 1}</td>
                      <td className="tdStyle"> {item.overallOrderStatus ? item.overallOrderStatus : ""}</td>
                      <td className="tdStyle">
                        {item.orderDate ? ((formatDate(item.orderDate)) + "---") : ""}
                        {item.orderTime ? convertTo12HourFormat(String(item.orderTime)) : ""}</td>
                      <td className="tdStyle">{item.orderConfirmedByName ? item.orderConfirmedByName : ""}</td>
                    </tr>
                  ))
              ) : (
                <tr><td colSpan="4" style={{ textAlign: "center" }}>No data available</td></tr>
              )}
            </tbody>
          </table>
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            className="redBtn"
            style={{ width: "12%" }}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrderDetailStatusModal