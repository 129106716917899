
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect, useContext } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import MainButton from "../../Inventory/Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import Order from "../../API/APIServices/Order";
import UserCredential from "../../API/APIServices/UserCredential";
import Loader from "../../Inventory/Props/Loader";
import MyContext from "../../Inventory/MyContext";
import ItemDepartment from "../../API/APIServices/ItemDepartment";
import FeedbackItemWiseModal from '../../Inventory/ModalBox/FeedbackItemWiseModal'
import ItemMaster from "../../API/APIServices/ItemMaster";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import * as XLSX from 'xlsx';

function FeedbackItemDepartmentWiseReport() {

    useState(() => {
        document.title = 'Feedback Item Department Wise Report';
    }, []);

    const { isShowTotalAmountInReport, isAllowExportExcel, UserDetails, fetchCompanyData } = useContext(MyContext);

    const [loading, setLoading] = useState(true);

    // ***************** Get Assign user from user master ***********************
    // const [assignUser, setAssignUser] = useState();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await UserCredential.getUserCredential();
    //             setAssignUser(data);
    //             console.log(data);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const [orderTaker, setOrderTaker] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const filteredData = [{
                    fieldName: "userRole",
                    operatorName: "Equal",
                    compareValue: 563  //OrderTaker
                }];
                const data = await UserCredential.getFilteredUserCredential(filteredData);
                setOrderTaker(data);
                console.log(data);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    // ***************** Item Department ******************
    const [itemDepartment, setItemDepartment] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const data = await ItemDepartment.getItemDepartment();
                setItemDepartment(data);
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        fetchData();
    }, [])

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    // **************Get Order List************************
    const [dataArray, setDataArray] = useState([]);
    const [dummyDataArray, setDummyDataArray] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState()

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const currentDate = await CurrentDateAndTime.getCurrentDate()
                setFromDate(currentDate)
                setToDate(currentDate)
                const filteredData = [
                    {
                        fieldName: "orderDate",
                        operatorName: "GreaterOrEqualThan",
                        compareValue: currentDate
                    },
                    {
                        fieldName: "orderDate",
                        operatorName: "LessOrEqualThan",
                        compareValue: currentDate
                    }
                ]
                setFilter(filteredData)
                const val = await Order.getFeedbackReportFilter(filteredData);
                console.log(val);
                setDataArray(val);
                setDummyDataArray(val);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addFilter, setFilter] = useState([]);
    const [serviceTypeName, setServiceTypeName] = useState('default');

    const handleFilterChange = async (event) => {
        setLoading(true);
        const { name, value } = event.target;

        let updatedFilter = [...addFilter];
        if (name == 'serviceTypeId') {
            if (value == 751) {
                setServiceTypeName("Table Order")
            }
            else if (value == 752) {
                setServiceTypeName("Home Delivery")
            }
            else if (value == 753) {
                setServiceTypeName("Pick Up")
            }
            else if (value == 754) {
                setServiceTypeName("POS")
            }
            else {
                setServiceTypeName("default")
            }
        }

        if (value === "default") {
            updatedFilter = updatedFilter.filter(filter => filter.fieldName !== name);
        } else {
            let newFilter;
            if (name === "fromDate" || name === "toDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "orderDate",
                    operatorName: name === "fromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                if (name === "fromDate") {
                    setFromDate(formattedValue)
                }
                else if (name === "toDate") {
                    setToDate(formattedValue)
                }
            }
            else if (name == "accMobileNo" || name == "accName") {
                newFilter = {
                    fieldName: name,
                    operatorName: "Contains",
                    compareValue: value
                };
            }
            else if (name === "feedBackList") {
                if (value === "feedbackDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "Equal",
                        compareValue: true
                    };
                }
                else if (value === "feedbackNotDone") {
                    newFilter = {
                        fieldName: "hasFeedbackDone",
                        operatorName: "NotEqual",
                        compareValue: true
                    };
                }
            }
            else if (name === "feedbackFromDate" || name === "feedbackToDate") {
                const formattedValue = formatDate(value);
                newFilter = {
                    fieldName: "feedbackUpdateOn",
                    operatorName: name === "feedbackFromDate" ? "GreaterOrEqualThan" : "LessOrEqualThan",
                    compareValue: formattedValue
                };
                // if (name === "fromDate") {
                //     setFromDate(formattedValue)
                // }
                // else if (name === "toDate") {
                //     setToDate(formattedValue)
                // }
            }
            else if (name === "itemRating") {
                newFilter = {
                    fieldName: "itemRating",
                    operatorName: "LessOrEqualThan",
                    compareValue: value
                };
            }
            else {
                newFilter = {
                    fieldName: name,
                    operatorName: "Equal",
                    compareValue: value
                };
            }
            const existingFilter = updatedFilter.find(filter => filter.fieldName === newFilter.fieldName && filter.operatorName === newFilter.operatorName);

            if (existingFilter) {
                const indexToUpdate = updatedFilter.findIndex(filter => filter === existingFilter);
                if (indexToUpdate !== -1) {
                    updatedFilter[indexToUpdate] = {
                        ...updatedFilter[indexToUpdate],
                        compareValue: newFilter.compareValue
                    };
                }
            } else {
                updatedFilter.push(newFilter);
            }
        }
        setFilter(updatedFilter);
        //const val = await Order.getOrderListFilter(updatedFilter);
        // const val = await Order.getFeedbackReportFilter(updatedFilter);
        // setDataArray(val);
        // setDummyDataArray(val);
        setLoading(false);
    };

    const [reportByItemWise, setReportByItemWise] = useState(false);
    const [d2ItemId, setD2ItemId] = useState(0);

    const handleModalOpen = (itemId) => {
        setD2ItemId(itemId);
        setReportByItemWise(true);
    }

    const closeModal = () => {
        setReportByItemWise(false);
    }

    const [itemName, setItemName] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ItemMaster.GetItemMaster();
                setItemName(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const [selectedValues, setSelectedValues] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);

    const handleCheckboxChange = (id, checked) => {
        setSelectedValues((prevValues) => {
            if (checked) {
                return [...prevValues, id];
            } else {
                return prevValues.filter((value) => value !== id);
            }
        });
    };

    useEffect(() => {
        // if (selectedValues && selectedValues.length > 0) {
        //     const updatedDataArray = dummyDataArray && dummyDataArray.filter(item =>
        //         selectedValues.includes(item.itemId)
        //     );
        //     setDataArray(updatedDataArray);
        // }
        // else if (selectedValues && selectedValues.length == 0) {
        //     setDataArray(dummyDataArray);
        // }
    }, [selectedValues, dataArray]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const totalQuantityDone = dataArray
        ?.filter(item => item.serviceTypeId === 752 ? item.orderStatus === "Delivered" : item.orderStatus === "Done")
        .reduce((acc, item) => {
            acc[item.itemId] = (acc[item.itemId] || 0) + item.noOfOrdersQty;
            return acc;
        }, {});

    const totalAmt = dataArray && dataArray
        .reduce((acc, item) => {
            // Sum the totalQty for each unique itemId
            if (!acc[item.itemId]) {
                acc[item.itemId] = 0;
            }
            acc[item.itemId] += item.itemPrice;
            return acc;
        }, {});

    const averageItemRatings = dataArray && dataArray
        .reduce((acc, item) => {
            if (item.itemRating != null) {  // Exclude null or undefined ratings
                if (!acc[item.itemId]) {
                    acc[item.itemId] = { totalRating: 0, count: 0 };
                }
                acc[item.itemId].totalRating += item.itemRating;
                acc[item.itemId].count += 1;
            }
            return acc;
        }, {}) || {};

    const getAverageRating = (itemId) => {
        const ratingData = averageItemRatings[itemId];
        if (ratingData && ratingData.count > 0) {
            return (
                <>
                    {Math.round(ratingData.totalRating / ratingData.count)}
                    <span style={{ color: "darkorange" }}>&#9733;</span>
                </>
            );
        }
        return null;
    };

    const itemAverages = Object.values(averageItemRatings)
        .map(item => item.count > 0 && item.totalRating && Math.round(item.totalRating / item.count))
        .filter(avg => avg != '0' && avg != 0 && avg != '');

    console.log(averageItemRatings);
    console.log(itemAverages);

    const overallAverageRating = itemAverages.length
        ? (itemAverages.reduce((sum, avg) => sum + avg)) / itemAverages.length
        : "0";

    console.log(overallAverageRating);

    const handleExportFileClick = () => {
        const ws = XLSX.utils.table_to_sheet(document.getElementById('tableToExport'));
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Feedback');
        XLSX.writeFile(wb, 'FeedbackItemDepartmentWiseReport.xlsx');
    }

    const handleSearchBtnClick = async () => {
        setLoading(true);
        try {
            const val = await Order.getFeedbackReportFilter(addFilter);
            // setDataArray(val);
            if (selectedValues && selectedValues.length > 0) {
                const updatedDataArray = val && val.filter(item =>
                    selectedValues.includes(item.itemId)
                );
                setDataArray(updatedDataArray);
                setDummyDataArray(updatedDataArray)
            }
            else if (selectedValues && selectedValues.length == 0) {
                setDataArray(val);
                setDummyDataArray(val);
            }
            // setDummyDataArray(val)
            setLoading(false)
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    // ***************** Get Service Type***********************
    const [serviceType, setServiceType] = useState();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                // const data = await UserCredentialApi.getUserCredential();
                const data = await Order.getServiceType();
                setServiceType(data);
                console.log(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
            setLoading(false)
        };
        fetchData();
    }, []);

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Feedback Item Department Wise Report</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton PrintIcon={true} isBtnSearch={true} saveButtonHide={true} isExportFile={true} handleExportFileClick={handleExportFileClick}
                    handleSearchClick={handleSearchBtnClick} /> {/*handleSearchClick={handleSearchClick} */}
            </div>
            <div><DateTime /></div>
            <div className="content_box">
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width feedback-report-width" style={{ width: "91vw" }}>
                            <div className="configure_radius_add_update">
                                <div className="company_info-text-im td-1">Feedback Item Department Wise Report</div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "33.3%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input1Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="itemMaster.IProductGroupDepartment"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (itemDepartment !== null && Array.isArray(itemDepartment)) {
                                                        return itemDepartment.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Item Department</label>
                                        </div>

                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "33.3%" }}>
                                            <div className="dropdown" ref={dropdownRef} onClick={toggleDropdown} style={{ padding: "0px" }}>
                                                <button
                                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                    style={{ fontSize: "14px" }}
                                                    onClick={toggleDropdown}
                                                    ref={input2Ref}
                                                    onKeyDown={(event) => handleKeyPress(event, input3Ref)}
                                                >
                                                    Select Items
                                                </button>
                                                {isDropdownOpen && (
                                                    <div className="feedback-dropDown" style={{ display: "block", position: "absolute", width: "100%" }} onClick={handleModalClick}>
                                                        {itemName && Array.isArray(itemName) && itemName.map((data) => (
                                                            <>
                                                                <label key={data.d2Id} className="dropdown-item">
                                                                    <input
                                                                        type="checkbox"
                                                                        value={data.d2Id}
                                                                        checked={selectedValues.includes(data.d2Id)}
                                                                        onChange={(e) => handleCheckboxChange(data.d2Id, e.target.checked)} style={{ padding: "1px", margin: "2px" }}
                                                                    />
                                                                    <label>{data.name}</label>
                                                                </label>
                                                                <hr />
                                                            </>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <label id="input1" className="labelEffect1" style={{ width: "auto", left: "28", top: "0" }}>Item Name</label>
                                        </div>

                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "33.3%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" name="itemRating" ref={input3Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input4Ref)}
                                                onChange={handleFilterChange} style={{ fontSize: "14px" }} required>
                                                <option value="default">N/A</option>
                                                <option value="1">1</option>
                                                {/* <option value="1.5">1.5</option> */}
                                                <option value="2">2</option>
                                                {/* <option value="2.5">2.5</option> */}
                                                <option value="3">3</option>
                                                {/* <option value="3.5">3.5</option> */}
                                                <option value="4">4</option>
                                                {/* <option value="4.5">4.5</option> */}
                                                <option value="5">5</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Rating Star</label>
                                        </div>
                                        {/* <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                onChange={handleFilterChange} name="feedBackList" ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} style={{ fontSize: "14px" }} required>
                                                <option value="default">All</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Confirm</label>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "33.3%" }}>
                                            <select
                                                className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                ref={input4Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)}
                                                style={{ fontSize: "14px" }}
                                                onChange={handleFilterChange}
                                                name="serviceTypeId"
                                                required
                                            >
                                                <option value="default">All</option>
                                                {(() => {
                                                    if (serviceType !== null && Array.isArray(serviceType)) {
                                                        return serviceType.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ));
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Service Type</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "33.3%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input5Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="date" required
                                                onChange={handleFilterChange} value={fromDate}
                                                name="fromDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%", width: "33.3%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input6Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="date" required
                                                onChange={handleFilterChange} value={toDate}
                                                name="toDate" style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">Order To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "33.3%" }}>
                                            <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input7Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input8Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.feedbackUser}
                                                onChange={handleFilterChange}
                                                name="ordConfirmById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (orderTaker !== null && Array.isArray(orderTaker)) {
                                                        return orderTaker.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Order Taker</label>
                                        </div>

                                        {/* <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input8Ref}
                                                onKeyDown={(event) => handleKeyPress(event, input9Ref)} style={{ fontSize: "14px" }}
                                                //value={inputValues.confirmUser}
                                                onChange={handleFilterChange}
                                                name="orderConfirmedById"
                                                required>
                                                <option value="default">--Select--</option>
                                                {(() => {
                                                    if (assignUser !== null && Array.isArray(assignUser)) {
                                                        return assignUser.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.userName}
                                                            </option>
                                                        ))
                                                    }
                                                })()}
                                            </select>
                                            <label id="input1" className="labelEffect1">Confirmed By</label>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="configure_radius_add_update config-search-top">
                                <div className="company_info-text-im td-1">Feedback Details</div>
                                <div id="tabs">
                                    <div className="col-sm-12 order-rpt-top">
                                        <div className="table-resposive tb-scroll-width-feedBack" style={{ height: "500px", overflow: "scroll" }}>
                                            <table id="tableToExport" className="table_ord_mod table-bordered1 table-width-cancel" frame="box">
                                                <thead textAlign="center" className="table_headerSticky thead_scroll_orderlist th-bg-color" >
                                                    <tr className="">
                                                        <th className="td_col6">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                        </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Department</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Feedback</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>Product Name</div>
                                                        </div> </th>
                                                        {isShowTotalAmountInReport && (
                                                            <th className="td_col6"> <div className="table_head_OrderList">
                                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Product Amt</div>
                                                            </div> </th>
                                                        )}
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>No of Person Rated</div>
                                                        </div> </th>
                                                        <th className="td_col6"> <div className="table_head_OrderList">
                                                            <div className="table_head_mmb1" style={{ textAlign: "center" }}>No of Order Delivered (Qty)</div>
                                                        </div> </th>
                                                        {isShowTotalAmountInReport && (
                                                            <th className="td_col6"> <div className="table_head_OrderList">
                                                                <div className="table_head_mmb1" style={{ textAlign: "center" }}>Total Amount</div>
                                                            </div> </th>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody_body_m1">
                                                    {dataArray && dataArray.length > 0 &&
                                                        dataArray
                                                            .filter((item, index, self) =>
                                                                index === self.findIndex((t) => (
                                                                    t.itemId === item.itemId
                                                                ))
                                                            )
                                                            .sort((a, b) => a.itemDeptId - b.itemDeptId)
                                                            .map((item, index) => (
                                                                console.log("index", index, item),
                                                                <tr key={index} className="table_tbody_Order_List">
                                                                    <td className="td_col6 td_sno-btn">
                                                                        <button className="" onClick={() => handleModalOpen(item.itemId)} style={{ padding: "5px 15px", backgroundColor: "rgba(185, 185, 234, 0.5)", borderRadius: "5px" }}>{index + 1}</button>
                                                                    </td>
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemDepartmentName}</td>
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>
                                                                        {getAverageRating(item.itemId)}
                                                                    </td>
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.itemName}</td>
                                                                    {isShowTotalAmountInReport && (
                                                                        <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{item.itemPrice}</td>
                                                                    )}
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{item.noOfPersonRating}</td>
                                                                    <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>{totalQuantityDone[item.itemId] || 0}</td>
                                                                    {isShowTotalAmountInReport && (
                                                                        // <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{(totalAmt[item.itemId] || 0)}</td>
                                                                        <td className="td_col6 td_sno-btn" style={{ textAlign: "center" }}>₹&nbsp;{(totalQuantityDone[item.itemId] || 0) * item.itemPrice}</td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                    {isShowTotalAmountInReport && (
                                                        <tr className="table_tbody_Order_List"
                                                            style={{ cursor: "pointer", backgroundColor: "ghostWhite" }} >
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "end" }}></td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1} style={{ textAlign: "center" }}>
                                                                {Math.round(overallAverageRating)}
                                                                {/* {(overallAverageRating)} */}
                                                                <span style={{ color: "darkorange" }}>&#9733;</span>
                                                            </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>Total :- </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            .filter((item, index, self) =>
                                                                                index === self.findIndex((t) => (
                                                                                    t.itemId === item.itemId
                                                                                ))
                                                                            )
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = data.itemPrice || 0;
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;
                                                                    return (
                                                                        <span>
                                                                            ₹ {sum.toFixed(2)}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>
                                                            <td className="td_col6 td-padding- td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            .filter((item, index, self) =>
                                                                                index === self.findIndex((t) => (
                                                                                    t.itemId === item.itemId
                                                                                ))
                                                                            )
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = ((data.noOfPersonRating || 0));
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;

                                                                    return (
                                                                        <span>
                                                                            {sum}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            // .filter((item, index, self) =>
                                                                            //     index === self.findIndex((t) => t.ord1Id === item.ord1Id)
                                                                            // )
                                                                            .filter(item => item.serviceTypeId === 752 ? item.orderStatus === "Delivered" : item.orderStatus === "Done")
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = ((data.noOfOrdersQty || 0));
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;
                                                                    return (
                                                                        <span>
                                                                            {sum}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>
                                                            <td className="td_col6 td-padding-sn td_footer" colSpan={1}>
                                                                {(() => {
                                                                    const sum = dataArray && dataArray.length > 0
                                                                        ? dataArray
                                                                            // .filter((item, index, self) =>
                                                                            //     index === self.findIndex((t) => t.ord1Id === item.ord1Id)
                                                                            // )
                                                                            .filter(item => item.serviceTypeId === 752 ? item.orderStatus === "Delivered" : item.orderStatus === "Done")
                                                                            .reduce((acc, data) => {
                                                                                const totalAmt = ((data.noOfOrdersQty || 0) * (data.itemPrice || 0));
                                                                                return acc + totalAmt;
                                                                            }, 0)
                                                                        : 0;
                                                                    return (
                                                                        <span>
                                                                            ₹ {sum.toFixed(2)}
                                                                        </span>
                                                                    );
                                                                })()}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/*********************** Feedback By Item wise Modal ********************/}
                                {reportByItemWise && (
                                    <div className="modal" >
                                        <div className="AddNewItem-modal modal-payment" style={{ minHeight: "95%", width: "90%" }}>
                                            <div className="">
                                                <h3 className="modal-OrderLIst modal-color-CAD">Item Order List</h3>
                                                <span className="close_modal" onClick={closeModal}>
                                                    &times;
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="ModalContentModalSettingP modal-overflow-feedback" style={{ height: "75vh", padding: "0px" }}>
                                                <FeedbackItemWiseModal itemId={d2ItemId} filteredData={addFilter} serviceTypeName={serviceTypeName} />
                                            </div>
                                            <div className="mt-2" style={{ display: "flex", justifyContent: "end", marginRight: "10px" }} >
                                                <button className="redBtn" style={{ width: "17%" }} onClick={closeModal} > Close</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
}


export default FeedbackItemDepartmentWiseReport;