import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useRef } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import ModelReportFor from "./ModelReportFor";
import CsvDownload from "./CsvDownload";
import ImportCsv from "./ImportCsv";

import PropTypes from "prop-types";

function ReportTemplateSetting({
  fetchData,
  setSliderCallBack,
  editData,
  modelOpen,
}) {
  ReportTemplateSetting.propTypes = {
    fetchData: PropTypes.func.isRequired,
    setSliderCallBack: PropTypes.func.isRequired,
    editData: PropTypes.object,
    modelOpen: PropTypes.bool.isRequired,
  };

  const [checkedItem, setCheckedItem] = useState([]);
  const [, setLoading] = useState(false);
  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    alertBoxDelete,
    OpenAlertBoxDelete,
  } = useContext(MyContext);
  const [FieldValue, SetFieldValue] = useState([
    { value: "", name: "--Select--" },
    { value: "statusMaster", name: "Status Master" },
    { value: "agentMaster", name: "Agent Master" },
  ]);

  const [FieldName, setFieldName] = useState([
    "Show/Hide",
    "Column Name",
    "Data Type",
    "Column Name By User",
    "Show Total Value",
    "Select Box With Save Button",
    "Priority",
  ]);

  /***********title***************/
  useState(() => {
    document.title = "Report Template Setting";
  }, []);

  //**********{control modal box 1}*****************//

  //*****************{modal box}*****************//
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  /************Category box****************/

  const [rows, setRows] = useState([]);

  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        columnName: `Column${prevRows.length + 1}`,
        otherData: `Data${prevRows.length + 1}`,
      },
    ]);
  };

  const [options, setOptions] = useState([]);

  const handleMasterIdChange = (newMasterId) => {
    debugger;
    // Update the selected value
    setOptions(newMasterId);
    setInputValues((prevValues) => ({
      ...prevValues,
      reportAttachTo: newMasterId,
    }));
  };

  const fetchData1 = async () => {
    try {
      const response = await TemplateSettingApi.getAllMasterType();
      if (response && Array.isArray(response)) {
        setOptions(response.map((item) => ({ id: item.id, name: item.name })));
      } else {
        console.error("Error:", response.errorMessages);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData1();
  }, []);

  const cleanQuery = (query) => {
    return query.replace(/\n/g, " ").replace(/\s+/g, " ").trim();
  };

  const DB1 = [
    "MainUnit",
    "AltUnit",
    "UnitConvFactor",
    "IsService",
    "ChangeApplyFromDate",
    "Tax1",
    "Tax1Range",
    "Tax2",
    "EnableBarcode",
    "DefaultBarcode",
    "SaleDiscPer",
    "PurDiscPer",
    "HSNCode",
    "LoyaltyPoint",
    "ItemIncentiveSalesman",
    "AlertNegativeQty",
    "RestrictNegativeQty",
    "TaxOnMrp",
    "CGST",
    "SGST",
    "IGST",
    "CESS",
    "Remarks",
    "BranchId",
  ];
  const DBboth = [
    "Name",
    "PrintName",
    "Alias",
    "PrimaryGroup",
    "ParentGrp",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
  ];
  const [ColumnNames, setColumnNames] = useState([]);

  const handleFormSubmit = async (event) => {
    debugger;
    event.preventDefault();

    // Get the values from the input fields
    const q1 = cleanQuery(inputValues.queryForSql.trim());
    const q2 = cleanQuery(inputValues.queryForMySQL.trim());
    const q3 = cleanQuery(inputValues.queryForSqlite.trim());

    if (inputValues.reportName.length == 0) {
      alert("Please write report name");
      return;
    }

    if (inputValues.reportAttachTo.length == 0) {
      alert("Please select report for");
      return;
    }

    if (inputValues.reportType.length == 0) {
      alert("Please select report type");
      return;
    }

    // Validate query input
    if (!q1 && !q2 && !q3) {
      alert("Please enter at least one query.");
      return;
    }
    if (!q1) {
      alert("SQL query box is empty.");
      return;
    }
    if (!q2) {
      alert("MySQL query box is empty.");
      return;
    }
    if (!q3) {
      alert("SQLite query box is empty.");
      return;
    }

    setError("");

    try {
      const response = await TemplateSettingApi.getQueryColumnName(q1, q2, q3);
      console.log(response);

      if (response) {
        Object.keys(response).map((item, index) => {
          setCheckedItem((prev) => [...prev, index]);
        });

        const newColumnNames = response;
        setColumnNames(newColumnNames);
        debugger;
        function CheckDB(FieldName) {
          debugger;
          if (DB1.includes(FieldName)) {
            return "1";
          }
          if (DBboth.includes(FieldName)) {
            return "3";
          }
          return "2";
        }
        setFormData((prev) => {
          const existingColumnNames = new Set(
            prev.rows.map((row) => row.columnName)
          );
          console.log(existingColumnNames);
          const updatedRows = [];

          prev.rows.forEach((row) => {
            if (Object.keys(newColumnNames)?.includes(row.columnName)) {
              updatedRows.push(row);
            }
          });

          console.log("newcolumnname", newColumnNames);

          Object.keys(newColumnNames).forEach((columnName) => {
            console.log(columnName);
            if (!existingColumnNames.has(columnName)) {
              updatedRows.push({
                showOrHide: true,
                columnName: columnName,
                columnDataType: newColumnNames[columnName],
                columnNameByUser: columnName,
                columnShowTotalValue: false,
                columnSelectBoxWithSaveBtn: CheckDB(columnName),
                col1: columnName,
                col2: "",
                col3: "",
                col4: "",
                Sub_Query: "",
                DBVAlue: CheckDB(columnName),
              });
            }
          });

          return { rows: updatedRows };
        });
      } else {
        console.error("Unexpected API response format:", response);
      }

      if (inputValues.reportType == "3") {
        SetFieldValue([
          {
            value: "",
            name: "--Select DB--",
          },
          {
            value: "1",
            name: "DB1",
          },
          {
            value: "2",
            name: "DB2",
          },
          {
            value: "3",
            name: "Both",
          },
        ]);

        setFieldName([
          "Show/Hide",
          "Column Name",
          "Data Type",
          "Sub-Query",
          "Column Name By User",
          "Apply Query",
          "Param Key",
          "Select Database",
          "Priority",
        ]);
        setshowImportDonwload(true);
      } else {
        SetFieldValue([
          { value: "", name: "--Select--" },
          { value: "statusMaster", name: "Status Master" },
          { value: "agentMaster", name: "Agent Master" },
        ]);
        setFieldName([
          "Show/Hide",
          "Column Name",
          "Data Type",
          "Column Name By User",
          "Show Total Value",
          "Select Box With Save Button",
          "Priority",
        ]);
        setshowImportDonwload(false);
      }
    } catch (error) {
      console.error("Error executing query:", error);
    }
  };

  console.log("checked ", checkedItem);

  useEffect(() => {
    console.log("checkitem", checkedItem);
  }, [checkedItem]);

  const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

  // const [QueryinputValues, setQueryInputValues] = useState({
  //     queryForSql: '',
  //     queryForMySQL: '',
  //     queryForSqlite: ''
  // });
  const [showfillbtn, setShowfillbtn] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleClickfillquery = (e) => {
    e.preventDefault();
    setShowMessage(true);
    const { queryForMySQL, queryForSql, queryForSqlite, eF1, eF2 } =
      inputValues;
    console.log(queryForMySQL, queryForSqlite, queryForSql);

    if (queryForSql.length > 0) {
      setInputValues((prev) => ({
        ...prev,
        queryForSql: queryForSql,
        queryForMySQL: queryForSql,
        queryForSqlite: queryForSql,
        eF1: eF1,
        eF2: eF2,
      }));
    } else if (queryForMySQL.length > 0) {
      setInputValues((prev) => ({
        ...prev,
        queryForSql: queryForMySQL,
        queryForMySQL: queryForMySQL,
        queryForSqlite: queryForMySQL,
        eF1: eF1,
        eF2: eF2,
      }));
    } else if (queryForSqlite.length > 0) {
      setInputValues((prev) => ({
        ...prev,
        queryForSql: queryForSqlite,
        queryForMySQL: queryForSqlite,
        queryForSqlite: queryForSqlite,
        eF1: eF1,
        eF2: eF2,
      }));
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index !== 7) {
      var firstTabElement = document.getElementById("firstTab");
      firstTabElement.classList.remove("active1");
      console.log(index);
    }
  };

  //  /* *******************Tab****************** */

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
    setTabContent3(false);
  };

  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
    setTabContent3(false);
  };

  const showtabs3 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(true);
  };

  const [CSVModalOpen, setCSVModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  //**********show hide*****************//
  const handleCheckboxChangeDrop = (option) => {
    setInputValues((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };
  const handleNavigationChange = (e) => {
    setInputValues((prev) => ({
      ...prev,
      eF3: e.target.value,
    }));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false); // Close dropdown if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /***********End Enter code*****************/

  // const input1Ref = useRef(null);
  // const input2Ref = useRef(null);

  // const handleKeyPress = (event, nextInputRef) => {
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         nextInputRef.current.focus();
  //     }
  // };

  // Handle form submission

  /****************************************************** */

  const [errorMessages, setErrorMessages] = useState({
    reportName: "",
    reportType: "",
    reportAttachTo: "",
    pagination: "",
  });

  const [inputValues, setInputValues] = useState({
    reportName: "",
    queryForSql: "",
    queryForSqlite: "",
    queryForMySQL: "",
    reportAttachTo: "",
    pageLink: "",
    reportType: "",
    pagination: "",
    search_ByDate: "",
    search_AnyText: "",
    search_ByColumn: "",
    // pagination: '',
    orderByButton: "",
    firmNameSelect: "",
    transactionTypeSelect: "",
    billSeriesNameSelect: "",
    printButton: "",
    excelButton: "",
    shareButton: "",
    rowWise_Edit: "",
    rowWise_Print: "",
    rowWise_Whatsapp: "",
    rowWise_Delete: "",
    rowWise_ArrowForOtherPage: "",
    rowWise_TransporterOrOther: "",
    srch1: "",
    srch2: "",
    srch3: "",
    srch4: "",
    eF1: "",
    eF2: "",
    eF3: "",
    isActive: true,
  });

  useEffect(() => {
    console.log("inputValues", inputValues);
  }, [inputValues]);

  const [formData, setFormData] = useState({
    rows: rows.map((data) => ({
      showOrHide: true,
      columnName: data.columnName,
      columnDataType: "",
      columnNameByUser: "",
      columnShowTotalValue: false,
      columnSelectBoxWithSaveBtn: "",
      col1: "",
      col2: "",
      col3: "",
      col4: "",
      DBVAlue: "",
      Sub_Query: "",
      isDisabled: true,
    })),
  });
  console.log(formData);

  const [error, setError] = useState("");

  // console.log(formData);

  const [showImportDonwload, setshowImportDonwload] = useState(false);
  const handleInputChange = (event) => {
    debugger;
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);
    if (value == "3" || value == "Import Export Type") {
      setFieldName([
        "Show/Hide",
        "Column Name",
        "Data Type",
        "Sub-Query",
        "Column Name By User",
        "Apply Query",
        "Param Key",
        "Select Database",
        "Priority",
      ]);

      SetFieldValue([
        {
          value: "",
          name: "--Select DB--",
        },
        {
          value: "1",
          name: "DB1",
        },
        {
          value: "2",
          name: "DB2",
        },
        {
          value: "3",
          name: "Both",
        },
      ]);
      setshowImportDonwload(true);
    } else {
      setFieldName([
        "Show/Hide",
        "Column Name",
        "Data Type",
        "Column Name By User",
        "Show Total Value",
        "Select Box With Save Button",
        "Priority",
      ]);

      SetFieldValue([
        { value: "", name: "--Select--" },
        { value: "statusMaster", name: "Status Master" },
        { value: "agentMaster", name: "Agent Master" },
      ]);
      setshowImportDonwload(false);
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // const [checkedItem, setCheckedItem] = useState([])
  // const handleCheckboxChange = (rowIndex, checkboxType) => (event) => {
  //     const { checked } = event.target;
  //     debugger
  //     if (checked) {
  //         setCheckedItem((prev) => [...prev, rowIndex])
  //     }

  //     if (!checked) {
  //         setCheckedItem(prev => prev = [prev.filter((item) => item !== rowIndex)])
  //     }

  //     setFormData(prev => {
  //         const updatedRows = [...prev.rows];
  //         updatedRows[rowIndex] = {
  //             ...updatedRows[rowIndex],
  //             [checkboxType]: checked
  //         };
  //         return { rows: updatedRows };
  //     });
  //     console.log(checkedItem)
  // };
  // console.log(setFormData);

  // const handleMasterCheckboxChange = (event) => {
  //     const isChecked = event.target.checked;
  //     setCheckedItem(isChecked ? formData.rows.map((_, index) => index) : []);

  //     // Update all rows' showOrHide property based on the master checkbox state
  //     formData.rows.forEach((row, index) => {
  //         row.showOrHide = isChecked;
  //     });
  // };

  // const handleCheckboxChange = (index, field) => (event) => {
  //     const isChecked = event.target.checked;
  //     const updatedCheckedItem = isChecked
  //         ? [...checkedItem, index]
  //         : checkedItem.filter(item => item !== index);

  //     setCheckedItem(updatedCheckedItem);

  //     // Update the specific row's property
  //     formData.rows[index][field] = isChecked;
  // };

  const handleCheckboxChangeWrapper = (index, field) => (event) => {
    debugger;
    const isChecked = event.target.checked;

    // Update the checked items array
    if (isChecked) {
      setCheckedItem((prevCheckedItem) => [...prevCheckedItem, index]);
    } else {
      setCheckedItem((prevCheckedItem) =>
        prevCheckedItem.filter((item) => item !== index)
      );
      console.log(checkedItem);
    }

    // setFormData(prev => {
    //     const updatedRows = [...prev.rows];
    //     updatedRows[rowIndex] = {
    //         ...updatedRows[rowIndex],
    //         [checkboxType]: checked
    //     };
    //     return { rows: updatedRows };
    // });

    // Assuming you're also updating the row's state
    formData.rows[index][field] = isChecked;
  };

  const handleFormData = (rowIndex, field) => (event) => {
    event.stopPropagation();

    const value = event.target.value;

    console.log(rows.Sub_Query);

    // Update form data
    setFormData((prev) => {
      const updatedRows = [...prev.rows];
      updatedRows[rowIndex] = { ...updatedRows[rowIndex], [field]: value };

      if (field === "columnDataType") {
        const isIntegerOrFloat = value === "Integer" || value === "Float";
        updatedRows[rowIndex].isDisabled = !isIntegerOrFloat;

        if (!isIntegerOrFloat) {
          // alert("Please select Integer or Float to enable the checkbox.");
          updatedRows[rowIndex].columnShowTotalValue = false;
        }
      }

      return { rows: updatedRows };
    });
  };

  const SaveBtn = async () => {
    debugger;
    // let allValid = true;
    // fieldNames.forEach((fieldName) => {
    //     const isValid = validateInput(fieldName);
    //     if (!isValid) {
    //         allValid = false;
    //     }
    // });
    if (true) {
      setLoading(true);
      debugger;
      if (Boolean(modelOpen) === true) {
        const isSuccess = await TemplateSettingApi.updateTemplateSetting(
          inputValues,
          formData
        ).catch((err) => console.log(err));
        if (isSuccess) {
          OpenAlertBoxEdit();
          fetchData();
          setLoading(false);
          setSliderCallBack(false);
          // window.location.reload();
        } else {
          alert("error while updating");
        }
      } else {
        setLoading(true);
        const isSuccess = await TemplateSettingApi.insertTemplateSetting(
          inputValues,
          formData
        );
        if (isSuccess) {
          // fetchData();
          // fetchData()
          setLoading(false);
          OpenAlertBox();
        } else {
          alert("Error inserting data");
        }
      }
      // props.fetchData()
      setInputValues({
        reportName: "",
        queryForSql: "",
        queryForSqlite: "",
        queryForMySQL: "",
        reportAttachTo: "",
        pageLink: "",
        reportType: "",
        pagination: "",
        search_ByDate: "",
        search_AnyText: "",
        search_ByColumn: "",
        pagination: "",
        orderByButton: "",
        firmNameSelect: "",
        transactionTypeSelect: "",
        billSeriesNameSelect: "",
        printButton: "",
        excelButton: "",
        shareButton: "",
        rowWise_Edit: "",
        rowWise_Print: "",
        rowWise_Whatsapp: "",
        rowWise_Delete: "",
        rowWise_ArrowForOtherPage: "",
        rowWise_TransporterOrOther: "",
        srch1: "",
        srch2: "",
        srch3: "",
        srch4: "",
        eF1: "",
        eF2: "",
      });

      setFormData({
        rows: rows.map((data) => ({
          showOrHide: false,
          columnName: data.columnName,
          columnDataType: "",
          columnNameByUser: "",
          columnShowTotalValue: false,
          columnSelectBoxWithSaveBtn: "",
          col1: data.columnName,
          col2: "",
          col3: "",
          col4: "",
          Sub_Query: "",
        })),
      });
    }
  };

  useEffect(() => {
    const fetchData = () => {
      if (editData) {
        try {
          debugger;
          const data = editData;
          console.log(data);
          setInputValues(data.templateSettingDTO ?? {});
          if (data.templateSettingDTO.reportType == 3) {
            setFieldName([
              "Show/Hide",
              "Column Name",
              "Data Type",
              "Sub-Query",
              "Column Name By User",
              "Apply Query",
              "Param Key",
              "Select Database",
              "Priority",
            ]);

            SetFieldValue([
              {
                value: "",
                name: "--Select DB--",
              },
              {
                value: "1",
                name: "DB1",
              },
              {
                value: "2",
                name: "DB2",
              },
              {
                value: "3",
                name: "Both",
              },
            ]);
            setshowImportDonwload(true);
          } else {
            setshowImportDonwload(false);
          }
          data.templateSettingReportConfigList.map((item, index) => {
            setCheckedItem((prev) => [...prev, index]);
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    editData != undefined && fetchData();
  }, [editData]);

  useEffect(() => {
    const fetchData1 = () => {
      if (editData) {
        try {
          const data = editData;
          console.log("Fetched data:", data);

          // Assuming templateSettingReportConfigList is an array of objects with rows
          const rows =
            data.templateSettingReportConfigList?.map((item) => ({
              showOrHide: item.showOrHide ?? false,
              columnName: item.columnName ?? "",
              columnDataType: item.columnDataType ?? "",
              columnNameByUser:
                item.columnNameByUser != ""
                  ? item.columnNameByUser
                  : item.columnName,
              columnShowTotalValue:
                item.columnShowTotalValue == "true" ? true : false,
              columnSelectBoxWithSaveBtn: item.columnSelectBoxWithSaveBtn ?? "",
              col1: item.col1.trim() != "" ? item.col1 : item.columnName,
              col2: item.col2 ?? "",
              col3: item.col3 ?? "",
              col4: item.col4 ?? "",
              Sub_Query: item.Sub_Query || "",
            })) ?? [];

          // Set formData with the expected structure
          setFormData({ rows });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData1();
  }, [editData]);

  const [isModalOpenFixed, setModalOpenFixed] = useState(false);
  const showOpenFixed = () => {
    setModalOpenFixed(!isModalOpenFixed);
  };

  const hideModalFixed = () => {
    setModalOpenFixed(false);
  };

  // ----------------------------HANDLECOLUMNCREATE--------------------------------

  // -----------------------------------handle csv import--------------------------------
  const [CSVImportModal, setCSVImportModal] = useState(false);
  const handleCSVimport = async () => {
    setCSVImportModal(true);
    // setI
  };

  const handleallclick = (alldata, e) => {
    debugger;
    console.log(alldata);
    if (e?.target.checked) {
      alldata.map((item, index) => {
        setCheckedItem((prev) => (prev = [...prev, index]));
        formData.rows[index].showOrHide = true;
      });
      console.log(checkedItem);
    } else {
      e != undefined && setCheckedItem([]);
    }
  };

  const [querydata, setQuery] = useState("");
  const handleCheckboxChange = (index) => (event) => {
    const isChecked = event.target.checked;

    debugger;
    if (inputValues.reportType == "3") {
      if (isChecked && inputValues.reportType == "3") {
        const param =
          'select id from masters1 where lower(name)=lower("@parm")'; // Replace with your dynamic parameter            // setIsChecked(!isChecked);
        formData.rows[index].columnNameByUser = param;
        // setQuery(param);
      } else {
        formData.rows[index].columnNameByUser = "";
      }
    }
    if (inputValues.reportType == "1") {
      if (formData.rows[index].columnDataType != "String" && isChecked) {
        setFormData((prevData) => {
          const updatedRows = [...prevData.rows];

          if (isChecked) {
            // Update the row's columnNameByUser with the query
            // const query = 'select id from masters1 where lower(name)=lower("@parm")';
            // updatedRows[rowIndex].columnNameByUser = query;
            // row.columnDataType = 'String';
            formData.rows[index].columnShowTotalValue = true;
          } else {
            updatedRows[index].columnNameByUser = "";
            // row.columnDataType = editData.templateSettingReportConfigList[rowIndex].columnDataType
            formData.rows[index].columnShowTotalValue = false;
          }
          return { ...prevData, rows: updatedRows };
        });
        // formData.rows[index].columnShowTotalValue = true
      } else {
        setFormData((prevData) => {
          const updatedRows = [...prevData.rows];
          formData.rows[index].columnShowTotalValue = false;
          return { ...prevData, rows: updatedRows };
        });
        // formData.rows[index].columnShowTotalValue = false
      }
    }

    // Update the specific row's property
    // formData.rows[index][field] = isChecked;
  };

  console.log("edit data", editData);
  console.log(inputValues);
  console.log(Array(editData?.templateSettingReportConfigList)?.length);
  console.log(checkedItem);

  useEffect(() => {
    console.log(checkedItem);
  }, [checkedItem]);

  useEffect(() => {
    if (formData.rows) {
      handleallclick(formData.rows);
    }
  }, [formData]);

  return (
    <div>
      <PageTabs modelOpen={modelOpen} setSliderCallBack={setSliderCallBack} />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Report Template Setting</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          SaveButton={SaveBtn}
          isBtnSetting={false}
          showModal={showModal}
          modelOpen={modelOpen}
          setSliderCallBack={setSliderCallBack}
        />
      </div>

      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1 item_scroll">
                    <div className="control_radius">
                      <div className="company_info-text-im depart_top">
                        Report Template Setting
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="reportName"
                                value={inputValues.reportName}
                                required
                              />
                              <label class="modify_p_text2">Report Name</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="pageLink"
                                value={inputValues.pageLink}
                                required
                              />
                              <label class="modify_p_text2">Page Link</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2 flex-report-for">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="reportAttachTo"
                                value={inputValues.reportAttachTo}
                                required
                              >
                                <option value="">--Select--</option>
                                {/* <option value="2">Account Master</option>
                                                                <option value="4">Item Master</option>
                                                                <option value="">--Select--</option> */}
                                {options.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                              <label class="modify_lbl_text3">Report For</label>
                            </div>
                            <div className="">
                              <i
                                onClick={showOpenFixed}
                                class="fa-solid fa-circle-plus dm_4"
                              ></i>
                            </div>
                          </div>
                        </div>
                        {isModalOpenFixed && (
                          <div>
                            <div className="modal">
                              <div className="OpticalField-modal Report-template-model">
                                <div className="modal-title-Report">
                                  <h3 className="Report-Fixed-Header">
                                    Add Masters
                                  </h3>
                                  <span
                                    className="close_modal"
                                    onClick={hideModalFixed}
                                  >
                                    &times;
                                  </span>
                                </div>
                                <div className="tab-content-modal">
                                  <div className="company-info-top-modal control_flex_modal item_scroll">
                                    <ModelReportFor
                                      onMasterIdChange={handleMasterIdChange}
                                      onCloseModal={hideModalFixed}
                                      // initialMasterId={currentMasterId}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="reportType"
                                value={inputValues.reportType}
                                required
                              >
                                <option>--Select Report Type--</option>
                                <option value="1">Master List Type</option>
                                <option value="2">Transaction List Type</option>
                                <option value="3">Import Export Type</option>
                                <option value="4">
                                  Ledger Detail List Type
                                </option>
                              </select>
                              <label class="modify_lbl_text3">
                                Report Type
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="tabs">
                        <div
                          className="tab-btn-box"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              id="firstTab"
                              className={`tab  ${
                                activeTab === 3 ? "active" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(3);
                                showtabs1();
                              }}
                            >
                              Query For Sql
                            </div>
                            <div
                              className={`tab ${
                                activeTab === 1 ? "active" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(1);
                                showtabs2();
                              }}
                            >
                              Query For MySql
                            </div>
                            <div
                              className={`tab ${
                                activeTab === 2 ? "active" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(2);
                                showtabs3();
                              }}
                            >
                              Query For Sqllite
                            </div>
                          </div>
                        </div>
                        <button
                          className={`fill-to-all`}
                          type="button"
                          onClick={(e) => handleClickfillquery(e)}
                          style={{ padding: "0px 8px" }}
                        >
                          Copy To All
                        </button>
                        {showMessage && (
                          <div className="success-message">Copied ✅</div>
                        )}
                        {tabcontent1 && (
                          <div className="Coupon-General-Top">
                            <div>
                              <div className="input-box-tab-head Sms-Text-Box">
                                <div className="input-box-m2">
                                  <textarea
                                    className="modify_im_textarea mode_discharge Report-temp-height"
                                    name="queryForSql"
                                    onChange={handleInputChange}
                                    value={inputValues.queryForSql}
                                    // type="text"
                                    placeholder="Enter SQL query here"
                                    rows="10"
                                    required
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      name="eF1"
                                      style={{ padding: "5px" }}
                                      onChange={handleInputChange}
                                      value={inputValues.eF1}
                                      type="text"
                                      placeholder="Enter Master Type"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      style={{ padding: "5px" }}
                                      name="eF2"
                                      onChange={handleInputChange}
                                      value={inputValues.eF2}
                                      type="text"
                                      placeholder="Enter Order By"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="execut-btn"
                              onClick={handleFormSubmit}
                            >
                              Execute Query
                            </button>
                          </div>
                        )}

                        {tabcontent2 && (
                          <div className="Coupon-General-Top">
                            <div>
                              <div className="input-box-tab-head Sms-Text-Box">
                                <div className="input-box-m2">
                                  <textarea
                                    className="modify_im_textarea mode_discharge Report-temp-height"
                                    name="queryForMySQL"
                                    onChange={handleInputChange}
                                    value={inputValues.queryForMySQL}
                                    // type="text"
                                    placeholder="Enter MYSQL query here"
                                    rows="10"
                                    required
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      name="eF1"
                                      style={{ padding: "5px" }}
                                      onChange={handleInputChange}
                                      value={inputValues.eF1}
                                      type="text"
                                      placeholder="Enter Master Type"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      style={{ padding: "5px" }}
                                      name="eF2"
                                      onChange={handleInputChange}
                                      value={inputValues.eF2}
                                      type="text"
                                      placeholder="Enter Order By"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="execut-btn"
                              onClick={handleFormSubmit}
                            >
                              Execute Query
                            </button>
                          </div>
                        )}
                        {tabcontent3 && (
                          <div className="Coupon-General-Top">
                            <div>
                              <div className="input-box-tab-head Sms-Text-Box">
                                <div className="input-box-m2">
                                  <textarea
                                    className="modify_im_textarea mode_discharge Report-temp-height"
                                    name="queryForSqlite"
                                    onChange={handleInputChange}
                                    value={inputValues.queryForSqlite}
                                    // type="text"
                                    placeholder="Enter SQLLite query here"
                                    rows="10"
                                    required
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      name="eF1"
                                      style={{ padding: "5px" }}
                                      onChange={handleInputChange}
                                      value={inputValues.eF1}
                                      type="text"
                                      placeholder="Enter Master Type"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="input-box-tab-head Sms-Text-Box">
                                  <div
                                    className="input-box-m2"
                                    style={{ marginBottom: "12px" }}
                                  >
                                    <input
                                      className="modify_im_textarea mode_discharge Report-temp-height"
                                      style={{ padding: "5px" }}
                                      name="eF2"
                                      onChange={handleInputChange}
                                      value={inputValues.eF2}
                                      type="text"
                                      placeholder="Enter Order By"
                                      rows="10"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              className="execut-btn"
                              onClick={handleFormSubmit}
                            >
                              Execute Query
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="company_info-text-im flex-import-export">
                        <p className={`column_name`}>Column Name</p>
                        {showImportDonwload && (
                          <div className={`div-for-column-header-btn`}>
                            <button
                              className="add-csv-btn"
                              onClick={handleCSVimport}
                            >
                              Import CSV
                            </button>
                            <CsvDownload
                              data={[
                                formData.rows.map(
                                  (item) => item.col1 || item.columnName
                                ),
                              ]}
                              filename={"CSVFile"}
                            />
                          </div>
                        )}
                        {CSVImportModal ? (
                          <ImportCsv
                            option={options}
                            ReportAttachto={inputValues.reportAttachTo}
                            isOpen={CSVImportModal}
                            onClose={setCSVImportModal}
                          />
                        ) : null}
                      </div>

                      <table className="Table-bottom-Column">
                        <thead className="thead-groupmaster">
                          <tr>
                            {FieldName.map((field, index) => {
                              return (
                                <>
                                  <th
                                    className="table-th"
                                    style={{
                                      width: `${
                                        index == 3
                                          ? "25%"
                                          : index == 6
                                          ? "8%"
                                          : null
                                      } `,
                                    }}
                                  >
                                    <div className={`show_hide`}>
                                      {field}
                                      {field == "Show/Hide" && (
                                        <input
                                          onClick={(e) =>
                                            handleallclick(formData.rows, e)
                                          }
                                          checked={
                                            checkedItem.length ===
                                            formData.rows.length
                                          }
                                          className="checkbox-report"
                                          type="checkbox"
                                        />
                                      )}
                                    </div>
                                  </th>
                                </>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {formData.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                              <td style={{ textAlign: "center" }}>
                                <input
                                  className="checkbox-report"
                                  type="checkbox"
                                  defaultChecked={true}
                                  checked={
                                    row.showOrHide &&
                                    checkedItem.includes(rowIndex)
                                  }
                                  onClick={handleCheckboxChangeWrapper(
                                    rowIndex,
                                    "showOrHide"
                                  )}
                                />
                              </td>
                              <td className="TD-center-column">
                                {row.columnName}
                              </td>
                              <td>
                                <input
                                  className="table-input-12"
                                  style={{
                                    marginLeft: "10px",
                                    outline: "none",
                                  }}
                                  type="text"
                                  onChange={handleFormData(
                                    rowIndex,
                                    "columnDataType"
                                  )}
                                  value={row.columnDataType}
                                  name="columnNameByUser"
                                />
                              </td>
                              <td>
                                <textarea
                                  className="table-input-12 input-textarea"
                                  style={{ marginLeft: "10px", padding: "4px" }}
                                  onChange={handleFormData(
                                    rowIndex,
                                    "columnNameByUser"
                                  )}
                                  value={row.columnNameByUser}
                                  name={
                                    FieldValue.includes("Sub-Query")
                                      ? "Sub_Query"
                                      : "columnNameByUser"
                                  }
                                />
                              </td>
                              {inputValues.reportType == 3 && (
                                <td>
                                  <textarea
                                    className="table-input-12 input-textarea"
                                    style={{
                                      marginLeft: "10px",
                                      padding: "4px",
                                    }}
                                    onChange={handleFormData(rowIndex, "col1")}
                                    value={row.col1}
                                    name={"col1"} //report tpye 3 column Name by User
                                  />
                                </td>
                              )}
                              <td style={{ textAlign: "center" }}>
                                <input
                                  className="checkbox-report"
                                  type="checkbox"
                                  checked={row.columnShowTotalValue}
                                  onChange={(e) => {
                                    if (inputValues.reportType == "3") {
                                      setFormData((prevData) => {
                                        const updatedRows = [...prevData.rows];
                                        if (e.target.checked) {
                                          // Update the row's columnNameByUser with the query
                                          const query =
                                            "select id from masters1 where lower(name)=lower('@parm')";
                                          updatedRows[
                                            rowIndex
                                          ].columnNameByUser = query;
                                          row.columnDataType = "String";
                                          row.columnShowTotalValue = true;
                                        } else {
                                          updatedRows[
                                            rowIndex
                                          ].columnNameByUser = "";
                                          if (editData != undefined) {
                                            row.columnDataType =
                                              editData?.templateSettingReportConfigList[
                                                rowIndex
                                              ]?.columnDataType;
                                          } else {
                                            row.columnDataType =
                                              ColumnNames[row.columnName];
                                          }
                                          row.columnShowTotalValue = false;
                                        }
                                        return {
                                          ...prevData,
                                          rows: updatedRows,
                                        };
                                      });
                                    } else {
                                      handleCheckboxChange(
                                        rowIndex,
                                        "columnShowTotalValue"
                                      )(e);
                                    }
                                  }}
                                />
                              </td>

                              <td>
                                <select
                                  className="table-input-12 select-data-type"
                                  name="col2"
                                  value={row.col2}
                                  onChange={handleFormData(rowIndex, "col2")}
                                >
                                  <option value="">----Select----</option>
                                  {formData.rows.map((row, index) => (
                                    <option key={index} value={row.col1}>
                                      {row.col1}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              <td>
                                <select
                                  className="table-input-12 select-data-type"
                                  name="columnSelectBoxWithSaveBtn"
                                  value={row.columnSelectBoxWithSaveBtn}
                                  onChange={handleFormData(
                                    rowIndex,
                                    "columnSelectBoxWithSaveBtn"
                                  )}
                                >
                                  {FieldValue.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  className="table-input-12"
                                  type="number"
                                  style={{ marginLeft: "10px" }}
                                  onChange={handleFormData(rowIndex, "col1")}
                                  value={row.col1}
                                  name="col1"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div onClick={handleAddRow}></div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <input
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="number"
                                name="pagination"
                                value={inputValues.pagination}
                                required
                              />
                              <label class="modify_p_text2">Pagination</label>
                            </div>
                          </div>
                        </div>
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1" ref={dropdownRef}>
                              <div
                                className="modify_im_input mode_category1"
                                onClick={toggleDropdown}
                              >
                                <label className="Campaigns-lbl">
                                  Search By Coulmn
                                </label>
                              </div>
                              {isOpen && (
                                <div className="dropdown-list-select">
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("search_ByDate")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.search_ByDate}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "search_ByDate"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("search_ByDate")
                                      }
                                    >
                                      Search By Date
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("search_AnyText")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.search_AnyText}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "search_AnyText"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("search_AnyText")
                                      }
                                    >
                                      Search By Any Text
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "search_ByColumn"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.search_ByColumn}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "search_ByColumn"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("search_ByColumn")
                                      }
                                    >
                                      Search By Column
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("orderByButton")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.orderByButton}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "orderByButton"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("orderByButton")
                                      }
                                    >
                                      Order By Button
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("firmNameSelect")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.firmNameSelect}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "firmNameSelect"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("firmNameSelect")
                                      }
                                    >
                                      Firm Name Select
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "transactionTypeSelect"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        inputValues.transactionTypeSelect
                                      }
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "transactionTypeSelect"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick(
                                          "transactionTypeSelect"
                                        )
                                      }
                                    >
                                      Transaction Type Select
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "billSeriesNameSelect"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.billSeriesNameSelect}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "billSeriesNameSelect"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick(
                                          "billSeriesNameSelect"
                                        )
                                      }
                                    >
                                      Bill Series Name Select
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("printButton")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.printButton}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop("printButton")
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("printButton")
                                      }
                                    >
                                      Print Button
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("excelButton")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.excelButton}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop("excelButton")
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("excelButton")
                                      }
                                    >
                                      Excel Export Button
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("shareButton")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.shareButton}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop("shareButton")
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("shareButton")
                                      }
                                    >
                                      Share Button
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("rowWise_Edit")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.rowWise_Edit}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop("rowWise_Edit")
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("rowWise_Edit")
                                      }
                                    >
                                      Row-wise Edit{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("rowWise_Print")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.rowWise_Print}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "rowWise_Print"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("rowWise_Print")
                                      }
                                    >
                                      Row-wise Print{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "rowWise_Whatsapp"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.rowWise_Whatsapp}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "rowWise_Whatsapp"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("rowWise_Whatsapp")
                                      }
                                    >
                                      Row-wise Whatsapp{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("rowWise_Delete")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.rowWise_Delete}
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "rowWise_Delete"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick("rowWise_Delete")
                                      }
                                    >
                                      Row-wise Delete{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "rowWise_ArrowForOtherPage"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        inputValues.rowWise_ArrowForOtherPage
                                      }
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "rowWise_ArrowForOtherPage"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick(
                                          "rowWise_ArrowForOtherPage"
                                        )
                                      }
                                    >
                                      Row-wise Arrow for Navigation to Another
                                      Page{" "}
                                    </span>
                                  </div>
                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop(
                                        "rowWise_TransporterOrOther"
                                      )
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        inputValues.rowWise_TransporterOrOther
                                      }
                                      // onChange={() =>
                                      //   handleCheckboxChangeDrop(
                                      //     "rowWise_TransporterOrOther"
                                      //   )
                                      // }
                                    />
                                    <span
                                      onClick={() =>
                                        handleOptionClick(
                                          "rowWise_TransporterOrOther"
                                        )
                                      }
                                    >
                                      Row-wise Button to Open Popup Window{" "}
                                    </span>
                                  </div>

                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("srch1")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.srch1}
                                    />
                                    <span
                                      onClick={() => handleOptionClick("srch1")}
                                    >
                                      Row-wise Double Click Enable{" "}
                                    </span>
                                  </div>

                                  <div
                                    className="dropdown-option"
                                    onClick={() =>
                                      handleCheckboxChangeDrop("srch2")
                                    }
                                  >
                                    <input
                                      type="checkbox"
                                      checked={inputValues.srch2}
                                    />
                                    <span
                                      onClick={() => handleOptionClick("srch2")}
                                    >
                                      Show Transaction Type Dropdown{" "}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        {inputValues.srch1 && (
                          <div className="stock-im-dep1">
                            <div className="input-box-Imo1 input-height2 flex-report-for">
                              <div className="input-box-mok1">
                                <select
                                  className="modify_im_input mode_category1"
                                  onChange={handleNavigationChange}
                                  type="text"
                                  name="reportAttachTo"
                                  value={inputValues.eF3}
                                >
                                  <option value="">--Select--</option>
                                  {options.map((option, index) => (
                                    <option key={index} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                <label class="modify_lbl_text3">
                                  Navigate To
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3">
                <i class="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          // onYesClick={DeleteLoginTypeConfirm}
        />
      )}
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Template Setting is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Template Setting is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}

      {/* {loading && <Loader />} */}
      {/* <MasterListReport inputValues={inputValues}/> */}
    </div>
  );
}

export default ReportTemplateSetting;
