import { useEffect, useState } from "react";
import Transaction from "../../API/APIServices/Transaction";
function ItemWiseBatchList({
  handleModalClick,
  enableBatchNoEntry,
  hideModal6,
  itemWiseBatchOpen,
  ProductOpnedInPopup,
  BatchNoData,
  setBatchNoData,
  itemMasterRows,
  setItemMasterRows,
  setInputValues,
  onChangeProductSaveMethod,
  inputValues,
}) {
  const [ShowBatchNo, setShowBatchNo] = useState(true);
  const BatchNo = () => {
    setShowBatchNo(!ShowBatchNo);
  };

  const [ShowMCentre, setShowMCentre] = useState(false);
  const MCentre = () => {
    setShowMCentre(!ShowMCentre);
  };
  const [showMfgDt, setShowMfgDt] = useState(true);
  const MfgDt = () => {
    setShowMfgDt(!showMfgDt);
  };
  const [showExpDt, setShowExpDt] = useState(true);
  const ExpDt = () => {
    setShowExpDt(!showExpDt);
  };

  const [showqty, setshowqty] = useState(true);
  const qty = () => {
    setshowqty(!showqty);
  };
  const [showPTS, setShowPTS] = useState(false);
  const PTS = () => {
    setShowPTS(!showPTS);
  };
  const [showPTR, setShowPTR] = useState(false);
  const PTR = () => {
    setShowPTR(!showPTR);
  };
  const [showMRP, setShowMRP] = useState(false);
  const MRP = () => {
    setShowMRP(!showMRP);
  };
  const [showCost, setShowCost] = useState(false);
  const Cost = () => {
    setShowCost(!showCost);
  };
  const [showOpPacks, setShowOpPacks] = useState(false);
  const OpPacks = () => {
    setShowOpPacks(!showOpPacks);
  };
  const [showOpLoose, setShowOpLoose] = useState(false);
  const OpLoose = () => {
    setShowOpLoose(!showOpLoose);
  };
  const [showOpValue, setShowOpValue] = useState(false);
  const OpValue = () => {
    setShowOpValue(!showOpValue);
  };

  const [TopSetting, setTopSetting] = useState(false);
  const showSetting = () => {
    setTopSetting(true);
  };
  const hideSetting = () => {
    setTopSetting(false);
  };
  // debugger;
  // const [isBtnChallan,] = useState(props.isBtnChallan);

  //**************************{Table}*****************************

  const [taxRows, setTaxRows] = useState([
    { id: 1, TaxName: "", Percent: "", Amount: "" },
    { id: 2, TaxName: "", Percent: "", Amount: "" },
    { id: 3, TaxName: "", Percent: "", Amount: "" },
    { id: 4, TaxName: "", Percent: "", Amount: "" },
    { id: 5, TaxName: "", Percent: "", Amount: "" },
  ]);

  const handleKeyPress = (event) => {
    // Check if both Alt key and X key are pressed
    if (event.key === "Escape") {
      itemWiseBatchOpen(ProductOpnedInPopup.rowIndex);
    }
  };

  useEffect(() => {
    // Add event listener when component is mounted
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener when component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleCellKeyDown = (event, rowIndex) => {
    if (event.key === "Enter") {
      const newRow = { id: taxRows.length + 1, content: "" };
      setTaxRows([...taxRows, newRow]);
      const nextRowIndex = rowIndex + 1;
      const nextRowCellId = `cell-${nextRowIndex}-0`;
      const nextRowCell = document.getElementById(nextRowCellId);
      if (nextRowCell) {
        nextRowCell.focus();
      }
    }
  };

  const [TotalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const totalQuantity =
      BatchNoData &&
      BatchNoData.reduce((sum, batch) => sum + Number(batch.qty), 0);
    setTotalCount(totalQuantity);
  }, [BatchNoData]);

  const deleteTaxRow = (rowIndex) => {
    if (BatchNoData.length > 1) {
      // Ensure at least one row remains
      const updatedRows = BatchNoData.filter((_, index) => index !== rowIndex);
      setBatchNoData(updatedRows);
    } else {
      alert("Cannot delete the last remaining row.");
    }
  };

  const addBatchRow = () => {
    const newRow = {
      id: 0,
      batchNo: "",
      mgfDate: "",
      expDate: "",
      qty: 0,
      purPrice: 0,
      salePrice: 0,
      mrp: 0,
      costPrice: 0,
      opPacks: 0,
      opLoose: 0,
      opValue: 0,
      vc1: "",
      vc2: "",
      vc3: "",
      vc4: "",
      vc5: "",
      vc6: "",
      vc7: "",
      vc8: "",
      vc9: "",
      vc10: "",
    };

    setBatchNoData((prevRows) => [...prevRows, newRow]);
  };

  const handleInputChange = (index, field, value) => {
    debugger;
    if (field == "qty") {
      if (Number(value) < 0) {
        alert("Quantity cannot be negative");
        return;
      }
      if (enableBatchNoEntry == "1") {
        if (Number(value) > Number(BatchNoData[index].AvaliableQty)) {
          alert("Quantity cannot be greater than available quantity");
          return;
        }
      }
      if (Number(value) > Number(ProductOpnedInPopup?.Qty)) {
        alert("Quantity cannot be greater than Buying Quantity");
        return;
      }
    }

    setBatchNoData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };
  useEffect(() => {
    console.log(BatchNoData);
  }, [BatchNoData]);

  useEffect(() => {
    if (enableBatchNoEntry == "2") {
      fetchDataForBatchNoAutoGeneration();
    }
  }, [BatchNoData.qty]);

  useEffect(() => {
    if (enableBatchNoEntry == "1") {
      fetchDataForBatchlNo();
    }
  }, [BatchNoData.qty]);

  const fetchDataForBatchlNo = async () => {
    debugger;
    if (ProductOpnedInPopup) {
      var filterData = {
        isSummary: true,
        codeType: 3,
        itmId_d2: [ProductOpnedInPopup.d2_Id],
        codeNo: "",
        mgfDate: "",
        expDate: "",
        invoiceId: [],
        vendorId_d2: [],
        pageNumber: 0,
        pagesize: 0,
      };

      var data = await Transaction.GetBarcodeSerialBatchNo(filterData);
      if (data && data.length > 0) {
        // Use a functional update to ensure you access the latest state of setBatchNoData
        setBatchNoData((prevBatchNoData) => {
          var newRows = []; // Initialize the array

          for (var i = 0; i < data.length; i++) {
            // Find the existing row in the previous state using the id
            const existingRow = prevBatchNoData?.find(
              (row) => row.id === data[i].id
            );

            // Create a new row object for each item in the data array
            var newRow = {
              id: data[i].id,
              batchNo: data[i].codeNo,
              mgfDate: data[i].mgfDate || data[i].mfgDate,
              expDate: data[i].expDate,
              qty: existingRow ? existingRow.qty : 0, // Retain qty from previous state if available
              purPrice: data[i].purPrice,
              salePrice: data[i].salePrice,
              mrp: data[i].mrp,
              costPrice: data[i].costPrice,
              opPacks: data[i].opPacks,
              opLoose: data[i].opLoose,
              opValue: data[i].opValue,
              AvaliableQty: data[i].qty,
              vc1: "",
              vc2: "",
              vc3: "",
              vc4: "",
              vc5: "",
              vc6: "",
              vc7: "",
              vc8: "",
              vc9: "",
              vc10: "",
            };

            // Push the new row object into the newRows array
            newRows.push(newRow);
          }

          return newRows; // Return the updated newRows array
        });
      }
    }

    if (inputValues.transId && inputValues.transId != 0) {
      var filterData = {
        isSummary: true,
        codeType: 3,
        itmId_d2: [],
        codeNo: "",
        mgfDate: "",
        expDate: "",
        invoiceId: [inputValues.transId],
        vendorId_d2: [],
        pageNumber: 0,
        pagesize: 0,
      };

      var data = await Transaction.GetBarcodeSerialBatchNo(filterData);
      if (data && data.length > 0) {
        setBatchNoData((prevBatchNoData) => {
          const updatedData = [...prevBatchNoData]; // Copy previous data
          data.forEach((item) => {
            // Find if the batchNo already exists in the previous data
            const existingRowIndex = updatedData.findIndex(
              (row) => row.batchNo === item.codeNo
            );

            if (existingRowIndex !== -1) {
              // If batchNo exists, update the qty by adding the new qty to the existing qty
              updatedData[existingRowIndex].qty += item.qty;
              updatedData[existingRowIndex].AvaliableQty += item.qty;
            } else {
              // If batchNo does not exist, add the new row
              const newRow = {
                id: item.id,
                batchNo: item.codeNo,
                mgfDate: item.mgfDate || item.mfgDate,
                expDate: item.expDate,
                qty: item.qty,
                purPrice: item.purPrice,
                salePrice: item.salePrice,
                mrp: item.mrp,
                costPrice: item.costPrice,
                opPacks: item.opPacks,
                opLoose: item.opLoose,
                opValue: item.opValue,
                AvaliableQty: item.qty,
                vc1: "",
                vc2: "",
                vc3: "",
                vc4: "",
                vc5: "",
                vc6: "",
                vc7: "",
                vc8: "",
                vc9: "",
                vc10: "",
              };
              updatedData.push(newRow);
            }
          });
          return updatedData; // Return the updated data
        });
      }
    }
  };

  const fetchDataForBatchNoAutoGeneration = () => {
    debugger;
    if (ProductOpnedInPopup.batchNos?.length != 0) {
      if (ProductOpnedInPopup.batchNos) {
        setBatchNoData(ProductOpnedInPopup.batchNos);
      } else {
        setBatchNoData(ProductOpnedInPopup?.BatchDetails);
      }
    } else if (ProductOpnedInPopup?.Qty) {
      setBatchNoData(
        Array.from({ length: 4 }, (_, index) => ({
          id: 0,
          batchNo: "",
          mgfDate: "",
          expDate: "",
          qty: 0,
          purPrice: 0,
          salePrice: 0,
          mrp: 0,
          costPrice: 0,
          opPacks: 0,
          opLoose: 0,
          opValue: 0,
          vc1: "",
          vc2: "",
          vc3: "",
          vc4: "",
          vc5: "",
          vc6: "",
          vc7: "",
          vc8: "",
          vc9: "",
          vc10: "",
          checked: false,
          AvaliableQty: 0,
        }))
      );
    } else if (ProductOpnedInPopup?.OpeningStock) {
      setBatchNoData(
        Array.from({ length: 4 }, (_, index) => ({
          id: 0,
          batchNo: "",
          mgfDate: "",
          expDate: "",
          qty: 0,
          purPrice: 0,
          salePrice: 0,
          mrp: 0,
          costPrice: 0,
          opPacks: 0,
          opLoose: 0,
          opValue: 0,
          vc1: "",
          vc2: "",
          vc3: "",
          vc4: "",
          vc5: "",
          vc6: "",
          vc7: "",
          vc8: "",
          vc9: "",
          vc10: "",
          checked: false,
          AvaliableQty: 0,
        }))
      );
    }
  };

  useEffect(() => {
    debugger;
    if (enableBatchNoEntry == "1") {
      if (BatchNoData) {
        if (setItemMasterRows) {
          var FilteredBatchNoData = BatchNoData?.filter(
            (item) =>
              item.batchNo.trim() !== "" && item.qty !== "" && item.qty != 0
          );
          console.log(FilteredBatchNoData);
          setItemMasterRows((prevRows) => {
            return prevRows.map((row, idx) => {
              if (idx + 1 == ProductOpnedInPopup.rowIndex) {
                return { ...row, batchNos: FilteredBatchNoData };
              }
              return row;
            });
          });
        } else {
          var FilteredBatchNoData = BatchNoData;
          setInputValues((prev) => ({
            ...prev,
            BatchDetails: FilteredBatchNoData,
          }));
        }
      }
    } else if (enableBatchNoEntry == "2") {
      if (BatchNoData) {
        if (setItemMasterRows) {
          var FilteredBatchNoData = BatchNoData?.filter(
            (item) => item.batchNo.trim() !== ""
          );
          console.log(FilteredBatchNoData);
          setItemMasterRows((prevRows) => {
            return prevRows.map((row, idx) => {
              if (idx + 1 == ProductOpnedInPopup.rowIndex) {
                return { ...row, batchNos: FilteredBatchNoData };
              }
              return row;
            });
          });
        } else {
          var FilteredBatchNoData = BatchNoData;
          setInputValues((prev) => ({
            ...prev,
            BatchDetails: FilteredBatchNoData,
          }));
        }
      }
    }
  }, [
    ProductOpnedInPopup?.Qty,
    ProductOpnedInPopup?.OpeningStock,
    BatchNoData,
  ]);

  useEffect(() => {
    console.log(BatchNoData);
  }, [BatchNoData]);

  function formatDateForInput(date) {
    // Check if it's already in YYYY-MM-DD format
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }

    // Check if it's in ISO format (e.g., 2024-10-01T00:00:00)
    if (/^\d{4}-\d{2}-\d{2}T/.test(date)) {
      return date.split("T")[0];
    }

    // Parse other formats (e.g., MM/DD/YYYY or DD-MM-YYYY)
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate)) {
      return parsedDate.toISOString().split("T")[0];
    }

    // If parsing fails, return an empty string
    return "";
  }

  return (
    <div>
      <div onClick={hideModal6} className="modal-back">
        <div onClick={handleModalClick} className="BatchWise-modal">
          <div style={{ padding: "15px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
              }}
            >
              <label>Item Wise Batch Details</label>
              <label
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={() => itemWiseBatchOpen(ProductOpnedInPopup.rowIndex)}
              >
                x
              </label>
            </div>

            <div
              className="PopUpSerialNumber-itemDetails"
              style={{ padding: "5px", height: "auto" }}
              S
            >
              <div className="PopUpSerialNumber-itemDetails-1">
                ITEM :- {ProductOpnedInPopup?.productName}
              </div>
              <div className="PopUpSerialNumber-itemDetails-1">
                Qty :- {ProductOpnedInPopup?.Qty}
              </div>
            </div>

            <div className="">
              <div>
                <table
                  className="PopUpSerialNumber-table"
                  style={{ width: "100%" }}
                >
                  <tbody className=" ">
                    <thead>
                      <tr>
                        {ShowBatchNo && <th className="">Batch No</th>}
                        {showMfgDt && <th className="">Mfg Dt</th>}
                        {showExpDt && <th className="">Exp Dt</th>}
                        {enableBatchNoEntry == "1" && (
                          <th className="">Avaliable Qty</th>
                        )}
                        {showqty && <th className="">QTY</th>}
                        {ShowMCentre && <th className="">M Centre</th>}
                        {showPTS && <th className="">PTS</th>}
                        {showPTR && <th className="">PTR</th>}
                        {showMRP && <th className="">MRP</th>}
                        {showCost && <th className="">Cost</th>}
                        {showOpPacks && <th className="">Op Packs</th>}
                        {showOpLoose && <th className="">Op Loose</th>}
                        {showOpValue && <th className="">Op Value</th>}
                        {/* <th className="table-th-4">Action</th> */}
                        <th className="" onClick={showSetting}>
                          <i
                            className="fa-solid fa-gear"
                            style={{ display: "block" }}
                          ></i>
                        </th>
                      </tr>
                    </thead>
                    {BatchNoData?.map((row, index) => (
                      <tr key={index}>
                        {ShowBatchNo && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=""
                              type="text"
                              name={row.BatchNo}
                              value={BatchNoData[index]?.batchNo || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "batchNo",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}

                        {showMfgDt && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=""
                              type="date"
                              name={row.MfgDt}
                              value={
                                BatchNoData[index]?.mgfDate
                                  ? formatDateForInput(
                                      BatchNoData[index]?.mgfDate
                                    )
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "mgfDate",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showExpDt && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=""
                              type="date"
                              name={row.ExpDt}
                              // value={BatchNoData[index]?.expDate || ""}
                              value={
                                BatchNoData[index]?.expDate
                                  ? formatDateForInput(
                                      BatchNoData[index]?.expDate
                                    )
                                  : ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "expDate",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {enableBatchNoEntry == "1" && (
                          <td className="">
                            {BatchNoData[index]?.AvaliableQty}
                          </td>
                        )}
                        {showExpDt && (
                          <td className="">
                            <input
                              className=" "
                              type="number"
                              name={row.ExpDt}
                              value={BatchNoData[index]?.qty || ""}
                              onChange={(e) =>
                                handleInputChange(index, "qty", e.target.value)
                              }
                            />
                          </td>
                        )}
                        {ShowMCentre && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=""
                              type="text"
                              name={row.MaterialCentre}
                              value={BatchNoData[index]?.MaterialCentre || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "MaterialCentre",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showPTS && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.purPrice}
                              value={BatchNoData[index]?.purPrice || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "purPrice",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showPTR && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.salePrice}
                              value={BatchNoData[index]?.salePrice || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "salePrice",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showMRP && (
                          <td className="" style={{ width: "110px" }}>
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.mrp}
                              value={BatchNoData[index]?.mrp || ""}
                              onChange={(e) =>
                                handleInputChange(index, "mrp", e.target.value)
                              }
                            />
                          </td>
                        )}
                        {showCost && (
                          <td className="" style={{ width: "110px" }}>
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.costPrice}
                              value={BatchNoData[index]?.costPrice || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "costPrice",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showOpPacks && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.opPacks}
                              value={BatchNoData[index]?.opPacks || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "opPacks",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showOpLoose && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.opLoose}
                              value={BatchNoData[index]?.opLoose || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "opLoose",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        )}
                        {showOpValue && (
                          <td className="">
                            <input
                              disabled={enableBatchNoEntry == "1"}
                              className=" "
                              type="text"
                              name={row.opValue}
                              value={BatchNoData[index]?.opValue || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "opValue",
                                  e.target.value
                                )
                              }
                              //   onChange={(e) => handleCellChange(e, rowIndex, 0)}
                              onKeyDown={(e) => handleCellKeyDown(e, index)}
                            />
                          </td>
                        )}
                        <td className="" style={{ textAlign: "center" }}>
                          {/* <button className="btn-table1" onClick={() => deleteTaxRow(rowIndex)}><i className="fa-solid fa-trash delete-btn"></i></button> */}
                          <i
                            onClick={() => deleteTaxRow(index)}
                            className="fa-solid fa-xmark delete-btn"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    padding: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  <div>Count:- {TotalCount}</div>
                  <div>OP Stock:-</div>
                  <div> Value:-</div>
                  <div>
                    {" "}
                    <button
                      style={{
                        borderRadius: "8px",
                        backgroundColor: "#5e709e",
                        color: "white",
                        padding: "2px 10px",
                        minWidth: "100px",
                      }}
                      className=""
                      onClick={addBatchRow}
                    >
                      {" "}
                      Add Rows{" "}
                    </button>
                  </div>
                </div>
                <div className="TableDownDiv">
                  <div>
                    <span>PTS = Price to Stock,</span>
                  </div>
                  <div>M Centre = Material Centre,</div>
                  <div>PTR = Price to Retailer,</div>
                  <div>MRP = Price Rate,</div>
                  <div>Cost = Purchase Price (+Exp).</div>
                </div>
              </div>
            </div>
            <div className="PopUpSerialNumber-buttonBox">
              {/* <button>Generate Auto items serial number</button> */}
              {/* <p>Press Alt + x to close Modal</p> */}
              <div>
                <button
                  onClick={() =>
                    itemWiseBatchOpen(ProductOpnedInPopup.rowIndex)
                  }
                  className="PopUpSerialNumber-button1"
                >
                  Close
                </button>

                <button
                  className="PopUpSerialNumber-button2"
                  onClick={() =>
                    onChangeProductSaveMethod(
                      "",
                      ProductOpnedInPopup.rowIndex,
                      "",
                      ProductOpnedInPopup.d2_Id
                    )
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        {TopSetting && (
          <div className="modal">
            <div className="ModalBoxProductSetting">
              <div className="modal-title">
                <h3 className="modal-h">Add Columns into Table</h3>
                <span className="close" onClick={hideSetting}>
                  &times;
                </span>
              </div>
              <div
                className="ModalContentModalSettingP"
                style={{ paddingTop: "5px" }}
              >
                <div className="gridview2">
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={BatchNo}
                        disabled={ShowBatchNo}
                        checked={ShowBatchNo}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Batch No</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={MCentre}
                        checked={ShowMCentre}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">M Centre</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={MfgDt}
                        disabled={showMfgDt}
                        checked={showMfgDt}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Mfg Dt</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={ExpDt}
                        disabled={showExpDt}
                        checked={showExpDt}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Exp Dt</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={qty}
                        // disabled={showPTS}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Qty</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={PTS}
                        // disabled={showPTS}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">PTS</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={PTR}
                        checked={showPTR}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">PTR</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={MRP}
                        // disabled={showMRP}
                        checked={showMRP}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">MRP</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={Cost}
                        checked={showCost}
                        // disabled={showCost}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Cost</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={OpPacks}
                        checked={showOpPacks}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Op Packs</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={OpLoose}
                        checked={showOpLoose}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Op Loose</label>
                    </div>
                  </div>
                  <div className="ModalBoxInputM5 ">
                    <div className="input-box3 size-a1">
                      <input
                        onChange={OpValue}
                        checked={showOpValue}
                        style={{ boxShadow: "none" }}
                        className="modify_product_checkbox"
                        type="checkbox"
                      />
                      <label className="modify_p_text_checkbox">Op Value</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemWiseBatchList;
