import React, { useContext, useState } from "react";
import { useEffect, useRef, useCallback } from "react";
import Account from "../../API/APIServices/Account.js";
import MyContext from "../MyContext.jsx";
import SundryDetailsDataForAddVoucher from "./SundryDetailsDataForAddVoucher.jsx";
import BillSundry from "../../API/APIServices/BillSundry.js";
import Transaction from "../../API/APIServices/Transaction.js";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex-end">
      {pages.map((page) => (
        <button
          className={`paginationButton ${
            currentPage === page ? "paginationActiveBtn" : ""
          }`}
          key={page}
          onClick={() => onPageChange(page)}
          disabled={currentPage === page}
        >
          {page}
        </button>
      ))}
    </div>
  );
};

function AddVoucherAccList(props) {
  /************Category box****************/
  const { debounce, companyData } = useContext(MyContext);
  const nextCellRefs = useRef([]);
  const [sundryList, setSundryList] = useState([]);

  const {
    VoucherData,
    handleInputChangeMain,
    setAddVouchersEnterAccountDetails,
    addVouchersEnterAccountDetails,
    setAddVoucherSundryDetail,
    addVoucherSundryDetail,
    GSTApplicable,
    selectedGSTId,
    selectedItem,
    shouldShowSundryDetails,
  } = props;

  useEffect(() => {
    try {
      if (sundryList && sundryList.length == 0) {
        // setLoading(true);
        BillSundry.GetBillSundry().then(function (result1) {
          // // debugger;
          setSundryList(result1);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // setLoading(false);
    }
  }, []);

  const [editMode, setEditMode] = useState({});
  var currencyDecimalPlaces = companyData?.currencyDecimalPlaces || 2;
  const [rows, setRows] = useState(Array(4).fill({}));

  const [BillSerisMasterData, setBillSerisMasterData] = useState([]);

  const GetbillMastersType = async () => {
    var ID = [12, 71];
    // setLoading(true);

    var data = await Transaction.GetTransactionVchSrsInfo(ID);
    if (data && data.length > 0) {
      setBillSerisMasterData(data);
    }
  };

  useEffect(() => {
    GetbillMastersType();
  }, []);

  const debouncedFetchAccountSuggestions = useCallback(
    debounce(async (name, value) => {
      const SearchItem = await fetchAccountSuggestions(name, value);
      if (name === "accountName") {
        if (SearchItem) {
          setFilteredAccount(SearchItem);
        }
        // setIsDropdownOpen1(true);
      }
    }, 500),
    []
  );

  const fetchAccountSuggestions = async (name, value) => {
    const filteredData = {
      MasterType: 2,
      GroupWiseSuggestion: false,
      GroupId: "",
      search_str: value,
      PageNumber: 1,
      PageSize: 50,
    };

    try {
      const SearchItem = await Account.GetAccountListForAutoSuggestion(
        filteredData
      );
      return SearchItem;
    } catch (error) {
      console.error("Error fetching account suggestions:", error);
      return null;
    }
  };

  // const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const suggestionBoxRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const itemRefs = useRef([]); // Reference for each list item
  const [AccountMasterModal, setAccountMasterModal] = useState(false);
  const closeSuggestionBox = (e, rowIndex) => {
    // // debugger;
    setTimeout(() => {
      setAddVouchersEnterAccountDetails((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex].isDropdownOpen = false;
        newRows[rowIndex].filteredProduct = [];
        return newRows;
      });
    }, 100);
  };
  const handleKeyDown_AccountSuggestion = (e, ToFilterData, rowIndex) => {
    // // debugger;

    if (!ToFilterData.length) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % ToFilterData.length
        );

        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? ToFilterData.length - 1 : prevIndex - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < ToFilterData.length) {
          if (e.target.name === "accountName") {
            handleAccountSelection(
              ToFilterData[highlightedIndex].d1RefId,
              // ToFilterData[highlightedIndex],
              "accountName",
              rowIndex
            );
          }
        }
        break;
      case "Escape":
        // setIsDropdownOpen1(false);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (rowIndex, e) => {
    const { name, value } = e.target;

    if (name === "accountName") {
      debouncedFetchAccountSuggestions(name, value);
      setAddVouchersEnterAccountDetails((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          [name]: value,
          // filteredProduct: FilteredSearchItem || [],
          isDropdownOpen: true,
          highlightedIndex: -1,
        };
        return newRows;
      });
    } else {
      setAddVouchersEnterAccountDetails((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          [name]: value,
        };
        return newRows;
      });
    }
  };

  const [selectedAccId, setselectedAccId] = useState(null);

  const [headingValue, setHeadingValue] = useState([]);

  const addRowAtEnd = () => {
    // Define the empty row with default values
    const newRow = {
      trans1Id: 0,
      mastType: 0,
      vouchSeries: 0,
      vouchNo: "",
      vouchDate: "2025-01-23", // or use an empty string if you want it empty
      accountId: 0,
      accountName: "",
      dc: addVouchersEnterAccountDetails.length % 2 === 0 ? "D" : "C",
      balanceAmt: "",
      debitAmt: "",
      creditAmt: "",
      taxVal1: "",
      taxVal2: "",
      taxVal3: "",
      docTypeDtl: "",
      chqDocNo: "",
      chqDocDate: "",
      shortNarration: "",
      e1: 0,
      e2: "",
      e3: "",
      e4: "",
      e5: "",
      createdBy: 0,
      createdOn: "2025-01-23", // or empty string for a fresh row
    };

    // Append the new row at the end of the array
    setAddVouchersEnterAccountDetails([
      ...addVouchersEnterAccountDetails,
      newRow,
    ]);
  };

  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  useEffect(() => {
    // Calculate total credit amount where dc = "C"
    const creditSum = addVouchersEnterAccountDetails
      .filter((item) => item.dc === "C")
      .reduce((sum, item) => sum + Number(item.creditAmt || 0), 0);

    // Calculate total debit amount where dc = "D"
    const debitSum = addVouchersEnterAccountDetails
      .filter((item) => item.dc === "D")
      .reduce((sum, item) => sum + Number(item.debitAmt || 0), 0);

    setTotalCredit(creditSum);
    setTotalDebit(debitSum);
  }, [addVouchersEnterAccountDetails]);

  //**********show hide*****************//
  const [isInputVisiblsi1, setInputVisiblsi1] = useState(false);
  const handleToggleChangsi1 = () => {
    setInputVisiblsi1(!isInputVisiblsi1);
  };
  const [isInputVisiblsi2, setInputVisiblsi2] = useState(false);
  const handleToggleChangsi2 = () => {
    setInputVisiblsi2(!isInputVisiblsi2);
  };
  const [isCheckboxDisabledsi3, setIsCheckboxDisabledsi3] = useState(true);
  const [isInputVisiblsi3, setInputVisiblsi3] = useState(true);
  const handleToggleChangsi3 = () => {
    setInputVisiblsi3(!isInputVisiblsi3);
    setIsCheckboxDisabledsi3(!isCheckboxDisabledsi3); // Toggle the disabled state
  };
  const [isInputVisiblsi4, setInputVisiblsi4] = useState(false);
  const handleToggleChangsi4 = () => {
    setInputVisiblsi4(!isInputVisiblsi4);
  };

  const [isInputVisiblsi9, setInputVisiblsi9] = useState(false);
  const handleToggleChangsi9 = () => {
    setInputVisiblsi9(!isInputVisiblsi9);
  };
  const [isInputVisiblsi10, setInputVisiblsi10] = useState(false);
  const handleToggleChangsi10 = () => {
    setInputVisiblsi10(!isInputVisiblsi10);
  };
  const [isInputVisiblsi11, setInputVisiblsi11] = useState(false);
  const handleToggleChangsi11 = () => {
    setInputVisiblsi11(!isInputVisiblsi11);
  };
  const [isInputVisiblsi12, setInputVisiblsi12] = useState(false);
  const handleToggleChangsi12 = () => {
    setInputVisiblsi12(!isInputVisiblsi12);
  };
  const [isInputVisiblsi13, setInputVisiblsi13] = useState(false);
  const handleToggleChangsi13 = () => {
    setInputVisiblsi13(!isInputVisiblsi13);
  };
  const [isInputVisiblsi14, setInputVisiblsi14] = useState(false);
  const handleToggleChangsi14 = () => {
    setInputVisiblsi14(!isInputVisiblsi14);
  };
  const [isInputVisiblsi15, setInputVisiblsi15] = useState(false);
  const handleToggleChangsi15 = () => {
    setInputVisiblsi15(!isInputVisiblsi15);
  };
  const [isCheckboxDisabledsi16, setIsCheckboxDisabledsi16] = useState(true); // You can initialize it to true to start as disabled
  const [isInputVisiblsi16, setInputVisiblsi16] = useState(true);
  const handleToggleChangsi16 = () => {
    setInputVisiblsi16(!isInputVisiblsi16);
    setIsCheckboxDisabledsi16(!isCheckboxDisabledsi16); // Toggle the disabled state
  };
  const [isInputVisiblsi17, setInputVisiblsi17] = useState(false);
  const handleToggleChangsi17 = () => {
    setInputVisiblsi17(!isInputVisiblsi17);
  };
  const [isCheckboxDisabledsi18, setIsCheckboxDisabledsi18] = useState(true);
  const [isInputVisiblsi18, setInputVisiblsi18] = useState(true);
  const handleToggleChangsi18 = () => {
    setInputVisiblsi18(!isInputVisiblsi18);
    setIsCheckboxDisabledsi18(!isCheckboxDisabledsi18); // Toggle the disabled state
  };
  const [isInputVisiblsi19, setInputVisiblsi19] = useState(false);
  const handleToggleChangsi19 = () => {
    setInputVisiblsi19(!isInputVisiblsi19);
  };
  const [isInputVisiblsi20, setInputVisiblsi20] = useState(false);
  const handleToggleChangsi20 = () => {
    setInputVisiblsi20(!isInputVisiblsi20);
  };
  const [isCheckboxDisabledsi21, setIsCheckboxDisabledsi21] = useState(true);
  const [isInputVisiblsi21, setInputVisiblsi21] = useState(true);
  const handleToggleChangsi21 = () => {
    setInputVisiblsi21(!isInputVisiblsi21);
    setIsCheckboxDisabledsi21(!isCheckboxDisabledsi21); // Toggle the disabled state
  };

  const [isInputVisiblsi24, setInputVisiblsi24] = useState(false);
  const handleToggleChangsi24 = () => {
    setInputVisiblsi24(!isInputVisiblsi24);
  };
  const [isInputVisiblsi25, setInputVisiblsi25] = useState(false);
  const handleToggleChangsi25 = () => {
    setInputVisiblsi25(!isInputVisiblsi25);
  };
  const [isInputVisiblsi26, setInputVisiblsi26] = useState(false);
  const handleToggleChangsi26 = () => {
    setInputVisiblsi26(!isInputVisiblsi26);
  };
  const [isInputVisiblsi27, setInputVisiblsi27] = useState(false);
  const handleToggleChangsi27 = () => {
    setInputVisiblsi27(!isInputVisiblsi27);
  };
  const [isInputVisiblsi28, setInputVisiblsi28] = useState(false);
  const handleToggleChangsi28 = () => {
    setInputVisiblsi28(!isInputVisiblsi28);
  };

  const [isInputVisible10, setInputVisible10] = useState(false);
  const handleToggleChange10 = () => {
    setInputVisible10(!isInputVisible10);
  };
  const [isInputVisible_0, setInputVisible1] = useState(false);
  const handleToggleChanges = ({ index }) => {
    //const val = "isInputVisible_" + index
    setInputVisible1(!isInputVisible_0);
  };

  //************modal box************//

  const [isModalOpen3, setModalOpen3] = useState(false);

  // const showModal3 = () => {
  //     setModalOpen3(true);
  // };

  const hideModal3 = () => {
    setModalOpen3(false);
  };

  const [customfields, setCustomefields] = useState([]);

  const customFieldAddBtn = () => {
    const val = document.getElementById("CustomFieldInput").value;
    console.log(val);
    if (val.trim() !== "") {
      const newData = { id: customfields.length + 1, FieldName: val };
      setCustomefields([...customfields, newData]);
      document.getElementById("inputField").style.display = "none";
      document.getElementById("CustomFieldInput").value = "";
    } else {
      alert("Please Enter valid Name", "ok");
    }
  };

  const AddCustmField_clicked = () => {
    document.getElementById("inputField").style.display = "block";
  };

  //************modal box************/ /

  const appendValue = (e) => {
    console.log(e.target.value);
    let newItem = [...headingValue, e.target.value];
    setHeadingValue(newItem);
  };
  useEffect(() => {
    let savedItems = JSON.parse(localStorage.getItem("headValue"));
    if (savedItems) {
      setHeadingValue(savedItems);
    }
  }, []);
  const [pageNo, setPageNo] = useState(5);

  // useEffect(() => {
  //     localStorage.setItem("headValue", JSON.stringify(headingValue));
  //     if (props.refVal=== "input12Ref"){
  //         input12Ref.current.focus();
  //         console.log("if")
  //     }
  //     else{
  //         console.log("else")
  //     }
  // }, [headingValue,props.refVal])

  // useEffect(() => {
  //     if (props.refVal === "input12Ref") {
  //         console.log(props.refVal);
  //         input12Ref.current.focus();
  //     } else {
  //         console.log("else");
  //     }
  // }, [ props.refVal]);

  // const input12Ref = useRef(null);
  // const input13Ref = useRef(null);
  // const input14Ref = useRef(null);
  // const handleKeyPress = (event, nextInputRef) => {
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         nextInputRef.current.focus();
  //     }
  // };

  //const sampleColumns = ['id'];

  const itemsPerPage = pageNo; // Adjust the number of items per page as needed

  const [currentPage, setCurrentPage] = useState(1);

  const totalItems = addVoucherSundryDetail.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAccountSelection = async (id, _name, rowIndex) => {
    // debugger;
    var selectedAccData = await Account.getAccountById(id);
    setselectedAccId(id);
    // debugger;
    var selectedAcc = selectedAccData.item1[0];
    var updatedData;
    if (selectedAcc) {
      if (Number(id) === selectedAcc.d1Id && _name == "accountName") {
        setAddVouchersEnterAccountDetails((prevValues) => {
          const updatedValues = [...prevValues]; // Create a copy of the previous array
          updatedValues[rowIndex] = {
            ...updatedValues[rowIndex], // Keep the existing data of the row
            accountId: selectedAcc.d2Id, // Update the accountId
            accountName: selectedAcc.name || "", // Update the accountName
            balanceAmt: selectedAcc.balanceAmt || 0,
          };
          return updatedValues; // Return the updated array
        });
        // setIsDropdownOpen1(false);
      }
      setHighlightedIndex(-1);
    }

    closeSuggestionBox("", rowIndex);
  };

  const openAccountMasterModal = async (val) => {
    // debugger;
    if (val === "Add") {
      setAccountMasterModal(true);
      // setIsDropdownOpen1(false);
    } else {
      var selectedAccData = await Account.getAccountById(val);
      // console.log("selectedAccData", selectedAccData.item1);
      // setSelectedAccData(selectedAccData.item1);

      setAccountMasterModal(true);
      //document.getElementById('PartySelectBox').value = "";
      // setIsDropdownOpen1(false);
    }
  };

  const closeAccountMasterModal = (data) => {
    // debugger;
    setAccountMasterModal(false);
    if (data) {
      var str = data.result;
      if (str) {
        const result = str.split(",").reduce((acc, item) => {
          const [key, value] = item.split(":").map((str) => str.trim()); // Split by ':' and trim spaces
          acc[key] = value; // Add the key-value pair to the object
          return acc;
        }, {});
        if (result) {
          handleAccountSelection(result.d1id, "accountName", 0);
        }
      }
    }
  };

  // const handleCellKeyDown = (event, rowIndex) => {
  //     if (event.key === 'Enter') {
  //         const newRow = { id: addVoucherSundryDetail.length + 1 };

  //         setAddVoucherSundryDetail([...addVoucherSundryDetail, newRow]);

  //         const nextRowIndex = rowIndex + 1;
  //         const nextRowCellId = `cell-${nextRowIndex}-0`;
  //         const nextRowCell = document.getElementById(nextRowCellId);

  //         if (nextRowCell) {
  //             nextRowCell.focus();
  //         }
  //     }
  // };

  function AdjustTableWidth(inputId, targetTh, targetTd) {
    var ItemName = document.getElementById(inputId).value;
    const parsedWidth = parseInt(ItemName, 10);
    const val = parsedWidth + "px";
    if (!isNaN(parsedWidth)) {
      document.getElementById(targetTh).style.width = val;

      for (var i = 0; i < addVoucherSundryDetail.length; i++) {
        var indexId = targetTd + i;
        console.log(indexId);

        const element = document.getElementById(indexId);
        if (element != null) {
          element.style.width = val;
        }
      }
    } else {
      console.error("Invalid width value:", ItemName);
    }
  }

  const SaveBtnClick = () => {
    debugger;
    const pageNo = document.getElementById("setNoOfFields").value;
    updatePage(pageNo);

    AdjustTableWidth("ItemCodeWidth", "Code-th", "Code-td-");
    AdjustTableWidth("ItemGroupWidth", "ItemGroup-th", "ItemGroup-td-");
    //AdjustTableWidth("ItemNameWidth", "ItemName-th", "ItemName-td-");
    AdjustTableWidth(
      "MasterDescriptionWidth",
      "MasterDescription-th",
      "MasterDescriptions-td-"
    );

    hideModal3();
  };

  const updatePage = (val) => {
    const newPage = val;
    setPageNo(newPage);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowHover = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };
  const [, setQuestiontoask] = useState(false);
  const deleteValueById = (id) => {
    setQuestiontoask((prevState) => prevState.filter((item) => item.id !== id));
  };

  /***********End Enter code*****************/

  // const nextCellRefsProduct = useRef(null);
  const nextCellRefsProduct = useRef([]);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      // Focus on the next input box
      nextInputRef.current.focus();
    }
  };

  const handleProductRowKeyDown = async (event, rowIndex, columnIndex) => {
    //   // debugger;
    //   if (event.key === "Enter" || event.key === "PageDown") {
    //     event.preventDefault();
    //     event.stopPropagation();
    //     const { name, value } = event.target;
    //     // Add a new row if at the end and 'Enter' is pressed
    //     if (rowIndex === itemMasterRows.length - 1) {
    //       if (
    //         name === "TotalAmt" ||
    //         name === "salePriceForPur" ||
    //         name === "saleProfit" ||
    //         name === "mrpPrice" ||
    //         name === "BrkgAmt"
    //       ) {
    //         await handleAddRow();
    //       }
    //     }
    //     // Calculate next column index
    //     const nextColumnIndex = columnIndex + 1;
    //     let nextRowCellRef =
    //       nextCellRefsProduct.current[rowIndex][nextColumnIndex];
    //     if (!nextRowCellRef) {
    //       for (let i = nextColumnIndex; i < 35; i++) {
    //         const cellRef = nextCellRefsProduct.current[rowIndex][i]; // Assuming index 0 for the first column
    //         if (cellRef) {
    //           nextRowCellRef = cellRef;
    //           break;
    //         }
    //       }
    //     }
    //     if (nextRowCellRef) {
    //       nextRowCellRef.focus();
    //       nextRowCellRef.select();
    //     } else {
    //       setTimeout(() => {
    //         const nextRowIndex = rowIndex + 1;
    //         let nextRowCellId = `Code-td-${nextRowIndex}`;
    //         let nextRowCell = document.getElementById(nextRowCellId);
    //         if (!nextRowCell) {
    //           nextRowCellId = `Group-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (!nextRowCell) {
    //           nextRowCellId = `ItemName-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (nextRowCell) {
    //           nextRowCell.focus();
    //         }
    //       }, 10);
    //     }
    //   } else if (event.key === "PageUp") {
    //     debugger;
    //     event.preventDefault();
    //     event.stopPropagation();
    //     debugger;
    //     const { name, value } = event.target;
    //     const nextColumnIndex = columnIndex - 1;
    //     let nextRowCellRef =
    //       nextCellRefsProduct.current[rowIndex][nextColumnIndex];
    //     if (!nextRowCellRef) {
    //       for (let i = nextColumnIndex; i > 0; i--) {
    //         debugger;
    //         // Loop from nextColumnIndex down to 0
    //         const cellRef = nextCellRefsProduct.current[rowIndex][i]; // Assuming index 0 for the first column
    //         if (cellRef) {
    //           nextRowCellRef = cellRef;
    //           break;
    //         }
    //       }
    //     }
    //     if (name == "defaultBarcode" && inputRefs.current[37]) {
    //       inputRefs.current[37].focus();
    //       return;
    //     }
    //     if (nextRowCellRef) {
    //       nextRowCellRef.focus();
    //       nextRowCellRef.select();
    //     } else {
    //       setTimeout(() => {
    //         // debugger;
    //         const nextRowIndex = rowIndex - 1;
    //         let nextRowCellId = `MrpPrc-td-${nextRowIndex}`;
    //         let nextRowCell = document.getElementById(nextRowCellId);
    //         if (!nextRowCell) {
    //           nextRowCellId = `TotalAmt-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (!nextRowCell) {
    //           nextRowCellId = `SalePrft-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (!nextRowCell) {
    //           nextRowCellId = `BrkgAmt-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (!nextRowCell) {
    //           nextRowCellId = `BrkgPer-td-${nextRowIndex}`;
    //           nextRowCell = document.getElementById(nextRowCellId);
    //         }
    //         if (nextRowCell) {
    //           nextRowCell.focus();
    //         }
    //       }, 10);
    //     }
    //   }
  };

  const [inputPosition, setInputPosition] = useState(null);
  const [inputPosition1, setInputPosition1] = useState(null);

  const getPosition = (rowIndex, columnIndex) => {
    debugger;
    const inputRef = nextCellRefs.current[rowIndex]?.[columnIndex];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom, // Position below the input
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const getPosition1 = (rowIndex, columnIndex) => {
    debugger;
    const inputRef = nextCellRefsProduct.current[rowIndex]?.[columnIndex];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition1({
        top: rect.bottom, // Position below the input
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const handleSundryCellChange = (event, rowIndex) => {
    const { name, value } = event.target;
    setAddVoucherSundryDetail((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        [name]: value,
      };
      return newRows;
    });
  };

  const handleKeyDown_SundrySuggestion = (e, rowIndex) => {
    const { filteredSundry, highlightedIndex } =
      addVoucherSundryDetail[rowIndex];
    if (filteredSundry && !filteredSundry.length) {
      handleSundryRowKeyDown(e, rowIndex, 1);
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setAddVoucherSundryDetail((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            (highlightedIndex + 1) % filteredSundry.length;
          return newRows;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setAddVoucherSundryDetail((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            highlightedIndex === 0
              ? filteredSundry.length
              : highlightedIndex - 1;
          return newRows;
        });
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredSundry.length) {
          handleSundrySelection(rowIndex, filteredSundry[highlightedIndex]);
        }
        handleSundryRowKeyDown(e, rowIndex, 1);
        break;
      case "Escape":
        setAddVoucherSundryDetail((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].isDropdownOpen = false;
          return newRows;
        });
        break;
      default:
        break;
    }
  };

  const handleSundryRowKeyDown = (event, rowIndex, columnIndex) => {
    if (event.key === "Enter") {
      const newRow = {
        id: addVoucherSundryDetail.length + 1,
        name: "",
        printName: "",
        sundryType: 0,
        methodApplied: "",
        absolutePercentAmount: false,
        defaultValue: 0,
        percentOfAmount: 100,
        applyOnBill: 1,
        roundOffSundryAmount: false,
        amtRoundOffConfig: 0,
        amtAdjustSaleAccount: false,
        selectedAccountSale: 0,
        amtAdjustPurchaseAccount: false,
        selectedAccountPurchase: 0,
        roundOffLimit: 0,
        Percent: 0,
        Amount: 0,
        isDropdownOpen: false,
        highlightedIndex: -1,
        filteredSundry: [],
      };

      if (rowIndex === addVoucherSundryDetail.length - 1) {
        if (event.target.name === "Amount") {
          setAddVoucherSundryDetail((prevRows) => {
            const newRows = [...prevRows, newRow];
            return newRows;
          });
        }
      }

      // Calculate next column index
      const nextColumnIndex = columnIndex + 1;
      const nextRowCellRef = nextCellRefs.current[rowIndex][nextColumnIndex];

      if (nextRowCellRef) {
        nextRowCellRef.focus();
      } else {
        setTimeout(() => {
          const nextRowIndex = rowIndex + 1;
          let nextRowCellId = `cell-${nextRowIndex}-0`;
          let nextRowCell = document.getElementById(nextRowCellId);

          if (nextRowCell) {
            nextRowCell.focus();
          }
        }, 10);
      }
    }
  };

  const handleSundryNameChange = (event, rowIndex) => {
    const { value } = event.target;
    setAddVoucherSundryDetail((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].accountName = value;
      newRows[rowIndex].filteredSundry = sundryList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      newRows[rowIndex].isDropdownOpen = true;
      newRows[rowIndex].highlightedIndex = -1;
      return newRows;
    });
  };

  const handleSundrySelection = (rowIndex, selectedSundry) => {
    // // debugger;
    if (selectedSundry.methodApplied == "discount") {
      setAddVoucherSundryDetail((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          accountId: selectedSundry.id,
          accountName: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          isAmtPrcnt: selectedSundry.absolutePercentAmount
            ? 0
            : selectedSundry.defaultValue || 0,
          itmNetAmt: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || 0
            : 0,
          isDropdownOpen: false,
          filteredSundry: [],
        };
        // onChangeSundrySaveMethod(newRows);
        return newRows;
      });
    } else {
      setAddVoucherSundryDetail((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          accountId: selectedSundry.id,
          accountName: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          isAmtPrcnt: selectedSundry.absolutePercentAmount
            ? 0
            : selectedSundry.defaultValue || 0,
          itmNetAmt: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || 0
            : 0,
          isDropdownOpen: false,
          filteredSundry: [],
        };
        // onChangeSundrySaveMethod(newRows);
        return newRows;
      });
    }
  };

  const handleInputChangeEdit = (e) => {
    setTimeout(() => {
      e.target.select();
    }, 100);
    // console.log("focus", e.target.name);
    setEditMode((prevState) => ({
      ...prevState,
      [e.target.id]: true,
    }));
  };

  const handleBlurForEdit = (e, rowIndex1, rowIndex) => {
    // console.log("Blur", e.target.id);
    setEditMode((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
  };

  /***********End Enter code*****************/

  return (
    <div>
      <div>
        <div></div>
        <div className="table-container-s1" style={{ height: "auto" }}>
          <table className="table-scrol2" style={{ width: "98%" }}>
            <thead className="table-tbody">
              <tr className="table-tr2">
                <th className=" table-th-1 tb_1 c-w">
                  <label className="c-w">SN</label>
                </th>
                <th className=" mb_1 tb_1 c-w" id="DC-th">
                  <div className="c-w">D/C</div>
                </th>
                <th className="Account_Vouch c-w">
                  {" "}
                  <div className="thm2 tb_1 c-w" id="Account-th">
                    <label className="c-w">Account</label>
                  </div>{" "}
                </th>
                <th className="mb_2 tb_1 c-w " id="Balance-th">
                  <div className="c-w">Balance</div>
                </th>
                <th className=" mb_4 tb_1 c-w" id="Debit-th">
                  {" "}
                  <div className="c-w">Debit</div>
                </th>
                <th className=" mb_9 tb_1 c-w" id="Credit-th">
                  {" "}
                  <div className="c-w">Credit</div>
                </th>
                <th className="mb_10 tb_1 c-w" id="DocType-th">
                  {" "}
                  <div className="c-w">Doc Type</div>
                </th>
                <th className="mb_11 tb_1 c-w" id="ChqDocNo-th">
                  {" "}
                  <div className="c-w">Chq/Doc No</div>
                </th>
                <th className="mb_12 tb_1 c-w" id="ChqDocDt-th">
                  {" "}
                  <div className="c-w">Chq/Doc Dt</div>
                </th>
                <th className="mb_13 tb_1 c-w" id="ShortNarr-th">
                  {" "}
                  <div className="c-w">Short Narr</div>
                </th>

                {/* {isInputVisible_0 && (<th className="mb_22 tb_1 c-w"><div className="thm21 c-w">{customfields[0].FieldName}</div></th>)} */}
                <th className="table-th table-btn table-btn1 c-w">Dlt</th>
              </tr>
            </thead>

            <tbody
              className="table-container-tbody"
              style={
                {
                  /*height: "200px"*/
                }
              }
            >
              {addVouchersEnterAccountDetails.map((row, rowIndex) => (
                <tr
                  className={`table-tr2 hover-tr onSerialNoHover ${
                    rowIndex === hoveredRow ? "hovered-row" : ""
                  }`}
                  key={rowIndex}
                  onMouseEnter={() => handleRowHover(rowIndex)}
                  onMouseLeave={handleRowLeave}
                  style={{ textAlign: "center" }}
                >
                  <td
                    className="table-th-1"
                    onClick={() => addRowAtEnd(rowIndex)}
                  >
                    {rowIndex + 1}
                    {/* {row.id} */}
                    {/* <i0
                      className="fa-solid fa-plus tablePlusIcon"
                      onClick={() => addRowAtEnd(rowIndex)}
                      style={{
                        display: rowIndex === hoveredRow ? "block" : "none",
                      }}
                    ></i0> */}
                  </td>
                  <td className="mb_1">
                    <select
                      className="table-input-13 pd_1"
                      id={`dc-td-${rowIndex}`}
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][1] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 1)}
                      onKeyDown={(event) => handleKeyPress(event)}
                      type="text"
                      value={row.dc}
                      name="dc"
                      //value={row[`code-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    >
                      <option value="D">Debit</option>
                      <option value="C">Credit</option>
                    </select>
                  </td>
                  <td
                    className="Account_Vouch"
                    style={{ position: "relative" }}
                  >
                    <input
                      className="table-input-13 pd_1"
                      type="text"
                      name="accountName"
                      id={`accountName-td-${rowIndex}`}
                      //value={row[`ItemName-${rowIndex}`] || ""}
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][2] = input;
                      }}
                      onKeyUp={(e) => {
                        handleKeyDown_AccountSuggestion(
                          e,
                          filteredAccount,
                          rowIndex
                        );
                      }}
                      autoComplete="off"
                      required
                      onBlur={(e) => closeSuggestionBox(e, rowIndex)}
                      onDoubleClick={() =>
                        selectedAccId
                          ? openAccountMasterModal(selectedAccId)
                          : openAccountMasterModal("Add")
                      }
                      onChange={(e) => handleInputChange(rowIndex, e)}
                      // id={[`Account-td-${rowIndex}`]}
                      value={row.accountName}
                      onClick={() => getPosition1(rowIndex, 2)} // Get position on click
                      // onFocus={() => getPosition(rowIndex, 3)} // Get position on click
                    />
                    {addVouchersEnterAccountDetails[rowIndex]
                      .isDropdownOpen && (
                      <ul
                        style={{
                          top: `${inputPosition1.top}px`,
                          left: `${inputPosition1.left}px`,
                          width: `${inputPosition1.width}px`,
                        }}
                        className="ItemmasterDuggestionsaleinvoice"
                        ref={suggestionBoxRef}
                      >
                        {filteredAccount.map((Acc, index) => (
                          <li
                            key={index}
                            ref={(el) => (itemRefs.current[index] = el)}
                            onClick={() =>
                              handleAccountSelection(
                                Acc.d1RefId,
                                // Acc,
                                "accountName",
                                rowIndex
                              )
                            }
                            style={{
                              // padding: "2px 5px",
                              // cursor: "pointer",
                              // borderBottom: "1px solid #ccc",
                              backgroundColor:
                                highlightedIndex === index
                                  ? "#e6f7ff"
                                  : "transparent",
                            }}
                          >
                            {Acc.name}
                          </li>
                        ))}
                        <li
                          key={filteredAccount.length}
                          ref={(el) =>
                            (itemRefs.current[filteredAccount.length] = el)
                          }
                          className="addNew_btn_saleInvoice"
                          onClick={() => openAccountMasterModal("Add")}
                          style={{
                            cursor: "pointer",
                            borderBottom: "1px solid #ccc",
                            backgroundColor:
                              highlightedIndex === filteredAccount.length
                                ? "black"
                                : "#5e709e",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          Add New
                          <i class="fa-solid fa-plus"></i>
                        </li>
                      </ul>
                    )}
                  </td>
                  <td className="mb_4">
                    <input
                      className="table-input-13 pd_1"
                      type="number"
                      name="balanceAmt"
                      //value={row[`Description-${rowIndex}`] || ""}
                      disabled="true"
                      style={{
                        backgroundColor: "rgb(244 244 244)",
                      }}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                      id={[`Balance-td-${rowIndex}`]}
                      value={row.balanceAmt}
                    />
                  </td>

                  <td className="mb_6">
                    <input
                      disabled={row.dc == "C"}
                      style={{
                        backgroundColor:
                          row.dc == "C" ? "rgb(244 244 244)" : "",
                      }}
                      className="table-input-13 pd_1"
                      type="number"
                      name="debitAmt"
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][3] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 3)}
                      id={`debitAmt-td-${rowIndex}`}
                      value={row.debitAmt}
                      //value={row[`DIA-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>
                  <td className="mb_6">
                    <input
                      className="table-input-13 pd_1"
                      type="number"
                      name="creditAmt"
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][4] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 4)}
                      id={`creditAmt-td-${rowIndex}`}
                      style={{
                        backgroundColor:
                          row.dc == "D" ? "rgb(244 244 244)" : "",
                      }}
                      disabled={row.dc == "D"}
                      value={row.creditAmt}
                      //value={row[`EYE-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>
                  <td className="mb_6">
                    <input
                      className="table-input-13 pd_1"
                      type="text"
                      name="docTypeDtl"
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][5] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 5)}
                      id={`docTypeDtl-td-${rowIndex}`}
                      value={row.docTypeDtl}
                      //value={row[`SPH-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>
                  <td className="mb_6">
                    <input
                      className="table-input-13 pd_1"
                      type="text"
                      name="chqDocNo"
                      id={`chqDocNo-td-${rowIndex}`}
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][6] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 6)}
                      value={row.chqDocNo}
                      //value={row[`CYL-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>
                  <td className="mb_6">
                    <input
                      className="table-input-13 pd_1"
                      type="Date"
                      name="chqDocDate"
                      id={`chqDocDate-td-${rowIndex}`}
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][7] = input;
                      }}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 7)}
                      value={row.chqDocDate}
                      //value={row[`AXIS-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>

                  <td className="mb_9">
                    <input
                      className="table-input-13 pd_1"
                      type="text"
                      name="shortNarration"
                      id={`shortNarration-td-${rowIndex}`}
                      ref={(input) => {
                        if (!nextCellRefsProduct.current[rowIndex])
                          nextCellRefsProduct.current[rowIndex] = [];
                        nextCellRefsProduct.current[rowIndex][8] = input;
                      }}
                      value={row.shortNarration}
                      onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 8)}
                      //value={row[`Material-${rowIndex}`] || ""}
                      onChange={(e) => handleInputChange(rowIndex, e)}
                    />
                  </td>

                  <td className="" style={{ textAlign: "center" }}>
                    <button
                      className="btn-table1"
                      // onClick={() => handleDeleteRow(rowIndex)}
                    >
                      <i className="fa-solid fa-trash delete-btn"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="table-down-main" style={{ marginBottom: "5px" }}>
          <div className="table-down-m1">
            <button className="btn-table2" style={{ marginLeft: "58px" }}>
              <i className="fa-solid fa-square-plus"></i>
            </button>
          </div>

          <div className="table-down-s1 stock-out-padding">
            <div className="debit_width">
              <input
                type="text"
                className="table-down-s2 debit_width2 margin-debit"
                value={totalDebit}
              />
            </div>
            <div className="debit_width">
              <input
                type="text"
                className="table-down-s2 debit_width2 margin-credit"
                value={totalCredit}
              />
            </div>
          </div>
        </div>
        {shouldShowSundryDetails && VoucherData.mastType !== "22" && (
          <div className="input-box-Imo1 input-heightAddVoucher StockOut_Copy">
            <div className="AddNewItem_Input_Box check_box_b1 ">
              <label className="modify_AddNewItem_checkbox particular_font">
                GST Tax Info.
              </label>
            </div>
          </div>
        )}

        <div
          className="table-down-main"
          style={{ marginTop: "15px", marginBottom: "5px" }}
        >
          {shouldShowSundryDetails && VoucherData.mastType !== "22" && (
            <>
              {VoucherData.mastType != "22" && (
                <div className="Table-GST-left">
                  <SundryDetailsDataForAddVoucher
                    nextCellRefs={nextCellRefs}
                    // inputValues={inputValues}
                    handleKeyDown_SundrySuggestion={
                      handleKeyDown_SundrySuggestion
                    }
                    handleSundryNameChange={handleSundryNameChange}
                    inputPosition={inputPosition}
                    suggestionBoxRef={suggestionBoxRef}
                    getPosition={getPosition}
                    sundryRows={addVoucherSundryDetail}
                    handleSundryCellChange={handleSundryCellChange}
                    handleInputChangeEdit={handleInputChangeEdit}
                    currencyDecimalPlaces={currencyDecimalPlaces}
                    editMode={editMode}
                    itemRefs={itemRefs}
                    // deleteSundryRow={deleteSundryRow}
                    handleSundryRowKeyDown={handleSundryRowKeyDown}
                    handleBlurForEdit={handleBlurForEdit}
                    handleSundrySelection={handleSundrySelection}
                  />
                </div>
              )}
            </>
          )}
          <div className="Bill-AMT-Right">
            <div className="table-down-AddVoucher stock-out-padding">
              <div className="table-down-s2 Reff-Series">
                <label className="table-down-l2">Odr Series</label>
                {/* <input type="text" className="table-down-s2" value="0.00" /> */}
                <select
                  style={{ color: "black" }}
                  className="table-down-s2"
                  id=""
                  name="refBillSrs"
                  value={VoucherData.refBillSrs}
                  onChange={(e) => handleInputChangeMain(e)}
                >
                  <option value="">--Select--</option>
                  {BillSerisMasterData.map((items) => (
                    <option value={items.t4}>{items.t3}</option>
                  ))}
                </select>
              </div>
              <div className="table-down-s2 Reff-Series">
                <label className="table-down-l2">Vouch No</label>
                <input
                  type="text"
                  className="table-down-s2"
                  style={{ color: "black" }}
                  name="refBillNo"
                  value={VoucherData.refBillNo}
                  onChange={(e) => handleInputChangeMain(e)}
                />
              </div>
              <div className="table-down-s2 Reff-Series">
                <label className="table-down-l2">Reff/Purch Date</label>
                <input
                  type="date"
                  style={{ color: "black" }}
                  className="table-down-s2"
                  name="refBillDate"
                  value={VoucherData.refBillDate}
                  onChange={(e) => handleInputChangeMain(e)}
                />
              </div>
              <div className="table-down-s2 Reff-Series">
                <label className="table-down-l2">Other</label>
                <input
                  type="text"
                  className="table-down-s2"
                  name="e3"
                  value={VoucherData.e3}
                  onChange={(e) => handleInputChangeMain(e)}
                  style={{ color: "black" }}
                />
              </div>

              <div className="table-down-s2 Reff-Series">
                <label className="table-down-l2">Extra Field 1</label>
                {/* <label className="table-down-l2">Reff&nbsp;Series</label> */}
                <input
                  type="text"
                  className="table-down-s2"
                  name="e1"
                  value={VoucherData.e1}
                  onChange={(e) => handleInputChangeMain(e)}
                  style={{ color: "black" }}
                />
              </div>

              <div className="table-down-s2 Reff-Series">
                {/* <label className="table-down-l2">Reff/Purch No.</label> */}
                <label className="table-down-l2">Extra Field 2</label>
                <input
                  type="text"
                  className="table-down-s2"
                  name="e2"
                  value={VoucherData.e2}
                  onChange={(e) => handleInputChangeMain(e)}
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/******************************************************/}
      {isModalOpen3 && (
        <div className="modal">
          <div className="ModalBoxProductSetting">
            <div className="modal-title">
              <h3 className="modal-h">Add Columns into Table</h3>
              <span className="close" onClick={hideModal3}>
                &times;
              </span>
            </div>
            <div
              className="ModalContentModalSettingP"
              style={{ paddingTop: "5px" }}
            >
              <div className="gridview2">
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) => {
                        handleToggleChangsi1(e, "Item Code");
                        appendValue(e);
                      }}
                      value="Item Code"
                      checked={isInputVisiblsi1}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Item Code</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="ItemCodeWidth"
                    />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={handleToggleChangsi2}
                      checked={isInputVisiblsi2}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Item group</label>
                  </div>

                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="ItemGroupWidth"
                    />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={handleToggleChangsi3}
                      disabled={isCheckboxDisabledsi3}
                      checked={isInputVisiblsi3}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox" id="ItemName-w">
                      {" "}
                      Item Name
                    </label>
                  </div>

                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="ItemNameWidth"
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi4}
                      checked={isInputVisiblsi4}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      Master&nbsp;Descriptn
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="MasterDescriptionWidth"
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChange10}
                      checked={isInputVisible10}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      Item&nbsp;Descriptn
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="MasterDescriptionWidth"
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi9}
                      checked={isInputVisiblsi9}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Modal</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi10}
                      checked={isInputVisiblsi10}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Color2</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi11}
                      checked={isInputVisiblsi11}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Batch No</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi12}
                      checked={isInputVisiblsi12}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Mgf Date</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi25}
                      checked={isInputVisiblsi25}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">EYE</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={handleToggleChangsi13}
                      checked={isInputVisiblsi13}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Exp Date</label>
                  </div>

                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={handleToggleChangsi14}
                      checked={isInputVisiblsi14}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Unit</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi15}
                      checked={isInputVisiblsi15}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Qty</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi16}
                      disabled={isCheckboxDisabledsi16}
                      checked={isInputVisiblsi16}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Free Qty</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi17}
                      checked={isInputVisiblsi17}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Price</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi18}
                      disabled={isCheckboxDisabledsi18}
                      checked={isInputVisiblsi18}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Discount %
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi19}
                      checked={isInputVisiblsi19}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      Discount Amt
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi20}
                      checked={isInputVisiblsi20}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">Sale Price</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi24}
                      checked={isInputVisiblsi24}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">DIA</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi21}
                      disabled={isCheckboxDisabledsi21}
                      checked={isInputVisiblsi21}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">MRP Price</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi28}
                      checked={isInputVisiblsi28}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">AXIS</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi26}
                      checked={isInputVisiblsi26}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">SPH</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={handleToggleChangsi27}
                      checked={isInputVisiblsi27}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">CYL</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      Sale Prof% (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      Sale Price (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      Mrp Price (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input type="text" className="ModalBoxWidthFix" />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    {/* <input onChange={handleToggleChangsi21} disabled={isCheckboxDisabledsi21} checked={isInputVisiblsi21} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" /> */}
                    <label
                      style={{ backgroundColor: "#ffff54" }}
                      className="modify_p_text_checkbox"
                    >
                      Set No of fields
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      id="setNoOfFields"
                    />
                  </div>
                </div>
                {(() => {
                  if (customfields.length !== 0) {
                    return customfields.map((fields, index) => (
                      <div className="ModalBoxInputM5 ">
                        <div className="input-box3 size-a1" key={fields.id}>
                          <input
                            onChange={() => handleToggleChanges(index)}
                            checked={isInputVisible_0}
                            style={{ boxShadow: "none" }}
                            className="modify_product_checkbox"
                            type="checkbox"
                          />
                          <label className="modify_p_text_checkbox">
                            {fields.FieldName}
                          </label>
                        </div>
                        <div
                          className="ModalboxTitleInput flex"
                          style={{ paddingBottom: "0px" }}
                        >
                          <input type="text" className="ModalBoxWidthFix" />
                          <i
                            className="fa-solid fa-trash mt-1"
                            style={{ zIndex: "1", marginLeft: "50px" }}
                            onClick={() => deleteValueById()}
                          ></i>
                        </div>
                      </div>
                    ));
                  }
                })()}
              </div>
              <hr />
              <div style={{ display: "none" }} id="inputField">
                <div className="flex-center">
                  <input type="text" id="CustomFieldInput" />
                  <button className="Add_btn" onClick={customFieldAddBtn}>
                    Add
                  </button>
                </div>
              </div>
              <div className="flex-center">
                <label
                  style={{
                    color: "darkblue",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={AddCustmField_clicked}
                >
                  + Add Custom Field into Table
                </label>
              </div>

              <hr />
              <div className="flex-end">
                <button
                  id="step-5"
                  className="greenBtn"
                  onClick={SaveBtnClick}
                  style={{ width: "25%" }}
                >
                  Save
                  <i
                    className="fa-regular fa-floppy-disk im-icon-1"
                    style={{ color: "white" }}
                  ></i>
                </button>
                <button
                  className="redBtn"
                  onClick={hideModal3}
                  style={{ width: "25%" }}
                >
                  Back
                  <i
                    className="fa-solid fa-right-from-bracket im-icon-1"
                    style={{ color: "white" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddVoucherAccList;
