import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const CommonDeploymentService = {

    createCommonDeployment: async() => {

        const apiUrl = `${WebApiUrl}/api/CommonDeployment/CreateCommonDeployment`;

        return await axios({
            method: 'post',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },

    createYESBMAccount: async(data) => {

        const apiUrl = `${WebApiUrl}/api/CommonDeployment/CreateYESBMAccount`;

        return await axios({
            method: 'post',
            url: apiUrl,
            data: data,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });
    },

    getYESBMAccountByEmailOrMobile: async(emailOrMobile) => {

        const apiUrl = `${WebApiUrl}/api/CommonDeployment/GetYESBMAccount/${emailOrMobile}`;

        return await axios({
            method: 'get',
            url: apiUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });
    },

    getYESBMAllAccount: async(filter) => {

        const apiUrl = `${WebApiUrl}/api/CommonDeployment/GetYESBMAllAccount`;

        return await axios({
            method: 'post',
            url: apiUrl,
            data: filter,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });
    },

};

export default CommonDeploymentService;