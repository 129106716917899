import React, { useContext, useEffect, useState } from "react";
import ItemMaster from "../../API/APIServices/ItemMaster";
import MyContext from "../MyContext";

const RelatedProducts = (props) => {
  const { ItemMasterActive, ItemGroupList, setLoading } = props;
  const { imageBaseUrl, UserDetails } = useContext(MyContext);

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [data, setData] = useState(ItemGroupList); // Initial state with ItemGroupList
  const [ItemmasterData, setItemmasterData] = useState([]);

  const removeSuggestion = () => {
    setTimeout(() => {
      setSuggestions([]);
    }, 300);
  };

  const [ItemNameSearch, setItemNameSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [ItemPerPage, setItemPerPage] = useState(20);
  
    const NextPage = () => {
      const nextPage = pageNo + 1;
      setPageNo(nextPage);
    };
  
    const PreviousPage = () => {
      const prevPage = pageNo - 1;
      if (prevPage > 0) {
        // Prevent pageNo from going negative
        setPageNo(prevPage);
      } else {
        alert("Already on frist page");
      }
    };
  
    const ChangeItemPerPage = (e) => {
      setItemPerPage(e.target.value);
    };
    useEffect(() => {
      searchItemWithGroup();
    }, [pageNo, ItemPerPage]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setItemNameSearch(value);
  };

  const searchItemWithGroup = async () => {
    debugger;
    var filteredData = [];
    if (inputValue === "") {
      filteredData = [
        {
          fieldName: "string",
          operatorName: "string",
          compareValue: "string",
        },
        {
          fieldName: ItemNameSearch === "" ? "string" : "name",
          operatorName: ItemNameSearch === "" ? "string" : "contains",
          compareValue: ItemNameSearch === "" ? "string" : ItemNameSearch,
        },
      ];
    } else {
      filteredData = [
        {
          fieldName: "parentGrpName",
          operatorName: "Equal",
          compareValue: inputValue,
        },
        {
          fieldName: ItemNameSearch === "" ? "string" : "name",
          operatorName: ItemNameSearch === "" ? "string" : "contains",
          compareValue: ItemNameSearch === "" ? "string" : ItemNameSearch,
        },
      ];
    }
    try {
      const data = await ItemMaster.GetItemMasterWithFilterAndPagination(
        filteredData,
        pageNo,
        ItemPerPage
      );
      console.log(data);
      setItemmasterData(data); // Update ItemmasterData state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
      // Implement error handling here
    }
  };

  const maxSuggestionsToShow = 10;
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    // Filter data based on inputValue
    const filteredData = data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .slice(0, maxSuggestionsToShow);

    console.log(filteredData.slice(0, maxSuggestionsToShow));
    // const filteredData = data.filter((suggestion) =>
    //     suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    // ).slice(0, maxSuggestionsToShow);

    // Update suggestions state
    setSuggestions(filteredData);
    if (event.target.value === "") {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.name); // Assuming suggestion has a 'name' property
    setSuggestions([]);
  };

  console.log(props.data);

  const [SendData, setSendData] = useState([]);
  const [Releatedproduct, setReleatedproduct] = useState([]);

  const Fetchreletedproduct = async () => {
    setLoading(true);
    try {
      // Fetch related product data
      const data = await ItemMaster.getItemRelatedProdSetting(props.data.d1_Id);
      debugger;
      if (data) {
        // Assuming setRelatedproduct is a state updater function
        setReleatedproduct(data);
        // Prepare data to send
        const sendData = data.map((product) => ({
          masterType: 220,
          itemId: props.data.d1_Id || 0,
          selectedValue: product.selectedValue,
          extraField1: product.extraField1,
          extraField2: product.extraField2,
          extraField3: product.extraField3,
        }));

        setSendData(sendData);
        setLoading(false);
        // Now you can do something with sendData, like send it to a server
      } else {
        alert("Error fetching related product data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error fetching related product:", error);
      alert("Error fetching related product data");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    Fetchreletedproduct();
  }, []);
  const CheckReletedProductlist = (id) => {
    setSendData((prevState) => {
      // Check if the object with the given id already exists in the array
      const itemIndex = prevState.findIndex(
        (item) => item.selectedValue === String(id)
      );

      if (itemIndex !== -1) {
        // If the item exists, remove it from the array
        return prevState.filter((_, index) => index !== itemIndex);
      } else {
        // If the item does not exist, create a new object and add it to the array
        const newItem = {
          masterType: 220,
          itemId: props.data.d1_Id || 0,
          selectedValue: String(id),
          extraField1: "string",
          extraField2: "string",
          extraField3: "string",
        };

        console.log(newItem);
        return [...prevState, newItem];
      }
    });
  };

  const saveItemReletedProduct = async () => {
    setLoading(true);
    var savedata = await ItemMaster.SaveItemRelatedProdSetting(SendData);
    if (savedata) {
      setLoading(false);
      Fetchreletedproduct();
      alert("saved");
    } else {
      setLoading(false);
      alert("error ");
    }
  };

  const DeleteProduct = async (id) => {
    var data = await ItemMaster.deleteItemLinkedReletedLensProduct(id, 220);
    if (data) {
      console.log("Delete Sucessful");
      await Fetchreletedproduct();
    } else {
      alert("error");
    }
  };

  return (
    <div className="tab-content-5" style={{ marginTop: "20px" }}>
      {ItemMasterActive && (
        <div className="">
          <div className="company-info-top-im">
            <div className="company-info-top-left-tab5" style={{ border: "0" }}>
              <div className="input-box-Im">
                <div className="input-box-m1" style={{ position: "relative" }}>
                  <input
                    className="modify_im_input mode_category"
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    required
                    onBlur={removeSuggestion}
                  />
                  {suggestions.length > 0 && (
                    <div className="suggestion-box">
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                          className="suggestion-item"
                        >
                          {suggestion.name}
                        </div>
                      ))}
                    </div>
                  )}
                  <label className="modify_p_text2">Search Category</label>
                </div>
                <div className="input-box-m1">
                  <input
                    className="modify_im_input mode_limit mode_category"
                    type="text"
                    required
                    onChange={(event) => handleSearch(event)}
                  />
                  <label className="modify_lbl_text22 value_12">
                    Product Name/Id
                  </label>
                </div>
                <div className="input-box-m1">
                  <button
                    className="stock_search1"
                    onClick={searchItemWithGroup}
                  >
                    Search
                  </button>
                  {/* <div className="ButtonTabSave"> */}
                  {UserDetails?.canModifyMaster && (
                    <button
                      id="SaveBtnMain stock_search1"
                      onClick={saveItemReletedProduct}
                      className={`save-button-main-save hovered`}
                    >
                      Save
                      <i
                        id="step-2"
                        className="fa-regular fa-floppy-disk im-icon-white"
                      ></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="tabs-col-sm-1">
                <div
                  className="table-resposive"
                  style={{
                    maxHeight: "60vh",
                    overflowY: "scroll",
                    border: "1px solid #c2c2c2",
                  }}
                >
                  <table
                    id="tblTransactionList-rp"
                    className="tabs-table table-bordered1"
                    frame="box"
                  >
                    <thead
                      textAlign="center"
                      style={{ backgroundColor: "#e5f2ff" }}
                    >
                      <tr className="" style={{ height: "30px" }}>
                        <th style={{ minWidth: "70px" }}>S.No.</th>
                        <th style={{ minWidth: "70px" }}>Product Id</th>
                        <th style={{ minWidth: "70px" }}>Image</th>
                        <th style={{ minWidth: "70px" }}>Product Name</th>
                        <th style={{ minWidth: "70px" }}>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ItemmasterData.length === 0 ? (
                        <tr>{/* <td colSpan="5">No items found</td> */}</tr>
                      ) : (
                        ItemmasterData.map((item, index) => (
                          <tr key={index}>
                            <td>{(pageNo - 1) * ItemPerPage + (index + 1)}</td>
                            <td>{item.d1Id}</td>
                            <td style={{ width: "50px", height: "50px" }}>
                              {item.images[0] && (
                                <img
                                  src={`${imageBaseUrl}${item.images[0]}`}
                                  alt=""
                                  style={{ width: "50px" }}
                                />
                              )}
                            </td>
                            <td>{item.name}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={SendData.some(
                                  (data) =>
                                    Number(data.selectedValue) ===
                                    Number(item.d2Id)
                                )}
                                onChange={() =>
                                  CheckReletedProductlist(item.d2Id)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      )}
                      {/* {ItemmasterData.length !== 0 && ( */}
                      {/* )} */}
                    </tbody>
                  </table>
                  <tr className="pagination-tr">
                    {/* <td></td> */}
                    <td>
                      Item Per Page
                      <select
                        className="salepgpagination-select"
                        name=""
                        id=""
                        value={ItemPerPage}
                        onChange={ChangeItemPerPage}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        {/* <option value="200">200</option> */}
                        {/* <option value="300">300</option> */}
                        {/* <option value="500">500</option> */}
                      </select>
                    </td>
                    <td onClick={PreviousPage}>
                      <button className="salePrevNextBtn">
                        <i class="fa-solid fa-angle-left"></i>
                      </button>
                    </td>
                    <td>
                      <button className="PageNoPagination">{pageNo}</button>
                    </td>
                    <td onClick={NextPage}>
                      <button className="salePrevNextBtn">
                        <i class="fa-solid fa-angle-right"></i>
                      </button>
                    </td>
                  </tr>
                </div>
              </div>
            </div>
            <div className="company-info-top-right-tab5">
              <div className="company-info-top-right-tab5">
                <div className="company_info-text">
                  List Of All Related Product
                </div>
              </div>
              <div className="tabs-col-sm-1">
                <div className="table-resposive">
                  <table
                    id="tblTransactionList-rp"
                    className="tabs-table1 table-bordered1"
                    frame="box"
                  >
                    <thead
                      textAlign="center"
                      style={{ backgroundColor: "#e5f2ff" }}
                    >
                      <tr className="">
                        <th>S.No.</th>
                        <th>Product Id</th>
                        <th>Product Name</th>
                        <th>Image</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Releatedproduct.length === 0 ? (
                        <tr>
                          <td colSpan="5">
                            No items added in related products
                          </td>
                        </tr>
                      ) : (
                        Releatedproduct.map((item, index) => (
                          <tr key={index} className="">
                            <td>{index + 1}</td>
                            <td>{item.id}</td>
                            <td>{item.selectedItemName}</td>
                            <td>
                              {item.selectedImageId.length > 0 ? (
                                <img
                                  src={`${imageBaseUrl}${item.selectedImageId[0]}`}
                                  alt=""
                                  style={{ width: "50px", height: "50px" }}
                                />
                              ) : (
                                "No Image"
                              )}
                            </td>
                            <td>
                              <i
                                onClick={(e) => DeleteProduct(item.id)}
                                style={{ padding: "0" }}
                                className="fa-regular fa-trash-can TableDelIcon"
                              ></i>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RelatedProducts;
