import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Agent = {

  InsertAgent: async (data) => {
    //debugger;
    try {
      //const apiUrl = `${express_server_url}/api/Agent/Agent`;
      const apiUrl = `${WebApiUrl}/api/Agent/Agent`;
      console.log(apiUrl);
      console.log(data);
      const response = await axios.post(apiUrl, {
        name: data.name,
        printName: data.printName,
        address: data.address,
        state: parseInt(data.state, 10) || 0,
        telephone: data.telephoneNo,
        mobileNo: data.mobileNo,
        email: data.email,
        brokerageMode: data.brokerageMode,
        brokerage: data.brokerage,
        freezeBrokerage: data.freezeBrokerage,
        isConsultant: data.isConsultant,
        isActive: data.isActive,
      }, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return true;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  GetAgent: async () => {
    try {
      //const apiUrl = `${express_server_url}/api/Agent/GetAgents`;
      const apiUrl = `${WebApiUrl}/api/Agent/GetAgents`;
      const response = await axios.post(apiUrl, {
        filter: [],
        order: [
          {
            propertyName: "string",
            ascending: false
          }
        ],
        pageNumber: 0,
        pageSize: 50000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
       console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Get All ***************************
  GetAgentFilter: async () => {
    try {
      //const apiUrl = `${express_server_url}/api/Agent/GetAgents`;
      const apiUrl = `${WebApiUrl}/api/Agent/GetAgents`;
      const response = await axios.post(apiUrl, {
        filter: [],
        order: [
          {
            propertyName: "id",
            ascending: false
          }
        ],
        pageNumber: 0,
        pageSize: 500000
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
       console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Delete ****************************
  deleteAgent: async (agentId) => {
    try {
      //const apiUrl = `${express_server_url}/api/Agent?agentId=${agentId}`;
      const apiUrl = `${WebApiUrl}/api/Agent?agentId=${agentId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
       console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

  // ************************* Get By Id ****************************
  GetAgentById: async (id) => {
    try {
      //const apiUrl = `${express_server_url}/api/Agent?agentId=${id}`;
      const apiUrl = `${WebApiUrl}/api/Agent?agentId=${id}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
       console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },
  // *************************** Update ***************************

  updateAgent: async (data) => {
    console.log(data.id)
    try {
      //const apiUrl = `${express_server_url}/api/Agent?agentId=${data.id}`;
      const apiUrl = `${WebApiUrl}/api/Agent?agentId=${data.id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, {
        id: data.id,
        name: data.name,
        printName: data.printName,
        address: data.address,
        state: parseInt(data.state, 10) || 0,
        telephone: data.telephoneNo,
        mobileNo: data.mobileNo,
        email: data.email,
        brokerageMode: data.brokerageMode,
        brokerage: data.brokerage,
        freezeBrokerage: data.freezeBrokerage,
        isConsultant: data.isConsultant,
        isActive: data.isActive,
      }, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // **********************{Insert Agent Setting}************************

  insertCreateAgentMasterSetting: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/Agent/CreateAgentMasterSetting`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          addressDetails: parseBoolean(data.addressDetails),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return true;
    } catch (error) {
         console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get Agent Setting All ***************************
  getGetAgentMasterSettings: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Agent/getGetAgentMasterSettings`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
       console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
    }
  },

};

export default Agent;
