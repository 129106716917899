import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryAndTimeSlot = {
  // ********************************* Get *********************************
  GetDeliveryAndTimeSlot: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/GetDeliveryAndTimeSlots`;
        const response = await axios.post(
          apiUrl, {},
          {
            withCredentials: true,
          }
        );
        // console.log(apiUrl);
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ********************************* Get *********************************
  GetDeliveryAndTimeSlotFilter: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/GetDeliveryAndTimeSlots`;
        const response = await axios.post(
          apiUrl, {
          filter: [],
          order: [
            {
              propertyName: "id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 500000
        },
          {
            withCredentials: true,
          }
        );
        // console.log(apiUrl);
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        }
        else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ********************************* Insert *********************************
  InsertDeliveryAndTimeSlot: async (data, checkedIds) => {
    //debugger
    console.log(data)
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot`;
      const response = await axios.post(
        apiUrl,
        {
          deliveryPointName: data.deliveryPointName || 1,
          orderType: data.orderType,
          mobile: data.mobile,
          website: data.website,
          serviceTax: data.serviceTax,
          delvryDistRangeFromKM: data.delvryDistRangeFromKM,
          delvryDistRangeToKM: data.delvryDistRangeToKM,
          minDelvryAmtInCart: data.minDelvryAmtInCart,
          deliveryCharges: data.deliveryCharges,
          estimateDelvryTime: data.estimateDelvryTime,
          noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot,
          deliveryStatus: parseBoolean(data.deliveryStatus),
          deliveryTimeSlots: checkedIds,
          isActive: true,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // ********************************* Get By Id *********************************
  GetDeliveryAndTimeSlotById: async (DeliveryAndTimeSlotId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        console.log("get by id", DeliveryAndTimeSlotId);
        const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
        const response = await axios.get(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // ********************************* delete *********************************
  deleteDeliveryAndTimeSlot: async (DeliveryAndTimeSlotId) => {
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // ********************************* update *********************************
  updateDeliveryAndTimeSlot: async (data, checkedIds) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          deliveryPointName: data.deliveryPointName || 1,
          orderType: data.orderType,
          mobile: data.mobile,
          website: data.website,
          serviceTax: data.serviceTax,
          delvryDistRangeFromKM: data.delvryDistRangeFromKM,
          delvryDistRangeToKM: data.delvryDistRangeToKM,
          minDelvryAmtInCart: data.minDelvryAmtInCart,
          deliveryCharges: data.deliveryCharges,
          estimateDelvryTime: data.estimateDelvryTime,
          noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot,
          deliveryStatus: parseBoolean(data.deliveryStatus),
          deliveryTimeSlots: checkedIds,
          isActive: true,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // ********************************* Get Allotted Delivery Time Slots *********************************
  getAllottedDeliveryTimeSlots: async (DeliveryAndTimeSlotId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/getAllottedDeliveryTimeSlots`;
        const response = await axios.post(
          apiUrl,
          {
            deliveryPointName: DeliveryAndTimeSlotId.deliveryPointName,
            orderType: DeliveryAndTimeSlotId.orderType,
            distance: DeliveryAndTimeSlotId.distance,
            isCurrentDate: DeliveryAndTimeSlotId.isCurrentDate,
            deliveryStatus: false,
            isActive: true,
          },
          {
            withCredentials: true,
          }
        );
        // console.log(apiUrl);
        console.log("Response:", response.data.result);
        return response.data.result;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

};

export default DeliveryAndTimeSlot;
