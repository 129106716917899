import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  useParams,
  Redirect,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Joyride from "react-joyride";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import LensInfoForInvoice from "../../Props/LensInfoForInvoice";
import ProductList from "../../Props/ProductList";
import BillPaymentInvoice from "../../Props/BillPaymentInvoice";
import AccountMaster from "../../Master/AccountMaster/AccountMaster";
import PreviousOrder from "../../Dashboard/PreviousOrder";
import BillSeriesAPI from "../../../API/APIServices/BillSeriesMaster";
import BillNumberingAPI from "../../../API/APIServices/BillNumbering";
import BillLayoutAPI from "../../../API/APIServices/BillLayoutSetting";
import BillTypeAPI from "../../../API/APIServices/BillType";
import GodownAPI from "../../../API/APIServices/Godown";
import AgentAPI from "../../../API/APIServices/Agent";
import AccountAPI from "../../../API/APIServices/Account";
import StatusAPI from "../../../API/APIServices/Status";
import BillSundryAPI from "../../../API/APIServices/BillSundry";
import TransactionTypeSettingAPI from "../../../API/APIServices/TransactionTypeSetting";
import MobileBottomButton from "../../Props/MobileBottomButton";
import Transaction from "../../../API/APIServices/Transaction";
import { Alert } from "bootstrap";
import BillSeriesMaster from "../../../API/APIServices/BillSeriesMaster";
import StateInfo from "../../../API/APIServices/StateInfo";
import VoucherConfigurationAndOtherSettingController from "../../../API/APIServices/VoucherConfigurationAndOtherSettingController";
import Email from "../../../API/APIServices/Email";
import SMS from "../../../API/APIServices/SMS";
import ItemMaster from "../../../API/APIServices/ItemMaster";
import Account from "../../../API/APIServices/Account";
import CouponMasterApi from "../../../API/APIServices/CouponMaster";
import ReportDesign from "../../../Restaurant/Orders/ReportDesign";
import BatchWiseModalBox from "../../Props/BatchWiseModalBox";
import ItemWiseBatchList from "../../Props/ItemWiseBatchList";
import PopUpSerialNumber from "../../Props/PopUpSerialNumber";
import TransactionTypeSetting from "../../../API/APIServices/TransactionTypeSetting";
import ReportDesignForA4 from "../../../Restaurant/Orders/ReportDesignForA4";
import ItemGroupMaster from "../../Master/InvertoryMaster/ItemGroupMaster";
import ConvertInvoiceAndSynchDataController from "../../../API/APIServices/ConvertInvoiceAndSynchDataController";
import Company from "../../../API/APIServices/Company";
import SundryDetailsData from "../../Props/SundryDetailsData";
import AlertBox from "../../Props/AlertBox";
import SaleInvoiceAlert from "../../Props/Dropdown/SaleInvoiceAlert";
import SmsTemplate from "../../../API/APIServices/SmsTemplate";

/**********************Start From(Shivendra 06062024)**********************/

function SaleInvoice(props) {
  const nextInput = useRef([]);
  const inputRefsForBill = useRef([]);
  const inputRefs = useRef([]);
  const nextCellRefs = useRef([]);
  const nextCellRefsProduct = useRef([]);
  const mainPageInputRef = useRef(null);
  const mainPageInputFocus2 = useRef(null);
  const lensPageInputRef = useRef(null);
  const prodPageInputRef = useRef(null);
  const lensinforef = useRef(null);
  const [ReferenceNumber, setReferenceNumber] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [vouchNoIds, setVouchNoIds] = useState([]);
  const [batchWise, setbatchWise] = useState(false);
  const [ItemWisebatch, setItemWisebatch] = useState(false);
  const [PopUpSerial, setPopUpSerial] = useState(false);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [count, setcount] = useState(false);
  const [serialNoData, setSerialNoData] = useState([]);
  const [BatchNoData, setBatchNoData] = useState([
    {
      id: 0,
      batchNo: "",
      mgfDate: "",
      expDate: "",
      qty: 0,
      purPrice: 0,
      salePrice: 0,
      mrp: 0,
      costPrice: 0,
      opPacks: 0,
      opLoose: 0,
      opValue: 0,
      vc1: "",
      vc2: "",
      vc3: "",
      vc4: "",
      vc5: "",
      vc6: "",
      vc7: "",
      vc8: "",
      vc9: "",
      vc10: "",
      checked: false,
      AvaliableQty: 0,
    },
  ]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    console.log(BatchNoData);
  }, [BatchNoData]);

  const {
    ConversionTransId,
    IsConversion,
    transTypeT1,
    ConvertType,
    DBIdT1,
    TableId,
  } = location?.state || {};

  const validIds = ["14", "15", "70", "72"];

  const handleDelivDayChange = async (delivDay, save) => {
    // // // debugger;
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + delivDay);
    if (!isNaN(delivDay)) {
      setInputValues((prev) => ({
        ...prev,
        DeliveryDate: newDate?.toISOString().split("T")[0],
      }));
    }
    if (save) {
      await onChangeSaveMethod();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      // // debugger;
      if (childRef.current) {
        if (typeof childRef.current.CloseSettingModal === "function") {
          childRef.current.CloseSettingModal();
        }

        if (
          typeof nextInput.current.closePopUpForLensSelection === "function"
        ) {
          nextInput.current.closePopUpForLensSelection();
        }

        if (typeof mainPageInputRef.current.hideModal3 === "function") {
          mainPageInputRef.current.hideModal3();
        }
        // if (PartyDetailModal == true) {
        ClosePartyModal();
        // }

        // if (AccountMasterModal == true) {
        closeAccountMasterModal();
        // }
      }
      return;
    }

    if (event.altKey && event.key.toLowerCase() === "y") {
      // // debugger;
      nextInput.current[8].focus();
    }
    if (event.altKey && event.key.toLowerCase() === "n") {
      // // debugger;
      nextCellRefsProduct.current[0][3].focus();
    }
    if (event.altKey && event.key.toLowerCase() === "t") {
      // // debugger;
      nextCellRefs.current[0][1].focus();
    }
    if (event.altKey && event.key.toLowerCase() === "l") {
      // // debugger;
      lensPageInputRef.current.focus();
    }
    if (event.altKey && event.key.toLowerCase() === "b") {
      // // debugger;
      inputRefsForBill.current[0].focus();
    }
    if (event.altKey && event.key.toLowerCase() === "d") {
      // // debugger;
      event.preventDefault();
      nextInput.current[100].focus();
    }
  };

  useEffect(() => {
    // Add event listener when component is mounted
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener when component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // useEffect(() => {
  //   alert(modalOpen);
  // }, [modalOpen]);

  const batchModalOpen = () => {
    setbatchWise(!batchWise);
    setModalOpen(!modalOpen);
  };

  const [inputValuesPaidAmount, setInputValuesPaidAmount] = useState([
    {
      remark: "",
      assignToShow: "",
      accountId: "",
      accountTitle: "",
      PayAmt: "",
      dt: "",
    },
  ]);

  const itemWiseBatchOpen = (Rowidx) => {
    setItemWisebatch(!ItemWisebatch);
    setModalOpen(!modalOpen);
    if (Rowidx) {
      const nextColumnIndex = 24;
      let nextRowCellRef =
        nextCellRefsProduct.current[Rowidx - 1][nextColumnIndex];

      if (!nextRowCellRef) {
        for (let i = nextColumnIndex; i < 35; i++) {
          const cellRef = nextCellRefsProduct.current[Rowidx - 1][i];
          if (cellRef) {
            nextRowCellRef = cellRef;
            break;
          }
        }
      }

      if (nextRowCellRef) {
        nextRowCellRef.focus();
        nextRowCellRef.select();
      }
    }
  };

  const PopUpSerialOpen = (Rowidx) => {
    setPopUpSerial(!PopUpSerial);
    setModalOpen(!modalOpen);
    if (Rowidx) {
      const nextColumnIndex = 24;
      let nextRowCellRef =
        nextCellRefsProduct.current[Rowidx - 1][nextColumnIndex];

      if (!nextRowCellRef) {
        for (let i = nextColumnIndex; i < 35; i++) {
          const cellRef = nextCellRefsProduct.current[Rowidx - 1][i]; // Assuming index 0 for the first column
          if (cellRef) {
            nextRowCellRef = cellRef;
            break;
          }
        }
      }

      if (nextRowCellRef) {
        nextRowCellRef.focus();
        nextRowCellRef.select();
      }
    }
  };

  const toggleSlider = () => {
    setSliderOpen(!isSliderOpen);
  };

  const [ProductOpnedInPopup, setProductOpnedInPopup] = useState([]);

  useEffect(() => {
    console.log(ProductOpnedInPopup);
  }, [ProductOpnedInPopup]);

  const [inputPosition, setInputPosition] = useState(null);

  const getPosition = (rowIndex, columnIndex) => {
    const inputRef = nextCellRefs.current[rowIndex]?.[columnIndex];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom, // Position below the input
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const titles = {
    12: "Sale Invoice",
    13: "Sale Return",
    14: "Purchase",
    15: "Purchase Return",
    16: "Material Issue",
    17: "Material Received",
    18: "Stock Transfer",
    19: "Payment",
    20: "Receipt",
    21: "Journal",
    22: "Contra",
    23: "Debit Note",
    24: "Credit Note",
    53: "Stock In",
    55: "Stock Out",
    57: "Rx Order",
    63: "Rx Sale Ret",
    65: "Rx Purchase Ret",
    70: "Purchase Order",
    71: "Sale Order",
    72: "Purchase Challan",
    73: "Sale Challan",
    122: "Product Exchange",
  };

  /*********************{Button Script}*********************/

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const [inputValues, setInputValues] = useState({
    TransType: id,
    transId: 0,
    TransName: "Sale",
    billSeriesMasterType: 29,
    billSeriesId: 0,
    billSeriesName: "",
    billTaxTypeId: 0,
    billTaxTypeName: "",
    billDate: new Date().toISOString().split("T")[0],
    billNumber: "",
    godownId: 0,
    godownName: "",
    bookedById: 0,
    bookedByName: "",
    DeliveryDate: "",
    vochStatusId: 931,
    vochStatusName: "",
    billDeliveryDate: "",
    refBillNumber: "",
    Remark: "",
    Remark1: "",
    Remark2: "",
    Remark3: "",
    TotalQty: 0,
    TotalPrice: 0,
    TotalDisc: 0,
    TotalItemAmt: 0,
    TotalMrp: 0,
    TotalSundry: 0,
    LoyalityAmt: 0,
    CouponAmt: 0,
    TaxableAmt: 0,
    GrossAmt: 0,
    PaidAmt: 0,
    DueAmt: 0,
    AdjstAmt: 0,
    isActive: true,
    isDefault: false,
  });

  useEffect(() => {
    console.log(inputValues);
  }, [inputValues]);

  const [itemMasterRows, setItemMasterRows] = useState(
    Array(4).fill({
      rowIndex: 0,
      d1_Id: 0,
      d2_Id: 0,
      productName: "",
      parentGrp: "",
      parentGrpName: "",
      productUnitName: "",
      productUnit: "",
      productAltUnit: "",
      unitConvFactor: "",
      enableBarcode: true,
      defaultBarcode: "",
      hsnCode: "",
      shortDescription: "",
      taxCategory1: "",
      taxCat1_Cgst: "",
      taxCat1_Sgst: "",
      taxCat1_Igst: "",
      taxCat1_Cess: "",
      taxLimit: "",
      taxCategory2: "",
      taxCat2_Cgst: "",
      taxCat2_Sgst: "",
      taxCat2_Igst: "",
      taxCat2_Cess: "",
      taxAmt_Cgst: "",
      taxAmt_Sgst: "",
      taxAmt_Igst: "",
      taxAmt_Cess: "",
      Qty: "",
      FreeQty: "",
      purchasePrice: "",
      purchaseDiscount: "",
      saleProfit: "",
      salePrice: "",
      saleDiscount: "",
      itemPrice: "",
      costPrice: "",
      mrpPrice: "",
      enableSerialNo: false,
      enableBatchNo: false,
      DiscPer: "",
      DoubleDis: "",
      DiscAmt: "",
      amt_after_discount: "",
      totalDisc_With_sundry: "",
      itemWiseTaxbleAmt: "",
      TotalAmt: "",
      purPageSalePrice: "",
      availableQuantity: "",
      iCateRateA: "",
      iCateRateB: "",
      iCateRateC: "",
      iCateRateD: "",
      iCateRateE: "",
      iCateRateF: "",
      iCateRateG: "",
      iCateRateH: "",
      iCateRateI: "",
      iCateRateJ: "",
      loyaltyPoint: "",
      salesManItemIncentive: "",
      BrkrName: "",
      BrkgAmt: "",
      sellerCommision: "",
      isReturnable: "",
      isStockable: "",
      isGoodsService: false,
      lens_Eye: "",
      lens_SPH: "",
      lens_CYL: "",
      lens_AXIS: "",
      lens_Dia: "",
      lens_Add: "",
      Attribute1: "",
      Attribute2: "",
      Attribute3: "",
      Attribute4: "",
      Attribute5: "",
      Note1: "",
      Note2: "",
      batchNos: [],
      serialNumbers: [],
      BatchNo: "",
      MgfDt: "",
      ExpDt: "",
      productCategory: "",
      isAttributeItem: true,
      iAttributeParent: "",
      batchNos: [],
      itemOptionalFields: [],
    })
  );

  const [BillSeriesSet, setBillSeries] = useState([]);

  const [TaxTypeSet, setTaxType] = useState([]);
  const [SelectedTaxTypeData, setSelectedTaxType] = useState([]);

  const [sundryDiscDistri, setSundryDiscDistri] = useState(false);

  const [stateInfoMain, setStateInfoMain] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [LensInfoShow, setLensInfoShow] = useState(false);
  const [TransactionTypeSetting, setTransactionTypeSetting] = useState([]);

  useEffect(() => {
    console.log(TransactionTypeSetting, LensInfoShow, allSettings);
  }, [TransactionTypeSetting, LensInfoShow, allSettings]);

  useEffect(() => {
    // console.logg("allSettings", allSettings);p
    if (allSettings.length > 0) {
      debugger;

      const lensInfo = allSettings.find(
        (s) => s.masterSettName === "IsLensInfoShow"
      );
      setLensInfoShow(lensInfo.enableDisable);
    }
  }, [allSettings]);

  useEffect(() => {
    const getAllSettings = async () => {
      // setLoading(true);

      try {
        const Data =
          await VoucherConfigurationAndOtherSettingController.GetVoucherConfigurationAndOtherSettingController();
        debugger;
        if (Data) {
          setAllSettings(Data.result);
        } else {
          alert("Error while creating and updating the data");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
        alert("An error occurred while fetching the settings.");
      }
    };

    getAllSettings();

    // User.login()
    const fetchData = async () => {
      // setLoading(true);

      try {
        const state = await StateInfo.GetAllState();
        setStateInfoMain(state);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    const GetDettingTransactionById = async () => {
      // setLoading(true);

      var filterdata = [
        {
          fieldName: "transType",
          operatorName: "equal",
          compareValue: id || 12,
        },
      ];

      var data =
        await TransactionTypeSettingAPI.getTransactionTypeSettingFilter(
          filterdata
        );
      // // debugger;
      if (data && data.length > 0) {
        setTransactionTypeSetting(data[0]);
      }
    };

    GetDettingTransactionById();
  }, []);

  useEffect(() => {
    if (SelectedTaxTypeData.length > 0) {
      const sundryDiscDistriData = SelectedTaxTypeData[0].sundryDiscDistri;
      setSundryDiscDistri(sundryDiscDistriData);
    }
  }, [SelectedTaxTypeData]);

  const [GodownSet, setGodown] = useState([]);
  const [AgentSet, setAgent] = useState([]);
  const [StatusMasterList, setStatusMasterList] = useState([]);
  const [IsExistTransInputSet, setIsExistTransInput] = useState(false);
  const [TransInputSet, setTransInput] = useState({
    masterType: 217,
    transType: inputValues.TransType,
    transName: inputValues.TransName,
    title: false,
    dateOfBirth: false,
    anniversaryDate: false,
    pos: false,
    accBal: false,
    creditLimit: false,
    referredBy: false,
    bookedBy: false,
    godown: false,
    deliveryStatus: false,
    deliveryDate: false,
    remark: false,
    remark1: false,
    remark2: false,
    remark3: false,
    extraField1: false,
    extraField2: false,
    extraField3: false,
    extraField4: false,
    extraField5: false,
  });

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen11, setIsDropdownOpen11] = useState(false);
  const [AccountMasterList, setAccountMasterList] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [filteredAccountWithMobile, setFilteredAccountWithMobile] = useState(
    []
  );
  const [InputValueAccMaster, setInputValueAccMaster] = useState({
    AccId: 214,
    namePrefix: "",
    AccName: "CASH",
    AccPhoneNo: "",
    AccDob: "",
    AccAnn: "",
    AccPOS: "",
    AccBalance: "",
    AccCreditLimit: "",
    AccAddress: "",
    AccStateId: 0,
    AccState: "",
    AccRefById: 0,
    AccRefferedBy: "",
    parentGrp: 212,
  });

  useEffect(() => {
    if (
      InputValueAccMaster.AccId != 0 &&
      ReferenceNumber != "" &&
      !isEdit &&
      !IsConversion
    ) {
      onChangeSaveMethod();
    }
    if (nextInput.current && nextInput.current[8]) {
      nextInput.current[8].focus();
    }
  }, [ReferenceNumber]);

  useEffect(() => {
    console.log("InputValueAccMaster", InputValueAccMaster);
  }, [InputValueAccMaster]);

  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [filteredRefferedBy, setFilteredRefferedBy] = useState([]);

  const [sundryList, setSundryList] = useState([]);
  const [sundryRows, setSundryRows] = useState([
    {
      rowIndex: 0,
      id: 0,
      name: "",
      printName: "",
      sundryType: 0,
      methodApplied: "",
      absolutePercentAmount: false,
      defaultValue: 0,
      percentOfAmount: 100,
      applyOnBill: 1,
      roundOffSundryAmount: false,
      amtRoundOffConfig: 0,
      amtAdjustSaleAccount: false,
      selectedAccountSale: 0,
      amtAdjustPurchaseAccount: false,
      selectedAccountPurchase: 0,
      roundOffLimit: 0,
      Percent: "",
      Amount: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredSundry: [],
    },
  ]);

  // useEffect({console.log(sundryRows),} [sundryRows]);

  useEffect(() => {
    console.log(sundryRows);
  }, [sundryRows]);

  useEffect(() => {
    console.log(InputValueAccMaster);
  }, [InputValueAccMaster]);

  const GetDataForAll = async () => {
    setTimeout(async () => {
      await GetTransBillDataFunction();
      await GetProductDataFunction();
      await GetBillSundryeDataFunction();
      // await GetAccountDataFunction();
    }, 200);
  };

  const [BillSerisMasterData, setBillSerisMasterData] = useState([]);
  const [TransactionLListForPayment, setTransactionLListForPayment] = useState(
    []
  );
  const [adjamountToSend, setAdjamountToSend] = useState();
  const [adjamountRemark, setAdjamountRemark] = useState();

  useEffect(() => {
    console.log(BillSerisMasterData);
    console.log(TransactionLListForPayment);
  }, [BillSerisMasterData, TransactionLListForPayment]);

  const GetbillMastersType = async () => {
    debugger;
    var ID = [13, 20, 23, 24];
    // setLoading(true);

    var data = await Transaction.GetTransactionVchSrsInfo(ID);
    if (data && data.length > 0) {
      setBillSerisMasterData(data);
    }
  };

  const [CallProductData, setCallProductData] = useState(false);

  const GetTransactionSummaryBySrsId = async (updatedList) => {
    console.log("t4Values");
    debugger;
    var t4Values;
    if (updatedList && updatedList.length > 0) {
      t4Values = updatedList
        .filter((row) => row.hasOwnProperty("checked") && row.checked === true)
        .map((row) => parseInt(row.t4));
    } else {
      t4Values = BillSerisMasterData.filter(
        (row) => row.hasOwnProperty("checked") && row.checked === true
      ).map((row) => parseInt(row.t4)); // Extract t4 values and convert to integers
    }
    if (t4Values.length > 0) {
      var data = await Transaction.GetTransactionSummaryBySrsId(
        InputValueAccMaster.AccId,
        t4Values
      );

      if (data && data.length > 0) {
        var data = await Transaction.GetTransactionSummaryBySrsId(
          InputValueAccMaster.AccId,
          t4Values
        );

        if (data && data.length > 0) {
          setcount(!count);
          setTransactionLListForPayment((prevList) => {
            if (!prevList || prevList.length === 0) {
              // If no previous data, just save the new data
              return data;
            }

            // If there is previous data, update the matching items based on id
            const updatedList = prevList.map((item) => {
              const matchedItem = data.find(
                (newItem) => newItem.id === item.id
              );
              return matchedItem ? { ...item, ...matchedItem } : item;
            });

            // Add new items that don't exist in the previous list
            const newItems = data.filter(
              (newItem) => !prevList.some((item) => item.id === newItem.id)
            );

            // Return the combined list of updated and new items
            return [...updatedList, ...newItems];
          });

          setCallProductData(true);
        }
      }
    } else {
      setTransactionLListForPayment([]);
      setCallProductData(false);
    }
  };

  useEffect(() => {
    GetbillMastersType();
    // GetTransactionSummaryBySrsId();
  }, []);

  const GetTransBillDataFunction = async () => {
    setLoading(true);
    var TransData = await Transaction.GetTransBillData(
      location?.state?.refID || ReferenceNumber
    );
    // // // debugger;
    if (TransData) {
      setLoading(true);

      var data = await AccountAPI.getAccountById(TransData.partyId_D1);
      debugger;
      var AccData = data.item1[0];
      if (AccData) {
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: AccData.d2Id,
          namePrefix: AccData.namePrefix || "",
          AccName: AccData.name || "",
          AccPhoneNo: AccData.mobileNo || "", //email enableLoyalty namePrefix
          AccDob: AccData.dob || "",
          AccAnn: AccData.dom || "",
          AccPOS: AccData.stateId || "",
          // AccBalance: AccData.openingCrDr || "",
          AccCreditLimit: AccData.creditlimit || "",
          AccAddress: AccData.address || "",
          AccStateId: AccData.stateId || "",
          AccState: AccData.stateName || "",
          AccRefById: TransData.refById || "",
          AccRefferedBy: TransData.refByName || "",
        }));
        setLoading(false);
      }

      setInputValues((prevValues) => ({
        ...prevValues,
        TransType: TransData.transtype || "",
        transId: TransData.transId || 0,
        TransName: TransData.TransName || "",
        billSeriesMasterType: TransData.billSeriesMasterType || "",
        billSeriesId: TransData.billSeriesId || "",
        billSeriesName: TransData.billSeriesName || "",
        billTaxTypeId: TransData.billTypeId || "",
        billTaxTypeName: TransData.billTypeName || "",
        billDate: TransData.billDate
          ? new Date(TransData.billDate).toLocaleDateString("en-CA") // Keeps YYYY-MM-DD format
          : "",
        billNumber: TransData.billNumber || "",
        godownId: TransData.godownId || "",
        godownName: TransData.godownName || "",
        bookedById: TransData.bookedById || "",
        bookedByName: TransData.bookedByName || "",
        DeliveryDate: TransData.deliveryDate
          ? new Date(TransData.deliveryDate).toLocaleDateString("en-CA") // Keeps YYYY-MM-DD format
          : "",
        vochStatusId: TransData.billStatus || 931,
        vochStatusName: TransData.billStatusName || "Pending",
        Remark: TransData.billRemark || "",
        Remark1: [TransData.billRemark1] || "",
        Remark2: [TransData.billRemark2] || "",
        Remark3: [TransData.billRemark3] || "",
        AccAddress: TransData.ecomDelAddress || "",
        paymentStatus: TransData.paymentStatus || "",
        isEcomInvoice: TransData.isEcomInvoice || "",
        ecomDelAddress: TransData.ecomDelAddress || "",
        ecomBillAddress: TransData.ecomBillAddress || "",
        ecomDelContact: TransData.ecomDelContact || "",
      }));

      if (TransData.ecomDelAddress && TransData.ecomDelAddress != "") {
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccAddress: TransData.ecomDelAddress,
        }));
      }
      setLoading(false);
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  const [pageNo, setPageNo] = useState(1);
  const [ItemPerPage, setItemPerPage] = useState(50);

  const NextPage = () => {
    const totalPages = Math.ceil(itemMasterRows.length / ItemPerPage);
    // const nextPage = pageNo + 1;
    // setPageNo(nextPage);
    if (pageNo < totalPages) {
      setPageNo(pageNo + 1);
    } else {
      alert("Already on the last page");
    }
  };

  // const PreviousPage = () => {
  //   const prevPage = pageNo - 1;
  //   if (prevPage > 0) {
  //     setPageNo(prevPage);
  //   } else {
  //     alert("Already on frist page");
  //   }
  // };

  const PreviousPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    } else {
      alert("Already on the first page");
    }
  };

  useEffect(() => {
    if (ReferenceNumber) {
      GetProductDataFunction();
    }
  }, [pageNo, ItemPerPage]);

  const GetProductDataFunction = async (NewRowIndex) => {
    // // debugger;
    setLoading(true);
    var filter;
    if (NewRowIndex == undefined || NewRowIndex == 0 || NewRowIndex == "") {
      filter = {
        minRowIndex: ItemPerPage * (pageNo - 1),
        maxRowIndex: ItemPerPage * pageNo,
      };
    }
    if (NewRowIndex) {
      filter = {
        minRowIndex: NewRowIndex,
        maxRowIndex: NewRowIndex,
      };
    }

    var Productdata = await Transaction.GetProductTempData(
      location?.state?.refID || ReferenceNumber,
      filter
    );
    // debugger;
    // if (Productdata) {
    var data = Productdata?.items;
    if (data && data.length > 0) {
      console.log(data);

      var AmountData = Productdata?.transSummary;
      // console.logg(AmountData);
      if (AmountData) {
        setInputValues((prevValues) => ({
          ...prevValues,
          TotalQty: AmountData?.transTotalQty || 0,
          TotalPrice: AmountData?.transTotalPrice || 0,
          TotalDisc: AmountData?.transTotalDisc || 0,
          TotalItemAmt: AmountData?.transTotalPrice || 0,
          TotalSundry: AmountData?.transTotalSundryAmt || 0,
          TaxableAmt: AmountData?.transTaxable || 0,
          GrossAmt: AmountData?.transGrossValue || 0,
          // PaidAmt: AmountData?.transPaidAmount|| 0,
          // DueAmt: AmountData?.transBalanceAmount|| 0,
          AdjstAmt: AmountData?.adjstAmt || 0,
        }));
      }

      if (filter.maxRowIndex == filter.minRowIndex) {
        if (data.length < 4) {
          var ProductDataList = {
            rowIndex: Number(data[0].indx) || 0,
            defaultBarcode: data[0].itmCode || "",
            d2_Id: Number(data[0].itmId_d2) || "",
            productName: data[0].itemName || "",
            parentGrp: Number(data[0].itmGrpId_d2) || "",
            parentGrp2: Number(data[0].itmGrpId_d2) || "",
            parentGrpName: data[0].itmGrpName || "",
            godownId: Number(data[0].godownId) || "",
            godownName: data[0].godownName || "",
            masterDescription: data[0].masterDescriptn || "",
            shortDescription: data[0].itemDescriptn || "",
            batchNos: data[0].batchNos || [],
            serialNumbers: data[0].serialNumbers || [],
            mgfDate: data[0].mgfDate || "",
            expDate: data[0].expDate || "",
            productUnitName: data[0].unitIdName || "",
            productUnit: Number(data[0].unitId) || "",
            Qty: Number(data[0].qty) || "",
            FreeQty: Number(data[0].freeQty) || "",
            itemPrice: Number(data[0].priceUnit) || "",
            DiscPer: Number(data[0].disc1Per) || "",
            DiscAmt: Number(data[0].disc1Amt) || "",
            DoubleDis: Number(data[0].disc2Per) || "",
            discountAmt2: Number(data[0].disc2Amt) || "",
            enableSerialNo: data[0].enableSerialNo || false,
            enableBatchNo: data[0].enableBatchNo || false,
            salePrice: Number(data[0].salePrice) || "",
            TotalAmt: Number(data[0].itmNetAmt) || "",
            mrpPrice: Number(data[0].itmMrp) || "",
            purPageSaleProfPer: Number(data[0].purPageSaleProfPer) || "",
            purPageSalePrice: Number(data[0].purPageSalePrice) || "",
            purPageMrpPrice: Number(data[0].purPageMrpPrice) || "",
            productCategory: data[0].productCategory || "",
            lens_Eye: data[0].lensEye || "",
            lens_Dia: data[0].lensDia || "",
            lens_AXIS: Number(data[0].lensAXIS) || "",
            lens_SPH: Number(data[0].lensSPH) || "",
            lens_CYL: Number(data[0].lensCYL) || "",
            lens_Add: Number(data[0].lensADD) || "", // Swapped 'lens_Add' and 'add'
            extraFields: data[0].extraFields || [],
          };
          setItemMasterRows((prevRows) => {
            const newRows = [...prevRows]; // Create a copy of the existing rows
            const existingRowIndex = newRows.findIndex(
              (row) => row.rowIndex === ProductDataList.rowIndex
            );

            if (existingRowIndex !== -1) {
              // Replace the existing row with the new data
              newRows[existingRowIndex] = ProductDataList;
            } else {
              // If no matching row is found, add the new row
              newRows.push(ProductDataList);
            }

            return newRows; // Update the state with the modified rows
          });

          setLoading(false);
          return true;
        }
      } else {
        // Check if the length of the data is less than 4
        if (data.length <= 4) {
          var ProductDataList = [];

          for (var i = 0; i < data?.length; i++) {
            var obj = {
              rowIndex: Number(data[i].indx) || 0,
              defaultBarcode: data[i].itmCode || "",
              d2_Id: Number(data[i].itmId_d2) || "",
              productName: data[i].itemName || "",
              parentGrp: Number(data[i].itmGrpId_d2) || "",
              parentGrp2: Number(data[i].itmGrpId_d2) || "",
              parentGrpName: data[i].itmGrpName || "",
              godownId: Number(data[i].godownId) || "",
              godownName: data[i].godownName || "",
              masterDescription: data[i].masterDescriptn || "",
              shortDescription: data[i].itemDescriptn || "",
              batchNos: data[i].batchNos || [],
              serialNumbers: data[i].serialNumbers || [],
              mgfDate: data[i].mgfDate || "",
              expDate: data[i].expDate || "",
              productUnitName: data[i].unitIdName || "",
              productUnit: Number(data[i].unitId) || "",
              Qty: Number(data[i].qty) || "",
              FreeQty: Number(data[i].freeQty) || "",
              itemPrice: Number(data[i].priceUnit) || "",
              DiscPer: Number(data[i].disc1Per) || "",
              DiscAmt: Number(data[i].disc1Amt) || "",
              DoubleDis: Number(data[i].disc2Per) || "",
              discountAmt2: Number(data[i].disc2Amt) || "",
              enableSerialNo: data[i].enableSerialNo || false,
              enableBatchNo: data[i].enableBatchNo || false,
              salePrice: Number(data[i].salePrice) || "",
              TotalAmt: Number(data[i].itmNetAmt) || "",
              mrpPrice: Number(data[i].itmMrp) || "",
              purPageSaleProfPer: Number(data[i].purPageSaleProfPer) || "",
              purPageSalePrice: Number(data[i].purPageSalePrice) || "",
              purPageMrpPrice: Number(data[i].purPageMrpPrice) || "",
              productCategory: data[i].productCategory || "",
              lens_Eye: data[i].lensEye || "",
              lens_Dia: data[i].lensDia || "",
              lens_AXIS: Number(data[i].lensAXIS) || "",
              lens_SPH: Number(data[i].lensSPH) || "",
              lens_CYL: Number(data[i].lensCYL) || "",
              lens_Add: Number(data[i].lensADD) || "", // Swapped 'lens_Add' and 'add'
              extraFields: data[i].extraFields || [],
            };
            ProductDataList.push(obj);
          }

          // Add empty/default objects until the list has at least 4 rows
          while (ProductDataList.length <= ItemPerPage) {
            ProductDataList.push({
              rowIndex: 0,
              d1_Id: 0,
              d2_Id: 0,
              productName: "",
              parentGrp: "",
              parentGrpName: "",
              productUnitName: "",
              productUnit: "",
              productAltUnit: "",
              unitConvFactor: "",
              enableBarcode: true,
              defaultBarcode: "",
              hsnCode: "",
              shortDescription: "",
              taxCategory1: "",
              taxCat1_Cgst: "",
              taxCat1_Sgst: "",
              taxCat1_Igst: "",
              taxCat1_Cess: "",
              taxLimit: "",
              taxCategory2: "",
              taxCat2_Cgst: "",
              taxCat2_Sgst: "",
              taxCat2_Igst: "",
              taxCat2_Cess: "",
              taxAmt_Cgst: "",
              taxAmt_Sgst: "",
              taxAmt_Igst: "",
              taxAmt_Cess: "",
              Qty: "",
              FreeQty: "",
              purchasePrice: "",
              purchaseDiscount: "",
              saleProfit: "",
              salePrice: "",
              saleDiscount: "",
              itemPrice: "",
              costPrice: "",
              mrpPrice: "",
              DiscPer: "",
              DoubleDis: "",
              DiscAmt: "",
              amt_after_discount: "",
              totalDisc_With_sundry: "",
              itemWiseTaxbleAmt: "",
              TotalAmt: "",
              purPageSalePrice: "",
              availableQuantity: "",
              iCateRateA: "",
              iCateRateB: "",
              iCateRateC: "",
              iCateRateD: "",
              iCateRateE: "",
              iCateRateF: "",
              iCateRateG: "",
              iCateRateH: "",
              iCateRateI: "",
              iCateRateJ: "",
              loyaltyPoint: "",
              salesManItemIncentive: "",
              BrkrName: "",
              BrkgAmt: "",
              sellerCommision: "",
              isReturnable: "",
              isStockable: "",
              isGoodsService: false,
              lens_Eye: "",
              lens_SPH: "",
              lens_CYL: "",
              lens_AXIS: "",
              lens_Dia: "",
              lens_Add: "",
              Attribute1: "",
              Attribute2: "",
              Attribute3: "",
              Attribute4: "",
              Attribute5: "",
              Note1: "",
              Note2: "",
              BatchNo: "",
              MgfDt: "",
              ExpDt: "",
              isAttributeItem: true,
              iAttributeParent: "",
              batchNos: [],
              itemOptionalFields: [],
            });
          }

          // ;
          setItemMasterRows(ProductDataList);
          setLoading(false);
          return true;
        }

        // If data length is 4 or more, do nothing
        else {
          var ProductDataList = [];

          for (var i = 0; i < data?.length; i++) {
            var obj = {
              rowIndex: Number(data[i].indx) || 0,
              defaultBarcode: data[i].itmCode || "",
              d2_Id: Number(data[i].itmId_d2) || 0,
              productName: data[i].itemName || "",
              parentGrp: Number(data[i].itmGrpId_d2) || null,
              parentGrpName: data[i].itmGrpName || "",
              godownId: Number(data[i].godownId) || null,
              godownName: data[i].godownName || "",
              masterDescription: data[i].masterDescriptn || "",
              shortDescription: data[i].itemDescriptn || "",
              batchNo: Number(data[i].batchNo) || "",
              mgfDate: data[i].mgfDate || "",
              expDate: data[i].expDate || "",
              productUnitName: data[i].unitIdName || "",
              batchNos: data[i].batchNos || [],
              serialNumbers: data[i].serialNumbers || [],
              enableSerialNo: data[i].enableSerialNo || false,
              enableBatchNo: data[i].enableBatchNo || false,
              productUnit: Number(data[i].unitId) || null,
              Qty: Number(data[i].qty) || null,
              FreeQty: Number(data[i].freeQty) || null,
              // itemPrice:
              //   id == 12
              //     ? Number(data[i].purPageSalePrice) || 0
              //     : Number(data[i].priceUnit) || 0,
              itemPrice: Number(data[i].priceUnit) || null,
              productCategory: data[i].productCategory || "",
              DiscPer: Number(data[i].disc1Per) || null,
              DiscAmt: Number(data[i].disc1Amt) || null,
              DoubleDis: Number(data[i].disc2Per) || null,
              discountAmt2: Number(data[i].disc2Amt) || null,
              salePrice: Number(data[i].salePrice) || null,
              TotalAmt: Number(data[i].itmNetAmt) || null,
              mrpPrice: Number(data[i].itmMrp) || null,
              purPageSaleProfPer: Number(data[i].purPageSaleProfPer) || null,
              purPageSalePrice: Number(data[i].purPageSalePrice) || null,
              purPageMrpPrice: Number(data[i].purPageMrpPrice) || null,
              lens_Eye: data[i].lensEye || "",
              lens_Dia: data[i].lensDia || "",
              lens_AXIS: Number(data[i].lensAXIS) || null,
              lens_SPH: Number(data[i].lensSPH) || null,
              lens_CYL: Number(data[i].lensCYL) || null,
              lens_Add: Number(data[i].lensADD) || null,
              extraFields: data[i].extraFields || [],
            };
            ProductDataList.push(obj);
          }
          // Directly set the data without modifications

          // if (data.length > ItemPerPage) {
          // ProductDataList.push({
          //   rowIndex: 0,
          //   d1_Id: 0,
          //   d2_Id: 0,
          //   productName: "",
          //   parentGrp: null,
          //   parentGrpName: "",
          //   productUnitName: "",
          //   productUnit: null,
          //   productAltUnit: null,
          //   unitConvFactor: null,
          //   enableBarcode: true,
          //   defaultBarcode: "",
          //   hsnCode: "",
          //   shortDescription: "",
          //   taxCategory1: null,
          //   taxCat1_Cgst: null,
          //   taxCat1_Sgst: null,
          //   taxCat1_Igst: null,
          //   taxCat1_Cess: null,
          //   taxLimit: null,
          //   taxCategory2: null,
          //   taxCat2_Cgst: null,
          //   taxCat2_Sgst: null,
          //   taxCat2_Igst: null,
          //   taxCat2_Cess: null,
          //   taxAmt_Cgst: null,
          //   taxAmt_Sgst: null,
          //   taxAmt_Igst: null,
          //   taxAmt_Cess: null,
          //   Qty: null,
          //   FreeQty: null,
          //   purchasePrice: null,
          //   purchaseDiscount: null,
          //   saleProfit: null,
          //   salePrice: null,
          //   saleDiscount: null,
          //   itemPrice: null,
          //   costPrice: null,
          //   mrpPrice: null,
          //   DiscPer: null,
          //   DoubleDis: null,
          //   DiscAmt: null,
          //   amt_after_discount: null,
          //   totalDisc_With_sundry: null,
          //   itemWiseTaxbleAmt: null,
          //   TotalAmt: null,
          //   purPageSalePrice: null,
          //   availableQuantity: null,
          //   iCateRateA: null,
          //   iCateRateB: null,
          //   iCateRateC: null,
          //   iCateRateD: null,
          //   iCateRateE: null,
          //   iCateRateF: null,
          //   iCateRateG: null,
          //   iCateRateH: null,
          //   iCateRateI: null,
          //   iCateRateJ: null,
          //   loyaltyPoint: null,
          //   salesManItemIncentive: null,
          //   BrkrName: null,
          //   BrkgAmt: null,
          //   sellerCommision: null,
          //   isReturnable: null,
          //   isStockable: null,
          //   isGoodsService: false,
          //   lens_Eye: "",
          //   lens_SPH: null,
          //   lens_CYL: null,
          //   lens_AXIS: null,
          //   lens_Dia: null,
          //   lens_Add: null,
          //   Attribute1: "",
          //   Attribute2: "",
          //   Attribute3: "",
          //   Attribute4: "",
          //   Attribute5: "",
          //   Note1: "",
          //   Note2: "",
          //   BatchNo: "",
          //   MgfDt: "",
          //   ExpDt: "",
          //   isAttributeItem: true,
          //   iAttributeParent: null,
          //   batchNos: [],
          //   itemOptionalFields: [],
          // });

          setItemMasterRows(ProductDataList);
          setLoading(false);
          return true;
        }
      }
    }
    // Processing AmountData regardless of the length of Productdata.items
    // }
    else {
      var ProductDataList = [];
      for (let index = 0; index < ItemPerPage; index++) {
        ProductDataList.push({
          rowIndex: 0,
          d1_Id: 0,
          d2_Id: 0,
          productName: "",
          parentGrp: "",
          parentGrpName: "",
          productUnitName: "",
          productUnit: "",
          productAltUnit: null,
          unitConvFactor: null,
          enableBarcode: true,
          defaultBarcode: "",
          hsnCode: "",
          shortDescription: "",
          taxCategory1: null,
          taxCat1_Cgst: null,
          taxCat1_Sgst: null,
          taxCat1_Igst: null,
          taxCat1_Cess: null,
          taxLimit: null,
          taxCategory2: null,
          taxCat2_Cgst: null,
          taxCat2_Sgst: null,
          taxCat2_Igst: null,
          taxCat2_Cess: null,
          taxAmt_Cgst: null,
          taxAmt_Sgst: null,
          taxAmt_Igst: null,
          taxAmt_Cess: null,
          Qty: null,
          FreeQty: null,
          purchasePrice: null,
          purchaseDiscount: null,
          saleProfit: null,
          salePrice: null,
          saleDiscount: null,
          itemPrice: null,
          costPrice: null,
          mrpPrice: null,
          DiscPer: null,
          DoubleDis: null,
          DiscAmt: null,
          amt_after_discount: null,
          totalDisc_With_sundry: null,
          itemWiseTaxbleAmt: null,
          TotalAmt: null,
          purPageSalePrice: null,
          availableQuantity: null,
          iCateRateA: null,
          iCateRateB: null,
          iCateRateC: null,
          iCateRateD: null,
          iCateRateE: null,
          iCateRateF: null,
          iCateRateG: null,
          iCateRateH: null,
          iCateRateI: null,
          iCateRateJ: null,
          loyaltyPoint: null,
          salesManItemIncentive: null,
          BrkrName: null,
          BrkgAmt: null,
          sellerCommision: null,
          isReturnable: null,
          isStockable: null,
          isGoodsService: false,
          lens_Eye: "",
          lens_SPH: null,
          lens_CYL: null,
          lens_AXIS: null,
          lens_Dia: null,
          lens_Add: null,
          Attribute1: "",
          Attribute2: "",
          Attribute3: "",
          Attribute4: "",
          Attribute5: "",
          Note1: "",
          Note2: "",
          BatchNo: "",
          MgfDt: "",
          ExpDt: "",
          isAttributeItem: true,
          iAttributeParent: null,
          batchNos: [],
          itemOptionalFields: [],
        });
      }

      setItemMasterRows(ProductDataList);
      return true;
    }
    setLoading(false);
  };
  const [addRowEnable, setAddRowEnable] = useState(false);

  const GetBillSundryeDataFunction = async () => {
    setLoading(true);

    var SundryData = await Transaction.GetSundryTempData(
      location?.state?.refID || ReferenceNumber
    );
    debugger;

    if (SundryData) {
      setLoading(false);

      console.log(SundryData);

      var data = SundryData?.result?.items;
      // debugger;

      var SundryDataList = [];

      for (var i = 0; i < data?.length; i++) {
        var obj = {
          rowIndex: Number(data[i].RowIndex) || 0,
          id: Number(data[i].SundryId) || 0,
          name: data[i].SundryName,
          printName: data[i].SundryName,
          sundryType: 0,
          methodApplied: data[i].SundryNature,
          absolutePercentAmount: false,
          defaultValue: 0,
          percentOfAmount: 100,
          applyOnBill: 1,
          roundOffSundryAmount: false,
          amtRoundOffConfig: 0,
          amtAdjustSaleAccount: false,
          selectedAccountSale: 0,
          amtAdjustPurchaseAccount: false,
          selectedAccountPurchase: 0,
          roundOffLimit: 0,
          Percent: parseFloat(Number(data[i].SundryPer).toFixed(2)) || "",
          Amount: parseFloat(Number(data[i].SundryAmt).toFixed(2)) || "",
          isDropdownOpen: false,
          highlightedIndex: -1,
          filteredSundry: [],
        };
        SundryDataList.push(obj);
      }

      // Create a blank object with the same structure
      if (addRowEnable) {
        var blankObj = {
          rowIndex: 0,
          id: 0,
          name: "",
          printName: "",
          sundryType: 0,
          methodApplied: "",
          absolutePercentAmount: false,
          defaultValue: 0,
          percentOfAmount: 100,
          applyOnBill: 1,
          roundOffSundryAmount: false,
          amtRoundOffConfig: 0,
          amtAdjustSaleAccount: false,
          selectedAccountSale: 0,
          amtAdjustPurchaseAccount: false,
          selectedAccountPurchase: 0,
          roundOffLimit: 0,
          Percent: "",
          Amount: "",
          isDropdownOpen: false,
          highlightedIndex: -1,
          filteredSundry: [],
        };
        // Push the blank object to the list
        SundryDataList.push(blankObj);
        setAddRowEnable(false);
      }

      if (SundryDataList.length > 0) {
        // setLoading(false);
        setSundryRows(SundryDataList);
      } else {
        setSundryRows([
          {
            rowIndex: 0,
            id: 0,
            name: "",
            printName: "",
            sundryType: 0,
            methodApplied: "",
            absolutePercentAmount: false,
            defaultValue: 0,
            percentOfAmount: 100,
            applyOnBill: 1,
            roundOffSundryAmount: false,
            amtRoundOffConfig: 0,
            amtAdjustSaleAccount: false,
            selectedAccountSale: 0,
            amtAdjustPurchaseAccount: false,
            selectedAccountPurchase: 0,
            roundOffLimit: 0,
            Percent: "",
            Amount: "",
            isDropdownOpen: false,
            highlightedIndex: -1,
            filteredSundry: [],
          },
        ]);
        setAddRowEnable(false);
      }

      var AmountData = SundryData.result?.transSummary;
      // console.logg(AmountData);
      if (AmountData) {
        setInputValues((prevValues) => ({
          ...prevValues,
          TotalQty: AmountData?.transTotalQty || 0,
          TotalPrice: AmountData?.transTotalPrice || 0,
          TotalDisc: AmountData?.transTotalDisc || 0,
          TotalItemAmt: AmountData?.transTotalPrice || 0,
          TotalSundry: AmountData?.transTotalSundryAmt || 0,
          TaxableAmt: AmountData?.transTaxable || 0,
          GrossAmt: AmountData?.transGrossValue || 0,
          // PaidAmt: AmountData?.transPaidAmount|| 0,
          // DueAmt: AmountData?.transBalanceAmount|| 0,
          AdjstAmt: AmountData?.adjstAmt || 0,
        }));
      }
      // ;
      // setLoading(false);
    } else {
      console.log("error");
      setLoading(false);
      // ;
    }
  };

  const GetAccountDataFunction = async () => {
    try {
      debugger;
      if (
        inputValuesPaidAmount[0].accountId == "" &&
        CallProductData == false
      ) {
        return;
      }
      const Data = await Transaction.GetAccountTempData(
        location?.state?.refID || ReferenceNumber
      );
      const AccData = Data?.result;
      console.log(inputValuesPaidAmount);
      if (AccData) {
        setLoading(false);
        // debugger;
        // Clone the existing state to avoid direct mutations
        const currentData = [...inputValuesPaidAmount];

        // Loop through AccData.amt to update or add entries
        AccData.amt?.forEach((item) => {
          const obj = {
            remark: item.remark,
            assignToShow: "",
            accountId: item.accountId_d2,
            accountTitle: item.accountName,
            PayAmt: item.amt,
            dt: "",
          };

          // Find the index of an existing object with the same accountId
          const index = currentData.findIndex(
            (existing) => existing.accountId === item.accountId_d2
          );

          if (index > -1) {
            // Update existing object
            currentData[index] = { ...currentData[index], ...obj };
          } else {
            // Add new object
            currentData.push(obj);
          }
        });

        setAdjamountToSend(AccData.vch.amt);
        setAdjamountRemark(AccData.vch.remark);
        const vouchSeriesIds = AccData.vch.inv.map(
          (item) => item.vouchSeriesId
        );
        // setTransactionLListForPayment(vouchSeriesIds);

        // for()

        if (BillSerisMasterData.length > 0) {
          const updatedBillSeriesMasterData = BillSerisMasterData.map(
            (item) => ({
              ...item, // Keep existing properties
              checked: vouchSeriesIds.includes(parseInt(item.t4))
                ? true
                : false, // Add `checked: true` if t4 is in vouchSeriesIds
            })
          );
          setBillSerisMasterData(updatedBillSeriesMasterData);
        }
        const vouchNoId = AccData.vch.inv.map((item) => item.transId);
        setVouchNoIds(vouchNoId);

        setInputValuesPaidAmount(currentData);
      } else {
        console.error("No data found in AccData");
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  useEffect(() => {
    debugger;
    setTimeout(() => {
      if (TransactionLListForPayment.length > 0 && vouchNoIds.length > 0) {
        // setcount(!count);
        const updatedTransactionLListForPayment =
          TransactionLListForPayment.map((item) => ({
            ...item, // Keep existing properties
            checked: vouchNoIds.includes(parseInt(item.id)) ? true : false, // Add `checked: true` if t4 is in vouchSeriesIds
          }));
        setTransactionLListForPayment(updatedTransactionLListForPayment);
      }
    }, 300);
  }, [vouchNoIds, count]);

  useEffect(() => {
    GetAccountDataFunction();
  }, [inputValuesPaidAmount[0].accountId]);

  useEffect(() => {
    // // debugger;
    if (!id) {
      // Navigate to "/saleinvoice/12" if no ID is found in the URL
      navigate("/saleinvoice/12");
    } else {
      // Check if location.state exists and refID is available
      if (location.state && location.state.refID) {
        setReferenceNumber(location.state.refID);
      } else {
        GetTransRefNo();
      }
      if (location.state && location.state.isEdit) {
        setIsEdit(location.state.isEdit);
        GetDataForAll();
      }
      if (location.state && location.state.transId) {
        setTransId(location.state.transId);
      }
    }
  }, [id, navigate, location]);

  const GetTransRefNo = async () => {
    var RefNoData = await Transaction.GetTransRefNo(id);
    // // // // debugger;
    if (RefNoData.isSuccess === true) {
      setReferenceNumber(RefNoData.result.referenceNumber);
    } else {
      alert("Error while getting Transaction Reference No.");
    }
  };

  const GetDataForConversion = async (ReferenceNumber, ConversionTransId) => {
    try {
      //;
      setLoading(true);
      if (ConvertType === 3) {
        //;
        const conversionData =
          await ConvertInvoiceAndSynchDataController.SendTransDataToConvert(
            ReferenceNumber,
            ConversionTransId,
            DBIdT1, //from branch id DBIdT1
            companyData.id, //to branch id
            ConvertType,
            id // Trans Type To which conversion is going to be done ForEx (12 For Sale)
          );
        if (conversionData.isSuccess === true) {
          setTimeout(async () => {
            GetProductDataFunction();
            GetTransBillDataFunction();
            GetBillSundryeDataFunction();
            if (lensinforef.current) {
              await lensinforef.current.GetLensData();
            }
          }, 100);
        }
      } else {
        const conversionData =
          await ConvertInvoiceAndSynchDataController.SendTransDataToConvert(
            ReferenceNumber,
            ConversionTransId,
            companyData.id,
            companyData.id,
            ConvertType,
            id // Trans Type To which conversion is going to be done ForEx (12 For Sale)
          );

        if (conversionData.isSuccess === true) {
          setTimeout(async () => {
            GetProductDataFunction();
            GetTransBillDataFunction();
            GetBillSundryeDataFunction();
            if (lensinforef.current) {
              await lensinforef.current.GetLensData();
            }
          }, 100);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    if (
      location.state &&
      location.state?.ConversionTransId !== null &&
      ReferenceNumber &&
      location.state.IsConversion === true
    ) {
      // Fetch all the data for conversion
      GetDataForConversion(ReferenceNumber, ConversionTransId);
    }
  }, [ReferenceNumber, ConversionTransId, IsConversion]);

  useEffect(() => {
    const fetchAllList = async () => {
      setLoading(true);
      // // // debugger;

      try {
        const filterData1 = [
          {
            fieldName: "parentGrpMasterId",
            operatorName: "equal",
            compareValue: id,
          },
        ];
        if (inputValues.billSeriesMasterType) {
          setLoading(true);
          const result1 = await BillSeriesAPI.getBillSeriesMasterFiltered(
            filterData1
          );
          if (result1.length > 0) {
            // // debugger;
            var filterActive = result1.filter((data) => data.isActive === true);
            setBillSeries(filterActive);
            var filterDefault = filterActive.filter(
              (data) => data.isDefault === true
            );
            setInputValues((prevState) => ({
              ...prevState, // Spread the existing state
              billSeriesId:
                filterDefault[0]?.billSeriesId || filterDefault[0]?.id || null, // Update or set a default value
            }));

            const SelectedTaxTypeData = TaxTypeSet.filter(
              (item) =>
                item.id == filterDefault[0]?.billSeriesId ||
                filterDefault[0]?.id ||
                null
            );
            if (SelectedTaxTypeData.length == 1) {
              setSelectedTaxType(SelectedTaxTypeData);
            }
          }
        }
        var VariabletoSend = 12;
        if (
          inputValues.TransType == 12 ||
          inputValues.TransType == 13 ||
          inputValues.TransType == 71 ||
          inputValues.TransType == 73
        ) {
          VariabletoSend = 12;
        } else if (
          inputValues.TransType == 14 ||
          inputValues.TransType == 15 ||
          inputValues.TransType == 70 ||
          inputValues.TransType == 72
        ) {
          VariabletoSend = 14;
        }
        const filterData2 = [
          {
            fieldName: "billType",
            operatorName: "equal",
            compareValue: VariabletoSend,
          },
        ];

        if (TaxTypeSet && TaxTypeSet.length == 0) {
          setLoading(true);
          BillTypeAPI.GetFilteredBillType(filterData2).then(function (result1) {
            // // debugger;
            var filterActive = result1.filter((data) => data.isActive === true);
            if (filterActive.length > 0) {
              setTaxType(filterActive);
            }
            var filterDefault = filterActive.filter(
              (data) => data.isDefault === true
            );
            if (filterDefault.length > 0) {
              // // // debugger;
              if (inputValues.billTaxTypeId == 0) {
                setInputValues((prevState) => ({
                  ...prevState, // Spread the existing state
                  billTaxTypeId: filterDefault[0]?.id || null, // Update or set a default value
                }));
              }
            }
          });
        }

        if (GodownSet && GodownSet.length == 0) {
          setLoading(true);
          GodownAPI.getGodown().then(function (result1) {
            setGodown(result1);
          });
        }
        if (AgentSet && AgentSet.length == 0) {
          setLoading(true);
          AgentAPI.GetAgent().then(function (result1) {
            setAgent(result1);
          });
        }
        if (StatusMasterList && StatusMasterList.length == 0) {
          setLoading(true);
          StatusAPI.GetStatus().then(function (result1) {
            setStatusMasterList(result1);
          });
        }

        TransactionTypeSettingAPI.GetVoucherWise_InputSetting(
          inputValues.TransType
        ).then(function (result1) {
          setLoading(true);
          if (result1 && result1.length > 0) {
            setTransInput(result1[0]);
            setIsExistTransInput(true);
          }
          //setTransInput(result1[0]);
        });

        if (sundryList && sundryList.length == 0) {
          setLoading(true);
          BillSundryAPI.GetBillSundry().then(function (result1) {
            // // // debugger;
            setSundryList(result1);
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchAllList();
  }, [id]);

  const [loading, setLoading] = useState(true);
  const {
    OpenAlertBoxForSalePage,
    alertBoxForSalePage,
    OpenAlertBoxEdit,
    alertBoxEdit,
    companyData,
    imageBaseUrl,
    debounce,
    // BusinessType,
    messageFunction,
  } = useContext(MyContext);

  var currencyDecimalPlaces = companyData?.currencyDecimalPlaces || 2;

  /***********title***************/
  const title = titles[id] || "Default Title";

  useEffect(() => {
    document.title = title;
  }, [id, title]);

  /************Image add******************/
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener("contextmenu", handleContextMenu);

    //Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  /********************Tab*******************/

  const [activeTab, setActiveTab] = useState(11); // Initialize the active tab index
  const [activeTab1, setActiveTab1] = useState(1); // Initialize the active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleTabClick1 = (index) => {
    setActiveTab1(index);
  };
  const [tabcontent11, setTabContent11] = useState(true);
  const [tabcontent22, setTabContent22] = useState(false);
  //const [tabcontent33, setTabContent33] = useState(false);
  const [tabcontent44, setTabContent44] = useState(false);

  const showtabs11 = () => {
    setTabContent11(true);
    setTabContent22(false);
    //setTabContent33(false)
    setTabContent44(false);
  };
  const showtabs22 = () => {
    setTabContent11(false);
    setTabContent22(true);
    setTabContent44(false);
  };
  const showtabs44 = () => {
    setTabContent11(false);
    setTabContent22(false);
    setTabContent44(true);
  };

  {
    /********************Tab*******************/
  }

  /************modal box************/

  const [isModalOpen1, setModalOpen1] = useState(false);

  const showModal1 = () => {
    setModalOpen1(true);
  };
  const hideModal1 = () => {
    setModalOpen1(false);
  };

  const [isModalOpen2, setModalOpen2] = useState(false);
  const showModal2 = () => {
    setModalOpen2(true);
  };
  const hideModal2 = () => {
    setModalOpen2(false);
  };
  /**********************{modal box}**********************/

  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  /*****************Onboarding code Open************************/

  const [{ run, steps }] = useState({
    run: true,
    steps: [
      {
        content: <h2>Let's begin our journey!</h2>,
        locale: { skip: <strong>SKIP</strong> },
        placement: "center",
        target: "body",
      },
      {
        content: <h2>Upload Item Image!</h2>,
        placement: "bottom",
        target: "#step-1",
        title: "First step",
      },
      {
        content: <h2>Here is second step!</h2>,
        placement: "bottom",
        target: "#step-2",
        title: "Second step",
      },
      {
        content: <h2>Here is third step!</h2>,
        placement: "bottom",
        target: "#step-3",
        title: "Third step",
      },
      {
        content: <h2>Here is fourth step!</h2>,
        placement: "bottom",
        target: "#step-4",
        title: "Fourth step",
      },
      {
        content: <h2>Here is fifth step!</h2>,
        placement: "bottom",
        target: "#step-5",
        title: "Fifth step",
      },
      {
        content: <h2>Here is six step!</h2>,
        placement: "bottom",
        target: "#step-6",
        title: "Six step",
      },
    ],
  });

  /*****************Onboarding code Close************************/

  /*************************** Party Detail ***************************************/

  const [PartyDetailModal, setPartyDetailModal] = useState(false);
  const OpenPartyModal = () => {
    setPartyDetailModal(true);
    setModalOpen(true);
  };
  const ClosePartyModal = () => {
    setPartyDetailModal(false);
    setModalOpen(false);
  };

  const [MobileSettingSlider, setMobileSettingSlider] = useState(false);

  const bottomSliderOpen = () => {
    setMobileSettingSlider(true);
    setModalOpen(true);
  };
  const closeBottomSlider = () => {
    setMobileSettingSlider(false);
    setModalOpen(false);
  };

  const [CouponModal, setCouponModal] = useState(false);
  const [CouponModalData, setCouponModalData] = useState([]);

  const openCouponModal = () => {
    setCouponModal(true);
    setModalOpen(true);
  };
  const closeCouponModal = () => {
    setCouponModal(false);
    setModalOpen(false);
  };

  const ApplyCouppon = async (CouponData) => {
    if (inputValues.TotalItemAmt > 0) {
      console.log(CouponData);
      // // // debugger;
      var data = [
        {
          rowIndex: 0,
          id: 961,
          adjustmentInItem: true,
          Percent:
            CouponData.discountType == "Percentage"
              ? CouponData.discountValue
              : 0,
          Amount:
            CouponData.discountType != "Percentage"
              ? CouponData.discountValue
              : 0,
        },
      ];

      var SundryTempData = await Transaction.SetSundryTempData(
        data,
        ReferenceNumber
      );
      if (SundryTempData.isSuccess === false) {
        console.log("API call failed. Response:", SundryTempData);
      } else if (SundryTempData.isSuccess === true) {
        console.log("Sundry Temp Data saved successfully.");
        closeCouponModal();

        // NewRowIndex should contain the new row index to be updated
        var NewRowIndex = SundryTempData.result;

        // if (NewRowIndex.length === 1) {
        //   // Update sundryRows with the new row index
        //   setSundryRows((prevRows) => {
        //     return prevRows.map((row, idx) => {
        //       if (idx === Index) {
        //         return { ...row, rowIndex: NewRowIndex[0] };
        //       }
        //       return row;
        //     });
        //   });
        // }

        GetBillSundryeDataFunction();
      } else if (SundryTempData === "no item") {
        console.log("No Item found in the response.");
      } else {
        console.log("Error in API response: ", SundryTempData);
      }
    } else {
      alert("Add product frist");
    }
  };

  const [DiscountData, setDiscountData] = useState({
    index: 0,
    id: 0,
    discountPercentage: 0,
    discountAmount: 0,
  });

  useEffect(() => {
    console.log(DiscountData);
  }, [DiscountData]);

  useEffect(() => {
    if (sundryRows.length > 0 && sundryRows[0].id != 0) {
      // // debugger;
      console.log("1st");

      const FillDataInDiscount = () => {
        // setLoading(true);
        // // debugger;
        var filterSundryPer = sundryRows.filter(
          (item) =>
            item.methodApplied === "Discount" &&
            item.absolutePercentAmount == false
        );
        if (filterSundryPer.length > 0) {
          setDiscountData((prevValues) => ({
            ...prevValues,
            index: filterSundryPer[0].rowIndex,
            // id: filterSundryPer[0].id,
            discountPercentage: filterSundryPer[0].Percent,
            discountAmount: filterSundryPer[0].Amount,
          }));
        } else {
          var filterSundryDis = sundryRows.filter(
            (item) =>
              item.methodApplied === "Discount" &&
              item.absolutePercentAmount == true
          );
          if (filterSundryDis.length > 0) {
            setDiscountData((prevValues) => ({
              ...prevValues,
              index: filterSundryDis[0].rowIndex,
              // id: filterSundryDis[0].id,
              discountAmount: filterSundryDis[0].Amount,
              discountPercentage: filterSundryDis[0].Percent,
            }));
          }
        }
      };
      FillDataInDiscount();
    }
  }, [sundryRows]);

  const setDiscountDataValue = (event) => {
    const { name, value } = event.target;
    // console.log(`Changing ${name} to ${value}`);
    if (name == "discountPercentage") {
      var filterSundryPer = sundryList.filter(
        (item) =>
          item.methodApplied === "Discount" &&
          item.absolutePercentAmount == false
      );
      setDiscountData((prevValues) => ({
        ...prevValues,
        id: filterSundryPer[0].id,
        [name]: value,
      }));
    } else if (name == "discountAmount") {
      // // // debugger;
      console.log("2nd");
      var filterSundryDis = sundryList.filter(
        (item) =>
          item.methodApplied === "Discount" &&
          item.absolutePercentAmount == true
      );
      setDiscountData((prevValues) => ({
        ...prevValues,
        id: filterSundryDis[0].id,
        [name]: value,
      }));
    }
  };

  const ApplyDiscountInPercentage = async (e) => {
    // // // debugger;
    const { name, value } = e.target;

    setEditMode((prevState) => ({
      ...prevState,
      [e.target.name]: false,
    }));

    if (value == 0) {
      return;
    }
    if (inputValues.TotalItemAmt > 0) {
      // // // debugger;
      var data = [
        {
          rowIndex: DiscountData.index || 0,
          id: DiscountData.id || 0,
          adjustmentInItem: true,
          Percent: value,
          Amount: 0,
        },
      ];

      var SundryTempData = await Transaction.SetSundryTempData(
        data,
        ReferenceNumber
      );
      if (SundryTempData.isSuccess === false) {
        console.log("API call failed. Response:", SundryTempData);
      } else if (SundryTempData.isSuccess === true) {
        console.log("Sundry Temp Data saved successfully.");
        closeCouponModal();

        // NewRowIndex should contain the new row index to be updated
        var NewRowIndex = SundryTempData.result;

        // if (NewRowIndex.length === 1) {
        //   // Update sundryRows with the new row index
        //   setSundryRows((prevRows) => {
        //     return prevRows.map((row, idx) => {
        //       if (idx === Index) {
        //         return { ...row, rowIndex: NewRowIndex[0] };
        //       }
        //       return row;
        //     });
        //   });
        // }

        GetBillSundryeDataFunction();
      } else if (SundryTempData === "no item") {
        console.log("No Item found in the response.");
      } else {
        console.log("Error in API response: ", SundryTempData);
      }
    } else {
      alert("Add product frist");
    }
  };

  const ApplyDiscountInAmount = async (e) => {
    // // // debugger;
    const { name, value } = e.target;

    setEditMode((prevState) => ({
      ...prevState,
      [e.target.name]: false,
    }));

    if (value == 0) {
      return;
    }
    if (inputValues.TotalItemAmt > 0) {
      // // // debugger;
      var data = [
        {
          rowIndex: DiscountData.index || 0,
          id: DiscountData.id || 0,
          adjustmentInItem: true,
          Percent: 0,
          Amount: value,
        },
      ];

      var SundryTempData = await Transaction.SetSundryTempData(
        data,
        ReferenceNumber
      );
      if (SundryTempData.isSuccess === false) {
        console.log("API call failed. Response:", SundryTempData);
      } else if (SundryTempData.isSuccess === true) {
        console.log("Sundry Temp Data saved successfully.");
        closeCouponModal();

        // NewRowIndex should contain the new row index to be updated
        var NewRowIndex = SundryTempData.result;

        // if (NewRowIndex.length === 1) {
        //   // Update sundryRows with the new row index
        //   setSundryRows((prevRows) => {
        //     return prevRows.map((row, idx) => {
        //       if (idx === Index) {
        //         return { ...row, rowIndex: NewRowIndex[0] };
        //       }
        //       return row;
        //     });
        //   });
        // }

        GetBillSundryeDataFunction();
      } else if (SundryTempData === "no item") {
        console.log("No Item found in the response.");
      } else {
        console.log("Error in API response: ", SundryTempData);
      }
    } else {
      alert("Add product frist");
    }
  };

  const [editMode, setEditMode] = useState({});

  // useEffect(() => {
  //   console.log(editMode);
  // }, [editMode]);

  const handleInputChangeEdit = (e) => {
    setTimeout(() => {
      e.target.select();
    }, 100);
    if (e.target.name == "Amount") {
      setAddRowEnable(true);
    }
    console.log("focus", e.target.name);

    setEditMode((prevState) => ({
      ...prevState,
      [e.target.id]: true,
    }));

    setDiscountDataValue(e);
  };

  const handleBlurForEdit = (e, rowIndex1, rowIndex) => {
    console.log("Blur", e.target.id);
    if (e.target.name == "Amount") {
      setAddRowEnable(true);
    }
    setEditMode((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));

    // You can also include your other logic for onBlur if needed
    onChangeSundrySaveMethod(e, rowIndex1, rowIndex);
  };

  useEffect(() => {
    const getCouponMaster = async () => {
      setLoading(true);
      var data = await CouponMasterApi.getCouponMaster();
      if (data.length > 0) {
        setCouponModalData(data);
      } else {
        console.error("error while finding coupon data");
      }
    };
    getCouponMaster();
  }, []);

  // const ApplyTaxClick = () => {
  //   const val = document.getElementById("BillType").value;
  //   console.log(val);

  //   var newRows;
  //   if (val === "12% GST(L)") {
  //     newRows = [
  //       { id: 1, TaxName: "CGST", Percent: "6%", Amount: "500" },
  //       { id: 2, TaxName: "SGST", Percent: "6%", Amount: "500" },
  //     ];
  //     setSundryRows([...newRows]);
  //   } else if (val === "12% GST(I)") {
  //     newRows = [{ id: 1, TaxName: "IGST", Percent: "12%", Amount: "1000" }];
  //     setSundryRows([...newRows]);
  //   }
  // };
  const [ItemGroupModal, setItemGroupModal] = useState(false);
  const [parentGrpName, setparentGrpName] = useState("");

  const openGroupModal = async (row) => {
    debugger;
    console.log(row);
    setparentGrpName(row.parentGrpName);
    // // debugger;
    setItemGroupModal(true);
    setModalOpen(true);
    setIsDropdownOpen1(false);
  };

  const closeGroupModal = () => {
    setModalOpen(false);
    setItemGroupModal(false);
  };

  const [AccountMasterModal, setAccountMasterModal] = useState(false);
  const [selectedAccData, setSelectedAccData] = useState({});
  const openAccountMasterModal = async (val) => {
    // // debugger;
    if (val === "Add") {
      setAccountMasterModal(true);
      // setModalOpen(true);
      // document.getElementById('PartySelectBox').value = "";
      setIsDropdownOpen1(false);
    } else {
      var selectedAccData = await Account.getAccountById(val);
      console.log("selectedAccData", selectedAccData.item1);
      setSelectedAccData(selectedAccData.item1);
      setAccountMasterModal(true);
      setModalOpen(true);
      //document.getElementById('PartySelectBox').value = "";
      setIsDropdownOpen1(false);
    }
  };

  const closeAccountMasterModal = (data) => {
    // // debugger;
    setModalOpen(false);
    setAccountMasterModal(false);
    if (data) {
      var str = data.result;
      if (str) {
        const result = str.split(",").reduce((acc, item) => {
          const [key, value] = item.split(":").map((str) => str.trim()); // Split by ':' and trim spaces
          acc[key] = value; // Add the key-value pair to the object
          return acc;
        }, {});
        if (result) {
          handleAccountSelection(result.d1id, "AccName");
        }
      }
    }
  };

  useEffect(() => {
    if (AccountMasterModal == false) {
      console.log("AccountMasterModal", AccountMasterModal);
    }
  }, [AccountMasterModal]);

  //---------------Fill List Of All Required Items (Load Event)-----------------------

  //---------------Fill Bill Numbering Setting (Load Event)-----------------------

  const [BillNumberingSetting, setBillNumberingSetting] = useState([]);
  // const [BillLayoutSetting, setBillLayoutSetting] = useState([]);
  // const [BillTypeSetting, setBillTypeSetting] = useState([]);

  useEffect(() => {
    const fetchBillNumberingSett = async (
      billSeriesMasterType,
      billSeriesId
    ) => {
      if (billSeriesId != 0 && BillNumberingSetting.length == 0) {
        try {
          const data1 = await BillNumberingAPI.getBillNumberingbyId(
            billSeriesMasterType || 29,
            billSeriesId
          );
          setBillNumberingSetting(data1);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    if (inputValues.billSeriesMasterType && inputValues.billSeriesId) {
      fetchBillNumberingSett(
        inputValues.billSeriesMasterType,
        inputValues.billSeriesId
      );
    }
  }, [inputValues.billSeriesMasterType, inputValues.billSeriesId]);

  //---------------Fill Bill Layout Setting (Load Event)-----------------------

  // useEffect(() => {
  //   const fetchBillLayoutSett = async (billSeriesId) => {
  //     // setLoading(true);
  //     if (billSeriesId != 0) {
  //       try {
  //         const data1 = await BillLayoutAPI.getBillLayoutSettingById(
  //           billSeriesId
  //         );
  //         if (data1) {
  //           setBillLayoutSetting(data1);
  //         } else {
  //           console.error("error while getting data");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   };
  //   fetchBillLayoutSett(inputValues.billSeriesId);
  // }, [inputValues.billSeriesId]);

  //---------------Fill Bill Type Setting (Load Event)-----------------------

  // useEffect(() => {
  //   const fetchBillTypeSett = async (billTaxTypeId) => {
  //     if (billTaxTypeId != 0) {
  //       setLoading(true);
  //       try {
  //         const data1 = await BillTypeAPI.GetBillTypeById(billTaxTypeId);
  //         if (data1) {
  //           setBillTypeSetting(data1);
  //           console.log(data1);
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };
  //   fetchBillTypeSett(inputValues.billTaxTypeId);
  // }, [inputValues]);

  //---------------Focus on the next input field if Enter is pressed-----------------------

  const handleKeyDownMainPage = (e) => {
    if (e.key === "Enter") {
      // // // debugger;
      let nextIndex = nextInput.current.indexOf(e.target) + 1;
      if (e.target.name == "AccRefferedBy") {
        try {
          lensPageInputRef.current.focus();
        } catch (e) {
          nextCellRefsProduct.current[0][1].focus();
        }
      } else if (nextIndex < nextInput.current.length) {
        if (nextInput.current[nextIndex]) {
          nextInput.current[nextIndex].focus();
        } else {
          if (lensPageInputRef.current && lensPageInputRef) {
            lensPageInputRef.current.focus();
          } else {
            nextCellRefsProduct.current[0][1].focus();
          }
        }
      }
    }
  };

  //---------------For Series,BillType,Godown,Party Detail Inpute Change-----------------------

  const handleInputChange_Series = async (e) => {
    // // debugger;
    var { name, type, value, checked } = e.target;

    console.log(name);

    if (value != "null") {
      if (name === "billDate" || name === "DeliveryDate") {
        if (value != "") {
          setInputValues((prevValues) => {
            const updatedValues = {
              ...prevValues,
              [name]: value || 0,
            };

            // const grossAmt =
            //   (parseFloat(updatedValues.TaxableAmt) || 0) -
            //   (parseFloat(updatedValues.LoyalityAmt) || 0) -
            //   (parseFloat(updatedValues.CouponAmt) || 0);
            return {
              ...updatedValues,
              // GrossAmt: grossAmt.toFixed(2),
            };
          });
        }
      } else if (name === "billNumber") {
        setInputValues((prevValues) => {
          const updatedValues = {
            ...prevValues,
            [name]: value || 0,
          };

          // const grossAmt =
          //   (parseFloat(updatedValues.TaxableAmt) || 0) -
          //   (parseFloat(updatedValues.LoyalityAmt) || 0) -
          //   (parseFloat(updatedValues.CouponAmt) || 0);
          return {
            ...updatedValues,
            // GrossAmt: grossAmt.toFixed(2),
          };
        });
      } else {
        setInputValues((prevValues) => {
          const updatedValues = {
            ...prevValues,
            [name]: parseFloat(value) || 0,
          };

          // const grossAmt =
          //   (parseFloat(updatedValues.TaxableAmt) || 0) -
          //   (parseFloat(updatedValues.LoyalityAmt) || 0) -
          //   (parseFloat(updatedValues.CouponAmt) || 0);
          return {
            ...updatedValues,
            // GrossAmt: grossAmt.toFixed(2),
          };
        });
      }
    }
    // // // debugger;
    if (name === "billTaxTypeId") {
      const SelectedTaxTypeData = TaxTypeSet.filter(
        (item) => item.id == e.target.value
      );
      if (SelectedTaxTypeData.length == 1) {
        setSelectedTaxType(SelectedTaxTypeData);
      }
    }

    if (name == "vochStatusId") {
      setWhatsappNoMsgActive(true);
    }

    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  useEffect(() => {
    // console.log(inputValues);
  }, [inputValues]);

  //---------------Account and RefferedBy Change-----------------------

  // const handleAccountMasterChange = useCallback(
  //   debounce(async (event) => {
  //     const { name, value } = event.target;
  //     // // debugger;
  //     setInputValueAccMaster((prevValues) => ({
  //       ...prevValues,
  //       [name]: value,
  //     }));

  //     if (name === "AccName") {
  //       setInputValueAccMaster((prevValues) => ({
  //         ...prevValues,
  //         [name]: value,
  //         AccId: 0,
  //         parentGrp: 212,
  //       }));
  //       setselectedAccId("");
  //     }

  //     if (name === "AccPOS") {
  //       setInputValues((prevValues) => ({
  //         ...prevValues,
  //         pos: value,
  //       }));
  //     }

  //     const SeparateAccountInTransaction = allSettings.find(
  //       (setting) =>
  //         setting.masterSettName === "SeparateAccountInTransaction" &&
  //         setting.enableDisable === true
  //     );

  //     // Determine GroupId based on the SeparateAccountInTransaction condition
  //     console.log(id);
  //     var IdNo = Number(id);

  //     let GroupId;

  //     if (SeparateAccountInTransaction?.enableDisable) {
  //       if ([12, 13, 71, 72].includes(IdNo)) {
  //         GroupId = [212, 214, 203, 202];
  //       } else if ([14, 70, 15].includes(IdNo)) {
  //         GroupId = [211, 214, 203, 202];
  //       } else {
  //         GroupId = [211, 212, 214, 203, 202];
  //       }
  //     } else {
  //       GroupId = [211, 212, 214, 203, 202];
  //     }

  //     console.log(GroupId);

  //     if (
  //       name === "AccName" ||
  //       name === "AccRefferedBy" ||
  //       name === "AccPhoneNo"
  //     ) {
  //       const filteredData = {
  //         MasterType: 2,
  //         GroupWiseSuggestion: true,
  //         GroupId: GroupId,
  //         search_str: value,
  //         PageNumber: 1,
  //         PageSize: 50,
  //       };

  //       const SearchItem = await AccountAPI.GetAccountListForAutoSuggestion(
  //         filteredData
  //       );

  //       if (name === "AccName") {
  //         if (SearchItem) {
  //           setFilteredAccount(SearchItem);
  //         }
  //         setIsDropdownOpen1(true);
  //       } else if (name === "AccRefferedBy") {
  //         if (SearchItem) {
  //           setFilteredRefferedBy(SearchItem);
  //         }
  //         setIsDropdownOpen2(true);
  //       } else if (name === "AccPhoneNo") {
  //         if (SearchItem) {
  //           setFilteredAccountWithMobile(SearchItem);
  //         }
  //         setIsDropdownOpen11(true);
  //       }
  //     }
  //   }, 500),
  //   []
  // );

  //seperate method for fetching
  const fetchAccountSuggestions = async (name, value, GroupId) => {
    const filteredData = {
      MasterType: 2,
      GroupWiseSuggestion: true,
      GroupId: GroupId,
      search_str: value,
      PageNumber: 1,
      PageSize: 50,
    };

    try {
      const SearchItem = await AccountAPI.GetAccountListForAutoSuggestion(
        filteredData
      );
      return SearchItem;
    } catch (error) {
      console.error("Error fetching account suggestions:", error);
      return null;
    }
  };

  const handleAccountMasterChange = async (event) => {
    const { name, value } = event.target;
    // // debugger;
    setInputValueAccMaster((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    if (name === "AccName") {
      setInputValueAccMaster((prevValues) => ({
        ...prevValues,
        [name]: value,
        AccId: 0,
        parentGrp: 212,
      }));
      setselectedAccId("");
    }

    if (name === "AccPOS") {
      setInputValues((prevValues) => ({
        ...prevValues,
        pos: value,
      }));
    }

    const SeparateAccountInTransaction = allSettings.find(
      (setting) =>
        setting.masterSettName === "SeparateAccountInTransaction" &&
        setting.enableDisable === true
    );

    const IdNo = Number(id);

    let GroupId;

    if (SeparateAccountInTransaction?.enableDisable) {
      if ([12, 13, 71, 72].includes(IdNo)) {
        GroupId = [212, 214, 203, 202];
      } else if ([14, 70, 15].includes(IdNo)) {
        GroupId = [211, 214, 203, 202];
      } else {
        GroupId = [211, 212, 214, 203, 202];
      }
    } else {
      GroupId = [211, 212, 214, 203, 202];
    }

    if (
      name === "AccName" ||
      name === "AccRefferedBy" ||
      name === "AccPhoneNo"
    ) {
      debouncedFetchAccountSuggestions(name, value, GroupId);
    }
  };

  const debouncedFetchAccountSuggestions = useCallback(
    debounce(async (name, value, GroupId) => {
      const SearchItem = await fetchAccountSuggestions(name, value, GroupId);

      if (name === "AccName") {
        if (SearchItem) {
          setFilteredAccount(SearchItem);
        }
        setIsDropdownOpen1(true);
      } else if (name === "AccRefferedBy") {
        if (SearchItem) {
          setFilteredRefferedBy(SearchItem);
        }
        setIsDropdownOpen2(true);
      } else if (name === "AccPhoneNo") {
        if (SearchItem) {
          setFilteredAccountWithMobile(SearchItem);
        }
        setIsDropdownOpen11(true);
      }
    }, 500),
    []
  );

  const suggestionBoxRef = useRef(null);
  const itemRefs = useRef([]); // Reference for each list item

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectedAccId, setselectedAccId] = useState(null);
  useEffect(() => {
    console.log("selectedAccId", selectedAccId);
  }, [selectedAccId]);

  useEffect(() => {
    if (
      highlightedIndex >= 0 &&
      itemRefs.current[highlightedIndex] &&
      suggestionBoxRef.current
    ) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [highlightedIndex]);

  const handleAccountSelection = async (id, _name) => {
    // // debugger;
    var selectedAccData = await Account.getAccountById(id);
    setselectedAccId(id);
    // // debugger;
    var selectedAcc = selectedAccData.item1[0];
    var updatedData;
    if (selectedAcc) {
      if (Number(id) === selectedAcc.d1Id && _name == "AccName") {
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: selectedAcc.d2Id,
          namePrefix: selectedAcc.namePrefix || "",
          AccName: selectedAcc.name || "",
          AccPhoneNo: selectedAcc.mobileNo || "", //email enableLoyalty namePrefix
          AccDob: selectedAcc.dob || "",
          AccAnn: selectedAcc.dom || "",
          AccPOS: selectedAcc.stateId || "",
          AccBalance: selectedAcc.openingCrDr || "",
          AccCreditLimit: selectedAcc.creditlimit || "",
          AccAddress: selectedAcc.address || "",
          AccStateId: selectedAcc.stateId || "",
          AccState: selectedAcc.stateName || "",
          AccRefferedBy: selectedAcc.referralByName || "",
        }));
        updatedData = {
          AccId: selectedAcc.d2Id,
          namePrefix: selectedAcc.namePrefix || "",
          AccName: selectedAcc.name || "",
          AccPhoneNo: selectedAcc.mobileNo || "", //email enableLoyalty namePrefix
          AccDob: selectedAcc.dob || "",
          AccAnn: selectedAcc.dom || "",
          AccPOS: selectedAcc.stateId || "",
          AccBalance: selectedAcc.openingCrDr || "",
          AccCreditLimit: selectedAcc.creditlimit || "",
          AccAddress: selectedAcc.address || "",
          AccStateId: selectedAcc.stateId || "",
          AccState: selectedAcc.stateName || "",
          AccRefferedBy: selectedAcc.referralByName || "",
          parentGrp: 212,
        };
        setIsDropdownOpen1(false);
      } else if (id === selectedAcc.d1Id && _name === "AccRefferedBy") {
        // // // debugger;
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccRefById: selectedAcc.d2Id,
          AccRefferedBy: selectedAcc.name,
        }));
        setIsDropdownOpen2(false);
      } else if (id === selectedAcc.d1Id && _name === "AccPhoneNo") {
        // // // debugger;
        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: selectedAcc.d2Id,
          namePrefix: selectedAcc.namePrefix || "",
          AccName: selectedAcc.name || "",
          AccPhoneNo: selectedAcc.mobileNo || "",
          AccDob: selectedAcc.dob || "",
          AccAnn: selectedAcc.dom || "",
          AccPOS: selectedAcc.stateId || "",
          AccBalance: selectedAcc.openingCrDr || "",
          AccCreditLimit: selectedAcc.creditlimit || "",
          AccAddress: selectedAcc.address || "",
          AccStateId: selectedAcc.stateId || "",
          AccState: selectedAcc.stateName || "",
          AccRefferedBy: selectedAcc.referralByName || "",
        }));
        setIsDropdownOpen11(false);
      }

      setHighlightedIndex(-1);
      setTimeout(() => {
        onChangeSaveMethod("", "", "", updatedData);
      }, 500);
    }
  };
  const closeSuggestionBox = (e) => {
    // // // debugger;
    // if (e.target.name == "AccName") {
    //   var data = filteredAccount.filter((name) => name.name == e.target.value);
    //   if (data.length != 1) {
    //     setInputValueAccMaster((prevValues) => ({
    //       ...prevValues,
    //       AccId: 214,
    //       namePrefix: "",
    //       AccPhoneNo: "",
    //       AccDob: "",
    //       AccAnn: "",
    //       AccPOS: "",
    //       AccBalance: "",
    //       AccCreditLimit: "",
    //       AccAddress: "",
    //       AccStateId: "",
    //       AccState: "",
    //       AccRefById: "",
    //       AccRefferedBy: "",
    //     }));
    //   }
    // }

    // onChangeSaveMethod();
    setTimeout(() => {
      setIsDropdownOpen2(false);
      setIsDropdownOpen1(false);
      setIsDropdownOpen11(false);
    }, 500);
  };

  //---------------Sundry Name Change-----------------------

  const handleSundryNameChange = (event, rowIndex) => {
    const { value } = event.target;
    setSundryRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].name = value;
      newRows[rowIndex].filteredSundry = sundryList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      newRows[rowIndex].isDropdownOpen = true;
      newRows[rowIndex].highlightedIndex = -1;
      return newRows;
    });
  };

  const handleSundrySelection = (rowIndex, selectedSundry) => {
    // // // debugger;
    if (selectedSundry.methodApplied == "discount") {
      setSundryRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          id: selectedSundry.id,
          name: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          Percent: selectedSundry.absolutePercentAmount
            ? ""
            : selectedSundry.defaultValue || "",
          Amount: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || ""
            : "",
          isDropdownOpen: false,
          filteredSundry: [],
        };
        return newRows;
      });
    } else {
      setSundryRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          id: selectedSundry.id,
          name: selectedSundry.name,
          printName: selectedSundry.printName,
          sundryType: selectedSundry.sundryType,
          methodApplied: selectedSundry.methodApplied,
          absolutePercentAmount: selectedSundry.absolutePercentAmount,
          defaultValue: selectedSundry.defaultValue,
          percentOfAmount: selectedSundry.percentOfAmount,
          applyOnBill: selectedSundry.applyOnBill,
          roundOffSundryAmount: selectedSundry.roundOffSundryAmount,
          amtRoundOffConfig: selectedSundry.amtRoundOffConfig,
          amtAdjustSaleAccount: selectedSundry.amtAdjustSaleAccount,
          selectedAccountSale: selectedSundry.selectedAccountSale,
          amtAdjustPurchaseAccount: selectedSundry.amtAdjustPurchaseAccount,
          selectedAccountPurchase: selectedSundry.selectedAccountPurchase,
          roundOffLimit: selectedSundry.roundOffLimit,
          Percent: selectedSundry.absolutePercentAmount
            ? ""
            : selectedSundry.defaultValue || "",
          Amount: selectedSundry.absolutePercentAmount
            ? selectedSundry.defaultValue || ""
            : "",
          isDropdownOpen: false,
          filteredSundry: [],
        };
        return newRows;
      });
    }
  };

  const handleSundryRowKeyDown = (event, rowIndex, columnIndex) => {
    if (event.key === "Enter") {
      const newRow = {
        id: sundryRows.length + 1,
        name: "",
        printName: "",
        sundryType: 0,
        methodApplied: "",
        absolutePercentAmount: false,
        defaultValue: 0,
        percentOfAmount: 100,
        applyOnBill: 1,
        roundOffSundryAmount: false,
        amtRoundOffConfig: 0,
        amtAdjustSaleAccount: false,
        selectedAccountSale: 0,
        amtAdjustPurchaseAccount: false,
        selectedAccountPurchase: 0,
        roundOffLimit: 0,
        Percent: 0,
        Amount: 0,
        isDropdownOpen: false,
        highlightedIndex: -1,
        filteredSundry: [],
      };

      if (rowIndex === sundryRows.length - 1) {
        if (event.target.name === "Amount") {
          setSundryRows((prevRows) => {
            const newRows = [...prevRows, newRow];
            return newRows;
          });
        }
      }

      // Calculate next column index
      const nextColumnIndex = columnIndex + 1;
      const nextRowCellRef = nextCellRefs.current[rowIndex][nextColumnIndex];

      if (nextRowCellRef) {
        nextRowCellRef.focus();
      } else {
        setTimeout(() => {
          const nextRowIndex = rowIndex + 1;
          let nextRowCellId = `cell-${nextRowIndex}-0`;
          let nextRowCell = document.getElementById(nextRowCellId);

          if (nextRowCell) {
            nextRowCell.focus();
          }
        }, 10);
      }
    }
  };

  const handleSundryCellChange = (event, rowIndex) => {
    const { name, value } = event.target;
    setSundryRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex] = {
        ...newRows[rowIndex],
        [name]: value,
      };
      return newRows;
    });
  };

  const deleteSundryRow = async (rowIndex) => {
    setLoading(true);
    var deleteSundry = await Transaction.RemoveSundryTempData(
      ReferenceNumber,
      rowIndex
    );
    if (deleteSundry == true) {
      setLoading(false);
      GetBillSundryeDataFunction();
      // const updatedRows = sundryRows.filter((_, index) => index != rowIndex);
      // setSundryRows(updatedRows);
      // updateSundryTotals(updatedRows);
    } else {
      setLoading(false);
    }
  };

  //--------------- Sundry Row Input Calculation -----------------------

  const calculateSundryTotalAmount = (
    totalItemAmount,
    sunPercent,
    sunAmount,
    sunType_
  ) => {
    let sunRowAmt = 0;
    if (sunType_ === 1) {
      //  Percentage
      sunRowAmt = totalItemAmount * (sunPercent / 100);
    } else if (sunType_ === 2) {
      //  Amount
      sunRowAmt = sunAmount;
    }

    // return { sunRowAmt };
  };

  //--------------- Update the total Sundry amount -----------------------
  const updateSundryTotals = (rows) => {
    // let totalAmount = 0;
    // rows.forEach((row) => {
    //   if (row.sundryType === 1) {
    //     totalAmount += parseFloat(row.Amount) || 0;
    //   } else {
    //     totalAmount -= parseFloat(row.Amount) || 0;
    //   }
    // });
    // let finalTaxable = inputValues.TotalItemAmt - totalAmount;
    // setInputValues((prevValues) => ({
    //   ...prevValues,
    //   TotalSundry: totalAmount.toFixed(2),
    //   TaxableAmt: finalTaxable.toFixed(2),
    // }));
  };

  //---------------Auto-suggestion account Change-----------------------

  const handleKeyDown_AccountSuggestion = (e, ToFilterData) => {
    // // // debugger;

    if (!ToFilterData.length) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % ToFilterData.length
        );

        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === 0 ? ToFilterData.length - 1 : prevIndex - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < ToFilterData.length) {
          if (e.target.name === "AccName") {
            handleAccountSelection(
              ToFilterData[highlightedIndex].d1RefId,
              // ToFilterData[highlightedIndex],
              "AccName"
            );
          } else if (e.target.name === "AccRefferedBy") {
            handleAccountSelection(
              ToFilterData[highlightedIndex].d1RefId,
              // ToFilterData[highlightedIndex],
              "AccRefferedBy"
            );
          }
        }
        break;
      case "Escape":
        setIsDropdownOpen1(false);
        break;
      default:
        break;
    }
  };

  //---------------Auto-suggestion sundry Change-----------------------

  const handleKeyDown_SundrySuggestion = (e, rowIndex) => {
    debugger;
    const { filteredSundry, highlightedIndex } = sundryRows[rowIndex];
    if (filteredSundry && !filteredSundry.length) {
      handleSundryRowKeyDown(e, rowIndex, 1);
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            (highlightedIndex + 1) % filteredSundry.length;
          return newRows;
        });
        break;
      case "ArrowUp":
        e.preventDefault();
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].highlightedIndex =
            highlightedIndex === 0
              ? filteredSundry.length
              : highlightedIndex - 1;
          return newRows;
        });
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredSundry.length) {
          handleSundrySelection(rowIndex, filteredSundry[highlightedIndex]);
        }
        handleSundryRowKeyDown(e, rowIndex, 1);
        break;
      case "Escape":
        setSundryRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].isDropdownOpen = false;
          return newRows;
        });
        break;
      default:
        break;
    }
  };

  //---------------Save Input Show/Hide Setting Change-----------------------

  const handleHideAndShow_InputBox = async (e) => {
    const { name, checked } = e.target;

    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...TransInputSet,
      [name]: checked,
    };

    setTransInput(updatedValues);
    try {
      if (IsExistTransInputSet) {
        const isSuccess =
          await TransactionTypeSettingAPI.UpdateVoucherWise_InputSettingAsync(
            updatedValues
          );
        if (isSuccess) {
        } else {
          alert("error while updating");
        }
      } else {
        // setLoading(true);
        const isSuccess =
          await TransactionTypeSettingAPI.CreateVoucherWise_InputSettingAsync(
            updatedValues
          );
        if (isSuccess) {
          // alert("save");
          setTimeout(() => {
            setIsExistTransInput(true);
          }, 100);
        } else {
          alert("error while updating");
        }
      }
    } catch (e) {}
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  /********************************************/

  //------------------- GST CALCULATION ITEM WISE -------------------
  /*const handleGSTCalculation = async (newRows) => {
  
          const result = await Gst_Calculation.ItemWiseGSTCalculation(
              newRows, itemMasterRows, setItemMasterRows, BillTypeSetting, inputValues, sundryRows);
      };*/

  //------------------- GST CALCULATION SUNDRY WISE -------------------
  function convertStringToJSON(inputString) {
    return inputString.split(",").reduce((acc, pair) => {
      const [key, value] = pair.split(":").map((str) => str.trim()); // Split and trim key-value pairs
      acc[key] = isNaN(value) ? value : Number(value); // Convert numeric strings to numbers
      return acc;
    }, {});
  }
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const SaveMethod = async () => {
    if (
      !InputValueAccMaster.AccId ||
      InputValueAccMaster.AccId == 0 ||
      InputValueAccMaster.AccId == ""
    ) {
      var accountSave = await AccountAPI.insertAccount(InputValueAccMaster, []);
      // // // debugger;
      if (accountSave.isSuccess == false) {
        if (accountSave.errorMessages[0].includes("already exist")) {
          alert("Account already exists");
          return;
        }
        alert("Account already exists");
        return;
      }

      if (accountSave.isSuccess == true) {
        var AccIdinJson = convertStringToJSON(accountSave.result.result);

        setInputValueAccMaster((prevValues) => ({
          ...prevValues,
          AccId: AccIdinJson.d2id,
        }));

        // handleAccountSelection(AccIdinJson.d2id);
        // // debugger;
        await onChangeSaveMethod(AccIdinJson.d2id, 1);
        await saveMethod2();
      } else {
        alert("Error while creating Account");
      }
    } else {
      saveMethod2();
    }
  };
  const [WhatsappNoMsgActive, setWhatsappNoMsgActive] = useState(false);

  const sendMessage = async (transactonId) => {
    debugger;
    if (WhatsappNoMsgActive) {
      var FilterData = {
        smsTypeId:0,
        smsType: "Sale Order",
        transId: 0 ,
        // Number(id),
        accId: 0,
      };
      var templateData = await SmsTemplate.GetAssignTemplatesByName(
        FilterData.smsType,
        FilterData.transId,
        FilterData.accId,
        FilterData.smsTypeId,
      );
      if (templateData.length > 0) {
        messageFunction(
          "Sale Order",
          0,
          0,
          InputValueAccMaster.AccPhoneNo,
          "",
        );
      }
    }
  };

  const saveMethod2 = async () => {
    setLoading(true);
    try {
      if (childRef.current) {
        await childRef.current.onChangeSavePayment();
      }
      if (lensinforef.current) {
        await lensinforef.current.saveLensInfoInList();
      }
      let transactionStatus;
      const TransBillData = await Transaction.SaveTransactions(ReferenceNumber);
      if (TransBillData.isSuccess === true) {
        let transactionfound = false;
        debugger;
        // setLoading(true);
        for (let i = 0; i < 60; i++) {
          transactionStatus = await Transaction.TransactionsStatus(
            ReferenceNumber
          );
          if (transactionStatus.isSuccess === true) {
            await sendMessage(transactionStatus.result.trans1Id);
            transactionfound = true;
            if (
              transactionStatus.result.status === 3 ||
              transactionStatus.result.status === 2
            ) {
              break;
            }
          } else {
            await sleep(500);
          }
        }

        if (!transactionfound) {
          console.log("Error in changing transaction status");
          setLoading(false);
          return;
        }

        if (transactionStatus.result.status === 3) {
          alert("Transaction Failed");
        } else if (transactionStatus.result.status === 2) {
          OpenAlertBoxForSalePage();
          setTransId(transactionStatus.result.trans1Id);
          // setShowBillPrint(true);
          if (location?.state?.IsConversion === true && ConvertType == 1) {
            await ConvertInvoiceAndSynchDataController.SetTransConvertInfoData(
              ConvertType,
              companyData.id, //from
              companyData.id, //to
              ConversionTransId, //from trans id
              transactionStatus.result.trans1Id //to trans id
            );
          } else if (location.state.IsConversion === true && ConvertType == 3) {
            debugger;
            const res =
              await ConvertInvoiceAndSynchDataController.UpdateTransConvertInfoData(
                ConvertType,
                DBIdT1, //from branch id DBIdT1
                companyData.id,
                TableId,
                transactionStatus.result.trans1Id
              );
            console.log("res", res);
          }

          // const confirmation = window.confirm("Do you want to reset the page?");
          // setConfirmation(confirmation);

          // if (confirmation) {
          //   window.location.reload();
          // } else {
          // }
        } else {
          alert("Transaction Failed");
        }
      } else {
        alert("Error in Trans Bill Data");
      }
    } catch (error) {
      console.error("Error while saving transaction:", error);
    } finally {
      setLoading(false);
    }
  };
  const childRef = useRef();

  const onChangeSaveMethod = async (d2id, Method, e, updatedData) => {
    // // debugger;
    let AccData = updatedData !== undefined ? updatedData : InputValueAccMaster;
    if (InputValueAccMaster.AccId == 0 && Method == 1) {
      InputValueAccMaster.AccId = d2id; // Corrected to assignment
    }

    // Proceed only if AccId is valid (not 0)
    if (
      AccData.AccId &&
      AccData.AccId != 0 &&
      ReferenceNumber != "" &&
      ReferenceNumber != null
    ) {
      try {
        // Attempt to save the transaction bill data
        const TransBillData = await Transaction.SetTransBillData(
          inputValues,
          AccData,
          ReferenceNumber
        );
        if (TransBillData.isSuccess === true) {
          console.log("Trans Bill Data saved");
          // GetTransBillDataFunction();
          if (e?.target?.name == "billTaxTypeId") {
            await GetProductDataFunction();
            await GetBillSundryeDataFunction();
          }
        } else {
          alert("Error in Trans Bill Data");
        }
      } catch (error) {
        console.error(
          "Error occurred while saving the transaction bill data:",
          error
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const [ApiCallProductAllowed, setApiCallProductAllowed] = useState(true);

  useEffect(() => {
    console.log(ApiCallProductAllowed);
  }, [ApiCallProductAllowed]);

  const [ChangeInData, setChangeInData] = useState(false);

  useEffect(() => {
    console.log("changeindata", ChangeInData);
  }, [ChangeInData]);

  const onChangeProductSaveMethod = async (e, Rowidx, Index, d2id, newrows) => {
    debugger;
    // setLoading(true);
    console.log(ApiCallProductAllowed);
    try {
      if (e == "" && Index == 0 && d2id != 0) {
        // setLoading(true);
        if (!ChangeInData) {
          console.log("change data false");
          return;
        }
        var RowToUpdate;
        if (newrows) {
          RowToUpdate = newrows.find((item) => item.rowIndex === Rowidx);
        } else {
          RowToUpdate = itemMasterRows.find((item) => item.rowIndex === Rowidx);
        }

        var BatchNos = RowToUpdate.batchNos;
        if (BatchNos && BatchNos.length > 0) {
          const totalQuantity = BatchNos.reduce(
            (sum, batch) => sum + Number(batch.qty),
            0
          );
          if (totalQuantity != RowToUpdate.Qty) {
            alert("Batch quantity does not match with product quantity.");
            return;
          }
        }

        if (RowToUpdate) {
          var ProductTempData = await Transaction.SetProductTempData(
            [RowToUpdate],
            ReferenceNumber
          );

          // Handle API response
          if (ProductTempData.isSuccess === false) {
            console.log("API call failed. Response:", ProductTempData);
          } else if (ProductTempData.isSuccess === true) {
            setChangeInData(false);
            console.log("Product Temp Data saved successfully.");
            // NewRowIndex should contain the new row index to be updated
            var NewRowIndex = ProductTempData.result;

            if (NewRowIndex.length === 1) {
              // Update itemMasterRows with the new row index
              setItemMasterRows((prevRows) => {
                return prevRows.map((row, idx) => {
                  if (idx === Index) {
                    return { ...row, rowIndex: NewRowIndex[0] };
                  }
                  return row;
                });
              });
            }
          }
        }

        setItemWisebatch(false);
        setPopUpSerial(false);
        setModalOpen(false);

        // // // debugger;
        const nextColumnIndex = 24;
        let nextRowCellRef =
          nextCellRefsProduct.current[Rowidx - 1][nextColumnIndex];

        if (!nextRowCellRef) {
          for (let i = nextColumnIndex; i < 35; i++) {
            const cellRef = nextCellRefsProduct.current[Rowidx - 1][i]; // Assuming index 0 for the first column
            if (cellRef) {
              nextRowCellRef = cellRef;
              break;
            }
          }
        }

        if (nextRowCellRef) {
          nextRowCellRef.focus();
          nextRowCellRef.select();
        }
        return;
      }
      const { name, value } = e.target;
      // Check if API call is allowed before proceeding

      if (!ApiCallProductAllowed) {
        console.log("API Call is in progress, please wait.");
        return; // Exit the function if API call is still in progress
      }

      setApiCallProductAllowed(false); // Disable further API calls

      // Filter the row to update
      var RowToUpdate;
      if (newrows) {
        RowToUpdate = newrows.filter((item) => item.rowIndex === Rowidx);
        console.log(ApiCallProductAllowed);
        console.log(RowToUpdate);
      } else {
        RowToUpdate = itemMasterRows.filter((item) => item.rowIndex === Rowidx);
        console.log(ApiCallProductAllowed);
        console.log(RowToUpdate);
      }
      // ;

      if (
        name == "Qty" &&
        value != 0 &&
        value != "" &&
        RowToUpdate[0].d2_Id != 0 &&
        RowToUpdate[0].enableSerialNo == true
      ) {
        {
          PopUpSerialOpen();
          setProductOpnedInPopup(RowToUpdate[0]);
        }
      }

      if (
        name == "Qty" &&
        value != 0 &&
        value != "" &&
        RowToUpdate[0].d2_Id != 0 &&
        RowToUpdate[0].enableBatchNo == true
      ) {
        {
          itemWiseBatchOpen();
          setProductOpnedInPopup(RowToUpdate[0]);
        }
      }

      // Ensure there's a valid product name
      if (RowToUpdate[0].productName || RowToUpdate[0].productName != "") {
        // setLoading(true);
        // // debugger;
        if (name == "itemPrice") {
          if (
            TransactionTypeSetting !== null &&
            (RowToUpdate[0].d2_Id == 0 || RowToUpdate[0].d1_Id == 0)
          ) {
            setApiCallProductAllowed(true);
            // if (RowToUpdate[0].itemPrice != 0) {
            // setLoading(true);

            var ItemMasterData = {
              D1_Id: RowToUpdate[0].d1_Id,
              D2_Id: RowToUpdate[0].d2_Id,
              IsFavorite: false,
              IsActive: true,
              businessType: 0,
              ProductName: RowToUpdate[0].productName,
              ProductAlias: "",
              PrintName: "",
              ParentGrp: RowToUpdate[0].parentGrp || 581,
              parentGrp2: 0,
              ProductUnit: RowToUpdate[0].productUnit || 511,
              ProductAltUnit: RowToUpdate[0].productAltUnit,
              UnitConvFactor: RowToUpdate[0].unitConvFactor,
              ItemDepartment: 0,
              ProductType: 0,
              SKU: RowToUpdate[0].productName,
              ProductCategory: 0,
              EnableBarcode: RowToUpdate[0].enableBarcode,
              DefaultBarcode: RowToUpdate[0].itmCode,
              TaxCategory1: RowToUpdate[0].taxCategory1,
              TaxLimit: RowToUpdate[0].taxLimit,
              TaxCategory2: RowToUpdate[0].taxCategory2,
              TaxOnMRP: true,
              hsnCode: RowToUpdate[0].hsnCode,
              ShortDescription: RowToUpdate[0].shortDescription,
              tax1LimitAmt: 0,
              LongDescription: "",
              DisplayOrder: "",
              PreparationTime: true,
              MealCourseType: true,
              OpeningStock: 0,
              restoAvailableQuantity: RowToUpdate[0].availableQuantity,
              OpeningStock_barcodeGodown: [
                {
                  GodownId: 0,
                  Qty: 0,
                  StockValue: 0,
                  Barcode: "",
                  CostPrice: 0,
                  SellingPrice: 0,
                },
              ],
              OpeningStockValue: "",
              restoAvailableTime: [
                {
                  availableTimefrom: "",
                  availableTimeTo: "",
                },
              ],
              // PurchasePrice: RowToUpdate[0].purchasePrice,
              PurchasePrice:
                TransactionTypeSetting.transAccount === 215
                  ? RowToUpdate[0].itemPrice
                  : RowToUpdate[0].purchasePrice,
              CostPrice: RowToUpdate[0].costPrice,
              SaleProfit: RowToUpdate[0].saleProfit,
              SalePrice:
                TransactionTypeSetting.transAccount != 215
                  ? RowToUpdate[0].itemPrice
                  : RowToUpdate[0].salePrice,
              // SalePrice: RowToUpdate[0].salePrice,
              SaleDiscount: RowToUpdate[0].saleDiscount,
              MrpPrice: RowToUpdate[0].mrpPrice,
              PurchaseDiscount: RowToUpdate[0].purchaseDiscount,
              MinSalePrice: "",
              BranchPrice: "",
              FranchisePrice: "",
              NormalLoss: "",
              EcomSalePrice: RowToUpdate[0].salePrice,
              StockCheck: "",
              VirtualQuantity: "",
              AvailableQuantity: "",
              MinQtyToPurchase: "",
              MaxQtyToPurchase: "",
              IncrementQty: "",
              IDefaultGodown: "",
              ICateRateA: RowToUpdate[0].iCateRateA,
              ICateRateB: RowToUpdate[0].iCateRateB,
              ICateRateC: RowToUpdate[0].iCateRateC,
              ICateRateD: RowToUpdate[0].iCateRateD,
              ICateRateE: RowToUpdate[0].iCateRateE,
              ICateRateF: RowToUpdate[0].iCateRateF,
              ICateRateG: RowToUpdate[0].iCateRateG,
              ICateRateH: RowToUpdate[0].iCateRateH,
              ICateRateI: RowToUpdate[0].iCateRateI,
              ICateRateJ: RowToUpdate[0].iCateRateJ,
              LoyaltyPoint: RowToUpdate[0].loyaltyPoint,
              AvailableDateTo: null,
              AvailableDatefrom: null,
              ReferalAmountDirect: "",
              ReferalAmountIndirect: "",
              SalesManItemIncentive: RowToUpdate[0].salesManItemIncentive,
              SellerCommision: RowToUpdate[0].sellerCommision,
              BuyX: "",
              GetX: "",
              ImmediateTransferForHD: false,
              OfferTagInCategory: "",
              OfferTagInProduct: "",
              SchemeType: 0,
              EnableSerialNo: "",
              EnableBatchNo: "",
              Location: "",
              RackNo: "",
              EnableStockReorder: "",
              MaxStockLevelQty: "",
              MinStockLevelQty: "",
              ReorderLevelQty: "",
              EnableLowStockWarning: "",
              IsReturnable: RowToUpdate[0].isReturnable,
              IsConsumable: "",
              IsStockable: RowToUpdate[0].isStockable,
              AllowRawMaterial: "",
              DefaultVendor: "",
              Lens_SPH: RowToUpdate[0].lens_SPH,
              Lens_CYL: RowToUpdate[0].lens_CYL,
              Lens_AXIS: RowToUpdate[0].lens_AXIS,
              Lens_Dia: RowToUpdate[0].lens_Dia,
              Lens_Add: RowToUpdate[0].lens_Add,
              lens_EYE: RowToUpdate[0].lens_Eye,
              LensAttributeList: [
                {
                  Barcode: 0,
                  SPH_frm: 0,
                  SPH_to: 0,
                  SPH_stp: 0,
                  CYL_frm: 0,
                  CYL_to: 0,
                  CYL_stp: 0,
                  AXIS_frm: 0,
                  AXIS_to: 0,
                  AXIS_stp: 0,
                  AD_frm: 0,
                  AD_to: 0,
                  AD_stp: 0,
                  DIA: 0,
                },
              ],
              IsAttributeItem: RowToUpdate[0].isAttributeItem,
              IAttributeParent: RowToUpdate[0].iAttributeParent,
              DrugCompany: "",
              DrugMFDB: "",
              DrugSaltName: 0,
              DrugManufacturer: 0,
              DrugStorageType: "",
              DrugTBItem: 0,
              DrugSchedule_H: true,
              DrugSchedule_H1: true,
              DrugSchedule_C: true,
              DrugSchedule_C1: true,
              DrugSchedule_G: true,
              DrugAnti_TB: true,
              DrugNorcotics: true,
              BatchDetails: RowToUpdate[0].batchNos,
              UseInEcom: true,
              ShowOnHomePage: true,
              DisableBuyButton: false,
              DisableWishListButton: false,
              VisibleIndividually: true,
              AllowCustomerReviews: true,
              AllowCustomerRemarks: true,
              Published: true,
              AddasComplementaryItem: false,
              AvailableForHomeDelivery: true,
              SP_PopupCategoryForTable: false,
              AvailableForTableOrder: true,
              AvailableForPickUp: true,
              AvailableForPOS: true,
              ItemAmtInComplementBonusAmt: true,
              Intermediate: true,
              PopularCategoryPriority: 0,
              ProductTag: "",
              SpecifyIndexNo: 0,
              MeasurmentGuideTool: "",
              MeasurmentHeightImage: "",
              HeightTitle: 0,
              Height: 0,
              MeasurmentWidthImage: "",
              WidthTilte: 0,
              Width: 0,
              MeasurmentWeightImage: "",
              WeightTitle: 0,
              Weight: 0,
              MeasurmentLengthImage: "",
              LengthTitle: 0,
              Length: 0,
              EnableShipping: true,
              CashOnDeliveryAvailable: true,
              FreeShipping: false,
              ShipSeparately: false,
              LocalShippingCharge: 0,
              ZonalShippingCharge: 0,
              NationalShippingCharge: 0,
              ReturnApplicationDays: 0,
              ReturnPolicyNotes: "",
              TryAtHome: false,
              AdditionShippingCharge: 0,
              EcomCategoryDetail: [0],
              MetaKeywords: "",
              MetaDescription: "",
              MetaTitle: "",
              SearchEnginFriendlyPageName: "",
              iUseMembershipCard: 0,
              IsDeleted: false,
            };

            var SaveItem = await ItemMaster.InsertItemMaster(ItemMasterData);
            setLoading(true);
            if (SaveItem && SaveItem.result) {
              // // // debugger;
              // Assuming SaveItem.result contains d1Id and d2Id values
              const { d1_Id, d2_Id } = SaveItem.result;
              // Update the array
              const updatedRows = RowToUpdate.map((row) => {
                // Modify d1Id and d2Id of each row
                return {
                  ...row,
                  d1_Id: d1_Id, // Use new value if available, otherwise keep existing
                  d2_Id: d2_Id,
                };
              });
              setLoading(false);
              alert("New Item saved");
              if (updatedRows) {
                // setLoading(true);
                var ProductTempData = await Transaction.SetProductTempData(
                  updatedRows,
                  ReferenceNumber
                );

                // Handle API response
                if (ProductTempData.isSuccess === false) {
                  console.log("API call failed. Response:", ProductTempData);
                } else if (ProductTempData.isSuccess === true) {
                  setChangeInData(false);
                  console.log("Product Temp Data saved successfully.");
                  // NewRowIndex should contain the new row index to be updated
                  var NewRowIndex = ProductTempData.result;

                  if (NewRowIndex.length === 1) {
                    // Update itemMasterRows with the new row index
                    setItemMasterRows((prevRows) => {
                      return prevRows.map((row, idx) => {
                        if (idx === Index) {
                          return { ...row, rowIndex: NewRowIndex[0] };
                        }
                        return row;
                      });
                    });
                  }
                  // alert(e.target.name);
                  // // debugger;
                  if (
                    e.target.name != "mrpPrice" &&
                    e.target.name != "TotalAmt"
                  ) {
                    var Productdata = await GetProductDataFunction(
                      NewRowIndex[0]
                    );
                    if (Productdata == true) {
                      setApiCallProductAllowed(true);
                    } else {
                      setApiCallProductAllowed(false);
                    }
                  }
                } else if (ProductTempData === "no item") {
                  console.log("No Item found in the response.");
                } else {
                  console.log("Error in API response: ", ProductTempData);
                }
              }
            } else if (SaveItem.errorMessages[0] == "Item already exists.") {
              alert("Item already exists");
              nextCellRefsProduct.current[Index][3].focus();
            } else {
              setLoading(false);
            }
            // }
          }
        }
        if (
          RowToUpdate[0].d2_Id == 0 ||
          RowToUpdate[0].d1_Id == 0
          //  || RowToUpdate[0].itemPrice === 0
        ) {
          try {
            // // debugger;
            // Call the API to save product temp data
            // var RowToUpdate = newrows.filter((item) => item.rowIndex === Rowidx);

            if (!ChangeInData) {
              return;
            }

            if (newrows) {
              RowToUpdate = newrows.find((item) => item.rowIndex === Rowidx);
            } else {
              RowToUpdate = itemMasterRows.find(
                (item) => item.rowIndex === Rowidx
              );
            }

            var ProductTempData = await Transaction.SetProductTempData(
              RowToUpdate,
              ReferenceNumber
            );

            // Handle API response
            if (ProductTempData.isSuccess === false) {
              console.log("API call failed. Response:", ProductTempData);
            } else if (ProductTempData.isSuccess === true) {
              setChangeInData(false);
              console.log("Product Temp Data saved successfully.");
              // NewRowIndex should contain the new row index to be updated
              var NewRowIndex = ProductTempData.result;

              if (NewRowIndex.length === 1) {
                // Update itemMasterRows with the new row index
                setItemMasterRows((prevRows) => {
                  return prevRows.map((row, idx) => {
                    if (idx === Index) {
                      return { ...row, rowIndex: NewRowIndex[0] };
                    }
                    return row;
                  });
                });
              }

              // alert(e.target.name);
              // // // // debugger;
              if (
                e.target.name !== "mrpPrice" &&
                e.target.name !== "TotalAmt"
              ) {
                var Productdata = await GetProductDataFunction(NewRowIndex[0]);
                if (Productdata == true) {
                  setApiCallProductAllowed(true);
                } else {
                  setApiCallProductAllowed(false);
                }
              }
            } else if (ProductTempData === "no item") {
              console.log("No Item found in the response.");
            } else {
              console.log("Error in API response: ", ProductTempData);
            }
          } catch (error) {
            console.error("Error while calling the API:", error);
          } finally {
            // Re-enable API call once the current call is completed
            setApiCallProductAllowed(true);
            return;
          }
        }

        try {
          let updatedData;
          if (name === "purPageSalePrice" || name === "mrpPrice") {
            if (name === "purPageSalePrice") {
              var data = [
                {
                  path: "salePrice",
                  op: "replace",
                  value: value,
                },
              ];
              if (!ChangeInData) {
                return;
              }
              const saveData = await ItemMaster.UpdateItemMasterPartially(
                data,
                RowToUpdate[0].d2_Id
              );
              console.log("saveData", saveData);
            } else if (name === "mrpPrice") {
              var data = [
                {
                  path: "mrpPrice",
                  op: "replace",
                  value: value,
                },
              ];
              if (!ChangeInData) {
                return;
              }
              const saveData = await ItemMaster.UpdateItemMasterPartially(
                data,
                RowToUpdate[0].d2_Id
              );
              console.log("saveData", saveData);
            }
            updatedData = RowToUpdate.map((row) => {
              return {
                ...row,
                purPageSalePrice:
                  name === "salePriceForPur"
                    ? parseFloat(value)
                    : row.purPageSalePrice,
                purPageMrpPrice:
                  name === "mrpPrice" ? parseFloat(value) : row.purPageMrpPrice,
              };
            });
          } else {
            updatedData = RowToUpdate;
          }
          if (!ChangeInData) {
            return;
          }
          var ProductTempData = await Transaction.SetProductTempData(
            updatedData,
            ReferenceNumber
          );

          // Handle API response
          if (ProductTempData.isSuccess === false) {
            console.log("API call failed. Response:", ProductTempData);
          } else if (ProductTempData.isSuccess === true) {
            setChangeInData(false);
            console.log("Product Temp Data saved successfully.");
            // NewRowIndex should contain the new row index to be updated
            var NewRowIndex = ProductTempData.result;

            if (NewRowIndex.length === 1) {
              // Update itemMasterRows with the new row index
              setItemMasterRows((prevRows) => {
                return prevRows.map((row, idx) => {
                  if (idx === Index) {
                    return { ...row, rowIndex: NewRowIndex[0] };
                  }
                  return row;
                });
              });
            }

            // alert(e.target.name);
            // // // // debugger;
            if (e.target.name != "mrpPrice" && e.target.name != "TotalAmt") {
              var Productdata = await GetProductDataFunction(NewRowIndex[0]);
              if (Productdata == true) {
                setApiCallProductAllowed(true);
              } else {
                setApiCallProductAllowed(false);
              }
            }
          } else if (ProductTempData === "no item") {
            console.log("No Item found in the response.");
          } else {
            console.log("Error in API response: ", ProductTempData);
          }
        } catch (error) {
          console.error("Error while calling the API:", error);
        } finally {
          // Re-enable API call once the current call is completed
          setApiCallProductAllowed(true);
        }
      } else {
        console.log("No item selected");
        setApiCallProductAllowed(true); // Re-enable API calls even when no product is selected
      }
    } catch {
      console.error("error");
    } finally {
      setLoading(false);
    }

    if (
      e.target.name == "Qty" ||
      e.target.name == "itemPrice" ||
      e.target.name == "DiscPer"
    ) {
      if (ReferenceNumber) {
        await Transaction.CalcualteTempData(ReferenceNumber);
      }
      await GetBillSundryeDataFunction();
    }
  };

  const [ApiCallSundryAllowed, setApiCallSundryAllowed] = useState(true);

  const onChangeSundrySaveMethod = async (e, Rowidx, Index) => {
    debugger;
    if (e.target.value == 0) {
      return;
    }
    if (!ApiCallSundryAllowed) {
      console.log("API Call is in progress, please wait.");
      return;
    }

    setApiCallSundryAllowed(false); // Disable further API calls

    // Filter the row to update
    var RowToUpdate = sundryRows.filter((item) => item.rowIndex === Rowidx);

    // Ensure there's a valid sundry data
    if (RowToUpdate[0]?.sundryName || RowToUpdate[0]?.sundryName != "") {
      try {
        // Call the API to save sundry temp data
        var SundryTempData = await Transaction.SetSundryTempData(
          RowToUpdate,
          ReferenceNumber
        );

        // Handle API response
        if (SundryTempData.isSuccess === false) {
          console.log("API call failed. Response:", SundryTempData);
        } else if (SundryTempData.isSuccess === true) {
          console.log("Sundry Temp Data saved successfully.");

          // NewRowIndex should contain the new row index to be updated
          var NewRowIndex = SundryTempData.result;

          if (NewRowIndex.length === 1) {
            // Update sundryRows with the new row index
            setSundryRows((prevRows) => {
              return prevRows.map((row, idx) => {
                if (idx === Index) {
                  return { ...row, rowIndex: NewRowIndex[0] };
                }
                return row;
              });
            });
          }

          GetBillSundryeDataFunction();
        } else if (SundryTempData === "no item") {
          console.log("No Item found in the response.");
        } else {
          console.log("Error in API response: ", SundryTempData);
        }
      } catch (error) {
        console.error("Error while calling the API:", error);
      } finally {
        // Re-enable API call once the current call is completed
        setApiCallSundryAllowed(true);
      }
    } else {
      console.log("No item selected");
      setApiCallSundryAllowed(true); // Re-enable API calls even when no sundry is selected
    }
  };

  const [transId, setTransId] = useState(0);
  const [showBillPrint, setShowBillPrint] = useState(false);

  const closeBillPrint = () => {
    setShowBillPrint(false);
  };

  const Reload = () => {
    window.location.reload();
  };

  const PrintBill = () => {
    setShowBillPrint(true);
    OpenAlertBoxForSalePage();
  };

  const DoneClicked = () => {
    OpenAlertBoxForSalePage();
    navigate(`/salelist/${id}`);
  };

  return (
    <div>
      <div>
        <div>
          <div className="rightmaster-company">
            {/* <TopNamePage heading="ModifyProduct"/> */}
            {/* <div>
              <Joyride
                continuous
                callback={() => {}}
                run={run}
                steps={steps}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                showProgress
              />
              {[].map((item) => {
                return (
                  <div
                    key={item}
                    id={`step-${item}`}
                    style={{
                      border: "1px solid white",
                      width: "100px",
                      height: "100px",
                      background: "#0c1d2b",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div> */}

            <div
              className="panel-heading"
              style={{ backgroundColor: "#212e4f" }}
            >
              <div className="panel-head-title">
                <h2
                  className="panel-title"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  {titles[id] || ""}
                </h2>
              </div>

              {/********************Modal Box Open*******************/}

              <MainButton
                isBtnSaleInvoiceSetting={true}
                OpenPartyModal={OpenPartyModal}
                isBtnOrder={true}
                isBtnChallan={true}
                showModal1={showModal1}
                showModal2={showModal2}
                SaveButton={SaveMethod}
                modalOpen={modalOpen}
              />
            </div>
            {/********************Modal Box End*******************/}
            {/* <div>
              <DateTime />
            </div> */}
            <div className="content_box">
              <div className="company-info-top-im">
                <div className="tab-head">
                  <div id="tabs">
                    <div className="tab-content-box">
                      <div className="tab-content-1">
                        {" "}
                        {/*height25vh*/}
                        <div
                          className="company-info-top-left table-left-padding mobileFlex-direction"
                          style={{
                            padding: "15px",
                            width:
                              LensInfoShow === true &&
                              TransactionTypeSetting.extraField1 == "1"
                                ? "40%"
                                : "100%",
                          }}
                        >
                          <div className="">
                            <div className="input-box-head">
                              {/*************************** Bill Data ***************/}
                              <div className="width100mobile">
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillSeries"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillSeries"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onBlur={onChangeSaveMethod}
                                      name="billSeriesId"
                                      value={inputValues.billSeriesId}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[1] = el)}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        -Select-
                                      </option>
                                      {BillSeriesSet && BillSeriesSet.length > 0
                                        ? BillSeriesSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billSeriesName}
                                            </option>
                                          ))
                                        : null}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Bill Series
                                    </label>
                                  </div>

                                  <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillNo"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="text"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      value={inputValues.billNumber}
                                      onBlur={onChangeSaveMethod}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[2] = el)}
                                      name="billNumber"
                                      required
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Bill No
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    {/* <div class="saleinvoicetop-input-container"> */}
                                    <select
                                      id="saleinvoicetopSelect"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onBlur={onChangeSaveMethod}
                                      name="billSeriesId"
                                      value={inputValues.billSeriesId}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[1] = el)}
                                      required
                                    >
                                      {/* <option value="">-Select-</option> */}
                                      {BillSeriesSet && BillSeriesSet.length > 0
                                        ? BillSeriesSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billSeriesName}
                                            </option>
                                          ))
                                        : null}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Series
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      autoComplete="off"
                                      type="text"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      value={inputValues.billNumber}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[2] = el)}
                                      name="billNumber"
                                      required
                                      onBlur={onChangeSaveMethod}
                                      disabled={
                                        BillNumberingSetting?.numberingCategory ===
                                        0
                                      } // Conditionally disable
                                    />

                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill No
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    id="DivBillDate width50mobile"
                                    style={{ margin: "1%", width: "48%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="date"
                                      // style={{ fontSize: "12px" }}
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onKeyUp={handleKeyDownMainPage}
                                      name="billDate"
                                      value={inputValues.billDate}
                                      ref={(el) => (nextInput.current[3] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Date
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1"
                                    id="DivBillType"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="billTaxTypeId"
                                      value={inputValues.billTaxTypeId}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[4] = el)}
                                      onBlur={onChangeSaveMethod}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          TaxTypeSet != null &&
                                          Array.isArray(TaxTypeSet)
                                        ) {
                                          return TaxTypeSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billTypeName}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Bill Types
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="date"
                                      // style={{ fontSize: "12px" }}
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      onKeyUp={handleKeyDownMainPage}
                                      name="billDate"
                                      value={inputValues.billDate}
                                      ref={(el) => (nextInput.current[3] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Date
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                  <div class="saleinvoicetop-input-container">
                                    <select
                                      // id="saleinvoicetopSelect"
                                      id="BillType"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="billTaxTypeId"
                                      value={inputValues.billTaxTypeId}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[4] = el)}
                                      onBlur={(e) =>
                                        onChangeSaveMethod("", "", e)
                                      }
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {(() => {
                                        if (
                                          TaxTypeSet != null &&
                                          Array.isArray(TaxTypeSet)
                                        ) {
                                          return TaxTypeSet.map((data) => {
                                            if (data.isActive === true) {
                                              return (
                                                <option
                                                  key={data.id}
                                                  value={data.id}
                                                >
                                                  {data.billTypeName}
                                                </option>
                                              );
                                            }
                                            return null; // If data.isDefault is false, no option is rendered
                                          });
                                        }
                                      })()}
                                    </select>

                                    <div class="saleinvoicetop-underline"></div>
                                    {/* </div> */}
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Bill Types
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>

                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 input-Width50"
                                    id="DivGodown"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.godown
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <select
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="godownId"
                                      value={inputValues.godownId}
                                      onBlur={onChangeSaveMethod}
                                      onKeyUp={handleKeyDownMainPage}
                                      required
                                      ref={(el) => (nextInput.current[5] = el)}
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          GodownSet != null &&
                                          Array.isArray(GodownSet)
                                        ) {
                                          return GodownSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Godown
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 input-Width50"
                                    id="DivBookedBy"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.bookedBy
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <select
                                      className="form-control inputEffect modify_im_input mode_limit mode_category"
                                      onChange={(e) =>
                                        handleInputChange_Series(e)
                                      }
                                      name="bookedById"
                                      onBlur={onChangeSaveMethod}
                                      value={inputValues.bookedById}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[6] = el)}
                                      required
                                    >
                                      <option
                                        Classname="form-control s-1"
                                        value=""
                                      >
                                        --Select--
                                      </option>
                                      {(() => {
                                        if (
                                          AgentSet != null &&
                                          Array.isArray(AgentSet)
                                        ) {
                                          return AgentSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.name}
                                            </option>
                                          ));
                                        }
                                      })()}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Booked By
                                    </label>
                                  </div> */}
                                  {TransInputSet.godown && (
                                    <div class="saleinvoicetop-input-container">
                                      <select
                                        // id="saleinvoicetopSelect"
                                        onChange={(e) =>
                                          handleInputChange_Series(e)
                                        }
                                        name="godownId"
                                        value={inputValues.godownId}
                                        onBlur={onChangeSaveMethod}
                                        onKeyUp={handleKeyDownMainPage}
                                        required
                                        ref={(el) =>
                                          (nextInput.current[5] = el)
                                        }
                                      >
                                        <option value="">-Select-</option>
                                        {(() => {
                                          if (
                                            GodownSet != null &&
                                            Array.isArray(GodownSet)
                                          ) {
                                            return GodownSet.map((data) => {
                                              if (data.isActive === true) {
                                                // Check if the item is active
                                                return (
                                                  <option
                                                    key={data.id}
                                                    value={data.id}
                                                  >
                                                    {data.name}
                                                  </option>
                                                );
                                              }
                                              return null; // Return null if the item is not active
                                            });
                                          }
                                          return null; // Return null or a default option if GodownSet is not an array or null
                                        })()}
                                      </select>

                                      <div class="saleinvoicetop-underline"></div>
                                      {/* </div> */}
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Godown
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                  {TransInputSet.bookedBy && (
                                    <div class="saleinvoicetop-input-container">
                                      <select
                                        // id="saleinvoicetopSelect"
                                        onChange={(e) =>
                                          handleInputChange_Series(e)
                                        }
                                        name="bookedById"
                                        onBlur={onChangeSaveMethod}
                                        value={inputValues.bookedById}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[6] = el)
                                        }
                                        required
                                      >
                                        <option value="">-Select-</option>
                                        {(() => {
                                          if (
                                            AgentSet != null &&
                                            Array.isArray(AgentSet)
                                          ) {
                                            return AgentSet.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            ));
                                          }
                                        })()}
                                      </select>

                                      <div class="saleinvoicetop-underline"></div>
                                      {/* </div> */}
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Booked By
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="input-box-SI input-height2 paddingzero Zeromargininmobile"
                                  style={{ width: "100%" }}
                                >
                                  {TransInputSet.creditLimit && (
                                    <div class="saleinvoicetop-input-container">
                                      <input
                                        id="CreditLimit"
                                        type="number"
                                        name="AccCreditLimit"
                                        value={
                                          InputValueAccMaster.AccCreditLimit
                                        }
                                        onChange={handleAccountMasterChange}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[15] = el)
                                        }
                                        required
                                        onBlur={onChangeSaveMethod}
                                        autoComplete="off"
                                      />
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Credit Limit
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                  {TransInputSet.referredBy && (
                                    <div class="saleinvoicetop-input-container">
                                      <input
                                        type="text"
                                        value={
                                          InputValueAccMaster.AccRefferedBy
                                        }
                                        name="AccRefferedBy"
                                        onKeyUp={(e) => {
                                          handleKeyDown_AccountSuggestion(
                                            e,
                                            filteredRefferedBy
                                          );
                                          handleKeyDownMainPage(e);
                                        }}
                                        autoComplete="off"
                                        onChange={handleAccountMasterChange}
                                        onBlur={closeSuggestionBox}
                                        ref={(el) =>
                                          (nextInput.current[16] = el)
                                        }
                                        required
                                      />
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Referred By
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                      {isDropdownOpen2 && (
                                        <ul
                                          className="ItemmasterDuggestionsaleinvoice"
                                          ref={suggestionBoxRef}
                                        >
                                          {filteredRefferedBy.map(
                                            (ref, index) => (
                                              <li
                                                key={index}
                                                ref={(el) =>
                                                  (itemRefs.current[index] = el)
                                                }
                                                onClick={() =>
                                                  handleAccountSelection(
                                                    ref.d1RefId,
                                                    // ref,
                                                    "AccRefferedBy"
                                                  )
                                                }
                                                style={{
                                                  // paddingBottom: "2px",
                                                  // cursor: "pointer",
                                                  // borderBottom: "1px solid #ccc",
                                                  backgroundColor:
                                                    highlightedIndex === index
                                                      ? "#e6f7ff"
                                                      : "transparent",
                                                }}
                                              >
                                                {ref.name}
                                              </li>
                                            )
                                          )}
                                          <li
                                            key={filteredAccount.length}
                                            ref={(el) =>
                                              (itemRefs.current[
                                                filteredAccount.length
                                              ] = el)
                                            }
                                            addNew_btn_saleInvoice
                                            onClick={() =>
                                              openAccountMasterModal("Add")
                                            }
                                            className="addNew_btn_saleInvoice"
                                            style={{
                                              cursor: "pointer",
                                              borderBottom: "1px solid #ccc",
                                              backgroundColor:
                                                highlightedIndex ===
                                                filteredAccount.length
                                                  ? "black"
                                                  : "#5e709e",
                                            }}
                                          >
                                            Add New{" "}
                                            <i class="fa-solid fa-plus"></i>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/*************************** Party Details ***************/}
                              <div className="width100mobile margin-25-mob">
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <select
                                    id="NameTitle"
                                    className="form-control nameTitle inputEffect"
                                    style={{
                                      width: "20%",
                                      display:
                                        TransInputSet && TransInputSet.title
                                          ? "block"
                                          : "none",
                                    }}
                                    onBlur={onChangeSaveMethod}
                                    onChange={handleAccountMasterChange}
                                    name="namePrefix"
                                    value={InputValueAccMaster.namePrefix}
                                    onKeyUp={handleKeyDownMainPage}
                                    ref={(el) => (nextInput.current[7] = el)}
                                  >
                                    <option>Mr.</option>
                                    <option>Mrs.</option>
                                    <option>Ms.</option>
                                  </select> */}
                                  {TransInputSet.title && (
                                    <div
                                      class="saleinvoicetop-input-container"
                                      style={{ width: "15%" }}
                                    >
                                      <select
                                        id="NameTitle"
                                        onBlur={onChangeSaveMethod}
                                        onChange={handleAccountMasterChange}
                                        name="namePrefix"
                                        value={InputValueAccMaster.namePrefix}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[7] = el)
                                        }
                                        required
                                      >
                                        <option value="">--</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                      </select>

                                      <div class="saleinvoicetop-underline"></div>
                                      {/* </div> */}
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Title
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}

                                  {/* <div style={{ width: "100%" }}>
                                    <div
                                      className="input-containerEffect mt-1"
                                      id="PartyInput"
                                    >
                                      <input
                                        className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                        type="text"
                                        value={InputValueAccMaster.AccName}
                                        name="AccName"
                                        onKeyUp={(e) =>
                                          handleKeyDown_AccountSuggestion(
                                            e,
                                            filteredAccount
                                          )
                                        }
                                        autoComplete="off"
                                        required
                                        onBlur={closeSuggestionBox}
                                        onChange={handleAccountMasterChange}
                                        onDoubleClick={() =>
                                          openAccountMasterModal("Add")
                                        }
                                        style={{
                                          fontSize: "14px",
                                          width: "100%",
                                        }}
                                      />

                                      <label
                                        id="input1"
                                        className="labelEffect1"
                                      >
                                        Part
                                        <span style={{ color: "red" }}>
                                          y
                                        </span>{" "}
                                        A/c
                                      </label>

                                      {isDropdownOpen1 && (
                                        <ul
                                          className="ItemmasterDuggestionsaleinvoice"
                                          ref={suggestionBoxRef}
                                        >
                                          {filteredAccount.map((Acc, index) => (
                                            <li
                                              key={index}
                                              ref={(el) =>
                                                (itemRefs.current[index] = el)
                                              }
                                              onClick={() =>
                                                handleAccountSelection(
                                                  Acc.d2Id,
                                                  Acc,
                                                  "AccName"
                                                )
                                              }
                                              style={{
                                                // padding: "2px 5px",
                                                // cursor: "pointer",
                                                // borderBottom: "1px solid #ccc",
                                                backgroundColor:
                                                  highlightedIndex === index
                                                    ? "#e6f7ff"
                                                    : "transparent",
                                              }}
                                            >
                                              {Acc.name}
                                            </li>
                                          ))}
                                          <li
                                            key={filteredAccount.length}
                                            ref={(el) =>
                                              (itemRefs.current[
                                                filteredAccount.length
                                              ] = el)
                                            }
                                            onClick={() =>
                                              openAccountMasterModal("Add")
                                            }
                                            style={{
                                              cursor: "pointer",
                                              borderBottom: "1px solid #ccc",
                                              padding: "2px 5px",
                                              backgroundColor:
                                                highlightedIndex ===
                                                filteredAccount.length
                                                  ? "#e6f7ff"
                                                  : "transparent",
                                            }}
                                          >
                                            Add New
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div> */}

                                  <div
                                    class="saleinvoicetop-input-container"
                                    style={{ width: "82%" }}
                                  >
                                    <input
                                      type="text"
                                      ref={(el) => (nextInput.current[8] = el)}
                                      value={InputValueAccMaster.AccName}
                                      name="AccName"
                                      onKeyUp={(e) => {
                                        handleKeyDown_AccountSuggestion(
                                          e,
                                          filteredAccount
                                        );
                                        handleKeyDownMainPage(e);
                                      }}
                                      autoComplete="off"
                                      required
                                      onBlur={closeSuggestionBox}
                                      onChange={handleAccountMasterChange}
                                      onDoubleClick={() =>
                                        selectedAccId
                                          ? openAccountMasterModal(
                                              selectedAccId
                                            )
                                          : openAccountMasterModal("Add")
                                      }
                                      style={{
                                        fontSize: "14px",
                                        width: "100%",
                                      }}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Part
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "600",
                                        }}
                                      >
                                        y
                                      </span>{" "}
                                      A/c
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                    {isDropdownOpen1 && (
                                      <ul
                                        className="ItemmasterDuggestionsaleinvoice"
                                        ref={suggestionBoxRef}
                                      >
                                        {filteredAccount.map((Acc, index) => (
                                          <li
                                            key={index}
                                            ref={(el) =>
                                              (itemRefs.current[index] = el)
                                            }
                                            onClick={() =>
                                              handleAccountSelection(
                                                Acc.d1RefId,
                                                // Acc,
                                                "AccName"
                                              )
                                            }
                                            style={{
                                              // padding: "2px 5px",
                                              // cursor: "pointer",
                                              // borderBottom: "1px solid #ccc",
                                              backgroundColor:
                                                highlightedIndex === index
                                                  ? "#e6f7ff"
                                                  : "transparent",
                                            }}
                                          >
                                            {Acc.name}{" "}
                                            {Acc.mobileNo
                                              ? `(${Acc.mobileNo})`
                                              : ""}
                                          </li>
                                        ))}
                                        <li
                                          key={filteredAccount.length}
                                          ref={(el) =>
                                            (itemRefs.current[
                                              filteredAccount.length
                                            ] = el)
                                          }
                                          className="addNew_btn_saleInvoice"
                                          addNew_btn_saleInvoice
                                          onClick={() =>
                                            openAccountMasterModal("Add")
                                          }
                                          style={{
                                            cursor: "pointer",
                                            borderBottom: "1px solid #ccc",
                                            backgroundColor:
                                              highlightedIndex ===
                                              filteredAccount.length
                                                ? "black"
                                                : "#5e709e",
                                          }}
                                        >
                                          {" "}
                                          Add New
                                          <i class="fa-solid fa-plus"></i>
                                        </li>
                                      </ul>
                                    )}
                                  </div>

                                  {/* <div
                                    onClick={toggleSlider}
                                    className="btn-lavender mt-1"
                                  >
                                    <i
                                      className="fa-solid fa-right-from-bracket"
                                      onClick={toggleSlider}
                                    ></i>
                                  </div> */}
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="text"
                                      name="AccAddress"
                                      value={InputValueAccMaster.AccAddress}
                                      onBlur={onChangeSaveMethod}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[9] = el)}
                                      required
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Address
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 width50mobile"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="number"
                                      name="AccPhoneNo"
                                      value={InputValueAccMaster.AccPhoneNo}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[10] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Mobile
                                    </label>
                                  </div> */}

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="number"
                                      name="AccPhoneNo"
                                      value={InputValueAccMaster.AccPhoneNo}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={(e) => {
                                        handleKeyDown_AccountSuggestion(
                                          e,
                                          filteredAccount
                                        );
                                        handleKeyDownMainPage(e);
                                      }}
                                      onBlur={closeSuggestionBox}
                                      // onKeyUp={handleKeyDownMainPage}
                                      inputMode="numeric"
                                      ref={(el) => (nextInput.current[9] = el)}
                                      style={{
                                        appearance: "textfield",
                                        MozAppearance: "textfield",
                                      }}
                                      required
                                      // onBlur={onChangeSaveMethod}
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Mobile
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>

                                    {isDropdownOpen11 && (
                                      <ul
                                        className="ItemmasterDuggestionsaleinvoice"
                                        ref={suggestionBoxRef}
                                      >
                                        {filteredAccountWithMobile.map(
                                          (Acc, index) => (
                                            <li
                                              key={index}
                                              ref={(el) =>
                                                (itemRefs.current[index] = el)
                                              }
                                              onClick={() =>
                                                handleAccountSelection(
                                                  Acc.d1RefId,
                                                  // Acc,
                                                  "AccPhoneNo"
                                                )
                                              }
                                              style={{
                                                // padding: "2px 5px",
                                                // cursor: "pointer",
                                                // borderBottom: "1px solid #ccc",
                                                backgroundColor:
                                                  highlightedIndex === index
                                                    ? "#e6f7ff"
                                                    : "transparent",
                                              }}
                                            >
                                              {Acc.mobileNo} ({Acc.name})
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>

                                  <div class="saleinvoicetop-input-container">
                                    <input
                                      type="text"
                                      name="AccAddress"
                                      value={InputValueAccMaster.AccAddress}
                                      onBlur={onChangeSaveMethod}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[10] = el)}
                                      required
                                    />
                                    <label
                                      for="saleinvoicetopInput"
                                      class="saleinvoicetop-label"
                                    >
                                      Address
                                    </label>
                                    <div class="saleinvoicetop-underline"></div>
                                  </div>
                                </div>
                                {/***********/}
                                {/*<div className="input-box-SI input-height2 paddingzero" style={{ width: "100%" }}>
                                                                    <div className="addedGridView" style={{ marginTop: "-5px" }}>
                                                                        {(() => {
                                                                            if (selectedData.length != null) {
                                                                                
                                                                                return (
                                                                                    selectedData.map((data) => (
                                                                                        <div className="input-containerEffect mt-2" style={{ margin: "1%" }} key={data.id}>
                                                                                            <input id={data.idName} className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale" type="text" style={{ width: "98%" }} required />
                                                                                            <label id="input1" className="labelEffect1">{data.name}</label>
                                                                                        </div>
                                                                                    ))
                                                                                )
                                                                            }
                                                                            return // console.logg("not found");;
                                                                        })()}
                                                                    </div>
                                                                </div>*/}
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{ width: "100%" }}
                                >
                                  {/* <div
                                    className="input-containerEffect width50mobile"
                                    id="DivDateofBirth"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet &&
                                        TransInputSet.dateOfBirth
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      id="DateofBirth"
                                      type="date"
                                      name="AccDob"
                                      value={InputValueAccMaster.AccDob}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[11] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Date of birth
                                    </label>
                                  </div>

                                  <div
                                    className="input-containerEffect width50mobile"
                                    id="DivAnniversaryDate "
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet &&
                                        TransInputSet.anniversaryDate
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                      id="AnniversaryDate"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                      type="date"
                                      name="AccAnn"
                                      value={InputValueAccMaster.AccAnn}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[12] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Anniversary Date
                                    </label>
                                  </div> */}
                                  {TransInputSet.dateOfBirth && (
                                    <div class="saleinvoicetop-input-container">
                                      <input
                                        id="DateofBirth"
                                        type="date"
                                        name="AccDob"
                                        value={InputValueAccMaster.AccDob}
                                        onChange={handleAccountMasterChange}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[11] = el)
                                        }
                                        required
                                        onBlur={onChangeSaveMethod}
                                      />
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Date of birth
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                  {TransInputSet.anniversaryDate && (
                                    <div class="saleinvoicetop-input-container">
                                      <input
                                        id="AnniversaryDate"
                                        type="date"
                                        name="AccAnn"
                                        value={InputValueAccMaster.AccAnn}
                                        onChange={handleAccountMasterChange}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[12] = el)
                                        }
                                        required
                                        onBlur={onChangeSaveMethod}
                                      />
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Anniversary Date
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="input-box-SI input-height2 paddingzero"
                                  style={{
                                    width: "100%",
                                    margin: "0px",
                                  }}
                                >
                                  {/* <div
                                    className="input-containerEffect width50mobile"
                                    id="DivPOS"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.pos
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                    id="POS"
                                      type="text"
                                      name="AccPOS"
                                      value={InputValueAccMaster.AccPOS}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[13] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      POS
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect width50mobile"
                                    id="DivAccountBalance"
                                    style={{
                                      margin: "1%",
                                      display:
                                        TransInputSet && TransInputSet.accBal
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <input
                                    className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                                    id="AccountBalance"
                                      type="number"
                                      name="AccBalance"
                                      value={InputValueAccMaster.AccBalance}
                                      onChange={handleAccountMasterChange}
                                      onKeyUp={handleKeyDownMainPage}
                                      ref={(el) => (nextInput.current[14] = el)}
                                      required
                                      onBlur={onChangeSaveMethod}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Acc Balance
                                    </label>
                                  </div> */}

                                  {TransInputSet.pos && (
                                    <div class="saleinvoicetop-input-container">
                                      {/* <input
                                  
                                        type="text"
                                        name="AccPOS"
                                        value={InputValueAccMaster.AccPOS}
                                     
                                        ref={(el) =>
                                          (nextInput.current[13] = el)
                                        }
                                        required
                                        onBlur={onChangeSaveMethod}
                                      /> */}

                                      <select
                                        id="POS"
                                        onBlur={onChangeSaveMethod}
                                        onChange={handleAccountMasterChange}
                                        name="AccPOS"
                                        value={InputValueAccMaster.AccPOS}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[13] = el)
                                        }
                                        required
                                      >
                                        <option value="">-Select State-</option>
                                        {(() => {
                                          if (
                                            stateInfoMain &&
                                            Array.isArray(stateInfoMain) &&
                                            stateInfoMain.length > 0
                                          ) {
                                            return stateInfoMain
                                              .filter(
                                                (state) =>
                                                  state.countryId == 1101
                                              )
                                              .map((state) => (
                                                <option
                                                  key={state.id}
                                                  value={state.id}
                                                >
                                                  {state.stateName}
                                                </option>
                                              ));
                                          } else {
                                            return (
                                              <option value="">
                                                No states available
                                              </option>
                                            );
                                          }
                                        })()}
                                      </select>

                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        POS
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                  {TransInputSet.accBal && (
                                    <div class="saleinvoicetop-input-container">
                                      <input
                                        autoComplete="off"
                                        id="AccountBalance"
                                        type="number"
                                        name="AccBalance"
                                        value={InputValueAccMaster.AccBalance}
                                        onChange={handleAccountMasterChange}
                                        onKeyUp={handleKeyDownMainPage}
                                        ref={(el) =>
                                          (nextInput.current[14] = el)
                                        }
                                        required
                                        onBlur={onChangeSaveMethod}
                                      />
                                      <label
                                        for="saleinvoicetopInput"
                                        class="saleinvoicetop-label"
                                      >
                                        Acc Balance
                                      </label>
                                      <div class="saleinvoicetop-underline"></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/*********************** Order List ********************/}
                            {isModalOpen1 && (
                              <div className="modal">
                                <div className="modal-content1">
                                  <div className="modal-title">
                                    <h3 className="modal-h">Order List</h3>
                                    <span
                                      className="close"
                                      onClick={hideModal1}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div className="modal-content-im">
                                    <div className="table-container">
                                      <table className="table-t">
                                        <thead className="thead-groupmaster">
                                          <tr>
                                            <th className="table-th table-sn">
                                              SN
                                            </th>
                                            <th className="table-th">
                                              Bill No
                                            </th>
                                            <th className="table-th">Date</th>
                                            <th className="table-th ">
                                              Bill Type
                                            </th>
                                            <th className="table-th">
                                              Blnc Qty
                                            </th>
                                            <th className="table-th">
                                              Total Amt
                                            </th>
                                            <th className="table-th">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          className="table-overflow"
                                          style={{
                                            overflowY: "scroll",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <tr>
                                            <td>1</td>
                                            <td>1343534</td>
                                            <td>10/01/2023</td>
                                            <td>GST</td>
                                            <td>Amount</td>
                                            <td>1000</td>
                                            <td>Pending</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <PageTabs />

                            {/*********************** Challan List ********************/}
                            {isModalOpen2 && (
                              <div className="modal">
                                <div className="modal-content1">
                                  <div className="modal-title">
                                    <h3 className="modal-h">Challan List</h3>
                                    <span
                                      className="close"
                                      onClick={hideModal2}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                  <div className="modal-content-im">
                                    <div className="table-container">
                                      <table className="table-t">
                                        <thead className="thead-groupmaster">
                                          <tr>
                                            <th className="table-th table-sn">
                                              SN
                                            </th>
                                            <th className="table-th">
                                              Bill No
                                            </th>
                                            <th className="table-th">Date</th>
                                            <th className="table-th ">
                                              Bill Type
                                            </th>
                                            <th className="table-th">
                                              Blnc Qty
                                            </th>
                                            <th className="table-th">
                                              Total Amt
                                            </th>
                                            <th className="table-th">Status</th>
                                          </tr>
                                        </thead>
                                        <tbody
                                          className="table-overflow"
                                          style={{
                                            overflowY: "scroll",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <tr>
                                            <td>1</td>
                                            <td>1343534</td>
                                            <td>10/01/2023</td>
                                            <td>GST</td>
                                            <td>Amount</td>
                                            <td>1000</td>
                                            <td>Pending</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {/**************************Lens Part ***************************/}
                        {TransactionTypeSetting.extraField1 == "1" &&
                          LensInfoShow &&
                          !validIds.includes(id) && (
                            <LensInfoForInvoice
                              inputValues={inputValues}
                              activeTab1={activeTab1}
                              setActiveTab1={setActiveTab1}
                              handleTabClick1={handleTabClick1}
                              mainPageInputRef={mainPageInputRef}
                              lensPageInputRef={lensPageInputRef}
                              ReferenceNumber={ReferenceNumber}
                              suggestionBoxRef={suggestionBoxRef}
                              openAccountMasterModal={openAccountMasterModal}
                              highlightedIndex={highlightedIndex}
                              setHighlightedIndex={setHighlightedIndex}
                              itemRefs={itemRefs}
                              nextCellRefs={nextCellRefsProduct}
                              itemMasterRows={itemMasterRows}
                              InputValueAccMaster={InputValueAccMaster}
                              imageBaseUrl={imageBaseUrl}
                              confirmation={confirmation}
                              lensinforef={lensinforef}
                              onDelivDayChange={handleDelivDayChange}
                              inputRefs={inputRefs}
                            />
                          )}
                      </div>
                      {/****************************************************** Product Table **************************************************************************/}
                      <p style={{ paddingLeft: "10px", display: "none" }}>
                        Ref No :-{ReferenceNumber}
                      </p>
                      <div>
                        <ProductList
                          setInputValues={setInputValues}
                          inputValues={inputValues}
                          mainPageInputRef={mainPageInputRef}
                          itemMasterRows={itemMasterRows}
                          setItemMasterRows={setItemMasterRows}
                          nextCellRefsProduct={nextCellRefsProduct}
                          sundryRows={sundryRows}
                          suggestionBoxRef={suggestionBoxRef}
                          itemRefs={itemRefs}
                          currencyDecimalPlaces={currencyDecimalPlaces}
                          onChangeProductSaveMethod={onChangeProductSaveMethod}
                          PreviousPage={PreviousPage}
                          NextPage={NextPage}
                          pageNo={pageNo}
                          setPageNo={setPageNo}
                          setItemPerPage={setItemPerPage}
                          ItemPerPage={ItemPerPage}
                          allSettings={allSettings}
                          GetProductDataFunction={GetProductDataFunction}
                          ReferenceNumber={ReferenceNumber}
                          setLoading={setLoading}
                          setModalOpen={setModalOpen}
                          openGroupModal={openGroupModal}
                          BatchNoData={BatchNoData}
                          setBatchNoData={setBatchNoData}
                          ProductOpnedInPopup={ProductOpnedInPopup}
                          enableBatchNoEntry={
                            TransactionTypeSetting.enableBatchNoEntry
                          }
                          setChangeInData={setChangeInData}
                          inputRefs={inputRefs}
                          // fetchDataForSerialNo={fe={fe}chDataForSerialNo}
                        />
                      </div>
                      {/****************************************************** Total Details *************************************************************************/}
                      <div className="tabs-main1 height30vh">
                        <div id="tabs" className="tab_a1">
                          <div
                            className="tab-btn-box1 tab-b3 bg-color"
                            style={{ backgroundColor: "#5e709e" }}
                          >
                            <div
                              className={`tab-b1 tab-2 active11 ${
                                activeTab === 11 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(11);
                                showtabs11();
                              }}
                            >
                              <label className="c-w col-black">Sundry</label>
                            </div>
                            <div
                              className={`tab-b1 tab-2 ${
                                activeTab === 22 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(22);
                                showtabs22();
                              }}
                            >
                              <label className="c-w col-black">GST</label>
                            </div>
                            {/*<div*/}
                            {/*    className={`tab-b1 tab-2 ${activeTab === 33 ? 'active' : ''}`}*/}
                            {/*    onClick={() => { handleTabClick(33); showtabs33() }} >*/}
                            {/*    <label className="c-white col-black">Coupon</label>*/}
                            {/*</div>*/}
                            <div
                              className={`tab-b1 tab-2 ${
                                activeTab === 44 ? "active-sundry" : ""
                              }`}
                              onClick={() => {
                                handleTabClick(44);
                                showtabs44();
                              }}
                            >
                              <label className="c-w col-black">Loyality</label>
                            </div>
                          </div>
                          <div className="tab-content-box1 ">
                            {/*************** Sundry *****************************/}
                            {tabcontent11 && (
                              <SundryDetailsData
                                nextCellRefs={nextCellRefs}
                                inputValues={inputValues}
                                handleKeyDown_SundrySuggestion={
                                  handleKeyDown_SundrySuggestion
                                }
                                handleSundryNameChange={handleSundryNameChange}
                                inputPosition={inputPosition}
                                suggestionBoxRef={suggestionBoxRef}
                                getPosition={getPosition}
                                sundryRows={sundryRows}
                                setSundryRows={setSundryRows}
                                handleSundryCellChange={handleSundryCellChange}
                                handleInputChangeEdit={handleInputChangeEdit}
                                currencyDecimalPlaces={currencyDecimalPlaces}
                                editMode={editMode}
                                itemRefs={itemRefs}
                                deleteSundryRow={deleteSundryRow}
                                handleSundryRowKeyDown={handleSundryRowKeyDown}
                                handleBlurForEdit={handleBlurForEdit}
                                handleSundrySelection={handleSundrySelection}
                              />
                            )}

                            {/*************** GST *****************************/}
                            {tabcontent22 && (
                              <div className="tab-content-1 height-115">
                                <div className="input-box-top-head">
                                  <div className="input-box-head-left1">
                                    <div className="input-box-head-1">
                                      <div className="input-box-SI-2 ">
                                        <div className="input-box-m7">
                                          <div className="space-between">
                                            <div className="button-col-s1">
                                              <button
                                                type="button"
                                                className="button-col-s2"
                                              >
                                                Bill Wise
                                              </button>
                                            </div>
                                            <div className="button-col-s1">
                                              <button
                                                type="button"
                                                className="button-col-s2"
                                              >
                                                Item Wise
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s1 SaleTaxBtn"></span>
                                            <label>Tax not calculated</label>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s4 SaleTaxBtn"></span>
                                            <label>Tax not apply</label>
                                          </div>
                                          <div
                                            className="button-col-SOTax flex-start"
                                            style={{ alignItems: "center" }}
                                          >
                                            <span className="color-col-s3 SaleTaxBtn"></span>
                                            <label>Tax value not apply</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/************************* Loyality ***********************/}
                            {tabcontent44 && (
                              <div className="tab-content-3">
                                <div className="input-box-top-head">
                                  <div className="input-box-head-left2">
                                    <div className="input-box-head-1">
                                      <table style={{ width: "100%" }}>
                                        <thead>
                                          <tr>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              S.No.
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Item Details
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Loyalty Point
                                            </th>
                                            <th
                                              className="th"
                                              style={{
                                                backgroundColor: "#eaeaea",
                                              }}
                                            >
                                              Loyalty Amount
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="td">1</td>
                                            <td className="td">Lens</td>
                                            <td className="td">50</td>
                                            <td className="td">50</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div
                              className="space-between"
                              style={{ padding: "7px" }}
                            >
                              {/* <button
                                style={{ padding: "0px 10px" }}
                                onClick={ApplyTaxClick}
                              >
                                Apply Tax
                              </button> */}
                              <span className="span1 fs-13">
                                Total Sundry Amt: &nbsp;&nbsp;
                                <span className="pay_amt1">
                                  {parseFloat(inputValues.TotalSundry).toFixed(
                                    currencyDecimalPlaces
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/***************************** Bill Payment Invoice **************************/}
                        <div className="table-row1">
                          {/*<div className="col_58" style={{ backgroundColor:"#98a1b7", color:"black", borderLeft:"1px solid black"} }>Bill Payment Details</div>*/}
                          <div>
                            <BillPaymentInvoice
                              setInputValues={setInputValues}
                              inputValues={inputValues}
                              nextInput={nextInput}
                              ReferenceNumber={ReferenceNumber}
                              BillSerisMasterData={BillSerisMasterData}
                              TransactionLListForPayment={
                                TransactionLListForPayment
                              }
                              setModalOpen={setModalOpen}
                              modalOpen={modalOpen}
                              setTransactionLListForPayment={
                                setTransactionLListForPayment
                              }
                              setBillSerisMasterData={setBillSerisMasterData}
                              currencyDecimalPlaces={currencyDecimalPlaces}
                              GetAccountDataFunction={GetAccountDataFunction}
                              GetTransactionSummaryBySrsId={
                                GetTransactionSummaryBySrsId
                              }
                              childRef={childRef}
                              inputRefs={inputRefsForBill}
                              inputValuesPaidAmount={inputValuesPaidAmount}
                              setInputValuesPaidAmount={
                                setInputValuesPaidAmount
                              }
                              setcount={setcount}
                              count={count}
                              adjamountToSend={adjamountToSend}
                              setAdjamountToSend={setAdjamountToSend}
                              adjamountRemark={adjamountRemark}
                              setAdjamountRemark={setAdjamountRemark}
                              InputValueAccMaster={InputValueAccMaster}
                            />
                          </div>
                        </div>
                        {/***************************** Total **************************/}
                        <div className="table-row2">
                          <span className="table-ax-1"></span>
                          <div
                            className="padding-20 totalBg-color display-center"
                            style={{
                              backgroundColor: "rgba(94, 112, 158, 0.12)",
                              height: "100%",
                            }}
                          >
                            <div className="space-between mt-1">
                              <label
                                className="c-b"
                                onClick={itemWiseBatchOpen}
                              >
                                <span style={{ color: "red" }}>D</span>
                                iscount
                              </label>
                              {/* <select
                                name=""
                                id=""
                                className=" modify_im_input12 mode_limit mode_category"
                                style={{ width: "50px" }}
                              >
                                <option value="">%</option>
                                <option value=""> ₹</option>
                              </select> */}
                              <div
                                className="flex-end"
                                style={{ width: "65%" }}
                              >
                                <div
                                  className="image-input-container"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "48%",
                                    }}
                                  >
                                    <label
                                      className="discount_label"
                                      htmlFor=""
                                    >
                                      {" "}
                                      %
                                    </label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      className=" modify_im_input12 mode_limit mode_category"
                                      onBlur={(e) =>
                                        ApplyDiscountInPercentage(e)
                                      }
                                      id="discountPercentage"
                                      value={
                                        editMode["discountPercentage"]
                                          ? DiscountData.discountPercentage
                                          : parseFloat(
                                              DiscountData.discountPercentage
                                            ).toFixed(currencyDecimalPlaces)
                                      }
                                      onFocus={(e) => handleInputChangeEdit(e)}
                                      onKeyUp={handleKeyDownMainPage}
                                      name="discountPercentage"
                                      onChange={(e) => setDiscountDataValue(e)}
                                      ref={(el) =>
                                        (nextInput.current[100] = el)
                                      }
                                      required
                                    />{" "}
                                  </div>
                                  -
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "48%",
                                    }}
                                  >
                                    <label
                                      className="discount_label"
                                      htmlFor=""
                                    >
                                      {" "}
                                      ₹
                                    </label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      className=" modify_im_input12 mode_limit mode_category"
                                      onBlur={(e) => ApplyDiscountInAmount(e)}
                                      onKeyUp={handleKeyDownMainPage}
                                      id="discountAmount"
                                      value={
                                        editMode["discountAmount"]
                                          ? DiscountData.discountAmount
                                          : parseFloat(
                                              DiscountData.discountAmount
                                            ).toFixed(currencyDecimalPlaces)
                                      }
                                      onFocus={(e) => handleInputChangeEdit(e)}
                                      name="discountAmount"
                                      onChange={(e) => setDiscountDataValue(e)}
                                      ref={(el) =>
                                        (nextInput.current[101] = el)
                                      }
                                      required
                                    />
                                  </div>
                                  {/*onClick={handleApplyCoupon}*/}
                                </div>
                              </div>
                            </div>
                            <div className="space-between mt-1">
                              <label className="c-b">Loyality</label>
                              <div
                                className="flex-end"
                                style={{ width: "65%" }}
                              >
                                <div
                                  className="image-input-container"
                                  style={{ width: "100%" }}
                                >
                                  <input
                                    type="text"
                                    placeholder=""
                                    className=" modify_im_input12 mode_limit mode_category"
                                    onChange={(e) =>
                                      handleInputChange_Series(e)
                                    }
                                    name="LoyalityAmt"
                                    value={inputValues.LoyalityAmt}
                                    onKeyUp={handleKeyDownMainPage}
                                    ref={(el) => (nextInput.current[102] = el)}
                                    required
                                  />
                                  <button className=" apply-button input-image">
                                    Use
                                  </button>{" "}
                                  {/*onClick={handleApplyCoupon}*/}
                                </div>
                              </div>
                            </div>
                            <div className="space-between mt-1">
                              <label className="c-b">Coupon</label>
                              <div
                                className="flex-end"
                                style={{ width: "65%" }}
                              >
                                <div
                                  className="image-input-container"
                                  style={{ width: "100%" }}
                                >
                                  <input
                                    className=" modify_im_input12 mode_limit mode_category"
                                    type="text"
                                    placeholder="Enter Coupon"
                                    onChange={(e) =>
                                      handleInputChange_Series(e)
                                    }
                                    name="CouponAmt"
                                    value={inputValues.CouponAmt}
                                    onKeyUp={handleKeyDownMainPage}
                                    ref={(el) => (nextInput.current[103] = el)}
                                    required
                                  />
                                  <button
                                    className=" apply-button input-image"
                                    onClick={openCouponModal}
                                  >
                                    View
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="space-between mt-1">
                              <label className="c-b">Taxable Value</label>
                              <input
                                className="modify_im_input12 mode_limit mode_category"
                                type="text"
                                style={{ width: "65%", color: "red" }}
                                onChange={(e) => handleInputChange_Series(e)}
                                name="TaxableAmt"
                                // value={inputValues.TaxableAmt}
                                value={parseFloat(
                                  inputValues.TaxableAmt
                                ).toFixed(currencyDecimalPlaces)}
                                disabled
                              />
                            </div>
                            <div className="space-between mt-1">
                              <label
                                className="c-b"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                Invoice Value
                              </label>
                              <input
                                className="modify_im_input12 mode_limit mode_category"
                                type="text"
                                style={{
                                  width: "65%",
                                  color: "red",
                                  fontSize: "17px",
                                }}
                                onChange={(e) => handleInputChange_Series(e)}
                                name="GrossAmt"
                                // value={inputValues.GrossAmt}
                                value={parseFloat(inputValues.GrossAmt).toFixed(
                                  currencyDecimalPlaces
                                )}
                                disabled
                              />
                            </div>
                            {/*<div className="space-between mt-1">*/}
                            {/*    <label className="c-b">Allow Loyality</label>*/}
                            {/*    <div className="col-d-1">*/}
                            {/*        <input onChange={handleToggleChangsi3} disabled={isCheckboxDisabledsi3} style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                            {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> Loyalty Amount</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="space-between mt-1">*/}
                            {/*    <label className="c-b">Add Discount</label>*/}
                            {/*    <div className="col-d-1">*/}
                            {/*        <input style={{ boxShadow: "none" }} className="modify_product_checkbox" type="checkbox" />*/}
                            {/*        <label className="modify_p_text_checkbox col-in-1 c-b"> (Party Wise Catgry Disc)</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                          </div>
                        </div>
                      </div>
                      <div id="saleRemarkPart">
                        <div className="space-evenly saleRemarkPart">
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivDeliveryDate"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.deliveryDate
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                              type="date"
                              style={{ padding: "0px 7px", fontSize: "13px" }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="DeliveryDate"
                              value={inputValues.DeliveryDate}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[104] = el)}
                              required
                              // onBlur={onChangeSaveMethod}
                            />
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{
                                fontSize: "12px",
                                padding: "0px 0px 2px 0px",
                              }}
                            >
                              Delivery Date &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivStatus"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.deliveryStatus
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 width-100 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onBlur={onChangeSaveMethod}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="vochStatusId"
                              value={inputValues.vochStatusId}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[105] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                -Select-
                              </option>
                              {(() => {
                                if (
                                  StatusMasterList != null &&
                                  Array.isArray(StatusMasterList)
                                ) {
                                  return StatusMasterList.map((data) => (
                                    <option key={data.id} value={data.id}>
                                      {data.name}
                                    </option>
                                  ));
                                }
                              })()}
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Status &nbsp; &nbsp; &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark"
                            style={{
                              margin: "1%",
                              // width: "20%",/
                              display:
                                TransInputSet && TransInputSet.remark
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <input
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1  BorderBottomsale"
                              type="text"
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark"
                              value={inputValues.Remark}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[106] = el)}
                              required
                              onBlur={onChangeSaveMethod}
                            />
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark1"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark1
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark1"
                              value={inputValues.Remark1}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[107] = el)}
                              required
                              onBlur={onChangeSaveMethod}
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="1">
                                Special Customer
                              </option>
                              <option Classname="form-control s-1" value="2">
                                Test1
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark1 &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark2"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark2
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark2"
                              value={inputValues.Remark2}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[108] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="0">
                                TEST2
                              </option>
                              <option Classname="form-control s-1" value="1">
                                CAMP CUSTOMER
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark2 &nbsp; &nbsp;
                            </label>
                          </div>
                          <div
                            className="input-containerEffect mt-1 w-20  width100mobile"
                            id="DivRemark3"
                            style={{
                              margin: "1%",
                              // width: "20%",
                              display:
                                TransInputSet && TransInputSet.remark3
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <select
                              className="modify_im_input mode_limit mode_category input-s1 inputEffect BorderBottomsale"
                              type="text"
                              style={{
                                padding: "0px 7px",
                                // backgroundColor: "white",
                              }}
                              onChange={(e) => handleInputChange_Series(e)}
                              name="Remark3"
                              value={inputValues.Remark3}
                              onKeyUp={handleKeyDownMainPage}
                              ref={(el) => (nextInput.current[109] = el)}
                              required
                            >
                              <option Classname="form-control s-1" value="">
                                Select
                              </option>
                              <option Classname="form-control s-1" value="1">
                                CHARITY
                              </option>
                            </select>
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{ fontSize: "12px" }}
                            >
                              Remark3 &nbsp; &nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <MobileBottomButton bottomSliderOpen={bottomSliderOpen} />
            </div>
            {loading && <Loader />}
          </div>
        </div>
      </div>
      {/************************* Party Detail Modal *****************************/}
      {PartyDetailModal && (
        <div className="modal">
          <div className="modal-content2">
            <div className="modal-title">
              <h3 className="modal-h">Invoice Setting</h3>
              <span className="close" onClick={ClosePartyModal}>
                &times;
              </span>
            </div>
            <div className="ModalBoxItem_FullHeight">
              <div className="ModalBoxItem_Left">
                <div className="modal-content-im ">
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.title}
                            name="title"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Title<i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/*{CheckBoxData.map((data, index) => (
                                        <div className="input-box-m1 modal-2" >
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch" key={data.id}>
                                                    <input type="checkbox" onChange={() => handleSliderButton(index)} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">{data.name}</label>
                                            </div>
                                        </div>
                                    ))}*/}
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.dateOfBirth}
                            name="dateOfBirth"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Date Of Birth</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.anniversaryDate}
                            name="anniversaryDate"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Anniversary Date
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.pos}
                            name="pos"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">POS</label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.accBal}
                            name="accBal"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Acc Bal.</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.creditLimit}
                            name="creditLimit"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Credit Limit</label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.referredBy}
                            name="referredBy"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">Referred By</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ModalBoxItem_Right">
                <div className="modal-content-im ">
                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.bookedBy}
                            name="bookedBy"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Booked By
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.godown}
                            name="godown"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Godown
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.deliveryStatus}
                            name="deliveryStatus"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Delivery Status
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.deliveryDate}
                            name="deliveryDate"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Delivery Date
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark}
                            name="remark"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark1}
                            name="remark1"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark1
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark2}
                            name="remark2"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark2
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <div className="input-box-m1 modal-2">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            checked={TransInputSet.remark3}
                            name="remark3"
                            onChange={(e) => handleHideAndShow_InputBox(e)}
                          />
                          <span></span>
                        </label>
                        <label className="ModalLabeltext2">
                          Remark3
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/******************************* Bottom Slider*************************/}
      {MobileSettingSlider && (
        <div className="modal-back">
          <div className="bottomSlider">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Setting</label>
              <label
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={closeBottomSlider}
              >
                x
              </label>
            </div>
            <div className="space-between">
              <label></label>
            </div>
          </div>
        </div>
      )}
      {/******************************* Coupon Modal*************************/}
      {CouponModal && (
        <div className="modal-back">
          <div className="partyDetail-modal">
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>View Coupon</label>
                <label
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={closeCouponModal}
                >
                  x
                </label>
              </div>
              {/* <hr /> */}
              <div style={{ margin: "10px", padding: "10px" }}>
                {/* <div className="couponBox">
                  <div className="couponLeftSide">
                    <label
                      className="rotated-text"
                      style={{ color: "white", fontWeight: "600" }}
                    >
                      20% OFF
                    </label>
                  </div>
                  <div className="" style={{ padding: "10px", width: "100%" }}>
                    <div className="space-between">
                      <label className="couponName">FRAMEFREE</label>
                      <button
                        className="apply-button"
                        style={{ padding: "5px" }}
                      >
                        Apply
                      </button>
                    </div>
                    <div>
                      <label style={{ color: "grey" }}>
                        Save &#x20B9;50 on this order.
                      </label>
                      <br />
                      <label style={{ color: "grey" }}>
                        Use code FRAMEFREE & get flat 20% off on this order.
                      </label>|
                    </div>
                  </div>
                </div> */}

                {CouponModalData &&
                  CouponModalData.map((data) => (
                    <div className="couponBox mt-3">
                      <div className="couponLeftSide">
                        <label
                          className="rotated-text"
                          style={{ color: "white", fontWeight: "600" }}
                        >
                          {data.discountValue != 0 && (
                            <>
                              {data.discountType == "Percentage" ? (
                                <>{data.discountValue} % OFF</>
                              ) : (
                                <>{data.discountValue}</>
                              )}
                            </>
                          )}
                        </label>
                      </div>
                      <div
                        className=""
                        style={{ padding: "10px", width: "100%" }}
                      >
                        <div className="space-between">
                          <label
                            style={{ display: "flex", alignItems: "center" }}
                            className="couponName"
                          >
                            {data.couponName}
                          </label>

                          <button
                            className="apply-button"
                            style={{ padding: "5px" }}
                            onClick={() => ApplyCouppon(data)}
                          >
                            Apply
                          </button>
                        </div>
                        <div>
                          <label style={{ color: "grey" }}>
                            {data.couponMethod}
                          </label>
                          <br />
                          <label style={{ color: "grey" }}>
                            {data.couponTitleOrCode != "" && (
                              <>
                                {data.couponMethodId == 1 ? (
                                  <label htmlFor="">
                                    Use code {data.couponTitleOrCode} & get flat{" "}
                                    {data.discountValue}% off on this order.
                                  </label>
                                ) : (
                                  <label htmlFor="">
                                    Use Title {data.couponTitleOrCode} & get
                                    flat{" "}
                                    {data.discountType == "Percentage" ? (
                                      <>{data.discountValue}%</>
                                    ) : (
                                      <>{data.discountValue}</>
                                    )}
                                    off on this order.
                                  </label>
                                )}
                              </>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <hr className="mt-2" />
              <div className="flex-end">
                <button
                  className="greenBtn"
                  onClick={closeCouponModal}
                  style={{ width: "20%" }}
                >
                  Apply
                </button>
                <button
                  className="redBtn"
                  onClick={closeCouponModal}
                  style={{ width: "20%" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/******************************* Account Master Modal*************************/}
      {AccountMasterModal && (
        <div className="modal-back">
          <div
            className="partyDetail-modal"
            style={{ height: "90%", width: "90%", backgroundColor: "white" }}
          >
            <div style={{ padding: "15px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label></label>
                <label
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={closeAccountMasterModal}
                >
                  x
                </label>
              </div>
              <AccountMaster
                InputValueAccMaster={InputValueAccMaster}
                editData={selectedAccData}
                modelOpen={modalOpen}
                closeAccountMasterModal={closeAccountMasterModal}
                TransType={id}
              />
            </div>
          </div>
        </div>
      )}

      {ItemGroupModal && (
        <div className="modal-back">
          <div
            className="partyDetail-modal"
            style={{ height: "90%", width: "90%", backgroundColor: "white" }}
          >
            <div style={{ padding: "15px", overflowY: "auto", height: "85vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label></label>
                <label
                  style={{ cursor: "pointer", fontSize: "24px" }}
                  onClick={closeGroupModal}
                >
                  x
                </label>
              </div>
              <ItemGroupMaster
                parentGrpName={parentGrpName}
                modelOpen={modalOpen}
                closeGroupModal={closeGroupModal}
              />
            </div>
          </div>
        </div>
      )}
      {loading && <Loader />}
      {batchWise && <BatchWiseModalBox />}

      {/* {(BatchNoData[0]?.expDate != "" &&
        BatchNoData[0]?.batchNo != "" &&
        BatchNoData[0]?.mgfDate != "" &&
        TransactionTypeSetting.enableBatchNoEntry == "2") ||
        (TransactionTypeSetting.enableBatchNoEntry == "1" && (
          <> */}
      {ItemWisebatch && TransactionTypeSetting.enableBatchNoEntry != "0" && (
        <ItemWiseBatchList
          enableBatchNoEntry={TransactionTypeSetting.enableBatchNoEntry}
          itemWiseBatchOpen={itemWiseBatchOpen}
          ProductOpnedInPopup={ProductOpnedInPopup}
          BatchNoData={BatchNoData}
          setBatchNoData={setBatchNoData}
          itemMasterRows={itemMasterRows}
          setItemMasterRows={setItemMasterRows}
          onChangeProductSaveMethod={onChangeProductSaveMethod}
          inputValues={inputValues}
        />
      )}
      {/* </>
        ))} */}
      {PopUpSerial &&
        TransactionTypeSetting.enableSerialNoGeneration != "0" && (
          <PopUpSerialNumber
            enableSerialNoGeneration={
              TransactionTypeSetting.enableSerialNoGeneration
            }
            inputValues={inputValues}
            PopUpSerialOpen={PopUpSerialOpen}
            ProductOpnedInPopup={ProductOpnedInPopup}
            serialNoData={serialNoData}
            setSerialNoData={setSerialNoData}
            itemMasterRows={itemMasterRows}
            setItemMasterRows={setItemMasterRows}
            onChangeProductSaveMethod={onChangeProductSaveMethod}
          />
        )}

      {/******************************* Previous order Modal*************************/}
      <div
        onClick={toggleSlider}
        className={`slider-container ${isSliderOpen ? "open" : ""}`}
      >
        <div onClick={handleModalClick} style={{ padding: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label></label>
            <label
              style={{ cursor: "pointer", fontSize: "24px" }}
              onClick={toggleSlider}
            >
              x
            </label>
          </div>
          <PreviousOrder />
        </div>
      </div>
      {/* ********************************** Print Bill ********************************** */}
      {showBillPrint && (
        <div style={{ display: "none" }}>
          <div className="modal-back" onClick={closeBillPrint}>
            <div className="billPrintModal" style={{}}>
              <div style={{ padding: "5px", backgroundColor: "white" }}>
                <ReportDesignForA4
                  templateName="FinalBillPrint"
                  ord1Id={transId}
                  setShowBillPrint={setShowBillPrint}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {alertBoxForSalePage && (
        <SaleInvoiceAlert
          HeadingTop="Saved"
          Heading="Saved Successfully !!"
          OpenAlertBoxForSalePage={OpenAlertBoxForSalePage}
          PrintBill={PrintBill}
          Reload={Reload}
          onYesClick={DoneClicked}
        />
      )}
    </div>
  );
}

export default SaleInvoice;
