import React, { useContext, useEffect, useState } from "react";
import LogInMaster from "./LogInMaster";
import OtpVerify from "./OtpVerify";
import CreateBusiness from "./CreateBusiness";
import Company from "../../API/APIServices/Company";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../Props/Loader";
import AlertBox from "../Props/AlertBox";
import MyContext from "../MyContext";
import ModalBoxMaster from "../Props/ModalBoxMaster";

const Login = () => {
  const handleKeyDown = async (event) => {
    if (event.altKey && event.key === "c") {
      debugger;
      console.log("alt + c");
      await toggleSoftwareConfigOpen();
    }
  };
  useEffect(() => {
    console.log("attach");
    document.addEventListener("keydown", handleKeyDown);
  }, []);

  const [isSoftwareConfigOpen, setIsSoftwareConfigOpen] = useState(false);

  function toggleSoftwareConfigOpen() {
    console.log("call");
    setIsSoftwareConfigOpen(!isSoftwareConfigOpen);
    return () => {
      console.log("remove call");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }

  const navigate = useNavigate();
  const [loginMaster, setLoginMaster] = useState(true);
  const [emailId, setEmailId] = useState();
  const [otpVerify, setOtpVerify] = useState(false);
  const [createbusiness, setCreatebusiness] = useState(false);

  const { OpenAlertBoxEdit, alertBoxEdit, fetchCompanyData } = useContext(MyContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchIsClientregistered = async () => {
      var data = await Company.IsClientregistered();
      if (data === true) {
        setOtpVerify(false);
        setCreatebusiness(true);
        setLoginMaster(false);
      } else {
        setOtpVerify(false);
        setCreatebusiness(false);
        setLoginMaster(true);
      }
    };
    fetchIsClientregistered();
  }, []);


  const gototop = () => {
    setLoginMaster(false);
    setOtpVerify(true);
    setCreatebusiness(false);
  };

  // const gotologinpanel = async () => {
  //   setOtpVerify(false);
  //   setLoginMaster(false);
  //   Navigate("/LoginPanel")
  // };

  const [inputValues, setInputValues] = useState({
    name: "",
    businessTypes: "",
    countryId: 1101,
    stateId: 8,
    mobileNo: "",
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const CreateCompany = async () => {
    setLoading(true);
    try {
      const response = await Company.CreateCompany(inputValues);
      if (response === true) {
        setLoading(false);
        OpenAlertBoxEdit();
        navigate("/LogInPanel");
        window.location.reload();
      } else {
        alert("Company Creation Fail");
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  return (
    <div>
      {loginMaster && (
        <LogInMaster emailId={emailId} setLoading={setLoading} setEmailId={setEmailId} gototop={gototop} handleInputChange={handleInputChange} />
      )}
      {otpVerify && (
        <OtpVerify
          emailId={emailId}
          // gotologinpanel={gotologinpanel}
          handleInputChange={handleInputChange}
        />
      )}
      {createbusiness && (
        <CreateBusiness
          handleInputChange={handleInputChange}
          CreateCompany={CreateCompany}
        />
      )}
      {loading && <Loader />}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Welcome"
          Heading="Welcome to CRM page"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
      <ModalBoxMaster
        isSoftwareConfigOpen={isSoftwareConfigOpen}
        toggleSoftwareConfigOpen={toggleSoftwareConfigOpen}
      />
    </div>
  );
};

export default Login;
