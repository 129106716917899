import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Page4.css";
import ShopBanner from "../../API/APIServices/Shopbanner";
import FloatingLabelInput from "./InputBox";
import FloatingDropdown from "./Dropdown";
import MyContext from "../../Inventory/MyContext";
import ItemGroup from "../../API/APIServices/ItemGroup";


function MainShopBanner() {
  const [imgurl, setimgurl] = useState("");
  // const [changeForlabel, setchangeForlabel] = useState(null);
  // const [changeblue, setchangeblue] = useState("");
  const [apiData, setApiData] = useState([]) /// get alla shop banner data usestate
  const [apiBannerData, setApiBannerData] = useState([]) /// get alla shop banner data usestate
  const {
    imageBaseUrl
  } = useContext(MyContext);
  const [selectedId, setselectedId] = useState(''); //selected id of the image banner swagger field:-imageBannerNameId
  // const [imageBannerName, setImageBannerName] = useState('');
  const [bannerCaption, setBannerCaption] = useState('');
  const [bannerPriority, setBannerPriority] = useState('');
  const [redirectTo, setRedirectTo] = useState('');
  const [redirectToURL, setRedirectToURL] = useState('');
  const [categoryGroupName, setCategoryGroupName] = useState('');
  const [showInCategoryGroup, setShowInCategoryGroup] = useState('');
  const [showImage, setShowImage] = useState()
  const [imageResponse, setImageResponse] = useState()
  const [isFocused, setIsFocused] = useState(false);
  const [parentName, setParentName] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);


  console.log(selectedId)
  console.log(redirectTo)
  console.log(imageBaseUrl)
  console.log(apiBannerData)
  console.log(showInCategoryGroup)

  const [inputValues, setInputValues] = useState({
    HeaderDesign: '',
    BannerDesign: '',
    IndexPageDesign: '',
    ProductListDesign: '',
    BuyPageDesign: '',
    PatternColor: ''
  });


  console.log("HeaderDesign", inputValues.HeaderDesign)
  console.log("bannerDesign", inputValues.BannerDesign)
  console.log("indexpage", inputValues.IndexPageDesign)
  console.log("product", inputValues.ProductListDesign)
  console.log("buypage", inputValues.BuyPageDesign)
  console.log("pattern", inputValues.PatternColor)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value
    });
  };

  const handleEditClick = (id) => {
    fetchdata(id); // Fetch data for editing item (assuming fetchdata updates some state for editing form fields)
    setEditingItemId(id); // Set the id of the item being edited
  };


  const validateData = (data) => {
    for (const key in data) {
      if (data[key] === null || data[key] === undefined || data[key] === '') {
        alert(`The field ${key} is required and cannot be empty.`);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    const updatedata = {
      id: id,
      imageBannerNameId: selectedId,
      bannerCaption,
      bannerPriority,
      redirectTo,
      redirectToURL,
      categoryGroupName,
      showInCategoryGroup
    }//old struct
    const data = {
      imageBannerNameId: selectedId,
      bannerCaption,
      bannerPriority,
      redirectTo,
      redirectToURL,
      categoryGroupName,
      showInCategoryGroup
    }//new struct

    const valData = {
      imageBannerNameId: selectedId,
      bannerCaption,
      bannerPriority,
    }

    try {
      if (id) {
        // Update operation if id (editingItemId) is provided
        const response = await ShopBanner.UpdateShopBanner(id, updatedata);
        // Handle image update if necessary
        if (response.isSuccess && imgurl) {
          const formData = new FormData();
          formData.append(`ShopBannerId`, id);
          formData.append(`files`, imgurl);
          const imageResponse = await ShopBanner.UpdateShopBannerImage(formData);
          if (imageResponse.isSuccess) {
            console.log("Banner Successfully Updated with Image");
            alert("Banner Successfully Updated with Image");
            handleReset(e)
          }
          fetchAllBannerdata()
        } else if (response.isSuccess) {
          alert("Banner Updated Successfully");
          handleReset(e);
          fetchAllBannerdata();
        }
      } else {
        if (validateData(valData)) {
          const response = await ShopBanner.CreateShopBanner(data)
          console.log("Shop Banner Res", response)
          if (response.isSuccess) {
            // fetchAlldata()
            const formData = new FormData();
            formData.append(`ShopBannerId`, response.result.id);
            formData.append(`files`, imgurl);

            const imageresponse = await ShopBanner.UpdateShopBannerImage(formData)
            if (imageresponse.isSuccess) {
              console.log("Banner Successfuly Added with Image")
              alert("Banner Successfuly Added with Image")
              handleReset(e);
            }
            fetchAllBannerdata();
          }
        }
      }
    } catch (error) {
      console.error("Failed", error)
    }
  }

  const fetchAlldata = async () => {
    try {
      // const response = await ShopBanner.GetShopBanners()
      const response = await ShopBanner.GetAllImageBannerName()
      if (response) {
        console.log("Response", response);
        setApiData(response)
      }
    } catch (error) {
      // throw new error("Unable To fetch Data")
      alert(error)
    }
  }
  const fetchAllBannerdata = async () => {
    try {
      const response = await ShopBanner.GetShopBanners()
      // const response = await ShopBanner.GetAllImageBannerName()
      if (response) {
        console.log("Response", response);
        setApiBannerData(response)

      }
    } catch (error) {
      alert(error)
      throw new error("Unable To fetch Data")
    }
  }


  // const GetImageDetails = async () => {

  // }

  useEffect(() => {
    fetchAlldata()
    fetchAllBannerdata()
  }, [])


  // console.log("Api data here", apiData)



  const acceptedFileTypes = ".jpg, .jpeg, .png, .webp";


  // const handleFile = (file) => {
  //   // Example: Displaying the selected image
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
  //     const imageDataUrl = event.target.result;
  //     console.log('Selected image:', imageDataUrl);

  //     // Example: Display the image in your component
  //     // Update state, pass to another component, etc.
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleChange = function (e) {
    const src = e.target.files[0];
    const Image = URL.createObjectURL(e.target.files[0])
    setShowImage(Image)
    if (src) {
      const fileType = src.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/webp') {
        setimgurl(src);
      } else {
        alert('Please upload a JPG, PNG, or WebP file.');
      }
    }
  };

  //**********Get by id************ */
  const fetchdata = async (Id) => {
    // setselectedId(imageBannerNameId)
    debugger
    console.log(Id)
    try {
      const response = await ShopBanner.GetShopBannerById(Id)
      if (response.isSuccess) {
        console.log("Fetch by id resposnse", response);
        // setImageBannerName(response.result.imageBannerName)
        setselectedId(response.result.imageBannerNameId)
        setBannerCaption(response.result.bannerCaption)
        setBannerPriority(response.result.bannerPriority)
        setRedirectTo(response.result.redirectTo)
        setRedirectToURL(response.result.redirectToURL)
        setCategoryGroupName(response.result.categoryGroupName)
        setShowInCategoryGroup(response.result.showInCategoryGroup)
        setImageResponse(response.result.bannerImageId)
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      throw new error("Unable To fetch Data")
    }
  }
  // useEffect(() => {
  //   fetchdata(selectedId)
  // }, [selectedId])

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this item?");

    if (!confirmDelete) {
      return; // If user cancels deletion, do nothing
    }
    console.log(id)
    try {
      const response = await ShopBanner.DeleteShopBannerById(id)
      if (response.isSuccess) {
        alert("Deleted Successfully")
        fetchAllBannerdata()
      }
    } catch (error) {
      console.error("Error While Deleting")
    }
  }

  const handleReset = (e) => {
    e.preventDefault()
    setBannerCaption('')
    setBannerPriority('')
    setRedirectTo('')
    setRedirectToURL('')
    setCategoryGroupName('')
    setShowInCategoryGroup('')
    setimgurl('')
    setShowImage('')
    setImageResponse('')
    setEditingItemId(null)
    setselectedId('')
  };

  // const [isFocused, setIsFocused] = useState(false);

  // console.log("selected Value", selectedId)
  async function GetImageDetails(BranchId, ShopBannerId) {
    try {
      const response = await ShopBanner.GetShopBannerImageDetail(BranchId, ShopBannerId)
      console.log(response.result[0].id)
      // setImageId(response.result[0].id)
      if (response.isSuccess) {
        // await GetImage(response.result[0].id)
        setImageResponse(response.result[0].id)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    GetImageDetails(1, selectedId) //call with  "bannerImageId": null, from api/v1/ShopBanner/GetShopBanners for table
  }, [selectedId])

  const handleWebDesign = async (e) => {
    e.preventDefault()
    const data = {
      HeaderDesign: inputValues.HeaderDesign,
      BannerDesign: inputValues.BannerDesign,
      IndexPageDesign: inputValues.IndexPageDesign,
      ProductListDesign: inputValues.ProductListDesign,
      BuyPageDesign: inputValues.BuyPageDesign,
      PatternColor: inputValues.PatternColor
    }
    console.log(data)
    try {
      const response = await ShopBanner.CreateWebsiteDesignPage(data)
      console.log(response)
      if (response.isSuccess) {
        setInputValues({
          HeaderDesign: '',
          BannerDesign: '',
          IndexPageDesign: '',
          ProductListDesign: '',
          BuyPageDesign: '',
          PatternColor: ''
        })
      }
    } catch (error) {
      console.error("Unable To Create", error)
    }
  }

  useEffect(() => {
    const fetchdata4 = async () => {
      debugger
      try {
        const val = await ItemGroup.GetItemGroup()
        setParentName(val);
        console.log(val);
      } catch (error) {
        console.error(error)
      }
    }
    fetchdata4();
  }, []);

  const [focusedDropdown, setFocusedDropdown] = useState(null);

  const handleFocus = (dropdownId) => {
    setFocusedDropdown(dropdownId);
  };

  const handleBlur = () => {
    setFocusedDropdown(null);
  };



  return (
    <>
      {/* main-div */}
      <h1 className={`h2-shopbanner`}>SHOP BANNER</h1>

      <form className="main-div">
        <div className="left-content-container">
          <div className="left-side">
            <div className="add-new-image-banner">
              <FloatingDropdown
                label="Image/Logo Banner"
                options={apiData}
                value={selectedId}
                Onchange={(e) => setselectedId(e.target.value)}
              />
              <Link to={"/shopbanner"}>
                <i class="fa-solid fa-square-plus" style={{ fontSize: 20, marginTop: 20, marginLeft: 13, color: '#B197FC' }}></i>
              </Link>
            </div>
            {/* <FloatingLabelInput
              label="Image Banner Name"
              name="imageBannerName"
              value={imageBannerName}
              onChange={(e) => setImageBannerName(e.target.value)}
            /> */}
            <FloatingLabelInput
              label="Banner Caption"
              name="bannerCaption"
              value={bannerCaption}
              onChange={(e) => setBannerCaption(e.target.value)}
            />
            <FloatingLabelInput
              label="Banner Priority"
              name="bannerPriority"
              value={bannerPriority}
              onChange={(e) => setBannerPriority(e.target.value)}
            />
            {/* <FloatingLabelInput
            label="Redirect To"
            name="redirectTo"
            value={redirectTo}
            onChange={(e) => setRedirectTo(Number(e.target.value))}
          /> */}

            <div className={`floating-dropdown ${isFocused ? 'focused' : ''}`}>
              <select
                value={redirectTo}
                onChange={(e) => setRedirectTo(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={isFocused ? 'focused' : ''}
              >
                <option value="">Select Category To Redirect To</option>
                {parentName.map((data) => (
                  <React.Fragment key={data.d2_Id}>
                    {data.parentGrp === 0 && (
                      <option value={data.d2_Id}>
                        {data.printName}
                      </option>
                    )}

                    {data.parentGrpName &&
                      data.parentGrp !== 0 && (
                        <option value={`${data.d2_Id}`}>
                          {`${data.parentGrpName} > ${data.name}`}
                        </option>
                      )}
                  </React.Fragment>
                ))}
              </select>
              <label>Redirect To</label>
            </div>


            <FloatingLabelInput
              label="Redirect To URL"
              name="redirectToURL"
              value={redirectToURL}
              onChange={(e) => setRedirectToURL(e.target.value)}
            />
            <FloatingLabelInput
              label="Category Group Name"
              name="categoryGroupName"
              value={categoryGroupName}
              onChange={(e) => setCategoryGroupName(e.target.value)}
            />
            {/* <FloatingLabelInput
              label="Show In Category Group"
              name="showInCategoryGroup"
              value={showInCategoryGroup}
              onChange={(e) => setShowInCategoryGroup(e.target.value)}
            /> */}

            <div className={`floating-dropdown ${isFocused ? 'focused' : ''}`}>
              <select
                value={showInCategoryGroup}
                onChange={(e) => setShowInCategoryGroup(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className={isFocused ? 'focused' : ''}
              >
                <option value="">Select Category To Show In Group</option>
                {parentName.map((data) => (
                  <React.Fragment key={data.d2_Id}>
                    {data.parentGrp === 0 && (
                      <option value={data.d2_Id}>
                        {data.printName}
                      </option>
                    )}

                    {data.parentGrpName &&
                      data.parentGrp !== 0 && (
                        <option value={`${data.d2_Id}`}>
                          {`${data.parentGrpName} > ${data.name}`}
                        </option>
                      )}
                  </React.Fragment>
                ))}
              </select>
              <label>Show In Category Group</label>
            </div>
            {/* Banner image */}
            <section className="left-side-img-section">
              <label className="left-side-img-section-name">BANNER IMAGE</label>
              <img
                // src={'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'}
                src={showImage || `${imageBaseUrl}${imageResponse}`}
                className="left-side-img-section-img"
                alt="Not loaded"
              />

              <label class="picture" for="picture__input" tabindex="0" style={{ overflow: "visible" }}>
                <span class="picture__image">
                </span>
                <div class="upload-button">
                  <i class="fa-solid fa-plus" style={{ fontSize: 18 }}>
                  </i>
                </div>
              </label>
              <input
                id="picture__input"
                type="file"
                className="left-side-img-upload"
                accept={acceptedFileTypes}
                onChange={(e) => handleChange(e)}
              />
            </section>
            {/* button */}
            <section className="left-side-section-btn">
              <button type="button" className="save-button-main" onClick={(e) => handleSubmit(e, editingItemId)}>{editingItemId ? 'Update' : 'Save'}</button>
              <button className="save-button-main" onClick={(e) => handleReset(e)}>Reset</button>
              {/* <button className="save-button-main" onClick={(e) => handleReset(e)}>Create Header & Footer Menu</button> */}
              {/* <button className="section-btn">Create Menu & Footer</button> */}
            </section>
          </div>
          <div className="left-side">
            <div className="table-container-tbn">
              <div className="table-div">
                <table className="tableList-main">
                  <thead className="table-head-main">
                    <tr>
                      <th>Sno</th>
                      <th>Name</th>
                      <th>Priority</th>
                      <th>Image</th>
                      <th>Redirect URL</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-body-main">
                    {apiBannerData.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.imageBannerName}</td>
                        <td>{item.bannerPriority}</td>
                        <td><img src={`${imageBaseUrl}${item.bannerImageId}`} alt={item.bannerCaption} height={50} width={150} /></td>
                        <td>{item.redirectToURL}</td>
                        <td>
                          <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => handleEditClick(item.id)} />
                          <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => handleDelete(item.id)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>



        {/* Website design pages */}
        <div className="right-side">
          <section className="right-side-box1">
            <p className="right-side-box1-name">WebSite Design Pages :</p>

            {Object.keys(inputValues).map((key) => (
              <FloatingLabelInput
                key={key}
                label={key}
                name={key}
                value={inputValues[key]}
                onChange={handleInputChange}
              />
            ))}


            {/* save design btn */}
            <div style={{ marginBottom: 12, marginTop: 12 }}>
              <button className="save-button-main " onClick={(e) => handleWebDesign(e)}>Save Design</button>
            </div>
          </section>

          {/* box second */}
          <section className="right-side-box1">
            {/* header and menu */}
            {/* <section className="right-side-select-section"> */}
            {/* <section className="right-side-inside-section">
                <label className="right-side-box2-label">
                  Choose Header and Menu
                </label>
                <select className="right-side-select">
                  <option>select option</option>
                </select>
              </section> */}
            {/* <FloatingDropdown label={" Choose Header and Menu"} options={staticdata} /> */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "header" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("header")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "header" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Header Design</label>
              </div>
              <button className="save-button-main ">Set Header</button>
            </div>
            {/* </section> */}

            {/* choose banner page*/}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "banner" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("banner")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "banner" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Choose Banner</label>
              </div>
              <button className="save-button-main ">Set Banner</button>
            </div>

            {/* choose index page */}
            <div className="dropdown-outer-class">
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "indexPage" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("indexPage")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "indexPage" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Choose Index Page</label>
                </div>
                <button className="save-button-main ">Set Index Page</button>
              </div>
              <div style={{ textAlign: "center", marginBottom: 15 }}>
                <button className="save-button-main ">View</button>
              </div>
            </div>

            {/* Product list page */}
            <div className="dropdown-outer-class">
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Product List Page" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Product List Page")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Product List Page" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Product List Page</label>
                </div>
                <button className="save-button-main ">Set Product List</button>
              </div>
              <div style={{ textAlign: "center", marginBottom: 15 }}>
                <button className="save-button-main ">View</button>
              </div>
            </div>

            {/* choose buy page */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "Choose Buy Page" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("Choose Buy Page")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "Choose Buy Page" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Choose Buy Page</label>
              </div>
              <button className="save-button-main ">Set Buy Page</button>
            </div>

            {/* pattern design */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "Choose Pattern Design" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("Choose Pattern Design")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "Choose Pattern Design" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Choose Pattern Design</label>
              </div>
              <button className="save-button-main ">Set Pattern</button>
            </div>

            {/* Prescription design */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "Lens Prescription Design" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("Lens Prescription Design")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "Lens Prescription Design" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Lens Prescription Design</label>
              </div>
              <button className="save-button-main ">Set Pattern</button>
            </div>

            {/* login page design */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "Login Page Design" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("Login Page Design")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "Login Page Design" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Login Page Design</label>
              </div>
              <button className="save-button-main">Set Pattern</button>
            </div>

            {/* product page design */}
            <div className="main-dropdown-container-shop">
              <div className={`floating-dropdown ${focusedDropdown === "Product Page Design" ? "focused" : ""}`}>
                <select
                  // value={value}
                  // onChange={Onchange}
                  onFocus={() => handleFocus("Product Page Design")}
                  onBlur={handleBlur}
                  className={focusedDropdown === "Product Page Design" ? "focused" : ""}
                >
                  <option value="" >Select</option>
                  {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                </select>
                <label>Product Page Design</label>
              </div>
              <button className="save-button-main ">Set Pattern</button>
            </div>

            {/* upload template file */}
            <div className="right-side-upload">
              <label htmlFor="templateFile" className="right-side-label">
                Upload Template File
              </label>
              <div className="right-side-file-container">
                <input id="templateFile" className="right-side-file-input" type="file" />
                <button className="upload-btn">Upload</button>
              </div>
              {/* <p className="span">File uploaded!</p> */}
            </div>

            {/* download button */}
            <div className="right-side-section-download">
              <p className="right-side-download-info">Download Shop Images</p>
              <button className="download">Download</button>
            </div>
          </section>

          {/* mobile site design pages */}
          <section className="right-side-third-box">
            {/* <div className="right-side-third-box-first"> */}
            <p className="right-side-box1-name mobile-name">
              Mobile Site Design Pages:
            </p>
            <div className="Mob-site">
              <FloatingLabelInput label={"Header Design"} name={"Header Design"} onChange={""} value={""} />
              <FloatingLabelInput label={"Banner Design"} name={"Header Design"} onChange={""} value={""} />
              <FloatingLabelInput label={"Index Page"} name={"Header Design"} onChange={""} value={""} />
              <FloatingLabelInput label={"Product List Design"} name={"Header Design"} onChange={""} value={""} />
              <FloatingLabelInput label={"Pattern Color"} name={"Header Design"} onChange={""} value={""} />
              {/* button */}
              <div className="rs-save-div">
                <button className="save-button-main">Save Design</button>
              </div>
              {/* </div> */}
            </div>

            {/* third-box-second-box */}
            <div className="rs-third-box-second">
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Choose Header Page" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Choose Header Page")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Choose Header Page" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Choose Header Page</label>
                </div>
                <button className="save-button-main ">Set Pattern</button>
              </div>

              {/* Banner page */}
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Banner page" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Banner page")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Banner page" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Choose Banner page</label>
                </div>
                <button className="save-button-main ">Set Pattern</button>
              </div>

              {/* choose index page */}
              <div className="dropdown-outer-class">
                <div className="main-dropdown-container-shop">
                  <div className={`floating-dropdown ${focusedDropdown === "indexPage" ? "focused" : ""}`}>
                    <select
                      // value={value}
                      // onChange={Onchange}
                      onFocus={() => handleFocus("indexPage")}
                      onBlur={handleBlur}
                      className={focusedDropdown === "indexPage" ? "focused" : ""}
                    >
                      <option value="" >Select</option>
                      {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                    </select>
                    <label>Choose Index Page</label>
                  </div>
                  <button className="save-button-main ">Set Index Page</button>
                </div>
                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <button className="save-button-main ">View</button>
                </div>
              </div>

              {/* Product list page */}
              <div className="dropdown-outer-class">
                <div className="main-dropdown-container-shop">
                  <div className={`floating-dropdown ${focusedDropdown === "Product List Page" ? "focused" : ""}`}>
                    <select
                      // value={value}
                      // onChange={Onchange}
                      onFocus={() => handleFocus("Product List Page")}
                      onBlur={handleBlur}
                      className={focusedDropdown === "Product List Page" ? "focused" : ""}
                    >
                      <option value="" >Select</option>
                      {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                    </select>
                    <label>Product List Page</label>
                  </div>
                  <button className="save-button-main ">Set Product List</button>
                </div>
                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <button className="save-button-main ">View</button>
                </div>
              </div>

              {/* Prescription design */}
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Lens Prescription Design" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Lens Prescription Design")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Lens Prescription Design" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Lens Prescription Design</label>
                </div>
                <button className="save-button-main ">Set Pattern</button>
              </div>


              {/* pattern design */}
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Choose Pattern Design" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Choose Pattern Design")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Choose Pattern Design" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Choose Pattern Design</label>
                </div>
                <button className="save-button-main ">Set Pattern</button>
              </div>



              {/* login page design */}
              <div className="main-dropdown-container-shop">
                <div className={`floating-dropdown ${focusedDropdown === "Login Page Design" ? "focused" : ""}`}>
                  <select
                    // value={value}
                    // onChange={Onchange}
                    onFocus={() => handleFocus("Login Page Design")}
                    onBlur={handleBlur}
                    className={focusedDropdown === "Login Page Design" ? "focused" : ""}
                  >
                    <option value="" >Select</option>
                    {/* {options.map((item, index) => (
                     <option key={index} value={item.id}>
                       {item.imageBannerName}
                     </option>
                   ))} */}
                  </select>
                  <label>Login Page Design</label>
                </div>
                <button className="save-button-main">Set Pattern</button>
              </div>
            </div>
          </section>
        </div>
      </form>

    </>
  );
}

export default MainShopBanner;
