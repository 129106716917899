import { useContext, useEffect, useState } from "react";
import "./POSInvoice.css";
import KotHomeDelivery from './KotHomeDelivery'
import KotPickUp from './KotPickup'
import OrderApi from "../../API/APIServices/Order";
import TableApi from "../../API/APIServices/Table";
import TableGroupApi from "../../API/APIServices/TableGroup";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Table from "../../API/APIServices/Table";
import Loader from "../../Inventory/Props/Loader";
import Company from "../../API/APIServices/Company";
import HomeDelivery from "./HomeDelivery";
import UserCredential from "../../API/APIServices/UserCredential";
import Order from "../../API/APIServices/Order";
import OrderTakerBillPrint from "../OrderTaker/OrderTakerBillPrint";
import KotPrintPage from "../KotManager/KotPrintPage";
import ItemDetailModalBox from "./ItemDetailModalBox";
import OverallAndKotPrint from "../OrderTaker/OverallAndKotPrint"
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ReportDesign from "./ReportDesign";
import MyContext from "../../Inventory/MyContext";

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const HomeDeliveryPickupList = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useState(() => {
        document.title = 'Delivery Pickup List';
    }, []);

    const { convertDateTimeToDateAndTime } = useContext(MyContext);

    // ***************** Get Order List *******************
    const [orderList, setOrderList] = useState([]);

    const fetchDeliveryPickupData = async () => {
        try {
            setLoading(true)
            const list = await OrderApi.getDeliveryPickupList();
            const data = list.slice().reverse();
            console.log(data);
            setOrderList(data);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchDeliveryPickupData();
    }, [])

    function convertTo12HourFormat(twentyFourHourTime) {
        if (!twentyFourHourTime) {
            return "";
        }

        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes)) {
            return "";
        }

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }

        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }

        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }

    function convertTo12HourFormat(twentyFourHourTime) {
        const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

        let period = 'AM';
        let twelveHourHours = hours;

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                twelveHourHours -= 12;
            }
        }
        if (twelveHourHours === 0) {
            twelveHourHours = 12;
        }
        if (minutes != undefined && minutes != null) {
            const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
            return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
        }
        else {
            return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
        }
    }
    const timeFormat = (twentyFourHourTime) => {
        const [startTime, endTime] = twentyFourHourTime.split('-');
        const convertedStartTime = convertTo12HourFormat(startTime.trim());
        const convertedEndTime = convertTo12HourFormat(endTime.trim());
        const convertedTimeRange = `${convertedStartTime} - ${convertedEndTime}`;
        return convertedTimeRange;
    }

    const [kotManager, setKotManager] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(false);
                const filteredData = [{
                    fieldName: "userRole",
                    operatorName: "Equal",
                    compareValue: 565  //KOT Manager
                }];
                const data = await UserCredential.getFilteredUserCredential(filteredData)
                setKotManager(data);
                setLoading(false);
            }
            catch (error) {
                console.log(error);
                setLoading(false)
            }
        }
        fetchData();
    }, [])

    const handleAssignKotManager = async (e, ord1Id, index) => {
        setLoading(true)
        const updatedData = [{
            operationType: 0,
            path: "KOTManagerId",
            op: "Add",
            from: "string",
            value: e.target.value
        }]
        await Order.updateOrderPartially(updatedData, ord1Id);

        setOrderList(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], "kotManagerId": e.target.value };
            return updatedData;
        });
        setLoading(false);
    }

    const handleDeliveredBtn = async (ord1Id) => {
        setLoading(true);

        const itemWiseData = await Order.getOrderItemWiseList(ord1Id)

        const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
        // const formattedDate = new Date(isoString);

        // ******************* Order 3 ***********************
        for (const element of itemWiseData) {
            try {
                const statusData = {
                    ord1Id: ord1Id,
                    ord2Id: element.ord2Id,
                    orderStatus: "Delivered",
                    orderDateTime: formattedDate,
                };
                await OrderApi.insertItemStatus(statusData);

                // ************************ Order 2 *********************
                const data = [
                    {
                        operationType: 0,
                        path: "KotStatus",
                        op: "Add",
                        from: "string",
                        value: "Delivered"
                    }
                ];
                await OrderApi.updateItemPartially(data, element.ord2Id);
            } catch (error) {
                console.error('Error processing element:', element, error);
            }
        }
        fetchDeliveryPickupData();
        setLoading(false);
        alert("Updated");
    }

    const [showPrintModal, setShowPrintModal] = useState(false);

    const closePrintModal = () => {
        setShowPrintModal(false);
        setLoading(false);
    }

    const [orderListData, setOrderListData] = useState([]);

    const handlePrintBtn = async (ord1Id, index) => {
        try {
            setLoading(true)
            try {
                const data = await OrderApi.getOrderListByOrd1Id(ord1Id);
                console.log("Print", data.item1)
                setOrderListData(data.item1);
                setShowPrintModal(true);
            }
            catch (error) {
                console.log(error)
            }
            try {
                const filteredData = [{
                    operationType: 0,
                    path: "orderPrintStatus",
                    op: "Add",
                    from: "string",
                    value: true
                }]
                await OrderApi.updateOrderPartially(filteredData, ord1Id)
            }
            catch (error) {
                console.log(error);
            }
            setOrderList(prevData => {
                const updatedData = [...prevData];
                updatedData[index] = { ...updatedData[index], "printStatus": true };
                return updatedData;
            });
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const [firmName, setFirmName] = useState();
    const [userName, setUserName] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const arrayString = localStorage.getItem('loginUser');
            const arrayToStore = JSON.parse(arrayString);
            setUserName(arrayToStore.userName);
            const data = await Company.GetCompanyById(arrayToStore.compId);
            setFirmName(data.name);
        };
        fetchData();
    }, []);

    // ***********************************************************

    const [ItemDetailModal, setItemDetailModal] = useState(false);

    const [ItemDetail, setItemDetail] = useState({});

    const openItemDetailModal = async (id) => {
        await handleGetItem(id);
        setItemDetailModal(true);
    };

    const handleGetItem = async (id) => {
        var filterData = {
            fieldName: "ord1Id",
            operatorName: "equal",
            compareValue: id,
        };
        const orderlistdata = await Order.getOrderItemWiseList(id);
        setItemDetail(orderlistdata);
    };

    const closeItemDetailModal = () => {
        setItemDetailModal(false);
    };

    const handlerefreshBtn = () => {
        fetchDeliveryPickupData();
    }

    return (
        <div>
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">DELIVERY PICKUP LIST</h2>
                </div>
                <div className="button-m5" style={{ marginLeft: "58%" }}>
                    <button onClick={handlerefreshBtn} className="save-button-main"
                    >Refresh
                        <i id="" className="fa-solid fa-rotate im-icon-2" ></i>
                    </button>
                </div>
                <div className="loggedInDataDiv" >
                    <label className="loggedInDataLabel">Hi,{userName}</label>
                    <label className="loggedInDataLabel">{firmName}</label>
                </div>
            </div>
            <div className="scroll-container" style={{ height: "99vh", padding: "10px" }}>
                <div className="mt-2">
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                            <label className="fw-600 mt-1" style={{ fontSize: "18px", width: "100%", textDecoration: "underline" }}>HOME DELIVERY PICKUP LIST</label>
                        </div>
                    </div> */}
                    {/**********************************/}
                    <div style={{ display: "block", padding: "0px", marginRight: "15px" }}>
                        <table className="tableStyle mt-4">
                            <thead>
                                <tr>
                                    <th className="thStyle">SN.</th>
                                    <th className="thStyle">Order No.</th>
                                    <th className="thStyle">Service Type</th>
                                    <th className="thStyle">Order Date</th>
                                    <th className="thStyle">KOT Ready Time</th>
                                    <th className="thStyle">Delivery Time Slot</th>
                                    <th className="thStyle">Delivery Date</th>
                                    <th className="thStyle">KOT Manager Name</th>
                                    <th className="thStyle">KOT Print</th>
                                    <th className="thStyle">KOT Delivery</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    if (orderList !== null && Array.isArray(orderList)) {
                                        return orderList
                                            .map((data, index) => {
                                                return (
                                                    <tr className="tableHover" style={{ marginTop: "0px", cursor: "pointer" }}>
                                                        <td className="tdStyle" style={{ textAlign: "center", }}>
                                                            <button style={{ padding: "5px 15px" }} onClick={() => openItemDetailModal(data.ord1Id)}>{index + 1}</button></td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{data.orderNo}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{data.serviceTypeName}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{moment(data.orderDate).format('DD-MM-YYYY')}</td>
                                                        {/* <td style={{ textAlign: "center", }} className="tdStyle">{moment.utc(data.kotReadyTime).utcOffset("+05:30").format('DD-MM-YYYY hh:mm A')}</td>moment(data.kotReadyTime).format('DD-MM-YYYY hh:mm A') */}
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{convertDateTimeToDateAndTime(data.kotReadyTime)}</td>{/*moment(data.kotReadyTime).format('DD-MM-YYYY hh:mm A')*/}
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{data.timeSlotName}</td>{/*timeFormat(data.deliveryTimeSlot)*/}
                                                        <td style={{ textAlign: "center", }} className="tdStyle">{moment(data.deliveryDate).format('DD-MM-YYYY')}</td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">
                                                            <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "100%" }} >
                                                                <select className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy"
                                                                    style={{ fontSize: "14px" }} name="kotManager" value={data.kotManagerId}
                                                                    onChange={(e) => handleAssignKotManager(e, data.ord1Id, index)} required>
                                                                    <option value="default">-- Select --</option>
                                                                    {(() => {
                                                                        if (kotManager !== null && Array.isArray(kotManager)) {
                                                                            return kotManager.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.userName}
                                                                                </option>
                                                                            ));
                                                                        }
                                                                    })()}
                                                                </select>
                                                            </div>
                                                        </td>
                                                        <td className="tdStyle" style={{}}>
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <button className="pos_printBtn" disabled={data.kotManagerId == null ? true : false} onClick={() => handlePrintBtn(data.ord1Id, index)}>Print</button>
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: "center", }} className="tdStyle">
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <button className="pos_deliveredBtn" disabled={data.printStatus == false ? true : false} onClick={() => handleDeliveredBtn(data.ord1Id)}>Delivered</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            });
                                    }
                                })()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/************************** Item Detail Modal ***************/}
            {
                ItemDetailModal && (
                    <ItemDetailModalBox
                        closeItemDetailModal={closeItemDetailModal}
                        ItemDetail={ItemDetail}
                        setItemDetail={setItemDetail}
                        serviceTypeId={ItemDetail[0].serviceTypeId}
                    />
                )
            }
            {/******************************* Print Modal *************************/}
            {showPrintModal && (
                <div style={{ display: "none" }}>
                    <div className="modal-back" onClick={closePrintModal}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                {/* <ReportDesign templateName="FinalBillPrint" ord1Id={orderListData && orderListData[0].ord1Id} setShowBillPrint={setShowPrintModal} />
                                <ReportDesign templateName="KOTBillPrint" orderData={orderListData} ord1Id={0} setShowBillPrint={setShowPrintModal} /> */}
                                <ReportDesign templateName="KOTBillPrint" orderData={orderListData} ord1Id={orderListData[0]?.ord1Id} isBillAndKot={true} 
                                setShowBillPrint={setShowPrintModal} setLoading={setLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {loading && <Loader />}
        </div>
    );
};

export default HomeDeliveryPickupList;
