import React, { useEffect, useState } from 'react'
import WebTemplate from '../../API/APIServices/WebTemplate';
import { useParams } from 'react-router-dom';
import TemplateSetting from '../../API/APIServices/TemplateSetting';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import Loader from "../../Inventory/Props/Loader";
// import "jspdf-autotable";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// import NotoSansBengali from "../../Assets/Fonts/NotoSans";

const ReportDesign = (props) => {

    const [apiData, setApiData] = useState([]);
    const [droppedItems, setDroppedItems] = useState([]);
    const [styleArray, setStylesArray] = useState([]);
    // const [loading, setLoading] = useState(true)

    const { setLoading } = props;

    const [variableReplaceData, setVariableReplaceData] = useState('');

    function replaceVariables(itemContent, variableReplaceData) {
        // Add CSS to remove borders and set line height to 1
        const tableStyle = `
            <style>
                table, th, td, tfoot {
                    border: none !important;
                    border-collapse: collapse;
                    line-height: 1;
                    text-align: start;
                }
            </style>
        `;
        // Prepend the style block to the itemContent
        itemContent = tableStyle + itemContent;

        // Create a map to track the visibility status of tables
        const tableVisibility = {};

        // Iterate through each table name in variableReplaceData
        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            // Initialize the visibility status for the table
            tableVisibility[tableName] = {
                shouldHide: true,
                rowsToAdd: ''
            };

            // Check if the table has data
            if (Array.isArray(tableData) && tableData.length > 0) {
                tableVisibility[tableName].shouldHide = false;
            } else {
                continue;
            }

            // Find the first <tbody> and get the content inside it
            const tbodyRegex = /<tbody[^>]*>(.*?)<\/tbody>/s;
            let tbodyMatch = tbodyRegex.exec(itemContent);
            let firstRowStructure = null;

            if (tbodyMatch) {
                const tbodyContent = tbodyMatch[1];
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(tbodyContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1];
                }
            }

            // If no <tbody> is found, fallback to searching for the first <tr> with <td> directly
            if (!firstRowStructure) {
                const trRegex = /<tr>(.*?)<\/tr>/g;
                const firstRowMatch = trRegex.exec(itemContent);
                if (firstRowMatch) {
                    firstRowStructure = firstRowMatch[1];
                }
            }

            if (firstRowStructure) {
                let firstRowAppended = false;

                // Process replacements for each object in tableData
                for (const obj of tableData) {
                    let shouldAppend = false;

                    const tdRegex = /<td[^>]*>(.*?)<\/td>/g;
                    let tdMatch;
                    while ((tdMatch = tdRegex.exec(firstRowStructure)) !== null) {
                        const tdContent = tdMatch[1];

                        for (const key in obj) {
                            if (tdContent.includes(key)) {
                                shouldAppend = true;
                                break;
                            }
                        }
                        if (shouldAppend) break;
                    }

                    if (shouldAppend) {
                        let rowContent = firstRowStructure;

                        rowContent = rowContent.replace(tdRegex, (match, p1) => {
                            for (const key in obj) {
                                if (p1.includes(key)) {
                                    return match.replace(key, obj[key]);
                                }
                            }
                            return match;
                        });

                        tableVisibility[tableName].rowsToAdd += `<tr>${rowContent}</tr>`;

                        if (!firstRowAppended) {
                            firstRowAppended = true;
                        }
                    }
                }

                if (firstRowAppended && tbodyMatch) {
                    const firstTrRegex = /<tr>(.*?)<\/tr>/;
                    itemContent = itemContent.replace(tbodyMatch[0], (match) => {
                        return match.replace(firstTrRegex, '');
                    });
                }

                if (tableVisibility[tableName].rowsToAdd) {
                    itemContent = itemContent.replace('</table>', tableVisibility[tableName].rowsToAdd + '</table>');
                }
            }

            for (const obj of tableData) {
                for (const key in obj) {
                    const regex = new RegExp(key, 'g');
                    itemContent = itemContent.replace(regex, obj[key]);
                }
            }
        }

        for (const tableName in tableVisibility) {
            if (tableVisibility[tableName].shouldHide) {
                const hideTableRegex = new RegExp(`<table[^>]*id="${tableName}"[^>]*>.*?<\\/table>`, 's');
                itemContent = itemContent.replace(hideTableRegex, '<!-- Table Hidden -->');
            }
        }

        return itemContent;
    }

    function replaceVariables1(itemContent, variableReplaceData) {
        for (const tableName in variableReplaceData) {
            const tableData = variableReplaceData[tableName];

            // If there's no data in the table, continue to the next table
            if (!Array.isArray(tableData) || tableData.length === 0) {
                continue;
            }

            // Loop through each key-value pair in the tableData (we consider only the first item for now)
            const obj = tableData[0];

            // Replace placeholders in the content with actual values from the table data
            for (const key in obj) {
                const placeholder = `${key}`;
                const value = obj[key];

                const placeholderRegex = new RegExp(placeholder, 'g');
                itemContent = itemContent.replace(placeholderRegex, value);
            }
        }
        return itemContent;
    }

    const [printData, setPrintData] = useState("");

    const showTemplateData = async (ord1Id, templateName) => {
        setLoading(true)
        const filteredData = [{
            "fieldName": "name",
            "operatorName": "equal",
            "compareValue": templateName || props.templateName || "FinalBillPrint" || "TestReport" || "ReportDesign" || "TestReport",
        }];
        var getdata = await WebTemplate.GetCustomizedPageTemplatesWithFilter(filteredData);
        if (getdata) {
            if (getdata.jsonStructure) {
                try {
                    const decodedData = decodeURIComponent(getdata.jsonStructure);
                    const parsedData = JSON.parse(decodedData);
                    console.log("parsedData1", parsedData);
                    // getdata.jsonStructure = parsedData
                    setDroppedItems(parsedData);
                    console.log("ord1Id", props.ord1Id)
                    const orderNo = ord1Id || props.ord1Id || 0;
                    let sqlQuery = getdata?.insertQuery;
                    if (sqlQuery?.includes('@transId')) {
                        sqlQuery = sqlQuery.replace(/@transId/g, orderNo);
                    }
                    const queryData = {
                        query1: sqlQuery, //"select ord1Id, itemName, OrderNo,ServiceTypeId,TableGroupId,TableId,PaymentStatus,ord2.itemName,D2ItemId,Qty,Rate,ord2.TotalAmt,KotStatus from ord2 inner Join ord1 on ord1.id = ord2.ord1id where o2orderno = 15",
                        query2: sqlQuery,
                        query3: sqlQuery
                    }
                    let tableData;
                    if (sqlQuery) {
                        tableData = await TemplateSetting.getMultiDatasetExecuteQuery(queryData);
                        setVariableReplaceData(tableData);
                    }
                    if (getdata.textContents) {
                        const decodedData = decodeURIComponent(getdata.textContents)
                        const parsedData1 = JSON.parse(decodedData);
                        console.log("parsedData2", parsedData);
                        return { 'printData': parsedData1, 'replaceData': tableData, 'droppedItems': parsedData };
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setDroppedItems([]);
                }
            } else {
                setDroppedItems([]);
            }
            var templateInnerHtml = getdata.textContents;
            var templateInnerHtmlDecode = decodeURIComponent(templateInnerHtml);
            getdata.textContents = templateInnerHtmlDecode

            setApiData(getdata);
        } else {
            setDroppedItems([]);
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true)
            const htmlRegex = /<([a-z][\w0-9]*)\s*[^>]*>.*<\/\1\s*>|<([a-z][\w0-9]*)\s*[^>]*\/>/i;
            if (props.orderData && props.orderData?.length > 0) {
                if (props.isBillAndKot) {
                    // if (props.templateName == "KOTBillPrintWithBill") {
                    let replacedData = [];
                    try {
                        // First, handle data for props.ord1Id
                        const { printData: finalPrintData, replaceData: finalReplaceData, droppedItems: finalDroppedItems } = await showTemplateData(props.ord1Id, "FinalBillPrint");
                        let dataForPrint = [];

                        // Common function for processing dropped items
                        const processDroppedItems = (droppedItems, replaceData) => {
                            droppedItems.forEach((item) => {
                                if (item.items.length > 0) {
                                    const itemContent = item.textContents;
                                    let updatedData = replaceVariables(itemContent, replaceData);

                                    if (updatedData && !/<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/.test(updatedData)) {
                                        dataForPrint.push(updatedData);

                                        // Add page break if itemContent contains '---------------------'
                                        if (typeof itemContent === 'string' && itemContent.includes('---------------------')) {
                                            dataForPrint.push('<div style="page-break-after: always;"></div>');
                                            dataForPrint.push('<div style="margin-top: 50px;"></div>');
                                        }
                                    }
                                }
                            });
                        };

                        // Process Final Bill Print
                        processDroppedItems(finalDroppedItems, finalReplaceData);

                        // **************************** Kot Print *********************

                        const allReplaceDataPromises = props.orderData?.map(order => showTemplateData(order?.ord2Id)); //"KOTBillPrint"
                        const resolvedReplaceData = await Promise.all(allReplaceDataPromises);

                        for (const { printData: kotPrintData, replaceData: kotReplaceData, droppedItems: kotDroppedItems } of resolvedReplaceData) {
                            processDroppedItems(kotDroppedItems, kotReplaceData);
                        }
                        generatePDF(dataForPrint);

                    } catch (error) {
                        console.error("Failed to process data:", error);
                        setLoading(false)
                    }

                }
                else { // for multiple kot print
                    let replacedData = [];
                    const allReplaceDataPromises = props.orderData?.map(order => showTemplateData(order?.ord2Id));
                    const resolvedReplaceData = await Promise.all(allReplaceDataPromises);

                    const dataForPrint = [];
                    for (const { printData, replaceData, droppedItems } of resolvedReplaceData) {
                        const processDroppedItems = (droppedItems, replaceData) => {
                            droppedItems.forEach((item) => {
                                if (item.items.length > 0) {
                                    const itemContent = item.textContents;
                                    let updatedData = replaceVariables(itemContent, replaceData);
                                    if (updatedData && !/<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/.test(updatedData)) {
                                        dataForPrint.push(updatedData);

                                        // Add page break if itemContent contains '---------------------'
                                        if (itemContent?.includes('---------------------')) {
                                            dataForPrint.push('<div style="page-break-after: always;"></div>');
                                            dataForPrint.push('<div style="margin-top: 50px;"></div>')
                                        }
                                    }
                                }
                            });
                        };
                        processDroppedItems(droppedItems, replaceData);
                    }
                    generatePDF(dataForPrint);

                    // console.log(replacedData);
                    // setStylesArray(replacedData);
                }
            } else {
                const { printData, replaceData, droppedItems } = await showTemplateData();
                const processDroppedItems = (droppedItems, replaceData) => {
                    const dataForPrint = [];
                    droppedItems.forEach((item) => {
                        if (item.items.length > 0) {
                            const itemContent = item.textContents;
                            let updatedData = replaceVariables(itemContent, replaceData);
                            if (updatedData && !/<td[^>]*>\s*<div[^>]*>\s*@T\d+\.\w+\s*<\/div>\s*<\/td>/.test(updatedData)) {
                                dataForPrint.push(updatedData);
                            }
                        }
                    });
                    return dataForPrint;
                };

                let dataForPrint = processDroppedItems(droppedItems, replaceData);
                generatePDF(dataForPrint);
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const pixelToMM = (pixels) => {
        const conversionFactor = 0.2645833333; // 1 pixel â‰ˆ 0.2645833333 mm
        return pixels * conversionFactor;
    };

    // const generatePDF = async (printData) => {
    //     try {
    //         if (!printData || printData.length === 0) return;

    //         // Convert printData (array) to a single HTML string
    //         const contentHTML = printData.join('');

    //         const width = 58 //mmToPx(pageWidth)
    //         let container = document.createElement('div');
    //         container.id = 'temporary-container';
    //         container.style.width = `${width}px`;//pageWidth ? "300px" : "300px";
    //         container.innerHTML = `<div id="innerhtml">${contentHTML}</div>`; // Adjust width
    //         document.body.appendChild(container);

    //         const contentHeight = container.offsetHeight;
    //         const contentWidth = container.offsetWidth; // This is the content width based on the container
    //         let contentHeightMm = pixelToMM(contentHeight);
    //         const contentWidthMm = pixelToMM(contentWidth);

    //         if (contentHeightMm < 297) {
    //             if (props.orderData && props.orderData?.length > 0) {
    //                 const orderHeight = (props.orderData?.length || 1) * 700;
    //                 contentHeightMm = pixelToMM(orderHeight);
    //             } else {
    //                 contentHeightMm = 297;
    //             }
    //         } else {
    //             contentHeightMm = pixelToMM(contentHeight);
    //         }

    //         const opt = {
    //             margin: 0,
    //             filename: 'myfile.pdf',
    //             image: { type: 'jpeg', quality: 1 },
    //             html2canvas: { scale: 4 },
    //             jsPDF: { unit: 'mm', format: [58, contentHeightMm], orientation: 'portrait' }
    //         };

    //         try {
    //             const pdfDataUri = await html2pdf()
    //                 .from(contentHTML)
    //                 .set(opt)
    //                 .toPdf()
    //                 .output('datauristring');

    //             const pdfBase64 = pdfDataUri.split(',')[1];

    //             if (window.ReactNativeWebView) {
    //                 try {
    //                     const pdfDataUri = await html2pdf()
    //                         .from(contentHTML)
    //                         .set(opt)
    //                         .toPdf()
    //                         .output('datauristring');

    //                     const pdfBase64 = pdfDataUri.split(',')[1];
    //                     window.ReactNativeWebView.postMessage(pdfBase64);
    //                     props.setShowBillPrint(false);
    //                     return;
    //                 } catch (error) {
    //                     console.log("Error sending PDF to WebView:", error);
    //                 }
    //             } else {
    //                 // Create a blob from the base64 string
    //                 const byteCharacters = atob(pdfBase64);
    //                 const byteNumbers = new Array(byteCharacters.length);
    //                 for (let i = 0; i < byteCharacters.length; i++) {
    //                     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //                 }
    //                 const byteArray = new Uint8Array(byteNumbers);
    //                 const blob = new Blob([byteArray], { type: 'application/pdf' });

    //                 const pdfUrl = URL.createObjectURL(blob);
    //                 const newWindow = window.open(pdfUrl, '_blank');
    //                 props.setShowBillPrint(false);

    //                 // newWindow.onload = function () {
    //                 //     newWindow.print();
    //                 //     newWindow.onafterprint = function () {
    //                 //         newWindow.close();
    //                 //         props.setShowBillPrint(false)
    //                 //     };
    //                 // };
    //                 if (newWindow) {
    //                     container.remove();
    //                     setLoading(false)
    //                     props.setShowBillPrint(false);
    //                     newWindow.focus();
    //                     newWindow.onload = () => {
    //                         newWindow.print();
    //                     };
    //                     props.setShowBillPrint(false);
    //                 }
    //                 document.body.removeChild(contentHTML);
    //                 props.setShowBillPrint(false);
    //             }
    //         } catch (error) {
    //             console.log('Error generating PDF:', error);
    //             props.setShowBillPrint(false)
    //         }
    //         props.setShowBillPrint(false);
    //         return
    //     } catch (error) {
    //         console.error("PDF Generation Error:", error);
    //         props.setShowBillPrint(false);
    //     }
    // };


    // const generatePDF = async (printData) => {
    //     try {
    //         if (!printData || printData.length === 0) return;

    //         // Convert printData (array) to a single HTML string with page breaks
    //         const contentHTML = printData.map(item => `<div>${item}</div>`).join('');

    //         // Create container element in DOM
    //         const width = 58; // mmToPx(pageWidth)
    //         let container = document.createElement('div');
    //         container.id = 'temporary-container';
    //         container.style.width = `${width}px`;
    //         container.innerHTML = `<div id="innerhtml">${contentHTML}</div>`;
    //         document.body.appendChild(container);

    //         // Calculate PDF dimensions
    //         const contentHeight = container.offsetHeight;
    //         let contentHeightMm = pixelToMM(contentHeight);

    //         if (contentHeightMm < 297) {
    //             contentHeightMm = props.orderData?.length ? pixelToMM(props.orderData.length * 700) : 297;
    //         }

    //         // const opt = {
    //         //     margin: 0,
    //         //     filename: 'myfile.pdf',
    //         //     image: { type: 'jpeg', quality: 1 },
    //         //     html2canvas: { scale: 4 },
    //         //     jsPDF: { unit: 'mm', format: [58, contentHeightMm], orientation: 'portrait' }
    //         // };

    //         const opt = {
    //             margin: 0,
    //             filename: 'myfile.pdf',
    //             image: { type: 'jpeg', quality: 1 },  // Use max quality
    //             html2canvas: {
    //                 scale: 5,  // Increase scale for better resolution (default is 1, try 5 for high quality)
    //                 useCORS: true, // Helps with external images
    //                 allowTaint: true, // Helps with cross-origin images
    //                 logging: false, // Reduce console noise
    //             },
    //             jsPDF: {
    //                 unit: 'mm',
    //                 format: [58, contentHeightMm],
    //                 orientation: 'portrait',
    //                 precision: 12 // Increase precision to get sharper text
    //             }
    //         };


    //         try {
    //             const pdfDataUri = await html2pdf()
    //                 .from(container)  // Use the container instead of contentHTML
    //                 .set(opt)
    //                 .toPdf()
    //                 .output('datauristring');

    //             const pdfBase64 = pdfDataUri.split(',')[1];

    //             if (window.ReactNativeWebView) {
    //                 try {
    //                     window.ReactNativeWebView.postMessage(pdfBase64);
    //                     props.setShowBillPrint(false);
    //                     return;
    //                 } catch (error) {
    //                     console.log("Error sending PDF to WebView:", error);
    //                 }
    //             } else {
    //                 // Create a blob from the base64 string
    //                 const byteCharacters = atob(pdfBase64);
    //                 const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
    //                 const byteArray = new Uint8Array(byteNumbers);
    //                 const blob = new Blob([byteArray], { type: 'application/pdf' });

    //                 const pdfUrl = URL.createObjectURL(blob);
    //                 const newWindow = window.open(pdfUrl, '_blank');

    //                 if (newWindow) {
    //                     container.remove();
    //                     setLoading(false);
    //                     props.setShowBillPrint(false);
    //                     newWindow.focus();
    //                     newWindow.onload = () => {
    //                         newWindow.print();
    //                     };
    //                 }

    //                 document.body.removeChild(container);
    //             }
    //         } catch (error) {
    //             console.log('Error generating PDF:', error);
    //             props.setShowBillPrint(false);
    //         }

    //         props.setShowBillPrint(false);
    //     } catch (error) {
    //         console.error("PDF Generation Error:", error);
    //         props.setShowBillPrint(false);
    //     }
    // };

    const generatePDF = async (printData) => {
        try {
            console.log(printData);
            if (!printData || printData.length === 0) return;

            // Convert printData (array) to a single HTML string with page breaks
            const contentHTML = printData.map(item => `<div>${item}</div>`).join('');

            // Create container element in DOM
            const width = 58; // mmToPx(pageWidth)
            let container = document.createElement('div');
            container.id = 'temporary-container';
            container.style.width = `${width}px`;
            container.style.padding = "10px";  // Prevents text from getting cut off
            container.style.background = "#fff"; // Ensure white background
            container.style.fontSmoothing = "antialiased"; // Sharpens text
            container.style.webkitFontSmoothing = "antialiased";
            container.style.mozOsxFontSmoothing = "grayscale";
            container.style.color = "#000"; // Prevent color fade
            container.style.lineHeight = "1.4"; // Improve readability
            container.innerHTML = `<div id="innerhtml">${contentHTML}</div>`;
            document.body.appendChild(container);

            // Calculate PDF dimensions
            const contentHeight = container.offsetHeight;
            let contentHeightMm = pixelToMM(contentHeight);

            if (contentHeightMm < 297) {
                contentHeightMm = props.orderData?.length ? pixelToMM(props.orderData.length * 700) : 297;
            }
            // PDF options with high-quality settings
            const opt = {
                margin: 0,
                filename: 'myfile.pdf',
                image: { type: 'png', quality: 1 },  // PNG for better text clarity
                html2canvas: {
                    scale: 5,  // Higher scale for better resolution
                    useCORS: true, // Helps with external images
                    allowTaint: false, // Prevents tainted canvas issues
                    backgroundColor: "#ffffff", // Ensures white background
                    logging: false, // Reduce console noise
                    letterRendering: true // Improves text sharpness
                },
                jsPDF: {
                    unit: 'mm',
                    format: [58, contentHeightMm],
                    orientation: 'portrait',
                    precision: 20 // Increased precision for sharper text
                }
            };
            try {
                const pdfDataUri = await html2pdf()
                    .from(container)  // Use the container instead of contentHTML
                    .set(opt)
                    .toPdf()
                    .output('datauristring');

                const pdfBase64 = pdfDataUri.split(',')[1];

                if (window.ReactNativeWebView) {
                    try {
                        window.ReactNativeWebView.postMessage(pdfBase64);
                        props.setShowBillPrint(false);
                        return;
                    } catch (error) {
                        console.log("Error sending PDF to WebView:", error);
                    }
                } else {
                    // Create a blob from the base64 string
                    // const byteCharacters = atob(pdfBase64);
                    // const byteNumbers = new Array(byteCharacters.length)
                    //     .fill(0)
                    //     .map((_, i) => byteCharacters.charCodeAt(i));
                    // const byteArray = new Uint8Array(byteNumbers);
                    // const blob = new Blob([byteArray], { type: 'application/pdf' });

                    // Decode base64 PDF data
                    const byteCharacters = atob(pdfBase64);
                    const byteArray = new Uint8Array(byteCharacters.length);

                    // Efficiently fill the Uint8Array
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteArray[i] = byteCharacters.charCodeAt(i);
                    }

                    // Create a Blob from the Uint8Array
                    const blob = new Blob([byteArray], { type: 'application/pdf' });

                    const pdfUrl = URL.createObjectURL(blob);
                    const newWindow = window.open(pdfUrl, '_blank');

                    if (newWindow) {
                        container.remove();
                        setLoading(false);
                        props.setShowBillPrint(false);
                        newWindow.focus();
                        newWindow.onload = () => {
                            newWindow.print();
                        };
                    }
                    document.body.removeChild(container);
                }
            } catch (error) {
                console.log('Error generating PDF:', error);
                props.setShowBillPrint(false);
            }

            props.setShowBillPrint(false);
        } catch (error) {
            console.error("PDF Generation Error:", error);
            props.setShowBillPrint(false);
        }
    };

    return (
        <>
            <div id="pdf-content" style={{ width: '100mm', margin: '5 auto', overflow: 'hidden' }}>
                <div style={{ padding: '5mm', boxSizing: 'border-box' }}>
                    <div
                        style={{
                            transformOrigin: "5% 5%",
                            transform: "scale(0.5, 0.5)",
                            width: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        {/* {renderDroppedItems()} */}
                    </div>
                </div>
                <style>
                    {`
                @media screen {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                @media print {
                    * {
                        color-adjust: exact !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
                `}
                </style>
            </div>
            {/* {loading && <Loader />} */}
        </>
    )
}

export default ReportDesign
