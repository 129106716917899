import { useState, useCallback } from "react";

const AutoCompleteInput = ({
  label,
  fetchData,
  data,
  inputRef,
  selectedItems,
  setSelectedItems,
  handleRemoveItem,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  const debouncedFetchData = useCallback(
    debounce((value) => {
      fetchData(value);
    }, 500),
    [fetchData]
  );

  const handleSelectItem = (item) => {
    const itemId = item.d2Id || item.d2_Id;
    if (
      !selectedItems.some(
        (selected) => selected.d2Id === itemId || selected.d2_Id === itemId
      )
    ) {
      setSelectedItems([...selectedItems, item]);
    }
    setShowSuggestions(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  // const handleRemoveItem = (itemId) => {
  //   setSelectedItems(
  //     selectedItems.filter(
  //       (selected) => selected.d2Id !== itemId && selected.d2_Id !== itemId
  //     )
  //   );
  // };

  return (
    <div className="input-box-m1">
      <input
        className="modify_im_input mode_category"
        ref={inputRef}
        onChange={(e) => {
          setShowSuggestions(true);
          debouncedFetchData(e.target.value);
        }}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
        type="text"
        required
      />
      <label className="modify_p_text2">{label}</label>

      {showSuggestions && (
        <ul className="category-suggestions-loyaltymaster">
          {data.length > 0 ? (
            data.map((item, index) => {
              const itemId = item.id || item.d2Id || item.d2_Id || index;
              return (
                <li
                  key={itemId}
                  className="suggestion-item-loyaltymaster"
                  onClick={() => handleSelectItem(item)}
                >
                  {item.name}
                </li>
              );
            })
          ) : (
            <li className="no-results-loyaltymaster">No results found</li>
          )}
        </ul>
      )}

      <div className="selected-items-container-loyaltymaster">
        {selectedItems.map((item) => {
          debugger;
          const itemId = item.d2Id || item.d2_Id;
          return (
            <div key={itemId} className="selected-item-bubble-loyaltymaster">
              {item.name}
              <span
                className="remove-item-loyaltymaster"
                onClick={() => handleRemoveItem(item)}
              >
                ×
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AutoCompleteInput;
