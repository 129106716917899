import React, { useState, useEffect } from "react";
import './KotStylesheet.css';
import { Link } from 'react-router-dom';
import OrderApi from "../../API/APIServices/Order";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../../Inventory/Props/Loader";
import AppHeaderDetail from "../AppHeaderDetail";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";
import ReportDesign from "../Orders/ReportDesign";
import CommonAlertBox from "../../Inventory/Props/CommonAlertBox";

const PickUpDetails = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ord1Id = searchParams.get('ord1Id');
    //const ord2Id = searchParams.get('ord2Id');

    const [loading, setLoading] = useState(true);

    const [orderStatus, setOrderStatus] = useState([]);

    const handleCheckBoxChange = (e, itemId) => {
        const val = e.target.checked;
        const index = tableOrder.findIndex(item => item.ord2Id === itemId);

        if (index !== -1) {
            setOrderStatus(prevOrderStatus => {
                const updatedOrderStatus = [...prevOrderStatus];
                const updatedItem = {
                    ...tableOrder[index],
                    "o3OrderStatus": "Delivered"
                };
                if (val) {
                    updatedOrderStatus.push(updatedItem);
                } else {
                    const itemIndexToRemove = updatedOrderStatus.findIndex(item => item.ord2Id === itemId);
                    if (itemIndexToRemove !== -1) {
                        updatedOrderStatus.splice(itemIndexToRemove, 1);
                    }
                }
                return updatedOrderStatus;
            });
        }
    };

    const [tableOrder, setTableOrder] = useState([]);

    const fetchData = async () => {
        try {
            setLoading(true)
            const data = await OrderApi.getOrderItemWiseList(ord1Id);
            console.log(data);
            setTableOrder(data);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");

    const closeBtn = () => {
        setIsShowAlertBox(false)
    }

    const deliveredBtn = async () => {
        if (orderStatus.length == 0) {
            // alert("Please Check Item to be Delivered");
            setIsShowAlertBox(true);
            setIsSingleButton(true);
            setHeadingTop("");
            setMessage('Please Check Item to be Delivered');
            return false;
        }
        const currentDate = await CurrentDateAndTime.getCurrentDateTime();
        // const currentDate = new Date(isoString);

        for (var i = 0; i < orderStatus.length; i++) {
            const updatedValues =
            {
                ord1Id: orderStatus[i].ord1Id,
                ord2Id: orderStatus[i].ord2Id,
                orderStatus: orderStatus[i].o3OrderStatus,
                orderDateTime: currentDate,
            }
            await OrderApi.insertItemStatus(updatedValues);
        }

        const data = [
            {
                operationType: 0,
                path: "KotStatus",
                op: "Add",
                from: "string",
                value: "Delivered"
            },
            {
                operationType: 0,
                path: "CookStatus",
                op: "Add",
                from: "string",
                value: "Delivered"
            }
        ]
        for (var i = 0; i < orderStatus.length; i++) {
            const ord2Id = orderStatus[i].ord2Id;
            await OrderApi.updateItemPartially(data, ord2Id);
        }
        // alert("Delivered Successfully");
        fetchData();
        setIsShowAlertBox(true);
        setIsSingleButton(true);
        setHeadingTop("");
        setMessage('Delivered Successfully');
        console.log(orderStatus);
    }

    const [showBillPrint, setShowBillPrint] = useState(false);
    const [ord2Id, setOrd2Id] = useState(0);

    const closeBillPrint = () => {
        setShowBillPrint(false);
        setLoading(false);
    }

    const handlePrint = (orderNo, itemName, preferenceNo, qty, sku, ord2Id) => {
        setOrd2Id(ord2Id)
        setShowBillPrint(true)

        // navigate("/KotPrintPage", {
        //     state: {
        //         orderNo,
        //         itemName,
        //         preferenceNo,
        //         qty,
        //         sku,
        //         ord2Id
        //     }
        // });
    }

    const handleTouchStart = (e) => {
    };

    const handleTouchMove = (e) => {
        setLoading(true)
    };

    const handleTouchEnd = () => {
        fetchData();
        setLoading(false)
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <AppHeaderDetail title={`Details of Order No ${tableOrder.length > 0 ? tableOrder[0].orderNo : 'Loading...'}`} setLoading={setLoading} fetchData={fetchData} />
            <div style={{ backgroundColor: "ghostwhite", marginTop: "55px", marginBottom: "80px", height: "90vh" }}>
                {tableOrder !== null && tableOrder.length > 0 && tableOrder.map((data, index) => (
                    data.kotStatus === "Pickup" &&
                    <div className="listDiv" key={index}>
                        {console.log(data)}
                        <div className="space-between">
                            <label className="ques">{data.d2ItemName}</label>
                            <div className="circle-red">
                                <span className="circle-text-red">{data.qty}</span>
                            </div>
                        </div>
                        <div className="space-between">
                            <div className="flex-start">
                                <label className="ques">SKU : &nbsp;</label>
                                <label className="ans">{data.sku} ---- {data.qty}</label>
                            </div>
                            <input
                                type="checkbox"
                                style={{ display: data.kotStatus === "Pickup" ? "block" : "none", marginRight: "3px" }}
                                onChange={(e) => handleCheckBoxChange(e, data.ord2Id)}
                            />
                        </div>
                        <div className="flex-start">
                            <label className="ques">Cooking Preference : &nbsp;</label>
                            <label className="ans">{data.preferenceNo}</label>
                        </div>
                        {/* <div className="flex-start">
                            <label className="ques">Confirm Date : &nbsp;</label>
                            <label className="ans" style={{ display: !data.itemConfirmedByName ? "none" : "block" }}>{moment(data.itemConfirmedOn).format('DD-MM-YYYY hh:mm A')}</label>
                        </div>
                        <div className="flex-start">
                            <label className="ques">Confirm By : &nbsp;</label>
                            <label className="ans">{data.itemConfirmedByName}</label>
                        </div><br /> */}
                        <div className="space-between">
                            <div className="flex-start">
                                <label className="ques">Kot Status : &nbsp;</label>
                                <label className="ques" style={{ color: "hotpink" }}>{data.kotStatus} &nbsp;</label>
                            </div>
                            <div onClick={() => handlePrint(data.orderNo, data.itemName, data.preferenceNo, data.qty, data.sku, data.ord2Id)}>
                                <i className="fa-solid fa-print"></i>
                            </div>
                        </div>
                    </div>
                ))}
                {/*******************/}
            </div>
            <div className="floating-button-container" style={{ width: "85%", bottom: "20px" }}>
                <button className="blueBtn" onClick={deliveredBtn}>Delivered</button>
            </div>
            {/* ****************************** BILL PRINT *************************** */}
            {showBillPrint && (
                <div style={{ display: "none" }}> {/**/}
                    <div className="modal-back" onClick={closeBillPrint}>
                        <div className="billPrintModal" style={{}}>
                            <div style={{ padding: "5px", backgroundColor: "white" }}>
                                <ReportDesign templateName="KOTBillPrint" ord1Id={ord2Id} setShowBillPrint={setShowBillPrint} setLoading={setLoading}  />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isShowAlertBox && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn=''
                    okayBtnName="Yes" />
            )}
            {loading && <Loader />}
        </div>
    );
};

export default PickUpDetails;










// import React, {  } from "react";
// import './KotStylesheet.css';
// import { Link } from 'react-router-dom';

// const PickupItemList = () => {

//     return (
//         <div style={{ backgroundColor: "white" }}>
//             <div className="heading">
//                 <div className="head-display">
//                     <div className="space-evenly">
//                         <Link to="/PickupList">
//                             <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
//                         </Link>
//                         <h2 className="head-title">Pickup Item List</h2>
//                     </div>
//                 </div>
//             </div>
//             <div style={{ backgroundColor: "ghostwhite", marginTop: "45px" }}>
//                 <div style={{ backgroundColor: "white", border: "1px solid lighgrey", padding: "5px" }}>
//                     <div className="space-between">
//                         <div className="flex-start">
//                             <label className="ques">Order No : &nbsp;</label>
//                             <label className="ans">932874</label>
//                         </div>
//                         <div className="flex-start">
//                             <label className="ques">Table No : &nbsp;</label>
//                             <label className="ans">PERGOLA 2</label>
//                         </div>
//                     </div><hr />
//                     <div>
//                         <div className="flex-start p-1">
//                             <input type="checkbox" style={{ boxShadow: "none" }} />
//                             <label className="ques" style={{ color: "black", fontSize:"16px" }}>&nbsp;Plain Naan 1 PC</label>
//                         </div>
//                         <div className="flex-start">
//                             <label className="ques">Qty - &nbsp;</label>
//                             <label className="ans">3 &nbsp;</label>
//                             <label className="ques">Pref.No. &nbsp;</label>
//                             <label className="ans">1 &nbsp;</label>
//                         </div>
//                         <hr />
//                     </div>
//                     {/***************/}
//                     <div>
//                         <div className="flex-start p-1">
//                             <input type="checkbox" style={{ boxShadow: "none" }} />
//                             <label className="ques" style={{ color: "black", fontSize: "16px" }}>&nbsp;Plain Naan 1 PC</label>
//                         </div>
//                         <div className="flex-start">
//                             <label className="ques">Qty - &nbsp;</label>
//                             <label className="ans">3 &nbsp;</label>
//                             <label className="ques">Pref.No. &nbsp;</label>
//                             <label className="ans">1 &nbsp;</label>
//                         </div>
//                         <hr />
//                     </div>
//                     <div>
//                         <div className="flex-start p-1">
//                             <input type="checkbox" style={{ boxShadow: "none" }} />
//                             <label className="ques" style={{ color: "black", fontSize: "16px" }}>&nbsp;Plain Naan 1 PC</label>
//                         </div>
//                         <div className="flex-start">
//                             <label className="ques">Qty - &nbsp;</label>
//                             <label className="ans">3 &nbsp;</label>
//                             <label className="ques">Pref.No. &nbsp;</label>
//                             <label className="ans">1 &nbsp;</label>
//                         </div>
//                         <hr />
//                     </div>

//                 </div>
//             </div>
//             <div className="floating-button-container w-100">
//                 <button className="btn-blue" style={{ width: "90%", backgroundColor: "rgb(76, 141, 162)" }}>Delivery</button>
//             </div>
//         </div>
//     );
// };

// export default PickupItemList;
