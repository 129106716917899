import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import MainButton from "../../Props/MainButton";
import ModelAddCoupon from "./ModelAddCoupon";
import LoyaltyMaster from "../../../API/APIServices/LoyaltyMaster";
import ItemGroup from "../../../API/APIServices/ItemGroup";
import ItemMaster from "../../../API/APIServices/ItemMaster";
import AutoCompleteInput from "../../Props/Dropdown/AutoCompleteInput ";
import Account from "../../../API/APIServices/Account";
import { values } from "pdf-lib";

function LoyaltyMasterSetting() {
  //*************************title Start***********************//
  useEffect(() => {
    document.title = "Loyalty Master Setting";
  }, []);

  //**************************title End***********************//

  const [isEdit, setIsEdit] = useState(false);
  // const [debouncedSearchVal, setDebouncedSearchVal] = useState("");
  const [searchVal, setSearchVal] = useState("");
  /***********************Refs****************** */
  const modalRef = useRef();
  /***********************Refs****************** */

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };

  const [mode, setMode] = useState("Percentage");
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState("");
  const [isCheckedDiscount, setIsCheckedDiscount] = useState(true);
  const [allowCashback, setAllowCashback] = useState("No");
  const [selectedOptionOccassion, setSelectedOptionOccassion] = useState(1);

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionsAward, setSelectedOptionsAward] = useState([]);

  const containerRef1 = useRef(null);
  const [isOpenRule, setIsOpenRule] = useState(false);
  const [selectedOptionsRule, setSelectedOptionsRule] = useState([]);

  //************************States********************************* */
  const [CardList, setCardList] = useState([]);
  const [membershipDetails, setMembershipDetails] = useState({
    cardTypes: "",
    memberShipCardId: "",
    registrationDate: "",
    initialAmt: "",
    redemptionAmtBy: "1",
    allowCashBack: "",
    discountValueType: "",
    discountValue: "",
    cardUseBy: "1",
    cardOwner: "",
    cardExpiryDate: "",
  });
  const [memAccId, setMemAccId] = useState(null);
  const [totalPages, setTotalPages] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(5);
  // const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  //************************States &********************************* */

  //*****************************Utility************************************////
  const getCardNameById = (id) => {
    if (!CardList || CardList.length === 0) {
      return "Card list is empty";
    }
    const card = CardList.find((card) => card.id === Number(id));
    return card ? card.cardName : "Card not found";
  };

  //*****************************Utility************************************////
  const tableHeaders = [
    "Card Types",
    "Membership Card ID",
    "Registration Date",
    "Initial Amount",
    "Redemption Amount By",
    // 'Allow Cashback',
    // 'Discount Value Type',
    // 'Discount Value',
    // 'Card Use By',
    "Card Owner",
    "Card Expiry Date",
    "Actions",
  ];

  const [tableData, setTableData] = useState([]);

  // const tableData = [
  //     {
  //         cardTypes: 'Gold',
  //         memberShipCardId: '12345',
  //         registrationDate: '2024-01-01',
  //         initialAmt: '$100',
  //         redemptionAmtBy: 'Points',
  //         allowCashBack: 'Yes',
  //         discountValueType: 'Percentage',
  //         discountValue: '10%',
  //         cardUseBy: 'Individual',
  //         cardOwner: 'John Doe',
  //         cardExpiryDate: '2025-12-31',
  //     },
  //     {
  //         cardTypes: 'Silver',
  //         memberShipCardId: '67890',
  //         registrationDate: '2024-02-01',
  //         initialAmt: '$50',
  //         redemptionAmtBy: 'Cash',
  //         allowCashBack: 'No',
  //         discountValueType: 'Flat',
  //         discountValue: '$5',
  //         cardUseBy: 'Corporate',
  //         cardOwner: 'Jane Doe',
  //         cardExpiryDate: '2026-01-31',
  //     },
  // ];

  const [filteredAccount, setFilteredAccount] = useState([]);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [cardOwnerName, setCardOwnerName] = useState("");

  const handleAccountSelection = async (id) => {
    debugger;
    var selectedAccData = await Account.getAccountById(id);
    var selectedAcc = selectedAccData.item1[0];
    setCardOwnerName(selectedAcc.name);
    setMembershipDetails((prevState) => ({
      ...prevState,
      cardOwner: id,
    }));
  };

  const debouncedFetchAccountSuggestions = useCallback(
    debounce(async (name, value, GroupId) => {
      const SearchItem = await fetchAccountSuggestions(name, value, []);

      if (name === "cardOwner") {
        if (SearchItem) {
          setFilteredAccount(SearchItem);
        }
        setIsDropdownOpen1(true);
      }
    }, 500),
    []
  );

  const fetchAccountSuggestions = async (name, value, GroupId) => {
    const filteredData = {
      MasterType: 2,
      GroupWiseSuggestion: true,
      GroupId: GroupId,
      search_str: value,
      PageNumber: 1,
      PageSize: 50,
    };

    try {
      const SearchItem = await Account.GetAccountListForAutoSuggestion(
        filteredData
      );
      return SearchItem;
    } catch (error) {
      console.error("Error fetching account suggestions:", error);
      return null;
    }
  };

  const handleEdit = (CardDetails) => {
    // alert(`Edit clicked for ID: ${CardDetails.id}`);
    setMemAccId(CardDetails.id);
    setMembershipDetails({
      id: CardDetails.id,
      cardTypes: CardDetails.cardTypes,
      memberShipCardId: CardDetails.memberShipCardId,
      registrationDate: CardDetails.registrationDate,
      initialAmt: CardDetails.initialAmt,
      redemptionAmtBy: CardDetails.redemptionAmtBy,
      allowCashBack: CardDetails.allowCashBack,
      discountValueType: CardDetails.discountValueType,
      discountValue: CardDetails.discountValue,
      cardUseBy: CardDetails.cardUseBy,
      cardOwner: CardDetails.cardOwner,
      cardExpiryDate: CardDetails.cardExpiryDate,
    });
    var cardOwnerName = customerNames[CardDetails.cardOwner];
    console.log("cardOwnerName", cardOwnerName);
    setCardOwnerName(cardOwnerName);
  };

  const handleDelete = (id) => {
    alert(`Delete clicked for ID: ${id}`);
    DeleteMemberShipAccMaster(id);
  };

  const handleSearch = () => {
    // debugger
    if (searchVal.trim() !== "") {
      GetAllLoyaltyMemberShipAccount();
    } else {
      GetAllLoyaltyMemberShipAccount();
    }
  };

  useEffect(() => {
    console.log("membershipDetails", membershipDetails);
  }, [membershipDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "cardOwner") {
      setCardOwnerName(value);
      debouncedFetchAccountSuggestions(name, value);
    }

    setMembershipDetails((prevState) => ({
      ...prevState,
      [name]: ["redemptionAmtBy", "cardUseBy"].includes(name)
        ? parseInt(value, 10) || 0
        : value,
    }));
  };

  //  /* *******************Tab****************** */

  const [activeTab, setActiveTab] = useState(0); // Initialize the active tab index

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [tabcontent1, setTabContent1] = useState(true);
  const [tabcontent2, setTabContent2] = useState(false);
  const [tabcontent3, setTabContent3] = useState(false);
  const [tabcontent4, setTabContent4] = useState(false);
  const [tabcontent5, setTabContent5] = useState(false);

  const showtabs1 = () => {
    setTabContent1(true);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
  };

  const showtabs2 = () => {
    setTabContent1(false);
    setTabContent2(true);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(false);
  };

  const showtabs3 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(true);
    setTabContent4(false);
    setTabContent5(false);
  };
  const showtabs4 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(true);
    setTabContent5(false);
  };
  const showtabs5 = () => {
    setTabContent1(false);
    setTabContent2(false);
    setTabContent3(false);
    setTabContent4(false);
    setTabContent5(true);
  };
  // {/* *******************Tab****************** */}

  //***************{Enter Btn Click}***************//
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);
  const input7Ref = useRef(null);
  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);
  const input12Ref = useRef(null);
  const input13Ref = useRef(null);
  const input14Ref = useRef(null);
  const input15Ref = useRef(null);
  const input16Ref = useRef(null);
  const input17Ref = useRef(null);
  const input18Ref = useRef(null);
  const input19Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };

  //************************Show Hide Start***********************//

  const [isInputVisiblam1, setInputVisiblam1] = useState(false);
  const handleToggleChangam1 = () => {
    setInputVisiblam1(!isInputVisiblam1);
  };
  //*************************Show Hide End***********************//

  //**************************modal box Start**********************//

  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  //****************************modal box End******************************//
  const handleModeChange = (e) => {
    setMode(e.target.value);
    setValue(""); // Clear input when mode changes
    setShowError(false);
    setIsCheckedDiscount(e.target.value === "Fixed Amount");
  };

  const handleInputChangeMode = (e) => {
    setValue(e.target.value);
    setShowError(false);
  };

  const handleInputBlur = () => {
    if (value.trim() === "") {
      setShowError(true);
    }
  };

  const handleAllowCashbackChange = (e) => {
    setAllowCashback(e.target.value);
  };

  //**********{Model Countries}*****************//
  const [isModalOpenCoupon, setModalOpenCoupon] = useState(false);
  const showModalCoupon = () => {
    setModalOpenCoupon(true);
  };
  const hideModalCoupon = () => {
    setModalOpenCoupon(false);
  };

  /********************************************* */

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (checkboxValue) => {
    if (selectedOptionsAward.includes(checkboxValue)) {
      setSelectedOptionsAward(
        selectedOptionsAward.filter((item) => item !== checkboxValue)
      );
    } else {
      setSelectedOptionsAward([...selectedOptionsAward, checkboxValue]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedOptionsAward.length === 0) {
      const allOptions = ["checkBox1", "checkBox2", "checkBox3", "checkBox4"];
      setSelectedOptionsAward(allOptions);
    } else {
      setSelectedOptionsAward([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  /********************************************* */

  const toggleDropdownRule = () => {
    setIsOpenRule(!isOpenRule);
  };

  const handleCheckboxChangeRule = (checkboxValue) => {
    if (selectedOptionsRule.includes(checkboxValue)) {
      setSelectedOptionsRule(
        selectedOptionsRule.filter((item) => item !== checkboxValue)
      );
    } else {
      setSelectedOptionsRule([...selectedOptionsRule, checkboxValue]);
    }
  };

  const handleSelectAllChangeRule = () => {
    if (selectedOptionsRule.length === 0) {
      const allOptionsRule = [
        "checkBox1",
        "checkBox2",
        "checkBox3",
        "checkBox4",
      ];
      setSelectedOptionsRule(allOptionsRule);
    } else {
      setSelectedOptionsRule([]);
    }
  };

  useEffect(() => {
    const handleClickOutsideRule = (event) => {
      if (
        containerRef1.current &&
        !containerRef1.current.contains(event.target)
      ) {
        setIsOpenRule(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideRule);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRule);
    };
  }, [containerRef1]);

  /********************************Handles******************************** */

  const handleAddCard = (e) => {
    // e.preventDefault();

    // debugger
    if (modalRef.current) {
      if (isEdit) {
        console.log("Calling update method in ModelAddCoupon");
        modalRef.current.update();
      } else {
        console.log("Calling save method in ModelAddCoupon");
        modalRef.current.save();
      }
    } else {
      console.log("ModalRef is null");
    }
  };

  const handleSave = () => {
    if (
      membershipDetails.memberShipCardId.trim() === "" ||
      membershipDetails.cardTypes.trim() === ""
    ) {
      alert(
        "Please fill all the required fields MemberShip CardId and Card Types"
      );
      return;
    }

    if (memAccId !== null) {
      UpdateLoyaltyMemberShipAccount();
    } else {
      CreateLoyaltyMemberShipAccount();
    }
  };
  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
      console.log("Page updated to:", page);
    }, 300),
    []
  );

  const handlePreviousPage = useCallback(() => {
    if (currentPage > 1) debouncedSetPage(currentPage - 1);
  }, [currentPage, debouncedSetPage]);

  const handleNextPage = useCallback(() => {
    if (currentPage < totalPages) debouncedSetPage(currentPage + 1);
  }, [currentPage, totalPages, debouncedSetPage]);

  // const handlePreviousPage = () => {
  //     if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  // };

  // const handleNextPage = () => {
  //     if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  // };
  /********************************Handles******************************** */

  /**********************************Api calls Start*************************************** */

  ////*********************************Get All Loyalty MemberShip Account Card Details***************************************************////
  const GetAllLoyaltyMemberShipAccountCardDetails = async () => {
    try {
      const response =
        await LoyaltyMaster.GetAllLoyaltyMemberShipAccountCardDetails();
      if (response.isSuccess && response.statusCode === 200) {
        console.log("response", response);
        setCardList(response.result);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  useEffect(() => {
    GetAllLoyaltyMemberShipAccountCardDetails();
  }, []);

  ////*********************************Get All Loyalty MemberShip Account Card Details***************************************************////

  ////***********************************Create Loyalty MemberShip Account***************************************************************////
  const CreateLoyaltyMemberShipAccount = async () => {
    // debugger
    const IsExisting = tableData.find(
      (memacc) => memacc.memberShipCardId === membershipDetails.memberShipCardId
    );

    if (IsExisting) {
      alert("MemberShip Card Id Already Exists ");
      return;
    }

    try {
      const response = await LoyaltyMaster.CreateLoyaltyMemberShipAccount(
        membershipDetails
      );
      if (response.isSuccess && response.statusCode === 201) {
        alert("Created Successfully");
        setMembershipDetails({
          cardTypes: "",
          memberShipCardId: "",
          registrationDate: "",
          initialAmt: "",
          redemptionAmtBy: "",
          allowCashBack: "",
          discountValueType: "",
          discountValue: "",
          cardUseBy: "",
          cardOwner: "",
          cardExpiryDate: "",
        });
        setCardOwnerName("");
        GetAllLoyaltyMemberShipAccount();
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  ////***********************************Create Loyalty MemberShip Account***************************************************************////

  ////***********************************Get All Loyalty MemberShip Account**********************************************************////

  const [customerNames, setCustomerNames] = useState({});
  const Getcustomer = async (Id) => {
    try {
      const response = await Account.getAccountById(Id);
      return response ? response.item1[0].name : Id;
    } catch (error) {
      console.error(error);
      return Id;
    }
  };
  useEffect(() => {
    const fetchCustomerNames = async () => {
      const names = {};
      await Promise.all(
        tableData.map(async (row) => {
          if (row.cardOwner) {
            names[row.cardOwner] = await Getcustomer(row.cardOwner);
          }
        })
      );
      setCustomerNames(names);
    };

    fetchCustomerNames();
  }, [tableData]);

  const GetAllLoyaltyMemberShipAccount = async () => {
    let filter;
    if (searchVal.trim() !== "") {
      filter = {
        filter: [
          {
            fieldName: "cardOwner",
            operatorName: "contains",
            compareValue: searchVal,
          },
        ],
        order: [
          {
            propertyName: "string",
            ascending: true,
          },
        ],
        pageNumber: currentPage,
        pageSize: PageSize,
      };
    } else {
      filter = {
        filter: [],
        order: [],
        pageNumber: currentPage,
        pageSize: PageSize,
      };
    }
    try {
      const response = await LoyaltyMaster.GetAllLoyaltyMemberShipAccount(
        filter
      );
      if (response.isSuccess && response.statusCode === 200) {
        console.log("response", response);
        setTotalPages(Math.ceil(response.totalRecords / PageSize));
        setTableData(response.result);
      } else {
        alert(response.errorMessages);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //     GetAllLoyaltyMemberShipAccount()
  // }, []);
  useEffect(() => {
    GetAllLoyaltyMemberShipAccount();
  }, [currentPage, PageSize]);
  ////***********************************Get All Loyalty MemberShip Account**********************************************************////

  ////***********************************Update Loyalty MemberShip Account**********************************************************////
  const UpdateLoyaltyMemberShipAccount = async () => {
    // debugger
    try {
      const response = await LoyaltyMaster.UpdateLoyaltyMemberShipAccount(
        membershipDetails
      );
      if (response.isSuccess && response.statusCode === 201) {
        alert("Updated Successfully");
        setMembershipDetails({
          cardTypes: "",
          memberShipCardId: "",
          registrationDate: "",
          initialAmt: "",
          redemptionAmtBy: "",
          allowCashBack: "",
          discountValueType: "",
          discountValue: "",
          cardUseBy: "",
          cardOwner: "",
          cardExpiryDate: "",
        });
        GetAllLoyaltyMemberShipAccount();
        setMemAccId(null);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  ////***********************************Update Loyalty MemberShip Account**********************************************************////

  ////***********************************Delete Loyalty MemberShip Account**********************************************************////
  const DeleteMemberShipAccMaster = async (id) => {
    // debugger
    try {
      const response = await LoyaltyMaster.DeleteMemberShipAccMaster(id);
      if (response) {
        alert("Deleted Successfully");
        GetAllLoyaltyMemberShipAccount();
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  ////***********************************Delete Loyalty MemberShip Account**********************************************************////

  /**********************************Api calls End*************************************** */

  ////************************************ MemberShip Card Details TabContent 1 *****************************************///////
  const [loyaltyData, setLoyaltyData] = useState({
    loyaltyUsed: 1,
    loyaltyExpire: 1,
    noOfDaysExpireAfterConfirm: 0,
    loyaltyPointValue: 0,
    loyaltyAmtAgainstPoint: 0,
    calculateLoyaltyOn: 1,
    isLoyaltyPointUpdated: true,
  });
  const handleLoyaltyChange = (e) => {
    const { name, value, type, checked } = e.target;

    setLoyaltyData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? checked
          : value === "true"
          ? true
          : value === "false"
          ? false
          : isNaN(value)
          ? value
          : Number(value),
    }));
  };
  const handleLoyaltySave = async () => {
    try {
      const response = await LoyaltyMaster.CreateAndUpdateMemberShipCardDetails(
        loyaltyData
      );
      if (response.isSuccess && response.statusCode === 201) {
        alert("Updated Successfully");
        setLoyaltyData({
          loyaltyUsed: 1,
          loyaltyExpire: 1,
          noOfDaysExpireAfterConfirm: 0,
          loyaltyPointValue: 0,
          loyaltyAmtAgainstPoint: 0,
          calculateLoyaltyOn: 1,
          isLoyaltyPointUpdated: true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLoyaltyData = async () => {
    try {
      const response = await LoyaltyMaster.GetAllMemberShipCardDetails();
      if (response.isSuccess && response.statusCode === 200) {
        setLoyaltyData(response.result[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchLoyaltyData();
  }, []);

  useEffect(() => {
    console.log("loyaltyData", loyaltyData);
  }, [loyaltyData]);

  ////************************************ MemberShip Card Details TabContent 1 ****************************************///////

  ////************************************ Loyalty Occassion  TabContent 3 ****************************************///////
  const [loyaltyAward, setLoyaltyAward] = useState({
    loyaltyAwardOccassion: 0,
    specificOccassion: [
      {
        occassion: "",
        fromDate: "",
        toDate: "",
        fromTime: "",
        toTime: "",
      },
    ],
    specificDayWeek: [
      {
        dayInWeek: 0,
        fromTime: "",
        toTime: "",
      },
    ],
  });
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [rows, setRows] = useState(
    Array(5).fill({
      occassion: "",
      fromDate: "",
      toDate: "",
      fromTime: "",
      toTime: "",
    })
  );

  const [dayTimes, setDayTimes] = useState(
    daysOfWeek.map((day) => ({
      day,
      fromTime: "",
      toTime: "",
    }))
  );

  const handleLoyaltyAwardChange = (field, value) => {
    setLoyaltyAward((prev) => ({
      ...prev,
      [field]: typeof prev[field] === "number" ? Number(value) : value,
    }));
  };
  const handleOptionChangeOccassion = (e) => {
    setSelectedOptionOccassion(e.target.value);
    handleLoyaltyAwardChange(e.target.name, e.target.value);
  };

  const handleSpecificOccassionChange = (index, event) => {
    const { name, value } = event.target;

    setRows((prevRows) =>
      prevRows.map((row, i) => (i === index ? { ...row, [name]: value } : row))
    );

    setLoyaltyAward((prev) => {
      const updatedOccasions = [...prev.specificOccassion];

      if (index < updatedOccasions.length) {
        updatedOccasions[index] = { ...updatedOccasions[index], [name]: value };
      } else {
        updatedOccasions.push({
          occassion: "",
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
          [name]: value,
        });
      }

      return { ...prev, specificOccassion: updatedOccasions };
    });
  };

  const handleDayTimesChange = (dayIndex, field, event) => {
    const { value } = event.target;

    setDayTimes((prevDayTimes) =>
      prevDayTimes.map((day, i) =>
        i === dayIndex ? { ...day, [field]: value } : day
      )
    );

    setLoyaltyAward((prev) => {
      const updatedDayWeek = [...prev.specificDayWeek];

      const existingIndex = updatedDayWeek.findIndex(
        (item) => item.dayInWeek === dayIndex
      );

      if (existingIndex !== -1) {
        updatedDayWeek[existingIndex] = {
          ...updatedDayWeek[existingIndex],
          [field]: value,
        };
      } else {
        updatedDayWeek.push({
          dayInWeek: dayIndex,
          fromTime: "",
          toTime: "",
          [field]: value,
        });
      }

      return { ...prev, specificDayWeek: updatedDayWeek };
    });
  };

  const handleClearDayTime = (dayIndex) => {
    setDayTimes((prevDayTimes) =>
      prevDayTimes.map((day, i) =>
        i === dayIndex ? { ...day, fromTime: "", toTime: "" } : day
      )
    );

    setLoyaltyAward((prev) => ({
      ...prev,
      specificDayWeek: prev.specificDayWeek.map((item, i) =>
        i === dayIndex ? { ...item, fromTime: "", toTime: "" } : item
      ),
    }));
  };

  const handleloyaltyOccassionSave = async () => {
    const formattedData = {
      ...loyaltyAward,
      specificDayWeek:
        loyaltyAward.loyaltyAwardOccassion === 3
          ? loyaltyAward.specificDayWeek.map((item) => ({
              ...item,
              fromTime: item.fromTime ? item.fromTime + ":00" : "00:00:00",
              toTime: item.toTime ? item.toTime + ":00" : "00:00:00",
            }))
          : [
              {
                dayInWeek: 0,
                fromTime: "00:00:00",
                toTime: "00:00:00",
              },
            ],

      specificOccassion:
        loyaltyAward.loyaltyAwardOccassion === 2
          ? loyaltyAward.specificOccassion.map((item) => ({
              ...item,
              fromTime: item.fromTime ? item.fromTime + ":00" : "00:00:00",
              toTime: item.toTime ? item.toTime + ":00" : "00:00:00",
            }))
          : [
              {
                occassion: "",
                fromDate: "2000-01-01",
                toDate: "2000-01-01",
                fromTime: "00:00:00",
                toTime: "00:00:00",
              },
            ],
    };

    console.log("Sending data:", formattedData);
    try {
      const response = await LoyaltyMaster.UpdateLoyaltyAwardOccassionSetting(
        formattedData
      );
      if (response.isSuccess && response.statusCode === 201) {
        alert("Updated Successfully");
        fetchloyaltyAwardData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchloyaltyAwardData = async () => {
    try {
      const response = await LoyaltyMaster.GetLoyaltyAwardOccassionSetting();
      if (response) {
        console.log("response", response);
        setLoyaltyAward({
          ...response.result,
          specificOccassion: response.result.specificOccassion ?? [],
          specificDayWeek: response.result.specificDayWeek ?? [],
        });

        setSelectedOptionOccassion(response.result.loyaltyAwardOccassion);
        if (response?.result) {
          const { loyaltyAwardOccassion, specificOccassion, specificDayWeek } =
            response.result;

          if (loyaltyAwardOccassion === 2 && specificOccassion) {
            setRows((prevRows) => {
              const updatedRows = [...prevRows];

              specificOccassion.forEach((item, index) => {
                if (index < updatedRows.length) {
                  updatedRows[index] = {
                    occassion: item.occassion,
                    fromDate: item.fromDate,
                    toDate: item.toDate,
                    fromTime: item.fromTime,
                    toTime: item.toTime,
                  };
                } else {
                  updatedRows.push({
                    occassion: item.occassion,
                    fromDate: item.fromDate,
                    toDate: item.toDate,
                    fromTime: item.fromTime,
                    toTime: item.toTime,
                  });
                }
              });

              return updatedRows;
            });
          }

          if (loyaltyAwardOccassion === 3 && specificDayWeek) {
            setDayTimes((prevDayTimes) => {
              const apiDataMap = specificDayWeek.reduce((acc, item) => {
                acc[item.dayInWeek] = {
                  day: daysOfWeek[item.dayInWeek],
                  fromTime: item.fromTime,
                  toTime: item.toTime,
                };
                return acc;
              }, {});

              return prevDayTimes.map((dayItem, index) =>
                apiDataMap[index] ? apiDataMap[index] : dayItem
              );
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchloyaltyAwardData();
  }, []);

  ////************************************ Loyalty Occassion  TabContent 3 ****************************************///////

  ////************************************  Tier Loyalty Award TabContent 4 ****************************************///////
  const [rows1, setRows1] = useState(
    Array(5).fill({
      limitUpto: false,
      transactionCurrencyValue: 0,
      currencyUnits: 0,
      loyaltyAwardPointsAgainstCurrencyUnits: 0,
    })
  );
  const handleTierLoyaltyAwardChange = (index, field, value) => {
    setRows1((prevRows) =>
      prevRows.map((row, rowIndex) =>
        rowIndex === index ? { ...row, [field]: value } : row
      )
    );
  };

  const handleTierSave = async () => {
    try {
      const filteredRows = rows1.filter(
        (row) =>
          row.limitUpto !== false ||
          row.transactionCurrencyValue !== 0 ||
          row.currencyUnits !== 0 ||
          row.loyaltyAwardPointsAgainstCurrencyUnits !== 0
      );

      if (filteredRows.length === 0) {
        console.warn("No data to save.");
        return;
      }

      const response = await LoyaltyMaster.CreateTierLoyaltyAwardSettings(
        filteredRows
      );

      if (response.isSuccess) {
        alert("Saved Successfully");
        console.log("Response:", response);
        fetchTierLoyaltyAwardData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTierLoyaltyAwardData = async () => {
    try {
      const response = await LoyaltyMaster.GetAllTierLoyaltyAwardSettings();

      if (response && response.result) {
        console.log("Response:", response);

        setRows1((prevRows) => {
          return prevRows.map((prevRow, index) => {
            const newRow = response.result[index];

            if (!newRow) {
              return prevRow;
            }

            return {
              ...prevRow,
              ...newRow,
            };
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTierLoyaltyAwardData();
  }, []);

  useEffect(() => {
    console.log("rows1", rows1);
  }, [rows1]);
  ////************************************  Tier Loyalty Award TabContent 4 ***************************************///////
  ////************************************  Referral Settings TabContent 2 ****************************************//////
  const [referralSettings, setReferralSettings] = useState({
    referalLoyaltyPointValue: 0,
    referalLoyaltyPointEarning: 0,
    loyaltyAmtUseOnCategory: 0,
    categoryList: [0],
    loyaltyAmtUseOnItem: 0,
    itemList: [0],
    pointUseInTransPercentwise: 0,
    referalLoyaltyPointEarningBy: 0,
  });

  const handleReferralSettingsChange = (e) => {
    const { name, value } = e.target;

    setReferralSettings((prev) => ({
      ...prev,
      [name]: parseInt(value, 10) || value,
    }));
  };

  const [CategoryData, setCategoryData] = useState([]);
  const [itemData, setItemData] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCategorySelection = (selectedCategories) => {
    setSelectedCategories(selectedCategories);

    setReferralSettings((prev) => ({
      ...prev,
      categoryList: selectedCategories.map((cat) => cat.d2_Id),
    }));
  };

  const handleRemoveCategory = (categoryToRemove) => {
    const updatedCategories = selectedCategories.filter(
      (cat) => cat.d2_Id !== categoryToRemove.d2_Id
    );

    setSelectedCategories(updatedCategories);

    setReferralSettings((prev) => ({
      ...prev,
      categoryList: updatedCategories.map((cat) => cat.d2_Id),
    }));
  };

  const handleItemSelection = (selectedItems) => {
    setSelectedItems(selectedItems);

    setReferralSettings((prev) => ({
      ...prev,
      itemList: selectedItems.map((item) => item.d2Id),
    }));
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedItems = selectedItems.filter(
      (item) => item.d2Id !== itemToRemove.d2Id
    );

    setSelectedItems(updatedItems);

    setReferralSettings((prev) => ({
      ...prev,
      itemList: updatedItems.map((item) => item.d2Id),
    }));
  };

  const getCategoryData = async (searchCatst, isFetch = false, d2_Id = 0) => {
    let sendData;
    if (searchCatst !== "") {
      sendData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: searchCatst,
        },
      ];
    } else if (isFetch && d2_Id != 0) {
      sendData = [
        {
          fieldName: "d2_Id",
          operatorName: "equal",
          compareValue: d2_Id,
        },
      ];
    } else {
      sendData = [];
    }
    try {
      const response = await ItemGroup.GetItemGroupWithFilter(sendData, 100);
      if (response && !isFetch) {
        setCategoryData(response);
      } else if (response && isFetch && d2_Id != 0) {
        setSelectedCategories((prevSelected) => {
          const newItems = Array.isArray(response) ? response : [response];
          const filteredItems = newItems.filter(
            (item) =>
              !prevSelected.some((selected) => selected.d2_Id === item.d2_Id)
          );
          return [...prevSelected, ...filteredItems];
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getItemData = async (searchCatst, isFetch = false, d2Id = 0) => {
    let sendData;
    if (searchCatst !== "") {
      sendData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: searchCatst,
        },
      ];
    } else if (isFetch && d2Id != 0) {
      sendData = [
        {
          fieldName: "d2Id",
          operatorName: "equal",
          compareValue: d2Id,
        },
      ];
    } else {
      sendData = [];
    }
    try {
      debugger;
      const response = await ItemMaster.GetItemMasterWithFilterAndPagination(
        sendData
      );
      if (response && !isFetch) {
        setItemData(response);
      } else if (response && isFetch && d2Id != 0) {
        setSelectedItems((prevSelected) => {
          const newItems = Array.isArray(response) ? response : [response];
          const filteredItems = newItems.filter(
            (item) =>
              !prevSelected.some((selected) => selected.d2Id === item.d2Id)
          );
          return [...prevSelected, ...filteredItems];
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReferralSave = async () => {
    try {
      const response =
        LoyaltyMaster.UpdateLoyaltyReferalCodeSetting(referralSettings);
      if (response.isSuccess) {
        console.log("response", response);
        alert("Updated Successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await LoyaltyMaster.GetLoyaltyReferalCodeSetting();
      if (response.isSuccess) {
        setReferralSettings(response.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(referralSettings && referralSettings.itemList && referralSettings.itemList[0] != 0) {
      referralSettings.itemList.forEach((itemId) => {
        getItemData("", true, itemId);
      });
    }
  }, [referralSettings]);

  useEffect(() => {
    debugger;
    if(referralSettings && referralSettings.categoryList && referralSettings.categoryList[0] != 0) {
    referralSettings.categoryList.forEach((itemId) => {
      getCategoryData("", true, itemId);
    });
  }
  }, [referralSettings]);

  useEffect(() => {
    console.log("referralSettings", referralSettings);
    console.log("selectedItems", selectedItems);
  }, [referralSettings, selectedItems]);

  ////************************************  Referral Settings TabContent 2 ****************************************///////

  return (
    <div>
      <PageTabs />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Loyalty Master Setting</h2>
        </div>
        {/* *******************Save Button Start****************** */}

        {/* *******************Top Buttons****************** */}
        <MainButton PageName="Masters" />

        {/* *******************Modal Box Open****************** */}
        <div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <div className="modal-title">
                  <h3 className="modal-h">Bill Sundry Master</h3>
                  <span className="close" onClick={hideModal}>
                    &times;
                  </span>
                </div>
                <div className="modal-content-im">
                  <div className="">
                    <div className="input-box-m1 modal-1">
                      <div className="cl-toggle-switch">
                        <label className="cl-switch">
                          <input
                            type="checkbox"
                            onChange={handleToggleChangam1}
                            checked={isInputVisiblam1}
                          />
                          <span></span>
                        </label>
                        <label className="modify_p_text2">
                          Address Details
                          <i className="fa-solid fa-circle-info ml-10"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-btn-box">
                <div
                  className={`tab1 ${activeTab === 5 ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(5);
                    showtabs1();
                  }}
                >
                  Membership Card Setting
                </div>
                <div
                  className={`tab1 ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(1);
                    showtabs2();
                  }}
                >
                  Referal Code Setting
                </div>
                <div
                  className={`tab1 ${activeTab === 2 ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(2);
                    showtabs3();
                  }}
                >
                  Loyalty Award Occassion
                </div>
                <div
                  className={`tab1 ${activeTab === 3 ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(3);
                    showtabs4();
                  }}
                >
                  Tier Loyalty Award Setting
                </div>
                <div
                  className={`tab1 ${activeTab === 4 ? "active" : ""}`}
                  onClick={() => {
                    handleTabClick(4);
                    showtabs5();
                  }}
                >
                  Membership Account
                </div>
              </div>
              <div className="tab-content-box">
                {tabcontent1 && (
                  <div className="tab-content-1">
                    <div className="company-info-top-left">
                      <div className="company_info-text-im gd-1">
                        Loyalty Setting
                      </div>
                      <div className="">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              ref={input2Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input3Ref)
                              }
                              name="loyaltyExpire"
                              value={loyaltyData?.loyaltyExpire}
                              onChange={handleLoyaltyChange}
                              type="text"
                              required
                            >
                              <option value={1}>No Expire</option>
                              {/* <option>Use Expire Date</option> */}
                              <option value={2}>
                                Expire Date from Transaction Date
                              </option>
                            </select>
                            <label className="modify_lbl_text3">
                              Loyalty Expire
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              ref={input6Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input7Ref)
                              }
                              name="noOfDaysExpireAfterConfirm"
                              value={loyaltyData?.noOfDaysExpireAfterConfirm}
                              onChange={handleLoyaltyChange}
                              type="number"
                              required
                            />
                            <label className="modify_lbl_text2">
                              No of Days Expire after Transaction Date
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              ref={input9Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input10Ref)
                              }
                              name="loyaltyUsed"
                              value={loyaltyData?.loyaltyUsed}
                              onChange={handleLoyaltyChange}
                              type="text"
                              required
                            >
                              <option value={1}>Same Store</option>
                              <option value={2}>All Store</option>
                              <option value={3}>
                                All Store With Online Portal
                              </option>
                            </select>
                            <label className="modify_lbl_text3">
                              Loyalty Used (membership card)
                            </label>
                          </div>

                          {/* <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              type="date"
                              name="loyaltyExpireDate"
                              value={loyaltyData?.loyaltyExpireDate}
                              onChange={handleLoyaltyChange}
                              required
                            />
                            <label className="modify_lbl_text4">
                              Expiry Date
                            </label>
                          </div> */}
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <label
                              className="modify_p_text2"
                              style={{ color: "red" }}
                            >
                              Ex. 10 Loyalty Point Value = 1 currency (Rs., &
                              etc)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="company-info-top-right"
                      style={{ width: "50%" }}
                    >
                      <div className="company_info-text-im">
                        Loyalty Point Convert
                      </div>
                      <div className="stock-im-1">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              ref={input3Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input4Ref)
                              }
                              name="loyaltyPointValue"
                              onChange={handleLoyaltyChange}
                              value={loyaltyData?.loyaltyPointValue}
                              type="number"
                              required
                            />
                            <label className="modify_p_text2">
                              Loyalty Point value
                            </label>
                          </div>

                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              ref={input7Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input8Ref)
                              }
                              type="number"
                              name="loyaltyAmtAgainstPoint"
                              value={loyaltyData?.loyaltyAmtAgainstPoint}
                              onChange={handleLoyaltyChange}
                              required
                            />
                            <label className="modify_lbl_text2">
                              Loyalty Amount against point (Currency)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <select
                            className="modify_im_input mode_limit mode_category"
                            ref={input4Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input5Ref)
                            }
                            name="calculateLoyaltyOn"
                            onChange={handleLoyaltyChange}
                            value={loyaltyData?.calculateLoyaltyOn}
                            required
                          >
                            <option value={1}>Taxable Value</option>
                            <option value={2}>Gross Value</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Calculate loyalty on
                          </label>
                        </div>
                        <div className="input-box-m1">
                          <select
                            className="modify_im_input mode_limit mode_category"
                            ref={input8Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input9Ref)
                            }
                            name="isLoyaltyPointUpdated"
                            onChange={handleLoyaltyChange}
                            value={loyaltyData?.isLoyaltyPointUpdated}
                            type="text"
                            required
                          >
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                          </select>
                          <label className="modify_lbl_text4">
                            Update loyalty point while update voucher
                          </label>
                        </div>
                      </div>
                      <div className="tab-content-1">
                        <div className="flex-loyalty-Save">
                          <button
                            className="btn-loyalty-save"
                            onClick={handleLoyaltySave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabcontent2 && (
                  <div className="tab-content-2 mmd">
                    <div className="company-info-top-left">
                      <div className="company_info-text-im gd-1">
                        Loyalty Setting
                      </div>
                      <div className="">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              ref={input10Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input11Ref)
                              }
                              type="number"
                              name="referalLoyaltyPointValue"
                              value={referralSettings?.referalLoyaltyPointValue}
                              onChange={handleReferralSettingsChange}
                              required
                            />
                            <label className="modify_p_text2">
                              Referal loyalty Point Value
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_limit mode_category"
                              ref={input11Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input12Ref)
                              }
                              name="referalLoyaltyPointEarning"
                              value={
                                referralSettings?.referalLoyaltyPointEarning
                              }
                              onChange={handleReferralSettingsChange}
                              type="text"
                              required
                            >
                              <option value={1}>None</option>
                              <option value={2}>After First Transaction</option>
                              <option value={3}>Before Transaction</option>
                            </select>
                            <label className="modify_lbl_text4">
                              Referal loyalty Point Earning
                            </label>
                          </div>
                        </div>

                        <div className="input-box-Im input-height2"></div>
                      </div>
                      <div className="company_info-text-im gd-1">
                        Category Details
                      </div>
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <select
                            className="modify_im_input mode_category"
                            ref={input12Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input13Ref)
                            }
                            name="loyaltyAmtUseOnCategory"
                            value={referralSettings?.loyaltyAmtUseOnCategory}
                            onChange={handleReferralSettingsChange}
                            type="text"
                            required
                          >
                            <option value={1}>None</option>
                            <option value={2}>Applicable</option>
                            <option value={3}>Not Applicable</option>
                          </select>
                          <label className="modify_lbl_text3">
                            Loyalty Amt Use On Category
                          </label>
                        </div>
                      </div>
                      <div className="input-box-Im input-height2">
                        {/* <div className="input-box-m1"> */}
                        {/* <input
                            className="modify_im_input mode_category"
                            ref={input16Ref}
                            onKeyDown={(event) =>
                              handleKeyPress(event, input17Ref)
                            }
                            onChange={(e) => {
                              setShowSuggestionsForCat(true);
                              debouncedGetCategoryData(e.target.value);
                            }}
                            onBlur={() =>
                              setTimeout(
                                () => setShowSuggestionsForCat(false),
                                200
                              )
                            }
                            type="text"
                            required
                          />
                          <label className="modify_p_text2">Category</label> */}

                        {/* {showSuggestionsForCat && (
                            <ul className="category-suggestions-loyaltymaster">
                              {CategoryData.length > 0 ? (
                                CategoryData.map((item, index) => (
                                  <li
                                    key={index}
                                    className="suggestion-item-loyaltymaster"
                                  >
                                    {item.name}
                                  </li>
                                ))
                              ) : (
                                <li className="no-results-loyaltymaster">
                                  No results found
                                </li>
                              )}
                            </ul>
                          )} */}
                        <AutoCompleteInput
                          label="Category"
                          fetchData={getCategoryData}
                          data={CategoryData}
                          inputRef={input16Ref}
                          selectedItems={selectedCategories}
                          setSelectedItems={handleCategorySelection}
                          handleRemoveItem={handleRemoveCategory}
                        />
                        {/* </div> */}

                        <div className="input-box-m1">
                          {/* <button className="stock_search">Add </button> */}
                          <button
                            className="stock_set"
                            onClick={() => {
                              setSelectedCategories([]);
                              setReferralSettings((prev) => ({
                                ...prev,
                                categoryList: [0],
                              }));
                            }}
                          >
                            Remove All{" "}
                          </button>
                        </div>
                      </div>

                      {/* <div className="company_info-text-im gd-1">Item List</div> */}
                      <div className="input-box-Im input-height2">
                        <div className="input-box-m1">
                          <label
                            className="modify_p_text2"
                            style={{ color: "red" }}
                          >
                            This option is valid for APP Based user only
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="company-info-top-right"
                      style={{ width: "50%" }}
                    >
                      <div className="company_info-text-im">
                        Loyalty Details
                      </div>
                      <div className="stock-im-1">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              ref={input15Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input16Ref)
                              }
                              name="pointUseInTransPercentwise"
                              value={
                                referralSettings?.pointUseInTransPercentwise
                              }
                              onChange={handleReferralSettingsChange}
                              type="number"
                              required
                            />
                            <label className="modify_p_text2">
                              Point Use In Trans % wise
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              ref={input14Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input15Ref)
                              }
                              name="referalLoyaltyPointEarningBy"
                              value={
                                referralSettings?.referalLoyaltyPointEarningBy
                              }
                              onChange={handleReferralSettingsChange}
                              type="text"
                              required
                            >
                              <option value={1}>Card User</option>
                              <option value={2}>Referral User</option>
                            </select>
                            <label className="modify_lbl_text4">
                              Referal loyalty Point Earning By
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="stock-im-1 loyalty-item-top">
                        <div className="company_info-text-im">Item Details</div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              ref={input13Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input14Ref)
                              }
                              name="loyaltyAmtUseOnItem"
                              value={referralSettings?.loyaltyAmtUseOnItem}
                              onChange={handleReferralSettingsChange}
                              type="text"
                              required
                            >
                              <option value={1}>None</option>
                              <option value={2}>Applicable</option>
                              <option value={3}>Not Applicable</option>
                            </select>
                            <label className="modify_lbl_text3">
                              Loyalty Amt Use On Item
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          {/* <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_category"
                              ref={input17Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input18Ref)
                              }
                              onChange={(e) => {
                                debouncedGetItemData(e.target.value);
                                setShowSuggestions(true);
                              }}
                              onBlur={() =>
                                setTimeout(() => setShowSuggestions(false), 200)
                              }
                              type="text"
                              required
                            />
                            <label className="modify_p_text2">Items</label>

                            {showSuggestions && (
                              <ul className="category-suggestions-loyaltymaster">
                                {itemData.length > 0 ? (
                                  itemData.map((item, index) => (
                                    <li
                                      key={index}
                                      className="suggestion-item-loyaltymaster"
                                    >
                                      {item.name}
                                    </li>
                                  ))
                                ) : (
                                  <li className="no-results-loyaltymaster">
                                    No results found
                                  </li>
                                )}
                              </ul>
                            )}
                          </div> */}
                          <AutoCompleteInput
                            label="Items"
                            fetchData={getItemData}
                            data={itemData}
                            inputRef={input17Ref}
                            selectedItems={selectedItems}
                            setSelectedItems={handleItemSelection}
                            handleRemoveItem={handleRemoveItem}
                          />
                          <div className="input-box-m1">
                            {/* <button className="add_btn1 stock_search">
                              Add{" "}
                            </button> */}
                            <button
                              className="remove_btn stock_set"
                              onClick={() => {
                                setSelectedItems([]);
                                setReferralSettings((prev) => ({
                                  ...prev,
                                  itemList: [0],
                                }));
                              }}
                            >
                              Remove All{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content-1">
                        <div className="flex-loyalty-Save">
                          <button
                            className="btn-loyalty-save"
                            onClick={handleReferralSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabcontent3 && (
                  <div className="tab-content-3 mmd">
                    <div className="company-info-top-left occassion-width-setting">
                      <div className="company_info-text-im gd-1">
                        Loyalty Award occassion setting
                      </div>
                      <div className="">
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <select
                              className="modify_im_input mode_category"
                              ref={input18Ref}
                              onKeyDown={(event) =>
                                handleKeyPress(event, input19Ref)
                              }
                              type="text"
                              name="loyaltyAwardOccassion"
                              value={loyaltyAward.loyaltyAwardOccassion}
                              onChange={handleOptionChangeOccassion}
                              required
                            >
                              <option value={1}>All Time</option>
                              <option value={2}>Specific Occassion</option>
                              <option value={3}>
                                Specific Occassion Day in Week
                              </option>
                            </select>
                            <label className="modify_lbl_text3">
                              Loyalty award occassion setting
                            </label>
                          </div>
                        </div>
                      </div>
                      {selectedOptionOccassion == "2" && (
                        <div className="tab-content-4">
                          <div className="">
                            <div className="company-info-top-im">
                              <div className="company-info-top-left occassion-width-setting">
                                <div className="company_info-text-im">
                                  Specific Occassion
                                </div>
                                <table className="table-t-Occassion">
                                  <thead className="thead-groupmaster">
                                    <tr>
                                      <th
                                        className="table-th"
                                        style={{ width: "20%" }}
                                      >
                                        Occassion
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        From Date
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        To Date
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        From Time
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        To Time
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        Update
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rows.map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        <td>
                                          <input
                                            className="table-input-12"
                                            type="text"
                                            name="occassion"
                                            value={row.occassion}
                                            onChange={(e) =>
                                              handleSpecificOccassionChange(
                                                rowIndex,
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="table-input-12"
                                            type="date"
                                            name="fromDate"
                                            value={row.fromDate}
                                            onChange={(e) =>
                                              handleSpecificOccassionChange(
                                                rowIndex,
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="table-input-12"
                                            type="date"
                                            name="toDate"
                                            value={row.toDate}
                                            onChange={(e) =>
                                              handleSpecificOccassionChange(
                                                rowIndex,
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="table-input-12"
                                            type="time"
                                            name="fromTime"
                                            value={row.fromTime}
                                            onChange={(e) =>
                                              handleSpecificOccassionChange(
                                                rowIndex,
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="table-input-12"
                                            type="time"
                                            name="toTime"
                                            value={row.toTime}
                                            onChange={(e) =>
                                              handleSpecificOccassionChange(
                                                rowIndex,
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <button className="loyalty-update-btn">
                                            Update
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div onClick={handleAddRow}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedOptionOccassion == "3" && (
                        <div className="tab-content-4">
                          <div className="">
                            <div className="company-info-top-im">
                              <div className="company-info-top-left occassion-width-setting">
                                <div className="company_info-text-im">
                                  Specific Occassion Day in week
                                </div>
                                <table className="table-t">
                                  <thead className="thead-groupmaster">
                                    <tr>
                                      <th
                                        className="table-th"
                                        style={{ width: "20%" }}
                                      >
                                        Day
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        From Time
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "15%" }}
                                      >
                                        To Time
                                      </th>
                                      <th
                                        className="table-th"
                                        style={{ width: "10%" }}
                                      >
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {dayTimes.map((day, dayIndex) => (
                                      <tr
                                        key={dayIndex}
                                        style={{ height: "45px" }}
                                      >
                                        <td>{day.day}</td>
                                        <td>
                                          <input
                                            type="time"
                                            value={day.fromTime}
                                            onChange={(e) =>
                                              handleDayTimesChange(
                                                dayIndex,
                                                "fromTime",
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="time"
                                            value={day.toTime}
                                            onChange={(e) =>
                                              handleDayTimesChange(
                                                dayIndex,
                                                "toTime",
                                                e
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              backgroundColor: "#ff4d4d",
                                              color: "white",
                                              border: "none",
                                              padding: "6px 12px",
                                              borderRadius: "5px",
                                              cursor: "pointer",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                              transition: "0.3s",
                                            }}
                                            onMouseOver={(e) =>
                                              (e.target.style.backgroundColor =
                                                "#cc0000")
                                            }
                                            onMouseOut={(e) =>
                                              (e.target.style.backgroundColor =
                                                "#ff4d4d")
                                            }
                                            onClick={() =>
                                              handleClearDayTime(dayIndex)
                                            }
                                          >
                                            Clear
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div onClick={handleAddRow}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="tab-content-1">
                        <div className="flex-loyalty-Save">
                          <button
                            className="btn-loyalty-save"
                            onClick={handleloyaltyOccassionSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabcontent4 && (
                  <div className="tab-content-4">
                    <div className="">
                      <div className="company-info-top-im">
                        <div className="company-info-top-left">
                          <div className="company_info-text-im">
                            Tier Loyalty point award setting
                          </div>
                          <table className="table-t">
                            <thead className="thead-groupmaster">
                              <tr>
                                <th
                                  className="table-th"
                                  style={{ width: "15%" }}
                                >
                                  Limit Upto
                                </th>
                                <th
                                  className="table-th"
                                  style={{ width: "28%" }}
                                >
                                  Transaction Currency Value
                                </th>
                                <th
                                  className="table-th"
                                  style={{ width: "28%" }}
                                >
                                  Currency Units
                                </th>
                                <th
                                  className="table-th"
                                  style={{ width: "28%" }}
                                >
                                  Loyalty award Points against Currency Units
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {rows1.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td style={{ textAlign: "center" }}>
                                    <input
                                      type="checkbox"
                                      checked={row.limitUpto}
                                      onChange={(e) =>
                                        handleTierLoyaltyAwardChange(
                                          rowIndex,
                                          "limitUpto",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="table-input-12"
                                      type="number"
                                      value={row.transactionCurrencyValue || ""}
                                      onChange={(e) =>
                                        handleTierLoyaltyAwardChange(
                                          rowIndex,
                                          "transactionCurrencyValue",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="table-input-12"
                                      type="number"
                                      value={row.currencyUnits || ""}
                                      onChange={(e) =>
                                        handleTierLoyaltyAwardChange(
                                          rowIndex,
                                          "currencyUnits",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="table-input-12"
                                      type="number"
                                      value={
                                        row.loyaltyAwardPointsAgainstCurrencyUnits ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleTierLoyaltyAwardChange(
                                          rowIndex,
                                          "loyaltyAwardPointsAgainstCurrencyUnits",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div onClick={handleAddRow}>
                            {" "}
                            {/* <p>Add Row</p> */}
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <label
                                className="modify_p_text2"
                                style={{ color: "red" }}
                              >
                                *Fill atleast one row, it is neccessory for
                                calculate loyalty amt.*
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <label
                                className="modify_p_text2"
                                style={{ color: "#943636" }}
                              >
                                *Please do not use Decimal(ex- 1.5), use
                                Integer(ex. 1) Value.
                              </label>
                            </div>
                          </div>
                          <div className="tab-content-1">
                            <div
                              className="flex-loyalty-Save"
                              style={{ marginTop: "16px" }}
                            >
                              <button
                                className="btn-loyalty-save"
                                onClick={handleTierSave}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tabcontent5 && (
                  <div className="tab-content-5 ">
                    <div className="mmd">
                      <div className="company-info-top-left">
                        <div className="company_info-text-im gd-1">
                          Membership Card
                        </div>
                        <div className="">
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1 flex-card-type">
                              <select
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                value={membershipDetails.cardTypes}
                                name="cardTypes"
                                onChange={handleInputChange}
                                required
                              >
                                <option value="">Select Card Type</option>
                                {CardList.length > 0 &&
                                  CardList.map((cardType) => (
                                    <option
                                      key={cardType.id}
                                      value={cardType.id}
                                    >
                                      {cardType.cardName}
                                    </option>
                                  ))}

                                {/* <option>Gold</option>
                                                                <option>Silver</option>
                                                                <option>Platinum</option> */}
                              </select>
                              <label className="modify_lbl_text3">
                                Card Types
                              </label>
                              <div
                                className="plus-icon-loyalty"
                                onClick={showModalCoupon}
                              >
                                <i class="fa-solid fa-circle-plus"></i>
                              </div>
                            </div>
                            <div>
                              {isModalOpenCoupon && (
                                <div className="modal">
                                  <div className="AddNewItem-modal modal_coupon-loyalty">
                                    <div className="modal-title">
                                      <h3 className="modal-OrderLIst collection-color-cpn">
                                        Add Card
                                      </h3>
                                      <span
                                        className="close_modal"
                                        onClick={hideModalCoupon}
                                      >
                                        &times;
                                      </span>
                                    </div>
                                    <div className="ModalContentModalSettingP ">
                                      <ModelAddCoupon
                                        ref={modalRef}
                                        setIsEdit={setIsEdit}
                                      />
                                    </div>
                                    <div className="footer-collection-cpn">
                                      <div className="flex-btn-cancel-add">
                                        <div>
                                          <button
                                            className="cancel-btn-cpn"
                                            onClick={hideModalCoupon}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            className="add-btn-cpn"
                                            onClick={(e) => handleAddCard(e)}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="text"
                                name="memberShipCardId"
                                value={membershipDetails.memberShipCardId}
                                onChange={handleInputChange}
                                required
                              />
                              <label className="modify_lbl_text2">
                                MemberShip Card Id
                              </label>
                            </div>
                          </div>
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="date"
                                name="registrationDate"
                                value={membershipDetails.registrationDate}
                                onChange={handleInputChange}
                                required
                              />
                              <label className="modify_lbl_text3">
                                Registration Date
                              </label>
                            </div>

                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_category"
                                type="date"
                                name="cardExpiryDate"
                                onChange={handleInputChange}
                                value={membershipDetails.cardExpiryDate}
                                required
                              />
                              <label className="modify_lbl_text4">
                                Card Expiry Date
                              </label>
                            </div>
                          </div>

                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <select
                                className="modify_im_input mode_category"
                                type="text"
                                value={membershipDetails.redemptionAmtBy}
                                name="redemptionAmtBy"
                                onChange={handleInputChange}
                                required
                              >
                                <option value={1}>Only Card Owner</option>
                                <option value={2}>Associate Member</option>
                              </select>
                              <label className="modify_lbl_text3">
                                Redemption Amount By
                              </label>
                            </div>
                            {/* <div className="input-box-m1">
                              <select
                                className="modify_im_input mode_category"
                                type="text"
                                // onChange={handleAllowCashbackChange}
                                // value={allowCashback}
                                name="allowCashBack"
                                onChange={handleInputChange}
                                value={membershipDetails.allowCashBack}
                                required
                              >
                                <option value={"No"}>No</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"After First Purchase"}>
                                  After First Purchase
                                </option>
                              </select>
                              <label className="modify_lbl_text4">
                                Allow Cashback
                              </label>
                            </div> */}
                          </div>
                          {/* {membershipDetails.allowCashBack === "Yes" && (
                            <div className="input-box-Im input-height2">
                              <div className="input-box-coupon">
                                <select
                                  className="modify_im_input mode_category"
                                  type="text"
                                  // onChange={handleModeChange}
                                  // value={mode}
                                  name="discountValueType"
                                  value={membershipDetails.discountValueType}
                                  onChange={handleInputChange}
                                >
                                  <option value="Percentage">Percentage</option>
                                  <option value="Fixed Amount">
                                    Fixed Amount
                                  </option>
                                </select>
                                <label class="modify_lbl_text3">
                                  Discount Value
                                </label>
                              </div>
                              <div className="input-box-coupon discount-value-cpn input-with-symbol-cpn">
                                <div className="symbol-container-cpn">
                                  {membershipDetails.discountValueType ===
                                    "Fixed Amount" && (
                                    <span className="symbol-left-cpn">â‚¹</span>
                                  )}
                                  <input
                                    className="modify_im_input mode_category value-cpn-padding"
                                    type="text"
                                    placeholder={
                                      membershipDetails.discountValueType ===
                                      "Percentage"
                                        ? ""
                                        : "0.00"
                                    }
                                    // value={value}
                                    // onChange={handleInputChangeMode}
                                    name="discountValue"
                                    onChange={handleInputChange}
                                    value={membershipDetails.discountValue}
                                    onBlur={handleInputBlur}
                                  />
                                  {membershipDetails.discountValueType ===
                                    "Percentage" && (
                                    <span className="symbol-right-cpn">%</span>
                                  )}
                                </div>
                                {showError && (
                                  <div className="error-message-cpn">
                                    <span className="alert-icon-cpn">
                                      <i class="fa-solid fa-circle-exclamation"></i>
                                    </span>
                                    Discount value can't be blank
                                  </div>
                                )}
                              </div>
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div
                        className="company-info-top-right"
                        style={{ width: "50%" }}
                      >
                        <div className="company_info-text-im">
                          Membership Details
                        </div>
                        <div className="stock-im-1">
                          <div className="input-box-Im input-height2">
                            <div className="input-box-m1">
                              <select
                                className="modify_im_input mode_category"
                                type="text"
                                name="cardUseBy"
                                onChange={handleInputChange}
                                value={membershipDetails.cardUseBy}
                                required
                              >
                                <option value={1}>Individual User</option>
                                <option value={2}>Group User</option>
                              </select>
                              <label className="modify_lbl_text3">
                                Card Use By
                              </label>
                            </div>
                            <div className="input-box-m1">
                              <input
                                className="modify_im_input mode_limit mode_category"
                                type="text"
                                name="cardOwner"
                                onChange={handleInputChange}
                                value={cardOwnerName}
                                onBlur={() => {
                                  setTimeout(() => {
                                    setIsDropdownOpen1(false);
                                  }, 500);
                                }}
                                required
                              />
                              <label className="modify_lbl_text2">
                                Card Owner
                              </label>
                              {isDropdownOpen1 && (
                                <ul
                                  className="ItemmasterDuggestionsaleinvoice"
                                  // ref={suggestionBoxRef}
                                >
                                  {filteredAccount.map((Acc, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleAccountSelection(Acc.d1RefId)
                                      }
                                      style={
                                        {
                                          // padding: "2px 5px",
                                          // cursor: "pointer",
                                          // borderBottom: "1px solid #ccc",
                                          // backgroundColor:
                                          //   highlightedIndex === index
                                          //     ? "#e6f7ff"
                                          //     : "transparent",
                                        }
                                      }
                                    >
                                      {Acc.name}
                                    </li>
                                  ))}
                                  <li
                                    key={filteredAccount.length}
                                    className="addNew_btn_saleInvoice"
                                    addNew_btn_saleInvoice
                                    // onClick={() =>
                                    //   openAccountMasterModal("Add")
                                    // }
                                    style={{
                                      cursor: "pointer",
                                      borderBottom: "1px solid #ccc",
                                      // backgroundColor:
                                      //   highlightedIndex ===
                                      //   filteredAccount.length
                                      //     ? "black"
                                      //     : "#5e709e",
                                    }}
                                  >
                                    {" "}
                                    Add New
                                    <i class="fa-solid fa-plus"></i>
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="number"
                              name="initialAmt"
                              onChange={handleInputChange}
                              value={membershipDetails.initialAmt}
                              required
                            />
                            <label className="modify_p_text2">
                              Initial Amount
                            </label>
                          </div>
                          <div className="input-box-m1">
                            <input
                              className="modify_im_input mode_limit mode_category"
                              type="text"
                              required
                            />
                            <label className="modify_lbl_text2">
                              Total Amount
                            </label>
                          </div>
                        </div>
                        <div className="input-box-Im input-height2">
                          <div className="input-box-m1">
                            <label
                              className="modify_p_text2"
                              style={{ color: "black", fontSize: "18px" }}
                            >
                              Associate Card Member
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content-1">
                      <div className="flex-loyalty-Save">
                        <button
                          className="btn-loyalty-save"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <table className="tableList-main" role="table">
                        <thead className="tablelist-input-box">
                          <tr>
                            <th colSpan={tableHeaders.length + 1}>
                              <div className="input-header-container">
                                {/* Pagination Controls */}
                                <div className="pagination-controls">
                                  <button
                                    className="pagination-arrow"
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 1}
                                  >
                                    <i className="fa-solid fa-arrow-left"></i>
                                  </button>
                                  <span>
                                    {/* Page {currentPage} of {totalPages} */}
                                    Page {currentPage} of {totalPages}
                                  </span>
                                  <button
                                    className="pagination-arrow"
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                  >
                                    <i className="fa-solid fa-arrow-right"></i>
                                  </button>
                                  <label htmlFor="">Select Size</label>
                                  <select
                                    name=""
                                    id=""
                                    value={PageSize}
                                    onChange={(e) =>
                                      setPageSize(e.target.value)
                                    }
                                  >
                                    <option value="2">2</option>
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                  </select>
                                </div>

                                <div className="search-box-loyalty">
                                  <input
                                    type="text"
                                    placeholder="Enter Card Owner Name"
                                    className="search-input-loyalty"
                                    value={searchVal}
                                    onChange={(e) =>
                                      setSearchVal(e.target.value)
                                    }
                                  />
                                  <button
                                    className="search-button-loyalty"
                                    onClick={handleSearch}
                                  >
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                  </button>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <thead
                          className="table-head-main"
                          style={{ fontSize: "12px" }}
                        >
                          <tr>
                            {tableHeaders.map((header, index) => (
                              <th key={index}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="table-body-main">
                          {tableData.map((row) => (
                            <tr key={row.id}>
                              <td key={row.id}>
                                {getCardNameById(row.cardTypes)}
                              </td>
                              <td key={row.id}>{row.memberShipCardId}</td>
                              <td key={row.id}>{row.registrationDate}</td>
                              <td key={row.id}>{row.initialAmt}</td>
                              <td key={row.id}>{row.redemptionAmtBy}</td>
                              <td key={row.id}>
                                {customerNames[row.cardOwner] || "Loading..."}
                              </td>
                              <td key={row.id}>{row.cardExpiryDate}</td>
                              <td>
                                <button
                                  className="TableEditIcon"
                                  onClick={() => handleEdit(row)}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fa-regular fa-pen-to-square"></i>
                                </button>
                                <button
                                  className="TableDelIcon"
                                  onClick={() => handleDelete(row.id)}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "#dc3545",
                                  }}
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {/* <div className="tab-content-1">
                                    <div className="flex-loyalty-Save">
                                        <button className="btn-loyalty-save" onClick={handleSave}>Save</button>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="buttom-box">
            <div className="button-btn-1">
              <button className="btn-1">Save</button>
            </div>
            <div className="button-btn-2">
              <button className="btn-2">Reset</button>
            </div>
            <div className="button-btn-3">
              <button className="btn-3" onClick={showModal}>
                <i className="fa-solid fa-gear "></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoyaltyMasterSetting;
