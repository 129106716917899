import axios from '../axiosConfig';
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;
var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const SmsTemplate = {

    insertSmsTemplate: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                title: data.title,
                templateForSMSAPI: data.templateForSMSAPI,
                templateId: data.templateId,
                queryName: data.queryName,
                fieldName: data.fieldName,
                smsTemplateBody: data.smsTemplateBody,
                templateForWhatsappAPI: data.templateForWhatsappAPI,
                whatsappLanguage: data.whatsappLanguage,
                messageHeaderSection: data.messageHeaderSection,
                messageBodySection: data.messageBodySection,
                smsType: data.smsType,
                templateForFormDetails: data.templateForFormDetails,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    MessageTemplate: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/saveMessageTemplate`;
            console.log(apiUrl);
            const response = await axios.post(apiUrl, {
                SMSTypeId: data.smsType,
                TemplateId: data.template,

                SMSTypeName: data.smsTypeName,
                TemplateName: data.templateName
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getSmsTemplate: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetSmsTemplate`;
                //const apiUrl = `${express_server_url}/api/SmsTemplate/GetSmsTemplateMasters`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteSmsTemplate: async (SmsTemplateId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates?SmsTemplateId=${SmsTemplateId}`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${SmsTemplateId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get By Id ****************************
    getSmsTemplateById: async (data) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetSmsById?SmsTemplateId=${data}`;

                //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${SmsTemplateId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *************************** Update ***************************
    updateSmsTemplate: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/SmsTemplates/UpdateSmsTemplate?SmsTemplateId=${data.id}`;

            //const apiUrl = `${express_server_url}/api/SmsTemplate?SmsTemplateId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                title: data.title,
                templateForSMSAPI: data.templateForSMSAPI,
                templateId: data.templateId,
                queryName: data.queryName,
                fieldName: data.fieldName,
                smsTemplateBody: data.smsTemplateBody,
                templateForWhatsappAPI: data.templateForWhatsappAPI,
                whatsappLanguage: data.whatsappLanguage,
                messageHeaderSection: data.messageHeaderSection,
                messageBodySection: data.messageBodySection,
                smsType: data.smsType,
                templateForFormDetails: data.templateForFormDetails,
                isActive: true,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    GetAssignTemplates: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetAssignTemplates`;
                //const apiUrl = `${express_server_url}/api/SmsTemplate/GetSmsTemplateMasters`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // ******************* Get Assign Template by Name *****************
    GetAssignTemplatesByName: async (smsType, transId, accId, smsTypeId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/SmsTemplates/GetAssignTemplatesByName`;
                const response = await axios.post(apiUrl,
                    {
                        smsTypeId: smsTypeId,
                        smsType: smsType,
                        transId: transId,
                        accId: accId
                    }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                }
                else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ********************************* WhatsApp File ********************************
    // uploadWhatsAppFile: async (blob) => {
    //     debugger;

    //     console.log(blob)

    //     const formData = new FormData();
    //     formData.append('files', blob); 

    //     // let sendData;
    //     for (let [key, value] of formData.entries()) {
    //         console.log(`${key}:`, value);
    //         // sendData = value;
    //     }
    //      console.log(formData)

    //     try {
    //         const apiUrl = `${WebApiUrl}/api/SmsTemplates/send-WhatsAppFile`;
    //         const response = await axios.post(apiUrl,
    //             formData, {
    //             withCredentials: true,
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',  // Set Content-Type for FormData
    //             },
    //         });
    //         console.log("Response:", response.data.isSuccess);
    //         return response;
    //     } catch (error) {
    //            console.error('Error:', error.message);
    //     }
    // },

    uploadWhatsAppFile: async (blob) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                console.log(blob); // Ensure this is a valid File or Blob object

                // const formData = new FormData();
                // formData.append('files', blob);

                // let sendData;

                // for (let [key, value] of formData.entries()) {
                //     console.log(`${key}:`, value);
                //     sendData = value;
                //     break;
                // }

                const formData1 = new FormData();
                formData1.append('files', blob);

                const apiUrl = `${WebApiUrl}/api/SmsTemplates/send-WhatsAppFile`;
                const response = await axios.post(apiUrl, formData1, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data', // Axios handles this automatically
                    },
                });
                console.log("Response:", response.data.isSuccess);
                return response.data;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
                if (error.response) {
                    console.error("Server Response:", error.response.data);
                }
                if (error.request) {
                    console.error("Request Error:", error.request);
                }
            }
        }
        return fetchData();
    },
};

export default SmsTemplate;
