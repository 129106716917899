import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Company = {

  CreateCompany: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/Company`;
      const response = await axios.post(
        apiUrl,
        {
          name: data.name,
          businessTypes: Number(data.businessTypes),
          countryId: 1101,
          stateId: 8,
          mobileNo: "",
          email: data.email || "test@gmail.com",
        },
        {
          //withCredentials: true,
        }
      );
      console.log(response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
    }
  },

  // **********************{Insert Company Setting}************************

  insertCreateCompanyMasterSetting: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/Company/CreateCompanyMasterSetting`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          additionalDetails: parseBoolean(data.additionalDetails),
          vatGstDetails: parseBoolean(data.vatGstDetails),
          upiPaymentDetails: parseBoolean(data.upiPaymentDetails),
          faxNo: parseBoolean(data.faxNo),
          loginThroughBranchCode: parseBoolean(data.loginThroughBranchCode),

        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getCompanyMasterSettings: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Company/GetCompanyMasterSettings`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
    }
  },

  GetCompany: async (q) => {
    console.log("get company");
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      const uniqueId = localStorage.getItem("CommonDeploymentUniqueId");
      try {
        // alert(WebApiUrl);
        const apiUrl = `${WebApiUrl}/api/Company/GetCompanies?UniqueId=${q || uniqueId}`;
        const response = await axios.post(
          apiUrl,
          {},
          // {
          //  // withCredentials: true,
          // }
        );
        console.log(response.data.result);
        // alert(response.data.result);

        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  updateCompany: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    let dataToSend = {
      id: Number(data.id),
      name: data.name,
      printName: data.printName,
      shortName: data.shortName,
      branchCode: data.branchCode,
      fySessionFrom: data.fySessionFrom,
      fySessionTo: data.fySessionTo,
      businessTypes: Number(data.businessTypes),
      defaultLanguage: Number(data.defaultLanguage),
      firmAddress1: data.firmAddress1,
      firmAddress2: data.firmAddress2,
      firmAddress3: data.firmAddress3,
      countryId: Number(data.countryId),
      stateId: Number(data.stateId),
      pinCode: data.pinCode,
      mobileNo: data.mobileNo,
      mobileNoForOTP: data.mobileNoForOTP,
      whatsappNo: data.whatsappNo,
      email: data.email,
      telephoneNo: data.telephoneNo,
      pan: data.pan,
      website: data.website,
      facebook: data.facebook,
      instagram: data.instagram,
      fax: data.fax,
      currencySymbol: data.currencySymbol,
      currencyString: data.currencyString,
      currencyUnit: data.currencyUnit,
      currencyDecimalPlaces: Number(data.currencyDecimalPlaces),
      dayIntervalForBackup: Number(data.dayIntervalForBackup),
      ecomMinShopLimit: Number(data.ecomMinShopLimit),
      enableVatGST: parseBoolean(data.enableVatGST),
      // vatGstType: data.vatGstType,
      enableAddTaxSchg: parseBoolean(data.enableAddTaxSchg),
      gstTinNo: data.gstTinNo,
      cstNo: data.cstNo,
      drugLicenseNo: data.drugLicenseNo,
      drugLicenseExpiryDate: data.drugLicenseExpiryDate,
      termsCondition: data.termsCondition,
      payeeName: data.payeeName,
      upiId: data.upiId,
      transferMsg: data.transferMsg,
      currencyCode: data.currencyCode,
      transferVoucherNo: data.transferVoucherNo,
      payAmt: Number(data.payAmt),
      urL1: data.urL1,
      urL2: data.urL2,
      isEnblMobOrdr: parseBoolean(data.isEnblMobOrdr),
      isAllowModifyCompany: parseBoolean(data.isAllowModifyCompany),
      isEnblPayFrmOrdrTakerApp: parseBoolean(data.isEnblPayFrmOrdrTakerApp),
      isEnableWebsite: parseBoolean(data.isEnableWebsite),
      isEnableHomeDelivery: parseBoolean(data.isEnableHomeDelivery),
      isEnableTblOrder: parseBoolean(data.isEnableTblOrder),
      isEnablePickUp: parseBoolean(data.isEnablePickUp),
      isEnblItmAvailTimeChk: parseBoolean(data.isEnblItmAvailTimeChk),
      isEnblItmAvailDateChk: parseBoolean(data.isEnblItmAvailDateChk),
      isEnblDlvryPnt: parseBoolean(data.isEnblDlvryPnt),
      isEnblTmSltPopupInHmPg: parseBoolean(data.isEnblTmSltPopupInHmPg),
      isEnblCnclOrdrFrmDlvryBoyApp: parseBoolean(data.isEnblCnclOrdrFrmDlvryBoyApp),
      isAllowTblOptionForGuest: parseBoolean(data.isAllowTblOptionForGuest),
      vatGstType: "",
      isAllowDlvryPreferCheck: parseBoolean(data.isAllowDlvryPreferCheck),
      isEnblAutoTransferToKitchen: parseBoolean(data.isEnblAutoTransferToKitchen),
      isShowBillAmtAfterOrderConfirm: parseBoolean(data.isShowBillAmtAfterOrderConfirm),
      isAllowDiffColorRunningOrder: parseBoolean(data.isAllowDiffColorRunningOrder),
      isCheckNoOfPersonBeforeOrdConfirm: parseBoolean(data.isCheckNoOfPersonBeforeOrdConfirm),
      canUsePineLabDevice: parseBoolean(data.canUsePineLabDevice),
      isEnableGoogleMap: parseBoolean(data.isEnableGoogleMap),
      isPOSConfirmNKotBtn: parseBoolean(data.isPOSConfirmNKotBtn),
      isVariationItmShowInCatPage: parseBoolean(data.isVariationItmShowInCatPage),
      isImmediateConfirmOrderAfterPlace: parseBoolean(data.isImmediateConfirmOrderAfterPlace),
      isEnblCnclItmFrmOTApp: parseBoolean(data.isEnblCnclItmFrmOTApp),
      showCurrentDateKOTList: parseBoolean(data.showCurrentDateKOTList),
    };
    console.log(dataToSend);
    try {
      const apiUrl = `${WebApiUrl}/api/Company?CompanyId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        dataToSend,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
    }
  },

  GetCompanyById: async (CompanyId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        console.log("get by id");
        const apiUrl = `${WebApiUrl}/api/Company?CompanyId=${CompanyId}`;
        const response = await axios.get(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  CompanyCreationAutoLogin: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, {
        compCode: "C0001",
        userName: "admin",
        password: "admin",
        comClientRefId: "string",
      }, {
        withCredentials: true,
      });
      return true

    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Authentication failed: Incorrect username or password");
      } else {
        alert("An error occurred during login. Please try again.");
      }
    }
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  },

  GetCompanyForLogin: async () => {
    console.log("get company");
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/UserInfo/GetCompanies`;
        const response = await axios.post(
          apiUrl,
          {},
          // {
          //  // withCredentials: true,
          // }
        );
        console.log(response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // ********************************* URL VALIDATION ********************************
  getLoginValidation: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Company/urlValidation`;
        const response = await axios.get(
          apiUrl,
          {},
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ********************************* Update Company Logo ********************************
  companyLogoUpload: async (data, companyId) => {
    console.log(data)
    try {
      // Assuming 'data' is a FormData object with appended fields
      const apiUrl = `${WebApiUrl}/api/Company/UpdateCompanyImage?companyId=${companyId}`;
      const response = await axios.post(apiUrl,
        data, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',  // Set Content-Type for FormData
        },
      });
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
    }
  },

  LoginThroughBranchCode: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Company/LoginThroughBranchCode`;
        const response = await axios.get(
          apiUrl
          // {
          //   withCredentials: true,
          // }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Is Client registered ***************************
  IsClientregistered: async (UniqueClientId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        let apiUrl;
        if (UniqueClientId != null && UniqueClientId != undefined && UniqueClientId != '' && UniqueClientId != "null") {
          apiUrl = `${WebApiUrl}/api/Company/IsClientregistered?UniqueClientId=${UniqueClientId}`;
        }
        else {
          apiUrl = `${WebApiUrl}/api/Company/IsClientregistered`;
        }
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Is Common Deployment ***************************
  IsCommonDeployment: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Company/IsCommonDeployment`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // *************************  Request Otp For User Registration ***************************
  RequestOtpForUserRegistration: async (EmailId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Company/RequestOtpForUserRegistration`;
        const response = await axios.post(
          apiUrl,
          { EmailId: EmailId },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // *************************  Validate Otp For Registration ***************************
  ValidateOtpForRegistration: async (EmailId, otp) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Company/ValidateOtpForRegistration`;
        const response = await axios.post(
          apiUrl,
          {
            EmailId: EmailId,
            otp: otp,
          },
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },


  // *************************  Validate Otp For Registration ***************************
  CreateLoginThroughBranchCodeSetting: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Company/CreateLoginThroughBranchCodeSetting`;
      const response = await axios.post(
        apiUrl,
        { data },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
        // window.location.href = "/loginpanel";
      }
    }
  },

  GetLoginThroughBranchCodeSetting: async (UniqueClientId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Company/GetLoginThroughBranchCodeSetting?UniqueClientId=${UniqueClientId}`;
        const response = await axios.get(
          apiUrl

        );
        console.log("Response:", response.data);
        return response.data;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
          // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

};
export default Company;
