import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import MasterReportDataTable from "../../Master/TableMaster/MasterReportDataTable";
import ReportTableButton from "../../Props/ReportTableButton";
import ItemMaster from "../../Master/InvertoryMaster/ItemMaster";
import AccountMaster1 from "../../Master/AccountMaster/AccountMaster";
import ItemMasterApi from "../../../API/APIServices/ItemMaster";
import { useNavigate } from "react-router-dom";
import AccountGroupMaster from "../../Master/AccountMaster/AccountGroupMaster";
import ItemGroupMaster from "../../Master/InvertoryMaster/ItemGroupMaster";
import UnitMaster from "../../Master/InvertoryMaster/UnitMaster";

function MasterListReportLedger(props) {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    input8,
  } = useContext(MyContext);

  // const {
  //     inputValues,
  //   } = props;

  // const { selectedDateFilter } = props;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [columns, setColumns] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [totalValue, setTotalValue] = useState([]);
  const [finalresult, setfinalresult] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [calculatedTotals, setCalculatedTotals] = useState({});
  const [reportAttachTo, setReportAttachTo] = useState("");

  const [searchAnyText, setSearchAnyText] = useState(false);
  const [searchByDate, setSearchByDate] = useState(false);
  const [printButton, setPrintButton] = useState(false);
  const [excelButton, setExcelButton] = useState(false);
  const [searchByColumn, setSearchByColumn] = useState(false);
  const [rowWiseEdit, setRowWiseEdit] = useState(false);
  const [rowWisePrint, setRowWisePrint] = useState(false);
  const [rowWiseWhatsapp, setRowWiseWhatsapp] = useState(false);
  const [rowWiseDelete, setRowWiseDelete] = useState(false);
  const [shareButton, setShareButton] = useState(false);
  const [columnShowTotalValue, setColumnShowTotalValue] = useState(false);
  const [firmNameSelect, setFirmNameSelect] = useState(false);
  const [transactionType, setTransactionType] = useState(false);
  const [billSeries, setBillSeries] = useState(false);
  const [reportConfig, setReportConfig] = useState([]);

  const [masterId, setMasterId] = useState(0);
  const [templateList, setTemplateList] = useState([]);
  const [filterValues, setFilterValues] = React.useState({});
  const [searchQueries, setSearchQueries] = useState({});
  const [universalSearch, setUniversalSearch] = useState("");

  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [yesterdayDate, setYesterdayDate] = useState("");

  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [heading, setHeading] = useState("");
  const [masters, setMasters] = useState([]);
  const [queryData, setQueryData] = useState("");
  // console.log(queryData);

  console.log(customStartDate);
  console.log(customEndDate);

  // Filter data based on search queries
  useEffect(() => {
    console.log(data1);
  }, [data1]);

  // const handleKeyDown = (e) => {
  //     const { name, value } = e.target;

  //     if (e.key === 'Enter') {
  //         e.preventDefault();
  //         setSearchQueries(prevQueries => ({
  //             ...prevQueries,
  //             [name]: value
  //         }));
  //         if (value.trim() === '') {
  //             fetchData();
  //         }
  //     }
  // };

  const [filtercondition, setfiltercondition] = useState([]);
  const handleKeyDown = async (e) => {
    //debugger;
    // console.log(filter)
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;

      if (!value) {
        fetchDataTemp();
        setfiltercondition([]);
        return;
      }

      // let filterData = [{
      //     fieldName: "Name",
      //     operatorName: "like",
      //     compareValue: "testacc",
      // }, {
      //     fieldName: "AAddress",
      //     operatorName: "like",
      //     compareValue: "bhilai",
      // }
      // ];

      setfiltercondition((prev) => [
        ...prev,
        {
          fieldName: name,
          operatorName: "like",
          compareValue: value,
        },
      ]);

      //debugger;
    }
  };

  const fetchfilter = async () => {
    try {
      //debugger;
      const response =
        await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
          queryData,
          filtercondition
        );
      console.log(response);
      listReport(response.table);
    } catch (error) {
      console.error("Error executing query:", error);
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [filtercondition]);

  const handleKeyDownUniversal = async (e, filterdata) => {
    //debugger;
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;
      console.log(filterdata);
      if (!value) {
        fetchDataTemp();
        return;
      }

      try {
        const finalResults = []; // Initialize a separate array to collect results

        for (let i = 0; i < filterdata.length; i++) {
          const item = filterdata[i];

          if (item.accessor.length === 0) {
            continue; // Skip this iteration if the accessor is empty
          }

          let filterData = [
            {
              fieldName: item.accessor,
              operatorName: "equal",
              compareValue: value,
            },
          ];

          try {
            // Await the result of the API call
            const response =
              await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
                queryData,
                filterData
              );

            // Collect the result
            finalResults.push(...response.table);
            console.log(response);
          } catch (apiError) {
            // Handle errors specific to the API call
            console.error("Error executing query for item:", apiError);
          }
        }

        // After the loop, log and process the final results
        console.log(finalResults);
        listReport(finalResults);
      } catch (error) {
        // Handle errors that occur outside of the loop
        console.error("Error executing query:", error);
      }
    }
  };

  // const handleDateFilterClick = (filter) => {
  //     setSelectedDateFilter(filter);
  //     showdatefilter();
  //     fetchData();
  // };

  // Update custom dates
  const handleCustomDateChange = async (e, filterData) => {
    //debugger;
    const { id, value, textContent } = e.target;
    if (id === "start-date") {
      setCustomStartDate(value);
    } else if (id === "end-date") {
      setCustomEndDate(value);
    }

    // --------------------changes --------------------

    if (!value && !textContent) {
      fetchDataTemp();
      return;
    }

    if (customStartDate && customEndDate && textContent == "Apply") {
      let filterDataa = {
        or: [
          {
            and: [
              {
                fieldName: "createdOn",
                operatorName: "between",
                compareValue: `${customStartDate},${customEndDate}`,
              },
              {
                fieldName: "mastertype",
                operatorName: "equal",
                compareValue: "4",
              },
            ],
          },
        ],
      };

      try {
        const response =
          await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
            queryData,
            filterDataa
          );
        console.log(response);
        listReport(response.table);
      } catch (error) {
        console.error("Error executing query:", error);
      }
    }
    // }
    console.log(filterData);
  };

  useEffect(() => {
    fetchDataTemp();
  }, [queryData, selectedDateFilter]);

  const getDateRange = (filter) => {
    //debugger;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    if (!startDate || !endDate) {
      console.warn("Start date and end date must be set to fetch data.");
      setLoading(false);
      return;
    }

    switch (filter) {
      case "Today":
        // startDate = endDate = today;
        // startDate.setHours(0, 0, 0, 0);
        // endDate.setHours(23, 59, 59, 999);
        break;
      case "Yesterday":
        startDate.setDate(today.getDate() - 1);
        endDate.setDate(today.getDate() - 1);
        // startDate = endDate = new Date(today.setDate(today.getDate() - 1));
        // startDate.setHours(0, 0, 0, 0);
        // endDate.setHours(23, 59, 59, 999);
        break;
      case "This Week":
        // startDate.setDate(today.getDate() - today.getDay());
        startDate.setDate(today.getDate() - today.getDay());
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Last 7 Days":
        // startDate.setDate(today.getDate() - 6);
        startDate.setDate(today.getDate() - 6);
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "This Month":
        // startDate.setDate(1);
        startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Set startDate to the first day of this month
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Previous Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Custom":
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
        break;
      default:
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
    }

    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
    }
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
    }

    return { startDate, endDate };
  };

  const parseCustomDate = (dateString) => {
    // Convert "2024-04-27 11:00:06.490854" to "2024-04-27T11:00:06.490Z"
    return new Date(dateString.replace(" ", "T") + "Z");
  };

  const [pagecount, setpagecount] = useState(1);
  const fetchDataTemp = async () => {
    try {
      debugger;
      setLoading(true);
      console.log(selectedDateFilter);
      const url = window.location.href;
      const id = url.split("?")[1];

      const filterData = [
        {
          fieldName: "reportAttachTo",
          operatorName: "Equal",
          compareValue: id,
        },
      ];
      const tempdata = await TemplateSettingApi.getTemplateSettingFilter(
        filterData
      );
      console.log(tempdata);
      // [1].templateSettingDTO.pagination

      // setTemplateList(tempdata);
      const FilterTempdata = tempdata.find(
        (item) => item.templateSettingDTO.reportType == 1
      );
      console.log(FilterTempdata.templateSettingDTO.pagination);
      var masterid = FilterTempdata.templateSettingDTO.id;
      console.log(masterid);
      setpagecount(FilterTempdata.templateSettingDTO.pagination);
      setReportAttachTo(id);
      setLoading(false);

      const response = await TemplateSettingApi.getTemplateSettingById(
        masterid
      );
      // alert(id[1])
      console.log(id);
      console.log(response);

      //debugger;
      setTemplateData(response);
      setQueryData(response.templateSettingDTO.queryForSql);
      setSearchAnyText(response.templateSettingDTO.search_AnyText);
      setSearchByDate(response.templateSettingDTO.search_ByDate);
      setPrintButton(response.templateSettingDTO.printButton);
      setExcelButton(response.templateSettingDTO.excelButton);
      setSearchByColumn(response.templateSettingDTO.search_ByColumn);
      setRowWiseEdit(response.templateSettingDTO.rowWise_Edit);
      setRowWisePrint(response.templateSettingDTO.rowWise_Print);
      setRowWiseWhatsapp(response.templateSettingDTO.rowWise_Whatsapp);
      setRowWiseDelete(response.templateSettingDTO.rowWise_Delete);
      setFirmNameSelect(response.templateSettingDTO.firmNameSelect);
      setTransactionType(response.templateSettingDTO.transactionTypeSelect);
      setBillSeries(response.templateSettingDTO.billSeriesNameSelect);

      const defaultColumns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
      ];

      const dynamicColumns = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
            .filter((column) => column.showOrHide)
            .map((column) => ({
              Header:
                column.columnNameByUser.trim() !== ""
                  ? column.columnNameByUser
                  : column.columnName,
              accessor: column.columnName.toLowerCase(),
              priority: parseInt(column.col1, 10) || 0,
            }))
        : [];

      const sortedDynamicColumns = dynamicColumns.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedColumns = [
        ...defaultColumns,
        // ...dynamicColumns,
        ...sortedDynamicColumns,
        { Header: "ACTION", accessor: "action" },
      ];

      setColumns(combinedColumns);
      console.log(combinedColumns);

      const defaultFilterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
      ];
      //debugger;
      const dynamicFilterData = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
            .filter((column) => column.showOrHide)
            .map((filter, index) => ({
              Header: (
                <input
                  type="text"
                  name={filter.columnName}
                  placeholder={filter.columnNameByUser}
                  // onChange={handleChange}
                  onKeyDown={(e) => handleKeyDown(e, filter)}
                />
              ),
              // accessor: filter.accessor || `dynamic_filter_${index}`,

              accessor:
                filter.columnName.toLowerCase() || `dynamic_filter_${index}`,
              priority: parseInt(filter.col1, 10) || 0,
            }))
        : [];

      const sortedDynamicFilterData = dynamicFilterData.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedFilterData = [
        ...defaultFilterData,
        // ...dynamicFilterData,
        ...sortedDynamicFilterData,
      ];

      setFilterData(combinedFilterData);
      console.log(combinedFilterData);

      fetchData(
        response.templateSettingDTO.rowWise_Edit,
        response.templateSettingDTO.rowWise_Delete,
        response.templateSettingDTO.rowWise_Whatsapp,
        response.templateSettingDTO.rowWise_Print,
        response.templateSettingDTO.queryForSql,
        response.templateSettingReportConfigList
      );
      setReportConfig(response.templateSettingReportConfigList);

      // -----------------filter based on selected date------------------
      if (selectedDateFilter.length != 0) {
        //debugger;
        const date = getDateRange(selectedDateFilter);
        const startDate = new Date(date.startDate);
        const endDate = new Date(date.endDate);
        const utcStartDate = new Date(startDate.toISOString());
        const utcEndDate = new Date(endDate.toISOString());
        console.log(utcStartDate);
        const formatDate = (date) => {
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, "0");
          const day = String(date.getUTCDate()).padStart(2, "0");
          // const hours = String(date.getUTCHours()).padStart(2, '0');
          // const minutes = String(date.getUTCMinutes()).padStart(2, '0');
          // const seconds = String(date.getUTCSeconds()).padStart(2, '0');
          // const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

          return `${year}-${month}-${day}`;
        };

        const startdate_apiform = formatDate(utcStartDate);
        const enddate_apiform = formatDate(utcEndDate);

        console.log(startdate_apiform);
        console.log(enddate_apiform);

        // ----------------------call api --------------------
        let filterDataa = {
          or: [
            {
              and: [
                {
                  fieldName: "createdOn",
                  operatorName: "between",
                  compareValue: `${startdate_apiform},${enddate_apiform}`,
                },
                {
                  fieldName: "mastertype",
                  operatorName: "equal",
                  compareValue: "4",
                },
              ],
            },
          ],
        };

        try {
          const response =
            await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
              queryData,
              filterDataa
            );
          console.log(response);
          listReport(response.table);
        } catch (error) {
          console.error("Error executing query:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataTemp();
  }, [selectedDateFilter]);

  const [PageNumber, setPageNumber] = useState(1);
  const fetchData = async (
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    queryData,
    combinedTotalValue,
    filter = selectedDateFilter,
    customStart = customStartDate,
    customEnd = customEndDate
  ) => {
    //debugger;
    setLoading(true);

    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    let filterData = [
      /* {
                       fieldName: "mastertype",
                       operatorName: "equal",
                       compareValue: reportAttachTo
                   },
                   {
                       fieldName: "isdeleted",
                       operatorName: "equal",
                       compareValue: "0"
                   }*/
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string",
      },
    ];
    // const { startDate, endDate } = getDateRange(filter, customStartDate, customEndDate);

    let { startDate, endDate } = getDateRange(filter);
    if (customStart && customEnd) {
      startDate = new Date(customStart);
      endDate = new Date(customEnd);
    }

    try {
      // let pagesize = 60
      const val = await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
        queryData,
        filterData,
        pagecount,
        PageNumber
      );
      console.log(val);
      //debugger;
      listReport(
        val.table,
        rowWiseEdit1,
        rowWiseDelete1,
        rowWiseWhatsapp1,
        rowWisePrint1,
        combinedTotalValue
      );
      // if (!val || !Array.isArray(val.table)) {
      //     console.error("API response does not contain a valid 'table' array.");
      //     setData1([]);
      //     return;
      // }

      // const columnNames = Object.keys(val.table[0] || {});
      // const columnMapping = columnNames.reduce((acc, column) => {
      //     acc[column.toLowerCase()] = column;
      //     return acc;
      // }, {});
    } catch (error) {
      console.log(error);
    }
  };

  console.log(PageNumber);
  const recall = async () => {
    //debugger;

    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    let filterData = [
      /* {
                       fieldName: "mastertype",
                       operatorName: "equal",
                       compareValue: reportAttachTo
                   },
                   {
                       fieldName: "isdeleted",
                       operatorName: "equal",
                       compareValue: "0"
                   }*/
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string",
      },
    ];
    console.log(templateData);
    const val = await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
      queryData,
      filterData,
      pagecount,
      PageNumber
    );
    listReport(val.table);
    console.log(val);
  };
  useEffect(() => {
    recall();
  }, [PageNumber]);

  const listReport = async (
    tableData,
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    combinedTotalValue
  ) => {
    //debugger;
    try {
      let filteredData = tableData;

      const newData = filteredData.map((item, i) => {
        //debugger;
        const dynamicProperties = Object.entries(item).reduce(
          (acc, [key, value]) => {
            acc[key.toLowerCase()] = value;
            return acc;
          },
          {}
        );

        return {
          input: (
            <input type="checkbox" onChange={() => handleCheckboxChange(i)} />
          ),
          sno: i + 1,
          // ...rest,
          ...dynamicProperties,
          action: (
            <div className="tablelist-action-box">
              {(rowWiseEdit1 || rowWiseEdit) && (
                <i
                  className="fa-regular fa-pen-to-square TableEditIcon"
                  onClick={() => editTemplateSetting(item)}
                ></i>
              )}
              {(rowWiseDelete1 || rowWiseDelete) && (
                <i
                  className="fa-regular fa-trash-can TableDelIcon"
                  onClick={() => deleteTemplateSetting(item)}
                ></i>
              )}
              {(rowWiseWhatsapp1 || rowWiseWhatsapp) && (
                <i
                  className="fa-brands fa-square-whatsapp TableWhatsappcon"
                  onClick={() => handleShare(item.id)}
                ></i>
              )}
              {(rowWisePrint1 || rowWisePrint) && (
                <i className="fa-solid fa-print TablePrint"></i>
              )}
            </div>
          ),
        };
      });

      console.log(newData);
      setData1(newData);
      console.log(combinedTotalValue);

      const dynamicTotalValue = Array.isArray(
        combinedTotalValue || reportConfig
      )
        ? (combinedTotalValue || reportConfig).map((total) => {
            const columnName = total.columnName.toLowerCase() || "";
            const columnSum = newData.reduce((sum, row) => {
              const value = parseFloat(row[columnName]);
              return !isNaN(value) ? sum + value : sum;
            }, 0);
            return {
              accessor: total.columnName.toLowerCase(),
              columnShowTotalValue: total.columnShowTotalValue,
              total: columnSum,
              priority: parseInt(total.col1, 10) || 0,
            };
          })
        : [];

      const sortedDynamicTotalValue = dynamicTotalValue.sort(
        (a, b) => a.priority - b.priority
      );

      setTotalValue(sortedDynamicTotalValue);
      console.log("Calculated Totals", sortedDynamicTotalValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //     fetchData();
  // }, [searchQueries, selectedDateFilter, customStartDate, customEndDate]);

  const [editData, setEditData] = useState(null);

  const editTemplateSetting = async (data) => {
    console.log(data);

    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1];

      let data1;
      //debugger;

      if (reportAttachTo == 2) {
        data1 = `/AccountMaster?${data.d1RefId}`;
      } else if (reportAttachTo == 4) {
        // data1 = `/ItemMaster?${data.id}`; // Adjust the path as needed
        data1 = null;
        const itemData = await ItemMasterApi.GetItemMasterById(data.id);
        setEditData(itemData);
        setReportAttachTo(4);
      } else if (reportAttachTo == 1) {
        data1 = `/AccountGroupMaster?${data.d1RefId}`; // Adjust the path as needed
      } else if (reportAttachTo == 3) {
        data1 = `/ItemGroupMaster?${data.id}`;
      } else if (reportAttachTo == 8) {
        data1 = `/UnitMaster?${data.id}`;
      }

      if (data1) {
        navigate(data1); // Redirect to the appropriate page
        console.log("Navigating to:", data1);
      }

      setMasterId(data);
      toggleSlider();
      setSliderOpen(true); // Open the slider
    } catch (error) {
      console.error("Error editing data:", error);
    }
  };

  const [getMasterType, setGetMasterType] = useState(null);
  const [getdeleteId, setDeleteId] = useState(null);

  // const deleteTemplateSetting = (data) => {
  //     //debugger
  //     setAlertBoxDelete(true);

  //     const url = window.location.href;
  //     const reportAttachTo = url.split('?')[1];

  //     if (reportAttachTo == 2) {
  //         setDeleteId(data.d1RefId);
  //     } else if (reportAttachTo == 4) {
  //         setDeleteId(data.id);
  //     }

  // }

  // const DeleteTemplateConfirm = async () => {
  //     setLoading(true);
  //     //debugger
  //     try {
  //         const url = window.location.href;
  //         const reportAttachTo = url.split('?')[1];

  //         if (reportAttachTo == 2) {
  //             const response = await AccountApi.getAccountById(getdeleteId);
  //             const d2Id = response.item1[0].d2Id
  //             const deleteResponse = await AccountApi.deleteAccount(getdeleteId, d2Id);
  //             if (deleteResponse) {
  //                 setAlertBoxDelete(false);
  //                 setDeleteId(null);
  //                 setLoading(false);
  //                 window.location.reload();
  //             } else {
  //                 alert("Error Deleting Data");
  //             }
  //             fetchData();
  //         } else if (reportAttachTo == 4) {
  //             const deleteResponse2 = await ItemMasterApi.deleteItemMaster(getdeleteId);

  //             if (deleteResponse2) {
  //                 setAlertBoxDelete(false);
  //                 setDeleteId(null);
  //                 setLoading(false);
  //                 window.location.reload();
  //             } else {
  //                 alert("Error Deleting Data");
  //             }

  //             fetchData();
  //         }

  //     } catch (error) {
  //         console.error("Error deleting data:", error);
  //     }
  // };

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    console.log(editData);
    setSliderOpen(!isSliderOpen);
  };

  const deleteTemplateSetting = (item) => {
    //debugger;
    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    setAlertBoxDelete(true);
    setGetMasterType(reportAttachTo);
    setDeleteId(item.id);
  };

  const DeleteTemplateConfirm = async () => {
    setLoading(true);
    //debugger;
    try {
      const val = await TemplateSettingApi.deleteTemplateSetting(
        getMasterType,
        getdeleteId
      );
      console.log(val);

      if (Boolean(val) === true) {
        //debugger;
        setAlertBoxDelete(false);
        setGetMasterType(null);
        setDeleteId(null);
        setLoading(false);
        fetchDataTemp();
      } else {
        alert("Error Deleting Data");
      }
      // fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find((item) => item.id === id);

    if (selectedItem) {
      setSelectedData((prevSelectedData) => {
        const isSelected = prevSelectedData.some((item) => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter((item) => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
  };

  const handleShare = (id) => {
    const listToSend = data1.find((item) => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      listToSend
    )}`;
    window.location.href = whatsappURL;
  };

  const [createData, setCreateData] = useState(null);
  console.log(createData);

  useEffect(() => {
    createTemplateSetting();
  }, []);

  const createTemplateSetting = async () => {
    //debugger;
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1]; // Get the reportAttachTo value

      let destinationUrl;

      if (reportAttachTo == 2) {
        destinationUrl = "/AccountMaster"; // Set URL for AccountMaster page
      } else if (reportAttachTo == 4) {
        destinationUrl = "/ItemMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 1) {
        destinationUrl = "/AccountGroupMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 3) {
        destinationUrl = "/ItemGroupMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 8) {
        destinationUrl = "/UnitMaster"; // Set URL for ItemMaster page
      }

      if (destinationUrl) {
        setCreateData(destinationUrl);
      }
    } catch (error) {
      console.error("Error creating template setting:", error);
    }
  };

  const fetchData1 = async () => {
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1];
      const response = await TemplateSettingApi.getAllMasterType();
      if (response && Array.isArray(response)) {
        const matchingItem = response.find((item) => item.id == reportAttachTo);

        if (matchingItem) {
          setHeading(matchingItem.name);
        } else {
          console.error(
            "No matching master type found for reportAttachTo:",
            reportAttachTo
          );
        }
      } else {
        console.error("Error:", response.errorMessages);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData1(reportAttachTo);
  }, [reportAttachTo]);

  // const headings = {
  //     '1': 'Account Group Master List',
  //     '2': 'Account Master List',
  //     '3': 'Item Group Master List',
  //     '4': 'Item Master List',

  // };

  // const heading = headings[reportAttachTo] || 'Master List Report';

  return (
    <div className="">
      <PageTabs
        locationTab={"/MasterReport"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">{heading} List</h2>
          <ReportTableButton
            createTemplateSetting={createTemplateSetting}
            createData={createData}
            filterData={filterData}
            columns={columns}
            data={data1}
            selectedData={selectedData}
            printButton={printButton}
            excelButton={excelButton}
            shareButton={shareButton}
          />
        </div>
        <MasterReportDataTable
          PageNumber={PageNumber}
          setPageNumber={setPageNumber}
          setSelectedDateFilter={setSelectedDateFilter}
          pagecount={pagecount}
          fetchData={fetchData}
          filterData={filterData}
          columns={columns}
          totalValue={totalValue}
          data={data1}
          searchAnyText={searchAnyText}
          searchByDate={searchByDate}
          searchByColumn={searchByColumn}
          columnShowTotalValue={columnShowTotalValue}
          calculatedTotals={calculatedTotals}
          tableData={tableData}
          universalSearch={universalSearch}
          handleKeyDownUniversal={handleKeyDownUniversal}
          setUniversalSearch={setUniversalSearch}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          handleCustomDateChange={handleCustomDateChange}
          fetchDataTemp={fetchDataTemp}
          onDateFilterClick={setSelectedDateFilter}
          firmNameSelect={firmNameSelect}
          transactionType={transactionType}
          billSeries={billSeries}
        />
      </div>
      {/******************************* Unit Master Modal*************************/}
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        {/* <div style={{ padding: "15px" }}>
                <MasterReport editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} 
                reportAttachTo={reportAttachTo == 2 ? AccountMaster1 : (reportAttachTo == 4 ? ItemMaster : null)}
                />
                </div> */}
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 2 && (
            <AccountMaster1
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 4 && (
            <ItemMaster
              data={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData} //data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 1 && (
            <AccountGroupMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 3 && (
            <ItemGroupMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 8 && (
            <UnitMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
      </div>
      {/* ********************************************** Mobile View ***************************** */}
      <div className="wrapper">
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListSaveButton />
            </div>
          </div>
        </div>
      </div>
      {/* ***************************** */}
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteTemplateConfirm}
        />
      )}
      {/* {loading && <Loader />} */}
    </div>
  );
}
export default MasterListReportLedger;
