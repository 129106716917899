import axios from '../axiosConfig';
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

function parseBoolean(v) {
    if (typeof v === "boolean") {
        return v;
    } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
    } else {
        console.log("error", v);
        return false;
    }
}

const BillSeriesMaster = {

    insertBillSeriesMaster: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                masterType: Number(data.masterType),
                billSeriesType: true,
                billSeriesName: data.billSeriesName,
                parentGrpMasterType: 226,
                setDefaultSeriesForEcom: parseBoolean(data.setDefaultSeriesForEcom),
                isActive: true,
                isDefault: parseBoolean(data.isDefault),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getBillSeriesMaster: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillSeriesMaster/GetBillSeriesMasters`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Get bill series Filtered ***************************
    getBillSeriesMasterFiltered: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillSeriesMaster/GetBillSeriesMasters`;
                const response = await axios.post(apiUrl, {
                    filter: filteredData,
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    pageNumber: 0,
                    pageSize: 0
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteBillSeriesMaster: async (billSeriesId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${billSeriesId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get By Id ****************************
    getbillSeriesId: async (billSeriesId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${billSeriesId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *************************** Update ***************************

    updateBillSeriesMaster: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                masterType: Number(data.masterType),
                billSeriesType: Number(data.billSeriesType),
                billSeriesName: data.billSeriesName,
                isDefault: parseBoolean(data.isDefault),
                isActive: parseBoolean(data.isActive),
                setDefaultSeriesForEcom: parseBoolean(data.setDefaultSeriesForEcom),
                parentGrpMasterType: 226,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get bill series by masterType ***************************
    getBillSeriesMasterByMasterType: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillSeriesMaster/GetBillSeriesMastersByMasterType`;
                const response = await axios.post(apiUrl, {
                    filter: [
                        {
                            fieldName: "isDefault",
                            operatorName: "Equal",
                            compareValue: true
                        }
                    ],
                    order: [
                        {
                            propertyName: "string",
                            ascending: true
                        }
                    ],
                    masterType: 29,
                    pageNumber: 0,
                    pageSize: 0
                }, {
                    withCredentials: true,
                });
                console.log('Response:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

};

export default BillSeriesMaster;
