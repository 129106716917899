import NavBarTypes from "../Components/DraggableComponent/NavBarTypes";
import Footer from "../Components/DraggableComponent/Footer";
import Home from "../WebsitePages/Home";
import About from "../WebsitePages/About";
import Career from "../WebsitePages/Career";
import Contact from "../WebsitePages/Contact";
import { useState } from "react";

const WebsiteContext = (props) => {
  const [selectedTab, setSelectedTab] = useState("home");


  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  let content;

  switch (selectedTab) {
    case "home":
      content = <Home widthV={props.widthValue} />;
      break;
    case "about":
      content = <About widthV={props.widthValue} />;
      break;
    case "contact":
      content = <Contact widthV={props.widthValue} />;
      break;
    case "career":
      content = <Career widthV={props.widthValue} />;
      break;
    default:
      content = <Home widthV={props.widthValue} />;
  }

  return (
    <>
      {/* <NavBarTypes widthV={props.widthValue} selectedTab={selectedTab} onTabClick={handleTabClick} /> */}
      {content}
      {/* <Footer widthV={props.widthValue} /> */}
    </>
  );
};

export default WebsiteContext;
