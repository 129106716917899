import React from "react";

const PrintModal = ({
  isOpen,
  onClose,
  showFormat1 = true,
  showFormat2 = true,
  showSendMsg = true,
  showEmail = true,
  showBarcode = true,
}) => {
  if (!isOpen) return null;

  return (
    <div className="BillBar-modal">
      <div className="BillBar-modal-content">
        <div className="BillBar-modal-header">
          <h2>Confirm!</h2>
          <button onClick={onClose} className="BillBar-modal-close">
            ✖
          </button>
        </div>
        <p className="mt-2">Do you want to print voucher !!</p>
        <div className="BillBar-modal-buttons">
          {showBarcode && (
            <button className="BillBar-modal-button BillBar-modal-format1">
              BARCODE
            </button>
          )}
          {showFormat1 && (
            <button className="BillBar-modal-button BillBar-modal-format1">
              FORMAT 1
            </button>
          )}
          {showFormat2 && (
            <button className="BillBar-modal-button BillBar-modal-format2">
              FORMAT 2
            </button>
          )}
          {showSendMsg && (
            <button className="BillBar-modal-button BillBar-modal-sendmsg">
              SEND MESSAGE
            </button>
          )}
          {showEmail && (
            <button className="BillBar-modal-button BillBar-modal-email">
              E-MAIL
            </button>
          )}
        </div>
        <div className="mt-4" style={{ textAlign: "center" }}>
          <button onClick={onClose} className="BillBar-modal-close-btn">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintModal;
