import React, { useContext, useEffect, useState } from 'react'
import GlobalSelector from './GlobalSelector'

const DimensionSection = (props) => {

  const { value: currentWidthValue, unit: extractedWidthUnit } = props.extractValueAndUnit(props.currentStyle?.style["width"] || 'auto');
  const { value: currentHeightValue, unit: extractedHeightUnit } = props.extractValueAndUnit(props.currentStyle?.style["height"] || 'auto');
  const { value: maxWidthValue, unit: maxWidthUnit } = props.extractValueAndUnit(props.currentStyle?.style["max-width"] || 'auto');
  const { value: maxHeightValue, unit: maxHeightUnit } = props.extractValueAndUnit(props.currentStyle?.style["max-height"] || 'auto');
  const { value: minWidthValue, unit: minWidthUnit } = props.extractValueAndUnit(props.currentStyle?.style["min-width"] || 'auto');
  const { value: minHeightValue, unit: minHeightUnit } = props.extractValueAndUnit(props.currentStyle?.style["min-height"] || 'auto');
  const { value: marginTopValue, unit: marginTopUnit } = props.extractValueAndUnit(props.currentStyle?.style["margin-top"] || 'auto');
  const { value: marginBottomValue, unit: marginBottomUnit } = props.extractValueAndUnit(props.currentStyle?.style["margin-bottom"] || 'auto');
  const { value: marginLeftValue, unit: marginLeftUnit } = props.extractValueAndUnit(props.currentStyle?.style["margin-left"] || 'auto');
  const { value: marginRightValue, unit: marginRightUnit } = props.extractValueAndUnit(props.currentStyle?.style["margin-right"] || 'auto');
  const { value: paddingTopValue, unit: paddingTopUnit } = props.extractValueAndUnit(props.currentStyle?.style["padding-top"] || 'auto');
  const { value: paddingBottomValue, unit: paddingBottomUnit } = props.extractValueAndUnit(props.currentStyle?.style["padding-bottom"] || 'auto');
  const { value: paddingLeftValue, unit: paddingLeftUnit } = props.extractValueAndUnit(props.currentStyle?.style["padding-left"] || 'auto');
  const { value: paddingRightValue, unit: paddingRightUnit } = props.extractValueAndUnit(props.currentStyle?.style["padding-right"] || 'auto');

  let currentWidthUnit = extractedWidthUnit || '%';
  let currentHeightUnit = extractedHeightUnit || 'px';


  useEffect(() => {
    console.log("Width", currentWidthValue + "unit", currentWidthUnit);
    console.log("Height", currentHeightValue + "unit", currentHeightUnit);
  }, [currentHeightValue, currentWidthValue]);

  const handleMargin = () => {
    const marginValue = "auto";
    props.handleStyleChange("margin-top", marginValue, "", props.id);
    props.handleStyleChange("margin-bottom", marginValue, "", props.id);
    props.handleStyleChange("margin-left", marginValue, "", props.id);
    props.handleStyleChange("margin-right", marginValue, "", props.id);
  };


  // return (


  //   <div className="dimension-container">
  //     <div className="global-selectbutton">
  //       <GlobalSelector placeholder={"auto"} value={currentWidthValue} unit={currentWidthUnit} name={"width"} id={props.id} onChange={props.handleStyleChange} />
  //       <GlobalSelector placeholder={"auto"} value={currentHeightValue} unit={currentHeightUnit} name={"height"} id={props.id} onChange={props.handleStyleChange} />
  //     </div>
  //     <div className="global-selectbutton">
  //       <GlobalSelector placeholder={"auto"} value={maxWidthValue} unit={maxWidthUnit} name={"max-width"} id={props.id} onChange={props.handleStyleChange} />
  //       <GlobalSelector placeholder={"auto"} value={maxHeightValue} unit={maxHeightUnit} name={"max-height"} id={props.id} onChange={props.handleStyleChange} />
  //     </div>
  //     <div className="dimension-margin">
  //       <p className='genral-heading'>Margin</p>

  //       <div className="dimension-marginpaddingcomponent">
  //         <div className="global-selectbutton">
  //           <GlobalSelector placeholder={"auto"} value={marginTopValue} unit={marginTopUnit} name={"margin-top"} id={props.id} onChange={props.handleStyleChange} />
  //           <GlobalSelector placeholder={"auto"} value={marginBottomValue} unit={marginBottomUnit} name={"margin-bottom"} id={props.id} onChange={props.handleStyleChange} />
  //         </div>
  //         <div className="global-selectbutton">
  //           <GlobalSelector placeholder={"auto"} value={marginLeftValue} unit={marginLeftUnit} name={"margin-left"} id={props.id} onChange={props.handleStyleChange} />
  //           <GlobalSelector placeholder={"auto"} value={marginRightValue} unit={marginRightUnit} name={"margin-right"} id={props.id} onChange={props.handleStyleChange} />
  //         </div>
  //       </div>
  //     </div>
  //     <div className="dimension-margin">
  //       <p className='genral-heading'>Padding</p>

  //       <div className="dimension-marginpaddingcomponent">
  //         <div className="global-selectbutton">
  //           <GlobalSelector placeholder={"auto"} name={"padding-top"} id={props.id} onChange={props.handleStyleChange} />
  //           <GlobalSelector placeholder={"auto"} name={"padding-bottom"} id={props.id} onChange={props.handleStyleChange} />
  //         </div>
  //         <div className="global-selectbutton">
  //           <GlobalSelector placeholder={"auto"} name={"padding-left"} id={props.id} onChange={props.handleStyleChange} />
  //           <GlobalSelector placeholder={"auto"} name={"padding-right"} id={props.id} onChange={props.handleStyleChange} />
  //         </div>
  //       </div>
  //     </div>


  //   </div>

  // )

  return (
    <div className="dimension-container">
      <div className="global-selectbutton">
        <GlobalSelector placeholder="auto" value={currentWidthValue} unit={currentWidthUnit} name="width" id={props.id} onChange={props.handleStyleChange} />
        <GlobalSelector placeholder="auto" value={currentHeightValue} unit={currentHeightUnit} name="height" id={props.id} onChange={props.handleStyleChange} />
      </div>
      <div className="global-selectbutton">
        <GlobalSelector placeholder="auto" value={minWidthValue} unit={minWidthUnit} name="min-width" id={props.id} onChange={props.handleStyleChange} />
        <GlobalSelector placeholder="auto" value={maxWidthValue} unit={maxWidthUnit} name="max-width" id={props.id} onChange={props.handleStyleChange} />
      </div>
      <div className="global-selectbutton">
        <GlobalSelector placeholder="auto" value={minHeightValue} unit={minHeightUnit} name="min-height" id={props.id} onChange={props.handleStyleChange} />
        <GlobalSelector placeholder="auto" value={maxHeightValue} unit={maxHeightUnit} name="max-height" id={props.id} onChange={props.handleStyleChange} />
      </div>
      <div className="dimension-margin">
        <div className='tab-content-box'>
          <p className='genral-heading'>Margin</p>
          <button className='margin-button-main' onClick={handleMargin}>auto</button>
        </div>
        <div className="dimension-marginpaddingcomponent">
          <div className="global-selectbutton">
            <GlobalSelector placeholder="auto" value={marginTopValue} unit={marginTopUnit} name="margin-top" id={props.id} onChange={props.handleStyleChange} />
            <GlobalSelector placeholder="auto" value={marginBottomValue} unit={marginBottomUnit} name="margin-bottom" id={props.id} onChange={props.handleStyleChange} />
          </div>
          <div className="global-selectbutton">
            <GlobalSelector placeholder="auto" value={marginLeftValue} unit={marginLeftUnit} name="margin-left" id={props.id} onChange={props.handleStyleChange} />
            <GlobalSelector placeholder="auto" value={marginRightValue} unit={marginRightUnit} name="margin-right" id={props.id} onChange={props.handleStyleChange} />
          </div>
        </div>
      </div>
      <div className="dimension-margin">
        <p className='genral-heading'>Padding</p>
        <div className="dimension-marginpaddingcomponent">
          <div className="global-selectbutton">
            <GlobalSelector placeholder="auto" value={paddingTopValue} unit={paddingTopUnit} name="padding-top" id={props.id} onChange={props.handleStyleChange} />
            <GlobalSelector placeholder="auto" value={paddingBottomValue} unit={paddingBottomUnit} name="padding-bottom" id={props.id} onChange={props.handleStyleChange} />
          </div>
          <div className="global-selectbutton">
            <GlobalSelector placeholder="auto" value={paddingLeftValue} unit={paddingLeftUnit} name="padding-left" id={props.id} onChange={props.handleStyleChange} />
            <GlobalSelector placeholder="auto" value={paddingRightValue} unit={paddingRightUnit} name="padding-right" id={props.id} onChange={props.handleStyleChange} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DimensionSection
