import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import MasterReportDataTable from "../../Master/TableMaster/MasterReportDataTable";
import ReportTableButton from "../../Props/ReportTableButton";
import ItemMaster from "../../Master/InvertoryMaster/ItemMaster";
import AccountMaster1 from "../../Master/AccountMaster/AccountMaster";
import ItemMasterApi from "../../../API/APIServices/ItemMaster";
import { useLocation, useNavigate } from "react-router-dom";
import AccountGroupMaster from "../../Master/AccountMaster/AccountGroupMaster";
import ItemGroupMaster from "../../Master/InvertoryMaster/ItemGroupMaster";
import UnitMaster from "../../Master/InvertoryMaster/UnitMaster";
import PrintModal from "../../ModalBox/PrintModal";
import Transaction from "../../../API/APIServices/Transaction";

function MasterListReportFormat2(props) {
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    input8,
  } = useContext(MyContext);

  const navigate = useNavigate();

  const location = useLocation();
  const row = location.state?.row;

  const [PageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [columns, setColumns] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [totalValue, setTotalValue] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [calculatedTotals, setCalculatedTotals] = useState({});
  const [reportAttachTo, setReportAttachTo] = useState("");

  const [searchAnyText, setSearchAnyText] = useState(false);
  const [searchByDate, setSearchByDate] = useState(false);
  const [printButton, setPrintButton] = useState(false);
  const [excelButton, setExcelButton] = useState(false);
  const [searchByColumn, setSearchByColumn] = useState(false);
  const [rowWiseEdit, setRowWiseEdit] = useState(false);
  const [rowWisePrint, setRowWisePrint] = useState(false);
  const [rowWiseWhatsapp, setRowWiseWhatsapp] = useState(false);
  const [rowWiseDelete, setRowWiseDelete] = useState(false);
  const [shareButton, setShareButton] = useState(false);
  const [columnShowTotalValue, setColumnShowTotalValue] = useState(false);
  const [firmNameSelect, setFirmNameSelect] = useState(false);
  const [transactionType, setTransactionType] = useState(false);
  const [billSeries, setBillSeries] = useState(false);
  const [
    rowwiseArrowforNavigationtoAnotherPage,
    setRowwiseArrowforNavigationtoAnotherPage,
  ] = useState(false);
  const [rowwiseButtontoOpenPopupWindow, setRowwiseButtontoOpenPopupWindow] =
    useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [useDoubleClick, setUseDoubleClick] = useState(false);
  const [reportConfig, setReportConfig] = useState([]);

  const [masterId, setMasterId] = useState(0);
  const [universalSearch, setUniversalSearch] = useState("");

  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [heading, setHeading] = useState("");
  const [queryData, setQueryData] = useState("");

  const [reportType, setReportType] = useState(null);
  const [navigateTo, setNavigateTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  // const handleKeyDown = (e) => {
  //     const { name, value } = e.target;

  //     if (e.key === 'Enter') {
  //         e.preventDefault();
  //         setSearchQueries(prevQueries => ({
  //             ...prevQueries,
  //             [name]: value
  //         }));
  //         if (value.trim() === '') {
  //             fetchData();
  //         }
  //     }
  // };
  useEffect(() => {
    console.log("row", row);
  }, [row]);

  const [filtercondition, setfiltercondition] = useState([]);
  const GetcolName = (name) => {
    var slicedQuery = queryData
      .toLocaleLowerCase()
      .slice(
        queryData.toLocaleLowerCase().indexOf("select") + "select".length,
        queryData.toLocaleLowerCase().indexOf("from")
      );
    let arr = slicedQuery.split(",").map((item) => item.trim());

    var searchval =
      arr.find((item) => {
        let parts = item.split(".");
        let columnName = parts.length > 1 ? parts[1].trim() : parts[0].trim();
        return columnName.toLowerCase() === name.toLowerCase();
      }) || null;

    if (!searchval) {
      let wildcardTable = arr.find((item) => item.includes(".*"));
      if (wildcardTable) {
        let tableName = wildcardTable.split(".*")[0].trim();
        searchval = `${tableName}.${name}`;
      } else {
        var searchval =
          arr.find((item) => {
            if (name.toLowerCase() === "id") {
              let parts = item.split(".");
              let columnName =
                parts.length > 1 ? parts[1].trim() : parts[0].trim();
              return columnName.toLowerCase() === "id";
            } else {
              return item.toLowerCase().includes(name.toLowerCase());
            }
          }) || name;
      }
    }

    if (searchval) {
      searchval = searchval.replace(/\s+AS\s+.*/i, "").trim();
    }
    return searchval;
  };
  const handleKeyDown = async (e) => {
    debugger;
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;

      if (!value) {
        fetchDataTemp();
        setfiltercondition([]);
        return;
      }
      var nm = GetcolName(name);
      console.log("nm", nm);
      // let filterData = [{
      //     fieldName: "Name",
      //     operatorName: "like",
      //     compareValue: "testacc",
      // }, {
      //     fieldName: "AAddress",
      //     operatorName: "like",
      //     compareValue: "bhilai",
      // }
      // ];
      const isNumeric = !isNaN(value) && !isNaN(parseFloat(value));
      setfiltercondition([
        {
          fieldName: GetcolName(name),
          operatorName: isNumeric ? "equal" : "like",
          compareValue: value,
        },
      ]);
    }
  };
  const recall = async () => {
    //debugger;

    let filterData =
      Array.isArray(filtercondition) && filtercondition.length > 0
        ? filtercondition
        : [
          {
            fieldName: "string",
            operatorName: "string",
            compareValue: "string",
          },
        ];

    console.log(templateData);
    const val = await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
      queryData,
      filterData,
      PageNumber,
      pagecount,
      reportType
    );
    listReport(val.table);
    console.log(val);
  };
  useEffect(() => {
    recall();
  }, [PageNumber]);

  const fetchfilter = async () => {
    try {
      debugger;
      const response =
        await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
          queryData,
          filtercondition,
          PageNumber,
          pagecount, //pagination value
          templateData.templateSettingDTO.reportType
        );
      console.log(response);
      listReport(response.table);
      setTotalRecords(response.table[0].totalRecords);
    } catch (error) {
      console.error("Error executing query:", error);
    }
  };

  useEffect(() => {
    fetchfilter();
  }, [templateData, filtercondition]);

  const handleKeyDownUniversal = async (e, filterdata) => {
    //debugger;
    if (e.key === "Enter") {
      e.preventDefault();

      const { name, value } = e.target;
      console.log(filterdata);
      if (!value) {
        fetchDataTemp();
        return;
      }

      try {
        const finalResults = [];

        for (let i = 0; i < filterdata.length; i++) {
          const item = filterdata[i];

          if (item.accessor.length === 0) {
            continue;
          }
          const isNumeric = !isNaN(value) && !isNaN(parseFloat(value));
          let filterData = [
            {
              fieldName: GetcolName(item.accessor),
              operatorName: isNumeric ? "equal" : "like",
              compareValue: value,
            },
          ];

          try {
            // Await the result of the API call
            const response =
              await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
                queryData,
                filterData,
                PageNumber,
                pagecount,
                templateData.templateSettingDTO.reportType
              );

            // Collect the result
            finalResults.push(...response.table);
            console.log(response);
            setTotalRecords(response.table[0].totalRecords);
          } catch (apiError) {
            // Handle errors specific to the API call
            console.error("Error executing query for item:", apiError);
          }
        }

        // After the loop, log and process the final results
        console.log(finalResults);
        listReport(finalResults);
      } catch (error) {
        // Handle errors that occur outside of the loop
        console.error("Error executing query:", error);
      }
    }
  };

  // const handleDateFilterClick = (filter) => {
  //     setSelectedDateFilter(filter);
  //     showdatefilter();
  //     fetchData();
  // };

  // Update custom dates
  const handleCustomDateChange = async (e, filterData) => {
    //debugger;
    const { id, value, textContent } = e.target;
    if (id === "start-date") {
      setCustomStartDate(value);
    } else if (id === "end-date") {
      setCustomEndDate(value);
    }

    // --------------------changes --------------------

    if (!value && !textContent) {
      fetchDataTemp();
      return;
    }

    if (customStartDate && customEndDate && textContent == "Apply") {
      let filterDataa = {
        or: [
          {
            and: [
              {
                fieldName: "createdOn",
                operatorName: "between",
                compareValue: `${customStartDate},${customEndDate}`,
              },
              {
                fieldName: "mastertype",
                operatorName: "equal",
                compareValue: "4",
              },
            ],
          },
        ],
      };

      try {
        const response =
          await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
            queryData,
            filterDataa,
            PageNumber,
            pagecount,
            templateData.templateSettingDTO.reportType
          );
        console.log(response);
        listReport(response.table);
        setTotalRecords(response.table[0].totalRecords);
      } catch (error) {
        console.error("Error executing query:", error);
      }
    }
    // }
    console.log(filterData);
  };

  useEffect(() => {
    fetchDataTemp();
  }, [queryData, selectedDateFilter]);

  const getDateRange = (filter) => {
    //debugger;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    if (!startDate || !endDate) {
      console.warn("Start date and end date must be set to fetch data.");
      setLoading(false);
      return;
    }

    switch (filter) {
      case "Today":
        // startDate = endDate = today;
        // startDate.setHours(0, 0, 0, 0);
        // endDate.setHours(23, 59, 59, 999);
        break;
      case "Yesterday":
        startDate.setDate(today.getDate() - 1);
        endDate.setDate(today.getDate() - 1);
        // startDate = endDate = new Date(today.setDate(today.getDate() - 1));
        // startDate.setHours(0, 0, 0, 0);
        // endDate.setHours(23, 59, 59, 999);
        break;
      case "This Week":
        // startDate.setDate(today.getDate() - today.getDay());
        startDate.setDate(today.getDate() - today.getDay());
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Last 7 Days":
        // startDate.setDate(today.getDate() - 6);
        startDate.setDate(today.getDate() - 6);
        startDate.setHours(0, 0, 0, 0); // Start of the day
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "This Month":
        // startDate.setDate(1);
        startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Set startDate to the first day of this month
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Previous Month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); // End of the day
        break;
      case "Custom":
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
        break;
      default:
        startDate = customStartDate ? new Date(customStartDate) : null;
        endDate = customEndDate ? new Date(customEndDate) : null;
    }

    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
    }
    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
    }

    return { startDate, endDate };
  };

  const parseCustomDate = (dateString) => {
    // Convert "2024-04-27 11:00:06.490854" to "2024-04-27T11:00:06.490Z"
    return new Date(dateString.replace(" ", "T") + "Z");
  };
  const [transActionList, setTransActionList] = useState([]);
  const [pagecount, setpagecount] = useState(1);
  const fetchDataTemp = async () => {
    try {
      debugger;
      setLoading(true);
      console.log(selectedDateFilter);
      const url = window.location.href;
      const id = url.split("?")[1];

      const filterData = [
        {
          fieldName: "reportAttachTo",
          operatorName: "Equal",
          compareValue: id,
        },
      ];
      const tempdata = await TemplateSettingApi.getTemplateSettingFilter(
        filterData
      );
      console.log(tempdata);
      const FilterTempdata = tempdata.find(
        (item) => item.templateSettingDTO.reportType == 4
      );
      console.log(FilterTempdata.templateSettingDTO.pagination);
      var masterid = FilterTempdata.templateSettingDTO.id;
      console.log(masterid);
      setpagecount(FilterTempdata.templateSettingDTO.pagination);
      setReportAttachTo(id);
      setLoading(false);

      const response = await TemplateSettingApi.getTemplateSettingById(
        masterid
      );
      // alert(id[1])
      console.log(id);
      console.log(response);

      debugger;
      setTemplateData(response);
      setQueryData(response.templateSettingDTO.queryForSql);
      setSearchAnyText(response.templateSettingDTO.search_AnyText);
      setSearchByDate(response.templateSettingDTO.search_ByDate);
      setPrintButton(response.templateSettingDTO.printButton);
      setExcelButton(response.templateSettingDTO.excelButton);
      setSearchByColumn(response.templateSettingDTO.search_ByColumn);
      setRowWiseEdit(response.templateSettingDTO.rowWise_Edit);
      setRowWisePrint(response.templateSettingDTO.rowWise_Print);
      setRowWiseWhatsapp(response.templateSettingDTO.rowWise_Whatsapp);
      setRowWiseDelete(response.templateSettingDTO.rowWise_Delete);
      setFirmNameSelect(response.templateSettingDTO.firmNameSelect);
      setTransactionType(response.templateSettingDTO.transactionTypeSelect);
      setBillSeries(response.templateSettingDTO.billSeriesNameSelect);
      setRowwiseArrowforNavigationtoAnotherPage(
        response.templateSettingDTO.rowWise_ArrowForOtherPage
      );
      setRowwiseButtontoOpenPopupWindow(
        response.templateSettingDTO.rowWise_TransporterOrOther
      );
      setUseDoubleClick(response.templateSettingDTO.srch1);

      setIsDropdownVisible(response.templateSettingDTO.srch2)

      setReportType(response.templateSettingDTO.reportType);
      setNavigateTo(response.templateSettingDTO.eF3);


      const defaultFilterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
      ];


      if (response.templateSettingDTO.srch2) {
        var transActionListData = await Transaction.GetTransactionTypes();
        if (transActionListData) {
          // setTransActionList(transActionListData);
          defaultFilterData.push({
            Header: (
              <select onChange={(e) => changetablevalue(e)} value={id}>
                {transActionListData.length > 0 ? (
                  <>  
                    {transActionListData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.MasterName}
                      </option>
                    ))}
                  </>
                ) : ("no data")}
              </select>
            ),
            accessor: "dropdown"
          });
        }

      }

      const defaultColumns = [
        { Header: <input type="checkbox" />, accessor: "input" },
        { Header: "SNO", accessor: "sno" },
      ];

      const dynamicColumns = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
          .filter((column) => column.showOrHide)
          .map((column) => ({
            Header:
              column.columnNameByUser.trim() !== ""
                ? column.columnNameByUser
                : column.columnName,
            accessor: column.columnName.toLowerCase(),
            priority: parseInt(column.col1, 10) || 0,
          }))
        : [];

      const sortedDynamicColumns = dynamicColumns.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedColumns = [
        ...defaultColumns,
        // ...dynamicColumns,
        ...sortedDynamicColumns,
        { Header: "ACTION", accessor: "action" },
      ];

      setColumns(combinedColumns);
      console.log(combinedColumns);


      //debugger;
      const dynamicFilterData = Array.isArray(
        response.templateSettingReportConfigList
      )
        ? response.templateSettingReportConfigList
          .filter((column) => column.showOrHide)
          .map((filter, index) => ({
            Header: (
              <input
                type="text"
                name={filter.columnName}
                placeholder={filter.columnNameByUser}
                // onChange={handleChange}
                onKeyDown={(e) => handleKeyDown(e, filter)}
              />
            ),
            // accessor: filter.accessor || `dynamic_filter_${index}`,

            accessor:
              filter.columnName.toLowerCase() || `dynamic_filter_${index}`,
            priority: parseInt(filter.col1, 10) || 0,
          }))
        : [];

      const sortedDynamicFilterData = dynamicFilterData.sort(
        (a, b) => a.priority - b.priority
      );

      const combinedFilterData = [
        ...defaultFilterData,
        // ...dynamicFilterData,
        ...sortedDynamicFilterData,
      ];

      setFilterData(combinedFilterData);
      console.log(combinedFilterData);

      fetchData(
        response.templateSettingDTO.rowWise_Edit,
        response.templateSettingDTO.rowWise_Delete,
        response.templateSettingDTO.rowWise_Whatsapp,
        response.templateSettingDTO.rowWise_Print,
        response.templateSettingDTO.queryForSql,
        response.templateSettingReportConfigList
      );
      setReportConfig(response.templateSettingReportConfigList);

      // -----------------filter based on selected date------------------
      if (selectedDateFilter.length != 0) {
        //debugger;
        const date = getDateRange(selectedDateFilter);
        const startDate = new Date(date.startDate);
        const endDate = new Date(date.endDate);
        const utcStartDate = new Date(startDate.toISOString());
        const utcEndDate = new Date(endDate.toISOString());
        console.log(utcStartDate);
        const formatDate = (date) => {
          const year = date.getUTCFullYear();
          const month = String(date.getUTCMonth() + 1).padStart(2, "0");
          const day = String(date.getUTCDate()).padStart(2, "0");
          // const hours = String(date.getUTCHours()).padStart(2, '0');
          // const minutes = String(date.getUTCMinutes()).padStart(2, '0');
          // const seconds = String(date.getUTCSeconds()).padStart(2, '0');
          // const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

          return `${year}-${month}-${day}`;
        };

        const startdate_apiform = formatDate(utcStartDate);
        const enddate_apiform = formatDate(utcEndDate);

        console.log(startdate_apiform);
        console.log(enddate_apiform);

        // ----------------------call api --------------------
        let filterDataa = {
          or: [
            {
              and: [
                {
                  fieldName: "createdOn",
                  operatorName: "between",
                  compareValue: `${startdate_apiform},${enddate_apiform}`,
                },
                {
                  fieldName: "mastertype",
                  operatorName: "equal",
                  compareValue: "4",
                },
              ],
            },
          ],
        };

        try {
          const response =
            await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
              queryData,
              filterDataa,
              PageNumber,
              pagecount,
              templateData.templateSettingDTO.reportType
            );
          console.log(response);
          listReport(response.table);
          setTotalRecords(response.table[0].totalRecords);
        } catch (error) {
          console.error("Error executing query:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataTemp();
  }, [selectedDateFilter]);

  const fetchData = async (
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    queryData,
    combinedTotalValue,
    filter = selectedDateFilter,
    customStart = customStartDate,
    customEnd = customEndDate
  ) => {
    //debugger;
    setLoading(true);



    let filterData = [
      /* {
                       fieldName: "mastertype",
                       operatorName: "equal",
                       compareValue: reportAttachTo
                   },
                   {
                       fieldName: "isdeleted",
                       operatorName: "equal",
                       compareValue: "0"
                   }*/
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string",
      },
    ];

    let { startDate, endDate } = getDateRange(filter);
    if (customStart && customEnd) {
      startDate = new Date(customStart);
      endDate = new Date(customEnd);
    }

    try {
      const val = await TemplateSettingApi.getDatasetExecuteQueryFilter_Ledger(
        queryData,
        filterData,
        PageNumber,
        pagecount,
        templateData.templateSettingDTO.reportType
      );
      console.log(val);
      //debugger;
      listReport(
        val.table,
        rowWiseEdit1,
        rowWiseDelete1,
        rowWiseWhatsapp1,
        rowWisePrint1,
        combinedTotalValue
      );
      setTotalRecords(val.table[0].totalRecords);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(PageNumber);

  const handleIconClick = (id) => {
    alert(id);
    const conditions = {
      1: () => alert("one"),
      2: () => alert("two"),
      3: () => alert("three"),
    };

    if (conditions[id]) {
      conditions[id]();
    } else {
      console.warn("No function found for this ID");
    }
  };

  const handleNavigation = (id, row) => {
    navigate(`/MasterListReportFormat2?${id}`, { state: { row } });
    // window.open(`/MasterListReportFormat2?${id}`, "_blank");
    window.location.reload();
  };
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const [billSettData, setBillSettData] = useState([]);
  // Function to open the modal
  const openModal = () => setIsPrintModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsPrintModalOpen(false);
  // const fetchBillSettingData = async () => {
  //   try {
  //     const response =
  //       await BillSeriesWiseTemplateSetting.GetAllBillSeriesWiseTemplateSetting();
  //     if (response.isSuccess) {
  //       console.log("response.Result", response.result);
  //       setBillSettData(response.result);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const changetablevalue = (e) => {
    const value = e.target.value;

    navigate(`/MasterListReportFormat2?${value}`);
  };

  const listReport = async (
    tableData,
    rowWiseEdit1,
    rowWiseDelete1,
    rowWiseWhatsapp1,
    rowWisePrint1,
    combinedTotalValue
  ) => {
    debugger;
    try {
      let filteredData = tableData;

      const newData = filteredData.map((item, i) => {
        //debugger;
        const dynamicProperties = Object.entries(item).reduce(
          (acc, [key, value]) => {
            acc[key.toLowerCase()] = value;
            return acc;
          },
          {}
        );

        return {
          input: (
            <input type="checkbox" onChange={() => handleCheckboxChange(i)} />
          ),
          sno: i + 1,
          ...dynamicProperties,
          action: (
            <div className="tablelist-action-box">

              {(rowWiseEdit1 || rowWiseEdit) && (
                <i
                  className="fa-regular fa-pen-to-square TableEditIcon"
                  onClick={() => editTemplateSetting(item)}
                ></i>
              )}
              {(rowWiseDelete1 || rowWiseDelete) && (
                <i
                  className="fa-regular fa-trash-can TableDelIcon"
                  onClick={() => deleteTemplateSetting(item)}
                ></i>
              )}
              {(rowWiseWhatsapp1 || rowWiseWhatsapp) && (
                <i
                  className="fa-brands fa-square-whatsapp TableWhatsappcon"
                  onClick={() => handleShare(item.id)}
                ></i>
              )}
              {(rowWisePrint1 || rowWisePrint) && (
                <i
                  className="fa-solid fa-print TablePrint"
                  onClick={openModal}
                ></i>
              )}
              {rowwiseArrowforNavigationtoAnotherPage && (
                <i
                  className="fa-solid fa-link TablePrint"
                  onClick={() => handleIconClick(item.Id || item.id)}
                ></i>
              )}
              {false && (
                <i
                  className="fa-solid fa-link TablePrint"
                  onClick={() => alert("popup")}
                ></i>
              )}
            </div>
          ),
        };
      });

      console.log(newData);
      setData1(newData);
      console.log(combinedTotalValue);

      const dynamicTotalValue = Array.isArray(
        combinedTotalValue || reportConfig
      )
        ? (combinedTotalValue || reportConfig).map((total) => {
          const columnName = total.columnName.toLowerCase() || "";
          const columnSum = newData.reduce((sum, row) => {
            const value = parseFloat(row[columnName]);
            return !isNaN(value) ? sum + value : sum;
          }, 0);
          return {
            accessor: total.columnName.toLowerCase(),
            columnShowTotalValue: total.columnShowTotalValue,
            total: columnSum,
            priority: parseInt(total.col1, 10) || 0,
          };
        })
        : [];

      const sortedDynamicTotalValue = dynamicTotalValue.sort(
        (a, b) => a.priority - b.priority
      );

      setTotalValue(sortedDynamicTotalValue);
      console.log("Calculated Totals", sortedDynamicTotalValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [editData, setEditData] = useState(null);

  const editTemplateSetting = async (data) => {
    console.log(data);

    try {
      debugger;
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1];
      const transactionId = data.id || data.Id || data.ID;
      let data1;

      if (transactionId) {
        const data = await Transaction.GetTransactionById(transactionId, reportAttachTo);
        // debugger;
        console.log(data);
        setEditData(data);
        // setRefID(data);
        // Navigate to the new page with state (passing refID)
        navigate(`/saleinvoice/${reportAttachTo}`, {
          state: { refID: data, isEdit: true, transId: transactionId },
        });
      }

      if (data1) {
        navigate(data1); // Redirect to the appropriate page
        console.log("Navigating to:", data1);
      }

      setMasterId(data);
      toggleSlider();
      setSliderOpen(true); // Open the slider
    } catch (error) {
      console.error("Error editing data:", error);
    }
  };

  const [getMasterType, setGetMasterType] = useState(null);
  const [getdeleteId, setDeleteId] = useState(null);
  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    console.log(editData);
    setSliderOpen(!isSliderOpen);
  };

  const deleteTemplateSetting = (item) => {
    const url = window.location.href;
    const reportAttachTo = url.split("?")[1];
    setAlertBoxDelete(true);
    setGetMasterType(reportAttachTo);
    setDeleteId(item.id);
  };
  const DeleteTransactionConfirm = async () => {
    setLoading(true);
    debugger;
    try {
      const val = await Transaction.DeleteTransactionData(getdeleteId);

      if (Boolean(val) === true) {
        await recall()
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      await recall()
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };
  const DeleteTemplateConfirm = async () => {
    setLoading(true);
    //debugger;
    try {
      const val = await TemplateSettingApi.deleteTemplateSetting(
        getMasterType,
        getdeleteId
      );
      console.log(val);

      if (Boolean(val) === true) {
        //debugger;
        setAlertBoxDelete(false);
        setGetMasterType(null);
        setDeleteId(null);
        setLoading(false);
        fetchDataTemp();
      } else {
        alert("Error Deleting Data");
      }
      // fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find((item) => item.id === id);

    if (selectedItem) {
      setSelectedData((prevSelectedData) => {
        const isSelected = prevSelectedData.some((item) => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter((item) => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
  };

  const handleShare = (id) => {
    const listToSend = data1.find((item) => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      listToSend
    )}`;
    window.location.href = whatsappURL;
  };

  const [createData, setCreateData] = useState(null);
  console.log(createData);

  useEffect(() => {
    createTemplateSetting();
  }, []);

  const createTemplateSetting = async () => {
    //debugger;
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1]; // Get the reportAttachTo value

      let destinationUrl;

      if (reportAttachTo == 2) {
        destinationUrl = "/AccountMaster"; // Set URL for AccountMaster page
      } else if (reportAttachTo == 4) {
        destinationUrl = "/ItemMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 1) {
        destinationUrl = "/AccountGroupMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 3) {
        destinationUrl = "/ItemGroupMaster"; // Set URL for ItemMaster page
      } else if (reportAttachTo == 8) {
        destinationUrl = "/UnitMaster"; // Set URL for ItemMaster page
      }

      if (destinationUrl) {
        setCreateData(destinationUrl);
      }
    } catch (error) {
      console.error("Error creating template setting:", error);
    }
  };

  const fetchData1 = async () => {
    try {
      const url = window.location.href;
      const reportAttachTo = url.split("?")[1];
      const response = await TemplateSettingApi.getAllMasterType();
      if (response && Array.isArray(response)) {
        const matchingItem = response.find((item) => item.id == reportAttachTo);

        if (matchingItem) {
          setHeading(matchingItem.name);
        } else {
          console.error(
            "No matching master type found for reportAttachTo:",
            reportAttachTo
          );
        }
      } else {
        console.error("Error:", response.errorMessages);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData1(reportAttachTo);
  }, [reportAttachTo]);


  return (
    <div className="">
      <PageTabs
        locationTab={"/MasterReport"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">{heading} List</h2>
          <ReportTableButton
            createTemplateSetting={createTemplateSetting}
            createData={createData}
            filterData={filterData}
            columns={columns}
            data={data1}
            selectedData={selectedData}
            printButton={printButton}
            excelButton={excelButton}
            shareButton={shareButton}
          />
        </div>
        <MasterReportDataTable
          PageNumber={PageNumber}
          setPageNumber={setPageNumber}
          setSelectedDateFilter={setSelectedDateFilter}
          pagecount={pagecount}
          fetchData={fetchData}
          filterData={filterData}
          columns={columns}
          totalValue={totalValue}
          data={data1}
          searchAnyText={searchAnyText}
          searchByDate={searchByDate}
          searchByColumn={searchByColumn}
          columnShowTotalValue={columnShowTotalValue}
          calculatedTotals={calculatedTotals}
          tableData={tableData}
          universalSearch={universalSearch}
          handleKeyDownUniversal={handleKeyDownUniversal}
          setUniversalSearch={setUniversalSearch}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          handleCustomDateChange={handleCustomDateChange}
          fetchDataTemp={fetchDataTemp}
          onDateFilterClick={setSelectedDateFilter}
          firmNameSelect={firmNameSelect}
          transactionType={transactionType}
          billSeries={billSeries}
          useDoubleClick={useDoubleClick}
          reportAttachTo={reportAttachTo}
          reportType={reportType}
          navigateTo={navigateTo}
          handleNavigation={handleNavigation}
          totalRecords={totalRecords}
        />
      </div>
      {/******************************* Unit Master Modal*************************/}
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        {/* <div style={{ padding: "15px" }}>
                <MasterReport editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} 
                reportAttachTo={reportAttachTo == 2 ? AccountMaster1 : (reportAttachTo == 4 ? ItemMaster : null)}
                />
                </div> */}
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 2 && (
            <AccountMaster1
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 4 && (
            <ItemMaster
              data={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData} //data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 1 && (
            <AccountGroupMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 3 && (
            <ItemGroupMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
        <div style={{ padding: "15px" }}>
          {reportAttachTo == 8 && (
            <UnitMaster
              editData={editData}
              getdeleteId={getdeleteId}
              modelOpen={true}
              setSliderCallBack={setSliderOpen}
              fetchData={fetchData}
              data={data1}
              masterId={masterId}
            />
          )}
        </div>
      </div>
      {/* ********************************************** Mobile View ***************************** */}
      <div className="wrapper">
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListSaveButton />
            </div>
          </div>
        </div>
      </div>
      {/* ***************************** */}
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteTransactionConfirm}
        />
      )}
      {/* {loading && <Loader />} */}
      <PrintModal
        isOpen={isPrintModalOpen}
        onClose={closeModal}
        showFormat1={true}
        showFormat2={true}
        showSendMsg={true}
        showEmail={true}
        showBarcode={true}
      />
    </div>
  );
}
export default MasterListReportFormat2;
