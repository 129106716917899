
import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUploadWithPreview from "../../Components/ImagePreview";

function PatientMaster() {
    /***********title***************/
    useState(() => {
        document.title = 'Patient Master';
    }, []);

   
    // //****************{control modal box 1}*********************//
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOptionsCredit, setSelectedOptionsCredit] = useState([]);
   
    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);
    const input24Ref = useRef(null);
    const input25Ref = useRef(null);
    const input26Ref = useRef(null);
    const input27Ref = useRef(null);
    const input28Ref = useRef(null);
    const input29Ref = useRef(null);
    const input30Ref = useRef(null);
    const input31Ref = useRef(null);
    const input32Ref = useRef(null);
    const input33Ref = useRef(null);
    const input34Ref = useRef(null);
    const input35Ref = useRef(null);
    const input36Ref = useRef(null);
    const input37Ref = useRef(null);
    const input38Ref = useRef(null);
    const input39Ref = useRef(null);
    const input40Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    //***************{From Date and To Date}***************//

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showTable, setShowTable] = useState(false);

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleSearchClick = () => {
        if (fromDate && toDate) {
            setShowTable(true);
        } else {
        }
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (checkboxValue) => {
        if (selectedOptionsCredit.includes(checkboxValue)) {
            setSelectedOptionsCredit(selectedOptionsCredit.filter((item) => item !== checkboxValue));
        } else {
            setSelectedOptionsCredit([...selectedOptionsCredit, checkboxValue]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectedOptionsCredit.length === 0) {
            const allOptions = ['checkBox1', 'checkBox2', 'checkBox3', 'checkBox4'];
            setSelectedOptionsCredit(allOptions);
        } else {
            setSelectedOptionsCredit([]);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);



    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Patient Master</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton SavePay={true} PrintIcon={true} />
            </div>

            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box tab-patient-top">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left1 patient-padding" >
                                        <div className="configure_radius">
                                            <div className="company_info-text-im td-1">Patient Info
                                            </div>
                                            <div className="">
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1 flex-pid-search">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input1Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Search PID</label>
                                                        <div>
                                                            <button className="pid-search-icon"><i class="fa-solid fa-magnifying-glass"></i></button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input1Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">PID <span style={{color: "red"}}>Last PID-44444</span></label>
                                                       
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat ">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input2Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="date" required />
                                                        <label class="UseMasterLebalFix">Reg. Date</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input3Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Patient Name</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input4Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Guard. Name</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input5Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="date" required />
                                                        <label class="UseMasterLebalFix">DOB</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input6Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text" required>
                                                            <option className="s-1 form-control" value="">Male</option>
                                                            <option className="s-1 form-control" value="optical">Female</option>
                                                            <option className="s-1 form-control" value="optical">Transgender</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">Gender</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input6Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text" required>
                                                            <option className="s-1 form-control" value="">Married</option>
                                                            <option className="s-1 form-control" value="">Unmarried</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">Marital status</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Aadhar No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">PAN No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Occupation</label>
                                                    </div>
                                                </div>
                                                {(() => {
                                                    var val = selectedOption ? input9Ref : input10Ref;
                                                    return (
                                                        <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                            <div className="input-box-mok1">
                                                                <select className="modify_im_input mode_category1 input-patient-box" ref={input8Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val)} type="text" required>
                                                                    <option className="s-1 form-control" value="">A+</option>
                                                                    <option className="s-1 form-control" value="optical">O+</option>
                                                                    <option className="s-1 form-control" value="optical">B+</option>
                                                                    <option className="s-1 form-control" value="optical">AB+</option>
                                                                    <option className="s-1 form-control" value="optical">A-</option>
                                                                    <option className="s-1 form-control" value="optical">O-</option>
                                                                    <option className="s-1 form-control" value="optical">B-</option>
                                                                    <option className="s-1 form-control" value="optical">AB-</option>
                                                                </select>
                                                                <label class="UseMasterLebalFix ">Blood Group</label>
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="company-info-top-left1 patient-padding" >
                                        <div className="configure_radius">
                                            <div className="company_info-text-im td-1">Address Info
                                            </div>
                                            <div className="">
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <textarea className="modify_im_textarea mode_category2 address-padding-patient" ref={input10Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input11Ref)} type="text" placeholder="" required />
                                                        <label class="modify_p_text-fix">Address</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input11Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Mobile No</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input12Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input13Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Pincode</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input13Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text" required>
                                                            <option className=" s-1 form-control" value="">Select Country</option>
                                                            <option className=" s-1 form-control" value="India">India</option>
                                                            <option className=" s-1 form-control" value="Afghanistan">Afghanistan</option>
                                                            <option className=" s-1 form-control" value="Åland Islands">Åland Islands</option>
                                                            <option className=" s-1 form-control" value="Albania">Albania</option>
                                                            <option className=" s-1 form-control" value="Algeria">Algeria</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">Country</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input14Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="text" required>
                                                            <option Classname="form-control s-1" value="">Select state</option>
                                                            <option Classname="form-control s-1" value="AN">Andaman and Nicobar Islands</option>
                                                            <option Classname="form-control s-1" value="AP">Andhra Pradesh</option>
                                                            <option Classname="form-control s-1" value="AR">Arunachal Pradesh</option>
                                                            <option Classname="form-control s-1" value="AS">Assam</option>
                                                            <option Classname="form-control s-1" value="BR">Bihar</option>
                                                            <option Classname="form-control s-1" value="CH">Chandigarh</option>
                                                            <option Classname="form-control s-1" value="CT">Chhattisgarh</option>
                                                            <option Classname="form-control s-1" value="DN">Dadra and Nagar Haveli</option>
                                                            <option Classname="form-control s-1" value="DD">Daman and Diu</option>
                                                            <option Classname="form-control s-1" value="DL">Delhi</option>
                                                            <option Classname="form-control s-1" value="GA">Goa</option>
                                                            <option Classname="form-control s-1" value="GJ">Gujarat</option>
                                                            <option Classname="form-control s-1" value="HR">Haryana</option>
                                                            <option Classname="form-control s-1" value="HP">Himachal Pradesh</option>
                                                            <option Classname="form-control s-1" value="JK">Jammu and Kashmir</option>
                                                            <option Classname="form-control s-1" value="JH">Jharkhand</option>
                                                            <option Classname="form-control s-1" value="KA">Karnataka</option>
                                                            <option Classname="form-control s-1" value="KL">Kerala</option>
                                                            <option Classname="form-control s-1" value="LA">Ladakh</option>
                                                            <option Classname="form-control s-1" value="LD">Lakshadweep</option>
                                                            <option Classname="form-control s-1" value="MP">Madhya Pradesh</option>
                                                            <option Classname="form-control s-1" value="MH">Maharashtra</option>
                                                            <option Classname="form-control s-1" value="MN">Manipur</option>
                                                            <option Classname="form-control s-1" value="ML">Meghalaya</option>
                                                            <option Classname="form-control s-1" value="MZ">Mizoram</option>
                                                            <option Classname="form-control s-1" value="NL">Nagaland</option>
                                                            <option Classname="form-control s-1" value="OR">Odisha</option>
                                                            <option Classname="form-control s-1" value="PY">Puducherry</option>
                                                            <option Classname="form-control s-1" value="PB">Punjab</option>
                                                            <option Classname="form-control s-1" value="RJ">Rajasthan</option>
                                                            <option Classname="form-control s-1" value="SK">Sikkim</option>
                                                            <option Classname="form-control s-1" value="TN">Tamil Nadu</option>
                                                            <option Classname="form-control s-1" value="TG">Telangana</option>
                                                            <option Classname="form-control s-1" value="TR">Tripura</option>
                                                            <option Classname="form-control s-1" value="UP">Uttar Pradesh</option>
                                                            <option Classname="form-control s-1" value="UT">Uttarakhand</option>
                                                            <option Classname="form-control s-1" value="WB">West Bengal</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">State</label>
                                                    </div>
                                                </div>
                                                {(() => {
                                                    var val = selectedOption ? input16Ref : input17Ref;
                                                    return (
                                                        <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                            <div className="input-box-mok1">
                                                                <input className="modify_im_input mode_category1 input-patient-box" ref={input15Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val)} type="text" required />
                                                                <label class="modify_p_text2 lbl-text-pt">City</label>
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input13Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="text" required>
                                                            <option className=" s-1 form-control" value="">Select Country</option>
                                                            <option className=" s-1 form-control" value="">Relation</option>
                                                            <option className=" s-1 form-control" value="">Mobile No</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">Emergency Detail</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input11Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">GST No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input11Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Old MR No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input11Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input12Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Description</label>
                                                    </div>
                                                </div>
                                                <div className="CompWidthMasterUser patient-widthcredit" ref={containerRef}>
                                                        <div className="input-box-mok1" onClick={toggleDropdown}>
                                                            <select className="modify_im_input mode_category1" type="checkbox" required>
                                                                <option>Select options</option>
                                                            </select>
                                                            <label className="UseMasterLebalFix">Credit Detail</label>
                                                            <div className="overSelect"></div>
                                                        </div>
                                                        {isOpen && (
                                                            <div id="checkBoxes" className="dropdown-content">
                                                                <label htmlFor="selectAll">
                                                                    <input className="user_checkbox" type="checkbox" id="selectAll"
                                                                        checked={selectedOptionsCredit.length === 4}
                                                                        onChange={handleSelectAllChange} />
                                                                    Select All
                                                                </label>

                                                                <label htmlFor="first">
                                                                    <input className="user_checkbox" type="checkbox" id="first"
                                                                        checked={selectedOptionsCredit.includes('checkBox1')}
                                                                        onChange={() => handleCheckboxChange('checkBox1')} />
                                                                    Category
                                                                </label>

                                                                <label htmlFor="second">
                                                                    <input className="user_checkbox" type="checkbox" id="second"
                                                                        checked={selectedOptionsCredit.includes('checkBox2')}
                                                                        onChange={() => handleCheckboxChange('checkBox2')} />
                                                                    General Category
                                                                </label>

                                                                {/* <label htmlFor="third">
                                                                    <input className="user_checkbox" type="checkbox" id="third"
                                                                        checked={selectedOptionsCredit.includes('checkBox3')}
                                                                        onChange={() => handleCheckboxChange('checkBox3')} />
                                                                    Sale
                                                                </label> */}

                                                                {/* <label htmlFor="fourth">
                                                                    <input className="user_checkbox" type="checkbox" id="fourth"
                                                                        checked={selectedOptionsCredit.includes('checkBox4')}
                                                                        onChange={() => handleCheckboxChange('checkBox4')}
                                                                    />
                                                                    Purchase
                                                                </label> */}
                                                            </div>
                                                        )}
                                                    </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="company-info-top-left1 patient-padding" >
                                        <div className="configure_radius">
                                            <div className="company_info-text-im td-1">Consultant Info
                                            </div>
                                            <div className="">
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input17Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input18Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Appo. No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1 input-patient-box" ref={input18Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input19Ref)} type="text" required>
                                                            <option className="s-1 form-control" value="">Follow</option>
                                                            <option className="s-1 form-control" value="optical">New Case</option>
                                                            <option className="s-1 form-control" value="optical">Report</option>
                                                        </select>
                                                        <label class="UseMasterLebalFix ">Purpose</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input19Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input20Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Consultant</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input20Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input21Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Consultant Fee</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input21Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input22Ref)} type="date" required />
                                                        <label class="UseMasterLebalFix">App Date & Time</label>
                                                    </div>
                                                </div>
                                                {(() => {
                                                    var val = selectedOption ? input23Ref : input24Ref;
                                                    return (
                                                        <div className="input-box-Imo1 input-height2 input-patient-mat user_add">
                                                            <div className="input-box-mok1">
                                                                <select className="modify_im_input mode_category1 input-patient-box" ref={input22Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val)} type="text" required>
                                                                    <option className="s-1 form-control" value="">Waiting</option>
                                                                    <option className="s-1 form-control" value="optical">Pending</option>
                                                                    <option className="s-1 form-control" value="optical">On Process</option>
                                                                    <option className="s-1 form-control" value="optical">Complete</option>
                                                                </select>
                                                                <label class="UseMasterLebalFix ">Status</label>
                                                            </div>
                                                        </div>
                                                    );
                                                })()}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="company-info-top-left1 patient-padding" >
                                        <div className="configure_radius">
                                            <div className="company_info-text-im td-1">Referred Info
                                            </div>
                                            <div className="">
                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input24Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input25Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Referred By</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input25Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input26Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Specialization</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input26Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input27Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Hospital Name</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Imo1 input-height2 input-patient-mat">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1 input-patient-box" ref={input27Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input28Ref)} type="text" required />
                                                        <label class="modify_p_text2 lbl-text-pt">Contact No</label>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    <div className="stock-im-1">
                                                        <div className="input-box-m1">
                                                            <p class="modify_p_2 "></p>
                                                            <div className="radio-buttons-container1 m2 overstack">
                                                                < ImageUploadWithPreview id={"item10"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-content-box tab-patient-top">
                    <div className="tab-content-1">
                        <div className="company-info-top-left1 patient-padding patient-table-width" >
                            <div className="configure_radius">
                                <div className="company_info-text-im td-1">Search List
                                </div>
                                <div className="input-order-buttons input-copy-Trans">
                                    <div className="input-remark-left01 input_copy_stock">
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input28Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input29Ref)} type="date" required onChange={handleFromDateChange} style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">From Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input29Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input30Ref)}   type="date" required onChange={handleToDateChange} style={{ fontSize: "12px" }} />
                                            <label id="input1" className="labelEffect1">To Date&nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                            <input className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input30Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input31Ref)}  type="text" required />
                                            <label class="modify_p_text2 lbl-text-pt">Search</label>
                                        </div>
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%", width: "50%" }}>
                                            <select id="BillType" className="form-control inputEffect modify_im_input mode_limit mode_category input-stk-copy" ref={input31Ref}
                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)}  style={{ fontSize: "14px" }} required>
                                                <option>All</option>
                                                <option>Waiting</option>
                                                <option>On Process</option>
                                                <option>Complete</option>
                                            </select>
                                            <label id="input1" className="labelEffect1">Status</label>
                                        </div>
                                        <div className="input-containerEffect mt-1 input-pur-invoice" style={{ margin: "1%" }}>
                                            <button class="search-button-main-search" onClick={handleSearchClick}>Search
                                                <i id="step-2" class="fa-solid fa-thin fa-magnifying-glass im-icon-white"></i>
                                            </button>
                                        </div>

                                    </div>
                                    <div className="input-box-Stockout ">
                                        <div className="input-containerEffect mt-1" style={{ margin: "1%" }}>
                                            <button className="btn-lavender Patient_treatment_btn" style={{ width: "auto", padding: "5px" }}>Patient Treatment</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="table-resposive" >
                                        <table id="tblTransactionList" className="table_ord_mod table-bordered1" frame="box">
                                            <thead textAlign="center" className="thead_scroll_orderlist table-tbody" >
                                                <tr className="">
                                                    <th className="td_col6">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>S.No.</div>
                                                    </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>App. No.</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>PID</div>
                                                    </div> </th>
                                                    <th className="td_col6 td_name_width">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Name</div>
                                                    </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_mmb1" style={{ textAlign: "center" }}>Age</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Gender</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Mobile No.</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Doctor</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>App Date</div>
                                                    </div> </th>
                                                    <th className="td_col6"> <div className="table_head_OrderList">
                                                        <div className="table_head_nnb1" style={{ textAlign: "center" }}>Status</div>
                                                    </div> </th>

                                                    <th className="td_col6 table_edit table_head_mmb1 " style={{ textAlign: "center" }} >Edit</th>
                                                    <th className="table_delete table_head_mmb1" style={{ textAlign: "center" }} >Delete</th>
                                                </tr>
                                            </thead>
                                            {showTable && (
                                                <tbody className="tbody_body_m1">
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">1</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6" ><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>


                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">2</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">3</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">4</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">5</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">6</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">7</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                    <tr className="table_tbody_Order_List">
                                                        <td className="td_col6">8</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>103 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>31822</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Kiran Sahu </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-1989 </td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Female</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>1234567890</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Dr. Sanjay</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>16-01-2024</td>
                                                        <td className="td_col6" style={{ textAlign: "center" }}>Waiting</td>
                                                        <td className="td_col6"><button className="edit_btn"><i class="fa-solid fa-pen-to-square"></i></button></td>
                                                        <td class="" style={{ textAlign: "center" }}>
                                                            <button className="btn-table1"><i class="fa-solid fa-trash delete-btn"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i class="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default PatientMaster;
