import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DBBackup = {

    // *********************** Database Backup ***********************
    databaseBackup: async () => {
        try {
            console.log("Initiating database backup...");
            const apiUrl = `${WebApiUrl}/api/DatabaseBackup/DatabaseBackup`;

            // Fetch the binary data from the backend
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                responseType: 'blob', // Important to handle binary data
            });

            console.log("Response received:", response);
            debugger
            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a link element for downloading
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);

            // Set the link attributes
            link.href = url;
            link.download = 'DatabaseBackup.zip'; // The name of the downloaded file
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

            console.log("Database Backup downloaded successfully.");
            return true;
        } catch (error) {
            console.error("Error downloading database backup:", error.message);
        }
    },
    //  **************************** Image Backup ***********************
    imageBackup: async () => {
        try {
            console.log("Initiating image backup...");
            const apiUrl = `${WebApiUrl}/api/DatabaseBackup/ImageBackup`;

            // Fetch the binary data from the backend
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                responseType: 'blob', // Important to handle binary data
            });

            console.log("Response received:", response);
            debugger
            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a link element for downloading
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);

            // Set the link attributes
            link.href = url;
            link.download = 'ImageBackup.zip'; // The name of the downloaded file
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

            console.log("Image Backup downloaded successfully.");
            return true;
        } catch (error) {
            console.error("Error downloading image backup:", error.message);
        }
    }

};

export default DBBackup;
