import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ItemDepartment = {

  insertItemDepartment: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemDepartment/ItemDepartment`;
      console.log(apiUrl);
      console.log(data);
      const response = await axios.post(apiUrl, {
        name: data.name,
        description: data.description,
        deptAvgPrepareTime: Number(data.deptAvgPrepareTime),
        remarks: data.remarks,
        isActive: data.isActive,
      }, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  getItemDepartment: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ItemDepartment/GetItemDepartments`;
        const response = await axios.post(apiUrl, {}, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Delete ****************************
  deleteItemDepartment: async (itemDepartmentId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/ItemDepartment?itemDepartmentId=${itemDepartmentId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get By Id ****************************
  getItemDepartmentById: async (itemDepartmentId) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/ItemDepartment/ItemDepartment?itemDepartmentId=${itemDepartmentId}`;
        const response = await axios.get(apiUrl, {}, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        //setEditCallBack(response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // *************************** Update ***************************

  updateItemDepartment: async (data) => {
    console.log(data.id)
    try {
      const apiUrl = `${WebApiUrl}/api/ItemDepartment?itemDepartmentId=${data.id}`;
      console.log(apiUrl);
      const response = await axios.put(apiUrl, {
        id: data.id,
        name: data.name,
        description: data.description,
        deptAvgPrepareTime: data.deptAvgPrepareTime,
        remarks: data.remarks,
        isActive: data.isActive,
      }, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

};

export default ItemDepartment;
