import axios from '../axiosConfig';
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const PineLab = {

    fetchDataFromPineLabs: async () => {
        try {
            //const apiUrl = `${WebApiUrl}/api/fetchDataFromPineLabs/`;
            // const response = await axios.get(apiUrl,  {
            //   withCredentials: true,
            //   credentials: 'include',
            // });
            // console.log('Response:', response.data.data);
            // return response.data.data;

            // try {

            const apiUrl = `${WebApiUrl}/api/PineLab/fetchDataFromPineLabs`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
        } catch (error) {
            console.error('Error fetching data from Pine Labs:', error);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
        //   } catch (error) {
        //      console.error('Error:', error.message);
        //   }
    },

};

export default PineLab;










