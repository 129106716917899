import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TaxCategory = {

    insertTaxCategory: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory/TaxCategory`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                name: data.name,
                taxCategoryType: Boolean(data.taxCategoryType),
                localTaxCGST: Number(data.localTaxCGST),
                localTaxSGST: Number(data.localTaxSGST),
                centralTaxIGST: Number(data.centralTaxIGST),
                cessTax: Number(data.cessTax),
                taxOnMRP: Boolean(data.taxOnMRP),
                remarks: data.remarks,
                isActive: Boolean(data.isActive)
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
               console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getTaxCategory: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory/GetTaxCategories`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
             console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get All ***************************
    getTaxCategoryFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory/GetTaxCategories`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
             console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Delete ****************************
    deleteTaxCategory: async (taxCategoryId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory?taxCategoryId=${taxCategoryId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
             console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get By Id ****************************
    getTaxCategoryById: async (taxCategoryId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory/TaxCategory?taxCategoryId=${taxCategoryId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
             console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },
    // *************************** Update ***************************

    updateTaxCategory: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/TaxCategory?taxCategoryId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                taxCategoryType: Boolean(data.taxCategoryType),
                localTaxCGST: Number(data.localTaxCGST),
                localTaxSGST: Number(data.localTaxSGST),
                centralTaxIGST: Number(data.centralTaxIGST),
                cessTax: Number(data.cessTax),
                taxOnMRP: Boolean(data.taxOnMRP),
                remarks: data.remarks,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
               console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default TaxCategory;
