
import DateTime from "../../Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Components/PageTabs";
// import ItemDepartmentMaster from "./ItemDepartmentMaster";
// import LoginTypeMaster from "./LoginTypeMaster";
// import DeliveryIncentive from "./DeliveryIncentive";
// import VehicleMaster from "./VehicleMaster";
import MainButton from "../../Props/MainButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import ModelFixedHeaderMultiplePage from "./ModelFixedHeaderMultiplePage";

function ReportLayout() {
    /***********title***************/
    useState(() => {
        document.title = 'Report Layout';
    }, []);

    //************{Check box dropdown Optical}******************//

    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (checkboxValue) => {
        if (selectedOptions.includes(checkboxValue)) {
            setSelectedOptions(selectedOptions.filter((item) => item !== checkboxValue));
        } else {
            setSelectedOptions([...selectedOptions, checkboxValue]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectedOptions.length === 0) {
            const allOptions = ['checkBox1', 'checkBox2', 'checkBox3', 'checkBox4'];
            setSelectedOptions(allOptions);
        } else {
            setSelectedOptions([]);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef]);

    //************End Check box dropdown Optical******************//

    ////************{Check box dropdown Optical}******************///

    const containerRef2 = useRef(null);
    const [isOpen2, setIsOpen2] = useState(false);
    const [selectedOptions2, setSelectedOptions2] = useState([]);

    const toggleDropdown2 = () => {
        setIsOpen2(!isOpen2);
    };

    const handleCheckboxChange2 = (checkboxValue) => {
        if (selectedOptions2.includes(checkboxValue)) {
            setSelectedOptions2(selectedOptions2.filter((item) => item !== checkboxValue));
        } else {
            setSelectedOptions2([...selectedOptions2, checkboxValue]);
        }
    };

    const handleSelectAllChange2 = () => {
        if (selectedOptions2.length === 0) {
            const allOptions2 = ['checkBox1', 'checkBox2', 'checkBox3', 'checkBox4', 'checkBox5']; // Corrected typo in checkBox5
            setSelectedOptions2(allOptions2);
        } else {
            setSelectedOptions2([]);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef2.current && !containerRef2.current.contains(event.target)) {
                setIsOpen2(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef2]);

    // *************************************************************************

    const containerRef3 = useRef(null);
    const [isOpen3, setIsOpen3] = useState(false);
    // const [selectedOptions3, setSelectedOptions3] = useState([]);

    const toggleDropdown3 = () => {
        setIsOpen3(!isOpen3);
    };



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef3.current && !containerRef3.current.contains(event.target)) {
                setIsOpen3(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef3]);


    const [, setIsOpenx1] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpenx1(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    //************{End Check box dropdown Resturant}******************//

    //****************{control modal box 1}*********************//
    const [selectedOption, setSelectedOption] = useState('');
    const [isModalOpen01, setModalOpen1] = useState(true);
    const [isModalOpenFixed, setModalOpenFixed] = useState(false);
    const showOpenFixed = () => {
        setModalOpenFixed(!isModalOpenFixed);
    }

    const hideModalFixed = () => {
        setModalOpenFixed(false);
    };

    // const handleSelectChange01 = (event) => {
    //     const selectedValue = event.target.value;
    //     setSelectedOption(selectedValue);

    //     if (selectedValue === 'optical') {
    //         setModalOpen1(true);
    //         setModalOpen2(false);
    //     } else if (selectedValue === 'restaurant') {
    //         setModalOpen1(true);
    //         setModalOpen2(true);
    //     }
    // };

    const [isIPAddres, setIPAddres] = useState(false);
    const [selectedOption5, sethandleInputChangeEcom] = useState(false);
    // const [, sethandleInputChangeEcom] = useState(false)
    const handleInputChangeEcom = (event) => {
        const selectedValue = event.target.value;
        sethandleInputChangeEcom(selectedValue);
        if (selectedValue === 'Yes') {
            setIPAddres(true);
        }
        else if (selectedValue === 'No') {
            setIPAddres(false);
        }
    }


    //**********{End control modal box 1}*****************//

    //***************{alloted modal box}*****************//
    const [isModalOpen03, setModalOpen03] = useState(false);

    const handleSelectChange03 = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'incentive1') {
            setModalOpen03(true);

        } else if (selectedValue === 'incentive2') {
            setModalOpen03(false);

        }
    };


    //**********{Login Type Master modal box}*****************//
    const [isModalOpen5, setModalOpen5] = useState(false);
    const showModal5 = () => {
        if (selectedOption === 'restaurant') {
            setModalOpen5(true);
        } else if (selectedOption === 'optical') {
            setModalOpen5(true);
        }
    };

    const hideModal5 = () => {
        setModalOpen5(false);
    };

    //**********{End Login Type Master modal box}*****************//


    // ************************{Modal Back}**************************


    const [IconButton, setIconButton] = useState(false);
    const ShowAddButton = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        if (selectedValue === 'Yes') {
            setIconButton(true);

        } else if (selectedValue === 'No') {
            setIconButton(false);
        }
    };

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);
    const input24Ref = useRef(null);
    const input25Ref = useRef(null);
    const input26Ref = useRef(null);
    const input27Ref = useRef(null);
    const input28Ref = useRef(null);
    const input29Ref = useRef(null);
    const input30Ref = useRef(null);
    const input31Ref = useRef(null);
    const input32Ref = useRef(null);
    const input33Ref = useRef(null);
    const input34Ref = useRef(null);
    const input35Ref = useRef(null);
    const input36Ref = useRef(null);
    const input37Ref = useRef(null);
    const input38Ref = useRef(null);
    const input39Ref = useRef(null);
    const input40Ref = useRef(null);
    const input41Ref = useRef(null);
    const input42Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    return (
        <div>
            < PageTabs />
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Report Layout</h2>
                </div >
                {/* *******************Top Buttons****************** */}
                <MainButton />
            </div>

            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-right report_layout-width">
                                        <div className="control_radius">
                                            <div className="company_info-text-im depart_top">
                                                Print Design Layout</div>
                                            <div className="HeadContentInputBpx Report-Layout-setting">
                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input39Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input40Ref)} onChange={handleSelectChange03} type="text" required>
                                                            <option value="">--Select--</option>
                                                            <option value="">Barcode 1*4 </option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Copy Layout Settings From</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="HeadContentInputBpx">
                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input8Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input9Ref)} type="text" required>
                                                            <option>Barcode Design</option>
                                                            <option>Form Design</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Design</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input9Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input10Ref)} type="text" required>
                                                            <option>--Select--</option>
                                                            <option>123 prism</option>
                                                            <option>321</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Print Setting</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Skip No Of Cells</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">No Of Rows</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">No Of Cols</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Cell Padding</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="MargeTwoInput">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input29Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text" required />
                                                            <label class="UseMasterLebalFix">Page Margin-top </label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input30Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" required />
                                                            <label class="UseMasterLebalFix FixEndH">Page Margin-bottom </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="MargeTwoInput">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input29Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text" required />
                                                            <label class="UseMasterLebalFix">Page Margin-left</label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input30Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" required />
                                                            <label class="UseMasterLebalFix FixEndH">Page Margin-right  </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Cell Space Bottom </label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Cell Space Left </label>
                                                    </div>
                                                </div>


                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Scale (1,1) Is 100% </label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Price Encryption String</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">No Of Duplicate Print Rpt </label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Use For Thermal Printer  </label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input22Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input23Ref)} type="text" required>
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Apply Default Row in all detail section</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="Report_Layout_top">
                                            </div>
                                            <div className="HeadContentInputBpx">
                                                <div className="CompWidthMasterUser flex-report-page">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input23Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input24Ref)} type="text" required>
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Fixed header for multiple page </label>
                                                    </div>
                                                    <div className="question-layout-icon">
                                                        <i onClick={showOpenFixed} class="fa-solid fa-question plus_user"></i>
                                                    </div>
                                                </div>
                                                {isModalOpenFixed && (
                                                    <div>
                                                        <div className="modal" >
                                                            <div className="OpticalField-modal Report-layout-model">
                                                                <div className="modal-title-Report">
                                                                    <h3 className="Report-Fixed-Header">Fixed Header multiple page</h3>
                                                                    <span className="close_modal" onClick={hideModalFixed}>
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                                <div className="tab-content-modal">
                                                                    <div className="company-info-top-modal control_flex_modal item_scroll">
                                                                        <ModelFixedHeaderMultiplePage />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}
                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input24Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input25Ref)} type="text" required>
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Header section </label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input25Ref}
                                                            onKeyDown={(event) => handleKeyPress(event)} type="text" required>
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Footer section </label>
                                                    </div>


                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        {/* <select className="modify_im_input mode_category1" ref={input26Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input27Ref)} type="text" required>
                                                            <option>No</option>
                                                            <option>Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">ttttttttttttttttttt</label> */}
                                                    </div>
                                                </div>


                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Header section Height</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text" required />
                                                        <label class="modify_p_text2">Footer section Height</label>
                                                    </div>
                                                </div>


                                                <div className="CompWidthMasterUser">
                                                    <div className="MargeTwoInput">
                                                        {/* <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input29Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="time" required />
                                                            <label class="UseMasterLebalFix">User LogIn Start Time </label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input30Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="time" required />
                                                            <label class="UseMasterLebalFix FixEndH"> End Time </label>
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="MargeTwoInput">
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" ref={input25Ref}
                                                                onKeyDown={(event) => handleKeyPress(event)} type="text" required>
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label class="modify_lbl_text3 userLeft1">Footer Line</label>
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <select className="modify_im_input mode_category1" ref={input25Ref}
                                                                onKeyDown={(event) => handleKeyPress(event)} type="text" required>
                                                                <option>No</option>
                                                                <option>Yes</option>
                                                            </select>
                                                            <label class="modify_lbl_text3 userLeft1">Footer Line</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="MargeTwoInput">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input29Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text" required placeholder="Bottom line position Width" />
                                                            {/* <label class="UseMasterLebalFix">Bottom line position</label> */}
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input30Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" required placeholder="Left" />
                                                            {/* <label class="UseMasterLebalFix FixEndH">Left </label> */}
                                                        </div>
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input30Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" required placeholder="Bottom" />
                                                            {/* <label class="UseMasterLebalFix FixEndH">Bottom </label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="Report_Layout_top">
                                            </div>
                                            <div className="HeadContentInputBpx">
                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category" ref={input34Ref}
                                                            onKeyDown={(event) => handleKeyPress(event)}
                                                            value={selectedOption5} onChange={handleInputChangeEcom}
                                                            type="text" required>
                                                            <option value="No">Portrait(Vertical)</option>
                                                            <option value="Yes">Landscape(Horizontal)</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Page Orientation </label>
                                                    </div>
                                                </div>


                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category" ref={input34Ref}
                                                            onKeyDown={(event) => handleKeyPress(event)}
                                                            value={selectedOption5} onChange={handleInputChangeEcom}
                                                            type="text" required>
                                                            <option value="No">No</option>
                                                            <option value="Yes">Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Generate PDF</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input35Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text" required />
                                                            <label class="modify_p_text2">Font Size</label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input36Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input37Ref)} type="text" required>
                                                            <option>A4</option>
                                                            <option>Custom Paper Size</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Page Size</label>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input35Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text" required />
                                                            <label class="modify_p_text2">Width(pt)</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input35Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text" required />
                                                            <label class="modify_p_text2">Height(pt)</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="CompWidthMasterUser">
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input39Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input40Ref)} onChange={handleSelectChange03} type="text" required>
                                                            <option value="incentive2">No</option>
                                                            <option value="incentive1">Yes</option>
                                                        </select>
                                                        <label class="modify_lbl_text3 userLeft1">Draw Outline</label>
                                                    </div>
                                                </div>
                                                <div className="CompWidthMasterUser">
                                                    <div className="">
                                                        <div className="input-box-mok1">
                                                            <input className="modify_im_input mode_category1" ref={input35Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text" required />
                                                            <label class="modify_p_text2">Printer Note</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="CompWidthMasterUser">
                                                <div className="MargeTwoInput">
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input29Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text" placeholder="Width" required />
                                                        {/* <label class="UseMasterLebalFix">Page Margin-top </label> */}
                                                    </div>
                                                    <div className="input-box-mok1">
                                                        <input className="modify_im_input mode_category1" ref={input30Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" placeholder="Height" required />
                                                        {/* <label class="UseMasterLebalFix FixEndH">Page Margin-bottom </label> */}
                                                        <label class="modify_lbl_text3 userLeft1">Conv W/H</label>
                                                    </div>
                                                    <div className="input-box-mok1">
                                                        <select className="modify_im_input mode_category1" ref={input25Ref}
                                                            onKeyDown={(event) => handleKeyPress(event)} type="text" required>
                                                            <option>INCH</option>
                                                            <option>CM</option>
                                                            <option>MM</option>
                                                        </select>
                                                    </div>
                                                    <div className="input-box-mok1">
                                                        <button className="modify_im_input mode_category1" ref={input30Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="button" required >
                                                            Conv Pt
                                                        </button>
                                                        {/* <label class="UseMasterLebalFix FixEndH">Page Margin-bottom </label> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="buttom-box">
                        <div className="button-btn-1">
                            <button className="btn-1">Save</button>
                        </div>
                        <div className="button-btn-2">
                            <button className="btn-2">Reset</button>
                        </div>
                        <div className="button-btn-3">
                            <button className="btn-3"><i class="fa-solid fa-gear "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default ReportLayout;
