import React from "react";
import Papa from "papaparse";

const CsvDownload = ({ data, filename }) => {
  const handleDownload = () => {
    // Convert JSON to CSV string

    debugger;
    const csv = Papa.unparse(data);

    // console.log("csv", csv)
    // Create a blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const setdatatype = Papa.parse(blob, {
      header: true,
      complete: (results) => {
        // Transform the data after parsing
        const transformedData = results.data.map((row) => {
          console.log(row);
        });

        // setData(transformedData);
      },
    });

    // Create a link element
    const link = document.createElement("a");

    // Set the link's href to a URL created from the blob
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Clean up and remove the link from the document
    document.body.removeChild(link);
  };
  return (
    <button className="add-csv-btn" onClick={handleDownload}>
      Download CSV
    </button>
  );
};

export default CsvDownload;
