import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useRef, useEffect, useContext } from "react";
import Joyride from 'react-joyride';
import 'react-quill/dist/quill.snow.css'
import ImageUploadWithPreview from "../../Components/ImagePreview";
import MainButton from "../../Props/MainButton";
import ModalBoxMaster from "../../Props/ModalBoxMaster";
import ToolTipMaster from "../../Props/ToolTipMaster";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import Loader from "../../Props/Loader";
import AccountApi from "../../../API/APIServices/Account";
import AccountGroupApi from "../../../API/APIServices/AccountGroup";
import StateInfoApi from "../../../API/APIServices/StateInfo";
import StationApi from "../../../API/APIServices/Station";
import DepartmentApi from "../../../API/APIServices/Department";
import DesignationApi from "../../../API/APIServices/Designation";
import AccountCategoryApi from "../../../API/APIServices/AccountCategory";
import TransporterApi from "../../../API/APIServices/Transporter";
import UserCredentialApi from "../../../API/APIServices/UserCredential";
import AlertBoxError from "../../Props/AlertBoxError";
import { useNavigate } from 'react-router-dom';
import CurrentDateAndTime from "../../../API/APIServices/CurrentDateAndTime";

function AccountMaster1(props) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const { alertBox,
        OpenAlertBoxEdit,
        OpenAlertBox,
        OpenAlertBoxWhenItemExists,
        openAlertItemExists,
        alertBoxEdit,
        OpenAlertBoxErrorInsert,
        alertBoxErrorInsert,
        OpenAlertBoxErrorUpdate,
        alertBoxErrorUpdate,
        UserDetails,
        maskMobileNo,
        companyData,
    } = useContext(MyContext);

    const [isOptionalField, setOptionalField] = useState(false);
    useState(() => {
        document.title = 'Account Master';
    }, []);

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);
    const input9Ref = useRef(null);
    const input10Ref = useRef(null);
    const input11Ref = useRef(null);
    const input12Ref = useRef(null);
    const input13Ref = useRef(null);
    const input14Ref = useRef(null);
    const input15Ref = useRef(null);
    const input16Ref = useRef(null);
    const input17Ref = useRef(null);
    const input18Ref = useRef(null);
    const input19Ref = useRef(null);
    const input20Ref = useRef(null);
    const input21Ref = useRef(null);
    const input22Ref = useRef(null);
    const input23Ref = useRef(null);
    const input24Ref = useRef(null);
    const input25Ref = useRef(null);
    const input26Ref = useRef(null);
    const input27Ref = useRef(null);
    const input28Ref = useRef(null);
    const input29Ref = useRef(null);
    const input30Ref = useRef(null);
    const input31Ref = useRef(null);
    const input32Ref = useRef(null);
    const input33Ref = useRef(null);
    const input34Ref = useRef(null);
    const input35Ref = useRef(null);
    const input36Ref = useRef(null);
    const input37Ref = useRef(null);
    const input38Ref = useRef(null);
    const input39Ref = useRef(null);
    const input40Ref = useRef(null);
    const input41Ref = useRef(null);
    const input42Ref = useRef(null);
    const input43Ref = useRef(null);
    const input44Ref = useRef(null);
    const input45Ref = useRef(null);
    const input46Ref = useRef(null);
    const input47Ref = useRef(null);
    const input48Ref = useRef(null);
    const input49Ref = useRef(null);
    const input50Ref = useRef(null);
    const input51Ref = useRef(null);
    const input52Ref = useRef(null);
    const input53Ref = useRef(null);
    const input54Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };
    const [controlType] = useState(168)
    //************Image add******************//
    const [isInputVisiblam1, setInputVisiblam1] = useState(false);
    const handleToggleChangam1 = () => {
        setInputVisiblam1(!isInputVisiblam1);
    };
    const [isInputVisiblam2, setInputVisiblam2] = useState(false);
    const handleToggleChangam2 = () => {
        setInputVisiblam2(!isInputVisiblam2);
    };
    const [isInputVisiblam3, setInputVisiblam3] = useState(false);
    const handleToggleChangam3 = () => {
        setInputVisiblam3(!isInputVisiblam3);
    };

    //************modal box************/ /
    const [isModalOpen, setModalOpen] = useState(false);
    const showModal = () => {
        setModalOpen(!isModalOpen);
    };
    const hideModal = () => {
        setModalOpen(false);
    };

    const [isModalOpen2, setModalOpen2] = useState(false);
    const showModal2 = () => {
        setModalOpen2(true);
    };
    const hideModal2 = () => {
        setModalOpen2(false);
    };

    const [transportModal, setTransportModal] = useState(false);
    const showTransporter = () => {
        setTransportModal(true);
    };
    const hideTransporter = () => {
        setTransportModal(false);
    };

    const [isModalOpen3, setModalOpen3] = useState(false);
    const showModal3 = () => {
        setModalOpen3(true);
    };
    const hideModal3 = () => {
        setModalOpen3(false);
    };

    const [isModalOpenDesignationDetails, setModalOpenDesignationDetails] = useState(false);
    const showModalDesignationDetails = () => {
        setModalOpenDesignationDetails(true);
    };
    const hideModalDesignationDetails = () => {
        setModalOpenDesignationDetails(false);
    };

    const [isModalOpenDepartmentDetails, setModalOpenDepartmentDetails] = useState(false);
    const showModalDepartmentDetails = () => {
        setModalOpenDepartmentDetails(true);
    };
    const hideModalDepartmentDetails = () => {
        setModalOpenDepartmentDetails(false);
    };

    const [isModalOpenAccCategoryMaster, setModalOpenAccCategoryMaster] = useState(false);
    const showModalAccCategoryMaster = () => {
        setModalOpenAccCategoryMaster(true);
    };
    const hideModalAccCategoryMaster = () => {
        setModalOpenAccCategoryMaster(false);
    };

    //************modal box************/ /
    const showOptionalField = () => {
        setOptionalField(!isOptionalField);
    };

    const [setModalOpen6] = useState(false);

    const hideModal6 = () => {
        setModalOpen6(false);
    };

    const [isAliasPrints, setAliasPrints] = useState(false)
    const showAliasPrints = () => {
        setAliasPrints(!isAliasPrints)
    }

    const [isDefaultSalePerson, setDefaultSalePerson] = useState(false);
    const showDefaultSalePerson = () => {
        setDefaultSalePerson(!isDefaultSalePerson)
    }

    /************Category****************/

    const [{ run, steps },] = useState({
        run: true,
        steps: [
            {
                content: <h2>Let's begin our journey!</h2>,
                locale: { skip: <strong>SKIP</strong> },
                placement: "center",
                target: "body"
            },
            {
                content: <h2>Upload Item Image!</h2>,
                placement: "bottom",
                target: "#step-1",
                title: "First step"
            },
            {
                content: <h2>Here is second step!</h2>,
                placement: "bottom",
                target: "#step-2",
                title: "Second step"
            },
            {
                content: <h2>Here is third step!</h2>,
                placement: "bottom",
                target: "#step-3",
                title: "Third step"
            },
            {
                content: <h2>Here is fourth step!</h2>,
                placement: "bottom",
                target: "#step-4",
                title: "Fourth step"
            },
            {
                content: <h2>Here is fifth step!</h2>,
                placement: "bottom",
                target: "#step-5",
                title: "Fifth step"
            },
            {
                content: <h2>Here is six step!</h2>,
                placement: "bottom",
                target: "#step-6",
                title: "Six step"
            },
        ]
    });

    // *************************{Age Calculator}***************************
    // const [birthDate, setBirthDate] = useState('');
    // const [age, setAge] = useState(null);

    // const handleDateChange = (e) => {
    //     const selectedDate = e.target.value;
    //     setBirthDate(selectedDate);
    //     const currentDate = new Date();
    //     const dob = new Date(selectedDate);
    //     let calculatedAge = currentDate.getFullYear() - dob.getFullYear();
    //     if (
    //         currentDate.getMonth() < dob.getMonth() ||
    //         (currentDate.getMonth() === dob.getMonth() &&
    //             currentDate.getDate() < dob.getDate())
    //     ) {
    //         calculatedAge -= 1;
    //     }

    //     setAge(calculatedAge);
    // };

    // const [ishandleInputChangeEcom, sethandleInputChangeEcom] = useState(true);
    // const handleInputChangeEcom2 = (event) => {
    //     const selectedValue = event.target.value;
    //     // sethandleInputChangeEcom(selectedValue);
    //     if (selectedValue === 'Yes') {
    //         sethandleInputChangeEcom(true);
    //     }
    //     else if (selectedValue === 'No') {
    //         sethandleInputChangeEcom(false);
    //     }
    // }

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    const [isModalOpenUnit, setModalOpenUnit] = useState(false);
    const hideUnit = () => {
        setModalOpenUnit(false);
    };

    // ***************************** Get Account Types ********************
    const [accountType, setAccountType] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountApi.getAccountTypes();
                setAccountType(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const [editedOptionalData, setEditedOptionalData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.editData) {
                    try {
                        const data = await props.editData;
                        setEditedOptionalData(data[0].accOptFieldValues);

                        setInputValues((prevInputValues) => ({
                            ...prevInputValues,
                            d1Id: data[0].d1Id,
                            d2Id: data[0].d2Id,
                            aMobileNo: data[0].mobileNo,
                            parentGrp: data[0].parentGroupId,
                            accountType: data[0].accountTypeId,
                            aState: data[0].stateId,
                            aStation: data[0].stationId,
                            aDefaultSalePerson: data[0].defaultSalePersonId,
                            adob: data[0].dob,
                            aAge: data[0].age,
                            adom: data[0].dom,
                            aGender: data[0].gender,
                            aDepartment: data[0].departmentId,
                            aDesignation: data[0].designationId,
                            aAccountCategory: data[0].accountCategoryId,
                            aDefaultTranspoter: data[0].defaultTranspoterId,
                            assignUser: data[0].assignUserId,
                            referralBy: data[0].referralById,
                            aDrugLicenseNo: data[0].drugLicenseNo,
                            aCreditlimit: data[0].creditlimit,
                            aEnableLoyalty: data[0].enableLoyalty,
                            aOpeningBal: data[0].openingBal,
                            aOpeningCrDr: data[0].openingCrDr,
                            aAddress: data[0].address,
                            aEmail: data[0].email,
                            aPincode: data[0].pincode,
                            aLocation: data[0].location,
                            aDistance: data[0].distance,
                            agStINNo: data[0].gStINNo,
                            aitPan: data[0].itPan,
                            alstNo: data[0].lstNo,
                            aAadharNo: data[0].aadharNo,

                            aContactperson: data[0].aContactperson,
                            contactpersonMbl: data[0].contactpersonMbl,
                            openingCrDr: data[0].openingCrDr,

                            namePrefix: data[0].namePrefix,
                            name: data[0].name,
                            printName: data[0].printName,
                            alias: data[0].alias,
                            aCreditperiod: data[0].aCreditperiod, //field is not there
                            aLoyaltyCardNo: data[0].aLoyaltyCardNo, //field is not there
                            aEnableEmail: data[0].aEnableEmail, //field is not there
                            accountDealerType: data[0].accountDealerType,
                            acstNo: data[0].acstNo, // remove this field
                            doNotDisturb: data[0].doNotDisturb,
                            system_defined: false,
                            createdBy: 0,
                            createdOn: "2024-02-01",
                            isActive: data[0].isActive,
                            optionalFieldName: data[0].optionalFieldName,
                            optionalFieldValue: data[0].optionalFieldValue,
                            isDeleted: data[0].isDeleted,
                            password: data[0].password,
                        }));
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.editData]);

    // *********************** Account Master Setting *************************

    const [settingInputValues, setSettingInputValues] = useState({
        accountDetails: false,
        aliasNPrintDetails: false,
        documentDetails: false,
        transportDetails: false,
        defaultSalesPerson: false,
        optionalField: false,
        optionalFieldName: "",
        priority: 0,
        // isActive: true
    });

    const handleSettingChange = (event) => {
        const { name, checked } = event.target;
        console.log(`Changing ${name} to ${checked}`);

        const updatedValues = {
            ...settingInputValues,
            [name]: checked,
        };

        setSettingInputValues(updatedValues);
        AccountApi.insertAccountMasterSetting(updatedValues, customfields);

        switch (name) {
            case 'accountDetails':
                handleToggleChangam3();
                break;
            case 'aliasNPrintDetails':
                showAliasPrints();
                break;
            case 'documentDetails':
                handleToggleChangam2();
                break;
            case 'transportDetails':
                handleToggleChangam1();
                break;
            case 'defaultSalesPerson':
                showDefaultSalePerson();
                console.log('defaultSalesPerson');
                break;
            case 'optionalField':
                showOptionalField();
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountApi.getAccountMasterSetting();
                console.log(data);
                setSettingInputValues(data);
                setOptionalField(data.optionalField);
                setInputVisiblam3(data.accountDetails);
                setAliasPrints(data.aliasNPrintDetails);
                setInputVisiblam2(data.documentDetails);
                setInputVisiblam1(data.transportDetails);
                setDefaultSalePerson(data.defaultSalesPerson);
            } catch (error) {
                console.log(error);
            }
        }
        console.log(settingInputValues);
        fetchData();
    }, []);

    // ****************************{Optional Fields}******************************

    const [customfields, setCustomefields] = useState([]);

    const [optionalData, setOptionalData] = useState({
        id: 1,
        optionalFieldName: '',
        priority: '',
        isDeleted: false,
        isActive: true
    })

    const optonalFieldAddBtn = () => {
        const val = document.getElementById('CustomFieldInput').value;
        if (val.trim() !== '') {
            const newItem = {
                optionalFieldName: optionalData.optionalFieldName,
                priority: optionalData.priority,
                isDeleted: optionalData.isDeleted,
                isActive: optionalData.isActive
            };
            const updatedValues = [...customfields, newItem];
            setCustomefields(updatedValues);

            document.getElementById("inputField").style.display = "none";
            document.getElementById('priority').value = "";
            document.getElementById('CustomFieldInput').value = "";

            AccountApi.insertAccountMasterSetting(settingInputValues, updatedValues);
        } else {
            alert("Please Enter valid Name", "ok");
        }
    }

    const handleOptionalFieldChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        const updatedValues = {
            ...optionalData,
            [name]: value,
        };
        setOptionalData(updatedValues);
    }

    const [customFieldValues, setCustomFieldValues] = useState({});

    const handleOptionalDataChange = (event, id) => {
        const { name, value } = event.target;

        setCustomFieldValues((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                [id]: value,
                "optionalFieldValue": value,
                "optionalFieldName": name
            }
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountApi.getAccountOptionalField();
                setCustomefields(data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    // **********************************************************

    const AddCustmField_clicked = () => {
        document.getElementById("inputField").style.display = "block";
    }

    const deleteValueById = (id) => {
        console.log("hello");
        setCustomefields((prevState) =>
            prevState.filter((item) => item.id !== id)
        );
    };

    const [isAccountMaster,] = useState(true);

    // **********************************************************************
    const [errorMessages, setErrorMessages] = useState({
        name: '',
        aEmail: '',
    });

    const [inputValues, setInputValues] = useState({
        d1Id: "",
        d2Id: "",
        namePrefix: "",
        name: "",
        printName: "",
        alias: "",
        accountType: 0,
        parentGrp: 0,
        aState: companyData?.stateId || 0,
        aStation: -1,
        aDefaultSalePerson: -1,
        adob: "",
        aAge: 0,
        adom: "",
        aGender: "",
        aDepartment: 0,
        aDesignation: 0,
        aDrugLicenseNo: "",
        aOpeningBal: 0,
        aOpeningCrDr: "",
        aContactperson: "",
        contactpersonMbl: "",
        aCreditlimit: 0,
        aCreditperiod: 0,
        aAccountCategory: -1,
        aEnableLoyalty: false,
        aLoyaltyCardNo: "",
        referralBy: -1,
        assignUser: -1,
        aAddress: "",
        aEnableEmail: "",
        aEmail: "",
        aMobileNo: "",
        aPincode: "",
        aLocation: "",
        aDistance: "",
        accountDealerType: "",
        agStINNo: "",
        aitPan: "",
        alstNo: "",
        acstNo: "",
        aAadharNo: "",
        doNotDisturb: "",
        aDefaultTranspoter: -1,
        openingCrDr: "",
        system_defined: false,
        createdBy: "",
        createdOn: "",
        isActive: true,
        optionalFieldName: "",
        optionalFieldValue: "",
        isDeleted: false,
        password: ""
    });
    useEffect(() => {
        console.log(inputValues);
    }, [inputValues]);

    useEffect(() => {
        if (props.TransType) {
            if (props.TransType == 12) {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    parentGrp: 212, // Set accountType to 211
                }));
            } else if (props.TransType == 14) {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    parentGrp: 211, // Set accountType to 212
                }));
            }
        }
    }, [props.TransType]);

    useEffect(() => {
        if (props.InputValueAccMaster) {
            setInputValues((prevValues) => ({
                ...prevValues,
                namePrefix: props.InputValueAccMaster.namePrefix || "",
                name: props.InputValueAccMaster.AccName || "",
                aState:
                    props.InputValueAccMaster.AccStateId || companyData?.stateId || 0,
                adob: props.InputValueAccMaster.AccDob || "",
                adom: props.InputValueAccMaster.AccAnn || "",
                aOpeningBal: props.InputValueAccMaster.AccBalance || 0,
                aCreditlimit: props.InputValueAccMaster.AccCreditLimit || 0,
                aAddress: props.InputValueAccMaster.AccAddress || "",
                aMobileNo: props.InputValueAccMaster.AccPhoneNo || "",
                password: props.InputValueAccMaster.AccPhoneNo || "",
            }));
        }
    }, [props.InputValueAccMaster]);
    // ***************** Get Station ***********************

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await StationApi.getStation();
    //             // setStation(data);
    //             //console.log("stateInfo",data[1].id);
    //             if (data.length > 0) {
    //                setInputValues((prevValues) => ({
    //                     ...prevValues,
    //                     "aStation": data[0].id,
    //                   }));
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            printName: name === 'name' ? value : prevInputValues.printName,
        }));
        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));

        if (name === 'aMobileNo') {
            setInputValues({
                ...inputValues,
                aMobileNo: value,
                password: value
            });
        }

        if (name === 'accountType') {
            //console.log("accountType",value);
            if (value === "771") {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "parentGrp": 212,
                }));
            } else if (value === "772") {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "parentGrp": 211,
                }));
            } else if (value === "773") {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "parentGrp": 203,
                }));
            } else if (value === "774") {
                setInputValues((prevValues) => ({
                    ...prevValues,
                    "parentGrp": 370,
                }));
            }
        }

        if (name === 'adob') {
            const selectedDate = event.target.value;
            //setBirthDate(selectedDate);
            const currentDate = await CurrentDateAndTime.getCurrentDateTime();
            // const currentDate = new Date(isoString);
            const dob = new Date(selectedDate);
            let calculatedAge = currentDate.getFullYear() - dob.getFullYear();
            if (
                currentDate.getMonth() < dob.getMonth() ||
                (currentDate.getMonth() === dob.getMonth() &&
                    currentDate.getDate() < dob.getDate())
            ) {
                calculatedAge -= 1;
            }

            setInputValues((prevValues) => ({
                ...prevValues,
                "aAge": calculatedAge,
            }));
            //setAge(calculatedAge);
            //console.log(calculatedAge);
        }
    };

    const handleInputChangeActive = (e) => {
        const { name, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : e.target.value;

        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: type === 'checkbox' ? !prevInputValues[name] : newValue,
        }));

        setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
        console.log(inputValues);
    };

    const toFriendlyName = (fieldName) => {
        return fieldName
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    const validateInput = (fieldName) => {
        console.log(fieldName);
        const fieldValue = inputValues[fieldName];

        if (!fieldValue || (typeof fieldValue === 'string' && fieldValue.trim() === '')) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                [fieldName]: `${toFriendlyName(fieldName)} field is required`,
            }));
            return false;
        }

        return true;
    };
    const fieldNames = ['name'];

    const SaveBtn = async () => {
        //console.log(customFieldValues);
        let allValid = true;
        fieldNames.forEach((fieldName) => {
            const isValid = validateInput(fieldName);
            if (!isValid) {
                allValid = false;
            }
        });

        console.log(allValid);
        if (allValid) {
            setLoading(true);
            if (Boolean(props.modelOpen) === true) {
                const isSuccess = await AccountApi.updateAccount(
                    inputValues,
                    customFieldValues
                );
                debugger;
                if (isSuccess.isSuccess === true) {
                    setLoading(false);
                    OpenAlertBox();
                    if (props.closeAccountMasterModal) {
                        setTimeout(() => {
                            OpenAlertBox();
                            props.closeAccountMasterModal(isSuccess.result);
                        }, 1000);
                    }
                }
                else if (isSuccess.errorMessages[0] === "Invalid data name") {
                    setLoading(false);
                    OpenAlertBoxWhenItemExists();
                } else {
                    setLoading(false)
                    OpenAlertBoxErrorInsert();
                }
            } else {
                setLoading(true);
                const isSuccess = await AccountApi.insertAccount(
                    inputValues,
                    customFieldValues
                );
                debugger
                if (isSuccess.isSuccess === true) {
                    setLoading(false);
                    OpenAlertBox();
                    if (props.closeAccountMasterModal) {
                        setTimeout(() => {
                            props.closeAccountMasterModal(isSuccess.result);
                        }, 1000);
                    }
                    setInputValues({
                        namePrefix: "",
                        name: "",
                        printName: "",
                        alias: "",
                        accountType: 0,
                        parentGrp: 0,
                        aState: -1,
                        aStation: -1,
                        aDefaultSalePerson: -1,
                        adob: "",
                        aAge: 0,
                        adom: "",
                        aGender: "",
                        aDepartment: 0,
                        aDesignation: 0,
                        aDrugLicenseNo: "",
                        aOpeningBal: 0,
                        aOpeningCrDr: "",
                        aContactperson: "",
                        contactpersonMbl: "",
                        aCreditlimit: 0,
                        aCreditperiod: 0,
                        aAccountCategory: -1,
                        aEnableLoyalty: false,
                        aLoyaltyCardNo: "",
                        referralBy: -1,
                        assignUser: -1,
                        aAddress: "",
                        aEnableEmail: "",
                        aEmail: "",
                        aMobileNo: "",
                        aPincode: "",
                        aLocation: "",
                        aDistance: "",
                        accountDealerType: "",
                        agStINNo: "",
                        aitPan: "",
                        alstNo: "",
                        acstNo: "",
                        aAadharNo: "",
                        doNotDisturb: "",
                        aDefaultTranspoter: -1,
                        openingCrDr: "",
                        system_defined: false,
                        createdBy: "",
                        createdOn: "",
                        isActive: true,
                        optionalFieldName: "",
                        optionalFieldValue: "",
                        isDeleted: false,
                        password: "",
                    });

                } else if (
                    isSuccess.errorMessages &&
                    isSuccess.errorMessages.length > 0 &&
                    isSuccess.errorMessages[0]
                        .toLowerCase()
                        .includes(
                            "already exist".toLowerCase()
                        )
                ) {
                    // alert("Account already exist");
                    setLoading(false);
                    OpenAlertBoxWhenItemExists();
                } else {
                    setLoading(false);
                    OpenAlertBoxErrorInsert();
                }
            }
        }
    };

    // *************** Get Account Group *******************
    const [accountGroup, setAccountGroup] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountGroupApi.GetAccountGroups();
                setAccountGroup(data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [isModalOpen2]);

    // ***************** Get State Name ***********************
    const [stateInfo, setStateInfo] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await StateInfoApi.GetAllState();
                setStateInfo(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // ***************** Get Station ***********************
    const [station, setStation] = useState();

    useEffect(() => {

        const fetchData = async () => {

            try {
                const data = await StationApi.getStation();
                setStation(data);
                if (data.length > 0) {
                    setInputValues((prevValues) => ({
                        ...prevValues,
                        "aStation": data[0].id,
                    }));
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [isModalOpen3]);

    // ***************** Get Department ***********************
    const [department, setDepartment] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await DepartmentApi.getDepartment();
                setDepartment(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [isModalOpenDepartmentDetails]);

    // ***************** Get Designation ***********************
    const [designation, setDesignation] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await DesignationApi.getDesignation();
                setDesignation(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [isModalOpenDesignationDetails]);

    // ***************** Get Account Category ***********************
    const [accountCategory, setAccountCategory] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountCategoryApi.getAccountCategory();
                setAccountCategory(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [isModalOpenAccCategoryMaster]);

    // ***************** Get Trasporter ***********************
    const [transporter, setTransporter] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await TransporterApi.GetTransporter();
                setTransporter(data);
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [setTransportModal, transportModal]);

    // ***************** Get Default Sales Person ***********************
    const [defaultSalesPerson, setDefaultSalesPerson] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await AccountApi.getAccount();
                setDefaultSalesPerson(data);
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // ***************** Get Assign user from user master ***********************
    const [assignUser, setAssignUser] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await UserCredentialApi.getUserCredential();
                setAssignUser(data);
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (UserDetails.userRoleCreateBtnVisible === false) {
            // Get all elements with the class name "dm_4"
            const elements = document.getElementsByClassName("dm_4");

            // Loop through all the elements and set their display style to "none"
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = "none";
            }
        }
    }, [UserDetails, isOptionalField])

    return (
        <div>
            <PageTabs locationTab={"/AccountMaster"} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} showModal={showModal} />
            <ModalBoxMaster
                isModalOpenUnit={isModalOpenUnit}
                hideUnit={hideUnit}
                isModalStationMaster={isModalOpen3}
                hideModal3={hideModal3}
                handleModalClick={handleModalClick}
                isModalOpen2={isModalOpen2}
                transportModal={transportModal}
                hideModal2={hideModal2}
                hideTransporter={hideTransporter}
                hideModalDepartmentDetails={hideModalDepartmentDetails}
                isModalOpenDepartmentDetails={isModalOpenDepartmentDetails}
                hideModalDesignationDetails={hideModalDesignationDetails}
                isModalOpenDesignationDetails={isModalOpenDesignationDetails}
                hideModalAccCategoryMaster={hideModalAccCategoryMaster}
                isModalOpenAccCategoryMaster={isModalOpenAccCategoryMaster}
            />
            <div>
                {/* <Joyride
                    continuous
                    callback={() => { }}
                    run={run}
                    steps={steps}
                    hideCloseButton
                    scrollToFirstStep
                    showSkipButton
                    showProgress
                /> */}
                {[].map((item) => {
                    return (
                        <div
                            key={item}
                            id={`step-${item}`}
                            style={{
                                border: "1px solid white",
                                width: "100px",
                                height: "100px",
                                background: "#0c1d2b",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {item}
                        </div>
                    );
                })}
            </div>

            {/* < AccountGroupModal /> */}
            <div className="panel-heading">
                <div className="panel-head-title">
                    <h2 className="panel-title">Account Master</h2>
                </div>

                {/* *******************Top Buttons****************** */}
                <MainButton showModal={showModal} isBtnOpticalField={true} isBtnSetting={true} SaveButton={SaveBtn} modelOpen={props.modelOpen} setSliderCallBack={props.setSliderCallBack} PageName="Masters" />
                {/* ******************* Setting Modal ****************** */}
                <div>
                    {isModalOpen && (
                        <div onClick={hideModal} className="modal" >
                            <div onClick={handleModalClick} className="modal-content2" style={{ width: "40%" }}>
                                <div className="modal-title">
                                    <h3 className="modal-h">Account Master</h3>
                                    <span className="close" onClick={hideModal}>
                                        &times;
                                    </span>
                                </div>
                                <div className="modal-content-im">
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="accountDetails" value={settingInputValues.accountDetails} onChange={handleSettingChange} checked={settingInputValues.accountDetails} />
                                                    <span></span>
                                                </label>
                                                <div className="ModalLabeltext2">Account Details<i className="fa-solid fa-circle-info ml-10"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="aliasNPrintDetails" value={settingInputValues.aliasNPrintDetails} onChange={handleSettingChange} checked={settingInputValues.aliasNPrintDetails} />
                                                    <span></span>
                                                </label>
                                                <div className="ModalLabeltext2">Alias & Print Details<i className="fa-solid fa-circle-info ml-10"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="documentDetails" value={settingInputValues.documentDetails} onChange={handleSettingChange} checked={settingInputValues.documentDetails} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Document Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="transportDetails" value={settingInputValues.transportDetails} onChange={handleSettingChange} checked={settingInputValues.transportDetails} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Transport Details<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="defaultSalesPerson" value={settingInputValues.defaultSalesPerson} onChange={handleSettingChange} checked={settingInputValues.defaultSalesPerson} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Default Sales Person <i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="input-box-m1 modal-1">
                                            <div className="cl-toggle-switch">
                                                <label className="cl-switch">
                                                    <input type="checkbox" name="optionalField" value={settingInputValues.optionalField} onChange={handleSettingChange} checked={settingInputValues.optionalField} />
                                                    <span></span>
                                                </label>
                                                <label className="ModalLabeltext2">Optional Field<i className="fa-solid fa-circle-info ml-10"></i></label>
                                            </div>
                                            {isOptionalField && (
                                                <div className="">
                                                    <div className="input-box-m1 modal-1">
                                                        {(() => {
                                                            if (customfields && customfields.length !== 0) {
                                                                return customfields.map((fields, index) => (
                                                                    <div className="OptionalHeadTop" key={fields.id}>
                                                                        <div style={{ width: "80%", paddingLeft: "33px", display: "flex", fontSize: "14px" }} className="cakcbox-label-1">
                                                                            <span>{fields.optionalFieldName}</span>
                                                                        </div>
                                                                        <div style={{ width: "20%" }} className="checkbox-btn-delete" type="button" onClick={() => deleteValueById(fields.id)}>
                                                                            <i className="fa-solid fa-trash" style={{ color: "#f90101", cursor: "pointer", fontWeight: "600", paddingLeft: "10px" }}></i>
                                                                        </div>
                                                                    </div>
                                                                ));
                                                            }
                                                        })()}
                                                        <hr />
                                                        <div style={{ display: "none" }} id="inputField">
                                                            <div className="flex-center" >
                                                                <input style={{ backgroundColor: "#0000001a", color: "white" }} name="optionalFieldName" onChange={handleOptionalFieldChange} type="text" id="CustomFieldInput" />
                                                                <div><input type="number" id="priority" name="priority" onChange={handleOptionalFieldChange} style={{ width: "50px", backgroundColor: "transparent", color: 'white', padding: '5px' }} placeholder="priority" /></div>
                                                                <button className="Add_btn" onClick={optonalFieldAddBtn}>Add</button>
                                                            </div>
                                                        </div>
                                                        <div className="flex-center">
                                                            <label style={{ color: "white", cursor: "pointer", fontWeight: "600" }} onClick={AddCustmField_clicked}>+ Add Optional Field into Table</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div>
                < DateTime />
            </div>
            <div className="content_box">
                <div className="company-info-top-im">
                    <div className="tab-head">
                        <div id="tabs">
                            <div className="tab-content-box">
                                <div className="tab-content-1">
                                    <div className="company-info-top-left" >
                                        <div className="company_info-text-im td-1">General Details
                                            <div className="input-box-checkbox2 ">
                                                <div className=" td-2">
                                                    <div className="cl-toggle-switch td-3">
                                                        <label className="cl-switch">
                                                            <input type="checkbox" onChange={handleInputChangeActive} name="isActive" checked={inputValues.isActive} value={inputValues.isActive} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <ToolTipMaster isActiveInactive={true} />
                                                </div>
                                            </div>
                                            {isAccountMaster && (
                                                <div className="tab-btn-box4 tab-b2" style={{
                                                    paddingBottom: "25px", paddingTop: "0px",
                                                    alignItems: "baseline", paddingLeft: "10px",
                                                }}>
                                                    <div className="radio-btn-1" style={{ width: "100%" }}>
                                                        {accountType && accountType.map((data) => (
                                                            <div className="flex" key={data.id}>
                                                                <input type="radio" id={data.id} className="radio-box-1 box-size1"
                                                                    value={data.id}
                                                                    name="accountType" onChange={handleInputChange} />
                                                                <label htmlFor={data.id} style={{ fontWeight: "600" }}>&nbsp;{data.name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                        {isAccountMaster && (
                                            <div className="">
                                                <div className="HeadContentInputBpx">
                                                    <div style={{ display: "flex" }} className="input-box-m11">
                                                        <div style={{ width: "25%" }}>
                                                            <select className="modify_im_input mode_category" ref={input1Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text"
                                                                value={inputValues.namePrefix}
                                                                name="namePrefix" onChange={handleInputChange}
                                                                required>
                                                                <option></option>
                                                                <option value="Mr">Mr.</option>
                                                                <option value="Mrs">Mrs</option>
                                                                <option value="Miss">Miss</option>
                                                            </select>
                                                            <label className="modifyTextLabel">Title</label>
                                                        </div>
                                                        <div style={{ width: "75%" }}>
                                                            <input className="modify_im_input mode_category" ref={input2Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="text"
                                                                value={inputValues.name}
                                                                name="name" onChange={handleInputChange} required />
                                                            <label className="AccountMasterNameLabel">Name
                                                                <span className="StarClr">*
                                                                    {errorMessages.name && <label className="errorMessage">{errorMessages.name}</label>}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        var val = isAliasPrints ? input4Ref : input6Ref;
                                                        return (
                                                            <div className="input-box-m11">
                                                                <input className="modify_im_input mode_limit mode_category" ref={input3Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val)} type="text" required
                                                                    value={UserDetails.canLst3DgtNo ? (maskMobileNo(inputValues.aMobileNo)) : (inputValues.aMobileNo)}
                                                                    name="aMobileNo" onChange={handleInputChange} />
                                                                <label className="modifyTextLabel">Mobile No.</label>
                                                            </div>
                                                        )
                                                    })()}
                                                    {isAliasPrints && (
                                                        <div className="input-box-m11">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input4Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="text"
                                                                value={inputValues.alias}
                                                                name="alias" onChange={handleInputChange} required />
                                                            <label className="modifyTextLabel">Alias</label>
                                                        </div>
                                                    )}
                                                    {isAliasPrints && (
                                                        <div className="input-box-m11">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input5Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="text"
                                                                value={inputValues.printName}
                                                                name="printName" onChange={handleInputChange} required />
                                                            <label className="modifyTextLabel">Print Name</label>
                                                        </div>
                                                    )}
                                                    <div className="input-box-m11 dm_3">
                                                        <select className="modify_im_input mode_category" ref={input6Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text"
                                                            value={inputValues.parentGrp}
                                                            name="parentGrp" onChange={handleInputChange} required>
                                                            <option Classname="form-control s-1" value="">--Select--</option>
                                                            {(() => {
                                                                if (accountGroup !== null && Array.isArray(accountGroup)) {
                                                                    return accountGroup.map((data) => (
                                                                        <option key={data.id} value={data.id}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            })()}
                                                        </select>
                                                        <label className="ItemMasterLabelFix">Account Group&nbsp;<span className="StarClr">*</span></label>

                                                        <div onClick={showModal2} ref={input7Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input8Ref)}>
                                                            <i className="fa-solid fa-circle-plus dm_4" ></i>
                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        var Val = input11Ref;
                                                        return (
                                                            <div className="input-box-m11">
                                                                <select className="modify_im_input mode_category" ref={input7Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, Val)} type="text"
                                                                    value={inputValues.aState}
                                                                    name="aState" onChange={handleInputChange} required>
                                                                    <option Classname="form-control s-1" value="">Select state</option>
                                                                    {(() => {
                                                                        if (stateInfo !== null && Array.isArray(stateInfo)) {
                                                                            return stateInfo.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.stateName}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label className="ItemMasterLabelFix" >State&nbsp;<span className="StarClr">*</span></label>
                                                            </div>
                                                        )
                                                    })()}

                                                    {(() => {
                                                        var val = isDefaultSalePerson ? input12Ref : input13Ref;
                                                        return (
                                                            <div className="input-box-m11 dm_3">
                                                                <select className="modify_im_input mode_category" ref={input11Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val)} type="text"
                                                                    value={inputValues.aStation}
                                                                    name="aStation" onChange={handleInputChange} required>
                                                                    <option Classname="form-control s-1" value="">--Select--</option>
                                                                    {(() => {
                                                                        if (station !== null && Array.isArray(station)) {
                                                                            return station.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label class="ItemMasterLabelFix">Station&nbsp;<span className="StarClr">*</span></label>
                                                                <div onClick={showModal3}>
                                                                    <i class="fa-solid fa-circle-plus dm_4" ></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })()}

                                                    {isDefaultSalePerson && (
                                                        <div className="input-box-m11">
                                                            <select className="modify_im_input mode_category" ref={input12Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input13Ref)} type="text"
                                                                value={inputValues.aDefaultSalePerson}
                                                                name="aDefaultSalePerson" onChange={handleInputChange} required>
                                                                <option>--Select--</option>
                                                                {(() => {
                                                                    if (defaultSalesPerson !== null && Array.isArray(defaultSalesPerson)) {
                                                                        return defaultSalesPerson.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label class="ItemMasterLabelFix">Default Sale Person</label>
                                                        </div>
                                                    )}
                                                    <div className="input-box-m11">
                                                        <input type="text" className="modify_im_input mode_category" ref={input12Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input13Ref)}
                                                            value={inputValues.password}
                                                            name="password" onChange={handleInputChange} required />

                                                        <label class="ItemMasterLabelFix">Password</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="company_info-text-im">Others Details
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <div style={{ display: "flex" }}>
                                                                <input className="modify_im_input mode_category" ref={input13Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input14Ref)} type="date"
                                                                    // value={birthDate}
                                                                    // onChange={handleDateChange}
                                                                    value={inputValues.adob}
                                                                    name="adob" onChange={handleInputChange}
                                                                    required />
                                                                <label class="modify_lbl_text3">Date of Birth</label>
                                                                <input className="modify_im_input mode_category Marg18" type="text"
                                                                    value={inputValues.aAge}
                                                                    name="aAge" onChange={handleInputChange} />
                                                                <label class="modify_lbl_textage">Age </label>
                                                            </div>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input14Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input15Ref)} type="date"
                                                                value={inputValues.adom}
                                                                name="adom" onChange={handleInputChange} required />
                                                            <label class="modify_lbl_text4">Date of Anniversary</label>
                                                        </div>
                                                    </div>

                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_category" ref={input15Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input16Ref)} type="text"
                                                                value={inputValues.aGender}
                                                                name="aGender" onChange={handleInputChange} required>
                                                                <option>Male</option>
                                                                <option>Female</option>
                                                                <option>Other</option>
                                                            </select>
                                                            <label class="modify_lbl_text3">Gender</label>
                                                        </div>
                                                        {(() => {
                                                            var val = input17Ref;
                                                            return (
                                                                <div className="input-box-m1 dm_3">
                                                                    <select className="modify_im_input mode_category" ref={input16Ref}
                                                                        onKeyDown={(event) => handleKeyPress(event, val)} type="text"
                                                                        value={inputValues.aDepartment}
                                                                        name="aDepartment" onChange={handleInputChange} required>
                                                                        <option Classname="form-control s-1" value="">--Select--</option>
                                                                        {(() => {
                                                                            if (department !== null && Array.isArray(department)) {
                                                                                return department.map((data) => (
                                                                                    <option key={data.id} value={data.id}>
                                                                                        {data.departmentName}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                    <label class="modify_lbl_text4">Department&nbsp;</label>
                                                                    <div>
                                                                        <i onClick={showModalDepartmentDetails} class="fa-solid fa-circle-plus dm_4"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })()}

                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input17Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input19Ref)} type="text"
                                                                value={inputValues.aDrugLicenseNo}
                                                                name="aDrugLicenseNo" onChange={handleInputChange} required />
                                                            <label class="modify_label_text5">Drug License No </label>
                                                        </div>
                                                        <div className="input-box-m1 dm_3">
                                                            <select className="modify_im_input mode_category" ref={input19Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input20Ref)} type="text"
                                                                value={inputValues.aDesignation}
                                                                name="aDesignation" onChange={handleInputChange} required>
                                                                <option Classname="form-control s-1" value="">--Select--</option>
                                                                {(() => {
                                                                    if (designation !== null && Array.isArray(designation)) {
                                                                        return designation.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.designationName}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label class="modify_lbl_text4">Designation&nbsp;</label>
                                                            <div>
                                                                <i onClick={showModalDesignationDetails} class="fa-solid fa-circle-plus dm_4"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-box-Im input-height2">
                                                    {/* <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" ref={input53Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input54Ref)} type="text"
                                                            value={inputValues.aContactperson}
                                                            name="aContactperson" onChange={handleInputChange} required />
                                                        <label class="modify_lbl_text3">Tel. No</label>
                                                    </div> */}

                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" ref={input20Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input21Ref)} type="text"
                                                            value={inputValues.contactpersonMbl}
                                                            name="contactpersonMbl" onChange={handleInputChange} required />
                                                        <label class="modify_lbl_text3">Tel. No</label>
                                                    </div>

                                                    {(() => {
                                                        var val5 = isInputVisiblam3 ? input23Ref : input30Ref;
                                                        return (
                                                            <div className="input-box-m1">
                                                                <input className="modify_im_input mode_limit mode_category" ref={input21Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, val5)} type="text"
                                                                    value={inputValues.aContactperson}
                                                                    name="aContactperson" onChange={handleInputChange} required />
                                                                <label class="modify_lbl_text2">Contact Person</label>
                                                            </div>
                                                        )
                                                    })()}
                                                </div>
                                                {isInputVisiblam3 && (
                                                    <div>
                                                        <div className="company_info-text-im">Account Details</div>
                                                        <div className="input-box-Im input-height2">
                                                            <div className="input-box-m1">
                                                                <input className="modify_im_input mode_category" ref={input23Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input24Ref)} type="text"
                                                                    value={inputValues.aOpeningBal}
                                                                    name="aOpeningBal" onChange={handleInputChange} required />
                                                                <label class="modify_lbl_text3">Opening Balance</label>
                                                            </div>
                                                            {/* <div className="input-box-m1">
                                                                <input className="modify_im_input mode_limit mode_category" ref={input21Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input22Ref)} type="text" required />
                                                                <label class="modify_lbl_text2">(Cr/Dr)</label>
                                                            </div> */}
                                                            <div className="input-box-m1">
                                                                <input className="modify_im_input mode_category" ref={input24Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input25Ref)} type="text"
                                                                    value={inputValues.aCreditlimit}
                                                                    name="aCreditlimit" onChange={handleInputChange} required />
                                                                <label class="modify_lbl_text2">Credit Limit</label>
                                                            </div>
                                                        </div>

                                                        <div className="input-box-Im input-height2">
                                                            <div className="input-box-m1 user_add">
                                                                <select className="modify_im_input mode_category" ref={input25Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input26Ref)} type="text"
                                                                    value={inputValues.aAccountCategory}
                                                                    name="aAccountCategory" onChange={handleInputChange} required>
                                                                    <option Classname="form-control s-1" value="">--Select--</option>
                                                                    {(() => {
                                                                        if (accountCategory !== null && Array.isArray(accountCategory)) {
                                                                            return accountCategory.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label class="modify_lbl_text3">Ac/Category&nbsp;</label>
                                                                <div>
                                                                    <i onClick={showModalAccCategoryMaster} class="fa-solid fa-circle-plus dm_4"></i>
                                                                </div>
                                                            </div>
                                                            <div className="input-box-m1">
                                                                <select //value={ishandleInputChangeEcom ? 'Yes' : 'No'}
                                                                    ref={input26Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input27Ref)} //onChange={handleInputChangeEcom2} 
                                                                    className="modify_im_input mode_category" type="text"
                                                                    value={inputValues.aEnableLoyalty}
                                                                    name="aEnableLoyalty" onChange={handleInputChange} required>
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                                <label class="modify_lbl_text2">Enable loyalty </label>
                                                            </div>
                                                        </div>

                                                        <div className="input-box-Im input-height2">
                                                            <div className="input-box-m1">
                                                                <input className="modify_im_input mode_limit mode_category" ref={input27Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input28Ref)} type="text"
                                                                    value={inputValues.aLoyaltyCardNo}
                                                                    name="aLoyaltyCardNo" onChange={handleInputChange} required />
                                                                <label class="modify_lbl_text3">Loyality Card No.</label>
                                                            </div>
                                                            <div className="input-box-m1">
                                                                <select className="modify_im_input mode_category" ref={input28Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input29Ref)} type="text"
                                                                    value={inputValues.referralBy}
                                                                    name="referralBy" onChange={handleInputChange} required>
                                                                    <option>--Select--</option>
                                                                    {(() => {
                                                                        if (defaultSalesPerson !== null && Array.isArray(defaultSalesPerson)) {
                                                                            return defaultSalesPerson.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label class="modify_lbl_text2">Referral By</label>
                                                            </div>
                                                        </div>

                                                        <div className="input-box-Im input-height2">
                                                            <div className="input-box-m1">
                                                                <select className="modify_im_input mode_category" ref={input29Ref}
                                                                    onKeyDown={(event) => handleKeyPress(event, input30Ref)} type="text"
                                                                    value={inputValues.assignUser}
                                                                    name="assignUser" onChange={handleInputChange} required>
                                                                    <option>--Select--</option>
                                                                    {(() => {
                                                                        if (assignUser !== null && Array.isArray(assignUser)) {
                                                                            return assignUser.map((data) => (
                                                                                <option key={data.id} value={data.id}>
                                                                                    {data.userName}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    })()}
                                                                </select>
                                                                <label class="modify_lbl_text3">Assign User</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {isAccountMaster && (
                                        <div className="company-info-top-right" style={{ width: '50%' }} >
                                            <div className="company_info-text-im">Address Details</div>
                                            <div className="stock-im-1">
                                                <div className="input-box-tab-head">
                                                    <div className="input-box-m2">
                                                        <textarea className="modify_im_textarea mode_category2" ref={input30Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input31Ref)} type="text" placeholder=""
                                                            value={inputValues.aAddress}
                                                            name="aAddress" onChange={handleInputChange} required />
                                                        <label class="modify_p_text-fix">Address</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" ref={input31Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input32Ref)} type="text"
                                                            value={inputValues.aEmail}
                                                            name="aEmail" onChange={handleInputChange} required />
                                                        <label class="modify_lbl_text3">E-Mail
                                                            {/* <span className="StarClr">*
                                                                {errorMessages.aEmail && <label className="errorMessage">{errorMessages.aEmail}</label>}
                                                            </span> */}
                                                        </label>
                                                    </div>
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_category" ref={input32Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input33Ref)} type="text"
                                                            value={inputValues.aPincode}
                                                            name="aPincode" onChange={handleInputChange} required />
                                                        <label class="modify_lbl_text2" style={{}}>PinCode</label>
                                                    </div>
                                                </div>

                                                <div className="input-box-Im input-height2">
                                                    <div className="input-box-m1">
                                                        <input className="modify_im_input mode_limit mode_category" ref={input33Ref}
                                                            onKeyDown={(event) => handleKeyPress(event, input34Ref)} type="text"
                                                            value={inputValues.aDistance}
                                                            name="aDistance" onChange={handleInputChange} required />
                                                        <label class="modify_lbl_text3">Distance.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {isInputVisiblam2 && (
                                                <div>
                                                    <div className="company_info-text-im">Document Details</div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <select className="modify_im_input mode_category" ref={input34Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input35Ref)} type="text"
                                                                value={inputValues.accountDealerType}
                                                                name="accountDealerType" onChange={handleInputChange} required>
                                                                <option>UnRegistered</option>
                                                                <option>Registered</option>
                                                                <option>Composition</option>
                                                                <option>UIN Holder</option>
                                                            </select>
                                                            <label class="modify_lbl_text3">Ac Dealer Types&nbsp;<span className="StarClr">*</span></label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input35Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input36Ref)} type="text"
                                                                value={inputValues.agStINNo}
                                                                name="agStINNo" onChange={handleInputChange} required />
                                                            <label class="modify_lbl_text2">GSTTIN No.</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input36Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input37Ref)} type="text"
                                                                value={inputValues.aitPan}
                                                                name="aitPan" onChange={handleInputChange} required />
                                                            <label class="modify_label_text5">IT PAN</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input37Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input38Ref)} type="text"
                                                                value={inputValues.alstNo}
                                                                name="alstNo" onChange={handleInputChange} required />
                                                            <label class="modify_lbl_text2">LST No.</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_category" ref={input38Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input39Ref)}
                                                                value={inputValues.aLocation}
                                                                name="aLocation" onChange={handleInputChange} type="text" required />
                                                            <label class="modify_label_text5">CST No./Location</label>
                                                        </div>
                                                        <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input39Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input40Ref)} type="text"
                                                                value={inputValues.aAadharNo}
                                                                name="aAadharNo" onChange={handleInputChange} required />
                                                            <label class="modify_lbl_text2">Adhar Card No.</label>
                                                        </div>
                                                    </div>
                                                    <div className="input-box-Im input-height2">
                                                        <div className="input-box-m1">
                                                            {/* <input className="modify_im_input mode_category" ref={input50Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input51Ref)} type="text"
                                                                value={inputValues.doNotDisturb}
                                                                name="doNotDisturb" onChange={handleInputChange} required /> */}

                                                            <select className="modify_im_input mode_category" ref={input40Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input41Ref)} type="text"
                                                                value={inputValues.doNotDisturb}
                                                                name="doNotDisturb" onChange={handleInputChange} required>
                                                                <option Classname="form-control s-1" value="No">No</option>
                                                                <option Classname="form-control s-1" value="Yes">Yes</option>
                                                            </select>
                                                            <label class="modify_label_text5">DND</label>
                                                        </div>
                                                        {/* <div className="input-box-m1">
                                                            <input className="modify_im_input mode_limit mode_category" ref={input51Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input52Ref)} type="text"
                                                                value={inputValues}
                                                                name="" onChange={handleInputChange} required />
                                                            <label class="modify_lbl_text2">Day Limit</label>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )}
                                            {isInputVisiblam1 && (
                                                <div>
                                                    <div className="company_info-text-im">Transport Details</div>
                                                    <div className="input-box-Im input-height2" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                        <div className="input-box-m1 user_add">
                                                            <select className="modify_im_input mode_category" ref={input41Ref}
                                                                onKeyDown={(event) => handleKeyPress(event, input42Ref)} type="text"
                                                                value={inputValues.aDefaultTranspoter}
                                                                name="aDefaultTranspoter" onChange={handleInputChange} required>
                                                                <option Classname="form-control s-1" value="">--Select--</option>
                                                                {(() => {
                                                                    if (transporter !== null && Array.isArray(transporter)) {
                                                                        return transporter.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                })()}
                                                            </select>
                                                            <label class="modify_lbl_text3">Transporter&nbsp;</label>
                                                        </div>
                                                        <div onClick={showTransporter}
                                                            onKeyDown={(event) => handleKeyPress(event, "")}>
                                                            <i class="fa-solid fa-circle-plus dm_4" ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {isOptionalField && (
                                                <div>
                                                    <div className="company_info-text-im">Optional Field Detail</div>
                                                    <div className="HeadContentInputBpx">
                                                        {customfields && customfields.length !== 0 && customfields.map((fields, index) => (
                                                            <div className="input-box-m11" key={fields.id}>
                                                                <input
                                                                    onChange={(event) => handleOptionalDataChange(event, fields.id)}
                                                                    className="modify_im_input mode_limit mode_category"
                                                                    name={fields.optionalFieldName}
                                                                    value={customFieldValues[fields.optionalFieldName]?.[fields.id] || ''}
                                                                    type="text"
                                                                    required
                                                                />
                                                                <label className="modifyTextLabel">{fields.optionalFieldName}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="company_info-text-im">Image Details</div>
                                            <div className="stock-im-1">
                                                <div className="input-box-Im1">
                                                    <p class="modify_p_2"></p>
                                                    <div className="radio-buttons-container1 m2 overstack">
                                                        <ImageUploadWithPreview id={"item10"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ************************ */}
            {alertBox && (
                <AlertBox
                    HeadingTop="Saved"
                    Heading="Your Account Master is Saved"
                    onYesClick={OpenAlertBox}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBox}
                />
            )}
            {alertBoxEdit && (
                <AlertBox
                    HeadingTop="Updated"
                    Heading="Your Account Master is Updated"
                    onYesClick={OpenAlertBoxEdit}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxEdit}
                />
            )}

            {alertBoxErrorUpdate && (
                <AlertBoxError
                    HeadingTop="ERROR"
                    Heading="Error While Updating Data"
                    onYesClick={OpenAlertBoxErrorUpdate}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxErrorUpdate}
                />
            )}
            {alertBoxErrorInsert && (
                <AlertBoxError
                    HeadingTop="ERROR"
                    Heading="Error While Inserting Data"
                    onYesClick={OpenAlertBoxErrorInsert}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxErrorInsert}
                />
            )}

            {openAlertItemExists && (
                <AlertBoxError
                    HeadingTop="Account already exists"
                    Heading="Try With Different Email and Mobile"
                    onYesClick={OpenAlertBoxWhenItemExists}
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxWhenItemExists}
                />
            )}
            {loading && <Loader />}
        </div >
    );
}
export default AccountMaster1;
