import { useContext, useEffect, useState, useHistory } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import MyContext from "../../MyContext";
import ItemMasterApi from "../../../API/APIServices/ItemMaster";
import AlertBox from "../../Props/AlertBox";

import Loader from "../../Props/Loader";
import DataTable from "../TableMaster/DataTable";
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import AlertBoxError from "../../Props/AlertBoxError";
import CouponMasterApi from "../../../API/APIServices/CouponMaster";
import CouponMaster from "./CouponMaster";

function CouponMasterList() {
    const {
        OpenAlertBoxDelete,
        alertBoxDelete,
        setAlertBoxDelete,
        input1,
        input2,
        input3,
        input4,
        setAlertBoxCantDelete,
        alertBoxCantDelete,
        OpenAlertBoxDeleteCant,
    } = useContext(MyContext);

    const navigate = useNavigate();

    const [mainData, setMainData] = useState([]);
    const [CouponMasterList, setCouponMasterList] = useState([]);
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("")

    console.log(CouponMasterList)

    const toggleSlider = (value) => {
        //   console.log(editData);
        setSliderOpen(value);
    };

    const fetchData = async () => {
        debugger
        setLoading(true);
        try {
            const val = await CouponMasterApi.getCouponMaster();
            setCouponMasterList(val)
            console.log(val)
            const newData = val.map((item, i) => {
                return {
                    input: <input type="checkbox" />,
                    sno: i + 1,
                    name: item.couponName,
                    Id: item.id,
                    // d2Id: item.d2Id,
                    CouponMethod: item.couponMethod,
                    action: (
                        <div className="tablelist-action-box">
                            <i
                                className="fa-regular fa-pen-to-square TableEditIcon"
                                onClick={() => Edit(item.id)}
                            ></i>
                            <i
                                className="fa-regular fa-trash-can TableDelIcon"
                                onClick={() => DeleteAccount(item.id)}
                            ></i>
                            <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                            <i className="fa-solid fa-print TablePrint"></i>

                        </div>
                    ),
                };
            });
            setMainData(newData);
            setLoading(false);
            // console.log(val);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [getdeleteId, setDeleteId] = useState(null);

    const DeleteAccount = (id) => {
        setAlertBoxDelete(true);
        setDeleteId(id);
    };

    const DeleteAccountConfirm = async () => {
        debugger
        setLoading(true);
        try {
            const val = await CouponMasterApi.deleteCouponMaster(getdeleteId);
            if (Boolean(val) === true) {
                setAlertBoxDelete(false);
                setDeleteId(null);
                setLoading(false);
            } else {
                setAlertBoxCantDelete(true)
                // alert("Unable to delete as this product is already used in another place");
                setLoading(false);
            }
            fetchData();
        } catch (error) {
            setLoading(false);
            console.error("Error deleting account:", error);
        }
    };

    //  const[data , setData] = useState({})
    const [editData, setEditData] = useState("");

    const Edit = async (id) => {
        const data = await CouponMasterApi.getCouponMasterId(id);
        console.log(data);
        setEditData(data);
        toggleSlider(true);
    };



    const columns = [
        { Header: "", accessor: "input" },
        { Header: "SNO", accessor: "sno" },
        { Header: "NAME", accessor: "name" },
        { Header: "Id", accessor: "Id" },
        // { Header: "D2Id", accessor: "d2Id" },
        { Header: "CouponMethod", accessor: "CouponMethod" },
        { Header: "ACTION", accessor: "action" },
    ];

    const [debouncedInput, setDebouncedInput] = useState("");
    // Debounce logic
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedInput(searchValue); // Set the actual debounced value
        }, 500);

        return () => clearTimeout(timer); // Clear the timeout on every change
    }, [searchValue]);

    // Fetch data when the debounced input changes
    useEffect(() => {
        if (debouncedInput) {
            const fetchData = async () => {
                const filteredData = [
                    {
                        fieldName: searchName,
                        operatorName: "contains",
                        compareValue: searchValue || "",
                    },
                ];

                // const SearchItem = await ItemMasterApi.GetItemMasterWithFilter(filteredData)
                // console.log(SearchItem)
                // setCouponMasterList(SearchItem)
                // console.log(SearchItem)
                // const newData = SearchItem.map((item, i) => {
                //     return {
                //         input: <input type="checkbox" />,
                //         sno: i + 1,
                //         name: item.name,
                //         d1Id: item.d1Id,
                //         d2Id: item.d2Id,
                //         attributeParentName: item.attributeParentName,
                //         action: (
                //             <div className="tablelist-action-box">
                //                 <i
                //                     className="fa-regular fa-pen-to-square TableEditIcon"
                //                     onClick={() => Edit(item.d2Id)}
                //                 ></i>
                //                 <i
                //                     className="fa-regular fa-trash-can TableDelIcon"
                //                     onClick={() => DeleteAccount(item.d2Id)}
                //                 ></i>
                //                 <i className="fa-brands fa-square-whatsapp TableWhatsappcon"></i>
                //                 <i className="fa-solid fa-print TablePrint"></i>
                //                 <Link to={`/LensMaster/${item.d2Id}`} state={{ item }}    >
                //                     <i class="fa-solid fa-gear"></i>
                //                 </Link>
                //             </div>
                //         ),
                //     };
                // });
                // if (newData) {
                //     setMainData(newData)
                //     setLoading(false)
                // }
            };
            fetchData();
        }
        else if (debouncedInput == "") {
            fetchData();
        }
    }, [debouncedInput]);

    const [searchName, setSearchName] = useState("");

    const searchFunction = async (e) => {
        setSearchValue(e.target.value);
        setSearchName(e.target.name);
        setLoading(true)
    }

    const filterData = [
        { Header: "", accessor: "" },
        { Header: "", accessor: "" },
        {
            Header: <input type="text" placeholder="Name" name="name" ref={input1} onChange={searchFunction} />,
            accessor: "name",
        },
        {
            Header: <input type="text" placeholder="Id" name="id" ref={input3} onChange={searchFunction} />,
            accessor: "Id",
        },
        // {
        //     Header: <input type="text" placeholder="ID" ref={input2} name="D2Id" onChange={searchFunction} />,
        //     accessor: "d2Id",
        // },
        {
            Header: <input type="text" placeholder="CouponName" ref={input4} name="CouponName" onChange={searchFunction} />,
            accessor: "CouponName",
        },
    ];

    return (
        <div className="">
            <PageTabs
                locationTab={"/CouponMaster"}
                modelOpen={true}
                setSliderCallBack={setSliderOpen}
            />
            <div className="nav-1">
                <div className="panel-heading">
                    <h2 className="panel-title">Coupon Master List</h2>
                    <TableButton
                        filterData={filterData}
                        columns={columns}
                        PageCreate={"/CouponMaster"}
                        data={mainData}
                        PageName="Masters"
                    />
                </div>
                <DataTable columns={columns} data={mainData} filterData={filterData} />
            </div>
            <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
                <div style={{ padding: "15px" }}>
                    <CouponMaster
                        data={editData}
                        modelOpen={true}
                        setSliderCallBack={setSliderOpen}
                        fetchData={fetchData}
                        variable={isSliderOpen}
                        CouponMasterList={CouponMasterList}
                    />
                </div>
            </div>
            {alertBoxDelete && (
                <AlertBox
                    HeadingTop="DELETE"
                    Heading="Are You sure you want to delete this"
                    OpenAlertBox={OpenAlertBoxDelete}
                    onYesClick={DeleteAccountConfirm}
                />
            )}
            {alertBoxCantDelete && (
                <AlertBoxError
                    HeadingTop="Alert"
                    Heading="Unable to delete as this product is already used in another place"
                    singleButton={true}
                    OpenAlertBox={OpenAlertBoxDeleteCant}
                    onYesClick={OpenAlertBoxDeleteCant}
                />
            )}
            {loading && <Loader />}
        </div>
    );
}

export default CouponMasterList;
