import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const BillSeriesWiseTemplateSetting = {
  CreateBillSeriesWiseTemplateSetting: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BillSeriesWiseTemplateSetting/Create`;
      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetAllBillSeriesWiseTemplateSetting: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/BillSeriesWiseTemplateSetting/GetAllBillSeriesWiseTemplateSetting`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  GetAllBillSeriesWiseTemplateSettingById: async (Id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BillSeriesWiseTemplateSetting/GetAllBillSeriesWiseTemplateSettingById/${Id}`;
      const response = await axios.get(apiUrl, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  UpdateBillSeriesWiseTemplateSetting: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BillSeriesWiseTemplateSetting/Update`;
      const response = await axios.put(apiUrl, data, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  DeleteBillSeriesWiseTemplateSettingById: async (Id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/BillSeriesWiseTemplateSetting/DeleteById/${Id}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
};

export default BillSeriesWiseTemplateSetting;
