import React, { useState, useRef, useEffect } from "react";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";

function ModelReportFor({ onMasterIdChange, onCloseModal }) {
  //*****************{title}*********************/
  //     const {
  // masterId,
  //   } = props;
  //******************{modal box}******************/ /
  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  //*********************{API}*********************//
  const [s32, setS32] = useState("");
  console.log(s32);

  const handleSave = async () => {
    debugger;
    if (!s32.trim()) {
      alert("Please provide a valid Master ID.");
      return;
    }
    const s31 = "GetMasterType"; // Your static value for s31
    try {
      const result = await TemplateSettingApi.createSelectedMasterType(
        s31,
        s32
      );
      console.log("Save result:", result);
      if (result) {
        // setS32(result);
        onMasterIdChange(result);
        console.log("Save successful:", result);
        alert("Data saved successfully");
        onCloseModal();
      } else {
        alert("Data is not saved");
      }
    } catch (error) {
      console.error("Save failed:", error);
      alert("Data is not saved");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TemplateSettingApi.getAllMasterType();
        if (response && Array.isArray(response)) {
          const commaSeparated = response
            .map((item) => (typeof item === "string" ? item : item.id))
            .join(", ");
          setS32(commaSeparated);
        } else {
          console.error("Error:", response.errorMessages);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  /***********End Enter code*****************/

  // const input1Ref = useRef(null);
  // const input2Ref = useRef(null);
  // const input3Ref = useRef(null);
  // const input4Ref = useRef(null);
  // const input5Ref = useRef(null);
  // const input6Ref = useRef(null);

  // const handleKeyPress = (event, nextInputRef) => {
  //     // console.log(isInputVisiblemc1);
  //     if (event.key === 'Enter') {
  //         event.preventDefault();
  //         nextInputRef.current.focus();
  //     }
  // };

  console.log(s32);

  return (
    <div>
      <div className="">
        <div
        // className="company_control"
        >
          {/* <div className="stock-im-dep1">
                    <div className="input-box-Imo1 input-height2">
                        <div className="input-box-mok1">
                           
                            <label class="modify_p_text2">Get Master Name</label>
                        </div>
                    </div>
                </div> */}

          <div
            // className="stock-im-dep1"
            style={{ padding: "15px" }}
          >
            <div className="input-box-mok1">
              <label class="modify_p_text2" style={{ top: "30px" }}>
                Get Master Name
              </label>
            </div>
            <div className="input-box-Imo1 input-height2">
              <div className="input-box-mok1">
                <textarea
                  className="modify_im_input mode_category1"
                  type="text"
                  name="s32"
                  value={s32}
                  onChange={(e) => setS32(e.target.value)}
                  //  onChange={handleInputChange}
                  style={{ height: "100px" }}
                  required
                />
                <label class="modify_p_text2">Master Id</label>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="Save-flex-btn">
          <button className="temp-save" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModelReportFor;
