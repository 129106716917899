import axios from "../axiosConfig"; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const BillType = {

  GetBillType: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        //const apiUrl = `${express_server_url}/api/BillType/GetBillTypes`;
        const apiUrl = `${WebApiUrl}/api/BillType/GetBillTypes`;
        const response = await axios.post(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        // console.log(apiUrl);
        console.log("Response:", response.data.result);
        return response.data.result;
        // setMainDataCallback(response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  insertBillType: async (data) => {
    try {
      //const apiUrl = `${express_server_url}/api/BillType/BillType`;
      const apiUrl = `${WebApiUrl}/api/BillType/BillType`;
      console.log(apiUrl);
      console.log(data);
      const response = await axios.post(apiUrl, {
        id: Number(data.id),
        billType: Number(data.billType),
        billTypeName: data.billTypeName,
        specifyAccInfo_Sale: Number(data.specifyAccInfo_Sale),
        accForGoods: Number(data.accForGoods),
        accForService: Number(data.accForService),
        typeOfTax: Number(data.typeOfTax),
        taxOn: Number(data.taxOn),
        modeOfTax: data.modeOfTax,
        calculatTaxOn: Number(data.calculatTaxOn),
        adjTaxInSale: Boolean(data.adjTaxInSale),
        tCgstAcc: data.tCgstAcc,
        tSgstAcc: data.tSgstAcc,
        tIgstAcc: data.tIgstAcc,
        tCesstAcc: data.tCesstAcc,
        taxRegion: Number(data.taxRegion),
        sundryDiscDistri: Boolean(data.sundryDiscDistri),
        transactionType: data.transactionType,
        taxRateType: Number(data.taxRateType),
        cgsTper: Number(data.cgsTper),
        sgsTper: Number(data.sgsTper),
        igsTper: Number(data.igsTper),
        cesSper: Number(data.cesSper),
        isDefault: Boolean(data.isDefault)
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetBillTypeById: async (BillTypeId) => {
    try {
      console.log("get by id");
      //const apiUrl = `${express_server_url}/api/BillType?BillTypeId=${BillTypeId}`;
      const apiUrl = `${WebApiUrl}/api/BillType?BillTypeId=${BillTypeId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // *********************** Filtered **************************
  GetFilteredBillType: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/BillType/GetBillTypes`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 0
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },


  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${express_server_url}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //      console.error('Error:', error.message);
  //   }
  // },

  deleteBillType: async (BillTypeId) => {
    try {
      console.log("delete");
      //const apiUrl = `${express_server_url}/api/BillType?BillTypeId=${BillTypeId}`;
      const apiUrl = `${WebApiUrl}/api/BillType?BillTypeId=${BillTypeId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  updateBillType: async (data) => {
    console.log(data);
    try {
      //const apiUrl = `${express_server_url}/api/BillType?BillTypeId=${data.id}`;
      const apiUrl = `${WebApiUrl}/api/BillType?BillTypeId=${data.id}`;
      const response = await axios.put(apiUrl, {
        id: Number(data.id),
        billType: Number(data.billType),
        billTypeName: data.billTypeName,
        specifyAccInfo_Sale: Number(data.specifyAccInfo_Sale),
        accForGoods: Number(data.accForGoods),
        accForService: Number(data.accForService),
        typeOfTax: Number(data.typeOfTax),
        taxOn: Number(data.taxOn),
        modeOfTax: data.modeOfTax,
        calculatTaxOn: Number(data.calculatTaxOn),
        adjTaxInSale: Boolean(data.adjTaxInSale),
        tCgstAcc: data.tCgstAcc,
        tSgstAcc: data.tSgstAcc,
        tIgstAcc: data.tIgstAcc,
        tCesstAcc: data.tCesstAcc,
        taxRegion: Number(data.taxRegion),
        sundryDiscDistri: Boolean(data.sundryDiscDistri),
        transactionType: data.transactionType,
        taxRateType: Number(data.taxRateType),
        cgsTper: Number(data.cgsTper),
        sgsTper: Number(data.sgsTper),
        igsTper: Number(data.igsTper),
        cesSper: Number(data.cesSper),
        isDefault: Boolean(data.isDefault),
        isActive: Boolean(data.isActive) || true,
      },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
};

export default BillType;
