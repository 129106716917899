import axios from "../axiosConfig";
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const WebTemplate = {

  CreateCustomizedPageTemplate: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/CreateCustomizedPageTemplate`;
      console.log(apiUrl);
      data.textContents = encodeURIComponent(data.textContents)
      console.log(data);
      const response = await axios.post(apiUrl, {
        data
      }, {
        withCredentials: true,
        credentials: 'include',
      });

      console.log("Response:", response.data);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ************************* Get All ***************************
  GetCustomizedPageTemplates: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplates`;
        const response = await axios.post(apiUrl, {}, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Get All ***************************
  getTableFilter: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/Table/GetTables`;
        const response = await axios.post(apiUrl, {
          filter: [],
          order: [
            {
              propertyName: "id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 500000
        }, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result);
        return response.data.result;

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

  // ************************* Delete ****************************
  deleteTable: async (tableId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Table?tableId=${tableId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },

  // ************************* Get By Id ****************************
  GetCustomizedPageTemplate: async (Templateid) => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplate?Templateid=${Templateid}`;
        const response = await axios.get(
          apiUrl,
          {},
          {
            withCredentials: true,
          }
        );
        console.log("Response:", response.data.result);
        return response.data.result;
      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },
  // *************************** Update ***************************
  UpdateCustomizedPageTemplate: async (TemplateData) => {
    // TemplateData.textContents  =   TemplateData.textContents
    // TemplateData.jsonStructure  =   JSON.stringify(TemplateData.jsonStructure)

    TemplateData.jsonStructure = encodeURIComponent(TemplateData.jsonStructure);
    TemplateData.textContents = encodeURIComponent(TemplateData.textContents);
    console.log(TemplateData);
    try {
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/UpdateCustomizedPageTemplate?Templateid=${TemplateData.id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {
          TemplateData,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },
  // ***********************************************************************
  UpdateCustomizedPageTemplateImage: async (formData, Templateid) => {
    console.log(formData)
    try {
      // Assuming 'data' is a FormData object with appended fields
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/UpdateCustomizedPageTemplateImage?CustomizedPageTemplateId=${Templateid}`;

      const response = await axios.post(apiUrl,
        formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',  // Set Content-Type for FormData
        },
      });
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // ***********************************************************************
  CustomizedPageTemplateImageDetailWithTemplateId: async (CustomizedPageTemplateId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/CustomizedPageTemplateImageDetail?CustomizedPageTemplateId=${CustomizedPageTemplateId}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
      if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        localStorage.removeItem('loginAccountUserData');
        localStorage.removeItem("loginUser");
        // localStorage.clear();
        await User.Logout();
         // window.location.href = "/loginpanel";
      }
    }
  },
  // ************************* Get All ***************************
  GetCustomizedPageTemplatesWithFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplatesWithFilter`;
        const response = await axios.post(apiUrl, {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: 0,
          pageSize: 50000
        }, {
          withCredentials: true,
        });
        console.log('Response:', response.data.result[0]);
        if (response.data.result[0]) {
          return response.data.result[0];
        }

      } catch (error) {
        console.error('Error:', error.message);
        if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
          localStorage.removeItem('loginAccountUserData');
          localStorage.removeItem("loginUser");
          // localStorage.clear();
          await User.Logout();
           // window.location.href = "/loginpanel";
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
      }
    }
    return fetchData();
  },

};

export default WebTemplate;
