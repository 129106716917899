import React, { useCallback, useEffect, useRef, useState } from 'react'
import AppHeader from '../AppHeader'
import User from '../../API/APIServices/User';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import MyContext from '../../Inventory/MyContext';
import Account from '../../API/APIServices/Account';
import menuImage from '../../Inventory/Assets/img/btn_menu.png';
import ItemMaster from '../../API/APIServices/ItemMaster';
import Loader from '../../Inventory/Props/Loader';
import Table from '../../API/APIServices/Table';
import TableAllocation from '../../API/APIServices/TableAllocation';
import useIntersectionObserver from './useIntersectionObserver'
import LazyLoadImage from './LazyLoadImage';
import Order from '../../API/APIServices/Order';
import { set } from 'react-hook-form';
import CommonAlertBox from '../../Inventory/Props/CommonAlertBox';

const POSInvoiceForMobile = (props) => {

    const { itemGroupList, itemGroupClick, itemDepartmentActiveIndex, containerRef, filteredItems, isAvailableForService, ItemButtonClick, companyData, imageRef, handleDecrement,
        onQuantityChange, handleIncrement, cartData, order2, setOrder2, deleteCartItemClick, openCommentModal, handleFocus, handleDelPreferenceChange, totalAmount,
        tableGroup, tableNumberData, onFloorSelect, onTableSelect, orderData, selectStyle, AllocatedTimeSlots, setOrderData, ConfirmAndKot, setShowPrintModal,
        isButtonVisible, ord1Id, d1Id, d2Id, handleTableSelect, tableId, tableGroupId, setFilteredItems, setLoginAccountData, fetchItemMasterData, handleIncrementPOS, openVariableItemModal,
        menuItems, setCartData, setEcomCartData, ecomCartData } = props;

    // console.log("cartData", cartData);
    // console.log("orderData", orderData);

    const { imageBaseUrl } = useContext(MyContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);

    const [isShowAlertBox, setIsShowAlertBox] = useState(false);
    const [isSingleButton, setIsSingleButton] = useState(false);
    const [headingTop, setHeadingTop] = useState("Alert");
    const [message, setMessage] = useState("");
    const [closeBtnName, setCloseBtnName] = useState("Close");
    const [okayBtnName, setOkayBtnName] = useState("OK");

    const closeBtn = () => {
        setIsShowAlertBox(false);
        setShowAlertBoxForTable(false);
    }

    const toggleSidebar = () => {
        setIsMenuModalOpen(true);
        // try {
        //     const sidebarElement = document.getElementById("sidebar1");
        //     const scrollBarElement = document.getElementById("scrollBar");
        //     const bodyElement = document.getElementById("body");

        //     // Check current state and toggle
        //     if (sidebarElement.style.left === "0px") {
        //         // Close the sidebar
        //         sidebarElement.style.left = "-300px";
        //         bodyElement.classList.remove("back-blur");
        //         scrollBarElement.classList.add("scroll-container");
        //         scrollBarElement.style.overflow = "scroll";
        //     } else {
        //         // Open the sidebar
        //         sidebarElement.style.left = "0px";
        //         bodyElement.classList.add("back-blur");
        //         scrollBarElement.classList.remove("scroll-container");
        //         scrollBarElement.style.overflow = "hidden";
        //     }
        // } catch (error) {
        //     console.error("An error occurred in toggleSidebar:", error);
        // }
    };

    const closeSidebar = () => {
        setIsMenuModalOpen(false);
        // try {
        //     const sidebarElement = document.getElementById("sidebar1");
        //     const scrollBarElement = document.getElementById("scrollBar");
        //     const bodyElement = document.getElementById("body");

        //     if (sidebarElement.style.left === "0px") {
        //         sidebarElement.style.left = "-300px";
        //         bodyElement.classList.remove("back-blur");
        //         scrollBarElement.classList.add("scroll-container");
        //         scrollBarElement.style.overflow = "scroll";
        //     }
        // } catch (error) {
        //     console.error("An error occurred in toggleSidebar:", error);
        // }
    }

    const handleLogOutClick = async () => {
        if (window.confirm("Are you sure? Your want to LogOut")) {
            localStorage.removeItem('loginAccountUserData');
            localStorage.removeItem("loginUser");
            await User.Logout();
            localStorage.clear();
            // window.location.href = "http://122.168.117.143:44483/loginpanel";
            // window.location.href = `${webToAppLink}/loginpanel`;
            // navigate("/loginpanel")
        }
    }

    const [isRunningOrder, setIsRunningOrder] = useState(false);
    const [totalAmountForMobile, setTotalAmountForMobile] = useState((totalAmount || 0))
    const [tableModal, setTableModal] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                console.log("ord1Id", ord1Id)
                if (ord1Id && ord1Id != 0 && ord1Id != "") {
                    await onFloorSelect(tableGroupId);
                    await handleTableSelect(tableId, "Busy");
                    await setIsRunningOrder(true);
                    await setTableModal(false);
                }
                setLoading(false);
            } catch (err) {
                console.log("error", err);
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const calculateTotalPrice = () => {
        let total = 0;
        if (cartData && cartData.length > 0) {
            for (let i = 0; i < cartData.length; i++) {
                const price = parseFloat((cartData[i].quantity || cartData[i].qty) * cartData[i].salePrice);
                if (!isNaN(price)) {
                    total += price;
                }
            }
            setTotalAmountForMobile(total);
            console.log("total", total);
        }
    }

    useEffect(() => {
        if (ord1Id && ord1Id != 0 && ord1Id != "") {
            calculateTotalPrice();
        }
        else {
            setTotalAmountForMobile(0);
        }
    }, [cartData])

    const [cartDetailModal, setCartDetailModal] = useState(false);

    const openCartDetailModal = () => {
        setCartDetailModal(true);
    }

    const closeCartDetailModal = () => {
        setCartDetailModal(false);
    }

    const debounceTimerForItemSuggestion = useRef(null);

    const saveSuggestion = (e, d2Id) => {
        const value = e.target.value;
        const itemIndex = order2.findIndex(item => item.d2Id === d2Id);
        if (itemIndex !== -1) {
            const updatedOrder2 = [...order2];
            updatedOrder2[itemIndex] = {
                ...updatedOrder2[itemIndex],
                "itemSuggestion": value
            };
            setOrder2(updatedOrder2);
            // console.log(updatedOrder2);
        }
        const itemIndex1 = cartData.findIndex(item => item.d2Id === d2Id);
        if (itemIndex !== -1) {
            const updatedOrder2 = [...cartData];
            updatedOrder2[itemIndex1] = {
                ...updatedOrder2[itemIndex1],
                "itemSuggestion": value
            };
            setCartData(updatedOrder2);
            // console.log(updatedOrder2);
        }
        if (debounceTimerForItemSuggestion.current) {
            clearTimeout(debounceTimerForItemSuggestion.current);
        }

        debounceTimerForItemSuggestion.current = setTimeout(async () => {
            const dataToSend = [
                {
                    fieldName: "tableId",
                    operatorName: "Equal",
                    compareValue: orderData.tableId,
                }
            ]
            const ecomCartData = await Order.getAllEcomCartDetailsFilter(dataToSend)
            const filteredData = [
                {
                    operationType: 0,
                    path: "itemSuggestion",
                    op: "Add",
                    from: "string",
                    value: value
                }
            ]
            const cartId = ecomCartData.find(item => item.d2ItemId == d2Id);
            if (cartId) {
                const data = await Order.UpdateEcomCartPartially(filteredData, cartId?.id)
            }
        }, 500);
    }

    function convertDateFormat(dateString) {
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options).replace(',', '');
    }

    const placeOrderBtn = async () => {
        setLoading(true);
        setShowPrintModal(false);
        const data = await ConfirmAndKot(false, true);
        setShowPrintModal(false);
        if (data == false) {
            setLoading(false);
        }
        else {
            setTimeout(() => {
                navigate(`/OrderTakerView`, {
                    state: { scrollOrd1Id: 0 }
                });
            }, 2000)
            setLoading(false);
        }
    }

    const [isLogin, setIsLogin] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const inputRef1 = useRef(null)
    const toggleLoginSlider = () => {
        inputRef1.current.focus()
        inputRef1.current.select()
        setIsOpen(prevState => !prevState);
    };

    const closeLoginSlider = () => {
        setIsOpen(false);
    }

    const [mobileNo, setMobileNo] = useState("");

    // const handleMobileNoChange = (e) => {
    //     // const value = e.target.value;
    //     // const sanitizedValue = value.replace(/\D/g, "");

    //     // if (sanitizedValue.length <= 10) {
    //     // setMobileNo(sanitizedValue);
    //     // }
    //     setMobileNo(e.target.value);
    // }

    const handleMobileNoChange = useCallback((e) => {
        const value = e.target.value;
        if (value.length <= 10) { // Prevents unnecessary state updates
            setMobileNo(value);
        }
    }, []);

    const LoginAccountOT = async () => {
        setLoading(true);
        const mobileNo = document.getElementById('customerId').value;
        console.log(mobileNo);
        const sanitizedValue = mobileNo.replace(/\D/g, "");

        if (sanitizedValue.length == 10) {
            const login = await Account.logInAccountOT(mobileNo);
            if (login) {
                const data = await Account.getAccountById(login.d1Id);
                setOrderData((prevValues) => ({
                    ...prevValues,
                    "d1AccountId": data.item1[0]?.d1Id || 0,
                    "d2AccountId": data.item1[0]?.d2Id || 0,
                    "deliveryAddress": "",
                    "name": data.item1[0]?.name || "",
                    "mobileNo": data.item1[0]?.mobileNo || 0,
                    "companyGSTNo": data.item1[0]?.gStINNo || "",
                    "companyDetails": data.item1[0]?.accountDetails || "",
                }));
                setLoginAccountData({
                    "d1AccountId": data.item1[0]?.d1Id || 0,
                    "d2AccountId": data.item1[0]?.d2Id || 0,
                    "deliveryAddress": "",
                    "name": data.item1[0]?.name || "",
                    "mobileNo": data.item1[0]?.mobileNo || 0,
                    "companyGSTNo": data.item1[0]?.gStINNo || "",
                    "companyDetails": data.item1[0]?.accountDetails || "",
                })
                setIsLogin(true);
                setIsShowAlertBox(true);
                setIsSingleButton(true);
                setHeadingTop("");
                setMessage('Successfully logged in !!');
                // alert("Successfully Login");
                closeLoginSlider();
            }
            setLoading(false);
        }
        else {
            setIsShowAlertBox(true);
            setHeadingTop("");
            setMessage('Please Enter Valid Mobile No.');
            setIsSingleButton(true);
            // alert("Please enter valid Mobile No.");
            setLoading(false);
        }
    }

    const backBtnClick = () => {
        navigate("/OrderTakerView");
    }

    const mainCategories = itemGroupList && itemGroupList.filter(item => item && item.primaryGroup);
    const subCategories = itemGroupList && itemGroupList.filter(item => item && !item.primaryGroup);

    function processElementScroll(d2Id) {
        closeCartDetailModal();
        const element = document.getElementById(d2Id);
        // fetchItemMasterData(d2Id)
        if (element) {
            const rect = element.getBoundingClientRect();
            const offsetTop = window.scrollY + rect.top;

            const topMargin = 150;

            window.scrollTo({
                top: offsetTop - topMargin,
                behavior: 'smooth',
            });
            closeSidebar();
        }
    }

    const itemGroupScroll = async (d2Id, index) => {
        // itemGroupClick(d2Id, index)
        // if (inputData != "" && inputData != null && inputData != undefined) {
        //     setLoading(true);
        //     await fetchItemMasterData(0);
        //     setTimeout(() => processElementScroll(d2Id), 1000);
        //     setInputData("")
        //     setLoading(false);
        // }
        // else {
        setIsOpenInput(false);
        setInputData('');
        setSearchData([]);
        processElementScroll(d2Id);
        // }

        // if (element && containerRef.current) {
        //     // Smooth scroll to the element with the matching d2Id
        //     element.scrollIntoView({
        //         behavior: "smooth",  // For smooth scrolling
        //         block: "start",  // Scroll to the top of the container
        //     });
        //     document.getElementById("body").classList.toggle("overflow-hidden");
        //     toggleSidebar();
        // }
    }

    const [openInput, setIsOpenInput] = useState(false);
    const inputRef = useRef(null);

    const searchBtn = () => {
        setIsOpenInput(true);
    }

    const [inputData, setInputData] = useState();
    const debounceTimer = useRef(null);

    const [searchData, setSearchData] = useState([]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setInputData(value);

        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }

        debounceTimer.current = setTimeout(async () => {
            setLoading(true)
            const filterData = [
                {
                    fieldName: "itm.name",
                    operatorName: "like",
                    compareValue: value
                },
                {
                    fieldName: "published",
                    operatorName: "Equal",
                    compareValue: 1
                },
                {
                    fieldName: "ecomCategoryDetail",
                    operatorName: "isnotnull",
                    compareValue: "null"
                },
            ]
            // const data = await ItemMaster.GetItemMasterWithFilter(filterData)
            // const data = await ItemMaster.GetItemMasterForRestaurant(filterData)
            if (value && value != '') {
                const data = await ItemMaster.GetItemsWithAliasNameFilter(filterData)
                setSearchData(data);
            }
            // setFilteredItems(data);
            setLoading(false)
        }, 500);
    }

    const closeInput = () => {
        setInputData("");
        setSearchData([]);
        setIsOpenInput(false);
    }

    useEffect(() => {
        if (openInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [openInput]);

    const [tableData, setTableData] = useState();
    const [allocatedTable, setAllocatedTable] = useState();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let data = await Table.getTable();
                if (data) {
                    data = data.sort((a, b) => {
                        if (a.tablePriority == null) return 1;
                        if (b.tablePriority == null) return -1;
                        return a.tablePriority - b.tablePriority;
                    })
                }
                setTableData(data);
                console.log('tableData', data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        //if (ord1Id && ord1Id != 0 && ord1Id != "") {
        fetchData();
        //}
        const fetchAllocatedTable = async () => {
            setLoading(true)
            try {
                const storedArrayString = localStorage.getItem('loginAccountUserData');
                var loginUser = JSON.parse(storedArrayString);
                const allocatedTable = await TableAllocation.getTableAllocationById(loginUser?.userId);
                setAllocatedTable(allocatedTable);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchAllocatedTable();
    }, [])

    // useEffect(() => {
    //     //********* Close Model on Mobile back btn click
    //     const handleBackButton = (event) => {
    //         if (cartDetailModal) {
    //             event.preventDefault();
    //             closeCartDetailModal();
    //         }
    //     };
    //     if (cartDetailModal) {
    //         window.history.pushState({ modalOpen: true }, "");
    //         window.addEventListener("popstate", handleBackButton);
    //     }
    //     return () => {
    //         window.removeEventListener("popstate", handleBackButton);
    //         if (cartDetailModal) {
    //             window.history.back();
    //         }
    //     };
    // }, [cartDetailModal, setCartDetailModal]);

    // if (!cartDetailModal) return null;

    // const observersRef = useRef(null);

    // useEffect(() => {
    //     const observerCallback = (entries, observer) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 // Only call fetchItemMasterData once an element is in view
    //                 const groupId = entry.target.getAttribute("data-group-id");
    //                 fetchItemMasterData(groupId);

    //                 // Stop observing after the first trigger (to avoid multiple calls)
    //                 observer.unobserve(entry.target);
    //             }
    //         });
    //     };

    //     const observer = new IntersectionObserver(observerCallback, {
    //         root: null, // Observe within the viewport
    //         threshold: 0.1, // Trigger when at least 10% of the element is visible
    //     });

    //     // Cleanup any previous observers
    //     if (observersRef.current) {
    //         observersRef.current.disconnect();
    //     }

    //     // Ensure you are observing only the elements that are not observed yet
    //     const elements = document.querySelectorAll(".ourmenu-heading-submenu");
    //     elements.forEach((el) => {
    //         if (!el.dataset.isObserved) {
    //             observer.observe(el);
    //             el.dataset.isObserved = "true"; // Mark as observed
    //         }
    //     });

    //     // Store the current observer to clean it up later
    //     observersRef.current = observer;

    //     return () => {
    //         // Disconnect the observer on cleanup
    //         if (observersRef.current) {
    //             observersRef.current.disconnect();
    //         }
    //     };
    // }, [itemGroupList]);    

    // const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
    // const groupRefs = useRef([]);
    // const GROUP_FETCH_LIMIT = 4;

    // useEffect(() => {
    //     if (itemGroupList && itemGroupList.length > 0) {
    //         fetchGroupData(currentGroupIndex, GROUP_FETCH_LIMIT);
    //     }
    // }, [itemGroupList]);

    // const fetchGroupData = async (startIndex, count) => {

    //     // setLoading(true);

    //     const container = containerRef.current;
    //     if (!container) return;

    //     // Find the first visible group
    //     const firstVisibleElement = [...container.children].find(el => el.getBoundingClientRect().top >= 0);
    //     const firstVisibleElementId = firstVisibleElement?.id;
    //     const previousTop = firstVisibleElement?.getBoundingClientRect().top || 0;

    //     const mainCategories = itemGroupList.filter(item => item?.primaryGroup);
    //     const subCategories = itemGroupList.filter(item => !item?.primaryGroup);

    //     try {
    //         let subCategoriesForMain = []; // Outer array to hold all subcategories for each main category

    //         const groupsToFetch = mainCategories
    //             .filter((category) =>
    //                 category.primaryGroup === true && category.showInMenu === true
    //             )
    //             .map((mainCategory) => {
    //                 // Filter subcategories for the current main category
    //                 const subCategoriesForCategory = subCategories.filter(
    //                     (subCategory) => subCategory.parentGrp === mainCategory.d1_Id
    //                 );
    //                 // Push the filtered subcategories into the outer subCategoriesForMain array
    //                 subCategoriesForMain = [...subCategoriesForMain, ...subCategoriesForCategory];
    //             });
    //         if (startIndex > (subCategoriesForMain.length + 1) || loading) return;  // Ensure no fetch while loading
    //         console.log("subMain", subCategoriesForMain)
    //         subCategoriesForMain
    //             .slice(startIndex, startIndex + count)
    //             .forEach((subCategory) => fetchItemMasterData(subCategory.d2_Id));

    //         setTimeout(() => {
    //             const newFirstVisibleElement = document.getElementById(firstVisibleElementId);
    //             if (newFirstVisibleElement) {
    //                 const newTop = newFirstVisibleElement.getBoundingClientRect().top;
    //                 container.scrollTop += newTop - previousTop; // Adjust scroll so it stays in place
    //             }
    //         }, 50);

    //         setCurrentGroupIndex(startIndex + count);  // Update the current group index for next fetch
    //     } catch (error) {
    //         console.error("Error fetching group data:", error);
    //     } finally {
    //         setLoading(false);  // Set loading state to false after fetch
    //     }
    // };

    // const handleScroll = useCallback(() => {
    //     // if (!containerRef.current || loading) return;

    //     // const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    //     // // Trigger next fetch only if the user is near the bottom (within 50px of bottom)
    //     // // if (scrollTop + clientHeight >= scrollHeight - 100) {
    //     //     fetchGroupData(currentGroupIndex, GROUP_FETCH_LIMIT);
    //     // // }

    //     // if (!containerRef.current || loading) return;  // Prevent fetching if already loading

    //     // const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    //     // // Trigger fetch on scroll, but only if we're not near the end
    //     // // Here, we'll prevent multiple fetches by checking the current group index
    //     // if (scrollTop + clientHeight < scrollHeight) return;  // Avoid triggering fetch if not at the end yet

    //     // // Fetch the next set of items when we reach the end
    //     // fetchGroupData(currentGroupIndex, GROUP_FETCH_LIMIT);

    //     if (!containerRef.current || loading) return;

    //     const { scrollTop } = containerRef.current;

    //     // If the user scrolls near the top, fetch previous groups
    //     if (scrollTop <= 50) {
    //         fetchGroupData(currentGroupIndex, GROUP_FETCH_LIMIT);
    //     }

    // }, [currentGroupIndex, loading]);

    // useEffect(() => {
    //     const container = containerRef.current;
    //     if (container) {
    //         container.addEventListener("scroll", handleScroll);
    //     }
    //     return () => container?.removeEventListener("scroll", handleScroll);
    // }, [handleScroll]);

    // const observersRef = useRef([]);
    // const [visibleGroupArray, setVisibleGroupArray] = useState([]);

    // useEffect(() => {
    //     console.log("Updated visibleGroupArray:", visibleGroupArray);
    //     setFilteredItems(filteredItems)
    // }, [visibleGroupArray, filteredItems]);

    useEffect(() => {
        fetchItemMasterData(0)
        // fetchItemMasterData((itemGroupList && itemGroupList[0]?.d2_Id) || 5001)
        //itemGroupScroll(581 || (itemGroupList && itemGroupList[0]?.d2_Id) || 5001)
    }, [itemGroupList])

    // useEffect(() => {
    //     const observerCallback = (entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 const groupId = Number(entry.target.getAttribute("data-group-id"));
    //                 if (!(visibleGroupArray.includes(groupId))) {
    //                     fetchItemMasterData(groupId); // Update state with the visible group's ID
    //                     setVisibleGroupArray((prevArray) => {
    //                         // Ensure the ID is only added once to avoid duplicates
    //                         if (!prevArray.includes(Number(groupId))) {
    //                             return [...prevArray, Number(groupId)];
    //                         }
    //                         return prevArray;
    //                     });
    //                     console.log("visibleGroupArray", visibleGroupArray)
    //                 }
    //             }
    //         });
    //     };
    //     const observer = new IntersectionObserver(observerCallback, {
    //         root: null, // Observe within the viewport
    //         threshold: 0.1, // Trigger when at least 10% of the element is visible
    //     });
    //     // Observe all relevant divs
    //     observersRef.current.forEach((observerRef) => observer.disconnect()); // Cleanup existing observers
    //     observersRef.current = []; // Reset references
    //     const elements = document.querySelectorAll(".ourmenu-heading-submenu");
    //     elements.forEach((el) => {
    //         observer.observe(el);
    //         observersRef.current.push(observer);
    //     });
    //     return () => {
    //         observersRef.current.forEach((observerRef) => observerRef.disconnect());
    //     };
    // }, [filteredItems]);

    const handleRefreshClick = () => {
        window.location.reload();
    }

    const inputRefs = useRef(new Map()); // Use Map for efficient ref storage

    const selectAtIndex = (index) => {
        const inputElement = inputRefs.current.get(index);
        if (inputElement) {
            inputElement.focus();
            inputElement.select(); // Select text inside input
        }
    };

    useEffect(() => {
        const handleBackButton = (event) => {
            if (cartDetailModal || !openInput) {
                event.preventDefault();
                closeCartDetailModal();
                setIsOpenInput(false);
                setInputData('')
                setSearchData([]);
                window.history.pushState(null, "", window.location.href); // Prevents history from changing
            }
        };

        window.history.pushState(null, "", window.location.href); // Add a fake entry to history
        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [cartDetailModal]);


    const [forChangeTableGroup, setForChangeTableGroup] = useState(orderData.tableGroupId);
    const [forChangeTable, setForChangeTable] = useState(orderData.tableId);

    const handleTableGroupSelect = async (e) => {
        const value = e.target.value;
        let data;
        if (value == "0") {
            data = await Table.getTable();
        }
        else {
            data = await Table.getTableByTableGroupId(value);
        }
        if (data) {
            data = data.sort((a, b) => {
                if (a.tablePriority == null) return 1;
                if (b.tablePriority == null) return -1;
                return a.tablePriority - b.tablePriority;
            })
        }
        setTableData(data);
        setForChangeTableGroup(value);
        await onFloorSelect(value);
    }

    const [imageModal, setImageModal] = useState(false)
    const [imageModalSrc, setImageModalSrc] = useState('');

    const openImageModal = (src) => {
        setImageModalSrc(src);
        setImageModal(true);
    }
    const closeImageModal = () => {
        setImageModalSrc('')
        setImageModal(false);
    }

    let holdTimer;

    const handleMouseDown = (src) => {
        holdTimer = setTimeout(() => {
            setImageModalSrc(src);
            setImageModal(true);
        }, 1000);
    };

    const handleMouseUp = () => {
        clearTimeout(holdTimer);
    };

    const [showGridView, setGridView] = useState(true);

    useEffect(() => {
        const data = localStorage.getItem('gridView');
        if (data == "true") {
            setGridView(true);
        }
        else {
            setGridView(false);
        }
    }, [])

    const handleToggleGridView = () => {
        localStorage.setItem('gridView', !showGridView);
        setGridView(!showGridView)
    }

    const openTableModal = () => {
        setTableModal(true);
    }
    const closeTableModal = () => {
        setTableModal(false);
    }

    const handleTableSaveBtn = () => {
        if (orderData.tableId == null || orderData.tableId == 0 || orderData.tableId == "") {
            setIsShowAlertBox(true);
            setMessage('Please Select Table to Proceed');
            setIsSingleButton(true);
            setHeadingTop("");
            return;
        }
        setTableModal(false);
    }

    const handleTableSelection = async (tableId) => {
        setLoading(true);
        setCartData([]);
        setOrder2([]);
        onTableSelect(tableId);
        const filteredData = [
            {
                fieldName: "tableId",
                operatorName: "Equal",
                compareValue: tableId
            }
        ]
        const data = await Order.getAllEcomCartDetailsFilter(filteredData);
        setEcomCartData(data);

        const newCartData = [];
        const newOrderData = [];

        data.forEach(orderData => {
            const selectedItem = menuItems.find(item => item.d2Id === orderData.d2ItemId);
            if (!selectedItem) return;

            newCartData.push({
                ...selectedItem,
                quantity: orderData.quantity || 1,
                preferenceNo: 0,
                itemSuggestion: orderData.itemSuggestion || "",
            });

            newOrderData.push({
                d2Id: selectedItem.d2Id,
                godownId: selectedItem.defaultGodown || 0,
                d1ItemId: orderData.d1ItemId || 0,
                d2ItemId: orderData.d2ItemId || 0,
                qty: orderData.quantity || 1,
                rate: orderData.itemPrice || 0,
                totalAmt: (orderData.quantity || 1) * orderData.itemPrice,
                kotStatus: "Pending",
                preferenceNo: 0,
                orderDate: "",
                orderTime: "",
                itemSuggestion: orderData.itemSuggestion || "",
                itemRunningOrderCounter: 0,
                orderRunningQty: 0,
                itemRunningQty: 0,
                taxableAmount: 0,//1 * selectedItem.itemPrice,
                cgstPer: 0,
                cgstValue: 0,
                sgstPer: 0,
                sgstValue: 0,
                igstPer: 0,
                igstValue: 0,
                cessPer: 0,
                cessValue: 0,
                totalTaxableAmt: 0, //1 * selectedItem.salePrice,
                isLocal: true,
                isInclusive: false,
                hasComplementryItem: '', //selectedItem.addasComplementaryItem,
                cookStatus: "Pending",
                itemIncentivePoint: '', //selectedItem.salesManItemIncentive,
                ord2Id: 0,
                itemName: orderData.itemName,
                popupCategoryForTable: orderData.popupCategoryForTable,
                immediateTransferForHD: orderData.immediateTransferForHD,
                itemDepartmentId: orderData.itemDepartId,
            });
        });
        // Update state once after the loop
        setCartData(prevCartData => [...prevCartData, ...newCartData]);
        setOrder2(prevOrder2 => [...prevOrder2, ...newOrderData]);
        setLoading(false);
    }

    const [enableTable, setEnableTable] = useState(true);
    const [isShowAlertBoxForTable, setShowAlertBoxForTable] = useState(false)

    const changeTableBtnClick = async () => {
        setShowAlertBoxForTable(true);
        setMessage(`Are you sure you want to change the table ??`);
        setIsSingleButton(false);
        setHeadingTop("Info");
        // if (window.confirm('Are you sure you want to change the table')) {
        // }
    }
    const handleOkBtnClick = () => {
        setEnableTable(false);
        setShowAlertBoxForTable(false);
    }

    // const handleChangeTableGroup = (e) => {
    //     const value = e.target.value
    //     setForChangeTableGroup(value)
    //     handleTableGroupSelect(e)
    // }

    const handleTableChange = async (e) => {
        setLoading(true);
        const value = e.target.value;
        setForChangeTable(value);

        const filteredData = [
            {
                operationType: 0,
                path: "tableGroupId",
                op: "Add",
                from: "string",
                value: forChangeTableGroup
            },
            {
                operationType: 0,
                path: "tableId",
                op: "Add",
                from: "string",
                value: value
            }
        ]
        console.log(ecomCartData);
        for (const item of ecomCartData) {
            await Order.UpdateEcomCartPartially(filteredData, item.id);
        }
        onTableSelect(value);
        setLoading(false);
        setIsShowAlertBox(true);
        setMessage('Successfully Changed Table !!');
        setIsSingleButton(true);
        setHeadingTop("");
        // alert("Successfully Changed")
    }

    const [isInputFocused, setIsInputFocused] = useState(false);

    return (
        <div ref={containerRef} style={{ height: "100vh" }}>
            <div className="heading"
                style={{ display: "flex" }}>
                <i className="fa-solid fa-arrow-left icon-white" style={{ fontSize: '20px' }} onClick={backBtnClick}></i>
                <div className="" style={{ alignItems: "baseline", height: "25px", width: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <label className="head-title-ot">ORDER TAKER</label>
                        <div className="space-evenly">
                            {/* {openInput && (
                                <input type="text" onChange={handleSearchChange} value={inputData} ref={inputRef} style={{ width: "auto", height: "30px" }} />
                            )} */}
                            {/* {!openInput && (
                                <i class="fa-solid fa-search head-title-ot" style={{ marginRight: "10px" }} onClick={searchBtn}></i>
                            )} */}
                            <i class="fa-solid fa-rotate-right head-title-ot" onClick={handleRefreshClick} style={{ marginRight: "10px" }}></i>
                            {/* <i class="fa-solid fa-power-off head-title-ot" onClick={handleLogOutClick}></i> */}
                            <i class="fa-solid fa-chair head-title-ot" onClick={openTableModal} style={{ marginRight: "10px" }}></i>
                            <i class="fa-solid fa-gear head-title-ot" onClick={handleToggleGridView}></i>
                        </div>
                    </div>
                </div>
            </div>
            {/* ************************ Body Part **************************** */}
            <div onClick={() => closeSidebar()} style={{ marginTop: "51px", backgroundColor: "rgb(249 249 249)", paddingBottom: "80px", height: "100%" }}> {/*f0f0f0 */}
                <div className="ourmenu-right-section" id="yourContainerDivId" style={{ width: "100%", paddingBottom: "180px" }}>
                    <div style={{ overflow: "scroll", }}>
                        {mainCategories && mainCategories
                            .filter((category) => category.primaryGroup && category.showInMenu)
                            .map((mainCategory, i) => {
                                // Filter subcategories for the current main category
                                const subCategoriesForMain = subCategories.filter(
                                    (subCategory) => subCategory.parentGrp === mainCategory.d1_Id
                                );
                                // Check if any subcategory is visible based on filteredItems
                                const anySubcategoryVisible = subCategoriesForMain.some((subCategory) =>
                                    filteredItems && filteredItems.some((item) =>
                                        item?.ecomCategoryDetail?.includes(subCategory.d2_Id) && item.attributeParentId === 0
                                    )
                                );
                                return (
                                    <div key={mainCategory.d2_Id} id={mainCategory.d2_Id} style={{ display: (anySubcategoryVisible) ? "block" : (openInput ? "none" : "none") }}>
                                        <div style={{ opacity: anySubcategoryVisible ? "1" : "0", height: anySubcategoryVisible ? "30px" : "0px" }}
                                            className="ourmenu-topdiv"
                                            id={mainCategory.d2_Id} >
                                            <i className="fa-solid fa-utensils cart-icon-utensile" style={{ color: "black" }}></i>&nbsp;&nbsp;
                                            {(mainCategory.name).toUpperCase()}&nbsp;&nbsp;
                                            <i className="fa-solid fa-utensils cart-icon-utensile" style={{ color: "black" }}></i>
                                        </div>
                                        {subCategoriesForMain.map((subCategory, index) => (
                                            <React.Fragment key={subCategory.d2_Id}>
                                                <div id={subCategory.d2_Id}
                                                    className="ourmenu-heading-submenu"
                                                    data-group-id={subCategory.d2_Id}
                                                    style={{
                                                        opacity: filteredItems && filteredItems.some((item) =>
                                                            item.ecomCategoryDetail.includes(subCategory.d2_Id) && item.attributeParentId === 0
                                                        ) ? "1" : "0",
                                                        height: filteredItems && filteredItems.some((item) =>
                                                            item.ecomCategoryDetail.includes(subCategory.d2_Id) && item.attributeParentId === 0
                                                        ) ? "18px" : "0",
                                                        padding: filteredItems && filteredItems.some((item) =>
                                                            item.ecomCategoryDetail.includes(subCategory.d2_Id) && item.attributeParentId === 0
                                                        ) ? "20px 0px" : "0",
                                                    }}
                                                >
                                                    {(subCategory.menuName).toUpperCase()}
                                                </div>
                                                <div className="ourmenu-product-box">
                                                    <div className="gridView-pos mt-3 overFlow_none" id="menu-pos" style={{ gridTemplateColumns: showGridView ? "repeat(2, auto)" : "repeat(1, auto)", width: "100%", margin: "0px" }}>
                                                        {filteredItems && filteredItems
                                                            .filter((item, index, self) =>
                                                                item.ecomCategoryDetail.includes(subCategory.d2_Id) &&
                                                                item.attributeParentId === 0 &&
                                                                item.published !== false &&
                                                                self.findIndex(i => i.d2Id == item.d2Id) == index
                                                            )
                                                            .map((item, i) => {
                                                                const { available, message } = isAvailableForService(item);
                                                                const cartItem = cartData.find(cart => cart.d2Id === item.d2Id);
                                                                const quantity = cartItem ? cartItem.quantity : 0;
                                                                // console.log(item)
                                                                let additionalCartQty = 0;
                                                                if (quantity === 0) {
                                                                    const relatedItems = filteredItems && filteredItems.filter(
                                                                        (relatedItem) =>
                                                                            relatedItem.attributeParentId == item.d2Id
                                                                    )
                                                                    additionalCartQty = relatedItems.filter((item, index, self) =>
                                                                        index === self.findIndex((t) => (
                                                                            t.d2Id === item.d2Id
                                                                        ))
                                                                    )
                                                                        .reduce((total, relatedItem) => {
                                                                            const relatedCartItem = cartData.find((cart) => cart.d2Id === relatedItem.d2Id);
                                                                            return total + (relatedCartItem ? (Number(relatedCartItem.quantity) || 0) : 0);
                                                                        }, 0);
                                                                }
                                                                const isVariable = (item.attributeParentId == 0 && item.isAttributeItem == true)
                                                                return (
                                                                    <div key={item.id} style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-evenly",
                                                                        opacity: available ? 1 : 0.6,
                                                                        pointerEvents: available ? "auto" : "none",
                                                                        // width: "200px"
                                                                        width: showGridView ? "auto" : "333px",
                                                                        maxWidth: showGridView ? "175px" : "350px",
                                                                    }}>
                                                                        <div className="menuItem" style={{ margin: "10px 10px", maxHeight: '200px', flexDirection: "column", justifyContent: "center" }}>
                                                                            <div className='row-m1'>
                                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                                    {/* <img
                                                                                        // src={`${imageBaseUrl}${(item?.images && item?.images?.length == 0) ? companyData?.companyImageId : (item?.images?.length > 0 ? item?.images[0] : item?.images)}`}
                                                                                        alt=""
                                                                                        style={{ height: "auto", width: "65px", borderRadius: "15px", border: "1px solid lightGrey", maxHeight: "80px" }}
                                                                                    /> */}
                                                                                    <LazyLoadImage
                                                                                        src={`${imageBaseUrl}${item?.images?.length === 0 ? companyData?.companyImageId : item?.images[0]}`}
                                                                                        alt=""
                                                                                        openImageModal={openImageModal}
                                                                                        onMouseUp={handleMouseUp}
                                                                                        onMouseLeave={handleMouseUp}
                                                                                        onTouchStart={handleMouseDown}
                                                                                        onTouchEnd={handleMouseUp}
                                                                                        style={{
                                                                                            height: "auto",
                                                                                            width: "65px",
                                                                                            borderRadius: "15px",
                                                                                            border: "1px solid lightGrey",
                                                                                            maxHeight: "80px",
                                                                                        }}
                                                                                    // onClick={() => openImageModal(`${imageBaseUrl}${item?.images?.length === 0 ? companyData?.companyImageId : item?.images[0]}`)}
                                                                                    />
                                                                                </div>
                                                                                <div className="" style={{ marginLeft: "5px" }}>
                                                                                    <label className="menuLabel">{item.name}</label>
                                                                                    <h6 style={{ fontWeight: "600", color: "#606060", fontSize: "13px" }}>
                                                                                        Price: &#x20B9;{item.salePrice}
                                                                                    </h6>
                                                                                    {isVariable && (
                                                                                        <div style={{ color: "#8f8b8b", fontSize: "11px", marginTop: "5px", fontWeight: "600" }}>
                                                                                            Customizable
                                                                                        </div>
                                                                                    )}
                                                                                    {!available && (
                                                                                        <div style={{ color: "red", fontSize: "10px", marginTop: "5px", fontWeight: "600" }}>
                                                                                            {message}
                                                                                        </div>
                                                                                    )}
                                                                                    {available && (
                                                                                        <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "0px" }}>
                                                                                            <button
                                                                                                style={{ paddingBottom: "4px" }}
                                                                                                className="quantity__minus"
                                                                                                onClick={() => isVariable ? openVariableItemModal(item.d2Id) : handleDecrement(item.d2Id)}
                                                                                            > - </button>
                                                                                            <input className="quantity__input"
                                                                                                type="number"
                                                                                                ref={(el) => inputRefs.current.set(`${item.d2Id}_${i}_${index}`, el)} // Store input ref
                                                                                                onClick={() => item.attributeParentId == 0 && item.isAttributeItem === true ? handleIncrementPOS(item.d2Id) : selectAtIndex(`${item.d2Id}_${i}_${index}`)}
                                                                                                onChange={(event) => onQuantityChange(event, item.d2Id)}
                                                                                                value={quantity == 0 ? (additionalCartQty || 0) : (quantity || 0)}
                                                                                                style={{ borderColor: "transparent", borderColor: "#ededed", borderWidth: "1px 0px" }}
                                                                                            />
                                                                                            <button className="quantity__plus"
                                                                                                onClick={() => handleIncrementPOS(item.d2Id)}
                                                                                            > + </button>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                );
                            })}
                    </div>
                    {/* ************************************************* */}
                </div>
            </div>
            {/* ********************************** Floating Button ************************************* */}
            <div className="bottomFixed_modal mt-2" style={{ height: "50px", backgroundColor: "black", zIndex: "6", padding: "0px", border: "1px solid white" }}>
                <div className="circle mt-1" onClick={openCartDetailModal} style={{ backgroundColor: "rgb(103,103,103)", width: "100%", display: "inline" }}>
                    <div className="space-between" style={{ padding: "6px 15px" }}>
                        <label style={{ color: "white" }}><i class="fa-solid fa-cart-shopping" style={{ color: "white", fontSize: "13px" }}></i>&nbsp;
                            View Cart ({cartData && cartData.length} items)</label>
                        <label style={{ color: "white" }}>₹ {(ord1Id && ord1Id != 0 && ord1Id != "") ? totalAmountForMobile : totalAmount}</label>
                    </div>
                </div>
            </div>
            <div className="rightBottomFixed_modal" onClick={searchBtn} style={{ height: "50px", backgroundColor: "black", zIndex: "5", border: "1px solid white" }}>
                <i class="fa-solid fa-search head-title-ot" style={{ marginRight: "10px" }}></i>
            </div>
            <div className="rightBottomFixed_modal" onClick={toggleSidebar} style={{ height: "50px", backgroundColor: "black", zIndex: "8", bottom: "18%", border: "1px solid white", padding: "11px" }}>
                {/* <i className="fa-brands fa-slack head-title-ot" style={{ marginRight: "10px" }} ></i> */}
                <label style={{ color: "white", fontSize: "13px", fontWeight: "600" }}>MENU</label>
            </div>
            {/* <div className="floating-button-container" style={{ bottom: "5%", left: "50%", zIndex: "6" }}>
                <div className="circle mt-1" onClick={toggleSidebar} style={{ backgroundColor: "transparent", boxShadow: "none" }}>
                    <div className="menu-open" style={{ backgroundImage: `url(${menuImage})` }}></div>
                </div>
            </div> */}
            {/* ******************************* Item Group Menu Modal ***************************** */}
            {isMenuModalOpen && (
                <div className='modal-back' onClick={closeSidebar}>
                    <div id="" className={`modalForMenuPOS ${isMenuModalOpen ? "show" : ""}`} style={{ maxHeight: "65vh", overflow: "none" }}>
                        <div className="">
                            <div id="sidebar1" className="" onClick={(e) => e.preventDefault()}>
                                <div className="space-between side-topbar" style={{ backgroundColor: "rgb(77 71 71)", paddingBottom: "0px", borderBottom: "1px solid black", paddingTop: "8px" }}>
                                    <div className='flex-start'>
                                        <label style={{ fontSize: "16px", fontWeight: "bold", color: "white" }}>MENU (PRODUCT CATEGORY)</label>
                                    </div>
                                    <p className="back_button" onClick={closeSidebar} style={{ color: "white", textAlign: "end" }}>
                                        <i className="fa-solid fa-xmark" style={{ color: "white", fontSize: "22px" }}></i>
                                    </p>
                                </div>
                                <div className="leftSideMenu" style={{ height: "auto", maxHeight: "92vh", backgroundColor: "#f5f5f500" }}>
                                    <div>
                                        {(() => {
                                            if (itemGroupList !== null && Array.isArray(itemGroupList)) {
                                                return itemGroupList
                                                    .filter(item => item.primaryGroup == true && item.showInMenu == true)
                                                    .sort((a, b) => a.displayOrder - b.displayOrder)
                                                    .map((list, index) => (
                                                        <div //className={itemDepartmentActiveIndex == (index + 1) ? `menuListAcive` : `menuList`}
                                                            style={{}} onClick={() => itemGroupScroll(list.d2_Id, index)}>
                                                            <div style={{ padding: "10px 14px", }}>
                                                                <label key={list.id} style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>
                                                                    {list.name}
                                                                </label>
                                                            </div>
                                                            <hr style={{ margin: "5px" }} />
                                                        </div>
                                                    ))
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* ********************************************************************************************* Cart Detail Modal ******************************************************* */}
            <div className={`slider-containerPOS ${cartDetailModal ? 'open' : ''}`} style={{ zIndex: "7" }}>
                <div className="slider-contentPOS">
                    <div style={{ padding: "8px 20px", backgroundColor: "black", borderBottom: "1px solid rgb(77 71 71)" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label style={{ fontWeight: "600", color: "white" }}>MY CART</label>
                            {/* <label style={{ cursor: "pointer", fontSize: "24px" }} onClick={closeCartDetailModal}>x</label> */}
                            <i onClick={closeCartDetailModal} style={{ color: "white", cursor: "pointer" }}
                                className="fa-solid fa-xmark" ></i>
                        </div>
                        {/* <hr style={{ padding: "0px", color: "black", margin: "0px" }} /> */}
                    </div>
                    <div style={{ marginTop: "10px", width: "98%" }}>
                        <div className='cart_box' style={{ maxHeight: "500px", height: "auto", overflowY: "scroll", overflowX: "clip" }}>
                            {cartData && cartData.map((item, i) => (
                                <div className="menuItem" style={{ maxHeight: '150px', flexDirection: "column", justifyContent: "center", width: "95%", margin: "15px 10px" }}>
                                    <div className='row-m1'>
                                        <img
                                            src={`${imageBaseUrl}${(item?.images && item?.images == 0) ? companyData?.companyImageId : (item?.images?.length > 0 ? item?.images[0] : item?.images)}`}
                                            ref={imageRef}
                                            alt=""
                                            style={{ height: "60px", width: "60px", borderRadius: "15px", border: "1px solid lightGrey" }}
                                        />
                                        <div className="" style={{ marginLeft: "5px" }}>
                                            <label className="menuLabel">{item.name}</label>
                                            {/* <h6 style={{ fontWeight: "600", color: "#606060", fontSize: "13px", marginBottom: "0px" }}>
                                                Price: &#x20B9;{item.salePrice}
                                            </h6> */}
                                            <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "0px" }}>
                                                <button
                                                    style={{ paddingBottom: "4px" }}
                                                    className="quantity__minus"
                                                    onClick={() => handleDecrement(item.d2Id)}
                                                > - </button>
                                                <input className="quantity__input"
                                                    type="number"
                                                    ref={(el) => inputRefs.current.set(`${item.d2Id}_${i}`, el)} // Store input ref
                                                    onClick={() => selectAtIndex(`${item.d2Id}_${i}`)}
                                                    onChange={(event) => onQuantityChange(event, item.d2Id)}
                                                    value={item.quantity || item.qty}
                                                    style={{ borderColor: "transparent", borderColor: "#ededed", borderWidth: "1px 0px" }}
                                                // onFocus={() => setIsInputFocused(true)}
                                                // onBlur={() => setIsInputFocused(false)}
                                                />
                                                <button className="quantity__plus"
                                                    onClick={() => handleIncrementPOS(item.d2Id)}
                                                >  + </button>
                                                <label style={{ fontSize: "13px" }}>&nbsp; x &#x20B9; {item.salePrice}&nbsp; &nbsp; </label>
                                                <label style={{ fontSize: "13px" }}> = &nbsp; &#x20B9; {(item.quantity || item.qty) * item.salePrice}&nbsp; &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ width: "100%" }}>
                                        <input
                                            className="suggesstion_box"
                                            type="text"
                                            placeholder=" Item Suggestion...."
                                            onChange={(e) => saveSuggestion(e, item.d2Id)}
                                            // value={item.itemSuggestion}
                                            name="itemSuggestion"
                                            style={{ fontWeight: "100", width: "100%" }}
                                        // onFocus={() => setIsInputFocused(true)}
                                        // onBlur={() => setIsInputFocused(false)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div className='cart_box' style={{ display: "flex", justifyContent: "center", backgroundColor: "transparent" }}>
                            <button style={{ margin: "0px 0px", padding: "10px 20px", height: "30px", borderRadius: "5px", backgroundColor: "33950b", fontWeight: "500" }}
                                className="myordercart-mobile-2"
                                onClick={closeCartDetailModal}> + Add New Item </button>
                        </div> */}
                        {/* <div className='cart_box'>
                            <div className='space-between' style={{ padding: "10px" }}>
                            <label>Total Amount</label>
                            <label>Rs {(ord1Id && ord1Id != 0 && ord1Id != "") ? totalAmountForMobile : totalAmount}</label>
                            </div>
                            </div> */}
                        {cartDetailModal && !isInputFocused && (
                            <div>
                                <div className='rightBottomFixed_modal' style={{ width: "100%", padding: "5px", borderRadius: "15px", borderTop: "2px solid darkgrey", backgroundColor: "#f7f7f7" }}>
                                    <div className='cart_box' style={{ backgroundColor: "transparent", padding: "0px", margin: "0px", width: "100%", }}>
                                        <div style={{ padding: "5px" }}>
                                            {!isRunningOrder && (
                                                <>
                                                    <div className="flex-end">
                                                        <select className="ques  POS_Select_im_input" onChange={(e) => handleTableGroupSelect(e)} disabled={enableTable}
                                                            value={forChangeTableGroup ? forChangeTableGroup : orderData.tableGroupId} style={{
                                                                selectStyle, border: "1px solid lightgrey", backgroundColor: "white", width: "90%",
                                                                fontSize: "14px", padding: "1px", fontWeight: "600"
                                                            }}>
                                                            <option value="0">Select Floor</option>
                                                            {(() => {
                                                                if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                                    // return tableGroup.map((data) => (
                                                                    //     <option key={data.id} value={data.id}>
                                                                    //         {data.name}
                                                                    //     </option>
                                                                    // ))
                                                                    if (allocatedTable && Array.isArray(allocatedTable)) {
                                                                        // Filter table groups based on tableGroupId being present in allocatedTable
                                                                        const availableTableGroups = tableGroup.filter((data) =>
                                                                            allocatedTable.some((allocated) => allocated.tableGroupId === data.id)
                                                                        );
                                                                        return availableTableGroups.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ));
                                                                    }
                                                                }
                                                            })()}
                                                        </select>
                                                        <label style={{ width: "40px" }}>&nbsp; : &nbsp;</label>
                                                        <select
                                                            className="ans POS_Select_im_input"
                                                            onChange={(e) => handleTableChange(e)}
                                                            disabled={enableTable}
                                                            value={forChangeTable ? forChangeTable : orderData.tableId}
                                                            style={{
                                                                ...selectStyle, border: "1px solid lightgrey", backgroundColor: "white", width: "90%",
                                                                fontSize: "14px", padding: "1px", fontWeight: "600"
                                                            }}
                                                        >
                                                            <option value="0">Select Table No.</option>

                                                            {allocatedTable && tableData
                                                                ?.filter((data) =>
                                                                    data.tableStatus === "Free" &&
                                                                    allocatedTable.some((allocated) => allocated.tableId === data.id)
                                                                )
                                                                .map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.tableName}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className='flex-end' onClick={changeTableBtnClick}>
                                                        <label style={{ fontSize: "12px", color: "darkred", fontWeight: "600", marginRight: "10px" }}>&nbsp;CHANGE TABLE &nbsp;
                                                            <span><i class="fa-solid fa-pen-to-square"></i></span>
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                            {isRunningOrder && (
                                                <div className='mt-2'>
                                                    <label className="fontSize14" style={{ color: "black" }}>Table Name: &nbsp;
                                                        <span className="fontSize14">
                                                            <select
                                                                className="posSelectBox"
                                                                name="deliveryTimeId"
                                                                style={{ fontWeight: "600", color: '#696969' }}
                                                                disabled
                                                                value={tableId}
                                                            >
                                                                {tableData?.map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.tableName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </span>
                                                    </label>
                                                    <br />
                                                    {/* <label className='fontSize14' style={{ color: "black" }}>Delivery Date: <span className='fontSize14'>{convertDateFormat(orderData.deliveryDate)}</span></label><br />
                                                    {!isRunningOrder && (
                                                        <label className='fontSize14' style={{ color: "black" }}>Delivery Time: <span className='fontSize14'>
                                                            <select className="posSelectBox"
                                                                name="deliveryTimeId"
                                                                style={{ fontWeight: "600" }}
                                                                disabled={true}
                                                                value={orderData.deliveryTimeId}>
                                                                {(() => {
                                                                    if (AllocatedTimeSlots && Array.isArray(AllocatedTimeSlots) && AllocatedTimeSlots.length > 0) {
                                                                        return AllocatedTimeSlots.map((data, index) => (
                                                                            <option key={index} value={data.id}>
                                                                                {data.name}
                                                                            </option>
                                                                        ));
                                                                    } else {
                                                                        return <option value=""></option>;
                                                                    }
                                                                })()}
                                                            </select></span>
                                                        </label>
                                                    )} */}
                                                </div>
                                            )}
                                            {(isLogin || !isRunningOrder) && (
                                                <>
                                                    {orderData.name && orderData.name != "" ? (<><label className='fontSize14' style={{ color: "black" }}>Name: <span className='fontSize14'>{(orderData.name)}</span></label><br /></>) : null}
                                                    {orderData.mobileNo && orderData.mobileNo != "" ? (<><label className='fontSize14' style={{ color: "black" }}>Mobile No: <span className='fontSize14'>{(orderData.mobileNo)}</span></label><br /></>) : null}
                                                </>
                                            )}
                                            {(isLogin || isRunningOrder) ? (
                                                isButtonVisible && (
                                                    <div className='space-between myordercart-mobile-2' onClick={placeOrderBtn}
                                                        style={{ margin: "20px 0px", borderRadius: "12px" }}>
                                                        <div>
                                                            Place Order &gt;
                                                        </div>
                                                        <label style={{ color: "white" }}>₹ {(ord1Id && ord1Id != 0 && ord1Id != "") ? totalAmountForMobile : totalAmount}</label>
                                                    </div>
                                                )
                                            ) : (
                                                !isRunningOrder && (
                                                    <div className='space-between myordercart-mobile-2' onClick={toggleLoginSlider}
                                                        style={{ margin: "20px 0px", borderRadius: "12px", backgroundColor: "rgb(77 71 71)" }}>
                                                        <div style={{}} //rgb(38 119 16) rgb(65 111 191)
                                                        >
                                                            Enter Customer Details &gt;
                                                        </div>
                                                        <label style={{ color: "white" }}>₹ {(ord1Id && ord1Id != 0 && ord1Id != "") ? totalAmountForMobile : totalAmount}</label>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* ************** Login Panel ******************* */}
                {isOpen && <div className="blur-background-forPos"></div>}

                <div className={`slider-container-forPos ${isOpen ? 'open' : ''}`}>
                    <div className="slider-content-forPos">
                        <div style={{ padding: "5px 20px 5px 20px", backgroundColor: "transparent", borderBottom: "1px solid black" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <label style={{ fontWeight: "600", color: "black" }}>LOGIN</label>
                                <i onClick={closeLoginSlider} style={{ color: "black", cursor: "pointer" }}
                                    className="fa-solid fa-xmark" ></i>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="">
                                <label htmlFor="username" style={{ color: "black" }}>Enter Customer Mobile No. &nbsp;</label><br />
                                <input //className='suggesstion_box'
                                    type="number"
                                    id="customerId"
                                    placeholder=""
                                    // onChange={handleMobileNoChange}
                                    // value={mobileNo}
                                    name="name_email"
                                    // maxLength={10}
                                    ref={inputRef1}
                                    className='mt-1'
                                    style={{ color: "black", backgroundColor: "transparent", border: "1px solid black", width: "100%", padding: "5px" }}
                                />
                            </div>
                            <br />
                            <div onClick={LoginAccountOT} className="myordercart-mobile-2"
                                style={{ margin: "0px 0px", marginTop: "5px", borderRadius: "12px", backgroundColor: "rgb(0 127 0)" }}>
                                LOG IN
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            {/* ************************************* Image Modal ***************************************  */}
            {
                imageModal && (
                    <div className="modal-back">
                        <div className="variableItem-modal" style={{ padding: "10px 0px" }}>
                            <div className='space-between'>
                                <div></div>
                                <i className="fa-solid fa-times" style={{ marginRight: "10px", fontSize: "16px" }} onClick={closeImageModal}></i>
                            </div>
                            <div>
                                <img alt="Image" src={imageModalSrc} style={{ width: "100%", height: "auto" }} />
                                <br />
                            </div>
                        </div>
                    </div>
                )
            }
            {/* ****************************************** Table selection Option ************************************** */}
            {
                tableModal && (
                    <div className="modal-back1" style={{ background: "rgb(0 0 0 / 48%)" }}>
                        <div className="bottomFixed_modal">
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "1px 10px" }}>
                                <label className="fw-600" style={{ fontSize: "18px" }}><span><i class="fa-solid fa-chair" style={{ color: "black", fontSize: "14px" }}></i></span>
                                    &nbsp;Select table to proceed order
                                </label>
                                <i className="fa-solid fa-xmark xmark" onClick={closeTableModal}></i>
                            </div>
                            <hr style={{ padding: "0px", margin: "0px", marginTop: "15px" }} />
                            <div className="">
                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    <div className="input-containerEffect" style={{ margin: "0px 3%", width: "90%" }}>
                                        <label id="input1" className="labelEffectForMobPos" style={{}}>Select Floor</label><br />
                                        <select className="ques  POS_Select_im_input" onChange={(e) => handleTableGroupSelect(e)} value={orderData.tableGroupId}
                                            style={{
                                                selectStyle, border: "1px solid lightgrey", height: "35px", backgroundColor: 'white', width: "100%", fontSize: "14px",
                                                padding: "1px 10px", fontWeight: "500"
                                            }}>
                                            <option value="0"></option>
                                            {(() => {
                                                if (tableGroup !== null && Array.isArray(tableGroup)) {
                                                    if (allocatedTable && Array.isArray(allocatedTable)) {
                                                        const availableTableGroups = tableGroup.filter((data) =>
                                                            allocatedTable.some((allocated) => allocated.tableGroupId === data.id)
                                                        );
                                                        return availableTableGroups.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ));
                                                    }
                                                }
                                            })()}
                                        </select>
                                    </div>
                                    <Link to="/TableOrderAllocation" style={{ textDecoration: "none" }}>
                                        <i className="fa-solid fa-circle-plus dm_4 mt-1"></i>
                                    </Link>
                                </div>

                                <div className="input-containerEffect" style={{ margin: "0px 3%", width: "90%" }}>
                                    <label id="input1" className="labelEffectForMobPos " style={{}}>Select Table</label><br />
                                    <select className="ans  POS_Select_im_input" onChange={(e) => handleTableSelection(e.target.value)} value={orderData.tableId}
                                        style={{
                                            selectStyle, border: "1px solid lightgrey", backgroundColor: 'white', height: "35px", width: "100%", fontSize: "14px",
                                            padding: "1px 10px",
                                        }}>
                                        <option style={{ appearance: 'none', background: "" }} value="0"></option>
                                        {(() => {
                                            if (allocatedTable) {
                                                // Filter tables that are Free and their tableId is present in allocatedTable
                                                const freeTables = tableData && tableData.filter((data) =>
                                                    data.tableStatus === "Free" &&
                                                    allocatedTable.some((allocated) => allocated.tableId === data.id)
                                                );
                                                return freeTables && freeTables.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.tableName}
                                                    </option>
                                                ));
                                            }
                                        })()}
                                    </select>
                                </div>
                            </div>
                            {/* <hr /> */}
                            <div className="mt-3" style={{ display: "flex", justifyContent: "end" }}>
                                <button className="redBtn" style={{ width: "25%", height: "40px", padding: "0px" }} onClick={closeTableModal}>Cancel</button>
                                <button className="greenBtn" style={{ width: "auto", height: "40px", padding: "0px 10px", maxWidth: "50%" }} onClick={handleTableSaveBtn} >Start Order</button>
                            </div>
                        </div>
                    </div>
                )
            }
            {/* ******************************************** Search Panel ******************************** */}
            <div className={`slider-containerPOS ${openInput ? 'open' : ''}`}>
                <div className="slider-contentPOS" style={{ backgroundColor: "white" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: "black", padding: "15px" }}>
                        <label style={{ fontWeight: "600", color: "white" }}>SEARCH PRODUCT</label>
                        <i onClick={closeInput} style={{ color: "white", cursor: "pointer" }}
                            className="fa-solid fa-xmark" ></i>
                    </div>
                    {/* <hr /> */}
                    <div style={{ padding: "15px" }}>
                        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                            <input type="text" placeholder=" Start Typing..." onChange={handleSearchChange} value={inputData} ref={inputRef} style={{ paddingLeft: "5px", width: "100%", height: "45px", borderRadius: "10px" }} />
                            {inputData && (
                                <span
                                    onClick={() => { setInputData(""); setSearchData([]) }}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        cursor: "pointer",
                                        color: "black",
                                        fontSize: "14px",
                                    }}
                                >
                                    ✖
                                </span>
                            )}
                        </div>
                        <div>
                            <div className="gridView-pos mt-3 overFlow_none" id="menu-pos" style={{ gridTemplateColumns: showGridView ? "repeat(2, auto)" : "repeat(1, auto)", width: "100%", margin: "0px" }}>
                                {searchData && searchData
                                    .filter((item, index, self) =>
                                        item.attributeParentId === 0 &&
                                        item.published !== false &&
                                        self.findIndex(i => i.d2Id == item.d2Id) == index
                                    )
                                    .map((item, i) => {
                                        const { available, message } = isAvailableForService(item);
                                        const cartItem = cartData.find(cart => cart.d2Id === item.d2Id);
                                        const quantity = cartItem ? cartItem.quantity : 0;
                                        // console.log(item)
                                        let additionalCartQty = 0;
                                        if (quantity === 0) {
                                            const relatedItems = filteredItems && filteredItems.filter(
                                                (relatedItem) =>
                                                    relatedItem.attributeParentId == item.d2Id
                                            )
                                            additionalCartQty = relatedItems.filter((item, index, self) =>
                                                index === self.findIndex((t) => (
                                                    t.d2Id === item.d2Id
                                                ))
                                            )
                                                .reduce((total, relatedItem) => {
                                                    const relatedCartItem = cartData.find((cart) => cart.d2Id === relatedItem.d2Id);
                                                    return total + (relatedCartItem ? (Number(relatedCartItem.quantity) || 0) : 0);
                                                }, 0);
                                        }
                                        const isVariable = (item.attributeParentId == 0 && item.isAttributeItem == true)
                                        return (
                                            <div key={item.id} style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                                opacity: available ? 1 : 0.6,
                                                pointerEvents: available ? "auto" : "none",
                                                width: showGridView ? "auto" : "333px",
                                                maxWidth: showGridView ? "175px" : "350px",
                                            }}>
                                                <div className="menuItem" style={{ margin: "10px 10px", maxHeight: '200px', flexDirection: "column", justifyContent: "center" }}>
                                                    <div className='row-m1'>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <LazyLoadImage
                                                                src={`${imageBaseUrl}${item?.images?.length === 0 ? companyData?.companyImageId : item?.images[0]}`}
                                                                alt=""
                                                                openImageModal={openImageModal}
                                                                onMouseUp={handleMouseUp}
                                                                onMouseLeave={handleMouseUp}
                                                                onTouchStart={handleMouseDown}
                                                                onTouchEnd={handleMouseUp}
                                                                style={{
                                                                    height: "auto",
                                                                    width: "65px",
                                                                    borderRadius: "15px",
                                                                    border: "1px solid lightGrey",
                                                                    maxHeight: "80px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="" style={{ marginLeft: "5px" }}>
                                                            <label className="menuLabel">{item.name}</label>
                                                            <h6 style={{ fontWeight: "600", color: "#606060", fontSize: "13px" }}>
                                                                Price: &#x20B9;{item.salePrice}
                                                            </h6>
                                                            {isVariable && (
                                                                <div style={{ color: "#8f8b8b", fontSize: "11px", marginTop: "5px", fontWeight: "600" }}>
                                                                    Customizable
                                                                </div>
                                                            )}
                                                            {!available && (
                                                                <div style={{ color: "red", fontSize: "10px", marginTop: "5px", fontWeight: "600" }}>
                                                                    {message}
                                                                </div>
                                                            )}
                                                            {available && (
                                                                <div className="quantity" style={{ justifyContent: "flex-start", marginLeft: "0px" }}>
                                                                    <button
                                                                        style={{ paddingBottom: "4px" }}
                                                                        className="quantity__minus"
                                                                        onClick={() => isVariable ? openVariableItemModal(item.d2Id) : handleDecrement(item.d2Id)}
                                                                    > - </button>
                                                                    <input className="quantity__input"
                                                                        type="number"
                                                                        ref={(el) => inputRefs.current.set(`${item.d2Id}_${i}`, el)} // Store input ref
                                                                        onClick={() => item.attributeParentId == 0 && item.isAttributeItem === true ? handleIncrementPOS(item.d2Id) : selectAtIndex(`${item.d2Id}_${i}`)}
                                                                        onChange={(event) => onQuantityChange(event, item.d2Id)}
                                                                        value={quantity == 0 ? (additionalCartQty || 0) : (quantity || 0)}
                                                                        style={{ borderColor: "transparent", borderColor: "#ededed", borderWidth: "1px 0px" }}
                                                                    />
                                                                    <button className="quantity__plus"
                                                                        onClick={() => handleIncrementPOS(item.d2Id)}
                                                                    > + </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowAlertBox && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn={handleOkBtnClick}
                    okayBtnName="Yes" />
            )}
            {isShowAlertBoxForTable && (
                <CommonAlertBox
                    isSingleButton={isSingleButton}
                    headingTop={headingTop}
                    message={message}
                    closeBtn={closeBtn}
                    closeBtnName={closeBtnName}
                    okayBtn={handleOkBtnClick}
                    okayBtnName="Yes" />
            )}
            {loading && <Loader />}
        </div >
    )
}

export default POSInvoiceForMobile