
import DateTime from "../../Inventory/Components/DateTime";
import React, { useState, useRef, useEffect } from "react";
import PageTabs from "../../Inventory/Components/PageTabs";
import OrderApi from "../../API/APIServices/Order";
import Loader from "../../Inventory/Props/Loader";
import Order from "../../API/APIServices/Order";
import { use } from "i18next";
import CurrentDateAndTime from "../../API/APIServices/CurrentDateAndTime";

function ModalCancellationAnalysisDetail({ orderId, setModalOpenStatus, fetchData, cancelList }) {

    const { ord1Id, ord2Id, itemOrd2Id } = typeof orderId === 'object' ? orderId : { ord1Id: null, ord2Id: null, itemOrd2Id: null };

    console.log("ord2Id", ord2Id);
    /***********title***************/
    useState(() => {
        document.title = 'Cancellation Analysis Detail';
    }, []);

    const [loading, setLoading] = useState(true);

    //***************{Enter Btn Click}***************//
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);
    const input5Ref = useRef(null);
    const input6Ref = useRef(null);
    const input7Ref = useRef(null);
    const input8Ref = useRef(null);

    const handleKeyPress = (event, nextInputRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            nextInputRef.current.focus();
        }
    };

    const [data, setData] = useState();
    const [checkIsAlreadyApprReject, setCheckIsAlreadyApprReject] = useState(false);
    const [isAlreadyApproved, setIsAlreadyApproved] = useState(false);
    const [orderDate, setOrderDate] = useState();

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            if (orderId) {
                //console.log(orderId.orderId);
                //alert(ord1Id +"-----"+ ord2Id)
                const filteredData = [
                    {
                        "fieldName": "ord1Id",
                        "operatorName": "Equal",
                        "compareValue": ord1Id
                    },
                    {
                        "fieldName": "ord2Id",
                        "operatorName": "Equal",
                        "compareValue": ord2Id
                    },
                ];
                // const result = await OrderApi.getOrderListFilter(filteredData);
                let result = cancelList.filter(item => item.ord2Id == ord2Id);
                if (result.length > 0) {
                    result = [
                        {
                            ...result[0],
                            cancelApprovedQty: result[0].cancelRequestQty  // Change the cancelApprovedQty of the first item
                        },
                        ...result.slice(1)
                    ];
                }
                setData(result);
                if (result?.[0]?.cancelApprovalStatus != null && result?.[0]?.cancelApprovalStatus != "" && result?.[0]?.cancelApprovalStatus != "Request") {
                    setIsAlreadyApproved(true)
                }
                console.log(result)
                setOrderDate(result[0]?.orderDate)
                if (result.length > 0 && ["Approve", "Reject"].includes(result[0].cancelApprovalStatus)) {
                    setCheckIsAlreadyApprReject(true);
                }
                setLoading(false);
            }
        }
        fetchData();
    }, [orderId]);

    const [prevApprovedQty, setPrevApprovedQty] = useState(0);
    useEffect(() => {
        let result = cancelList.filter(item => item.itemOrd2Id == itemOrd2Id);
        const total = result.reduce((sum, data) => sum + (data.cancelApprovedQty), 0);
        setPrevApprovedQty(total)
    }, [])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);

        setData((prevValues) => {
            const updatedData = [...prevValues];
            if (updatedData.length > 0) {
                updatedData[0] = { ...updatedData[0], [name]: value };
            } else {
                // If the array is empty, create a new object with the updated value
                updatedData.push({ [name]: value });
            }
            return updatedData; // Return the updated array
        });

    };

    const handleSaveBtn = async () => {
        if (data[0].cancelApprovedQty == 0 || data[0].cancelApprovedQty == "") {
            alert("Approve Qty should be greater than 0");
            return;
        }
        if (window.confirm(`Are you sure you want to update the status to ${data[0].cancelApprovalStatus} ?`)) {
            // const TotalCancelAmount = await Order.getCancelItemByOrd1Id(ord1Id);
            const orderData = await Order.getOrderListByOrd1Id(ord1Id);
            const TotalCancelAmount = orderData.item1;

            setLoading(true)
            const storedArrayString = localStorage.getItem('loginAccountUserData');
            const loginUser = JSON.parse(storedArrayString);

            const formattedDate = await CurrentDateAndTime.getCurrentDateTime();
            // const formattedDate = new Date(isoString);
            console.log(data);

            const filterData = [{
                operationType: 0,
                path: "O2OrderDate",
                op: "Add",
                from: "string",
                value: orderDate
            }]
            const UpadetItem = await OrderApi.updateItemPartially(filterData, ord2Id);
            // ********************** If Partial Approve/Reject ****************************
            let filteredData;
            if (data[0].cancelApprovedQty != data[0].cancelRequestedBy) {
                if (data[0].cancelApprovalStatus == "Partial Approve" || data[0].cancelApprovalStatus == "Approve") {
                    // alert(data[0].cancelApprovedQty === null ? data[0].cancelQty : data[0].cancelApprovedQty)
                    // alert(data[0].cancelApprovedQty)
                    // alert(data[0].cancelQty)
                    filteredData = [
                        {
                            operationType: 0,
                            path: "ICanApprBy", // Approved By
                            op: "Add",
                            from: "string",
                            value: loginUser.userId
                        },
                        {
                            operationType: 0,
                            path: "ICanApprOn",  //Approved On
                            op: "Add",
                            from: "string",
                            value: formattedDate
                        },
                        {
                            operationType: 0,
                            path: "ICanApprStatus", //Approved Status
                            op: "Add",
                            from: "string",
                            value: data[0].cancelApprovalStatus
                        },
                        {
                            operationType: 0,
                            path: "ICanReqRemarks",  //Approved Remarks
                            op: "Add",
                            from: "string",
                            value: data[0].cancelApprovalRemarks
                        },
                        {
                            operationType: 0,
                            path: "ICanApprQty", //Approved Qty
                            op: "Add",
                            from: "string",
                            value: data[0].cancelApprovedQty === null ? data[0].cancelRequestedBy : data[0].cancelApprovedQty
                        },
                        {
                            operationType: 0,
                            path: "ICanReqAmount", //CancelAmount
                            op: "Add",
                            from: "string",
                            value: (data[0].cancelApprovedQty * data[0].itemPrice)
                        }
                    ]
                    const cancelData = [
                        {
                            operationType: 0,
                            path: "CancelOrderTotalAmt",
                            op: "Add",
                            from: "string",
                            value: ((TotalCancelAmount ? TotalCancelAmount[0].o1CancelledAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                            // value: ((TotalCancelAmount ? TotalCancelAmount[0].totalCancelAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                        }
                    ];
                    await OrderApi.updateOrderPartially(cancelData, ord1Id);
                    // ********************** If balance is zero than update payment status ****************
                    // const balanceAmt = data[0].confirmTotalAmt - (data[0].paymentAmount + (data[0].qty * data[0].rate))
                    // const balanceAmt = data[0].grandTotalAmt - ((data[0].paidAmt + TotalCancelAmount[0].totalCancelAmt) + Number(Number(data[0].cancelApprovedQty) * data[0].itemPrice))
                    const balanceAmt = data[0].grandTotalAmt - ((data[0].paidAmt + TotalCancelAmount[0].o1CancelledAmt) + Number(Number(data[0].cancelApprovedQty) * data[0].itemPrice))
                    //alert(balanceAmt)
                    if (balanceAmt == 0) {
                        const paymentStatus = [
                            {
                                operationType: 0,
                                path: "paymentStatus",
                                op: "Add",
                                from: "string",
                                value: "Done"
                            }
                        ];
                        await OrderApi.updateOrderPartially(paymentStatus, ord1Id);
                    }
                }
                else {
                    // **************** If Reject **********************
                    filteredData = [
                        {
                            operationType: 0,
                            path: "ICanApprBy", //Approved By
                            op: "Add",
                            from: "string",
                            value: loginUser.userId
                        },
                        {
                            operationType: 0,
                            path: "ICanApprOn",  //Approved On
                            op: "Add",
                            from: "string",
                            value: formattedDate
                        },
                        {
                            operationType: 0,
                            path: "ICanApprStatus",  //Approved Status
                            op: "Add",
                            from: "string",
                            value: data[0].cancelApprovalStatus
                        },
                        {
                            operationType: 0,
                            path: "ICanReqRemarks", //Approved Remarks
                            op: "Add",
                            from: "string",
                            value: data[0].cancelApprovalRemarks
                        }
                    ]
                }
                // else if (data[0].cancelApprovalStatus == "Partial Reject" || data[0].cancelApprovalStatus == "Reject") {
                //     filteredData = [
                //         {
                //             operationType: 0,
                //             path: "ItemCancelApprovedBy",
                //             op: "Add",
                //             from: "string",
                //             value: loginUser.userId
                //         },
                //         {
                //             operationType: 0,
                //             path: "ItemCancelApprovedOn",
                //             op: "Add",
                //             from: "string",
                //             value: formattedDate
                //         },
                //         {
                //             operationType: 0,
                //             path: "ICanApprStatus",
                //             op: "Add",
                //             from: "string",
                //             value: data[0].cancelApprovalStatus
                //         },
                //         {
                //             operationType: 0,
                //             path: "ICanReqRemarks",
                //             op: "Add",
                //             from: "string",
                //             value: data[0].cancelApprovalRemarks
                //         },
                //         {
                //             operationType: 0,
                //             path: "ItemCancelApprovedQty",
                //             op: "Add",
                //             from: "string",
                //             value: data[0].cancelQty - data[0].cancelApprovedQty
                //         },
                //     ]
                //     const cancelData = [
                //         {
                //             operationType: 0,
                //             path: "CancelOrderTotalAmt",
                //             op: "Add",
                //             from: "string",
                //             value: (data[0].orderCancelOrderTotalAmt + (data[0].cancelApprovedQty * data[0].rate))
                //         }
                //     ];
                //     await OrderApi.updateOrderPartially(cancelData, ord1Id);
                //     //   *************** If first Approved and then Reject **********************
                //     // if (checkIsAlreadyApprReject === true) {
                //     //     const cancelData = [
                //     //         {
                //     //             operationType: 0,
                //     //             path: "CancelOrderTotalAmt",
                //     //             op: "Add",
                //     //             from: "string",
                //     //             value: (data[0].orderCancelOrderTotalAmt - (data[0].cancelApprovedQty * data[0].rate))
                //     //         }
                //     //     ];
                //     //     await OrderApi.updateOrderPartially(cancelData, ord1Id);

                //     //     const data = [{
                //     //         operationType: 0,
                //     //         path: "cancelAmt",
                //     //         op: "Add",
                //     //         from: "string",
                //     //         value: 0
                //     //     }]
                //     //     await OrderApi.updateItemPartially(data, ord2Id);
                //     // }
                //     // ******************************************************************
                // }
                // await OrderApi.updateItemPartially(filteredData, ord2Id);
                await OrderApi.updateCancelItemPartially(filteredData, ord2Id);
                alert("Updated")
                fetchData();
                setLoading(false)
                closeModal();
                return 0;
            }
            // ***************************************************
            let updatedData;
            if (data[0].cancelApprovalStatus === "Approve") {
                updatedData = [
                    {
                        operationType: 0,
                        path: "ICanApprBy", //Approved By
                        op: "Add",
                        from: "string",
                        value: loginUser.userId
                    },
                    {
                        operationType: 0,
                        path: "ICanApprOn", //Approved On
                        op: "Add",
                        from: "string",
                        value: formattedDate
                    },
                    {
                        operationType: 0,
                        path: "ICanApprStatus", //Approved Status
                        op: "Add",
                        from: "string",
                        value: data[0].cancelApprovalStatus
                    },
                    {
                        operationType: 0,
                        path: "ICanReqRemarks", //Approved Remarks
                        op: "Add",
                        from: "string",
                        value: data[0].cancelApprovalRemarks
                    },
                    {
                        operationType: 0,
                        path: "ICanApprQty",  //Approved Qty
                        op: "Add",
                        from: "string",
                        value: data[0].cancelApprovedQty === null ? data[0].cancelQty : data[0].cancelApprovedQty
                    },
                    // {
                    //     operationType: 0,
                    //     path: "cancelAmt",
                    //     op: "Add",
                    //     from: "string",
                    //     value: (data[0].cancelQty * data[0].rate)
                    // }
                ]
                const cancelData = [
                    {
                        operationType: 0,
                        path: "CancelOrderTotalAmt",
                        op: "Add",
                        from: "string",
                        value: ((TotalCancelAmount ? TotalCancelAmount[0].o1CancelledAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                        // value: ((TotalCancelAmount ? TotalCancelAmount[0].totalCancelAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                        // value: (data[0].orderCancelOrderTotalAmt + ((data[0].cancelApprovedQty === null ? data[0].cancelQty : data[0].cancelApprovedQty) * data[0].rate))
                    }
                ];
                await OrderApi.updateOrderPartially(cancelData, ord1Id);
                // ********************** If balance is zero than update payment status ****************
                // const balanceAmt = data[0].grandTotalAmt - (data[0].paidAmt + Number(Number(data[0].cancelApprovedQty) * data[0].itemPrice))
                // const balanceAmt = data[0].grandTotalAmt - ((data[0].paidAmt + TotalCancelAmount[0].totalCancelAmt) + Number(Number(data[0].cancelApprovedQty) * data[0].itemPrice))
                const balanceAmt = data[0].grandTotalAmt - ((data[0].paidAmt + TotalCancelAmount[0].o1CancelledAmt) + Number(Number(data[0].cancelApprovedQty) * data[0].itemPrice))
                //alert(balanceAmt)
                if (balanceAmt == 0) {
                    const paymentStatus = [
                        {
                            operationType: 0,
                            path: "paymentStatus",
                            op: "Add",
                            from: "string",
                            value: "Done"
                        }
                    ];
                    await OrderApi.updateOrderPartially(paymentStatus, ord1Id);
                }
            }
            else {
                // ******************************* When Reject *************************
                updatedData = [
                    {
                        operationType: 0,
                        path: "ICanApprBy", //Approved By
                        op: "Add",
                        from: "string",
                        value: loginUser.userId
                    },
                    {
                        operationType: 0,
                        path: "ICanApprOn",  //Approved On
                        op: "Add",
                        from: "string",
                        value: formattedDate
                    },
                    {
                        operationType: 0,
                        path: "ICanApprStatus",  //Approved Status
                        op: "Add",
                        from: "string",
                        value: data[0].cancelApprovalStatus
                    },
                    {
                        operationType: 0,
                        path: "ICanReqRemarks", //Approved Remarks
                        op: "Add",
                        from: "string",
                        value: data[0].cancelApprovalRemarks
                    }
                ]
                //   *************** If first Approved and then Reject **********************
                if (checkIsAlreadyApprReject === true) {
                    const cancelData = [
                        {
                            operationType: 0,
                            path: "CancelOrderTotalAmt",
                            op: "Add",
                            from: "string",
                            // value: ((TotalCancelAmount ? TotalCancelAmount[0].totalCancelAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                            value: ((TotalCancelAmount ? TotalCancelAmount[0].o1CancelledAmt : 0) + ((data[0].cancelApprovedQty === null ? 0 : data[0].cancelApprovedQty) * data[0].itemPrice))
                        }
                    ];
                    //await OrderApi.updateOrderPartially(cancelData, ord1Id);

                    // const data = [{
                    //     operationType: 0,
                    //     path: "cancelAmt",
                    //     op: "Add",
                    //     from: "string",
                    //     value: 0
                    // }]
                    // await OrderApi.updateItemPartially(data, ord2Id);
                }
                // ******************************************************************
            }
            // await OrderApi.updateItemPartially(updatedData, ord2Id);
            await OrderApi.updateCancelItemPartially(updatedData, ord2Id);


            alert("Updated")
            fetchData();
            setLoading(false)
            closeModal();
        }
    }

    const closeModal = () => {
        setModalOpenStatus(false);
    }

    const handleAprroveQtyChange = (event) => {
        const { name, value } = event.target;
        console.log(data);
        if (data[0].orderQty < (prevApprovedQty + value)) {
            alert("Cannot Approve Qty more than the actual Qty");
            setData((prevValues) => {
                const updatedData = [...prevValues];
                if (updatedData.length > 0) {
                    updatedData[0] = { ...updatedData[0], [name]: (data[0].orderQty - prevApprovedQty) };
                } else {
                    updatedData.push({ [name]: data[0].orderQty });
                }
                return updatedData;
            });
            return;
        }
        setData((prevValues) => {
            const updatedData = [...prevValues];
            if (updatedData.length > 0) {
                updatedData[0] = { ...updatedData[0], [name]: value };
            } else {
                updatedData.push({ [name]: value });
            }
            return updatedData;
        });
    }

    const handleFocus = (event) => {
        event.target.select();
    };

    return (
        <div>
            <PageTabs />
            <div className="content_box scrollModal">
                <div className="space-between" style={{ padding: "5px 15px" }}>
                    <div className="flex-start">
                        <label className="ques">Item&nbsp;Name&nbsp;:&nbsp;</label>
                        <label className="ans">{data?.[0]?.itemName}</label>
                    </div>
                    <div className="flex-start">
                        <label className="ques">Order&nbsp;No&nbsp;:&nbsp;</label>
                        <label className="ans">{data?.[0]?.orderNo}</label>
                    </div>
                </div>
                <div className="company_control">
                    <div className="stock-im-dep1">
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <select className="modify_im_input mode_category1" ref={input1Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input2Ref)} type="text" required value={data?.[0]?.cancelStatus} >
                                    <option>--Select--</option>
                                    <option value="NA">NA</option>
                                    <option value="Cancel Item">Cancel Item</option>
                                    <option value="NCKOT">NCKOT</option>
                                </select>
                                <label class="modify_lbl_text3">Cancel Request Status</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input2Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input3Ref)} type="number" required value={data?.[0]?.orderQty} />
                                <label class="ml-15 modify_p_text2">Sale Qty</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input3Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input4Ref)} type="number" required value={data?.[0]?.cancelRequestQty} />
                                <label class="ml-15 modify_p_text2">Cancel Qty</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input5Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input6Ref)} type="text" required value={data?.[0]?.cancelRemarks} />
                                <label class="ml-15 modify_p_text2">Cancel Request Remark</label>
                            </div>
                        </div>
                    </div>
                    <div className="stock-im-dep1">
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input4Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="number" name="" onFocus={(e) => handleFocus(e)} onClick={(e) => handleFocus(e)}
                                    required value={prevApprovedQty} />
                                <label class="ml-15 modify_p_text2">Previous Approved Qty</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input4Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input5Ref)} type="number" name="cancelApprovedQty" onFocus={(e) => handleFocus(e)} onClick={(e) => handleFocus(e)}
                                    //onChange={handleAprroveQtyChange} 
                                    //disabled={true}
                                    required value={data?.[0]?.cancelApprovedQty == null ? 0 : data?.[0]?.cancelApprovedQty} />
                                <label class="ml-15 modify_p_text2">Approve Qty</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">     {/* ********************* */}
                            <div className="m-10 input-box-mok1">
                                <select className="modify_im_input mode_category1" ref={input7Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input8Ref)} type="text" required
                                    value={data?.[0]?.cancelApprovalStatus} name="cancelApprovalStatus" onChange={handleInputChange}>
                                    <option value="">--Select--</option>
                                    {/* <option value="NA">NA</option>
                                    <option value="Request">Request</option> */}
                                    <option value="Approve">Approve</option>
                                    <option value="Reject">Reject</option>
                                    {/* <option value="Partial Approve">Partially Approve</option> */}
                                    {/* <option value="Partial Reject">Partially Reject</option> */}
                                </select>
                                <label class="modify_lbl_text3">Reject/Approve Status</label>
                            </div>
                        </div>
                        <div className="input-box-Imo1 input-height2">                {/* ********************* */}
                            <div className="m-10 input-box-mok1">
                                <input className="modify_im_input mode_category1" ref={input6Ref}
                                    onKeyDown={(event) => handleKeyPress(event, input7Ref)} type="text" required
                                    value={data?.[0]?.cancelApprovalRemarks} name="cancelApprovalRemarks" onChange={handleInputChange} />
                                <label class="ml-15 modify_p_text2">Reject/Approve Remarks</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="input-order-buttons" style={{ justifyContent: "flex-end", paddingTop: "0px" }}>
                    <div className="input-remark-left01 input_RMK">
                        <div className="button-m5 btn_ord_pad">
                            {/* <button class="search-button-main-search">Save */}
                            {!isAlreadyApproved && (
                                <button class="greenBtn" style={{ width: "100px" }} onClick={handleSaveBtn}>Save
                                    {/* <i id="step-2" class="fa-solid fa-thin fa-magnifying-glass im-icon-white"></i> */}
                                </button>
                            )}
                            <button class="redBtn" style={{ width: "100px" }} onClick={closeModal}>Close</button>
                        </div>
                        {/* <div className="button-m5 btn_ord_pad">
                            <button class="search-button-main">Cancel
                                <i id="step-2" class="fa-solid fa-regular fa-xmark im-icon-2"></i>
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div >
    );
}

export default ModalCancellationAnalysisDetail;
