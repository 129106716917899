import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const AddVoucher = {
   
   
    GetVouchers: async (MasterType) => {
    try {
      //const apiUrl = `${express_server_url}/api/AddVouchers/GetAddVoucherss`;
	  const apiUrl = `${WebApiUrl}/api/AddVoucher/GetAllAddVouchers?MasterType=${MasterType}`;
      const response = await axios.post(apiUrl, {
      }, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log('Response:', response.data.result); 
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }

  },

  GetVouchersWithFilter: async () => {
    try {
	  const apiUrl = `${WebApiUrl}/api/AddVouchers/GetAddVoucherss`;
      const response = await axios.post(apiUrl, {
        filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
      }, {
        withCredentials: true,
      });
      // console.log(apiUrl);
      console.log('Response:', response.data.result); 
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }

  },

  AddVoucherDetail: async (VoucherData, addVouchersEnterAccountDetails , addVoucherSundryDetail) => {  
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);

    var addVoucherSundryDetailNew = [];
    if (Array.isArray(addVoucherSundryDetail) && addVoucherSundryDetail.length > 0) {
        addVoucherSundryDetailNew = addVoucherSundryDetail.map(item => ({
              accountId: Number(item.accountId),
              accountId2: Number(item.accountId2),
              indx: Number(item.indx),
              sundryType: Number(item.sundryType),
              isAmtPrcnt: Number(item.isAmtPrcnt),
              sundryNature: item.sundryNature ,
              igstPer: Number(item.igstPer),
              taxableAmt: Number(item.taxableAmt),
              itmNetAmt: Number(item.itmNetAmt),
              createdBy: 0,
              createdOn: formattedDate,
      }));
    }

    var addVouchersEnterAccountDetailsNew = [];
    if (Array.isArray(addVouchersEnterAccountDetails) && addVouchersEnterAccountDetails.length > 0) {
        addVouchersEnterAccountDetailsNew = addVouchersEnterAccountDetails.map(item => ({
                accountId: Number(item.accountId),
                dc:  item.dc,
                balanceAmt: Number(item.balanceAmt),
                debitAmt: Number(item.debitAmt),
                creditAmt: Number(item.creditAmt),
                taxVal1: Number(item.taxVal1),
                taxVal2: Number(item.taxVal2),
                taxVal3: Number(item.taxVal3),
                docTypeDtl:  item.docTypeDtl,
                chqDocNo:  item.chqDocNo,
                chqDocDate:  item.chqDocDate,
                shortNarration:  item.shortNarration,
                e1: Number(item.e1),
                e2:  item.e2,
                e3:  item.e3,
                e4:  item.e4,
                e5:  item.e5,
                createdBy: 0,
                createdOn:  formattedDate,
      }));
    }
    
    var VoucherDataNew = {};
    var obj = {
        mastType: Number(VoucherData.mastType),
        vouchSeries: Number(VoucherData.vouchSeries),
        vouchNo: VoucherData.vouchNo ,
        autoVouchNo: Number(VoucherData.autoVouchNo),
        vouchDate: VoucherData.vouchDate ? new Date(VoucherData.vouchDate).toISOString() : new Date().toISOString(),
        partyId: Number(VoucherData.partyId),
        bookedby: Number(VoucherData.bookedby),
        billAmt: Number(VoucherData.billAmt),
        refBillSrs: VoucherData.refBillSrs ,
        refBillNo: VoucherData.refBillNo ,
        refBillDate: VoucherData.refBillDate ? new Date(VoucherData.refBillDate).toISOString() : new Date().toISOString(),
        refBillRemark: VoucherData.refBillRemark,
        refTransId: VoucherData.refTransId,
        gstApplicable: VoucherData.gstApplicable,
        itc: VoucherData.itc,
        rcm: VoucherData.rcm,
        gstType: VoucherData.gstType,
        e1: VoucherData.e1,
        e2: VoucherData.e2,
        e3: VoucherData.e3,
        e4: VoucherData.e4,
        e5: VoucherData.e5,
        shortNarration: VoucherData.shortNarration ,
        createdBy: 0,
        createdOn: formattedDate ,
        addVouchersEnterAccountDetails: addVouchersEnterAccountDetailsNew,
        addVoucherSundryDetail : addVoucherSundryDetailNew
    };
    VoucherDataNew = obj;

    try {
      //const apiUrl = `${express_server_url}/api/AddVouchers`;
	   const apiUrl = `${WebApiUrl}/api/AddVoucher`;
      const response = await axios.post(apiUrl, VoucherDataNew , {
        withCredentials: true,
        credentials: 'include',
      });
      console.log('Response:', response.data.result);
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }
  }, 
  

  GetVouchersById: async (accountId , controlTypevalue) => {
    try {
      console.log("get by id")
      //const apiUrl = `${express_server_url}/api/AddVouchers?accountId=${accountId}`;
	  const apiUrl = `${WebApiUrl}/api/AddVoucher/GetVouchersById?Trans1Id=${accountId}&MasterType=${controlTypevalue}`;
      const response = await axios.get(apiUrl, {}, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${express_server_url}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  
  
  
  DeleteVouchers: async (Trans1Id) => {
    try {
      console.log("delete")
      //const apiUrl = `${express_server_url}/api/AddVouchers?accountId=${accountId}`;
	  const apiUrl = `${WebApiUrl}/api/AddVouchers?accountId=${Trans1Id}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log('Delete Response:', response.data.result);
      return true;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },


  UpdateVouchers: async(data)=>{
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      
      //const apiUrl = `${express_server_url}/api/AddVouchers?accountId=${data.id}`;
	  const apiUrl = `${WebApiUrl}/api/AddVouchers?accountId=${data.id}`;
      console.log(data)
      const response = await axios.put(apiUrl, {
        id: data.id,
        name: data.name,
        printName: data.printName,
        primaryGroup: parseBoolean(data.primaryGroup),
        parentGrp: Number(data.underGroup),
        groupType: Number(data.groupType), 
        isActive: parseBoolean(data.isActive),
      }, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log('Response:', response.data.result);
       return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error('Error:', error.message);
    }


  }
  
};

export default AddVoucher;
