import axios from '../axiosConfig'; // Adjust the import path accordingly
//import AccountGroupApi from './AccountGroup';
//import StateInfoApi from './StateInfo';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const User = {

  loginUser: async (data) => {
    // console.log(data)
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
        const response = await axios.post(apiUrl, {
          compCode: data.compCode,
          userName: data.userName,
          password: data.password,
          comClientRefId: data.comClientRefId,
        }, {
          withCredentials: true,
        });

        console.log("Response:", response.data);
        const arrayToStore = response.data.result;
        const arrayString = JSON.stringify(arrayToStore);
        localStorage.setItem('loginUser', arrayString);

        if (response.data.isSuccess) {
          return response.data.result;
        } else {
          alert("Incorrect username or password");
        }
      } catch (error) {
        console.error('Error:', error.message);
        // if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        //     localStorage.removeItem('loginAccountUserData');
        //     localStorage.removeItem("loginUser");
        //     // localStorage.clear();
        //     await User.Logout();
        //      // window.location.href = "/loginpanel";
        // }

        if (error.response && error.response.status === 401) {
          alert("Authentication failed: Incorrect username or password");
        } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        } else {
          alert("An error occurred during login. Please try again.");
        }
      }
    }
    return fetchData();
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  },
  // ************************* Company **********************************
  GetCompany: async () => {
    const fetchData = async (retryCount = 0, maxRetries = 3) => {
      try {
        const apiUrl = `${WebApiUrl}/api/UserInfo/GetCompanies`;
        const response = await axios.post(apiUrl, {}, {
          withCredentials: true,
        });
        console.log(apiUrl);
        console.log('Response:', response.data.result);
      } catch (error) {
        console.error('Error:', error.message);
        if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
          console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
          await new Promise(resolve => setTimeout(resolve, 2000));
          return fetchData(retryCount + 1, maxRetries);
        }
        // if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
        //     localStorage.removeItem('loginAccountUserData');
        //     localStorage.removeItem("loginUser");
        //     // localStorage.clear();
        //     await User.Logout();
        //      // window.location.href = "/loginpanel";
        // }
      }
    }
    return fetchData();
  },

  Logout: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/UserInfo/logout`;
      const response = await axios.post(apiUrl, {}, {
        withCredentials: true,
      });
      console.log(apiUrl);
      console.log('Response:', response.data.result);
      const uniqueId = localStorage.getItem("CommonDeploymentUniqueId");
      if (uniqueId != null && uniqueId != undefined && uniqueId != "" && uniqueId != "null") {
        localStorage.clear();
        window.location.href = `/loginpanel?q=${uniqueId}`;
      }
      else {
        localStorage.clear();
        window.location.href = "/loginpanel";
      }
    } catch (error) {
      console.error('Error:', error.message);
      // if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
      //     localStorage.removeItem('loginAccountUserData');
      //     localStorage.removeItem("loginUser");
      //     // localStorage.clear();
      //     await User.Logout();
      //      // window.location.href = "/loginpanel";
      // }
    }
  },

  login: async (data) => {
    try {
      var WebApiUrl = express_server_url;
      WebApiUrl = express_server_url.replace("localhost", window.location.hostname);
      const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      //const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, {
        id: "1",
        compCode: "C0001",
        userName: "admin",
        password: "admin",
        comClientRefId: "string",
      }, {
        withCredentials: true,
      });

      console.log("Response:", response.data.result);

      const arrayToStore = response.data.result;
      const arrayString = JSON.stringify(arrayToStore);
      localStorage.setItem('loginUser', arrayString);

      if (response.data.isSuccess) {
        alert("Login successfull");
      } else {
        alert("Incorrect username or password");
      }
    } catch (error) {
      console.error('Error:', error.message);
      // if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
      //     localStorage.removeItem('loginAccountUserData');
      //     localStorage.removeItem("loginUser");
      //     // localStorage.clear();
      //     await User.Logout();
      //      // window.location.href = "/loginpanel";
      // }

      if (error.response && error.response.status === 401) {
        alert("Authentication failed: Incorrect username or password");
      } else {
        alert("An error occurred during login. Please try again.");
      }
    }
    //AccountGroupApi.GetAccountGroups(setMainDataCallback);
    //StateInfoApi.GetState(setStateInfoCallback);
  },
};

export default User;
