import DateTime from "../../Components/DateTime";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import PreviousOrder from "../../Dashboard/PreviousOrder";
import AddVoucherAccList from "../../Props/AddVoucherAccList";
import MainButton from "../../Props/MainButton";
import BillSeriesAPI from "../../../API/APIServices/BillSeriesMaster";
import BillNumberingAPI from "../../../API/APIServices/BillNumbering";
import AddVouchers from "../../../API/APIServices/AddVoucher";
function AddVoucher(Props) {
  /***********title***************/
  useState(() => {
    document.title = "Add Voucher";
  }, []);

  useEffect(() => {
    debugger;
    if (Props.data) {
      var AccountData = Props.data.addVouchersEnterAccountListDetails;

      var AccoundDataList = [];
      for (var i = 0; i < AccountData?.length; i++) {
        var obj = {
          trans1Id: AccountData[i].trans1Id || "",
          mastType: AccountData[i].mastType || "",
          vouchSeries: AccountData[i].vouchSeries || "",
          vouchNo: AccountData[i].vouchNo || "",
          vouchDate: AccountData[i].vouchDate || "",
          accountId: AccountData[i].accountId || "",
          accountName: AccountData[i].partyIdName || "",
          dc: AccountData[i].dc || "",
          balanceAmt: AccountData[i].balanceAmt || "",
          debitAmt: AccountData[i].debitAmt || "",
          creditAmt: AccountData[i].creditAmt || "",
          taxVal1: AccountData[i].taxVal1 || "",
          taxVal2: AccountData[i].taxVal2 || "",
          taxVal3: AccountData[i].taxVal3 || "",
          docTypeDtl: AccountData[i].docTypeDtl || "",
          chqDocNo: AccountData[i].chqDocNo || "",
          chqDocDate: AccountData[i].chqDocDate || "",
          shortNarration: AccountData[i].shortNarration || "",
          e1: AccountData[i].e1 || "",
          e2: AccountData[i].e2 || "",
          e3: AccountData[i].e3 || "",
          e4: AccountData[i].e4 || "",
          e5: AccountData[i].e5 || "",
          createdBy: AccountData[i].createdBy,
          createdOn: AccountData[i].createdOn,
          isDropdownOpen: false,
          highlightedIndex: -1,
          filteredSundry: [],
        };
        AccoundDataList.push(obj);
      }
      if (AccoundDataList.length > 0) {
        // setLoading(false);
        setAddVouchersEnterAccountDetails(AccoundDataList);
      }

      var Sundrydata = Props.data.addVoucherSundryDetail;
      debugger;

      var SundryDataList = [];
      for (var i = 0; i < Sundrydata?.length; i++) {
        var obj = {
          rowIndex: Sundrydata[i].indx || "",
          accountId: Sundrydata[i].accountId || "",
          accountName: Sundrydata[i].sundryNature || "",
          accountId2: Sundrydata[i].accountId2 || "",
          indx: Sundrydata[i].indx || "",
          sundryType: Sundrydata[i].sundryType || "",
          isAmtPrcnt: Sundrydata[i].isAmtPrcnt || "",
          sundryNature: Sundrydata[i].sundryNature || "",
          igstPer: Sundrydata[i].igstPer || "",
          taxableAmt: Sundrydata[i].taxableAmt || "",
          itmNetAmt: Sundrydata[i].itmNetAmt || "",
          createdBy: Sundrydata[i].createdBy || "",
          createdOn: Sundrydata[i].createdOn || "",
          isDropdownOpen: false,
          highlightedIndex: -1,
          filteredSundry: [],
        };
        SundryDataList.push(obj);
      }
      if (SundryDataList.length > 0) {
        // setLoading(false);
        setAddVoucherSundryDetail(SundryDataList);
      }

      debugger;
      console.log(Props.data);
      var data = Props.data;

      setVoucherData({
        mastType: data.mastType || "",
        vouchSeries: data.vouchSeries || "",
        vouchNo: data.vouchNo || "",
        autoVouchNo: data.autoVouchNo || "",
        // vouchDate: data.vouchDate || "",
        vouchDate: data.vouchDate ? data.vouchDate.split("T")[0] : "",
        partyId: data.partyId || "",
        bookedby: data.bookedby || "",
        billAmt: data.billAmt || "",
        refBillSrs: data.refBillSrs || "",
        refBillNo: data.refBillNo || "",
        refBillDate: data.refBillDate ? data.refBillDate.split("T")[0] : "",
        refBillRemark: data.refBillRemark || "",
        refTransId: data.refTransId || "",
        gstApplicable: data.gstApplicable || "",
        itc: data.itc || "",
        rcm: data.rcm || "",
        gstType: data.gstType || "",
        e1: data.e1 || "",
        e2: data.e2 || "",
        e3: data.e3 || "",
        e4: data.e4 || "",
        e5: data.e5 || "",
        shortNarration: data.shortNarration || "",
        createdBy: data.createdBy,
        createdOn: data.createdOn,
      });

      setSelectedGSTId(data.gstApplicable);
    }
  }, [Props.data]);

  const [addVoucherSundryDetail, setAddVoucherSundryDetail] = useState([
    {
      rowIndex: 0,
      accountId: 0,
      accountName: "",
      accountId2: 0,
      indx: 0,
      sundryType: 0,
      isAmtPrcnt: 0,
      sundryNature: "",
      igstPer: 0,
      taxableAmt: 0,
      itmNetAmt: 0,
      createdBy: 0,
      createdOn: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredSundry: [],
    },
    {
      rowIndex: 0,
      accountId: 0,
      accountName: "",
      accountId2: 0,
      indx: 0,
      sundryType: 0,
      isAmtPrcnt: 0,
      sundryNature: "",
      igstPer: 0,
      taxableAmt: 0,
      itmNetAmt: 0,
      createdBy: 0,
      createdOn: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredSundry: [],
    },
  ]);

  const [addVouchersEnterAccountDetails, setAddVouchersEnterAccountDetails] =
    useState(
      Array.from({ length: 4 }, (_, index) => ({
        trans1Id: 0,
        mastType: 0,
        vouchSeries: 0,
        vouchNo: "",
        vouchDate: "2025-01-23",
        accountId: 0,
        accountName: "",
        dc: index % 2 === 0 ? "D" : "C", // Alternates between "D" and "C"
        balanceAmt: "",
        debitAmt: "",
        creditAmt: "",
        taxVal1: "",
        taxVal2: "",
        taxVal3: "",
        docTypeDtl: "",
        chqDocNo: "",
        chqDocDate: "",
        shortNarration: "",
        e1: "",
        e2: "",
        e3: "",
        e4: "",
        e5: "",
        createdBy: 0,
        createdOn: "2025-01-23",
      }))
    );

  const [VoucherData, setVoucherData] = useState({
    mastType: 19,
    vouchSeries: 0,
    vouchNo: "",
    autoVouchNo: 0,
    vouchDate: new Date().toISOString().split("T")[0],
    partyId: 0,
    bookedby: 0,
    billAmt: 0,
    refBillSrs: "",
    refBillNo: "",
    refBillDate: "",
    refBillRemark: "",
    refTransId: "",
    gstApplicable: "",
    itc: "",
    rcm: "",
    gstType: "",
    e1: "",
    e2: "",
    e3: "",
    e4: "",
    e5: "",
    shortNarration: "",
    createdBy: 0,
    createdOn: "2025-01-23",
  });

  const [GSTApplicable, setGSTApplicable] = useState([
    {
      ParentID: [19, 20, 21, 22, 23, 24],
      id: 1,
      name: "Not Applicable",
      GstBox: false,
    },
    { ParentID: [19, 21], id: 2, name: "RCM/UnReg. Expense", GstBox: true },
    { ParentID: [19, 21], id: 3, name: "Registered Expense", GstBox: true },
    { ParentID: [19], id: 4, name: "Refund Advance", GstBox: true },
    { ParentID: [19, 21], id: 5, name: "Exemption Expense", GstBox: false },
    { ParentID: [19], id: 6, name: "GST Pay To Govt", GstBox: true },
    { ParentID: [20], id: 7, name: "Advance Receipt", GstBox: true },
    {
      ParentID: [21],
      id: 8,
      name: "Cr. Note Receive Against Purchase",
      GstBox: true,
    },
    {
      ParentID: [21],
      id: 9,
      name: "Dr. Note Receive Against Purchase",
      GstBox: true,
    },
    { ParentID: [23], id: 10, name: "Received against Purchase", GstBox: true },
    { ParentID: [24], id: 11, name: "Issued against Sale", GstBox: true },
    { ParentID: [], id: 12, name: "Consolidated RCM Payable", GstBox: true },
  ]);

  // debugger;
  const [selectedGSTId, setSelectedGSTId] = useState(null);

  const selectedItem = GSTApplicable.find(
    (item) => item.id === Number(selectedGSTId)
  );
  const shouldShowSundryDetails = selectedItem ? selectedItem.GstBox : false;

  useEffect(() => {
    console.log("selectedGSTId", selectedGSTId);
    console.log("shouldShowSundryDetails", shouldShowSundryDetails);
  }, [shouldShowSundryDetails, selectedGSTId]);

  useEffect(() => {
    console.log(VoucherData);
  }, [VoucherData]);

  useEffect(() => {
    console.log(addVoucherSundryDetail);
  }, [addVoucherSundryDetail]);

  useEffect(() => {
    console.log(addVouchersEnterAccountDetails);
  }, [addVouchersEnterAccountDetails]);

  const handleInputChangeMain = (event) => {
    const { name, value } = event.target;
    if (name == "gstApplicable") {
      setSelectedGSTId(value);
    }
    setVoucherData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(addVouchersEnterAccountDetails);
  }, [addVouchersEnterAccountDetails]);

  ////************Image add******************///
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    window.addEventListener("contextmenu", handleContextMenu);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  //************{modal box}************//

  const [BillSeriesSet, setBillSeries] = useState([]);

  useEffect(() => {
    const fetchBillSeries = async () => {
      if (VoucherData.mastType) {
        const filterData1 = [
          {
            fieldName: "parentGrpMasterId",
            operatorName: "equal",
            compareValue: VoucherData.mastType,
          },
        ];

        const result1 = await BillSeriesAPI.getBillSeriesMasterFiltered(
          filterData1
        );
        if (result1.length > 0) {
          // debugger;
          var filterActive = result1.filter((data) => data.isActive === true);
          setBillSeries(filterActive);
          var filterDefault = filterActive.filter(
            (data) => data.isDefault === true
          );
          setVoucherData((prevState) => ({
            ...prevState, // Spread the existing state
            vouchSeries:
              filterDefault[0]?.billSeriesId || filterDefault[0]?.id || null, // Update or set a default value
          }));
        }
      }
    };

    fetchBillSeries();
  }, [VoucherData.mastType]);

  const [BillNumberingSetting, setBillNumberingSetting] = useState([]);
  useEffect(() => {
    const fetchBillNumberingSett = async (billSeriesId) => {
      // setLoading(true);
      if (billSeriesId != 0 && BillNumberingSetting?.length == 0) {
        try {
          const data = await BillNumberingAPI.getBillNumberingbyId(
            19,
            billSeriesId
          );
          setBillNumberingSetting(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    if (VoucherData.vouchSeries) {
      fetchBillNumberingSett(VoucherData.vouchSeries);
    }
  }, [VoucherData.vouchSeries]);

  const [MobileSettingSlider, setMobileSettingSlider] = useState(false);

  const bottomSliderOpen = () => {
    setMobileSettingSlider(true);
  };
  const closeBottomSlider = () => {
    setMobileSettingSlider(false);
  };

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    setSliderOpen(!isSliderOpen);
  };

  /***********table Modal*****************/

  const [, setSelectedOption] = useState("Not Applicable");
  const [showModalTable, setShowModalTable] = useState(false);

  const handleSelectChangeGodown = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Show modal when a specific option is selected
    setShowModalTable(
      selectedValue === "RCM/UnReg. Expense" ||
        selectedValue === "Registered Expense" ||
        selectedValue === "Refund Advance" ||
        selectedValue === "GST Pay To Govt."
    );
  };

  /***********End Enter code*****************/

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);

  const input8Ref = useRef(null);
  const input9Ref = useRef(null);
  const input10Ref = useRef(null);
  const input11Ref = useRef(null);

  const handleKeyPress = (event, nextInputRef) => {
    // console.log(isInputVisiblemc1);
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.focus();
    }
  };
  /***********End Enter code*****************/

  const SaveVoucher = async () => {
    var data = await AddVouchers.AddVoucherDetail(
      VoucherData,
      addVouchersEnterAccountDetails,
      addVoucherSundryDetail
    );
    debugger;
    if (data.isSuccess == true) {
      alert("Voucher saved successfully");
    } else {
      alert("Error in saving voucher");
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="rightmaster-company">
            <div
              className="panel-heading"
              style={{ backgroundColor: "#212e4f" }}
            >
              <div className="panel-head-title">
                <h2
                  className="panel-title"
                  style={{ color: "white", fontWeight: "600" }}
                >
                  Add Voucher
                </h2>
              </div>
              {/* *******************Top Buttons****************** */}
              <MainButton SaveButton={SaveVoucher} />
            </div>
            {/* *******************{Modal Box End}****************** */}
            <div>
              <DateTime />
            </div>
            <div className="content_box">
              <div className="company-info-top-im">
                <div className="tab-head">
                  <div id="tabs">
                    <div className="tab-content-box">
                      <div className="tab-content-1">
                        <div className="company-info-top-left Purchase-invoice-table content-addvoucher-height">
                          <div className="">
                            <div className="input-box-head">
                              {/*************************** Bill Data ***************/}
                              <div className="mt-1 pur-body-width">
                                <div
                                  className="input-box-SI input-height2 input-stock-bottom"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="input-containerEffect mt-1"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input3Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input4Ref)
                                      }
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      required
                                      name="mastType"
                                      value={VoucherData.mastType}
                                      onChange={(e) => handleInputChangeMain(e)}
                                    >
                                      <option value="19">Payment</option>
                                      <option value="20">Receipt</option>
                                      <option value="21">Journal</option>
                                      <option value="22">Contra</option>
                                      <option value="23">Debit Note</option>
                                      <option value="24">Credit Note</option>
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Record Type
                                    </label>
                                  </div>

                                  <div
                                    className="input-containerEffect mt-1"
                                    style={{ margin: "1%", width: "50%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input4Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input5Ref)
                                      }
                                      style={{ fontSize: "14px" }}
                                      // onChange={handleSelectChangeGodown}
                                      name="gstApplicable"
                                      value={VoucherData.gstApplicable}
                                      onChange={(e) => handleInputChangeMain(e)}
                                      required
                                    >
                                      {GSTApplicable.filter((item) =>
                                        item.ParentID.includes(
                                          Number(VoucherData.mastType)
                                        )
                                      ).map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Firm Godown
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="input-box-SI input-height2 input-stock-bottom"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="input-containerEffect mt-1 input-pur-invoice"
                                    style={{ margin: "1%" }}
                                  >
                                    {/* <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input1Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input2Ref)
                                      }
                                      type="text"
                                      required
                                      name="vouchSeries"
                                      value={VoucherData.vouchSeries}
                                      onChange={(e) => handleInputChangeMain(e)}
                                    /> */}
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input4Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input5Ref)
                                      }
                                      style={{ fontSize: "14px" }}
                                      // onChange={handleSelectChangeGodown}
                                      name="vouchSeries"
                                      value={VoucherData.vouchSeries}
                                      onChange={(e) => handleInputChangeMain(e)}
                                      required
                                    >
                                      <option value="">-Select-</option>
                                      {BillSeriesSet && BillSeriesSet.length > 0
                                        ? BillSeriesSet.map((data) => (
                                            <option
                                              key={data.id}
                                              value={data.id}
                                            >
                                              {data.billSeriesName}
                                            </option>
                                          ))
                                        : null}
                                    </select>

                                    <label id="input1" className="labelEffect1">
                                      Bill Series
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 input-pur-invoice"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input2Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input3Ref)
                                      }
                                      type="text"
                                      required
                                      name="vouchNo"
                                      value={VoucherData.vouchNo}
                                      disabled={
                                        BillNumberingSetting?.numberingCategory ===
                                        0
                                      } // Conditionally disable
                                      onChange={(e) => handleInputChangeMain(e)}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Bill No
                                    </label>
                                  </div>
                                </div>
                              </div>

                              {/*************************** Party Details ***************/}
                              <div className="mt-1 pur-body-width">
                                <div
                                  className="input-box-SI input-height2 input-stock-bottom"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="input-containerEffect mt-1 input-pur-invoice"
                                    style={{ margin: "1%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input9Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input10Ref)
                                      }
                                      style={{ fontSize: "14px" }}
                                      required
                                      name="itc"
                                      value={VoucherData.itc}
                                      onChange={(e) => handleInputChangeMain(e)}
                                    >
                                      <option value="1">Not Applicable</option>
                                      {VoucherData.gstApplicable == 2 && (
                                        <>
                                          <option value="2">Goods</option>
                                          <option value="3">Service</option>
                                          <option value="4">
                                            Capital
                                          </option>{" "}
                                        </>
                                      )}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      Input Tax Credit
                                    </label>
                                  </div>
                                  <div
                                    className="input-containerEffect mt-1 input-pur-invoice"
                                    style={{ margin: "1%" }}
                                  >
                                    <select
                                      id="BillType"
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input10Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input11Ref)
                                      }
                                      style={{ fontSize: "14px" }}
                                      name="rcm"
                                      value={VoucherData.rcm}
                                      onChange={(e) => handleInputChangeMain(e)}
                                      required
                                    >
                                      <option value="1">Not Applicable</option>
                                      {VoucherData.gstApplicable == 2 && (
                                        <>
                                          <option value="2">
                                            Registred Dealer
                                          </option>
                                          <option value="3">
                                            Un-Registred Dealer
                                          </option>
                                          <option value="4">
                                            Service Import
                                          </option>
                                        </>
                                      )}
                                    </select>
                                    <label id="input1" className="labelEffect1">
                                      RCM
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className="input-box-SI input-height2 input-stock-bottom"
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className="input-containerEffect mt-1 input-pur-invoice"
                                    style={{ margin: "1%" }}
                                  >
                                    <input
                                      className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                      ref={input8Ref}
                                      onKeyDown={(event) =>
                                        handleKeyPress(event, input9Ref)
                                      }
                                      type="date"
                                      name="vouchDate"
                                      value={VoucherData.vouchDate}
                                      onChange={(e) => handleInputChangeMain(e)}
                                      required
                                      style={{ fontSize: "12px" }}
                                    />
                                    <label id="input1" className="labelEffect1">
                                      Date
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <PageTabs />
                          </div>
                        </div>
                      </div>

                      {/* <div className="input-box-Imo1 input-heightAddItem StockOut_Copy">
                        <div className="AddNewItem_Input_Box check_box_b1 ">
                          <label className="modify_AddNewItem_checkbox particular_font">
                            Particular
                          </label>
                        </div>
                      </div> */}
                      {/****************************************************** Product Table **************************************************************************/}
                      <div style={{ marginTop: "15px" }}>
                        <AddVoucherAccList
                          VoucherData={VoucherData}
                          GSTApplicable={GSTApplicable}
                          showModalTable={showModalTable}
                          handleInputChangeMain={handleInputChangeMain}
                          addVouchersEnterAccountDetails={
                            addVouchersEnterAccountDetails
                          }
                          addVoucherSundryDetail={addVoucherSundryDetail}
                          setAddVouchersEnterAccountDetails={
                            setAddVouchersEnterAccountDetails
                          }
                          setAddVoucherSundryDetail={setAddVoucherSundryDetail}
                          selectedGSTId={selectedGSTId}
                          shouldShowSundryDetails={shouldShowSundryDetails}
                          selectedItem={selectedItem}
                        />
                      </div>
                      <div id="saleRemarkPart" style={{ display: "block" }}>
                        <div className="stock-flex-start saleRemarkPart remark-padding">
                          {shouldShowSundryDetails &&
                            VoucherData.mastType !== "22" && (
                              <div
                                className="input-containerEffect mt-1"
                                style={{
                                  margin: "0px -10px 0px 13px",
                                  width: "10%",
                                }}
                              >
                                <input
                                  className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                                  type="number"
                                  required
                                  name=""
                                  style={{ borderRadius: "3px" }}
                                />
                                <label
                                  id="input1"
                                  className="labelEffect1"
                                  style={{
                                    fontSize: "12px",
                                    top: "5px",
                                    left: "8px",
                                  }}
                                >
                                  Total Sundry Amount
                                </label>
                              </div>
                            )}
                          <div
                            className="input-containerEffect mt-1"
                            style={{ margin: "1%", width: "31.9%" }}
                          >
                            <input
                              className="form-control inputEffect modify_im_input mode_limit mode_category input-s1 stock_padding"
                              type="text"
                              required
                              name="refBillRemark"
                              style={{ borderRadius: "3px" }}
                              value={VoucherData.refBillRemark}
                              onChange={(e) => handleInputChangeMain(e)}
                            />
                            <label
                              id="input1"
                              className="labelEffect1"
                              style={{
                                fontSize: "12px",
                                top: "5px",
                                left: "8px",
                              }}
                            >
                              Remark
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="buttom-box"
                style={{
                  backgroundColor: "#ececec",
                  padding: "5px",
                  borderTop: "2px solid lightgrey",
                  borderRadius: "5px",
                }}
              >
                <div
                  className="flex"
                  style={{ display: "flex", width: "100%" }}
                >
                  <button className="greenBtn" style={{ width: "40%" }}>
                    Save
                  </button>
                  <button className="redBtn" style={{ width: "40%" }}>
                    Reset
                  </button>
                  <button style={{ width: "20%", margin: "5px" }}>
                    <i
                      className="fa-solid fa-gear"
                      onClick={bottomSliderOpen}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/******************************* Bottom Slider*************************/}
      {MobileSettingSlider && (
        <div className="modal-back">
          <div className="bottomSlider">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <label>Setting</label>
              <label
                style={{ cursor: "pointer", fontSize: "24px" }}
                onClick={closeBottomSlider}
              >
                x
              </label>
            </div>
            <div className="space-between">
              <label></label>
            </div>
          </div>
        </div>
      )}
      {/******************************* Previous order Modal*************************/}
      <div className={`slider-container ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label></label>
            <label
              style={{ cursor: "pointer", fontSize: "24px" }}
              onClick={toggleSlider}
            >
              x
            </label>
          </div>
          <PreviousOrder />
        </div>
      </div>
    </div>
  );
}

export default AddVoucher;
