import React, { useEffect, useState, useContext } from "react";
import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import TableButton from "./../../Props/TableButton";
import TemplateSettingApi from "../../../API/APIServices/TemplateSetting";
import ReportTemplateSetting from "./ReportTemplateSetting";
import MasterReport from "./ReportTemplateSetting";
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import DataTable from "../../Master/TableMaster/DataTable";
import MasterReportDataTable from "../../Master/TableMaster/MasterReportDataTable";
import ReportTableButton from "../../Props/ReportTableButton";
import { useCookies } from "react-cookie";


function ReportTemplateSettingList() {
  const [cookies, setcookies, removecookies] = useCookies(['jwtToken'])
  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    input6,
    input7,
    input8,
  } = useContext(MyContext);

  const [loading, setLoading] = useState(true);
  // const [filterData, setFilterData] = useState([]);
  const [templateData, setTemplateData] = useState();
  const [data1, setData1] = useState([]);
  // const [columns, setColumns] = useState([]);

  const columns = [
    { Header: <input type="checkbox" />, accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "REPORT NAME", accessor: "reportName" },
    { Header: "REPORT TYPE", accessor: "reportType" },
    { Header: "REPORT ATTACH TYPE", accessor: "reportAttachTo" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    { Header: <input type="text" placeholder="Report Name" ref={input1} />, accessor: "reportName" },
    { Header: <input type="text" placeholder="Report Type" ref={input2} />, accessor: "reportType" },
    { Header: <input type="text" placeholder="Report Attach Type" ref={input3} />, accessor: "reportAttachTo" },
  ];


  const [totalRecords, setTotalRecords] = useState(0)
  const [PageNumber, setPageNumber] = useState(1);
  const fetchData = async () => {
    debugger
    setLoading(true);
    try {
      const val = await TemplateSettingApi.getTemplateSetting();
      console.log(val.templateSettingDTO);
      console.log(val);
      if (val.length > 0) {
        setTotalRecords(val.length)
      }
      const pageSize = 15;
      const startIndex = (PageNumber - 1) * pageSize;
      const paginatedData = val.slice(startIndex, startIndex + pageSize);
      const newData = paginatedData.map((item, i) => {

        return {
          input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
          sno: i + 1,
          reportName: item.templateSettingDTO.reportName,
          //  item.reportName,
          reportType: item.templateSettingDTO.reportType,
          reportAttachTo: item.templateSettingDTO.reportAttachTo,
          action: (

            <div className="tablelist-action-box">

              <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => editTemplateSetting(item.templateSettingDTO.id)}></i>
              <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => deleteTemplateSetting(item.templateSettingDTO.id)}></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.templateSettingDTO.id)}></i>
              <i className="fa-solid fa-print TablePrint"></i>

            </div>
          ),
        };
      });
      setData1(newData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [PageNumber]);

  const [editData, setEditData] = useState([]);

  console.log(editData);

  const editTemplateSetting = async (id) => {
    debugger
    const data = await TemplateSettingApi.getTemplateSettingById(id);
    console.log(data);
    setEditData(data);
    toggleSlider();
  }

  // const editTemplateSetting = async (id) => {
  //     setEditData(true)
  //     const dataById = TemplateSettingApi.getTemplateSettingById(id);
  //     if (dataById) {
  //         try {
  //             const editData = await dataById; 
  //             console.log("dataaaa" + editData);
  //             setEditData(editData[0]);

  //         } catch (error) {
  //             console.error("Error fetching data:", error);
  //         }
  //     }
  // }

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    console.log(editData);
    setSliderOpen(!isSliderOpen);
  };

  const [getdeleteId, setDeleteId] = useState(null);

  const deleteTemplateSetting = (id) => {
    debugger
    setAlertBoxDelete(true);
    setDeleteId(id);
  }
  const DeleteTemplateConfirm = async () => {
    setLoading(true);
    debugger
    try {
      const val = await TemplateSettingApi.DeleteTemplateSetting2(getdeleteId);
      if (Boolean(val) === true) {
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find(item => item.id === id);

    if (selectedItem) {
      setSelectedData(prevSelectedData => {
        const isSelected = prevSelectedData.some(item => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter(item => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
  };

  const handleShare = (id) => {
    const listToSend = data1.find(item => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
    window.location.href = whatsappURL;
  };

  const handleUniversalSearch = async (e, filter) => {
    try {
      if (e.key === "Enter") {
        debugger;
        e.preventDefault();

        const { name, value } = e.target;

        // var searchval;

        let finalResults = null;

        for (let i = 0; i < filter.length; i++) {
          const item = filter[i];

          if (item.accessor.length === 0) {
            continue;
          }

          let filterData = [
            {
              fieldName: item.accessor,
              operatorName: "contains",
              compareValue: value,
            },
            {
              fieldName: "isDeleted",
              operatorName: "equal",
              compareValue: 0,
            },
          ];
          const response = await TemplateSettingApi.getTemplateSettingFilter(
            filterData
          );
          if (response.length > 0) {
            finalResults = response;
            break;
          }
          console.log("response", response);
        }
        const newData = finalResults.map((item, i) => {
          //   item.templateSettingDTO.map((im, idx) => {
          return {
            input: (
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(item.id)}
              />
            ),
            sno: i + 1,
            reportName: item.templateSettingDTO.reportName,
            //  item.reportName,
            reportType: item.templateSettingDTO.reportType,
            reportAttachTo: item.templateSettingDTO.reportAttachTo,
            action: (
              <div className="tablelist-action-box">
                <i
                  className="fa-regular fa-pen-to-square TableEditIcon"
                  onClick={() =>
                    editTemplateSetting(item.templateSettingDTO.id)
                  }
                ></i>
                <i
                  className="fa-regular fa-trash-can TableDelIcon"
                  onClick={() =>
                    deleteTemplateSetting(item.templateSettingDTO.id)
                  }
                ></i>
                <i
                  className="fa-brands fa-square-whatsapp TableWhatsappcon"
                  onClick={() => handleShare(item.templateSettingDTO.id)}
                ></i>
                <i className="fa-solid fa-print TablePrint"></i>
              </div>
            ),
          };
          //   });
        });
        setData1(newData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="">
      <PageTabs
        locationTab={"/ReportTemplateSetting"}
        modelOpen={true}
        setSliderCallBack={setSliderOpen}
      />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Report Template Setting List</h2>
          <ReportTableButton
            PageCreate={"/ReportTemplateSetting"}
            filterData={filterData}
            columns={columns}
            data={data1}
            selectedData={selectedData}
          />
        </div>
        <MasterReportDataTable
          PageNumber={PageNumber}
          pagecount={15}
          setPageNumber={setPageNumber}
          filterData={filterData}
          columns={columns}
          data={data1}
          handleKeyDownUniversal={handleUniversalSearch}
          searchAnyText={true}
          totalRecords={totalRecords}
        />
      </div>
      {/******************************* Unit Master Modal*************************/}
      <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
        <div style={{ padding: "15px" }}>
          <MasterReport
            editData={editData}
            modelOpen={true}
            setSliderCallBack={setSliderOpen}
            fetchData={fetchData}
          />
        </div>
      </div>
      {/* ********************************************** Mobile View ***************************** */}
      <div className="wrapper">
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search
                                    "
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p"><i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>1000</p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              < ListSaveButton />
            </div>
          </div>
        </div>
      </div>
      {/* ***************************** */}
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteTemplateConfirm}
        />
      )}
      {loading && <Loader />}
    </div >
  );
}
export default ReportTemplateSettingList;
