import React, { useEffect, useRef, useState } from 'react'

const LazyLoadImage = ({ src, alt, style, openImageModal, onMouseUp, onMouseLeave, onTouchStart, onTouchEnd }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.unobserve(entry.target);
            }
        });
        if (imgRef.current) observer.observe(imgRef.current);
        return () => imgRef.current && observer.unobserve(imgRef.current);
    }, []);

    return (
        <img
            ref={imgRef}
            src={isVisible ? src : ""}
            alt={alt}
            style={style}
            onClick={() => openImageModal(src)}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            onTouchStart={() => onTouchStart(src)}
            onTouchEnd={onTouchEnd}
        />
    );
};

export default LazyLoadImage;
