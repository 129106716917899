import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import AddNewItem from "./AddNewItem";
import ItemWiseBatchList from "./ItemWiseBatchList";
import ItemMasterAPI from "../../API/APIServices/ItemMaster";
import TransactionTypeSettingAPI from "../../API/APIServices/TransactionTypeSetting";
import TaxCategoryApi from "../../API/APIServices/TaxCategory";
import SaleItemAddPageComponent from "./SaleItemAddPageComponent";
import ItemGroup from "../../API/APIServices/ItemGroup";
import LensItemCreation from "../../API/APIServices/LensItemCreation";
import Transaction from "../../API/APIServices/Transaction";
import UnitMaster from "../../API/APIServices/UnitMaster";
import MyContext from "../MyContext";

function ProductList(props) {
  /************Category box****************/
  const {
    PreviousPage,
    NextPage,
    pageNo,
    setPageNo,
    setItemPerPage,
    ItemPerPage,
    inputValues,
    setInputValues,
    mainPageInputRef,
    itemMasterRows,
    setItemMasterRows,
    handlesGSTCalculation,
    GetProductDataFunction,
    sundryRows,
    allSettings,
    onChangeProductSaveMethod,
    ReferenceNumber,
    // itemRefs,
    nextCellRefsProduct,
    currencyDecimalPlaces,
    PopUpSerialOpen,
    setLoading,
    setModalOpen,
    openGroupModal,
  } = props;

  const { debounce } = useContext(MyContext);

  useEffect(() => {
    console.log(itemMasterRows);
    console.log(currencyDecimalPlaces);
  }, [itemMasterRows]);

  useImperativeHandle(mainPageInputRef, () => ({
    hideModal3,
  }));

  useEffect(() => {
    const createDefaultState = (length) => {
      return Array(length).fill({
        PowerRangeADDShow: false,
        PowerRangeADDList: [],
      });
    };
    const createDefaultState1 = (length) => {
      return Array(length).fill({
        PowerRangeCYLShow: false,
        PowerRangeCYLList: [],
      });
    };
    const createDefaultState2 = (length) => {
      return Array(length).fill({
        PowerRangeAXISShow: false,
        PowerRangeAXISList: [],
      });
    };
    const createDefaultState3 = (length) => {
      return Array(length).fill({
        PowerRangeSPHShow: false,
        PowerRangeSPHList: [],
      });
    };
    const createDefaultState4 = (length) => {
      return Array(length).fill({
        PowerRangeEYEShow: false,
        PowerRangeEYEList: [],
      });
    };

    // Set the state arrays with the same length as itemMasterRows
    setPowerRangeADD(createDefaultState(itemMasterRows.length + 1));
    setPowerRangeCYL(createDefaultState1(itemMasterRows.length + 1));
    setPowerRangeAXIS(createDefaultState2(itemMasterRows.length + 1));
    setPowerRangeSPH(createDefaultState3(itemMasterRows.length + 1));
    setPowerRangeEYE(createDefaultState4(itemMasterRows.length + 1));

    console.log(itemMasterRows);
  }, [itemMasterRows.length]);

  // const [PowerRange, setPowerRange] = useState({});
  const [PowerRangeADD, setPowerRangeADD] = useState([
    { PowerRangeADDShow: false, PowerRangeADDList: [] },
  ]);
  const [PowerRangeCYL, setPowerRangeCYL] = useState([
    { PowerRangeCYLShow: false, PowerRangeCYLList: [] },
  ]);
  const [PowerRangeAXIS, setPowerRangeAXIS] = useState([
    { PowerRangeAXISShow: false, PowerRangeAXISList: [] },
  ]);
  const [PowerRangeSPH, setPowerRangeSPH] = useState([
    { PowerRangeSPHShow: false, PowerRangeSPHList: [] },
  ]);
  const [PowerRangeEYE, setPowerRangeEYE] = useState([
    { PowerRangeEYEShow: false, PowerRangeEYEList: [] },
  ]);

  // useEffect to update state arrays based on itemMasterRows length

  // const [PowerRangeCYLShow, setPowerRangeCYLShow] = useState(false);
  // const [PowerRangeADDShow, setPowerRangeADDShow] = useState(false);
  // const [PowerRangeAXISShow, setPowerRangeAXISShow] = useState(false);
  // const [PowerRangeSPHShow, setPowerRangeSPHShow] = useState(false);

  const ChangeItemPerPage = (e) => {
    setItemPerPage(e.target.value);
  };

  const suggestionBoxRef = useRef(null);
  const itemRefs = useRef([]); // Reference for each list item

  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    if (
      highlightedIndex >= 0 &&
      itemRefs.current[highlightedIndex] &&
      suggestionBoxRef.current
    ) {
      itemRefs.current[highlightedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    console.log(highlightedIndex);
  }, [highlightedIndex]);

  let TransType = inputValues.TransType;
  let TransName = inputValues.TransName;

  const [headingValue, setHeadingValue] = useState([]);
  const [inputPosition, setInputPosition] = useState({
    top: 0,
    left: 0,
    width: 80,
  });
  useEffect(() => {
    console.log(inputPosition);
  }, [inputPosition]);

  const getPosition = (rowIndex, columnIndex) => {
    setTimeout(() => {
      if (columnIndex === 8) {
        setPowerRangeSPH((prevState) => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeSPHList
            PowerRangeSPHShow: true, // Update only PowerRangeSPHShow
          };
          return updatedState;
        });
      } else if (columnIndex === 9) {
        setPowerRangeCYL((prevState) => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeCYLList
            PowerRangeCYLShow: true, // Update only PowerRangeCYLShow
          };
          return updatedState;
        });
      } else if (columnIndex === 10) {
        setPowerRangeAXIS((prevState) => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeAXISList
            PowerRangeAXISShow: true, // Update only PowerRangeAXISShow
          };
          return updatedState;
        });
      } else if (columnIndex === 11) {
        setPowerRangeADD((prevState) => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeADDList
            PowerRangeADDShow: true, // Update only PowerRangeADDShow
          };
          return updatedState;
        });
      } else if (columnIndex === 7) {
        setPowerRangeEYE((prevState) => {
          const updatedState = [...prevState];
          updatedState[rowIndex] = {
            ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeADDList
            PowerRangeEYEShow: true, // Update only PowerRangeADDShow
          };
          return updatedState;
        });
      }
    }, 300);

    console.log(columnIndex);

    const inputRef = nextCellRefsProduct.current[rowIndex]?.[columnIndex];
    if (inputRef) {
      const rect = inputRef.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom, // Position below the input
        left: rect.left,
        width: rect.width,
      });
    }
  };

  const ShowCostPriceAndLandingPrice = allSettings.find(
    (setting) =>
      setting.masterSettName === "ShowCostPriceAndLandingPrice" &&
      setting.enableDisable === true
  );

  const closeSuggestionAll = async (e, rowIndex1, rowIndex) => {
    // debugger;
    setTimeout(() => {
      setPowerRangeSPH((prevState) => {
        const updatedState = [...prevState];
        updatedState[rowIndex] = {
          ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeSPHList
          PowerRangeSPHShow: false, // Update only PowerRangeSPHShow
        };
        return updatedState;
      });
      setPowerRangeCYL((prevState) => {
        const updatedState = [...prevState];
        updatedState[rowIndex] = {
          ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeCYLList
          PowerRangeCYLShow: false, // Update only PowerRangeCYLShow
        };
        return updatedState;
      });
      setPowerRangeAXIS((prevState) => {
        const updatedState = [...prevState];
        updatedState[rowIndex] = {
          ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeAXISList
          PowerRangeAXISShow: false, // Update only PowerRangeAXISShow
        };
        return updatedState;
      });
      setPowerRangeADD((prevState) => {
        const updatedState = [...prevState];
        updatedState[rowIndex] = {
          ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeADDList
          PowerRangeADDShow: false, // Update only PowerRangeADDShow
        };
        return updatedState;
      });
      setPowerRangeEYE((prevState) => {
        const updatedState = [...prevState];
        updatedState[rowIndex] = {
          ...updatedState[rowIndex], // Preserve all previous values, including PowerRangeADDList
          PowerRangeEYEShow: false, // Update only PowerRangeADDShow
        };
        return updatedState;
      });
    }, 300);
    // setTimeout(() => {
    //   onChangeProductSaveMethod(e, rowIndex1, rowIndex);
    // }, 100);
  };

  // const [PowerRangeEYE, setPowerRangeEYE] = useState({});
  useEffect(() => {
    console.log(PowerRangeADD);
    console.log(PowerRangeCYL);
    console.log(PowerRangeSPH);
    console.log(PowerRangeAXIS);
  }, [PowerRangeADD, PowerRangeCYL, PowerRangeAXIS, PowerRangeSPH]);

  // useEffect(() => {
  //   console.log(PowerRangeCYLShow);
  //   console.log(PowerRangeADDShow);
  //   console.log(PowerRangeAXISShow);
  //   console.log(PowerRangeSPHShow);
  // }, [
  //   PowerRangeCYLShow,
  //   PowerRangeADDShow,
  //   PowerRangeAXISShow,
  //   PowerRangeSPHShow,
  // ]);

  const handlePowerSelection = async (rowindex, value, name) => {
    debugger;
    let isValid = true;
    const checkValue = value;

    if (checkValue === undefined) {
      return;
    }

    if (
      name === "lens_Eye" ||
      name === "lens_SPH" ||
      name === "lens_CYL" ||
      name === "lens_AXIS" ||
      name === "lens_Add"
    ) {
      if (name === "lens_Add") {
        const minValue = ProductRangeDataMain?.add_Min || 0;
        const maxValue = ProductRangeDataMain?.add_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_SPH") {
        const minValue = ProductRangeDataMain?.spH_Min || 0;
        const maxValue = ProductRangeDataMain?.spH_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_CYL") {
        const minValue = ProductRangeDataMain?.cyL_Min || 0;
        const maxValue = ProductRangeDataMain?.cyL_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_AXIS") {
        const minValue = 0;
        const maxValue = 180;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_Eye") {
        const allowedValues = ["R", "L", "RL", ""];

        if (!allowedValues.includes(checkValue)) {
          alert(`Invalid value for lens_Eye! It must be one of: R, L, RL.`);
          await setItemMasterRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowindex] = {
              ...newRows[rowindex],
              [name]: "R",
            };
            updateTotals(newRows);
            return newRows;
          });
          isValid = false;
        } else {
          console.log(`Value for lens_Eye is ${checkValue}`);
        }
      }
    }

    if (isValid) {
      await setItemMasterRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowindex] = {
          ...newRows[rowindex],
          [name]: value,
        };
        updateTotals(newRows);
        return newRows;
      });
    }
  };

  // const handlePowerSelection = async (rowindex, item, name, value) => {
  //   debugger;

  //   await setItemMasterRows((prevRows) => {
  //     const newRows = [...prevRows];
  //     if (item !== "" || item !== undefined || item != []) {
  //       newRows[rowindex] = {
  //         ...newRows[rowindex],
  //         [name]: item,
  //       };
  //     } else {
  //       newRows[rowindex] = {
  //         ...newRows[rowindex],
  //         [name]: value, // Set the provided `value` if `item` is empty or undefined
  //       };
  //     }

  //     updateTotals(newRows);
  //     return newRows;
  //   });
  // };

  // useEffect(() => {
  //   console.log(PowerRange);

  // }, [PowerRange]);

  /************modal box************/

  const [isModalOpen3, setModalOpen3] = useState(false);

  const showModal3 = () => {
    setModalOpen3(true);
    setModalOpen(true);
  };

  const hideModal3 = () => {
    setModalOpen3(false);
    setModalOpen(false);
  };

  const [AddNewModal, setNewModal] = useState(false);

  const openAddItemModal = () => {
    setNewModal(true);
  };

  const closeAddItemModal = () => {
    setNewModal(false);
  };
  /*****************************************/

  const [customfields, setCustomefields] = useState([]);

  const customFieldAddBtn = () => {
    const val = document.getElementById("CustomFieldInput").value;
    console.log(val);
    if (val.trim() !== "") {
      const newData = { id: customfields.length + 1, FieldName: val };
      setCustomefields([...customfields, newData]);
      document.getElementById("inputField").style.display = "none";
      document.getElementById("CustomFieldInput").value = "";
    } else {
      alert("Please Enter valid Name", "ok");
    }
  };

  const AddCustmField_clicked = () => {
    document.getElementById("inputField").style.display = "block";
  };

  /************************{modal box}************************/

  const appendValue = (e) => {
    console.log(e.target.value);
    let newItem = [...headingValue, e.target.value];
    setHeadingValue(newItem);
  };
  useEffect(() => {
    let savedItems = JSON.parse(localStorage.getItem("headValue"));
    if (savedItems) {
      setHeadingValue(savedItems);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("headValue", JSON.stringify(headingValue));
  }, [headingValue]);

  const [sampleData, setSampleData] = useState([]);

  const generateData = (count) => {
    return Array.from({ length: count }, (_, index) => ({ id: index + 1 }));
  };
  // Example function to update the data dynamically
  const updateData = () => {
    setSampleData(generateData(100)); // Generate data for 100 items
  };

  // const [dynamicHeight, setDynamicHeight] = useState(auto);

  // const updateHeight = (val) => {
  //     const newHeight = val;
  //     setDynamicHeight(newHeight);
  // };

  const [SaleItemAddPage, setSaleItemAddPage] = useState(false);

  const SaleItemAdd = (edit) => {
    // debugger;
    // setIndexToEdit(null);
    if (itemMasterRows[0].productName != "") {
      if (edit !== "edit") {
        handleAddRow();
      }
    }
    setSaleItemAddPage(true);
  };

  const CloswSaleItemAdd = () => {
    setIndexToEdit(null);
    setSaleItemAddPage(false);
  };

  const addRowAtBetween = (index) => {
    const newId = index + 1;
    const newRow = {
      id: newId,
    };

    const updatedTableData = sampleData.map((row, i) => ({
      ...row,
      id: i >= index ? row.id + 1 : row.id,
    }));

    updatedTableData.splice(index, 0, newRow);

    setSampleData(updatedTableData);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowHover = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const deleteValueById = (id) => {
    console.log("hello");
    setCustomefields((prevState) => prevState.filter((item) => item.id !== id));
  };
  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  const [isItemWiseList, setItemWiseList] = useState(false);
  const showModalOpen6 = () => {
    setItemWiseList(true);
  };
  const hideModalOpen61 = () => {
    setItemWiseList(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      showModalOpen6();
    }
  };

  /**********************Start From(Shivendra 06062024)**********************/

  //----------------- Show/Hide Column ----------------//

  const [IsExistTransColumnSet, setIsExistTransColumn] = useState(false);
  const [TransColumnSet, setTransColumn] = useState([]);
  const [TransColumnCb, setTransColumnCb] = useState({
    isInputVisible1: false,
    isInputVisible2: false,
    isInputVisible3: true,
    isInputVisible4: false,
    isInputVisible5: false,
    isInputVisible6: false,
    isInputVisible7: false,
    isInputVisible8: false,
    isInputVisible9: false,
    isInputVisible10: false,
    isInputVisible11: false,
    isInputVisible12: false,
    isInputVisible13: false,
    isInputVisible14: false,
    isInputVisible15: false,
    isInputVisible16: false,
    isInputVisible17: false,
    isInputVisible18: false,
    isInputVisible19: false,
    isInputVisible20: false,
    isInputVisible21: false,
    isInputVisible22: true,
    isInputVisible23: false,
    isInputVisible24: true,
    isInputVisible25: false,
    isInputVisible26: false,
    isInputVisible27: false,
    isInputVisible28: true,
    isInputVisible29: false,
    isInputVisible30: false,
    isInputVisible31: false,
    isInputVisible32: false,
    isInputVisible33: false,
    isInputVisible34: false,
    isInputVisible35: false,
    isInputVisible36: false,
    isInputVisible37: false,
    isInputVisible38: false,
    isInputVisible39: false,
    isInputVisible40: false,
  });

  const [itemMasterList, setItemMasterList] = useState([
    {
      id: 0,
      name: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredProduct: [],
    },
  ]);

  useEffect(() => {
    console.log("itemMasterListi", itemMasterList);
  }, [itemMasterList]);

  const [itemGroupMasterList, setitemGroupMasterList] = useState([
    {
      id: 0,
      name: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredProduct: [],
    },
  ]);

  const [UnitList, setUnitList] = useState([
    {
      id: 0,
      name: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredProduct: [],
    },
  ]);

  useEffect(() => {
    console.log(itemGroupMasterList);
  }, [itemGroupMasterList]);

  useEffect(() => {
    console.log(TransColumnSet);
  }, [TransColumnSet]);

  /*const [itemMasterRows, setItemMasterRows] = useState([{
        d1_Id: 0,
        d2_Id: 0,
        productName: "",
        parentGrp: 0,
        parentGrpName: "",
        productUnit: 0,
        productAltUnit: 0,
        unitConvFactor: 0,
        enableBarcode: true,
        defaultBarcode: null,
        hsnCode: null,
        shortDescription: null,

        taxCategory1: 0,
        taxCat1_Cgst: 0,
        taxCat1_Sgst: 0,
        taxCat1_Igst: 0,
        taxCat1_Cess: 0,
        taxLimit: 0,
        taxCategory2: 0,
        taxCat2_Cgst: 0,
        taxCat2_Sgst: 0,
        taxCat2_Igst: 0,
        taxCat2_Cess: 0,
        
        Qty: 0,
        FreeQty: 0,
        purchasePrice: 0,
        purchaseDiscount: 0,
        saleProfit: 0,
        salePrice: 0,
        saleDiscount: 0,
        itemPrice: 0,
        costPrice: 0,
        mrpPrice: 0,
        DiscPer: 0,
        DoubleDis: 0,
        DiscAmt: 0,
        TotalAmt: 0,
        salePriceForPur: 0,
        availableQuantity: 0,

        iCateRateA: null,
        iCateRateB: null,
        iCateRateC: null,
        iCateRateD: null,
        iCateRateE: null,
        iCateRateF: null,
        iCateRateG: null,
        iCateRateH: null,
        iCateRateI: null,
        iCateRateJ: null,

        loyaltyPoint: null,
        salesManItemIncentive: null,
        BrkrName: null,
        BrkgAmt: null,
        sellerCommision: null,
        isReturnable: null,
        isStockable: null,
        isGoodsService: false,

        lens_Eye: "",
        lens_SPH: null,
        lens_CYL: null,
        lens_AXIS: null,
        lens_Dia: null,
        lens_Add: null,
        
        Attribute1: null,
        Attribute2: null,
        Attribute3: null,
        Attribute4: null,
        Attribute5: null,
        Note1: null,
        Note2: null,
        BatchNo: null,
        MgfDt: null,
        ExpDt: null,

        isAttributeItem: true,
        iAttributeParent: 0,
        batchDetails: [],
        itemOptionalFields: [],
    }]);
    */

  //---------------Fill Column Setting (Load Event)-----------------------

  useEffect(() => {
    const fetchAllList = async () => {
      try {
        debugger;
        TransactionTypeSettingAPI.GetVoucherWise_ColumnSetting(TransType)
          .then(function (result1) {
            if (result1) {
              if (result1 && Array.isArray(result1)) {
                // Map the API result to the visibility state
                const visibilityState = result1.reduce((acc, column) => {
                  acc[`isInputVisible${column.colId}`] = column.colShow;
                  // Fetch Extra Column
                  if (column.colId > 34) {
                    const newData = {
                      id: column.colId,
                      FieldName: column.colName,
                    };
                    setCustomefields([...customfields, newData]);
                  }

                  return acc;
                }, {});
                setTransColumnCb(visibilityState);

                setTransColumn(result1);
                setIsExistTransColumn(true);
              } else {
                console.error("API returned an unexpected result:", result1);
              }
            } else {
              console.error("API returned undefined result");
            }
          })
          .catch(function (error) {
            console.error("API Error:", error);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllList();
    updateData();
  }, [TransType]);

  //---------------Product Row Add, Delete-----------------------

  const handleAddRow = () => {
    const newItemRow = {
      rowIndex: 0,
      d1_Id: 0,
      d2_Id: 0,
      productName: "",
      parentGrp: null,
      parentGrpName: "",
      productUnit: null,
      productAltUnit: null,
      unitConvFactor: null,
      enableBarcode: true,
      defaultBarcode: "",
      taxCategory1: null,
      taxLimit: null,
      taxCategory2: null,
      hsnCode: "",
      shortDescription: "",

      Qty: null,
      FreeQty: null,
      purchasePrice: null,
      purchaseDiscount: null,
      saleProfit: null,
      salePrice: null,
      saleDiscount: null,
      itemPrice: null,
      costPrice: null,
      mrpPrice: null,
      DiscPer: null,
      DoubleDis: null,
      DiscAmt: null,
      totalDisc_With_sundry: null,
      itemWiseTaxbleAmt: null,
      TotalAmt: null,
      salePriceForPur: null,
      availableQuantity: null,

      iCateRateA: null,
      iCateRateB: null,
      iCateRateC: null,
      iCateRateD: null,
      iCateRateE: null,
      iCateRateF: null,
      iCateRateG: null,
      iCateRateH: null,
      iCateRateI: null,
      iCateRateJ: null,

      loyaltyPoint: null,
      salesManItemIncentive: null,
      BrkrName: null,
      BrkgAmt: null,
      sellerCommision: null,
      isReturnable: null,
      isStockable: null,
      isGoodsService: false,

      lens_Eye: "",
      lens_SPH: null,
      lens_CYL: null,
      lens_AXIS: null,
      lens_Dia: null,
      lens_Add: null,

      Attribute1: null,
      Attribute2: null,
      Attribute3: null,
      Attribute4: null,
      Attribute5: null,
      Note1: null,
      Note2: null,
      BatchNo: null,
      MgfDt: null,
      ExpDt: null,

      isAttributeItem: true,
      iAttributeParent: null,
      batchDetails: [],
      itemOptionalFields: [],
    };

    const newItemList = {
      id1: 0,
      id2: 0,
      name: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredProduct: [],
    };

    setItemMasterRows([...itemMasterRows, newItemRow]);
    setItemMasterList([...itemMasterList, newItemList]);
    setitemGroupMasterList([...itemMasterList, newItemList]);
    setSaleItemAddPage(false);
  };

  const handleDeleteRow = async (index, rowIndex) => {
    debugger;
    const updatedRows = itemMasterRows.filter((row, i) => i !== rowIndex);
    const updatedList = itemMasterList.filter((row, i) => i !== rowIndex);
    setItemMasterRows(updatedRows);
    setItemMasterList(updatedList);
    setSaleItemAddPage(false);
    if (index != 0) {
      var deleteProduct = await Transaction.RemoveProductTempData(
        ReferenceNumber,
        index
      );
      debugger;
      if (deleteProduct == true) {
        GetProductDataFunction();
      }
    }
  };

  const [indexToEdit, setIndexToEdit] = useState();

  const handleEditRow = (index) => {
    setIndexToEdit(index);
    SaleItemAdd("edit");
  };

  //--------------- Row Input Calculation -----------------------

  const calculateTotalAmount = (Qty, itemPrice, DiscPer, DiscAmt, dicType_) => {
    // debugger;
    let discount = 0;
    if (dicType_ === 1) {
      // Discount Percentage
      discount = itemPrice * (DiscPer / 100);
    } else if (dicType_ === 2) {
      // Discount Amount
      discount = DiscAmt;
    }
    const totalAmount = Qty * (itemPrice - discount);
    return { totalAmount, discount };
  };

  //--------------- Update the total quantity, price, and amount -----------------------
  const updateTotals = (rows) => {
    // let totalQty = 0;
    // let totalPrice = 0;
    // let totalDisc = 0;
    // let totalItemWiseTaxbleAmt = 0;
    // let totalAmount = 0;
    // let totalMrp = 0;
    // rows.forEach((row) => {
    //   totalQty += parseFloat(row.Qty) || 0;
    //   totalPrice += parseFloat(row.itemPrice) || 0;
    //   totalDisc += parseFloat(row.DiscAmt) || 0;
    //   totalItemWiseTaxbleAmt += parseFloat(row.itemWiseTaxbleAmt) || 0;
    //   totalAmount += parseFloat(row.TotalAmt) || 0;
    //   totalMrp += parseFloat(row.mrpPrice) || 0;
    // });
    // setInputValues((prevValues) => ({
    //   ...prevValues,
    //   TotalQty: totalQty.toFixed(2),
    //   TotalPrice: totalPrice.toFixed(2),
    //   TotalDisc: totalDisc.toFixed(2),
    //   TotalItemAmt: totalAmount.toFixed(2),
    //   TotalMrp: totalMrp.toFixed(2),
    //   TaxableAmt: totalItemWiseTaxbleAmt.toFixed(2),
    //   GrossAmt: totalAmount.toFixed(2),
    // }));
  };

  //---------------Row Input Change-----------------------
  const ensureNumeric = (value) => (isNaN(Number(value)) ? 0 : Number(value));

  const handleInputChange = async (event, rowIndex) => {
    // debugger;
    const { name, value } = event.target;
    let isValid = true;
    const checkValue = value;
    if (checkValue === undefined) {
      return;
    }

    if (
      name === "lens_Eye" ||
      name === "lens_SPH" ||
      name === "lens_CYL" ||
      name === "lens_AXIS" ||
      name === "lens_Add"
    ) {
      if (name === "lens_Add") {
        const minValue = ProductRangeDataMain?.add_Min || 0;
        const maxValue = ProductRangeDataMain?.add_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_SPH") {
        const minValue = ProductRangeDataMain?.spH_Min || 0;
        const maxValue = ProductRangeDataMain?.spH_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_CYL") {
        const minValue = ProductRangeDataMain?.cyL_Min || 0;
        const maxValue = ProductRangeDataMain?.cyL_Max || 5;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_AXIS") {
        const minValue = 0;
        const maxValue = 180;

        if (checkValue < minValue || checkValue > maxValue) {
          alert(
            `Value ${checkValue} is out of range! It must be between ${minValue} and ${maxValue}.`
          );
          isValid = false;
        }
      } else if (name === "lens_Eye") {
        const allowedValues = ["R", "L", "RL", ""];

        if (!allowedValues.includes(checkValue)) {
          alert(`Invalid value for lens_Eye! It must be one of: R, L, RL.`);
          await setItemMasterRows((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex] = {
              ...newRows[rowIndex],
              [name]: "R",
            };
            updateTotals(newRows);
            return newRows;
          });
          isValid = false;
        }
      }
    }

    if (isValid) {
      setItemMasterRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          [name]: value,
        };

        updateTotals(newRows);

        return newRows;
      });
    }
  };

  const [units, setUnits] = useState([
    {
      id: 0,
      name: "",
      isDropdownOpen: false,
      highlightedIndex: -1,
      filteredProduct: [],
    },
  ]);

  const handleKeyDown_UnitSuggestion = (e, rowIndex) => {
    // Validate `rowIndex`
    setTimeout(() => {
      // if (rowIndex < 0 || rowIndex > units.length) {
      //   console.error("Invalid rowIndex:", rowIndex);
      //   return;
      // }
      if (rowIndex < 0) {
        console.error("Invalid rowIndex:", rowIndex);
        return;
      }

      // Safely access the row
      const row = itemMasterRows[rowIndex];
      if (!row) {
        console.error("Undefined row at index:", rowIndex);
        return;
      }

      const { filteredProduct = [], highlightedIndex = 0 } = row;

      // Handle empty product list
      if (!filteredProduct.length) {
        handleProductRowKeyDown(e, rowIndex, 5);
        // return;
      }

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setUnits((prevRows) => {
            const newRows = [...prevRows];
            const currentHighlightedIndex =
              newRows[rowIndex]?.highlightedIndex || 0;
            const totalItems = newRows[rowIndex].filteredProduct.length || 0;
            newRows[rowIndex].highlightedIndex =
              (currentHighlightedIndex + 1) % totalItems;
            return newRows;
          });
          // setHighlightedIndex((prevIndex) => prevIndex + 1);

          setHighlightedIndex((prevIndex) => {
            const totalItems = units[rowIndex]?.filteredProduct.length || 0;
            let newIndex = prevIndex + 1;

            // Reset to -1 if newIndex exceeds the totalItems
            if (newIndex >= totalItems) {
              newIndex = 0;
            }

            return newIndex;
          });

          break;

        case "ArrowUp":
          e.preventDefault();
          setUnits((prevRows) => {
            const newRows = [...prevRows];
            const currentHighlightedIndex =
              newRows[rowIndex]?.highlightedIndex || 0;
            const totalItems = newRows[rowIndex].filteredProduct.length || 0;
            // newRows[rowIndex].highlightedIndex =
            //   currentHighlightedIndex === 0
            //     ? filteredProduct.length - 1
            //     : currentHighlightedIndex - 1;
            if (totalItems > 0) {
              newRows[rowIndex].highlightedIndex =
                (currentHighlightedIndex - 1 + totalItems) % totalItems;
            }
            return newRows;
          });
          // setHighlightedIndex((prevIndex) =>
          //   prevIndex === 0 ? filteredProduct.length - 1 : prevIndex - 1
          // );

          setHighlightedIndex((prevIndex) => {
            const totalItems = units[rowIndex]?.filteredProduct.length || 0;
            let newIndex = prevIndex - 1;

            // If newIndex is less than 0, reset to the last item
            if (newIndex < 0) {
              newIndex = totalItems - 1; // Reset to the last item when the index goes below 0
            }

            return newIndex;
          });

          break;

        case "Enter":
          e.preventDefault();
          if (e.target.name != "productUnitName") {
            // highlightedIndex >= 0 && highlightedIndex < filteredProduct.length;
          } else {
            debugger;
            if (
              highlightedIndex >= 0 &&
              highlightedIndex < units[rowIndex]?.filteredProduct.length
            ) {
              handleUnitSelection(
                rowIndex,
                units[rowIndex].filteredProduct[
                  units[rowIndex].highlightedIndex
                ]
              );
            }
          }
          break;

        case "Escape":
          setItemMasterList((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex].isDropdownOpen = false;
            return newRows;
          });
          break;

        default:
          break;
      }
    }, 100);
  };

  const OpenSuggestionUnit = async (event, rowIndex) => {
    const { name, value } = event.target;
    getPosition(rowIndex, 5);
    // await handleInputChange(event, rowIndex);
    // debugger;
    const filterData = [
      {
        fieldName: "name",
        operatorName: "contains",
        compareValue: value,
      },
    ];
    try {
      const Unit = await UnitMaster.getUnitMaster(filterData);

      if (Unit && Unit.length > 0) {
        setUnits((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            name: value,
            filteredProduct: Unit || [],
            isDropdownOpen: true,
            highlightedIndex: -1,
          };
          return newRows;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const debouncedOpenSuggestionUnit = useCallback(
    debounce(OpenSuggestionUnit, 500), // Set the debounce delay (e.g., 500ms)
    []
  );
  const handleUnitSelection = async (rowIndex, selectedItem) => {
    debugger;
    setItemMasterRows((prevRows) => {
      const newRows = [...prevRows];

      newRows[rowIndex] = {
        ...newRows[rowIndex],
        productUnitName: selectedItem?.unitName,
        productUnit: selectedItem?.id,
      };
      return newRows;
    });
    // setGroupSuggestionShow(false);

    setUnits((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].isDropdownOpen = false;
      newRows[rowIndex].filteredProduct = [];
      return newRows;
    });
  };

  useEffect(() => {
    console.log("units", units);
  }, [units]);

  // TotalAmount Calculation

  //---------------Adding items from the Popup-----------------------

  // const AddingItrmFromPopUp = (event) => {
  //   var rowIndex = itemMasterList.length - 1;
  //   // debugger;
  //   console.log(event.target.value, event.target.name, rowIndex);

  //   const { name, value } = event.target;

  //   setItemMasterRows((prevRows) => {
  //     const newRows = [...prevRows];
  //     newRows[rowIndex] = {
  //       ...newRows[rowIndex],
  //       [name]: value,
  //     };

  //     // TotalAmount Calculation
  //     const { Qty, itemPrice, DiscPer, DiscAmt } = newRows[rowIndex];
  //     if (
  //       name === "Qty" ||
  //       name === "itemPrice" ||
  //       name === "DiscPer" ||
  //       name === "DiscAmt"
  //     ) {
  //       let dicType_ = 0;
  //       if (name === "DiscPer") {
  //         dicType_ = 1;
  //       }
  //       if (name === "DiscAmt") {
  //         dicType_ = 2;
  //       }
  //       const { totalAmount, discount } = calculateTotalAmount(
  //         Qty,
  //         itemPrice,
  //         DiscPer,
  //         DiscAmt,
  //         dicType_
  //       );
  //       newRows[rowIndex].TotalAmt = totalAmount.toFixed(2);
  //       newRows[rowIndex].amt_after_discount = totalAmount.toFixed(2);
  //       if (name !== "DiscAmt") {
  //         newRows[rowIndex].DiscAmt = discount.toFixed(2);
  //       }
  //     }
  //     //handleGSTCalculation(newRows[rowIndex]);

  //     (async () => {
  //       try {
  //         const gstCalculationResult =
  //           await Gst_Calculation.ItemWiseGSTCalculation(
  //             newRows[rowIndex],
  //             itemMasterRows,
  //             setItemMasterRows,
  //             BillTypeSetting,
  //             inputValues,
  //             sundryRows
  //           );
  //         // debugger;
  //         // Destructure the result only if the function returns an object
  //         if (gstCalculationResult) {
  //           const { taxable_item_price, tAmt1, tAmt2, tAmt3, tAmt4, value1 } =
  //             gstCalculationResult;
  //           // debugger;
  //           // Update the current row with GST calculations
  //           newRows[rowIndex] = {
  //             ...newRows[rowIndex],
  //             itemWiseTaxbleAmt: ensureNumeric(taxable_item_price),
  //             taxAmt_Cgst: ensureNumeric(tAmt1),
  //             taxAmt_Sgst: ensureNumeric(tAmt2),
  //             taxAmt_Igst: ensureNumeric(tAmt3),
  //             taxAmt_Cess: ensureNumeric(tAmt4),
  //             TotalAmt: ensureNumeric(value1),
  //           };
  //         }
  //         setItemMasterRows(newRows);
  //       } catch (err) {
  //         console.error("Error in GST Calculation:", err);
  //       }
  //     })();

  //     // Update the total values
  //     updateTotals(newRows);

  //     return newRows;
  //   });
  // };

  //---------------Product Name Change-----------------------

  const handleProductNameChange = async (event, rowIndex) => {
    // debugger;
    const { name, value } = event.target;

    getPosition(rowIndex, 3);

    // handleInputChange(event, rowIndex);

    try {
      const filteredData = {
        search_str: value,
        PageNumber: 1,
        PageSize: 50,
      };
      const SearchItem = await ItemMasterAPI.GetItemList(filteredData); // Assuming GetItemList returns a Promise

      let ShowMainProductOnlyInItemSuggestion = false;
      ShowMainProductOnlyInItemSuggestion = allSettings?.find(
        (setting) =>
          setting.masterSettName === "ShowMainProductOnlyInItemSuggestion"
      );

      if (ShowMainProductOnlyInItemSuggestion?.enableDisable == true) {
        var FilteredSearchItem = SearchItem.filter(
          (item) => item.attributeParentId2 == 0
        );

        setItemMasterList((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            name: value,
            filteredProduct: FilteredSearchItem || [],
            isDropdownOpen: true,
            highlightedIndex: -1,
          };
          return newRows;
        });
      } else {
        setItemMasterList((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            name: value,
            filteredProduct: SearchItem || [],
            isDropdownOpen: true,
            highlightedIndex: -1,
          };
          return newRows;
        });
      }
    } catch (error) {
      console.error("Error fetching item list:", error);
    }
  };

  const debouncedHandleProductNameChange = useCallback(
    debounce(handleProductNameChange, 500), // Set the debounce delay (e.g., 500ms)
    []
  );
  //---------------Product Name Change-----------------------
  // const [GroupSuggestionShow, setGroupSuggestionShow] = useState(false);
  // const [ItemGroupList, setItemGroupList] = useState([]);

  const OpenSuggestionItemGroup = async (event, rowIndex) => {
    // debugger;
    const { name, value } = event.target;
    getPosition(rowIndex, 2);

    // handleInputChange(event, rowIndex);
    // debugger;
    if (value && value.trim() != "") {
      const filterData = [
        {
          fieldName: "name",
          operatorName: "contains",
          compareValue: value,
        },
      ];

      var data = await ItemGroup.GetItemGroupWithFilter(filterData);
      if (data) {
        // setItemGroupList(data);
        setitemGroupMasterList((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex] = {
            ...newRows[rowIndex],
            name: value,
            filteredProduct: data || [],
            isDropdownOpen: true,
            highlightedIndex: -1,
          };
          return newRows;
        });
      }
    }
  };
  const debouncedOpenSuggestionItemGroup = useCallback(
    debounce(OpenSuggestionItemGroup, 500), // Set the debounce delay (e.g., 500ms)
    []
  );
  const handleProductGroupSelection = async (rowIndex, selectedItem) => {
    // debugger;
    setItemMasterRows((prevRows) => {
      const newRows = [...prevRows];

      newRows[rowIndex] = {
        ...newRows[rowIndex],
        parentGrp: selectedItem?.d1_Id,
        parentGrpName: selectedItem?.name,
      };
      return newRows;
    });
    // setGroupSuggestionShow(false);

    setitemGroupMasterList((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex].isDropdownOpen = false;
      newRows[rowIndex].filteredProduct = [];
      return newRows;
    });

    setHighlightedIndex(-1);
  };

  const [ProductRangeDataMain, setProductRangeDataMain] = useState({});

  const fetchDataForSerialNo = async (e, rowIndex1, rowIndex) => {
    debugger;

    if (e.target.value && e.target.value != "") {
      var filterData = {
        isSummary: false,
        codeType: 1,
        itmId_d2: [],
        codeNo: e.target.value,
        mgfDate: "",
        expDate: "",
        invoiceId: [],
        vendorId_d2: [],
        pageNumber: 0,
        pagesize: 0,
      };

      var dataArray = await Transaction.GetBarcodeSerialBatchNo(filterData);
      debugger;

      if (dataArray && dataArray.length > 0) {
        var data = dataArray[0];
        handleProductSelection(
          rowIndex,
          data,
          e,
          rowIndex1,
          true,
          e.target.value
        );

        // Create a new object with the rowIndex passed into the function
        // var obj = {
        //   rowIndex: 0, // Use the rowIndex passed as an argument
        //   defaultBarcode: data.codeNo || "",
        //   d2_Id: Number(data.itmId_d2) || 0,
        //   productName: data.itmName || "",
        //   parentGrp: Number(data.itmGrpId_d2) || 0,
        //   parentGrpName: data.itmGrpName || "",
        //   godownId: Number(data.godownId) || 0,
        //   godownName: data.godownName || "",
        //   masterDescription: data.masterDescriptn || "",
        //   shortDescription: data.itemDescriptn || "",
        //   batchNo: Number(data.batchNo) || "",
        //   mgfDate: data.mgfDate || "",
        //   expDate: data.expDate || "",
        //   productUnitName: data.unitIdName || "",
        //   batchNos: data.batchNos || [],
        //   serialNumbers: data.serialNumbers || [],
        //   enableSerialNo: data.enableSerialNo || false,
        //   enableBatchNo: data.enableBatchNo || false,
        //   productUnit: Number(data.mainUnit) || 0,
        //   Qty: 1,
        //   FreeQty: Number(data.freeQty) || 0,
        //   itemPrice: Number(data.priceUnit) || 0,
        //   DiscPer: Number(data.disc1Per) || 0,
        //   DiscAmt: Number(data.disc1Amt) || 0,
        //   DoubleDis: Number(data.disc2Per) || 0,
        //   discountAmt2: Number(data.disc2Amt) || 0,
        //   salePrice: Number(data.sellingPrice) || 0,
        //   TotalAmt: Number(data.itmNetAmt) || 0,
        //   mrpPrice: Number(data.itmMrp) || 0,
        //   purPageSaleProfPer: Number(data.purPageSaleProfPer) || 0,
        //   purPageSalePricePer: Number(data.purPageSalePricePer) || 0,
        //   purPageMrpPrice: Number(data.purPageMrpPrice) || 0,
        //   lens_Eye: data.lensEye || "",
        //   lens_Dia: data.lensDia || "",
        //   lens_AXIS: Number(data.lensAXIS) || 0,
        //   lens_SPH: Number(data.lensSPH) || 0,
        //   lens_CYL: Number(data.lensCYL) || 0,
        //   lens_Add: Number(data.lensADD) || 0, // Swapped 'lens_Add' and 'add'
        //   extraFields: data.extraFields || [],
        // };

        // Update the specific row in ItemMasterRows
        // setItemMasterRows((prevRows) => {
        //   const updatedRows = [...prevRows];
        //   updatedRows[rowIndex] = obj; // Replace the row at rowIndex1 with the new object
        //   return updatedRows;
        // });
      }
    }
  };

  const handleProductSelection = async (
    rowIndex,
    selectedItem,
    e,
    rowIndex1,
    IsBarcode = false,
    Barcode
  ) => {
    // setLoading(true);
    let newrows = [];
    debugger;
    if (selectedItem) {
      var ItemMasterId = selectedItem.id2 || selectedItem.itmId_d2;
      const ItemData = await ItemMasterAPI.GetItemMasterById(ItemMasterId);
      debugger;

      if (ItemData) {
        setItemMasterRows((prevRows) => {
          const newRows = [...prevRows];

          newRows[rowIndex] = {
            ...newRows[rowIndex],
            // rowIndex : ItemData.rowIndex,
            d1_Id: ItemData.d1_Id || "",
            d2_Id: ItemData.d2_Id || "",
            productName: ItemData.productName || "",
            parentGrp: ItemData.parentGrp2 || "",
            parentGrpName: ItemData.parentGrpName || "",
            productUnitName: ItemData.mainUnitName,
            productUnit: ItemData.productUnit || "",
            productAltUnit: ItemData.productAltUnit || "",
            unitConvFactor: ItemData.unitConvFactor || "",
            enableBarcode: ItemData.enableBarcode || "",
            defaultBarcode: Barcode || "",
            taxCategory1: ItemData.taxCategory1 || "",
            taxLimit: ItemData.taxLimit || "",
            taxCategory2: ItemData.taxCategory2 || "",
            hsnCode: ItemData.hsnCode || "",
            shortDescription: ItemData.shortDescription || "",
            Qty: ItemData.Qty || 1,
            FreeQty: ItemData.FreeQty || "",
            purchasePrice: ItemData.purchasePrice || "",
            purchaseDiscount: ItemData.purchaseDiscount || "",
            saleProfit: ItemData.saleProfit || "",
            salePrice: ItemData.salePrice || "",
            saleDiscount: ItemData.saleDiscount || "",
            itemPrice: IsBarcode
              ? selectedItem.sellingPrice
              : Number(TransType) === 12 || Number(TransType) === 13
              ? ItemData.salePrice
              : ItemData.purchasePrice,
            costPrice: ItemData.costPrice || 0,
            mrpPrice: ItemData.mrpPrice || 0,
            DiscPer:
              Number(TransType) === 12 || Number(TransType) === 13
                ? ItemData.saleDiscount
                : ItemData.purchaseDiscount || 0,
            DiscAmt: ItemData.DiscAmt || 0,
            TotalAmt: ItemData.TotalAmt || 0,
            // salePriceForPur: ItemData.salePriceForPur || 0,
            purPageSalePrice: ItemData.salePrice || 0,
            availableQuantity: ItemData.availableQuantity || 0,
            lens_Eye: ItemData.lens_EYE || 0,
            lens_SPH: ItemData.lens_SPH || 0,
            lens_CYL: ItemData.lens_CYL || 0,
            lens_AXIS: ItemData.lens_AXIS || 0,
            lens_Dia: ItemData.lens_Dia || 0,
            lens_Add: ItemData.lens_Add || 0,
            enableSerialNo: ItemData.enableSerialNo || 0,
          };
          newrows = newRows;
          return newRows;
        });

        setItemMasterList((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].isDropdownOpen = false;
          newRows[rowIndex].filteredProduct = [];
          newRows[rowIndex].highlightedIndex = -1;
          return newRows;
        });
        setHighlightedIndex(-1);

        fetchBillTypeSett(ItemData.taxCategory1, rowIndex);
      }

      const PowerRangeData = await LensItemCreation.getLensItemCreationByItemId(
        selectedItem.id2 || selectedItem.itmId_d2
      );
      setProductRangeDataMain(PowerRangeData);
      debugger;
      if (PowerRangeData) {
        // Generate values for each list based on PowerRangeData
        const values1 = [];
        for (
          let i = PowerRangeData.add_Min;
          i <= PowerRangeData.add_Max;
          i += PowerRangeData.add_Step
        ) {
          values1.push(i);
        }

        const values2 = [];
        for (
          let i = PowerRangeData.cyL_Min;
          i <= PowerRangeData.cyL_Max;
          i += PowerRangeData.cyL_Step
        ) {
          values2.push(i);
        }

        const values3 = [];
        for (
          let i = PowerRangeData.spH_Min;
          i <= PowerRangeData.spH_Max;
          i += PowerRangeData.spH_Step
        ) {
          values3.push(i);
        }

        const axis = "45,90,180";
        const axisValues = axis.split(",").map(Number); // Convert "45,90,180" into an array of numbers [45, 90, 180]

        const values4 = [];

        // Loop through each value in axisValues
        for (let value of axisValues) {
          values4.push(value); // You can perform other operations with 'value' inside the loop if needed
        }

        const EYE = "R,L,RL";
        const EyeValues = EYE.split(","); // Split the string into an array of ["R", "L", "RL"]
        // Create the values5 array directly by using map
        const values5 = EyeValues.map((eye) => {
          // Optional: You could add transformations here if needed
          return eye; // Since you seem to want to keep the values the same, just return the value
        });

        console.log(values5); // Output: ["R", "L", "RL"]

        // Update the specific row in the state arrays
        setPowerRangeADD((prevState) => {
          if (values1.length > 0) {
            const updatedState = [...prevState];
            updatedState[rowIndex] = {
              PowerRangeADDShow: false,
              PowerRangeADDList: values1,
            };
            return updatedState;
          }
          return prevState;
        });

        setPowerRangeCYL((prevState) => {
          if (values2.length > 0) {
            const updatedState = [...prevState];
            updatedState[rowIndex] = {
              PowerRangeCYLShow: false,
              PowerRangeCYLList: values2,
            };
            return updatedState;
          }
          return prevState;
        });

        setPowerRangeSPH((prevState) => {
          if (values3.length > 0) {
            const updatedState = [...prevState];
            updatedState[rowIndex] = {
              PowerRangeSPHShow: false,
              PowerRangeSPHList: values3,
            };
            return updatedState;
          }
          return prevState;
        });

        setPowerRangeAXIS((prevState) => {
          if (values4.length > 0) {
            const updatedState = [...prevState];
            updatedState[rowIndex] = {
              PowerRangeAXISShow: false,
              PowerRangeAXISList: values4,
            };
            return updatedState;
          }
          return prevState;
        });

        setPowerRangeEYE((prevState) => {
          if (values5.length > 0) {
            const updatedState = [...prevState];
            updatedState[rowIndex] = {
              PowerRangeEYEShow: false,
              PowerRangeEYEList: values5,
            };
            return updatedState;
          }
          return prevState;
        });
      }
      // setLoading(false);

      setTimeout(async () => {
        await onChangeProductSaveMethod(e, rowIndex1, rowIndex, "", newrows);
      }, 100);
    }
    // setLoading(false);
  };
  let preventBlur = false;
  //---------------Get TaxCategory By Id (on item select)-----------------------

  const fetchBillTypeSett = async (itaxCategoryId, rowIndex) => {
    try {
      const TaxData = await TaxCategoryApi.getTaxCategoryById(itaxCategoryId);
      console.log(TaxData);
      if (TaxData) {
        setItemMasterRows((prevRows) => {
          const newRows = Array.isArray(prevRows) ? [...prevRows] : [];

          newRows[rowIndex] = {
            ...newRows[rowIndex],
            taxCat1_Cgst: TaxData.localTaxCGST,
            taxCat1_Sgst: TaxData.localTaxSGST,
            taxCat1_Igst: TaxData.centralTaxIGST,
            taxCat1_Cess: TaxData.cessTax,
          };
          return newRows;
        });
      } /**/ else {
        setItemMasterRows((prevRows) => {
          const newRows = Array.isArray(prevRows) ? [...prevRows] : [];

          newRows[rowIndex] = {
            ...newRows[rowIndex],
            taxCat1_Cgst: 0,
            taxCat1_Sgst: 0,
            taxCat1_Igst: 0,
            taxCat1_Cess: 0,
          };
          return newRows;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [highlightedIndex7, setHighlightedIndex7] = useState(0);
  const [highlightedIndex8, setHighlightedIndex8] = useState(0);
  const [highlightedIndex9, setHighlightedIndex9] = useState(0);
  const [highlightedIndex10, setHighlightedIndex10] = useState(0);
  const [highlightedIndex11, setHighlightedIndex11] = useState(0);

  useEffect(() => {
    console.log("highlightedIndex7", highlightedIndex7);
  }, [highlightedIndex7]);
  useEffect(() => {
    console.log("highlightedIndex8", highlightedIndex8);
  }, [highlightedIndex8]);
  useEffect(() => {
    console.log("highlightedIndex9", highlightedIndex9);
  }, [highlightedIndex9]);
  useEffect(() => {
    console.log("highlightedIndex10", highlightedIndex10);
  }, [highlightedIndex10]);
  useEffect(() => {
    console.log("highlightedIndex11", highlightedIndex11);
  }, [highlightedIndex11]);

  const handleProductRowKeyDown = async (event, rowIndex, columnIndex) => {
    // debugger;
    if (event.key === "Enter" || event.key === "PageDown") {
      event.preventDefault();
      const { name, value } = event.target;
      // Add a new row if at the end and 'Enter' is pressed
      if (rowIndex === itemMasterRows.length - 1) {
        if (
          name === "TotalAmt" ||
          name === "salePriceForPur" ||
          name === "saleProfit" ||
          name === "mrpPrice" ||
          name === "BrkgAmt"
        ) {
          await handleAddRow();
        }
      }

      // Calculate next column index
      const nextColumnIndex = columnIndex + 1;
      let nextRowCellRef =
        nextCellRefsProduct.current[rowIndex][nextColumnIndex];

      if (!nextRowCellRef) {
        for (let i = nextColumnIndex; i < 35; i++) {
          const cellRef = nextCellRefsProduct.current[rowIndex][i]; // Assuming index 0 for the first column
          if (cellRef) {
            nextRowCellRef = cellRef;
            break;
          }
        }
      }

      if (nextRowCellRef) {
        nextRowCellRef.focus();
        nextRowCellRef.select();
      } else {
        setTimeout(() => {
          const nextRowIndex = rowIndex + 1;
          let nextRowCellId = `Code-td-${nextRowIndex}`;
          let nextRowCell = document.getElementById(nextRowCellId);
          if (!nextRowCell) {
            nextRowCellId = `Group-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }

          if (!nextRowCell) {
            nextRowCellId = `ItemName-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }

          if (nextRowCell) {
            nextRowCell.focus();
          }
        }, 10);
      }
    } else if (event.key === "PageUp") {
      event.preventDefault();
      debugger;

      const { name, value } = event.target;
      const nextColumnIndex = columnIndex - 1;
      let nextRowCellRef =
        nextCellRefsProduct.current[rowIndex][nextColumnIndex];

      if (!nextRowCellRef) {
        for (let i = nextColumnIndex; i > 0; i--) {
          // Loop from nextColumnIndex down to 0
          const cellRef = nextCellRefsProduct.current[rowIndex][i]; // Assuming index 0 for the first column
          if (cellRef) {
            nextRowCellRef = cellRef;
            break;
          }
        }
      }

      if (nextRowCellRef) {
        nextRowCellRef.focus();
        nextRowCellRef.select();
        // nextRowCellRef.addEventListener("keydown", (e) => {
        //   const preventKeys = [
        //     "ArrowUp",
        //     "ArrowDown",
        //     "ArrowLeft",
        //     "ArrowRight",
        //     "Tab",
        //     "Shift",
        //     "Control",
        //     "Alt",
        //   ];

        //   if (preventKeys.includes(e.key)) {
        //     e.preventDefault();
        //   }
        // });
      } else {
        setTimeout(() => {
          // debugger;
          const nextRowIndex = rowIndex - 1;
          let nextRowCellId = `MrpPrc-td-${nextRowIndex}`;
          let nextRowCell = document.getElementById(nextRowCellId);

          if (!nextRowCell) {
            nextRowCellId = `TotalAmt-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }
          if (!nextRowCell) {
            nextRowCellId = `SalePrft-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }

          if (!nextRowCell) {
            nextRowCellId = `BrkgAmt-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }
          if (!nextRowCell) {
            nextRowCellId = `BrkgPer-td-${nextRowIndex}`;
            nextRowCell = document.getElementById(nextRowCellId);
          }

          if (nextRowCell) {
            nextRowCell.focus();
          }
        }, 10);
      }
    }
  };

  const handleProductKeydownForSuggestion = async (
    event,
    rowIndex,
    columnIndex
  ) => {
    if (event.key === "ArrowDown") {
      if (columnIndex === 7) {
        setHighlightedIndex7((prev) => Math.min(prev + 1, 11));
      } else if (columnIndex === 8) {
        setHighlightedIndex8((prev) => Math.min(prev + 1, 11));
      } else if (columnIndex === 9) {
        setHighlightedIndex9((prev) => Math.min(prev + 1, 11));
      } else if (columnIndex === 10) {
        setHighlightedIndex10((prev) => Math.min(prev + 1, 11));
      } else if (columnIndex === 11) {
        setHighlightedIndex11((prev) => Math.min(prev + 1, 11));
      }
    }
    if (event.key === "ArrowUp") {
      if (columnIndex === 7) {
        setHighlightedIndex7((prev) => Math.max(prev - 1, 0));
      } else if (columnIndex === 8) {
        setHighlightedIndex8((prev) => Math.max(prev - 1, 0));
      } else if (columnIndex === 9) {
        setHighlightedIndex9((prev) => Math.max(prev - 1, 0));
      } else if (columnIndex === 10) {
        setHighlightedIndex10((prev) => Math.max(prev - 1, 0));
      } else if (columnIndex === 11) {
        setHighlightedIndex11((prev) => Math.max(prev - 1, 0));
      }
    }

    if (event.key === "Enter") {
      if (columnIndex == 8) {
        var item = PowerRangeSPH[rowIndex]?.PowerRangeSPHList.filter(
          (element, index) => index === highlightedIndex8
        );
        handlePowerSelection(
          rowIndex,
          item[0],
          event.target.name,
          event.target.value
        );
      } else if (columnIndex == 7) {
        var item = PowerRangeEYE[rowIndex]?.PowerRangeEYEList.filter(
          (element, index) => index === highlightedIndex7
        );
        handlePowerSelection(
          rowIndex,
          item[0] || event.target.value,
          event.target.name,
          event.target.value
        );
      } else if (columnIndex == 9) {
        var item = PowerRangeCYL[rowIndex]?.PowerRangeCYLList.filter(
          (element, index) => index === highlightedIndex9
        );
        handlePowerSelection(
          rowIndex,
          item[0],
          event.target.name,
          event.target.value
        );
      } else if (columnIndex == 10) {
        var item = PowerRangeAXIS[rowIndex]?.PowerRangeAXISList.filter(
          (element, index) => index === highlightedIndex10
        );
        handlePowerSelection(
          rowIndex,
          item[0],
          event.target.name,
          event.target.value
        );
      } else if (columnIndex == 11) {
        var item = PowerRangeADD[rowIndex]?.PowerRangeADDList.filter(
          (element, index) => index === highlightedIndex11
        );
        handlePowerSelection(
          rowIndex,
          item[0],
          event.target.name
          // event.target.value
        );
      }
    }

    setTimeout(() => {
      handleProductRowKeyDown(event, rowIndex, columnIndex);
    }, 100);
  };

  // function debounce(func, delay, immediate = false) {
  //   let timer;
  //   const debounced = function (...args) {
  //     const context = this;
  //     const callNow = immediate && !timer;
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       timer = null;
  //       if (!immediate) func.apply(context, args);
  //     }, delay);
  //     if (callNow) func.apply(context, args);
  //   };

  //   debounced.cancel = function () {
  //     clearTimeout(timer);
  //     timer = null;
  //   };

  //   return debounced;
  // }

  const debouncedSaveMethod = useCallback(
    debounce((e, rowIndex1, rowIndex2) => {
      console.log("debouncedSaveMethod");
      onChangeProductSaveMethod(e, rowIndex1, rowIndex2);
    }, 300),
    [onChangeProductSaveMethod]
  );

  useEffect(() => {
    return () => {
      debouncedSaveMethod.cancel?.();
    };
  }, [debouncedSaveMethod]);

  //---------------Auto-suggestion Change-----------------------

  // const handleKeyDown_ProductSuggestion = (e, rowIndex) => {
  //   // // debugger;
  //   const { filteredProduct, highlightedIndex } = itemMasterList[rowIndex];
  //   if (!filteredProduct.length) {
  //     handleProductRowKeyDown(e, rowIndex, 3);
  //     return;
  //   }

  //   switch (e.key) {
  //     case "ArrowDown":
  //       e.preventDefault();
  //       setItemMasterList((prevRows) => {
  //         const newRows = [...prevRows];
  //         newRows[rowIndex].highlightedIndex =
  //           (highlightedIndex + 1) % filteredProduct.length;
  //         return newRows;
  //       });
  //       setHighlightedIndex(
  //         (prevIndex) => (prevIndex + 1) % filteredProduct.length // +1 for "Add New" option
  //       );
  //       break;
  //     case "ArrowUp":
  //       e.preventDefault();
  //       setItemMasterList((prevRows) => {
  //         const newRows = [...prevRows];
  //         newRows[rowIndex].highlightedIndex =
  //           highlightedIndex === 0
  //             ? filteredProduct.length - 1
  //             : highlightedIndex - 1;
  //         return newRows;
  //       });
  //       setHighlightedIndex((prevIndex) =>
  //         prevIndex === 0 ? filteredProduct.length : prevIndex - 1
  //       );
  //       break;
  //     case "Enter":
  //       e.preventDefault();
  //       if (
  //         highlightedIndex >= 0 &&
  //         highlightedIndex < filteredProduct.length
  //       ) {
  //         handleProductSelection(rowIndex, filteredProduct[highlightedIndex]);
  //       }
  //       break;
  //     case "Escape":
  //       setItemMasterList((prevRows) => {
  //         const newRows = [...prevRows];
  //         newRows[rowIndex].isDropdownOpen = false;
  //         return newRows;
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  //---------------Auto-suggestion Change-----------------------

  const handleKeyDown_ProductGroupSuggestion = (e, rowIndex) => {
    // Validate `rowIndex`
    if (rowIndex < 0 || rowIndex >= itemMasterRows.length) {
      console.error("Invalid rowIndex:", rowIndex);
      return;
    }

    // Safely access the row
    const row = itemMasterRows[rowIndex];
    if (!row) {
      console.error("Undefined row at index:", rowIndex);
      return;
    }

    const { filteredProduct = [], highlightedIndex = 0 } = row;

    // Handle empty product list
    if (!filteredProduct.length) {
      handleProductRowKeyDown(e, rowIndex, 2);
      // return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setitemGroupMasterList((prevRows) => {
          const newRows = [...prevRows];
          const currentHighlightedIndex =
            newRows[rowIndex]?.highlightedIndex || 0;
          // newRows[rowIndex].highlightedIndex =
          //   (highlightedIndex + 1) % filteredProduct.length;
          const totalItems = newRows[rowIndex].filteredProduct.length || 0;
          newRows[rowIndex].highlightedIndex =
            (currentHighlightedIndex + 1) % totalItems;
          return newRows;
        });
        // setHighlightedIndex((prevIndex) => prevIndex + 1);

        setHighlightedIndex((prevIndex) => {
          const totalItems =
            itemGroupMasterList[rowIndex]?.filteredProduct.length || 0;
          let newIndex = prevIndex + 1;

          // Reset to -1 if newIndex exceeds the totalItems
          if (newIndex >= totalItems) {
            newIndex = -1;
          }

          return newIndex;
        });

        break;

      case "ArrowUp":
        e.preventDefault();
        setitemGroupMasterList((prevRows) => {
          const newRows = [...prevRows];
          const currentHighlightedIndex =
            newRows[rowIndex]?.highlightedIndex || 0;
          const totalItems = newRows[rowIndex].filteredProduct.length || 0;
          // newRows[rowIndex].highlightedIndex =
          //   currentHighlightedIndex === 0
          //     ? filteredProduct.length - 1
          //     : currentHighlightedIndex - 1;
          if (totalItems > 0) {
            newRows[rowIndex].highlightedIndex =
              (currentHighlightedIndex - 1 + totalItems) % totalItems;
          }
          return newRows;
        });
        // setHighlightedIndex((prevIndex) =>
        //   prevIndex === 0 ? filteredProduct.length - 1 : prevIndex - 1
        // );

        setHighlightedIndex((prevIndex) => {
          const totalItems =
            itemGroupMasterList[rowIndex]?.filteredProduct.length || 0;
          let newIndex = prevIndex - 1;

          // If newIndex is less than 0, reset to the last item
          if (newIndex < 0) {
            newIndex = totalItems - 1; // Reset to the last item when the index goes below 0
          }

          return newIndex;
        });

        break;

      case "Enter":
        e.preventDefault();
        if (
          highlightedIndex >= 0 &&
          highlightedIndex <
            itemGroupMasterList[rowIndex]?.filteredProduct.length
        ) {
          handleProductGroupSelection(
            rowIndex,
            itemGroupMasterList[rowIndex].filteredProduct[
              itemGroupMasterList[rowIndex].highlightedIndex
            ]
          );
        }
        break;

      case "Escape":
        setitemGroupMasterList((prevRows) => {
          const newRows = [...prevRows];
          newRows[rowIndex].isDropdownOpen = false;
          return newRows;
        });
        break;

      default:
        break;
    }
  };

  const handleKeyDown_ProductSuggestion = (e, rowIndex, rowindex1) => {
    // Validate `rowIndex`
    // debugger;
    setTimeout(() => {
      if (rowIndex < 0 || rowIndex >= itemMasterRows.length) {
        console.error("Invalid rowIndex:", rowIndex);
        return;
      }

      // Safely access the row
      const row = itemMasterRows[rowIndex];
      if (!row) {
        console.error("Undefined row at index:", rowIndex);
        return;
      }

      const { filteredProduct = [], highlightedIndex = 0 } = row;

      // Handle empty product list
      if (!filteredProduct.length) {
        handleProductRowKeyDown(e, rowIndex, 3);
        // return;
      }

      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setItemMasterList((prevRows) => {
            const newRows = [...prevRows];
            const currentHighlightedIndex =
              newRows[rowIndex]?.highlightedIndex || 0;
            const totalItems = newRows[rowIndex].filteredProduct.length || 0;
            newRows[rowIndex].highlightedIndex =
              (currentHighlightedIndex + 1) % totalItems;
            return newRows;
          });
          // setHighlightedIndex((prevIndex) => prevIndex + 1);

          setHighlightedIndex((prevIndex) => {
            const totalItems =
              itemMasterList[rowIndex]?.filteredProduct.length || 0;
            let newIndex = prevIndex + 1;

            // Reset to -1 if newIndex exceeds the totalItems
            if (newIndex >= totalItems) {
              newIndex = 0;
            }

            return newIndex;
          });

          break;

        case "ArrowUp":
          e.preventDefault();
          setItemMasterList((prevRows) => {
            const newRows = [...prevRows];
            const currentHighlightedIndex =
              newRows[rowIndex]?.highlightedIndex || 0;
            const totalItems = newRows[rowIndex].filteredProduct.length || 0;
            // newRows[rowIndex].highlightedIndex =
            //   currentHighlightedIndex === 0
            //     ? filteredProduct.length - 1
            //     : currentHighlightedIndex - 1;
            if (totalItems > 0) {
              newRows[rowIndex].highlightedIndex =
                (currentHighlightedIndex - 1 + totalItems) % totalItems;
            }
            return newRows;
          });
          // setHighlightedIndex((prevIndex) =>
          //   prevIndex === 0 ? filteredProduct.length - 1 : prevIndex - 1
          // );

          setHighlightedIndex((prevIndex) => {
            const totalItems =
              itemMasterList[rowIndex]?.filteredProduct.length || 0;
            let newIndex = prevIndex - 1;

            // If newIndex is less than 0, reset to the last item
            if (newIndex < 0) {
              newIndex = totalItems - 1; // Reset to the last item when the index goes below 0
            }

            return newIndex;
          });

          break;

        case "Enter":
          e.preventDefault();
          if (e.target.name != "productName") {
            // highlightedIndex >= 0 && highlightedIndex < filteredProduct.length;
          } else {
            debugger;
            if (
              highlightedIndex >= 0 &&
              highlightedIndex <
                itemMasterList[rowIndex]?.filteredProduct.length
            ) {
              handleProductSelection(
                rowIndex,
                itemMasterList[rowIndex].filteredProduct[
                  itemMasterList[rowIndex].highlightedIndex
                ],
                e,
                rowindex1
              );
            }
          }
          break;

        case "Escape":
          setItemMasterList((prevRows) => {
            const newRows = [...prevRows];
            newRows[rowIndex].isDropdownOpen = false;
            return newRows;
          });
          break;

        default:
          break;
      }
    }, 100);
  };

  //---------------Fill Column Setting (Load Event)-----------------------

  const handleHideAndShow_Column = (e, col_Id, col_Name) => {
    const { name, checked } = e.target;
    console.log(`Changing ${name} to ${checked}`);

    const updatedValues = {
      ...TransColumnCb,
      [name]: checked,
    };

    setTransColumnCb(updatedValues);

    // Update TransColumnSet
    setTransColumn((prevState) => {
      const columnIndex = prevState.findIndex(
        (column) => column.colId === col_Id
      );
      if (columnIndex > -1) {
        // Update existing column
        const updatedColumns = prevState.map((column, index) =>
          index === columnIndex
            ? { ...column, colShow: checked, colName: col_Name }
            : column
        );
        return updatedColumns;
      } else {
        // Add new column
        const newColumn = {
          masterType: 218,
          transType: TransType,
          transName: TransName,
          colId: col_Id,
          colName: col_Name,
          colShow: checked,
          colWidth: 0,
          colType: "",
          extraField1: "",
          extraField2: "",
          extraField3: "",
        };
        return [...prevState, newColumn];
      }
    });
  };

  const handleWidth_Column = (e, col_Id) => {
    const { name, checked } = e.target;
    console.log(`Changing ${name} to ${checked}`);

    setTransColumn((prevState) =>
      prevState.map((column) =>
        column.colId === col_Id
          ? { ...column, colWidth: e.target.value }
          : column
      )
    );
  };

  useEffect(() => {
    console.log(TransColumnSet);
  }, [TransColumnSet]);

  //--------------- Save Column Setting -----------------------

  const SaveBtnClick = async () => {
    debugger;
    const pageNo = document.getElementById("setNoOfRows").value;
    setPageNo(pageNo);
    /*
        /*AdjustTableWidth("ItemCodeWidth", "Code-th", "Code-td-");
        AdjustTableWidth("ItemGroupWidth", "ItemGroup-th", "ItemGroup-td-");
        //AdjustTableWidth("ItemNameWidth", "ItemName-th", "ItemName-td-");
        AdjustTableWidth("MasterDescriptionWidth", "MasterDescription-th", "MasterDescriptions-td-");
        */

    //const val = document.getElementById('tableHeight').value;
    //updateHeight(val);

    try {
      if (IsExistTransColumnSet) {
        const isSuccess =
          await TransactionTypeSettingAPI.UpdateVoucherWise_ColumnSettingAsync(
            TransColumnSet
          );
        debugger;
        if (isSuccess) {
          // alert("update");
          console.log("updated");
        } else {
          alert("error while updating");
        }
      } else {
        const isSuccess =
          await TransactionTypeSettingAPI.CreateVoucherWise_ColumnSettingAsync(
            TransColumnSet
          );
        if (isSuccess) {
          // alert("save");
          setTimeout(() => {
            setIsExistTransColumn(true);
          }, 1000);
        } else {
          alert("error while updating");
        }
      }
    } catch (e) {}

    hideModal3();
  };

  return (
    <div>
      <div>
        {isItemWiseList && (
          <ItemWiseBatchList
            hideModal6={hideModalOpen61}
            handleModalClick={handleModalClick}
          />
        )}

        <div
          className="table-container-s1 hideInMobile"
          // style={{ height: "auto" }}
        >
          <table className="table-scrol2" style={{ width: "100%" }}>
            <thead className="table-tbody">
              <tr className="table-tr2">
                <th className=" table-th-1 tb_1 c-w">
                  <label className="c-w">SN</label>
                </th>
                {TransColumnCb.isInputVisible1 && (
                  <th className="mb_1 tb_1 c-w" id="Code-th">
                    {" "}
                    <div className="c-w">BarCode (?)</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible2 && (
                  <th className="mb_2 tb_1 c-w " id="ItemGroup-th">
                    {" "}
                    <div className="c-w">Group</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible3 && (
                  <th
                    className="mb_3 tb_1 c-w"
                    id="ItemName-th"
                    style={{ minWidth: "300px" }}
                  >
                    {" "}
                    <div className="thm2 tb_1 c-w">
                      <label className="c-w" onDoubleClick={openAddItemModal}>
                        Item <span style={{ color: "red" }}>N</span>ame
                      </label>
                    </div>{" "}
                  </th>
                )}
                {TransColumnCb.isInputVisible4 && (
                  <th className="mb_4 tb_1 c-w" id="MasterDescription-th">
                    {" "}
                    <div className="c-w">Description</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible5 && (
                  <th className="mb_5 tb_1 c-w" id="Unit-th">
                    {" "}
                    <div className="c-w">Unit</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible6 && (
                  <th className="mb_6 tb_1 c-w" id="Dia-th">
                    {" "}
                    <div className="c-w">DIA</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible7 && (
                  <th className="mb_7 tb_1 c-w" id="Eye-th">
                    {" "}
                    <div className="c-w">EYE</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible8 && (
                  <th className="mb_8 tb_1 c-w" id="Sph-th">
                    {" "}
                    <div className="c-w">SPH</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible9 && (
                  <th className="mb_9 tb_1 c-w" id="Cyl-th">
                    {" "}
                    <div className="c-w">CYL</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible10 && (
                  <th className="mb_10 tb_1 c-w" id="Axis-th">
                    {" "}
                    <div className="c-w">AXIS</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible11 && (
                  <th className="mb_11 tb_1 c-w" id="Add-th">
                    {" "}
                    <div className="c-w">ADD</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible12 && (
                  <th className="mb_12 tb_1 c-w" id="Attribute1-th">
                    {" "}
                    <div className="c-w">Attribute1</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible13 && (
                  <th className="mb_13 tb_1 c-w" id="Attribute2-th">
                    {" "}
                    <div className="c-w">Attribute2</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible14 && (
                  <th className="mb_14 tb_1 c-w" id="Attribute3-th">
                    {" "}
                    <div className="c-w">Attribute3</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible15 && (
                  <th className="mb_15 tb_1 c-w" id="Attribute4-th">
                    {" "}
                    <div className="c-w">Attribute4</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible16 && (
                  <th className="mb_16 tb_1 c-w" id="Attribute5-th">
                    {" "}
                    <div className="c-w">Attribute5</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible17 && (
                  <th className="mb_17 tb_1 c-w" id="Note1-th">
                    {" "}
                    <div className="c-w">Note1</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible18 && (
                  <th className="mb_18 tb_1 c-w" id="Note2-th">
                    {" "}
                    <div className="c-w">Note2</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible19 && (
                  <th className="mb_19 tb_1 c-w" id="BatchNo-th">
                    {" "}
                    <div className="c-w">Batch No</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible20 && (
                  <th className="mb_20 tb_1 c-w" id="MgfDt-th">
                    {" "}
                    <div className="c-w">Mgf Dt</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible21 && (
                  <th className="mb_21 tb_1 c-w" id="ExpDt-th">
                    {" "}
                    <div className="c-w">Exp Dt</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible22 && (
                  <th className="mb_22 tb_1 c-w" id="Qty-th">
                    {" "}
                    <div className="c-w">Qty</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible23 && (
                  <th className="mb_23 tb_1 c-w" id="FreeQty-th">
                    {" "}
                    <div className="c-w">Free Qty</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible24 && (
                  <th className="mb_24 tb_1 c-w" id="Price-th">
                    {" "}
                    <div className="c-w">Price</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible25 && (
                  <th className="mb_25 tb_1 c-w" id="DiscPer-th">
                    {" "}
                    <div className="c-w">Dis%</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible26 && (
                  <th className="mb_26 tb_1 c-w" id="DoubleDis-th">
                    {" "}
                    <div className="c-w">Double Dis%</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible27 && (
                  <th className="mb_27 tb_1 c-w" id="DiscAmt-th">
                    {" "}
                    <div className="c-w">Dis Amt</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible28 && (
                  <th className="mb_28 tb_1 c-w" id="TotalAmt-th">
                    {" "}
                    <div className="c-w">Total Amt</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible29 && (
                  <th className="mb_29 tb_1 c-w" id="SalePrc-th">
                    {" "}
                    <div className="c-w">Sale Prc</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible30 && (
                  <th className="mb_30 tb_1 c-w" id="SalePrft-th">
                    {" "}
                    <div className="c-w">Sale Prft</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible31 && (
                  <th className="mb_31 tb_1 c-w" id="MrpPrc-th">
                    {" "}
                    <div className="c-w">MRP Prc</div>
                  </th>
                )}

                {TransColumnCb.isInputVisible32 && (
                  <th className="mb_32 tb_1 c-w" id="BrkrName-th">
                    {" "}
                    <div className="c-w">Brkr Name</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible33 && (
                  <th className="mb_33 tb_1 c-w" id="BrkgPer-th">
                    {" "}
                    <div className="c-w">Brkg%</div>
                  </th>
                )}
                {TransColumnCb.isInputVisible34 && (
                  <th className="mb_34 tb_1 c-w" id="BrkgAmt-th">
                    {" "}
                    <div className="c-w">Brkg Amt</div>
                  </th>
                )}

                {/*{TransColumnCb.isInputVisible35 && (<th className="mb_35 tb_1 c-w" id="customeFields-th"> <div className="c-w">{customfields[0].FieldName}</div></th>)}*/}

                {(() => {
                  if (customfields.length !== 0) {
                    return customfields.map(
                      (fields, index) =>
                        TransColumnCb[`isInputVisible${fields.id + 34}`] && (
                          <th
                            className={`mb_${fields.id + 34} tb_1 c-w`}
                            id={`customeFields${fields.id}-th`}
                          >
                            <div className="c-w">
                              {customfields[index].FieldName}
                            </div>
                          </th>
                        )
                    );
                  }
                })()}

                <th
                  className="table-th table-btn table-btn1 c-w"
                  onClick={showModal3}
                >
                  <i
                    className="fa-solid fa-gear settingIcon"
                    style={{ display: "block" }}
                  ></i>
                </th>
              </tr>
            </thead>

            <tbody
              className="table-container-tbody"
              // style={{ height: "130px", overflowx: "scroll" }}
            >
              {itemMasterRows.map((row, rowIndex) => (
                <tr
                  className={`table-tr2 hover-tr onSerialNoHover ${
                    rowIndex === hoveredRow ? "hovered-row" : ""
                  }`}
                  key={rowIndex}
                  onMouseEnter={() => handleRowHover(rowIndex)}
                  onMouseLeave={handleRowLeave}
                  style={{ textAlign: "center" }}
                >
                  <td
                    className="table-th-1"
                    onClick={() => addRowAtBetween(rowIndex)}
                  >
                    {rowIndex + 1}
                  </td>
                  {TransColumnCb.isInputVisible1 && (
                    <td className="mb_1">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="defaultBarcode"
                        value={row.defaultBarcode}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          fetchDataForSerialNo(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 1)}
                        ref={mainPageInputRef}
                        id={`Code-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible2 && (
                    <td className="mb_2">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="parentGrpName"
                        value={row.parentGrpName}
                        autoComplete="off"
                        onChange={(e) => {
                          debouncedOpenSuggestionItemGroup(e, rowIndex);
                          handleInputChange(e, rowIndex);
                        }}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleKeyDown_ProductGroupSuggestion(e, rowIndex)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][2] = input;
                        }}
                        id={`Group-td-${rowIndex}`}
                        onClick={() => getPosition(rowIndex, 2)} // Get position on click
                      />

                      {itemGroupMasterList[rowIndex]?.isDropdownOpen && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            // width: `${inputPosition.width}px`,
                          }}
                        >
                          {itemGroupMasterList[rowIndex].filteredProduct.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() => {
                                  preventBlur = true;
                                  handleProductGroupSelection(rowIndex, item);

                                  if (
                                    nextCellRefsProduct.current[rowIndex]?.[2]
                                  ) {
                                    nextCellRefsProduct.current[
                                      rowIndex
                                    ][2].focus();
                                  }

                                  setTimeout(() => {
                                    preventBlur = false;
                                  }, 0);
                                }}
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    itemGroupMasterList[rowIndex]
                                      .highlightedIndex === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item.name}
                              </li>
                            )
                          )}

                          <li
                            key={
                              itemGroupMasterList[rowIndex].filteredProduct
                                .length
                            }
                            ref={(el) =>
                              (itemRefs.current[
                                itemGroupMasterList[
                                  rowIndex
                                ].filteredProduct.length
                              ] = el)
                            }
                            className="addNew_btn_saleInvoice"
                            onClick={() => openGroupModal()}
                            style={{
                              cursor: "pointer",
                              borderBottom: "1px solid #ccc",
                              backgroundColor:
                                highlightedIndex ===
                                itemGroupMasterList[rowIndex].filteredProduct
                                  ? "black"
                                  : "#5e709e",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            Add New Group
                            <i class="fa-solid fa-plus"></i>
                          </li>
                        </ul>
                      )}
                    </td>
                  )}
                  {TransColumnCb.isInputVisible3 && (
                    <td className="mb_3" style={{ minWidth: "300px" }}>
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        // style={{ minWidth: "300px" }}
                        name="productName"
                        autoComplete="off"
                        value={row.productName}
                        onKeyUp={(e) =>
                          handleKeyDown_ProductSuggestion(
                            e,
                            rowIndex,
                            row.rowIndex
                          )
                        }
                        onChange={(e) => {
                          debouncedHandleProductNameChange(e, rowIndex);
                          handleInputChange(e, rowIndex);
                        }}
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][3] = input;
                        }}
                        onBlur={() => {
                          if (itemMasterList[rowIndex]) {
                            itemMasterList[rowIndex].isDropdownOpen = false;
                          }
                        }}
                        // onBlur={() => {
                        //   if (!preventBlur && itemMasterList[rowIndex]) {
                        //     itemMasterList[rowIndex].isDropdownOpen = false;
                        //   }
                        // }}
                        id={`ItemName-td-${rowIndex}`}
                        onClick={() => getPosition(rowIndex, 3)} // Get position on click
                      />
                      {itemMasterList[rowIndex]?.isDropdownOpen && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {itemMasterList[rowIndex]?.filteredProduct.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={(e) => {
                                  preventBlur = true;
                                  handleProductSelection(
                                    rowIndex,
                                    item,
                                    e,
                                    row.rowIndex
                                  );
                                  if (
                                    nextCellRefsProduct.current[rowIndex]?.[3]
                                  ) {
                                    nextCellRefsProduct.current[
                                      rowIndex
                                    ][3].focus();
                                  }
                                  setTimeout(() => {
                                    preventBlur = false;
                                  }, 0);
                                }}
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    itemMasterList[rowIndex]
                                      .highlightedIndex === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item.name}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      <input
                        type="hidden"
                        name="parentGrp"
                        value={row.parentGrp}
                      />
                      <input
                        type="hidden"
                        name="productUnit"
                        value={row.productUnit}
                      />
                      <input
                        type="hidden"
                        name="productAltUnit"
                        value={row.productAltUnit}
                      />
                      <input
                        type="hidden"
                        name="unitConvFactor"
                        value={row.unitConvFactor}
                      />
                      <input
                        type="hidden"
                        name="mrpPrice"
                        value={row.mrpPrice}
                      />
                      <input
                        type="hidden"
                        name="amt_after_discount"
                        value={row.amt_after_discount}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible4 && (
                    <td className="mb_4">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="shortDescription"
                        autoComplete="off"
                        value={row.shortDescription}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 4)}
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][4] = input;
                        }}
                        id={`MasterDescription-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible5 && (
                    <td className="mb_5">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="productUnitName"
                        value={row.productUnitName}
                        autoComplete="off"
                        onChange={(e) => {
                          debouncedOpenSuggestionUnit(e, rowIndex);
                          handleInputChange(e, rowIndex);
                        }}
                        onBlur={() => {
                          if (units[rowIndex]) {
                            units[rowIndex].isDropdownOpen = false;
                          }
                        }}
                        onKeyUp={(e) =>
                          handleKeyDown_UnitSuggestion(e, rowIndex, 5)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][5] = input;
                        }}
                        id={`Unit-td-${rowIndex}`}
                      />
                      {units[rowIndex]?.isDropdownOpen && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            // width: `${inputPosition.width}px`,
                          }}
                        >
                          {units[rowIndex]?.filteredProduct.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() => {
                                  preventBlur = true;
                                  handleUnitSelection(rowIndex, item);
                                  if (
                                    nextCellRefsProduct.current[rowIndex]?.[5]
                                  ) {
                                    nextCellRefsProduct.current[
                                      rowIndex
                                    ][5].focus();
                                  }

                                  // Reset preventBlur after the click is processed
                                  setTimeout(() => {
                                    preventBlur = false;
                                  }, 0);
                                }}
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    units[rowIndex].highlightedIndex === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item.unitName}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </td>
                  )}

                  {TransColumnCb.isInputVisible6 && (
                    <td className="mb_6">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="lens_Dia"
                        autoComplete="off"
                        value={row.lens_Dia}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) => handleProductRowKeyDown(e, rowIndex, 6)}
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][6] = input;
                        }}
                        id={`Dia-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible7 && (
                    <td className="mb_7">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="lens_Eye"
                        autoComplete="off"
                        value={row.lens_Eye}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) => {
                          closeSuggestionAll(e, row.rowIndex, rowIndex);
                        }}
                        onClick={() => getPosition(rowIndex, 7)} // Get position on click
                        onKeyUp={(e) =>
                          handleProductKeydownForSuggestion(e, rowIndex, 7)
                        }
                        onFocus={() => getPosition(rowIndex, 7)} // Get position on click
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][7] = input;
                        }}
                        id={`Eye-td-${rowIndex}`}
                      />
                      {/* {PowerRangeEYE.length >= rowIndex && (
                        <> */}
                      {(PowerRangeEYE[rowIndex]?.PowerRangeEYEShow ||
                        false) && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {PowerRangeEYE[rowIndex]?.PowerRangeEYEList.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() =>
                                  handlePowerSelection(
                                    rowIndex,
                                    item,
                                    "lens_Eye"
                                  )
                                }
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    highlightedIndex7 === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {/* </>
                      )} */}
                    </td>
                  )}
                  {TransColumnCb.isInputVisible8 && (
                    <td className="mb_8">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        autoComplete="off"
                        name="lens_SPH"
                        value={row.lens_SPH}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) => {
                          closeSuggestionAll(e, row.rowIndex, rowIndex);
                        }}
                        onKeyUp={(e) =>
                          handleProductKeydownForSuggestion(e, rowIndex, 8)
                        }
                        onClick={() => getPosition(rowIndex, 8)} // Get position on click
                        onFocus={() => getPosition(rowIndex, 8)} // Get position on click
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][8] = input;
                        }}
                        id={`Sph-td-${rowIndex}`}
                      />

                      {/* {PowerRangeSPHShow && ("true")} */}
                      {/* {PowerRangeSPH.length >= rowIndex && (
                        <> */}
                      {(PowerRangeSPH[rowIndex]?.PowerRangeSPHShow ||
                        false) && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {PowerRangeSPH[rowIndex]?.PowerRangeSPHList.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() =>
                                  handlePowerSelection(
                                    rowIndex,
                                    item,
                                    "lens_SPH"
                                  )
                                }
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    highlightedIndex8 === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {/* </>
                      )} */}
                    </td>
                  )}
                  {TransColumnCb.isInputVisible9 && (
                    <td className="mb_9">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        autoComplete="off"
                        name="lens_CYL"
                        value={row.lens_CYL}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) => {
                          closeSuggestionAll(e, row.rowIndex, rowIndex);
                        }}
                        onKeyUp={(e) =>
                          handleProductKeydownForSuggestion(e, rowIndex, 9)
                        }
                        onClick={() => getPosition(rowIndex, 9)} // Get position on click
                        onFocus={() => getPosition(rowIndex, 9)} // Get position on click
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][9] = input;
                        }}
                        id={`Cyl-td-${rowIndex}`}
                      />
                      {/* {PowerRangeCYL.length >= rowIndex && (
                        <> */}
                      {(PowerRangeCYL[rowIndex]?.PowerRangeCYLShow ||
                        false) && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {PowerRangeCYL[rowIndex]?.PowerRangeCYLList.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() =>
                                  handlePowerSelection(
                                    rowIndex,
                                    item,
                                    "lens_CYL"
                                  )
                                }
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    highlightedIndex9 === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {/* </>
                      )} */}
                    </td>
                  )}
                  {TransColumnCb.isInputVisible10 && (
                    <td className="mb_10">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        autoComplete="off"
                        name="lens_AXIS"
                        value={row.lens_AXIS}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) => {
                          closeSuggestionAll(e, row.rowIndex, rowIndex);
                        }}
                        onKeyUp={(e) =>
                          handleProductKeydownForSuggestion(e, rowIndex, 10)
                        }
                        onClick={() => getPosition(rowIndex, 10)} // Get position on click
                        onFocus={() => getPosition(rowIndex, 10)} // Get position on click
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][10] = input;
                        }}
                        id={`Axis-td-${rowIndex}`}
                      />
                      {/* {PowerRangeAXIS.length <= rowIndex && (
                        <> */}
                      {(PowerRangeAXIS[rowIndex]?.PowerRangeAXISShow ||
                        false) && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {PowerRangeAXIS[rowIndex]?.PowerRangeAXISList.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() =>
                                  handlePowerSelection(
                                    rowIndex,
                                    item,
                                    "lens_AXIS"
                                  )
                                }
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    highlightedIndex10 === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                      {/* </>
                      )} */}
                    </td>
                  )}
                  {TransColumnCb.isInputVisible11 && (
                    <td className="mb_11">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        autoComplete="off"
                        name="lens_Add"
                        value={row.lens_Add}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) => {
                          closeSuggestionAll(e, row.rowIndex, rowIndex);
                        }}
                        onKeyUp={(e) =>
                          handleProductKeydownForSuggestion(e, rowIndex, 11)
                        }
                        onClick={() => getPosition(rowIndex, 11)} // Get position on click
                        onFocus={() => getPosition(rowIndex, 11)} // Get position on click
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][11] = input;
                        }}
                        id={`Add-td-${rowIndex}`}
                      />
                      {(PowerRangeADD[rowIndex]?.PowerRangeADDShow ||
                        false) && (
                        <ul
                          className="ItemmasterDuggestionsaleinvoice"
                          ref={suggestionBoxRef}
                          style={{
                            top: `${inputPosition.top}px`,
                            left: `${inputPosition.left}px`,
                            width: `${inputPosition.width}px`,
                          }}
                        >
                          {PowerRangeADD[rowIndex]?.PowerRangeADDList.map(
                            (item, index) => (
                              <li
                                key={item.id2}
                                onClick={() =>
                                  handlePowerSelection(
                                    rowIndex,
                                    item,
                                    "lens_Add"
                                  )
                                }
                                ref={(el) => (itemRefs.current[index] = el)}
                                style={{
                                  backgroundColor:
                                    highlightedIndex11 === index
                                      ? "#e6f7ff"
                                      : "transparent",
                                }}
                              >
                                {item}
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </td>
                  )}

                  {TransColumnCb.isInputVisible12 && (
                    <td className="mb_12">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Attribute1"
                        value={row.Attribute1}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 12)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][12] = input;
                        }}
                        id={`Attribute1-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible13 && (
                    <td className="mb_13">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Attribute2"
                        value={row.Attribute2}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 13)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][13] = input;
                        }}
                        id={`Attribute2-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible14 && (
                    <td className="mb_14">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Attribute3"
                        value={row.Attribute3}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 14)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][14] = input;
                        }}
                        id={`Attribute3-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible15 && (
                    <td className="mb_15">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Attribute4"
                        value={row.Attribute4}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 15)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][15] = input;
                        }}
                        id={`Attribute4-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible16 && (
                    <td className="mb_16">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Attribute5"
                        value={row.Attribute5}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 16)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][16] = input;
                        }}
                        id={`Attribute5-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {TransColumnCb.isInputVisible17 && (
                    <td className="mb_17">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Note1"
                        value={row.Note1}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 17)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][17] = input;
                        }}
                        id={`Note1-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible18 && (
                    <td className="mb_18">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Note2"
                        value={row.Note2}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 18)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][18] = input;
                        }}
                        id={`Note2-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {TransColumnCb.isInputVisible19 && (
                    <td className="mb_19">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="BatchNo"
                        value={row.BatchNo}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 19)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][19] = input;
                        }}
                        id={`BatchNo-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible20 && (
                    <td className="mb_20">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="MgfDt"
                        value={row.MgfDt}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 20)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][20] = input;
                        }}
                        id={`MgfDt-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible21 && (
                    <td className="mb_21">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="ExpDt"
                        value={row.ExpDt}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 21)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][21] = input;
                        }}
                        id={`ExpDt-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {TransColumnCb.isInputVisible22 && (
                    <td className="mb_22">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="Qty"
                        value={row.Qty}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 22)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][22] = input;
                        }}
                        //onKeyUp={handleKeyDown} BatchPopup
                        id={`Qty-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible23 && (
                    <td className="mb_23">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="FreeQty"
                        value={row.FreeQty}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 23)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][23] = input;
                        }}
                        id={`FreeQty-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible24 && (
                    <td className="mb_24">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="itemPrice"
                        value={row.itemPrice}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 24)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][24] = input;
                        }}
                        id={`Price-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible25 && (
                    <td className="mb_25">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="DiscPer"
                        value={row.DiscPer}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 25)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][25] = input;
                        }}
                        id={`DiscPer-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible26 && (
                    <td className="mb_26">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="DoubleDis"
                        value={row.DoubleDis}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 26)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][26] = input;
                        }}
                        id={`DoubleDis-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible27 && (
                    <td className="mb_27">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="DiscAmt"
                        value={row.DiscAmt}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 27)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][27] = input;
                        }}
                        id={`DiscAmt-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible28 && (
                    <td className="mb_28">
                      <input
                        className="table-input-13 pd_1"
                        type="text"
                        name="TotalAmt"
                        // value={row.TotalAmt}
                        value={
                          row.TotalAmt === null ||
                          row.TotalAmt === undefined ||
                          row.TotalAmt === ""
                            ? ""
                            : parseFloat(row.TotalAmt).toFixed(
                                currencyDecimalPlaces
                              )
                        }
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        // onBlur={(e) =>
                        //   onChangeProductSaveMethod(e, row.rowIndex, rowIndex)
                        // }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 28)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][28] = input;
                        }}
                        id={`TotalAmt-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {TransColumnCb.isInputVisible29 && (
                    <td className="mb_29">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="purPageSalePrice"
                        value={row.purPageSalePrice}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 29)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][29] = input;
                        }}
                        id={`SalePrc-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible30 && (
                    <td className="mb_30">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="saleProfit"
                        value={row.saleProfit}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 30)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][30] = input;
                        }}
                        id={`SalePrft-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible31 && (
                    <td className="mb_31">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="mrpPrice"
                        value={row.mrpPrice}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 31)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][31] = input;
                        }}
                        id={`MrpPrc-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {TransColumnCb.isInputVisible32 && (
                    <td className="mb_32">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="BrkrName"
                        value={row.BrkrName}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 32)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][32] = input;
                        }}
                        id={`BrkrName-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible33 && (
                    <td className="mb_33">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="salesManItemIncentive"
                        value={row.salesManItemIncentive}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 33)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][33] = input;
                        }}
                        id={`BrkgPer-td-${rowIndex}`}
                      />
                    </td>
                  )}
                  {TransColumnCb.isInputVisible34 && (
                    <td className="mb_34">
                      <input
                        className="table-input-13 pd_1 "
                        type="text"
                        name="BrkgAmt"
                        value={row.BrkgAmt}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        onBlur={(e) =>
                          debouncedSaveMethod(e, row.rowIndex, rowIndex)
                        }
                        onKeyUp={(e) =>
                          handleProductRowKeyDown(e, rowIndex, 34)
                        }
                        ref={(input) => {
                          if (!nextCellRefsProduct.current[rowIndex])
                            nextCellRefsProduct.current[rowIndex] = [];
                          nextCellRefsProduct.current[rowIndex][34] = input;
                        }}
                        id={`BrkgAmt-td-${rowIndex}`}
                      />
                    </td>
                  )}

                  {(() => {
                    if (customfields.length !== 0) {
                      return customfields.map(
                        (fields, index) =>
                          TransColumnCb[`isInputVisible${fields.id + 34}`] && (
                            <td className={`mb_${fields.id + 34}`}>
                              <input
                                className="table-input-13 pd_1 "
                                type="text"
                                name={`customeFields${fields.id}-${rowIndex}`}
                                //value={row[`customeFields-${rowIndex}`] || ""}
                                onChange={(e) => handleInputChange(e, rowIndex)}
                                onBlur={(e) => debouncedSaveMethod(e, rowIndex)}
                                onKeyDown={(e) =>
                                  handleProductRowKeyDown(
                                    e,
                                    rowIndex,
                                    fields.id
                                  )
                                }
                                ref={(input) => {
                                  if (!nextCellRefsProduct.current[rowIndex])
                                    nextCellRefsProduct.current[rowIndex] = [];
                                  nextCellRefsProduct.current[rowIndex][
                                    fields.id
                                  ] = input;
                                }}
                                id={`customeFields${fields.id}-td-${rowIndex}`}
                              />
                            </td>
                          )
                      );
                    }
                  })()}

                  <td className="" style={{ textAlign: "center" }}>
                    <button
                      className="btn-table1"
                      onClick={() => handleDeleteRow(row.rowIndex, rowIndex)}
                    >
                      <i
                        className="fa-solid fa-trash delete-btn "
                        style={{ margin: "0" }}
                      ></i>
                    </button>
                  </td>
                </tr>
              ))}
              {itemMasterRows.length >= ItemPerPage && (
                <tr className="pagination-tr">
                  <td>
                    Item Per Page
                    <select
                      className="salepgpagination-select"
                      name=""
                      id=""
                      value={ItemPerPage}
                      onChange={ChangeItemPerPage}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="300">300</option>
                      {/* <option value="500">500</option> */}
                    </select>
                  </td>
                  <td onClick={PreviousPage}>
                    <button className="salePrevNextBtn">
                      <i class="fa-solid fa-angle-left"></i>
                    </button>
                  </td>
                  <td>
                    <button className="PageNoPagination">{pageNo}</button>
                  </td>
                  <td onClick={NextPage}>
                    <button className="salePrevNextBtn">
                      <i class="fa-solid fa-angle-right"></i>
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {itemMasterRows && (
          <>
            {itemMasterRows[0].productName && (
              <div className="ItemShowingTabMobileBox">
                <p>Item list</p>
                {itemMasterRows.map((item, index) => (
                  <>
                    {item.productName && (
                      <div className="ItemShowingTabMobile">
                        <h1 className="ItemShowingTabMobile-datarow">
                          <label htmlFor="">
                            <span>#{index + 1}</span>
                            Name : {item.productName.toUpperCase()}{" "}
                          </label>
                          <label htmlFor="">
                            {" "}
                            <i class="fa-solid fa-indian-rupee-sign"></i>
                            {item.itemPrice}
                          </label>
                        </h1>
                        <h1 className="ItemShowingTabMobile-datarow">
                          <label htmlFor="">
                            Item Sub Total : (
                            <i class="fa-solid fa-indian-rupee-sign"></i>
                            {item.itemPrice} * {item.Qty})
                          </label>
                          <label htmlFor="">
                            {" "}
                            <i class="fa-solid fa-indian-rupee-sign"></i>
                            {item.itemPrice * item.Qty}
                          </label>
                        </h1>
                        <h1 className="ItemShowingTabMobile-datarow">
                          <label htmlFor="">Discount : ({item.DiscPer}%)</label>
                          <label htmlFor="">
                            <i class="fa-solid fa-indian-rupee-sign"></i>
                            {item.DiscAmt}
                          </label>
                        </h1>
                        <h1
                          style={{ marginTop: "10px" }}
                          className="ItemShowingTabMobile-datarow"
                        >
                          <label
                            htmlFor=""
                            onClick={(e) => handleDeleteRow(index)}
                          >
                            Delete <i class="fa-solid fa-trash"></i>
                          </label>
                          <label
                            htmlFor=""
                            onClick={() => handleEditRow(index)}
                          >
                            Edit <i class="fa-solid fa-pen-to-square"></i>
                          </label>
                        </h1>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
            <div className="table-down-main showMobile">
              <div className="table-down-s2">
                <div className="table-down-s1 fs-13">
                  <label className="table-down-l2">Total&nbsp;Qty:</label>
                  <span>{inputValues.TotalQty}</span>
                </div>
                <div className="table-down-s1 fs-13">
                  <label className="table-down-l2">Price:</label>
                  <span>
                    {parseFloat(inputValues.TotalPrice).toFixed(
                      currencyDecimalPlaces
                    )}
                  </span>
                </div>
                <div className="table-down-s1 fs-13">
                  <label className="table-down-l2">Disc:</label>
                  <span>
                    {/* {inputValues.} */}
                    {parseFloat(inputValues.TotalDisc).toFixed(
                      currencyDecimalPlaces
                    )}
                  </span>
                </div>
                <div className="table-down-s1 fs-13">
                  <label className="table-down-l2">Amount:</label>
                  <span>
                    {/* {inputValues.} */}
                    {parseFloat(inputValues.TotalItemAmt).toFixed(
                      currencyDecimalPlaces
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="saveitemButton showMobile">
          <button className="addItemtnInsale" onClick={SaleItemAdd}>
            <i className="fa-solid fa-add"></i>
          </button>
        </div>
        <div style={{ display: "flex" }}>
          <div className="stock-label hideInMobile">
            <div>
              <label className="label-red fs-13">
                &nbsp; There is no stock available for this product. Avail Qty :
              </label>
            </div>
          </div>

          <div
            className="table-down-main hideInMobile"
            // style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <div className="table-down-s2">
              {ShowCostPriceAndLandingPrice && (
                <>
                  <div className="table-down-s1 fs-13">
                    <label className="table-down-l2">Cost&nbsp;Price:</label>
                    <span>{inputValues.TotalQty}</span>
                  </div>
                  <div className="table-down-s1 fs-13">
                    <label className="table-down-l2">Landing&nbsp;Price:</label>
                    <span>{inputValues.TotalQty}</span>
                  </div>
                </>
              )}
              <div className="table-down-s1 fs-13">
                <label className="table-down-l2">Total&nbsp;Qty:</label>
                <span>{inputValues.TotalQty}</span>
              </div>
              <div className="table-down-s1 fs-13">
                <label className="table-down-l2">Price:</label>
                <span>
                  {parseFloat(inputValues.TotalPrice).toFixed(
                    currencyDecimalPlaces
                  )}
                </span>
              </div>
              <div className="table-down-s1 fs-13">
                <label className="table-down-l2">Disc:</label>
                <span>
                  {/* {inputValues.} */}
                  {parseFloat(inputValues.TotalDisc).toFixed(
                    currencyDecimalPlaces
                  )}
                </span>
              </div>
              <div className="table-down-s1 fs-13">
                <label className="table-down-l2">Amount:</label>
                <span>
                  {/* {inputValues.} */}
                  {parseFloat(inputValues.TotalItemAmt).toFixed(
                    currencyDecimalPlaces
                  )}
                </span>
              </div>
            </div>

            <div className="table-down-s2">
              <button className="btn-table2" onClick={handleAddRow}>
                <i className="fa-solid fa-square-plus sale-Invoice-plus-button"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/******************************************************/}
      {isModalOpen3 && (
        <div className="modal">
          <div className="ModalBoxProductSetting">
            <div className="modal-title">
              <h3 className="modal-h">Add Columns into Table</h3>
              <span className="close" onClick={hideModal3}>
                &times;
              </span>
            </div>
            <div
              className="ModalContentModalSettingP"
              style={{ height: "400px", overflowY: "scroll" }}
            >
              <div className="gridview2">
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) => {
                        handleHideAndShow_Column(e, 1, "Item Code");
                        appendValue(e);
                      }}
                      name="isInputVisible1"
                      checked={TransColumnCb.isInputVisible1}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Item Code</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 1)}
                    />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 2, "Item Group")
                      }
                      name="isInputVisible2"
                      checked={TransColumnCb.isInputVisible2}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Item Group
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 2)}
                    />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 3, "Item Name")
                      }
                      name="isInputVisible3"
                      checked={TransColumnCb.isInputVisible3}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox" id="ItemName-w">
                      Item Name
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 3)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 4, "Master Description")
                      }
                      name="isInputVisible4"
                      checked={TransColumnCb.isInputVisible4}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Master Description
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 4)}
                    />
                  </div>
                </div>
                <div className=" ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 5, "Unit")}
                      name="isInputVisible5"
                      checked={TransColumnCb.isInputVisible5}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Unit</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 5)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 6, "DIA")}
                      name="isInputVisible6"
                      checked={TransColumnCb.isInputVisible6}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> DIA</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 6)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 7, "EYE")}
                      name="isInputVisible7"
                      checked={TransColumnCb.isInputVisible7}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> EYE</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 7)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 8, "SPH")}
                      name="isInputVisible8"
                      checked={TransColumnCb.isInputVisible8}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> SPH</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 8)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 9, "CYL")}
                      name="isInputVisible9"
                      checked={TransColumnCb.isInputVisible9}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> CYL</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 9)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 10, "AXIS")}
                      name="isInputVisible10"
                      checked={TransColumnCb.isInputVisible10}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> AXIS</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 10)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 11, "ADD")}
                      name="isInputVisible11"
                      checked={TransColumnCb.isInputVisible11}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> ADD</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 11)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 12, "Attribute 1")
                      }
                      name="isInputVisible12"
                      checked={TransColumnCb.isInputVisible12}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Attribute 1
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 12)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 13, "Attribute 2")
                      }
                      name="isInputVisible13"
                      checked={TransColumnCb.isInputVisible13}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Attribute 2
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 13)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 14, "Attribute 3")
                      }
                      name="isInputVisible14"
                      checked={TransColumnCb.isInputVisible14}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Attribute 3
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 14)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 15, "Attribute 4")
                      }
                      name="isInputVisible15"
                      checked={TransColumnCb.isInputVisible15}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Attribute 4
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 15)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 16, "Attribute 5")
                      }
                      name="isInputVisible16"
                      checked={TransColumnCb.isInputVisible16}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Attribute 5
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 16)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 17, "Note 1")
                      }
                      name="isInputVisible17"
                      checked={TransColumnCb.isInputVisible17}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Note 1</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 17)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 18, "Note 2")
                      }
                      name="isInputVisible18"
                      checked={TransColumnCb.isInputVisible18}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Note 2</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 18)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 19, "Batch No")
                      }
                      name="isInputVisible19"
                      checked={TransColumnCb.isInputVisible19}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Batch No</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 19)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 20, "Mgf Date")
                      }
                      name="isInputVisible20"
                      checked={TransColumnCb.isInputVisible20}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Mgf Date</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 20)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5">
                  <div className="input-box3 size-a1 ">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 21, "Exp Date")
                      }
                      name="isInputVisible21"
                      checked={TransColumnCb.isInputVisible21}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Exp Date</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 21)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 22, "Qty")}
                      name="isInputVisible22"
                      checked={TransColumnCb.isInputVisible22}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Qty</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 22)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 23, "Free Qty")
                      }
                      name="isInputVisible23"
                      checked={TransColumnCb.isInputVisible23}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Free Qty</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 23)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 24, "Price")}
                      name="isInputVisible24"
                      checked={TransColumnCb.isInputVisible24}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Price</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 24)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 25, "Discount %")
                      }
                      name="isInputVisible25"
                      checked={TransColumnCb.isInputVisible25}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Discount %
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 25)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 26, "Double Discount %")
                      }
                      name="isInputVisible26"
                      checked={TransColumnCb.isInputVisible26}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Double Discount %
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 26)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 27, "Discount Amt")
                      }
                      name="isInputVisible27"
                      checked={TransColumnCb.isInputVisible27}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox">
                      {" "}
                      Discount Amt
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 27)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 28, "Total Amt")
                      }
                      name="isInputVisible28"
                      checked={TransColumnCb.isInputVisible28}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label className="modify_p_text_checkbox"> Total Amt</label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 28)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(
                          e,
                          29,
                          "Sale Price (Pur. Page)"
                        )
                      }
                      name="isInputVisible29"
                      checked={TransColumnCb.isInputVisible29}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Sale Price (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 29)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(
                          e,
                          30,
                          "Sale Prof% (Pur. Page)"
                        )
                      }
                      name="isInputVisible30"
                      checked={TransColumnCb.isInputVisible30}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Sale Prof% (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 30)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 31, "Mrp Price (Pur. Page)")
                      }
                      name="isInputVisible31"
                      checked={TransColumnCb.isInputVisible31}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Mrp Price (Pur. Page)
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 31)}
                    />
                  </div>
                </div>

                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 32, "Brkr Name")
                      }
                      name="isInputVisible32"
                      checked={TransColumnCb.isInputVisible32}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Brkr Name
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 32)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) => handleHideAndShow_Column(e, 33, "Brkr%")}
                      name="isInputVisible33"
                      checked={TransColumnCb.isInputVisible33}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Brkr%
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 33)}
                    />
                  </div>
                </div>
                <div className="ModalBoxInputM5 ">
                  <div className="input-box3 size-a1">
                    <input
                      onChange={(e) =>
                        handleHideAndShow_Column(e, 34, "Brkr Amt")
                      }
                      name="isInputVisible34"
                      checked={TransColumnCb.isInputVisible34}
                      style={{ boxShadow: "none" }}
                      className="modify_product_checkbox"
                      type="checkbox"
                    />
                    <label
                      className="modify_p_text_checkbox"
                      style={{ color: "teal", fontWeight: "600" }}
                    >
                      {" "}
                      Brkr Amt
                    </label>
                  </div>
                  <div className="ModalboxTitleInput">
                    <input
                      type="text"
                      className="ModalBoxWidthFix"
                      onChange={(e) => handleWidth_Column(e, 34)}
                    />
                  </div>
                </div>

                {(() => {
                  if (customfields.length !== 0) {
                    return customfields.map((fields, index) => (
                      <div className="ModalBoxInputM5 ">
                        <div className="input-box3 size-a1" key={fields.id}>
                          <input
                            onChange={(e) =>
                              handleHideAndShow_Column(
                                e,
                                index + 35,
                                fields.FieldName
                              )
                            }
                            name={`isInputVisible${fields.id + 34}`}
                            checked={
                              TransColumnCb[`isInputVisible${fields.id + 34}`]
                            }
                            style={{ boxShadow: "none" }}
                            className="modify_product_checkbox"
                            type="checkbox"
                          />
                          <label className="modify_p_text_checkbox">
                            {fields.FieldName}
                          </label>
                        </div>
                        <div className="ModalboxTitleInput ">
                          <input
                            type="text"
                            className="ModalBoxWidthFix"
                            onChange={(e) =>
                              handleWidth_Column(e, fields.id + 34)
                            }
                          />
                          <i
                            className="fa-solid fa-trash mt-1"
                            style={{ zIndex: "1", marginLeft: "50px" }}
                            onClick={() => deleteValueById(fields.id)}
                          ></i>
                        </div>
                      </div>
                    ));
                  }
                })()}
              </div>

              <div className="ModalBoxInputM5 " style={{ paddingLeft: "20px" }}>
                <div className="input-box3 size-a1">
                  <label className="modify_p_text_checkbox">
                    Set No Of Row In Page
                  </label>
                </div>
                <div className="ModalboxTitleInput">
                  <input
                    type="text"
                    className="ModalBoxWidthFix"
                    id="setNoOfRows"
                  />
                </div>
              </div>
              <div style={{ display: "none" }} id="inputField">
                <div className="flex-center">
                  <input
                    style={{ backgroundColor: "#0000001a", color: "white" }}
                    type="text"
                    id="CustomFieldInput"
                  />
                  <button className="Add_btn" onClick={customFieldAddBtn}>
                    Add
                  </button>
                </div>
              </div>
              <div className="flex-center">
                <label
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={AddCustmField_clicked}
                >
                  + Add Custom Field into Table
                </label>
              </div>
              <div className="flex-end" style={{ paddingTop: "10px" }}>
                <button
                  id="step-5"
                  className="greenBtn"
                  onClick={SaveBtnClick}
                  style={{ width: "25%" }}
                >
                  Save
                  <i
                    className="fa-regular fa-floppy-disk im-icon-1"
                    style={{ color: "white" }}
                  ></i>
                </button>
                <button
                  className="redBtn"
                  onClick={hideModal3}
                  style={{ width: "25%" }}
                >
                  Back
                  <i
                    className="fa-solid fa-right-from-bracket im-icon-1"
                    style={{ color: "white" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* **************************** Add New Modal ************** */}
      {AddNewModal && (
        <div className="modal">
          <div className="AddNewItem-modal increase_modal">
            <div className="modal-title">
              <span className="close_modal" onClick={closeAddItemModal}>
                &times;
              </span>
            </div>
            <div className="">
              <div className="tab-content-modal">
                <div className="company-info-top-modal control_flex_modal AddNewItem_scroll">
                  <AddNewItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {SaleItemAddPage && (
        <SaleItemAddPageComponent
          handleInputChange={handleInputChange}
          TransColumnCb={TransColumnCb}
          SaleItemAdd={CloswSaleItemAdd}
          showModal3={showModal3}
          appendValue={appendValue}
          itemMasterRows={itemMasterRows}
          onChangeProductSaveMethod={onChangeProductSaveMethod}
          handleProductRowKeyDown={handleProductRowKeyDown}
          mainPageInputRef={mainPageInputRef}
          nextCellRefsProduct={nextCellRefsProduct}
          handleProductNameChange={handleProductNameChange}
          handleKeyDown_ProductSuggestion={handleKeyDown_ProductSuggestion}
          itemMasterList={itemMasterList}
          itemRefs={itemRefs}
          handleProductSelection={handleProductSelection}
          suggestionBoxRef={suggestionBoxRef}
          handleAddRow={handleAddRow}
          handleDeleteRow={handleDeleteRow}
          indexToEdit={indexToEdit}
        />
      )}
    </div>
  );
}

export default ProductList;
