import axios from '../axiosConfig'; // Adjust the import path accordingly
import User from "./User";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const BillNumbering = {

    insertBillNumbering: async (data) => {
        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }
        try {
            const apiUrl = `${WebApiUrl}/api/BillNumbering/BillNumbering`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                billTypeId: Number(data.billTypeId),
                billSeriesId: Number(data.billSeriesId),
                numberingCategory: Number(data.numberingCategory),
                duplicateBillNumber: Number(data.duplicateBillNumber),
                prefixType: Number(data.prefixType),
                prefix: data.prefix,
                suffix: data.suffix,
                startingNumber: Number(data.startingNumber),
                isEndingNumber: parseBoolean(data.isEndingNumber),
                endingNumber: data.endingNumber,
                warningBeforeNoOfVouchLeft: data.warningBeforeNoOfVouchLeft,
                warningMessage: data.warningMessage,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getBillNumbering: async () => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillNumbering/GetBillNumbers`;
                const response = await axios.post(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;

            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },

    // ************************* Delete ****************************
    deleteBillNumbering: async (billNumberingId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillNumbering?billNumberingId=${billNumberingId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }
        }
    },

    // ************************* Get By Id ****************************
    getBillNumberingbyId: async (MasterType, SeriesId) => {
        const fetchData = async (retryCount = 0, maxRetries = 3) => {
            try {
                const apiUrl = `${WebApiUrl}/api/BillNumbering?MasterType=${MasterType}&SeriesId=${SeriesId}`;
                const response = await axios.get(apiUrl, {}, {
                    withCredentials: true,
                });
                console.log('Response:', response.data.result);
                return response.data.result;
            } catch (error) {
                console.error('Error:', error.message);
                if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                    localStorage.removeItem('loginAccountUserData');
                    localStorage.removeItem("loginUser");
                    // localStorage.clear();
                    await User.Logout();
                     // window.location.href = "/loginpanel";
                } else if ((error.message === 'Request failed with status code 502' || error.message === 'Request failed with status code 500') && retryCount < maxRetries) {
                    console.warn(`Retrying API call... Attempt ${retryCount + 1} of ${maxRetries}`);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    return fetchData(retryCount + 1, maxRetries);
                }
            }
        }
        return fetchData();
    },
    // *************************** Update ***************************

    updateBillNumbering: async (data) => {
        function parseBoolean(v) {
            if (typeof v === "boolean") {
                return v;
            } else if (typeof v === "string") {
                v = v.toLowerCase().trim();
                return v === "true";
            } else {
                console.log("error", v);
                return false;
            }
        }
        try {
            const apiUrl = `${WebApiUrl}/api/BillNumbering?billNumberingId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                billTypeId: Number(data.billTypeId),
                billSeriesId: Number(data.billSeriesId),
                numberingCategory: Number(data.numberingCategory),
                duplicateBillNumber: Number(data.duplicateBillNumber),
                prefixType: Number(data.prefixType),
                prefix: data.prefix,
                suffix: data.suffix,
                startingNumber: Number(data.startingNumber),
                isEndingNumber: parseBoolean(data.isEndingNumber),
                endingNumber: data.endingNumber,
                warningBeforeNoOfVouchLeft: data.warningBeforeNoOfVouchLeft,
                warningMessage: data.warningMessage,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;
        } catch (error) {
            console.error('Error:', error.message);
            if (error.message == "Request failed with status code 401" || error.message == "Access denied. Token not provided" || error.message == "Request failed with status code 403") {
                localStorage.removeItem('loginAccountUserData');
                localStorage.removeItem("loginUser");
                // localStorage.clear();
                await User.Logout();
                 // window.location.href = "/loginpanel";
            }

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default BillNumbering;
