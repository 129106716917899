import React, { useEffect, useState } from "react";
import MainButton from "../Props/MainButton";
import AddFieldModal from "./AddFieldModal";
import ThemeMasterAPI from "../../API/APIServices/ThemeMaster";
import ProductCart5_lc_img from "../../Inventory/Assets/ProductCart5_lc.png";
import ProductCart11 from "../../Inventory/Assets/ProductCart11.png";
import ProductDetailsTheme2 from "../../Inventory/Assets/ProductDetailsTheme2.png";
import ProductDetailsTheme from "../../Inventory/Assets/ProductDetialsTheme.png";
import Slider2 from "../../Inventory/Assets/Slider1.png";
import Slider1 from "../../Inventory/Assets/Slider2.png";
import Cart3 from "../../Inventory/Assets/Cart3.png";
import Cart from "../../Inventory/Assets/Cart.png";
import Variation from "../../Inventory/Assets/Variation.png";
import ProductImgSize from "../../Inventory/Assets/ProductCategoryImageSize.PNG";
import ProductDetailsImgSize from "../../Inventory/Assets/ProductImgSizeDetails.png";
import ProductBottomColor1 from "../../Inventory/Assets/leftSideColor.png";
import RatingBack from "../../Inventory/Assets/ProductRatingBack.png";
import PriceColorCard from "../../Inventory/Assets/PriceColor.png";
import ProductBottomColor2 from "../../Inventory/Assets/ProductBottomColor2.png";
import DesktopLogo from "../../Inventory/Assets/Logo_Desktop_img.png";
import MobileLogo from "../../Inventory/Assets/Logo_img_size.png";
import SidebarIconSize from "../../Inventory/Assets/SidebarIcon.png";
import SidebarFontColor from "../../Inventory/Assets/SidebarForntColor.png";
import NavbarTextColor from "../../Inventory/Assets/NavbarTextColor.png";
import NavbarTopBackgroundimg from "../../Inventory/Assets/NavbarBackgroundColorTop.png";
import MenuDesktop from "../../Inventory/Assets/MenuDesktop.png";
import FullImgSize from "../../Inventory/Assets/ProductFullImage.png";
import HoverDivWidth from "../../Inventory/Assets/ProductFullimgHoverWidth.png";
import HoverDivHeight from "../../Inventory/Assets/ProductFullimgHover.png";
import YoutubeVideoImg from "../../Inventory/Assets/YoutubeVideo.png";
import NoYoutubeVideo from "../../Inventory/Assets/NoYoutubeVideo.png";
import CategoryShow from "../../Inventory/Assets/CategoryShow.png";
import NoCategoryShow from "../../Inventory/Assets/NoCategoryShow.png";
import CategoryBoxImageSize from "../../Inventory/Assets/CategoryBoxImageSize.png";
import WearTrend1 from "../../Inventory/Assets/weartreand1.png";
import WearTrend2 from "../../Inventory/Assets/weartrend2.png";

const ThemeMaster = () => {
  const [ModalForThemeAdd, setModalForThemeAdd] = useState(false);
  const [ShowDropdown, setShowDropdown] = useState("");
  const [UpdateOrSave, setUpdateOrSave] = useState("Save");
  const [ShowReset, setShowReste] = useState("hide");
  const [inputbox, setInputbox] = useState([
    {
      id: "ImgHoverTheme",
      label: "Image Hover Theme[px]",
      inputType: "number",
      imgsrc: FullImgSize,
      category: "product",
      value: 80,
    },
    {
      id: "HoverBoxSmallDivWidth",
      label: "Image Box On Hover Width[px]",
      inputType: "number",
      imgsrc: HoverDivWidth,
      category: "product",
      value: 300,
    },
    {
      id: "HoverBoxSmallDivHeight",
      label: "Image Box On Hover Height[px]",
      inputType: "number",
      imgsrc: HoverDivHeight,
      category: "product",
      value: 250,
    },
    {
      id: "ProductDetailsPage1",
      label: "Product Details Page",
      inputType: "dropdown",
      category: "product",
      options: [
        {
          name: "/ProductDetailTheme2",
          imgsrc: ProductDetailsTheme2,
        },
        {
          name: "/ProductDetailTheme",
          imgsrc: ProductDetailsTheme,
        },
        {
          name: "/ProductDetail",
          imgsrc: "",
        },
      ],
      value: "/ProductDetailTheme2",
    },
    {
      id: "SliderSizeMobile1",
      label: "Slider Size Mobile[vh]",
      inputType: "number",
      category: "slider",
      value: "20",
    },
    {
      id: "BannerSizeMobile1",
      label: "Banner Size Mobile[vh]",
      inputType: "number",
      category: "banner",
      value: "20",
    },
    {
      id: "CategoriesBannerSize1",
      label: "Category Banner Size[vh]",
      inputType: "number",
      category: "banner",
      value: "70",
    },
    {
      id: "ProductViewBottom1",
      label: "Product View-Bottom[Category Page]",
      inputType: "color",
      imgsrc: ProductBottomColor1,
      category: "product",
      value: "#fff",
    },
    {
      id: "NoOfVarietion1",
      label: "No Of Color Variation[Category Page]",
      inputType: "number",
      imgsrc: Variation,
      category: "product",
      value: "2",
    },
    {
      id: "ProductImageSizeCategory1",
      label: "Product Image Size[Category Page]",
      inputType: "number",
      imgsrc: ProductImgSize,
      category: "product",
      value: "80",
    },
    {
      id: "ProductImageSizeDetails1",
      label: "Product Image Size[Product Detail Page]",
      imgsrc: ProductDetailsImgSize,
      inputType: "number",
      category: "product",
      value: "80",
    },
    {
      id: "SliderSizeDesktop1",
      label: "Slider Size Desktop[vh]",
      inputType: "number",
      category: "slider",
      value: "80",
    },
    {
      id: "ProductDesignCategryPage1",
      label: "Product Design[Category Page]",
      inputType: "dropdown",
      category: "product",
      options: [
        {
          name: "ProductCart11",
          imgsrc: ProductCart11,
        },
        {
          name: "ProductCart2",
          imgsrc: "",
        },
        {
          name: "ProductCart5_lc",
          imgsrc: ProductCart5_lc_img,
        },
      ],
      value: "ProductCart5_lc",
    },
    {
      id: "logoSizeDesktop1",
      label: "Logo Size[Desktop]",
      imgsrc: DesktopLogo,
      inputType: "number",
      category: "genral",
      value: "80",
    },
    {
      id: "PrimaryFontFamily1",
      label: "Primary Font Family",
      inputType: "select",
      category: "genral",
      options: [
        "Arial, Helvetica, sans-serif",
        "Georgia, 'Times New Roman', Times, serif",
        "'Courier New', Courier, monospace",
        "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
        "cursive",
        "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      ],
      value: "Arial, Helvetica, sans-serif",
    },
    {
      id: "SecondaryFontFamily1",
      label: "Secondary Font Family",
      inputType: "select",
      category: "genral",
      options: [
        "Arial, Helvetica, sans-serif",
        "Georgia, 'Times New Roman', Times, serif",
        "'Courier New', Courier, monospace",
        "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
        "cursive",
        "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      ],
      value: "Arial, Helvetica, sans-serif",
    },
    {
      id: "TertiaryFontFamily1",
      label: "Tertiary Font Family",
      inputType: "select",
      category: "genral",
      options: [
        "Arial, Helvetica, sans-serif",
        "Georgia, 'Times New Roman', Times, serif",
        "'Courier New', Courier, monospace",
        "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
        "cursive",
        "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      ],
      value: "Arial, Helvetica, sans-serif",
    },
    {
      id: "ThemeColor1",
      label: "Theme Color[like Button etc.]",
      inputType: "color",
      imgsrc: "",
      category: "genral",
      value: "#943409",
    },
    {
      id: "NavbarCategoryTextColor",
      label: "Navbar Category Text Color",
      imgsrc: "",
      inputType: "color",
      category: "navbar",
      value: "#943409",
    },
    {
      id: "Productratingbackground",
      label: "Product Rating Background",
      inputType: "color",
      imgsrc: RatingBack,
      category: "product",
      value: "#943409",
    },
    {
      id: "ProductBackText",
      label: "Product Rating Text",
      inputType: "color",
      imgsrc: RatingBack,
      category: "product",
      value: "#943409",
    },
    {
      id: "ThemeColor22",
      label: "Theme Color 2",
      imgsrc: "",
      inputType: "color",
      category: "genral",
      value: "#8b7373",
    },
    {
      id: "ThemeColor3",
      label: "Theme 3 Color",
      imgsrc: "",
      inputType: "color",
      category: "genral",
      value: "#000042",
    },
    {
      id: "SidebarFontColorMobile1",
      label: "Sidebar(Menu) Font Color[Mobile]",
      imgsrc: SidebarFontColor,
      inputType: "color",
      category: "genral",
      value: "rgb(0, 0, 0)",
    },
    {
      id: "ProductPriceColor",
      label: "Product Card Price Color",
      inputType: "color",
      imgsrc: PriceColorCard,
      category: "product",
      value: "rgb(0, 0, 0)",
    },
    {
      id: "MenuFontColorDesktop1",
      label: "Menu Font Color[Desktop]",
      inputType: "color",
      imgsrc: MenuDesktop,
      category: "genral",
      value: "rgb(0, 0, 0)",
    },
    {
      id: "FooterBackground1",
      label: "Footer Background",
      category: "footer",
      inputType: "color",
      value: "rgb(247, 247, 247)",
    },
    {
      id: "FooterTextColor1",
      label: "Footer Text Color",
      inputType: "color",
      category: "footer",
      value: "black",
    },
    {
      id: "BannerSizeDesktop1",
      label: "Banner Size Desktop(vh)",
      inputType: "number",
      value: "80",
      category: "banner",
    },
    {
      id: "PrimaryTextColor1",
      label: "Primary Text Color",
      inputType: "color",
      imgsrc: "",
      value: "#3b3b3b",
      category: "genral",
    },
    {
      id: "ButtonBorder1",
      label: "Button Border",
      imgsrc: "",
      inputType: "color",
      value: "rgb(230, 230, 230)",
      category: "genral",
    },
    {
      id: "ButtonBackgroundColor1",
      label: "Button Background Color",
      inputType: "color",
      imgsrc: "",
      value: "rgb(230, 230, 230)",
      category: "genral",
    },
    {
      id: "ButtonTextColor1",
      label: "Button Text Color",
      inputType: "color",
      imgsrc: "",
      value: "rgb(230, 230, 230)",
      category: "genral",
    },
    // -----------category box----------------------------
    {
      id: "CategoryBoxText",
      label: "Category Box Text",
      inputType: "color",
      imgsrc: "",
      value: "rgb(230, 230, 230)",
      category: "categorybox",
    },
    {
      id: "CategoryBoxShow",
      label: "Category Box Show",
      inputType: "dropdown",
      options: [
        {
          name: "Show",
          imgsrc: CategoryShow,
        },
        {
          name: "Hide",
          imgsrc: NoCategoryShow,
        },
      ],
      imgsrc: "",
      value: "Show",
      category: "categorybox",
    },
    {
      id: "CategoryBoxImageSize",
      label: "Category Box Image Size[%]",
      inputType: "number",
      imgsrc: CategoryBoxImageSize,
      value: "80",
      category: "categorybox",
    },
    {
      id: "WearTreandBoxType",
      label: "Wear Treand",
      inputType: "dropdown",
      imgsrc: CategoryBoxImageSize,
      value: "Wear Treand 1",
      options: [
        {
          name: "Wear Treand 1",
          imgsrc: WearTrend1,
        },
        {
          name: "Wear Treand 2",
          imgsrc: WearTrend2,
        },
      ],
      category: "genral",
    },
    {
      id: "CategoryBoxImageSize",
      label: "Category Box Image Size[%]",
      inputType: "number",
      imgsrc: CategoryBoxImageSize,
      value: "80",
      category: "categorybox",
    },
    {
      id: "CategoryBoxBackground",
      label: "Category Box BackGround",
      inputType: "color",
      imgsrc: "",
      value: "#fff",
      category: "categorybox",
    },
    {
      id: "CategoryBoxColor",
      label: "Category Box Color",
      inputType: "color",
      imgsrc: "",
      value: "#fff",
      category: "categorybox",
    },
    // ------------------END---------------------
    {
      id: "YoutubeVideoShow",
      label: "Youtube Video Show",
      inputType: "dropdown",
      options: [
        {
          name: "Show",
          imgsrc: YoutubeVideoImg,
        },
        {
          name: "Hide",
          imgsrc: NoYoutubeVideo,
        },
      ],
      imgsrc: "",
      value: "Show",
      category: "genral",
    },
    {
      id: "TopNavbarShow",
      label: "Top Navbar Show",
      inputType: "dropdown",
      options: [
        {
          name: "Show",
          imgsrc: "",
        },
        {
          name: "Hide",
          imgsrc: "",
        },
      ],
      imgsrc: "",
      value: "Show",
      category: "genral",
    },
    {
      id: "SecondaryTextColor1",
      label: "Secondary Text Color",
      inputType: "color",
      imgsrc: "",
      value: "#f8f8f8",
      category: "genral",
    },
    {
      id: "TestimonialShow",
      label: "Testimonial On/Off",
      inputType: "dropdown",
      imgsrc: "",
      options: [
        {
          name: "On",
          imgsrc: "",
        },
        {
          name: "Off",
          imgsrc: "",
        },
      ],
      value: "On",
      category: "genral",
    },
    {
      id: "NoOfProductsInSliderIndexPage1",
      label: "No Of Products In Slider[Index Page]",
      inputType: "number",
      value: "2",
      category: "slider",
    },
    {
      id: "TextColorApplyWithThemeBackground1",
      label: "Text Color[Apply With Theme Background]",
      inputType: "color",
      imgsrc: "",
      value: "#ffff",
      category: "genral",
    },
    {
      id: "Logo Size[Mobile]",
      label: "Mobile Icon Size[px]",
      imgsrc: SidebarIconSize,
      inputType: "number",
      value: "24",
      category: "genral",
    },
    {
      id: "SidebarIconColorMobile1",
      label: "Sidebar Icon Color[Mobile]",
      inputType: "color",
      imgsrc: "",
      value: "black",
      category: "genral",
    },
    {
      id: "WebsiteThemeBackground1",
      label: "Website Theme Background",
      imgsrc: "",
      inputType: "color",
      value: "#fff",
      category: "genral",
    },
    {
      id: "ProductViewBackground1",
      label: "Product View Background",
      inputType: "color",
      imgsrc: "",
      value: "#fff",
      category: "product",
    },
    {
      id: "ProductDetailsImgBoxWidth",
      label: "Product Details Img Box Width[%]",
      inputType: "number",
      imgsrc: "",
      value: "80",
      category: "product",
    },
    {
      id: "ProductDetailsImgBoxHeight",
      label: "Product Details Img Box Height[px]",
      inputType: "number",
      imgsrc: "",
      value: "300",
      category: "product",
    },
    {
      id: "Productbottomcolor2",
      label: "Product View Bottom Color2",
      inputType: "color",
      imgsrc: ProductBottomColor2,
      value: "#fff",
      category: "product",
    },
    {
      id: "SliderTypeSelectIndexPage1",
      label: "Slider Type Select[Index Page]",
      inputType: "dropdown",
      options: [
        {
          name: "ProductSliderImages_Lc2",
          imgsrc: Slider2,
        },
        {
          name: "ProductSliderImages_Lc",
          imgsrc: Slider1,
        },
      ],
      category: "slider",
      value: "ProductSliderImages_Lc",
    },
    {
      id: "GoldMembershipShow",
      label: "Gold MemberShip Button",
      inputType: "dropdown",
      options: [
        {
          name: "Show",
          imgsrc: "",
        },
        {
          name: "Hide",
          imgsrc: "",
        },
      ],
      category: "genral",
      value: "Show",
    },
    {
      id: "WishlistTypeSelect1",
      label: "Wishlist Type Select",
      inputType: "dropdown",
      category: "genral",
      options: [],
      value: "",
    },
    {
      id: "MyCartPageDesignSelect1",
      label: "My Cart Page Design Select",
      inputType: "dropdown",
      category: "genral",
      options: [
        {
          name: "/Cart3",
          imgsrc: Cart3,
        },
        {
          name: "/Cart",
          imgsrc: Cart,
        },
        {
          name: "/Cart2",
          imgsrc: "",
        },
      ],
      value: "/Cart3",
    },
    {
      id: "NavbarBackgroundColor1",
      label: "Navbar Background Color",
      imgsrc: "",
      inputType: "color",
      category: "navbar",
      value: "#fff",
    },
    {
      id: "TopNavbarNavigation",
      label: "Top Navbar Navigation",
      imgsrc: "",
      options: [
        {
          name: "Contact Us",
          status: true,
        },
        {
          name: "Store Login",
          status: false,
        },
        {
          name: "Store Locator",
          status: true,
        },
        {
          name: "L2C Blogs",
          status: false,
        },
        {
          name: "Partners With Us",
          status: true,
        },
      ],
      inputType: "checkbox",
      category: "navbar",
    },
    {
      id: "NavbarTopTextColor",
      label: "Navbar Top Text Color",
      inputType: "color",
      imgsrc: "",
      category: "navbar",
      value: "#fff",
    },
    {
      id: "ProductViewBottomTextCategoryPage1",
      label: "Product View Bottom Text[Category Page]",
      inputType: "color",
      imgsrc: "",
      category: "product",
      value: "#fff",
    },
    {
      id: "IconColorDesktop1",
      label: "Icon Color[Desktop]",
      inputType: "color",
      imgsrc: "",
      category: "genral",
      value: "#000",
    },
    {
      id: "",
      label: "Icon Color[Desktop]",
      inputType: "color",
      imgsrc: "",
      category: "genral",
      value: "#000",
    },
    {
      id: "NavbarColorTop1",
      label: "Navbar Color Top",
      inputType: "color",
      imgsrc: "",
      category: "navbar",
      value: "#000",
    },
    {
      id: "ProductViewBorder1",
      label: "Product View Border",
      inputType: "color",
      imgsrc: "",
      category: "product",
      value: "#000",
    },
  ]);
  const [SortArray, setSortArray] = useState([]);
  // Handle input change for all types of inputs
  const handleInputChange = (event, input, currentitem) => {
    debugger;
    if (input?.inputType == "checkbox") {
      const Tempdata = SortArray.map((item) => {
        if (item.id == input.id) {
          const tempoption = item.options.find(
            (optionname) => optionname.name == currentitem.name
          );
          const otheroption = item.options.filter(
            (fitem) => fitem.name != tempoption.name
          );
          return {
            ...item,
            options: [
              ...otheroption,
              {
                ...tempoption,
                status: !tempoption.status,
              },
            ],
          };
        } else {
          return item;
        }
      });
      setSortArray(Tempdata);
      return;
    }

    // const finditem = inputbox.findIndex(item=> item.label==event.target.name);
    const Tempdata = SortArray.map((item) => {
      if (item.label == event.target.name) {
        return {
          ...item,
          value: event.target.value,
        };
      } else {
        return item;
      }
    });
    setSortArray(Tempdata);
  };

  const handleModalOpen = () => {
    debugger;
    setModalForThemeAdd(!ModalForThemeAdd);
  };

  const SaveButton = async () => {
    debugger;
    console.log("SortArray", SortArray);
    const jsonconvert = JSON.stringify(SortArray, "", 2);
    var a1 = encodeURIComponent(jsonconvert);
    const data = {
      companyName: "string",
      themeVariable: a1,
    };
    let result;
    if (UpdateOrSave == "Save") {
      result = await ThemeMasterAPI.CreateThemeMaster(data);
    } else {
      result = await ThemeMasterAPI.UpdateThemeMaster(data);
    }
    console.log(result);
    if (result.isSuccess) {
      if (UpdateOrSave == "Save") {
        alert(`Theme Add Successfully!`);
        setUpdateOrSave("Update");
        setShowReste("Show");
      } else {
        alert(`Theme Update Successfully!`);
      }
      getthemecolor();
    } else {
      if (UpdateOrSave == "Save") {
        alert(`Theme Add Failure!`);
      } else {
        alert(`Theme Update faild!`);
      }
    }
  };

  const UpdateHandler = async () => {
    debugger;
    console.log("SortArray", SortArray);
    const jsonconvert = JSON.stringify(SortArray, "", 2);
    var a1 = encodeURIComponent(jsonconvert);
    const data = {
      companyName: "string",
      themeVariable: a1,
    };
    let result = await ThemeMasterAPI.UpdateThemeMaster(data);

    console.log(result);

    if (result.isSuccess) {
      alert(`Theme Update Success`);
      getthemecolor();
    } else {
      alert(`Theme Update faild!`);
    }
  };

  const getthemecolor = async () => {
    debugger;
    const result = await ThemeMasterAPI.GetThemeMaster();
    console.log(`result`, result);
    if (result != null) {
      var a1 = decodeURIComponent(result.themeVariable);
      const arraydata = JSON.parse(a1);
      const allids = arraydata.map((item) => item.id);
      const newdata = inputbox.filter((item) => {
        if (!allids.includes(item.id)) {
          return item;
        }
      });
      console.log(`newdata`, newdata);
      const tempdata = arraydata?.map((item) => {
        const currentlabel = inputbox.find((fitem) => fitem.id == item.id);
        if (item.inputType == "select" || item.inputType == "dropdown") {
          const findfromreact = inputbox.find(
            (fitem) => fitem.label == item.label
          );
          return {
            id: item.id,
            label: item?.label,
            inputType: item.inputType,
            imgsrc: item?.imgsrc,
            value: item.value != "" ? item.value : findfromreact.value,
            category: item.category,
            options: item.options,
          };
        }
        {
          return {
            ...item,
            imgsrc: currentlabel?.imgsrc,
            label: currentlabel?.label,
          };
        }
      });
      setInputbox([...tempdata, ...newdata]);
      setUpdateOrSave("Update");
      setShowReste("Show");
    }
  };

  useEffect(() => {
    console.log(`ShowReset`, ShowReset);
  }, [ShowReset]);

  useEffect(() => {
    console.log(`SortArray`, SortArray);
  }, [SortArray]);

  const SortArrayFunction = () => {
    debugger;
    try {
      const colorComponent = inputbox.filter(
        (item) => item.inputType == "color"
      );
      const textcomponent = inputbox.filter((item) => item.inputType == "text");
      const Dropdown = inputbox.filter((item) => item.inputType == "dropdown");
      const Checkbox = inputbox.filter((item) => item.inputType == "checkbox");
      const Select = inputbox.filter((item) => item.inputType == "select");
      const Number = inputbox.filter((item) => item.inputType == "number");

      const Sortedarry = [
        ...textcomponent,
        ...Number,
        ...Select,
        ...Checkbox,
        ...Dropdown,
        ...colorComponent,
      ];

      setSortArray(Sortedarry);
    } catch (error) {
      console.log(error);
    }
  };

  const ResetSettings = async () => {
    try {
      debugger;
      const confirm = window.confirm(`Are you sure you want to reset?`);
      if (!confirm) return;

      console.log("Resetting settings");
      const result = await ThemeMasterAPI.DeleteThemeMaster(332);
      if (result.isSuccess) {
        alert("Theme Settings Reset Successfully!");
        setShowReste("hide");
        setInputbox(inputbox);
        SortArrayFunction();
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("UpdateOrSave changed to:", UpdateOrSave);
  }, [UpdateOrSave]);

  useEffect(() => {
    console.log("inputbox changed to:", UpdateOrSave);
    SortArrayFunction();
  }, [inputbox]);

  useEffect(() => {
    getthemecolor();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#212e4f",
        }}
      >
        <div className="panel-head-title">
          <h2
            className="panel-title"
            style={{ color: "white", fontWeight: "600" }}
          >
            ThemeMaster
          </h2>
        </div>
        <MainButton
          ShowReset={ShowReset == "Show" ? true : false}
          ResetSettings={ResetSettings}
          SaveButton={UpdateOrSave == "Save" ? SaveButton : UpdateHandler}
        />
      </div>
      {/* <div className={`all-input`}>
       
      </div> */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Genral Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "genral").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="number"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{ boxShadow: input.imgsrc == "" && "none" }}
                      className={`theme-product-card-img   ${
                        input.label == "Product Details Page"
                          ? "theme-dropdown-details-page"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input `}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{
                                display: item.imgsrc == "" ? "none" : "",
                              }}
                              className={`theme-product-card-img ${
                                input.id == "MyCartPageDesignSelect1" &&
                                "img-show-li"
                              } ${
                                input.label == "Product Details Page"
                                  ? "theme-dropdown-details-page"
                                  : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="color"
                      className={`modify_im_input mode_category input-theme-master input-theme-color`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{ display: input.imgsrc == "" && "none" }}
                      className={`theme-product-card-img ${
                        input.id == "SidebarFontColorMobile1" &&
                        "Product-rating-text-left"
                      } ${
                        input.label == "Product Rating Text"
                          ? "Product-rating-text-left"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------categorybox---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Category Box
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "categorybox").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="number"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{ boxShadow: input.imgsrc == "" && "none" }}
                      className={`theme-product-card-img   ${
                        input.label == "Product Details Page"
                          ? "theme-dropdown-details-page"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input `}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ boxShadow: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img ${
                                input.id == "MyCartPageDesignSelect1" &&
                                "img-show-li"
                              } ${
                                input.label == "Product Details Page"
                                  ? "theme-dropdown-details-page"
                                  : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="color"
                      className={`modify_im_input mode_category input-theme-master input-theme-color`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{ display: input.imgsrc == "" && "none" }}
                      className={`theme-product-card-img ${
                        input.id == "SidebarFontColorMobile1" &&
                        "Product-rating-text-left"
                      } ${
                        input.label == "Product Rating Text"
                          ? "Product-rating-text-left"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------navbar---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Navbar Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "navbar").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <input
                    type="number"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input`}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ display: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img   ${
                                input.label == "Product Details Page"
                                  ? "theme-dropdown-details-page"
                                  : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType == "checkbox" && (
                  <div className={`theme-master-dropdown-input`}>
                    <div className={`checkbox-div`}>
                      {input.options.map((item) => {
                        return (
                          <span
                            className={`${item.status ? "active" : ""}`}
                            onClick={(e) => handleInputChange(e, input, item)}
                          >
                            {item.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="color"
                      className={`modify_im_input mode_category input-theme-master input-theme-color`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{ display: input.imgsrc == "" ? "none" : null }}
                      className={`theme-product-card-img   ${
                        input.label == "Product Rating Text"
                          ? "Product-rating-text-left"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------product---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Product Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "product").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="number"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{
                        display: input.imgsrc == "" ? "none" : null,
                        right: input.id == "NoOfVarietion1" ? "-27px" : null,
                        top: "0px",
                      }}
                      className={`theme-product-card-img   ${
                        input.label == "Product Details Page"
                          ? "theme-dropdown-details-page"
                          : null
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input`}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ boxShadow: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img   ${
                                input.id == "ProductDetailsPage1" ? "" : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <div
                    className={`theme-master-dropdown-input number-input-hover`}
                  >
                    <input
                      type="color"
                      className={`modify_im_input mode_category input-theme-master input-theme-color`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />
                    <span
                      style={{
                        display: input.imgsrc == "" && "none",
                        right:
                          input.id == "Productratingbackground"
                            ? "-27px"
                            : null,
                        top: "0px",
                      }}
                      className={`theme-product-card-img   ${
                        input.id == "ProductBackText"
                          ? null
                          : "Product-rating-text-left"
                      }`}
                    >
                      <img
                        className={`theme-master-dropdown-img`}
                        src={input.imgsrc}
                      />
                    </span>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------product---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Slider Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "slider").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <input
                    type="number"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input`}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ boxShadow: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img   
                              ${
                                input.id == "MyCartPageDesignSelect1"
                                  ? "theme-dropdown-details-page"
                                  : input.id == "ProductDetailsPage1"
                                  ? "theme-dropdown-details-page"
                                  : input.id == "SliderTypeSelectIndexPage1"
                                  ? "slider-type-img"
                                  : null
                              }
                              `}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <input
                    type="color"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------banner---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Banner Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "banner").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <input
                    type="number"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input`}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ boxShadow: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img   ${
                                input.label == "Product Details Page"
                                  ? "theme-dropdown-details-page"
                                  : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <input
                    type="color"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* ------------------banner---------------- */}
      <div className={`theme-title-and-theme`}>
        <div class="company_info-text-im depart_top theme-master-theme-header">
          Footer Theme
        </div>
        <div className="HeadContentItemgroup theme-master-main-div">
          {SortArray.filter((item) => item.category == "footer").map(
            (input, index) => (
              <div className={`input-box-m11 w-25`} key={index}>
                <label
                  className={`ItemMasterLabelFix label-color 
                `}
                >
                  {input.label}
                </label>
                {input.inputType === "number" ? (
                  <input
                    type="number"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                ) : input?.inputType === "select" ? (
                  <select
                    className="modify_im_input mode_category input-theme-master"
                    type="text"
                    onChange={(e) => handleInputChange(e)}
                    required
                    value={input.value}
                    name={input.label}
                  >
                    {input.options?.map((option, idx) => {
                      if (input.label.includes("Font")) {
                        return (
                          <option
                            style={{ fontFamily: option }}
                            key={idx}
                            value={option}
                          >
                            {option}
                          </option>
                        );
                      }
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                ) : null}

                {input.inputType == "dropdown" && (
                  <div
                    onMouseEnter={() => setShowDropdown(input.label)}
                    onMouseLeave={() => setShowDropdown("")}
                    className={`theme-master-dropdown-input`}
                  >
                    <input
                      type="text"
                      className={`modify_im_input mode_category input-theme-master`}
                      value={input.value}
                      name={input.label}
                      onChange={(e) => handleInputChange(e)}
                    />

                    <ul
                      style={{
                        display: ShowDropdown == input.label ? "block" : "none",
                      }}
                    >
                      {input.options.map((item, index) => {
                        return (
                          <li
                            onClick={() => {
                              debugger;
                              input.value = item.name;
                            }}
                            // key={index}
                          >
                            <span>{item.name}</span>
                            <span
                              style={{ boxShadow: item.imgsrc == "" && "none" }}
                              className={`theme-product-card-img   ${
                                input.label == "Product Details Page"
                                  ? ""
                                  : null
                              }`}
                            >
                              <img
                                className={`theme-master-dropdown-img`}
                                src={item.imgsrc}
                              />
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {input.inputType === "text" && (
                  <input
                    type="text"
                    className={`modify_im_input mode_category input-theme-master`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType == "image" && (
                  <input
                    type="file"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}

                {input.inputType === "color" && (
                  <input
                    type="color"
                    className={`modify_im_input mode_category input-theme-master input-theme-color`}
                    value={input.value}
                    name={input.label}
                    onChange={(e) => handleInputChange(e)}
                  />
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* <div className={`div-for-key-value btn-add-theme`}>
        <button onClick={handleModalOpen} className={`bt-add-theme-master`}>
          Add
        </button>
      </div> */}

      {/* {ModalForThemeAdd && (
        <AddFieldModal
          close={handleModalOpen}
          inputbox={inputbox}
          setinputbox={setInputbox}
        />
      )} */}
    </div>
  );
};

export default ThemeMaster;
