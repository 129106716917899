import React, { useContext, useEffect, useState } from "react";
import VoucherConfigurationAndOtherSettingController from "../../../API/APIServices/VoucherConfigurationAndOtherSettingController";
import Company from "../../../API/APIServices/Company";
import MyContext from "../../MyContext";

const PopupAndSearchCondition = () => {
  const [AllSettings, setAllSettings] = useState({
    RoundoffOnGrossValue: false,
    LensPowerValidationInTransaction: false,
    SendEMailToShopOwner: false,
    SendSMSToShopOwner: false,
    HideStockOutPriceForUser: false,
    EnableBrokerageSetting: false,
    RestrictSaleInvoiceDueAmtWithAdvPayment: false,
    GetSalePriceByLensPwrInRetailSale: false,
    DiscountWithRemarksIsNecessary: false,
    DoubleTaxForItemInTransaction: false,
    ChangeProductNameWhileEnterOnly1Attribute: false,
    IncludeChallanVoucherInLedger: false,
    DownloadPdfAfterSaveInvoice: false,
    EnableEcommerceSetting: false,
    SeparateAccountInTransaction: false,
    IncludeLensStockForRetailSale: false,
    CreateNewItemInTransactionRowChange: false,
    FirstCreateOrderThenConvertInSaleVoucher: false,
    FreezeSalePageOnConvertOrder: false,
    ShowRxOrderPrice: false,
    HideRxVendorOrder: false,
    ShowBussinessFigur: false,
    ShowBussinessFigurToUser: false,
    ShowLatestInvoiceTable: false,
    ShowBirthdayAnniversaryPopup: false,
    ShowLensReorderPopup: false,
    NegativeStockBillingRestriction: false,
    NegativeStockAlert: false,
    ShowOpticalDeliveryNotification: false,
    PromptUserForBackupWhenLogout: false,
    ShowWorkSummaryOnLogout: false,
    EnableOTPVarificationForDiscount: false,
    ShowVendorRxOrderNotification: false,
    ShowCostPriceAndLandingPrice: false,
    ShowMainProductOnlyInItemSuggestion: false,
    IsLensInfoShow: false,
    CashOnDeliveryAvaliable: false,

  });

  const CreateAndUpdateSetting = async (e) => {
    var { name, checked } = e.target;
    var DataForCreatingAndUpdatingsetting = [
      {
        masterSettName: name,
        masterSettInfo: "string",
        enableDisable: checked,
      },
    ];

    var Data =
      await VoucherConfigurationAndOtherSettingController.CreateVoucherConfigurationAndOtherSettingController(
        DataForCreatingAndUpdatingsetting
      );
    if (Data.isSuccess == true) {
      getAllSettings();
    } else {
      alert("Error while creating and updating the data");
    }
  };

  const getAllSettings = async () => {
    try {
      const Data =
        await VoucherConfigurationAndOtherSettingController.GetVoucherConfigurationAndOtherSettingController();
      if (Data.isSuccess === true) {
        // Initialize a new state object that starts as a copy of AllSettings
        let updatedSettings = { ...AllSettings };

        // Loop through the result array
        Data.result.forEach((setting) => {
          // Check if the setting's masterSettName exists as a key in the AllSettings object
          if (updatedSettings.hasOwnProperty(setting.masterSettName)) {
            // Update the corresponding key with the value of enableDisable
            updatedSettings[setting.masterSettName] = setting.enableDisable;
          }
        });

        // Set the updated state
        setAllSettings(updatedSettings);
      } else {
        alert("Error while creating and updating the data");
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      alert("An error occurred while fetching the settings.");
    }
  };

  useEffect(() => {
    getAllSettings();
    GetLoginThroughBranchCodeSetting();
  }, []);

  const [LoginThroughCompanyCode, setLoginThroughCompanyCode] = useState(false);

  useEffect(() => {
    console.log(LoginThroughCompanyCode);
  }, [LoginThroughCompanyCode]);

  function stringToBoolean(str) {
    return str.trim().toLowerCase() === "true";
  }

  const LoginThroughBranchCode = async (e) => {
    var { checked } = e.target;
    var loginThroughBC = await Company.CreateLoginThroughBranchCodeSetting(
      checked
    );
    debugger;
    if (loginThroughBC?.isSuccess) {
      setLoginThroughCompanyCode(
        typeof loginThroughBC.result === "boolean"
          ? loginThroughBC.result
          : stringToBoolean(loginThroughBC.result)
      );
    }
  };

  useEffect(() => {}, []);

  const GetLoginThroughBranchCodeSetting = async () => {
    var data = await Company.GetLoginThroughBranchCodeSetting();
    if (data.isSuccess == true) {
      setLoginThroughCompanyCode(stringToBoolean(data.result));
    }
  };

  return (
    <div className="PopupAndSearchCondition-box">
      <div className="PopupAndSearchCondition-box-1">
        <p> Voucher Configuration</p>
        <div className="PopupAndSearchCondition-box-2">
          <div>
            <input
              type="checkbox"
              name="RoundoffOnGrossValue"
              checked={AllSettings.RoundoffOnGrossValue}
              id=""
              onChange={(e) => CreateAndUpdateSetting(e)}
            />
            <p>Round off On Gross Value</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="LensPowerValidationInTransaction"
              checked={AllSettings.LensPowerValidationInTransaction}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Lens Power Validation In Transaction`</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="SendEMailToShopOwner"
              checked={AllSettings.SendEMailToShopOwner}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p> Send E-Mail To Shop Owner ` </p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="SendSMSToShopOwner"
              checked={AllSettings.SendSMSToShopOwner}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p> Send SMS To Shop Owner `</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="HideStockOutPriceForUser"
              checked={AllSettings.HideStockOutPriceForUser}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p> Hide Stock Out Price For User</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="EnableBrokerageSetting"
              checked={AllSettings.EnableBrokerageSetting}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Enable Brokerage Setting</p>
          </div>{" "}
          <div>
            <div>
              <input
                type="radio"
                name="SendSMSToShopOwner"
                // checked={AllSettings.SendSMSToShopOwner}
                onChange={(e) => CreateAndUpdateSetting(e)}
                id=""
              />
              <label htmlFor="">Voucher Wise</label>
            </div>
            <div>
              <input
                type="radio"
                name="SendSMSToShopOwner"
                // checked={AllSettings.SendSMSToShopOwner}
                onChange={(e) => CreateAndUpdateSetting(e)}
                id=""
              />
              <label htmlFor="">Item Wise</label>
            </div>
          </div>
          <div>
            <input
              type="checkbox"
              name="RestrictSaleInvoiceDueAmtWithAdvPayment"
              checked={AllSettings.RestrictSaleInvoiceDueAmtWithAdvPayment}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Restrict Sale Invoice Due Amt With Adv Payment</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="GetSalePriceByLensPwrInRetailSale"
              checked={AllSettings.GetSalePriceByLensPwrInRetailSale}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Get Sale Price By Lens Pwr In Retail Sale</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="DiscountWithRemarksIsNecessary"
              checked={AllSettings.DiscountWithRemarksIsNecessary}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Discount With Remarks Is Necessary</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="DoubleTaxForItemInTransaction"
              checked={AllSettings.DoubleTaxForItemInTransaction}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Double Tax For Item In Transaction</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowExtraChargesOnTransactions"
              checked={AllSettings.ShowExtraChargesOnTransactions}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Extra Charges On Transactions</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowMainProductOnlyInItemSuggestion"
              checked={AllSettings.ShowMainProductOnlyInItemSuggestion}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Main Product Only In Item Suggestion `</p>
          </div>
        </div>
      </div>

      <div className="PopupAndSearchCondition-box-1">
        <p>Other Features</p>
        <div className="PopupAndSearchCondition-box-2">
          <div>
            <input
              type="checkbox"
              name="LoginThroughCompanyCode"
              checked={LoginThroughCompanyCode}
              onChange={(e) => LoginThroughBranchCode(e)}
              id=""
            />
            <p>Login Through Company Code `</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ChangeProductNameWhileEnterOnly1Attribute"
              checked={AllSettings.ChangeProductNameWhileEnterOnly1Attribute}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Change Product Name While Enter Only 1 Attribute</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="IncludeChallanVoucherInLedger"
              checked={AllSettings.IncludeChallanVoucherInLedger}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Include Challan Voucher In Ledger</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="DownloadPdfAfterSaveInvoice"
              checked={AllSettings.DownloadPdfAfterSaveInvoice}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p> Download Pdf After Save Invoice</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="EnableEcommerceSetting"
              checked={AllSettings.EnableEcommerceSetting}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p> EnableE-commerce Setting</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="SeparateAccountInTransaction"
              checked={AllSettings.SeparateAccountInTransaction}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Separate Account In Transaction `</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="IncludeLensStockForRetailSale"
              checked={AllSettings.IncludeLensStockForRetailSale}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Include Lens Stock For Retail Sale</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="CreateNewItemInTransactionRowChange"
              checked={AllSettings.CreateNewItemInTransactionRowChange}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Create New Item In Transaction Row Change</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="FirstCreateOrderThenConvertInSaleVoucher"
              checked={AllSettings.FirstCreateOrderThenConvertInSaleVoucher}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>First Create Order Then Convert In Sale Voucher</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="FreezeSalePageOnConvertOrder"
              checked={AllSettings.FreezeSalePageOnConvertOrder}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Freeze Sale Page On Convert Order</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowRxOrderPrice"
              checked={AllSettings.ShowRxOrderPrice}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show RxOrder Price</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="HideRxVendorOrder"
              checked={AllSettings.HideRxVendorOrder}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Hide RxVendor Order</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowCostPriceAndLandingPrice"
              checked={AllSettings.ShowCostPriceAndLandingPrice}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Cost Price And Landing Price `</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="IsLensInfoShow"
              checked={AllSettings.IsLensInfoShow}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Is Lens Info Show</p>
          </div>
        </div>
      </div>
      <div className="PopupAndSearchCondition-box-1">
        <p>Popup Setting</p>
        <div className="PopupAndSearchCondition-box-2">
          <div>
            <input
              type="checkbox"
              name="ShowBussinessFigur"
              checked={AllSettings.ShowBussinessFigur}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Bussiness Figur</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowBussinessFigurToUser"
              checked={AllSettings.ShowBussinessFigurToUser}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Bussiness Figur To User</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="ShowLatestInvoiceTable"
              checked={AllSettings.ShowLatestInvoiceTable}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Latest Invoice Table</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="ShowBirthdayAnniversaryPopup"
              checked={AllSettings.ShowBirthdayAnniversaryPopup}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Birthday Anniversary Popup</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="ShowLensReorderPopup"
              checked={AllSettings.ShowLensReorderPopup}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Lens Reorder Popup</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="NegativeStockBillingRestriction"
              checked={AllSettings.NegativeStockBillingRestriction}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Negative Stock Billing Restriction</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="NegativeStockAlert"
              checked={AllSettings.NegativeStockAlert}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Negative Stock Alert</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="ShowOpticalDeliveryNotification"
              checked={AllSettings.ShowOpticalDeliveryNotification}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Optical Delivery Notification</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="PromptUserForBackupWhenLogout"
              checked={AllSettings.PromptUserForBackupWhenLogout}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Prompt User For Backup When Logout</p>
          </div>{" "}
          <div>
            <input
              type="checkbox"
              name="ShowWorkSummaryOnLogout"
              checked={AllSettings.ShowWorkSummaryOnLogout}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Work Summary On Logout</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="EnableOTPVarificationForDiscount"
              checked={AllSettings.EnableOTPVarificationForDiscount}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Enable OTP Varification For Discount</p>
          </div>
          <div>
            <input
              type="checkbox"
              name="ShowVendorRxOrderNotification"
              checked={AllSettings.ShowVendorRxOrderNotification}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Show Vendor RxOrder otification</p>
          </div>

          <div>
            <input
              type="checkbox"
              name="CashOnDeliveryAvaliable"
              checked={AllSettings.CashOnDeliveryAvaliable}
              onChange={(e) => CreateAndUpdateSetting(e)}
              id=""
            />
            <p>Cash On Delivery Avaliable</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupAndSearchCondition;
