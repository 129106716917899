import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState, useEffect, useContext } from "react";
import XLS from "../Userdetails/XLS";
import TableButton from "./../../Props/TableButton"
import MyContext from "../../MyContext";
import AlertBox from "../../Props/AlertBox";
import Loader from "../../Props/Loader";
import AccountApi from "../../../API/APIServices/Account";
import DataTable from "../TableMaster/DataTable";
import AccountMaster from './AccountMaster';
import { Link, useNavigate } from 'react-router-dom';

function AccountMasterList() {
  const navigate = useNavigate();

  const {
    OpenAlertBoxDelete,
    alertBoxDelete,
    setAlertBoxDelete,
    input1,
    input2,
    input3,
    input4,
    input5,
    UserDetails,
    maskMobileNo
  } = useContext(MyContext);

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("")
  //**********xls modal box*****************//
  const [isModalOpen, setModalOpen] = useState(false);
  const hideModal = () => {
    setModalOpen(false);
  };

  const [data1, setData1] = useState([]);

  // const [filters, setFilters] = useState({
  //   name: "",
  //   mobileNo: "",
  //   email: "",
  //   d1Id: "",
  //   defaultSalePersonName: ""
  // });

  const searchFunction = async (e) => {
    setSearchValue(e.target.value);

    // const { name, value } = e.target;
    // // Update the filter state
    // setFilters({
    //   ...filters,
    //   [name]: value
    // });


    // const newFilteredData = Object.keys(filters).map(key => ({
    //   fieldName: key,
    //   operatorName: "contains",
    //   compareValue: filters[key] || ""
    // }));


    // console.log(newFilteredData);

    const filteredData = [
      {
        fieldName: e.target.name,
        operatorName: "contains",
        compareValue: e.target.value || "",
      },
    ];
    console.log(filteredData)
    const SearchItem = await AccountApi.getAccountWithFilter(filteredData)
    console.log(SearchItem)
    const newData = SearchItem.map((item, i) => {
      return {
        id: item.d1Id,
        input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
        sno: i + 1,
        name: item.name,
        mobileNo: UserDetails.canLst3DgtNo ? maskMobileNo(item.mobileNo) : item.mobileNo,
        email: item.email,
        d1Id: item.d1Id,
        defaultSalePersonName: item.defaultSalePersonName === null ? "-------" : item.defaultSalePersonName,
        action: (
          <div className="tablelist-action-box">
            <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => EditAccountClick(item.d1Id)}></i>
            {/* {UserDetails?.canModifyMaster && (
                <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteAccount(item.d1Id, item.d2Id)}></i>
              )} */}
            <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteAccount(item.d1Id, item.d2Id)}></i>
            <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.d1Id)}></i>
            <i className="fa-solid fa-print TablePrint"></i>
          </div>
        ),
      };
    });
    if (newData) {
      setData1(newData);
    }
  }

  const columns = [
    { Header: <input type="checkbox" />, accessor: "input" },
    { Header: "SNO", accessor: "sno" },
    { Header: "NAME", accessor: "name" },
    { Header: "MOBILE NO", accessor: "mobileNo" },
    { Header: "EMAIL", accessor: "email" },
    { Header: "D1Id", accessor: "d1Id" },
    { Header: "DEFAULT SALE PERSON", accessor: "defaultSalePersonName" },
    { Header: "ACTION", accessor: "action" },
  ];

  const filterData = [
    { Header: "", accessor: "" },
    { Header: "", accessor: "" },
    { Header: <input type="text" placeholder="Name" ref={input1} name="name" onChange={searchFunction} />, accessor: "Name" },
    { Header: <input type="text" placeholder="Mobile No" ref={input2} name="mobileNo" onChange={searchFunction} />, accessor: "MobileNo" },
    { Header: <input type="text" placeholder="Email" ref={input3} name="email" onChange={searchFunction} />, accessor: "email" },
    { Header: <input type="text" placeholder="D1Id" ref={input4} name="d1Id" onChange={searchFunction} />, accessor: "d1Id" },
    { Header: <input type="text" placeholder="Default Sale Person" ref={input5} name="defaultSalePersonName" onChange={searchFunction} />, accessor: "DefaultSalePersonName" },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const val = await AccountApi.getAccountFilter();
      console.log(val);

      const newData = val.map((item, i) => {

        return {
          id: item.d1Id,
          input: <input type="checkbox" onChange={() => handleCheckboxChange(item.id)} />,
          sno: i + 1,
          name: item.name,
          mobileNo: UserDetails.canLst3DgtNo ? maskMobileNo(item.mobileNo) : item.mobileNo,
          email: item.email,
          d1Id: item.d1Id,
          defaultSalePersonName: item.defaultSalePersonName === null ? "-------" : item.defaultSalePersonName,
          action: (
            <div className="tablelist-action-box">
              <i className="fa-regular fa-pen-to-square TableEditIcon" onClick={() => EditAccountClick(item.d1Id)}></i>
              <i className="fa-regular fa-trash-can TableDelIcon" onClick={() => DeleteAccount(item.d1Id, item.d2Id)}></i>
              <i className="fa-brands fa-square-whatsapp TableWhatsappcon" onClick={() => handleShare(item.d1Id)}></i>
              <i className="fa-solid fa-print TablePrint"></i>
            </div>
          ),
        };
      });
      setData1(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
  }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [editData, setEditData] = useState([]);

  const EditAccountClick = async(id) => {
    const data = await AccountApi.getAccountById(id);
    try {
      console.log(data.item1);
      setEditData(data.item1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    toggleSlider();
  }

  const [isSliderOpen, setSliderOpen] = useState(false);

  const toggleSlider = () => {
    console.log(editData);
    setSliderOpen(!isSliderOpen);
  };

  const [getdeleteId, setDeleteId] = useState(null);
  const [getdeleteId1, setDeleteId1] = useState(null);

  const DeleteAccount = (id, d2Id) => {
    setAlertBoxDelete(true);
    setDeleteId(id);
    setDeleteId1(d2Id)
  }
  const DeleteAccountConfirm = async () => {
    setLoading(true);
    try {
      const val = await AccountApi.deleteAccount(getdeleteId, getdeleteId1);
      if (Boolean(val) === true) {
        setAlertBoxDelete(false);
        setDeleteId(null);
        setLoading(false);
      } else {
        alert("Error Deleting Data");
      }
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCheckboxChange = (id) => {
    const selectedItem = data1.find(item => item.id === id);

    if (selectedItem) {
      setSelectedData(prevSelectedData => {
        const isSelected = prevSelectedData.some(item => item.id === id);

        if (isSelected) {
          return prevSelectedData.filter(item => item.id !== id);
        } else {
          return [...prevSelectedData, selectedItem];
        }
      });
    }
    console.log(selectedData);
    console.log(data1);
  };

  const handleShare = (id) => {
    const listToSend = data1.find(item => item.id === id);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(listToSend)}`;
    window.location.href = whatsappURL;
  };

  return (
    <div className="">
      < PageTabs locationTab={"/AccountMaster"} modelOpen={true} setSliderCallBack={setSliderOpen} />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Account Master List</h2>
          <TableButton PageCreate={"/AccountMaster"} filterData={filterData} columns={columns} data={data1} selectedData={selectedData} PageName="Masters" />
        </div>
        <DataTable filterData={filterData} columns={columns} data={data1} />
      </div>
      {/* *************************** Mobile View ******************************** */}
      <div className="wrapper">
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListSaveButton />
            </div>
          </div>
        </div>
      </div>

      {/******************************* Account Master Modal*************************/}
      <div className={`slider-container100 ${isSliderOpen ? 'open' : ''}`}>
        <div style={{ padding: "15px" }}>
          <AccountMaster editData={editData} modelOpen={true} setSliderCallBack={setSliderOpen} fetchData={fetchData} />
        </div>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
          onYesClick={DeleteAccountConfirm}
        />
      )}
      {loading && <Loader />}
    </div>
  );
}

export default AccountMasterList;
