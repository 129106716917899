import ListSaveButton from "../../Components/ListSaveButton";
import PageTabs from "../../Components/PageTabs";
import React, { useState } from "react";
import XLS from "../../Master/Userdetails/XLS";
import TableButton from "../../Props/TableButton";
import WorkshopModalLens from "../../WorkshopOrder/WorkshopModalLens";

function StockOutList() {
  //**********xls modal box*****************//
  const [isModalOpen, setModalOpen] = useState(false);

  const hideModal = () => {
    setModalOpen(false);
  };

  //**********workshop modal box*****************//
  const [WorkshopModal, setWorkshopModal] = useState(false);

  const openWorkshopModal = () => {
    setWorkshopModal(true);
  };

  const closeWorkshopModal = () => {
    setWorkshopModal(false);
  };

  const [LabelField] = useState([
    {
      id: 1,
      name: "Customer Card",
      type: "text",
      idName: "CustomerCard",
    },
    {
      id: 2,
      name: "DIA",
      type: "text",
      idName: "Dia",
    },

    {
      id: 5,
      name: "Fitting Height",
      type: "text",
      idName: "FittingHeight",
    },
    {
      id: 6,
      name: "Fitting Type",
      type: "text",
      idName: "Fitting Type",
    },
    {
      id: 7,
      name: "DIM R",
      type: "text",
      idName: "Dim-r",
    },
    {
      id: 8,
      name: "DIM L",
      type: "text",
      idName: "Dim-l",
    },
    {
      id: 9,
      name: "Base",
      type: "text",
      idName: "base",
    },
    {
      id: 10,
      name: "Prism Remarks",
      type: "text",
      idName: "PrismRemarks",
    },
    {
      id: 11,
      name: "TINT",
      type: "text",
      idName: "Tint",
    },
    {
      id: 12,
      name: "Fitting",
      type: "text",
      idName: "Fitting",
    },
    {
      id: 13,
      name: "Mirror",
      type: "text",
      idName: "Mirror",
    },
    {
      id: 14,
      name: "Remarks",
      type: "text",
      idName: "Remarks",
    },
  ]);

  const WithFrameChange = () => {
    const val = document.getElementById("withFrame").value;
    console.log(val);
    if (val === "Y" || val === "y") {
      document.getElementById("FrameName").style.display = "block";
    } else {
      document.getElementById("FrameName").style.display = "none";
    }
  };

  return (
    <div className="">
      <PageTabs />
      <div className="nav-1">
        <div className="panel-heading">
          <h2 className="panel-title">Stock Out List</h2>
          <TableButton PageCreate="/StockOut" />
        </div>
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="search_panel_hd1">
              <div className="search_panel_left">
                <div className="col-sm-n1">
                  <div className="group_head">
                    <input
                      placeholder="Bill Series"
                      type="text"
                      className="bill_panel_input svl_input"
                    />
                  </div>
                  <div className="group_head">
                    <input
                      placeholder="Date From"
                      type="date"
                      className="date_panel_input svl_date_input"
                    />
                  </div>
                  <div className="group_head">
                    <input
                      placeholder="Date To"
                      type="date"
                      className="date_panel_input svl_date_input"
                    />
                  </div>
                  <div className="group_head">
                    <input
                      placeholder="Firm Name"
                      type="search"
                      className="searchbar_panel_input "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {isModalOpen && (
                <div className="modal">
                  <div className="modal-content_xls">
                    <div className="modal-title">
                      <span className="close" onClick={hideModal}>
                        &times;
                      </span>
                    </div>
                    <div className="tab-content-1">
                      <div className="company-info-top-right control_flex_m1 item_scroll">
                        <XLS />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-sm-12">
              <div className="table-resposive">
                <table
                  id="tblTransactionList"
                  className="table_hd1 table-bordered1"
                  frame="box"
                >
                  <thead textAlign="center" className="thead_scroll_col1">
                    <tr className="">
                      {/* <th><div className="table_head_m1">S.No.</div></th> */}
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb ">
                          <div className="table_head_mmb1">S.No.</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Date</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Bill No</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Mat Center</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">A/C</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Firm Name S_In</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Mat Ctr S_In</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Stock Value</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Sale Value</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>
                      <th className="td_col5">
                        {" "}
                        <div className="table_head_mmb">
                          <div className="table_head_mmb1">Status</div>
                          <div className="table_head_mmb2">
                            <i className="fa-solid fa-sort-up t_icon1"></i>
                            <i className="fa-solid fa-sort-down t_icon2"></i>
                          </div>
                        </div>{" "}
                      </th>

                      <th
                        className="table_edit table_head_mmb1 "
                        style={{ textAlign: "center" }}
                      >
                        Edit
                      </th>
                      <th
                        className="table_delete table_head_mmb1"
                        style={{ textAlign: "center" }}
                      >
                        Delete
                      </th>
                      <th
                        className="table_delete table_head_mmb1"
                        style={{ textAlign: "center" }}
                      >
                        Print
                      </th>
                      <th
                        className="table_delete table_head_mmb1"
                        style={{ textAlign: "center" }}
                      >
                        Wtsapp
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody_body_m1">
                    <tr className="table_tbody_tr1">
                      <td className="td_col5" onClick={openWorkshopModal}>
                        1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        27-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pankaj Verma
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        600
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">2</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        27-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        2
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Ram Sharma
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">3</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        27-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        3
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        jayant
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        0.00
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">4</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        27-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        4
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Vijay singh
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        0.00
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">5</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        28-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        5
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Rahul Singh
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        1000
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">6</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        28-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        6
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Sanjay Bhatt
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        600
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="table_tbody_tr1">
                      <td className="td_col5">7</td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        20-10-2023
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        7
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR1
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Geeta Ratre
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Techcherry
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        FR
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        0.00
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        500
                      </td>
                      <td className="td_col5" style={{ textalign: "start" }}>
                        Pending
                      </td>
                      <td>
                        <button className="edit_btn">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#008080" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="delete_btn">
                          <i
                            className="fa-solid fa-trash"
                            style={{ color: "red" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="print_btn">
                          <i
                            className="fa-solid fa fa-print"
                            style={{ color: "darkblue" }}
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button className="whatsapp_btn">
                          <i className="fa-brands fa-square-whatsapp"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/******************************************* For Mobile View ***********************************/}
      <div className="wrapper" style={{ display: "none" }}>
        <div className="container">
          <div className=" content-row-1">
            <div className="class_control">
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-up icon_3"></i>Sale
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    {" "}
                    <i className="fa-solid fa-clipboard icon_3"></i> Total
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>200
                  </p>
                </div>
              </div>
              <div className="balance-1">
                <div className="p-11">
                  <p className="balance-p1">
                    <i className="fa-solid fa-arrow-down icon_3"></i>Return
                  </p>
                </div>
                <div className="p-12">
                  <p className="balance-p2">
                    <i className="fa-solid fa-indian-rupee-sign rs_sign"></i>100
                  </p>
                </div>
              </div>
            </div>
            <div className="class_control_2">
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Parties</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Trasaction</p>
                </div>
              </div>
              <div className="parti_1">
                <div className="sub_parti_1">
                  <p className="parti-p-1">Items</p>
                </div>
              </div>
            </div>
            <div className="class_control_4">
              <div className="col_ms_1">
                <p className="p_13">Recent History</p>
                <div className="con_input_1">
                  <input
                    type="text"
                    className="input_box_2"
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div className="col_sm_11">
                <div className="col_sm_21">
                  <div className="col_sm_12">
                    <div className="con_name_1">
                      <p className="con_p_3">Akash kumar</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_1">Sale</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_2">Total</p>
                    </div>
                    <div className="con_name_1">
                      <p className="con_p_4">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_13">
                  <div className="col_sm_12">
                    <div className="con_total_3">
                      <p className="con_p_3">Balance</p>
                    </div>
                    <div className="con_total_3">
                      <p className="con_price_p">
                        <i className="fa-solid fa-indian-rupee-sign rs_sign1"></i>
                        1000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col_sm_14">
                  <div className="col_sm_12">
                    <div className="con_sale_2">
                      <p className="con_p_3">Date</p>
                    </div>
                    <div className="con_sale_2">
                      <p>#1</p>
                    </div>
                    <div className="con_name_1 con_name_7">
                      <i className="fa-solid fa-print"></i>
                      <i className="fa-solid fa-share"></i>
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ListSaveButton />
            </div>
          </div>
        </div>
      </div>
      {/************************* Workshop Modal *****************************/}
      {WorkshopModal && (
        <WorkshopModalLens
          closeWorkshopModal={closeWorkshopModal}
          WithFrameChange={WithFrameChange}
          LabelField={LabelField}
        />
      )}
    </div>
  );
}

export default StockOutList;
