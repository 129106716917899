// import { Alert } from "bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import CompanyApi from "../../../API/APIServices/Company";
import { useNavigate } from "react-router-dom";

const MasterReportDataTable = ({
  setPageNumber,
  PageNumber,
  pagecount,
  columns,
  data,
  filterData,
  totalValue,
  searchAnyText,
  searchByDate,
  searchByColumn,
  handleKeyDownUniversal,
  customStartDate,
  customEndDate,
  handleCustomDateChange,
  onDateFilterClick,
  firmNameSelect,
  transactionType,
  billSeries,
  useDoubleClick,
  reportAttachTo,
  reportType,
  navigateTo,
  handleNavigation,
  totalRecords,
}) => {
  //console.log(calculatedTotals);
  // //console.log("Template data", data);
  // //console.log("totalValue", totalValue);
  // //console.log("count", typeof pagecount);

  const [companyList, setCompanyList] = useState([]);
  const [showbox, setShowbox] = useState(false);
  const showdatefilter = () => {
    setShowbox((prevShowbox) => !prevShowbox);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of 'rows', we'll use 'page'
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 }, // Set the initial page index and page size
    },
    usePagination // Add the usePagination plugin
  );

  useEffect(() => {
    setPageSize(pagecount);
  }, [pagecount]);

  const [selectedDateFilter, setSelectedDateFilter] = useState("");

  const handleDateFilterClick = (filter) => {
    setSelectedDateFilter(filter);
    setShowbox(false);
    onDateFilterClick(filter);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await CompanyApi.GetCompany();
        //console.log(data);
        setCompanyList(data);
      } catch (error) {
        localStorage.removeItem("loginAccountUserData");
        localStorage.removeItem("loginUser");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("transactionType", transactionType);
  }, [transactionType]);
  /***************************************************************/

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  /***************************************************************/
  const containerRef2 = useRef(null);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedOptions2, setSelectedOptions2] = useState([]);

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleCheckboxChange2 = (checkboxValue) => {
    debugger;
    if (selectedOptions2.includes(checkboxValue)) {
      setSelectedOptions2(
        selectedOptions2.filter((item) => item !== checkboxValue)
      );
    } else {
      setSelectedOptions2([...selectedOptions2, checkboxValue]);
    }
  };

  const handleSelectAllChange2 = (companyList) => {
    //console.log(companyList);
    if (selectedOptions2.length === 0) {
      const allOptions2 = [...companyList]; // Corrected typo in checkBox5
      setSelectedOptions2(allOptions2);
    } else {
      setSelectedOptions2([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef2.current &&
        !containerRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef2]);

  const handleclear = () => {
    setSelectedDateFilter("");
    handleDateFilterClick();
  };

  const [increasecount, setincreasecount] = useState(0);
  const [currentpagecount, setcurrentpagecount] = useState(1);
  const handlenext = () => {
    debugger;
    //console.log("logext");
    setPageNumber(PageNumber + 1);
    setcurrentpagecount((prev) => prev + 1);
    setincreasecount((prev) => prev + pagecount);
  };

  const handleprev = () => {
    PageNumber != 1 && setincreasecount((prev) => prev - pagecount);
    PageNumber != 1 && setPageNumber(PageNumber - 1);
    currentpagecount != 1 && setcurrentpagecount((prev) => prev - 1);
  };

  const handleDoubleClick = (row) => {
    // alert(id);
    debugger;
    console.log("row", row.t1id);
    console.log("row", row.t2id);
    const conditions = {
      1: () => alert("one"),
      2: () => alert("two"),
      //ReportType 4
      63: () => handleNavigation(navigateTo, row),
    };

    if (conditions[reportAttachTo]) {
      conditions[reportAttachTo]();
    } else {
      console.warn("No function found for this ID");
    }
  };

  return (
    <div className="panel panel-default">
      <div className="panel-body">
        {/* ... Search panel code ... */}
        <div className="col-sm-12" id="pdfContent">
          <div>
            {/* <button onClick={handleExportToExcel}>Btn</button> */}
            <div className="Search-Any_Text">
              {searchAnyText == true && (
                <div className="Search-report-box">
                  <input
                    className="Input-search-report"
                    type="text"
                    // value={universalSearch}
                    // onChange={(e) => setUniversalSearch(e.target.value)}
                    onKeyDown={(e) => handleKeyDownUniversal(e, filterData)}
                    placeholder="Search By Any Text"
                  />
                </div>
              )}
              {firmNameSelect == true && (
                <div
                  className="CompWidthMasterUser FirmName-select"
                  ref={containerRef2}
                >
                  <div className="input-box-mok1" onClick={toggleDropdown2}>
                    <select
                      className="modify_im_input mode_category1 input-firmname"
                      type="checkbox"
                      required
                    >
                      <option>Select options</option>
                      {/* {companyList && companyList.length > 0 ? (
                                                companyList.map((company, index) => (
                                                    <option
                                                        className="s-1 form-control"
                                                        value={company.branchCode}
                                                        key={index}
                                                    >
                                                        {company.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>No options available</option>
                                            )} */}
                    </select>
                    <label className="UseMasterLebalFix">Firm Name</label>
                    <div className="overSelect"></div>
                  </div>
                  {isOpen2 && (
                    <div className="check-firname" id="checkBoxes">
                      <label htmlFor="selectAll">
                        <input
                          className="user_checkbox"
                          type="checkbox"
                          id="selectAll"
                          checked={
                            selectedOptions2.length === companyList.length
                          }
                          onChange={() => handleSelectAllChange2(companyList)}
                        />
                        Select All
                      </label>
                      {companyList.map((company) => (
                        <label htmlFor="first" key={company}>
                          <input
                            className="user_checkbox"
                            type="checkbox"
                            id={company}
                            checked={selectedOptions2.includes(company)}
                            onChange={() => handleCheckboxChange2(company)}
                          />
                          {company.name}
                        </label>
                      ))}
                      {/* <label htmlFor="second">
                                                <input className="user_checkbox"
                                                    type="checkbox" id="second"
                                                    checked={selectedOptions2.includes('checkBox2')}
                                                    onChange={() => handleCheckboxChange2('checkBox2')} />
                                                My Optical
                                            </label>

                                            <label htmlFor="third">
                                                <input className="user_checkbox"
                                                    type="checkbox" id="third"
                                                    checked={selectedOptions2.includes('checkBox3')}
                                                    onChange={() => handleCheckboxChange2('checkBox3')} />
                                                E-Com
                                            </label>

                                            <label htmlFor="fourth">
                                                <input className="user_checkbox"
                                                    type="checkbox" id="fourth"
                                                    checked={selectedOptions2.includes('checkBox4')}
                                                    onChange={() => handleCheckboxChange2('checkBox4')} />
                                                Restaurant
                                            </label>
                                            <label htmlFor="fourth">
                                                <input className="user_checkbox"
                                                    type="checkbox" id="fourth"
                                                    checked={selectedOptions2.includes('checkBox5')}
                                                    onChange={() => handleCheckboxChange2('checkBox5')} />
                                                Pharmacy
                                            </label> */}
                    </div>
                  )}
                </div>
              )}
              {transactionType == true && (
                <div className="CompWidthMasterUser FirmName-select">
                  <div className="input-box-mok1">
                    <select
                      className="modify_im_input mode_category1 input-firmname"
                      type="checkbox"
                      required
                    >
                      <option>Sale</option>
                      <option>Purchase</option>
                      <option>Sale Return</option>
                      <option>Purchase</option>
                    </select>
                    <label className="UseMasterLebalFix">
                      Transaction Type
                    </label>
                  </div>
                </div>
              )}
              {billSeries == true && (
                <div className="CompWidthMasterUser FirmName-select">
                  <div className="input-box-mok1">
                    <select
                      className="modify_im_input mode_category1 input-firmname"
                      type="checkbox"
                      required
                    >
                      <option>All</option>
                      <option>Sale</option>
                      <option>Sale-L</option>
                    </select>
                    <label className="UseMasterLebalFix">Bill Series</label>
                  </div>
                </div>
              )}
              {searchByDate == true && (
                <div className="tablelist-datefilter-box">
                  <button
                    className="tablelist-main-search-button"
                    onClick={showdatefilter}
                  >
                    {selectedDateFilter || "Select Date Filter"}
                  </button>
                  {showbox && (
                    <div className="tablelist-datefilter date-right-prev">
                      {/* <div className="tablelist-datefilter-1">
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === "Today"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick("Today")}
                                                >
                                                    Today
                                                </div>
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === "Yesterday"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick("Yesterday")}
                                                >
                                                    Yesterday
                                                </div>
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === "This Week"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick("This Week")}
                                                >
                                                    This Week
                                                </div>
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === " Last 7 Days"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick(" Last 7 Days")}
                                                >
                                                    Last 7 Days
                                                </div>
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === "This Month"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick("This Month")}
                                                >
                                                    This Month
                                                </div>
                                                <div
                                                    className={`tablelist-datefilter-option ${selectedDateFilter === "Previous Month"
                                                        ? "tablelist-datefilter-option-selected"
                                                        : ""
                                                        }`}
                                                    onClick={() => handleDateFilterClick("Previous Month")}
                                                >
                                                    Previous Month
                                                </div>
                                            </div> */}
                      <div className="tablelist-datefilter-1">
                        {[
                          "Today",
                          "Yesterday",
                          "This Week",
                          "Last 7 Days",
                          "This Month",
                          "Previous Month",
                        ].map((filter) => (
                          <div
                            key={filter}
                            className={`tablelist-datefilter-option ${selectedDateFilter === filter
                              ? "tablelist-datefilter-option-selected"
                              : ""
                              }`}
                            onClick={() => handleDateFilterClick(filter)}
                          >
                            {filter}
                          </div>
                        ))}
                      </div>
                      <div className="tablelist-datefilter-2">
                        <p style={{ margin: "0", textAlign: "start" }}>
                          Custom
                        </p>
                        <div className="tablelist-datefilter-date">
                          <input
                            type="date"
                            name=""
                            id="start-date"
                            value={customStartDate}
                            onChange={handleCustomDateChange}
                          />{" "}
                          to
                          <input
                            type="date"
                            name=""
                            id="end-date"
                            value={customEndDate}
                            onChange={handleCustomDateChange}
                          />
                        </div>
                      </div>
                      <button
                        className="tablelist-datefilter-button"
                        //  onClick={showdatefilter}
                        onClick={(e) => handleCustomDateChange(e, filterData)}
                      >
                        Apply
                      </button>
                      {selectedDateFilter?.length != 0 && (
                        <button
                          className="tablelist-datefilter-button"
                          //  onClick={showdatefilter}
                          onClick={() => handleclear()}
                        >
                          Clear
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                minHeight: "",
                maxHeight: "80vh",
                overflow: "scroll",
                scrollbarWidth: "none",
              }}
            >
              <table className="tableList-main" {...getTableProps()}>
                {/* <thead className="tablelist-input-box" style={{ height: "35px" }}>
                                <tr>

                                    <th></th>
                                </tr>
                            </thead> */}
                {/* <div style={{ position: 'sticky', top: '0', display: 'block', width:'100vw' }}> */}
                {searchByColumn == true && (
                  <thead
                    className="tablelist-input-box thead-2"
                    style={{ height: "35px" }}
                  >
                    <tr>
                      {filterData.map((data, index) => (
                        <th key={index} style={{ width: "max-content" }}>
                          {data.Header}
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                )}

                <thead
                  id="pdfContent"
                  className="table-head-main"
                  style={{ height: "35px" }}
                >
                  {headerGroups.map((headerGroup, index) => {
                    return (
                      <tr
                        {...headerGroup.getHeaderGroupProps()}
                        id={`header-row-${index}`}
                      >
                        {headerGroup.headers.map((column, columnIndex) => {
                          if (column.Header == "SNO") {
                            return (
                              <th
                                style={{ width: "40px" }}
                                {...column.getHeaderProps()}
                                id={`header-${index}-${columnIndex}`}
                              >
                                {column.render("Header")}
                              </th>
                            );
                          }
                          return (
                            <th
                              {...column.getHeaderProps()}
                              id={`header-${index}-${columnIndex}`}
                            >
                              {column.render("Header")}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                {/* </div> */}

                <tbody
                  id=""
                  style={{ height: "auto", overflowY: "scroll" }}
                  className="table-body-main"
                  {...getTableBodyProps()}
                >
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{ height: "30px" }}
                        id={`row-${rowIndex}`}
                        className={useDoubleClick ? "highlight-row" : ""}
                        onDoubleClick={
                          useDoubleClick
                            ? () => handleDoubleClick(row.original)
                            : undefined
                        }
                      >
                        {row.cells.map((cell, cellIndex) => {
                          if (cell.column.Header == "REPORT TYPE") {
                            if (cell.value == 1) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  List Type
                                </td>
                              );
                            }
                          }
                          if (cell.column.Header == "REPORT TYPE") {
                            if (cell.value == 2) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Bill Type
                                </td>
                              );
                            }
                          }
                          if (cell.column.Header == "REPORT TYPE") {
                            if (cell.value == 3) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Import Export Type
                                </td>
                              );
                            }
                          }
                          if (cell.column.Header == "REPORT TYPE") {
                            if (cell.value == 4) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Ledger Detail List Type
                                </td>
                              );
                            }
                          }
                          if (cell.column.Header == "REPORT ATTACH TYPE") {
                            if (cell.value == 4) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Item
                                </td>
                              );
                            }
                            if (cell.value == 8) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Unit
                                </td>
                              );
                            }
                            if (cell.value == 8) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Unit
                                </td>
                              );
                            }
                            if (cell.value == 1) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Account Group
                                </td>
                              );
                            }
                            if (cell.value == 2) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Account
                                </td>
                              );
                            }
                            if (cell.value == 3) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Item Gruop
                                </td>
                              );
                            }
                            if (cell.value == 6) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Material Center
                                </td>
                              );
                            }
                            if (cell.value == 7) {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                >
                                  Std Narration
                                </td>
                              );
                            }
                          }

                          if (cell.column.Header == "SNO") {
                            //console.log("sno cell", cell);

                            return (
                              <React.Fragment key={cellIndex}>
                                <td
                                  {...cell.getCellProps()}
                                  id={`cell-${rowIndex}-${cellIndex}`}
                                  style={{ width: "40px" }}
                                >
                                  {cell.value + increasecount}
                                </td>
                              </React.Fragment>
                            );
                          }

                          return (
                            <React.Fragment key={cellIndex}>
                              <td
                                {...cell.getCellProps()}
                                id={`cell-${rowIndex}-${cellIndex}`}
                              >
                                {cell.render("Cell")}
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                  {/* </div> */}
                </tbody>
              </table>
            </div>

            <div
              style={{ width: "95%" }}
              className="table-list-button-box"
            >
              {/* <div className="shortcutkeys-header">
                                {columns.map((data, index) => {
                                    if (index !== columns.length - 3 && index !== columns.length - 2 && index !== columns.length - 1) {
                                        return (
                                            <p key={index} style={{ fontSize: "13px", color: "#212e4f" }}>
                                                Press ALT + {index + 1} to open {columns[index + 2]?.Header || 'unknown'} filter
                                            </p>
                                        );
                                    }
                                    return null;
                                })}
                            </div> */}
              <div
                className="shortcutkeys-header"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "5px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "end",
                    gap: "2px",
                    width: "77%",
                    // margin: "5px 0px"
                  }}
                >
                  {totalValue &&
                    totalValue.map((data, index) => {
                      // //console.log("columns", data);
                      if (data.columnShowTotalValue == "false") {
                        return null;
                      }
                      return (
                        <div
                          style={{
                            display:
                              data.columnShowTotalValue == "true"
                                ? "block"
                                : "none",
                            width: "190px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="tablelist-input-box"
                          key={index}
                        >
                          <label
                            className=""
                            type="text"
                            key={index}
                            style={{ width: "max-content" }}
                          >
                            {data.total !== null
                              ? `${data.accessor} Total: ${data.total}`
                              : ""}
                          </label>
                        </div>
                      );
                    })}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "25%",
                    margin: "5px 0px",
                  }}
                >
                  <button
                    onClick={() => handleprev()}
                    // disabled={!canPreviousPage}
                    className="table-list-button"
                  >
                    <i className="fa-solid fa-chevron-left"></i>
                  </button>
                  <span className="table-list-page-txt">
                    Page -
                    <strong style={{ margin: "0px 3px" }}>
                      {/* {pageIndex + 1} of {Math.ceil(data.length / pageSize)} */}
                      {currentpagecount} of{" "}
                      {Math.ceil(totalRecords / pagecount)}
                    </strong>
                  </span>
                  <button
                    onClick={() => handlenext()}
                    disabled={page.length < pagecount}
                    className="table-list-button"
                  >
                    <i className="fa-solid fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterReportDataTable;
