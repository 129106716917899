import DateTime from "../../Components/DateTime";
import React, { useState, useEffect, useContext, useMemo } from "react";
import PageTabs from "../../Components/PageTabs";
import MainButton from "../../Props/MainButton";
import AlertBox from "../../Props/AlertBox";
import MyContext from "../../MyContext";
import PropTypes from "prop-types";
import TransactionTypeSetting from "../../../API/APIServices/TransactionTypeSetting";
import BillSeriesMaster from "../../../API/APIServices/BillSeriesMaster";
import WebTemplate from "../../../API/APIServices/WebTemplate";
import BillSeriesWiseTemplateSetting from "../../../API/APIServices/BillSeriesWiseTemplateSetting";

function VoucherWiseReportSetting({ setSliderCallBack, modelOpen }) {
  VoucherWiseReportSetting.propTypes = {
    fetchData: PropTypes.func.isRequired,
    setSliderCallBack: PropTypes.func.isRequired,
    editData: PropTypes.object,
    modelOpen: PropTypes.bool.isRequired,
  };

  const {
    OpenAlertBox,
    alertBox,
    OpenAlertBoxEdit,
    alertBoxEdit,
    alertBoxDelete,
    OpenAlertBoxDelete,
  } = useContext(MyContext);

  useState(() => {
    document.title = "Bill Series Wise Report Settings";
  }, []);

  const [, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };
  const [inputValues, setInputValues] = useState({
    printType: 0,
    voucherSeries: 0,
    reportTemplateId: 0,
    showPrintOptionPopup: false,
    formate1_Btn: false,
    formate2_Btn: false,
    sendMsg_Btn: false,
    sendEmail_Btn: false,
    printBarcode_Btn: false,
  });

  const [options, setOptions] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [billSettData, setBillSettData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [billSettId, setBillSettId] = useState(false);
  const fetchData1 = async () => {
    try {
      const response =
        await TransactionTypeSetting.GetAllTransactionTypeMaster();
      const response2 = await BillSeriesMaster.getBillSeriesMaster();

      if (
        response2 &&
        Array.isArray(response2) &&
        response &&
        Array.isArray(response)
      ) {
        const optionsData = response2
          .filter((billSeries) => billSeries.billSeriesName)
          .map((billSeries) => {
            const transactionType = response.find(
              (type) => type.id === billSeries.parentGrpMasterId
            );
            return {
              id: billSeries.id,
              billSeriesName: billSeries.billSeriesName,
              transactionTypeName: transactionType?.masterName || "Unknown",
              transactionTypeId: billSeries.parentGrpMasterId,
            };
          });

        return optionsData;
      } else {
        console.error("Error:", response.errorMessages);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const memoizedOptions = useMemo(() => {
    if (isDataFetched) {
      return options;
    }

    return [];
  }, [options, isDataFetched]);

  useEffect(() => {
    if (!isDataFetched) {
      fetchData1().then((data) => {
        setOptions(data);
        setIsDataFetched(true);
      });
    }
  }, [isDataFetched]);

  useEffect(() => {
    if (memoizedOptions.length > 0) {
      setOptions(memoizedOptions);
    }
  }, [memoizedOptions]);

  const fetchTemplateData = async () => {
    try {
      const response = await WebTemplate.GetCustomizedPageTemplates();
      if (response) {
        setTemplateData(response);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTemplateData();
  }, []);

  const fetchBillSettingData = async () => {
    try {
      const response =
        await BillSeriesWiseTemplateSetting.GetAllBillSeriesWiseTemplateSetting();
      if (response.isSuccess) {
        console.log("response.Result", response.result);
        setBillSettData(response.result);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchBillSettingData();
  }, []);

  useEffect(() => {
    console.log("inputValues", inputValues);
  }, [inputValues]);

  const SaveBtn = async () => {
    debugger;
    if (inputValues.printType == 0) {
      alert("Please select Print Type!");
      return;
    }
    var response;
    try {
      if (isEditing) {
        const data = {
          ...inputValues,
          id: billSettId,
        };
        response =
          await BillSeriesWiseTemplateSetting.UpdateBillSeriesWiseTemplateSetting(
            data
          );
      } else {
        response =
          await BillSeriesWiseTemplateSetting.CreateBillSeriesWiseTemplateSetting(
            inputValues
          );
      }
      if (response.isSuccess) {
        console.log("response", response);
        alert("Success!");
        fetchBillSettingData();
        setIsEditing(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const updatedValue = type === "checkbox" ? checked : value;

    setInputValues({
      ...inputValues,
      [name]: updatedValue,
    });
  };

  const handleUpdate = async (id) => {
    try {
      setBillSettId(id);
      const response =
        await BillSeriesWiseTemplateSetting.GetAllBillSeriesWiseTemplateSettingById(
          id
        );
      if (response.isSuccess) {
        debugger;
        setIsEditing(true);
        setInputValues({
          printType: response.result.printType,
          voucherSeries: response.result.voucherSeries,
          reportTemplateId: response.result.reportTemplateId,
          showPrintOptionPopup: response.result.showPrintOptionPopup,
          formate1_Btn: response.result.formate1_Btn,
          formate2_Btn: response.result.formate2_Btn,
          sendMsg_Btn: response.result.sendMsg_Btn,
          sendEmail_Btn: response.result.sendEmail_Btn,
          printBarcode_Btn: response.result.printBarcode_Btn,
        });
        console.log("responseById", response);
      }
    } catch (error) {
      throw new error("Unable to Fetch the data");
    }
  };

  const handleDelete = async (id) => {
    try {
      setBillSettId(id);
      const response =
        await BillSeriesWiseTemplateSetting.DeleteBillSeriesWiseTemplateSettingById(
          id
        );
      if (response.isSuccess) {
        fetchBillSettingData();
        console.log("responseById", response);
      }
    } catch (error) {
      throw new error("Unable to Fetch the data");
    }
  };

  return (
    <div>
      <PageTabs modelOpen={modelOpen} setSliderCallBack={setSliderCallBack} />
      <div className="panel-heading">
        <div className="panel-head-title">
          <h2 className="panel-title">Bill Series Wise Report Settings</h2>
        </div>
        {/* *******************Top Buttons****************** */}
        <MainButton
          SaveButton={SaveBtn}
          isBtnSetting={false}
          showModal={showModal}
          modelOpen={modelOpen}
          setSliderCallBack={setSliderCallBack}
        />
      </div>

      <div>
        <DateTime />
      </div>
      <div className="content_box">
        <div className="company-info-top-im">
          <div className="tab-head">
            <div id="tabs">
              <div className="tab-content-box">
                <div className="tab-content-1">
                  <div className="company-info-top-right control_flex_m1">
                    <div className="control_radius">
                      <div className="company_info-text-im depart_top">
                        Bill Series Wise Report Settings
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                type="text"
                                name="printType"
                                value={inputValues.printType}
                                required
                              >
                                <option>--Select Report Type--</option>
                                <option value="1">Bill Print</option>
                                <option value="2">Barcode Print</option>
                              </select>
                              <label class="modify_lbl_text3">Print Type</label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2 flex-report-for">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                name="voucherSeries"
                                value={inputValues.voucherSeries}
                                required
                              >
                                <option value="">--Select--</option>
                                {options.map((option, index) => (
                                  <option key={index} value={option.id}>
                                    {option.billSeriesName} {" - "}{" "}
                                    {`(${option.transactionTypeName})`}
                                  </option>
                                ))}
                              </select>
                              <label className="modify_lbl_text3">
                                Select Voucher Series
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2 flex-report-for">
                            <div className="input-box-mok1">
                              <select
                                className="modify_im_input mode_category1"
                                onChange={handleInputChange}
                                name="reportTemplateId"
                                value={inputValues.reportTemplateId}
                                required
                              >
                                <option value="">--Select--</option>
                                {templateData.map((data, index) => (
                                  <option key={index} value={data.id}>
                                    {data.name}
                                  </option>
                                ))}
                              </select>
                              <label className="modify_lbl_text3">
                                Select Template Name
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              <label className="modify_p_text2">
                                Show Print Window
                              </label>
                              <input
                                type="checkbox"
                                name="showPrintOptionPopup"
                                checked={inputValues.showPrintOptionPopup}
                                onChange={handleInputChange}
                                className="toggle-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="company_control">
                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2 flex-report-for">
                            <div className="input-box-mok1"></div>
                          </div>
                        </div>

                        <div className="stock-im-dep1">
                          <div className="input-box-Imo1 input-height2">
                            <div className="input-box-mok1">
                              {inputValues.showPrintOptionPopup && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label
                                      className="modify_p_text2"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "10px",
                                      }}
                                    >
                                      Show Format 1
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="formate1_Btn"
                                      checked={inputValues.formate1_Btn}
                                      onChange={handleInputChange}
                                      className="toggle-input"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label
                                      className="modify_p_text2"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "10px",
                                      }}
                                    >
                                      Show Format 2
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="formate2_Btn"
                                      checked={inputValues.formate2_Btn}
                                      onChange={handleInputChange}
                                      className="toggle-input"
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label
                                      className="modify_p_text2"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "10px",
                                      }}
                                    >
                                      Send Message
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="sendMsg_Btn"
                                      checked={inputValues.sendMsg_Btn}
                                      onChange={handleInputChange}
                                      className="toggle-input"
                                    />
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label
                                      className="modify_p_text2"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "10px",
                                      }}
                                    >
                                      Send Email
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="sendEmail_Btn"
                                      checked={inputValues.sendEmail_Btn}
                                      onChange={handleInputChange}
                                      className="toggle-input"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label
                                      className="modify_p_text2"
                                      style={{
                                        position: "relative",
                                        top: "0",
                                        left: "10px",
                                      }}
                                    >
                                      Print Barcode
                                    </label>
                                    <input
                                      type="checkbox"
                                      name="printBarcode_Btn"
                                      checked={inputValues.printBarcode_Btn}
                                      onChange={handleInputChange}
                                      className="toggle-input"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="table-div"
        style={{
          maxHeight: "400px",
          // overflowX: "auto",
          overflowY: "auto",
          border: "1px solid #ddd",
        }}
      >
        <table className="tableList-main">
          <thead className="table-head-main">
            <tr>
              <th>Sno</th>
              <th>Print Type</th>
              <th>Voucher Series</th>
              <th>Report Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-body-main">
            {billSettData.map((item, idx) => (
              <tr key={item.id}>
                <td>{idx + 1}</td>
                <td>
                  {Number(item.printType) == 1 ? "Print Type" : "Barcode Type"}
                </td>
                <td>{item.voucherSeriesName}</td>
                <td>{item.reportTemplateName}</td>
                <td>
                  <i
                    className="fa-regular fa-pen-to-square TableEditIcon"
                    onClick={() => handleUpdate(item.id)}
                  />
                  <i
                    className="fa-regular fa-trash-can TableDelIcon"
                    onClick={() => handleDelete(item.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {alertBoxDelete && (
        <AlertBox
          HeadingTop="DELETE"
          Heading="Are You sure you want to delete this"
          OpenAlertBox={OpenAlertBoxDelete}
        />
      )}
      {alertBox && (
        <AlertBox
          HeadingTop="Saved"
          Heading="Your Report Setting is Saved"
          onYesClick={OpenAlertBox}
          singleButton={true}
          OpenAlertBox={OpenAlertBox}
        />
      )}
      {alertBoxEdit && (
        <AlertBox
          HeadingTop="Updated"
          Heading="Your Report Setting is Updated"
          onYesClick={OpenAlertBoxEdit}
          singleButton={true}
          OpenAlertBox={OpenAlertBoxEdit}
        />
      )}
    </div>
  );
}

export default VoucherWiseReportSetting;
