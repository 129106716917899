import React, { useState } from 'react';
import logo from "./../../images/logo.png";
import CommonDeploymentService from '../../API/APIServices/CommonDeploymentService';
import Loader from '../Props/Loader';
import AlertBox from '../Props/AlertBox';
import { useNavigate } from 'react-router-dom';


function LoginCommonDeployment() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [mobileOrEmail, setMobileOrEmail] = useState('');
    const [openAlertBox, setOpenAlertBox] = useState(false);
    const [headingTop, setHeadingTop] = useState('YES-BM !');
    const [heading, setHeading] = useState('');

    async function handleLogin() {

        let data = mobileOrEmail.trim();

        if (!data) {
            setHeading("Please enter a mobile or email id.");
            setOpenAlertBox(true);
            return;
        }

        setLoading(true);

        try {
            let response = await CommonDeploymentService.getYESBMAccountByEmailOrMobile(data);

            if (response.data.isSuccess) {
                navigate(`/LogInPanel?q=${response.data.result.uniqueId}`);
            } else {
                setHeading(response.data.errorMessages);
                setOpenAlertBox(true);
            }
        } catch (error) {
            setHeading(error.response.data.errorMessages);
            setOpenAlertBox(true);
        }

        setLoading(false);

    }


    return (
        <div className="Formheader git check">
            <div>
                <div className="form-container">
                    <div className="form-sidebar-yesbm-logo ">
                        <img src={logo} alt="" />
                    </div>
                    <p className="FormTitle">Login</p>
                    <div className="formMainDiv">

                        <div className="InputTextD">
                            <input value={mobileOrEmail} onChange={(e) => setMobileOrEmail(e.target.value)} type="text" className="FormInputBox" placeholder='Enter Registered Mobile No. Or E-Mail' />
                        </div>

                        <button onClick={handleLogin} className="form-btn">Login</button>


                    </div>
                </div>
            </div>

            {loading && <Loader />}
            {openAlertBox &&
                    <AlertBox
                        OpenAlertBox={() => setOpenAlertBox(false)}
                        HeadingTop={headingTop}
                        Heading={heading}
                        singleButton={true}
                        onYesClick={() => setOpenAlertBox(false)}
                    />
            }
        </div>
    )
}

export default LoginCommonDeployment;